import axios from "axios";
import { bus } from "@/main";
import { Notify } from "quasar";

const state = {
  registro: {
    email: "",
    nome: "",
    celular: "",
    tfls: 1,
    telefone: "",
    cnpj: "",
    atividade: "",
    razao: "",
    fantasia: "",
    cep: "",
    cidade: "",
    estado: "",
    bairro: "",
    endereco: "",
    numero: "",
    complemento: "",
    acceptTerms: false,
    uuid: null,
  },
  registrar: {
    saveRegistro: false,
    errors: [],
    quartaEtapa: false
  },
  passosRegistro: 0,
  videoRegistro: null
};

const mutations = {
  registro: (state, userData) => {
    state.registro.email = userData.email;
    state.registro.nome = userData.nome;
    state.registro.celular = userData.celular;
    state.registro.tfls = userData.tfls;
    state.registro.telefone = userData.telefone;
    state.registro.cnpj = userData.cnpj;
    state.registro.atividade = userData.atividade;
    state.registro.razao = userData.razao;
    state.registro.fantasia = userData.fantasia;
    state.registro.cep = userData.cep;
    state.registro.cidade = userData.cidade;
    state.registro.estado = userData.estado;
    state.registro.bairro = userData.bairro;
    state.registro.endereco = userData.endereco;
    state.registro.numero = userData.numero;
    state.registro.complemento = userData.complemento;
    state.registro.acceptTerms = userData.acceptTerms;
    state.registro.uuid = userData.uuid;
  },
  registrar: (state, userData) => {
    state.registrar.saveRegistro = userData.saveRegistro;
    state.registrar.errors = userData.errors;
    state.registrar.quartaEtapa = userData.quartaEtapa;
  },
  passosRegistro: (state, userData) => {
    state.passosRegistro = userData;
  },
  videoRegistro: (state, userData) => state.videoRegistro = userData
};

const actions = {
  registro: ({ commit }, userData) => {
    commit("registro", {
      email: userData.email,
      nome: userData.nome,
      celular: userData.celular,
      tfls: userData.tfls,
      telefone: userData.telefone,
      cnpj: userData.cnpj,
      atividade: userData.atividade,
      razao: userData.razao,
      fantasia: userData.fantasia,
      cep: userData.cep,
      cidade: userData.cidade,
      estado: userData.estado,
      bairro: userData.bairro,
      endereco: userData.endereco,
      numero: userData.numero,
      complemento: userData.complemento,
      acceptTerms: userData.acceptTerms,
      uuid: userData.uuid,
    });
  },
  registrar: ({ commit }, userData) => {
    commit("registrar", {
      saveRegistro: true,
      quartaEtapa: false,
      errors: []
    });
    commit("passosRegistro", 0);

    userData.telefone = userData.celular;

    axios
      .post("/users/registrar", userData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          commit("videoRegistro", res.data.videoRegistro);
          commit("registrar", {
            quartaEtapa: true,
            errors: []
          });
        } else {
          if (res.data.user && !res.data.empresa_errors) {
            commit("registrar", {
              errors: res.data.user_errors || {},
              quartaEtapa: false
            });
            commit("passosRegistro", 1);
            Notify.create({
              message:
                "Houve problemas no passo 1. Retorne os passos e digite os campos corretamente.",
              color: "red",
              position: "top-right"
            });

            if (res.data.celular_diferente) {
              bus.$emit("celularDiferenteRegistro");
            }
            return;
          }

          // PASSSO 2
          var passo2 = [
            res.data.empresa_errors.cnpj,
          ];
          var aux2 = false;

          for (var i = 0; i < passo2.length; i++) {
            if (passo2[i] != undefined) {
              aux2 = true;
            }
          }
          if (aux2) {
            commit("registrar", {
              errors: res.data.empresa_errors,
              quartaEtapa: false
            });
            commit("passosRegistro", 2);
            Notify.create({
              message:
                "Houve problemas no passo 2. Retorne os passos e digite os campos corretamente.",
              color: "red",
              position: "top-right"
            });
            return;
          }

          // PASSSO 3
          var passo3 = [
            res.data.empresa_errors.razao,
            res.data.empresa_errors.fantasia,
            res.data.empresa_errors.cep,
            res.data.empresa_errors.cidade,
            res.data.empresa_errors.estado,
            res.data.empresa_errors.bairro,
            res.data.empresa_errors.endereco,
            res.data.empresa_errors.acceptTerms
          ];
          var aux3 = false;
          for (var y = 0; y < passo3.length; y++) {
            if (passo3[y] != undefined) {
              aux3 = true;
            }
          }
          if (aux3) {
            commit("registrar", {
              errors: res.data.empresa_errors,
              quartaEtapa: false
            });
            commit("passosRegistro", 3);
            Notify.create({
              message:
                "Houve problemas no passo 3. Retorne os passos e digite os campos corretamente.",
              color: "red",
              position: "top-right"
            });
            return;
          }
        }
        commit("registrar", {
          saveRegistro: false,
          quartaEtapa: state.registrar.quartaEtapa,
          errors: state.registrar.errors
        });
      });
  }
};

const getters = {
  registro: state => {
    return state.registro;
  },
  registrar: state => {
    return state.registrar;
  },
  passosRegistro: state => {
    return state.passosRegistro;
  },
  videoRegistro: state => {
    return state.videoRegistro;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
