<template>
  <div id="modalDataBloqueio">
    <q-dialog v-model="OpenDialogDataBloqueio" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Data de Bloqueio</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div>
            <div>
              <div class="col col-md-12" v-if="ultima_data_bloqueio === null">
                <center>
                  <q-spinner
                      color="primary"
                      size="2em"
                    />
                </center>
              </div>

              <div v-else class="q-pa-sm">
                <q-banner class="bg-grey-3 q-mb-lg">
                  <strong>O último bloqueio foi definido para o dia {{ultima_data_bloqueio}}</strong>
                </q-banner>

                <div class="col col-md-12">
                  <q-input
                    v-model="movcontas_data_bloqueio"
                    label="Data"
                    @click="$refs.qDateProxy.show();"
                    :error="errors.movcontas_data_bloqueio ? true : false"
                    clearable
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          ref="qDateProxy"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="movcontas_data_bloqueio"
                            @input="() => $refs.qDateProxy.hide()"
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            minimal
                            today-btn
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.movcontas_data_bloqueio" :key="error + i">{{error}}</span>
                    </template>
                  </q-input>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>

         <q-card-actions align="right" class="text-primary">
          <q-btn
            no-wrap
            v-ripple
            label="Salvar"
            color="green"
            no-caps
            style="margin: 0 20px 20px 0"
            clickable
            :loading="loading"
            @click="salvarSaldoInicial()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify } from "quasar";
import axios from "@/axios-auth";

export default {
  name: "modal-data-bloqueio",
  data() {
    return {
      movcontas_data_bloqueio: null,
      loading: false,
      options: {},
      ultima_data_bloqueio: null,
      errors:[]
    };
  },
  methods: {
    closeModal() {
      this.errors = [];
      this.ultima_data_bloqueio = null;
      this.movcontas_data_bloqueio = null;

      this.$store.commit("OpenDialogDataBloqueio", false);
    },
    salvarSaldoInicial() {
      if (this.loading) return;

      this.loading = true;

      let data = {
        movcontas_data_bloqueio: this.movcontas_data_bloqueio,
      };

      axios
        .post("/financeiro/bloquear", data)
        .then(res => {
          if (!res.data.success) {
            this.errors = res.data.errors;
          }else{
            Notify.create({
              message: "Sucesso ao alterar data de bloqueio",
              color: "green",
              position: "top-right"
            });
            
            this.closeModal();
          }

          this.loading = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.loading = false;
        });
    },
    loadOptions() {
      axios
        .get("/financeiro/bloquear")
        .then(res => {
          if (!res.data.success) {
            throw res.data;
          }

          this.movcontas_data_bloqueio = res.data.options.movcontas_data_bloqueio;
          this.ultima_data_bloqueio = res.data.options.movcontas_data_bloqueio;
        });
    },
  },
  computed: {
    OpenDialogDataBloqueio:{
      get(){
        let open  = this.$store.getters.OpenDialogDataBloqueio;

        if(open){
          this.loadOptions();
        }

        return open;
      },
      set(){}
    }
  },
};
</script>

<style lang="stylus" scoped></style>
