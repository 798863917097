<template>
  <q-dialog v-model="dialogOpened" persistent>
    <q-card style="min-width: 1024px !important">
      <q-card-section class="row items-center">
        <div class="text-h6">{{ title }}</div>
        <q-space />

        <q-btn-group class="q-ml-md" rounded flat no-wrap no-caps>
          <q-btn
            class="white text-blue-7"
            clickable
            padding="sm"
            @click="changeFilterPeriodo('prev')"
            rounded
            icon="chevron_left"
          />

          <q-btn
            class="white text-blue-7"
            rounded
            :label="dtini"
            padding="sm"
          >
            <template v-slot:default>
              <q-popup-proxy ref="qDateDtini" transition-show="scale" transition-hide="scale">
                <q-date
                  mask="DD/MM/YYYY"
                  minimal
                  today-btn
                  v-model="filtros.dtini"
                  @input="value => alterarDataInicio(value)"
                />
              </q-popup-proxy>
            </template>
          </q-btn>

          <q-btn
            class="white text-blue-7"
            rounded
            :label="dtfim"
            padding="sm"
          >
            <template v-slot:default>
              <q-popup-proxy ref="qDateDtfim" transition-show="scale" transition-hide="scale">
                <q-date
                  mask="DD/MM/YYYY"
                  minimal
                  today-btn
                  v-model="filtros.dtfim"
                  @input="value => alterarDataFim(value)"
                />
              </q-popup-proxy>
            </template>
          </q-btn>

          <q-btn
            class="white text-blue-7"
            clickable
            padding="sm"
            @click="changeFilterPeriodo('next')"
            rounded
            icon="chevron_right"
          />
        </q-btn-group>

          <!-- Filtro Tipo -->
          <q-btn
            :label="filtrosTipo[filtros.tipo]"
            color="primary"
            flat
            class="q-mr-lg"
          >
            <q-menu>
              <q-list class="text-gray-8">
                <q-item
                  v-for="(label, key) in filtrosTipo"
                  :key="label"
                  clickable
                  v-close-popup
                  @click="changeFilterType(key)"
                >
                  <q-item-section>
                    {{ label }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

        <q-btn icon="close" flat round dense v-close-popup @click="dialogOpened = false" />
      </q-card-section>

      <q-card-section>
        <q-table
          :columns="columns"
          :data="acessos"
          :loading="loadingAcessos"
          :pagination.sync="pagination"
          title
          row-key="id"
          separator="horizontal"
          no-data-label="Nenhum acesso encontrado."
          flat
        >
          <template v-slot:bottom></template>
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="created" class="text-left" >
                {{ props.row.created | formatDateTime }}
              </q-td>
              <q-td key="ip" class="text-left" >
                {{ props.row.ip }}
              </q-td>
              <q-td key="tipo" class="text-left" >
                <q-icon
                :name="props.row.success ? 'check' : 'close'"
                :class="props.row.success ? 'text-positive' : 'text-negative'"
                size="24px"
              />
                {{ props.row.tipo }}
              </q-td>
              <q-td key="route" class="text-left" >
                {{ parseMessage(props.row.route) }} <br>
                {{ props.row.message }}
                <q-tooltip v-if="props.row.route.length > 40">
                  {{ props.row.route }}
                </q-tooltip>
              </q-td>
              <q-td key="info" class="text-center" >
                {{ parseMessage(props.row.info) }} <br>
                <q-tooltip v-if="props.row.info.length > 40">
                  {{ props.row.info }}
                </q-tooltip>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>

      <q-card-section align="right">
        <div class="q-pa-md">
          <div class="q-col-gutter-md row">
            <div class="col-12">
              <q-btn
                @click="getAcessos(true)"
                :loading="loadingAcessos"
                color="primary"
                :disabled="!paginationAcessos.nextPage"
                label="Mostrar mais"
                style="margin-left: 10px; position: relative; top: -3px"
              />
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import moment from "moment";
import axios from "@/axios-auth";

export default {
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => null,
    },
  },
  emits: ["update:openDialog"],
  data() {
    return {
      acessos: [],
      dtini: "",
      dtfim: "",
      filtros: {
        tipo: "todos",
        dtini: "",
        dtfim: "",
      },
      filtrosTipo: {
        todos: "Todos",
        basic: "Basic",
        bearer: "Bearer"
      },
      loadingAcessos: false,
      pagination: {
        rowsPerPage: 0
      },
      paginationAcessos: {
        page: 0,
        nextPage: true,
        count: 0
      },
      columns: [
        {
          name: "created",
          required: true,
          label: "Data",
          align: "left",
          field: row => row.created,
        },
        {
          name: "ip",
          required: true,
          label: "IP",
          align: "left",
          field: row => row.ip,
        },
        {
          name: "tipo",
          required: true,
          label: "Tipo",
          align: "left",
          field: row => row.tipo,
        },
        {
          name: "route",
          required: true,
          label: "Rota",
          align: "left",
          field: row => row.route,
        },
        {
          name: "info",
          required: true,
          label: "Info",
          align: "center",
          field: row => row.info,
        },
      ]
    };
  },
  methods: {
    getAcessos(pagination = false) {
      if (this.loadingAcessos) return;

      if (!pagination) {
        this.paginationAcessos.page = 0;
      }

      this.loadingAcessos = true;
      const params = {
        ...this.filtros,
        page: this.paginationAcessos.page + 1
      };

      axios.get(`/users/acessos/${this.user.id}`, { params })
        .then(res => {
          this.loadingAcessos = false;

          if (!res.data.success) {
            this.$emit("update:openDialog", false);

            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            return;
          }
          
          this.paginationAcessos = res.data.paging;
          if (!pagination) {
            this.acessos = res.data.acessos;
            return;
          }

          this.acessos = this.acessos.concat(res.data.acessos);
      })
      .catch(err => {
        this.loadingAcessos = false;
        this.$emit("update:openDialog", false);

        this.$q.notify({
          message: "Não foi possível visualizar os logs de acesso.",
          color: "red",
          position: "top-right"
        });
      });
    },
    changeFilterPeriodo(periodo) {
      if (this.loadingAcessos) return;

      let dtIni = moment(this.filtros.dtini, "DD-MM-YYYY");
      let dtFim = moment(this.filtros.dtfim, "DD-MM-YYYY");

      if (periodo === "prev") {
        dtIni.subtract(1, "months");
        dtFim.subtract(1, "months");
      }

      if (periodo === "next") {
        dtIni.add(1, "months");
        dtFim.add(1, "months");
      }

      this.filtros.dtini = dtIni.startOf("month").format("DD-MM-YYYY");
      this.filtros.dtfim = dtFim.endOf("month").format("DD-MM-YYYY");
      this.dtini = dtIni.startOf("month").format("DD/MM/YYYY");
      this.dtfim = dtFim.endOf("month").format("DD/MM/YYYY");
      this.paginationAcessos.page = 1;

      this.getAcessos();
    },
    alterarDataInicio(value) {
      if (this.loadingAcessos) return;

      this.filtros.dtini = value.replace(/\//g, "-");
      this.dtini = value.replace(/\//g, "/");
      this.$refs.qDateDtini.hide();

      this.getAcessos();
    },
    alterarDataFim(value) {
      if (this.loadingAcessos) return;

      this.filtros.dtfim = value.replace(/\//g, "-");
      this.dtfim = value.replace(/\//g, "/");
      this.$refs.qDateDtfim.hide();

      this.getAcessos();
    },
    getFiltros() {
      this.filtros.dtini = moment().startOf("month").format("DD-MM-YYYY");
      this.filtros.dtfim = moment().endOf("month").format("DD-MM-YYYY");
      this.dtini = moment().startOf("month").format("DD/MM/YYYY");
      this.dtfim = moment().endOf("month").format("DD/MM/YYYY");
    },
    changeFilterType(key) {
      if (this.loadingAcessos) return;

      this.filtros.tipo = key;
      this.paginationAcessos.page = 1;
      this.getAcessos();
    },
    parseMessage(msg) {
      if (msg.length > 40) {
        return msg.slice(0, 40) + "...";
      }

      return msg;
    }
  },  
  computed: {
    dialogOpened: {
      get() {
        return this.openDialog;
      },
      set(value) {
        this.$emit("update:openDialog", value);
      },
    },
    title() {
      if (!this.user) return "Logs de Acesso";

      return `Logs de Acesso - ${this.user.nome}`;
    }
  },
  watch: {
    dialogOpened(value) {
      if (value) {
        this.getFiltros();
        this.getAcessos();
      }
    },
  },
};
</script>
