<template>
  <div id="modalAdicionarRemoverEstoque">
    <q-dialog v-model="openDialogInfoEstoque" persistent>
      <q-card style="width: 450px; max-width: 80vw;">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">Visualizar Estoque</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />

        <q-card-section>
          <h6 class="q-my-md">{{ estoque.nome }}</h6>

          <p>{{ estoque.tpcontrole }} {{ estoque.evento }} {{ estoque.extracao }} {{ estoque.concurso }} {{ estoque.vencimento | formatDate }}</p>
          <p>Valor de venda: R$ {{ estoque.vlrvenda | currency }}</p>
          <p>Quantidade na empresa: {{ estoque.qtd_empresa }}</p>
          <p>Quantidade com funcionários: {{ estoque.qtd_funcionarios }}</p>

        </q-card-section>

        <q-card-section>
          <p><b>Operadores</b></p>

          <table>
            <tbody>
              <tr v-if="informacoesEstoque.funcionarios.length === 0">
                <td colspan="2">Nenhum Funcionário</td>
              </tr>
              <tr v v-for="(funcionario, i) in informacoesEstoque.funcionarios" :key="i">
                <td>{{ funcionario.produtos[0].quantidade }}</td>
                <td class="q-pl-sm">{{ funcionario.nome }}</td>
              </tr>
            </tbody>
          </table>

        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Fechar"
            clickable
            class="text-white bg-positive"
            style="margin-right: 22px; margin-bottom: 10px"
            @click="closeModal"
          />
        </q-card-actions>

        <q-separator />
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "modal-info-produto-estoque",
  methods: {
    closeModal() {
      this.$store.commit("setInformacoesEstoque", {
        estoque: {},
        funcionarios: [],
      });
      this.$store.commit("openDialogInfoEstoque", false);
    },
  },
  computed: {
    ...mapGetters([
      "openDialogInfoEstoque",
      "informacoesEstoque",
    ]),
    estoque() {
      return this.informacoesEstoque.estoque;
    },
  },
};
</script>

<style lang="stylus" scoped>
  p {
    margin-bottom 8px;
  }
</style>
