<template>
  <q-page id="historicoEmails">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <div class="col-12" v-if="!permissao">
          <sem-permissao></sem-permissao>
        </div>
        <div class="col-12" v-if="permissao">
          <q-btn
            dense
            flat
            size="12px"
            style="margin-bottom: 10px"
            icon="arrow_back"
            label="Voltar para a página anterior"
            @click="returnAnterior"
          ></q-btn>
          <q-table
            title="Histórico de E-mails"
            :data="emails"
            :columns="columns"
            row-key="id"
            :loading="buscando"
            :bottom="false"
            :pagination.sync="pagination"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
            :visible-columns="visibleColumns"
          >
            <template v-slot:bottom>
              <div></div>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                <q-td
                  key="id"
                  v-if="visibleColumns.indexOf('id') !== -1"
                  class="text-right"
                >{{props.row.id}}</q-td>
                <q-td
                  key="status"
                  v-if="visibleColumns.indexOf('status')"
                >{{tipos[props.row.status]}}</q-td>
                <q-td
                  key="subject"
                  v-if="visibleColumns.indexOf('subject') !== -1"
                  @click="detalhar(props.row)"
                >
                  {{props.row.subject}}<br>

                  {{ props.row.error && props.row.error.length > 35
                    ? props.row.error.substr(0, 35) + "..."
                    : props.row.error
                  }}
                  <q-tooltip
                    v-if=" props.row.error && props.row.error.length > 35"
                  >
                    {{ props.row.error }}
                  </q-tooltip>
                </q-td>
                <q-td
                  v-if="visibleColumns.indexOf('emails') !== -1"
                  key="emails"
                  v-html="props.row.emails.replace(/,/g, '<br>')"
                ></q-td>
                <q-td
                  v-if="visibleColumns.indexOf('enviadoem') !== -1"
                  key="enviadoem"
                  class="text-right"
                >{{props.row.enviadoem | formatDate}}</q-td>
                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn
                        @click="gotoCadastro(props.row.cadastro_id)"
                        size="12px"
                        class="bg-green text-white"
                        icon="person"
                      ></q-btn>
                      <q-btn
                        @click="gotoReceita(props.row.cadastro_id)"
                        class="bg-orange text-white"
                        icon="attach_money"
                        size="12px"
                      ></q-btn>
                      <q-btn
                        @click="detalhar(props.row)"
                        class="text-white bg-blue-7"
                        icon="info"
                        size="12px"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      @click="gotoCadastro(props.row.cadastro_id)"
                      size="12px"
                      class="bg-green text-white"
                      icon="person"
                    />
                    <q-btn
                      @click="gotoReceita(props.row.cadastro_id)"
                      class="bg-orange text-white"
                      icon="attach_money"
                      size="12px"
                    />
                    <q-btn
                      @click="detalhar(props.row)"
                      class="text-white bg-blue-7"
                      icon="info"
                      size="12px"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:top="props">
              <div class="col-4 q-table__title">Histórico de E-mails</div>
              <q-space />
              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="tipo === null ? 'Todos' : tipos[tipo]"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Tipos de E-mail</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': i === tipo}"
                      v-for="(tip, i) in tipos"
                      :key="tip"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="trocaTipo(tip, i)"
                    >
                      <q-item-section>{{ tip }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
              <q-select
                v-model="visibleColumns"
                multiple
                borderless
                dense
                options-dense
                :display-value="'Colunas'"
                emit-value
                map-options
                :options="columns"
                option-value="name"
                style="min-width: 100px"
              />
              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                rounded
                no-wrap
                v-ripple
                class="q-ml-sm q-px-md bg-white text-black"
                no-caps
                icon="more_vert"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Mais Opções</q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup aria-hidden="true" @click="atualiza()">
                      <q-item-section>Atualizar</q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="props.toggleFullscreen"
                    >
                      <q-item-section>{{props.inFullscreen ? 'Minimizar' : 'Maximizar Tela'}}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </template>
          </q-table>
        </div>
      </div>
    </div>
    <q-dialog v-model="OpenDialogHistoricoEmails" persistent>
      <q-card style="max-width: 1200px !important; width: 1000px;">
        <q-card-section class="row items-center">
          <div class="text-h6">E-mail {{email.id}} - {{email.created | formatDateTime}}</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="OpenDialogHistoricoEmails = false"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-list class="row" dense>
            <q-item class="col-6">
              <q-item-section style="justify-content: start;">
                <q-item-label caption>De</q-item-label>
                <q-item-label>{{email.from_name}} - {{ email.from_email}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item class="col-6">
              <q-item-section>
                <q-item-label caption>Para</q-item-label>
                <q-item-label>
                  <p v-for="(email, idx) in toEmails" :key="idx" style="margin: 0;">{{ email }}<br></p>
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item class="col-6">
              <q-item-section>
                <q-item-label caption>Assunto</q-item-label>
                <q-item-label>{{email.subject}} - {{tipos[email.status]}} - {{email.enviadoem | formatDateTime}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item class="col-3">
              <q-item-section>
                <q-item-label caption>Aberto em</q-item-label>
                <q-item-label v-if="email.abertoem !== null">{{email.abertoem | formatDateTime}}</q-item-label>
                <q-item-label v-else>Não aberto</q-item-label>
              </q-item-section>
            </q-item>
            <q-item  class="col-3">
              <q-item-section>
                <q-item-label caption>Clicado em</q-item-label>
                <q-item-label v-if="email.clicadoem !== null">{{email.clicadoem | formatDateTime}}</q-item-label>
                <q-item-label v-else>Não clicado</q-item-label>
              </q-item-section>
            </q-item>
            <q-item class="col-6">
              <q-item-section>
                <q-item-label caption>Responder Para</q-item-label>
                <q-item-label>{{email.replyto_name}} - {{ email.replyto_email }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item class="col-6">
              <q-item-section>
                <q-item-label caption>Bounce</q-item-label>
                <q-item-label>{{email.return_path}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item class="col-6">
              <q-item-section>
                <q-item-label caption>Enviado por</q-item-label>
                <q-item-label>{{returnUser(email.user_id)}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item class="col-6" v-if="email.error !== null">
              <q-item-section>
                <q-item-label caption>Erro</q-item-label>
                <q-item-label>
                  {{ email.error && email.error.length > 50
                    ? email.error.substr(0, 50) + "..."
                    : email.error
                  }}
                  <q-tooltip v-if=" email.error && email.error.length > 50">
                    {{ email.error }}
                  </q-tooltip>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <!-- Template enviado -->
        <q-card-section>
          <div style="border: 1px solid #eee;" v-html="email.html_body"></div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
export default {
  name: "historico-emails",
  meta: {
    title: "DSB | Histórico de E-mails"
  },
  data() {
    return {
      visibleColumns: JSON.parse(localStorage.getItem("historico_emails__visibleColumns")) || [
        "id",
        "status",
        "subject",
        "emails",
        "enviadoem",
      ],
      columns: [
        {
          name: "id",
          label: "ID",
          align: "center",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "status",
          required: true,
          align: "center",
          label: "Status",
          field: row => row.status,
          sortable: true
        },
        {
          name: "subject",
          align: "center",
          label: "Assunto",
          field: row => row.subject,
          sortable: true
        },
        {
          name: "emails",
          align: "center",
          label: "E-mails",
          field: row => row.emails,
          sortable: true
        },
        {
          name: "enviadoem",
          align: "center",
          label: "Enviado em",
          field: row => row.enviadoem,
          sortable: true
        }
      ],
      pagination: {
        sortBy: "cadastro",
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      emails: [],
      tipos: [],
      tipo: null,
      paging: {
        count: 0,
        current: 0,
        direction: false,
        directionDefault: false,
        finder: "all",
        limit: null,
        nextPage: true,
        page: 0,
        pageCount: 0,
        perPage: 20,
        prevPage: false,
        scope: null,
        sort: null,
        sortDefault: false
      },
      filtros: {},
      cadastroId: null,
      dtini: "01-05-2019",
      email: {},
      buscando: false,
      permissao: true,
      OpenDialogHistoricoEmails: false
    };
  },
  methods: {
    returnUser(user_id) {
      for (let i = 0; i < this.usersApp.length; i++) {
        if (this.usersApp[i].user.id === user_id) {
          return this.usersApp[i].user.nome;
        }
      }
    },
    gotoCadastro(id) {
      this.$router.push("/cadastros/" + id);
    },
    gotoReceita(id) {
      this.$router.push("/receitas?cadastro_id=" + id);
    },
    detalhar(email) {
      this.$store.dispatch("usersApp");
      this.email = email;
      this.OpenDialogHistoricoEmails = true;
    },
    listarEmails() {
      if (!this.paging.nextPage) return;
      this.buscando = true;
      if (this.cadastroRoute.cadastro_id) {
        this.cadastroId = this.cadastroRoute.cadastro_id;
      }
      if (this.cadastroRoute.dtini) {
        this.dtini = this.cadastroRoute.dtini;
      }
      this.paging.page += 1;
      if (this.tipo === 10) {
        this.tipo = null;
      }
      axios
        .get("/timelines/emails", {
          params: {
            status: this.tipo,
            dtini: this.dtini,
            page: this.paging.page,
            cadastro_id: this.cadastroId
          }
        })
        .then(res => {
          if (res.data.success) {
            this.emails = this.emails.concat(res.data.emails);
            this.tipos = res.data.tipos;
            this.tipos.push("Todos");
            this.paging = res.data.paging;
            this.filtros = res.data.filtros;
          }
          this.buscando = false;
        })
        .catch(() => {
          // M.toast({html: 'Não foi possível contactar, ou você não possui permissão!', classes: 'red'})
          this.buscando = false;
          this.permissao = false;
        });
    },
    resetPagingEmails() {
      let paging = {
        count: 0,
        current: 0,
        direction: false,
        directionDefault: false,
        finder: "all",
        limit: null,
        nextPage: true,
        page: 0,
        pageCount: 0,
        perPage: 20,
        prevPage: false,
        scope: null,
        sort: null,
        sortDefault: false
      };
      this.paging = paging;
      this.emails = [];
    },
    trocaTipo(tipo, i) {
      this.tipo = i;
      this.resetPagingEmails();
      this.listarEmails();
    },
    returnAnterior() {
      this.$router.go(-1);
    },
    handleScroll() {
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.listarEmails();
      }
    },
    atualiza() {
      this.resetPagingEmails();
      this.listarEmails();
    },
  },
  computed: {
    ...mapGetters(["usersApp"]),
    cadastroRoute() {
      return this.$route.query;
    },
    toEmails() {
      if (!this.email.emails) return;
      return this.email.emails.split(",");
    },
/*     emailBody() {
      if (!this.email) return;

      let body = this.email.html_body;
      const el = document.createElement("html");
      el.innerHTML = body;

      const links = el.getElementsByTagName("a");
      for (let i = 0; i < links.length; i++) {
        links[i].removeAttribute("href");
      }

      return el.innerHTML;
    } */
  },
  watch: {
    visibleColumns() {
      localStorage.setItem("historico_emails__visibleColumns", JSON.stringify(this.visibleColumns));
    },
  },
  mounted() {
    this.listarEmails();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  }
};
</script>

