<template>
  <q-page id="mapa-resgate">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!isPermissoMapaResgates"></sem-permissao>
        <div class="col-12" v-if="isPermissoMapaResgates">
          <q-table
            title="Mapa de resgates"
            :data="mapasResgates"
            :columns="columns"
            row-key="id"
            :loading="loadingMapasResgates"
            :bottom="false"
            :pagination.sync="pagination"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
            :visible-columns="visibleColumns"
          >
            <template v-slot:bottom>
                <div></div>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                <q-td align="center" key="created" v-if="visibleColumns.indexOf('created') !== -1">
                  {{ new Date(props.row.created) | formatDateTime }}
                </q-td>
                <q-td align="center" key="total_resgates" v-if="visibleColumns.indexOf('total_resgates') !== -1">
                  {{ props.row.total_resgates }} itens
                </q-td>
                <q-td align="center" key="total_valor" v-if="visibleColumns.indexOf('total_valor') !== -1">
                  R$ {{ props.row.total_valor | currency }}
                </q-td>
                <q-td align="center" key="total_comissao" v-if="visibleColumns.indexOf('total_comissao') !== -1">
                  R$ {{ props.row.total_comissao | currency }}
                </q-td>
                <q-td align="center" key="status" v-if="visibleColumns.indexOf('status') !== -1">
                  {{ listaStatus[props.row.status] }}
                </q-td>
                <q-td align="center" key="user_nome" v-if="visibleColumns.indexOf('user_nome') !== -1">
                  {{ props.row.user_nome }}
                </q-td>
                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn
                        v-if="props.row.status === 'F'"
                        @click="imprimirRelatorio(props.row.id)"
                        size="12px"
                        class="bg-white text-black"
                        icon="print"
                        :loading="loadingRelatorio"
                      >
                        <template v-slot:loading>
                          <q-spinner color="grey-10" size="1em" />
                        </template>
                      </q-btn>
                      <q-btn
                        v-if="props.row.status === 'F'"
                        @click="visualizarMapaResgate(props.row.id)"
                        size="12px"
                        class="bg-blue-7 text-white"
                        icon="info"
                      ></q-btn>
                      <q-btn
                        v-if="props.row.status !== 'F'"
                        @click="editarMapaResgate(props.row.id)"
                        size="12px"
                        class="bg-positive text-white"
                        icon="edit"
                      ></q-btn>
                      <q-btn
                        @click="removeMapaResgate(props.row.id)"
                        size="12px"
                        class="bg-negative text-white"
                        icon="delete"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      v-if="props.row.status === 'F'"
                      @click="imprimirRelatorio(props.row.id)"
                      size="12px"
                      class="bg-white text-black"
                      icon="print"
                      :loading="loadingRelatorio"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                    <q-btn
                      v-if="props.row.status === 'F'"
                      @click="visualizarMapaResgate(props.row.id)"
                      size="12px"
                      class="bg-blue-7 text-white"
                      icon="info"
                    />
                    <q-btn
                      v-if="props.row.status !== 'F'"
                      @click="editarMapaResgate(props.row.id)"
                      size="12px"
                      class="bg-positive text-white"
                      icon="edit"
                    />
                    <q-btn
                      @click="removeMapaResgate(props.row.id)"
                      size="12px"
                      class="bg-negative text-white"
                      icon="delete"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:top="props">
              <q-btn
                flat
                round
                dense
                :icon="'add'"
                class="q-ml-md text-primary"
                style="margin-right: 10px;margin-left: 0"
                @click="abrirNovoMapaResgate()"
              />
              <div class="q-table__title">Mapa de Resgates</div>
              <q-space />
              <q-select
                v-model="visibleColumns"
                multiple
                borderless
                dense
                options-dense
                :display-value="'Colunas'"
                emit-value
                map-options
                :options="columns.filter(column => column.name != 'tdFora')"
                option-value="name"
                style="min-width: 100px"
              />
              <q-btn
                v-if="hasVideo"
                dense
                flat
                color="bg-primary text-blue-7"
                icon="ondemand_video"
                class="q-ml-md"
                @click="modalIntro"
              >
                <template v-slot:default>
                  <q-tooltip>Introdução ao Mapa de Resgates</q-tooltip>
                </template>
              </q-btn>

              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                rounded
                no-wrap
                v-ripple
                class="q-ml-sm q-px-md bg-white text-black"
                no-caps
                icon="more_vert"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Mais Opções</q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup aria-hidden="true" @click="listarMapasResgates">
                      <q-item-section>Atualizar</q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="props.toggleFullscreen"
                    >
                      <q-item-section>{{props.inFullscreen ? 'Minimizar' : 'Maximizar Tela'}}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </template>
          </q-table>
          <div>
            <q-page-sticky position="top-right" :offset="[0, 90]">
              <q-btn
                :color="openOpcoesAvancadasMapaResgates ? 'red-7' : 'blue-7'"
                :icon="openOpcoesAvancadasMapaResgates ? 'close' : 'settings'"
                class="rotate-0"
                style="border-top-left-radius: 50px;border-bottom-left-radius: 50px;"
                @click="openOpcoesAvancadasMapaResgates = !openOpcoesAvancadasMapaResgates"
              >
                <q-tooltip
                  :content-class="
                    openOpcoesAvancadasMapaResgates
                      ? 'bg-negative text-white text-center'
                      : 'bg-secondary text-white text-center'
                  "
                  >{{
                    openOpcoesAvancadasMapaResgates ? "Fechar Opções Avançadas" : "Opções Avançadas"
                  }}</q-tooltip
                >
              </q-btn>
            </q-page-sticky>

            <q-drawer
              side="right"
              v-model="openOpcoesAvancadasMapaResgates"
              bordered
              :width="320"
              :breakpoint="500"
              behavior="default"
              content-class="bg-white"
            >
              <q-scroll-area class="fit">
                <div class="q-pa-sm">
                  <div class="q-table__title">Opções Avançadas</div>
                  <div>
                    <q-list
                      class="rounded-borders"
                      style="max-width: 350px; margin-left: -10px;width: 300px;"
                    >
                      <q-item-label header></q-item-label>

                      <q-item clickable v-ripple @click.exact="abrirOpcoes()">
                        <q-item-section>
                          <q-item-label lines="1">Opções</q-item-label>
                          <q-item-label caption>Configurações adicionais</q-item-label>
                        </q-item-section>
                      </q-item>

                    </q-list>
                  </div>
                </div>
              </q-scroll-area>
            </q-drawer>
          </div>
        </div>
        <div
          class="col-12 col-md-12"
          v-if="!mapasResgates && isPermissoMapaResgates"
        >
          <q-card>
            <q-card-section>
              <div class="col-4 q-table__title">
                <q-btn
                  flat
                  round
                  dense
                  :icon="'add'"
                  class="q-ml-md text-primary"
                  style="margin-right: 10px;margin-left: 0"
                  @click="modal()"
                />Estoque
                <q-btn
                  v-if="$q.screen.gt.xs"
                  flat
                  dense
                  rounded
                  no-wrap
                  v-ripple
                  class="q-ml-sm q-px-md bg-white text-black"
                  no-caps
                  icon="more_vert"
                  style="margin-right: 10px; float: right"
                >
                  <q-menu anchor="top right" self="top right">
                    <q-list class="text-grey-8" style="min-width: 100px">
                      <q-item aria-hidden="true">
                        <q-item-section
                          class="text-uppercase text-grey-7"
                          style="font-size: 0.7rem"
                        >Mais Opções</q-item-section>
                      </q-item>
                      <q-item clickable v-close-popup aria-hidden="true" @click="atualizar">
                        <q-item-section>Atualizar</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>

              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <modal-mapa-resgates-novo></modal-mapa-resgates-novo>
    <modal-mapa-resgates-opcoes></modal-mapa-resgates-opcoes>
  </q-page>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from "vuex";

export default {
  name: "mapa-resgates",
  meta: {
    title: "DSB | Mapa de Resgates"
  },
  data() {
    return {
      visibleColumns: JSON.parse(localStorage.getItem("mapa_resgate__visibleColumns")) || [
        "created",
        "total_resgates",
        "total_valor",
        "total_comissao",
        "status",
        "user_nome",
        "tdFora",
      ],
      columns: [
        {
          name: "created",
          label: "Gerado em",
          align: "center",
          field: row => row.created,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "total_resgates",
          label: "Quantidade",
          align: "center",
          field: row => row.total_resgates,
          format: val => `${val}`,
          sortable: true,
        },
        {
          name: "total_valor",
          label: "Total",
          align: "center",
          field: row => row.total_resgates,
          format: val => `${val}`,
          sortable: true,
        },
        {
          name: "total_comissao",
          label: "Comissão",
          align: "center",
          field: row => row.total_resgates,
          format: val => `${val}`,
          sortable: true,
        },
        {
          name: "status",
          label: "Status",
          align: "center",
          sortable: false,
        },
        {
          name: "user_nome",
          label: "Criado por",
          align: "center",
          field: row => row.user_nome,
          format: val => `${val}`,
          sortable: true,
        },
        { name: "tdFora" },
      ],
      pagination: {
        sortBy: "created",
        descending: true,
        rowsPerPage: 0,
        page: 1,
      },
      listaStatus: {
        'A': 'Aberto',
        'F': 'Fechado',
      },
      openOpcoesAvancadasMapaResgates: false,
      loadingRelatorio: false,
      hasVideo: false
    };
  },
  methods: {
    abrirNovoMapaResgate() {
      this.$store.dispatch('openDialogNovoMapaResgates', true);
    },
    abrirOpcoes() {
      this.$store.dispatch('openDialogOpcoesMapaResgates', true);
    },
    listarMapasResgates() {
      this.$store.dispatch("loadMapasResgates", { forcarCarregamento: true });
    },
    visualizarMapaResgate(id) {
      this.$store.dispatch('openDialogNovoMapaResgates', { id, visualizar: true });
    },
    editarMapaResgate(id) {
      this.$store.dispatch('openDialogNovoMapaResgates', { id });
    },
    imprimirRelatorio(id) {
      this.loadingRelatorio = true;

      axios
        .get("maparesgates/relatorio/" + id, {
          headers: {
            Accept: "text/html",
          },
        })
        .then(res => {
          const windowJanela = window.open("about:blank");
          windowJanela.document.write(res.data);
          windowJanela.document.close();

          this.loadingRelatorio = false;
        })
        .catch(err => {
          this.$q.notify({
            message:"Não foi possível imprimir o relatório!",
            color: "red",
            position: "top-right"
          });
          this.loadingRelatorio = false;
        });
    },
    removeMapaResgate(id) {
      this.$q.dialog({
        title: 'Cuidado',
        message: 'Deseja realmente excluir este mapa de resgate?',
        cancel: true,
        persistent: true,
      })
      .onOk(() => {

        axios.post('maparesgates/delete', { id })
          .then(resp => {
            this.$q.notify({
              message: 'Mapa de resgates excluído com sucesso',
              color: "green",
              position: "top-right",
            });
            this.listarMapasResgates();
          })
        .catch(err => {
          this.$q.notify({
            message: 'Houve um erro ao excluir o mapa de resgates',
            color: "red",
            position: "top-right"
          });
        });
      }).onCancel(() => {});
    },
    handleScroll() {
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.$store.dispatch("loadMapasResgates");
      }
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "mapa_resgates");

      if (!this.videosManuais.video_mapa_resgates) {
        this.modalIntro();
      }
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "mapa_resgates");
    },
  },
  computed: {
    ...mapGetters([
      "isPermissoMapaResgates",
      "loadingMapasResgates",
      "mapasResgates",
      "videosManuais",
      "manuaisDisponiveis"
    ]),
  },
  watch: {
    visibleColumns() {
      localStorage.setItem("mapa_resgate__visibleColumns", JSON.stringify(this.visibleColumns));
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    this.listarMapasResgates();
    this.verificarPrimeiroVideo();
    this.$store.dispatch("loadOpcoes");
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  },
};
</script>

<style lang="stylus" scoped></style>
