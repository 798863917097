<template>
  <q-dialog v-model="openDialogOpcoesFolhaPagamentos" persistent>
    <q-card style="width: 400px; max-width: 100%;" class="q-pb-md">

        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">Opções Avançadas</div>

          <q-space />

          <q-btn
            flat
            round
            dense
            v-close-popup
            icon="close"
            @click="closeModal()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-py-xl text-center" v-show="loading">
          <q-spinner color="primary" size="3em" />
        </q-card-section>

        <q-card-section v-show="!loading">
          <div class="q-col-gutter-md row">
            <div class="col-12">
              <q-checkbox
                color="green"
                true-value="1"
                false-value="0"
                label="Exibir diferença de caixa"
                v-model="opcoes.folha_pagamentos_exibir_diferenca_caixa"
              />
            </div>

            <div class="col-12">
              <q-checkbox
                color="green"
                true-value="1"
                false-value="0"
                label="Exibir comissões"
                v-model="opcoes.folha_pagamentos_exibir_comissoes"
              />
            </div>

            <div class="col-12">
              <q-input
                label="Descrição das comissões"
                v-model="opcoes.folha_pagamentos_descricao_comissoes"
              />
            </div>

            <div class="col-12">
              <q-input
                label="Descrição das diferenças de caixa"
                v-model="opcoes.folha_pagamentos_descricao_movcaixas"
              />
            </div>

          </div>
        </q-card-section>

        <q-card-actions class="text-primary column items-start" v-show="!loading">
          <div class="self-end">
            <q-btn
              flat
              clickable
              label="Salvar"
              class="bg-positive text-white"
              style="margin-right: 22px; margin-bottom: 10px"
              :loading="loadingSalvar"
              @click="salvar()"
            >
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
          </div>
        </q-card-actions>

    </q-card>
  </q-dialog>
</template>

<script>
import moment from 'moment';
import axios from '@/axios-auth';
import { bus } from '@/main';

export default {
  name: 'modal-opcoes-folha-pagamentos',
  data() {
    return {
      openDialogOpcoesFolhaPagamentos: false,
      loading: true,
      loadingSalvar: false,
      opcoes: {
        folha_pagamentos_exibir_pagamentos: '0',
        folha_pagamentos_exibir_diferenca_caixa: '0',
        folha_pagamentos_exibir_comissoes: '0',
        folha_pagamentos_exibir_recebimentos: '0',
        folha_pagamentos_descricao_comissoes: '',
        folha_pagamentos_descricao_movcaixas: '',
      },
    };
  },
  methods: {
    closeModal() {
      this.openDialogOpcoesFolhaPagamentos = false;
    },
    openModal() {
      this.openDialogOpcoesFolhaPagamentos = true;
      this.loadOpcoes();
    },
    loadOpcoes() {
      this.loading = true;

      axios
        .get('/financeiro/opcoes-pagamentos')
        .then(res => {
          this.loading = false;

          if (!res.data.success) {
            throw res.data;
          }

          this.opcoes = res.data.opcoes;
        })
        .catch(err => {
          this.loading = false;
          this.$q.notify({
            message:'Não foi possível listar as opções!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    salvar() {
      this.loadingSalvar = true;

      const data = JSON.parse(JSON.stringify(this.opcoes));

      axios
        .post('/financeiro/opcoes-pagamentos', data)
        .then(res => {
          this.loadingSalvar = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }
          this.opcoes = res.data.opcoes;

          this.$q.notify({
            message: res.data.message,
            color: 'green',
            position: 'top-right',
          });
          this.closeModal();
        })
        .catch(() => {
          this.loadingSalvar = false;

          this.$q.notify({
            message:'Houve um erro ao salvar as opções!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    bus.$on('abrirOpcoesFolhaPagamentos', () => this.openModal());
  },
  beforeMount() {
    bus.$off('abrirOpcoesFolhaPagamentos');
  },
};
</script>

<style>

</style>
