<template>
  <div id="gruposComp">
    <p class="text-left" v-if="getTags && getTags.length === 0">Sem Tags</p>
    <q-chip
      v-for="(key, val) in getTags"
      :key="val"
      removable
      @remove="excluirTag(key, val)"
      color="secondary"
      text-color="white"
    >{{key.title}}</q-chip>
    <q-btn
      dense
      no-wrap
      v-ripple
      flat
      no-caps
      icon="add"
      round
      class="q-ml-sm bg-white text-primary absolute-bottom-right"
      style="margin: 0 35px 20px 0"
      clickable
      @click="addTag"
    ></q-btn>
    <tags-cadastro ref="tagsCadastro"></tags-cadastro>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
import { Dialog, Loading } from "quasar";
export default {
  name: "grupos-component",
  data() {
    return {
      excluiTag: false
    };
  },
  methods: {
    addTag() {
      this.$store.dispatch("addTag");
    },
    excluirTag(value, val) {
      Dialog.create({
        title: value.title,
        message: "Deseja remover a tag deste cadastro?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          this.excluiTag = true;
          let dados = {
            tag_id: value.id,
            cadastro_id: parseInt(this.$route.params.id)
          };
          axios
            .post("/cadastros/delete-tag", dados)
            .then(res => {
              if (res.data.success) {
                Dialog.create({
                  title: value.title + " removido do cadastro!",
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
                this.$store.dispatch("getTags", this.$route.params.id);
              }
              this.excluiTag = false;
            })
            .catch((error) => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
              this.excluiTag = false;
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    }
  },
  computed: {
    ...mapGetters(["getTags"])
  },
};
</script>

<style scoped>
</style>
