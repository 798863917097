<template>
  <div id="modalMapaResgatesOpcoes">
    <q-dialog v-model="openDialogOpcoesMapaResgates" persistent style="max-width: 100%;">
      <q-card class="q-px-lg">

        <q-card-section class="row items-center">
          <div class="text-h6">Mapa de Resgates</div>
          <q-space />
          <q-btn
            class="q-ml-lg"
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="cancela()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <q-select
            dense
            use-input
            emit-value
            map-options
            label="Produtos"
            option-label="nome"
            :options="produtos"
            :option-value="produto => String(produto.id)"
            @filter="buscarProduto"
            v-model="opcoes.maparesgates_produto_id"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Produto não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>

          <q-input
            v-model="opcoes.maparesgates_valor_comissao"
            prefix="R$"
            label="Comissão"
            mask="#.##"
            fill-mask="0"
            reverse-fill-mask
          />

          <q-checkbox v-model="opcoes.maparesgates_sincronizar_estoque" label="Sincronizar com estoque" />
        </q-card-section>

        <q-card-actions class="q-mb-sm" align="right">
          <q-btn
            @click="salvaOpcoes()"
            :loading="loadingSalvaOpcoes"
            label="Salvar"
            color="positive"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from "vuex";

export default {
  name: "modal-mapa-resgates-options",
  data() {
    return {
      loadingSalvaOpcoes: false,
      produtos: [],
    };
  },
  methods: {
    cancela() {
      this.$store.commit("openDialogOpcoesMapaResgates", false);
    },
    salvaOpcoes() {
      this.loadingSalvaOpcoes = true;

      const data = {
        produto_id: this.opcoes.maparesgates_produto_id,
        valor_comissao: this.opcoes.maparesgates_valor_comissao,
        sincronizar_estoque: this.opcoes.maparesgates_sincronizar_estoque,
      };

      axios.post('maparesgates/opcoes', data)
        .then(resp => {
          this.$q.notify({
            message: "Sucesso ao salvar as opções",
            color: "green",
            position: "top-right",
          });

          this.loadingSalvaOpcoes = false;
          this.$store.dispatch("loadOpcoes");
          this.cancela();
        })
        .catch(err => {
          this.$q.notify({
            message: "Houve um erro ao salvar as opções",
            color: "red",
            position: "top-right",
          });
          this.loadingSalvaOpcoes = false;
        });
    },
    buscarProduto(val, update, abort) {
      update(() => {
        this.produtos = this.produtosMapaResgate.filter(
          produto => produto.nome.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
  },
  computed: {
    ...mapGetters([
      "openDialogOpcoesMapaResgates",
      "opcoes",
      "produtosMapaResgate",
    ]),
  },
};
</script>
