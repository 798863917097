const state = {
  openClose: true,
  ready: false,
  registered: false,
  cached: false,
  updatefound: false,
  updated: false,
  offline: false,
  error: ""
};

const mutations = {
  openClose: (state, message) => {
    state.openClose = message;
  },
  ready: (state, message) => {
    state.ready = message;
  },
  registered: (state, message) => {
    state.registered = message;
  },
  cached: (state, message) => {
    state.cached = message;
  },
  updatefound: (state, message) => {
    state.updatefound = message;
  },
  updated: (state, message) => {
    state.updated = message;
  },
  offline: (state, message) => {
    state.offline = message;
  },
  error: (state, message) => {
    state.error = message;
  }
};

const actions = {
  someActionPWA: ({ commit }, message) => {
    commit("ready", message);
  },
  updated: ({ commit }, message) => {
    commit("updated", message);
  }
};

const getters = {
  openClose: state => {
    return state.openClose;
  },
  ready: state => {
    return state.ready;
  },
  registered: state => {
    return state.registered;
  },
  cached: state => {
    return state.cached;
  },
  updatefound: state => {
    return state.updatefound;
  },
  updated: state => {
    return state.updated;
  },
  offline: state => {
    return state.offline;
  },
  error: state => {
    return state.error;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
