<template>
  <div id="modalDetalharCofreInteligente">
    <q-dialog v-model="openDialogDetalharCofreDeposito" persistent>
      <q-card style="width: 800px; max-width: 80vw;">
        <q-card-section class="row items-center">
          <div class="text-h6" v-if="!loadingDeposito">
            Detalhamento -
            <strong>{{ cofreDeposito ? cofreDeposito.id : '' }}</strong>
          </div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="toggleDialogDetalharCofreDeposito()"
          />
        </q-card-section>
        <q-separator />

        <q-card-section v-if="loadingDeposito">
          <q-spinner
            color="blue-7"
            size="4em"
            style="position:relative;top: 50%;left: 40%;"
          />
        </q-card-section>

        <q-card-section v-else>
          <q-list>
            <q-item>
              <q-item-section>
                <q-item-label>{{new Date(cofreDeposito.data) | formatDate}}</q-item-label>
                <q-item-label caption>Data</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>R$ {{cofreDeposito.total_automatico | currency}}</q-item-label>
                <q-item-label caption>Total Automático</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>R$ {{cofreDeposito.total_manual | currency}}</q-item-label>
                <q-item-label caption>Total Manual</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>R$ {{
                  (cofreDeposito.total_automatico + cofreDeposito.total_manual) | currency}}
                </q-item-label>
                <q-item-label caption>Total</q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-if="cofreDeposito.cnpj">
              <q-item-section>
                <q-item-label>{{cofreDeposito.cnpj | cnpj}}</q-item-label>
                <q-item-label caption>CNPJ</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>{{cofreDeposito.importado ? 'Sim' : 'Não'}}</q-item-label>
                <q-item-label caption>Importado</q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-if="cofreDeposito.cadastro">
              <q-item-section>
                <q-item-label>{{cofreDeposito.cadastro.nome}}</q-item-label>
                <q-item-label caption>Cadastro</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
const moment = require("moment");
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "modal-detalhar-cofre-inteligente",
  data() {
    return {
      loadingDeposito: false,
    };
  },
  methods: {
    ...mapActions(["toggleDialogDetalharCofreDeposito"]),
  },
  computed: {
    ...mapGetters([
      "openDialogDetalharCofreDeposito",
      "cofreDeposito",
    ]),
  },
};
</script>
