import axios from "@/axios-auth";
import router from "@/router/router";
import { bus } from "@/main";
import { Notify, Loading, Dialog } from "quasar";
import hash from "object-hash";
let moment = require("moment");

const state = {
  comissoes: [],
  filtrosComissoes: {
    dtini: "",
    dtfim: "",
    busca: null,
    status: null,
    cadastro_id: null
  },
  pagingComissoes: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  totalGrid: 0,
  totalComissoesAbertasItens: 0,
  totalComissoesAbertasValor: 0,
  totalComissoesGeradasItens: 0,
  totalComissoesGeradasValor: 0,
  totalComissoesItens: 0,
  totalComissoesValor: 0,
  hashFilter: null,
  newHashFilter: false,
  forceUpdate: false,
  buscandoComissao: false,
  permiteVisualizarComissoes: true,
};

const mutations = {
  comissoes: (state, userData) => {
    state.comissoes = userData;
  },
  totalGrid: (state, userData) => {
    state.totalGrid = userData;
  },
  totalComissoesItens: (state, userData) => {
    state.totalComissoesItens = userData;
  },
  totalComissoesValor: (state, userData) => {
    state.totalComissoesValor = userData;
  },
  totalComissoesAbertasItens: (state, userData) => {
    state.totalComissoesAbertasItens = userData;
  },
  totalComissoesAbertasValor: (state, userData) => {
    state.totalComissoesAbertasValor = userData;
  },
  totalComissoesGeradasItens: (state, userData) => {
    state.totalComissoesGeradasItens = userData;
  },
  totalComissoesGeradasValor: (state, userData) => {
    state.totalComissoesGeradasValor = userData;
  },
  filtrosComissoes: (state, userData) => {
    state.filtrosComissoes = userData;
  },
  pagingComissoes: (state, userData) => {
    state.pagingComissoes = userData;
  },
  hashFilter: (state, userData) => {
    state.hashFilter = userData;
  },
  forceUpdate: (state, userData) => {
    state.forceUpdate = userData;
  },
  newHashFilter: (state, userData) => {
    state.newHashFilter = userData;
  },
  buscandoComissao: (state, userData) => {
    state.buscandoComissao = userData;
  },
  permiteVisualizarComissoes: (state, userData) => {
    state.permiteVisualizarComissoes = userData;
  }
};

const actions = {
  resetFilterComissoes: ({ commit }) => {
    commit("pagingComissoes", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });
  },

  hashFilter: ({ commit, state, dispatch }) => {
    commit("newHashFilter", false);
    if(state.forceUpdate === true){
      commit("comissoes", []);
      return;
    }

    let hfilter = hash(state.filtrosComissoes);

    if (hfilter !== state.hashFilter) {
      if(state.hashFilter !== null){
        commit("comissoes", []);
      }
      commit("hashFilter", hfilter);
      commit("newHashFilter", true);
      dispatch("resetFilterComissoes");
    }
  },

  listarComissoes: ({ commit, state }) => {
    if (state.buscandoComissao) return;

    if (!state.pagingComissoes.nextPage && !state.forceUpdate) {
      return;
    }
    commit("permiteVisualizarComissoes", true);
    const query = router.history.current.query;

    if (Object.keys(query).length) {
      if (query.cadastro_id) {
        commit("filtrosComissoes", {
          ...state.filtrosComissoes,
          cadastro_id: query.cadastro_id
        });
      }

      if (query.dtini) {
        commit("filtrosComissoes", {
          ...state.filtrosComissoes,
          dtini: query.dtini
        });
      }

      if (query.dtfim) {
        commit("filtrosComissoes", {
          ...state.filtrosComissoes,
          dtfim: query.dtfim
        });
      }

      if (query.status) {
        commit("filtrosComissoes", {
          ...state.filtrosComissoes,
          status: query.status
        });
      }

      let newQueryParams = [];

      for (let queryParam in query) {
        if (state.filtrosComissoes[queryParam] !== undefined) {
          newQueryParams.push(
            queryParam + "=" + state.filtrosComissoes[queryParam]
          );
        }
      }

      let newUrl = "?" + newQueryParams.join("&");
      window.history.replaceState(state.filtrosComissoes, "", newUrl);

    }

    let page = state.pagingComissoes.page;
    if(!state.forceUpdate){
      page += 1;
    }

    commit("buscandoComissao", true);

    axios
      .get("/comissoes", {
        params: {
          page: page,
          status: state.filtrosComissoes.status,
          dtini: state.filtrosComissoes.dtini,
          dtfim: state.filtrosComissoes.dtfim,
          busca: state.filtrosComissoes.busca,
          cadastro_id: state.filtrosComissoes.cadastro_id,
        }
      })
      .then(res => {
        if (res.data.success) {
          commit("comissoes", state.comissoes.concat(res.data.comissoes));

          commit("totalGrid", res.data.totalGrid);
          commit("totalComissoesAbertasValor", res.data.totalComissoesAbertasValor);
          commit("totalComissoesAbertasItens", res.data.totalComissoesAbertasItens);
          commit("totalComissoesGeradasItens", res.data.totalComissoesGeradasItens);
          commit("totalComissoesGeradasValor", res.data.totalComissoesGeradasValor);
          if(page == 1){
            commit("totalComissoesItens", res.data.totalComissoesItens);
            commit("totalComissoesValor", res.data.totalComissoesValor);
          }

          commit("filtrosComissoes", res.data.filters);
          commit("pagingComissoes", res.data.paging);
        }
        commit("buscandoComissao", false);
      })
      .catch(() => {
        commit("buscandoComissao", false);

        commit("permiteVisualizarComissoes",  false);
      });
  },

  filtrosComissoes: ({ commit, dispatch }, userData) => {
    commit("forceUpdate", false);
    if (userData) {
      commit("filtrosComissoes", {
        dtini: userData.dtini,
        dtfim: userData.dtfim,
        busca: state.filtrosComissoes.busca ?? null,
        status: userData.status,
        cadastro_id: state.filtrosComissoes.cadastro_id ?? null,
      });
      commit("forceUpdate", userData.forceUpdate);
    } else {
      commit("filtrosComissoes", {
        dtini: state.filtrosComissoes.dtini,
        dtfim: state.filtrosComissoes.dtfim,
        busca: state.filtrosComissoes.busca,
        cadastro_id: state.filtrosComissoes.cadastro_id,
        status: state.filtrosComissoes.status,
      });
    }
    dispatch("hashFilter");

    dispatch("listarComissoes");

  },
};

const getters = {
  comissoes: state => {
    return state.comissoes;
  },
  filtrosComissoes: state => {
    return state.filtrosComissoes;
  },
  permiteVisualizarComissoes: state => {
    return state.permiteVisualizarComissoes;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
