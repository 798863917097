<template>
  <q-dialog v-model="OpenDialogWebhookLogs" persistent>

    <q-card :style="`width: 100%; max-width: ${showResponseData ? '1280' : '720'}px;`">
      <q-card-section class="row items-center ">
        <div class="text-h6">Webhooks Enviados</div>
        <q-space />

        <q-btn-group class="q-ml-md" rounded flat no-wrap no-caps>
          <q-btn
            class="white text-blue-7"
            clickable
            padding="sm"
            @click="changeFilterPeriodo('prev')"
            rounded
            icon="chevron_left"
          />

          <q-btn
            class="white text-blue-7"
            rounded
            :label="dtini"
            padding="sm"
          >
            <template v-slot:default>
              <q-popup-proxy ref="qDateDtini" transition-show="scale" transition-hide="scale">
                <q-date
                  mask="DD/MM/YYYY"
                  minimal
                  today-btn
                  v-model="webhookLogsFilters.dtini"
                  @input="value => alterarDataInicio(value)"
                />
              </q-popup-proxy>
            </template>
          </q-btn>

          <q-btn
            class="white text-blue-7"
            rounded
            :label="dtfim"
            padding="sm"
          >
            <template v-slot:default>
              <q-popup-proxy ref="qDateDtfim" transition-show="scale" transition-hide="scale">
                <q-date
                  mask="DD/MM/YYYY"
                  minimal
                  today-btn
                  v-model="webhookLogsFilters.dtfim"
                  @input="value => alterarDataFim(value)"
                />
              </q-popup-proxy>
            </template>
          </q-btn>

          <q-btn
            class="white text-blue-7"
            clickable
            padding="sm"
            @click="changeFilterPeriodo('next')"
            rounded
            icon="chevron_right"
          />
        </q-btn-group>

        <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
      </q-card-section>

      <q-separator />

      <q-card-section>
        <div>
          <div class="q-col-gutter-md row">
            <div :class="showResponseData ? 'col-6' : 'col-12'">
              <div>
                <p class="text-subtitle1 text-weight-bold q-mb-none">{{ acoesWebhook[webhook.acao] }}</p>
                <p class="text-subtitle2 q-mb-none">{{ webhook.descricao }}</p>
                <p class="text-subtitle3 q-mb-none">{{ webhook.url }}</p>
              </div>
              <q-table
                flat
                :data="webhookLogs"
                :columns="columns"
                row-key="id"
                :separator="'horizontal'"
                :loading="loadingWebhookLogs"
                no-data-label="Nenhum resultado encontrado."
                :pagination.sync="pagination"
              >
                <template v-slot:bottom>
                  <div></div>
                </template>
                <template v-slot:body="props">
                  <q-tr :props="props" @click="inspectWebhook(props.row)" :style="getRowStyle(props.row)">
                    <q-td key="created" class="text-left">{{props.row.created | formatDateTime}}</q-td>
                    <q-td key="status" class="text-left">{{props.row.response_status}}<br>Tentativas {{props.row.tentativas}}</q-td>
                    <q-td key="body" class="text-center">
                      <span v-if="props.row.body">
                        {{
                          props.row.body.length > 25
                            ? props.row.body.substr(0, 25) + "..."
                            : props.row.body

                        }}
                      </span>
                    </q-td>
                    <q-td key="response" class="text-center">
                      <span v-if="props.row.response">
                        {{
                          props.row.response.length > 25
                            ? props.row.response.substr(0, 25) + "..."
                            : props.row.response

                        }}
                      </span>
                    </q-td>
                    <q-td class="tdfora">
                      <div class="divfora">
                        <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                          <q-btn
                            v-if="props.row.status === 3"
                            @click.stop="resendWebhook(props.row)"
                            size="12px"
                            class="bg-primary text-white"
                            icon="refresh"
                            :loading="props.row.id === resendingWebhookId"
                          >
                          <q-tooltip>
                            Reenviar webhook
                          </q-tooltip>
                          </q-btn>
                        </div>
                      </div>
                    </q-td>
                  </q-tr>
                </template>
              </q-table>
            </div>

            <div v-if="showResponseData && webhookInspected" class="col-6">
              <q-btn icon="close" flat round dense v-close-popup @click="closeWebhookInspect()" />
              <q-card-section>
              <div class="text-h5">Headers</div>
              <pre style="white-space: pre-wrap;">{{webhookInspected.headers}}</pre>
              </q-card-section>
              <q-card-section>
                <div class="text-h5">Dados</div>
                <pre style="white-space: pre-wrap;">{{webhookInspected.body}}</pre>
              </q-card-section>
              <q-card-section>
                <div class="text-h5">Resposta</div>
                <pre style="white-space: pre-wrap;">{{webhookInspected.response}}</pre>
              </q-card-section>

            </div>
          </div>
        </div>
      </q-card-section>

      <q-card-section align="right">
        <div class="q-pa-md">
          <div class="q-col-gutter-md row">
            <div class="col-12">
              <q-btn
                @click="info(webhook)"
                :loading="loadingWebhookLogs"
                color="primary"
                :disabled="!paginationWebhookLogs.nextPage"
                label="Mostrar mais"
                style="margin-left: 10px; position: relative; top: -3px"
              />
            </div>
          </div>
        </div>
      </q-card-section>

    </q-card>
  </q-dialog>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import axios from '@/axios-auth';

export default {
  data: () => ({
    pagination: {
      rowsPerPage: 0
    },
    status: [
      'Aguardando Envio',
      'Enviando',
      'Enviado',
      'Erro',
    ],
    resendingWebhookId: null,
    showResponseData: false,
    webhookInspected: null,
    dtini: '',
    dtfim: '',
  }),
  methods: {
    ...mapActions({
      info: 'listWebhookInfo',
      resetPaging: 'resetPaginationWebhookLogs',
    }),
    resendWebhook(webhook) {
      if (!webhook || this.resendingWebhookId === webhook.id) return;

      this.$q
        .dialog({
          title: "Confirmar reenvio",
          message: "Deseja realmente reenviar este webhook?",
          ok: 'Reenviar',
          cancel: 'Cancelar',
          persistent: true,
        })
        .onOk(() => {
          this.resendingWebhookId = webhook.id;

          axios
            .post("/webhooks/edit/" + webhook.webhook_id, {}, { params: {
              filawebhook_id: webhook.id,
              reenviar: "1",
            }})
            .then(res => {
              this.resendingWebhookId = null;

              if (!res.data.success) {
                this.$q.notify({
                  message: res.data.message,
                  color: "orange",
                  position: "top-right",
                });
                return;
              }

              // Atualiza o webhook na lista
              const updatedWebhook = res.data.webhook;
              const index = this.webhookLogs.findIndex(
                webhook => webhook.id === updatedWebhook.id
              );
              this.webhookLogs.splice(index, 1, updatedWebhook);

              this.$q.notify({
                message: res.data.message,
                color: "green",
                position: "top-right",
              });
            });
        });
    },
    inspectWebhook(webhook) {
      if (!webhook) return;

      this.showResponseData = true;
      this.webhookInspected = webhook;
    },
    closeWebhookInspect() {
      this.showResponseData = false;
      this.webhookInspected = null;
    },
    closeModal() {
      this.OpenDialogWebhookLogs = false;
      this.showResponseData = false;
      this.webhookInspected = null;
    },
    formatDateTime(value) {
      if (!value) return '';
      return moment(value).format('DD/MM/YYYY HH:mm') + 'h';
    },
    getRowStyle(row) {
      if (!this.showResponseData || !this.webhookInspected) return "";

      if (row.id === this.webhookInspected.id) {
        return "background-color: #e6f7ff !important";
      }
    },
    alterarDataInicio(value) {
      this.webhookLogsFilters.dtini = value.replace(/\//g, '-');
      this.dtini = value.replace(/\//g, '/');
      this.$refs.qDateDtini.hide();

      this.resetPaging();
      this.info(this.webhook, this.webhookLogsFilters);
    },
    alterarDataFim(value) {
      this.webhookLogsFilters.dtfim = value.replace(/\//g, '-');
      this.dtfim = value.replace(/\//g, '/');
      this.$refs.qDateDtfim.hide();

      this.resetPaging();
      this.info(this.webhook, this.webhookLogsFilters);
    },
    changeFilterPeriodo(periodo) {
      if (this.searching) return;

      let dtIni = moment(this.webhookLogsFilters.dtini, 'DD-MM-YYYY');
      let dtFim = moment(this.webhookLogsFilters.dtfim, 'DD-MM-YYYY');

      if (periodo === 'prev') {
        dtIni.subtract(1, 'months');
        dtFim.subtract(1, 'months');
      }

      if (periodo === 'next') {
        dtIni.add(1, 'months');
        dtFim.add(1, 'months');
      }

      this.webhookLogsFilters.dtini = dtIni.startOf('month').format('DD-MM-YYYY');
      this.webhookLogsFilters.dtfim = dtFim.endOf('month').format('DD-MM-YYYY');
      this.dtini = dtIni.startOf('month').format('DD/MM/YYYY');
      this.dtfim = dtFim.endOf('month').format('DD/MM/YYYY');
      this.webhookLogsFilters.page = 1;

      this.resetPaging();
      this.info(this.webhook, this.webhookLogsFilters);
    },
    getDateFilters() {
      this.webhookLogsFilters.dtini = moment().startOf('month').format('DD-MM-YYYY');
      this.webhookLogsFilters.dtfim = moment().endOf('month').format('DD-MM-YYYY');
      this.dtini = moment().startOf('month').format('DD/MM/YYYY');
      this.dtfim = moment().endOf('month').format('DD/MM/YYYY');
    },
  },
  computed: {
    ...mapGetters([
      'webhookLogs',
      'OpenDialogWebhookLogs',
      'loadingWebhookLogs',
      'paginationWebhookLogs',
      'webhook',
      'acoesWebhook',
      'webhooksOptions',
      'webhookLogsFilters'
    ]),
    OpenDialogWebhookLogs: {
      get () {
        return this.$store.getters.OpenDialogWebhookLogs;
      },
      set (value) {
        this.$store.commit('OpenDialogWebhookLogs', value);
      },
    },
    columns() {
      return [
        {
          name: "created",
          required: true,
          label: "Criado",
          align: "left",
          field: row => this.formatDateTime(row.created),
          sortable: true,
        },
        {
          name: "status",
          required: true,
          label: "Status",
          align: "left",
          field: row => row.status,
          sortable: true,
        },
        {
          name: "body",
          required: true,
          label: "Dados",
          align: "left",
          field: row => row.body,
          sortable: true,
        },
        {
          name: "response",
          required: true,
          label: "Resposta",
          align: "left",
          field: row => row.response,
          sortable: true,
        }
      ];
    },
  },
  mounted() {
    this.getDateFilters();
  }
};
</script>
