<template>
  <q-layout view="hHh lpR fFf">
    <div id="configuracao">
      <div class="q-pa-md">
        <div class="q-col-gutter-md row">
          <div class="col-12 col-md-4 offset-4">
            <q-card style="box-shadow: none">
              <q-card-section class="text-center">
                <img src="@/assets/douranovo.png" alt="DouraSoft Logo" width="150" />
              </q-card-section>

              <q-card-section class="text-center">
                <q-spinner color="blue-7" size="4em" style="position:relative;" />
                <br />
                <br />
                <p>Aguarde enquanto configuramos seu ambiente...</p>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
    </div>
  </q-layout>
</template>

<script>
import axios from "@/axios-auth";
import { Notify } from "quasar";
export default {
  name: "config",
  meta: {
    title: "DSB | Configuração"
  },
  // watch: {
  //   route(valor) {
  //     if (valor === "/configuracao") {
  //       this.config();
  //     }
  //   }
  // },
  methods: {
    config() {
      axios
        .post(
          "/users/config",
          {},
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " +  localStorage["token" + localStorage["empresa_atual"]]
            }
          }
        )
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            this.$router.push("/tour");
            this.$router.go();
          }
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    }
  },

  computed: {
    route() {
      return this.$route.path;
    },
    token() {
      return this.$store.getters.token;
    }
  },
  mounted() {
    this.config();
  }
};
</script>

<style lang="stylus" scoped></style>
