<template>
  <div id="tourContas">
    <q-card-section class="text-center" v-if="loadContas">
      <q-spinner color="blue-7" size="4em" style="position: relative" />
    </q-card-section>
    <q-card-section v-if="!loadContas">
      <p class="text-justify">
        Para garantir o mínimo de informações necessárias, preencha abaixo conforme solicitado. Caso
        haja mais de uma conta para os itens abaixo, basta clicar em "Adicionar nova conta..."
      </p>

      <div class="row">
        <div
          class="col-12 col-md-12"
          v-for="(conta, index) in contas"
          :key="conta.id"
          @keyup.enter="pulaProx(index)"
        >
          <q-input
            square
            :id="'conta' + index"
            :label="nomeContas[index]"
            @blur="envia(conta, index)"
            @keyup.enter="envia(conta, index)"
            v-model="conta.titulo"
          >
            <template v-slot:append v-if="conta.valid">
              <q-icon name="check" color="green" />
            </template>
          </q-input>
          <br />
        </div>
        <div class="col-12 col-md-12">
          <q-btn
            icon="add"
            label="Adicionar nova conta..."
            color="white text-blue-7"
            flat
            clickable
            @click="addNewItem"
          ></q-btn>
        </div>
      </div>
    </q-card-section>
  </div>
</template>

<script>
import { Notify } from 'quasar';
import axios from '@/axios-auth';
export default {
  name: 'tour-contas',
  data() {
    return {
      contas: [],
      loadContas: false,
      loadSave: false,
      novoItem: false,
      nomeContas: ['Conta 003', 'Conta 043', 'Retaguarda'],
    };
  },
  methods: {
    addNewItem() {
      this.contas.push({
        titulo: '',
        agencia: '0000',
        conta: '0000',
        operacao: '000',
        tipo: 'conta',
        valid: false,
        id: null,
      });
      this.nomeContas.push('Nova Conta');
    },
    contasNext() {
      this.$parent.contasNext();
    },
    contasPrev() {
      this.$parent.contasPrev();
    },
    listar() {
      this.loadContas = true;
      axios.get('/users/tour?passo=2').then(res => {
        for (let i = 0; i < res.data.contas.length; i++) {
          res.data.contas[i].valid = false;
        }
        this.contas = res.data.contas;
        this.loadContas = false;
      });
    },
    envia(conta, index) {
      if (conta.saving) {
        return;
      }

      conta.saving = true;

      let id = '#conta' + index;
      if (conta.titulo === '') {
        conta.valid = false;
        return;
      }
      let url = '';
      if (conta.id === null) {
        url = '/contas/add';
      } else {
        url = '/contas/edit/' + conta.id;
      }
      axios
        .post(url, conta)
        .then(res => {

          const newConta = res.data.success ? res.data.conta : this.contas[index];
          newConta.saving = false;
          newConta.valid = res.data.success;

          this.$set(this.contas, index, newConta);
        })
        .catch(() => {
          conta.saving = false;
          Notify.create({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    this.listar();
  },
};
</script>

<style lang="stylus" scoped></style>
