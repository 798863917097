<template>
  <div id="categoriasParametros">
    <div
      v-if="!loadingCategorias && categorias && categorias.length === 0"
      style="text-align: center"
    >
      <q-icon size="10em" name="info_outline" color="grey" />
      <br />
      <p>
        Não foi encontrado nenhuma categoria. Deseja importar as Categorias
        padrões?
      </p>
      <br />
      <q-btn
        no-wrap
        v-ripple
        label="Importar Categorias"
        no-caps
        class="q-ml-sm q-px-md bg-positive text-white"
        clickable
        :loading="saveLoteria"
        @click="importaPadrao()"
      >
        <template v-slot:loading>
          <q-spinner color="grey-10" size="1em" />
        </template>
      </q-btn>
    </div>
    <q-table
      v-if="!loadingCategorias && categorias && categorias.length > 0"
      :data="categorias"
      :columns="columns"
      row-key="id"
      :separator="separator"
      :loading="loadingCategorias"
      no-data-label="Nenhum resultado encontrado."
      :pagination.sync="pagination"
      hide-bottom
    >
      <template v-slot:top="props">
        <q-space />
        <p>
          Exibindo
          <strong>{{ returnCategoria() }}</strong> itens.
        </p>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
          <q-td key="id" class="text-right">{{ props.row.id }}</q-td>
          <q-td key="ativa" class="text-center">
            <q-icon
              :name="props.row.ativa ? 'check' : 'close'"
              size="24px"
              :class="props.row.ativa ? 'text-positive' : 'text-negative'"
            ></q-icon>
          </q-td>
          <q-td key="titulo"
            ><a class="cursor-pointer" @click="modalCategoria(props.row)">{{
              props.row.titulo
            }}</a></q-td
          >
          <q-td key="ordem" class="text-right">{{ props.row.ordem }}</q-td>
          <q-td key="created" class="text-center">{{
            props.row.created | formatDateTime
          }}</q-td>
          <q-td key="modified" class="text-center">{{
            props.row.modified | formatDateTime
          }}</q-td>
          <q-td v-if="$q.platform.is.desktop" class="tdfora">
            <div class="divfora">
              <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                <q-btn
                  @click="modalCategoria(props.row)"
                  size="12px"
                  class="bg-primary text-white"
                  icon="edit"
                ></q-btn>
                <q-btn
                  @click="deleteCategoria(props.row)"
                  size="12px"
                  class="bg-negative text-white"
                  icon="delete_forever"
                ></q-btn>
              </div>
            </div>
          </q-td>
        </q-tr>
        <q-tr v-show="props.expand" :props="props">
          <q-td colspan="100%">
            <div class="q-py-sm q-gutter-sm">
              <q-btn
                @click="modalCategoria(props.row)"
                size="12px"
                class="bg-primary text-white"
                icon="edit"
              />
              <q-btn
                @click="deleteCategoria(props.row)"
                size="12px"
                class="bg-negative text-white"
                icon="delete_forever"
              />
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <div class="col-12 col-md-12 text-center" v-if="loadingCategorias">
        <br />
        <q-spinner color="blue-7" size="3em" />
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "categorias-parametros",
  data() {
    return {
      pagination: {
        rowsPerPage: 0
      },
      separator: "horizontal",
      columns: [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: row => row.id,
          sortable: true
        },
        {
          name: "ativa",
          align: "center",
          label: "Ativo",
          field: row => row.ativa,
          sortable: true
        },
        {
          name: "titulo",
          label: "Título",
          field: row => row.titulo,
          sortable: true,
          align: "center"
        },
        {
          name: "ordem",
          label: "Ordem",
          field: row => row.ordem,
          sortable: true,
          align: "center"
        },
        {
          name: "created",
          label: "Criado em",
          field: row => row.created,
          sortable: true,
          align: "center"
        },
        {
          name: "modified",
          label: "Modificado em",
          field: row => row.modified,
          sortable: true,
          align: "center"
        },
        {
          name: "tdfora",
          label: "",
        },
      ]
    };
  },
  methods: {
    modalCategoria(categoria) {
      this.$store.dispatch("modalCategoria", categoria);
    },
    deleteCategoria(categoria) {
      this.$store.dispatch("deleteCategoria", categoria);
    },
    returnCategoria() {
      return this.categorias.length;
    },
    importaPadrao() {
      let padrao = {
        importar_produtos: true
      };
      this.$store.dispatch("addLoteria", padrao);
    }
  },
  computed: {
    ...mapGetters(["categorias", "loadingCategorias", "saveLoteria"])
  },
  mounted() {
    this.$store.dispatch("listarCategorias");
  }
};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
