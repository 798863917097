<template>
  <div id="semPermissao">
    <div style="text-align: center" class="fixed-center">
      <q-icon size="10em" name="info_outline" color="grey" />
      <br />
      <p style="font-weight: bold;font-size: 25px" class="text-grey">Sem Permissão</p>
      <br />
      <p>
        Algo inesperado aconteceu, ou você não tem permissão para acessar este local.
        <br />
        <router-link to="/visao360" style="color: #000;text-decoration: underline;">Clique aqui</router-link>&nbsp;para voltar para a página inicial.
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "sem-permissao"
};
</script>