import axios from "@/axios-auth";
import { Notify } from "quasar";

const state = {
  OpenDialogEditarSettings: false,
  OpenDialogAgendarMensagem: false,
  OpenDialogCancelarFilas: false,
  loadingWppMessagesList: false,
  DetalhesAgendado: "",
  wppMessagesList: [],
  status: {
    label: "Na Fila",
    index: 0,
  },
  filas: [],
  pagingWhatsapp: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  filtrosWhatsapp: {
    page: 0,
    status: 2,
  },
  conexao: "",
  tipoWhatsapp: "",
  userConectadoWpp:null
};

const mutations = {
  OpenDialogEditarSettings: (state, userData) => {
    state.OpenDialogEditarSettings = userData;
  },
  OpenDialogAgendarMensagem: (state, userData) => {
    state.OpenDialogAgendarMensagem = userData;
  },
  OpenDialogCancelarFilas: (state, userData) => {
    state.OpenDialogCancelarFilas = userData;
  },
  DetalhesAgendado: (state, userData) => {
    state.DetalhesAgendado = userData;
  },
  setWppMessagesList: (state, userData) => {
    state.wppMessagesList = userData;
  },
  setStatus: (state, userData) => {
    state.status = userData;
  },
  setLoadingWppMessagesList: (state, userData) => {
    state.loadingWppMessagesList = userData;
  },
  setPagingWhatsapp: (state, userData) => {
    state.pagingWhatsapp = userData;
  },
  setFiltrosWhatsapp: (state, userData) => {
    state.filtrosWhatsapp = userData;
  },
  setFilas: (state, userData) => {
    state.filas = userData;
  },
  setUserConectadoWpp: (state, userData) => {
    state.userConectadoWpp = userData;
  },
  conexao: (state, userData) => {
    state.conexao = userData;
  },
  tipoWhatsapp: (state, userData) => {
    state.tipoWhatsapp = userData;
  }
};

const actions = {
  //change: fazer função para verificar se usuário tem permissão pro aplicativo ou não

  listarWppMessagesList({ commit, state, dispatch }, userData = {}) {
    if (userData.atualizar) {
      dispatch("resetFiltrosWhatsapp");
      dispatch("resetPagingWhatsapp");
      commit("setWppMessagesList", []);
    }
    if (state.loadingWppMessagesList) return;
    if (!state.pagingWhatsapp.nextPage) return;

    commit("setLoadingWppMessagesList", true);

    let dados = state.filtrosWhatsapp;
    dados.page += 1;

    axios
      .get('/whatsapp', {
        params: dados,
      })
      .then(res => {
        commit("setLoadingWppMessagesList", false);

        if (!res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "red",
            position: "top-right"
          });
          return;
        }

        const wppMessagesList = [...state.wppMessagesList, ...res.data.whatsapps];

        commit("setWppMessagesList", wppMessagesList);
        commit("setPagingWhatsapp", res.data.paging);
      }).catch(err => {
        Notify.create({
          message: "Houve um erro ao listar a filas de mensages!",
          color: "red",
          position: "top-right"
        });
        commit("setLoadingWppMessagesList", false);
        commit("hasWhatsapp", false);
      });
  },
  resetPagingWhatsapp: ({ commit }) => {
    commit("setPagingWhatsapp", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });
  },
  resetFiltrosWhatsapp: ({ commit, status }) => {
    commit("setFiltrosWhatsapp", {
      page: 0,
      status: state.status.index,
    });
  },
  abrirDialogCancelarFilas: ({ commit }, userData) => {
    commit("OpenDialogCancelarFilas", true);
    commit("setFilas", userData);
  },
};

const getters = {
  wppMessagesList: state => {
    return state.wppMessagesList;
  },
  status: state => {
    return state.status;
  },
  OpenDialogEditarSettings: state => {
    return state.OpenDialogEditarSettings;
  },
  OpenDialogAgendarMensagem: state => {
    return state.OpenDialogAgendarMensagem;
  },
  OpenDialogCancelarFilas: state => {
    return state.OpenDialogCancelarFilas;
  },
  DetalhesAgendado: state => {
    return state.DetalhesAgendado;
  },
  DetalhesAgendado: state => {
    return state.DetalhesAgendado;
  },
  loadingWppMessagesList: state => {
    return state.loadingWppMessagesList;
  },
  pagingWhatsapp: state => {
    return state.pagingWhatsapp;
  },
  filtrosWhatsapp: state => {
    return state.filtrosWhatsapp;
  },
  filas: state => {
    return state.filas;
  },
  conexao: state => {
    return state.conexao;
  },
  tipoWhatsapp: state => {
    return state.tipoWhatsapp;
  },
  userConectadoWpp: state => {
    return state.userConectadoWpp;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
