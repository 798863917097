<template>
  <div id="tourGateways">
    <q-card-section v-if="loadingGateways" class="text-center">
      <q-spinner color="blue-7" size="4em" style="position: relative" />
    </q-card-section>
    <q-card-section>
      <p class="text-justify" style="hyphens: none;">
        Informe os dados a seguir para realizar a configuração das formas de recebimento da sua empresa.
        Para cadastrar clique em "Cadastrar conta"<br>
          
        Caso queira realizar esse cadastro mais tarde clique em "Continuar".
      </p>

      <div class="row q-col-gutter-sm">

        <div class="col-md-6">
          <q-input
            v-model="newCobranca.titulo"
            label="Banco"
            bottom-slots
            maxlength="50"
            dense
            :error="errors.titulo ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.titulo" :key="error + i">{{ error }}</span>
            </template>
          </q-input>
        </div>

        <div class="col-12 col-md-6">
          <q-input
            v-model="newCobranca.codigoBanco"
            label="Código do Banco"
            bottom-slots
            maxlength="3"
            dense
            :error="errors.codigoBanco ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.codigoBanco" :key="error + i">{{ error }}</span>
            </template>
          </q-input>
        </div>

        <div class="col-12 col-md-6">
          <q-input
            v-model="newCobranca.agencia"
            label="Agência"
            bottom-slots
            maxlength="6"
            dense
            :error="errors.agencia ? true : false"
            @input="$event => formatDigit($event, 'agencia')"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.agencia" :key="error + i">{{ error }}</span>
            </template>
          </q-input>
        </div>

        <div class="col-12 col-md-6">
          <q-input
            v-model="newCobranca.conta"
            label="Conta"
            bottom-slots
            maxlength="11"
            dense
            :error="errors.conta ? true : false"
            @input="$event => formatDigit($event, 'conta')"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.conta" :key="error + i">{{ error }}</span>
            </template>
          </q-input>
        </div>

        <p class="text-h6 q-mb-none">Responsável</p>

        <div class="col-12">
          <q-input
            v-model="newCobranca.nome"
            :error="errors.nome ? true : false"
            label="Nome"
            dense
            bottom-slots
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.nome" :key="error + i">{{ error }}</span>
            </template>
          </q-input>
        </div>

        <div class="col-12">
          <q-input
            v-model="newCobranca.cpf"
            label="CPF"
            bottom-slots
            mask="###.###.###-##"
            dense
            :error="errors.cpf ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.cpf" :key="error + i">{{ error }}</span>
            </template>
          </q-input>
        </div>

        <div class="col-12">
          <q-input
            v-model="newCobranca.email"
            type="email"
            :error="errors.email ? true : false"
            label="E-mail"
            dense
            bottom-slots
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.email" :key="error + i">{{ error }}</span>
            </template>
          </q-input>
        </div>

        <div class="col-12 col-md-12 text-center">
          <q-btn
            label="Cadastrar Conta"
            class="q-px-md bg-positive text-white"
            :loading="loadingGateways"
            @click="createGateways"
          />
        </div>
      </div>
    </q-card-section>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify } from "quasar";

export default {
  name: "tour-gateways",
  emits: ["next"],
  data() {
    return {
      loadingGateways: false,
      errors: [],
      newCobranca: {
        titulo: null,
        agencia: null,
        conta: null,
        codigoBanco: null,
        nome: null,
        cpf: null,
        email: null,
      }
    };
  },
  methods: {
    createGateways() {
      if (this.loadingGateways) return;
      this.loadingGateways = true;
      this.errors = [];

      const dados = {
        ...this.newCobranca,
        cpf: this.newCobranca.cpf ? this.newCobranca.cpf.match(/\d/g).join("") : null,
      };

      axios
        .post("/gateways/tour", dados)
        .then((res) => {
          this.loadingGateways = false;

          if (res.data.safe2PayError) {
            Notify.create({
              message: res.data.safe2PayError,
              color: "red",
              position: "top-right",
            });
            return;
          }

          if (!res.data.success) {
            this.errors = res.data.errors || [];

            Notify.create({
              message: res.data.message || "Erro ao adicionar conta e formas de pagamento.",
              color: "red",
              position: "top-right",
            });
            return;
          }

          Notify.create({
            message: res.data.message,
            color: "positive",
            position: "top-right",
          });

          this.$emit("next");
        })
        .catch(() => {
          Notify.create({
            message: "Não foi possível importar as formas de pagamento. Tente novamente mais tarde.",
            color: "red",
            position: "top-right",
          });

          this.loadingGateways = false;
        });
    },
    formatDigit(number, ref) {
      let cleaned = number.replace(/\D/g, '');
      let formattedNumber = '';

      if (cleaned.length > 1) {
        formattedNumber = cleaned.slice(0, -1) + '-' + cleaned.slice(-1);
      } else {
        formattedNumber = cleaned;
      }

      this.newCobranca[ref] = formattedNumber;
    }
  },
};
</script>
