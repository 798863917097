<template>
  <q-page id="caixas">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!isPermissoCaixas"></sem-permissao>
        <div class="col-12" >
          <q-table v-if="isPermissoCaixas && !isCardView"
            title="Caixas"
            :data="lancamentoCaixas"
            :columns="columns"
            row-key="id"
            :loading="buscandoCaixas"
            :bottom="false"
            :pagination.sync="pagination"
            :selection="permissoesCaixas.lancarQuebraCaixa ? 'multiple' : undefined"
            :selected.sync="selected"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
            :visible-columns="visibleColumns"
            >
            <template v-slot:bottom>
              <div></div>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                <q-td auto-width v-if="permissoesCaixas.lancarQuebraCaixa">
                  <q-checkbox v-model="props.selected" color="secondary" />
                </q-td>
                <q-td
                  key="id"
                  v-if="visibleColumns.indexOf('id') !== -1"
                  class="text-right"
                  >{{props.row.id}}
                </q-td>
                <q-td
                  key="dtmovimento"
                  v-if="visibleColumns.indexOf('dtmovimento') !== -1"
                  class="text-center"
                  >
                  <router-link
                    :to="getUrl(props.row)"
                    style="color: black; text-decoration: none"
                  >{{new Date(props.row.dtmovimento) | formatDate}}</router-link>
                </q-td>
                <q-td key="caixa_nome" v-if="visibleColumns.indexOf('caixa_nome') !== -1">
                  <router-link
                    :to="getUrl(props.row)"
                    style="color: black; text-decoration: none"
                  >{{props.row.caixa_nome + ' - Sessão ' + props.row.sessao}}</router-link>
                </q-td>
                <q-td
                  key="func_resp_nome"
                  v-if="visibleColumns.indexOf('func_resp_nome') !== -1"
                  >{{props.row.func_resp_nome}}
                </q-td>
                <q-td
                  key="func_aber_nome"
                  v-if="visibleColumns.indexOf('func_aber_nome') !== -1"
                  >{{props.row.func_aber_nome}}
                </q-td>
                <q-td
                  key="created"
                  v-if="visibleColumns.indexOf('created') !== -1"
                  class="text-center"
                  >
                  {{props.row.created | formatDateTime}}
                </q-td>
                <q-td
                  v-if="visibleColumns.indexOf('quebra') !== -1 && permissoesCaixas.lancarQuebraCaixa"
                  key="quebra"
                  class="text-right"
                >
                  <q-badge v-if="props.row.gerado" outline color="green" label="Gerado" class="q-mr-sm" />
                  <q-badge v-if="props.row.quebra > 0" outline color="red" label="Falta" />
                  <q-badge v-if="props.row.quebra < 0" outline color="blue" label="Sobra" />
                  <br>
                  R$ {{ Math.abs(props.row.quebra) | currency }}
                </q-td>
                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn
                        @click="lancar(props.row)"
                        size="12px"
                        class="bg-positive text-white"
                        icon="arrow_forward"
                      ></q-btn>
                      <q-btn
                        :disable="resumoPrinting"
                        @click="imprimir(props.row)"
                        size="12px"
                        class="bg-white text-black"
                        icon="print"
                        :loading="resumoPrinting"
                      >
                        <template v-slot:loading>
                          <q-spinner color="grey-10" size="1em" />
                        </template>
                      </q-btn>
                      <q-btn
                        v-if="filtrosCaixas.status === 'F'"
                        @click="reabrir(props.row)"
                        size="12px"
                        class="bg-orange text-white"
                        icon="reply"
                      ></q-btn>
                      <q-btn
                        v-if="filtrosCaixas.status === 'A'"
                        @click="fechar(props.row)"
                        size="12px"
                        class="bg-negative text-white"
                        icon="delete_forever"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      @click="lancar(props.row)"
                      size="12px"
                      class="bg-positive text-white"
                      icon="arrow_forward"
                    />
                    <q-btn
                      :disable="resumoPrinting"
                      @click="imprimir(props.row)"
                      size="12px"
                      class="bg-white text-black"
                      icon="print"
                      :loading="resumoPrinting"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                    <q-btn
                      v-if="filtrosCaixas.status === 'F'"
                      @click="reabrir(props.row)"
                      size="12px"
                      class="bg-orange text-white"
                      icon="reply"
                    />
                    <q-btn
                      v-if="filtrosCaixas.status === 'A'"
                      @click="fechar(props.row)"
                      size="12px"
                      class="bg-negative text-white"
                      icon="delete_forever"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:top="props">
              <q-btn
                flat
                round
                dense
                id="addCaixas"
                :icon="'add'"
                class="q-ml-md text-primary"
                style="margin-right: 10px;margin-left: 0"
                @click="modal()"
              />
              <div class="col-1 q-table__title">Caixas</div>
              <q-btn
                v-if="allCaixa"
                flat
                dense
                no-wrap
                v-ripple
                color="secondary"
                no-caps
                :label="
                  'Selecionar todos os ' + pagingCaixas.count + ' caixas'
                "
                class="q-ml-sm q-px-md"
                :class="{
                  'bg-white text-blue-7': !allCaixas,
                  'bg-green-7 text-white': allCaixas
                }"
                clickable
                @click="setAllCaixas()"
              />

              <q-space />
              <q-btn
                v-if="hasVideo"
                dense
                flat
                color="bg-primary text-blue-7"
                @click="modalIntro"
                icon="ondemand_video"
                style="margin-right: 10px"
                >
                <template v-slot:default>
                  <q-tooltip>Introdução aos Caixas</q-tooltip>
                </template>
              </q-btn>
              <q-btn
                flat
                dense
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="tipo"
                :class="`q-ml-sm ${$q.platform.is.desktop ? 'q-px-md' : 'q-px-none'} bg-white text-blue-7`"
                >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Status</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': tipo === 'Aberto'}"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="disparaFiltros('A')"
                    >
                      <q-item-section>Aberto</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': tipo === 'Fechado'}"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="disparaFiltros('F')"
                    >
                      <q-item-section>Fechado</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
              <q-btn
                flat
                dense
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="funcionarioNome === undefined ? 'Funcionário' : funcionarioNome"
                :class="`q-ml-sm ${$q.platform.is.desktop ? 'q-px-md' : 'q-px-none'} bg-white text-blue-7`"
                style="margin-right: 10px"
                >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Funcionários</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': funcionarioNome === f.nome}"
                      v-for="f in funcionarioCaixa"
                      :key="f.id + f.nome"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="disparaFiltrosFunc(f)"
                    >
                      <q-item-section>{{ f.nome }}</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': funcionarioNome === 'Funcionário'}"
                      @click="disparaFiltrosFunc()"
                      v-close-popup
                      clickable
                      aria-hidden="true"
                    >
                      <q-item-section>Todos</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
              <date-picker-caixas v-if="filtrosCaixas.status && filtrosCaixas.status === 'F'"></date-picker-caixas>
              <q-select
                v-model="visibleColumns"
                multiple
                borderless
                dense
                options-dense
                :display-value="'Colunas'"
                emit-value
                map-options
                :options="columns"
                option-value="name"
                style="min-width: 100px"
                />
              <q-btn flat v-if="permissoesCaixas.visualizarCardView && !props.inFullscreen" icon="apps" @click="changeCardView">
                <template v-slot:default>
                  <q-tooltip>Visualização em Cards</q-tooltip>
                </template>
              </q-btn>
              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                rounded
                no-wrap
                v-ripple
                class="q-ml-sm q-px-md bg-white text-black"
                no-caps
                icon="more_vert"
                style="margin-right: 10px"
                >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Mais Opções</q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup aria-hidden="true" @click="listarCaixas()">
                      <q-item-section>Atualizar</q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="props.toggleFullscreen"
                    >
                      <q-item-section>{{props.inFullscreen ? 'Minimizar' : 'Maximizar Tela'}}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </template>
          </q-table>

          <q-layout v-if="isPermissoCaixas && isCardView && permissoesCaixas.visualizarCardView" style="min-height: auto;">
            <div class="row q-mb-md">
              <div class="col-6 flex-center">
                <div class="q-ml-md relative-position row items-center">
                  <q-btn
                    flat
                    round
                    dense
                    id="addCaixas"
                    :icon="'add'"
                    class="q-ml-md text-primary"
                    style="margin-right: 10px;margin-left: 0"
                    @click="modal()"
                  />
                  <div class="col-1 q-table__title">Caixas</div>
                </div>
              </div>
              <div class="col-6 text-right">
                <q-btn
                  v-if="hasVideo"
                  dense
                  flat
                  color="bg-primary text-blue-7"
                  @click="modalIntro"
                  icon="ondemand_video"
                  style="margin-right: 10px"
                  >
                  <template v-slot:default>
                    <q-tooltip>Introdução aos Caixas</q-tooltip>
                  </template>
                </q-btn>
                <q-btn
                  style="margin-right: 10px"
                  flat
                  dense
                  icon="update"
                  :class="atualizacaoAutomatica ? 'text-blue-7' : 'text-black'"
                  clickable
                  @click="changeAtualizacaoAutomatica()"
                >
                  <template v-slot:default>
                    <q-tooltip v-if="atualizacaoAutomatica">Atualização automática em {{tempoParaAtualizar != 1 ? tempoParaAtualizar + ' min' : 'em menos de 1 min'}}</q-tooltip>
                    <q-tooltip v-else>Atualização automática desligada</q-tooltip>
                  </template>
                </q-btn>
                <q-btn
                  style="margin-right: 10px"
                  flat
                  dense
                  :icon="isFullScreen ? 'fullscreen_exit' : 'fullscreen'"
                  class="text-black"
                  clickable
                  @click="openFullscreen()"
                >
                  <template v-slot:default>
                    <q-tooltip>{{isFullScreen ? 'Minimizar': 'Maximizar'}}</q-tooltip>
                  </template>
                </q-btn>
                <q-btn dense flat icon="menu" @click="changeCardView" v-if="!isFullScreen">
                  <template v-slot:default>
                    <q-tooltip>Visualização em lista</q-tooltip>
                  </template>
                </q-btn>
              </div>
            </div>

            <div class="q-col-gutter-md row" style="height:auto;" v-if="!buscandoCaixas">
              <div class="col-12 col-md-3 q-pt-sm" v-for="(caixa, index) in lancamentoCaixasComResumo" :key="index">
                <q-card v-ripple dense class="my-card cursor-pointer q-hoverable" @click="lancar(caixa)" bordered>
                  <q-card-section class="no-padding">
                    <q-list
                      dense
                      ref="listaTotalizadores"
                    >

                      <q-item-label header class="q-pt-sm text-bold q-pb-none no-margin">{{caixa.func_resp_nome}}</q-item-label>
                      <q-item-label header class="q-pt-none text-bold q-pb-sm no-margin">{{new Date(caixa.dtmovimento) | formatDate}} - {{caixa.caixa_nome}} - Sessão {{caixa.sessao}}</q-item-label>

                      <q-item-label header class="q-pt-none q-pb-none no-margin">Entradas</q-item-label>

                      <q-item
                        v-ripple
                        style="min-height: 22px;"
                      >
                        <q-item-section avatar class="sinal-totalizador">
                          <q-item-label>(+)</q-item-label>
                        </q-item-section>
                        <q-item-section>
                          <q-item-label>Saldo Inicial</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-item-label>R$ {{caixa.total_saldo_inicial |currency}}</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item
                      v-ripple
                      style="min-height: 22px;"
                      >
                        <q-item-section avatar class="sinal-totalizador">
                          <q-item-label>(+)</q-item-label>
                        </q-item-section>
                        <q-item-section>
                          <q-item-label lines="1">Reforços</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-item-label>R$ {{caixa.total_reforco |currency}}</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item
                      v-ripple
                      style="min-height: 22px;"
                      >
                        <q-item-section avatar class="sinal-totalizador">
                          <q-item-label>(+)</q-item-label>
                        </q-item-section>
                        <q-item-section>
                          <q-item-label lines="1">Vendas</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-item-label>R$ {{caixa.total_vendas |currency}}</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item
                      v-ripple
                      style="min-height: 22px;"
                      >
                        <q-item-section avatar class="sinal-totalizador">
                          <q-item-label>(+)</q-item-label>
                        </q-item-section>
                        <q-item-section>
                          <q-item-label lines="1">Resumo do Operador</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-item-label>R$ {{caixa.total_resumo |currency}}</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item
                      v-ripple
                      style="min-height: 22px;"
                      >
                        <q-item-section avatar class="sinal-totalizador">
                          <q-item-label>(+)</q-item-label>
                        </q-item-section>
                        <q-item-section>
                          <q-item-label lines="1">Recebimento de Contas</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-item-label>R$ {{caixa.total_recebimentos |currency}}</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item-label header class="q-pt-none q-pb-none no-margin">Saídas</q-item-label>

                      <q-item
                      v-ripple
                      style="min-height: 22px;"
                      >
                      <q-item-section avatar class="sinal-totalizador">
                        <q-item-label>(-)</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label lines="1">Pagamento de Contas</q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-item-label>R$ {{caixa.total_pagamentos |currency}}</q-item-label>
                      </q-item-section>
                      </q-item>

                      <q-item
                      v-ripple
                      style="min-height: 22px;"
                      >
                      <q-item-section avatar class="sinal-totalizador">
                        <q-item-label>(-)</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label lines="1">Compras</q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-item-label>R$ {{caixa.total_compras |currency}}</q-item-label>
                      </q-item-section>
                      </q-item>

                      <q-item
                      v-ripple
                      style="min-height: 22px;"
                      >
                      <q-item-section avatar class="sinal-totalizador">
                        <q-item-label>(-)</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label lines="1">Vendas Fiado</q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-item-label>R$ {{caixa.total_fiado |currency}}</q-item-label>
                      </q-item-section>
                      </q-item>

                      <q-item
                      v-ripple
                      style="min-height: 22px;"
                      >
                      <q-item-section avatar class="sinal-totalizador">
                        <q-item-label>(-)</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label lines="1">Sangrias</q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-item-label>R$ {{caixa.total_sangria |currency}}</q-item-label>
                      </q-item-section>
                      </q-item>

                      <q-item
                      v-ripple
                      style="min-height: 22px;"
                      >
                      <q-item-section avatar class="sinal-totalizador">
                        <q-item-label>(-)</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label lines="1">Saldo Final do Caixa</q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-item-label>R$ {{caixa.total_saldo_final |currency}}</q-item-label>
                      </q-item-section>
                      </q-item>

                      <q-item
                      v-ripple
                      style="min-height: 22px;"
                      >
                      <q-item-section avatar class="sinal-totalizador">
                        <q-item-label>(-)</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label lines="1">Saldo Final do Operador</q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-item-label>R$ {{caixa.total_saldo_final_operador |currency}}</q-item-label>
                      </q-item-section>
                      </q-item>

                      <q-separator />

                      <q-item>
                      <q-item-section>
                        <q-item-label lines="1" class="text-bold">{{caixa.total > 0 ? 'Falta' : caixa.total == 0 ? 'Total' : ' Sobra'}}</q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-item-label :class="caixa.total > 0 ? 'text-red' :  caixa.total == 0 ? 'text-black' : 'text-green' ">R$ {{Math.abs(caixa.total) | currency}}</q-item-label>
                      </q-item-section>
                      </q-item>
                    </q-list>
                  </q-card-section>
                </q-card>
              </div>
            </div>
          </q-layout>

          <div class="col-12 col-md-12 text-center" v-if="buscandoCaixas">
            <br />
            <q-spinner color="blue-7" size="3em" />
          </div>

          <q-page-sticky position="top-right" :offset="[0, 90]">
            <q-btn
              :color="OpenOpcoesAvancadasCaixas ? 'red-7' : 'blue-7'"
              :icon="OpenOpcoesAvancadasCaixas ? 'close' : 'assignment'"
              class="rotate-0"
              style="border-top-left-radius: 50px;border-bottom-left-radius: 50px"
              @click="OpenOpcoesAvancadasCaixas = !OpenOpcoesAvancadasCaixas"
              >
              <q-tooltip
                :content-class="OpenOpcoesAvancadasCaixas ? 'bg-negative text-white text-center' : 'bg-secondary text-white text-center'"
              >{{OpenOpcoesAvancadasCaixas ? 'Fechar Opções Avançadas' : 'Opções Avançadas'}}</q-tooltip>
            </q-btn>
          </q-page-sticky>

          <q-drawer
            side="right"
            v-model="OpenOpcoesAvancadasCaixas"
            bordered
            :width="280"
            :breakpoint="500"
            behavior="default"
            content-class="bg-white"
            >
            <q-scroll-area class="fit">
              <div class="q-pa-sm">
                <div class="q-table__title">Opções Avançadas</div>
                <div>
                  <q-list
                    class="rounded-borders"
                    style="max-width: 350px; margin-left: -10px;width: 300px;"
                  >
                    <q-item-label header></q-item-label>

                    <q-item clickable v-ripple @click.exact="openModal('loterias')">
                      <q-item-section>
                        <q-item-label lines="1">Cob. Diária de Loterias</q-item-label>
                        <q-item-label caption>Cobrança diária de serviços</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="openModal('servicos')">
                      <q-item-section>
                        <q-item-label lines="1">Cob. Diária de Serviços</q-item-label>
                        <q-item-label caption>Cobrança diária de serviços</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="openModalQuebraCaixa()" v-if="permissoesCaixas.lancarQuebraCaixa">
                      <q-item-section>
                        <q-item-label lines="1">Lançar Quebra de Caixa</q-item-label>
                        <q-item-label caption>Realiza a inclusão de contas a receber referentes a quebra do caixa</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="deleteQuebraCaixa()" v-if="permissoesCaixas.lancarQuebraCaixa">
                      <q-item-section>
                        <q-item-label lines="2">Marcar como Pendente a Quebra de Caixa</q-item-label>
                        <q-item-label caption>Atualiza o status da quebra de caixa como pendente.</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </div>
            </q-scroll-area>
          </q-drawer>
        </div>
      </div>
    </div>
    <modal-cobranca-diaria :tipo="tipoCobranca"></modal-cobranca-diaria>
    <modal-novo-lancamento-caixa></modal-novo-lancamento-caixa>

    <card-total-itens
      tipo="caixas"
      :total="totalCaixasCard"
      :selecionado="totalSelecionadoCard"
      v-if="permissoesCaixas.lancarQuebraCaixa && !isCardView"
    />

    <q-dialog v-model="openDialogLancarQuebraCaixa" persistent>
      <q-card style="max-width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Lançar Quebra de Caixa</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="openDialogLancarQuebraCaixa = false"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12">
                <q-input
                    bottom-slots
                    :error="errorsLancamentoCaixa.vencimento ? true : false"
                    v-model="dadosLancamentoCaixa.vencimento"
                    label="Vencimento"
                    @click="$refs.vencimentoCaixa.show()"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          ref="vencimentoCaixa"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="dadosLancamentoCaixa.vencimento"
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            minimal
                            today-btn
                            @input="() => $refs.vencimentoCaixa.hide()"
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLancamentoCaixa.vencimento"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-input>
              </div>
              <div class="col-12">
                <q-input
                    bottom-slots
                    :error="errorsLancamentoCaixa.descricao ? true : false"
                    v-model="dadosLancamentoCaixa.descricao"
                    label="Descrição"
                    ref="descricaoCaixa"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLancamentoCaixa.descricao"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-input>
              </div>

              <div class="col-12">
                <q-checkbox
                  v-model="dadosLancamentoCaixa.gerarLancamentoComSobras"
                  label="Calcular com as sobras"
                  color="green"
                />
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Gerar"
            clickable
            @click="lancarQuebraCaixa()"
            :loading="salvandoQuebraCaixa"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
const moment = require("moment");
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";

export default {
  name: "caixas",
  meta: {
    title: "DSB | Caixas"
  },
  data() {
    return {
      visibleColumns: JSON.parse(localStorage.getItem("caixas__visibleColumns")) || [
        "id",
        "dtmovimento",
        "caixa_nome",
        "func_resp_nome",
        "func_aber_nome",
        "created",
        "quebra"
      ],
      columns: [
        {
          name: "id",
          label: "ID",
          align: "center",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "dtmovimento",
          align: "center",
          label: "Data",
          field: row => row.dtmovimento,
          sortable: true
        },
        {
          name: "caixa_nome",
          required: true,
          align: "left",
          label: "Nome do Caixa",
          field: row => row.caixa_nome,
          sortable: true
        },
        {
          name: "func_resp_nome",
          align: "left",
          label: "Responsável",
          field: row => row.func_resp_nome,
          sortable: true
        },
        {
          name: "func_aber_nome",
          align: "left",
          label: "Aberto Por",
          field: row => row.func_aber_nome,
          sortable: true
        },
        {
          name: "created",
          align: "center",
          label: "Aberto em",
          field: row => row.created,
          sortable: true
        },
        {
          name: "quebra",
          align: "Center",
          label: "Quebra",
          field: row => row.quebra,
          sortable: true
        },

      ],
      pagination: {
        sortBy: "dtmovimento",
        descending: true,
        page: 1,
        rowsPerPage: 0
      },
      tipo: "Aberto",
      OpenOpcoesAvancadasCaixas: false,
      tipoCobranca: "",
      selected: [],
      allCaixa: false,
      allCaixas: false,
      openDialogLancarQuebraCaixa: false,
      salvandoQuebraCaixa: false,
      dadosLancamentoCaixa: {
        vencimento: null,
        descricao: 'Quebra de caixa',
        gerarLancamentoComSobras: false,
      },
      errorsLancamentoCaixa: {},
      hasVideo: false,
      isFullScreen:false,
      atualizacaoAutomatica: false,
      intervalId: null,
      intervalId2: null,
      tempoParaAtualizar: 5,
    };
  },
  methods: {
    modalIntro() {
      this.$store.dispatch("loadManual", "caixas");
    },
    openModal(value) {
      this.tipoCobranca = value;
      this.$store.commit("OpenModalCobrancaDiaria", true);
    },
    modal() {
      this.$store.commit("newCaixa", {
        isNew: true,
        nome: "",
        numero: "",
        id: null
      });
      this.$store.dispatch("caixas");
      this.$store.dispatch("callFuncionarios");
      this.$store.commit("OpenDialogNovoLancamentoCaixa", true);
    },
    disparaFiltros(value) {
      this.filtrosCaixas.status = value;
      this.tipo = value === "A" ? "Aberto" : "Fechado";
      this.$store.dispatch("filtrosCaixas", this.filtrosCaixas);
    },
    disparaFiltrosFunc(value) {
      if (value) {
        this.filtrosCaixas.cadastro_id = value.id;
        this.$store.commit("funcionarioNome", value.nome);
        this.$store.dispatch("filtrosCaixas", this.filtrosCaixas);
      } else {
        this.filtrosCaixas.cadastro_id = null;
        this.$store.dispatch("filtrosCaixas", this.filtrosCaixas);
        this.$store.commit("funcionarioNome", "Funcionário");
      }
    },
    listarCaixas() {
      if(this.isCardView){
        this.$store.dispatch("listarCaixasComResumo");
        return;
      }
      this.$store.dispatch("resetPagingCaixas");
      this.$store.dispatch("listarCaixas");
    },
    fechar(caixa) {
      this.$store.dispatch("inativarCaixa", caixa);
    },
    reabrir(caixa) {
      this.$store.dispatch("reativarCaixa", caixa);
    },
    lancar(caixa) {
      this.$router.push("/caixas/" + caixa.id);
    },
    handleScroll() {
      if(this.isCardView) return;
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.$store.dispatch("listarCaixas");
      }
    },
    getUrl(caixa) {
      return "/caixas/" + caixa.id;
    },
    imprimir(caixa) {
      this.$store.dispatch("imprimirRelatorioCaixa", caixa);
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais || this.buscandoUser) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "caixas");

      if (!this.videosManuais.video_caixas) {
        this.modalIntro();
      }
    },
    setAllCaixas() {
      this.allCaixas = !this.allCaixas;
    },
    openModalQuebraCaixa() {
      this.dadosLancamentoCaixa = {
        vencimento: null,
        descricao: 'Quebra de caixa',
        gerarLancamentoComSobras: false,
      };
      this.openDialogLancarQuebraCaixa = true;
    },
    lancarQuebraCaixa() {
      this.salvandoQuebraCaixa = true;

      const data = {
        dtini: this.filtrosCaixas.dtini,
        dtfim: this.filtrosCaixas.dtfim,
        dtmovimento: this.filtrosCaixas.dtmovimento,
        cadastro_id: this.filtrosCaixas.cadastro_id,
        all: this.allCaixas,
        ids: this.selected.map(movcaixa => movcaixa.id),
        gerar_sobras: this.dadosLancamentoCaixa.gerarLancamentoComSobras,
        descricao: this.dadosLancamentoCaixa.descricao,
        vencimento: this.dadosLancamentoCaixa.vencimento,
      };

      axios
        .post("/lancamentos/quebra-caixa", data)
        .then(res => {
          this.salvandoQuebraCaixa = false;

          let message = res.data.message;

          if (!res.data.success && res.data.errors) {
            this.errorsLancamentoCaixa = res.data.errors;
            message = Object.values(res.data.errors)
              .map(error => Object.values(error)[0])
              .join("<br/>");
          }

          this.$q.notify({
            color: res.data.success ? 'green' : 'orange',
            message: message,
            position: 'top-right',
            html: true,
          });

          if (!res.data.success) return;

          this.openDialogLancarQuebraCaixa = false;
          //this.$store.dispatch("listarCaixas");
        })
        .catch(err => {
          this.salvandoQuebraCaixa = false;
          this.$q.notify({
            color: 'red',
            message: 'Houve um erro ao lançar as quebras de caixa',
            position: 'top-right',
          });
        });
    },
    deleteQuebraCaixa() {

      const dados = {
        dtini: this.filtrosCaixas.dtini,
        dtfim: this.filtrosCaixas.dtfim,
        dtmovimento: this.filtrosCaixas.dtmovimento,
        cadastro_id: this.filtrosCaixas.cadastro_id,
        all: this.allCaixas,
        ids: this.selected.map(movcaixa => movcaixa.id)
      };

      Dialog.create({
        title: "Marcar como Pendente a Quebra de Caixa",
        message: "Confirma marcar como Pendente a Quebra de Caixa?",
        html: true,
        cancel: "Cancelar",
        persistent: true
      })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        this.salvandoQuebraCaixa = true;

        axios
        .post("/lancamentos/quebra-caixa-delete", dados)
        .then(res => {
          this.salvandoQuebraCaixa = false;

          let message = res.data.message;

          if (!res.data.success && res.data.errors) {
            this.errorsLancamentoCaixa = res.data.errors;
            message = Object.values(res.data.errors)
              .map(error => Object.values(error)[0])
              .join("<br/>");
          }

          this.$q.notify({
            color: res.data.success ? 'green' : 'orange',
            message: message,
            position: 'top-right',
            html: true,
          });

        })
        .catch(err => {
          this.salvandoQuebraCaixa = false;
          this.$q.notify({
            color: 'red',
            message: 'Houve um erro ao lançar as quebras de caixa',
            position: 'top-right',
          });
        });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });

    },
    ...mapMutations(["setIsCardView"]),
    changeCardView(){
      this.isCardView = !this.isCardView;
      localStorage.setItem("isCardView", this.isCardView);
      if(this.isCardView){
        this.$store.dispatch("listarCaixasComResumo");
      }
    },
    openFullscreen() {
      if (this.isFullScreen) {
        this.closeFullscreen();
        return;
      }
      let elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
      document.getElementById("headerPrincipal").style.visibility = "hidden";
      document.getElementById("drawerPrincipal").style.visibility = "hidden";
      this.isFullScreen = true;
    },
    closeFullscreen() {
      if (!this.isFullScreen) return;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      document.getElementById("headerPrincipal").style.visibility = "visible";
      document.getElementById("drawerPrincipal").style.visibility = "visible";
      this.isFullScreen = false;
    },
    changeAtualizacaoAutomatica(){
      this.atualizacaoAutomatica = !this.atualizacaoAutomatica;
    },
    contador(){
      if(this.tempoParaAtualizar === 1){
        this.tempoParaAtualizar = 5;
      }else{
        this.tempoParaAtualizar -= 1;
      }
    }
  },
  computed: {
    ...mapGetters([
      "buscandoCaixas",
      "lancamentoCaixas",
      "pagingCaixas",
      "filtrosCaixas",
      "totalCaixas",
      "funcionarioCaixa",
      "funcionarioNome",
      "resumoPrinting",
      "isPermissoCaixas",
      "buscandoUser",
      "metadadosCaixas",
      "permissoesCaixas",
      "videosManuais",
      "manuaisDisponiveis",
      "getIsCardView",
      "lancamentoCaixasComResumo"
    ]),
    isCardView: {
      get() {
        return this.getIsCardView;
      },
      set(newValue) {
        this.setIsCardView(newValue);
      }
    },
    query() {
      return this.$route;
    },
    totalCaixasCard() {
      const total = {
        valor: this.metadadosCaixas.valor_total,
        quantidade: this.pagingCaixas.count || 0
      };

      return total;
    },
    totalSelecionadoCard() {
      const selecionado = {
        valor: 0.00,
        quantidade: 0,
      };

      if (this.allCaixas) {
        selecionado.valor = this.metadadosCaixas.valor_total;
        selecionado.quantidade = this.pagingCaixas.count;

        return selecionado;
      }

      selecionado.valor = this.selected.reduce((quebra, caixa) => quebra + caixa.quebra, 0.00);
      selecionado.quantidade = this.selected.length;

      return selecionado;
    },
  },
  mounted() {
    if(localStorage.getItem('isCardView') === "true" && !this.isCardView) this.changeCardView();
    window.addEventListener("scroll", this.handleScroll);
    this.$store.dispatch("filtrosCaixas", this.filtrosCaixas);
    this.verificarPrimeiroVideo();
    if(this.isCardView){
      this.$store.dispatch("listarCaixasComResumo");
    }
  },
  watch: {
    isCardView(val){
      if(val){
        this.$store.commit("buscandoCaixas", true);
      }
    },
    buscandoUser(val) {
      this.verificarPrimeiroVideo();
    },
    visibleColumns() {
      localStorage.setItem("caixas__visibleColumns", JSON.stringify(this.visibleColumns));
    },
    selected(value) {
      if (value.length === 0) {
        this.allCaixa = false;
      } else if (value.length >= 10 || value.length === this.totalCaixas) {
        this.allCaixa = true;
      }
    },
    atualizacaoAutomatica(val){
      if(val){
        this.intervalId = setInterval(this.listarCaixas, 1000 * 60 * 5 );
        this.intervalId2 = setInterval(this.contador, 1000 * 60 );
      }else{
        clearInterval(this.intervalId);
        clearInterval(this.intervalId2);
        this.tempoParaAtualizar = 5;
      }
    }
  },
  created() {
    this.$store.commit("queryCaixas", this.query);
  },
  beforeRouteEnter(to, from, next) {
    if (!localStorage["playintro"]) {
      localStorage.setItem("playintro", "false");
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  }
};
</script>

<style lang="stylus"></style>
