<template>
  <div id="modalEnviarEmail">
    <q-dialog v-model="OpenDialogEnviarEmail" persistent>
      <q-card style="max-width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6 text-center">Deseja fazer o envio de receitas em massa?</div>
          <!-- <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="cancela()" />-->
        </q-card-section>
        <!-- <q-separator /> -->
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col col-md-12 text-center">
                <q-checkbox
                  v-model="isTemplate"
                  label="Escolher o Template de E-mail"
                  color="green"
                />
              </div>
              <div class="col col-md-12" v-if="isTemplate">
                <q-select
                  clearable
                  v-model="template_id"
                  :options="templates"
                  label="Template de E-mail"
                  option-label="subject"
                  option-value="id"
                  emit-value
                  map-options
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            style="margin-right: 0px; margin-bottom: 10px"
            flat
            dense
            label="Cancelar"
            clickable
            @click="cancela"
            class="text-grey-8"
          ></q-btn>
          <q-btn
            flat
            label="Enviar"
            clickable
            @click="enviaAcao"
            :loading="realizandoAcaoReceitas"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";

export default {
  name: "modal-enviar-email",
  data() {
    return {
      isTemplate: false,
      template_id: null,
      templates: [],
    };
  },
  watch: {
    isTemplate() {
      this.ativaTemplate();
    }
  },
  methods: {
    ativaTemplate() {
      axios
        .get("/templates", {
          params: {tipo: "boleto,cartao"}
        })
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right",
            });
            return;
          }

          this.templates = res.data.data.templates;
        })
        .catch(() => {
          this.$q.notify({
            message: "Houve um erro ao listar templates",
            color: "red",
            position: "top-right",
          });
        });

      if (!this.isTemplate) this.template_id = null;
    },
    enviaAcao() {
      if (!this.isTemplate) this.template_id = null;
      this.$store.dispatch("realizarEnvioEmail", this.template_id);
      this.isTemplate = false;
      this.template_id = null;
    },
    cancela() {
      this.$store.commit("OpenDialogEnviarEmail", false);
      this.isTemplate = false;
      this.template_id = null;
    }
  },
  computed: {
    OpenDialogEnviarEmail: {
      get() {
        return this.$store.getters.OpenDialogEnviarEmail;
      },
      set() {}
    },
    realizandoAcaoReceitas() {
      return this.$store.getters.realizandoAcaoReceitas;
    }
  },
};
</script>

<style lang="stylus" scoped></style>
