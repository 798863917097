<template>
  <div id="faturamentoAutomativoParametros">
    <q-table
      flat
      row-key="id"
      no-data-label="Nenhum dado"
      no-results-label="Nenhum resultado obtido"
      loading-label="Carregando dados"
      :data="faturamentoAutomaticoArray"
      :columns="columns"
      :loading="loadingListar"
      :pagination.sync="pagination"
    >
      <template v-slot:bottom></template>

      <template v-slot:body="props">
        <q-tr :props="props">

          <q-td key="ativo" class="text-left">
            <q-icon
              name="check"
              color="positive"
              size="24px"
              v-if="props.row.ativo"
            />
            <q-icon
              name="close"
              color="negative"
              size="24px"
              v-else
            />
          </q-td>

          <q-td key="id" class="text-left">
            {{ props.row.id }}
          </q-td>

          <q-td key="dia_hora_execucao" class="text-left">
            <span v-if="props.row.dia_execucao === 0">Diariamente</span>
            <span v-else>Dia {{ props.row.dia_execucao }}</span>
            às {{ `0${props.row.horario_execucao}`.slice(-2) }}:00 hs
          </q-td>

          <q-td key="dia_vencimento" class="text-left">
            <span v-if="props.row.dia_execucao === 0">{{ props.row.dias_antes}} dias depois</span>
            <span v-else>
              Dia {{ props.row.dia_vencimento }} do {{ getLabelMesVencimento(props.row.mes_vencimento) }}
            </span>
          </q-td>

          <q-td key="dia_inicial_final" class="text-left">
            <span v-if="props.row.dia_execucao === 0"> -- </span>
            <span v-else>
              <span v-if="props.row.dia_inicial">
                {{ `0${props.row.dia_inicial}`.slice(-2) }} - {{ `0${props.row.dia_final}`.slice(-2) }}
              </span>
            </span>
          </q-td>

          <q-td key="referencia" class="text-left">
            {{ props.row.referencia }}
          </q-td>

          <q-td key="descricao_personalizada" class="text-left">
            {{ props.row.descricao_personalizada }}
          </q-td>

          <q-td class="tdfora">
            <div class="divfora">
              <div class="divdentro q-gutter-sm" style="margin-right: 10px">

                <q-btn
                  size="12px"
                  icon="edit"
                  color="positive"
                  @click="editar(props.row)"
                ></q-btn>

                <q-btn
                  size="12px"
                  icon="delete_forever"
                  color="negative"
                  @click="excluir(props.row)"
                ></q-btn>

              </div>
            </div>
          </q-td>

        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Loading } from 'quasar';
import axios from '@/axios-auth';
import { bus } from "@/main";

export default {
  name: "faturamento-automatico-parametros",
  data() {
    return {
      columns: [
        {
          name: 'ativo',
          label: 'Ativo',
          field: row => row.ativo,
          align: 'center',
          sortable: true,
        },
        {
          name: 'id',
          label: 'ID',
          field: row => row.id,
          align: 'center',
          sortable: true,
        },
        {
          name: 'dia_hora_execucao',
          label: 'Dia de Execução',
          field: row => `${row.dia_execucao}${row.hora_execucao}`,
          align: 'center',
          sortable: true,
        },
        {
          name: 'dia_vencimento',
          label: 'Dia de Vencimento',
          field: row => row.dia_vencimento,
          align: 'center',
          sortable: true,
        },
        {
          name: 'dia_inicial_final',
          label: 'Início e Fim',
          field: row => `${row.dia_inicial}${row.dia_final}`,
          align: 'center',
          sortable: true,
        },
        {
          name: 'referencia',
          label: 'Referência',
          field: row => row.referencia,
          align: 'center',
          sortable: true,
        },
        {
          name: 'descricao_personalizada',
          label: 'Descrição Personalizada',
          field: row => row.descricao_personalizada,
          align: 'center',
          sortable: true,
        },
        { name: 'tdfora' },
      ],
      pagination: {
        sortBy: 'id',
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      faturamentoAutomaticoArray: [],
      loadingListar: false,
    };
  },
  methods: {
    load() {
      this.loadingListar = true;

      axios
        .get('/financeiro/faturamento-automatico')
        .then(res => {
          this.loadingListar = false;

          if (this.notificarSemPermissao(res)) {
            return;
          }

          this.faturamentoAutomaticoArray = res.data.faturamento_automatico;
        })
        .catch(() => {
          this.loadingListar = false;
          this.$q.notify({
            message: 'Houve um erro ao carregar os faturamentos automáticos',
            color: 'red',
            position: 'top-right',
          });
          this.$store.dispatch('fecharModalFaturamentoAutomatico');
        });
    },
    notificarSemPermissao(res) {
      const semPermissao = res.error && res.error.response.status === 403;

      if (!semPermissao) {
        return false;
      }

      this.$q.notify({
        message: res.error.response.data.message,
        color: 'red',
        position: 'top-right',
      });

      return true;
    },
    editar(faturamentoAutomatico) {
      faturamentoAutomatico = JSON.parse(JSON.stringify(faturamentoAutomatico));

      faturamentoAutomatico.utilizar_inicio_fim = Boolean(faturamentoAutomatico.dia_inicial);
      faturamentoAutomatico.personalizar_descricao = Boolean(faturamentoAutomatico.descricao_personalizada);
      faturamentoAutomatico.faturamento_diario = faturamentoAutomatico.dia_execucao == 0 ? true : false;

      this.$store.dispatch("abrirModalAdicionarFaturamento", faturamentoAutomatico);
    },
    excluir(faturamentoAutomatico) {
      this.$q.dialog({
        title: `${faturamentoAutomatico.id} - Faturamento Automático`,
        message: 'Tem certeza que deseja excluir a régua de faturamento?',
        cancel: 'Cancelar',
        persistent: true,
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(`/financeiro/delete-faturamento-automatico/${faturamentoAutomatico.id}`)
            .then(res => {
              if (this.notificarSemPermissao(res)) return;

              this.$q.dialog({
                title: res.data.message,
                class: `bg-${res.data.success ? 'positive' : 'warning'} text-white`,
                ok: {
                  'text-color': res.data.success ? 'green' : 'orange',
                  color: 'white',
                },
              });

              if (!res.data.success) return;

              this.load();
            })
            .catch(() => {
              this.$q.dialog({
                title: 'Houve um erro ao excluir o faturamento automático',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white',
                },
              });
            });
          })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    getLabelMesVencimento(mes) {
      const labels = {
        0: 'mês atual',
        1: 'próximo mês',
      };

      return labels[mes];
    },
  },
  mounted() {
    this.load();
    bus.$on("loadFaturamentoAutomatico", () => {
      this.load();
    });
  },
  destroyed() {
    bus.$off("loadFaturamentoAutomatico");
  },
};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
