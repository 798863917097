<template>
  <div id="graficoAcessosLicencas">
    <q-card>
      <q-card-section>
        <div class="q-table__title">
          {{dashboard.title}}
          <q-btn
            v-if="$q.screen.gt.xs"
            flat
            dense
            rounded
            no-wrap
            v-ripple
            class="q-ml-sm q-px-md bg-white text-black"
            no-caps
            icon="more_vert"
            style="margin-right: 10px;float:right"
          >
            <q-menu anchor="top right" self="top right">
              <q-list class="text-grey-8" style="min-width: 100px">
                <q-item aria-hidden="true">
                  <q-item-section
                    class="text-uppercase text-grey-7"
                    style="font-size: 0.7rem"
                  >Mais Opções</q-item-section>
                </q-item>
                <q-item clickable v-close-popup aria-hidden="true" @click="listar()">
                  <q-item-section>Atualizar</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </q-card-section>
      <q-card-section v-if="loading">
        <div class="q-pa-md">
          <div class="q-col-gutter-md row">
            <div class="col-12 text-center">
              <q-spinner color="blue-7" size="4em" />
            </div>
          </div>
        </div>
      </q-card-section>
      <q-card-section v-if="!loading" style="padding-left: 0;overflow: auto">
        <GChart type="LineChart" :data="dashboard.data" :options="chartOptions" />
      </q-card-section>
      <q-card-actions></q-card-actions>
    </q-card>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { bus } from "@/main";
import { GChart } from "vue-google-charts";
export default {
  name: "grafico-acessos-licencas",
  components: {
    GChart
  },
  data() {
    return {
      dashboard: [],
      loading: false
    };
  },
  methods: {
    listar(value) {
      if (this.loading) return;
      if (this.dashboard.length > 0) this.dashboard = [];
      this.loading = true;
      let query = {};
      if (this.$route.query) {
        query = this.$route.query;
      }
      if (value !== undefined) {
        if (value.id) {
          query = {
            servico_id: value.id
          };
        }
        if (value.busca) {
          query = {
            busca: value.busca
          };
        }
        if (value.estado) {
          query = {
            estado: value.estado
          };
        }
        if (value.version_id) {
          query = {
            version_id: value.version_id
          };
        }
      }
      if (
        this.acessosData &&
        (this.acessosData.dtini.length > 0 || this.acessosData.dtfim.length > 0)
      ) {
        query.dtini = this.acessosData.dtini;
        query.dtfim = this.acessosData.dtfim;
      }
      axios
        .get("/licencas/dashboard", {
          params: query
        })
        .then(res => {
          if (res.data.success) {
            this.dashboard = res.data.acessos;
            // this.graph()
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    acessosData() {
      return this.$store.getters.acessosData;
    },
    chartOptions() {
      return {
        colors: ["#4285f4"],
        legend: { position: "in" },
        hAxis: {
          slantedText: false,
          minValue: 0
        }
      };
    }
  },
  mounted() {
    this.listar();
  },
  created() {
    bus.$on("atualizaGrafico", value => {
      this.listar(value);
    });
  }
};
</script>

<style lang="stylus" scoped></style>
