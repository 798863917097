const state = {
  openDialogMetricasSaasOpcoes: false,
};

const mutations = {
  setOpenDialogMetricasSaasOpcoes: (state, userData) => {
    state.openDialogMetricasSaasOpcoes = userData;
  },
};

const actions = {
  controlDialogOpcoesMetricasSaas: ({ commit }, userData) => {
    commit("setOpenDialogMetricasSaasOpcoes", userData);
  },
};

const getters = {
  openDialogMetricasSaasOpcoes: state => {
    return state.openDialogMetricasSaasOpcoes;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
