import axios from "@/axios-auth";
import { Notify } from "quasar";

const state = {
  tags: [],
  allTags: []
};

const mutations = {
  tags: (state, userData) => {
    state.tags = userData.tags;
  },
  allTags: (state, userData) => {
    state.allTags = userData.allTags;
  }
};

const actions = {
  getTags: ({ commit }, userData) => {
    commit("tags", {
      tags: []
    });
    axios
      .get("/cadastros/tags/" + userData)
      .then(res => {
        if (res.data.success) {
          commit("tags", {
            tags: res.data.tags
          });
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  addTag: ({ commit }) => {
    axios
      .get("/tags", {
        params: {
          sort: "title"
        }
      })
      .then(res => {
        if (res.data.success) {
          let allTags = res.data.tags;
          for (let i = 0; i < allTags.length; i++) {
            allTags[i].edit = false;
            allTags[i].save = false;
          }
          commit("allTags", {
            allTags: allTags
          });
          commit("OpenDialogTags", true);
        }
      });
  }
};

const getters = {
  getTags: state => state.tags,
  allTags: state => state.allTags
};

export default {
  state,
  mutations,
  actions,
  getters
};
