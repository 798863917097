<template>
  <div id="modalNotaFiscalOpcoes">
    <q-dialog v-model="openDialogOpcoesNotaFiscal" persistent style="max-width: 100%;">
      <q-card>
        <q-card-section class="row items-center">
          <div class="text-h6">Nota Fiscal</div>
          <q-space />
          <q-btn
            class="q-ml-lg"
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="cancela()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-6 col-md-3">
                <q-input
                  label="Último Lote"
                  v-model="opcoes.notasfiscais_ultimolote"
                />
              </div>
              <div class="col-6 col-md-3">
                <q-input
                  label="Último RPS"
                  v-model="opcoes.notasfiscais_ultimorps"
                />
              </div>
              <div class="col-6">
                <q-input
                  label="Regime Especial de Tributação"
                  v-model="opcoes.notasfiscais_regimeespecialtributacao"
                  :error="errors.notasfiscais_regimeespecialtributacao ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.notasfiscais_regimeespecialtributacao"
                      :key="error + i"
                    >
                      {{ error }}
                    </span>
                  </template>
                </q-input>
              </div>
              <div class="col-6">
                <q-input
                  label="Alíquota"
                  v-model="opcoes.notasfiscais_aliquota"
                />
              </div>
              <div class="col-6">
                <q-input
                  label="Item da Lista de Serviço"
                  v-model="opcoes.notasfiscais_itemlistaservico"
                />
              </div>
              <div class="col-6">
                <q-input
                  label="Descrição do Item da Lista de Serviço"
                  v-model="opcoes.notasfiscais_descricaoitemlistaservico"
                />
              </div>
              <div class="col-6">
                <q-input
                  label="Código CNAE"
                  v-model="opcoes.notasfiscais_codigocnae"
                />
              </div>
              <div class="col-6">
                <q-input
                  label="Inscrição Municipal"
                  v-model="opcoes.notasfiscais_inscricaomunicipal"
                  :error="errors.notasfiscais_inscricaomunicipal ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.notasfiscais_inscricaomunicipal"
                      :key="error + i"
                    >
                      {{ error }}
                    </span>
                  </template>
                </q-input>
              </div>
              <div class="col-6">
                <q-input
                  label="Código do Município"
                  v-model="opcoes.notasfiscais_codigomunicipio"
                />
              </div>
              <div class="col-6">
                <q-input
                  label="Código de Tributação do Município"
                  v-model="opcoes.notasfiscais_codigotributacaomunicipio"
                />
              </div>
              <div class="col-6">
                <q-input
                  label="Optante Simples Nacional"
                  v-model="opcoes.notasfiscais_optantesimplesnacional"
                >
                </q-input>
              </div>
              <div class="col-6">
                <q-input
                  label="Incentivador Cultural"
                  v-model="opcoes.notasfiscais_incentivadorcultural"
                >
                </q-input>
              </div>
              <div class="col-6">
                <q-select
                  v-model="opcoes.notasfiscais_homologacao"
                  label="Ambiente"
                  :options="opcoesAmbiente"
                  option-value.value="notasfiscais_homologacao"
                  emit-value
                  map-options
                />
              </div>
              <!-- <div class="col-12 q-pl-sm">
                <q-checkbox
                  v-model="opcoes.notasfiscais_addautoliquidareceita"
                  label="Criar nota automaticamente ao liquidar receita"
                />
              </div> -->

              <div class="col-6">
                <q-select
                  v-model="opcoes.notasfiscais_gerar_nfse"
                  label="Gerar nota quando:"
                  :options="opcoesGerarNota"
                  option-value.value="notasfiscais_gerar_nfse"
                  emit-value
                  map-options
                />
              </div>

              <div class="col-6">
                <q-input
                  label="Senha do Certificado"
                  v-model="opcoes.notasfiscais_passwordcertificado"
                  :error="errors.notasfiscais_passwordcertificado ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.notasfiscais_passwordcertificado"
                      :key="error + i"
                    >
                      {{ error }}
                    </span>
                  </template>
                </q-input>
              </div>

              <div class="col-12">
                <label class="custom-file-upload">
                  <input type="file" style="display: none;" @input="onUpload" />

                  <p id="title">
                    <q-icon name="upload" />
                    Certificado
                  </p>

                  <p v-if="filePath !== ''" style="font-size: 12px">
                    {{ filePath }}
                  </p>
                  <p v-else>Nenhum arquivo selecionado</p>
                </label>
              </div>

              <div class="col-12 q-pl-sm">
                <q-checkbox
                  v-model="opcoes.notasfiscais_enviar_nfse_registrada"
                  label="Enviar nota fiscal para o cliente quando registrada"
                  color="green"
                  true-value="1"
                  false-value="0"
                />
              </div>
              <div class="col-12 q-pl-sm">
                <q-checkbox
                  v-model="opcoes.notasfiscais_registrar_aut_incluida"
                  label="Registrar nota fiscal automaticamente quando incluída"
                  color="green"
                  true-value="1"
                  false-value="0"
                />
              </div>
              <div class="col-12 q-pl-sm">
                <q-checkbox
                  v-model="opcoes.notasfiscais_registrar_aut_cancelada"
                  label="Registrar nota fiscal automaticamente quando cancelada"
                  color="green"
                  true-value="1"
                  false-value="0"
                />
              </div>
              <div class="col-12 q-pl-sm">
                <q-checkbox
                  v-model="opcoes.notasfiscais_permitir_registro_notas_futuras"
                  label="Permitir o registro de notas futuras"
                  color="green"
                  true-value="1"
                  false-value="0"
                />
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <div class="q-pa-md">
            <q-btn
              @click="salvaOpcoes()"
              :loading="loadingSalvaOpcoes"
              label="Salvar"
              color="positive"
            >
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
          </div>
        </q-card-actions>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
import { Loading, Dialog } from "quasar";

export default {
  name: "modal-notas-fiscais-opcoes",
  data() {
    return {
      loadingSalvaOpcoes: false,
      uploadingCertificado: false,
      opcoes: {},
      opcoesGerarNota: [
        {
          value: 0,
          label: 'Desativado',
        },
        {
          value: 1,
          label: 'Ao criar cobrança',
        },
        {
          value: 2,
          label: 'Ao liquidar cobrança',
        },
      ],
      opcoesAmbiente: [
        {
          value: 1,
          label: 'Produção',
        },
        {
          value: 2,
          label: 'Homologação',
        },
      ],
      errors: [],
    };
  },
  methods: {
    cancela() {
      this.errors = [];
      this.$store.commit("openDialogOpcoesNotaFiscal", false);
    },
    salvaOpcoes() {
      this.loadingSalvaOpcoes = true;

      axios.post('notas-fiscais/opcoes', this.opcoes)
        .then(res => {
          this.loadingSalvaOpcoes = false;

          if (!res.data.success) {
            if (res.data.atualizar_rps) {
              this.opcoes.notasfiscais_ultimorps = res.data.atualizar_rps;
            }

            if (res.data.errors) {
              this.errors = res.data.errors;
            }

            if (res.data.errorFocus) {
              Dialog.create({
                title: "Erro",
                message: res.data.message,
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            } else {
              this.$q.notify({
                message: res.data.message,
                color: "orange",
                position: "top-right",
              });
            }

            return;
          }

          this.$q.notify({
            message: "Sucesso ao salvar as opções",
            color: "green",
            position: "top-right",
          });

          this.$store.commit("opcoesNotaFiscal", res.data.opcoes);

          this.cancela();
        })
        .catch(() => {
          this.$q.notify({
            message: "Houve um erro ao salvar as opções",
            color: "red",
            position: "top-right",
          });
          this.loadingSalvaOpcoes = false;
        });
    },
    onUpload(event) {
      if (this.uploadingCertificado) return;
      this.uploadingCertificado = true;

      event.preventDefault();
      const file = event.target.files[0];

      if (!file) {
        this.$q.notify({
          message: "Nenhum arquivo selecionado",
          color: "orange",
          position: "top-right",
        });
        return;
      }

      Loading.show();
      const fd = new FormData();
      fd.append("file", file);

      axios
        .post("notas-fiscais/opcoes?upload_certificado=1", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(res => {
          this.uploadingCertificado = false;
          Loading.hide();

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right",
            });
            return;
          }

          this.opcoes["notasfiscais_pathcertificado"] = res.data.path;

          this.$q.notify({
            message: res.data.message,
            color: "green",
            position: "top-right",
          });
        });
    }
  },
  computed: {
    ...mapGetters([
      "openDialogOpcoesNotaFiscal",
      "opcoesNotaFiscal",
      "homologadosNotaFiscal"
    ]),
    filePath() {
      if (!this.opcoes["notasfiscais_pathcertificado"]) return '';
      const path = this.opcoes["notasfiscais_pathcertificado"].split(/[\\/]/);
      return path[path.length - 1];
    }
  },
  watch: {
    opcoesNotaFiscal (val) {
      this.opcoes = val;
      if (this.opcoesGerarNota[val.notasfiscais_gerar_nfse]) {
        this.opcoes.notasfiscais_gerar_nfse = this.opcoesGerarNota[val.notasfiscais_gerar_nfse].value;
      }
      if (this.opcoesAmbiente[val.notasfiscais_homologacao - 1]) {
        this.opcoes.notasfiscais_homologacao = this.opcoesAmbiente[val.notasfiscais_homologacao - 1].value;
      }
    },
  },
};
</script>

<style scoped>
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 12px 0px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.3s;
}

.custom-file-upload:hover {
  background-color: #eee;
}

.custom-file-upload p#title {
  font-size: 16px;
  color: rgba(0,0,0,0.6);
}

.custom-file-upload p {
  margin: 0;
}
</style>