<template>
  <q-page id="despesas">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!isPermissoDespesas"></sem-permissao>
        <div class="col-12" v-if="isPermissoDespesas">
          <q-table
            dense
            title="Despesas"
            :data="despesas"
            :columns="columns"
            row-key="id"
            :loading="buscandoDes"
            :bottom="false"
            :pagination.sync="pagination"
            selection="multiple"
            :selected.sync="selectedDespesas"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
            :visible-columns="visibleColumns"
          >
            <template v-slot:bottom></template>
            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile && (props.row.id !== null)">
                <q-td auto-width>
                  <q-checkbox
                    v-model="props.selected"
                    color="secondary"
                    v-if="!props.row.is_despesas_recorrencia"
                  />
                  <q-checkbox
                    color="secondary"
                    :val="props.row"
                    v-else
                    v-model="selectedDespesasRecorrencias"
                  />
                </q-td>
                <q-td
                  key="id"
                  v-if="visibleColumns.indexOf('id') !== -1"
                  class="text-right"
                >
                  {{ props.row.id }}
                  <span v-if="props.row.is_despesas_recorrencia" class="text-grey">Sugerido</span>
                </q-td>

                <q-td key="cadastro">
                  <router-link
                    style="text-decoration: none;color: #000"
                    :to="
                      props.row.cadastro
                        ? '/cadastros/' + props.row.cadastro_id
                        : ''
                    "
                    >
                      {{
                        props.row.cadastro
                          ? props.row.cadastro.nome.length > 40
                            ? props.row.cadastro.nome.substr(0, 40) + "..."
                            : props.row.cadastro.nome
                          : "Sem Cadastro"
                      }}
                      <q-tooltip
                        v-if=" props.row.cadastro && props.row.cadastro.nome && props.row.cadastro.nome.length > 40"
                        >
                        {{ props.row.cadastro.nome }}
                      </q-tooltip>
                    </router-link
                  >
                </q-td>
                <q-td
                  key="descricao"
                  v-if="visibleColumns.indexOf('descricao') !== -1"
                >
                  <template v-slot:default>
                    {{
                      props.row.descricao && props.row.descricao.length > 40
                        ? props.row.descricao.substr(0, 40) + "..."
                        : props.row.descricao
                    }}
                    <q-tooltip
                      v-if="
                        props.row.descricao && props.row.descricao.length > 40
                      "
                    >
                      {{ props.row.descricao }}
                    </q-tooltip>
                  </template>
                </q-td>

                <q-td
                  key="forma_pagamento"
                  v-if="visibleColumns.indexOf('forma_pagamento') !== -1"
                  :style="visibleColumns.length > 6 ? 'max-width: 300px' : 'max-width: 200px' + '!important;'"
                >
                  <div v-if="props.row.gateway" style="display: inline;">
                    {{ props.row.gateway.titulo}}

                    <q-badge
                      v-if="(props.row.status=='Agendado' || props.row.status=='Liquidado') && props.row.fatura"
                      :color="getBtnStatusPgmento(props.row.fatura)"
                      rounded
                      align="middle"
                    >
                      <template v-slot:default>
                        <q-tooltip
                          :content-class="'bg-' + getBtnStatusPgmento(props.row.fatura)"
                        >
                          {{
                            statusPagamentoMsg(props.row.fatura)
                          }}
                        </q-tooltip>
                      </template>
                    </q-badge>
                  </div>

                  <br>

                  <span v-if="props.row.cadastros_gateway">
                    {{ props.row.cadastros_gateway.codigo.length > 30 ?
                      props.row.cadastros_gateway.codigo.substr(0, 30) + "..." :
                      props.row.cadastros_gateway.codigo
                    }}
                    {{ props.row.cadastros_gateway.agencia }}
                    {{ props.row.cadastros_gateway.conta }}

                    <q-tooltip v-if="props.row.cadastros_gateway.codigo.length > 30">
                      {{ props.row.cadastros_gateway.codigo }}
                    </q-tooltip>
                  </span>

                </q-td>

                <q-td
                  key="vencimento"
                  v-if="visibleColumns.indexOf('vencimento') !== -1"
                  class="text-center"
                  >{{ new Date(props.row.vencimento) | formatDate }}</q-td
                >
                <q-td
                  key="referencia"
                  v-if="visibleColumns.indexOf('referencia') !== -1"
                  class="text-center"
                  >{{ props.row.referencia }}</q-td
                >
                <q-td
                  key="valor"
                  v-if="visibleColumns.indexOf('valor') !== -1"
                  class="text-right"
                  >R$ {{ props.row.valor | currency }}</q-td
                >
                <q-td
                  v-if="visibleColumns.indexOf('valor_liquidado') !== -1"
                  class="text-right"
                  key="valor_liquidado"
                  >R$ {{ props.row.valor_liquidado | currency }}</q-td
                >
                <q-td
                  v-if="visibleColumns.indexOf('liquidacao') !== -1"
                  class="text-center"
                  key="liquidacao"
                  >{{ props.row.liquidacao | formatDate }}</q-td
                >
                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div
                      class="divdentro q-gutter-sm"
                      style="margin-right: 10px"
                    >
                      <q-btn
                        @click="detalhar(props.row)"
                        size="12px"
                        class="bg-primary text-white"
                        icon="info"
                      ></q-btn>
                      <q-btn
                        v-show="props.row.status !== 'Cancelado' && props.row.status !== 'Agendado'"
                        @click="editar(props.row)"
                        class="bg-secondary text-white"
                        icon="edit"
                        size="12px"
                      ></q-btn>
                      <q-btn
                        v-if="
                          props.row.status !== 'Cancelado' &&
                            !props.row.liquidado && props.row.status !== 'Agendado'
                        "
                        @click="liquidar(props.row)"
                        class="bg-green-7 text-white"
                        icon="attach_money"
                        size="12px"
                        :loading="loadingLiquidar"
                      ></q-btn>
                      <q-btn
                        v-if="props.row.status === 'Liquidado'"
                        @click="estornar(props.row)"
                        class="bg-secondary text-white"
                        icon="reply"
                        size="12px"
                      ></q-btn>
                      <q-btn
                        v-if="props.row.status === 'Cancelado'"
                        @click="reativa(props.row)"
                        class="bg-secondary text-white"
                        icon="reply"
                        size="12px"
                      ></q-btn>
                      <q-btn
                        v-if="props.row.status === 'Aberto' && !props.row.is_despesas_recorrencia"
                        @click="excluir(props.row)"
                        class="bg-negative text-white"
                        icon="delete_forever"
                        size="12px"
                      ></q-btn>
                      <q-btn
                        v-if="props.row.status === 'Agendado' && props.row.fatura"
                        @click="retornarPgmentoStatus(props.row)"
                        class="bg-secondary text-white"
                        icon="reply"
                        size="12px"
                      ></q-btn>
                      <q-btn
                        v-if="props.row.status === 'Agendado' && props.row.fatura"
                        @click="realizarPagamento(props.row)"
                        class="bg-green-7 text-white"
                        icon="attach_money"
                        size="12px"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      @click="detalhar(props.row)"
                      size="12px"
                      class="bg-primary text-white"
                      icon="info"
                    />
                    <q-btn
                      v-show="props.row.status !== 'Cancelado' && props.row.status !== 'Agendado'"
                      @click="editar(props.row)"
                      class="bg-secondary text-white"
                      icon="edit"
                      size="12px"
                    />
                    <q-btn
                      v-if="props.row.status !== 'Cancelado' && !props.row.liquidado && props.row.status !== 'Agendado'"
                      @click="liquidar(props.row)"
                      class="bg-green-7 text-white"
                      icon="attach_money"
                      size="12px"
                      :loading="loadingLiquidar"
                    />
                    <q-btn
                      v-if="props.row.status === 'Liquidado'"
                      @click="estornar(props.row)"
                      class="bg-secondary text-white"
                      icon="reply"
                      size="12px"
                    />
                    <q-btn
                      v-if="props.row.status === 'Cancelado'"
                      @click="reativa(props.row)"
                      class="bg-secondary text-white"
                      icon="reply"
                      size="12px"
                    />
                    <q-btn
                      v-if="props.row.status === 'Aberto' && !props.row.is_despesas_recorrencia"
                      @click="excluir(props.row)"
                      class="bg-negative text-white"
                      icon="delete_forever"
                      size="12px"
                    />
                    <q-btn
                      v-if="props.row.status === 'Agendado' && props.row.fatura"
                      @click="retornarPgmentoStatus(props.row)"
                      class="bg-secondary text-white"
                      icon="reply"
                      size="12px"
                    />
                    <q-btn
                      v-if="props.row.status === 'Agendado' && props.row.fatura"
                      @click="realizarPagamento(props.row)"
                      class="bg-green-7 text-white"
                      icon="attach_money"
                      size="12px"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:top="props">
              <q-btn
                flat
                round
                dense
                :icon="'add'"
                id="addDespesa"
                class="q-ml-md text-primary"
                style="margin-right: 10px;margin-left: 0"
                @click="modal()"
              />
              <div class="col-1 q-table__title">Despesa</div>
              <q-btn
                v-if="allDespesa"
                flat
                dense
                no-wrap
                v-ripple
                color="secondary"
                no-caps
                :label="
                  'Selecionar todas as ' + pagingDespesas.count + ' despesas'
                "
                class="q-ml-sm q-px-md"
                :class="{
                  'bg-white text-blue-7': !allDespesas,
                  'bg-green-7 text-white': allDespesas
                }"
                clickable
                @click="setAllDespesas"
              />
              <q-space />

              <q-btn
                v-if="$q.screen.gt.xs"
                v-show="filtrosDrpDespesas === 'Liquidado'"
                flat
                dense
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="parseInt(filtrarPorLiquidacao) ? 'Liquidação' : 'Vencimento'"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                        >Data de</q-item-section
                      >
                    </q-item>
                    <q-item
                      :class="{ active_status: !Boolean(parseInt(filtrarPorLiquidacao)) }"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="filtrarPorLiquidacao = '0'"
                    >
                      <q-item-section>Vencimento</q-item-section>
                    </q-item>
                    <q-item
                      :class="{ active_status: Boolean(parseInt(filtrarPorLiquidacao)) }"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="filtrarPorLiquidacao = '1'"
                    >
                      <q-item-section>Liquidação</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <date-picker></date-picker>
              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="filtrosDrpDespesas"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                        >Status</q-item-section
                      >
                    </q-item>
                    <q-item
                      :class="{ active_status: filtrosDrpDespesas === key }"
                      v-for="(key, val) in statusDespesas"
                      :key="key"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="changeFilterStatusDespesas(key, val)"
                    >
                      <q-item-section
                        >{{ key }} |
                        {{ recordCountDespesas[val] }}</q-item-section
                      >
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>


              <q-btn
                v-if="hasDourasoftBank && filtrosDrpDespesas == 'Agendado' "
                flat
                dense
                no-caps
                no-wrap
                v-ripple
                color="primary"
                style="margin-right: 10px"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                :label="statusPagamento === 'Todos' ? 'Status de Pagamento' : statusPagamentoLista[statusPagamento]"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                        >Status de Pagamento</q-item-section
                      >
                    </q-item>
                    <q-item
                      v-for="(value, key) in statusPagamentoLista"
                      :key="key"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      :class="{ active_status: statusPagamento === value }"
                      @click="changeFilterStatusPagamento(value, key)"
                    >
                      <q-item-section>{{ value }}</q-item-section>
                    </q-item>
                    <q-item
                      :class="{ active_status: statusPagamento === 'Todos' }"
                      @click="changeFilterStatusPagamento('Todos', null)"
                      v-close-popup
                      clickable
                      aria-hidden="true"
                    >
                      <q-item-section>Todos</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-select
                v-model="visibleColumns"
                multiple
                borderless
                dense
                options-dense
                :display-value="'Colunas'"
                emit-value
                map-options
                :options="columns.filter(column => column.name !== 'tdFora')"
                option-value="name"
                style="min-width: 100px;"
                class="q-ml-sm"
              />

              <q-btn
                v-if="hasVideo"
                dense
                flat
                color="bg-primary text-blue-7"
                icon="ondemand_video"
                class="q-ml-md"
                @click="modalIntro"
              >
                <template v-slot:default>
                  <q-tooltip>Introdução as Despesas</q-tooltip>
                </template>
              </q-btn>

              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                rounded
                no-wrap
                v-ripple
                class="q-ml-sm q-px-md bg-white text-black"
                no-caps
                icon="more_vert"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                        >Mais Opções</q-item-section
                      >
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="atualizarDespesas()"
                    >
                      <q-item-section>Atualizar</q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="props.toggleFullscreen"
                    >
                      <q-item-section>{{
                        props.inFullscreen ? "Minimizar" : "Maximizar Tela"
                      }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </template>
          </q-table>
          <div class="col-12 col-md-12 text-center" v-if="buscandoDes">
            <br />
            <q-spinner color="blue-7" size="3em" />
          </div>
          <q-page-sticky position="top-right" :offset="[0, 90]">
            <q-btn
              :color="OpenOpcoesAvancadasDespesas ? 'red-7' : 'blue-7'"
              :icon="OpenOpcoesAvancadasDespesas ? 'close' : 'settings'"
              class="rotate-0"
              style="border-top-left-radius: 50px;border-bottom-left-radius: 50px"
              @click="
                OpenOpcoesAvancadasDespesas = !OpenOpcoesAvancadasDespesas
              "
            >
              <q-tooltip
                :content-class="
                  OpenOpcoesAvancadasDespesas
                    ? 'bg-negative text-white text-center'
                    : 'bg-secondary text-white text-center'
                "
                >{{
                  OpenOpcoesAvancadasDespesas
                    ? "Fechar Opções Avançadas"
                    : "Opções Avançadas"
                }}</q-tooltip
              >
            </q-btn>
          </q-page-sticky>
          <q-drawer
            side="right"
            v-model="OpenOpcoesAvancadasDespesas"
            bordered
            :width="280"
            :breakpoint="500"
            behavior="default"
            content-class="bg-white"
          >
            <q-scroll-area class="fit">
              <div class="q-pa-sm">
                <div class="q-table__title">Opções Avançadas</div>
                <div>
                  <q-list
                    class="rounded-borders"
                    style="max-width: 350px; margin-left: -10px;width: 300px;"
                  >
                    <q-item-label header></q-item-label>

                    <q-item clickable v-ripple @click.exact="$router.push('/despesas-recorrentes')">
                      <q-item-section>
                        <q-item-label lines="1">Despesas Recorrentes</q-item-label>
                        <q-item-label caption>Despesas recorrentes</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item v-if="hasDourasoftBank" clickable v-ripple @click.exact="paymentStatus()">
                      <q-item-section>
                        <q-item-label lines="1">Marcar como autorizada</q-item-label>
                        <q-item-label caption>Na data de liquidação, realiza o pagamento no banco</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item
                      clickable
                      v-ripple
                      :disable="selectedDespesasRecorrencias.length === 0"
                      @click.exact="gerarDespesasRecorrencias()"
                    >
                      <q-item-section>
                        <q-item-label lines="1">Gerar Despesas Recorrentes</q-item-label>
                        <q-item-label caption>Gerar despesas recorrentes</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="$router.push('/comissoes')">
                      <q-item-section>
                        <q-item-label lines="1">Comissões</q-item-label>
                        <q-item-label caption>Comissionamento de funcionários e fornecedores</q-item-label>
                      </q-item-section>
                    </q-item>

                    <!-- <q-item clickable v-ripple @click.exact="despesasMassa">
                      <q-item-section>
                        <q-item-label lines="1">Inclusão em Massa</q-item-label>
                        <q-item-label caption>Lorem Ipsum Dolor Sit Amet</q-item-label>
                      </q-item-section>
                    </q-item>-->

                    <q-item clickable v-ripple @click.exact="lancamentosMassa()">
                      <q-item-section>
                        <q-item-label lines="1"
                          >Alterar Conta Bancária em Massa</q-item-label
                        >
                        <q-item-label caption
                          >Alterar conta bancária cadastrada na
                          despesa</q-item-label
                        >
                      </q-item-section>
                    </q-item>

                    <q-item
                      clickable
                      v-ripple
                      @click.exact="planoDeContasMassa"
                    >
                      <q-item-section>
                        <q-item-label lines="1"
                          >Alterar Plano de Contas em Massa</q-item-label
                        >
                        <q-item-label caption
                          >Alterar plano de contas da despesa</q-item-label
                        >
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="alterarVencimento">
                      <q-item-section>
                        <q-item-label lines="1"
                          >Alterar Vencimento em Massa</q-item-label
                        >
                        <q-item-label caption
                          >Alteração do vencimento das despesas em
                          massa</q-item-label
                        >
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="alterarReferencia">
                      <q-item-section>
                        <q-item-label lines="1">
                          Alterar Referência em Massa
                        </q-item-label>
                        <q-item-label caption>
                          Alteração da referência das despesas em massa
                        </q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="cancelarMassa">
                      <q-item-section>
                        <q-item-label lines="1">Cancelar em Massa</q-item-label>
                        <q-item-label caption
                          >Cancelamento das despesas em massa</q-item-label
                        >
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="liquidarMassa">
                      <q-item-section>
                        <q-item-label lines="1">Liquidar em Massa</q-item-label>
                        <q-item-label caption>Liquidar em massa as despesas selecionadas</q-item-label>
                      </q-item-section>
                    </q-item>

                     <q-item clickable v-ripple @click.exact="openModalOptions">
                      <q-item-section>
                        <q-item-label lines="1">Opções de Despesas</q-item-label>
                        <q-item-label caption>Opções de Despesas Recorrentes</q-item-label>
                      </q-item-section>
                    </q-item>

                  </q-list>
                </div>
              </div>
            </q-scroll-area>
          </q-drawer>
        </div>
      </div>
    </div>

    <modal-alterar-plano-contas />
    <modal-alterar-conta-bancaria />
    <modal-alterar-vencimento />
    <modal-alterar-referencia />
    <modal-cancelar-massa />
    <modal-liquidar-movconta tipo-conta="despesa" />
    <modal-editar-movconta tipo-conta="despesa" />
    <modal-detalhar-movconta />
    <modal-liquidar-massa />

    <q-dialog v-model="modalOptions" persistent>
      <q-card style="max-width: 600px; width: 90%;">
        <q-card-section class="row items-center">
          <div class="text-h6">Opções</div>
          <q-space />
          <q-btn
            flat
            round
            dense
            icon="close"
            v-close-popup
            @click="closeModalOptions()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-lg">

            <div class="col-12">
              <q-checkbox
                label="Converter automaticamente as 'Despesas Recorrentes' em 'Despesas' no primeiro dia de cada mês."
                v-model="options.despesas_recorrencias_gerar_automatico"
                true-value="1"
                false-value="0"
                color="green"
              />
            </div>

          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            label="Salvar"
            clickable
            v-ripple
            @click="salvarOpcoes()"
            :loading="salvandoOpcoes"
            color="green"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <card-total-itens
      tipo="despesas"
      :total="totalDespesasCard"
      :selecionado="totalSelecionadoCard"
    />

  </q-page>
</template>

<script>
import { mapGetters } from "vuex";
import axios from '@/axios-auth';
import moment from 'moment';
import { Loading } from "quasar";

export default {
  name: "despesas",
  meta: {
    title: "DSB | Despesas"
  },
  data() {
    return {
      OpenOpcoesAvancadasDespesas: false,
      visibleColumns: JSON.parse(localStorage.getItem("despesas__visibleColumns")) || [
        "cadastro",
        "id",
        "descricao",
        "forma_pagamento",
        "vencimento",
        "referencia",
        "valor"
      ],
      columns: [
        {
          name: "id",
          label: "ID",
          align: "center",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "cadastro",
          required: true,
          align: "center",
          label: "Cadastro",
          field: row => (row.cadastro ? row.cadastro.nome : null),
          sortable: true
        },
        {
          name: "descricao",
          required: true,
          align: "center",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true
        },
        {
          name: "forma_pagamento",
          align: "center",
          label: "Forma de Pagamento",
          field: row => row.gateway.titulo,
          sortable: true
        },
        {
          name: "vencimento",
          align: "center",
          label: "Vencimento",
          field: row => row.vencimento,
          sortable: true
        },
        {
          name: "referencia",
          align: "center",
          label: "Referência",
          field: row => row.referencia,
          sortable: true
        },
        {
          name: "valor",
          align: "center",
          label: "Valor",
          field: row => row.valor,
          sortable: true
        },
        {
          name: "valor_liquidado",
          align: "center",
          label: "Valor Liquidado",
          field: row => row.valor_liquidado,
          sortable: true
        },
        {
          name: "liquidacao",
          align: "center",
          label: "Liquidado em",
          field: row => row.liquidacao,
          sortable: true
        },
        { name: "tdFora" }
      ],
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      allDespesa: false,
      loadingLiquidar: false,
      selectedDespesasRecorrencias: [],
      modalOptions: false,
      options: {
        despesas_recorrencias_gerar_automatico: "0",
      },
      optionsOriginais: {
        despesas_recorrencias_gerar_automatico: "0",
      },
      salvandoOpcoes: false,
      hasVideo: false
    };
  },
  watch: {
    selectedDespesas(value) {
      if (value.length === 0) {
        this.allDespesa = false;
        this.$store.commit("selectediesDespesas", []);
      } else {
        if (value.length >= 10) {
          this.allDespesa = true;
        }
        let ids = [];
        for (let i in value) {
          if (value.indexOf(value[i]) !== -1) {
            ids.push(value[i].id);
          }
        }
        this.$store.commit("selectediesDespesas", ids);
      }
    },
    visibleColumns() {
      localStorage.setItem("despesas__visibleColumns", JSON.stringify(this.visibleColumns));
    },
  },
  methods: {
    detalhar(despesa) {
      this.$store.commit('despesaQrCode', despesa.id);
      this.$store.dispatch("detalharDespesas", despesa);
    },
    editar(despesa) {
      this.$store.dispatch("editarDespesas", JSON.parse(JSON.stringify(despesa)));
    },
    liquidar(despesa) {
      despesa = JSON.parse(JSON.stringify(despesa));

      this.loadingLiquidar = true;
      let url = `/financeiro/edit/${despesa.id}`;

      if (despesa.is_despesas_recorrencia) {
        url = `/financeiro/view-despesas-recorrencia/${despesa.despesas_recorrencia_id}`;
      }

      axios
        .get(url)
        .then(res => {
          this.loadingLiquidar = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          if (!despesa.is_despesas_recorrencia) {
            despesa = res.data.movconta;
          } else {
            const despesasRecorrencia = res.data.despesas_recorrencia;

            despesa.vencimento_original = moment(despesa.vencimento, "YYYY/MM/DD").format("DD/MM/YYYY");
            despesa.planodeconta = despesasRecorrencia.planodeconta;
            despesa.conta = despesasRecorrencia.conta;
            despesa.gateway = despesasRecorrencia.gateway;
            despesa.cadastros_gateway = despesasRecorrencia.cadastros_gateway;
            despesa.movcontas_rateios = despesasRecorrencia.despesas_recorrencias_rateios;
          }

          this.$store.dispatch("liquidarDespesas", despesa);
        })
        .catch(() => {
          this.loadingLiquidar = false;

          this.$q.notify({
            message: 'Houve um erro ao recuperar a despesa',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    estornar(despesa) {
      this.$store.dispatch("estornarDespesas", despesa);
    },
    reativa(despesa) {
      this.$store.dispatch("reativaDespesas", despesa);
    },
    excluir(despesa) {
    this.$store.dispatch("excluirDespesas", despesa);
    },
    changeFilterStatusDespesas(status, valor) {
      // limpar checkbox selecionados
      this.$store.dispatch("clearCheckbox");

      this.$store.dispatch("changeFilterStatusDespesas", {
        status: status,
        valor: valor
      });
      switch (status) {
        case "Liquidado":
          if (this.visibleColumns.indexOf("valor_liquidado") == -1) {
            this.visibleColumns.push("valor_liquidado");
          }
          if (this.visibleColumns.indexOf("liquidacao") == -1) {
            this.visibleColumns.push("liquidacao");
          }
          break;
        case "Aberto":
          if (this.visibleColumns.indexOf("valor_liquidado") >= 0) {
            this.visibleColumns.splice(
              this.visibleColumns.indexOf("valor_liquidado"),
              1
            );
          }
          if (this.visibleColumns.indexOf("liquidacao") >= 0) {
            this.visibleColumns.splice(
              this.visibleColumns.indexOf("liquidacao"),
              1
            );
          }
          break;
        case "Cancelado":
          if (this.visibleColumns.indexOf("valor_liquidado") == -1) {
            this.visibleColumns.push("valor_liquidado");
          }
          if (this.visibleColumns.indexOf("liquidacao") == -1) {
            this.visibleColumns.push("liquidacao");
          }
      }
    },
    atualizarDespesas() {
      this.$store.dispatch("resetPagingDespesas");
      this.$store.dispatch("listarDespesas");
    },
    alterarVencimento() {
      this.$store.commit("OpenDialogAlterarVencimento", true);
    },
    alterarReferencia() {
      this.$store.commit("OpenDialogAlterarReferencia", true);
    },
    planoDeContasMassa() {
      this.$store.dispatch("planoContas", "D");
      this.$store.commit("OpenDialogAlterarPlanoDeContas", true);
    },
    lancamentosMassa() {
      this.$store.dispatch("getSelected");
      this.$store.dispatch("contas");
      this.$store.dispatch("listarCentroCustos");
      this.$store.commit("OpenDialogAlterarContaBancaria", true);
    },
    cancelarMassa() {
      this.$store.dispatch("cancelarMassa");
      this.$store.commit("OpenDialogCancelarConfirmarDespesas", true);
    },
    despesasMassa() {
      this.$store.dispatch("acaoDespesasMassa");
    },
    setAllDespesas() {
      this.$store.dispatch("setAllDespesas");
    },
    modal() {
      this.$store.commit("callDespesa", true);
      this.$store.dispatch("planoContas");
      this.$store.dispatch("contas");
      this.$store.dispatch("listarCentroCustos");
      this.$store.commit("OpenDialogNovaDespesa", true);
    },
    handleScroll() {
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.$store.dispatch("listarDespesas");
      }
    },
    liquidarMassa() {
      this.$store.dispatch("openModalLiquidarMassa", true);
    },
    resetFiltrosDespesas() {
      this.$store.commit("filtrosDespesas", {
        ...JSON.parse(JSON.stringify(this.filtrosDespesas)),
        cadastro_id: null,
        movconta_id: null,
        busca: "",
      });
    },
    gerarDespesasRecorrencias() {
      const data = {
        dtfim: this.filtrosDespesas.dtfim,
      };
      data.despesas_recorrencias = this.selectedDespesasRecorrencias
        .map(despesaRecorrencia => ({
          id: despesaRecorrencia.despesas_recorrencia_id,
          vencimento: despesaRecorrencia.vencimento,
        }));

      this.$q.dialog({
        title: 'Confirma a geração das despesas recorrentes?',
        cancel: 'Cancelar',
        persistent: true,
      })
      .onOk(() => {
        axios
          .post('/financeiro/gerar-despesas-recorrencias', data)
          .then(res => {
            this.$q.notify({
              message: res.data.message,
              color: res.data.success ? 'green' : 'orange',
              position: 'top-right',
            });

            if (!res.data.success) return;

            this.selectedDespesasRecorrencias = [];

            this.$store.dispatch("resetPagingDespesas");
            this.$store.dispatch("listarDespesas");
          })
          .catch(() => {
            this.$q.notify({
              message: 'Houve um erro ao gerar despesas recorrentes',
              color: 'red',
              position: 'top-right',
            });
          });
      });
    },
    openModalOptions() {
      this.modalOptions = true;

      axios
        .get("/financeiro/opcoes-despesas")
        .then(res => {
          if (!res.data.message) {
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right",
            });
            return;
          }

          this.optionsOriginais = res.data.options;
          this.options = res.data.options;
        })
        .catch(() => {
          this.$q.notify({
            message: "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right",
          });
        });
    },
    closeModalOptions(){
      this.modalOptions = false;
    },
    salvarOpcoes(){
      if (this.salvandoOpcoes) return;

      this.salvandoOpcoes = true;

      axios
        .post("/financeiro/opcoes-despesas", this.options)
        .then(res => {
          this.salvandoOpcoes = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? "green" : "orange",
            position: "top-right"
          });

          if (!res.data.success) {
            return;
          }

          this.options = res.data.options;

          this.closeModalOptions();
        })
        .catch(() => {
          this.salvandoOpcoes = false;
          this.$q.notify({
            message: "Houve um erro ao salvar as opções, tente novamente!",
            color: "red",
            position: "top-right"
          });
        });
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "despesas");

      if (!this.videosManuais.video_despesas) {
        this.modalIntro();
      }
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "despesas");
    },
    changeFilterStatusPagamento(status, valor) {
      const statusPagamento = status === "Todos" ? "Todos" : valor;
      this.$store.commit("statusPagamento", statusPagamento);

      this.$store.commit("filtrosDespesas", {
        ...JSON.parse(JSON.stringify(this.filtrosDespesas)),
        status_pagamento: statusPagamento,
      });

      this.$store.dispatch("resetPagingDespesas");
      this.$store.dispatch("listarDespesas");
      this.$store.dispatch("clearCheckbox");
    },
    paymentStatus() {
      this.$q.dialog({
        title: `Confirma autorização de pagamento das despesas?`,
        cancel: "Cancelar",
        persistent: true,
      })
      .onOk(() => {
        const despesas_ids = this.selectedDespesas.map(despesa => despesa.id);

        axios
          .post(`/financeiro/autorizar`, { despesas_ids })
          .then(res => {
            if (res.error) {
              this.$q.notify({
                message: res.error.response.data.message,
                color: "red",
                position: "top-right",
              });

              return;
            }

            this.$q.notify({
              message: res.data.message,
              color: res.data.success ? "green" : "orange",
              position: "top-right",
            });

            this.$store.dispatch("resetPagingDespesas");
            this.$store.dispatch("listarDespesas");
          })
          .catch(error => {
            this.$q.notify({
              message: error ?? `Houve um erro ao autorizar as despesas`,
              color: "red",
              position: "top-right",
            });
          });
      });
    },
    getBtnStatusPgmento(fatura) {
      if (!fatura) return;

      /**
       * 0 (Nenhum) - Cinza
       * 1 (Lançado) - Cinza Azul
       * 2 (Autorizado) - Verde
       * 3 (Pago) - Indigo
       * 4 (Erro) - Vermelho
       */

      switch (fatura.status_pagamento) {
        case "0":
          return "blue-grey-3";
        case "1":
          return "blue-grey-7";
        case "2":
          return "green-70";
        case "3":
          return "indigo-10";
        case "4":
          return "red-7";
      }
    },
    statusPagamentoMsg(despesa) {
      if (!despesa) return;

      switch (despesa.status_pagamento) {
        case "0":
          return "Nenhum";
        case "1":
          return "Lançado";
        case "2":
          return "Autorizado";
        case "3":
          return "Pago";
        case "4":
          return "Erro" + " - " + despesa.error_pagamento;
      }
    },
    retornarPgmentoStatus(despesa) {
      if (!despesa || !despesa.fatura) return;
      this.$q.dialog({
        title: "Cancelar o agendamento?",
        cancel: "Cancelar",
        persistent: true,
      })
      .onOk(() => {
        axios
          .post("/financeiro/desfazer-agendamento/" + despesa.id )
          .then(res => {
            if (res.error) {
              const errorMessage = res.error.response.data.message || res.data.message;
              this.$q.notify({
                message: errorMessage,
                color: "red",
                position: "top-right",
              });

              return;
            }

            this.$q.notify({
              message: res.data.message,
              color: res.data.success ? "green" : "orange",
              position: "top-right",
            });

            this.$store.dispatch("resetPagingDespesas");
            this.$store.dispatch("listarDespesas");
          });
      });
    },
    realizarPagamento(despesa) {
      if (!despesa || !despesa.fatura) return;

      this.$q.dialog({
        title: `Pagar a despesa <b>${despesa.descricao}</b> agora?`,
        cancel: "Cancelar",
        persistent: true,
        html: true
      })
        .onOk(() => {
          Loading.show();

          axios
            .post("/financeiro/realizar-pagamento/" + despesa.id)
            .then(res => {
              Loading.hide();

              if (res.error) {
                const errorMessage = res.error.response.data.message || res.data.message;
                this.$q.notify({
                  message: errorMessage,
                  color: "red",
                  position: "top-right",
                });

                return;
              }

              this.$q.notify({
                message: res.data.message,
                color: res.data.success ? "green" : "orange",
                position: "top-right",
              });

              this.$store.dispatch("resetPagingDespesas");
              this.$store.dispatch("listarDespesas");
            });
        });
    }
  },
  computed: {
    ...mapGetters([
      "selectedDespesas",
      "despesas",
      "despesaQrCode",
      "pagingDespesas",
      "filtrosDespesas",
      "recordCountDespesas",
      "totalDespesas",
      "filtrosDrpDespesas",
      "statusDespesas",
      "buscandoDes",
      "dataDespesas",
      "pulseCountDespesas",
      "pulseRapidoDespesas",
      "marcadoDespesas",
      "marquinhosDespesas",
      "mTodosDespesas",
      "counterDespesas",
      "allDespesas",
      "aguardeEmailDes",
      "isPermissoDespesas",
      "centroCustos",
      "metadadosDespesas",
      "buscarPorLiquidacaoDespesas",
      "videosManuais",
      "manuaisDisponiveis",
      "statusPagamentoLista",
      "statusPagamento",
      "hasDourasoftBank"
    ]),
    selectedDespesas: {
      get() {
        return this.$store.getters.selectedDespesas;
      },
      set(value) {
        const selectedDespesas = value.filter(despesa => !despesa.is_despesas_recorrencia);
        this.$store.commit("selectedDespesas", selectedDespesas);
      }
    },
    totalDespesasCard() {
      const total = {
        valor: this.metadadosDespesas.valor_total || 0,
        quantidade: this.pagingDespesas.count || 0,
      };

      return total;
    },
    totalSelecionadoCard() {
      const selecionado = {
        valor: 0.00,
        quantidade: 0,
      };

      if (this.allDespesas) {
        selecionado.valor = this.metadadosDespesas.valor_total || 0;
        selecionado.quantidade = this.pagingDespesas.count || 0;

        return selecionado;
      }

      const campoValor = this.filtrosDrp === "Liquidado" ? "valor_liquidado" : "valor";

      selecionado.valor = this.selectedDespesas.reduce((total, despesa) => total + despesa[campoValor], 0.00);
      selecionado.quantidade = this.selectedDespesas.length;

      return selecionado;
    },
    filtrarPorLiquidacao: {
      get() {
        return String(this.buscarPorLiquidacaoDespesas);
      },
      set(value) {
        this.$store.commit("buscarPorLiquidacaoDespesas", value);
        this.atualizarDespesas();
      },
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.resetFiltrosDespesas();
    this.verificarPrimeiroVideo();
    this.$store.dispatch("resetPagingDespesas");
    this.$store.dispatch("listarDespesas");
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  }
};
</script>

<style lang="stylus"></style>
