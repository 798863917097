<template>
  <div id="servicosParametros">
    <div v-if="canImportServicos" style="text-align: center">
      <q-icon size="10em" name="info_outline" color="grey" />
      <br />
      <p>
        Não foi encontrado nenhum serviço. Deseja importar os Serviços padrões?
      </p>
      <br />
      <q-btn
        no-wrap
        v-ripple
        label="Importar Serviços"
        no-caps
        class="q-ml-sm q-px-md bg-positive text-white"
        clickable
        :loading="saveServico"
        @click="importaPadrao()"
      >
        <template v-slot:loading>
          <q-spinner color="grey-10" size="1em" />
        </template>
      </q-btn>
    </div>
    <q-table
      v-if="!canImportServicos"
      :data="produtosServicos"
      :columns="columns"
      row-key="id"
      :separator="separator"
      :loading="loadingServicos"
      no-data-label="Nenhum resultado encontrado."
     :pagination.sync="pagination"
    >
      <template v-slot:bottom>
        <div></div>
      </template>
      <template v-slot:top="props">
        <q-space />
        <q-btn
          v-if="$q.screen.gt.xs"
          dense
          flat
          no-wrap
          v-ripple
          color="primary"
          no-caps
          :label="
            !filtrosServicos.ativo
              ? 'Todos'
              : filtrosServicos.ativo === '1'
              ? 'Ativos'
              : 'Inativos'
          "
          class="q-ml-sm q-px-md bg-white text-blue-7"
        >
          <q-menu anchor="top right" self="top right">
            <q-list class="text-grey-8" style="min-width: 100px">
              <q-item aria-hidden="true">
                <q-item-section
                  class="text-uppercase text-grey-7"
                  style="font-size: 0.7rem"
                  >Tipo de Serviço</q-item-section
                >
              </q-item>
              <q-item
                :class="{ active_status: tipo === menu.label }"
                v-for="menu in filtroAtivo"
                :key="menu.text"
                clickable
                v-close-popup
                aria-hidden="true"
                @click="filtrosAtivo(menu.value)"
              >
                <q-item-section>{{ menu.label }}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <q-btn
          v-if="$q.screen.gt.xs"
          dense
          flat
          no-wrap
          v-ripple
          color="primary"
          no-caps
          label="Categoria"
          class="q-ml-sm q-px-md bg-white text-blue-7"
        >
          <q-menu anchor="top right" self="top right">
            <q-list class="text-grey-8" style="min-width: 100px">
              <q-item aria-hidden="true">
                <q-item-section
                  class="text-uppercase text-grey-7"
                  style="font-size: 0.7rem"
                  >Tipo de Categoria</q-item-section
                >
              </q-item>
              <q-item
                :class="{
                  active_status:
                    parseInt(filtrosServicos.categoria_id) === menu.id
                }"
                v-for="menu in categoriasServicosDisp"
                :key="menu.text"
                clickable
                v-close-popup
                aria-hidden="true"
                @click="filtrosCategorias(menu.id)"
              >
                <q-item-section>{{ menu.titulo }}</q-item-section>
              </q-item>
              <q-item
                :class="{ active_status: !filtrosServicos.categoria_id }"
                clickable
                v-close-popup
                aria-hidden="true"
                @click="filtrosCategorias('T')"
              >
                <q-item-section>Todos</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <div>
          Exibindo
          <strong>{{ qtdsSrevicos() }}</strong> itens.
        </div>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
          <q-td key="id" class="text-right">{{ props.row.id }}</q-td>
          <q-td key="ativo" class="text-center">
            <q-icon
              :name="props.row.ativo ? 'check' : 'close'"
              size="24px"
              :class="props.row.ativo ? 'text-positive' : 'text-negative'"
            ></q-icon>
          </q-td>
          <q-td key="nome">
            <a class="cursor-pointer" @click="modalServicos(props.row)">
              {{ props.row.nome }} <br>

              <q-badge
                class="q-mr-xs"
                outline
                color="primary"
                v-for="tag in props.row.tags_produtos"
                :key="tag.id"
              >
                  {{ tag.title }}
              </q-badge>
            </a>
          </q-td>
          <q-td key="categoria">{{ props.row.categoria ? props.row.categoria.titulo : 'Sem Categoria' }}</q-td>

          <q-td key="vlrcusto" class="text-right">
            R$ {{ props.row.vlrcusto|currency }}</q-td>
          <q-td key="vlrvenda" class="text-right">
            R$ {{ props.row.vlrvenda|currency }}</q-td>
          <q-td key="vlrcomissao" class="text-right">
            {{ props.row.tpcomissao === '$' ? 'R$' : '' }} {{ props.row.vlrcomissao|currency }}{{props.row.tpcomissao !== '$' ? '%' : ''}}
          </q-td>
          <q-td key="modified" class="text-center">{{
            props.row.modified | formatDateTime
          }}</q-td>
          <q-td v-if="$q.platform.is.desktop" class="tdfora">
            <div class="divfora">
              <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                <q-btn
                  @click="modalProdutosTags(props.row)"
                  size="12px"
                  class="bg-info text-white"
                  icon="fa fa-tags"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
                <q-btn
                  @click="clonarServico(props.row.id)"
                  size="12px"
                  class="bg-positive text-white"
                  icon="fa fa-copy"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
                <q-btn
                  @click="modalServicos(props.row)"
                  size="12px"
                  class="bg-primary text-white"
                  icon="edit"
                  :loading="props.row.loading"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
                <q-btn
                  @click="deleteItemServicos(props.row)"
                  size="12px"
                  class="bg-negative text-white"
                  icon="delete_forever"
                ></q-btn>
              </div>
            </div>
          </q-td>
        </q-tr>
        <q-tr v-show="props.expand" :props="props">
          <q-td colspan="100%">
            <div class="q-py-sm q-gutter-sm">
              <q-btn
                @click="modalProdutosTags(props.row)"
                size="12px"
                class="bg-info text-white"
                icon="fa fa-tags"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
              <q-btn
                @click="clonarServico(props.row.id)"
                size="12px"
                class="bg-positive text-white"
                icon="fa fa-copy"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
              <q-btn
                @click="modalServicos(props.row)"
                size="12px"
                class="bg-primary text-white"
                icon="edit"
                :loading="props.row.loading"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
              <q-btn
                @click="deleteItemServicos(props.row)"
                size="12px"
                class="bg-negative text-white"
                icon="delete_forever"
              />
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-btn
      v-if="!canImportServicos"
      @click="proxPagina()"
      :label="
        'Carregar mais ' + pagingServicos.page + '/' + pagingServicos.pageCount
      "
      class="absolute-bottom-right text-white bg-primary"
      style="margin: 0 25px 15px 0"
      :disable="
        pagingServicos.page >= pagingServicos.pageCount || loadingServicos
      "
      :loading="loadingServicos"
    >
      <template v-slot:loading>
        <q-spinner color="white" size="1em" />
      </template>
    </q-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "servicos-parametros",
  data() {
    return {
      separator: "horizontal",
      pagination: {
        rowsPerPage: 0
      },
      columns:
      [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "ativo",
          required: true,
          label: "Ativo",
          align: "center",
          field: row => row.ativo,
          sortable: true
        },
        {
          name: "nome",
          align: "left",
          label: "Nome",
          field: row => row.nome,
          sortable: true
        },
        {
          name: "categoria",
          label: "Categoria",
          field: row => row.categoria.titulo,
          sortable: true,
          align: "left"
        },
        {
          name: "vlrcusto",
          label: "Valor de custo",
          field: row => row.vlrcusto,
          sortable: true,
          align: "center"
        },
        {
          name: "vlrvenda",
          label: "Valor de venda",
          field: row => row.vlrvenda,
          sortable: true,
          align: "center"
        },
        {
          name: "vlrcomissao",
          label: "Lucro",
          field: row => row.vlrcomissao,
          sortable: true,
          align: "center"
        },
        {
          name: "modified",
          label: "Modificado",
          field: row => row.modified,
          sortable: true,
          align: "center"
        },
        {
          name: "tdfora",
          label: "",
        },
      ],
      filtroAtivo: [
        {
          label: "Ativos",
          value: "A"
        },
        {
          label: "Inativos",
          value: "I"
        },
        {
          label: "Todos",
          value: "T"
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "produtosServicos",
      "pagingServicos",
      "loadingServicos",
      "totalServicos",
      "saveServico",
      "filtrosServicos",
      "categoriasServicosDisp",
      "canImportServicos"
    ]),
    tipo() {
      return !this.filtrosServicos.ativo
        ? "Todos"
        : this.filtrosServicos.ativo === "1"
        ? "Ativos"
        : "Inativos";
    }
  },
  mounted() {
    this.$store.dispatch("filtrosServicos");
  },
  methods: {
    filtrosCategorias(categoria) {
      this.$store.commit("filtrosServicos", {
        tipo: "S",
        page: 1,
        ativo: this.filtrosServicos.ativo,
        categoria_id: categoria === "T" ? null : categoria
      });
      this.$store.dispatch("resetPagingServicos");
      this.$store.commit("produtosServicos",[]);
      this.$store.dispatch("listarServicos");
    },
    filtrosAtivo(tipo) {
      this.$store.commit("filtrosServicos", {
        tipo: "S",
        page: 1,
        ativo: tipo === "A" ? 1 : tipo === "I" ? 0 : null,
        categoria_id: this.filtrosServicos.categoria_id
      });
      this.$store.dispatch("resetPagingServicos");
      this.$store.commit("produtosServicos",[]);
      this.$store.dispatch("listarServicos");
    },
    proxPagina() {
      if (!this.pagingServicos.nextPage) return;
      this.$store.commit("filtrosServicos", {
        tipo: "S",
        page: this.pagingServicos.page + 1,
        ativo: this.filtrosServicos.ativo
      });
      this.$store.dispatch("listarServicos");
    },
    deleteItemServicos(servico) {
      this.$store.dispatch("deleteItemServicos", servico);
    },
    modalServicos(servico) {
      if (servico.loading) return;
      servico.loading = true;
      this.$store.dispatch("modalServicos", servico);
    },
    importaPadrao() {
      let padrao = {
        importar_produtos: true
      };
      this.$store.dispatch("addServico", padrao);
    },
    qtdsSrevicos() {
      return this.produtosServicos.length + " de " + this.pagingServicos.count;
    },
    clonarServico(id) {
      this.$q.dialog({
        title: "Clonar serviço",
        message: "Deseja realmente clonar este serviço?",
        cancel: "Cancelar",
        persistent: true
      })
      .onOk(() => {
        this.$store.dispatch("setaNovoServico", true);
        this.$store.dispatch("addServico", {
          abrirServico: true,
          clonar_produto: id,
        });
      });
    },
    modalProdutosTags(produto) {
      if (produto.loading) return;
      produto.loading = true;
      this.$store.dispatch("modalProdutosTags", produto);
    }
  }
};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
