import { render, staticRenderFns } from "./servicos-parametros.vue?vue&type=template&id=ccc1a8a8&scoped=true&"
import script from "./servicos-parametros.vue?vue&type=script&lang=js&"
export * from "./servicos-parametros.vue?vue&type=script&lang=js&"
import style0 from "./servicos-parametros.vue?vue&type=style&index=0&id=ccc1a8a8&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccc1a8a8",
  null
  
)

export default component.exports