import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";

const state = {
  categorias: [],
  categoria: {
    titulo: "",
    ordem: null,
    estorno_id: null,
    ativo: true
  },
  loadingCategorias: false,
  isNewCategoria: true,
  saveCategoria: false,
  errorsCategorias: [],
  OpenDialogNovaCategoria: false
};

const mutations = {
  categorias: (state, userData) => {
    state.categorias = userData;
  },
  categoria: (state, userData) => {
    state.categoria = userData;
  },
  loadingCategorias: state => {
    state.loadingCategorias = !state.loadingCategorias;
  },
  isNewCategoria: (state, userData) => {
    state.isNewCategoria = userData;
  },
  saveCategoria: state => {
    state.saveCategoria = !state.saveCategoria;
  },
  errorsCategorias: (state, userData) => {
    state.errorsCategorias = userData;
  },
  OpenDialogNovaCategoria: (state, userData) => {
    state.OpenDialogNovaCategoria = userData;
  }
};

const actions = {
  listarCategorias: ({ commit, state }) => {
    if (state.loadingCategorias) return;
    commit("loadingCategorias");
    axios
      .get("/categorias")
      .then(res => {
        if (res.data.success) {
          commit("categorias", res.data.categorias);
        }
        commit("loadingCategorias");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadingCategorias");
      });
  },
  modalCategoria: ({ commit }, userData) => {
    commit("categoria", userData);
    commit("isNewCategoria", false);
    commit("OpenDialogNovaCategoria", true);
  },
  deleteCategoria: ({ commit, dispatch }, userData) => {
    Dialog.create({
      title: userData.titulo,
      message: "Deseja realmente excluir esta categoria?",
      html: true,
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/categorias/delete/" + userData.id)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              commit("categorias", []);
              dispatch("listarCategorias");
            } else {

              let texto = "";

              if (res.data.produtos) {

                texto += "<ul>";

                for (let i = 0; i < 5; i++) {
                  if (!res.data.produtos[i]) continue;

                  texto+= `<li>${res.data.produtos[i].nome}</li>`;
                }
                if (res.data.produtos.length > 5) {
                  texto+= `<li>E mais outros ${res.data.produtos.length - 5}</li>`;
                }
                texto += "</ul>";
              }

              Dialog.create({
                title: res.data.message,
                message: texto,
                html: true,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi possível contactar, ou você não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  addCategoria: ({ commit, dispatch, state }, userData) => {
    commit("saveCategoria");
    let url = state.isNewCategoria
      ? "/categorias/add"
      : "/categorias/edit/" + userData.id;
    axios
      .post(url, userData)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          commit("categorias", []);
          commit("categoria", {
            titulo: "",
            ordem: null,
            estorno_id: null,
            ativo: true
          });
          commit("errorsCategorias", []);
          dispatch("listarCategorias");
          if (userData.importar_produtos) {
            dispatch("filtrosProdutos");
            dispatch("filtrosServicos");
            dispatch("filtrosLoterias");
          }
          commit("OpenDialogNovaCategoria", false);
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });

          commit("errorsCategorias", res.data.errors);
        }
        commit("saveCategoria");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("saveCategoria");
      });
  }
};

const getters = {
  categorias: state => {
    return state.categorias;
  },
  categoria: state => {
    return state.categoria;
  },
  loadingCategorias: state => {
    return state.loadingCategorias;
  },
  isNewCategoria: state => {
    return state.isNewCategoria;
  },
  saveCategoria: state => {
    return state.saveCategoria;
  },
  errorsCategorias: state => {
    return state.errorsCategorias;
  },
  OpenDialogNovaCategoria: state => {
    return state.OpenDialogNovaCategoria;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
