<template>
  <div id="modalLiquidarMassa">
    <q-dialog v-model="OpenDialogLiquidarMassa" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">{{ title }}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />

        <q-card-section>
          <q-list>
            <q-item>
              <q-item-section>
                <q-item-label>{{ quantidade }} itens selecionados</q-item-label>
                <q-item-label caption>Quantidade</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>R$ {{valorTotal | currency}}</q-item-label>
                <q-item-label caption>Valor</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-section>
           <div class="col-12 col-md-12">
            <q-select
              v-model="conta_id"
              option-label="titulo"
              option-value="id"
              :options="contas"
              label="Conta Bancária: "
              clearable
              emit-value
              map-options
              id="contaDespesa"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey"
                    >Sem Resultados</q-item-section
                  >
                </q-item>
              </template>
            </q-select>
          </div>
          <div>
            <q-input
              bottom-slots
              :error="errors.liquidacao ? true : false"
              v-model="liquidacao"
              label="Data de Liquidação"
              @click="$refs.qDateProxy.show();"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="liquidacao"
                      @input="() => $refs.qDateProxy.hide()"
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      minimal
                      today-btn
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span v-for="(error, i) in errors.liquidacao" :key="error + i">{{error}}</span>
              </template>
            </q-input>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveLiquidar"
            :loading="loadLiquidar"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "@/axios-auth";

export default {
  name: "modal-liquidar-movconta",
  data(){
    return {
      quantidade: 0,
      valorTotal: 0,
      liquidacao: moment().format("DD/MM/YYYY"),
      errors: {},
      loadLiquidar: false,
      conta_id: null
    };
  },
  methods: {
    saveLiquidar() {
      let tipo = "";
      let data = {};

      if (this.$route.path === "/despesas") {
        tipo = "despesa";

        data = {
          ids: this.selectediesDespesas,
          all: this.allDespesas,
          busca: this.filtrosDespesas.busca,
          dtini: this.filtrosDespesas.dtini,
          dtfim: this.filtrosDespesas.dtfim,
          status: this.filtrosDespesas.status,
          cadastro_id: this.filtrosDespesas.cadastro_id
        };
      } else { // Receita
        tipo = "receita";

        data = {
          ids: this.selectediesReceitas,
          all: this.allReceitas,
          busca: this.filtrosReceitas.busca,
          dtini: this.filtrosReceitas.dtini,
          dtfim: this.filtrosReceitas.dtfim,
          status: this.filtrosReceitas.status,
          cadastro_id: this.filtrosReceitas.cadastro_id,
          gateway_id: this.filtrosReceitas.gateway_id,
          status_remessa: this.filtrosReceitas.status_remessa,
        };
      }

      data.action = "liquidar-massa";
      data.confirmar = 1;
      data.liquidacao = this.liquidacao;

      if(this.conta_id > 0){
        data.conta_id = this.conta_id;
      }

      this.loadLiquidar = true;

      axios
        .post(`/financeiro/edit-multiple/${tipo}`, data)
        .then(res => {
          this.loadLiquidar = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? "green" : "orange",
            position: "top-right",
          });

          if (!res.data.success) {
            this.errors = res.data.errors || {};
            return;
          }

          if (this.$route.path === "/despesas") {
            this.$store.dispatch("resetPagingDespesas");
            this.$store.dispatch("listarDespesas");
          } else {
            this.$store.dispatch("resetPagingReceitas");
            this.$store.dispatch("listarReceitas");
          }

          this.closeModal();
        })
        .catch(() => {
          this.loadLiquidar = false;

          this.$q.notify({
            message: `Houve um erro ao liquidar as ${tipo}s`,
            color: "red",
            position: "top-right",
          });
        });
    },
    closeModal() {
      this.$store.commit("OpenDialogLiquidarMassa", false);
    },
    getMovcontasData() {
      let tipo = "";
      let data = {};

      this.$store.dispatch("contas");

      if (this.$route.path === "/despesas") {
        tipo = "despesa";

        data = {
          ids: this.selectediesDespesas,
          all: this.allDespesas,
          busca: this.filtrosDespesas.busca,
          dtini: this.filtrosDespesas.dtini,
          dtfim: this.filtrosDespesas.dtfim,
          status: this.filtrosDespesas.status,
          cadastro_id: this.filtrosDespesas.cadastro_id
        };
      } else { // Receita
        tipo = "receita";

        data = {
          ids: this.selectediesReceitas,
          all: this.allReceitas,
          busca: this.filtrosReceitas.busca,
          dtini: this.filtrosReceitas.dtini,
          dtfim: this.filtrosReceitas.dtfim,
          status: this.filtrosReceitas.status,
          cadastro_id: this.filtrosReceitas.cadastro_id,
          gateway_id: this.filtrosReceitas.gateway_id,
          status_remessa: this.filtrosReceitas.status_remessa,
        };
      }

      data.action = "liquidar-massa";

      axios
        .post(`/financeiro/edit-multiple/${tipo}`, data)
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right",
            });
            return;
          }

          this.valorTotal = Math.abs(res.data.valor_total);
          this.quantidade = res.data.quantidade;
          this.conta_id = res.data.conta_id;
        })
        .catch(() => {
          this.$q.notify({
            message: `Houve um erro ao buscar as ${tipo}s`,
            color: "red",
            position: "top-right",
          });
        });
    },
  },
  computed: {
     ...mapGetters([
      "OpenDialogLiquidarMassa",
      "selectediesReceitas",
      "selectediesDespesas",
      "contas",
      "allDespesas",
      "allReceitas",
      "filtrosReceitas",
      "filtrosDespesas",
    ]),
    title() {
      return this.$route.path === "/despesas"
        ? "Liquidar Despesa em Massa"
        : "Liquidar Receita em Massa";
    },
  },
  watch: {
    OpenDialogLiquidarMassa() {
      if (this.OpenDialogLiquidarMassa) {
        this.getMovcontasData();
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
  .q-field--with-bottom
    padding-bottom: unset;
</style>
