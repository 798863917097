<template>
  <div id="modalDetalharMovconta">
    <q-dialog v-model="OpenDialogDetalharMovconta" persistent>
      <q-card id="cardModalDetalharMovconta" style="width: 800px; max-width: 80vw;" class="q-pb-md">
        <q-card-section class="row items-center">
          <div class="text-h6" v-if="!buscandoItems">
            Detalhamento -
            <strong>{{ movimentacao.id }}</strong>
            <span class="text-grey" v-if="movimentacao.is_despesas_recorrencia">(Sugerido) </span>
          </div>
          <div class="text-h6 q-pl-sm" v-if="!buscandoItems">
            {{
              movimentacao.cadastro
                ? movimentacao.cadastro.nome
                : "Sem Cadastro"
            }}
          </div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />
        <q-card-section v-if="buscandoItems">
          <q-spinner
            color="blue-7"
            size="4em"
            style="position:relative;top: 50%;left: 40%;"
          />
        </q-card-section>

        <q-card-section v-if="!buscandoItems" class="row q-pb-none">
          <div :class="this.$q.platform.is.mobile ? 'layout-responsive' : 'col-12 col-md-6' ">
            <q-list>
              <q-item>
                <q-item-section >
                  <q-item-label class="text-left">
                    <p v-if="movimentacao.cadastro && movimentacao.cadastro.codigo">
                      Código Cadastro <b>{{ movimentacao.cadastro.codigo }}</b>
                    </p>
                    <p v-if="movimentacao.cadastro && movimentacao.cadastro.telefone">
                      Telefone(s) <b v-html="separaLinha(movimentacao.cadastro.telefone)"></b>
                    </p>
                    <p v-if="movimentacao.gateway">
                      Forma de Pagamento <b>{{ movimentacao.gateway.titulo }}

                      <q-btn
                        v-if="showVerificarPagamentoBtn"
                        flat
                        dense
                        size="sm"
                        icon="refresh"
                        :disable="btn_verificarPagamento.disable"
                        :loading="btn_verificarPagamento.loading"
                        color="primary"
                        class="align-self-center"
                        @click="verificarPagamento()"
                      >
                        <q-tooltip v-if="!btn_verificarPagamento.loading">
                          Revisar status da liquidação
                        </q-tooltip>
                      </q-btn>
                      
                      <q-btn
                        flat
                        dense
                        size="sm"
                        icon="info"
                        color="primary"
                        class="align-self-center"
                        @click="openListarRegistrosModal()"
                      >
                        <q-tooltip>
                          Ver registros
                        </q-tooltip>
                      </q-btn>

                      </b>
                    </p>
                    <p v-if="movimentacao.cadastros_gateway">
                      Código para pagamento <b>{{ movimentacao.cadastros_gateway.codigo }}</b>
                    </p>
                    <p v-if="movimentacao.cadastros_gateway">
                      Conta para pagamento <b>
                        {{movimentacao.cadastros_gateway.agencia}}
                        {{movimentacao.cadastros_gateway.operacao}}
                        {{movimentacao.cadastros_gateway.conta}}
                        Banco {{movimentacao.cadastros_gateway.banco}}</b>
                    </p>
                    <p v-if="movimentacao.planodeconta">
                      Plano de conta <b>{{ movimentacao.planodeconta.titulo }}</b>
                    </p>
                    <p v-if="movimentacao.fatura && movimentacao.tipo == 2">
                      Nosso número <b>{{ movimentacao.fatura.nosso_numero }}</b>
                    </p>
                    <p v-if="movimentacao.created">
                      Criado em <b>{{ movimentacao.created | formatDate }}</b>
                    </p>
                    <p v-if="movimentacao.liquidacao">
                      Liquidado em <b>{{ movimentacao.liquidacao | formatDate }}</b>
                    </p>
                    <p v-if="movimentacao.dtmovimento">
                      Data de Movimento <b>{{ movimentacao.dtmovimento | formatDate }}</b>
                    </p>
                    <p v-if="movimentacao.status === 'Cancelado'">
                      Cancelado em <b>{{ movimentacao.modified | formatDate }}</b>
                    </p>
                    <p v-if="movimentacao.relacionadas && movimentacao.relacionadas.length > 0">
                      <router-link
                        :to="`?movconta_id=${movimentacao.movconta_id}`"
                        style="color: inherit; text-decoration: none;"
                        target="_blank"
                      >
                        Ver relacionadas
                      </router-link>
                      <b>{{ movimentacao.relacionadas.length }} Relacionadas</b>
                    </p>
                    <p>
                      Conta <b>{{ contaMovimentacao }}</b>
                    </p>
                    <p v-if="movimentacao.fatura && movimentacao.tipo == 2">
                      Multa <b>{{ movimentacao.fatura.multa | currency }}</b>
                    </p>
                    <p v-if="movimentacao.fatura && movimentacao.tipo == 2">
                      Juros <b>{{ movimentacao.fatura.juros | currency }}</b>
                    </p>
                    <p v-if="movimentacao.fatura && movimentacao.tipo == 2">
                      Protesto <b>{{ movimentacao.fatura.protesto }}</b>
                    </p>
                    <p v-if="movimentacao.valor_acrecimo">
                      Valor de Acréscimo <b>R$ {{ movimentacao.valor_acrecimo | currency }}</b>
                    </p>
                    <p v-if="movimentacao.valor_desconto">
                      Valor de Desconto <b>R$ {{ movimentacao.valor_desconto | currency }}</b>
                    </p>
                    <p v-if="movimentacao.data_limite_desconto">
                      Data limite de desconto <b>{{ movimentacao.data_limite_desconto | formatDate }}</b>
                    </p>
                    <p v-if="movimentacao.planodeconta_item">
                      Utilizar plano de contas dos itens <b>Sim</b>
                    </p>
                    <p v-if="movimentacao.fatura && movimentacao.tipo == 1 && movimentacao.conta.tipo == 'banco-dourasoft'">
                      Status Pagamento <b>{{ getPagamentoStatus(movimentacao.fatura.status_pagamento) }}</b>
                    </p>
                    <p v-if="movimentacao.fatura && movimentacao.fatura.error_pagamento !== '' && movimentacao.fatura.status_pagamento == 4">
                      Erro Pagamento <b>{{ movimentacao.fatura.error_pagamento }}</b>
                    </p>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
          <div class="col-12 col-md-6">
            <div class="q-px-md">
              <div class="q-col-gutter-md row">
                <div
                  class="q-uploader"
                  style="width: 100%; box-shadow: none;overflow:hidden"
                  @dragleave="onDragleave"
                  @mouseover="onHover"
                  @mouseleave="offHover"
                  @dragover="onDragover"
                  @drop="onDrop"
                  >
                  <div class="q-uploader__header">
                    <div class="header-upload">
                      <a
                        v-if="arrayFile && arrayFile.length > 0"
                      >
                        <div tabindex="-1" class="q-focus-helper"></div>
                        <q-btn
                          dense
                          flat
                          icon="clear_all"
                          @click="apagaTodos()"
                        />
                      </a>
                      <div :class="!this.$q.platform.is.mobile ?  'layout' :'layout-responsive'">
                        <div  v-if="!this.$q.platform.is.mobile">
                          <div class="q-uploader__title">Anexar imagens e documentos!</div>
                        </div>
                        <div class="icones-layout">
                            <label for="file"  v-if="this.$q.platform.is.mobile">
                              <i
                                class="material-icons"
                                style="font-size: 20px; cursor: pointer; float: right"
                              >add_a_photo</i>
                              <input
                                accept="image/*"
                                capture="camera"
                                type="file"
                                id="file"
                                style="width: 0"
                                multiple
                                class="file"
                                @change="onFileSelected"
                              />
                            </label>
                            <label v-else>
                              <i
                                @click="openModal()"
                                class="material-icons"
                                style="font-size: 20px; cursor: pointer;"
                              >add_a_photo</i>
                            </label>
                            <label>
                              <i v-if="!this.$q.platform.is.mobile"
                                class="material-icons"
                                style="font-size: 20px; cursor: pointer">
                                cloud_upload
                              </i>
                              <input
                                type="file"
                                id="file"
                                style="width: 0"
                                multiple
                                class="file"
                                @change="onFileSelected"
                              />
                            </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="q-uploader__list scroll" style="border: 1px solid #ccc;">
                    <div v-for="(file, i) in arrayFile" :key="file.name" style="margin-bottom: 8px">
                      <div class="q-uploader__file relative-position">
                        <div class="q-uploader__file-header row flex-center no-wrap">
                          <i
                            v-if="file.error"
                            aria-hidden="true"
                            class="material-icons q-icon text-negative q-uploader__file-status"
                          >warning</i>
                          <div class="q-uploader__file-header-content col">
                            <div class="q-uploader__title">{{file.fileName}}</div>
                            <div
                              class="q-uploader__subtitle row items-center no-wrap"
                            >{{file.fileSize}} / {{file.progress}}</div>
                          </div>
                          <q-btn
                            round
                            dense
                            flat
                            icon="clear"
                            @click="apagaItem(i)"
                            :loading="file.uploading"
                          >
                            <template v-slot:loading>
                              <q-spinner color="grey-10" size="1em" />
                            </template>
                          </q-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <q-table
              v-if="receitaArquivos.length > 0"
              title="Anexos"
              :data="receitaArquivos"
              :columns="columnsArquivos"
              :pagination="{page: 1,
                  rowsPerPage: 5,}"
              dense
              hide-header
              row-key="id"
              >
              <template v-slot:body="props">
                <q-tr :props="props" @click="openArquivo(props.row)" style="cursor: pointer">
                    <q-td
                      key="id"
                      class="text-center"
                    >{{props.row.id}}</q-td>
                    <q-td key="nome">{{props.row.nome}}</q-td>
                </q-tr>
              </template>
            </q-table>
          </div>
        </q-card-section>

        <q-card-section v-if="movimentacao.rateios && movimentacao.rateios.length > 0" class="row q-pb-none">
          <div class="col col-md-12">
            <q-table
              title
              :data="movimentacao.rateios"
              :columns="columnsRateios"
              row-key="nome"
              :separator="separator"
              :loading="buscandoItems"
              no-data-label="Nenhum resultado encontrado."
              :pagination.sync="pagination"
              dense
              >
              <template v-slot:bottom></template>
              <template v-slot:body="props">
                <q-tr :props="props">
                  <q-td key="centrocusto" style="white-space: break-spaces;">{{ props.row.nome_centrocusto }}</q-td>
                  <q-td key="porcentagem" class="text-right">{{ props.row.porcentagem }} %</q-td>
                  <q-td key="valor" class="text-right">R$ {{ props.row.valor | currency }}</q-td>
                </q-tr>
              </template>
            </q-table>
          </div>
        </q-card-section>

        <q-card-section
          v-if="movimentacao.relacionadas && movimentacao.relacionadas.length > 0"
          class="row q-pb-none"
        >
          <div class="col col-md-12">
            <q-table
              title
              :data="movimentacao.relacionadas"
              :columns="columnsRelacionadas"
              row-key="id"
              :separator="separator"
              :loading="buscandoItems"
              no-data-label="Nenhum resultado encontrado."
              :pagination.sync="paginationRelacionadas"
              hide-bottom
              >
              <template v-slot:bottom></template>
              <template v-slot:top="props">
                <div class="col-12 q-table__title">Relacionadas</div>
              </template>
              <template v-slot:body="props">
                <q-tr :props="props">
                  <q-td key="id">{{ props.row.id }}</q-td>
                  <q-td key="status" class="text-center">{{ props.row.status }}</q-td>
                  <q-td key="descricao" style="white-space: break-spaces;">{{ props.row.descricao }}</q-td>
                  <q-td key="vencimento" class="text-center">
                    {{ new Date(props.row.vencimento) | formatDate }}
                  </q-td>
                  <q-td key="valor" class="text-right">
                    R$ {{ props.row.valor | currency }}
                  </q-td>
                  <q-td key="liquidacao" class="text-center">
                    {{ props.row.liquidacao | formatDate }}
                  </q-td>
                  <q-td key="valor_liquidado" class="text-right">
                    <span v-if="props.row.status === 'Liquidado'">
                      R$ {{ props.row.valor_liquidado | currency }}
                    </span>
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </div>
        </q-card-section>
        <q-card-section v-if="items && items.length > 0" class="row q-pb-none">
          <div class="col col-md-12">
          <q-table
            dense
            title
            row-key="nome"
            no-data-label="Nenhum resultado encontrado."
            :data="items"
            :separator="separator"
            :columns="columnsItems"
            :loading="buscandoItems"
            :pagination.sync="pagination"
            >
            <template v-slot:top="props">
              <div class="col-12 q-table__title">
                Detalhamento da Receita
                <strong>{{ movconta.id }}</strong>
              </div>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td key="id" class="text-right">{{
                  props.row.id
                }}</q-td>
                <q-td key="descricao">
                  {{ props.row.cadastro ? props.row.cadastro.nome : "" }}
                  <br v-if="props.row.cadastro" />
                  {{ props.row.descricao }}
                </q-td>
                <q-td key="planodeconta" class="text-left">
                  <span v-if="props.row.planodeconta">
                    {{ `${props.row.planodeconta.numero} ${props.row.planodeconta.titulo}`}}
                  </span>
                </q-td>
                <q-td key="valor" class="text-right"
                  >{{ props.row.valor | currency }}</q-td
                >
              </q-tr>
            </template>
            <template v-slot:bottom>
              <div class="full-width">
                <p v-if="!buscandoItems && items.length > 0" class="text-right">
                  Total
                  <strong>R$ {{ itemsTotal | currency }}</strong>
                </p>
              </div>
            </template>
          </q-table>
          </div>
        </q-card-section>

        <q-card-section v-if="isReceitas() && movPraDet.length > 0" class="row q-pb-none">
          <div class="q-px-md">
            <div class="q-col-gutter-md row">
              <div class="col col-md">
                <q-table
                  dense
                  title
                  :data="movPraDet"
                  :columns="columnsMov"
                  row-key="nome"
                  :separator="separator"
                  :loading="buscandoItems"
                  no-data-label="Nenhum resultado encontrado."
                  :pagination.sync="pagination"
                >
                  <template v-slot:bottom>
                    <div></div>
                  </template>
                  <template v-slot:top="props">
                    <div class="col-12 q-table__title">
                      Outras Receitas em Aberto de
                      <strong>{{ cadPraDet.nome }}</strong>
                    </div>
                  </template>
                  <template v-slot:body="props">
                    <q-tr :props="props">
                      <q-td key="id" class="text-right">{{
                        props.row.id
                      }}</q-td>
                      <q-td key="descricao">{{ props.row.descricao }}</q-td>
                      <q-td key="vencimento" class="text-right">{{
                        props.row.vencimento | formatDate
                      }}</q-td>
                      <q-td key="referencia" class="text-right">{{
                        props.row.referencia
                      }}</q-td>
                      <q-td key="valor" class="text-right"
                        >R$ {{ props.row.valor | currency }}</q-td
                      >
                      <q-td key="titulo">{{ props.row.titulo }}</q-td>
                    </q-tr>
                  </template>
                </q-table>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-section v-if="timeline && timeline.length > 0" class="row q-pb-none q-pt-md q-mt-none">
          <div class="q-px-md">
            <div class="q-col-gutter-md row">
              <q-timeline dense >
                <q-timeline-entry heading>Histórico
                  <q-btn
                    flat
                    round
                    icon="add"
                    color="primary"
                    style="height: 42px"
                    class="align-self-center"
                    @click="add()"
                  ></q-btn>
                </q-timeline-entry>
                <q-timeline-entry
                  v-for="time in timeline"
                  :key="time.id"
                  :title="(time.nome ? `${time.nome} ` : '') + acaoFiltroTimeline(time.tipo)"
                  :subtitle="returnSubtitle(time)"
                  :icon="getIcone(time.icone)"
                >
                  <div class="q-timeline__body" v-html="time.corpo"></div>
                </q-timeline-entry>
              </q-timeline>
              <q-btn
                v-if="timeline && timeline.length > 0"
                @click="loadMore()"
                :label="
                  'Carregar mais ' +
                    pagingTime.page +
                    '/' +
                    pagingTime.pageCount
                "
                class="absolute-bottom-right text-white bg-primary"
                style="margin: 0 25px 15px 0;padding: 8px"
                :disable="
                  btnDisabled || pagingTime.page >= pagingTime.pageCount
                "
                :loading="false"
              >
                <template v-slot:loading>
                  <q-spinner color="white" size="1em" />
                </template>
              </q-btn>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <modal-timeline />
    <modal-upload-resumo-tfl 
      ref="uploadResumoTfl"
      title="Faça login com o código QR"
      movmentacao="true" 
    />
    <modal-listar-registros
      v-if="movimentacao && movimentacao.id"
      :movconta-id="movimentacao.id"
      :fatura-id-prop="movimentacao.fatura_id" 
    />
  </div>
</template>

<script>
import moment from "moment";
import axios from "@/axios-auth";
import { Notify } from "quasar";
export default {
  name: "modal-detalhar-movconta",
  data() {
    return {
      icones: {
        table: "list",
        key: "vpn_key",
        upload: "cloud_upload",
        trash: "delete_forever",
        envelope: "mail_outline",
        plus: "add",
        pencil: "mode_edit",
        money: "monetization_on",
        print: "print",
        file: "description"
      },
      separator: "horizontal",
      pagination: {
        rowsPerPage: 0,
      },
      paginationRelacionadas: {
        sortBy: "id",
        descending: false,
        rowsPerPage: 0,
      },
      columnsItems: [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: row => row.id,
          sortable: true
        },
        {
          name: "descricao",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true,
          align: "center"
        },
        {
          name: "planodeconta",
          label: "Plano de Contas",
          field: row => row.planodeconta ? row.planodeconta.numero : null,
          sortable: true,
          align: "center"
        },
        {
          name: "valor",
          label: "Valor",
          field: row => row.valor,
          sortable: true,
          align: "center"
        }
      ],
      columnsMov: [
        {
          name: "id",
          required: true,
          label: "Cobrança",
          align: "center",
          field: row => row.id,
          sortable: true
        },
        {
          name: "descricao",
          align: "center",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true
        },
        {
          name: "vencimento",
          label: "Vencimento",
          field: row => row.vencimento,
          sortable: true,
          align: "center"
        },
        {
          name: "referencia",
          label: "Referência",
          field: row => row.referencia,
          sortable: true,
          align: "center"
        },
        {
          name: "valor",
          label: "Valor",
          field: row => row.valor,
          sortable: true,
          align: "center"
        },
        {
          name: "titulo",
          label: "Título",
          field: row => row.titulo,
          sortable: true,
          align: "center"
        }
      ],
      columnsArquivos: [
        {
          name: "id",
          label: "ID",
          align: "center",
          field: row => row.id,
          format: val => `${val}`,
          sortable: false,
        },
        {
          name: "nome",
          align: "left",
          label: "Nome",
          field: row => row.nome,
          sortable: false
        },
      ],
      columnsRateios: [
        {
          name: "centrocusto",
          label: "Centro de custo",
          field: row => row.nome_centrocusto,
          sortable: true,
          align: "center",
        },
        {
          name: "porcentagem",
          label: "Porcentagem",
          field: row => row.porcentagem,
          sortable: true,
          align: "center",
        },
        {
          name: "valor",
          label: "Valor",
          field: row => row.valor,
          sortable: true,
          align: "center",
        },
      ],
      columnsRelacionadas: [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: row => row.id,
          sortable: true,
        },
        {
          name: "status",
          label: "Status",
          field: row => row.status,
          sortable: true,
          align: "center",
        },
        {
          name: "descricao",
          align: "center",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true,
        },
        {
          name: "vencimento",
          label: "Vencimento",
          field: row => row.vencimento,
          sortable: true,
          align: "center",
        },
        {
          name: "valor",
          label: "Valor",
          field: row => row.valor,
          sortable: true,
          align: "center",
        },
        {
          name: "liquidacao",
          label: "Liquidação",
          field: row => row.liquidacao,
          sortable: true,
          align: "center",
        },
        {
          name: "valor_liquidado",
          label: "Valor Liquidado",
          field: row => row.valor_liquidado,
          sortable: true,
          align: "center",
        },
      ],
      arrayFile: [],
      selectedFile: null,
      edit: false,
      editor: {
        corpo:"",
        movconta_id: "",
        cadastro_id: "",

      },
      erroEditor: false,
      btn_verificarPagamento: {
        disable: false,
        loading: false
      },
    };
  },
  methods: {
    isReceitas() {
      return this.$route.path === "/receitas";
    },
    closeModal() {
      this.$store.commit('receitaQrcode', null);
      this.$store.commit('despesaQrCode', null);
      this.$store.commit("cadastroDespesa", {
        isCadastro: false,
        cadastro: {}
      });
      this.$store.commit("OpenDialogDetalharMovconta", false);
      this.arrayFile = [];
    },
    acaoFiltroTimeline(value) {
      if (this.timelineAction[value] !== undefined) {
        return this.timelineAction[value].label;
      }
    },
    separaLinha(value) {
      if (!value) return "";
      return value
        .replace(/,/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
        .replace("/", "&nbsp;&nbsp;&nbsp;&nbsp;");
    },
    getIcone(value) {
      return this.icones[value];
    },
    getUrlBoleto(shortUrl) {
      return this.urlGeral + shortUrl;
    },
    mostraCobranca(value, gateway) {
      if (value.status_remessa === 0) {
        return gateway.titulo + " - Sem Registro";
      } else if (value.status_remessa === 1) {
        return gateway.titulo + " - Marcado para Remessa";
      } else if (value.status_remessa === 2) {
        return gateway.titulo + " - Remessa Gerada";
      } else if (value.status_remessa === 3) {
        return gateway.titulo + " - Registrado";
      } else if (value.status_remessa === 4) {
        return gateway.titulo + " - " + value.status_retorno;
      } else if (value.status_remessa === 5) {
        return gateway.titulo + " - " + value.status_retorno;
      } else if (value.status_remessa === 6) {
        return gateway.titulo + " - " + value.status_retorno;
      } else if (value.status_remessa === 7) {
        return gateway.titulo + " - " + value.status_retorno;
      } else if (value.status_remessa === 8) {
        return gateway.titulo + " - " + value.status_retorno;
      }
    },
    returnSubtitle(timeline) {
      return (
        "em " +
        moment(timeline.created).format("DD/MM/YYYY") +
        " às " +
        moment(timeline.created).format("HH:mm") +
        "h"
      );
    },
    offHover() {
      event.preventDefault();
    },
    onHover() {
      event.preventDefault();
    },
    onDragleave() {
      event.preventDefault();
    },
    onDragover() {
      event.preventDefault();
    },
    apagaTodos() {
      this.arrayFile = [];
    },
    apagaItem(i) {
      for (let i = 0; i < this.arrayFile.length; i++) {
        if (this.arrayFile[i].uploading) {
          return;
        }
      }
      this.arrayFile.splice(i, 1);
    },
    onDrop(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.arrayFile.length >= 1) {
        this.arrayFile.pop();
      } // mantem somente uma notificação de upload de arquivo
      var files = event.dataTransfer.files;
      this.selectedFile = files;
      let tam = this.arrayFile.length;
      let j;
      // neste componente somente é aceito upload de 1 arquivo por vez, por isso o tamanho de selected file sempre será 1, mas este bloco será mantido caso seja necessário no futuro
      for (let i = 0; i < this.selectedFile.length; i++) {
        this.arrayFile.push({
          fileName: this.selectedFile[i].name,
          fileSize: this.bytesToSize(this.selectedFile[i].size),
          success: false,
          error: false,
          message: "",
          progress: "0%",
          uploading: false
        });
        j = tam + i;
        this.onUpload(this.selectedFile[i], this.arrayFile[j]);
      }
    },
    onFileSelected(event) {
      // this.selectedFile = []
      if (this.arrayFile.length >= 1) {
        this.arrayFile.pop();
      } // mantem somente uma notificação de upload de arquivo
      this.selectedFile = event.target.files;
      let tam = this.arrayFile.length;
      let j;
      // neste componente somente é aceito upload de 1 arquivo por vez, por isso o tamanho de selected file sempre será 1, mas este bloco será mantido caso seja necessário no futuro
      for (let i = 0; i < this.selectedFile.length; i++) {
        this.arrayFile.push({
          fileName: this.selectedFile[i].name,
          fileSize: this.bytesToSize(this.selectedFile[i].size),
          success: false,
          error: false,
          message: "",
          progress: "0%",
          uploading: false,
          delivered: false
        });
        j = tam + i;
        this.onUpload(this.selectedFile[i], this.arrayFile[j]);
      }
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    onUpload(arq, arr) {
      arr.uploading = true;
      const fd = new FormData();
      fd.append("file", arq, arq.name);
      fd.append("movconta_id", this.movimentacao.id);
      axios
        .post(axios.defaults.baseURL + "/arquivos/upload", fd, {
          onUploadProgress: uploadEvent => {
            arr.progress =
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
          }
        })
        .then(res => {
          if (res.data.success) {
            arr.message = res.data.message;
            this.$store.commit("pushReceitaArquivo", res.data.arquivo);
            // this.selectedFile = null
          } else {
            arr.error = true;
            arr.message = res.data.message;
            Notify.create({
              message: arr.message + "  " + arr.fileName,
              color: "orange",
              position: "top-right"
            });
          }
          arr.success = res.data.success;
          arr.uploading = false;
          arr.delivered = true;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível fazer o upload do arquivo" +
              arr.fileName +
              ", ou você não possui permissão para isto.",
            color: "red",
            position: "top-right"
          });
          arr.success = false;
          arr.error = true;
          arr.uploading = false;
          arr.progress = "0%";
        });
    },
    openArquivo(value) {
      axios
        .get("/arquivos/get-url/" + value.id)
        .then(response => {
          Notify.create({
            message: "Iniciando o Download de " + response.data.fileName,
            color: "green",
            position: "top-right"
          });
          const link = document.createElement("a");
          link.href = response.data.downloadUrl;
          link.setAttribute("type", "hidden");
          window.open(link, "_blank");
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível baixar o arquivo, ou você não possui permissão!",
            color: "green",
            position: "top-right"
          });
        });
    },
    loadMore() {
      if (this.$route.path === "/despesas" || this.cadastroDespesa.isCadastro) {
        this.$store.dispatch("loadMoreDespesas");
      } else {
        this.$store.dispatch("loadMoreReceitas");
      }
    },
     add() {
      this.$store.dispatch("openObs", true);
      this.$store.dispatch("isTimeGlobal", false);
      this.$store.commit("isTimelineCadastro", {
        cadastro: false,
        routeId: '',
      });
    },
    openModal() {
      this.$store.dispatch("getTokenQrcode");
      this.$store.commit("OpenDialogUploadResumoCaixas", true);
      this.$refs.uploadResumoTfl.dizsize();
    },
    verificarPagamento() {

      this.btn_verificarPagamento.disable = true;
      this.btn_verificarPagamento.loading = true;
      let params = {id: this.movimentacao.id};

      axios
        .post("/financeiro/reSubmitSafe2Pay", params)
        .then(res => {
          this.btn_verificarPagamento.loading = false;
          this.btn_verificarPagamento.disable = false;

          if (res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            this.closeModal();
          } else {
            Notify.create({
              message: res.data.message,
              color: "red",
              position: "top-right"
            });
          }
        })
        .catch (e => {
          this.btn_verificarPagamento.loading = false;
          this.btn_verificarPagamento.disable = false;

          Notify.create({
           message: "Não foi possivel enviar a solicitação ao Safe3Pay",
           color: "green",
           position: "top-right"
         });
        });
    },
    getPagamentoStatus(status) {
      const statusMap = {
        "0": "Nenhum",
        "1": "Lançado",
        "2": "Autorizado",
        "3": "Pago",
        "4": "Erro"
      };
      return statusMap[parseInt(status)] || "Status inválido";
    },
    openListarRegistrosModal() {
      this.$store.commit("OpenDialogListarRegistros", true);
    }
  },
  computed: {
    OpenDialogDetalharMovconta: {
      get() {
        return this.$store.getters.OpenDialogDetalharMovconta;
      },
      set() {}
    },
    timelineAction() {
      return this.$route.path === "/despesas" || this.cadastroDespesa.isCadastro
        ? this.$store.getters.timelineActionDespesas
        : this.$store.getters.timelineActionReceitas;
    },
    itemsTotal() {
      let total = this.items;
      let value = 0;
      for (let i = 0; i < total.length; i++) {
        value += total[i].valor;
      }
      return value;
    },
    timeline() {
      return this.$route.path === "/despesas" || this.cadastroDespesa.isCadastro
        ? this.$store.getters.timelineDespesas
        : this.$store.getters.timelineReceitas;
    },
    pagingTime() {
      return this.$route.path === "/despesas" || this.cadastroDespesa.isCadastro
        ? this.$store.getters.pagingTimeDespesas
        : this.$store.getters.pagingTimeReceitas;
    },
    cadPraDet() {
      return this.$store.getters.cadPraDet;
    },
    movPraDet() {
      return this.$store.getters.movPraDet;
    },
    items() {
      return this.$route.path === "/despesas" || this.cadastroDespesa.isCadastro
        ? this.$store.getters.itemsDespesas
        : this.$store.getters.itemsReceitas;
    },
    movimentacao() {
      return this.$store.getters.movimentacao;
    },
    btnDisabled() {
      return this.$store.getters.btnDisabled;
    },
    urlGeral() {
      return this.$store.getters.urlGeral;
    },
    buscandoItems() {
      return this.$store.getters.buscandoItems;
    },
    movconta() {
      return this.$store.getters.movconta;
    },
    receitaArquivos() {
      return this.$store.getters.receitaArquivos;
    },
    cadastroDespesa() {
      return this.$store.getters.cadastroDespesa;
    },
    contaMovimentacao() {
      if (!this.movimentacao || !this.movimentacao.conta) {
        return "Lançamento sem Conta Bancária";
      }

      const { titulo } = this.movimentacao.conta;

      if (this.movimentacao.liquidacao) {
        return `Liquidado na ${titulo}`;
      }

      return `Previsão de Liquidação na ${titulo}`;
    },
    showVerificarPagamentoBtn() {
      return (this.movimentacao.status === 'Aberto' && this.movimentacao.gateway.tipo == 13) || ([14, 15].includes(this.movimentacao.gateway.tipo));  
    }
  }
};
</script>

<style lang="stylus" scoped>
p {
  margin-bottom: 0;
}

.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.layout
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:10px 10px;

.layout-responsive
  text-align:center;
  padding:10px 10px;

.layout-responsive .icones-layout label
  padding:3rem;

.icones-layout label
  margin-right:5px;

.q-timeline__heading-title {
  padding-bottom: 0;
}
</style>

<style>
  #cardModalDetalharMovconta .q-timeline p {
    margin: 0px;
  }
</style>
