<template>
  <div id="modalCaixaSangria">
    <q-dialog v-model="OpenDialogSangriaCaixas" persistent>
      <q-card :style="`width: 100%; max-width: ${ (dadosSangrias.detalharNotas || dadosSangrias.detalharMoedas) ? 800 : 400 }px;`">
        <q-card-section class="row items-center">
          <div class="text-h6">Sangria/Retirada</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="clearFormFechar()"
          />
        </q-card-section>

        <q-separator />

        <div class="row">
          <div :class="{ 'col-12': true, 'col-md-6': (dadosSangrias.detalharNotas || dadosSangrias.detalharMoedas) }" style="padding-bottom: 0;">

            <q-card-section class="q-pa-md" style="padding-bottom: 0;">
              <div class="q-col-gutter-md row q-px-md">
                <div class="col-12">
                  <q-checkbox
                    color="green"
                    label="Transferir para outro caixa"
                    v-model="dadosSangrias.transferirCaixa"
                  />
                </div>

                <div class="col-12">
                  <q-select
                    v-model="tipoConta"
                    :option-label="
                      value => (value === null ? 'Item Vazio' : value.titulo)
                    "
                    :option-value="value => (value === null ? null : value)"
                    :options="contas"
                    :label="dadosSangrias.transferirCaixa
                      ? 'Passando pela conta'
                      : 'Enviando a retirada para'
                    "
                    :autofocus="true"
                    id="praOndeVai"
                    clearable
                    @input="() => updateValueAction()"
                    :hide-bottom-space="!Boolean(errorsCaixas.conta_id)"
                    :error="errorsCaixas.conta_id ? true : false"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsCaixas.conta_id"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey"
                          >Sem Resultados</q-item-section
                        >
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-12" v-show="dadosSangrias.transferirCaixa">
                  <q-select
                    clearable
                    id="deOndeVemCaixa"
                    label="Sangria/Transferir para Colega"
                    :options="movcaixasOptions"
                    :error="errorsCaixas.caixa_id ? true : false"
                    :hide-bottom-space="!Boolean(errorsCaixas.caixa_id)"
                    :option-value="value => value"
                    :option-label="value => getLabelMovcaixa(value, tipoConta.titulo)"
                    v-model="dadosSangrias.caixa"
                    @input="() => updateLabelDescricao()"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsCaixas.caixa_id"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>


              </div>
            </q-card-section>

            <acao-caixa botoesIncremento="true">
              <template v-slot:valor-bottom>

                <q-checkbox
                  label="Detalhar notas"
                  color="green"
                  v-model="dadosSangrias.detalharNotas"
                />

                <q-checkbox
                  label="Detalhar moedas"
                  color="green"
                  v-model="dadosSangrias.detalharMoedas"
                  v-if="controleMoedasInstalado"
                />

              </template>
            </acao-caixa>

          </div>

          <div class="col-12 col-md-6">
            <q-card-section class="q-pa-md" v-show="dadosSangrias.detalharNotas">
              <p><strong>Detalhamento de Notas</strong></p>

              <q-markup-table flat dense>
                <tbody>
                  <tr>
                    <td>R$ 2,00</td>
                    <td class="text-right" style="width: 100px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.notas.notas_2"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_notas && errorsCaixas.detalhe_notas.notas_2"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_notas.notas_2"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.notas.notas_2 * 2) | currency }}</td>
                  </tr>

                  <tr>
                    <td>R$ 5,00</td>
                    <td class="text-right" style="width: 100px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.notas.notas_5"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_notas && errorsCaixas.detalhe_notas.notas_5"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_notas.notas_5"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.notas.notas_5 * 5) | currency }}</td>
                  </tr>

                  <tr>
                    <td>R$ 10,00</td>
                    <td class="text-right" style="width: 100px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.notas.notas_10"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_notas && errorsCaixas.detalhe_notas.notas_10"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_notas.notas_10"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.notas.notas_10 * 10) | currency }}</td>
                  </tr>

                  <tr>
                    <td>R$ 20,00</td>
                    <td class="text-right" style="width: 100px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.notas.notas_20"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_notas && errorsCaixas.detalhe_notas.notas_20"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_notas.notas_20"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.notas.notas_20 * 20) | currency }}</td>
                  </tr>

                  <tr>
                    <td>R$ 50,00</td>
                    <td class="text-right" style="width: 100px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.notas.notas_50"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_notas && errorsCaixas.detalhe_notas.notas_50"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_notas.notas_50"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.notas.notas_50 * 50) | currency }}</td>
                  </tr>

                  <tr>
                    <td>R$ 100,00</td>
                    <td class="text-right" style="width: 100px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.notas.notas_100"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_notas && errorsCaixas.detalhe_notas.notas_100"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_notas.notas_100"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.notas.notas_100 * 100) | currency }}</td>
                  </tr>

                  <tr>
                    <td>R$ 200,00</td>
                    <td class="text-right" style="width: 100px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.notas.notas_200"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_notas && errorsCaixas.detalhe_notas.notas_200"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_notas.notas_200"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.notas.notas_200 * 200) | currency }}</td>
                  </tr>

                  <tr class="text-weight-bold">
                    <td>Total</td>
                    <td>{{ totalQuantidade }}</td>
                    <td class="text-right">R$ {{ totalNotas | currency }}</td>
                  </tr>
                </tbody>
              </q-markup-table>
            </q-card-section>

            <q-card-section class="q-pa-md" v-show="dadosSangrias.detalharMoedas">
              <p><strong>Detalhamento de Moedas</strong></p>

              <q-markup-table flat dense>
                <tbody>
                  <tr>
                    <td>R$ 0,05</td>
                    <td class="text-right" style="width: 100px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.moedas.moedas_5"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_moedas && errorsCaixas.detalhe_moedas.moedas_5"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_moedas.moedas_5"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.moedas.moedas_5 * 0.05) | currency }}</td>
                  </tr>

                  <tr>
                    <td>R$ 0,10</td>
                    <td class="text-right" style="width: 100px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.moedas.moedas_10"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_moedas && errorsCaixas.detalhe_moedas.moedas_10"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_moedas.moedas_10"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.moedas.moedas_10 * 0.10) | currency }}</td>
                  </tr>

                  <tr>
                    <td>R$ 0,25</td>
                    <td class="text-right" style="width: 250px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.moedas.moedas_25"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_moedas && errorsCaixas.detalhe_moedas.moedas_25"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_moedas.moedas_25"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.moedas.moedas_25 * 0.25) | currency }}</td>
                  </tr>

                  <tr>
                    <td>R$ 0,50</td>
                    <td class="text-right" style="width: 100px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.moedas.moedas_50"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_moedas && errorsCaixas.detalhe_moedas.moedas_50"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_moedas.moedas_50"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.moedas.moedas_50 * 0.50) | currency }}</td>
                  </tr>

                  <tr>
                    <td>R$ 1,00</td>
                    <td class="text-right" style="width: 100px;">
                      <q-input
                        dense
                        mask="####"
                        v-model="dadosSangrias.moedas.moedas_1"
                        no-error-icon
                        hide-bottom-space
                        :error="errorsCaixas.detalhe_moedas && errorsCaixas.detalhe_moedas.moedas_1"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsCaixas.detalhe_moedas.moedas_1"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </td>
                    <td class="text-right">R$ {{ (dadosSangrias.moedas.moedas_1 * 1) | currency }}</td>
                  </tr>

                  <tr class="text-weight-bold">
                    <td>Total</td>
                    <td>{{ totalQuantidadeMoedas }}</td>
                    <td class="text-right">R$ {{ totalMoedas | currency }}</td>
                  </tr>
                </tbody>
              </q-markup-table>
            </q-card-section>
          </div>

        </div>

        <q-separator />

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { bus } from "@/main";
import { mapGetters } from "vuex";
import moment from 'moment';
import axios from '@/axios-auth';

export default {
  name: "modal-caixa-sangria",
  data() {
    return {
      movcaixasOptions: [],
    };
  },
  methods: {
       updateValueAction() {
      let  descricao = "";

      if(this.tipoConta === null)
       return false;

      if(this.dadosSangrias && this.dadosSangrias.caixa){
        this.descricao = this.getLabelMovcaixa(this.dadosSangrias.caixa, this.tipoConta.titulo);
      }
      this.$store.commit("venda", {
        descricao: descricao,

        valor: this.venda.valor,
      });
      this.$store.commit("conta_id_caixa", this.tipoConta.id);
      document.getElementsByName('valorCaixa')[0].focus();
    },
    updateLabelDescricao(){

      if(this.tipoConta === null)
       return false;
      if(this.dadosSangrias && this.dadosSangrias.caixa){

        let descricao = 'Destino: '+this.getLabelMovcaixa(this.dadosSangrias.caixa, this.tipoConta.titulo);

         this.$store.commit("venda", {
          descricao: descricao,

          valor: this.venda.valor,
        });
      }
    },
    clearForm() {
      this.tipo = null;
      this.$store.commit("errorsCaixas", {});
      this.$store.dispatch("resetDadosSangrias");
    },
    clearFormFechar() {
      this.clearForm();
      this.$store.commit("OpenDialogSangriaCaixas", false);
    },
    recalcularValorVenda() {
      const { detalharNotas, detalharMoedas } = this.dadosSangrias;

      if (!detalharNotas && !detalharMoedas) {
        return;
      }

      let valor = 0;

      if (detalharNotas) {
        valor += this.totalNotas;
      }
      if (detalharMoedas) {
        valor += this.totalMoedas;
      }

      this.venda.valor = valor;
    },
    loadCaixasAbertos() {
      const params = {
        status: 'A',
        dtini: moment().subtract(1, 'month').format('DD-MM-YYYY'),
        dtfim: moment().add(1, 'month').format('DD-MM-YYYY'),
      };

      axios
        .get('/lancamentos/caixas', { params })
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          const movcaixaId = parseInt(this.$route.params.id);
          this.movcaixasOptions = res.data.movcaixas.filter(movcaixa => movcaixa.id !== movcaixaId);
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao buscar os caixas abertos',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    getLabelMovcaixa(movcaixa) {
      const dtmovimento = moment(movcaixa.dtmovimento, 'YYYY/MM/DD').format('DD/MM/YYYY');

      return `${dtmovimento} - ${movcaixa.caixa_nome} - ${movcaixa.func_resp_nome} - Sessão ${movcaixa.sessao}`;
    },
  },
  computed: {
    tipoConta: {
      get() {
        return this.contaPadraoSangria;
      },
      set(value) {
        this.$store.commit("contaPadraoSangria", value);
      }
    },
    OpenDialogSangriaCaixas: {
      get() {
        return this.$store.getters.OpenDialogSangriaCaixas;
      },
      set() {}
    },
    ...mapGetters([
      "contas",
      "errorsCaixas",
      "dadosSangrias",
      "venda",
      "contaPadraoSangria",
      "controleMoedasInstalado",
    ]),
    totalQuantidade() {
      return (
        parseInt(this.dadosSangrias.notas.notas_2)
        + parseInt(this.dadosSangrias.notas.notas_5)
        + parseInt(this.dadosSangrias.notas.notas_10)
        + parseInt(this.dadosSangrias.notas.notas_20)
        + parseInt(this.dadosSangrias.notas.notas_50)
        + parseInt(this.dadosSangrias.notas.notas_100)
        + parseInt(this.dadosSangrias.notas.notas_200)
      );
    },
    totalQuantidadeMoedas() {
      return (
        + parseInt(this.dadosSangrias.moedas.moedas_5)
        + parseInt(this.dadosSangrias.moedas.moedas_10)
        + parseInt(this.dadosSangrias.moedas.moedas_25)
        + parseInt(this.dadosSangrias.moedas.moedas_50)
        + parseInt(this.dadosSangrias.moedas.moedas_1)
      );
    },
    totalNotas() {
      return (
        (parseInt(this.dadosSangrias.notas.notas_2) * 2)
        + (parseInt(this.dadosSangrias.notas.notas_5) * 5)
        + (parseInt(this.dadosSangrias.notas.notas_10) * 10)
        + (parseInt(this.dadosSangrias.notas.notas_20) * 20)
        + (parseInt(this.dadosSangrias.notas.notas_50) * 50)
        + (parseInt(this.dadosSangrias.notas.notas_100) * 100)
        + (parseInt(this.dadosSangrias.notas.notas_200) * 200)
      );
    },
    totalMoedas() {
      return (
        (parseInt(this.dadosSangrias.moedas.moedas_5) * 0.05)
        + (parseInt(this.dadosSangrias.moedas.moedas_10) * 0.10)
        + (parseInt(this.dadosSangrias.moedas.moedas_25) * 0.25)
        + (parseInt(this.dadosSangrias.moedas.moedas_50) * 0.50)
        + (parseInt(this.dadosSangrias.moedas.moedas_1) * 1)
      );
    },
  },
  watch: {
    totalNotas() {
      this.recalcularValorVenda();
    },
    totalMoedas() {
      this.recalcularValorVenda();
    },
    contaPadraoSangria(value) {
      if (value) {
        this.venda.descricao = `Sangria - ${value.titulo}`;
      }
    },
    'dadosSangrias.detalharNotas'(value) {
      this.recalcularValorVenda();
    },
    'dadosSangrias.detalharMoedas'(value) {
      this.recalcularValorVenda();
    },
    OpenDialogSangriaCaixas(value) {
      if (!value) return;

      this.loadCaixasAbertos();
    },
  },
  mounted() {
    document.addEventListener("keyup", event => {
      if (event.keyCode === 27) {
        this.clear();
      }
    });
  },
  created() {
    bus.$on("clear", () => {
      this.clearForm();
    });
  },
  destroyed() {
    bus.$off("clear");
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener("keyup");
    next();
  }
};
</script>
