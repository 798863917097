<template>
  <q-btn
    flat
    dense
    clickable
    icon="print"
    class="text-blue-7"
    style="margin-right: 10px"
    :loading="loadingImprimir"
    @click="imprimir()"
  >
    <template v-slot:loading>
      <q-spinner color="grey-10" size="1em" />
    </template>
    <template v-slot:default>
      <q-tooltip>Imprimir Relatório</q-tooltip>
    </template>
  </q-btn>
</template>

<script>
import axios from '@/axios-auth';

export default {
  name: 'button-metricas-saas-imprimir-detalhamento',
  props: ['campo', 'referencia'],
  data() {
    return {
      loadingImprimir: false,
    };
  },
  methods: {
    imprimir() {
      this.loadingImprimir = true;

      const params = {
        referencia: String(this.referencia).replace('/', '-'),
      };
      const headers = {
        Accept: 'text/html',
      };

      axios
        .get(`/metricas-saas/imprimir-detalhamento/${this.campo}`, { params, headers })
        .then(res => {
          this.loadingImprimir = false;

          if (!res.data) {
            throw new Error();
          }

          let windowJanela = window.open('about:blank');
          windowJanela.document.write(res.data);
          windowJanela.document.close();
        })
        .catch(() => {
          this.loadingImprimir = false;
          this.$q.notify({
            message: 'Houve um erro ao imprimir o detalhamento',
            position: 'top-right',
            color: 'red',
          });
        });
    },
  },
};
</script>
