var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-dialog',{model:{value:(this.OpenDrawerTimelineObs),callback:function ($$v) {_vm.$set(this, "OpenDrawerTimelineObs", $$v)},expression:"this.OpenDrawerTimelineObs"}},[_c('q-card',{staticStyle:{"width":"800px"}},[_c('q-card-section',{staticClass:"row justify-between"},[_c('div',{staticClass:"text-h6 col"},[_vm._v("Adicionar uma Observação")]),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"col-1",attrs:{"icon":"close","flat":"","round":"","dense":""},on:{"click":function($event){return _vm.close()}}})],1),_c('q-card-section',{staticClass:"q-pt-none"},[_c('q-editor',{attrs:{"min-height":"5rem","dense":"","toolbar":[
            [
              {
                label: _vm.$q.lang.editor.align,
                icon: _vm.$q.iconSet.editor.align,
                fixedLabel: true,
                list: 'only-icons',
                options: ['left', 'center', 'right', 'justify']
              }, 
            ],
            ['bold', 'italic', 'strike', 'underline'],
            [
                {
                  label: _vm.$q.lang.editor.formatting,
                  icon: _vm.$q.iconSet.editor.formatting,
                  list: 'no-icons',
                  options: [
                    'h4',
                    'h5',
                    'h6',
                    'p',
                  ]
                },
            ],
            ['undo', 'redo'],
          ]},model:{value:(_vm.editor.corpo),callback:function ($$v) {_vm.$set(_vm.editor, "corpo", $$v)},expression:"editor.corpo"}}),_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[(_vm.erroEditor)?_c('p',{staticClass:"text-negative"},[_vm._v("Não é possivel salvar uma observação vazia!")]):_vm._e()])],1),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"bg-positive",attrs:{"color":"primary","label":"Salvar"},on:{"click":function($event){_vm.salvar(); _vm.updateTimeline();}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }