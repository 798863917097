<template>
  <div id="modalLancamentoDetalhamento">
    <q-dialog v-model="openModalLancamentoDetalhamento" persistent>
      <q-card style="max-width: 700px !important; width: 100%;">
        <q-card-section class="flex justify-between">
          <div class="text-h6">Detalhamento do Lançamento</div>
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div>
            <p>Descrição: <b>{{ movconta.descricao }}</b></p>
            <p>Conta: <b>{{ movconta.conta.titulo }}</b></p>
            <p>Valor: <b>R$ {{ movconta.valor | currency }}</b></p>
            <p>Criado por: <b>{{ movconta.criador }}</b></p>
          </div>
        </q-card-section>

        <q-card-section class="row">
          <div class="col-6">
            <p class="q-mb-none text-h6">Cédulas</p>

            <div class="text-right" style="width: 65%;">
              <p>R$ 2,00 x {{ movconta.movcontas_detalhenota.notas_2 }} = R$ {{( movconta.movcontas_detalhenota.notas_2 * 2) | currency }}</p>
              <p>R$ 5,00 x {{ movconta.movcontas_detalhenota.notas_5 }} = R$ {{( movconta.movcontas_detalhenota.notas_5 * 5) | currency }}</p>
              <p>R$ 10,00 x {{ movconta.movcontas_detalhenota.notas_10 }} = R$ {{( movconta.movcontas_detalhenota.notas_10 * 10) | currency }}</p>
              <p>R$ 20,00 x {{ movconta.movcontas_detalhenota.notas_20 }} = R$ {{( movconta.movcontas_detalhenota.notas_20 * 20) | currency }}</p>
              <p>R$ 50,00 x {{ movconta.movcontas_detalhenota.notas_50 }} = R$ {{( movconta.movcontas_detalhenota.notas_50 * 50) | currency }}</p>
              <p>R$ 100,00 x {{ movconta.movcontas_detalhenota.notas_100 }} = R$ {{( movconta.movcontas_detalhenota.notas_100 * 100) | currency }}</p>
              <p>R$ 200,00 x {{ movconta.movcontas_detalhenota.notas_200 }} = R$ {{( movconta.movcontas_detalhenota.notas_200 * 200) | currency }}</p>
              <p><b>Total: R$ {{ valorTotalNotas | currency }}</b></p>
            </div>
          </div>

          <div class="col-6">
            <p class="q-mb-none text-h6">Moedas</p>

            <div class="text-right" style="width: 65%;">
              <p>R$ 0,05 x {{ movconta.movcontas_detalhemoeda.moedas_5 }} = R$ {{( movconta.movcontas_detalhemoeda.moedas_5 * 0.05) | currency }}</p>
              <p>R$ 0,10 x {{ movconta.movcontas_detalhemoeda.moedas_10 }} = R$ {{( movconta.movcontas_detalhemoeda.moedas_10 * 0.1) | currency }}</p>
              <p>R$ 0,25 x {{ movconta.movcontas_detalhemoeda.moedas_25 }} = R$ {{( movconta.movcontas_detalhemoeda.moedas_25 * 0.25) | currency }}</p>
              <p>R$ 0,50 x {{ movconta.movcontas_detalhemoeda.moedas_50 }} = R$ {{( movconta.movcontas_detalhemoeda.moedas_50 * 0.5) | currency }}</p>
              <p>R$ 1,00 x {{ movconta.movcontas_detalhemoeda.moedas_1 }} = R$ {{( movconta.movcontas_detalhemoeda.moedas_1) | currency }}</p>
              <p><b>Total: R$ {{ valorTotalMoedas | currency }}</b></p>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: 'modalLancamentoDetalhamento',
  props: ['movconta', 'openModalLancamentoDetalhamento'],
  methods: {
    closeModal() {
      this.$emit('closeModal');
    }
  },
  computed: {
    valorTotalNotas() {
      const { movcontas_detalhenota } = this.movconta;

      return (
        (parseInt(movcontas_detalhenota.notas_2) * 2)
        + (parseInt(movcontas_detalhenota.notas_5) * 5)
        + (parseInt(movcontas_detalhenota.notas_10) * 10)
        + (parseInt(movcontas_detalhenota.notas_20) * 20)
        + (parseInt(movcontas_detalhenota.notas_50) * 50)
        + (parseInt(movcontas_detalhenota.notas_100) * 100)
        + (parseInt(movcontas_detalhenota.notas_200) * 200)
      );
    },
    valorTotalMoedas() {
      const { movcontas_detalhemoeda } = this.movconta;

      return (
        (parseInt(movcontas_detalhemoeda.moedas_5) * 0.05)
        + (parseInt(movcontas_detalhemoeda.moedas_10) * 0.1)
        + (parseInt(movcontas_detalhemoeda.moedas_25) * 0.25)
        + (parseInt(movcontas_detalhemoeda.moedas_50) * 0.5)
        + (parseInt(movcontas_detalhemoeda.moedas_1))
      );
    },
    vencimento() {
      return moment(this.movconta.vencimento, 'YYYY/MM/DD').format('DD/MM/YYYY');
    }
  }
};
</script>

<style scoped>
p {
  margin-bottom: 4px;
}
</style>