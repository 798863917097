<template>
  <div id="integracaoZenviaParametros">
    <card-section-sem-permissao v-if="semPermissao" />

    <div v-else>
      <div class="text-center" v-if="loadingConfiguracoes">
        <q-spinner color="blue-7" size="3em" />
      </div>

      <div class="row">
        <div class="col-10 col-md-6">
          <q-input
            v-model="config.zenvia_token"
            label="Token Zenvia (Total Voice)"
          >
            <template v-slot:append>
              <q-btn
                flat
                round
                dense
                @click="copyText(config.zenvia_token, 'Token', 'o')"
                icon="fa fa-clipboard"
                size="12px"
              />
            </template>
          </q-input>
        </div>

        <div class="col-2">
          <q-btn
            class="q-mt-md q-ml-md"
            color="primary"
            icon="history"
            @click="showChamadas"
          />
        </div>
      </div>

      <div class="row q-my-md">
        <div class="col-5 col-md-3">
          <autocomplete
            title="Selecionar Cadastro"
            :filter="filterFn"
            :carregando="loading"
            :options="optionsCadastros"
            :formatDisplay="formatDisplay"
            :create="createValue"
            @resultAutocomplete="resultEmitRecebe"
            :display="displayMontar"
            tipoConsulta="cadastro"
            :cadastro="abreCadastro"
          />
        </div>

        <div class="col-5 col-md-3 q-ml-md">
          <q-input
            label="Ramal"
            v-model="idRamal"
          />
        </div>

        <div class="col-1">
          <q-btn
            flat
            round
            icon="add"
            size="1.3em"
            color="primary"
            @click="addUsuario"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <q-markup-table
            v-if="usuarios"
            flat
            dense
            separator="none"
            class="q-mt-md text-left"
          >
            <thead>
              <tr>
                <th>Lista de Usuários</th>
                <th>Ramal</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(usuario, index) in usuarios"
                :key="usuario.funcionario_id"
              >
                <td>{{ usuario.funcionario_nome }}</td>
                <td>{{ usuario.ramal }}</td>
                <td>
                  <q-btn
                    flat
                    dense
                    size="1em"
                    color="red"
                    icon="delete"
                    @click="removeUsuario(index)"
                  />
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </div>
      </div>

      <div class="row q-mt-md">
        <div class="col-12 col-md-6">
          <q-input
            v-model="zenvia_url"
            label="Sua url"
            readonly
          >
          <template v-slot:append>
              <q-btn
                flat
                round
                dense
                @click="copyText(zenvia_url, 'URL', 'a')"
                icon="fa fa-clipboard"
                size="12px"
              />
              <q-btn
                flat
                round
                dense
                class="q-ml-sm"
                @click="renovarToken"
                icon="fa fa-refresh"
                size="12px"
              />
            </template>
          </q-input>
        </div>
      </div>

      <div class="flex justify-end q-mt-md q-mr-auto">
        <q-btn color="green" label="Salvar" :loading="loadingSalvar" @click="salvarConfiguracoes(false)" />
      </div>
    </div>

    <modal-chamadas-logs />
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { copyToClipboard } from 'quasar';

export default {
  name: 'integracao-zenvia-parametros',
  data() {
    return {
      semPermissao: false,
      loading: false,
      loadingConfiguracoes: false,
      loadingSalvar: false,
      usuarios: [],
      optionsCadastros: [],
      idRamal: null,
      cadastro: null,
      config: {
        zenvia_token: '',
        zenvia_url_webhook: '',
        zenvia_token_interno: ''
      }
    };
  },
  methods: {
    renovarToken() {
      this.$q.dialog({
        title: 'Confirmar',
        message: `
          Deseja realmente renovar o token?<br/>
          Todos os dispositivos serão desconectados!
        `,
        cancel: true,
        persistent: true,
        html: true,
      }).onOk(() => {
        this.salvarConfiguracoes(true);
      });
    },
    salvarConfiguracoes(token) {
      this.config.zenvia_usuarios = this.usuarios;
      const data = this.config;

      this.loadingSalvar = true;
      axios
        .post(`/integracoes/zenvia?load_opcoes=1&token=${token ? 1 : 0}`, data)
        .then(res => {
          if (res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'green',
              position: 'top-right'
            });
          } else {
            this.$q.notify({
              message: 'Erro ao salvar configurações!',
              color: 'red',
              position: 'top-right'
            });
          }

          this.loadingSalvar = false;
          this.config = res.data.config;
        });

    },
    showChamadas() {
      this.$store.dispatch("getChamadasLogs");
    },
    abreCadastro(value) {
      this.$store.dispatch("cadastro", {
        nome: value.name,
        noSearch: true,
      });
    },
    addUsuario() {
      if (!this.cadastro || !this.idRamal) return;

      const cadastroIndex = this.usuarios.findIndex(usuario => usuario.funcionario_id === this.cadastro.id);

      const novoUsuario = {
        ramal: +this.idRamal,
        funcionario_id: this.cadastro.id,
        funcionario_nome: this.cadastro.nome,
      };

      if (cadastroIndex === -1) {
        this.usuarios.push(novoUsuario);
      } else {
        this.usuarios[cadastroIndex] = novoUsuario;
      }

      this.idRamal = null;
      this.$root.$emit("limpar");
      this.$forceUpdate();
    },
    removeUsuario(index) {
      this.usuarios.splice(index, 1);
    },
    loadOpcoes() {
      this.loadingConfiguracoes = true;

       axios
        .get("/integracoes/zenvia?load_opcoes=1")
        .then(res => {
          if (!res.data.success) {
            throw res.data;
          }

          this.loadingConfiguracoes = false;
          this.semPermissao = false;
          this.config = res.data.config;

          const cadastrosRamais = res.data.config.zenvia_usuarios || [];
          if (cadastrosRamais.length > 0) {
            this.usuarios = JSON.parse(cadastrosRamais);
          }
        })
        .catch(() => {
          this.loadingConfiguracoes = false;
          this.semPermissao = true;
        });
    },
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.loading = true;
        axios.get("/cadastros?ativo=1&funcionario=1&busca=" + val).then(res => {
          this.loading = false;
          this.optionsCadastros = res.data.data.cadastros;
        });
      });
    },
    displayMontar(value) {
      return !value.nome ? value.name : value.nome;
    },
    resultEmitRecebe(result) {
      this.cadastro = result;
    },
    formatDisplay(result) {
      return result.nome;
    },
    createValue(val, done) {
      this.$q.notify({
        message: "Cadastro não foi encontrado.",
        color: "orange",
        position: "top-right"
      });
    },
    copyText(text, type, artigo) {
      copyToClipboard(text)
        .then(() => {
          this.$q.notify({
            message: `${type} Copiad${artigo}!`,
            color: "green",
            position: "top-right",
          });
        });
    },
  },
  computed: {
    zenvia_url() {
      return this.config.zenvia_url_webhook + '?token=' + this.config.zenvia_token_interno;
    },
  },
  mounted() {
    this.loadOpcoes();
  }
};
</script>

<style scoped>
th, td {
  padding-left: 0px !important;
}
</style>
