<template>
  <q-page id="area-cliente">
    <div class="q-pa-md">

      <div class="q-mt-md text-center" v-show="loadingOpcoes">
        <q-spinner color="blue-7" size="3em" />
      </div>

      <q-card v-show="!loadingOpcoes">
        <q-card-section class="row items-center">
          <div class="text-h6">Parâmetros da Área do Cliente</div>

          <a
            v-show="urlAreaCliente"
            target="_blank"
            class="q-ml-md"
            style="text-decoration: none;"
            :href="urlAreaCliente"
          >
            <q-icon name="fa fa-external-link" size="18px" color="grey-7" />
          </a>

          <q-btn
            v-if="hasVideo"
            dense
            flat
            color="bg-primary text-blue-7"
            icon="ondemand_video"
            class="q-ml-auto"
            @click="modalIntro"
          >
            <template v-slot:default>
              <q-tooltip>Introdução a Área do Cliente</q-tooltip>
            </template>
          </q-btn>
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row items-start">

              <div class="q-mb-md col-12 col-md-6">
                <q-list>

                  <q-item
                    header
                    class="row justify-between items-center"
                  >
                    <q-item-section>
                      <q-item-label>Pode editar o cadastro</q-item-label>
                      <q-item-label caption>Permite a edição do cadastro pelo cliente ( endereço, telefone, email ) a qualquer momento.</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle
                        label="Sim"
                        color="green"
                        checked-icon="check"
                        unchecked-icon="clear"
                        v-model="opcoes.areacliente_pode_editar"
                      />
                    </q-item-section>
                  </q-item>

                  <q-item
                    header
                    class="row justify-between items-center"
                  >
                    <q-item-section>
                      <q-item-label>Necessário confirmar o cadastro</q-item-label>
                      <q-item-label caption>
                        <div>A cada 6 meses antes de ter acesso ao extrato, o cliente precisa confirmar/atualizar as informações de cadastro ( endereço, telefone, email )</div>
                        <div>Importante: Sem a opção de editar ativa, só é possivel confirmar.</div>
                      </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle
                        label="Sim"
                        color="green"
                        checked-icon="check"
                        unchecked-icon="clear"
                        v-model="opcoes.areacliente_necessita_confirmacao"
                      />
                    </q-item-section>
                  </q-item>

                  <q-item
                    header
                    class="row justify-between items-center"
                  >
                    <q-item-section>
                      <q-item-label>Pode visualizar extrato</q-item-label>
                      <q-item-label caption>Consegue visualizar e imprimir o histórico de cobranças / notas fiscais / boletos.</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle
                        label="Sim"
                        color="green"
                        checked-icon="check"
                        unchecked-icon="clear"
                        v-model="opcoes.areacliente_pode_ver_extrato"
                      />
                    </q-item-section>
                  </q-item>

                  <q-item
                    header
                    class="row justify-between items-center"
                  >
                    <q-item-section>
                      <q-item-label>Pode visualizar o extrato de imposto de renda</q-item-label>
                      <q-item-label caption>Consegue visualizar e imprimir o extrato de imposto de renda.</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle
                        label="Sim"
                        color="green"
                        checked-icon="check"
                        unchecked-icon="clear"
                        v-model="opcoes.areacliente_pode_ver_imposto"
                      />
                    </q-item-section>
                  </q-item>

                  <q-item
                    header
                    class="row justify-between items-center"
                  >
                    <q-item-section>
                      <q-item-label>Exibir o campo de capital social</q-item-label>
                      <q-item-label caption>Exibe o campo capital social.</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle
                        label="Sim"
                        color="green"
                        checked-icon="check"
                        unchecked-icon="clear"
                        v-model="opcoes.areacliente_cnpj"
                      />
                    </q-item-section>
                  </q-item>

                  <q-item
                    header
                    class="row justify-between items-center"
                  >
                    <q-item-section>
                      <q-item-label>Editar o campo de capital social</q-item-label>
                      <q-item-label caption>Permite editar o capital social.</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle
                        label="Sim"
                        color="green"
                        checked-icon="check"
                        unchecked-icon="clear"
                        v-model="opcoes.areacliente_capital_social"
                      />
                    </q-item-section>
                  </q-item>

                  <q-item
                    header
                    class="row justify-between items-center"
                  >
                    <q-item-section>
                      <q-item-label>Pode editar a forma de pagamento</q-item-label>
                      <q-item-label caption>Permite adicionar/editar a forma de pagamento.</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle
                        label="Sim"
                        color="green"
                        checked-icon="check"
                        unchecked-icon="clear"
                        v-model="opcoes.areacliente_pode_adicionar_cartao"
                      />
                    </q-item-section>
                  </q-item>

                  <q-item
                    header
                    class="row justify-between items-center"
                  >
                    <q-item-section>
                      <q-item-label>Atualizar cadastro sem login</q-item-label>
                      <q-item-label caption>Exibe um formulário específico para atualizar o cadastro através de uma url codificada.</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle
                        label="Sim"
                        color="green"
                        checked-icon="check"
                        unchecked-icon="clear"
                        v-model="opcoes.areacliente_sem_login"
                      />
                    </q-item-section>
                  </q-item>

                </q-list>
              </div>

              <div class="col-12-col col-md-6 row q-col-gutter-md">
                <div class="col-12 col-md-6">
                  <q-input
                    readonly
                    label="URL da área do cliente"
                    v-model="urlAreaCliente"
                  >
                    <template v-slot:append>
                      <q-btn
                        flat
                        round
                        dense
                        @click="copyText(urlAreaCliente, 'url', 'a')"
                        icon="fa fa-clipboard"
                        size="12px"
                      />
                    </template>
                  </q-input>
                </div>

                <div class="col-12 col-md-6">
                  <q-input
                    readonly
                    label="Token de acesso via api"
                    v-model="opcoes.areacliente_token_acesso"
                  >
                    <template v-slot:append>
                      <q-btn
                        flat
                        round
                        dense
                        @click="copyText(opcoes.areacliente_token_acesso, 'token', 'o')"
                        icon="fa fa-clipboard"
                        size="12px"
                      />
                      <q-btn
                        flat
                        round
                        dense
                        class="q-ml-sm"
                        @click="renovarToken()"
                        icon="fa fa-refresh"
                        size="12px"
                      />
                    </template>
                  </q-input>
                </div>

                <div class="col-12 col-md-6">
                  <q-input
                    bottom-slots
                    label="Texto de contato no rodapé"
                    v-model="opcoes.areacliente_contato"
                  />
                </div>

                <div class="col-12 col-md-6">
                  <q-input
                    bottom-slots
                    label="Redirecionar após confirmar"
                    hint="Ao confirmar o cadastro redirecionar para o seguinte endereço"
                    v-model="opcoes.areacliente_site_redirection"
                  />
                </div>

                <div class="col-12 col-md-6">
                  <q-input
                    bottom-slots
                    label="Mensagem passo 01"
                    hint="Mensagem ao atualizar cadastro da empresa"
                    v-model="opcoes.areacliente_mensagem_cabecalho1"
                  />
                </div>

                <div class="col-12 col-md-6">
                  <q-input
                    bottom-slots
                    label="Mensagem passo 02"
                    hint="Mensagem para atualizar dados dos sócios da empresa"
                    v-model="opcoes.areacliente_mensagem_cabecalho2"
                  />
                </div>
              </div>

            </div>
          </div>
        </q-card-section>

        <q-card-actions class="q-pb-md q-pr-md" align="right">
          <q-btn
            label="Salvar"
            color="positive"
            @click="salvaOpcoes()"
            :loading="loadingSalvaOpcoes"
          />
        </q-card-actions>

      </q-card>

    </div>

  </q-page>
</template>

<script>
import axios from '@/axios-auth';
import { copyToClipboard } from 'quasar';
import { mapGetters } from 'vuex';

export default {
  name: 'area-cliente',
  meta: {
    title: 'DSB | Área do Cliente',
  },
  data() {
    return {
      loadingOpcoes: false,
      loadingSalvaOpcoes: false,
      hasVideo: false,
      urlAreaCliente: '',
      opcoes: {
        areacliente_token_acesso: '',
        areacliente_cnpj: '',
        areacliente_pode_adicionar_cartao: 0,
        areacliente_pode_ver_imposto: 0,
        areacliente_pode_ver_extrato: 0,
        areacliente_capital_social: 0,
        areacliente_pode_editar: 0,
        areacliente_sem_login: 0,
      },
    };
  },
  methods: {
    // Opções Avançadas
    loadOpcoes() {
      this.loadingOpcoes = true;

      axios
        .get('/cadastros/opcoesAreaCliente')
        .then(res => {
          this.loadingOpcoes = false;

          if (res.error && res.error.response.status === 403) {
            this.$q.notify({
              message: res.error.response.data.message,
              position: 'top-right',
              color: 'red',
            });
            return;
          }

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              position: 'top-right',
              color: 'orange',
            });
            return;
          }

          this.opcoes = res.data.opcoes;
          this.urlAreaCliente = res.data.url_area_cliente;

        })
        .catch(() => {
          this.loadingOpcoes = false;
          this.$q.notify({
            message: 'Houve um erro ao carregar as opções da área do cliente',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    renovarToken() {
      this.$q.dialog({
        title: 'Confirmar',
        message: `
          Deseja realmente renovar o token?<br/>
          Todos os dispositivos serão desconectados!
        `,
        cancel: true,
        persistent: true,
        html: true,
      }).onOk(() => {
        this.salvaOpcoes(true);
      });
    },
    salvaOpcoes(atualizarToken = false) {
      if (this.loadingSalvaOpcoes) return;

      this.loadingSalvaOpcoes = true;

      const params = {};
      const data = JSON.parse(JSON.stringify(this.opcoes));

      if (atualizarToken) {
        params.token = 1;
      }

      axios
        .post('/cadastros/opcoesAreaCliente', data, { params })
        .then(res => {
          this.loadingSalvaOpcoes = false;

          if (res.error && res.error.response.status === 403) {
            this.$q.notify({
              message: res.error.response.data.message,
              position: 'top-right',
              color: 'red',
            });
            return;
          }

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) return;

          if (atualizarToken) {
            this.opcoes.areacliente_token_acesso = res.data.opcoes.areacliente_token_acesso;
          }
        })
        .catch(() => {
          this.loadingSalvaOpcoes = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar as opções da área do cliente',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    // Util
    breakLine(value) {
      return value.replace(/,/g, '<br>');
    },
    copyText(text, nomeItemCopia, artigo = 'o') {
      nomeItemCopia = nomeItemCopia.charAt(0).toUpperCase() + nomeItemCopia.slice(1);

      copyToClipboard(text)
        .then(() => {
          this.$q.notify({
            message: `${nomeItemCopia} copiad${artigo}`,
            color: 'green',
            position: 'top-right',
          });
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao tentar copiar seu texto',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "area_cliente");

      if (!this.videosManuais.video_area_cliente) {
        this.modalIntro();
      }
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "area_cliente");
    },
  },
  computed: {
    ...mapGetters([
      "videosManuais",
      "manuaisDisponiveis"
    ])
  },
  mounted() {
    this.loadOpcoes();
    this.verificarPrimeiroVideo();
  },
};
</script>

<style></style>
