<template>
  <div id="modalEnviarRelatorioRme">
    <q-dialog v-model="openDialogEnviarRelatorioRme" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Detalhamento de Notas</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <autocomplete
            class="q-mr-md"
            title="Selecionar Cadastro"
            :filter="filterCadastro"
            :carregando="carregandoCadastro"
            :options="optionsCadastros"
            :formatDisplay="formatDisplay"
            :create="createValue"
            @resultAutocomplete="resultEmitRecebe"
            :display="displayMontar"
            tipoConsulta="cadastro"
            :cadastro="abreCadastro"
            :disable="!canAddEmail"
          />

          <q-select
            label="Emails"
            v-model="emails"
            use-input
            use-chips
            multiple
            hide-dropdown-icon
            input-debounce="0"
            hint="O limite de emails é 5"
            :new-value-mode="canAddEmail ? 'add-unique' : undefined"
            :error="Boolean(errors.emails)"
          >
            <template v-slot:errors>
              <span
                v-for="(error, i) in errors.emails"
                :key="error + i"
              >
                {{ error }}
              </span>
            </template>
          </q-select>
        </q-card-section>

        <q-card-actions class="text-primary justify-end">
          <q-btn
            flat
            label="Enviar Email"
            clickable
            @click="enviarEmail()"
            :loading="loadingEnviar"
            class="bg-positive text-white"
            style="margin: 10px 22px; margin-top: 0;"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { bus } from "@/main";

export default {
  name: "modal-enviar-relatorio-rme",
  data() {
    return {
      emails: [],
      openDialogEnviarRelatorioRme: false,
      loadingEnviar: false,
      errors: {},
      movconta: {},
      // Autocomplete
      carregandoCadastro: false,
      optionsCadastros: [],
    };
  },
  methods: {
    closeModal() {
      this.openDialogEnviarRelatorioRme = false;
    },
    enviarEmail() {
      this.loadingEnviar = true;

      const data = {
        emails: this.emails.join(","),
      };

      axios
        .post(`/financeiro/enviar-relatorio-rme/${this.movconta.id}`, data)
        .then(res => {
          this.loadingEnviar = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? "green" : "orange",
            position: "top-right",
          });

          if (!res.data.success) return;

          const link = document.createElement("a");
          link.href = res.data.arquivo.public_url;
          link.setAttribute("type", "hidden");
          window.open(link, "_blank");

          this.closeModal();
          bus.$emit("fecharModalDetalharNotas");
        })
        .catch(() => {
          this.loadingEnviar = false;
          this.$q.notify({
            message: "Houve um erro ao enviar email de relatório de RME",
            color: "red",
            position: "top-right",
          });
        });
    },
    // Autocomplete methods
    filterCadastro(val, update, abort) {
      update(() => {
        if (val === null) return abort();

        this.carregandoCadastro = true;

        axios
          .get("/cadastros?ativo=1&cliente=1&busca=" + val)
          .then(res => {
            const cadastros = res.data.data.cadastros
              .filter(cadastro => cadastro.email && !this.emails.includes(cadastro.email));

            this.carregandoCadastro = false;
            this.optionsCadastros = cadastros;
          });
      });
    },
    formatDisplay(result) {
      return result.nome;
    },
    createValue(val, done) {
      this.$q.notify({
        message: "Cadastro não foi encontrado.",
        color: "orange",
        position: "top-right"
      });
    },
    resultEmitRecebe(result) {
      if (!result.email || this.emails.includes(result.email)) return;

      this.emails.push(result.email);
      this.$root.$emit("limpar");
    },
    displayMontar(value) {
      return !value.nome ? value.name : value.nome;
    },
    abreCadastro(value) {
      this.$store.dispatch("cadastro", {
        nome: value.name,
        noSearch: true,
      });
    },
  },
  computed: {
    canAddEmail() {
      return this.emails.join(",").split(",").length < 5;
    },
  },
  mounted() {
    bus.$on("abrirModalEnviarRelatorioRme", movconta => {
      this.openDialogEnviarRelatorioRme = true;
      this.emails = [];
      this.errors = {};
      this.movconta = movconta;
    });
  },
  destroyed() {
    bus.$off("abrirModalEnviarRelatorioRme");
  },
};
</script>

<style lang="stylus" scoped></style>
