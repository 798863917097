<template>
  <q-page id="arquivos">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <div class="col-12">
          <div id="upload">
            <div class="q-pa-md">
              <div class="q-col-gutter-md row">
                <q-btn
                  dense
                  flat
                  size="12px"
                  style="margin-bottom: 10px; padding: 0 5px 0 5px"
                  icon="arrow_back"
                  label="Voltar para a página anterior"
                  to="receitas"
                ></q-btn>
                <q-space />
                <div class="on-right" style="position: relative; top: -10px">
                  <q-linear-progress style="width: 300px" :value="percent/100" class="q-mt-md" />
                  <small
                    class="right"
                    style="margin-right: 10px"
                  >{{ocupado}}/{{tamanho}} ({{percent}}%)</small>
                </div>
              </div>
            </div>
            <div
              class="q-uploader column no-wrap"
              style="width: 100%"
              @dragover="onDragover"
              @drop="onDrop"
              @dragleave="onDragleave"
              @mouseover="onHover"
              @mouseleave="offHover"
            >
              <div class="q-uploader__header">
                <div class="q-uploader__header-content flex flex-center no-wrap q-gutter-xs">
                  <a
                    v-if="arrayFile && arrayFile.length > 0"
                    tabindex="0"
                    class="q-btn inline q-btn-item non-selectable q-btn--flat q-btn--rectangle q-btn--dense"
                  >
                    <div tabindex="-1" class="q-focus-helper"></div>
                    <q-btn
                      dense
                      flat
                      icon="clear_all"
                      class="q-btn__content text-center col items-center q-anchor--skip justify-center row"
                      @click="apagaTodos()"
                    />
                  </a>
                  <div class="col column justify-center">
                    <div class="q-uploader__title">Selecione ou arraste os arquivos...</div>
                  </div>

                  <div>

                    <q-btn-dropdown flat class="q-mr-md">
                      <template v-slot:label>
                        <q-icon name="file_download" />
                      </template>

                      <q-list>
                        <q-item aria-hidden="true">
                          <q-item-section
                            class="text-uppercase text-grey-7"
                            style="font-size: 0.7rem"
                          >
                            Modelos para Importação
                          </q-item-section>
                        </q-item>

                        <q-item
                          v-for="option in optionsTipo"
                          tag="a"
                          target="_blank"
                          clickable
                          v-close-popup
                          :key="option.value"
                          :href="option.link"
                        >
                          <q-item-section>
                            <q-item-label>{{ option.label }}</q-item-label>
                          </q-item-section>
                        </q-item>

                      </q-list>
                    </q-btn-dropdown>

                    <a
                      tabindex="0"
                      class="q-btn inline q-btn-item non-selectable q-btn--flat q-btn--rectangle q-focusable q-hoverable q-btn--dense"
                    >
                      <div tabindex="-1" class="q-focus-helper"></div>
                      <div
                        class="q-btn__content text-center col items-center q-anchor--skip justify-center row"
                        style="width: 30px; cursor: pointer"
                        >
                        <label for="file">
                          <i
                            class="material-icons"
                            style="font-size: 25px; cursor: pointer"
                          >cloud_upload</i>
                          <input
                            type="file"
                            id="file"
                            style="width: 0"
                            multiple
                            class="file"
                            @change="onFileSelected"
                          />
                        </label>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="q-uploader__list scroll">
                <div v-for="(file, i) in arrayFile" :key="file.name" style="margin-bottom: 8px">
                  <div class="q-uploader__file relative-position">
                    <div class="q-uploader__file-header row flex-center no-wrap">
                      <i
                        v-if="file.error"
                        aria-hidden="true"
                        class="material-icons q-icon text-negative q-uploader__file-status"
                        >warning</i>
                      <div class="q-uploader__file-header-content col">
                        <div class="q-uploader__title">{{file.fileName}}</div>
                        <div
                          class="q-uploader__subtitle row items-center no-wrap"
                        >{{file.fileSize}} / {{file.progress}}</div>
                      </div>
                      <q-btn
                        round
                        dense
                        flat
                        icon="clear"
                        @click="apagaItem(i)"
                        :loading="file.uploading"
                      >
                        <template v-slot:loading>
                          <q-spinner color="grey-10" size="1em" />
                        </template>
                      </q-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <q-table
            title="Receitas"
            :data="arquivos"
            :columns="columns"
            row-key="id"
            :loading="buscando"
            :bottom="false"
            :pagination.sync="pagination"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
            :visible-columns="visibleColumns"
          >
            <template v-slot:bottom>
              <div></div>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td
                  key="id"
                  v-if="visibleColumns.indexOf('id') !== -1"
                  class="text-right"
                  >{{props.row.id}}</q-td>
                <q-td key="cadastro" v-if="visibleColumns.indexOf('cadastro') !== -1">
                  <router-link :to="'/cadastros/' + props.row.cadastro_id">{{props.row.cadastro_id}}</router-link>
                </q-td>
                <q-td key="lido" v-if="visibleColumns.indexOf('lido') !== -1" class="text-center">
                  <q-badge v-if="props.row.lido && props.row.tipo === 'retorno'" color="green">Sim</q-badge>
                  <q-badge v-if="!props.row.lido && props.row.tipo === 'retorno'" color="grey">Não</q-badge>
                </q-td>
                <q-td
                  key="created"
                  v-if="visibleColumns.indexOf('created') !== -1"
                  class="text-center"
                >{{props.row.created | formatDateTime}}</q-td>
                <q-td
                  key="total"
                  v-if="visibleColumns.indexOf('total') !== -1"
                  class="text-right"
                >{{props.row.count_registros}}</q-td>
                <q-td key="count" v-if="visibleColumns.indexOf('count') !== -1">
                  <q-btn
                    v-if="props.row.count_notfound > 0"
                    :label="props.row.count_notfound"
                    dense
                    flat
                    size="11px"
                    class="gray_border"
                    @click="detalhar(props.row, 'notfound')"
                  >
                    <template v-slot:default>
                      <q-tooltip>
                        {{ props.row.tipo === 'extrato' ? 'Novos' : 'Não Encontrados' }}
                      </q-tooltip>
                    </template>
                  </q-btn>
                  <q-btn
                    v-if="props.row.count_registrados > 0"
                    :label="props.row.count_registrados"
                    dense
                    flat
                    size="11px"
                    class="blue_border"
                    @click="props.row.tipo !== 'extrato' ? detalhar(props.row, 'registrados') : null"
                  >
                    <template v-slot:default>
                      <q-tooltip content-class="bg-blue">
                        {{ props.row.tipo === 'extrato' ? 'Itens' : 'Registrados' }}
                      </q-tooltip>
                    </template>
                  </q-btn>
                  <q-btn
                    v-if="props.row.count_errors > 0"
                    :label="props.row.count_errors"
                    dense
                    flat
                    size="11px"
                    class="red_border"
                    @click="detalhar(props.row, 'errors')"
                  >
                    <template v-slot:default>
                      <q-tooltip content-class="bg-red">Erro no Registro</q-tooltip>
                    </template>
                  </q-btn>
                  <q-btn
                    v-if="props.row.count_liquidados > 0"
                    :label="props.row.count_liquidados"
                    dense
                    flat
                    size="11px"
                    class="green_border"
                    @click="props.row.tipo !== 'extrato' ? detalhar(props.row, 'liquidados') : null"
                  >
                    <template v-slot:default>
                      <q-tooltip content-class="bg-green">
                        {{ props.row.tipo === 'extrato' ? 'Repetidos' : 'Liquidados' }}
                      </q-tooltip>
                    </template>
                  </q-btn>
                  <q-btn
                    v-if="props.row.count_baixados > 0"
                    :label="props.row.count_baixados"
                    dense
                    flat
                    size="11px"
                    class="orange_border"
                    @click="detalhar(props.row, 'baixados')"
                  >
                    <template v-slot:default>
                      <q-tooltip content-class="bg-orange">Baixados</q-tooltip>
                    </template>
                  </q-btn>
                  <q-btn
                    v-if="props.row.count_protesto > 0"
                    :label="props.row.count_protesto"
                    dense
                    flat
                    size="11px"
                    class="purple_border"
                    @click="detalhar(props.row, 'protesto')"
                  >
                    <template v-slot:default>
                      <q-tooltip content-class="bg-purple">Protesto</q-tooltip>
                    </template>
                  </q-btn>
                  <q-btn
                    v-if="props.row.count_outros > 0"
                    :label="props.row.count_outros"
                    dense
                    flat
                    size="11px"
                    class="pink_border"
                   @click="detalhar(props.row, 'outros')"
                  >
                    <template v-slot:default>
                      <q-tooltip content-class="bg-pink">Outros</q-tooltip>
                    </template>
                  </q-btn>
                  <q-btn
                    v-if="props.row.count_cadastros > 0"
                    :label="props.row.count_cadastros"
                    dense
                    flat
                    size="11px"
                    class="gray_border"
                    @click="detalhaCadastro(props.row)"
                  >
                    <template v-slot:default>
                      <q-tooltip content-class="gray-blue">Cadastros</q-tooltip>
                    </template>
                  </q-btn>
                </q-td>
                <q-td key="nome" v-if="visibleColumns.indexOf('nome') !== -1">{{props.row.nome}}</q-td>
                <q-td key="tipo" v-if="visibleColumns.indexOf('tipo') !== -1">
                  <span v-if="props.row.gateway !== null">{{props.row.gateway}} -</span>
                  {{props.row.tipo}}
                </q-td>
                <q-td class="tdfora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn
                        @click="importaArquivo(props.row)"
                        size="12px"
                        class="bg-positive text-white"
                        label="Selecionar"
                        :loading="buscaPlanilha"
                        v-if="props.row.tipo === 'planilha'"
                      ></q-btn>
                      <q-btn
                        @click="retorno(props.row)"
                        size="12px"
                        class="bg-orange text-white"
                        label="Processar"
                        v-if="props.row.tipo === 'retorno'"
                        :disable="loadingProcessar"
                        >
                        <template v-slot:loading>
                          <q-spinner color="grey-10" size="1em" />
                        </template>
                      </q-btn>
                      <q-btn
                        @click="conciliar(props.row)"
                        size="12px"
                        class="bg-orange text-white"
                        label="Conciliar"
                        v-if="props.row.tipo === 'extrato'"
                        >
                      </q-btn>
                      <q-btn
                        @click="detalhar(props.row)"
                        class="bg-blue-7 text-white"
                        icon="info"
                        size="12px"
                        v-if="props.row.tipo === 'retorno' && props.row.lido"
                      >
                        <template v-slot:loading>
                          <q-spinner color="grey-10" size="1em" />
                        </template>
                      </q-btn>
                      <q-btn
                        @click="baixaArquivo(props.row)"
                        size="12px"
                        class="bg-primary text-white"
                        style="width: 75px;"
                        icon="file_download"
                        :label="props.row.download"
                        :loading="downloading"
                      >
                        <template v-slot:loading>
                          <q-spinner color="grey-10" size="1em" />
                        </template>
                      </q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:top="props">
              <div class="col-1 q-table__title">Arquivos</div>
              <q-space />
              <q-btn
                flat
                dense
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="btnTipo === 'Todos' ? 'Tipos de Arquivos' : btnTipo"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Tipo de Arquivos</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': btnTipo === key}"
                      v-for="(key, val) in tipos"
                      :key="key + val"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="changeFilterArquivo(key, val)"
                    >
                      <q-item-section>{{ key }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-select
                v-model="visibleColumns"
                multiple
                borderless
                dense
                options-dense
                :display-value="'Colunas'"
                emit-value
                map-options
                :options="columns"
                option-value="name"
                style="min-width: 100px"
              />
              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                rounded
                no-wrap
                v-ripple
                class="q-ml-sm q-px-md bg-white text-black"
                no-caps
                icon="more_vert"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Mais Opções</q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="resetPaging(), listar()"
                    >
                      <q-item-section>Atualizar</q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="props.toggleFullscreen"
                    >
                      <q-item-section>{{props.inFullscreen ? 'Minimizar' : 'Maximizar Tela'}}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </template>
          </q-table>
        </div>
      </div>
    </div>
    <modal-detalhar-arquivo
      :cadastrosContent="cadastrosContent"
      :contents="contents"
      :arquivo="arquivo"
      :filtroTipo="filtroTipo"
      :pagingDetalhar="pagingDetalhar"
      :loadDetalhamento="loadDetalhamento"
      :OpenDialogDetalharArquivo="OpenDialogDetalharArquivo"
      @onClose="setOpenDialogDetalharArquivo"
    ></modal-detalhar-arquivo>
    <modal-planilha-arquivo
      :planilha="planilhaP"
      :arquivo="arquivoPlanilha"
      :optionsTipo="optionsTipo"
      :OpenDialogPlanilhaArquivo="OpenDialogPlanilhaArquivo"
      @onClose="setOpenDialogPlanilhaArquivo(false)"
    ></modal-planilha-arquivo>
    <modal-nova-conciliacao
      :OpenDialogNovaConciliacao="OpenDialogNovaConciliacao"
      :arquivo="selectedFile"
      :Isconta="contaArquivoJaLido"
      @onClose="setOpenDialogNovaConciliacao">
    </modal-nova-conciliacao>

  </q-page>
</template>

<script>
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";
export default {
  name: "arquivos",
  meta: {
    title: "DSB | Arquivos"
  },
  data() {
    return {
      arquivos: [],
      paging: {
        page: 0,
        count: 0,
        prevPage: false,
        nextPage: true,
        pageCount: 0
      },
      total: 0,
      buscando: false,
      downloading: false,
      loadContent: false,
      loadDetalhamento: false,
      contents: [],
      arquivo: {},
      pagingDetalhar: {},
      roberto: {},
      ocupado: "",
      percent: null,
      tamanho: "",
      tipos: [],
      btnTipo: "Tipos de Arquivos",
      planilhaP: [],
      optionsTipo: [],
      arquivoPlanilha: {},
      buscaPlanilha: false,
      cadastrosContent: [],
      visibleColumns: JSON.parse(localStorage.getItem("arquivos__visibleColumns")) || [
        "id",
        "lido",
        "created",
        "total",
        "count",
        "nome",
        "tipo"
      ],
      columns: [
        {
          name: "id",
          label: "ID",
          align: "right",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "cadastro",
          label: "Cadastro",
          align: "left",
          field: row => row.cadastro_id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "lido",
          required: true,
          align: "center",
          label: "Processado",
          field: row => row.lido,
          sortable: true
        },
        {
          name: "created",
          align: "center",
          label: "Criado em",
          field: row => row.created,
          sortable: true
        },
        {
          name: "total",
          align: "right",
          label: "Total",
          field: row => row.count_registros,
          sortable: true
        },
        {
          name: "count",
          align: "left",
          label: "Detalhamento",
          field: row => row.name,
          sortable: false
        },
        {
          name: "nome",
          align: "left",
          label: "Nome",
          field: row => row.nome,
          sortable: true
        },
        {
          name: "tipo",
          align: "left",
          label: "Tipo",
          field: row => row.tipo,
          sortable: true
        }
      ],
      pagination: {
        sortBy: "id",
        descending: true,
        page: 1,
        rowsPerPage: 0
      },
      OpenDialogDetalharArquivo: false,
      OpenDialogPlanilhaArquivo: false,
      OpenDialogNovaConciliacao: false,
      contaArquivoJaLido: null,
      errors: [],
      selectedFile: null,
      uploadProgress: "",
      uploading: false,
      success: true,
      porcentagem: 0,
      fileName: "",
      fileSize: null,
      arrayFile: [],
      filtroTipo: null,
      loadingProcessar: false
    };
  },
  methods: {
    setOpenDialogDetalharArquivo(value){
      this.OpenDialogDetalharArquivo = value;
    },
    setOpenDialogPlanilhaArquivo(value){
      this.OpenDialogPlanilhaArquivo = value;
    },
    setOpenDialogNovaConciliacao(value){
      this.OpenDialogNovaConciliacao = value;
    },
    changeFilterArquivo(key, val) {
      if (this.buscando) return;
      if (key === "Todos") {
        this.tipo = "";
        this.btnTipo = key;
      } else {
        this.tipo = key;
        this.btnTipo = key;
      }
      this.resetPaging();
      this.listar();
    },
    detalhaCadastro(value) {
      this.cadastrosContent = [];
      this.contents = [];
      this.arquivo = value;
      axios.get("/arquivos/cadastros/" + value.id).then(res => {
        if (res.data.success) {
          this.cadastrosContent = res.data.data.cadastros;
          this.pagingDetalhar = res.data.data.paging;
          if (this.cadastrosContent && this.cadastrosContent.length === 0) {
            Notify.create({
              message: "Arquivo Vazio",
              color: "orange",
              position: "top-right"
            });
            this.OpenDialogDetalharArquivo = false;
          } else {
            this.OpenDialogDetalharArquivo = true;
          }
        }
      });
    },
    detalhar(value, filtro) {
      this.contents = [];
      this.cadastrosContent = [];
      this.loadContent = true;
      this.arquivo = value;
      this.filtroTipo = filtro;
      let q = {};
      if (this.filtroTipo){
        q = {
          tipo: this.filtroTipo
        };
      }
      axios.get("/arquivos/contents/" + value.id, {params: q}).then(res => {
        if (res.data.success) {
          this.contents = res.data.data.contents;
          this.pagingDetalhar = res.data.data.paging;
          this.loadContent = false;
          if (this.contents.length === 0) {
            Notify.create({
              message: "Arquivo Vazio",
              color: "orange",
              position: "top-right"
            });
          } else {
            this.OpenDialogDetalharArquivo = true;
          }
        }
      });
    },
    mostrarMais(value) {
      if (!value.nextPage) return;
      this.loadDetalhamento = true;
      axios
        .get("/arquivos/contents/" + this.arquivo.id, {
          params: {
            page: value.page + 1
          }
        })
        .then(res => {
          this.contents = this.contents.concat(res.data.data.contents);
          this.pagingDetalhar = res.data.data.paging;
          this.loadDetalhamento = false;
        });
    },
    resetPaging() {
      let paging = {
        page: 0,
        count: 0,
        prevPage: false,
        nextPage: true,
        pageCount: 0
      };
      this.arquivos = [];
      this.paging = paging;
      this.total = 0;
    },
    dirsize() {
      axios.get("/arquivos/dirsize").then(res => {
        if (res.data.success) {
          this.ocupado = res.data.data.ocupado;
          this.percent = res.data.data.percent;
          this.tamanho = res.data.data.tamanho;
        }
      });
    },
    listar() {
      if (this.buscando) {
        return;
      }
      if (!this.paging.nextPage) {
        return;
      }
      let dados = {
        page: JSON.parse(JSON.stringify(this.paging.page))
      };
      if (this.tipo !== "") {
        dados.tipo = this.tipo;
      }
      this.buscando = true;
      if (this.$route.query.dtini) {
        dados.dtini = this.$route.query.dtini;
      }
      if (this.$route.query.dtfim) {
        dados.dtfim = this.$route.query.dtfim;
      }
      if (this.$route.query.cadastro_id) {
        dados.cadastro_id = this.$route.query.cadastro_id;
      }
      dados.page += 1;
      axios
        .get("/arquivos", {
          params: dados
        })
        .then(res => {
          if (res.data.success) {
            this.arquivos = this.arquivos.concat(res.data.arquivos);
            this.optionsTipo = res.data.optionsTipo;
            this.paging = res.data.paging;
            this.total += this.paging.current;
            this.tipos = res.data.tipos;
            this.tipos.push("Todos");
            this.buscando = false;
            this.OpenDialogDetalharArquivo = false;
            this.OpenDialogPlanilhaArquivo = false;
          }
        })
        .catch(() => {
          this.buscando = false;
        });
    },
    baixaArquivo(value) {
      this.downloading = true;
      axios
        .get("/arquivos/get-url/" + value.id)
        .then(response => {
          Notify.create({
            message: "Iniciando o Download de " + response.data.fileName,
            color: "green",
            position: "top-right"
          });
          const link = document.createElement("a");
          link.href = response.data.downloadUrl;
          link.setAttribute("type", "hidden");
          window.open(link, "_blank");
          this.downloading = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível baixar o arquivo, ou você não possui permissão!",
            color: "green",
            position: "top-right"
          });
          this.downloading = false;
        });
    },
    importaArquivo(value) {
      this.buscaPlanilha = true;
      axios
        .get("/financeiro/importar/" + value.id)
        .then(res => {
          if (res.data.status) {
            this.planilhaP = res.data.planilha;
            this.arquivoPlanilha = res.data.arquivo;
            this.OpenDialogPlanilhaArquivo = true;
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          this.buscaPlanilha = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.buscaPlanilha = false;
        });
    },
    retorno(arquivo) {
      if (this.loadingProcessar) return;
      this.loadingProcessar = true;

      if (arquivo.lido) {
        Dialog.create({
          title: arquivo.nome,
          message:
            "Este arquivo já foi processado. Deseja fazer o processamento novamente?",
          cancel: "Cancelar",
          persistent: true
        })
          .onOk(() => {
            Loading.show();
          })
          .onOk(() => {
            axios
              .get("/financeiro/retorno/" + arquivo.id)
              .then(res => {
                if (res.data.status) {
                  this.resetPaging();
                  this.listar();
                  Dialog.create({
                    title: "Arquivo processado com sucesso!",
                    class: "bg-positive text-white",
                    ok: {
                      "text-color": "green",
                      color: "white"
                    }
                  });
                } else {
                  Notify.create({
                    message: res.data.errors,
                    color: "orange",
                    position: "top-right"
                  });
                }
              })
              .then(() => this.loadingProcessar = false)
              .catch(() => {
                Dialog.create({
                  title: "Não foi possível contactar ou não possui permissão!",
                  class: "bg-negative text-white",
                  ok: {
                    "text-color": "red",
                    color: "white"
                  }
                });
                this.loadingProcessar = false;
              });
          })
          .onCancel(() => {
            Loading.hide();
            this.loadingProcessar = false;
          })
          .onDismiss(() => {
            Loading.hide();
          });
      } else {
        this.processarRetorno(arquivo);
      }
    },
    processarRetorno(arquivo) {
      axios
        .get("/financeiro/retorno/" + arquivo.id)
        .then(res => {
          if (res.data.status) {
            this.resetPaging();
            this.listar();
            Notify.create({
              message: "Arquivo processado com sucesso!",
              color: "green",
              position: "top-right"
            });
          } else {
            Notify.create({
              message: res.data.errors,
              color: "orange",
              position: "top-right"
            });
          }
        })
        .then(() => this.loadingProcessar = false)
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível selecionar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.loadingProcessar = false;
        });
    },
    conciliar(arquivo){
      this.$store.dispatch("contas");
      this.selectedFile = arquivo;
      this.contaArquivoJaLido = null;

       axios
        .get("/financeiro/conciliar?arquivo_id="+arquivo.id+"&getConta=true")
        .then(res => {
          if(res.data.conta){
           this.contaArquivoJaLido = res.data.conta;
          }
           this.OpenDialogNovaConciliacao = true;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    handleScroll() {
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.listar();
      }
    },
    apagaTodos() {
      this.arrayFile = [];
    },
    apagaItem(p) {
      for (let i = 0; i < this.arrayFile.length; i++) {
        if (this.arrayFile[i].uploading) {
          return;
        }
      }
      this.arrayFile.splice(p, 1);
    },
    offHover() {
      event.preventDefault();
      // this.onHoverAqui = false;
    },
    onHover() {
      event.preventDefault();
      // this.onHoverAqui = true;
    },
    onDragleave() {
      event.preventDefault();
      // this.onHoverAqui = false;
    },
    onDragover() {
      event.preventDefault();
      // this.onHoverAqui = true;
    },
    onDrop(event) {
      event.stopPropagation();
      event.preventDefault();
      var files = event.dataTransfer.files;
      this.selectedFile = files;
      let tam = this.arrayFile.length;
      let j;
      for (let i = 0; i < this.selectedFile.length; i++) {
        this.arrayFile.push({
          fileName: this.selectedFile[i].name,
          fileSize: this.bytesToSize(this.selectedFile[i].size),
          success: false,
          error: false,
          message: "",
          progress: "0%",
          uploading: false
        });
        j = tam + i;
        this.onUpload(this.selectedFile[i], this.arrayFile[j]);
      }
      //   $("#cardUpload").removeClass("onDrop");
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    percentage() {
      return "width: " + this.percent.toFixed(2) + "%";
    },
    onFileSelected(event) {
      // this.selectedFile = []
      this.selectedFile = event.target.files;
      let tam = this.arrayFile.length;
      let j;
      for (let i = 0; i < this.selectedFile.length; i++) {
        this.arrayFile.push({
          fileName: this.selectedFile[i].name,
          fileSize: this.bytesToSize(this.selectedFile[i].size),
          success: false,
          error: false,
          message: "",
          progress: "0%",
          uploading: false,
          delivered: false
        });
        j = tam + i;
        this.onUpload(this.selectedFile[i], this.arrayFile[j]);
      }
    },
    onUpload(arq, arr) {
      arr.uploading = true;
      const fd = new FormData();
      fd.append("file", arq, arq.name);
      axios
        .post(axios.defaults.baseURL + "/arquivos/upload", fd, {
          onUploadProgress: uploadEvent => {
            arr.progress =
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
          }
        })
        .then(res => {
          if (res.data.success) {
            arr.message = res.data.message;
            this.resetPaging();
            this.listar();
          } else {
            arr.error = true;
            arr.message = res.data.message ?? 'Erro ao fazer upload: ';
            Notify.create({
              message: arr.message + "  " + arr.fileName,
              color: "orange",
              position: "top-right"
            });
          }
          arr.success = res.data.success;
          arr.uploading = false;
          arr.delivered = true;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível fazer o upload do arquivo" +
              arr.fileName +
              ", ou você não possui permissão para isto.",
            color: "red",
            position: "top-right"
          });
          arr.success = false;
          arr.error = true;
          arr.uploading = false;
          arr.progress = "0%";
        });
    }
  },
  watch: {
    visibleColumns() {
      localStorage.setItem("arquivos__visibleColumns", JSON.stringify(this.visibleColumns));
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    let query = this.$route.query;
    if (query.tipo){
      this.tipo = query.tipo;
    }
    this.dirsize();
    this.listar();
  },
  computed: {
    dataEmpresa() {
      return this.$store.getters.dataEmpresa;
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  }
};
</script>

<style lang="stylus" scoped>
.blue_border {
  border: 1px solid #2196f3;
  margin: 0 2px 0 2px;
}

.red_border {
  border: 1px solid #f44336;
  margin: 0 2px 0 2px;
}

.green_border {
  border: 1px solid #4caf50;
  margin: 0 2px 0 2px;
}

.orange_border {
  border: 1px solid #ff9800;
  margin: 0 2px 0 2px;
}

.purple_border {
  border: 1px solid #9c27b0;
  margin: 0 2px 0 2px;
}

.pink_border {
  border: 1px solid #e91e63;
  margin: 0 2px 0 2px;
}

.gray_border {
  border: 1px solid #ccc;
  margin: 0 2px 0 2px;
}
</style>
