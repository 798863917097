import axios from "@/axios-auth";

const state = {
  openDialogManuais: false,
  openDialogTour: false,
  manuaisDisponiveis: [],
  manuais: [],
  manual: {
    titulo: '',
    tipo: '',
    link: '',
    extras: [],
    icone: ''
  }
};

const getters = {
  openDialogManuais: state => state.openDialogManuais,
  openDialogTour: state => state.openDialogTour,
  manuaisDisponiveis: state => state.manuaisDisponiveis,
  manuais: state => state.manuais,
  manual: state => state.manual
};

const mutations = {
  openDialogManuais: (state, userData) => {
    state.openDialogManuais = userData;
  },
  openDialogTour: (state, userData) => {
    state.openDialogTour = userData;
  },
  manuaisDisponiveis: (state, userData) => {
    state.manuaisDisponiveis = userData;
  },
  manuais: (state, userData) => {
    state.manuais = userData;
  },
  manual: (state, userData) => {
    state.manual = userData;
  }
};

const actions = {
  loadManuais: ({ commit, dispatch }) => {
    axios
      .get("/users/manuais")
      .then(res => {
        commit("manuais", res.data.manuais);
        dispatch("checkManuaisDisponiveis");
      });
  },
  loadManual: ({ commit }, manual) => {
    if (state.manuais.length == 0) return;

    const searchManual = state.manuais.filter(item => item.local === manual);
    if (searchManual.length == 0) return;

    const manualSelecionado = searchManual[0];

    if (manualSelecionado.tipo === "link_externo") {
      window.open(manualSelecionado.link, "_blank");
      return;
    }

    commit("manual", manualSelecionado);

    if (manualSelecionado.local === "tour") {
      commit("openDialogTour", true);
      return;
    }

    commit("openDialogManuais", true);
  },
  checkManuaisDisponiveis: ({ commit }) => {
    const manuais = state.manuais;

    let manuaisExistentes = [];
    manuais.forEach(manual => {
      manuaisExistentes.push(manual.local);
    });

    commit("manuaisDisponiveis", manuaisExistentes);
  },
  setVisualizado({ commit }, manual) {
    axios
    .post('users/perfil', {
      action: 'manuais',
      manual: `${manual.tipo}_${manual.local}`
    })
    .then(res => {
      commit('videosManuais', res.data.videosManuais);
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
