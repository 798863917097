import axios from "@/axios-auth";
import router from "@/router/router";
import { bus } from "@/main";
import { Notify, Loading, Dialog } from "quasar";
let moment = require("moment");

const state = {
  despesas: [],
  despesaQrCode: null,
  despesa: {
    cadastro: null,
    cadastro_id: null,
    descricao: "",
    id: null,
    liquidacao: null,
    liquidado: false,
    referencia: "",
    status: "Aberto",
    tipo: null,
    valor: 0,
    valor_liquidado: 0,
    vencimento: moment().format("DD/MM/YYYY"),
    valor_acrecimo: 0,
    valor_desconto: 0,
    planodeconta_item: false,
  },
  pagingDespesas: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  filtrosDespesas: {
    status: "Aberto",
    dtini: "",
    dtfim: "",
    busca: null,
    cadastro_id: null,
    movconta_id: null,
    status_pagamento: 'Todos',
  },
  recordCountDespesas: {
    aberto: 0,
    agendado: 0,
    liquidado: 0,
    cancelado: 0
  },
  totalDespesas: 0,
  filtrosDrpDespesas: "Aberto",
  statusDespesas: {
    aberto: "Aberto",
    agendado: "Agendado",
    liquidado: "Liquidado",
    cancelado: "Cancelado"
  },
  counterDespesas: 0,
  buscandoDes: false,
  dataDespesas: {
    dtini: "",
    dtfim: ""
  },
  pulseRapidoDespesas: false,
  pulseCountDespesas: 0,
  marcadoDespesas: false,
  marquinhosDespesas: false,
  mTodosDespesas: false,
  allDespesas: false,
  contadorDespesas: 0,
  aguardeEmailDes: false,
  itemsDespesas: [],
  timelineDespesas: [],
  errorsDespesas: [],
  pagingTimeDespesas: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  despesasAgrupadas: false,
  selectediesDespesas: [],
  callDespesa: false,
  OpenDialogNovaDespesa: false,
  OpenDialogLiquidarMovconta: false,
  OpenOpcoesAvancadasDespesas: false,
  OpenDialogCancelarConfirmarDespesas: false,
  OpenDialogAlterarPlanoDeContas: false,
  OpenDialogAlterarContaBancaria: false,
  OpenDialogAlterarVencimento: false,
  OpenDialogAlterarReferencia: false,
  OpenDialogDetalharMovconta: false,
  isPermissoDespesas: true,
  selectedDespesas: [],
  loadFiltrosQueryDespesas: false,
  cadastroDespesa: {
    isCadastro: false,
    cadastro: {
      cadastro_id: null,
      cadastro_nome: null
    }
  },
  movimentacaoDespesa: {},
  OpenDialogEditarMovconta: false,
  loadEditarMovconta: false,
  metadadosDespesas: {
    valor_total: 0.00,
  },
  buscarPorLiquidacaoDespesas: 0,
  statusPagamentoLista: [],
  statusPagamento: "Todos"
};

const mutations = {
  selectedDespesas: (state, userData) => {
    state.selectedDespesas = userData;
  },
  despesas: (state, userData) => {
    state.despesas = userData;
  },
  pagingDespesas: (state, userData) => {
    state.pagingDespesas = userData;
  },
  filtrosDespesas: (state, userData) => {
    state.filtrosDespesas = userData;
  },
  recordCountDespesas: (state, userData) => {
    state.recordCountDespesas = userData;
  },
  totalDespesas: (state, userData) => {
    state.totalDespesas = userData;
  },
  filtrosDrpDespesas: (state, userData) => {
    state.filtrosDrpDespesas = userData;
  },
  counterDespesas: (state, userData) => {
    state.counterDespesas = userData;
  },
  buscandoDes: (state, userData) => {
    state.buscandoDes = userData;
  },
  dataDespesas: (state, userData) => {
    state.dataDespesas = userData;
  },
  marcarDesmarcarTodosDespesas: (state, userData) => {
    state.pulseRapidoDespesas = userData.pulseRapidoDespesas;
    state.pulseCountDespesas = userData.pulseCountDespesas;
    state.marcadoDespesas = userData.marcadoDespesas;
    state.marquinhosDespesas = userData.marquinhosDespesas;
    state.mTodosDespesas = userData.mTodosDespesas;
  },
  allDespesas: (state, userData) => {
    state.allDespesas = userData;
  },
  contadorDespesas: (state, userData) => {
    state.contadorDespesas = userData;
  },
  despesa: (state, userData) => {
    if (!userData.movcontas_rateios) {
      userData.movcontas_rateios = [];
    }

    state.despesa = userData;
  },
  aguardeEmailDes: (state, userData) => {
    state.aguardeEmailDes = userData.aguardeEmailDes;
  },
  itemsDespesas: (state, userData) => {
    state.itemsDespesas = userData;
  },
  timelineDespesas: (state, userData) => {
    state.timelineDespesas = userData;
  },
  errorsDespesas: (state, userData) => {
    state.errorsDespesas = userData;
  },
  pagingTimeDespesas: (state, userData) => {
    state.pagingTimeDespesas = userData;
  },
  despesasAgrupadas: (state, userData) => {
    state.despesasAgrupadas = userData;
  },
  selectediesDespesas: (state, userData) => {
    state.selectediesDespesas = userData;
  },
  callDespesa: (state, userData) => {
    state.callDespesa = userData;
  },
  OpenDialogNovaDespesa: (state, userData) => {
    state.OpenDialogNovaDespesa = userData;
  },
  OpenDialogLiquidarMovconta: (state, userData) => {
    state.OpenDialogLiquidarMovconta = userData;
  },
  setOpenDialogEditarMovconta: (state, userData) => {
    state.OpenDialogEditarMovconta = userData;
  },
  OpenOpcoesAvancadasDespesas: (state, userData) => {
    state.OpenOpcoesAvancadasDespesas = userData;
  },
  OpenDialogCancelarConfirmarDespesas: (state, userData) => {
    state.OpenDialogCancelarConfirmarDespesas = userData;
  },
  OpenDialogDetalharMovconta: (state, userData) => {
    state.OpenDialogDetalharMovconta = userData;
  },
  OpenDialogAlterarPlanoDeContas: (state, userData) => {
    state.OpenDialogAlterarPlanoDeContas = userData;
  },
  OpenDialogAlterarContaBancaria: (state, userData) => {
    state.OpenDialogAlterarContaBancaria = userData;
  },
  OpenDialogAlterarVencimento: (state, userData) => {
    state.OpenDialogAlterarVencimento = userData;
  },
  OpenDialogAlterarReferencia: (state, userData) => {
    state.OpenDialogAlterarReferencia = userData;
  },
  isPermissoDespesas: (state, userData) => {
    state.isPermissoDespesas = userData;
  },
  setLoadFiltrosQueryDespesas: (state, userData) => {
    state.loadFiltrosQueryDespesas = userData;
  },
  cadastroDespesa: (state, userData) => {
    state.cadastroDespesa.isCadastro = userData.isCadastro;
    state.cadastroDespesa.cadastro = userData.cadastro;
  },
  setMovimentacaoDespesa: (state, userData) => {
    state.movimentacaoDespesa = userData;
  },
  setLoadEditarMovconta: (state, userData) => {
    state.loadEditarMovconta = userData;
  },
  setMetadadosDespesas: (state, userData) => {
    state.metadadosDespesas = userData;
  },
  buscarPorLiquidacaoDespesas: (state, userData) => {
    state.buscarPorLiquidacaoDespesas = userData;
  },
  despesaQrCode: (state, userData) => {
    state.despesaQrCode = userData;
  },
  statusPagamentoLista: (state, userData) => {
    state.statusPagamentoLista = userData;
  },
  statusPagamento: (state, userData) => {
    state.statusPagamento = userData;
  }
};

const actions = {
  clearCheckbox({ commit }) {
    commit("selectediesReceitas", {
      selectediesReceitas: []
    });
    commit("selectedDespesas", []);
  },
  listarDespesas: ({ commit, state, dispatch }) => {
    if (state.buscandoDes) return;
    if (!state.pagingDespesas.nextPage) {
      return;
    }
    commit("isPermissoDespesas", true);

    const query = router.history.current.query;

    if (!state.loadFiltrosQueryDespesas && Object.keys(query).length) {
      commit("setLoadFiltrosQueryDespesas", true);
      if (query.cadastro_id) {
        commit("filtrosDespesas", {
          ...state.filtrosDespesas,
          cadastro_id: query.cadastro_id
        });
      }
      if (query.dtini) {
        commit("filtrosDespesas", {
          ...state.filtrosDespesas,
          dtini: query.dtini
        });
      }
      if (query.dtfim) {
        commit("filtrosDespesas", {
          ...state.filtrosDespesas,
          dtfim: query.dtfim
        });
      }
      if (query.status) {
        commit("filtrosDespesas", {
          ...state.filtrosDespesas,
          status: query.status
        });
        commit("filtrosDrpDespesas", query.status);
      }
      if (query.movconta_id) {
        commit("filtrosDespesas", {
          ...state.filtrosDespesas,
          movconta_id: query.movconta_id
        });
      }
      if (query.liquidacao !== undefined) {
        commit("buscarPorLiquidacaoDespesas", query.liquidacao);
      }
    }

    commit("buscandoDes", true);

    if (Object.keys(query).length) {
      let newQueryParams = [];

      for (let queryParam in query) {
        if (state.filtrosDespesas[queryParam] !== undefined) {
          newQueryParams.push(
            queryParam + "=" + state.filtrosDespesas[queryParam]
          );
        }
      }

      if (state.buscarPorLiquidacaoDespesas) {
        newQueryParams.push("liquidacao=1");
      }

      let newUrl = "?" + newQueryParams.join("&");

      window.history.replaceState(state.filtrosDespesas, "", newUrl);
    }

    let statusPagamento = null;
    if (state.filtrosDespesas.status_pagamento !== "Todos")  {
      statusPagamento = state.filtrosDespesas.status_pagamento;
    }

    axios
      .get("/financeiro/despesas", {
        params: {
          page: state.pagingDespesas.page + 1,
          status: state.filtrosDespesas.status,
          dtini: state.filtrosDespesas.dtini,
          dtfim: state.filtrosDespesas.dtfim,
          busca: state.filtrosDespesas.busca,
          cadastro_id: state.filtrosDespesas.cadastro_id,
          movconta_id: state.filtrosDespesas.movconta_id,
          liquidacao: state.buscarPorLiquidacaoDespesas,
          status_pagamento: statusPagamento
        }
      })
      .then(res => {
        if (res.data.success) {
          commit("despesas", state.despesas.concat(res.data.data.movcontas));
          commit("pagingDespesas", res.data.data.paging);
          commit("filtrosDespesas", res.data.data.filters);
          commit("recordCountDespesas", res.data.data.record_count);
          commit("dataDespesas", {
            dtini: res.data.data.filters.dtini,
            dtfim: res.data.data.filters.dtfim
          });
          commit(
            "totalDespesas",
            state.totalDespesas + state.pagingDespesas.current
          );
          commit("setMetadadosDespesas", res.data.data.metadados);
          commit("statusPagamentoLista", res.data.data.status_pagamento_lista);
        }
        commit("buscandoDes", false);
        // dispatch("displayDateDespesas");
      })
      .catch(() => {
        // Notify.create({
        //   message: "Não foi possível listar as despesas. Atualize sua página!",
        //   color: "red",
        //   position: "top-right"
        // });
        commit("buscandoDes", false);
        commit("isPermissoDespesas", false);
      });
  },
  setAllDespesas: ({ commit }) => {
    commit("allDespesas", !state.allDespesas);
    if (state.allDespesas) {
      commit("counterDespesas", state.pagingDespesas.count);
    } else {
      commit(
        "counterDespesas",
        JSON.parse(JSON.stringify(state.totalDespesas))
      );
    }
  },
  //   displayDateDespesas: ({ commit }) => {
  //     let value = state.filtrosDespesas.dtini;
  //     if (!value) return "";
  //     commit("dataDespesas", {
  //       dataArranjada: moment.utc(value).format("DD"),
  //       anoAtual: moment.utc(value).format("YYYY")
  //     });
  //   },
  changeFilterStatusDespesas: ({ commit, dispatch }, userData) => {
    if (state.buscandoDes) return;
    commit("filtrosDrpDespesas", userData.status);
    commit("filtrosDespesas", {
      status: userData.status,
      busca: state.filtrosDespesas.busca,
      dtini: state.filtrosDespesas.dtini,
      dtfim: state.filtrosDespesas.dtfim,
      cadastro_id: state.filtrosDespesas.cadastro_id,
      movconta_id: state.filtrosDespesas.movconta_id,
      status_pagamento: state.filtrosDespesas.status_pagamento
    });
    dispatch("resetPagingDespesas");
    dispatch("listarDespesas");
  },
  resetPagingDespesas: ({ commit }) => {
    commit("pagingDespesas", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });
    commit("despesas", []);
    commit("totalDespesas", 0);
    commit("counterDespesas", 0);
  },
  filtrosDespesas: ({ commit, dispatch }, userData) => {
    if (userData) {
      commit("filtrosDespesas", {
        busca: state.filtrosDespesas.busca,
        dtini: userData.dtini,
        dtfim: userData.dtfim,
        cadastro_id: state.filtrosDespesas.cadastro_id,
        status: state.filtrosDespesas.status,
        movconta_id: state.filtrosDespesas.movconta_id,
        status_pagamento: state.filtrosDespesas.status_pagamento
      });
    } else {
      commit("filtrosDespesas", {
        busca: state.filtrosDespesas.busca,
        dtini: state.filtrosDespesas.dtini,
        dtfim: state.filtrosDespesas.dtfim,
        cadastro_id: state.filtrosDespesas.cadastro_id,
        status: state.filtrosDespesas.status,
        movconta_id: state.filtrosDespesas.movconta_id,
        status_pagamento: state.filtrosDespesas.status_pagamento
      });
    }
    dispatch("clearCheckbox");
    dispatch("resetPagingDespesas");
    dispatch("listarDespesas");
  },
  changeFilterPeriodoDespesas: ({ commit, dispatch, state }, userData) => {
    if (state.buscandoDes) return;

    let dtini = state.dataDespesas.dtini.split("-");
    let dtfim = state.dataDespesas.dtfim.split("-");

    let i = moment(new Date(dtini[2], dtini[1] - 1, dtini[0]));
    let f = moment(new Date(dtfim[2], dtfim[1] - 1, dtfim[0]));

    if (userData === "prev") {
      i.subtract(1, "months");
      f.subtract(1, "months");
    }
    if (userData === "next") {
      i.add(1, "months");
      f.add(1, "months");
    }
    commit("filtrosDespesas", {
      ...state.filtrosDespesas,
      page: 1,
      dtini: i.startOf("month").format("DD-MM-YYYY"),
      dtfim: f.endOf("month").format("DD-MM-YYYY")
    });
    // dispatch("displayDateDespesas");
    dispatch("clearCheckbox");
    dispatch("resetPagingDespesas");
    dispatch("listarDespesas");
    dispatch("clearCheckbox");
  },
  marcarDesmarcarTodosDespesas: ({ commit, state }) => {
    if (state.pulseCountDespesas === 1) {
      commit("marcarDesmarcarTodosDespesas", {
        pulseCountDespesas: 0,
        pulseRapidoDespesas: state.pulseRapidoDespesas,
        marcadoDespesas: state.marcadoDespesas,
        marquinhosDespesas: state.marquinhosDespesas,
        mTodosDespesas: state.mTodosDespesas
      });
    } else {
      commit("marcarDesmarcarTodosDespesas", {
        pulseRapidoDespesas: true,
        pulseCountDespesas: state.pulseCountDespesas,
        marcadoDespesas: state.marcadoDespesas,
        marquinhosDespesas: state.marquinhosDespesas,
        mTodosDespesas: state.mTodosDespesas
      });
    }
    let max = state.despesas.length;
    let des = state.despesas;
    commit("marcarDesmarcarTodosDespesas", {
      marcadoDespesas: !state.marcadoDespesas,
      pulseRapidoDespesas: state.pulseRapidoDespesas,
      pulseCountDespesas: state.pulseCountDespesas,
      marquinhosDespesas: state.marquinhosDespesas,
      mTodosDespesas: state.mTodosDespesas
    });
    if (!state.marcadoDespesas) {
      commit("allDespesas", {
        allDespesas: false
      });
    }
    for (let i = 0; i < max; i++) {
      des[i].marcado = state.marcadoDespesas;
      commit("despesas", des);
    }
    if (state.marcadoDespesas) {
      commit(
        "counterDespesas",
        JSON.parse(JSON.stringify(state.totalDespesas))
      );
      for (let i = 0; i < max; i++) {
        // $("tr.item" + i).addClass("marcou");
      }
      commit("marcarDesmarcarTodosDespesas", {
        marquinhosDespesas: true,
        mTodosDespesas: true,
        marcadoDespesas: state.marcadoDespesas,
        pulseRapidoDespesas: state.pulseRapidoDespesas,
        pulseCountDespesas: state.pulseCountDespesas
      });
    } else {
      commit("counterDespesas", 0);
      for (let i = 0; i < max; i++) {
        // $("tr.item" + i).removeClass("marcou");
      }
      commit("marcarDesmarcarTodosDespesas", {
        marquinhosDespesas: false,
        mTodosDespesas: false,
        marcadoDespesas: state.marcadoDespesas,
        pulseRapidoDespesas: state.pulseRapidoDespesas,
        pulseCountDespesas: state.pulseCountDespesas
      });
      for (let i = 0; i < max; i++) {
        // $("tr.item" + i).removeClass("marcou");
      }
    }
    setTimeout(() => {
      commit("marcarDesmarcarTodosDespesas", {
        marquinhosDespesas: state.marquinhosDespesas,
        mTodosDespesas: state.mTodosDespesas,
        marcadoDespesas: state.marcadoDespesas,
        pulseRapidoDespesas: false,
        pulseCountDespesas: 1
      });
    }, 5000);
  },
  marcaItemDespesas: ({ commit }, userData) => {
    // let trClass = $(".item" + userData.val).attr("class");
    let contador = 0;
    let counter = 0;
    if (
      trClass === undefined ||
      trClass === "item" + userData.val + " marcou"
    ) {
      //   $("tr.item" + userData.val).removeClass("marcou");
      contador = state.contadorDespesas + 1;
      counter = state.counterDespesas - 1;
      commit("contadorDespesas", contador);
      commit("counterDespesas", counter);
    } else {
      //   $("tr.item" + userData.val).addClass("marcou");
      counter = state.counterDespesas + 1;
      commit("counterDespesas", counter);
    }
  },
  editarDespesas: ({ commit, dispatch }, userData) => {
    dispatch("itemsDespesasClear");

    let url = `/financeiro/edit/${userData.id}`;
    if (userData.is_despesas_recorrencia) {
      url = `/financeiro/view-despesas-recorrencia/${userData.despesas_recorrencia_id}`;
    }

    axios
      .get(url)
      .then(res => {
        if (!res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right",
          });

          return;
        }

        let despesa = userData;

        if (!despesa.is_despesas_recorrencia) {
          despesa = res.data.movconta;

          commit("itemsDespesas", despesa.movcontas_items);
        } else {
          const despesasRecorrencia = res.data.despesas_recorrencia;

          despesa.vencimento_original = moment(despesa.vencimento, "YYYY/MM/DD").format("DD/MM/YYYY");
          despesa.planodeconta = despesasRecorrencia.planodeconta;
          despesa.conta = despesasRecorrencia.conta;
          despesa.gateway = despesasRecorrencia.gateway;
          despesa.cadastros_gateway = despesasRecorrencia.cadastros_gateway;
          despesa.movcontas_rateios = despesasRecorrencia.despesas_recorrencias_rateios;
        }

        commit("errorsDespesas", []);
        commit("despesa", despesa);
        commit("setOpenDialogEditarMovconta", true);
      })
      .catch(() => {
        Notify.create({
          message: "Houve um erro ao recuperar despesa",
          color: "red",
          position: "top-right",
        });
      });
  },
  liquidarDespesas: ({ commit }, userData) => {
    commit("errorsDespesas", []);
    commit("cadastroDespesa", {
      isCadastro: userData.isCadastro ? userData.isCadastro : false,
      cadastro: {}
    });
    commit("despesa", userData);
    commit("OpenDialogLiquidarMovconta", true);
  },
  excluirDespesas: async ({ commit, dispatch }, userData) => {
    commit("aguardeEmailDes", {
      aguardeEmailDes: true
    });

    let options = undefined;
    let motivo = '';

    try {
      const res = await axios.get(`/financeiro/edit/${userData.id}`);

      if (!res.data.success) {
        throw res.data;
      }

      userData = res.data.movconta;
      userData.movcaixa_criado = res.data.movcaixa_criado;
      userData.movcaixa_liquidado = res.data.movcaixa_criado;
    } catch (err) {
      Dialog.create({
        title: "Não foi possível recuperar a despesa!",
        class: "bg-negative text-white",
        ok: {
          "text-color": "red",
          color: "white"
        }
      });
      return;
    }

    const items = [];

    if (userData.relacionada) {
      items.push({
        label: "Cancelar despesas relacionadas? (Apenas as em aberto)",
        value: "relacionadas",
      });
    }

    if (userData.movcaixa_criado) {
      const dtmovimentoCaixa = moment(userData.movcaixa_criado.dtmovimento, "YYYY/MM/DD").format("DD/MM/YYYY");
      items.push({
        label: `Cancelar saldo no ${userData.movcaixa_criado.caixa_nome} ID ${userData.movcaixa_criado.id} ${dtmovimentoCaixa}?`,
        value: "alterar_saldo",
      });
    }

    if (items.length > 0) {
      options = {
        type: "checkbox",
        model: [],
        items: items,
      };
    }

    Dialog.create({
      title: "Descreva o Motivo de Cancelamento",
      cancel: "Cancelar",
      ok: "Continuar",
      prompt: {
        model: '',
        isValid: val => val.length > 2,
      },
      persistent: true
    }).onOk(data => {
      motivo = data;

      Dialog.create({
        title: "Deseja cancelar esta despesa?",
        message: '<strong>Despesa: </strong>' + userData.id + ' - ' + userData.cadastro.nome + '<br/><strong>Motivo: </strong>'+ motivo,
        options: options,
        cancel: "Cancelar",
        ok: "Confirmar",
        persistent: true,
        html: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(data => {
          userData.relacionadas = data && data.includes("relacionadas");
          userData.alterar_saldo = data && data.includes("alterar_saldo");
          userData.motivo = motivo;

          axios
            .post("/financeiro/delete/" + userData.id, userData)
            .then(res => {
              commit("aguardeEmailDes", {
                aguardeEmailDes: false
              });

              if (!res.data.success) {
                Dialog.create({
                  title: res.data.msg || res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
                dispatch("resetPagingDespesas");
                dispatch("listarDespesas");
                return;
              }

              commit(
                "despesas",
                state.despesas.splice(state.despesas.indexOf(userData), 1)
              );
              Dialog.create({
                title: res.data.msg || res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              dispatch("resetPagingDespesas");
              dispatch("listarDespesas");

              if (router.history.current.path === "/extrato-cliente") {
                bus.$emit("loadExtratoCliente");
              }
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível cancelar a receita, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
              commit("aguardeEmailDes", {
                aguardeEmailDes: false
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
          commit("aguardeEmailDes", {
            aguardeEmailDes: false
          });
        })
        .onDismiss(() => {
          Loading.hide();
        });


    }).onCancel(() => {
      Loading.hide();
      commit("aguardeEmailRec", {
        aguardeEmailRec: false
      });
    });

  },
  reativaDespesas: ({ commit, dispatch }, userData) => {
    commit("aguardeEmailDes", {
      aguardeEmailDes: true
    });
    Dialog.create({
      title: "Confirma a reativação da despesa?",

      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        commit("despesa", userData);
        userData.action = "reativar";
        axios
          .post("/financeiro/edit/" + userData.id, userData)
          .then(res => {
            if (res.data.status) {
              Dialog.create({
                title: "Lançamento " + res.data.movconta.id + " reativado!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              commit(
                "despesas",
                state.despesas.splice(state.despesas.indexOf(userData), 1)
              );
              dispatch("resetPagingDespesas");
              dispatch("listarDespesas");
            } else {
              Dialog.create({
                title: res.data.message || res.data.errors,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
            commit("aguardeEmailDes", {
              aguardeEmailDes: false
            });
          })
          .catch(() => {
            Dialog.create({
              title: "Não foi possível reativar, ou não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            commit("aguardeEmailDes", {
              aguardeEmailDes: false
            });
            dispatch("resetPagingDespesas");
            dispatch("listarDespesas");
          });
      })
      .onCancel(() => {
        Loading.hide();
        commit("aguardeEmailDes", {
          aguardeEmailDes: false
        });
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  estornarDespesas: ({ commit, dispatch }, userData) => {
    commit("aguardeEmailDes", {
      aguardeEmailDes: true
    });

    axios
      .get(`/financeiro/edit/${userData.id}`)
      .then(res => {
        if (!res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "red",
            position: "top-right",
          });
          commit("aguardeEmailRec", {
            aguardeEmailRec: false,
          });
          return;
        }

        let options = undefined;

        if (res.data.movcaixa_liquidado) {
          const dtmovimentoCaixa = moment(res.data.movcaixa_liquidado.dtmovimento, "YYYY/MM/DD").format("DD/MM/YYYY");
          options = {
            type: "checkbox",
            model: ["alterar_saldo"],
            items: [{
              label: `Cancelar liquidação no ${res.data.movcaixa_liquidado.caixa_nome} ID ${res.data.movcaixa_liquidado.id} ${dtmovimentoCaixa}?`,
              value: "alterar_saldo",
            }],
          };
        }

        Dialog.create({
          title: "Confirma o estorno da despesa?",
          options: options,
          cancel: "Cancelar",
          persistent: true
        })
          .onOk(() => {
            Loading.show();
          })
          .onOk(data => {
            commit("despesa", userData);
            userData.action = "estornar";
            userData.alterar_saldo = data && data.includes("alterar_saldo");

            axios
              .post("/financeiro/edit/" + userData.id, userData)
              .then(res => {
                commit("aguardeEmailDes", {
                  aguardeEmailDes: false
                });

                if (!res.data.success) {
                  Dialog.create({
                    title: res.data.message || res.data.errors,
                    class: "bg-warning text-white",
                    ok: {
                      "text-color": "orange",
                      color: "white"
                    }
                  });
                  dispatch("resetPagingDespesas");
                  dispatch("listarDespesas");
                  return;
                }

                Dialog.create({
                  title: "Lançamento " + res.data.movconta.id + " estornado!",
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
                commit(
                  "despesas",
                  state.despesas.splice(state.despesas.indexOf(userData), 1)
                );
                dispatch("resetPagingDespesas");
                dispatch("listarDespesas");

                if (router.history.current.path === "/extrato-cliente") {
                  bus.$emit("loadExtratoCliente");
                }
              })
              .catch(() => {
                Dialog.create({
                  title: "Não foi possível estornar, ou não possui permissão!",
                  class: "bg-negative text-white",
                  ok: {
                    "text-color": "red",
                    color: "white"
                  }
                });
                commit("aguardeEmailDes", {
                  aguardeEmailDes: false
                });
              });
          })
          .onCancel(() => {
            Loading.hide();
            commit("aguardeEmailDes", {
              aguardeEmailDes: false
            });
          })
          .onDismiss(() => {
            Loading.hide();
          });
    })
    .catch(() => {
      commit("aguardeEmailRec", {
        aguardeEmailRec: false,
      });
      Notify.create({
        message: "Não foi possível recuperar a despesa!",
        color: "red",
        position: "top-right",
      });
    });
  },
  detalharDespesas: ({ commit, dispatch }, userData) => {
    commit("receitaArquivos", []);
    dispatch("itemsDespesasClear");
    dispatch("timelineDespesasClear");
    commit("cadastroDespesa", {
      isCadastro: userData.isCadastro ? userData.isCadastro : false,
      cadastro: {}
    });

    if (Number.isInteger(userData)) {
      commit("buscandoItems", {
        buscandoItems: true
      });
      axios
        .get("/financeiro/items/" + userData)
        .then(res => {
          commit("buscandoItems", {
            buscandoItems: false
          });
          commit("OpenDialogDetalharMovconta", true);

          if (!res.data.success) return;
          if (res.data.data.arquivos) {
            commit("receitaArquivos", res.data.data.arquivos);
          }
          commit("itemsDespesas", res.data.data.items);
          commit("movimentacao", {
            movimentacao: res.data.data.movconta
          });
          commit("setMovimentacaoDespesa", res.data.data.movconta);
          dispatch("pagingTimeDespesas", res.data.data.movconta);
        })
        .catch(() => {
          Notify.create({
            message: "Não foi possível detalhar, ou não possui permissão!",
            color: "red",
            position: "top-right"
          });
          commit("buscandoItems", {
            buscandoItems: false
          });
        });
      return;
    }

    if (userData.is_despesas_recorrencia) {
      axios
        .get(`/financeiro/view-despesas-recorrencia/${userData.despesas_recorrencia_id}`)
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right",
            });

            return;
          }

          const despesa = JSON.parse(JSON.stringify(userData));
          const despesasRecorrencia = res.data.despesas_recorrencia;

          despesa.vencimento_original = moment(despesa.vencimento, "YYYY/MM/DD").format("DD/MM/YYYY");
          despesa.planodeconta = despesasRecorrencia.planodeconta;
          despesa.conta = despesasRecorrencia.conta;
          despesa.gateway = despesasRecorrencia.gateway;
          despesa.cadastros_gateway = despesasRecorrencia.cadastros_gateway;
          despesa.rateios = despesasRecorrencia.despesas_recorrencias_rateios
            .map(rateio => ({ ...rateio, nome_centrocusto: rateio.centrocusto.nome }));

          commit("movimentacao", {
            movimentacao: despesa,
          });
          commit("OpenDialogDetalharMovconta", true);
        })
        .catch(() => {
          Notify.create({
            message: "Houve um erro ao recuperar despesa",
            color: "red",
            position: "top-right",
          });
        });

      return;
    }

    commit("movimentacao", {
      movimentacao: userData
    });
    commit("buscandoItems", {
      buscandoItems: true
    });
    axios
      .get("/financeiro/items/" + userData.id)
      .then(res => {
        commit("buscandoItems", {
          buscandoItems: false
        });
        commit("OpenDialogDetalharMovconta", true);

        if (!res.data.success) return;
        if (res.data.data.arquivos) {
          commit("receitaArquivos", res.data.data.arquivos);
        }
        commit("itemsDespesas", res.data.data.items);
        commit("movimentacao", {
          movimentacao: res.data.data.movconta
        });
        commit("setMovimentacaoDespesa", res.data.data.movconta);
        dispatch("pagingTimeDespesas", userData);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível detalhar, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("buscandoItems", {
          buscandoItems: false
        });
      });
  },
  itemsDespesasClear: ({ commit }) => {
    commit("itemsDespesas", []);
  },
  timelineDespesasClear: ({ commit }) => {
    commit("timelineDespesas", []);
  },
  pagingTimeDespesas: ({ commit }, userData) => {
    axios.get("/timelines?movconta_id=" + userData.id).then(res => {
      if (res.data.success) {
        commit("timelineDespesas", {
          timelineDespesas: res.data.timelines,
          timelineAction: res.data.tipos
        });
        commit("pagingTimeDespesas", res.data.paging);
      }
    });
  },
  saveLiquidarDespesas: ({ commit, dispatch }, userData) => {
    let despesa = userData;
    commit("loadLiquidar", {
      loadLiquidar: true
    });
    despesa.action = "liquidar-despesa";
    axios
      .post("/financeiro/edit/" + despesa.id, despesa)
      .then(res => {
        if (!res.data.status) {
          commit("errorsDespesas", res.data.errors || {});
          commit("loadLiquidar", {
            loadLiquidar: false
          });
          return;
        }

        Notify.create({
          message: "Despesa " + res.data.movconta.id + " liquidada!",
          color: "green",
          position: "top-right"
        });
        commit(
          "despesas",
          state.despesas.splice(state.despesas.indexOf(state.despesa), 1)
        );
        dispatch("resetPagingDespesas");
        dispatch("listarDespesas");
        commit("OpenDialogLiquidarMovconta", false);
        commit("OpenOpcoesAvancadasDespesas", false);
        commit("loadLiquidar", {
          loadLiquidar: false
        });
        commit("cadastroDespesa", {
          isCadastro: false,
          cadastro: {}
        });
        dispatch("newDespesa");

        if (router.history.current.path === "/extrato-cliente") {
          bus.$emit("loadExtratoCliente");
        }
        bus.$emit("listarDespesas");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadLiquidar", {
          loadLiquidar: false
        });
      });
  },
  saveEditarDespesas: ({ commit, dispatch }, userData) => {
    let despesa = userData;
    despesa.action = "editar-despesa";

    commit("setLoadEditarMovconta", true);

    axios
      .post("/financeiro/edit/" + despesa.id, despesa)
      .then(res => {
        if (!res.data.status) {
          if (res.data.message) {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right",
            });
          }

          commit("errorsDespesas", res.data.errors);
          commit("setLoadEditarMovconta", false);
          return;
        }

        Notify.create({
          message: "Despesa " + res.data.movconta.id + " editada!",
          color: "green",
          position: "top-right",
        });
        dispatch("resetPagingDespesas");
        dispatch("listarDespesas");
        dispatch("newDespesa");

        commit("setOpenDialogEditarMovconta", false);
        commit("setLoadEditarMovconta", false);
        commit("cadastroDespesa", {
          isCadastro: false,
          cadastro: {}
        });

        bus.$emit("listarDespesas");
        bus.$emit("closeModalEditarMovconta");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou não possui permissão!",
          color: "red",
          position: "top-right",
        });
        commit("setLoadEditarMovconta", false);
      });
  },
  newDespesa: ({ commit }) => {
    commit("despesa", {
      cadastro: null,
      cadastro_id: null,
      descricao: "",
      id: null,
      liquidacao: null,
      liquidado: false,
      referencia: "",
      status: "Aberto",
      tipo: null,
      valor: 0,
      valor_liquidado: 0,
      vencimento: ""
    });
  },
  acaoDespesasMassa: ({ commit, dispatch }) => {
    dispatch("contas");
    commit("despesasAgrupadas", true);
    commit("OpenDialogNovaDespesa", true);
  },
  realizarCancelarMassaDespesas: ({ commit, dispatch }, data) => {
    commit("realizandoAcaoReceitas", {
      realizandoAcaoReceitas: true
    });
    // dispatch("getSelectedDespesas");
    let dados = {
      ids: state.selectediesDespesas,
      all: state.allDespesas,
      busca: state.filtrosDespesas.busca,
      dtini: state.filtrosDespesas.dtini,
      dtfim: state.filtrosDespesas.dtfim,
      status: state.filtrosDespesas.status,
      cadastro_id: state.filtrosDespesas.cadastro_id,
      tipo: 1 // Despesa
    };
    if (data !== null && data !== undefined) {
      dados.template_id = data;
    }
    if (dados.cadastro_id === undefined) {
      dados.cadastro_id = null;
    }
    if (dados.all === true) {
      dados.all = 1;
    } else {
      dados.all = 0;
    }
    let acao = "/financeiro/delete";

    axios
      .post(acao, dados)
      .then(res => {
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
        commit("OpenDialogCancelarConfirmarDespesas", false);
        commit("OpenOpcoesAvancadasDespesas", false);

        if (!res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
          return;
        }

        Notify.create({
          message: res.data.message,
          color: "green",
          position: "top-right"
        });

        dispatch("resetPagingDespesas");
        dispatch("listarDespesas");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
      });
  },
  getSelectedDespesas: ({ commit }, userData) => {
    // if (state.selectediesDespesas.length > 0) {
    //   commit("selectediesDespesas", []);
    // }
    // let ids = [];
    // let max = state.despesas.length;
    // for (let i = 0; i < max; i++) {
    //   if (state.despesas[i].marcado) {
    //     ids.push(state.despesas[i].id);
    //   }
    // }
    commit("selectediesDespesas", userData);
  },
  loadMoreDespesas: ({ commit, dispatch }) => {
    if (state.pagingTimeDespesas.page >= state.pagingTimeDespesas.pageCount) {
      commit("btnDisabled", {
        btnDisabled: true
      });
      return;
    }
    let page = state.pagingTimeDespesas.page + 1;
    commit("pagingTimeDespesas", {
      ...state.pagingTimeDespesas,
      page: page
    });
    dispatch("getTimelineDespesas");
  },
  getTimelineDespesas: ({ commit, state }) => {
    if (state.pagingDespesas.page === 0) {
      commit("timelineDespesas", []);
    }
    let page = 1 + state.pagingDespesas.page;
    let dados = {
      page: page,
      movconta_id: state.movimentacaoDespesa.id,
    };
    axios
      .get("/timelines", {
        params: dados
      })
      .then(res => {
        if (res.data.success) {
          let time = res.data.timelines;
          commit("timelineDespesas", {
            timelineDespesas: state.timelineDespesas.timelineDespesas.concat(time),
            timelineAction: res.data.tipos,
          });
          commit("pagingTimeDespesas", {
            finder: res.data.paging.finder,
            page: res.data.paging.page,
            current: res.data.paging.current,
            count: res.data.paging.count,
            perPage: res.data.paging.perPage,
            prevPage: res.data.paging.prevPage,
            nextPage: res.data.paging.nextPage,
            pageCount: res.data.paging.pageCount,
            sort: res.data.paging.sort,
            direction: res.data.paging.direction,
            limit: res.data.paging.limit,
            sortDefault: res.data.paging.sortDefault,
            directionDefault: res.data.paging.directionDefault,
            scope: res.data.paging.scope
          });
        }
      });
  },
};

const getters = {
  despesas: state => {
    return state.despesas;
  },
  pagingDespesas: state => {
    return state.pagingDespesas;
  },
  filtrosDespesas: state => {
    return state.filtrosDespesas;
  },
  recordCountDespesas: state => {
    return state.recordCountDespesas;
  },
  totalDespesas: state => {
    return state.totalDespesas;
  },
  filtrosDrpDespesas: state => {
    return state.filtrosDrpDespesas;
  },
  statusDespesas: state => {
    return state.statusDespesas;
  },
  buscandoDes: state => {
    return state.buscandoDes;
  },
  dataDespesas: state => {
    return state.dataDespesas;
  },
  pulseCountDespesas: state => {
    return state.pulseCountDespesas;
  },
  pulseRapidoDespesas: state => {
    return state.pulseRapidoDespesas;
  },
  marcadoDespesas: state => {
    return state.marcadoDespesas;
  },
  marquinhosDespesas: state => {
    return state.marquinhosDespesas;
  },
  mTodosDespesas: state => {
    return state.mTodosDespesas;
  },
  counterDespesas: state => {
    return state.counterDespesas;
  },
  allDespesas: state => {
    return state.allDespesas;
  },
  despesa: state => {
    return state.despesa;
  },
  aguardeEmailDes: state => {
    return state.aguardeEmailDes;
  },
  itemsDespesas: state => {
    return state.itemsDespesas;
  },
  timelineDespesas: state => {
    return state.timelineDespesas.timelineDespesas;
  },
  timelineActionDespesas: state => {
    return state.timelineDespesas.timelineAction;
  },
  errorsDespesas: state => {
    return state.errorsDespesas;
  },
  pagingTimeDespesas: state => {
    return state.pagingTimeDespesas;
  },
  despesasAgrupadas: state => {
    return state.despesasAgrupadas;
  },
  callDespesa: state => {
    return state.callDespesa;
  },
  OpenDialogNovaDespesa: state => {
    return state.OpenDialogNovaDespesa;
  },
  OpenDialogLiquidarMovconta: state => {
    return state.OpenDialogLiquidarMovconta;
  },
  OpenOpcoesAvancadasDespesas: state => {
    return state.OpenOpcoesAvancadasDespesas;
  },
  OpenDialogCancelarConfirmarDespesas: state => {
    return state.OpenDialogCancelarConfirmarDespesas;
  },
  OpenDialogDetalharMovconta: state => {
    return state.OpenDialogDetalharMovconta;
  },
  OpenDialogAlterarPlanoDeContas: state => {
    return state.OpenDialogAlterarPlanoDeContas;
  },
  OpenDialogAlterarContaBancaria: state => {
    return state.OpenDialogAlterarContaBancaria;
  },
  OpenDialogAlterarVencimento: state => {
    return state.OpenDialogAlterarVencimento;
  },
  OpenDialogAlterarReferencia: state => {
    return state.OpenDialogAlterarReferencia;
  },
  selectediesDespesas: state => {
    return state.selectediesDespesas;
  },
  isPermissoDespesas: state => {
    return state.isPermissoDespesas;
  },
  selectedDespesas: state => {
    return state.selectedDespesas;
  },
  loadFiltrosQueryDespesas: state => {
    return state.loadFiltrosQueryDespesas;
  },
  cadastroDespesa: state => {
    return state.cadastroDespesa;
  },
  movimentacaoDespesa: state => {
    return state.movimentacaoDespesa;
  },
  OpenDialogEditarMovconta: state => {
    return state.OpenDialogEditarMovconta;
  },
  loadEditarMovconta: state => {
    return state.loadEditarMovconta;
  },
  metadadosDespesas: state => {
    return state.metadadosDespesas;
  },
  buscarPorLiquidacaoDespesas: state => {
    return state.buscarPorLiquidacaoDespesas;
  },
  despesaQrCode: state => {
    return state.despesaQrCode;
  },
  statusPagamentoLista: state => {
    return state.statusPagamentoLista;
  },
  statusPagamento: state => {
    return state.statusPagamento;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
