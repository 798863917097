<template>
  <div id="modal-nova-empresa">
    <q-dialog v-model="OpenDialogNovaEmpresa" persistent>
      <q-card style="max-width: 900px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Nova Empresa</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-5">
                <q-input
                  v-model="novaEmpresa.cnpj"
                  label="CNPJ"
                  bottom-slots
                  :error="errors.cnpj ? true : false"
                  mask="##.###.###/####-##"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.cnpj" :key="error + i">{{error}}</span>
                  </template>
                  <template v-if="novaEmpresa.cnpj" v-slot:append>
                    <q-btn
                      :loading="buscandoCNPJ"
                      name="search"
                      clickable
                      @click.stop="consultaCNPJ(novaEmpresa.cnpj)"
                      :disable="buscandoCNPJ"
                      icon="search"
                      dense
                      class="cursor-pointer bg-positive"
                      color="text-white"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-3">
                <q-input
                  v-model="novaEmpresa.tfls"
                  mask="###"
                  label="TFLs"
                  bottom-slots
                  :error="errors.tfls ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.tfls" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-4">
                <q-input
                  v-model="novaEmpresa.codigo"
                  label="Identificador"
                  bottom-slots
                  :error="errors.codigo ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.codigo" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="novaEmpresa.razao"
                  label="Razão"
                  bottom-slots
                  :error="errors.razao ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.razao" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="novaEmpresa.fantasia"
                  label="Fantasia"
                  bottom-slots
                  :error="errors.fantasia ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.fantasia" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-2">
                <q-input
                  v-model="novaEmpresa.cep"
                  label="CEP"
                  bottom-slots
                  :error="errors.cep ? true : false"
                  mask="#####-###"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.cep" :key="error + i">{{error}}</span>
                  </template>
                  <template v-if="novaEmpresa.cep" v-slot:append>
                    <q-btn
                      :loading="buscandoCEP"
                      name="search"
                      clickable
                      @click.stop="consultaCEP(novaEmpresa.cep)"
                      :disable="buscandoCEP"
                      icon="search"
                      dense
                      class="cursor-pointer bg-positive"
                      color="text-white"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-4">
                <q-input
                  v-model="novaEmpresa.cidade"
                  label="Cidade"
                  bottom-slots
                  :error="errors.cidade ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.cidade" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-2">
                <q-select
                  v-model="novaEmpresa.estado"
                  :options="estadosShort"
                  label="Estado"
                  bottom-slots
                  :error="errors.estado ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.estado" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-4">
                <q-input
                  v-model="novaEmpresa.bairro"
                  label="Bairro"
                  bottom-slots
                  :error="errors.bairro ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.bairro" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="novaEmpresa.endereco"
                  label="Endereço"
                  bottom-slots
                  :error="errors.endereco ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.endereco" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-2">
                <q-input
                  v-model="novaEmpresa.numero"
                  label="Número"
                  bottom-slots
                  :error="errors.numero ? true : false"
                  ref="numero2"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.numero" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-4">
                <q-input
                  v-model="novaEmpresa.complemento"
                  label="Complemento"
                  bottom-slots
                  :error="errors.complemento ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.complemento" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  disable
                  v-model="novaEmpresa.atividade"
                  label="Atividade"
                  bottom-slots
                  :error="errors.atividade ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.atividade" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="novaEmpresa.telefone"
                  label="Telefone"
                  bottom-slots
                  :error="errors.telefone ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.telefone" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <br />
                <br />
                <br />
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  type="submit"
                  :loading="loadModalEmpresa"
                  @click="save()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import globalAxios from "axios";
import axios from "@/axios-auth";
import { Notify } from "quasar";
export default {
  name: "modal-nova-empresa",
  data() {
    return {
      novaEmpresa: {
        razao: "",
        fantasia: "",
        cnpj: "",
        codigo: "",
        cep: "",
        cidade: "",
        estado: "",
        bairro: "",
        endereco: "",
        numero: "",
        created: "",
        modified: "",
        tfls: 1,
        complemento: "",
        telefone: "",
        atividade: ""
      },
      loadModalEmpresa: false,
      errors: [],
      perfil: {},
      buscandoCEP: false,
      buscandoCNPJ: false
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogNovaEmpresa", false);
    },
    consultaCNPJ() {
      if (!this.novaEmpresa.cnpj) {
        Notify.create({
          message: "CNPJ Vazio.",
          color: "orange",
          position: "top-right"
        });
        return;
      }

      let cnpj = this.novaEmpresa.cnpj;
      cnpj = cnpj.replace(/\D/g, "");

      // Expressão regular para validar o cnpj.
      let validacnpj = /^[0-9]{14}$/;

      // Valida o formato do CNPJ
      if (!validacnpj.test(cnpj) || cnpj === "") {
        if (cnpj !== "") {
          Notify.create({
            message: "CNPJ Inválido.",
            color: "orange",
            position: "top-right"
          });
          return;
        }
        Notify.create({
          message: "CNPJ Inválido ou vazio.",
          color: "orange",
          position: "top-right"
        });
        return;
      }

      let url = "/empresas/cnpj/" + cnpj;
      this.buscandoCNPJ = true;

      axios
        .get(url)
        .then(res => {
          let data = res.data;
          this.novaEmpresa.nome = data.nome;
          this.novaEmpresa.razao = data.nome;
          this.novaEmpresa.fantasia =  data.fantasia;
          this.novaEmpresa.cep = data.cep;
          this.novaEmpresa.cidade = data.municipio;
          this.novaEmpresa.estado = data.uf;
          this.novaEmpresa.bairro = data.bairro;
          this.novaEmpresa.endereco = data.logradouro;
          this.novaEmpresa.numero = data.numero;
          this.novaEmpresa.dtnascimento = data.abertura;
          this.novaEmpresa.atividade = data.atividade_principal[0].code+' - '+data.atividade_principal[0].text;
          this.buscandoCNPJ = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.buscandoCNPJ = false;
        });
    },
    consultaCEP() {
      if (!this.novaEmpresa.cep) {
        Notify.create({
          message: "CEP Vazio.",
          color: "orange",
          position: "top-right"
        });
        return;
      }

      let cep = this.novaEmpresa.cep;
      cep = cep.replace(/\D/g, "");
      // Expressão regular para validar o CEP.
      let validacep = /^[0-9]{8}$/;
      // Valida o formato do CEP.
      if (!validacep.test(cep) || cep === "") {
        if (cep !== "") {
          Notify.create({
            message: "CEP Inválido, digite novamente.",
            color: "orange",
            position: "top-right"
          });
          return;
        }
        return;
      }
      this.buscandoCEP = true;

      globalAxios
        .get("https://viacep.com.br/ws/" + cep + "/json/")
        .then(res => {
          let data = res.data;
          if (!("erro" in data)) {
            Notify.create({
              message: "CEP encontrado!",
              color: "green",
              position: "top-right"
            });
            this.novaEmpresa.endereco = data.logradouro;
            this.novaEmpresa.cidade = data.localidade;
            this.novaEmpresa.bairro = data.bairro;
            this.novaEmpresa.estado = data.uf;
            this.$refs.numero2.focus();
          } else {
            Notify.create({
              message: "CEP não encontrado.",
              color: "orange",
              position: "top-right"
            });
          }
          this.buscandoCEP = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!.",
            color: "red",
            position: "top-right"
          });
          this.buscandoCEP = false;
        });
    },
    save() {
      this.loadModalEmpresa = true;
      const dados = {
        razao: this.novaEmpresa.razao,
        fantasia: this.novaEmpresa.fantasia,
        cnpj: this.novaEmpresa.cnpj,
        codigo: this.novaEmpresa.codigo,
        cep: this.novaEmpresa.cep,
        cidade: this.novaEmpresa.cidade,
        estado: this.novaEmpresa.estado,
        bairro: this.novaEmpresa.bairro,
        endereco: this.novaEmpresa.endereco,
        numero: this.novaEmpresa.numero,
        tfls: this.novaEmpresa.tfls,
        complemento: this.novaEmpresa.complemento,
        telefone: this.novaEmpresa.telefone,
        atividade: this.novaEmpresa.atividade
      };
      axios
        .post("/empresas/add", dados)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: "Nova empresa criada!",
              color: "green",
              position: "top-right"
            });
            this.$store.commit("OpenDialogNovaEmpresa", false);
          } else {
            this.errors = res.data.errors;
            Notify.create({
              message: "Erro ao criar a empresa",
              color: "orange",
              position: "top-right"
            });
          }
          this.loadModalEmpresa = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível criar a nova empresa, ou você não possui permissão",
            color: "red",
            position: "top-right"
          });
        });
    }
  },
  computed: {
    // ...mapGetters(["OpenDialogNovaEmpresa"]),
    OpenDialogNovaEmpresa: {
      get() {
        return this.$store.getters.OpenDialogNovaEmpresa;
      },
      set() {}
    },
    user() {
      return this.$store.getters.dataUser;
    }
  }
};
</script>

<style lang="stylus" scoped></style>
