<template>
  <q-page id="cadastros">
    <div class="q-pa-md">
      <q-table
        dense
        title="Cadastros"
        :data="cadastros"
        :columns="columns"
        row-key="nome"
        :loading="buscandoCadastros"
        :bottom="false"
        :pagination.sync="pagination"
        selection="multiple"
        :selected.sync="selected"
        :no-data-label="'Nenhum dado'"
        :no-results-label="'Nenhum resultado obtido'"
        :loading-label="'Carregando dados'"
        :visible-columns="visibleColumns"
        >
        <template v-slot:bottom>
          <div></div>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
            <q-td auto-width>
              <q-checkbox v-model="props.selected" color="secondary" />
            </q-td>
            <q-td
              key="codigo"
              v-if="visibleColumns.indexOf('codigo') !== -1"
              class="text-right"
            >{{props.row.codigo ? props.row.codigo : ''}}</q-td>
            <q-td key="nome">
              <router-link
                style="text-decoration: none;color: #000"
                :to="'/cadastros/' + props.row.id"
              >
                {{
                  props.row
                    ? props.row.nome.length > 40
                      ? props.row.nome.substr(0, 40) + "..."
                      : props.row.nome
                    : "Sem Cadastro"
                }}
                <q-tooltip
                  v-if="
                    props.row.nome && props.row.nome.length > 40
                  "
                >
                  {{ props.row.nome }}
                </q-tooltip>
                <br v-if="props.row.cpfcnpj">
                <span v-if="props.row.cpfcnpj">{{props.row.cpfcnpj}}</span>
              </router-link>
            </q-td>
            <q-td
              key="email"
              v-if="visibleColumns.indexOf('email') !== -1"
              v-html="breakLine(props.row.email ? props.row.email : '')"
            ></q-td>
            <q-td
              key="telefone"
              v-if="visibleColumns.indexOf('telefone') !== -1"
              v-html="breakLine(props.row.telefone ? props.row.telefone : '')"
              class="text-right"
            ></q-td>
            <q-td
              key="cadastros_tags"
            >

              <q-badge
               class="q-ml-xs"
                outline
                color="primary"
                v-for="grupo in props.row.tags"
                :key="grupo.id">
                {{grupo.title}}

              </q-badge>
            </q-td>
            <q-td v-if="$q.platform.is.desktop" class="tdfora">
              <div class="divfora">
                <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                  <q-btn
                    @click="modal(props.row)"
                    size="12px"
                    class="bg-primary text-white"
                    icon="edit"
                  ></q-btn>
                  <q-btn
                    v-if="filtrosCadastros.ativo"
                    @click="inativaCadastro(props.row)"
                    class="bg-negative text-white"
                    icon="block"
                    size="12px"
                  ></q-btn>

                  <q-btn
                    v-if="!filtrosCadastros.ativo"
                    @click="reativaCadastro(props.row)"
                    class="bg-positive text-white"
                    icon="check"
                    size="12px"
                  ></q-btn>
                </div>
              </div>
            </q-td>
          </q-tr>
          <q-tr v-show="props.expand" :props="props">
            <q-td colspan="100%">
              <div class="q-py-sm q-gutter-sm">
                <q-btn
                  @click="modal(props.row)"
                  size="12px"
                  class="bg-primary text-white"
                  icon="edit"
                />
                <q-btn
                  v-if="filtrosCadastros.ativo"
                  @click="inativaCadastro(props.row)"
                  class="bg-negative text-white"
                  icon="block"
                  size="12px"
                />
                <q-btn
                  v-if="!filtrosCadastros.ativo"
                  @click="reativaCadastro(props.row)"
                  class="bg-positive text-white"
                  icon="check"
                  size="12px"
                />
              </div>
            </q-td>
          </q-tr>
        </template>
        <template v-slot:top="props">
          <q-btn
            flat
            round
            dense
            :icon="'add'"
            id="addCadastro"
            class="q-ml-md text-primary"
            style="margin-right: 10px;margin-left: 0"
            @click="modal()"
          />
          <div class="col-2 q-table__title">Cadastros</div>
          <q-btn
            v-if="allCadastro"
            flat
            dense
            no-wrap
            v-ripple
            color="secondary"
            no-caps
            :label="'Selecionar todos os ' + pagingCadastros.count + ' cadastros'"
            class="q-ml-sm q-px-md"
            :class="{'bg-white text-blue-7': !allCadastros, 'bg-green-7 text-white': allCadastros}"
            clickable
            @click="selecionarTodos"
          />
          <q-space />

          <q-btn
            v-if="$q.screen.gt.xs"
            dense
            flat
            no-wrap
            v-ripple
            color="primary"
            no-caps
            :label="tipo +' | '+  pagingCadastros.count "
            class="q-ml-sm q-px-md bg-white text-blue-7"
          >
            <q-menu anchor="top right" self="top right">
              <q-list class="text-grey-8" style="min-width: 100px">
                <q-item aria-hidden="true">
                  <q-item-section
                    class="text-uppercase text-grey-7"
                    style="font-size: 0.7rem"
                  >Tipo de Cadastro</q-item-section>
                </q-item>

                <q-item
                  :class="{'active_status': tipo === menu.text}"
                  v-for="menu in createMenuCadastros"
                  :key="menu.text"
                  clickable
                  v-close-popup
                  aria-hidden="true"
                  @click="setTipo(menu.text)"
                >
                  <!-- <q-item-section avatar>
                    <q-icon :name="menu.icon" />
                  </q-item-section>-->
                  <q-item-section>{{ menu.text }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>


          <q-btn
            v-if="$q.screen.gt.xs"
            flat
            dense
            no-wrap
            v-ripple
            color="primary"
            no-caps
            :label="status"
            class="q-ml-sm q-px-md bg-white text-blue-7"
            style="margin-right: 10px"
          >
            <q-menu anchor="top right" self="top right">
              <q-list class="text-grey-8" style="min-width: 100px">
                <q-item aria-hidden="true">
                  <q-item-section
                    class="text-uppercase text-grey-7"
                    style="font-size: 0.7rem"
                  >Status</q-item-section>
                </q-item>
                <q-item
                  :class="{'active_status': status === menu.text}"
                  v-for="menu in createMenuAtivos"
                  :key="menu.text"
                  clickable
                  v-close-popup
                  aria-hidden="true"
                  @click="setStatus(menu.text)"
                >
                  <!-- <q-item-section avatar>
                    <q-icon :name="menu.icon" />
                  </q-item-section>-->
                  <q-item-section>{{ menu.text }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

          <q-select
            label="Tags"
            use-chips
            multiple
            borderless
            dense
            v-model="tagFiltro"
            @input="setTag()"
            :options="tags"
            option-value="id"
            option-label="title"
            options-selected-class="active_status"
            style="min-width: 100px"
          />

          <q-btn
            v-if="hasVideo"
            dense
            flat
            color="bg-primary text-blue-7"
            icon="ondemand_video"
            class="q-ml-md"
            @click="modalIntro"
          >
            <template v-slot:default>
              <q-tooltip>Introdução aos Cadastros</q-tooltip>
            </template>
          </q-btn>

          <q-btn
            v-if="$q.screen.gt.xs"
            flat
            dense
            rounded
            no-wrap
            v-ripple
            class="q-ml-sm q-px-md bg-white text-black"
            no-caps
            icon="more_vert"
            style="margin-right: 10px"
          >
            <q-menu anchor="top right" self="top right">
              <q-list class="text-grey-8" style="min-width: 100px">
                <q-item aria-hidden="true">
                  <q-item-section
                    class="text-uppercase text-grey-7"
                    style="font-size: 0.7rem"
                  >Mais Opções</q-item-section>
                </q-item>
                <q-item clickable v-close-popup aria-hidden="true" @click="listar()">
                  <q-item-section>Atualizar</q-item-section>
                </q-item>
                <q-item clickable v-close-popup aria-hidden="true" @click="props.toggleFullscreen">
                  <q-item-section>{{props.inFullscreen ? 'Minimizar' : 'Maximizar Tela'}}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </template>
      </q-table>
      <div class="col-12 col-md-12 text-center" v-if="buscandoCadastros">
        <br />
        <q-spinner color="blue-7" size="3em" />
      </div>
      <q-page-sticky position="top-right" :offset="[0, 90]">
        <q-btn
          :color="drawerRight ? 'red-7' : 'blue-7'"
          :icon="drawerRight ? 'close' : 'settings'"
          class="rotate-0"
          style="border-top-left-radius: 50px;border-bottom-left-radius: 50px"
          @click="drawerRight = !drawerRight"
        >
          <q-tooltip
            :content-class="drawerRight ? 'bg-negative text-white text-center' : 'bg-secondary text-white text-center'"
          >{{drawerRight ? 'Fechar Opções Avançadas' : 'Opções Avançadas'}}</q-tooltip>
        </q-btn>
      </q-page-sticky>
      <q-drawer
        side="right"
        v-model="drawerRight"
        bordered
        :width="280"
        :breakpoint="500"
        behavior="default"
        content-class="bg-white"
        >
        <q-scroll-area class="fit">
          <div class="q-pa-sm">
            <div class="q-table__title">
              Opções Avançadas
              <!-- <q-btn
                icon="close"
                v-ripple
                @click="drawerRight = false"
                round
                flat
                color="bg-transparent text-black"
              />-->
            </div>
            <div>
              <q-list
                class="rounded-borders"
                style="max-width: 350px; margin-left: -10px;width: 300px;"
              >
                <q-item-label header>Opções</q-item-label>

                <q-item clickable v-ripple @click.exact="acaoCNPJ">
                  <q-item-section>
                    <q-item-label lines="1">Atualizar CNPJ</q-item-label>
                    <q-item-label caption>Atualização cadastral com a base de dados da receita federal (Utiliza créditos)</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-ripple @click.exact="acaoNotificarCliente">
                  <q-item-section>
                    <q-item-label lines="1">Notificar Cliente</q-item-label>
                    <q-item-label caption>Notificação de clientes atráves de WhatsApp ou E-mail</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-ripple @click.exact="acaoGrupo">
                  <q-item-section>
                    <q-item-label lines="1">Marcar com a Tag</q-item-label>
                    <q-item-label caption>Marcar cadastros com a Tag</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>
        </q-scroll-area>
      </q-drawer>
    </div>
    <notificar-cliente ref="notificarCliente"></notificar-cliente>
    <tags-cadastro ref="tagsCadastro"></tags-cadastro>
  </q-page>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";
export default {
  name: "cadastros",
  meta: {
    title: "DSB | Cadastros"
  },
  data() {
    return {
      drawerRight: false,
      tipo: "Clientes",
      status: "Ativos",
      tagFiltro: [],
      tags:[],
      createMenuCadastros: [
        { text: "Clientes" },
        { text: "Funcionários" },
        { text: "Fornecedores" },
        { text: "Vinculados" },
        { text: "Não Clientes" },
        { text: "Todos" }
      ],
      createMenuAtivos: [{ text: "Ativos" }, { text: "Inativos" }],
      visibleColumns: JSON.parse(localStorage.getItem("cadastros__visibleColumns")) || ["codigo", "nome", "email", "telefone"],
      pagination: {
        sortBy: "nome",
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      selected: [],
      columns: [
        {
          name: "codigo",
          label: "Código",
          align: "center",
          field: row => row.codigo,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "nome",
          required: true,
          align: "left",
          label: "Nome",
          field: row => row.nome,
          sortable: true
        },

        {
          name: "email",
          align: "left",
          label: "E-mail",
          field: row => row.email,
          sortable: true
        },
        {
          name: "telefone",
          align: "left",
          label: "Telefone",
          field: row => row.telefone,
          sortable: true
        },
        {
          name: "cadastros_tags",
          required: true,
          align: "left",
          label: "Tags",
          field: row => row.cadastros_tags,
          sortable: true
        },
      ],
      allCadastro: false,
      hasVideo: false
    };
  },
  computed: {
    ...mapGetters([
      "cadastros",
      "buscandoCadastros",
      "filtrosCadastros",
      "pagingCadastros",
      "allCadastros",
      "videosManuais",
      "manuaisDisponiveis"
    ]),
    tagLabel(){
      return ( this.tagFiltro.length == 0 ? "Tags" : false );
    },
  },
  watch: {
    selected(value) {
      if (value.length === 0) {
        this.allCadastro = false;
        this.$store.commit("getSelectedCadastros", []);
        this.$store.commit("allCadastros", false);
      } else {
        if (value.length >= 10) {
          this.allCadastro = true;
        }
        let ids = [];
        for (let i in value) {
          if (value.indexOf(value[i]) !== -1) {
            ids.push(value[i].id);
          }
        }
        this.$store.commit("getSelectedCadastros", ids);
      }
    },
    visibleColumns() {
      localStorage.setItem("cadastros__visibleColumns", JSON.stringify(this.visibleColumns));
    },
  },
  methods: {
    listar() {
      this.$store.dispatch("resetPagingCadastros");
      this.$store.dispatch("listarCadastros");
    },
    acaoGrupo() {
      this.$store.dispatch("acaoGrupo");
    },
    selecionarTodos() {
      this.$store.commit("allCadastros", !this.allCadastros ? true : false);
    },
    acaoCNPJ() {
      this.$store.dispatch("acaoCnpj");
    },
    acaoNotificarCliente() {
      this.$store.dispatch("acaoNotificar");
    },
    setStatus(value) {
      if (this.status === value) return;
      this.status = value;
      this.$store.dispatch("resetPag");
      this.$store.commit("filtrosCadastros", {
        ativo: value === "Ativos" ? 1 : 0,
        cliente: this.filtrosCadastros.cliente ,
        funcionario: this.filtrosCadastros.funcionario,
        fornecedor: this.filtrosCadastros.fornecedor,
        dependente: this.filtrosCadastros.dependente,
        busca: this.filtrosCadastros.busca,
        page: 0
      });
      this.$store.dispatch("listarCadastros");
    },
    setTag(){
      this.$store.dispatch("resetPag");
      this.$store.commit("filtrosCadastros", {
        ativo: this.status === "Ativos" ? 1 : 0,
        cliente: 1,
        funcionario:  null,
        fornecedor:  null,
        busca: this.filtrosCadastros.busca,
        page: 0,
        tags: this.tagFiltro.map(e=>e.id).join()
      });
      this.$store.dispatch("listarCadastros");
    },
    setTipo(value) {
      if (this.tipo === value) return;
      this.status = 'Ativos';
      this.tipo = value;
      this.$store.dispatch("resetPag");
      this.$store.commit("filtrosCadastros", {
        ativo: this.status === "Ativos" ? 1 : 0,
        cliente: value === "Clientes" ? 1 : value === "Não Clientes" ? 0 : null,
        funcionario: value === "Funcionários" ? 1 : null,
        fornecedor: value === "Fornecedores" ? 1 : null,
        dependente:
          value === "Vinculados"
            ? 1
            : value === "Clientes"
            ? 0
            : value === "Não Clientes"
            ? 0
            : null,
        busca: this.filtrosCadastros.busca,
        page: 0
      });
      this.$store.dispatch("listarCadastros");
    },
    modal(cadastro) {
      // this.$refs.novoCadastro.dialog = true;
      if (cadastro) {
        this.$store.dispatch("cadastro", cadastro);
      } else {
        this.$store.dispatch("cadastro", { noSearch: true, novo: true });
      }
    },
    handleScroll() {
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.$store.dispatch("listarCadastros");
      }
    },
    breakLine(value) {
      return value.replace(/,/g, "<br>");
    },
    getUrl(cadastro) {
      return "/cadastros/" + cadastro.id;
    },
    inativaCadastro(value) {
      this.$store.dispatch("inativaCadastro", value);
    },
    reativaCadastro(value) {
      this.$store.dispatch("reativaCadastro", value);
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "cadastros");

      if (!this.videosManuais.video_cadastros) {
        this.modalIntro();
      }
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "cadastros");
    },
  },
  mounted() {
    this.$store.dispatch("listarCadastros");
    this.verificarPrimeiroVideo();

    axios
        .get('/tags')
        .then(res => {

          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }

          this.tags = res.data.tags;
        });
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("resetPagingCadastros");
    this.$store.commit("allCadastros", false);
    window.removeEventListener("scroll", this.handleScroll);
    next();
  }
};
</script>

<style lang="stylus" scoped>
.q-table__title {
  font-weight: 300;
  font-size: 25px;
}

.tdfora {
  position: relative;
  min-width: 32px;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
}

.divfora {
  background: inherit;
  position: absolute;
  right: 1px;
  top: 0.25rem;
  display: none;
}

.divdentro {
  display: flex;
}

tr:hover .tdfora {
  .divfora {
    display: inline-block;
  }
}

.active_status {
  background-color: #ff9c00 !important;
  color: white !important;
}
</style>
