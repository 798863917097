import axios from "@/axios-auth";
import { Loading, Dialog, Notify } from "quasar";

const state = {
  produtosCategoria: [],
  categoriasResumo: [],
  loadingCategoriasResumo: false,
  totalResumoCreditos: 0,
  totalResumoDebitos: 0,
  validarEstoque: false,
  utilizarEstoqueEmpresa: false,
  enviaDadosImgData: null,
};
const mutations = {
  produtosCategoria: (state, userData) => {
    state.produtosCategoria = userData;
  },
  categoriasResumo: (state, userData) => {
    state.categoriasResumo = userData;
  },
  loadingCategoriasResumo: state => {
    state.loadingCategoriasResumo = !state.loadingCategoriasResumo;
  },
  totalResumoCreditos: (state, userData) => {
    state.totalResumoCreditos = userData;
  },
  totalResumoDebitos: (state, userData) => {
    state.totalResumoDebitos = userData;
  },
  categoriaResumo: (state, userData) => {
    // atualiza somente o item no indice desejado do array categoriasResumo
    state.categoriasResumo[userData.index] = userData.categoriaData;
  },
  produtoResumo: (state, userData) => {
    // atualiza somente o item no indice desejado do array produtosCategoria
    Object.assign(
      state.produtosCategoria[userData.index],
      userData.produtoData
    );
  },
  setValidarEstoque: (state, userData) => {
    state.validarEstoque = userData;
  },
  setUtilizarEstoqueEmpresa: (state, userData) => {
    state.utilizarEstoqueEmpresa = userData;
  },
  enviaDadosImgData: (state, userData) => {
    state.enviaDadosImgData = userData;
  },
};
const actions = {
  buscaProdutos({ commit }, userData) {
    return new Promise((resolve, reject) => {
      // commit('loadingCategoriasResumo')
      axios
        .get("/lancamentos/caixa/" + userData.caixaId, {
          params: {
            tipo: "resumo",
            categoria_id: userData.categoriaId
          }
        })
        .then(res => {
          if (res.data.success) {
            commit("movcaixacategoria", res.data.categoria);
            commit("categoriasResumo", res.data.categorias);
            commit("totalResumoCreditos", res.data.total_creditos);
            commit("totalResumoDebitos", res.data.total_debitos);
            commit("setValidarEstoque", res.data.opcoes_estoque.validar_estoque);
            commit("setUtilizarEstoqueEmpresa", res.data.opcoes_estoque.utilizar_estoque_empresa);
    
            /** Esta parte do código serve para atualizar todo o estado da propriedade */
            commit("produtosCategoria", []);
            setTimeout(() => commit("produtosCategoria", res.data.produtos));

            resolve(res.data);
          } else {
            reject();
          }
        })
        .catch(() => {
          // commit('loadingCategoriasResumo')
          reject();
        });
    });
  },
  editProdutoResumo: ({ dispatch }, userData) => {
    return new Promise((resolve, reject) => {
      let params = {
        id: userData.id,
        categoria_id: userData.categoria_id,
        produto_id: userData.produto_id,
        quantidade: userData.quantidade,
        is_resumo: true,
        valor: userData.valor,
        tpcontrole: userData.tpcontrole,
        concurso: userData.concurso,
      };
      axios
        .post("/lancamentos/add-saldo/" + userData.caixaId, params)
        .then(res => {
          if (res.data.success) {
            dispatch("adicionaNovoElemento", {
              response: res.data,
              lancamentoOriginal: userData,
            });
          }
          resolve(res.data);
        })
        .catch(() => {
          reject();
        });
    });
  },
  atualizaCatProdutos({}, userData) {
    Dialog.create({
      title: "Transferir estoque para o caixa",
      message:
        "Deseja realmente transferir seu estoque de produtos para este caixa?",
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/lancamentos/add-saldo/" + userData.id, { is_estoque: true })
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
          })
          .catch(() => {
            Dialog.create({
              title: "Não foi possível contactar ou não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  enviaDadosImg: ({ commit }, userData) => {
    return new Promise((resolve, reject) => {
      if (userData.arquivo_id) {
        let params = {
          is_ocr: true,
          arquivo_id: userData.arquivo_id,
          save_ocr: userData.save_ocr,
          raw: userData.raw
        };
        axios
          .post("/lancamentos/add-saldo/" + userData.caixa_id, params)
          .then(res => {
            //atualiza a store somente se NAO for salvar o arquivo no BD
            if(!params.save_ocr) commit("imgOCRId", userData.arquivo_id);
            resolve(res.data);
            commit('enviaDadosImgData', res.data);
          })
          .catch(() => {
            reject();
          });
      }
    });
  },
  adicionaNovoElemento: ({ commit, state }, userData) => {
    const { response, lancamentoOriginal } = userData;

    commit("totalResumoCreditos", response.total_creditos);
    commit("totalResumoDebitos", response.total_debitos);
    let index = state.categoriasResumo.findIndex(
      i => i.id === response.categoria.id
    );
    let categorias = state.categoriasResumo;
    Object.assign(categorias[index],response.categoria);
    commit("categoriasResumo", categorias);

    /**
     * Quando a categoria não é de produtos, nós atualizamos somente o id daquele lançamento,
     * pois caso o cliente deseje alterar a quantidade/valor, se não tiver o id vai dar um erro
     * dizendo que aquele lançamento já existe, o id serve para atualizar
     */
    if (!response.categoria.is_produtos) {
      const produtosCategoriasIndex = state.produtosCategoria.indexOf(lancamentoOriginal);
      const lancamento = {
        id: response.lancamento.id,
      };

      if (produtosCategoriasIndex !== -1) {
        commit("produtoResumo", {
          index: produtosCategoriasIndex,
          produtoData: lancamento,
        });
      }
    } else {
      /**
       * Quando a categoria é de produtos, devemos atualizar todo o objeto por que o valor, quantidade
       * e quantidade em mãos podem sofrer alterações no backend, então devemos atualizar os valores
       * no frontend também
       */
      const produtosCategoriasIndex = state.produtosCategoria.findIndex(
        produtoCategoria => produtoCategoria.id === response.lancamento.id
      );
      commit("produtoResumo", {
        index: produtosCategoriasIndex,
        produtoData: response.lancamento,
      });
    }
  },
  updateIgnorar({ commit }, userData) {
    let params = {
      ignorar: userData.ignorar,
      linha_id: userData.linhaId,
      is_atualiza_ignorar: true,
    };
    axios
      .post("/lancamentos/add-saldo/" + userData.caixaId, params)
      .then((res) => {
        if(res.data.success) {
          commit("categoriasResumo", res.data.categorias);
        }
      })
      .catch(() => {
        Notify.create({
          message: "Erro ao tentar desabilitar uma detecção automática.",
          color: "orange"
        });
      });
  }
};
const getters = {
  produtosCategoria: state => {
    return state.produtosCategoria;
  },
  categoriasResumo: state => {
    return state.categoriasResumo;
  },
  loadingCategoriasResumo: state => {
    return state.loadingCategoriasResumo;
  },
  totalResumoCreditos: state => {
    return state.totalResumoCreditos;
  },
  totalResumoDebitos: state => {
    return state.totalResumoDebitos;
  },
  validarEstoque: state => {
    return state.validarEstoque;
  },
  utilizarEstoqueEmpresa: state => {
    return state.utilizarEstoqueEmpresa;
  },
  enviaDadosImgData: state => {
    return state.enviaDadosImgData;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
