<template>
  <div id="modalAdicionarVersao">
    <q-dialog v-model="OpenDialogAdicionarVersao" persistent>
      <q-card style="width:400px">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">Nova Versão</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12" style="padding-left: 6px">
                <q-checkbox v-model="versao.ativo" label="Ativo" color="green" />
              </div>
              <div class="col-12 col-md-12" style="padding-left: 6px">
                <q-checkbox v-model="versao.is_test" label="Testes" color="green" />
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  v-model="servico_id"
                  :option-label="value => value === null ? 'Item Vazio' : value.nome"
                  :option-value="value => value === null ? null : value"
                  :options="servicos"
                  label="Serviço"
                  bottom-slots
                  :error="errorsLicencas.servico_id ? true : false"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsLicencas.servico_id" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="versao.numero"
                  label="Número"
                  bottom-slots
                  :error="errorsLicencas.numero ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsLicencas.numero" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="versao.notes"
                  label="Notas de Atualização"
                  bottom-slots
                  :error="errorsLicencas.notes ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsLicencas.notes" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="versao.url"
                  label="URL de Download"
                  bottom-slots
                  :error="errorsLicencas.url ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsLicencas.url" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveVersao"
            :loading="salvaSV"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "modal-adicionar-versao",
  data() {
    return {
      servico_id: {}
    };
  },
  methods: {
    saveVersao() {
      this.versao.servico_id = this.servico_id.id;
      this.$store.dispatch("saveVersao", this.versao);
    },
    closeModal() {
      this.$store.commit("OpenDialogAdicionarVersao", false);
      this.$store.dispatch("errorsLicencasClear");
      this.$store.dispatch("newVersao");
    }
  },
  computed: {
    ...mapGetters(["errorsLicencas", "versao", "servicos", "salvaSV"]),
    OpenDialogAdicionarVersao: {
      get() {
        return this.$store.getters.OpenDialogAdicionarVersao;
      },
      set() {}
    }
  }
};
</script>

<style lang="stylus" scoped></style>
