<template>
  <div class="col-12 flex justify-end">
    <div
      class="q-uploader"
      style="width: 100%; box-shadow: none; overflow-x:hidden; overflow-y:auto;"
      @dragleave="onDragLeave"
      @mouseover="onHover"
      @mouseleave="offHover"
      @dragover="onDragOver"
      @drop="onDrop"
    >
      <div class="q-uploader__header bg-white text-grey-6">
        <div class="header-upload">
          <div class="flex justify-between items-center q-pa-md" :class="!this.$q.platform.is.mobile ?  'layout' :'layout-responsive'">
            <div  v-if="!this.$q.platform.is.mobile">
              <div class="q-uploader__title">Anexar imagens e documentos</div>
            </div>

            <div class="icones-layout">
              <label for="file"  v-if="this.$q.platform.is.mobile">
                <i
                  class="material-icons"
                  style="font-size: 20px; cursor: pointer; float: right"
                >
                  add_a_photo
                </i>
                <input
                  accept="image/*"
                  capture="camera"
                  type="file"
                  id="file"
                  style="width: 0"
                  multiple
                  class="file"
                  @change="addFile"
                />
              </label>

              <label v-else>
                <i
                  @click="openModal"
                  class="material-icons"
                  style="font-size: 20px; cursor: pointer;"
                >
                  add_a_photo
                </i>
              </label>

              <label class="q-pl-sm">
                <i v-if="!this.$q.platform.is.mobile"
                  class="material-icons"
                  style="font-size: 20px; cursor: pointer;">
                  cloud_upload
                </i>
                <input
                  type="file"
                  id="file"
                  style="width: 0"
                  multiple
                  class="file"
                  @change="addFile"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="q-uploader__list scroll" style="border: 1px solid #ccc;">

        <div v-for="(file) in uploads" :key="file.name" style="margin-bottom: 8px">
          <div class="q-uploader__file relative-position">
            <div class="q-uploader__file-header row flex-center no-wrap">
              <i v-if="file.error" aria-hidden="true"
                class="material-icons q-icon text-negative q-uploader__file-status">warning</i>
              <div class="q-uploader__file-header-content col" v-if="!file.processing" style="cursor: pointer">
                <div class="q-uploader__title">{{ file.fileName }}</div>
                <div v-if="!isNew" class="q-uploader__subtitle row items-center no-wrap">{{ file.fileSize }} /
                  {{ file.progress }}</div>
                <div v-else class="q-uploader__subtitle row items-center no-wrap">Aguardando Salvar a Recorrência</div>
              </div>
            </div>
          </div>
        </div>                      

        <div v-for="(arquivo, i) in arquivosCarregados" :key="i" clickable style="margin-bottom: 8px;">
          <div class="q-uploader__file relative-position">
            <div class="q-uploader__file-header row flex-center no-wrap">
              <i
                v-if="arquivo.error"
                aria-hidden="true"
                class="material-icons q-icon text-negative q-uploader__file-status"
              >
                warning
              </i>
              <div class="q-uploader__file-header-content col cursor-pointer" @click="openArquivo(arquivo.id)">
                <div class="q-uploader__title">{{arquivo.nome}}</div>
                <div class="q-uploader__subtitle row items-center no-wrap">
                  {{ bytesToSize(arquivo.tamanho) }}
                </div>
              </div>
              <q-btn
                round
                dense
                flat
                icon="clear"
                @click="apagaItem(arquivo)"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios-auth';
import { Notify } from "quasar";
import { mapGetters } from 'vuex';
export default {
  name: 'upload-document-recorrencia',
  props: ['uploads', 'recorrencia' ,'isNew'],
  data() {
    return {
      selectedFile:[],
      arquivosCarregados: null
    };
  },
  methods:{
    onDragLeave() {
      event.preventDefault();
    },
    onHover() {
      event.preventDefault();
    },
    offHover() {
      event.preventDefault();
    },
    onDragOver() {
      event.preventDefault();
    },
    onDrop(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.uploads.length >= 1) {
        this.uploads.pop();
      } 
      var files = event.dataTransfer.files;
      this.selectedFile = files;
      let tam = this.uploads.length;
      let j;

      for (let i = 0; i < this.selectedFile.length; i++) {
        this.uploads.push({
          fileName: this.selectedFile[i].name,
          fileSize: this.bytesToSize(this.selectedFile[i].size),
          success: false,
          error: false,
          message: "",
          progress: "0%",
          uploading: false
        });
        j = tam + i;
        if(!this.isNew){
          this.uploadFile(this.selectedFile[i], this.uploads[j], this.recorrencia);
        }
      }
    },
    addFile(event) {
      if (this.uploads.length >= 1) {
        this.uploads.pop();
      }
      this.selectedFile = event.target.files;
      let tam = this.uploads.length;
      let j;

      for (let i = 0; i < this.selectedFile.length; i++) {
        this.uploads.push({
          fileName: this.selectedFile[i].name,
          fileSize: this.bytesToSize(this.selectedFile[i].size),
          success: false,
          error: false,
          message: "",
          progress: "0%",
          uploading: false,
          delivered: false
        });
        j = tam + i;

        if(!this.isNew){
          this.uploadFile(this.selectedFile[i], this.uploads[j], this.recorrencia);
        }
      }
    },
    uploadFile(arq, arr, recorrencia) {
      if(!recorrencia) return;
      if (!arq.name) {
        arq.name = "Document";
      }
      arr.uploading = true;
      const fd = new FormData();
      fd.append("file", arq, arq.name);
      fd.append("recorrencia_id", recorrencia.id);
      fd.append("cadastro_id", recorrencia.cadastro_id);

      axios
        .post("/arquivos/upload", fd, {
          onUploadProgress: uploadEvent => {
            arr.progress =
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
          }
        })
        .then(async res => {
          if (res.data.success) {
            arr.message = res.data.message;

          } else {
            arr.error = true;
            arr.message = res.data.message;
            Notify.create({
              message: arr.message + "  " + arr.fileName,
              color: "orange",
              position: "top-right"
            });
          }

          arr.success = res.data.success;
          arr.uploading = false;
          arr.delivered = true;

          this.arquivosCarregados.unshift(res.data.arquivo);
          this.uploads.pop();
        });
        this.$set(this.$store.state, 'newRecorrencia', null);
    },
    bytesToSize(bytes) {
      if (bytes == 0) return "0 Byte";

      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    openArquivo(id) {
      axios
        .get("/arquivos/get-url/" + id)
        .then(response => {
          Notify.create({
            message: "Iniciando o Download de " + response.data.fileName,
            color: "green",
            position: "top-right"
          });
          const link = document.createElement("a");
          link.href = response.data.downloadUrl;
          link.setAttribute("type", "hidden");
          window.open(link, "_blank");
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível baixar o arquivo, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    openModal() {
      this.$store.commit("recorrenciaQrCode", this.recorrencia);
      this.$store.dispatch("getTokenQrcode");
      this.$store.commit("OpenDialogUploadResumoCaixas", true);
      this.$refs.uploadResumoTfl.dizsize();
    },
    apagaItem(arquivo, i) {
      if(arquivo.id) {
        axios
          .post("/arquivos/edit/" + arquivo.id)
          .then((res) => {
            if(!res.data.success){
              Notify.create({
                message: res.data.message,
                color: "red",
                position: "top-right"
              });
              return;
            }

            this.arquivosCarregados = this.arquivosCarregados.filter(arq => arq.id != arquivo.id);

            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
          })
          .catch(() => {
            Notify.create({
                message: "Houve um erro ao remover o arquivo!",
                color: "red",
                position: "top-right"
            });
          });
      }
      return;
    },
    loadArquivos(){
      if(!this.recorrencia.id) return;
      let params = {
        recorrencia_id: this.recorrencia.id,
        cadastro_id: null
      };
      axios
        .get('/arquivos',{ params })
        .then((res)=>{
          if(res.data.success){
            this.arquivosCarregados = [...res.data.arquivos];
          }

        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possivel carregar os arquivos.',
            color: 'orange',
            position: 'top-right',
          });
        });
    }
  },
  computed:{
    ...mapGetters(["newRecorrencia"]),
  },
  beforeDestroy(){
    if (this.newRecorrencia && this.isNew && (this.uploads.length>0)) {
      for (var i = 0; i < this.selectedFile.length; i++) {
        this.uploadFile(this.selectedFile[i], this.uploads[i], this.newRecorrencia);
      }
    }
    this.$store.commit("newRecorrencia", null);
  },
  mounted(){
    if(this.recorrencia){
      this.loadArquivos();
    }
  }

};
</script>
