<template>
  <q-layout id="perfil">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <div class="col-12 col-md-5">
          <q-card>
            <q-card-section>
              <div class="text-h6">{{user.nome}}</div>
              <q-btn
                class="bg-blue-7 text-white absolute-top-right"
                style="margin: 15px 15px 0 0"
                label="Alterar Senha"
                @click="alterarSenha"
              ></q-btn>
            </q-card-section>

            <q-card-section>
              <div class="text-black">
                {{user.celular}}
                <br />
                {{user.email}}
              </div>
            </q-card-section>

            <q-separator />

            <q-card-section>
              <div style="font-size: 1.15em;">
                <strong>Acessibilidade</strong>
              </div>

              <div class="flex justify-between items-center">
                <div class="flex no-wrap items-center">
                  <q-icon
                    size="24px"
                    name="format_size"
                    :color="acessibilidade.aumentarTamanhoFonteTabelas ? 'primary' : 'grey-8'"
                  />
                  <q-toggle
                    v-model="acessibilidade.aumentarTamanhoFonteTabelas"
                    color="primary"
                    label="Aumentar o tamanho da fonte nas tabelas"
                  />
                </div>
                <div :style="`font-size: ${fontSizeTabelas}px;`" class="text-grey-8">A a</div>
              </div>

            </q-card-section>

          </q-card>
        </div>

        <div class="col-12 col-md-7">
          <notificacoes></notificacoes>
        </div>
        <div class="col-12 col-md-5">
          <q-card>
            <estoque :buscando="buscando" :user="user"></estoque>
          </q-card>
        </div>
        <div class="col-12 col-md-7">
          <sessoes-ativas></sessoes-ativas>
        </div>
        <div class="col-12 col-md-5">

        </div>
        <div class="col-12 col-md-7">
          <meus-atalhos></meus-atalhos>
        </div>
      </div>
    </div>
    <modal-alterar-senha></modal-alterar-senha>
  </q-layout>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "perfil",
  meta: {
    title: "DSB | Cadastros"
  },
  data() {
    return {};
  },
  methods: {
    alterarSenha() {
      this.$store.commit("OpenDialogAlterarSenha", true);
    },
    updateAcessibilidade() {
      localStorage.setItem("acessibilidade", JSON.stringify(this.acessibilidade));
      this.$store.dispatch("loadAcessibilidade");
    },
  },
  computed: {
    ...mapGetters({
      user: "dataUser",
      atalhos: "atalhosUser",
      buscando: "buscandoUser",
      acessibilidade: "acessibilidade",
    }),
    fontSizeTabelas() {
      return this.acessibilidade.aumentarTamanhoFonteTabelas ? 15 : 13;
    },
  },
  watch:  {
    acessibilidade: {
      deep: true,
      handler() {
        this.updateAcessibilidade();
      },
    },
  },
  mounted() {
    this.$store.dispatch("listarUser");
    this.$store.commit('isPerfil',true);
  },
  beforeRouteLeave(to,from,next){
    this.$store.commit('isPerfil',false);
    next();
  }
};
</script>
