import Vue from "vue";
import App from "@/App.vue";
import router from "@/router/router";
import store from "@/store/store";
import axios from "axios";
import "@/registerServiceWorker";
import "@/quasar/quasar";
import "@/filters/filters";
import "@/mixins/mixins";
import "@/components/_globals";

Vue.config.productionTip = true;

axios.defaults.baseURL = process.env.VUE_APP_URL_API;

if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line no-console
  console.log(
    "Modo " + process.env.NODE_ENV + ": " + process.env.VUE_APP_URL_API
  );
}

export const bus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
