<template>
  <div id="compras">
    <q-dialog v-model="dialogCaixaCompraDetalhada">
      <q-card style="min-width: 40%">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">
            Compra
            <b>
              {{ compras.compra && compras.compra.status ? ' - ' + compras.compra.status : '' }}
            </b>
          </div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModel('dialogCaixaCompraDetalhada')"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="row q-col-gutter">
            <div class="col-12 q-pa-none">
              <div>
                <q-form>
                  <div class="row q-col-gutter-sm">
                    <div class="col-md-4 col-12">
                      <q-input
                        label="Data"
                        :disable=true
                        v-model="getDtMovimento"
                        @keyup.enter.native="pularProxCampo('funcionario')"
                        :error="errors && errors.dtmovimento ? true : false"
                        @click="$refs.qDateProxy.show()"
                      >
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.fornecedor_id" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="produtoCompra.dtmovimento"
                                @input="() => $refs.qDateProxy.hide()"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                      </q-input>
                    </div>
                    <div class="col-md-8 col-12" v-if="!fornecedorSelecionado">
                      <q-select
                        :options="fornecedoresFilter"
                        option-label="nome"
                        option-value="id"
                        v-model="produtoCompra.fornecedor_id"
                        label="Fornecedor:"
                        emit-value
                        @filter="filterFn"
                        ref="funcionario"
                        use-input
                        input-debounce="500"
                        map-options
                        :error="errors && errors.fornecedor_id ? true : false"
                        clearable
                        @keyup.enter.native="pularProxCampo('quantidade')"
                      >
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.fornecedor_id" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey"> Sem resultados </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>
                    <div
                      class="col-md-8 col-12"
                      v-if="fornecedorSelecionado && fornecedorSelecionado.nome"
                    >
                      <q-input label="Fornecedor" v-model="fornecedorSelecionado.nome" disable />
                    </div>
                  </div>
                  <div
                    class="row q-col-gutter-sm q-mt-md"
                    v-if="
                      !fornecedorSelecionado ||
                      (compras.compra && compras.compra.status === 'Aberto')
                    "
                  >
                    <div class="col-md-2 col-12">
                      <q-input
                        mask="########"
                        label="Quantidade"
                        v-model="produtoCompra.quantidade"
                        ref="quantidade"
                        @keyup.enter.native="pularProxCampo('produto')"
                        :error="errors && errors.quantidade ? true : false"
                        dense
                      >
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.quantidade" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                      </q-input>
                    </div>
                    <div class="col-md-5 col-12">
                      <q-select
                        use-input
                        input-debounce="500"
                        label="Produtos"
                        v-model="produtoSelecionado"
                        :options="getProdutos"
                        @filter="buscarProduto"
                        option-label="nome"
                        :option-value="value => value"
                        map-options
                        emit-value
                        @keyup.enter.native="pularProxCampo('valor')"
                        ref="produto"
                        :error="errors && errors.produto_id ? true : false"
                        clearable
                        :loading="carregando"
                        dense
                      >
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey">
                              Produto não encontrado
                            </q-item-section>
                          </q-item>
                        </template>

                        <template v-slot:error>
                          <span v-for="(error, i) in errors.produto_id" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                      </q-select>
                    </div>
                    <div
                      class="col-md-3 col-12"
                      @keydown.enter.prevent="pularProxCampo('cadastrar')"
                    >
                      <q-field
                        v-model="valorTotalProdutoSelecionado"
                        label="Valor Total Produtos"
                        bottom-slots
                        dense
                      >
                        <template
                          v-slot:control="{
                            id,
                            floatingLabel,
                            value,
                            emitValue,
                          }"
                        >
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                            name="valorCaixa"
                          >
                          </money>
                        </template>
                      </q-field>
                    </div>
                    <!-- Novo Item: Evento, extração, concurso -->
                    <div class="col-2">
                      <q-btn
                        @click="save()"
                        :loading="loadingAdicionarItem"
                        color="green"
                        icon="add"
                        :disable="loadingAdicionarItem"
                        class="pull-right"
                        ref="cadastrar"
                        size="12px"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <span class="q-mb-lg" v-if="compras.compra && compras.compra.observacao"
                      ><b>Observação</b>: {{ compras.compra.observacao }}</span
                    >
                  </div>
                </q-form>
              </div>
            </div>
            <div class="col-12">
              <div class="q-mt-lg">
                <div>
                  <q-scroll-area
                    :thumb-style="scrollStyle"
                    class=" "
                    style="height: 400px; width: 100%"
                  >
                    <div>
                      <q-markup-table separator="horizontal" flat bordered>
                        <thead>
                          <tr>
                            <th class="text-left"><b>Quantidade</b></th>
                            <th class="text-left"><b>Produto</b></th>
                            <th class="text-right"><b>Valor</b></th>
                            <th style="width: 50px"></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(produto, index) in compras.produtos" :key="index">
                            <td class="text-left">{{ produto.quantidade }}</td>
                            <td class="text-left">
                              <div v-if="produto.tpcontrole === 'Extração'">
                                {{
                                  produto.nome +
                                  ' - ' +
                                  produto.extracao +
                                  ' - ' +
                                  format(produto.vencimento)
                                }}
                              </div>
                              <div v-else-if="produto.tpcontrole === 'Evento'">
                                {{ produto.nome + ' - ' + produto.nome_evento }}
                              </div>
                              <div v-else-if="produto.tpcontrole === 'Concurso'">
                                {{ produto.nome + ' - ' + produto.concurso }}
                              </div>
                              <div v-else>
                                {{ produto.nome }}
                              </div>
                            </td>
                            <td class="text-right">R$ {{ produto.valor | currency }}</td>
                            <td>
                              <q-btn
                                class="bg-red text-white btn-sm pull-right"
                                icon="remove"
                                size="xs"
                                @click="deleteCompraItem(produto.movcompra_id)"
                                v-if="compras.compra && compras.compra.status === 'Aberto'"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right">
                              <b>R$ {{ total | currency }}</b>
                            </td>
                          </tr>
                        </tbody>
                      </q-markup-table>
                    </div>

                    <div class="row q-col-gutter-sm items-center q-mt-sm">
                      <div class="col-12 col-md-9">
                        <q-input
                          label="Observação"
                          v-model="observacao"
                          v-if="compras.compra && compras.compra.status === 'Aberto'"
                        />
                      </div>

                      <div class="col-12 col-md-3">
                        <q-btn
                          class="bg-green text-white q-pl-xs q-pr-xs pull-right"
                          label="Finalizar Compra"
                          dense
                          size="md"
                          @click="finalizarCompra(compras.compra.id)"
                          v-if="compras.compra && compras.compra.status === 'Aberto'"
                        />
                      </div>
                    </div>
                  </q-scroll-area>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="camposAdicionaisDialog" persistent>
      <q-card style="min-width: 300px !important">
        <q-card-section class="row items-center">
          <div>
            <b>
              {{
                produtoSelecionado && produtoSelecionado.nome ? produtoSelecionado.nome : 'Compra'
              }}
            </b>
          </div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModel('camposAdicionaisDialog')"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div>
            <div v-if="produtoSelecionado && produtoSelecionado.tpcontrole === 'Evento'">
              <div class="col-12">
                <q-input
                  @input="val => buscaAno(val)"
                  v-model="evento_search"
                  label="Ano Evento:"
                  aria-autocomplete="false"
                  ref="evento_search"
                  input-class="text-right"
                  @keyup.enter.native="pularProxCampo('evento')"
                />
                <q-select
                  :option-label="value => (value === null ? 'Item Vazio' : value.nome)"
                  option-value="id"
                  label="Evento"
                  :options="produtosEstoqueAno"
                  bottom-slots
                  ref="evento"
                  emit-value
                  map-options
                  v-model="produtoCompra.evento_id"
                  :error="errors && errors.evento_id ? true : false"
                  @keydown.enter.prevent="pularProxCampo('cadastrar')"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.evento_id" :key="error + i">{{ error }}</span>
                  </template>
                </q-select>
              </div>
            </div>
            <div
              class="col-12"
              v-if="produtoSelecionado && produtoSelecionado.tpcontrole === 'Concurso'"
            >
              <q-input
                label="Concurso:"
                v-model="produtoCompra.concurso"
                aria-autocomplete="false"
                ref="concurso"
                input-class="text-right"
                :error="errors && errors.concurso ? true : false"
                @keydown.enter.prevent="pularProxCampo('cadastrar')"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.concurso" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>
            <div v-if="produtoSelecionado && produtoSelecionado.tpcontrole === 'Extração'">
              <div class="col-12">
                <q-input
                  label="Extração:"
                  aria-autocomplete="false"
                  ref="extracao"
                  input-class="text-right"
                  v-model="produtoCompra.extracao"
                  :error="errors && errors.extracao ? true : false"
                  @keyup.enter.native="pularProxCampo('vencimento')"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.extracao" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  input-class="text-right"
                  label="Vencimento:"
                  ref="vencimento"
                  v-model="produtoCompra.vencimento"
                  @click="$refs.vencimentoRef.show()"
                  :error="errors && errors.vencimento ? true : false"
                  @keydown.enter.prevent="pularProxCampo('salvar')"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="vencimentoRef"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="produtoCompra.vencimento"
                          mask="DD/MM/YYYY"
                          minimal
                          today-btn
                          :locale="langDate"
                          @input="() => $refs.vencimentoRef.hide()"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.vencimento" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-input>
              </div>
            </div>
            <div class="col-12 col-md-12">
              <q-btn
                no-wrap
                v-ripple
                label="Salvar"
                no-caps
                ref="save"
                size="12px"
                class="q-ml-sm q-mt-md q-mb-md q-px-md bg-positive text-white pull-right"
                @click="addCompra()"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from '@/axios-auth';
import { Money } from 'v-money';
import { mapGetters } from 'vuex';
import { Loading } from 'quasar';
import moment from 'moment';

export default {
  name: 'modal-compra-estoque',
  components: { Money },
  data() {
    return {
      scrollStyle: {
        right: '4px',
        borderRadius: '5px',
        width: '5px',
        opacity: 0.5,
      },
      lancamento: {
        id: null,
        ativo: true,
      },
      errors: [],
      loadingAdicionarItem: false,
      observacao: null,
      fornecedor_id: null,
      carregando: false,
      produtoSelecionado: null,
      fornecedoresFilter: [],
      produtoCompra: this.produtoCompraReset(),
      getProdutos: [],
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      camposAdicionaisDialog: false,
      evento_search: null,
      valorTotalProdutoSelecionado: 0,
    };
  },
  methods: {
    // Init
    init(movcaixasaldo = null) {
      // this.loadProdutosVendaOptions();
      // this.resetModal();

      this.loadMovcaixasaldo(movcaixasaldo);
      this.$store.dispatch('getFornecedores');
    },
    loadMovcaixasaldo(movcaixasaldo) {
      if (!movcaixasaldo) {
        this.lancamento = {
          id: null,
          ativo: true,
        };
        return;
      }

      this.lancamento.id = movcaixasaldo.id;
      this.lancamento.ativo = movcaixasaldo.ativo;
      // this.loadMovvenda();
    },
    format(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    pularProxCampo(value) {
      switch (value) {
        case 'extracao':
          this.$refs.extracao.focus();
          break;
        case 'valor':
          document.getElementsByName('valorCaixa')[0].focus();
          break;
        case 'concurso':
          this.$refs.concurso.focus();
          break;
        case 'evento_ano':
          this.$refs.evento_search.focus();
          break;
        case 'evento':
          this.$refs.evento.focus();
          break;
        case 'quantidade':
          this.$refs.quantidade.focus();
          break;
        case 'funcionario':
          setTimeout(() => {
            this.$refs.funcionario.focus();
          }, 200);
          break;
        case 'produto':
          setTimeout(() => {
            this.$refs.produto.focus();
          }, 200);
          break;
        case 'vencimento':
          this.$refs.vencimento.focus();
          this.$refs.vencimentoRef.show();
          break;
        case 'cadastrar':
          setTimeout(() => {
            this.$refs.cadastrar.click();
            this.$refs.produto.focus();
          }, 200);
          break;
        case 'save':
          this.$refs.save.click();
          break;
      }
    },
    reset(tipo) {
      this.evento_search = null;
      this.camposAdicionaisDialog = false;
      this.produtoSelecionado = null;
      this.observacao = null;
      this.errors = [];

      switch (tipo) {
        case 'atualizar': // Limpar e atualizar modal
          // this.$store.dispatch('getCompras');
          this.produtoCompra = this.produtoCompraReset();
          break;
        case 'fechar': // Limpar e fechar modal
          // this.$store.dispatch('getCompras');
          this.$store.dispatch('resetModal');
          this.$store.commit('setDialogCaixaCompraDetalhada', false);
          break;
      }
    },
    produtoCompraReset() {
      return {
        fornecedor_id: null,
        user_id: null,
        produto_id: null,
        tpcontrole: null,
        quantidade: 1,
        dtmovimento: moment().format('DD/MM/YYYY'),
        evento_id: null,
        extracao: null,
        concurso: null,
        vencimento: null,
        valor: 0.0,
        observacao: null,
      };
    },
    resetCamposAdicionaisInputs() {
      return {
        user_id: null,
        tpcontrole: null,
        quantidade: 1,
        dtmovimento: moment().format('DD/MM/YYYY'),
        evento_id: null,
        extracao: null,
        concurso: null,
        vencimento: null,
        valor: 0.0,
        observacao: null,
      };

      this.camposAdicionaisDialog = false;
    },
    closeModel(modal) {
      if (modal === 'dialogCaixaCompraDetalhada') {
        this.reset('fechar');
      } else {
        this.resetCamposAdicionaisInputs();
      }
    },
    save() {
      let produtoCompra = this.produtoSelecionado;

      if (
        !produtoCompra ||
        produtoCompra.tpcontrole === 'Nenhum' ||
        produtoCompra.tpcontrole === null
      ) {
        return this.addCompra();
      }

      this.camposAdicionaisDialog = true;

      switch (produtoCompra.tpcontrole) {
        case 'Extração':
          setTimeout(() => {
            this.pularProxCampo('extracao');
          }, 200);
          break;
        case 'Concurso':
          setTimeout(() => {
            this.pularProxCampo('concurso');
          }, 200);
          break;
        case 'Evento':
          setTimeout(() => {
            this.pularProxCampo('evento_ano');
          }, 200);
          break;
      }
    },
    buscarProduto(val, update, abort) {
      update(() => {
        this.getProdutos = this.produtosEstoque.filter(
          produto => produto.nome.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
    buscarFornecedor(val, update) {
      update(() => {
        this.fornecedoresFilter = this.fornecedores.filter(
          fornecedor => fornecedor.nome.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
    filterFn(val, update, abort) {
      update(() => {
        const params = {
          ativo: 1,
          funcionario: 1,
          fornecedor: 1,
          busca: val,
        };

        axios.get('/cadastros', { params }).then(res => {
          this.fornecedoresFilter = res.data.data.cadastros;
        });
      });
    },
    buscaAno(val) {
      if (val <= 1990) return;
      this.$store.dispatch("produtosEstoque", {
        tipo: 'P',
        ano: val,
        ativo: 1,
      });

      this.pularProxCampo('evento');
    },
    addCompra(save) {
      if (this.loadingAdicionarItem) return;

      this.loadingAdicionarItem = true;

      let produto = this.produtoSelecionado;
      let produtoCompra = {
        // Lançamento
        tipo: 12,
        id: this.lancamento.id,
        nome: '',
        valor: 0.01,
        // Ação
        acao_compra:
          this.fornecedorSelecionado && this.fornecedorSelecionado.id ? 'add-item' : 'abrir-compra',
        // Movcompra
        ...this.produtoCompra,
      };

      let url = `/lancamentos/add-saldo/${this.movcaixa.id}`;

      if (produto && produto.tpcontrole) {
        produtoCompra.tpcontrole = produto.tpcontrole;
      }

      if (this.produtoSelecionado && this.produtoSelecionado.id) {
        produtoCompra.produto_id = this.produtoSelecionado.id;
        produtoCompra.valor = this.valorTotalProdutoSelecionado;
        produtoCompra.user_id = this.dataUser.cadastro_id;
      }

      if (this.fornecedorSelecionado && this.fornecedorSelecionado.id) {
        produtoCompra.movcompra_id = this.compras.compra.id;
        produtoCompra.fornecedor_id = this.fornecedorSelecionado.id;
        produtoCompra.dtmovimento = moment
          .utc(this.compras.compra.dtmovimento)
          .format('DD/MM/YYYY');
      }

      axios
        .post(url, produtoCompra)
        .then(res => {
          this.loadingAdicionarItem = false;

          if (!res.data.success) {
            this.errors = res.data.errors;

            if (this.errors && this.errors.fornecedor_id) {
              this.camposAdicionaisDialog = false;
            }

            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });

            return;
          }

          this.$q.notify({
            message: 'Produto adicionado na lista de compra !',
            color: 'green',
            position: 'top-right',
          });

          this.listaCompras(
            res.data.movcompra && res.data.movcompra.id
              ? res.data.movcompra.id
              : this.compras.compra.id
          );

          this.reset('atualizar');

          this.lancamento = res.data.movcaixasaldo;

          this.$store.dispatch('atualizarListaLancamentos', this.movcaixa.id);
        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });

          this.loadingAdicionarItem = false;

          setTimeout(() => {
            this.saving = false;
          }, 500);
        });
    },
    listaCompras(id = null) {
      this.$store.dispatch('comprasProdutosItems', id);
    },
    deleteCompraItem(id) {
      this.$q
        .dialog({
          title: 'Deseja remover produto da lista de compra ?',
          message: 'Produto será removido',
          cancel: 'Cancelar',
          persistent: true,
        })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(`/lancamentos/add-saldo/${this.movcaixa.id}`, {
              // Lançamento
              tipo: 12,
              id: this.lancamento.id,
              nome: '',
              valor: 0.01,
              // Movcompra
              movcompra_item_id: id,
              acao_compra: 'remover-item',
            })
            .then(res => {
              if (res.data.success) {
                this.$q.notify({
                  message: 'Produto removido da lista.',
                  color: 'green',
                  position: 'top-right',
                });
              } else {
                this.$q.dialog({
                  title: res.data.message,
                  class: 'bg-warning text-white',
                  ok: {
                    'text-color': 'orange',
                    color: 'white',
                  },
                });
              }
              Loading.hide();

              if (this.fornecedorSelecionado && this.fornecedorSelecionado.id) {
                this.listaCompras(this.compras.compra.id);
              }

              this.reset('atualizar');

              this.lancamento = res.data.movcaixasaldo;

              this.$store.dispatch('atualizarListaLancamentos', this.movcaixa.id);
            })
            .catch(err => {
              this.$q.dialog({
                title: 'Não foi Possível realizar a ação, ou você não possui permissão isto!',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white',
                },
              });
              Loading.hide();
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    atualizarListaCompras(atualizar = false) {
      this.$store.dispatch('getCompras', atualizar);
    },
    finalizarCompra(id) {
      this.$q
        .dialog({
          title: 'Deseja finalizar sua compra?',
          message: 'Sua comprar será finalizada.',
          cancel: 'Cancelar',
          persistent: true,
        })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(`/lancamentos/add-saldo/${this.movcaixa.id}`, {
              // Lançamento
              tipo: 12,
              id: this.lancamento.id,
              nome: '',
              valor: 0.01,
              // Movcompra
              movcompra_id: id,
              observacao: this.observacao,
              acao_compra: 'finalizar-compra',
            })
            .then(res => {
              if (res.data.success) {
                this.$q.notify({
                  message: 'Compra foi finalizada com sucesso !',
                  color: 'green',
                  position: 'top-right',
                });

                this.lancamento = res.data.movcaixasaldo;

                this.$store.dispatch('atualizarListaLancamentos', this.movcaixa.id);
              } else {
                this.$q.dialog({
                  title: res.data.message,
                  class: 'bg-warning text-white',
                  ok: {
                    'text-color': 'orange',
                    color: 'white',
                  },
                });
              }
              Loading.hide();
              this.reset('fechar');
            })
            .catch(err => {
              this.$q.dialog({
                title: 'Não foi Possível realizar a ação, ou você não possui permissão isto!',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white',
                },
              });
              Loading.hide();
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    atualizarValorTotalProdutoSelecionado() {
      this.valorTotalProdutoSelecionado = this.produtoSelecionado
        ? this.produtoSelecionado.vlrcusto * this.produtoCompra.quantidade
        : 0;
    },
  },
  computed: {
    ...mapGetters([
      'produtosEstoque',
      'fornecedores',
      'produtosEstoqueAno',
      'dataUser',
      'compras',
      'total',
      'fornecedorSelecionado',
      'dialogCaixaCompraDetalhada',
      'carregamentoItemsCompras',
      'movcaixa',
      'compraCaixasDetalhada'

    ]),
    dialogCaixaCompraDetalhada: {
      get() {
        return this.$store.getters.dialogCaixaCompraDetalhada;
      },
      set() {},
    },
    getDtMovimento(){
      let dtmovimento = '';
       if(this.compras && this.compras.compra && this.compras.compra.dtmovimento){
          dtmovimento = moment(this.compras.compra.dtmovimento).format('DD/MM/YYYY');
       }
       return dtmovimento;
    }
  },
  watch: {
    dialogCaixaCompraDetalhada(val) {
      if (!this.dialogCaixaCompraDetalhada) return;

      this.init(this.compraCaixasDetalhada);
    },
    'produtoCompra.quantidade'() {
      this.atualizarValorTotalProdutoSelecionado();
    },
    produtoSelecionado() {
      this.atualizarValorTotalProdutoSelecionado();
    },
  },
};
</script>
