<template>
  <q-card :class="{ 'fixed-bottom': true, 'fixed-middle': true, expanded }" style="border">
    <q-card-section :class="{ relative: true, 'q-pa-xs': !expanded }">
      <div :class="expanded ? 'absolute-right' : ''">
        <q-btn
          flat
          round
          dense
          color="grey-7"
          :icon="expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
          @click="expanded = !expanded"
        />
      </div>

      <q-slide-transition @show="updateClassName" @hide="updateClassName">
        <div v-show="expanded" :class="{ 'q-pr-xl': true, 'total-info': true }" ref="totalInfo">
          <div class="flex justify-between">
            <div>
              <strong>Total</strong>
              ({{ total.quantidade }})
            </div>
            <p class="q-mb-none">R$ {{ total.valor | currency }}</p>
          </div>
          <div class="flex justify-between ">
            <div>
              <strong>Selecionado</strong>
              ({{ selecionado.quantidade }})
            </div>
            <p class="q-mb-none">R$ {{ selecionado.valor | currency }}</p>
          </div>
        </div>
      </q-slide-transition>
    </q-card-section>
  </q-card>
</template>

<script>
import { QSlideTransition } from 'quasar';

export default {
  name: 'card-total-itens',
  components: { QSlideTransition },
  props: {
    total: Object,
    selecionado: Object,
    tipo: String,
  },
  data() {
    return {
      expanded: true,
    };
  },
  methods: {
    updateClassName() {
      if (this.expanded) {
        this.$refs.totalInfo.classList.remove('d-none');
        this.$refs.totalInfo.classList.add('d-block');
      } else {
        this.$refs.totalInfo.classList.remove('d-block');
        this.$refs.totalInfo.classList.add('d-none');
      }
    },
  },
  watch: {
    expanded(val) {
      localStorage.setItem(`${this.tipo}ExpandedTotal`, Number(this.expanded));
    },
  },
  mounted() {
    const expanded = localStorage.getItem(`${this.tipo}ExpandedTotal`);

    if (expanded) {
      this.expanded = expanded === '1';
    }

    this.updateClassName();
  },
};
</script>

<style lang="stylus" scoped>
.q-card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.q-card:not(.expanded) {
  width: 41px;

  .total-info {
    width 100vh;
    visibility: hidden !important;
  }
}

.q-card.expanded {
  width: 90%;
  max-width: 400px;

  .absolute-right {
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
}

.fixed-middle {
  left: 50%;
  transform: translateX(-50%);
}
</style>
