<template>
  <div id="tourVideoFinal">
    <q-card-section v-if="openDialogTour">
      <iframe
        style="width: 100%; height: 385px;"
        :src="manual.link"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
      ></iframe>
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "tour-video-final",
  computed: {
    ...mapGetters([
      "manual",
      "openDialogTour"
    ])
  },
  beforeMount() {
    this.$store.dispatch("loadManual", "tour");
  }
};
</script>

<style lang="stylus" scoped></style>
