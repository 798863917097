<template>
  <div id="tourProdutos">
    <q-card-section v-if="loadingContas" class="text-center">
      <q-spinner color="blue-7" size="4em" style="position: relative" />
    </q-card-section>
    <q-card-section>
      <p class="text-center">
        Deseja importar os cadastros de produtos padrão?
      </p>

      <div class="row">
        <div class="col-12 col-md-12 text-center">
          <q-btn
            label="Importar"
            class="q-px-md bg-positive text-white"
            :loading="loadingContas"
            @click="importProdutos"
          />
        </div>
      </div>
    </q-card-section>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
import { Notify } from "quasar";

export default {
  name: "tour-produtos",
  emits: ["next"],
  data() {
    return {
      importingPlanodeContas: false,
      productsImported: false
    };
 },
  methods: {
    importProdutos() {
      if (this.importingPlanodeContas) {
        return;
      }

      this.importingPlanodeContas = true;
      
      // Primeiro é importado os planos de conta
      // em seguida os produtos
      axios
        .post("/plano-contas/importar")
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: "Não foi possível importar os produtos. Tente novamente mais tarde.",
              color: "red",
              position: "top-right",
            });
          }

          this.importingPlanodeContas = false;
          this.$store.dispatch("addProduto", { importar_produtos: true });
          this.productsImported = true;
        });
    },
  },
  computed: {
    ...mapGetters([
      "saveProduto"
    ]),
    loadingContas() {
      return this.importingPlanodeContas || this.saveProduto;
    }
  },
  watch: {
    saveProduto(val) {
      if (!val && this.productsImported) {
        this.$emit("next");
      }
    }
  }
};
</script>
