<template>
  <q-layout view="hHh lpR fFf" class="fundo-alterar">
    <div id="alterarSenha">
      <div class="q-pa-md">
        <div class="row">
          <div class="col-12 col-md-3 fixed-center">
            <q-card>
              <q-card-section class="text-center">
                <img src="@/assets/douranovo.png" alt="DouraSoft Logo" width="150" />
              </q-card-section>

              <q-card-section v-if="newPass.primeiraEtapa" class="text-center">
                <p>Digite o e-mail para iniciar a recuperação de sua senha.</p>
              </q-card-section>

              <q-card-section v-if="newPass.primeiraEtapa">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <q-input v-model="email" label="E-mail"></q-input>
                  </div>
                  <div class="col col-md-12">
                    <br />
                    <br />
                    <br />
                    <q-btn
                      no-wrap
                      v-ripple
                      label="Trocar Senha"
                      no-caps
                      class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                      style="margin: 0 15px 20px 0"
                      clickable
                      :loading="newPass.loadAlterar"
                      @click="enviar()"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                  </div>
                </div>
              </q-card-section>

              <q-card-section v-if="newPass.segundaEtapa" class="text-center">
                <p>Cheque sua caixa de entrada, em instantes você receberá o E-mail de redefinição de senha.</p>
              </q-card-section>

              <q-separator />

              <q-card-section class="text-center" style="padding: 5px;font-size: 13px">
                <router-link
                  to="/login"
                  style="text-decoration:none; color: black"
                >Voltar ao Login...</router-link>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
    </div>
  </q-layout>
</template>

<script>
export default {
  name: "alterar-senha",
  meta: {
    title: "DSB | Alterar Senha"
  },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    enviar() {
      this.$store.dispatch("enviar", { email: this.email });
    },
  },
  computed: {
    newPass() {
      return this.$store.getters.enviar;
    },
  }
};
</script>

<style lang="stylus" scoped></style>
