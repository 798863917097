<template>
  <div id="modalNovoUsuario">
    <q-dialog v-model="OpenDialogNovoUsuario" persistent>
      <q-card style="min-width: 350px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Novo Usuário</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <autocomplete
                  title="Buscar Funcionário"
                  :filter="filterFn"
                  :carregando="carregando"
                  :options="options"
                  :formatDisplay="formatDisplay"
                  :create="createValue"
                  @resultAutocomplete="resultEmitRecebe"
                  :display="displayMontar"
                  tipoConsulta="cadastro"
                  :cadastro="abreCadastro"
                  :newCadastro="cadastroReturn"
                />
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  v-model="novoFuncionario.cargo"
                  :options="cargos"
                  label="Cargo"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12 q-pb-xl">
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  type="submit"
                  :loading="loadNovo"
                  @click="salvar()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { bus } from "@/main";
import { Notify } from "quasar";
export default {
  name: "modal-novo-usuario",
  data() {
    return {
      novoFuncionario: this.newFuncionario(),
      funcionarios: [],
      user: [],
      cargos: [],
      loadNovo: false,
      // Funções do autocomplete
      carregando: false,
      options: [],
      selectItem: null,
      autocompleteResult: {}
    };
  },
  methods: {
    newFuncionario() {
      return {
        funcionario: null,
        cargo: ""
      };
    },
    closeModal() {
      this.$store.commit("OpenDialogNovoUsuario", false);
    },
    listar() {
      axios.get("/users/add").then(res => {
        if (res.data.success) {
          this.cargos = res.data.data.cargos;
        }
      });
    },
    salvar() {
      let funcionario = JSON.parse(JSON.stringify(this.novoFuncionario));
      funcionario.funcionario =
        this.autocompleteResult && this.autocompleteResult.id
          ? this.autocompleteResult.id
          : this.cadastroReturn.id;
      this.loadNovo = true;
      axios
        .post("/users/add", funcionario)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            this.$store.dispatch("editUsers");
            this.$store.commit("OpenDialogNovoUsuario", false);
            this.novoFuncionario = this.newFuncionario();
            bus.$emit("atualizarListaUsuario");
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          this.loadNovo = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.loadNovo = false;
        });
    },
    // autocomplete
    // Funções do autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/cadastros?ativo=1&funcionario=1&busca=" + val).then(res => {
          this.carregando = false;
          this.options = res.data.data.cadastros;
        });
      });
    },
    createValue(val, done) {
      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
    },
    formatDisplay(result) {
      return result.nome;
    },
    resultEmitRecebe(result) {
      this.autocompleteResult = result;
      this.$store.commit("cadastroReturn", null);

      if (this.autocompleteResult && this.autocompleteResult.id != null) {
        Notify.create({
          message: this.autocompleteResult.nome + " foi selecionado.",
          color: "green",
          position: "top-right"
        });
      }
    },
    displayMontar(value) {
      return !value ? value : !value.nome ? value.name : value.nome;
    },
    abreCadastro(value) {
      this.$store.dispatch("cadastro", {
        nome: value.name,
        noFuncionario: true,
        noCliente: false,
        noFornecedor: false,
        noSearch: true
      });
    }
  },
  computed: {
    OpenDialogNovoUsuario: {
      get() {
        return this.$store.getters.OpenDialogNovoUsuario;
      },
      set() {}
    },
    ...mapGetters(["cadastroReturn"])
  },
  mounted() {
    this.listar();
  }
};
</script>

<style lang="stylus" scoped></style>
