<template>
  <div id="lancamentoCaixa">
    <div class="row q-col-gutter-md">
      <div class="col-12 col-md-4">
        <q-list
          v-show="!escondeTotalizadores"
          bordered
          dense
          class="rounded-borders"
          ref="listaTotalizadores"
        >

          <q-item-label header>Entradas</q-item-label>

          <q-item
            v-ripple
            clickable
            :class="{ active_status: tipoSaldoFiltro === 1 }"
            @click="handleTipoSaldoFiltro(1);"
          >
            <q-item-section avatar class="sinal-totalizador">
              <q-item-label>(+)</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label>Saldo Inicial</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>R$ {{totaldosCaixas.total_saldo_inicial |currency}}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-ripple
            clickable
            :class="{ active_status: tipoSaldoFiltro === 3 }"
            @click="handleTipoSaldoFiltro(3);"
          >
            <q-item-section avatar class="sinal-totalizador">
              <q-item-label>(+)</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Reforços</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>R$ {{totaldosCaixas.total_reforco |currency}}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-ripple
            clickable
            :class="{ active_status: tipoSaldoFiltro === 2 }"
            @click="handleTipoSaldoFiltro(2);"
          >
            <q-item-section avatar class="sinal-totalizador">
              <q-item-label>(+)</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Vendas</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>R$ {{totaldosCaixas.total_vendas |currency}}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-ripple
            clickable
            :class="{ active_status: tipoSaldoFiltro === 2.1 }"
            @click="handleTipoSaldoFiltro(2.1);"
          >
            <q-item-section avatar class="sinal-totalizador">
              <q-item-label>(+)</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Resumo do Operador</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>R$ {{totaldosCaixas.total_resumo |currency}}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-ripple
            clickable
            :class="{ active_status: tipoSaldoFiltro === 4 }"
            @click="handleTipoSaldoFiltro(4);"
          >
            <q-item-section avatar class="sinal-totalizador">
              <q-item-label>(+)</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Recebimento de Contas</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>R$ {{totaldosCaixas.total_recebimentos |currency}}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item-label header>Saídas</q-item-label>

          <q-item
            v-ripple
            clickable
            :class="{ active_status: tipoSaldoFiltro === 6 }"
            @click="handleTipoSaldoFiltro(6);"
          >
            <q-item-section avatar class="sinal-totalizador">
              <q-item-label>(-)</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Pagamento de Contas</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>R$ {{totaldosCaixas.total_pagamentos |currency}}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-ripple
            clickable
            :class="{ active_status: tipoSaldoFiltro === 10 }"
            @click="handleTipoSaldoFiltro(10);"
          >
            <q-item-section avatar class="sinal-totalizador">
              <q-item-label>(-)</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Compras</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>R$ {{totaldosCaixas.total_compras |currency}}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-ripple
            clickable
            :class="{ active_status: tipoSaldoFiltro === 5 }"
            @click="handleTipoSaldoFiltro(5);"
          >
            <q-item-section avatar class="sinal-totalizador">
              <q-item-label>(-)</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Vendas Fiado</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>R$ {{totaldosCaixas.total_fiado |currency}}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-ripple
            clickable
            :class="{ active_status: tipoSaldoFiltro === 7 }"
            @click="handleTipoSaldoFiltro(7);"
          >
            <q-item-section avatar class="sinal-totalizador">
              <q-item-label>(-)</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Sangrias</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>R$ {{totaldosCaixas.total_sangria |currency}}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-ripple
            clickable
            :class="{ active_status: tipoSaldoFiltro === 8 }"
            @click="handleTipoSaldoFiltro(8);"
          >
            <q-item-section avatar class="sinal-totalizador">
              <q-item-label>(-)</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Saldo Final do Caixa</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>R$ {{totaldosCaixas.total_saldo_final |currency}}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-ripple
            clickable
            :class="{ active_status: tipoSaldoFiltro === 9 }"
            @click="handleTipoSaldoFiltro(9);"
          >
            <q-item-section avatar class="sinal-totalizador">
              <q-item-label>(-)</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Saldo Final do Operador</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>R$ {{totaldosCaixas.total_saldo_final_operador |currency}}</q-item-label>
            </q-item-section>
          </q-item>

          <q-separator />

          <q-item>
            <q-item-section>
              <q-item-label lines="1" class="text-bold">{{totaldosCaixas.total > 0 ? 'Falta' : totaldosCaixas.total == 0 ? 'Total' : ' Sobra'}}</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label :class="totaldosCaixas.total > 0 ? 'text-red' :  totaldosCaixas.total == 0 ? 'text-black' : 'text-green' ">R$ {{Math.abs(totaldosCaixas.total) | currency}}</q-item-label>
            </q-item-section>
          </q-item>

        </q-list>

        <q-btn
          clickable
          @click="escondeTotalizadores = !escondeTotalizadores"
          flat
          dense
          style="width: 100%; height: 25px"
          :icon="button"
        />
      </div>

      <div class="col-12 col-md-8" v-if="temPermissaoVer">
        <q-card class="shadow-0" v-if=" caixa && caixa.length === 0">
          <q-card-section  class="colum text-center bg-grey-4 text-grey-6" style="height: 48vh">
            <div class="text-h6 absolute-center">Nenhum lançamento realizado</div>
          </q-card-section>
        </q-card>
        <div v-else style="height: 100%">
          <div class="tableContainer table-caixa"  v-show="!escondeTabela" :style="`height: ${alturaTabelaCaixa};`">
            <table style="max-height: 400px;overflow-y: auto;"  class="shadow-0" >
              <thead>
                  <tr>
                    <th width="25%">Tipo</th>
                    <th width="45%">Descrição</th>
                    <th width="15%">Entrada</th>
                    <th width="15%">Saída</th>
                    <th width="1%"></th>
                  </tr>
              </thead>
              <tbody>
                <tr  v-for="(c, i) in caixaFiltrado" :key="i"  :class="c.deleted ? 'through' :  ''">
                  <td  v-if="c.hide"  colspan="4" >
                    <div>
                      Usuário
                      <strong>{{c.user_add}} criado em {{c.created | formatDateTime}}</strong>
                      <span v-if="c.user_delete !== null && c.user_delete !== undefined">
                        &nbsp;&nbsp;&nbsp; Excluído em
                        <strong>{{c.deleted | formatDateTime}}</strong>&nbsp;&nbsp;&nbsp; Motivo
                        <strong>{{c.motivo}}</strong>
                      </span>
                    </div>
                  </td >
                  <td  v-if="!c.hide">{{c.created ? c.created : c.deleted | formatTime}} {{returnTipo(c.tipo)}}  {{ c.id }}  </td >
                  <td key="nome" v-if="!c.hide">
                    {{c.nome}}
                    <q-badge
                      color="blue"
                      v-if="!c.deleted && [2, 5].includes(c.tipo) && c.movvenda && !c.movvenda.finalizada"
                    >
                      Aberta
                    </q-badge>

                    <q-badge
                      color="blue"
                      v-if="
                        !c.deleted &&
                        [10].includes(c.tipo) &&
                        c.movcompra &&
                        c.movcompra.status === 'Aberto'
                      "
                    >
                      Aberta
                    </q-badge>
                  </td >
                  <td  key="valor" v-if="!c.hide" class="text-right" >
                    <span
                      v-if="c.valor !== null && ehPositivo(c.tipo)"
                    >R$ {{c.valor | currency}}</span>
                  </td >
                  <td  key="valor_negativo" v-if="!c.hide" class="text-right">
                    <span
                      v-if="c.valor !== null && ehNegativo(c.tipo)"
                    >R$ {{c.valor | currency}}</span>
                  </td >
                  <td class="tdfora">
                    <div class="divfora">
                      <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                        <q-btn
                          size="10px"
                          icon="edit"
                          class="bg-secondary text-white"
                          v-if="[2, 5].includes(c.tipo) && c.movvenda"
                          @click="editarMovvendaDetalhada(c)"
                        ></q-btn>

                        <q-btn
                          size="10px"
                          icon="edit"
                          class="bg-secondary text-white"
                          v-if="[10].includes(c.tipo) && c.movcompra"
                          @click="editarMovcompraDetalhada(c)"
                        />

                        <q-btn
                          size="10px"
                          icon="request_quote"
                          color="teal"
                          v-if="[3, 7].includes(c.tipo)"
                          @click="visualizarDetalhe(c.movconta_id)"
                        />

                        <q-btn
                          @click="esconde(c)"
                          size="10px"
                          class="bg-primary text-white"
                          icon="info"
                        ></q-btn>
                          <q-btn
                            :disable="c.user_delete !== null && c.user_delete !== undefined"
                            @click="excluiLancamento(c)"
                            size="10px"
                            :class="c.user_delete !== null && c.user_delete !== undefined ? 'bg-light text':'bg-negative text-white'"
                          >
                          <q-icon  name='delete_forever' />
                        </q-btn>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <q-btn
            clickable
            @click="escondeTabela = !escondeTabela"
            flat
            dense
            style="width: 100%; height: 25px"
            :icon="buttonT"
            v-if=" caixa && caixa.length > 0"
          />
        </div>
      </div>
      </div>

      <modal-lancamento-detalhamento
        v-if="modalDetalhamento"
        :openModalLancamentoDetalhamento="modalDetalhamento"
        :movconta="movcontaDetalhes"
        @closeModal="modalDetalhamento = false"
      />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify } from "quasar";

export default {
  name: "lancamento-caixa",
  data() {
    return {
      escondeTotalizadores: false,
      button: "arrow_drop_up",
      buttonT: "arrow_drop_up",
      escondeTabela: false,
      alturaTabelaCaixa: '44vh',
      tipoSaldoFiltro: null,
      movcontaDetalhes: null,
      modalDetalhamento: false,
    };
  },
  watch: {
    escondeTotalizadores() {
      this.escondeTotalizadores === true
        ? (this.button = "arrow_drop_down")
        : (this.button = "arrow_drop_up");
    },
    escondeTabela() {
      this.escondeTabela === true
        ? (this.buttonT = "arrow_drop_down")
        : (this.buttonT = "arrow_drop_up");
    }
  },
  methods: {
    esconde(c) {
      c.hide = !c.hide;
      this.$forceUpdate();
    },
    excluiLancamento(caixa) {
      this.$store.dispatch("excluiLancamento", caixa);
    },
    Substring(value) {
      if (value.length > 43) {
        return value.substring(0, 43) + "...";
      }
      return value;
    },
    ehPositivo(tipo) {
      if (!this.tiposSaldos.length) {
        return false;
      }

      var filtered = this.tiposSaldos.filter(function(item){
        return item.tipo === tipo;
      });

      if (!filtered[0]){
        return false;
      }

      return filtered[0].value === "+";
    },
    ehNegativo(tipo) {
      if (!this.tiposSaldos.length) {
        return false;
      }

      var filtered = this.tiposSaldos.filter(function(item){
        return item.tipo === tipo;
      });

      if (!filtered[0]){
        return false;
      }

      return filtered[0].value === "-";
    },
    returnTipo(tipo) {

      tipo = tipo === this.tiposSaldos.length ? tipo - 1 : tipo;

      var tipos = [
        "",
        "Saldo Inicial",
        "Venda",
        "Reforço",
        "Receber Contas",
        "Venda Fiado",
        "Pagar Contas",
        "Sangria",
        "Saldo Final",
        "Saldo Final do Operador",
        "Compra"
      ];

      return tipos[tipo];
    },
    editarMovvendaDetalhada(caixa) {
      this.$store.dispatch("openDialogVendaCaixasDetalhada", caixa);
    },
    editarMovcompraDetalhada(movcaixasaldo) {
      this.$store.dispatch("openDialogCaixaCompraDetalhada", movcaixasaldo);
    },
    handleTipoSaldoFiltro(tipo) {
      this.tipoSaldoFiltro = tipo !== this.tipoSaldoFiltro ? tipo : null;
    },
    visualizarDetalhe(movconta_id) {
      axios
        .get(`lancamentos/detalhar/${movconta_id}/${this.movcaixa.id}`)
        .then(res => {
            if (!res.data.success) {
              Notify.create({
                message: "Não foi possível carregar o detalhamento.",
                color: "red",
                position: "top-right"
              });
              return;
            }

            this.modalDetalhamento = true;
            this.movcontaDetalhes = res.data.movconta;
        });
    }
  },
  computed: {
    ...mapGetters([
      "caixa",
      "totaldosCaixas",
      "movcaixa",
      "tiposSaldos",
      "temPermissaoVer",
    ]),
    tiposSaldosOptions() {
      const tiposSaldos = [
        {
          label: 'Todos',
          tipo: null,
        },
        ...this.tiposSaldos,
      ];

      return tiposSaldos;
    },
    caixaFiltrado() {
      if (!this.tipoSaldoFiltro) {
        return this.caixa;
      }

      /**
       * O tipo 2.1 não existe nos tipos de saldo, porém, ele é referente ao
       * resumo do operador, que é do tipo 2 e tem o atributo is_resumo como true.
       * Então criamos este tipo especial para filtrar manualmente nestas condições
       **/
      if (this.tipoSaldoFiltro === 2.1) {
        return this.caixa.filter(c => c.tipo === 2 && c.is_resumo);
      }

      return this.caixa.filter(c => c.tipo === this.tipoSaldoFiltro && (c.tipo !== 2 || !c.is_resumo));
    },
  },
  mounted() {
    this.alturaTabelaCaixa = `${this.$refs.listaTotalizadores.$el.offsetHeight}px !important`;
  },
};
</script>

<style lang="stylus" scoped>

.sem_lancamento {
  background-color: #f2f2f2;
  height: 48vh;
  position: relative;
}

.textofilho {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18pt;
  color: #bbb;
}

table{
  width 100%;
  border-collapse: collapse;
}

thead{
  background:#4285f4;
  color:#ffffff;
}

tr,th,td{
  padding: 4px;
}

tbody tr:hover {
  background-color:#ededed;
}

.tableContainer {
  clear: both;
  overflow: auto;
  width: 100%;
  -moz-box-shadow: inset 0 -10px 20px -10px gray;
  -webkit-box-shadow: inset 0 -10px 20px -10px gray;
  box-shadow: inset 0 -10px 20px -10px gray;
}

.table-caixa {
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
}

.table-caixa table tbody tr td {
  border-top: 1px solid #ddd;
}

.through{
  text-decoration: line-through;
}

.numb-align{
  text-align :center;
}

.text-align-right{
  text-align :right;
}

.sinal-totalizador {
  padding-right: 8px;
  min-width: 32px;
}

</style>
