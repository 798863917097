<template>
  <div id="cardGrafReceita">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-h6">
            <q-icon style="position:relative; top: -3px" name="show_chart" />
           {{fetchUser.db.receitas.title}}
          </div>
          <div class="text-subtitle2">{{fetchUser.db.receitas.subtitle}}</div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <GChart type="BarChart" :data="fetchUser.db.receitas.data" :options="chartOptions" />
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GChart } from "vue-google-charts";
export default {
  name: "card-graf-receita",
  components: {
    GChart
  },
  data() {
    return {
      chartOptions: {
        isStacked: "percent",
        height: 99,
        legend: { position: "top", maxLines: 3 },
        bar: { groupWidth: "25%" },
        hAxis: { minValue: 0 },
        colors: ["#ea4335", "#34a853", "#4285f4"]
      }
    };
  },
  methods: {
    atualizaDash() {
      this.$store.dispatch("fetchUser");
    },
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "0",
      });
    },
  },
  computed: {
    ...mapGetters([
      "fetchUser",
      "layout",
    ]),
    chartData() {
      return this.fetchUser.db.receitas.data;
    }
  },
  mounted() {}
};
</script>

<style lang="stylus" scoped></style>
