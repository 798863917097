<template>
  <div id="acaoCaixa">
    <q-card-section style="padding-top: 0">
      <div class="q-pa-md">
        <div class="q-col-gutter-md">
          <div class="col-12" v-if="acaoCaixa === 8">
            <q-toggle
              v-model="isSaldoFinalOperador"
              color="green"
              class="text-weight-bold"
              :label="
                isSaldoFinalOperador
                  ? 'Saldo Final com o Operador'
                  : 'Saldo Final fica no Caixa'
              "
            />
          </div>
          <div class="col-12" @keydown.enter.prevent="proximo('valor')">
            <q-input
              bottom-slots
              :error="errorsCaixas.nome ? true : false"
              v-model="venda.descricao"
              :label="returnPlaceholder()"
              :disable="isSaldoFinal"
              name="descricaoCaixa"
              autocomplete="off"
            >
              <template v-slot:error>
                <span
                  v-for="(error, i) in errorsCaixas.nome"
                  :key="error + i"
                  >{{ error }}</span
                >
              </template>
            </q-input>
          </div>
          <div
            class="col-12"
            v-if="!movcontaReturn"
            @keydown.enter.prevent="proximo('salvar')"
          >
            <q-field
              id="valorCaixa"
              v-model="venda.valor"
              label="Valor"
              bottom-slots
              :error="errorsCaixas.valor ? true : false"
              :disable="isSaldoFinal || detalharNotasMoedas"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <money
                  :id="id"
                  class="q-field__native text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  name="valorCaixa"
                  v-show="floatingLabel"
                  :readonly="detalharNotasMoedas"
                >
                </money>
              </template>
              <template v-slot:error>
                <span
                  v-for="(error, i) in errorsCaixas.valor"
                  :key="error + i"
                  >{{ error }}</span
                >
              </template>
            </q-field>
          </div>
          <div class="col-12" v-if="botoesIncremento">
            <div class="row">
              <q-btn
                color="primary"
                label="+500"
                class="q-mr-md"
                :disable="detalharNotasMoedas"
                @click="() => (venda.valor += 500)"
              />
              <q-btn
                color="primary"
                label="+1000"
                class="q-mr-md"
                :disable="detalharNotasMoedas"
                @click="() => (venda.valor += 1000)"
              />
              <q-btn
                color="primary"
                label="+2000"
                :disable="detalharNotasMoedas"
                @click="() => (venda.valor += 2000)"
              />
            </div>
          </div>

          <slot name="valor-bottom" />

          <div
            class="col-12"
            v-if="movcontaReturn && acaoCaixa === tipoAcaocaixa()"
          >
            <q-list bordered padding class="rounded-borders">
              <q-item
                v-if="movcontaReturn.cadastro && movcontaReturn.cadastro.id"
              >
                <q-item-section>
                  <q-item-label lines="1">ID</q-item-label>
                </q-item-section>

                <q-item-section side class="text-black">
                  <q-item-label>{{ movcontaReturn.cadastro.id }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-if="movcontaReturn.cadastro && movcontaReturn.cadastro.nome"
              >
                <q-item-section>
                  <q-item-label lines="1">Nome</q-item-label>
                </q-item-section>

                <q-item-section side class="text-black">
                  <q-item-label>{{
                    movcontaReturn.cadastro.nome
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label lines="1">Descrição</q-item-label>
                </q-item-section>

                <q-item-section side class="text-black">
                  <q-item-label>{{
                    movcontaReturn.movconta.descricao
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label lines="1">Referência</q-item-label>
                </q-item-section>

                <q-item-section side class="text-black">
                  <q-item-label>{{
                    movcontaReturn.movconta.referencia
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label lines="1">Status</q-item-label>
                </q-item-section>

                <q-item-section side class="text-black">
                  <q-item-label>{{
                    movcontaReturn.movconta.status
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label lines="1">Valor</q-item-label>
                </q-item-section>

                <q-item-section side class="text-black">
                  <q-item-label
                    >R$
                    {{ movcontaReturn.movconta.valor | currency }}</q-item-label
                  >
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label lines="1">Vencimento</q-item-label>
                </q-item-section>

                <q-item-section side class="text-black">
                  <q-item-label>{{
                    movcontaReturn.movconta.vencimento | formatDate
                  }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
      </div>
    </q-card-section>
    <q-card-actions align="right" class="text-primary">
      <q-btn
        flat
        label="Salvar & Novo"
        clickable
        @click="envia('Novo')"
        :disable="addItemCaixa2 || isSaldoFinal"
        :loading="addItemCaixa2"
        class="bg-grey-6 text-white"
        style="margin-right: 10px; margin-bottom: 10px"
        id="salvarNovo"
      >
        <template v-slot:loading>
          <q-spinner color="grey-10" size="1em" />
        </template>
      </q-btn>
      <q-btn
        flat
        label="Salvar"
        clickable
        @click="envia()"
        :loading="addItemCaixa"
        :disable="addItemCaixa || isSaldoFinal"
        class="bg-green text-white"
        style="margin-right: 22px; margin-bottom: 10px"
        id="salvar"
      >
        <template v-slot:loading>
          <q-spinner color="grey-10" size="1em" />
        </template>
      </q-btn>
    </q-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
export default {
  name: "acao-caixa",
  components: { Money },
  props: ["botoesIncremento"],
  data() {
    return {
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      isSaldoFinalOperador: true
    };
  },
  methods: {
    clear() {
      this.search = "";
      this.isOpen = false;
      this.isLoading = false;
      this.arrowCounter = -1;
      this.taVazio = false;
      this.$store.dispatch("contas");
      this.$root.$emit("limpar"); // $root acessar a instancia raiz, ou seja, consigo acessar componente autocomplete
      this.$store.dispatch("resetDadosCaixaContaPagarReceber");
     this.$store.commit('conta_id_caixa', null);
    },
    limpaCadastro() {
      this.$store.commit("movcontaReturn", null);
      this.$store.commit("venda", {
        descricao: "",
        valor: 0
      });
      setTimeout(() => {
        document.getElementById("autocompleted").focus();
      }, 100);
    },
    envia(salvareNovo) {
      if (this.salvandoVendaCaixa) {
        return;
      }

      let data = {
        tipo: this.acaoCaixa,
        valor: this.venda.valor,
        nome: this.venda.descricao,
        movconta_id: this.movconta_id_caixa,
        conta_id: this.conta_id_caixa,
        cadastro_id: null
      };
      if (this.movcontaReturn) {
        data = {
          tipo: this.acaoCaixa,
          valor: Math.abs(this.movcontaReturn.movconta.valor),
          nome: this.venda.descricao,
          movconta_id: this.movcontaReturn.movconta.id,
          conta_id: this.conta_id_caixa,
          cadastro_id: this.movcontaReturn.movconta.cadastro
            ? this.movcontaReturn.movconta.cadastro.id
            : null
        };
      }

      if ([4, 6].includes(this.acaoCaixa)) {
        data.gerar_nova_movconta = this.dadosCaixaContaPagarReceber.gerarNovaMovconta;
        data.novo_vencimento = this.dadosCaixaContaPagarReceber.novoVencimento;
        data.valor_restante = this.dadosCaixaContaPagarReceber.valorRestante;
      }

      if (this.acaoCaixa === 4) {
        data.adicionar_saldo_cliente = this.dadosCaixaContaPagarReceber.adicionarSaldoCliente;
      }

      if (this.acaoCaixa === 7 && this.dadosSangrias.detalharNotas) {
        data.detalhe_notas = this.dadosSangrias.notas;
      }

      if (this.acaoCaixa === 7 && this.dadosSangrias.detalharMoedas) {
        data.detalhe_moedas = this.dadosSangrias.moedas;
      }

      if (this.acaoCaixa === 7 && this.dadosSangrias.transferirCaixa) {
        if (!this.dadosSangrias.caixa) {
          this.$store.commit("errorsCaixas", {
            caixa_id: ['Este campo não pode ser em branco'],
          });
          return;
        }

        data.caixa_id = this.dadosSangrias.caixa.id;
      }

      if (this.acaoCaixa === 3 && this.dadosReforcos.detalharNotas) {
        data.detalhe_notas = this.dadosReforcos.notas;
      }

      if (this.acaoCaixa === 3 && this.dadosReforcos.detalharMoedas) {
        data.detalhe_moedas = this.dadosReforcos.moedas;
      }

      if (this.acaoCaixa === 3 && this.dadosReforcos.transferirCaixa) {
        if (!this.dadosReforcos.caixa) {
          this.$store.commit("errorsCaixas", {
            caixa_id: ['Este campo não pode ser em branco'],
          });
          return;
        }

        data.caixa_id = this.dadosReforcos.caixa.id;
      }

      if (this.acaoCaixa === 8 && this.isSaldoFinalOperador) {
        data.tipo = 9;
      }

      this.$store.commit("salvareNovo", salvareNovo);
      this.$store.dispatch("alimentaCaixa", data);
      if (salvareNovo) {
        this.clear();
        this.$store.commit("addItemCaixa2", true);
      } else {
        this.$store.commit("addItemCaixa", true);
      }

      this.clear();
      this.$store.commit("isReadOnlyCaixa", false);
      this.$store.commit("blockValor", true);

      // document.getElementsByName('autocompleted')[0].focus();
    },
    proximo(val) {
      if (val === "valor") {
        if (!this.blockValor) {
          document.getElementsByName("valorCaixa")[0].focus();
        } else {
          this.envia("Novo");
        }
      }
      if (val === "salvar") {
        this.envia("Novo");
      }
    },
    returnPlaceholder() {
      if (this.acaoCaixa === 2) {
        return "Produto";
      } else if (this.acaoCaixa === 1) {
        return "Descrição Complementar";
      } else {
        return "Descrição";
      }
    },
    tipoAcaocaixa() {
      if (this.acaoCaixa === 6) {
        return this.acaoCaixa;
      }
      if (this.acaoCaixa === 4) {
        return this.acaoCaixa;
      }
    }
  },
  computed: {
    ...mapGetters([
      "acaoCaixa",
      "venda",
      "addItemCaixa",
      "addItemCaixa2",
      "isSaldoFinal",
      "errorsCaixas",
      "movconta_id_caixa",
      "conta_id_caixa",
      "isPrazo",
      "movconta_vencimento",
      "movcontaReturn",
      "blockValor",
      "isReadOnlyCaixa",
      "salvandoVendaCaixa",
      "dadosCaixaContaPagarReceber",
      "dadosSangrias",
      "dadosReforcos",
    ]),
    detalharNotasMoedas() {
      return this.dadosSangrias.detalharNotas || this.dadosSangrias.detalharMoedas
        || this.dadosReforcos.detalharNotas || this.dadosReforcos.detalharMoedas;
    },
  },
  mounted () {
    if(this.movcontaReturn) {
      this.$store.commit("movcontaReturn", null);
    }
  }
};
</script>

<style scoped></style>
