<template>
  <q-page id="estoque">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!isPermissoEstoque"></sem-permissao>
        <div class="col-12" v-if="tipo === 'empresa' && isPermissoEstoque">
          <q-table
            :data="estoques"
            row-key="id"
            :columns="columns"
            :loading="listaEstoques"
            :bottom="false"
            :pagination.sync="pagination"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
            :visible-columns="visibleColumns"
          >
            <template v-slot:bottom>
                <div></div>
            </template>
            <template v-slot:header="props">
              <q-tr :props="props">
                <q-th
                  v-if="visibleColumns.indexOf('estoque') !== -1"
                  key="estoque"
                  :props="props"
                >
                  Estoque
                </q-th>
                <q-th
                  v-if="visibleColumns.indexOf('funcionarios') !== -1"
                  key="funcionarios"
                  :props="props"
                >
                  Funcionários
                </q-th>
                <q-th
                  v-if="visibleColumns.indexOf('qtde_total') !== -1"
                  key="qtde_total"
                  :props="props"
                >
                  Qtde Total
                </q-th>
                <q-th
                  v-if="visibleColumns.indexOf('nome') !== -1"
                  key="nome"
                  :props="props"
                >
                  Nome
                </q-th>
                <q-th
                  v-if="visibleColumns.indexOf('tpcontrole') !== -1"
                  key="tpcontrole"
                  :props="props"
                >
                  Unidade
                </q-th>
                <th></th>
              </q-tr>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td
                  key="estoque"
                  class="text-right"
                  v-if="visibleColumns.indexOf('estoque') !== -1"
                >{{props.row.qtd_empresa}}</q-td>
                <q-td
                  key="funcionarios"
                  class="text-right"
                  v-if="visibleColumns.indexOf('funcionarios') !== -1"
                >{{props.row.qtd_funcionarios}}</q-td>
                <q-td
                  key="qtde_total"
                  class="text-right"
                  v-if="visibleColumns.indexOf('qtde_total') !== -1"
                >
                  <span
                    class="cursor-pointer"
                    @click="abrirInfoEstoque(props.row)">
                    {{ props.row.qtd_empresa + props.row.qtd_funcionarios }}
                  </span>
                </q-td>
                <q-td key="nome" v-if="visibleColumns.indexOf('nome') !== -1">
                  <span
                    class="cursor-pointer"
                    @click="abrirInfoEstoque(props.row)">
                    {{props.row.nome}}
                  </span>
                </q-td>
                <q-td
                  key="tpcontrole"
                  v-if="visibleColumns.indexOf('tpcontrole') !== -1"
                >{{props.row.tpcontrole}} {{props.row.extracao}} {{props.row.concurso}} {{props.row.evento}} {{props.row.vencimento | formatDate}}</q-td>
                <q-td class="tdfora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn
                        @click="abrirModalEstoque('adicionar', props.row)"
                        size="12px"
                        class="bg-positive text-white"
                        icon="add"
                      ><q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale">
                                  Adicionar estoque sem passar pela Retaguarda
                                </q-tooltip></q-btn>
                      <q-btn
                        @click="abrirModalEstoque('remover', props.row)"
                        size="12px"
                        class="bg-negative text-white"
                        icon="remove"
                      ><q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale">
                                  Remover estoque sem passar pela Retaguarda
                                </q-tooltip></q-btn>
                      <q-btn
                        @click="abrirModalEstoque('transferir', props.row)"
                        size="12px"
                        class="bg-warning text-white"
                        icon="compare_arrows"
                      ><q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale">
                                  Transferir para Operadores
                                </q-tooltip></q-btn>
                      <q-btn
                        @click="abrirInfoEstoque(props.row)"
                        size="12px"
                        class="bg-info text-white"
                        icon="info"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:top="props">
              <q-btn
                flat
                id="addEstoque"
                round
                dense
                icon="add"
                class="q-ml-md text-primary"
                style="margin-right: 10px;margin-left: 0"
                @click="abrirModalEstoque()"
              />
              <div class="col-1 q-table__title">Estoques</div>
              <q-space />
              <q-btn
                flat
                dense
                icon="print"
                size="12px"
                color="primary"
                :loading="loadingRelatorio"
                @click="imprimirRelatorioEstoque"
              />
              <q-btn
                flat
                dense
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="tipo === 'empresa' ? 'Estoque da Empresa' : 'Estoque do Operador'"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item
                      :class="{'active_status': tipo === 'empresa'}"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="filtroVisualizacao('empresa')"
                    >
                      <q-item-section>Estoque da Empresa</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': tipo === 'operador'}"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="filtroVisualizacao('operador')"
                    >
                      <q-item-section>Estoque do Operador</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-select
                v-model="visibleColumns"
                multiple
                borderless
                dense
                options-dense
                :display-value="'Colunas'"
                emit-value
                map-options
                :options="columns"
                option-value="name"
                style="min-width: 100px"
              />

              <q-btn
                v-if="hasVideo"
                dense
                flat
                color="bg-primary text-blue-7"
                icon="ondemand_video"
                class="q-ml-md"
                @click="modalIntro"
              >
                <template v-slot:default>
                  <q-tooltip>Introdução ao Estoque</q-tooltip>
                </template>
              </q-btn>

              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                rounded
                no-wrap
                v-ripple
                class="q-ml-sm q-px-md bg-white text-black"
                no-caps
                icon="more_vert"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Mais Opções</q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup aria-hidden="true" @click="atualizar">
                      <q-item-section>Atualizar</q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="props.toggleFullscreen"
                    >
                      <q-item-section>{{props.inFullscreen ? 'Minimizar' : 'Maximizar Tela'}}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </template>
          </q-table>
          <div>
            <q-page-sticky position="top-right" :offset="[0, 90]">
              <q-btn
                :color="OpenOpcoesAvancadasEstoque ? 'red-7' : 'blue-7'"
                :icon="OpenOpcoesAvancadasEstoque ? 'close' : 'settings'"
                class="rotate-0"
                style="border-top-left-radius: 50px;border-bottom-left-radius: 50px;"
                @click="OpenOpcoesAvancadasEstoque = !OpenOpcoesAvancadasEstoque"
              >
                <q-tooltip
                  :content-class="
                    OpenOpcoesAvancadasEstoque
                      ? 'bg-negative text-white text-center'
                      : 'bg-secondary text-white text-center'
                  "
                  >{{
                    OpenOpcoesAvancadasEstoque ? "Fechar Opções Avançadas" : "Opções Avançadas"
                  }}</q-tooltip
                >
              </q-btn>
            </q-page-sticky>

            <q-drawer
              side="right"
              v-model="OpenOpcoesAvancadasEstoque"
              bordered
              :width="320"
              :breakpoint="500"
              behavior="default"
              content-class="bg-white"
            >
              <q-scroll-area class="fit">
                <div class="q-pa-sm">
                  <div class="q-table__title">Opções Adicionais</div>
                  <div>
                    <q-list
                      class="rounded-borders"
                      style="max-width: 350px; margin-left: -10px;width: 300px;"
                    >
                      <q-item-label header></q-item-label>

                      <q-item
                        clickable
                        v-ripple
                        @click.exact="abrirVendaProdutosSemEstoque()"
                      >
                        <q-item-section>
                          <q-item-label lines="1">Opções Avançadas</q-item-label>
                          <q-item-label caption>Opções avançadas de estoque</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item
                        v-if="possuiMapaResgates"
                        clickable
                        v-ripple
                        @click.exact="abrirMapaResgate()"
                      >
                        <q-item-section>
                          <q-item-label lines="1">Mapa de Resgates</q-item-label>
                          <q-item-label caption>Gerar o Mapa de Resgate</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item clickable v-ripple to="/compras">
                        <q-item-section>
                          <q-item-label lines="1">Compras</q-item-label>
                          <q-item-label caption>Listar as Compras de produtos</q-item-label>
                        </q-item-section>
                      </q-item>

                    </q-list>
                  </div>
                </div>
              </q-scroll-area>
            </q-drawer>
          </div>
        </div>
        <div
          class="col-12 col-md-12"
          v-if="tipo === 'operador' && !listaEstoques && isPermissoEstoque"
        >
          <q-card>
            <q-card-section>
              <div class="col-4 q-table__title">
                <q-btn
                  flat
                  round
                  dense
                  :icon="'add'"
                  class="q-ml-md text-primary"
                  style="margin-right: 10px;margin-left: 0"
                  @click="abrirModalEstoque()"
                />Estoque
                <q-btn
                  v-if="$q.screen.gt.xs"
                  flat
                  dense
                  rounded
                  no-wrap
                  v-ripple
                  class="q-ml-sm q-px-md bg-white text-black"
                  no-caps
                  icon="more_vert"
                  style="margin-right: 10px; float: right"
                >
                  <q-menu anchor="top right" self="top right">
                    <q-list class="text-grey-8" style="min-width: 100px">
                      <q-item aria-hidden="true">
                        <q-item-section
                          class="text-uppercase text-grey-7"
                          style="font-size: 0.7rem"
                        >Mais Opções</q-item-section>
                      </q-item>
                      <q-item clickable v-close-popup aria-hidden="true" @click="atualizar">
                        <q-item-section>Atualizar</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>

                <q-btn
                  v-if="hasVideo"
                  dense
                  flat
                  color="bg-primary text-blue-7"
                  icon="ondemand_video"
                  @click="modalIntro"
                  style="float: right"
                >
                  <template v-slot:default>
                    <q-tooltip>Introdução ao Estoque</q-tooltip>
                  </template>
                </q-btn>

                <q-btn
                  flat
                  dense
                  no-wrap
                  v-ripple
                  color="primary"
                  no-caps
                  :label="tipo === 'empresa' ? 'Estoque da Empresa' : 'Estoque do Operador'"
                  class="q-ml-sm q-px-md bg-white text-blue-7"
                  style="margin-right: 10px; float: right"
                >
                  <q-menu anchor="top right" self="top right">
                    <q-list class="text-grey-8" style="min-width: 100px">
                      <q-item
                        :class="{'active_status': tipo === 'empresa'}"
                        clickable
                        v-close-popup
                        aria-hidden="true"
                        @click="filtroVisualizacao('empresa')"
                      >
                        <q-item-section>Estoque da Empresa</q-item-section>
                      </q-item>
                      <q-item
                        :class="{'active_status': tipo === 'operador'}"
                        clickable
                        v-close-popup
                        aria-hidden="true"
                        @click="filtroVisualizacao('operador')"
                      >
                        <q-item-section>Estoque do Operador</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
                <q-btn
                  flat
                  dense
                  icon="print"
                  size="12px"
                  :loading="loadingRelatorio"
                  @click="imprimirRelatorioEstoqueOperador"
                  style="margin-right: 10px; float: right"
                />
              </div>
            </q-card-section>

            <q-card-section>
              <div class="row q-col-gutter-x-lg">
                <div class="col-12 col-md-4 q-mb-lg" v-for="(estoque, i) in estoques" :key="i">
                  <q-card bordered style="height: 100%" class="no-shadow">
                    <q-card-section class="flex justify-between">
                      <div class="text-h6">
                        {{estoque.nome}}
                      </div>
                      <div>
                        <q-btn
                          @click="abrirModalEstoqueFuncionario('adicionar', estoque)"
                          flat
                          dense
                          round
                          icon="add"
                          color="green"
                          :disable="buscandoFuncEstoque"
                        >
                          <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale">
                            Adicionar múltiplos produtos sem passar pela Retaguarda
                          </q-tooltip>
                        </q-btn>
                        <q-btn
                          @click="abrirModalEstoqueFuncionario('remover', estoque)"
                          flat
                          dense
                          round
                          color="red"
                          icon="remove"
                          :disable="buscandoFuncEstoque"
                        >
                          <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale">
                            Remover múltiplos produtos sem passar pela Retaguarda
                          </q-tooltip>
                        </q-btn>
                        <q-btn
                          flat
                          dense
                          round
                          color="warning"
                          icon="compare_arrows"
                          :disable="buscandoFuncEstoque"
                          @click="abrirModalEstoqueFuncionario('transferir', estoque)"
                        >
                          <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale">
                            Transferir múltiplos produtos para Retaguarda ou Operadores
                          </q-tooltip>
                        </q-btn>
                      </div>
                    </q-card-section>

                    <q-card-section>
                      <q-list>
                        <q-item v-for="(produto, j) in estoque.produtos" :key="j + i">
                          <q-item-section side>
                            <q-item-label caption>Quantidade</q-item-label>
                            <q-item-label class="text-black">
                              <strong>{{produto.quantidade}}</strong>
                            </q-item-label>
                          </q-item-section>

                          <q-item-section>
                            <q-item-label caption>Produto</q-item-label>
                            <q-item-label>{{produto.nome}} {{produto.tpcontrole}} {{produto.extracao}} {{produto.concurso}} {{produto.evento}} {{produto.vencimento | formatDate}}</q-item-label>
                          </q-item-section>

                          <q-item-section top side>
                            <div class="text-grey-8 q-gutter-xs">
                              <q-btn
                                @click="abrirModalEstoque('adicionar', produto, estoque.id)"
                                :disable="buscandoFuncEstoque"
                                class="gt-xs"
                                size="12px"
                                flat
                                dense
                                round
                                icon="add"
                                color="green"
                              >
                                <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale">
                                  Adicionar estoque sem passar pela Retaguarda
                                </q-tooltip>
                              </q-btn>
                              <q-btn
                                @click="abrirModalEstoque('remover', produto, estoque.id)"
                                :disable="buscandoFuncEstoque"
                                class="gt-xs"
                                size="12px"
                                flat
                                dense
                                round
                                icon="remove"
                                color="red"
                              >
                                <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale">
                                  Remover estoque sem passar pela Retaguarda
                                </q-tooltip>
                              </q-btn>
                              <q-btn
                                @click="abrirModalEstoque('transferir', produto, estoque.id)"
                                :disable="buscandoFuncEstoque"
                                size="12px"
                                flat
                                dense
                                round
                                icon="compare_arrows"
                                color="warning"
                              >
                                <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale">
                                  Transferir para Retaguarda ou Operadores
                                </q-tooltip>
                              </q-btn>
                            </div>
                          </q-item-section>
                        </q-item>
                        <q-separator spaced inset />
                        <q-item>
                          <q-item-section>
                            <q-item-label caption></q-item-label>
                            <q-item-label></q-item-label>
                          </q-item-section>

                          <q-item-section side top>
                            <q-item-label caption>Total</q-item-label>
                            <q-item-label class="text-black">
                              <strong>{{returnTotalQuantidade(estoque.produtos)}}</strong>
                            </q-item-label>
                          </q-item-section>

                          <q-item-section top side>
                            <q-item-label caption></q-item-label>
                            <q-item-label></q-item-label>
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-card-section>
                  </q-card>
                </div>
              </div>
            </q-card-section>

          </q-card>
        </div>
      </div>
    </div>
    <modal-adicionar-remover-estoque ref="adicionarR" :tipoDialogEstoque="tipoDialogEstoque" :tipo="tipo"></modal-adicionar-remover-estoque>
    <modal-adicionar-remover-estoque-funcionario />
    <modal-info-produto-estoque />
    <modal-opcoes-avancadas-estoque />
  </q-page>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from '@/main';
import axios from "@/axios-auth";

export default {
  name: "estoque",
  meta: {
    title: "DSB | Estoque"
  },
  data() {
    return {
      visibleColumns: JSON.parse(localStorage.getItem("estoque__visibleColumns")) || ["estoque", "funcionarios", "qtde_total", "nome", "tpcontrole"],
      OpenOpcoesAvancadasEstoque: false,
      columns: [
        {
          name: "estoque",
          required: true,
          align: "center",
          label: "Estoque",
          field: row => row.qtd_empresa,
          sortable: true
        },
        {
          name: "funcionarios",
          align: "center",
          label: "Funcionários",
          field: row => row.qtd_funcionarios,
          sortable: true
        },
        {
          name: "qtde_total",
          align: "center",
          label: "Qtde Total",
          field: row => row.qtd_empresa + row.qtd_funcionarios,
          sortable: true
        },
        {
          name: "nome",
          label: "Nome",
          align: "center",
          field: row => row.name,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "tpcontrole",
          label: "Unidade",
          align: "center",
          field: this.estoqueControleCampo,
          format: val => `${val}`,
          sortable: true
        },
      ],
      pagination: {
        sortBy: "nome",
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      tipo: "empresa",
      ra: false,
      estoquesOperador: [],
      possuiMapaResgates: false,
      tipoDialogEstoque: "novo",
      loadingRelatorio: false,
      hasVideo: false
    };
  },
  methods: {
    estoqueControleCampo(row) {
      if (row.extracao) return row.extracao;
      if (row.concurso) return row.concurso;
      if (row.evento) return row.evento;
    },
    async verificaMapaResgates() {
      let empresa = await this.$store.getters.dataEmpresa;

      if (!empresa.aplicativos) {
        this.possuiMapaResgates = false;
        return;
      }

      this.possuiMapaResgates = Boolean(empresa.aplicativos.find(aplicativo => aplicativo.label === "Mapa de Resgates"));
    },
    abrirMapaResgate() {
      this.$router.push('/mapa-resgates');
    },
    filtroVisualizacao(tipo) {
      this.tipo = tipo;
      this.$store.dispatch("listarEstoque", {
        tipo: tipo === "operador" ? 1 : 0,
        forcarCarregamento: true,
      });
    },
    abrirModalEstoque(tipoDialogEstoque = 'novo', estoque, idFuncionario) {
      this.$refs.adicionarR.closeModal();
      this.tipoDialogEstoque = tipoDialogEstoque;

      if (tipoDialogEstoque === "novo") {

        this.$store.dispatch("produtosEstoque", {
          tipo: "P",
          all: 1,
          ativo: 1
        });

      }else {
        this.$store.commit("estoque", {
          ...estoque,
          funcionario_id: idFuncionario,
        });
      }

      this.$store.commit("openDialogEstoque", true);
    },
    atualizar() {
      this.$store.dispatch("listarEstoque", {
        tipo: this.tipo === "operador" ? 1 : 0
      });
    },
    returnTotalQuantidade(produtos) {
      if (!produtos) return 0;

      let total = 0;
      produtos.forEach(produto => {
        total += produto.quantidade;
      });
      return total;
    },
    abrirInfoEstoque(row) {
      this.$store.dispatch("dialogInfoEstoque", row);
    },
    imprimirRelatorioEstoque() {
      this.loadingRelatorio = true;

      axios
        .get("produtos/estoque?relatorio=1", {
          headers: {
            Accept: "text/html",
          },
        })
        .then(res => {
          if (!res.data) {
            throw res;
          }

          const windowJanela = window.open("about:blank");
          windowJanela.document.write(res.data);
          windowJanela.document.close();

          this.loadingRelatorio = false;
        })
        .catch(err => {
          this.$q.notify({
            message:"Não foi possível imprimir o relatório!",
            color: "red",
            position: "top-right"
          });
          this.loadingRelatorio = false;
        });
    },
    imprimirRelatorioEstoqueOperador() {
      this.loadingRelatorio = true;

      axios
        .get("produtos/estoque?relatorio=1&funcionarios=1", {
          headers: {
            Accept: "text/html",
          },
        })
        .then(res => {
          if (!res.data) {
            throw res;
          }

          const windowJanela = window.open("about:blank");
          windowJanela.document.write(res.data);
          windowJanela.document.close();

          this.loadingRelatorio = false;
        })
        .catch(err => {
          this.$q.notify({
            message:"Não foi possível imprimir o relatório!",
            color: "red",
            position: "top-right"
          });
          this.loadingRelatorio = false;
        });
    },
    abrirVendaProdutosSemEstoque() {
      this.$store.dispatch("toggleModalVendaProdutosSemEstoque", true);
    },
    abrirModalEstoqueFuncionario(tipo, funcionario) {
      bus.$emit("openModalAdicionarRemoverEstoqueFuncionario", { tipo, funcionario });
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "estoques");

      if (!this.videosManuais.video_estoques) {
        this.modalIntro();
      }
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "estoques");
    },
  },
  computed: {
    ...mapGetters([
      "estoques",
      "buscandoFuncEstoque",
      "listaEstoques",
      "isPermissoEstoque",
      "videosManuais",
      "manuaisDisponiveis"
    ])
  },
  watch: {
    visibleColumns() {
      localStorage.setItem("estoque__visibleColumns", JSON.stringify(this.visibleColumns));
    },
  },
  mounted() {
    this.$store.dispatch("listarEstoque");
    this.verificaMapaResgates();
    this.verificarPrimeiroVideo();
  },
};
</script>

<style lang="stylus" scoped></style>
