<template>
  <q-layout view="hHh lpR fFf">
    <div id="tour">
      <div class="q-pa-md">
        <div class="q-col-gutter-md row">
          <div class="col-12 col-md-4 offset-4">
            <q-card>
              <q-card-section class="text-center" v-if="step <= maxSteps">
                <img src="@/assets/douranovo.png" alt="DouraSoft Logo" width="150" />
                <br />
                <br />
                <p>Passo {{step}}/{{maxSteps}}</p>
              </q-card-section>

              <tour-inicio v-if="step === 1" />
              <tour-caixas v-if="caixas" />
              <tour-fornecedores v-if="step === 2" />
              <tour-funcionarios v-if="step === 3" />
              <tour-responsavel v-if="responsavel" />
              <tour-contas v-if="options.is_loterica && step === 4" />
              <tour-gateways v-else-if="step === 4" @next="step += 1" />
              <tour-produtos v-if="options.is_loterica && step == 5" @next="step += 1"/>
              <tour-video-final v-if="step === (maxSteps + 1)" />

              <q-card-section class="text-right">
                <q-btn
                  style="margin-top: 10px; margin-left: 10px"
                  v-if="step > 1 && step <= maxSteps"
                  flat
                  color="primary"
                  @click="step -= 1"
                  label="Voltar"
                  class="q-ml-sm"
                />
                <q-btn
                  style="margin-top: 10px; margin-left: 10px"
                  @click="step += 1"
                  color="primary"
                  v-if="step <= maxSteps"
                  :label="step === 1 ? 'Iniciar' : 'Continuar'"
                />
                <q-btn
                  style="margin-top: 10px; margin-left: 10px"
                  @click="videoNext"
                  color="primary"
                  v-if="step === (maxSteps + 1)"
                  :label="'Finalizar'"
                />
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
    </div>
  </q-layout>
</template>

<script>
import axios from "@/axios-auth";
import { Notify } from "quasar";
export default {
  name: "tour",
  meta: {
    title: "DSB | Tour"
  },
  data() {
    return {
      inicio: true,
      caixas: false,
      fornecedores: false,
      funcionarios: false,
      responsavel: false,
      video: false,
      step: 1,
      options: {},
    };
  },
  methods: {
    videoNext() {
      axios.get("/users/tour?passo=7").then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });
          this.$router.push("/visao360");
          this.$router.go();
        }
      });
    },
    getOptions() {
      axios.get("/users/tour?passo=1").then(res => {
        if (res.data.success) {
          this.options = res.data.options;
        }
      });
    },
  },
  computed: {
    maxSteps() {
      let maxSteps = 4;

      if (this.options.is_loterica) {
        maxSteps++;
      }

      return maxSteps;
    },
  },
  mounted() {
    this.getOptions();
    this.$store.dispatch("loadManuais");
  },
};
</script>

<style lang="stylus" scoped></style>
