<template>
  <div id="modalNovaReceita">
    <q-dialog v-model="OpenDialogNovaReceita" persistent>
      <q-card :class="{
        'scroll': true,
        'nova-receita-card': true,
        'open': opcoesAvancadas,
      }" ref="novaReceitaCard">
        <q-card-section class="row items-center">
          <div class="text-h6">Nova Receita</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />

        <q-card-section>
          <div class="row q-col-gutter-md">
            <div :class="opcoesAvancadas ? 'col-6' : 'col-12'">
              <div class="row q-col-gutter-sm">
                <div class="col" id="vencimento">
                  <q-input bottom-slots :error="errors.vencimento ? true : false" v-model="movconta.vencimento"
                    label="Vencimento" @click="$refs.qDateProxy.show()">
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                          <q-date v-model="movconta.vencimento" @input="() => pulaProx('vencimento')" mask="DD/MM/YYYY"
                            :locale="langDate" minimal today-btn />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.vencimento" :key="error + i">{{ error }}</span>
                    </template>
                  </q-input>
                </div>
              </div>
              <div class="row q-col-gutter-sm" v-if="!cadastroReceita.isCadastro">
                <div class="col" id="autocomplete">
                  <autocomplete title="Nome cliente" :filter="filterFn" :carregando="carregando" :options="options"
                    :formatDisplay="formatDisplay" :create="createValue" @resultAutocomplete="resultEmitRecebe"
                    :display="displayMontar" tipoConsulta="cadastro" :errors="errors.cadastro_id"
                    :cadastro="abreCadastro" :newCadastro="cadastroReturn" ref="autocomplete"
                    :pularProx="() => pulaProx('autocomplete')" />
                </div>
              </div>
              <div class="row q-col-gutter-sm">
                <div class="col" id="descricao">
                  <q-input bottom-slots :error="errors.descricao ? true : false" v-model="movconta.descricao"
                    label="Descrição" ref="descricaoCaixa" @keydown.enter.prevent="pulaProx('descricao')">
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.descricao" :key="error + i">{{ error }}</span>
                    </template>
                  </q-input>
                </div>
              </div>
              <div class="row q-col-gutter-sm">
                <div class="col" id="referencia">
                  <q-input bottom-slots :error="errors.referencia ? true : false" v-model.lazy="movconta.referencia"
                    label="Referência" ref="referenciaReceita" @keydown.enter="pulaProx('referencia')">
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.referencia" :key="error + i">{{ error }}</span>
                    </template>
                  </q-input>
                </div>
                <div class="col" id="valor" @keydown.enter.prevent="pulaProx('valor')">
                  <q-field name="valor" v-model="movconta.valor" label="Valor" bottom-slots id="valor"
                    :error="errors.valor ? true : false">
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money :id="id" class="q-field__native text-right" :value="value" @input="emitValue"
                        v-bind="moneyFormat" v-show="floatingLabel" ref="valor">
                      </money>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.valor" :key="error + i">{{
                        error
                        }}</span>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-sm">
                <div class="col">
                  <q-select use-input v-model="valuePC" :option-label="value =>
                      value === null
                        ? 'Item Vazio'
                        : value.caminho != null
                          ? value.caminho
                          : value.titulo
                    " :option-value="value => (value === null ? null : value)" :options="planoContasFilterResult"
                    label="Plano de Conta" clearable ref="planodecontaReceita" @filter="planoContasFilter"
                    @input="() => pulaProx('planodeconta')">
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
              </div>
              <div class="row q-pt-md">
                <div class="col">
                  <q-checkbox v-model="cobrar" clickable label="Gerar Cobrança" color="green" id="debitoReceita"
                    @input="(valor) => chamaCobranca(valor)" v-if="liberaOpcaoLiquidado()" />
                </div>
              </div>
              <div class="row ">
                <div class="col" v-if="cobrar">
                  <q-select clearable v-model="movconta.gateway_id" option-label="titulo" option-value="id"
                    :options="findGateways.gateways" emit-value map-options bottom-slots
                    :error="errors.gateway_id ? true : false" label="Forma de Pagamento" ref="formaPagamento"
                    @input="selectGateway()">
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.gateway_id" :key="error + i">{{ error }}</span>
                    </template>
                  </q-select>
                </div>
                <div class="col" v-else>
                  <q-select v-model="movconta.conta_id" :options="contas" option-label="titulo" option-value="id"
                    emit-value map-options label="Conta" clearable ref="contaBancaria"
                    @input="() => pulaProx('liquidado')" v-if="$route.path != '/caixas/' + $route.params.id">
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
              </div>
              <div class="flex justify-between q-pt-md">
                <q-checkbox v-model="movconta.liquidado" clickable label="Liquidado" color="green" id="liquidado"
                  v-if="liberaOpcaoLiquidado()" @input="() => pulaProx('liquidado')" />
                <q-btn flat dense no-caps label="Opções Avançadas"
                  :icon-right="opcoesAvancadas ? 'keyboard_arrow_left' : 'keyboard_arrow_right'"
                  @click="opcoesAvancadas = !opcoesAvancadas" v-if="!($route.name.includes('caixa'))" />
              </div>
              <div class="row">
                <div class="col" v-if="movconta.liquidado">
                  <q-input bottom-slots :error="errors.liquidacao ? true : false" v-model="movconta.liquidacao"
                    ref="liquidadoEm" @click="$refs.qDateProxy2.show()">
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy2" transition-show="scale" transition-hide="scale">
                          <q-date v-model="movconta.liquidacao" @input="() => pulaProx('valorReceita')"
                            mask="DD/MM/YYYY" :locale="langDate" minimal today-btn />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.liquidacao" :key="error + i">{{ error }}</span>
                    </template>
                  </q-input>
                </div>
              </div>
              <div class="row q-col-gutter-sm" @keydown.enter.prevent="pulaProx('salvar')">
                <div class="col" v-if="movconta.liquidado" id="valorPago">
                  <q-field v-model="movconta.valor_liquidado" label="Valor Pago" bottom-slots
                    :error="errors.valor_liquidado ? true : false" id="valorLiquidado">
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money :id="id" class="q-field__native text-right" :value="value" @input="emitValue"
                        v-bind="moneyFormat" v-show="floatingLabel" ref="valorReceita">
                      </money>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.valor_liquidado" :key="error + i">{{ error }}</span>
                    </template>
                  </q-field>
                </div>
              </div>
            </div>

            <div :class="opcoesAvancadas ? 'col-6' : 'col-12'" v-show="opcoesAvancadas">
              <div class="row q-mb-sm">
                <div class="col-12 q-mb-md">
                  <q-checkbox v-model="parcelar" label="Parcelar" @input="() => pulaProx('parcelar')" color="green" />
                </div>

                <div class="col-12 q-mb-md" v-show="parcelar">
                  <div class="row q-col-gutter-lg">
                    <div class="col-12">
                      <q-checkbox v-model="dividirValorParcelas" label="Dividir valor entre parcelas"
                        @input="() => pulaProx('parcelar')" color="green" />
                      <p class="q-mb-none q-mt-md text-grey-7">
                        <span>{{ quantidadeParcelas }} parcelas de </span>
                        R$ <span>{{
                          (dividirValorParcelas && quantidadeParcelas > 0 ?
                            (movconta.valor / quantidadeParcelas)
                            : movconta.valor
                          ) | currency
                        }}
                        </span>
                      </p>
                    </div>
                    <div class="col-6">
                      <q-input class="parcelas-input" v-model="quantidadeParcelas" label="Quantidade de parcelas"
                        mask="###" ref="quantidadeParcelas" @keydown.enter.prevent="pulaProx('quantidadeParcelas')"
                        bottom-slots :error="Boolean(errors.quantidadeParcelas)">
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.quantidadeParcelas" :key="error + i">{{ error }}</span>
                        </template>
                      </q-input>
                    </div>
                    <div class="col-6">
                      <q-input class="parcelas-input" v-model="diaParcelas" label="Sempre no dia" mask="##"
                        ref="diasParcelas" @keydown.enter.prevent="pulaProx('salvar')" bottom-slots
                        :error="Boolean(errors.diaParcelas)">
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.diaParcelas" :key="error + i">{{ error }}</span>
                        </template>
                      </q-input>
                    </div>
                  </div>
                </div>

                <div class="col-12 q-mb-md" v-if="movconta.gateway_id">
                  <q-checkbox v-model="descontoPagamentoAntecipado" label="Aplicar desconto para pagamento antecipado"
                    color="green" />
                    <q-btn icon="help_outline" flat dense round>
                      <q-tooltip>Disponível para o Gateway 1</q-tooltip>
                    </q-btn>
                </div>

                <div class="row q-mb-md" v-show="descontoPagamentoAntecipado">
                  <div class="col-6">
                    <q-input bottom-slots v-model="movconta.data_limite_desconto" label="Data limite do Desconto"
                      ref="dataLimiteDesconto" @click="$refs.qDateProxy3.show()" :error="errors.data_limite_desconto ? true : false">
                      <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy ref="qDateProxy3" transition-show="scale" transition-hide="scale">
                            <q-date v-model="movconta.data_limite_desconto"
                              @input="() => pulaProx('dataLimiteDesconto')" mask="DD/MM/YYYY" :locale="langDate" minimal
                              today-btn />
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                      <template v-slot:error>
                        <span v-for="(error, i) in errors.data_limite_desconto" :key="error + i">{{ error }}</span>
                      </template>
                    </q-input>
                  </div>

                  <div class="col-6">
                    <q-field name="valor_desconto" v-model="movconta.valor_desconto" label="Valor do desconto"
                      bottom-slots id="valor_desconto" :error="errors.valor_desconto ? true : false">
                      <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <money :id="id" class="q-field__native text-right" :value="value" @input="emitValue"
                          v-bind="moneyFormat" v-show="floatingLabel" ref="valor_desconto" />
                      </template>
                      <template v-slot:error>
                        <span v-for="(error, i) in errors.valor_desconto" :key="error + i">{{
                          error
                          }}</span>
                      </template>
                    </q-field>
                  </div>
                </div>


                <div class="col-12 q-mb-md" v-if="movconta.gateway_id">
                  <q-checkbox label="Personalizar multa e juros" v-model="personalizarMultaJuros"
                    @input="() => pulaProx('personalizarMultaJuros')" color="green" />
                </div>


                <div class="col-12 q-mb-md" v-show="movconta.gateway_id && personalizarMultaJuros">
                  <div class="row q-col-gutter-lg">

                    <div class="col-4" @keydown.enter.prevent="pulaProx('multa')">
                      <q-field v-model="fatura.multa" label="Multa" bottom-slots :error="Boolean(errors.fatura_multa)">
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money :id="id" class="q-field__native text-right" :value="value" @input="emitValue"
                            v-bind="percentFormat" v-show="floatingLabel" ref="multa">
                          </money>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.fatura_multa" :key="error + i">{{ error }}</span>
                        </template>
                      </q-field>
                    </div>

                    <div class="col-4" @keydown.enter.prevent="pulaProx('juros')">
                      <q-field v-model="fatura.juros" label="Juros" bottom-slots :error="Boolean(errors.fatura_juros)">
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money :id="id" class="q-field__native text-right" :value="value" @input="emitValue"
                            v-bind="percentFormat" v-show="floatingLabel" ref="juros">
                          </money>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.fatura_juros" :key="error + i">{{ error }}</span>
                        </template>
                      </q-field>
                    </div>

                    <div class="col-4">
                      <q-input v-model="fatura.protesto" label="Protesto" mask="##" ref="protesto"
                        @keydown.enter.prevent="pulaProx('salvar')" bottom-slots
                        :error="Boolean(errors.fatura_protesto)">
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.fatura_protesto" :key="error + i">{{ error }}</span>
                        </template>
                      </q-input>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat v-if="$route.path != '/caixas/' + $route.params.id" label="Salvar & Novo" id="SalvarNovo"
            clickable @click="saveReceitaNovo" :loading="salvarNovo" ref="salvarNovo" class="bg-grey-6 text-white"
            style="margin-right: 10px; margin-bottom: 10px">
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
          <q-btn flat id="salvar" label="Salvar" clickable @click="saveReceita" :loading="loadNovoREC"
            class="bg-positive text-white" style="margin-right: 22px; margin-bottom: 10px">
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify } from "quasar";
import { mapGetters } from "vuex";
import { bus } from "@/main";
import { setTimeout } from "timers";
import { Money } from "v-money";
const moment = require("moment");
export default {
  name: "modal-nova-receita",
  components: { Money },
  data() {
    return {
      gateway: null,
      valuePC: null,
      errors: [],
      planoContasFilterResult: [],
      movconta: this.newMovconta(),
      cobrar: false,
      salvarNovo: false,
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      opcoesAvancadas: false,
      parcelar: false,
      descontoPagamentoAntecipado: false,
      quantidadeParcelas: null,
      diaParcelas: null,
      dividirValorParcelas: false,
      personalizarMultaJuros: false,
      fatura: {
        protesto: 0,
        multa: 0.00,
        juros: 0.00,
      },
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false,
      },

      // Funções do autocomplete
      carregando: false,
      options: [],
      selectItem: null,
      autocompleteResult: {},
      saving: false,
    };
  },
  methods: {
    async selectGateway() {
      await this.findGateway();
      this.recalculaDataDesconto();
    },
    recalculaDataDesconto() {
      const minimoDiasAntecipacaoDesconto = this.gateway.gateways_opts.find(opt => opt.nome === 'desconto_dias_antecipacao').valor;
      const dataAntiga = moment(this.movconta.vencimento, "DD/MM/YYYY");
      const novaData = dataAntiga.subtract(minimoDiasAntecipacaoDesconto, 'days');

      const dataAtual = moment();

      if (novaData.isBefore(dataAtual, 'day')) {
        Notify.create({
          message: "A nova data de desconto é anterior à data atual. Por favor, verifique.",
          color: "red",
          position: "top-right"
        });
      } else {
        this.movconta.data_limite_desconto = novaData.format("DD/MM/YYYY");
      }
    },
    planoContasFilter(val, update) {
      update(() => {
        this.planoContasFilterResult = this.planoContas.filter(
          plano => plano.titulo.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
    liberaOpcaoLiquidado() {
      let routesBloqueadas = ["resumo_tfl", "caixa_operador", "caixa"];

      for (let i = 0; i < routesBloqueadas.length; i++) {
        if (routesBloqueadas[i] === this.$route.name) {
          routesBloqueadas.splice(i, 1);
        }
      }
      return routesBloqueadas.length === 3 ? true : false;
    },
    pulaProx(value) {
      switch (value) {
        case "vencimento":
          this.$refs.qDateProxy.hide();
          this.$refs.autocomplete.$refs.autocompletedComponent.focus();
          break;
        case "autocomplete":
          this.$refs.descricaoCaixa.focus();
          break;
        case "autocompleteReturn":
          this.$refs.autocomplete.$refs.autocompletedComponent.focus();
          break;
        case "liquidacao":
          this.$refs.qDateProxy2.hide();
          document.getElementById("valorLiquidadoReceita").focus();
          break;
        case "descricao":
          this.$refs.referenciaReceita.focus();
          break;
        case "referencia":
          this.$refs.valor.$el.focus();
          break;
        case "valor":
          this.$refs.planodecontaReceita.focus();
          break;
        case "planodeconta":
          document.getElementById("debitoReceita").focus();
          break;
        case "debitoemconta":
          if (this.cobrar) {
            setTimeout(() => {
              this.$refs.formaPagamento.focus();
            }, 200);
          } else {
            setTimeout(() => {
              this.$refs.contaBancaria.focus();
            }, 200);
          }
          break;
        case "liquidado":
          if (this.movconta.liquidado) {
            setTimeout(() => {
              this.$refs.qDateProxy2.show();
            }, 200);
          }
          break;
        case "valorReceita":
          this.$refs.valorReceita.$el.focus();
          break;
        case "salvar":
          this.$refs.salvarNovo.click();
          break;
        case "parcelar":
          setTimeout(() => {
            this.$refs.quantidadeParcelas.focus();
          }, 10);
          break;
        case "quantidadeParcelas":
          this.$refs.diasParcelas.focus();
          break;
        case "personalizarMultaJuros":
          setTimeout(() => {
            this.$refs.multa.$el.focus();
          }, 10);
          break;
        case "multa":
          this.$refs.juros.$el.focus();
          break;
        case "juros":
          this.$refs.protesto.focus();
          break;
      }
    },
    async findGateway() {
      try {
        const res = await axios.get("/gateways?ativo=true&gateways_opts=true");
        this.gateway = res.data.gateways.find(gateway => gateway.id == this.movconta.gateway_id);
      } catch (error) {
        Notify.create({
          message: "Não foi possível trazer as formas de pagamento, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
      }
    },
    newMovconta() {
      this.cobrar = false;

      return {
        id: null,
        tipo: 2,
        vencimento: moment().format("DD/MM/YYYY"),
        liquidacao: moment().format("DD/MM/YYYY"),
        conta_id: null,
        descricao: null,
        valor: 0.0,
        valor_liquidado: 0.0,
        valor_acrescimo_desconto: 0.0,
        cadastro_id: null,
        cadastro_nome: null,
        liquidado: false,
        conciliado: false,
        gateway_id: null,
        cadastros_gateways_id: null,
        status: "Aberto",
        cobrar: false,
        data_limite_desconto: moment().format("DD/MM/YYYY"),
      };
    },
    resetModal() {
      this.movconta = this.newMovconta();
      this.errors = [];
      this.opcoesAvancadas = false;
      this.parcelar = false;
      this.quantidadeParcelas = null;
      this.diaParcelas = null;
      this.cobrar = false;
      this.valuePC = null;
      this.dividirValorParcelas = false;
      this.personalizarMultaJuros = false;
      this.fatura = {
        protesto: 0,
        multa: 0.00,
        juros: 0.00,
      };
      this.autocompleteResult = null;
      this.$root.$emit('limpar');
    },
    closeModal() {
      this.$store.commit("OpenDialogNovaReceita", false);
      this.$store.commit("cadastroReceita", {
        isCadastro: false,
        cadastro: {}
      });
      this.resetModal();
    },
    saveReceitaNovo() {
      this.salvarNovo = true;
      this.saveReceita();
    },
    async saveReceita() {
      if (!this.salvarNovo) this.$store.commit("loadNovoREC", true);
      let newMov = JSON.parse(JSON.stringify(this.movconta));
      if (!newMov.liquidado) {
        newMov.liquidacao = null;
        newMov.valor_liquidado = 0;
      } else {
        newMov.status = "Liquidado";
      }

      if (this.valuePC !== null) {
        newMov.planodeconta_id = this.valuePC.id;
      }
      if (this.movconta.conta_id !== null) {
        newMov.conta_id = this.movconta.conta_id
          ? this.movconta.conta_id
          : null;
      }

      if (this.cadastroReceita.isCadastro) {
        newMov.cadastro_id = this.cadastroReceita.cadastro.cadastro_id;
        newMov.cadastro_nome = this.cadastroReceita.cadastro.cadastro_nome;
      } else {
        if (this.autocompleteResult && this.autocompleteResult.id) {
          newMov.cadastro_id = this.autocompleteResult.id;
          newMov.cadastro_nome = this.autocompleteResult.name;
        }
        if (
          this.autocompleteResult &&
          this.autocompleteResult.id == null &&
          this.autocompleteResult.name != null
        ) {
          let cadastro = {
            nome: this.autocompleteResult.name,
            cliente: true
          };
          await axios
            .post("/cadastros/add", cadastro)
            .then(res => {
              if (res.data.success) {
                this.updateAutocompleteCadastro(res.data.data.cadastro);

                newMov.cadastro_id = res.data.data.cadastro.id;
                newMov.cadastro_nome = res.data.data.cadastro.nome;
              } else {
                this.errors = res.data.errors;
                Notify.create({
                  message: res.data.message,
                  color: "orange",
                  position: "top-right"
                });

                this.$store.commit("loadNovoREC", false);
              }
            })
            .catch(() => {
              Notify.create({
                message:
                  "Não foi possível contactar, ou você não possui permissão!",
                color: "red",
                position: "top-right"
              });
            });
        }
      }
      setTimeout(() => {
        this.receita(newMov);
      }, 500);
    },
    receita(newMov) {
      if (this.saving) {
        return;
      }
      this.saving = true;

      let url = "/financeiro/add/receita";

      if (this.parcelar) {
        url = "/financeiro/add-multiple/receita";
        newMov.action = "recorrencia";
        newMov.quantidadeParcelas = this.quantidadeParcelas;
        newMov.diaParcelas = this.diaParcelas;
        newMov.dividirValorParcelas = this.dividirValorParcelas;
      }

      if (newMov.gateway_id && this.personalizarMultaJuros) {
        newMov.personalizar_multa_juros = true;
        newMov.fatura_multa = this.fatura.multa;
        newMov.fatura_juros = this.fatura.juros;
        newMov.fatura_protesto = this.fatura.protesto;
      }

      axios
        .post(url, newMov)
        .then(res => {
          if (this.parcelar) {
            this.saving = false;
            this.$store.commit("loadNovoREC", false);

            if (!res.data.success) {
              this.salvarNovo = false;
              this.errors = res.data.errors;
              Notify.create({
                message: "Não foi possível salvar, ou não possui permissão!",
                color: "red",
                position: "top-right"
              });
              return;
            }

            Notify.create({
              message: "Lançamentos adicionados!",
              color: "green",
              position: "top-right"
            });

            if (this.$route.path === "/receitas") {
              this.$store.dispatch("resetPagingReceitas");
              this.$store.dispatch("listarReceitas");
            }

            if (!this.salvarNovo) {
              this.closeModal();
            } else {
              this.resetModal();

              setTimeout(() => {
                this.pulaProx("autocompleteReturn");
              }, 100);

              this.salvarNovo = false;
            }

            return;
          }

          if (res.data.status) {
            Notify.create({
              message: "Lançamento " + res.data.movconta.id + " adicionado!",
              color: "green",
              position: "top-right"
            });

            this.valuePC = null;

            if (!this.salvarNovo) {
              this.closeModal();
            } else {
              this.resetModal();

              setTimeout(() => {
                this.pulaProx("autocompleteReturn");
              }, 100);

              this.salvarNovo = false;
            }
            this.$store.commit("movcontaReturn", {
              cadastro: res.data.cadastro,
              movconta: res.data.movconta
            });
            if (this.$route.path === "/receitas") {
              this.$store.dispatch("resetPagingReceitas");
              this.$store.dispatch("listarReceitas");
            }
            if (this.acaoCaixa === 4) {
              let cadastro =
                this.movcontaReturn.cadastro != null
                  ? this.movcontaReturn.cadastro.nome
                  : "Sem cadastro";

              let descricaoVenda =
                "Recebido: " +
                this.movcontaReturn.movconta.id +
                ", " +
                moment
                  .utc(this.movcontaReturn.movconta.vencimento)
                  .format("DD/MM/YYYY") +
                " - " +
                this.movcontaReturn.movconta.descricao +
                " - " +
                cadastro;

              this.$store.commit("venda", {
                descricao: descricaoVenda,
                valor: this.movcontaReturn.movcontavalor
              });
            }
            this.$forceUpdate();
          } else {
            this.errors = res.data.errors;
            this.$store.commit("loadNovoREC", false);
          }
          this.$store.commit("loadNovoREC", false);
          this.$store.commit("callDespesa", false);
          this.salvarNovo = false;

          if (this.$route.path === "/cadastros/" + this.$route.params.id) {
            bus.$emit("listarReceitas");
          }

          setTimeout(() => {
            this.saving = false;
          }, 500);
        })
        .catch((err) => {
          Notify.create({
            message: "Não foi possível salvar, ou não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.$store.commit("loadNovoREC", false);
          this.salvarNovo = false;

          setTimeout(() => {
            this.saving = false;
          }, 500);
        });
    },
    // Funções do autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/cadastros/busca?term=" + val).then(res => {
          this.carregando = false;
          this.options = res.data;
        });
      });
    },
    createValue(val, done) {
      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
      this.pulaProx("autocomplete");
    },
    formatDisplay(result) {
      return result.label;
    },
    resultEmitRecebe(result) {
      this.autocompleteResult = null;
      this.$store.commit("cadastroReturn", null);

      this.autocompleteResult = result;

      if (this.autocompleteResult && this.autocompleteResult.id != null) {
        Notify.create({
          message: this.autocompleteResult.name + " foi selecionado.",
          color: "green",
          position: "top-right"
        });
        this.pulaProx("autocomplete");
      }
    },
    displayMontar(value) {
      return !value ? value : value.name;
    },
    abreCadastro(value) {
      const autocompleteId = this.autocompleteResult ? this.autocompleteResult.id : null;

      if (value.id || autocompleteId) {
        this.$store.dispatch("cadastro", {
          id: value.id || autocompleteId,
        });
        return;
      }

      this.$store.dispatch("cadastro", {
        nome: value.name,
        noSearch: true
      });
    },
    chamaCobranca(value) {
      if (value) {
        this.pulaProx('debitoemconta');
        this.$store.dispatch('findGateways');
      } else {
        this.pulaProx('debitoemconta');
      }
      this.movconta.cobrar = value;
    },
    updateAutocompleteCadastro(cadastro) {
      this.autocompleteResult = cadastro;

      const cadastroIndex = this.options.findIndex(option => option ? option.id === cadastro.id : false);
      if (cadastroIndex) {
        this.options.splice(cadastroIndex, 1);
      }

      bus.$emit("updateAutocompleteField", {
        id: cadastro.id,
        name: cadastro.nome,
        label: cadastro.nome,
        canEdit: true,
      });
    },
  },
  computed: {
    ...mapGetters([
      "acaoCaixa",
      "contas",
      "planoContas",
      "loadNovoREC",
      "findGateways",
      "movcontaReturn",
      "cadastroReturn",
      "cadastroReceita"
    ]),
    OpenDialogNovaReceita: {
      get() {
        return this.$store.getters.OpenDialogNovaReceita;
      },
      set() { }
    }
  },
  mounted() {
    bus.$on("novoCadastroAdicionado", () => {
      this.updateAutocompleteCadastro(this.cadastroReturn);
    });
  },
  watch: {
    // Atualiza o dia da parcela com o dia do vencimento
    "movconta.vencimento": {
      immediate: true,
      handler(newVencimento, oldVencimento) {
        if (!this.diaParcelas) {
          const dataArray = String(newVencimento).split('/');
          const dia = dataArray[0];

          if (dia) {
            this.diaParcelas = dia;
          }
        }

        const newDtvencimento = moment(newVencimento, "DD/MM/YYYY");
        const oldDtvencimento = moment(oldVencimento, "DD/MM/YYYY");

        if (!this.movconta.referencia || oldDtvencimento.format("MM/YYYY") === this.movconta.referencia) {
          this.$set(this.movconta, "referencia", newDtvencimento.format("MM/YYYY"));
        }
      },
    },
  },
};
</script>

<style lang="stylus" scoped>

.nova-receita-card {
  max-width: 400px;
  width: 90%;
}

.nova-receita-card.open {
  max-width: 800px;
}

</style>
