<template>
  <div id="opAvancadasReceitas">
    <h1>Oi Receitas</h1>
  </div>
</template>

<script>
export default {
  name: 'opAvancadasReceitas'
};
</script>

<style lang="scss">
</style>
