<template>
  <div id="estoque">
    <q-card>
      <q-card-section>
        <div class="text-h6">Estoque em minhas mãos</div>
      </q-card-section>

      <q-card-section>
        <table>
          <tbody>
            <tr
              v-for="produto in produtosUser"
              :key="produto.produto_id"
              >
              <td class="text-right text-bold">{{ produto.quantidade }}</td>
              <td>{{ produto.nome }} {{ tipo(produto.tpcontrole) }} {{ informacoes(produto) }}  {{produto.vencimento | formatDate}}</td>
            </tr>

            <tr v-if="produtosUser.length === 0">
              <td colspan="2">
                Nenhum produto no estoque
              </td>
            </tr>

          </tbody>
        </table>
      </q-card-section>

      <q-card-actions></q-card-actions>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "estoque",
  props: ["buscando", "user"],
  methods: {
    tipo(tipo) {
      if (tipo === "Nenhum") {
        return null;
      }

      return tipo;
    },
    informacoes(produto) {
      switch(produto.tpcontrole) {
        case "Extração":
          return produto.extracao;
        case "Concurso":
          return produto.concurso;
        case "Evento":
          return produto.evento;
        default:
          return null;
      }
    },
  },
  computed: {
    ...mapGetters(["produtosUser"]),
  }
};
</script>

<style lang="stylus" scoped></style>
