import axios from "@/axios-auth";
import globalAxios from "axios";
import store from '@/store/store';
import router from "@/router/router";
import login from "./login";
import moment from 'moment';
import Vue from 'vue';

const state = {
  dataUser: {},
  firstLoadDataUser: false,
  atalhosUser: [],
  buscandoUser: false,
  videosManuais: null,
  usersApp: [],
  dataEmpresa: {},
  produtosUser: [],
  sessoesAtivas: [],
  cobrancaVencida: null,
  viuAtualizacao: null,
  viuCobranca: null,
  movcontasOptions: {},
  acessibilidade: JSON.parse(localStorage.getItem('acessibilidade')) || {
    aumentarTamanhoFonteTabelas: false,
  },
  avisoCobrancaTexto: '',
  avisoCobrancaClasses: ''
};

const mutations = {
  dataUser(state, userData) {
    state.dataUser = userData;
  },
  setFirstLoadDataUser(state, userData) {
    state.firstLoadDataUser = userData;
  },
  atalhosUser: (state, userData) => {
    state.atalhosUser = userData;
  },
  buscandoUser: (state, userData) => {
    state.buscandoUser = userData;
  },
  videosManuais: (state, userData) => {
    state.videosManuais = userData;
  },
  usersApp: (state, userData) => {
    state.usersApp = userData;
  },
  dataEmpresa(state, userData) {
    state.dataEmpresa = userData;
  },
  produtosUser: (state, userData) => {
    state.produtosUser = userData;
  },
  sessoesAtivas: (state, userData) => {
    state.sessoesAtivas = userData;
  },
  setViuAtualizacao: (state, userData) => {
    state.viuAtualizacao = userData;
  },
  setViuCobranca: (state, userData) => {
    state.viuCobranca = userData;
  },
  setCobrancaVencida: (state, userData) => {
    state.cobrancaVencida = userData;
  },
  setMovcontasOptions: (state, userData) => {
    state.movcontasOptions = userData;
  },
  setAcessibilidade: (state, userData) => {
    state.acessibilidade = userData;
  },
  avisoCobrancaTexto: (state, userData) => {
    state.avisoCobrancaTexto = userData;
  },
  avisoCobrancaClasses: (state, userData) => {
    state.avisoCobrancaClasses = userData;
  }
};

const actions = {
  dataUser({ commit }, userData) {
    commit("dataUser", userData);
  },
  aposLogin({ commit }, userData) {
    commit("aposLogin", userData);
  },
  listarUser: ({ commit, dispatch }) => {
    if (state.buscandoUser) {
      return;
    }
    commit("buscandoUser", true);

    axios
      .get("/users/perfil?apos_login=" + store.getters.aposLogin)
      .then(res => {
        if (res.data.success) {
          localStorage.setItem("menuFixed", String(res.data.menuFixed));
          localStorage.setItem("sessionAtual", res.data.sessaoAtual);
          //commit("dataUser", res.data.user); //ja preenchido no token
          commit("atalhosUser", res.data.meusLinks);
          commit("videosManuais", res.data.videosManuais);
          commit("produtosUser", res.data.estoques);
          commit("sessoesAtivas", res.data.sessoesAtivas);
          commit("setViuAtualizacao", res.data.viuAtualizacao);
          commit("setViuCobranca", res.data.viuCobranca);
          commit("setMovcontasOptions", res.data.movcontasOptions);
          commit("buscandoUser", false);
          commit("aposLogin", false);
          dispatch("loadManuais");

          if (res.data.cobrancaVencida.success) {
            commit("setCobrancaVencida", res.data.cobrancaVencida);
            dispatch("avisoCobranca", res.data.cobrancaVencida);
          }
        }
      })
      .catch(() => {
        commit("buscandoUser", false);
      });
  },
  usersApp: ({ commit }) => {
    axios.get("/users").then(res => {
      if (res.data.success) {
        commit("usersApp", res.data.data.cadastros);
      }
    });
  },
  alwaysOn: ({ dispatch, commit }, userData) => {

    let empresa = store.getters.dataEmpresa.id === undefined
      ? localStorage['empresa_atual'] : store.getters.dataEmpresa.id;

    if (empresa === undefined) {
      return;
    }

    let token = login.state.token
      ? login.state.token
      : localStorage["token" + empresa];

    globalAxios
      .post(
        "/users/token",
        {},
        {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          }
        }
      )
      .then(res => {
        if (res.data.success) {

          if (localStorage.getItem('auth-qrcode')) {
            //Abrir modal
            commit('OpenDialogUploadResumoCaixas', true);
            localStorage.removeItem("auth-qrcode");
          }

          let empresa_id = res.data.data.empresa.id;
          let empresa = `empresa${empresa_id}`;
          let tokenName = `token${empresa_id}`;

          localStorage.setItem('empresa_atual', res.data.data.empresa.id);
          localStorage.setItem(empresa, empresa_id);
          localStorage.setItem(`menu${empresa_id}`, JSON.stringify(res.data.data.menu));
          localStorage.setItem(tokenName, res.data.data.token);
          localStorage.setItem('empresa_plano', res.data.data.empresa.assinatura.plano);
          // localStorage.setItem('validade', res.data.data.validade)
          commit("token", res.data.data.token);
          commit("dataUser", res.data.data.user);
          commit("dataEmpresa", res.data.data.empresa);

          dispatch("listarUser");
          if (res.data.data.isTour) {
            router.push("/tour");
          } else if (res.data.data.isConfig) {
            router.push("/configuracao");
          }
        } else {
          dispatch("logout");
          router.push("/login");
        }
      });
  },
  loadAcessibilidade:  ({ state }) => {
    if (state.acessibilidade.aumentarTamanhoFonteTabelas) {
      document.querySelector('body').classList.add('aumentar_tamanho_fonte_tabelas');
    } else {
      document.querySelector('body').classList.remove('aumentar_tamanho_fonte_tabelas');
    }
  },
  avisoCobranca: ({ commit }, userData) => {
    const cobrancaVencimento = moment(userData.cobranca.vencimento, "DD/MM/YYYY");
    const currency = Vue.filter('currency');
    const cobrancaValor = currency(userData.cobranca.valor);
    let texto = "";
    let classes = "";

    if (cobrancaVencimento.isBefore(moment().subtract(3, "days"))) {
      // se a cobrança venceu a mais de 3 dias
      texto = `Sua fatura no valor de R$${cobrancaValor} consta como vencida desde ${cobrancaVencimento.format("DD/MM/YYYY")}. Clique para imprimir...`;
      classes = "bg-red toolbar-text-white";
    } else if (cobrancaVencimento.isBefore(moment().subtract(1, "days"))) {
      // se a cobranca venceu a mais de 1 dia
      texto = `Sua fatura no valor de R$${cobrancaValor} com vencimento em ${cobrancaVencimento.format("DD/MM/YYYY")} está disponível...`;
      classes = "bg-amber-4 toolbar-text-black";
    } else {
      // se a cobranca vencerá em menos de 1 dia
      texto = `Sua fatura no valor de R$${cobrancaValor} com vencimento em ${cobrancaVencimento.format("DD/MM/YYYY")} já está disponível...`;
      classes = "bg-white toolbar-text-blue";
    }

    commit("avisoCobrancaTexto", texto);
    commit("avisoCobrancaClasses", classes);
  }
};

const getters = {
  dataUser(state) {
    return state.dataUser;
  },
  firstLoadDataUser(state) {
    return state.firstLoadDataUser;
  },
  atalhosUser: state => {
    return state.atalhosUser;
  },
  buscandoUser: state => {
    return state.buscandoUser;
  },
  videosManuais: state => {
    return state.videosManuais;
  },
  usersApp: state => {
    return state.usersApp;
  },
  dataEmpresa(state) {
    return state.dataEmpresa;
  },
  produtosUser: state => {
    return state.produtosUser;
  },
  sessoesAtivas: state => {
    return state.sessoesAtivas;
  },
  cobrancaVencida: state => {
    return state.cobrancaVencida;
  },
  viuAtualizacao: state => {
    return state.viuAtualizacao;
  },
  viuCobranca: state => {
    return state.viuCobranca;
  },
  movcontasOptions: state => {
    return state.movcontasOptions;
  },
  acessibilidade: state => {
    return state.acessibilidade;
  },
  avisoCobrancaTexto: state => {
    return state.avisoCobrancaTexto;
  },
  avisoCobrancaClasses: state => {
    return state.avisoCobrancaClasses;
  },
  extratoClienteInstalado: state => {
    const aplicativos = state.dataEmpresa ? state.dataEmpresa.aplicativos : null;

    if (!aplicativos) {
      return false;
    }

    let extratoClienteInstalado = false;

    aplicativos.forEach(aplicativo => {
      if (aplicativo.id === 12) {
        extratoClienteInstalado = true;
      }
    });

    return extratoClienteInstalado;
  },
  hasWhatsApp: state => {
    const aplicativos = state.dataEmpresa ? state.dataEmpresa.aplicativos : null;

    if (!aplicativos) {
      return false;
    }

    let hasWhatsApp = false;

    aplicativos.forEach(aplicativo => {
      if (aplicativo.label === "WhatsApp") {
        hasWhatsApp = true;
      }
    });

    return hasWhatsApp;
  },
  notasFiscaisInstalado: state => {
    const aplicativos = state.dataEmpresa ? state.dataEmpresa.aplicativos : null;

    if (!aplicativos) {
      return false;
    }

    let notasFiscaisInstalado = false;

    aplicativos.forEach(aplicativo => {
      if (aplicativo.id === 10) {
        notasFiscaisInstalado = true;
      }
    });

    return notasFiscaisInstalado;
  },
  controleMoedasInstalado: state => {
    const aplicativos = state.dataEmpresa ? state.dataEmpresa.aplicativos : null;

    if (!aplicativos) {
      return false;
    }

    let notasFiscaisInstalado = false;

    aplicativos.forEach(aplicativo => {
      if (aplicativo.id === 13) {
        notasFiscaisInstalado = true;
      }
    });

    return notasFiscaisInstalado;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
