<template>
  <div id="detalhes-component">
    <div>
      <q-list>
        <q-item
          v-ripple
          v-for="(key, val) in campos"
          :key="key.texto + val"
          clickable
          class="QItemDetalhes"
        >
          <q-item-section>
            <q-item-label caption>{{ key.nome }}</q-item-label>
            <a v-if="islink(key.texto)" :href="key.texto" target="_blank"> 
              <q-item-label  style="word-break: break-all;">{{ key.texto }}</q-item-label>
            </a>
            <q-item-label v-else style="word-break: break-all;">{{ key.texto }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-btn
              dense
              flat
              no-wrap
              v-ripple
              round
              size="11px"
              icon="edit"
              no-caps
              class="text-white bg-primary"
              clickable
              @click="editar(key)"
            ></q-btn>
          </q-item-section>
          <q-item-section side>
            <q-btn
              dense
              flat
              no-wrap
              v-ripple
              round
              size="11px"
              icon="delete_forever"
              no-caps
              class="text-white bg-negative"
              clickable
              @click="excluir(key)"
            ></q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <q-dialog v-model="OpenDialogNovoCampo" persistent>
      <q-card style="width: 400px; max-width: 90vw;">
        <q-card-section class="row items-center">
          <div
            class="text-h6"
            v-text="isNew ? 'Novo Campo' : 'Editar Campo'"
          ></div>
          <q-space />
          <q-btn
            icon="close"
            clickable
            flat
            round
            dense
            v-close-popup
            @click="resetData"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-gutter-md">
              <div>
                <q-select
                  v-if="isNew"
                  v-model="novoCampo.nome"
                  :options="tipos"
                  label="Campo Adicional"
                  clearable
                  @input="verifyinputOutros"
                  bottom-slots
                  :error="errors && errors.nome ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.nome" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-select>
              </div>
              <div>
                <q-input
                  v-if="campoOutros"
                  required
                  v-model="campoOutroNome"
                  label="Nome Campo"
                  bottom-slots
                  :error="errors && errors.nome ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.nome" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-input>
              </div>
              <div>
                <q-input
                  required
                  v-model="novoCampo.texto"
                  :label="isNew ? 'Texto' : novoCampo.nome"
                  bottom-slots
                  :error="errors && errors.texto ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.texto" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-input>
              </div>
              <br />
              <div>
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 15px 0"
                  clickable
                  @click="salvar"
                  :loading="loadNovocampo"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Dialog, Loading, Notify } from "quasar";
import { mapGetters } from "vuex";
export default {
  name: "detalhes-component",
  data() {
    return {
      isNew: true,
      campoOutros: false,
      campoOutroNome: null,
      campos: [],
      errors: [],
      tipos: [],
      //   incluindo: false,
      novoCampo: { nome: "", texto: "" },
      camposSuccess: false,
      expandButton: "expand_less",
      hide: false,
      loading: false,
      loadNovocampo: false
    };
  },
  methods: {
    listar() {
      this.$store.commit("buscandoDetalhes", true);
      axios
        .get("/cadastros/campos-extras/" + this.$route.params.id)
        .then(res => {
          if (res.data.success) {
            this.campos = res.data.data.camposextras;
            for (let i in this.campos) {
              this.campos[i].hover = false;
            }
            this.tipos = res.data.data.tipos;
            this.camposSuccess = res.data.success;
            this.$store.commit("buscandoDetalhes", false);
          }
        });
    },
    excluir(campo) {
      Dialog.create({
        title: campo.nome,
        message: "Tem certeza que deseja remover o Campo?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          this.campos.splice(this.campos.indexOf(campo), 1);
          axios
            .post("/cadastros/delete-campo-extra/" + campo.id, campo)
            .then(() => {
              Dialog.create({
                title: "Campo excluído!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              this.listar();
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
              this.listar();
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    editar(campo) {
      axios
        .get("/cadastros/edit-campo-extra/" + campo.id)
        .then(res => {
          this.isNew = false;
          this.novoCampo = res.data.campoExtra;
          this.$store.commit("OpenDialogNovoCampo", true);
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    cancel() {
      this.novoCampo = { nome: "", texto: "" };
      this.hide = true;
      this.escondeDet();
    },
    salvar() {
      this.loadNovocampo = true;
      if (!this.isNew) {
        let novo = {
          texto: this.novoCampo.texto
        };
        axios
          .post("/cadastros/edit-campo-extra/" + this.novoCampo.id, novo)
          .then(res => {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            this.loadNovocampo = false;
          })
          .catch(() => {
            Notify.create({
              message:
                "Não foi possível contactar, ou você não possui permissão!",
              color: "red",
              position: "top-right"
            });
          })
          .finally(() => {
            this.listar();
          });
        this.resetData();
      } else {
        let novo = {
          nome: !this.campoOutros ? this.novoCampo.nome : this.campoOutroNome,
          texto: this.novoCampo.texto
        };

        axios
          .post("/cadastros/add-campo-extra/" + this.$route.params.id, novo)
          .then(res => {
            if (res.data.success) {
              Notify.create({
                message: "Novo Campo Salvo!",
                color: "green",
                position: "top-right"
              });

              this.resetData();
              this.listar();
            } else {
              this.errors = res.data.errors.campoextra;
            }
            this.loadNovocampo = false;
          })
          .catch(() => {
            Notify.create({
              message:
                "Não foi possível contactar, ou você não possui permissão!",
              color: "red",
              position: "top-right"
            });
          });
      }
    },
    resetData() {
      this.isNew = true;
      this.campoOutros = false;
      this.campoOutroNome = null;
      this.novoCampo = { nome: "", texto: "" };
      this.$store.commit("OpenDialogNovoCampo", false);
      this.errors = null;
    },
    verifyinputOutros() {
      if (this.novoCampo.nome === "Outros") {
        this.campoOutros = true;
      } else {
        this.campoOutros = false;
      }
    },
    islink(text){

      let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      let link = text.match(new RegExp(urlRegex));
    
      if (!link){
        return false;
      }

      return true;
    }
  },
  computed: {
    ...mapGetters(["buscandoDetalhes"]),
    OpenDialogNovoCampo: {
      get() {
        return this.$store.getters.OpenDialogNovoCampo;
      },
      set() {}
    }
  },
  mounted() {
    this.listar();
  }
};
</script>

<style lang="stylus" scoped></style>
