<template>
  <div id="modalAlterarReferencia">
    <q-dialog v-model="OpenDialogAlterarReferencia" persistent>
      <q-card style="width: 400px">
        <q-card-section class="row items-center">
          <div class="text-h6">Alterar Data de Referência</div>
          <q-space/>
          <q-btn v-close-popup dense flat icon="close" round @click="closeModal"/>
        </q-card-section>
        <q-separator/>
        <q-card-section>
          <div class="q-pa-sm">
            <q-input
              v-model="referencia"
              bottom-slots
              label="Nova data de Referência"
              maxlength="7"
              @click="$refs.qDateProxy.show()"
            >
              <template v-slot:append>
                <q-icon class="cursor-pointer" name="event">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-hide="scale"
                    transition-show="scale"
                  >
                    <q-date
                      v-model="referencia"
                      :locale="langDate"
                      default-view="Years"
                      emit-immediately
                      mask="MM/YYYY"
                      minimal
                      @input="checkValue"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="q-pa-md q-mb-md">
            <div>
              <div class="text-h6">Confirma a alteração na referência em massa das
                {{ isDespesa ? 'despesas' : 'receitas' }}?
              </div>
              <span>Digite <strong style="color: #EA4335;">"Compreendo os riscos"</strong> para prosseguir.</span>
            </div>
            <div>
              <q-input
                v-model="promptResult"
                autofocus
                dense
                square
              />
            </div>
          </div>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-btn
                  v-ripple
                  :disable="promptResult !== confirmText"
                  :loading="loading"
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  clickable
                  label="Salvar"
                  no-caps
                  no-wrap
                  style="margin: 0 30px 20px 0"
                  @click="alterarReferencia"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em"/>
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "@/axios-auth";
import {Dialog, Loading, Notify} from "quasar";

const moment = require("moment");

export default {
  name: "modal-alterar-referencia",
  data() {
    return {
      referencia: moment().format("MM/YYYY"),
      loading: false,
      confirmText: "Compreendo os riscos",
      promptResult: "",
      isDespesa: this.$route.path !== "/receitas"
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogAlterarReferencia", false);
    },
    alterarReferencia() {
      let url = "";
      let dados = {
        confirmar: 0,
        referencia: this.referencia,
        action: "alterar-referencia",
      };

      if (this.$route.path === "/receitas") {
        url = "/financeiro/edit-multiple/receita";
        dados = {
          ...dados,
          ids: this.selectediesReceitas,
          all: this.allReceitas ? 1 : 0,
          dtini: this.filtrosReceitas.dtini,
          dtfim: this.filtrosReceitas.dtfim,
          status: this.filtrosReceitas.status,
          status_remessa: this.filtrosReceitas.status_remessa,
        };
      }

      if (this.$route.path === "/despesas") {
        url = "/financeiro/edit-multiple/despesa";
        dados = {
          ...dados,
          ids: this.selectediesDespesas,
          all: this.allDespesas ? 1 : 0,
          dtini: this.filtrosDespesas.dtini,
          dtfim: this.filtrosDespesas.dtfim,
          status: this.filtrosDespesas.status
        };
      }

      this.loading = true;

      axios.post(url, dados)
        .then(res => {
          if (res.data.success) {
            Dialog.create({
              title: res.data.message,
              cancel: "Cancelar",
              persistent: true
            })
              .onOk(() => {
                Loading.show();
              })
              .onOk(() => {
                dados.confirmar = 1;
                axios
                  .post(url, dados)
                  .then(res => {
                    if (res.data.success) {
                      this.showResultMessage(res.data.message, true);

                      if (this.$route.name === 'despesas') {
                        this.$store.dispatch("resetPagingDespesas");
                        this.$store.dispatch("listarDespesas");
                      } else {
                        this.$store.dispatch("resetPagingReceitas");
                        this.$store.dispatch("listarReceitas");
                      }

                      // limpar checkbox selecionados
                      this.$store.dispatch("clearCheckbox");

                      this.closeModal();
                    } else {
                      this.showResultMessage(res.data.message, false);
                    }
                  })
                  .catch(() => this.showErrorMessage());
              })
              .onCancel(() => {
                Loading.hide();
              })
              .onDismiss(() => {
                Loading.hide();
              });

          } else {
            this.showResultMessage(res.data.message, false);
          }
        })
        .catch(() => this.showErrorMessage());

      this.loading = false;
      this.promptResult = "";
      this.referencia = moment().format("MM/YYYY");
    },
    showResultMessage(message, success) {
      Dialog.create({
        title: message,
        class: success ? "bg-positive text-white" : "bg-warning text-white",
        ok: {
          "text-color": success ? "green" : "orange",
          color: "white"
        }
      });
    },
    showErrorMessage() {
      Notify.create({
        message: "Não foi possível contactar, ou você não possui permissão!",
        color: "red",
        position: "top-right"
      });
    },
    checkValue(val, reason) {
      if (reason === 'month') {
        this.$refs.qDateProxy.hide();
      }
    }
  },
  computed: {
    ...mapGetters([
      "OpenDialogAlterarReferencia",
      "selectediesReceitas",
      "allReceitas",
      "filtrosReceitas",
      "selectediesDespesas",
      "allDespesas",
      "filtrosDespesas"
    ])
  }
};
</script>
