<template>
  <div id="modalCaixaVenda">
    <q-dialog v-model="dialogQrcodeVenda" persistent>
      <q-card style="width: 400px !important">

        <q-card-section class="row items-center">
          <div class="text-h6">Aguardando Pagamento</div>
          <q-space />
          <q-btn
            flat
            round
            dense
            v-close-popup
            icon="close"
            @click="closeModal()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section v-if="qrcode && !pago" class="text-center">
          <qrcode-vue
            v-if="!pago"
            level="L"
            size="250"
            :value="qrcode.digitableline"
          />
        </q-card-section>

        <q-card-section v-if="pago" class="text-center">
          <q-icon
            size="5em"
            color="positive"
            name="check_circle_outline"
          />
          <div class="q-pt-md">{{ descricao }}</div>
        </q-card-section>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import axios from '@/axios-auth';
import { bus } from '@/main';

export default {
  name: 'modal-caixa-venda-qrcode',
  components: { QrcodeVue },
  data() {
    return {
      dialogQrcodeVenda: false,
      movconta: null,
      qrcode: null,
      pago: false,
      descricao: null,
    };
  },
  methods: {
    closeModal() {
      this.movconta = null;
      this.qrcode = null;
      this.dialogQrcodeVenda = false;
      this.pago = false;
    },
    verificarStatusMovconta() {
      if (!this.dialogQrcodeVenda || !this.qrcode) return;

      axios
        .get(`/financeiro/status-safe2pay/${this.qrcode.idtransaction}`)
        .then(res => {
          const { s2ppagamentos } = res.data;

          this.pago = s2ppagamentos.response_status === 3;
          this.descricao = s2ppagamentos.response_description;

          if (this.pago) {
            setTimeout(() => this.closeModal(), 5 * 1000);
            return;
          }

          setTimeout(() => this.verificarStatusMovconta(), 3 * 1000);
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao verificar o status da venda',
            color: 'red',
            position: 'top-right',
          });
        });
    },
  },
  beforeMount() {
    this.closeModal();
    bus.$off('openModalQrcodeVenda');
  },
  mounted() {
    bus.$on('openModalQrcodeVenda', data => {
      this.movconta = data.movconta;
      this.qrcode = data.qrcode;
      this.pago = false;
      this.dialogQrcodeVenda = true;

      this.verificarStatusMovconta();
    });
  },
};
</script>

<style scoped></style>
