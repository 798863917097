import axios from "@/axios-auth";
import { Notify } from "quasar";

const state = {
  isNewCentroCusto: true,
  idCentroCusto: null,
  openDialogNovoCentroCusto: false,
  salvandoCentroCusto: false,
  listandoCentroCustos: false,
  centroCustos: [],
  centroCustoData: {
    nome: "",
  },
};

const mutations = {
  centroCustos: (state, userData) => {
    state.centroCustos = userData.centroCustos;
  },
  centroCustoData: (state, userData) => {
    state.centroCustoData = userData;
  },
  errorsCentroCusto: (state, userData) => {
    state.errorsCentroCusto = userData;
  },
  salvandoCentroCusto: (state, userData) => {
    state.salvandoCentroCusto = userData;
  },
  isNewCentroCusto: (state, userData) => {
    state.isNewCentroCusto = userData;
  },
  idCentroCusto: (state, userData) => {
    state.idCentroCusto = userData.id;
  },
  listandoCentroCustos: (state, userData) => {
    state.listandoCentroCustos = userData;
  },
  openDialogNovoCentroCusto: (state, userData) => {
    state.openDialogNovoCentroCusto = userData;
  }
};

const actions = {
  resetpagingCentroCustos: ({ commit }) => {
    commit("centroCustos", {
      centroCustos: [],
    });
  },
  listarCentroCustos: ({ commit }, userData) => {
    if (state.listandoCentroCustos) return;
    if (localStorage.getItem('empresa_plano') !== "Premium"){
      return;
    }
    commit("listandoCentroCustos", true);

    axios
      .get("/centrocustos")
      .then(res => {
        if (res.data.success) {
          commit("centroCustos", {
            centroCustos: res.data.centroCustos,
          });
        }
        commit("listandoCentroCustos", false);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("listandoCentroCustos", false);
      });
  },
  salvarCentroCusto: ({ commit, dispatch }, userData) => {
    commit("salvandoCentroCusto", true);
    axios
      .post("/centrocustos/add", userData)
      .then(res => {
        commit("salvandoCentroCusto", false);

        if (!res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
          commit("errorsCentroCusto", res.data.errors);

          return;
        }

        Notify.create({
          message: res.data.message,
          color: "green",
          position: "top-right"
        });
        dispatch("resetpagingCentroCustos");
        dispatch("listarCentroCustos");
        commit("centroCustoData", {
          nome: "",
        });
        commit("errorsCentroCusto", []);
        commit("openDialogNovoCentroCusto", false);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("salvandoCentroCusto", false);
      });
  },
  editarCentroCustoGet: ({ commit }, userData) => {
    commit("isNewCentroCusto", false);
    commit("idCentroCusto", {
      id: userData.id
    });
    commit("errorsCentroCusto", []);
    axios
      .get("/centrocustos/edit/" + userData.id)
      .then(res => {
        if (res.data.success) {
          commit("centroCustoData", {
            nome: res.data.centroCusto.nome,
          });
          commit("openDialogNovoCentroCusto", true);
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  editarCentroCustoPost: ({ commit, dispatch }, userData) => {
    commit("salvandoCentroCusto", true);
    axios
      .post("/centrocustos/edit/" + state.idCentroCusto, userData)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          dispatch("resetpagingCentroCustos");
          dispatch("listarCentroCustos");
          dispatch("clearCentroCusto");

          commit("errorsCentroCusto", []);
          commit("openDialogNovoCentroCusto", false);
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
        commit("salvandoCentroCusto", false);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("salvandoCentroCusto", false);
      });
  },
  clearCentroCusto: ({ commit }) => {
    commit("isNewCentroCusto", true);
    commit("centroCustoData", {
      nome: "",
    });
    commit("errorsCentroCusto", []);
  }
};

const getters = {
  centroCustoData: state => {
    return state.centroCustoData;
  },
  errorsCentroCusto: state => {
    return state.errorsCentroCusto;
  },
  salvandoCentroCusto: state => {
    return state.salvandoCentroCusto;
  },
  centroCustos: state => {
    return state.centroCustos;
  },
  pagingCentroCustos: state => {
    return state.pagingCentroCustos;
  },
  totalCentroCustos: state => {
    return state.totalCentroCustos;
  },
  isNewCentroCusto: state => {
    return state.isNewCentroCusto;
  },
  listandoCentroCustos: state => {
    return state.listandoCentroCustos;
  },
  openDialogNovoCentroCusto: state => {
    return state.openDialogNovoCentroCusto;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
