<template>
  <div id="modalAgendarMensagem">
    <q-dialog v-model="OpenDialogAgendarMensagem" persistent>
      <q-card v-if="!hasWhatsApp" style="width: 600px">
        <q-card-section class="row bg-upgrade items-center">
          <div class="text-h6">Evolua com a Gente!</div>
          <q-space/>
          <q-btn
            style="font-size: 18px;"
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal"
          />
        </q-card-section>
        <q-separator/>

        <q-card-section class="bg-upgrade-text">
          <h6 class="text-center">Seu Plano atual não possui este recurso, <br>
          <a href="https://dourasoft.com.br/" target="_blanck" style="color: white;">Fale com Nosso Time</a>, e realize um Upgrade.</h6>
        </q-card-section>
      </q-card>

      <q-card v-else style="width: 600px;">
        <q-card-section class="row items-center">
          <div class="text-h6">Nova Mensagem</div>
          <q-space />
          <q-btn
            icon="close"
            clickable
            flat
            round
            dense
            v-close-popup
            @click="closeModal"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="q-pa-md q-gutter-sm">
            <div v-if="!isReceitasPage">
              <q-banner rounded style="background-color: #31ccec;">
                <q-icon name="info" size="2em" />
                Essa notificação possui apenas os dados do cadastro.
              </q-banner>
            </div>

            <div v-if="!isReceitasPage && !isCadastrosPage" >
              <autocomplete
                title="Selecionar Cadastro"
                :filter="filterFn"
                :carregando="carregando"
                :options="options"
                :formatDisplay="formatDisplay"
                :create="createValue"
                @resultAutocomplete="resultEmitRecebe"
                :display="displayMontar"
                tipoConsulta="cadastro"
                :cadastro="abreCadastro"
                :error="Boolean(errors.ids)"
              />
            </div>

            <div class="q-ml-none q-mt-md">
              <q-select
                clearable
                style="width: 100%"
                v-model="template"
                :options="templates"
                :error="Boolean(errors.template_id)"
                emit-value
                option-label="subject"
                option-value="id"
                map-options
                label="Template de WhatsApp"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Nenhum template encontrado
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span
                      v-for="(error, i) in errors.template_id"
                      :key="error + i"
                      >{{ error }}
                  </span>
                </template>
              </q-select>

              <div class="q-mt-md">
                <q-input
                  label="Data de expiração"
                  v-model="expira"
                  bottom-slots
                  :error="Boolean(errors.expira)"
                  @click="$refs.expiraDate.show()"
                  >
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="expiraDate"
                        transition-show="scale"
                        transition-hide="scale"
                        >
                        <q-date
                          v-model="expira"
                          mask="DD/MM/YYYY HH:mm"
                          :locale="langDate"
                          minimal
                          today-btn
                          @change="closeExpiraDialog"
                          @click="closeExpiraDialog"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                  <span
                      v-for="(error, i) in errors.expira"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                  <template v-slot:append>
                    <q-icon name="access_time" class="cursor-pointer">
                      <q-popup-proxy
                        ref="expiraTime"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-time
                          v-model="expira"
                          mask="DD/MM/YYYY HH:mm"
                          format24h
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:hint>
                    Data limite para envio da mensagem
                  </template>
                </q-input>
              </div>

              <div class="q-mt-md">
                <q-select
                  v-model="variavel"
                  :options="variaveis"
                  label="Variáveis"
                  bottom-slots
                />
              </div>
              <q-input
                v-model="whatsappBody"
                outlined
                autogrow
                label="Mensagem"
              />
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            @click.exact="enviarWhatsApp"
            label="Enviar"
            class="bg-positive"
            color="primary"
            v-close-popup
            :loading="salvando"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify } from "quasar";
import axios from "@/axios-auth";

export default {
  name: "modal-agendar-mensagem",
  props: ["isReceitasPage", "isCadastrosPage", 'isWhatsPage'],
  data() {
    return {
      whatsappBody: "",
      whatsappBodyTemp: "",
      template: null,
      templates: [],
      expira: null,
      cadastro: null,
      variaveis: [
        {
          label: "Campo nome no cadastro",
          value: "{CadastroNome}",
        },
        {
          label: "Campo email no cadastro",
          value: "{CadastroEmail}",
        },
        {
          label: "Cadastro CPF ou CNPJ",
          value: "{CadastroCpfCnpj}",
        },
        {
          label: "Boleto Descrição",
          value: "{FaturaDescricao}",
        },
        {
          label: "Boleto Link",
          value: "{FaturaLink}",
        },
        {
          label: "Boleto Vencimento",
          value: "{FaturaVencimento}",
        },
        {
          label: "Boleto Valor",
          value: "{FaturaValor}",
        },
        {
          label: "Boleto Valor Liquidado",
          value: "{FaturaValorLiquidado}",
        },
        {
          label: "Agenda Título",
          value: "{EventoAgenda}",
        },
        {
          label: "Agenda Horário",
          value: "{EventoHorario}",
        },
        {
          label: "Agenda Descrição",
          value: "{EventoDescricao}",
        },
        {
          label: "Link da Nota Fiscal",
          value: "{NotaFiscalLink}",
        },
        {
          label: "Número da Nota Fiscal",
          value: "{FaturaValor}",
        },
        {
          label: "Código de Verificação da Fatura",
          value: "{NotaFiscalCodigoVerificacao}",
        },
      ],
      variavel: null,
      salvando: false,
      // autocomplete
      carregando: false,
      options: [],
    };
  },
  methods: {
    limparCampos() {
      this.whatsappBody = "";
      this.whatsappBodyTemp = "";
      this.template = null;
      this.expira = null;
      this.variavel = null;
    },
    buscaTemplates() {
      let params = {
        tipo: "whatsapp",
      };
      axios
        .get("/templates", {
          params: params
        })
        .then(res => {
          if (res.data.success) {
            this.templates = res.data.data.templates;
          }
        })
        .catch(err => {
          Notify.create({
            message: "Houve um erro ao buscar os templates",
            color: "red",
            position: "top-right"
          });
        });
    },
    buscaTemplate(template) {
      axios.get("/templates/edit/" + template).then(res => {
        if (res.data.success) {
          this.tipoWhatsapp === 'dourasoft' ? (this.whatsappBody = "", this.whatsappBodyTemp = res.data.template.body) : this.whatsappBody = res.data.template.body;
        }
      });
    },
    closeModal() {
      this.$store.commit("OpenDialogAgendarMensagem", false);
      this.$store.commit("errorsWhatsApp", { errorsWhatsApp: {} });
    },
    enviarWhatsApp() {
      let tipo = this.isReceitasPage ? "movconta" : "cadastro";

      var dados = {
        template: this.template,
        mensagem: this.whatsappBody,
        expira: this.expira,
        tipo: tipo
      };

      if(this.tipoWhatsapp === 'dourasoft') dados.whatsappBodyTemp = this.whatsappBodyTemp;

      if (tipo === "cadastro") {
        if (this.cadastroView.id && !this.isWhatsPage)  {
          dados.ids = [this.cadastroView.id];
        } else if (this.cadastro) {
          dados.ids = [this.cadastro];
        }

        this.$store.dispatch("realizarEnvioWhatsCadastro", dados);
      } else {
        this.$store.dispatch("realizarEnvioWhatsReceita", dados);
      }
    },
    closeExpiraDialog() {
      this.$refs.expiraDate.hide();
    },
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/cadastros?ativo=1&cliente=1&busca=" + val).then(res => {
          this.carregando = false;
          this.options = res.data.data.cadastros;
        });
      });
    },
    formatDisplay(result) {
      return result.nome;
    },
    createValue(val, done) {
      Notify.create({
        message: "Cadastro não foi encontrado.",
        color: "orange",
        position: "top-right"
      });
    },
    resultEmitRecebe(result) {
      this.cadastro = result.id;
    },
    displayMontar(value) {
      return !value.nome ? value.name : value.nome;
    },
    abreCadastro(value) {
      this.$store.dispatch("cadastro", {
        nome: value.name,
        noSearch: true
      });
    },
    verificaConexaoDB(){
      axios.get("/whatsapp/status?get-conexaoDB=1")
        .then(res=>{
          this.$store.commit("conexao", res.data.status);
          this.$store.commit("tipoWhatsapp", res.data.tipo);
        })
        .catch(err=>{
        });
    }
  },
  mounted()  {

  },
  computed: {
    ...mapGetters([
      "hasWhatsApp",
      "cadastroView",
      "errorsWhatsApp",
      "tipoWhatsapp",
    ]),
    OpenDialogAgendarMensagem: {
      get() {
        return this.$store.getters.OpenDialogAgendarMensagem;
      },
      set() {
        this.$store.commit(
          "OpenDialogAgendarMensagem",
          this.OpenDialogAgendarMensagem
        );
      }
    },
    errors() {
      return this.errorsWhatsApp;
    }
  },
  watch: {
    template(value){
      if (value){
        this.buscaTemplate(value);
      }
    },
    variavel(value) {
      if (value) {
        this.whatsappBody += value.value;
        this.variavel = null;
      }
    },
    expira() {
      const primeiroElementoHorario = document.querySelector('.q-time__clock-pos-0 span');

      if (primeiroElementoHorario && primeiroElementoHorario.innerHTML === '0') {
        this.$refs.expiraTime.hide();
      }
    },
    OpenDialogAgendarMensagem(value){
      if(value){
        this.buscaTemplates();
        this.verificaConexaoDB();
      }
    }
  },
};
</script>

<style lang="stylus" scoped></style>
