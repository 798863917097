<template>
  <q-dialog v-model="OpenDialogNovoWebhook" persistent>
    <q-card style="max-width: 550px !important">

      <q-card-section class="row items-center">
        <div class="text-h6">{{ isNew ? 'Novo ' : 'Editar ' }} Webhook</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
      </q-card-section>

      <q-separator />

      <q-card-section>
        <div class="q-pa-md">
          <div class="q-col-gutter-md row">

            <div class="col-12">
              <q-checkbox v-model="webhook.ativo" label="Ativo" color="green" />
            </div>

            <div class="col-12">
              <q-select
                  v-model="webhook.acao"
                  :readonly="!isNew"
                  :options="acoes"
                  label="Ação"
                  option-value="action"
                  bottom-slots
                  emit-value
                  map-options
                  :error="Boolean(errors.acao)"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.acao" :key="i">{{error}}</span>
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
            </div>

            <div class="col-12">
              <q-input
                  v-model="webhook.descricao"
                  label="Descrição"
                  bottom-slots
                  :error="Boolean(errors.descricao)"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.nome" :key="i">{{error}}</span>
                  </template>
                </q-input>
            </div>

            <div class="col-12">
              <q-input
                  v-model="webhook.url"
                  label="URL de Envio"
                  bottom-slots
                  :error="Boolean(errors.url)"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.nome" :key="i">{{error}}</span>
                  </template>
                </q-input>
            </div>

            <div class="col-12">
              <p class="text-subtitle2">Headers</p>

              <div class="q-col-gutter-md row" v-for="(header, index) in headers" :key="'header' + index">
                <div class="col-5">
                  <q-input
                    v-model="header.chave"
                    label="Chave"
                    bottom-slots
                    :error="Boolean(errors.url)"
                  >
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.nome" :key="i">{{error}}</span>
                    </template>
                  </q-input>
                </div>

                <div class="col-6">
                  <q-input
                    v-model="header.valor"
                    label="Valor"
                    bottom-slots
                    :error="Boolean(errors.url)"
                  >
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.nome" :key="i">{{error}}</span>
                    </template>
                  </q-input>
                </div>

                <div class="col-1">
                  <q-btn style="position:relative; top: 20px" icon="delete" flat dense round class="text-negative" @click="headers.splice(index, 1)" />
                </div>
              </div>

              <div class="col-2">
                <q-btn icon="add" flat dense round class="text-positive" @click="headers.push({chave: '', valor: ''})" />
              </div>
            </div>

          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          label="Salvar"
          clickable
          v-ripple
          @click="salvar"
          :loading="salvando"
          color="green"
          text-color="white"
          style="margin-right: 22px; margin-bottom: 10px"
        />
      </q-card-actions>

    </q-card>
  </q-dialog>
</template>

<script>
import axios from '@/axios-auth';
export default {
  data: () => ({
    errors: [],
    headers: [
      {
        chave: '',
        valor: ''
      }
    ],
    salvando: false
  }),
  methods: {
    closeModal () {
      this.OpenDialogNovoWebhook = false;
      this.webhook = {
        acao: '',
        headers: {},
        url: null,
        ativo: true,
        descricao: ''
      };
      this.headers = [
        {
          chave: '',
          valor: ''
        }
      ];
    },
    salvar() {
      const url = this.isNew ? '/webhooks/add' : '/webhooks/edit/' + this.webhook.id;

      let headers = {};
      let verify = false;
      for (const header of this.headers) {
        if (header.chave.length > 0) {
          verify = true;
          headers[header.chave] = header.valor;
        }
      }

      this.webhook.headers = verify ? headers : null;

      this.salvando = true;

      axios.post(url, this.webhook)
        .then((res) => {
          if (res.error && res.error.response.status === 403) {
            this.$q.notify({
              message: res.error.response.data.message,
              color: 'red',
              position: 'top-right',
            });
            return;
          }

          if (res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            this.$store.dispatch('listWebhooks');
            this.closeModal();
          } else {
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
        })
        .catch(() => {
          this.$q.notify({
              message: 'Não foi possível contactar, ou você não possui permissão!',
              color: "red",
              position: "top-right"
            });
        })
        .finally(() => {
          this.salvando = false;
        });
    }
  },
  watch: {
    webhook(value) {
      if (value.headers && value.headers.length > 0) {
        const headers = JSON.parse(value.headers);

        this.headers = Object.keys(headers).map(key => ({chave: key, valor: headers[key]}));
      } else {
        this.headers = [
          {
            chave: '',
            valor: ''
          }
        ];
      }
    }
  },
  computed: {
    OpenDialogNovoWebhook: {
      get () {
        return this.$store.getters.OpenDialogNovoWebhook;
      },
      set (value) {
        this.$store.commit('OpenDialogNovoWebhook', value);
      }
    },
    isNew: {
      get () {
        return this.$store.getters.isNewWebhook;
      },
      set (value) {
        this.$store.commit('isNewWebhook', value);
      }
    },
    webhook: {
      get () {
        return this.$store.getters.webhook;
      },
      set (value) {
        this.$store.commit('webhook', value);
      }
    },
    acoes: {
      get () {
        return this.$store.getters.acoesWebhookArray;
      },
      set (value) {
        this.$store.commit('acoesWebhookArray', value);
      }
    }
  }
};
</script>
