<template>
  <div>
    <q-table
      flat
      dense
      row-key="id"
      :data="dataTabela"
      :columns="columns"
      :title="tabela.title"
      :pagination="pagination"
    >
      <template v-slot:top>
        <div class="q-table__title">{{ tabela.title }}</div>

        <q-space />

        <button-metricas-saas-imprimir-detalhamento :campo="campo" :referencia="referencia" />
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" class="text-right">{{ props.row.id }}</q-td>

          <q-td key="ativo" class="text-center">
            <q-icon
              size="24px"
              :name="props.row.ativo ? 'check' : 'close'"
              :class="props.row.ativo ? 'text-positive' : 'text-negative'"
            >
              <q-tooltip v-if="!props.row.ativo && props.row.deactivated">
                {{ props.row.deactivated | formatDateTime }}
              </q-tooltip>
            </q-icon>
            <div v-if="props.row.congelado">
              <q-badge color="orange">Congelada</q-badge>
            </div>
          </q-td>

          <q-td key="cadastro_nome" class="text-left">
            {{ props.row.cadastro_nome | truncate(30) }}
            <q-tooltip v-if="props.row.cadastro_nome && props.row.cadastro_nome.length > 30">
              {{ props.row.cadastro_nome }}
            </q-tooltip>

            <br v-if="props.row.cadastro_cpfcnpj" />
            {{ props.row.cadastro_cpfcnpj }}
          </q-td>

          <q-td key="descricao" class="text-left">
            <span v-if="props.row.quantidade > 1">({{ props.row.quantidade }})</span>

            <span>
              {{ props.row.descricao | truncate(40) }}
              <q-tooltip v-if="props.row.descricao && props.row.descricao.length > 40">
                {{ props.row.descricao }}
              </q-tooltip>
            </span>

            <br />

            Intervalo: {{ getPeriodoDescricao(props.row.periodo) }}

            <br v-if="props.row.dtini || props.row.dtfim" />

            <span class="text-caption text-grey" v-if="props.row.dtini"
              >Início: {{ props.row.dtini | formatDate }}</span
            >

            <span class="text-caption text-grey" v-if="props.row.dtfim">
              Fim: {{ props.row.dtfim | formatDate }}</span
            >
          </q-td>

          <q-td
            key="valor_anterior"
            class="text-right"
            v-if="props.row.valor_anterior !== undefined"
          >
            {{ props.row.valor_anterior | currency }}
          </q-td>

          <q-td key="valor" class="text-right">{{ props.row.valor | currency }}</q-td>

          <q-td key="expansao_mrr" class="text-right" v-if="props.row.expansao_mrr !== undefined">
            {{ props.row.expansao_mrr | currency }}
          </q-td>

          <q-td key="contracao_mrr" class="text-right" v-if="props.row.contracao_mrr !== undefined">
            {{ props.row.contracao_mrr | currency }}
          </q-td>

          <q-td
            v-if="(props.row.contracao_mrr || props.row.expansao_mrr) !== undefined"
            key="taxa_expansao" class="text-right"
          >
            {{ taxa(props.row) }}
          </q-td>
        </q-tr>
      </template>

      <template v-slot:bottom-row>
        <tr class="text-weight-bold">
          <td
            v-for="(td, index) in tfootColumns"
            :key="index"
            :colspan="td.colspan"
            :class="`text-${td.align}`"
          >
            <span v-if="td.type === 'currency'">{{ td.value | currency }}</span>
            <span v-else>{{ td.value }}</span>
          </td>
        </tr>
      </template>
    </q-table>
  </div>
</template>

<script>
export default {
  props: ['tabela', 'campo', 'referencia'],
  name: 'tabela-metricas-saas-recorrencias',
  data() {
    return {
      pagination: {
        sortBy: 'cadastro_nome',
        descending: false,
        page: 1,
        rowsPerPage: 20,
      },
      dataTabela: [],
      lastIndex: 0,
    };
  },
  methods: {
    getPeriodoDescricao(intervalo) {
      intervalo = parseInt(intervalo);

      switch (intervalo) {
        case 1:
          return 'Mensal';
        case 2:
          return 'Bimestral';
        case 3:
          return 'Trimestral';
        case 6:
          return 'Semestral';
        case 12:
          return 'Anual';
        default:
          return intervalo + ' meses';
      }
    },
    taxa(dados) {
      const mudanca = dados.expansao_mrr || dados.contracao_mrr;
      const valorAnterior = dados.valor_anterior;

      return ((mudanca / valorAnterior) * 100).toFixed(2) + '%';
    },
  },
  computed: {
    columns() {
      const columns = [
        {
          name: 'id',
          label: 'ID',
          field: 'id',
          sortable: true,
          align: 'center',
        },
        {
          name: 'ativo',
          label: 'Ativo',
          field: 'ativo',
          sortable: true,
          align: 'center',
        },
        {
          name: 'cadastro_nome',
          label: 'Cliente',
          field: 'cadastro_nome',
          sortable: true,
          align: 'center',
        },
        {
          name: 'descricao',
          label: 'Descrição',
          field: 'descricao',
          sortable: true,
          align: 'center',
        },
        {
          name: 'valor',
          label: 'Valor',
          field: 'valor',
          sortable: true,
          align: 'center',
        },
      ];

      if (this.campo === 'expansao_mrr') {
        columns.splice(4, 0, {
          name: 'valor_anterior',
          label: 'Valor Anterior',
          field: 'valor_anterior',
          sortable: true,
          align: 'center',
        });
        columns.push({
          name: 'expansao_mrr',
          label: 'Expansão',
          field: 'expansao_mrr',
          sortable: true,
          align: 'center',
        });
        columns.push({
          name: 'taxa_expansao',
          label: 'Taxa',
          field: 'taxa_expansao',
          sortable: true,
          align: 'center',
        });
      }

      if (this.campo === 'contracao_mrr') {
        columns.splice(4, 0, {
          name: 'valor_anterior',
          label: 'Valor Anterior',
          field: 'valor_anterior',
          sortable: true,
          align: 'center',
        });
        columns.push({
          name: 'contracao_mrr',
          label: 'Contração',
          field: 'contracao_mrr',
          sortable: true,
          align: 'center',
        });
        columns.push({
          name: 'taxa_contracao',
          label: 'Taxa',
          field: 'taxa_contracao',
          sortable: true,
          align: 'center',
        });
      }

      return columns;
    },
    totalValorAnterior() {
      if (!['expansao_mrr', 'contracao_mrr'].includes(this.campo)) return 0.0;

      return this.tabela.data.reduce((total, item) => total + parseFloat(item.valor_anterior), 0);
    },
    totalValor() {
      return this.tabela.data.reduce((total, item) => total + parseFloat(item.valor), 0);
    },
    totalExpansaoMrr() {
      if (this.campo !== 'expansao_mrr') return 0.0;

      return this.tabela.data.reduce((total, item) => total + parseFloat(item.expansao_mrr), 0);
    },
    totalContracaoMrr() {
      if (this.campo !== 'contracao_mrr') return 0.0;

      return this.tabela.data.reduce((total, item) => total + parseFloat(item.contracao_mrr), 0);
    },
    tfootColumns() {
      const tfootColumns = [
        {
          value: 'Total',
          align: 'left',
          type: '',
          colspan: 4,
        },
        {
          value: this.totalValor,
          align: 'right',
          type: 'currency',
          colspan: 1,
        },
      ];

      if (this.campo === 'expansao_mrr') {
        tfootColumns.splice(1, 0, {
          value: this.totalValorAnterior,
          align: 'right',
          type: 'currency',
          colspan: 1,
        });
        tfootColumns.push({
          value: this.totalExpansaoMrr,
          align: 'right',
          type: 'currency',
          colspan: 1,
        });
        tfootColumns[0].colspan = 4;
      }

      if (this.campo === 'contracao_mrr') {
        tfootColumns.splice(1, 0, {
          value: this.totalValorAnterior,
          align: 'right',
          type: 'currency',
          colspan: 1,
        });
        tfootColumns.push({
          value: this.totalContracaoMrr,
          align: 'right',
          type: 'currency',
          colspan: 1,
        });
        tfootColumns[0].colspan = 4;
      }

      return tfootColumns;
    },
  },
  mounted() {
    this.dataTabela = [];
    this.lastIndex = 0;
    this.dataTabela = this.tabela.data;
  },
};
</script>
