import Vue from "vue";
import Vuex from "vuex";
import login from "./modules/login";
import profile from "./modules/profile";
import cadastros from "./modules/cadastros";
import buscaCEP from "./modules/buscaCEP";
import buscaCNPJ from "./modules/buscaCNPJ";
import grupos from "./modules/grupos";
import caixas from "./modules/caixas";
import cadastro_view from "./modules/cadastro_view";
import parametros from "./modules/parametros";
import users from "./modules/users";
import contas from "./modules/contas";
import regua from "./modules/regua";
import template from "./modules/template";
import formasRecebimento from "./modules/formasRecebimento";
import planodecontas from "./modules/planodecontas";
import loterias from "./modules/loterias";
import servicos from "./modules/servicos";
import produtos from "./modules/produtos";
import categorias from "./modules/categorias";
import dashboard from "./modules/dashboard";
import extrato from "./modules/extrato";
import register from "./modules/register";
import newPass from "./modules/newPass";
import activatePass from "./modules/activatePass";
import despesas from "./modules/despesas";
import comissoes from "./modules/comissoes";
import receitas from "./modules/receitas";
import findGateways from "./modules/findGateways";
import relatorios from "./modules/relatorios";
import changeCompany from "./modules/changeCompany";
import estoque from "./modules/estoque";
import licencas from "./modules/licencas";
import resumoTFL from "./modules/resumoTFL";
import timeline from "./modules/timeline";
import pwa from "./modules/pwa";
import whatsapp from "./modules/whatsapp";
import mapaResgates from "./modules/mapaResgates";
import centroCustos from "./modules/centroCustos";
import notaFiscal from "./modules/notaFiscal";
import cofreInteligente from "./modules/cofreInteligente";
import planos from "./modules/planos";
import metricasSaas from "./modules/metricasSaas";
import zenvia from "./modules/zenvia";
import manuais from "./modules/manuais";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    login,
    profile,
    cadastros,
    buscaCEP,
    buscaCNPJ,
    grupos,
    caixas,
    cadastro_view,
    parametros,
    users,
    contas,
    regua,
    template,
    formasRecebimento,
    planodecontas,
    loterias,
    servicos,
    produtos,
    categorias,
    dashboard,
    extrato,
    register,
    newPass,
    activatePass,
    despesas,
    comissoes,
    receitas,
    findGateways,
    relatorios,
    changeCompany,
    estoque,
    licencas,
    resumoTFL,
    timeline,
    pwa,
    whatsapp,
    mapaResgates,
    centroCustos,
    notaFiscal,
    cofreInteligente,
    planos,
    metricasSaas,
    zenvia,
    manuais
  }
});
