import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";
import { bus } from "../../main";

const state = {
  licencas: [],
  pagingLicencas: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  filtrosLicencas: {},
  dadosLicencas: {
    page: 0,
    busca: null,
    ativo: "ativo",
    estado: null,
    uuid: null,
    cadastro_id: null,
    servico_id: null,
    version_id: null
  },
  buscandoLicencas: false,
  totalLicencas: 0,
  statusLicencas: {
    ativo: "Ativos",
    inativo: "Inativos"
  },
  btnStatusLicencas: "Ativos",
  btnServico: 0,
  btnVersao: 0,
  pulseCountLicencas: 0,
  pulseRapidoLicencas: false,
  marcadoLicencas: false,
  allLicencas: false,
  counterLicencas: 0,  
  getSelectedLicencas: [],
  tipoLicencas: ["Data", "Plano", "Data/Plano", "Nenhum"],
  licenca: {
    id: null,
    ativo: true,
    cadastro_id: null,
    servico_id: 1,
    version_id: 1,
    codigo_uuid: "",
    descricao: "",
    plano: "",
    tipo: "",
    bloquear_em: "",
    cadastro_nome: ""
  },
  errorsLicencas: {},
  aguardeLicencas: false,
  licencaEdit: null,
  aguardeBackground: false,
  bloqueioLicenca: false,
  queryLicencas: {},
  acessosData: {
    dtini: "",
    dtfim: ""
  },
  servicos: [],
  versoes: [],
  servico: {
    id: null,
    ativo: true,
    nome: null,
    descricao: null,
    token: null,
    private_key: null
  },
  versao: {
    id: null,
    ativo: true,
    is_test: true,
    servico_id: null,
    numero: null,
    notes: "",
    url: ""
  },
  salvaSV: false,
  versionU: null,
  OpenDialogAdicionarVersao: false,
  OpenDialogLiberarLicenca: false,
  OpenDialogAdicionarServico: false,
  OpenDialogAdicionarLicenca: false,
  OpenDialogDetalharLicenca: false,
  OpenDialogAtualizarVersao: false,
  isPermissoLicencas: true
};

const mutations = {
  versionU: (state, userData) => {
    state.versionU = userData.versionU;
  },
  licencas: (state, userData) => {
    state.licencas = userData.licencas;
  },
  pagingLicencas: (state, userData) => {
    state.pagingLicencas.finder = userData.finder;
    state.pagingLicencas.page = userData.page;
    state.pagingLicencas.current = userData.current;
    state.pagingLicencas.count = userData.count;
    state.pagingLicencas.perPage = userData.perPage;
    state.pagingLicencas.prevPage = userData.prevPage;
    state.pagingLicencas.nextPage = userData.nextPage;
    state.pagingLicencas.pageCount = userData.pageCount;
    state.pagingLicencas.sort = userData.sort;
    state.pagingLicencas.direction = userData.direction;
    state.pagingLicencas.limit = userData.limit;
    state.pagingLicencas.sortDefault = userData.sortDefault;
    state.pagingLicencas.directionDefault = userData.directionDefault;
    state.pagingLicencas.scope = userData.scope;
  },
  filtrosLicencas: (state, userData) => {
    state.filtrosLicencas = userData.filtrosLicencas;
  },
  dadosLicencas: (state, userData) => {
    state.dadosLicencas.page = userData.page;
    state.dadosLicencas.busca = userData.busca;
    state.dadosLicencas.ativo = userData.ativo;
    state.dadosLicencas.estado = userData.estado;
    state.dadosLicencas.uuid = userData.uuid;
    state.dadosLicencas.cadastro_id = userData.cadastro_id;
    state.dadosLicencas.servico_id = userData.servico_id;
    state.dadosLicencas.version_id = userData.version_id;
  },
  buscandoLicencas: (state, userData) => {
    state.buscandoLicencas = userData.buscandoLicencas;
  },
  totalLicencas: (state, userData) => {
    state.totalLicencas = userData.totalLicencas;
  },
  btnStatusLicencas: (state, userData) => {
    state.btnStatusLicencas = userData.btnStatusLicencas;
  },
  pulseCountLicencas: (state, userData) => {
    state.pulseCountLicencas = userData.pulseCountLicencas;
  },
  pulseRapidoLicencas: (state, userData) => {
    state.pulseRapidoLicencas = userData.pulseRapidoLicencas;
  },
  marcadoLicencas: (state, userData) => {
    state.marcadoLicencas = userData.marcadoLicencas;
  },
  allLicencas: (state, userData) => {
    state.allLicencas = userData.allLicencas;
  },
  counterLicencas: (state, userData) => {
    state.counterLicencas = userData.counterLicencas;
  },
  getSelectedLicencas: (state, userData) => {
    state.getSelectedLicencas = userData;
  },
  licenca: (state, userData) => {
    state.licenca.id = userData.id;
    state.licenca.ativo = userData.ativo;
    state.licenca.cadastro_id = userData.cadastro_id;
    state.licenca.servico_id = userData.servico_id;
    state.licenca.version_id = userData.version_id;
    state.licenca.codigo_uuid = userData.codigo_uuid;
    state.licenca.descricao = userData.descricao;
    state.licenca.plano = userData.plano;
    state.licenca.tipo = userData.tipo;
    state.licenca.bloquear_em = userData.bloquear_em;
    state.licenca.cadastro_nome = userData.cadastro_nome;
  },
  errorsLicencas: (state, userData) => {
    state.errorsLicencas = userData.errorsLicencas;
  },
  aguardeLicencas: (state, userData) => {
    state.aguardeLicencas = userData.aguardeLicencas;
  },
  licencaEdit: (state, userData) => {
    state.licencaEdit = userData.licencaEdit;
  },
  aguardeBackground: (state, userData) => {
    state.aguardeBackground = userData.aguardeBackground;
  },
  bloqueioLicenca: (state, userData) => {
    state.bloqueioLicenca = userData.bloqueioLicenca;
  },
  queryLicencas: (state, userData) => {
    state.queryLicencas = userData.queryLicencas;
  },
  acessosData: (state, userData) => {
    state.acessosData.dtini = userData.dtini;
    state.acessosData.dtfim = userData.dtfim;
  },
  servicos: (state, userData) => {
    state.servicos = userData.servicos;
  },
  versoes: (state, userData) => {
    state.versoes = userData.versoes;
  },
  servico: (state, userData) => {
    state.servico.id = userData.id;
    state.servico.ativo = userData.ativo;
    state.servico.nome = userData.nome;
    state.servico.descricao = userData.descricao;
    state.servico.token = userData.token;
    state.servico.private_key = userData.private_key;
  },
  versao: (state, userData) => {
    state.versao.id = userData.id;
    state.versao.ativo = userData.ativo;
    state.versao.is_test = userData.is_test;
    state.versao.servico_id = userData.servico_id;
    state.versao.numero = userData.numero;
    state.versao.notes = userData.notes;
    state.versao.url = userData.url;
  },
  salvaSV: (state, userData) => {
    state.salvaSV = userData.salvaSV;
  },
  btnServico: (state, userData) => {
    state.btnServico = userData.btnServico;
  },
  btnVersao: (state, userData) => {
    state.btnVersao = userData.btnVersao;
  },
  OpenDialogAdicionarVersao: (state, userData) => {
    state.OpenDialogAdicionarVersao = userData;
  },
  OpenDialogLiberarLicenca: (state, userData) => {
    state.OpenDialogLiberarLicenca = userData;
  },
  OpenDialogAdicionarServico: (state, userData) => {
    state.OpenDialogAdicionarServico = userData;
  },
  OpenDialogAdicionarLicenca: (state, userData) => {
    state.OpenDialogAdicionarLicenca = userData;
  },
  OpenDialogDetalharLicenca: (state, userData) => {
    state.OpenDialogDetalharLicenca = userData;
  },
  OpenDialogAtualizarVersao: (state, userData) => {
    state.OpenDialogAtualizarVersao = userData;
  },
  isPermissoLicencas: (state, userData) => {
    state.isPermissoLicencas = userData;
  }
};

const actions = {
  listaLicencas: ({ commit }) => {
    commit("isPermissoLicencas", true);
    if (state.buscandoLicencas) return;
    if (!state.pagingLicencas.nextPage) return;
    commit("buscandoLicencas", {
      buscandoLicencas: true
    });
    if (state.queryLicencas.ativo) {
      commit("dadosLicencas", {
        ativo: state.queryLicencas.ativo,
        page: state.dadosLicencas.page,
        busca: state.dadosLicencas.busca,
        estado: state.dadosLicencas.estado,
        uuid: state.dadosLicencas.uuid,
        cadastro_id: state.dadosLicencas.cadastro_id,
        servico_id: state.dadosLicencas.servico_id,
        version_id: state.dadosLicencas.version_id
      });
    }
    if (state.queryLicencas.busca) {
      commit("dadosLicencas", {
        busca: state.queryLicencas.busca,
        page: state.dadosLicencas.page,
        ativo: state.dadosLicencas.ativo,
        estado: state.dadosLicencas.estado,
        uuid: state.dadosLicencas.uuid,
        cadastro_id: state.dadosLicencas.cadastro_id,
        servico_id: state.dadosLicencas.servico_id,
        version_id: state.dadosLicencas.version_id
      });
      bus.$emit("atualizaGrafico", { busca: state.queryLicencas.busca });
    }
    if (state.queryLicencas.estado) {
      commit("dadosLicencas", {
        estado: state.queryLicencas.estado,
        page: state.dadosLicencas.page,
        busca: state.dadosLicencas.busca,
        ativo: state.dadosLicencas.ativo,
        uuid: state.dadosLicencas.uuid,
        cadastro_id: state.dadosLicencas.cadastro_id,
        servico_id: state.dadosLicencas.servico_id,
        version_id: state.dadosLicencas.version_id
      });
      bus.$emit("atualizaGrafico", { estado: state.queryLicencas.estado });
    }
    if (state.queryLicencas.uuid) {
      commit("dadosLicencas", {
        uuid: state.queryLicencas.uuid,
        page: state.dadosLicencas.page,
        busca: state.dadosLicencas.busca,
        ativo: state.dadosLicencas.ativo,
        estado: state.dadosLicencas.estado,
        cadastro_id: state.dadosLicencas.cadastro_id,
        servico_id: state.dadosLicencas.servico_id,
        version_id: state.dadosLicencas.version_id
      });
    }
    if (state.queryLicencas.cadastro_id) {
      commit("dadosLicencas", {
        cadastro_id: state.queryLicencas.cadastro_id,
        page: state.dadosLicencas.page,
        busca: state.dadosLicencas.busca,
        ativo: state.dadosLicencas.ativo,
        estado: state.dadosLicencas.estado,
        uuid: state.dadosLicencas.uuid,
        servico_id: state.dadosLicencas.servico_id,
        version_id: state.dadosLicencas.version_id
      });
    }
    if (state.queryLicencas.servico_id) {
      commit("dadosLicencas", {
        servico_id: state.queryLicencas.servico_id,
        page: state.dadosLicencas.page,
        busca: state.dadosLicencas.busca,
        ativo: state.dadosLicencas.ativo,
        estado: state.dadosLicencas.estado,
        uuid: state.dadosLicencas.uuid,
        cadastro_id: state.dadosLicencas.cadastro_id,
        version_id: state.dadosLicencas.version_id
      });
    }
    if (state.queryLicencas.version_id) {
      commit("dadosLicencas", {
        version_id: state.queryLicencas.version_id,
        page: state.dadosLicencas.page,
        busca: state.dadosLicencas.busca,
        ativo: state.dadosLicencas.ativo,
        estado: state.dadosLicencas.estado,
        uuid: state.dadosLicencas.uuid,
        cadastro_id: state.dadosLicencas.cadastro_id,
        servico_id: state.dadosLicencas.servico_id
      });
    }
    let dados = {
      page: state.dadosLicencas.page + 1,
      busca: state.dadosLicencas.busca,
      ativo: state.dadosLicencas.ativo === "ativo" ? "s" : "n",
      estado: state.dadosLicencas.estado,
      uuid: state.dadosLicencas.uuid,
      cadastro_id: state.dadosLicencas.cadastro_id,
      servico_id: state.dadosLicencas.servico_id,
      version_id: state.dadosLicencas.version_id
    };
    axios
      .get("/licencas", {
        params: dados
      })
      .then(res => {
        if (res.data.success) {
          commit("licencas", {
            licencas: state.licencas.concat(res.data.licencas)
          });
          commit("pagingLicencas", {
            finder: res.data.paging.finder,
            page: res.data.paging.page,
            current: res.data.paging.current,
            count: res.data.paging.count,
            perPage: res.data.paging.perPage,
            prevPage: res.data.paging.prevPage,
            nextPage: res.data.paging.nextPage,
            pageCount: res.data.paging.pageCount,
            sort: res.data.paging.sort,
            direction: res.data.paging.direction,
            limit: res.data.paging.limit,
            sortDefault: res.data.paging.sortDefault,
            directionDefault: res.data.paging.directionDefault,
            scope: res.data.paging.scope
          });
          commit("filtrosLicencas", {
            filtrosLicencas: res.data.filters
          });
          commit("totalLicencas", {
            totalLicencas: state.totalLicencas + state.pagingLicencas.current
          });
          commit("dadosLicencas", {
            page: dados.page,
            busca: dados.busca,
            ativo: dados.ativo === "s" ? "ativo" : "inativo",
            version_id: state.dadosLicencas.version_id,
            estado: state.dadosLicencas.estado,
            uuid: state.dadosLicencas.uuid,
            cadastro_id: state.dadosLicencas.cadastro_id,
            servico_id: state.dadosLicencas.servico_id
          });
        }
        commit("buscandoLicencas", {
          buscandoLicencas: false
        });
      })
      .catch(() => {
        commit("bloqueioLicenca", {
          bloqueioLicenca: true
        });
        commit("buscandoLicencas", {
          buscandoLicencas: false
        });
        commit("isPermissoLicencas", false);
      });
  },
  queryLicencas: ({ commit }, userData) => {
    commit("queryLicencas", {
      queryLicencas: userData
    });
  },
  resetPagingLicencas: ({ commit }) => {
    commit("pagingLicencas", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });
    commit("licencas", {
      licencas: []
    });
  },
  dadosLicencas: ({ commit, dispatch }, userData) => {
    commit("dadosLicencas", {
      ativo: userData.ativo,
      busca: userData.busca,
      page: 0,
      estado: userData.estado,
      uuid: userData.uuid,
      cadastro_id: userData.cadastro_id,
      servico_id: userData.servico_id,
      version_id: userData.version_id
    });
    commit("totalLicencas", {
      totalLicencas: 0
    });
    dispatch("resetPagingLicencas");
    dispatch("listaLicencas");
  },
  changeFilterStatusLicencas: ({ commit, dispatch }, userData) => {
    commit("dadosLicencas", {
      ativo: userData.val,
      page: 0,
      estado: state.dadosLicencas.estado,
      uuid: state.dadosLicencas.uuid,
      cadastro_id: state.dadosLicencas.cadastro_id,
      servico_id: state.dadosLicencas.servico_id,
      version_id: state.dadosLicencas.version_id
    });
    commit("btnStatusLicencas", {
      btnStatusLicencas: userData.key
    });
    commit("totalLicencas", {
      totalLicencas: 0
    });
    dispatch("resetPagingLicencas");
    dispatch("listaLicencas");
  },
  changeFilterServicos: ({ commit, dispatch }, userData) => {
    commit("dadosLicencas", {
      ativo: state.dadosLicencas.ativo,
      page: 0,
      estado: state.dadosLicencas.estado,
      uuid: state.dadosLicencas.uuid,
      cadastro_id: state.dadosLicencas.cadastro_id,
      servico_id: userData.id,
      version_id: state.dadosLicencas.version_id
    });
    commit("totalLicencas", {
      totalLicencas: 0
    });
    bus.$emit("atualizaGrafico", { id: userData.id });
    dispatch("versoes", userData.id);
    commit("btnServico", {
      btnServico: userData.id
    });
    dispatch("resetPagingLicencas");
    dispatch("listaLicencas");
  },
  changeFilterVercoes: ({ commit, dispatch }, userData) => {
    commit("dadosLicencas", {
      ativo: state.dadosLicencas.ativo,
      page: 0,
      estado: state.dadosLicencas.estado,
      uuid: state.dadosLicencas.uuid,
      cadastro_id: state.dadosLicencas.cadastro_id,
      servico_id: state.dadosLicencas.servico_id,
      version_id: userData.id
    });
    commit("totalLicencas", {
      totalLicencas: 0
    });
    commit("btnVersao", {
      btnVersao: userData.id
    });
    bus.$emit("atualizaGrafico", { version_id: userData.id });
    dispatch("resetPagingLicencas");
    dispatch("listaLicencas");
  },
  marcarDesmarcarTodosLicencas: ({ commit, state }) => {
    if (state.pulseCountLicencas === 1) {
      commit("pulseCountLicencas", {
        pulseCountLicencas: 0
      });
    } else {
      commit("pulseRapidoLicencas", {
        pulseRapidoLicencas: true
      });
    }
    let max = state.licencas.length;
    let licencas = state.licencas;
    commit("marcadoLicencas", {
      marcadoLicencas: !state.marcadoLicencas
    });
    if (!state.marcadoLicencas) {
      commit("allLicencas", {
        allLicencas: false
      });
    }
    for (var i = 0; i < max; i++) {
      licencas[i].marcado = state.marcadoLicencas;
    }
    commit("licencas", {
      licencas: licencas
    });
    if (state.marcadoLicencas) {
      commit("counterLicencas", {
        counterLicencas: state.totalLicencas
      });
    } else {
      commit("counterLicencas", {
        counterLicencas: 0
      });
    }
    setTimeout(() => {
      commit("pulseRapidoLicencas", {
        pulseRapidoLicencas: false
      });
      commit("pulseCountLicencas", {
        pulseCountLicencas: 1
      });
    }, 5000);
  },
  setAllLicencas: ({ commit }) => {
    commit("allLicencas", {
      allLicencas: !state.allLicencas
    });
    if (state.allLicencas) {
      commit("counterLicencas", {
        counterLicencas: state.pagingLicencas.count
      });
    } else {
      commit("counterLicencas", {
        counterLicencas: state.totalLicencas
      });
    }
  },
  marcaItemLicencas: ({ commit }, userData) => {
    let contador = state.contadorLincencas;
    let counter = state.counterLicencas;
    if (
      trClass === undefined ||
      trClass === "item" + userData.val + " marcou"
    ) {
      contador += 1;
      commit("contadorLincencas", {
        contadorLincencas: contador
      });
      counter -= 1;
      commit("counterLicencas", {
        counterLicencas: counter
      });
    } else {
      counter += 1;
      commit("counterLicencas", {
        counterLicencas: counter
      });
    }
  },
  newLicenca: ({ commit }) => {
    commit("licenca", {
      id: null,
      ativo: true,
      cadastro_id: null,
      servico_id: 1,
      version_id: 1,
      codigo_uuid: "",
      descricao: "",
      plano: "",
      tipo: "",
      bloquear_em: "",
      cadastro_nome: ""
    });
  },
  newServico: ({ commit }) => {
    commit("servico", {
      id: null,
      ativo: true,
      nome: null,
      descricao: null,
      token: null,
      private_key: null
    });
  },
  newVersao: ({ commit }) => {
    commit("versao", {
      id: null,
      ativo: true,
      is_test: true,
      servico_id: null,
      numero: null,
      notes: "",
      url: ""
    });
  },
  errorsLicencasClear: ({ commit }) => {
    commit("errorsLicencas", {
      errorsLicencas: {}
    });
  },
  reativaLicenca: ({ commit, dispatch }, userData) => {
    commit("aguardeBackground", {
      aguardeBackground: true
    });
    let data = {
      id: userData.id,
      ativo: userData.ativo,
      bloquear_em: userData.bloquear_em
    };
    Dialog.create({
      title: userData.key.cadastro.nome,
      message: "Confirma a reativação da licença?",
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/licencas/edit/" + userData.id, data)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: "Ação Realizada com Sucesso!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              let dadosd = {
                busca: state.dadosLicencas.busca,
                ativo: state.dadosLicencas.ativo,
                estado: state.dadosLicencas.estado,
                uuid: state.dadosLicencas.uuid,
                cadastro_id: state.dadosLicencas.cadastro_id,
                servico_id: state.dadosLicencas.servico_id,
                version_id: state.dadosLicencas.version_id
              };
              dispatch("dadosLicencas", dadosd);
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
            commit("aguardeBackground", {
              aguardeBackground: false
            });
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi Possível realizar a ação, ou você não possui permissão isto!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            commit("aguardeBackground", {
              aguardeBackground: false
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
        commit("aguardeBackground", {
          aguardeBackground: false
        });
      })
      .onDismiss(() => {
        Loading.hide();
        commit("aguardeBackground", {
          aguardeBackground: false
        });
      });
  },
  adicionarLicenca: ({ commit, dispatch }, userData) => {
    commit("aguardeLicencas", {
      aguardeLicencas: true
    });
    axios
      .post("/licencas/add", userData)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });
          dispatch("editLicencaClear");
          dispatch("errorsLicencasClear");
          dispatch("newLicenca");
          let dadosd = {
            busca: state.dadosLicencas.busca,
            ativo: state.dadosLicencas.ativo,
            estado: state.dadosLicencas.estado,
            uuid: state.dadosLicencas.uuid,
            cadastro_id: state.dadosLicencas.cadastro_id,
            servico_id: state.dadosLicencas.servico_id,
            version_id: state.dadosLicencas.version_id
          };
          dispatch("dadosLicencas", dadosd);
          commit("OpenDialogAdicionarLicenca", false);
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
          commit("errorsLicencas", {
            errorsLicencas: res.data.errors
          });
        }
        commit("aguardeLicencas", {
          aguardeLicencas: false
        });
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("aguardeLicencas", {
          aguardeLicencas: false
        });
      });
  },
  editarLicenca: ({ commit, dispatch }, userData) => {
    commit("aguardeLicencas", {
      aguardeLicencas: true
    });
    axios
      .post("/licencas/edit/" + userData.id, userData)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });
          dispatch("editLicencaClear");
          dispatch("errorsLicencasClear");
          dispatch("newLicenca");
          let dadosd = {
            busca: state.dadosLicencas.busca,
            ativo: state.dadosLicencas.ativo,
            estado: state.dadosLicencas.estado,
            uuid: state.dadosLicencas.uuid,
            cadastro_id: state.dadosLicencas.cadastro_id,
            servico_id: state.dadosLicencas.servico_id,
            version_id: state.dadosLicencas.version_id
          };
          dispatch("dadosLicencas", dadosd);
          commit("OpenDialogAdicionarLicenca", false);
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
          commit("errorsLicencas", {
            errorsLicencas: res.data.errors
          });
        }
        commit("aguardeLicencas", {
          aguardeLicencas: false
        });
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("aguardeLicencas", {
          aguardeLicencas: false
        });
      });
  },
  editLicenca: ({ commit, dispatch }, userData) => {
    commit("licencaEdit", {
      licencaEdit: userData
    });
    if (userData){
      commit("licenca", {
        id: userData.id,
        ativo: userData.ativo,
        cadastro_id: userData.cadastro_id,
        servico_id: userData.servico_id,
        version_id: userData.version_id,
        codigo_uuid: userData.codigo_uuid,
        descricao: userData.descricao,
        plano: userData.plano,
        tipo: userData.tipo,
        bloquear_em: userData.bloquear_em,
        cadastro_nome: userData.cadastro_nome
      });  
    } else {
      dispatch("newLicenca");
    }
  },
  editLicencaClear: ({ commit }) => {
    commit("licencaEdit", {
      licencaEdit: null
    });
  },
  excluirLicenca: ({ commit, dispatch }, userData) => {
    commit("aguardeBackground", {
      aguardeBackground: true
    });
    let dados = {
      excluir: userData.excluir
    };
    Dialog.create({
      title: userData.key.cadastro.nome,
      message: userData.excluir
        ? "Confirma a exclusão da licença?"
        : "Tem certeza que deseja inativar a licença?",
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/licencas/delete/" + userData.key.id, dados)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: "Ação Realizada com Sucesso!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              let dadosd = {
                busca: state.dadosLicencas.busca,
                ativo: state.dadosLicencas.ativo,
                estado: state.dadosLicencas.estado,
                uuid: state.dadosLicencas.uuid,
                cadastro_id: state.dadosLicencas.cadastro_id,
                servico_id: state.dadosLicencas.servico_id,
                version_id: state.dadosLicencas.version_id
              };
              dispatch("dadosLicencas", dadosd);
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
            commit("aguardeBackground", {
              aguardeBackground: false
            });
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi Possível realizar a ação, ou você não possui permissão isto!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            commit("aguardeBackground", {
              aguardeBackground: false
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
        commit("aguardeBackground", {
          aguardeBackground: false
        });
      })
      .onDismiss(() => {
        Loading.hide();
        commit("aguardeBackground", {
          aguardeBackground: false
        });
      });
  },
  liberarLicencas: ({ commit, dispatch }, userData) => {
    commit("aguardeBackground", {
      aguardeBackground: true
    });
    dispatch("getSelectedLicencas");
    let dados = {
      ids: state.getSelectedLicencas,
      bloquear_em: userData,
      all: state.allLicencas,
      filters: {}
    };
    if (
      state.dadosLicencas.busca !== null ||
      state.dadosLicencas.busca !== undefined
    ) {
      dados.filters.busca = state.dadosLicencas.busca;
    }
    if (
      state.dadosLicencas.ativo !== null ||
      state.dadosLicencas.ativo !== undefined
    ) {
      dados.filters.ativo = state.dadosLicencas.ativo;
    }
    if (
      state.dadosLicencas.estado !== null ||
      state.dadosLicencas.estado !== undefined
    ) {
      dados.filters.estado = state.dadosLicencas.estado;
    }
    if (
      state.dadosLicencas.uuid !== null ||
      state.dadosLicencas.uuid !== undefined
    ) {
      dados.filters.uuid = state.dadosLicencas.uuid;
    }
    if (
      state.dadosLicencas.cadastro_id !== null ||
      state.dadosLicencas.cadastro_id !== undefined
    ) {
      dados.filters.cadastro_id = state.dadosLicencas.cadastro_id;
    }
    if (
      state.dadosLicencas.servico_id !== null ||
      state.dadosLicencas.servico_id !== undefined
    ) {
      dados.filters.servico_id = state.dadosLicencas.servico_id;
    }
    if (
      state.dadosLicencas.version_id !== null ||
      state.dadosLicencas.version_id !== undefined
    ) {
      dados.filters.version_id = state.dadosLicencas.version_id;
    }
    Dialog.create({
      title: "Liberação de licença em massa",
      message: "Confirma a liberação?",
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/licencas/liberar", dados)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: "Ação Realizada com Sucesso!",
                message: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              let dadosd = {
                busca: state.dadosLicencas.busca,
                ativo: state.dadosLicencas.ativo,
                estado: state.dadosLicencas.estado,
                uuid: state.dadosLicencas.uuid,
                cadastro_id: state.dadosLicencas.cadastro_id,
                servico_id: state.dadosLicencas.servico_id,
                version_id: state.dadosLicencas.version_id
              };
              dispatch("dadosLicencas", dadosd);

              commit("OpenDialogLiberarLicenca", false);
            } else {
              Dialog.create({
                title: "Erro ao realizar ação!",
                message: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
            commit("aguardeBackground", {
              aguardeBackground: false
            });
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi Possível realizar a ação, ou você não possui permissão isto!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            commit("aguardeBackground", {
              aguardeBackground: false
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
        commit("aguardeBackground", {
          aguardeBackground: false
        });
      })
      .onDismiss(() => {
        Loading.hide();
        commit("aguardeBackground", {
          aguardeBackground: false
        });
      });
  },
  acessosData: ({ commit }, userData) => {
    commit("acessosData", {
      dtini: userData.dtini,
      dtfim: userData.dtfim
    });
  },
  servicos: ({ commit, dispatch }) => {
    axios
      .get("/licencas/servicos")
      .then(res => {
        if (res.data.success) {
          commit("servicos", {
            servicos: res.data.servicos
          });
          dispatch("versoes");
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
      })
      .catch(() => {
        Notify.create({
          message:
            "Não foi possível listar os serviços, tente novamente mais tarde.",
          color: "red",
          position: "top-right"
        });
      });
  },
  versoes: ({ commit }, userData) => {
    let id;
    if (userData !== undefined) {
      id = userData;
    } else {
      id = "1";
    }
    axios
      .get("/licencas/versoes/" + id)
      .then(res => {
        if (res.data.success) {
          commit("versoes", {
            versoes: res.data.versoes
          });
          commit("btnVersao", {
            btnVersao: 0
          });
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
      })
      .catch(() => {
        Notify.create({
          message:
            "Não foi possível listar as versões, tente novamente mais tarde.",
          color: "red",
          position: "top-right"
        });
      });
  },
  saveServico: ({ commit, dispatch }, userData) => {
    commit("salvaSV", {
      salvaSV: true
    });
    axios
      .post("/licencas/servicos-add", userData)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: "Serviço " + res.data.servico.nome + " adicionado!",
            color: "green",
            position: "top-right"
          });
          commit("errorsLicencas", {
            errorsLicencas: {}
          });
          commit("OpenDialogAdicionarServico", false);
          dispatch("newServico");
        } else {
          commit("errorsLicencas", {
            errorsLicencas: res.data.errors
          });
        }
        commit("salvaSV", {
          salvaSV: false
        });
      })
      .catch(() => {
        commit("salvaSV", {
          salvaSV: false
        });
        Notify.create({
          message:
            "Não foi possível adicionar o serviço, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  saveVersao: ({ commit, dispatch }, userData) => {
    commit("salvaSV", {
      salvaSV: true
    });
    axios
      .post("/licencas/versions-add", userData)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: "Versão " + res.data.version.numero + " adicionada!",
            color: "green",
            position: "top-right"
          });
          commit("errorsLicencas", {
            errorsLicencas: {}
          });
          commit("OpenDialogAdicionarVersao", false);
          dispatch("newVersao");
        } else {
          commit("errorsLicencas", {
            errorsLicencas: res.data.errors
          });
        }
        commit("salvaSV", {
          salvaSV: false
        });
      })
      .catch(() => {
        commit("salvaSV", {
          salvaSV: false
        });
        Notify.create({
          message:
            "Não foi possível adicionar a versão, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  updateV: ({ commit, dispatch }, userData) => {
    commit("versionU", {
      versionU: userData.versionU
    });
    if (!state.versionU) {
      Notify.create({
        message: "Selecione uma versão",
        color: "orange",
        position: "top-right"
      });
      return;
    }
    dispatch("getSelectedLicencas");
    if (state.getSelectedLicencas.length === 0 && state.allLicencas === false) {
      Notify.create({
        message: "Selecione ao menos um cadastro para atualizar a versão",
        color: "orange",
        position: "top-right"
      });
      return;
    }

    commit("aguardeBackground", {
      aguardeBackground: true
    });
    commit("salvaSV", {
      salvaSV: true
    });

    let filtros = {
      page: state.pagingLicencas.page,
      busca: state.filtrosLicencas.busca,
      ativo: state.filtrosLicencas.ativo,
      cadastro_id: state.filtrosLicencas.cadastro_id,
      servico_id: userData.servico_id,
      version_id: state.filtrosLicencas.version_id,
      estado: state.filtrosLicencas.estado,
      uuid: state.filtrosLicencas.uuid
    };
    let dados = {
      all: state.allLicencas,
      filtros: filtros,
      ids: state.getSelectedLicencas,
      version_id: state.versionU
    };
    Dialog.create({
      title: "Atualizar versão",
      message: "Confirma a atualização da versão em massa?",
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/licencas/update-version", dados)
          .then(res => {
            if (res.data.success) {
              commit("OpenDialogAtualizarVersao", false);
              Dialog.create({
                title: "Ação Realizada com Sucesso!",
                message: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              commit("errorsLicencas", {
                errorsLicencas: {}
              });
              commit("counterLicencas", {
                counterLicencas: 0
              });
              commit("dadosLicencas", {
                busca: state.queryLicencas.busca,
                page: 0,
                ativo: state.dadosLicencas.ativo,
                estado: state.dadosLicencas.estado,
                uuid: state.dadosLicencas.uuid,
                cadastro_id: state.dadosLicencas.cadastro_id,
                servico_id: state.dadosLicencas.servico_id,
                version_id: state.dadosLicencas.version_id
              });
              dispatch("resetPagingLicencas");
              dispatch("listaLicencas");
            } else {
              Dialog.create({
                title: "Erro ao executar ação!",
                message: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
            commit("salvaSV", {
              salvaSV: false
            });
            commit("aguardeBackground", {
              aguardeBackground: false
            });
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi Possível realizar a ação, ou você não possui permissão isto!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            commit("salvaSV", {
              salvaSV: false
            });
            commit("aguardeBackground", {
              aguardeBackground: false
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
        commit("aguardeBackground", {
          aguardeBackground: false
        });
      })
      .onDismiss(() => {
        Loading.hide();
        commit("aguardeBackground", {
          aguardeBackground: false
        });
      });
  }
};

const getters = {
  versionU: state => {
    return state.versionU;
  },
  licencas: state => {
    return state.licencas;
  },
  pagingLicencas: state => {
    return state.pagingLicencas;
  },
  filtrosLicencas: state => {
    return state.filtrosLicencas;
  },
  buscandoLicencas: state => {
    return state.buscandoLicencas;
  },
  totalLicencas: state => {
    return state.totalLicencas;
  },
  statusLicencas: state => {
    return state.statusLicencas;
  },
  btnStatusLicencas: state => {
    return state.btnStatusLicencas;
  },
  dadosLicencas: state => {
    return state.dadosLicencas;
  },
  pulseCountLicencas: state => {
    return state.pulseCountLicencas;
  },
  pulseRapidoLicencas: state => {
    return state.pulseRapidoLicencas;
  },
  marcadoLicencas: state => {
    return state.marcadoLicencas;
  },
  allLicencas: state => {
    return state.allLicencas;
  },
  counterLicencas: state => {
    return state.counterLicencas;
  },
  licenca: state => {
    return state.licenca;
  },
  errorsLicencas: state => {
    return state.errorsLicencas;
  },
  tipoLicencas: state => {
    return state.tipoLicencas;
  },
  licencaEdit: state => {
    return state.licencaEdit;
  },
  aguardeBackground: state => {
    return state.aguardeBackground;
  },
  bloqueioLicenca: state => {
    return state.bloqueioLicenca;
  },
  acessosData: state => {
    return state.acessosData;
  },
  servicos: state => {
    return state.servicos;
  },
  versoes: state => {
    return state.versoes;
  },
  servico: state => {
    return state.servico;
  },
  versao: state => {
    return state.versao;
  },
  salvaSV: state => {
    return state.salvaSV;
  },
  btnServico: state => {
    return state.btnServico;
  },
  btnVersao: state => {
    return state.btnVersao;
  },
  OpenDialogAdicionarVersao: state => {
    return state.OpenDialogAdicionarVersao;
  },
  OpenDialogLiberarLicenca: state => {
    return state.OpenDialogLiberarLicenca;
  },
  OpenDialogAdicionarServico: state => {
    return state.OpenDialogAdicionarServico;
  },
  OpenDialogAdicionarLicenca: state => {
    return state.OpenDialogAdicionarLicenca;
  },
  OpenDialogDetalharLicenca: state => {
    return state.OpenDialogDetalharLicenca;
  },
  OpenDialogAtualizarVersao: state => {
    return state.OpenDialogAtualizarVersao;
  },
  aguardeLicencas: state => {
    return state.aguardeLicencas;
  },
  isPermissoLicencas: state => {
    return state.isPermissoLicencas;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
