<template>
  <q-dialog v-model="OpenDialogLancarDiferenca">
    <q-card :style="`width: 100%; max-width: ${opcoesAvancadas ? '800px' : '400px'};`">
      <q-card-section class="row items-center">
        <div class="text-h6">Lançar Diferença</div>
        <q-space />
        <q-btn v-close-popup dense flat icon="close" round @click="closeDialog" />
      </q-card-section>

      <q-separator />

      <q-card-section class="row q-col-gutter-lg">
        <div :class="`col-12 col-md-${opcoesAvancadas ? 6 : 12} row q-pb-md`">

          <q-list v-if="selectedExtrato">
            <q-item>
              <q-item-section>
                <q-item-label caption>Descrição</q-item-label>
                <q-item-label>{{ selectedExtrato.descricao }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>Vencimento</q-item-label>
                <q-item-label>{{ selectedExtrato.dtmovimento  | formatDate }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>Valor</q-item-label>
                <q-item-label
                >R$ {{ selectedExtrato.valor | currency }}
                </q-item-label
                >
              </q-item-section>
            </q-item>
          </q-list>
          <q-list v-else>
            <q-item v-for="conta in selectedExtrato" :key=conta.id>
              <q-item-section>
                <q-item-label caption>Descrição</q-item-label>
                <q-item-label>{{ conta.descricao }} - {{ conta.dtmovimento  | formatDate }} - R$
                  {{ conta.valor | currency }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>

          <div class="col-12 q-mt-md">
            <q-checkbox
              v-model="isTransferencia"
              clickable
              color="green"
              label="Realizar transferencia"
            />
          </div>

          <div class="col-12 q-mb-sm">
            <autocomplete
              :cadastro="abreCadastro"
              :create="createValue"
              :display="displayMontar"
              :errors="errors.nome"
              :filter="filter"
              :formatDisplay="formatDisplay"
              :newCadastro="cadastroReturn"
              :options="options"
              tipoConsulta="cadastro"
              title="Cadastro ou fornecedor"
              @resultAutocomplete="resultEmitRecebe"
            />
          </div>

          <div v-if="isTransferencia" class="col-12 q-mb-sm">
            <q-select
              v-model="conta.conta_id"
              :option-label="
                value => (value === null ? 'Item Vazio' : value.titulo)
              "
              :option-value="value => (value === null ? null : value)"
              :options="contas"
              clearable
              label="Conta"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>

          <div v-if="!isTransferencia" class="col-12 q-mb-sm">
            <q-select
              v-model="conta.planodeconta_id"
              :options="planoContasFilterResult"
              clearable
              emit-value
              input-debounce="500"
              label="Plano de Conta"
              map-options
              option-label="titulo"
              option-value="id"
              use-input
              @filter="planoFilter"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>

          <div v-if="hasOpcoesAvancadas" class="col-12 q-mt-md">
            <q-checkbox
              v-model="opcoesAvancadas"
              color="green"
              label="Opções Avançadas"
            />
          </div>

        </div>

        <div v-show="opcoesAvancadas" class="col-12 col-md-6">

          <div v-if="exibirCentrosdecusto" class="row">

            <div class="col-12 q-mb-md">
              <q-select
                v-model="newMovcontaRateio.centrodecusto"
                :error="Boolean(errors.centrodecusto)"
                :hide-bottom-space="!Boolean(errors.centrodecusto)"
                :option-label="value => value === null ? 'Item Vazio' : value.nome"
                :option-value="value => value === null ? null : value"
                :options="optionsCentrosdecusto"
                label="Centro de Custo"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem Resultados
                    </q-item-section
                    >
                  </q-item>
                </template>
                <template v-slot:error>
                  <span>{{ errors.centrodecusto }}</span>
                </template>
              </q-select>
            </div>

            <div class="col-2 flex items-end">
              <q-btn
                color="white"
                disable
                label="%"
                text-color="black"
              />
            </div>

            <div class="col-8">
              <q-field
                v-model="newMovcontaRateio.porcentagem"
                :error="Boolean(errors.porcentagem)"
                :hide-bottom-space="!Boolean(errors.porcentagem)"
                label="Porcentagem"
              >
                <template
                  v-slot:control="{ id, floatingLabel, value, emitValue }"
                >
                  <money
                    v-show="floatingLabel"
                    :id="id"
                    :value="value"
                    class="q-field__native text-right"
                    v-bind="percentFormat"
                    @input="emitValue"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span>{{ errors.porcentagem }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-2 flex items-end justify-end">
              <q-btn
                color="primary"
                flat
                icon="add"
                round
                @click="addMovcontaRateio()"
              />
            </div>

            <div class="col-12 q-mt-md">
              <p
                v-if="porcentagemMovcontasRateioTotal > 100"
                class="text-negative caption"
              >
                A porcentagem total passa de 100%
              </p>
              <div v-if="Boolean(errors.movcontas_rateios)">
                <p
                  v-for="(error, index) in errors.movcontas_rateios"
                  :key="index"
                  class="text-negative caption"
                >
                  {{ error }}
                </p>
              </div>

              <q-markup-table
                dense
                flat
              >
                <tbody>
                <tr
                  v-for="(rateio, index) in movcontas_rateios"
                  :key="index"
                >
                  <td>{{ rateio.centrocusto.nome }}</td>
                  <td class="text-right">{{ rateio.porcentagem }} %</td>
                  <td style="width: 30px;">
                    <q-btn
                      color="red"
                      dense
                      flat
                      icon="delete"
                      @click="movcontas_rateios.splice(index, 1)"
                    />
                  </td>
                </tr>
                <tr v-if="movcontas_rateios.length > 0">
                  <td colspan="2"><strong>Total</strong></td>
                  <td class="text-right">{{ porcentagemMovcontasRateioTotal }} %</td>
                </tr>
                <tr v-if="movcontas_rateios.length === 0">
                  <td class="text-center" colspan="3">Nenhum rateio</td>
                </tr>
                </tbody>
              </q-markup-table>
            </div>

          </div>

        </div>
      </q-card-section>

      <q-card-actions class="justify-between q-px-lg q-pb-md">
        <q-btn
          v-if="exibirIgnorarDiferenca"
          color="red"
          label="Ignorar diferença"
          @click="ignorarDiferenca()"
        />
        <div v-else></div>

        <q-btn
          :disable="loadSave"
          :loading="loadSave"
          class="bg-positive text-white"
          clickable
          flat
          label="Salvar & Conciliar"
          @click="addConciliar()"
        >
          <template v-slot:loading>
            <q-spinner color="grey-10" size="1em" />
          </template>
        </q-btn>
      </q-card-actions>

    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { bus } from "@/main";
import { Money } from "v-money";


export default {
  name: "modal-lancar-diferenca",
  components: { Money },
  data() {
    return {
      isTransferencia: false,
      conta: {
        planodeconta_id: null,
        conta_id: null
      },
      options: [],
      planodecontas: [],
      planoContasFilterResult: [],
      cadastro: {},
      loadSave: false,
      errors: {},
      // Opções Avançadas
      opcoesAvancadas: false,
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false
      },
      newMovcontaRateio: {
        centrodecusto: null,
        porcentagem: 0
      },
      movcontas_rateios: []
    };
  },
  methods: {
    closeDialog() {
      this.$store.commit("OpenDialogLancarDiferenca", false);
      this.$store.commit("OpenDialogSearch", false);
    },
    reset() {
      // Emitir evento para o componente pai, saber que deve atualizar a lista de extratos importado
      bus.$emit("atualizarConciliacao", true);
      this.$store.commit("selectedExtratos", []);

      // Resetar variaveis
      this.loadSave = false;
      this.isTransferencia = false;
      this.cadastro = {};
      this.conta = {
        planodeconta_id: null,
        conta_id: null
      };
      this.errors = {};
      this.movcontas_rateios = [];
      this.opcoesAvancadas = false;
      this.newMovcontaRateio = {
        centrodecusto: null,
        porcentagem: 0
      };

      // Fechar modal
      this.closeDialog();
    },
    // Funções do autocomplete
    filter(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        axios.get("/cadastros/busca?term=" + val).then(res => {
          this.options = res.data;
        });
      });
    },
    createValue(val, done) {
      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
    },
    formatDisplay(result) {
      return result.label;
    },
    resultEmitRecebe(result) {
      this.cadastro = result;
      this.$store.commit("cadastroReturn", null);

      if (this.cadastro && this.cadastro.id != null) {
        this.$q.notify({
          message: this.cadastro.name + " foi selecionado.",
          color: "green",
          position: "top-right"
        });
      }
    },
    displayMontar(value) {
      return !value ? value : value.name;
    },
    abreCadastro(value) {
      this.$store.dispatch("cadastro", {
        nome: value.name,
        noSearch: true
      });
    },
    planoFilter(val, update) {
      update(() => {
        this.planoContasFilterResult = this.planodecontas.filter(
          plano => plano.titulo.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
    planodeContasFilter(tipo = null) {
      axios
        .get("/plano-contas", {
          params: {
            cd: tipo
          }
        })
        .then(res => {
          if (res.data.success) {
            this.planodecontas = res.data.planodecontas;
          }
        });
    },
    addConciliar() {
      this.loadSave = true;
      let cadastro = false;
      let data = {
        action: this.selectedExtrato.isDiferenca ? "diferenca" : "adicionar",
        extrato_id: this.selectedExtrato.id,
        cadastro_id: null
      };

      if (this.cadastro && this.cadastro.id) {
        data.cadastro_id = this.cadastro.id;
        cadastro = true;
      } else {
        let newCadastro = {
          nome: this.cadastro.name,
          fornecedor: true
        };

        axios
          .post("/cadastros/add", newCadastro)
          .then(res => {
            if (res.data.success) {
              data.cadastro_id = res.data.data.cadastro.id;
              this.errors = false;
              cadastro = true;
            } else {
              this.errors = res.data.errors;
              this.loadSave = false;
              cadastro = false;
              this.cadastro.name = "";
            }
          })
          .catch(() => {
            this.$q.notify({
              message: "Não foi possivel ignorar lançamento!",
              color: "red",
              position: "top-right"
            });
          });
      }

      if (this.conta.planodeconta_id !== null) {
        data.planodeconta_id = this.conta.planodeconta_id;
      }

      if (this.isTransferencia && this.conta.conta_id !== null) {
        data.conta_transferencia_id = this.conta.conta_id.id;
      }

      if (this.exibirCentrosdecusto) {
        data.movcontas_rateios = this.movcontas_rateios.map(rateio => ({
          porcentagem: rateio.porcentagem,
          centrocusto_id: rateio.centrocusto.id
        }));
      }

      if (this.selectedExtrato.fromConciliacao) {
        data.nao_conciliar = true;
        data.extratos_ids = this.selectedExtrato.dadosConciliacao.extratos_ids;
        data.movcontas_ids = this.selectedExtrato.dadosConciliacao.movcontas_ids;
      }
      const { arquivo_id, conta_id } = this.$route.query;

      let transferencia = 0;
      if (this.isTransferencia) {
        transferencia = 1;
      }

      if (cadastro) {
        axios
          .post(`financeiro/conciliar/${conta_id}?arquivo_id=${arquivo_id}&transferencia=${transferencia}`, data)
          .then(res => {
            this.loadSave = false;

            this.$q.notify({
              message: res.data.message,
              color: res.data.success ? "green" : "orange",
              position: "top-right"
            });

            if (!res.data.success) {
              this.errors = res.data.errors || {};
              return;
            }

            if (this.selectedExtrato.fromConciliacao) {
              this.selectedExtrato.dadosConciliacao.movcontas_ids.push(res.data.movconta.id);
              bus.$emit("conciliar", this.selectedExtrato.dadosConciliacao);
            }

            this.reset();
          }).catch(() => {
          this.$q.notify({
            message: "Não foi possivel ignorar lançamento!",
            color: "red",
            position: "top-right"
          });

          this.loadSave = false;
        });
      }
    },
    init() {
      if (this.selectedExtrato) {
        let tipo = this.selectedExtrato.valor >= 0 ? "C" : "D";
        this.planodeContasFilter(tipo);
      }
      this.errors = {};
    },
    addMovcontaRateio() {
      this.errors.porcentagem = null;
      this.errors.centrodecusto = null;

      if (!this.newMovcontaRateio.centrodecusto) {
        this.errors.centrodecusto = "Centro de custo é obrigatório";
        return;
      }

      if (!this.newMovcontaRateio.porcentagem) {
        this.errors.porcentagem = "Porcentagem é obrigatória";
        return;
      }

      if (this.porcentagem < 0 || this.porcentagem > 100) {
        this.errors.porcentagem = "Valor inválido";
        return;
      }

      const rateioIndex = this.movcontas_rateios.findIndex(rateio => rateio.centrocusto.id === this.newMovcontaRateio.centrodecusto.id);

      const rateio = {
        porcentagem: Math.abs(Number(this.newMovcontaRateio.porcentagem)),
        centrocusto: {
          id: this.newMovcontaRateio.centrodecusto.id,
          nome: this.newMovcontaRateio.centrodecusto.nome
        }
      };

      if (rateioIndex === -1) {
        this.movcontas_rateios.push(rateio);
      } else {
        this.movcontas_rateios[rateioIndex] = rateio;
      }

      this.newMovcontaRateio = {
        centrodecusto: null,
        porcentagem: 0
      };
    },
    ignorarDiferenca() {
      this.selectedExtrato.dadosConciliacao.confirmar_diferenca = true;
      bus.$emit("conciliar", this.selectedExtrato.dadosConciliacao);

      this.reset();
    }
  },
  computed: {
    ...mapGetters([
      "selectedExtrato",
      "contas",
      "cadastroReturn",
      "centroCustos",
      "OpenDialogLancarDiferenca"
    ]),
    exibirCentrosdecusto() {
      return this.selectedExtrato && this.selectedExtrato.valor < 0;
    },
    hasOpcoesAvancadas() {
      return this.exibirCentrosdecusto;
    },
    optionsCentrosdecusto() {
      if (!this.exibirCentrosdecusto) {
        return [];
      }

      return this.centroCustos.filter(centroCusto => {
        return !(this.movcontas_rateios.find(rateio => rateio.centrocusto.id == centroCusto.id));
      });
    },
    porcentagemMovcontasRateioTotal() {
      if (!this.exibirCentrosdecusto) {
        return 0.00;
      }

      return this.movcontas_rateios.reduce((total, rateio) => total + Math.abs(Math.abs(Number(rateio.porcentagem))), 0);
    },
    exibirIgnorarDiferenca() {
      return this.selectedExtrato && this.selectedExtrato.isDiferenca && this.selectedExtrato.fromConciliacao
        && this.selectedExtrato.dadosConciliacao && this.selectedExtrato.dadosConciliacao.extratos_ids.length === 1;
    }
  },
  watch: {
    OpenDialogLancarDiferenca() {
      if (!this.OpenDialogLancarDiferenca) return;

      this.init();
    }
  },
  mounted() {
    this.$store.dispatch("listarCentroCustos");
  }
};
</script>
