<template>
  <div class="text-center">
    <q-icon size="10em" name="info_outline" color="grey" />
    <br />
    <p style="font-weight: bold;font-size: 25px" class="text-grey">Sem Permissão</p>
    <p>Algo inesperado aconteceu, ou você não tem permissão para acessar este local.</p>
  </div>
</template>

<script>
export default {
  name: 'card-section-sem-permissao',
};
</script>
