<template>
  <div id="usuarios-parametros">
    <q-table
      title
      :data="cadastros"
      :columns="columns"
      row-key="id"
      :separator="separator"
      :loading="buscando"
      no-data-label="Nenhum resultado encontrado."
      :pagination.sync="pagination"
    >
      <template v-slot:bottom>
        <div></div>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
          <q-td key="ativo" class="text-center" >
            <q-icon
              :name="props.row.user.ativo ? 'check' : 'close'"
              size="24px"
              :class="props.row.user.ativo ? 'text-positive' : 'text-negative'"
            ></q-icon>
          </q-td>
          <q-td key="nome"><router-link style="text-decoration: none; color: black" :to="'/cadastros/' + props.row.id">{{props.row.user.nome}}</router-link></q-td>
          <q-td key="email">{{props.row.user.email}}</q-td>
          <q-td key="confirmado" class="text-center">
            <q-icon
              :name="props.row.user.confirmado ? 'check' : 'close'"
              size="24px"
              :class="props.row.user.confirmado ? 'text-positive' : 'text-negative'"
            ></q-icon>
          </q-td>
          <q-td key="created" class="text-center">{{props.row.user.created | formatDate}}</q-td>
          <q-td key="modified" class="text-center">{{props.row.user.modified | formatDate}}</q-td>
          <q-td v-if="$q.platform.is.desktop" class="tdfora">
            <div class="divfora">
              <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                <q-btn
                  @click="modalAcessosLogs(props.row.user)"
                  size="12px"
                  class="bg-positive text-white"
                  icon="info"
                ></q-btn>
                <q-btn
                  @click="modal(props.row.user)"
                  size="12px"
                  class="bg-primary text-white"
                  icon="edit"
                ></q-btn>
                <q-btn
                  @click="excluir(props.row.user)"
                  class="bg-negative text-white"
                  icon="block"
                  size="12px"
                ></q-btn>
              </div>
            </div>
          </q-td>
        </q-tr>
        <q-tr v-show="props.expand" :props="props">
          <q-td colspan="100%">
            <div class="q-py-sm q-gutter-sm">
              <q-btn
                @click="modalAcessosLogs(props.row.user)"
                size="12px"
                class="bg-positive text-white"
                icon="info"
              />
              <q-btn
                @click="modal(props.row.user)"
                size="12px"
                class="bg-primary text-white"
                icon="edit"
              />
              <q-btn
                @click="excluir(props.row.user)"
                class="bg-negative text-white"
                icon="block"
                size="12px"
              />
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog v-model="OpenDialogEditUser" persistent>
      <q-card style="width: 900px; max-width: 80vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">{{dataArray.funcionario ? dataArray.funcionario.user.nome : ''}}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="row">
              <div class="col-12 col-md-12">
                <q-select
                  v-if="dataArray.funcionario"
                  v-model="dataArray.funcionario.cargo"
                  :options="dataArray.cargos"
                  label="Cargos"
                />
              </div>
              <div class="col-12 col-md-12">
                <p class="q-mt-lg cursor-pointer" v-if="!visualizarToken" @click="viewToken">Clique para visualizar o token de API!</p>
                <q-input
                  v-if="visualizarToken"
                  v-model="apiTokenFormatted"
                  label="Token de API"
                  readonly
                  :loading="generatingToken"
                  hint="Envie esse Token no Header Authorization"
                >
                  <template v-slot:append>
                    <q-btn
                      flat
                      round
                      dense
                      icon="fa fa-clipboard"
                      size="12px"
                      @click="copyText(apiTokenFormatted, 'Token', 'o')"
                    >
                      <q-tooltip>
                        Copiar Token
                      </q-tooltip>
                    </q-btn>
                    <q-btn
                      flat
                      round
                      dense
                      class="q-ml-sm"
                      icon="fa fa-refresh"
                      size="12px"
                      @click="confirmGenerateToken"
                    >
                      <q-tooltip>
                        Atualizar Token
                      </q-tooltip>
                    </q-btn>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-list>
                      <q-item-label class="row items-right q-mt-lg">
                        <q-toggle
                          color="green"
                          label="Sim para todas as permissões"
                          checked-icon="check"
                          unchecked-icon="clear"
                          v-model="modelTodasPermissoes"
                        />
                      </q-item-label>

                  <div
                    v-for="(possibilidades, grupo) in listaPermissoesPorGrupo"
                    :key="grupo"
                  >
                    <q-separator spaced v-if="grupo !== primeiroGrupo" />


                    <q-item-label>
                      <span><strong>{{ grupo }}</strong></span>
                      <q-toggle
                        color="green"

                        label="Sim para todos"
                        checked-icon="check"
                        unchecked-icon="clear"
                        :val="grupo"
                        v-model="modelGrupo"
                        @input="() => trocaPermissaoGrupo(grupo)"
                      />
                    </q-item-label>


                    <q-item
                      v-ripple
                      class="row justify-between items-center"

                      v-for="(possibilidade, value) in possibilidades"
                      :key="value"
                    >
                      <q-item-section>
                        <q-item-label>{{possibilidade.label}}</q-item-label>
                        <q-item-label caption>{{possibilidade.descr}}</q-item-label>
                      </q-item-section>

                      <q-item-section>
                        <div class="content">
                          <div class="col"  v-if="possibilidade.label=='Extrato'">
                            <q-select
                                v-model="contasPermitidas"
                                filled
                                multiple
                                stack-builder
                                :options="contas"
                                option-label="titulo"
                                option-value="id"
                                map-options
                                emit-value
                                use-chips
                                label="Contas Permitidas"
                            >

                              <template v-slot:no-option>
                                <q-item>
                                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                                </q-item>
                              </template>
                            </q-select>

                          </div>
                        </div>
                      </q-item-section>

                      <q-item-section side>
                        <q-toggle
                          color="green"
                          label="Sim"
                          checked-icon="check"
                          unchecked-icon="clear"
                          :val="value"
                          v-model="model"
                        />
                      </q-item-section>
                    </q-item>
                  </div>
                </q-list>

              </div>
              <div class="col-12 col-md-12">
                <br />
                <br />
                <br />
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  :loading="saveEdit"
                  @click="salvar()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <modal-acessos-logs 
      :openDialog="openDialogAcessosLogs" 
      :user="userAcessosLogs"
      @update:openDialog="closeDialogAcessosLogs" 
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "@/main";
import axios from "@/axios-auth";
import { Loading, copyToClipboard, Dialog } from "quasar";

export default {
  name: "usuarios-parametros",
  data() {
    return {
      model: [],
      modelGrupo: [],
      contasPermitidas: [],
      separator: "horizontal",
       pagination: {
        rowsPerPage: 0
      },
      columns: [
        {
          name: "ativo",
          required: true,
          label: "Ativo",
          align: "center",
          field: row => row.ativo,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "nome",
          align: "left",
          label: "Nome",
          field: row => row.nome,
          sortable: true
        },
        {
          name: "email",
          label: "E-mail",
          field: row => row.email,
          sortable: true,
          align: "left"
        },
        {
          name: "confirmado",
          label: "Confirmado",
          field: row => row.confirmado,
          align: "center",
          sortable: true
        },
        {
          name: "created",
          label: "Criado",
          field: row => row.created,
          align: "center",
          sortable: true
        },
        {
          name: "modified",
          label: "Modificado",
          field: row => row.modified,
          align: "center",
          sortable: true
        }
      ],
      loadEdit: false,
      buscando: false,
      cadastros: [],
      OpenDialogEditUser: false,
      dataArray: {},
      saveEdit: false,
      visualizarToken: false,
      generatingToken: false,
      apiToken: "",
      openDialogAcessosLogs: false,
      acessosLogs: null,
      userAcessosLogs: null,
    };
  },
  methods: {
    listar() {
      this.buscando = true;
      axios
        .get("/users")
        .then(res => {
          if (res.data.success) {
            this.cadastros = res.data.data.cadastros;
          }
          this.buscando = false;
        })
        .catch(() => {
          this.buscando = false;
        });
    },
    listarContas(){
      this.$store.dispatch('contas');
    },
    excluir(value) {
      this.$q.dialog({
        title:"Confirma a exclusão?",
        message: "Ao excluir o usuário <b>" + value.nome +" - "+  value.email + "</b> ele deixará de ter acesso ao sistema."   ,
        cancel: "Cancelar",
        persistent: true,
        html: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios.post("/users/delete/" + value.id).then(res => {
            if (res.data.success) {
              this.listar();
            }
          });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    salvar() {
      this.saveEdit = true;
      let dados = {
        cargo: this.dataArray.funcionario.cargo,
        permissoes: this.model,
        contasPermitidas: this.contasPermitidas
      };
      axios
        .post("/users/edit/" + this.dataArray.funcionario.user.id, dados)
        .then(res => {
          if (res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            this.listar();
            this.OpenDialogEditUser = false;
          } else {
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          this.saveEdit = false;
        })
        .catch(() => {
          this.$q.notify({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.saveEdit = false;
        });
    },
    modal(value) {
      this.visualizarToken = false;
      this.dataArray = {};
      this.loadEdit = true;

      axios
        .get("/users/edit/" + value.id)
        .then(res => {
          this.loadEdit = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            return;
          }

          this.dataArray = res.data.data;
          this.model = res.data.data.permissoes;
          this.contasPermitidas = res.data.data.contasPermitidas;
          this.apiToken = res.data.data.token_api;
          //this.$store.dispatch("editUsers", this.dataArray);
          this.OpenDialogEditUser = true;

          this.updateModelGrupos();
          const funcionario = this.dataArray.funcionario;
          if (funcionario.user || funcionario.user.api_key_plain) {
            this.apiToken = funcionario.user.api_key_plain;
          }
        })
        .catch(() => {
          this.$q.notify({
            message: "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.loadEdit = false;
        });
    },
    closeModal() {
      this.model = [];
      this.contasPermitidas = [];
      this.dataArray = [];
      this.OpenDialogEditUser = false;
      this.generatingToken = false;
      this.apiToken = "";
      this.visualizarToken = false;
    },
    viewToken(){
      this.visualizarToken = true;
    },
    /**
     * Percorre todos os grupos, e adiciona ao modelo de grupos todos os que
     * possuem todas as posições nas permissões
     */
    updateModelGrupos() {
      const modelGrupo = [];

      for (const grupo in this.listaPermissoesPorGrupo) {
        const permissoesGrupo = this.listaPermissoesPorGrupo[grupo];
        let temPermissao = true;

        for (const permissaoValue in permissoesGrupo) {
          if (!this.model.includes(permissaoValue)) {
            temPermissao = false;
          }
        }

        if (temPermissao) {
          modelGrupo.push(grupo);
        }
      }

      this.modelGrupo = modelGrupo;
    },
    trocaPermissaoGrupo(grupo) {
      const permissoesGrupo = this.listaPermissoesPorGrupo[grupo];
      const marcado = this.modelGrupo.includes(grupo);

      if (!marcado) {
        for (const permissaoValue in permissoesGrupo) {
          const permissaoIndex = this.model.findIndex(permissao => permissao === permissaoValue);
          if (permissaoIndex !== -1) {
            this.model.splice(permissaoIndex, 1);
          }
        }

        return;
      }

      for (const permissaoValue in permissoesGrupo) {
        if (!this.model.includes(permissaoValue)) {
          this.model.push(permissaoValue);
        }
      }
      return;
    },
    confirmGenerateToken() {
      Dialog.create({
        title: "Confirma a atualização do token?",
        cancel: "Cancelar",
        persistent: true
      })
      .onOk(() => {
        this.generateToken();
      });
    },
    generateToken() {
      if (this.generatingToken) return;
      this.generatingToken = true;

      axios
        .post("/users/edit/" + this.dataArray.funcionario.user.id+"?newApiToken=1")
        .then(res => {
          this.generatingToken = false;
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            return;
          }

          this.$q.notify({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          this.apiToken = res.data.api_token;
        });
    },
    copyText(text, type, artigo) {
      copyToClipboard(text)
        .then(() => {
          this.$q.notify({
            message: `${type} Copiad${artigo}!`,
            color: "green",
            position: "top-right",
          });
        });
    },
    async modalAcessosLogs(user) {
        this.userAcessosLogs = user;
        this.openDialogAcessosLogs = true;
    },
    closeDialogAcessosLogs() {
      this.openDialogAcessosLogs = false;
      this.acessosLogs = null;
      this.userAcessosLogs = null;
    },
  },
  computed: {

    /**
     * Agrupa as permissões por grupo em um objeto
     */
    listaPermissoesPorGrupo() {
      const permissoes = {};
      const possibilidades = this.dataArray.possibilidades || {};

      for (const permissao in possibilidades) {
        const possibilidade = possibilidades[permissao];

        if (!permissoes[possibilidade.grupo]) {
          permissoes[possibilidade.grupo] = {};
        }

        permissoes[possibilidade.grupo][permissao] = possibilidade;
      }

      return permissoes;
    },
    primeiroGrupo() {
      if (!Object.keys(this.listaPermissoesPorGrupo)[0]) {
        return null;
      }

      return Object.keys(this.listaPermissoesPorGrupo)[0];
    },
    apiTokenFormatted() {
      if (!this.apiToken) return "";
      return "Basic " + window.btoa(this.apiToken);
    },
    modelTodasPermissoes: {
      get() {
        for (const permissao in this.dataArray.possibilidades) {
          if (!this.model.includes(permissao)) {
            return false;
          }
        }

        return true;
      },
      set(value) {
        if (!value) {
          this.model = [];
          return;
        }

        this.model = Object.keys(this.dataArray.possibilidades);
      },
    },
    ...mapGetters([
      "contas"
    ]),

  },
  watch: {
    model() {
      this.updateModelGrupos();
    }
  },
  mounted() {
    this.listar();
  },
  created() {
    bus.$on("atualizarListaUsuario",() => {
      this.listar();
    });
    this.listarContas();
  },

};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.border-top {
  border-top: 1px solid #ddd;
  padding-top: 5px;
}
</style>
