<template>
  <div id="modalUploadResumoTfl">
    <q-dialog v-model="OpenDialogUploadResumoCaixas" v-if="!($q.platform.is.mobile && movmentacao)" full-width persistent>
      <q-card style="width: auto !important">
        <div class="modal">
          <q-card-section class="row items-center">
            <div class="text-h6">{{ title }} <span v-if="reviewingFile" class="text-h6">- {{ reviewingFile.nome }} </span> </div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup @click="close()" />
          </q-card-section>

          <q-separator />

          <div class="row">
            <div class="col-12 col-md">
              <q-card-section>
                <div class="q-col-gutter-md row">
                  <div class="col-12 text-center" v-if="!$q.platform.is.mobile">
                    <qrcode-vue v-if="urlQrcode != null" :value="urlQrcode" :size="size" level="L"></qrcode-vue>
                  </div>
                  <div class="q-uploader" style="width: 100%; box-shadow: none;overflow:hidden" @dragover="onDragover"
                    @drop="onDrop" @dragleave="onDragleave" @mouseover="onHover" @mouseleave="offHover">
                    <div class="q-uploader__header">
                      <div class="header-upload">
                        <div v-if="!movmentacao" :class="!$q.platform.is.mobile ? 'layout' : 'layout-responsive'">
                          <div v-if="!$q.platform.is.mobile">
                            <div class="q-uploader__title">Selecione um arquivo</div>
                          </div>
                          <div class="icones-layout">
                            <label for="file" v-if="$q.platform.is.mobile">
                              <i class="material-icons" style="font-size: 20px; cursor: pointer;">add_a_photo</i>
                              <input accept="image/*" capture="camera" type="file" id="file" style="width: 0" class="file"
                                @change="onFileSelected" />
                            </label>
                            <label>
                              <i class="material-icons" style="font-size: 20px; cursor: pointer">
                                cloud_upload
                              </i>
                              <input type="file" id="file" style="width: 0" class="file" accept="image/*"
                                @change="onFileSelected" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!movmentacao" class="q-uploader__list scroll" style="border: 1px solid #ccc;">
                      <div v-for="(file, i) in uploads" :key="file.name" style="margin-bottom: 8px">
                        <div class="q-uploader__file relative-position">
                          <div class="q-uploader__file-header row flex-center no-wrap">
                            <i v-if="file.error" aria-hidden="true"
                              class="material-icons q-icon text-negative q-uploader__file-status">warning</i>
                            <div class="q-uploader__file-header-content col" v-if="!file.processing" style="cursor: pointer" @click="reviewItem(file)">
                              <div class="q-uploader__title">{{ file.fileName }}</div>
                              <div class="q-uploader__subtitle row items-center no-wrap">{{ file.fileSize }} /
                                {{ file.progress }}</div>
                            </div>
                            <q-btn round dense flat icon="clear" @click="apagaItem(i)" :loading="file.uploading">
                              <template v-slot:loading>
                                <q-spinner color="grey-10" size="1em" />
                              </template>
                            </q-btn>
                          </div>
                        </div>
                      </div>
                      <q-list dense bordered separator>
                        <q-item v-for="(arquivo, i) in arquivos" :key="i" clickable v-ripple :active="(reviewingFile !== null) && (reviewingFile.id == arquivo.id)">
                          <q-item-section @click="reviewItem(arquivo)">{{arquivo.nome}}</q-item-section>
                          <q-btn round dense flat icon="clear" :loading="carregando && carregandoImgId == arquivo.id" @click="apagaItem(arquivo)">
                              <template v-slot:loading>
                                <q-spinner color="grey-10" size="1em" />
                              </template>
                            </q-btn>
                        </q-item>
                      </q-list>
                    </div>
                  </div>
                </div>
              </q-card-section>
            </div>

            <div class="col-10 col-md-9 q-mt-md" v-if="reviewingFile">
              <q-card-section class="q-pa-none">
                <div>
                  <img id="reviewingImage" :style="resumoImgWidth" :src="reviewingFile.server_url" @load="setDimensoesImagem"  />
                  <div v-if="isImgLoaded" style="width: 1200px;">
                    <div v-for="block in reviewingFile.raw" :key="block.id" :style="calculaArea(block)" @click="toggleBloco(block)" :id="block.Id">
                      <div v-if="isAtivo(block)" style="margin-left: 576px; margin-top: -16px; font-size: 14px; user-select: none;">
                        <pre>{{ block.Text }}</pre>
                      </div>
                    </div>
                  </div>
                  <div v-else class="row flex-center q-ma-lg">
                    <q-spinner color="grey-10" size="5em" />
                  </div>
                </div>
              </q-card-section>
            </div>
          </div>
          <q-card-actions v-if="!movmentacao && movcaixa.status === 'A'" align="center" class="text-primary q-mb-md">
            <q-btn flat label="Salvar processamento e Tirar nova foto" clickable @click="enviaDadosOCR"
              :disable="disableButton" class="bg-primary  text-white q-ma-xs">
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
            <q-btn flat label="Salvar processamento" clickable @click="enviaDadosOCR(false)" :disable="disableButton"
              class="bg-green text-white q-ma-xs">
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
          </q-card-actions>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { Notify, Dialog } from "quasar";
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
import QrcodeVue from 'qrcode.vue';

export default {
  name: "modal-upload-resumo-tfl",
  props: ['movmentacao', 'title'],
  components: {
    QrcodeVue
  },
  data() {
    return {
      size: 200,
      ocupado: "",
      percent: null,
      tamanho: "",
      errors: [],
      selectedFile: null,
      uploadProgress: "",
      uploading: false,
      success: true,
      porcentagem: 0,
      fileName: "",
      fileSize: null,
      uploads: [],
      carregando: false,
      carregandoImgId: null,
      downloading: false,
      interval: '',
      arquivos: [],
      loadingArquivos: false,
      reviewingFile: null,
      blocosMapeados: [],
      blocosAlterados: [],
      isImgLoaded: false,
      dimensoesImagem: null,
    };
  },
  computed: {
    ...mapGetters(["imgOCRId", "caixaId", "urlQrcode", "despesaQrCode", "movcaixa"]),
    imagemAtribuida() {
      let message = "";
      if (this.imgOCRId) message = "Caixa com Resumo";
      else message = "Caixa sem Resumo";

      return message;
    },
    defineColor() {
      let color = "";
      if (this.imgOCRId) color = "background-color: #4CAF50; color: white;";
      else color = "background-color: #F44336; color: white;";

      return color;
    },
    disableButton() {
      return (this.carregando || this.uploading) || this.arquivos.length == 0;
    },
    resumoImgWidth() {
      if (this.$q.screen.width > 700) {
        return "width: 600px;";
      }

      if (this.$q.screen.width > 600) {
        return "width: 500px;";
      }

      if (this.$q.screen.width > 500) {
        return "width: 400px;";
      }

      return "width: 300px;";
    },
    OpenDialogUploadResumoCaixas: {
      get() {
        return this.loadQrcode();
      },
      set() {}
    }
  },
  methods: {
    close(){
      if (this.blocosAlterados.length > 0) {
        Dialog.create({
          title: "Sair dessa tela sem processar o resumo TFL fará com ele seja descartado.",
          message: "Tem certeza que quer prosseguir?",
          cancel: "Cancelar",
          persistent: false
        })
          .onOk(() => {
            this.$store.commit('OpenDialogUploadResumoCaixas', false);
            let array = this.uploads;
            this.apagaTodos();
            for (let i = 0; i < array.length; i++) {
              axios
                .post("/lancamentos/delete-resumo-tfl/" + this.caixaId, { arquivo_id: array[i].id })
                .catch(() => {
                  reject();
                });
            }
          })
          .onCancel(() => { });
        return;
      }

      this.$store.commit('OpenDialogUploadResumoCaixas', false);
      this.apagaTodos();
    },
    loadQrcode(){
      if (this.$store.getters.OpenDialogUploadResumoCaixas && this.$q.platform.is.desktop) {
        this.$store.dispatch("getTokenQrcode", true);
      }
      return this.$store.getters.OpenDialogUploadResumoCaixas;
    },
    dizsize() {
      this.carregando = true;
      axios
        .get("/arquivos/dirsize")
        .then(res => {
          if (res.data.success) {
            this.ocupado = res.data.data.ocupado;
            this.percent = res.data.data.percent;
            this.tamanho = res.data.data.tamanho;
          }
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    apagaItem(arquivo) {
      if (!arquivo) return;

      // Se o arquivo que está sendo visualizado é excluído, a visualização é encerrada
      Dialog.create({
        title: "Deseja mesmo excluir?",
        cancel: "Cancelar",
        persistent: false
      })
        .onOk(() => {
          if (this.reviewingFile != null && arquivo.id === this.reviewingFile.id) {
            this.fechaVisualizacao();
          }

          for (let i = 0; i < arquivo.length; i++) {
            if (arquivo.uploading) {
              return;
            }
          }
          let id = arquivo.id;
          let params = {
            arquivo_id: id
          };
          this.carregando = true;

          axios
            .post("/lancamentos/delete-resumo-tfl/" + this.caixaId, params)
            .then(() => {
              this.carregando = false;

              const index = this.arquivos.indexOf(arquivo);
              this.arquivos.splice(index, 1);

              if (id === this.imgOCRId) {
                this.$store.commit("imgOCRId", null);
              }
            })
            .catch(() => {
              this.carregando = false;
              reject();
            });
        });
    },
    offHover() {
      event.preventDefault();
    },
    onHover() {
      event.preventDefault();
    },
    onDragleave() {
      event.preventDefault();
    },
    onDragover() {
      event.preventDefault();
    },
    onDrop(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.uploads.length >= 1) {
        this.uploads.pop();
      } // mantem somente uma notificação de upload de arquivo
      var files = event.dataTransfer.files;
      this.selectedFile = files;
      let tam = this.uploads.length;
      let j;
      // neste componente somente é aceito upload de 1 arquivo por vez, por isso o tamanho de selected file sempre será 1, mas este bloco será mantido caso seja necessário no futuro
      for (let i = 0; i < this.selectedFile.length; i++) {
        this.uploads.push({
          fileName: this.selectedFile[i].name,
          fileSize: this.bytesToSize(this.selectedFile[i].size),
          success: false,
          error: false,
          message: "",
          progress: "0%",
          uploading: false
        });
        j = tam + i;
        this.onUpload(this.selectedFile[i], this.uploads[j]);
      }
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    percentage() {
      return "width: " + this.percent + "%";
    },
    onFileSelected(event) {
      // this.selectedFile = []
      if (this.uploads.length >= 1) {
        this.uploads.pop();
      } // mantem somente uma notificação de upload de arquivo
      this.selectedFile = event.target.files;
      let tam = this.uploads.length;
      let j;
      // neste componente somente é aceito upload de 1 arquivo por vez, por isso o tamanho de selected file sempre será 1, mas este bloco será mantido caso seja necessário no futuro
      for (let i = 0; i < this.selectedFile.length; i++) {
        this.uploads.push({
          fileName: this.selectedFile[i].name,
          fileSize: this.bytesToSize(this.selectedFile[i].size),
          success: false,
          error: false,
          message: "",
          progress: "0%",
          uploading: false,
          delivered: false
        });
        j = tam + i;
        this.onUpload(this.selectedFile[i], this.uploads[j]);
      }
    },
    apagaTodos() {
      this.uploads = [];
      this.arquivos = [];
      this.fechaVisualizacao();
    },
    onUpload(arq, arr) {
      if (!arq.name) {
        arq.name = "Imagem";
      }
      arr.uploading = true;

      const fd = new FormData();
      fd.append("file", arq, arq.name);
      fd.append("is_resumo", 1);
      if (this.despesaQrCode){
        fd.append("movconta_id", this.despesaQrCode);
      } else {
        fd.append("movcaixa_id", this.$route.params.id);
      }

      axios
        .post(axios.defaults.baseURL + "/arquivos/upload", fd, {
          onUploadProgress: uploadEvent => {
            arr.progress =
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
          }
        })
        .then(async res => {
          if (res.data.success) {
            arr.message = res.data.message;

            // Processa o OCR da imagem
            await this.processaOCR(res.data.arquivo, false);

            if (this.despesaQrCode){
              this.$store.commit("pushReceitaArquivo", res.data.arquivo);
            } else {
              this.$store.commit("imgOCRId", res.data.arquivo.id);
            }
          } else {
            arr.error = true;
            arr.message = res.data.message;
            Notify.create({
              message: arr.message + "  " + arr.fileName,
              color: "orange",
              position: "top-right"
            });
          }

          arr.success = res.data.success;
          arr.uploading = false;
          arr.delivered = true;

          this.arquivos.unshift(res.data.arquivo);
          this.uploads.pop();
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível fazer o upload do arquivo" +
              arr.fileName +
              ", ou você não possui permissão para isto.",
            color: "red",
            position: "top-right"
          });
          arr.success = false;
          arr.error = true;
          arr.uploading = false;
          arr.progress = "0%";
        });
    },
    async enviaDadosOCR(novo = true) {
      let arquivo = this.arquivos.find(arquivo => arquivo.id === this.imgOCRId);
      if (!arquivo || !arquivo.raw) return;

      this.carregando = true;
      this.carregandoImgId = arquivo.id;
      // Filtra somente os textos ativos para enviar para processar os saldos
      this.blocosMapeados.forEach(bloco => {
        let index = arquivo.raw.findIndex(b => b.Id === bloco.id);
        arquivo.raw[index].ativo = bloco.ativo;
      });

      let dadosImg = {
        save_ocr: true,
        caixa_id: this.caixaId,
        arquivo_id: arquivo.id,
        raw: arquivo.raw
      };
      this.$store
        .dispatch("enviaDadosImg", dadosImg)
        .then(() => {
          this.$store
            .dispatch("buscaCaixa", this.caixaId)
            .then(() => {
              this.carregando = false;
              this.carregandoImgId = null;
            });

          const imgData = this.$store.getters.enviaDadosImgData;
          if (!imgData.success) {
            Notify.create({
              message: imgData.message || "Ocorreu um erro ao processar a Imagem.",
              color: "red",
              position: "top-right"
            });
            return;
          }

          this.blocosAlterados = [];
          if (!novo) {
            this.close();
            return;
          }
          this.apagaTodos();
          this.recuperarResumosCaixa();
        })
        .catch(() => {
          Notify.create({
            message:
              "Ocorreu um erro desconhecido. Tente novamente ou envie outra imagem, caso o erro persista, por favor comunique nossa equipe.",
            color: "red",
            position: "top-right"
          });
          this.carregando = false;
          this.carregandoImgId = null;
        });
    },
    intervalGetToken() {
      if (this.OpenDialogUploadResumoCaixas) {
        this.interval = setInterval(() => {
          this.loadQrcode();
        }, 180000); // 3 minutos
      }
    },
    fechaVisualizacao() {
      this.reviewingFile = null;
      this.blocosMapeados = [];
      this.blocosAlterados = [];
      this.$store.commit("imgOCRId", null);
    },
    reviewItem(arquivo) {
      // Se já tiver alterado os blocos de texto, prompta o usuário de que as
      // alterações serão perdidas se fechar a revisão atual ou revisar outro arquivo
      if (this.blocosAlterados.length && this.reviewingFile && (this.reviewingFile.id !== arquivo.id)) {
        Dialog.create({
          title: "Alterações não salvas",
          message: "Deseja salvar as alterações feitas no arquivo atual?",
          ok: "Salvar",
          cancel: "Cancelar",
        })
          .onOk(() => {
            this.enviaDadosOCR()
              .then(() => {
                this.showReviewingItem(arquivo);
              });
          });
        return;
      }

      this.showReviewingItem(arquivo);
    },
    showReviewingItem(arquivo) {
      // Verifica se o arquivo a ser revisado é um recém enviado ou um enviado anteriormente
      if (!arquivo.raw) {
        this.processaOCR(arquivo);
        return;
      }

      this.blocosMapeados = [];
      this.isImgLoaded = false;
      arquivo.raw.forEach(bloco => this.blocosMapeados.push({ ativo: bloco.ativo, id: bloco.Id }));

      /* Verifica se já tem um arquivo sendo revisado. depois, verifica se o
      arquivo A SER revisado e o arquivo SENDO revisado é o mesmo. se sim,
      fecha a visualizacao*/
      if (this.reviewingFile && (arquivo.id == this.reviewingFile.id)) {
        this.fechaVisualizacao();
        return;
      }

      this.reviewingFile = arquivo;
      this.$store.commit("imgOCRId", this.reviewingFile.id);
    },
    setDimensoesImagem() {
      // Recupera as dimensoes originais da imagem pois o json de textract retorna o mapeamento
      // dos blocos proporcionalmente ao tamanho da imagem que foi processada
      const img = document.getElementById("reviewingImage");
      this.dimensoesImagem = {
        h: img.clientHeight,
        w: img.clientWidth
      };

      this.isImgLoaded = true;
    },
    async processaOCR(arquivo, openReview = true) {
      // Envia a imagem do bucket para o aws.
      // não salva reumo/resumo_log/saldo no bd, apenas o retorno do aws textract.
      this.carregando = true;
      this.carregandoImgId = arquivo.id;

      let dadosImg = {
        save_ocr: false,
        caixa_id: this.caixaId,
        arquivo_id: arquivo.id,
        raw: null
      };

      this.$store
        .dispatch("enviaDadosImg", dadosImg)
        .then(() => {
          const result = this.$store.getters.enviaDadosImgData;

          if (!result.success) {
            Notify.create({
              message: result.message || "Ocorreu um erro ao processar a Imagem.",
              color: "red",
              position: "top-right"
            });

            arquivo.raw = null;
            arquivo.delivered = false;
          } else {
            arquivo.raw = result.raw;
            arquivo.delivered = true;
          }

          arquivo.uploading = false;
          arquivo.processing = false;
          this.carregando = false;
          this.carregandoImgId = null;

          if (openReview && result.success) {
            this.reviewItem(arquivo);
          }
        })
        .catch(() => {
          Notify.create({
            message:
              "Ocorreu um erro ao processar a imagem. Tente novamente ou envie outra imagem, caso o erro persista, por favor comunique nossa equipe.",
            color: "red",
            position: "top-right"
          });
          this.carregando = false;
          this.carregandoImgId = null;
        });
    },
    calculaArea(block) {
      // Dinamicamente calcula a área da div de overlay para cada texto identificado conforme o retorno do textract
      if (this.dimensoesImagem) {
        let width = block.BoundingBox.Width * this.dimensoesImagem.w;
        let height = block.BoundingBox.Height * this.dimensoesImagem.h;
        let left = block.BoundingBox.Left * this.dimensoesImagem.w;
        let top = block.BoundingBox.Top * this.dimensoesImagem.h;

        let retornoStyle = {
          cursor: 'pointer',
          borderWidth: '2px',
          borderStyle: 'solid',
          position: 'absolute',
          width: '' + width + 'px',
          height: '' + height + 'px',
          left: '' + left + 'px',
          top: '' + top + 'px'
        };

        if (block.ativo) {
          retornoStyle.backgroundColor = 'rgba(66, 133, 244, 0.2)';
          retornoStyle.borderColor = 'rgb(66, 133, 244)';
        } else {
          retornoStyle.backgroundColor = 'rgba(255, 100, 100, 0.2)';
          retornoStyle.borderColor = 'rgb(255, 100, 100)';
        }

        return retornoStyle;
      }
    },
    toggleBloco(block) {
      if (this.movcaixa.status !== 'A') return;
      /*faz com que os textos identificados possam ser ativados ou desativados
      conforme o usuário queira (para possível missclick)*/
      let index = this.blocosMapeados.findIndex(b => block.Id === b.id);
      this.blocosMapeados[index].ativo = !this.blocosMapeados[index].ativo;

      let b = document.getElementById(block.Id);
      if (this.blocosMapeados[index].ativo) {
        b.style.backgroundColor = 'rgba(66, 133, 244, 0.2)';
        b.style.borderColor = 'rgb(66, 133, 244)';
      } else {
        b.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
        b.style.borderColor = 'rgb(255, 100, 100)';
      }

      if (!this.blocosAlterados.includes(this.blocosMapeados[index].id)) {
        this.blocosAlterados.push(this.blocosMapeados[index].id);
        return;
      }
      this.blocosAlterados.splice(this.blocosAlterados.indexOf(this.blocosMapeados[index].id), 1);
    },
    recuperarResumosCaixa() {
      /*recupera do BD os resumos enviados anteriormente relacionados a esse caixa*/
      this.arquivos = [];
      this.loadingArquivos = true;
      axios
        .get("/lancamentos/arquivos/" + this.caixaId)
        .then(res => {
          this.arquivos = res.data.arquivos;
          this.loadingArquivos = false;
        })
        .catch(() => {
          Notify.create({
            message: "Não foi possível recuperar os resumos TFL anteriores",
            color: "red",
            position: "top-right"
          });
          this.loadingArquivos = false;
        });
    },
    isAtivo(block) {
      let index = this.blocosMapeados.findIndex(b => block.Id === b.id);
      return this.blocosMapeados[index].ativo;
    },
  },
  watch: {
    OpenDialogUploadResumoCaixas(val) {
      if (val) {
        return this.intervalGetToken();
      }
      return clearInterval(this.interval);
    }
  },
  mounted() {
    this.$store.commit("imgOCRId", null);
  }
};
</script>

<style lang="stylus" scoped>
.layout
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:10px 10px;

.layout-responsive
  text-align:center;
  padding:10px 10px;

.layout-responsive .icones-layout label
  padding:3rem;

.icones-layout label
  margin-right:5px;

</style>
