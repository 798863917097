<template>
  <q-card-section style="padding-bottom: 0">
    <div class="q-pr-md q-pl-md">
      <div class="q-col-gutter-md row">
        <div class="col-12" style="padding-left: 0">
          <q-checkbox
            v-model="isVendaAPrazo"
            label="Venda à Prazo"
            color="green"
          />
        </div>
        <div class="col-12" v-if="isVendaAPrazo">
          <autocomplete
            title="Nome cliente"
            :filter="filterFn"
            :carregando="carregando"
            :options="options"
            :formatDisplay="formatDisplay"
            :create="createValue"
            @resultAutocomplete="resultEmitRecebe"
            :display="displayMontar"
            tipoConsulta="cadastro"
            :cadastro="abreCadastro"
            :newCadastro="cadastroReturn"
            :errors="errorsCaixas.cadastro_id"
          />
        </div>
        <div
          class="col-12"
          v-if="isVendaAPrazo"
          @keydown.enter.prevent="proximo('vencimento')"
        >
          <q-input
            bottom-slots
            :error="errorsCaixas.vencimento ? true : false"
            v-model="vencimento"
            label="Vencimento"
            name="vencimentoCaixa"
            fill-mask
            @click="$refs.qDateProxy.show()"
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    v-model="vencimento"
                    @input="() => $refs.qDateProxy.hide()"
                    mask="DD/MM/YYYY"
                    :locale="langDate"
                    minimal
                    today-btn
                    type="date"
                  />
                </q-popup-proxy>
              </q-icon>
            </template>
            <template v-slot:error>
              <span
                v-for="(error, i) in errorsCaixas.vencimento"
                :key="error + i"
                >{{ error }}</span
              >
            </template>
          </q-input>
        </div>
      </div>
    </div>
  </q-card-section>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { bus } from "@/main";
const moment = require("moment");
export default {
  name: "venda-fiado",
  data() {
    return {
      cadastro_id: null,
      cadastro_nome: null,
      isVendaAPrazo: false,
      vencimento: "",
      // autocomplete
      carregando: false,
      options: [],
      selectItem: null
    };
  },
  watch: {
    isVendaAPrazo(value) {
      this.$store.commit("isPrazo", value);
      this.clearr();
      if (value) {
        this.$store.commit("cadastroReturn", null);
        this.$store.commit("venda", {
          descricao: "",
          valor: 0
        });
        this.$store.dispatch('contas');
        setTimeout(() => {
          document.getElementsByName("autocompleted")[0].focus();
        }, 100);
      } else {
        setTimeout(() => {
          document.getElementsByName("descricaoCaixa")[0].focus();
        }, 100);
      }
    },
    vencimento(value) {
      this.$store.dispatch("movconta_vencimento", value);
    },
    cadastroReturn(value) {
      if (value && value.id) {
        this.cadastro_id = value.id;
        this.cadastro_nome = value.nome;
        document.getElementsByName("vencimentoCaixa")[0].focus();
        this.save();
      } else {
        let autocompletedElement = document.getElementsByName("autocompleted");
        if (autocompletedElement.length > 0) {
          setTimeout(() => {
            autocompletedElement[0].focus();
          }, 100);
        } else {
          document.getElementsByName("descricaoCaixa")[0].focus();
        }
      }
    }
  },
  methods: {
    clearr(fiado) {
      this.$store.commit("cadastroReturn", null);
      this.$store.commit("venda", {
        descricao: "",
        valor: 0
      });
      this.$store.commit("errorsCaixas", {});

      this.search = "";
      this.isOpen = false;
      this.isLoading = false;
      this.arrowCounter = -1;
      this.taVazio = false;

      if (fiado) {
        this.isVendaAPrazo = false;
      }
    },
    proximo(type) {
      if (type === "vencimento") {
        document.getElementsByName("descricaoCaixa")[0].focus();
      }
      if (type === "autocomplete") {
        document.getElementsByName("vencimentoCaixa")[0].focus();
      }
    },
    limpaCadastro() {
      this.vencimento = null;
      this.$store.commit("cadastroReturn", null);
      this.$store.commit("venda", {
        descricao: "",
        valor: 0
      });
    },
    newCadastro() {
      this.$store.dispatch("cadastro");
    },
    save() {
      this.$store.commit("venda", {
        descricao: "Venda a Prazo - " + this.cadastro_nome,
        valor: 0
      });
      if (this.$route.path != "/caixas/" + this.$route.params.id) {
        this.isVendaAPrazo = false;
      }
    },
    // Funções do autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/cadastros/busca?term=" + val).then(res => {
          this.carregando = false;
          this.options = res.data;
        });
      });
    },
    createValue(val, done) {
      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
    },
    formatDisplay(result) {
      return result.label;
    },
    resultEmitRecebe(result) {
      this.$emit("autocomplete", result);
    },
    displayMontar(value) {
      return !value ? value : value.name;
    },
    abreCadastro(value) {
      this.$store.dispatch("cadastro", {
        nome: value.name,
        noSearch: true
      });
    }
  },
  computed: {
    ...mapGetters([
      "cadastroReturn",
      "isPrazo",
      "salvareNovo",
      "movconta_vencimento",
      "errorsCaixas",
      "cadastro",
      "contas"
    ]),
    conta_id_caixa:{
      get(){
        return this.$store.getters.conta_id_caixa;
      },
      set(value){
        this.$store.commit('conta_id_caixa', value);
      }
    }
  },
  created() {
    bus.$on("clearFiado", () => {
      this.clearr(true);
      this.limpaCadastro();
      bus.$emit("clearReset");
    });
  },
  mounted() {
    document.addEventListener("keyup", event => {
      if (event.keyCode === 27) {
        this.$store.commit("OpenDialogVendaCaixas", false);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener("keyup");
    next();
  }
};
</script>

<style lang="stylus" scoped></style>
