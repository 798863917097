<template>
  <div id="OpendialogDetalhamento">
    <q-dialog v-model="OpenDialogDetalhamento" @input="closeModal">
      <q-card :style="`width: ${(tipo === 'sangria') ? '600' : '1050'}px; max-width: 100%; max-height: 90vh; display: flex; flex-direction: column;`">
        <q-card-section class="row items-center">
          <div class="text-h6" v-if="tipo == 'detalhamento'">
            Detalhamento {{ this.extratoDetalhamentoTotal.data | formatDate}} {{ this.extratoDetalhamentoTotal.descricao }} R$ {{ this.extratoDetalhamentoTotal.total.liquido | currency }}
          </div>
          <div class="text-h6" v-else-if="tipo == 'sangria'">
            Detalhamento - {{ extratoDetalhamentoTotal.descricao }}
          </div>

          <q-space />

          <div>
            <q-toggle
              v-model="showDetalhado"
              v-if="showExportar"
              left-label
              label="Detalhado"
              :true-value="true"
              :false-value="false"
            />

            <q-btn
              v-if="showExportar"
              flat
              no-caps
              color="primary"
              icon-right="archive"
              label="Exportar"
              :loading="loadingExportar"
              @click="exportarDetalhamento"
            />

            <q-btn
              v-if="datasInicialEFinal !== ''"
              class="q-mr-lg"
              flat
              no-caps
              :label="datasInicialEFinal"
            />

            <q-btn
              icon="close"
              flat
              round
              dense
              v-close-popup
              @click="closeModal()"
            />
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section class="row items-center" style="overflow-y: auto;">
          <div v-if="tipo === 'detalhamento'" style="width: 100%;">
            <q-table
              class="q-pa-none responsive"
              flat
              dense
              row-key="id"
              :data="extratoData"
              :columns="columns"
              hide-bottom
              :rows-per-page-options="[0]"
          >
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td class="text-center" v-if="showDetalhado">{{ props.row.dtmovimento | formatDate }}</q-td>
                  <q-td key="id" class="text-left" v-if="showDetalhado">
                    <a
                      v-if="props.row.movcaixa"
                      style="text-decoration: none; color: inherit;"
                      :href="`/caixas/${props.row.movcaixa_id}/resumo`"
                      target="_blank"
                    >
                      {{ props.row.movcaixa.caixa_nome }} - {{ props.row.movcaixa.func_resp_nome }} - Sessão {{ props.row.movcaixa.sessao }}
                    </a>
                    </q-td>
                  <q-td key="quantidade" class="text-right">{{ props.row.quantidade }}</q-td>
                  <q-td key="produto_nome" class="text-left">{{ props.row.produto.nome }} {{ props.row.concurso }}</q-td>
                  <q-td key="valor_venda" class="text-right"> {{ props.row.vlrvenda | currency }}</q-td>
                  <q-td key="valor" class="text-right"> {{ props.row.valor | currency }}</q-td>
                  <q-td key="valor_comissao" class="text-right"> {{ props.row.vlrcomissao | currency }}</q-td>
                </q-tr>
              </template>

              <template v-slot:bottom-row>
                <tr class="text-weight-bold">
                  <td
                    v-for="(td, index) in tfootColumns"
                    :key="index"
                    :colspan="td.colspan"
                    :class="`text-${td.align}`"
                  >
                    <span v-if="td.type === 'currency'"> R$ {{ td.value | currency }}</span>
                    <span v-else>{{ td.value }}</span>
                  </td>
                </tr>
              </template>
            </q-table>
          </div>
          <div v-else style="width: 100%;">
            <div class="q-mb-md">
              <p class="q-ma-none">
                Data: <b>{{ transferenciaDetalhamento.vencimento | formatDate }}</b>
              </p>
              <p class="q-ma-none">
                Conta: <b>{{ transferenciaDetalhamento.conta.titulo }}</b>
              </p>
              <p class="q-ma-none">
                Valor: <b>R$ {{ transferenciaDetalhamento.valor | currency }}</b>
              </p>
            </div>
            <q-markup-table
              flat
              dense
              separator="horizontal"
            >
              <thead>
                <tr>
                  <th class="text-left">Data</th>
                  <th class="text-center">Descrição</th>
                  <th class="text-center">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(sangria, index) in sangriasDetalhamento"
                  :key="index"
                >
                  <td class="text-left">{{ sangria.vencimento | formatDate }}</td>
                  <td class="text-left">
                    {{ sangria.descricao.length > 50 ?
                      sangria.descricao.substring(0, 50) + '...'
                      : sangria.descricao
                    }}
                    <q-tooltip v-if="sangria.descricao.length > 50">
                      {{ sangria.descricao }}
                    </q-tooltip>
                  </td>
                  <td class="text-right">R${{ sangria.valor | currency }}</td>
                </tr>
              </tbody>
            </q-markup-table>

            <div class="q-ml-auto q-mt-md q-mr-md" style="width: 35%">
              <div class="flex justify-between">
                <div>
                  <b>Total</b>
                  ({{ sangriasDetalhamento.length }})
                </div>

                <span>R$ {{ totalValorSangrias | currency }}</span>
              </div>
            </div>

          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from "@/axios-auth";
import { Notify } from "quasar";
import moment from "moment";

export default {
  name: "modal-detalhamento-caixa",
  props: ["movcontaId"],
  data() {
    return {
      tipo: "",
      showDetalhado: true,
      loadingExportar: false,
    };
  },
  methods: {
    exportarDetalhamento() {
      if (this.loadingExportar) return;

      this.loadingExportar = true;
      const params = {
        exportar: 1,
        agrupado: this.showDetalhado ? 0 : 1
      };

      axios
        .get(`/financeiro/detalhamento/${this.movcontaId}`, { params })
        .then(res => {
          this.loadingExportar = false;

          Notify.create({
            message: res.data.message,
            color: res.data.success ? "green" : "red",
            position: "top-right"
          });

          if (res.data.success) {
            window.location.href = res.data.linkArquivo;
          }
        });
    },
    closeModal(){
      this.$store.dispatch("setOpenDialogDetalhamento", false);
    },
  },
  computed: {
    columns() {
      const columns = [
        {
          name: 'quantidade',
          label: 'Quantidade',
          field: 'quantidade',
          sortable: true,
          align: 'center',
        },
        {
          name: 'produto',
          label: 'Produto',
          field: 'produto',
          sortable: true,
          align: 'center',
        },
        {
          name: 'venda',
          label: 'Venda',
          field: 'vlrvenda',
          sortable: true,
          align: 'center',
        },
        {
          name: 'valor',
          label: 'Líquido',
          field: 'valor',
          sortable: true,
          align: 'center',
        },
        {
          name: 'comissao',
          label: 'Comissão',
          field: 'vlrcomissao',
          sortable: true,
          align: 'center',
        },
      ];

      if (this.showDetalhado) {
        columns.unshift(
          {
            name: 'data',
            label: 'Data',
            field: 'dtmovimento',
            sortable: true,
            align: 'center',
          },
          {
            name: 'caixa',
            label: 'Caixa',
            field: 'movcaixa',
            sortable: true,
            align: 'center',
          },
        );
      }
      return columns;
    },
    tfootColumns() {
      const tfootColumns = [
        {
          value: 'Total',
          align: 'left',
          type: '',
          colspan: 2,
        },
        {
          value: this.extratoDetalhamentoTotal.total.quantidade,
          align: 'right',
          type: '',
          colspan: 1,
        },
        {
          value: this.extratoDetalhamentoTotal.total.bruto,
          align: 'right',
          type: 'currency',
          colspan: 2,
        },
        {
          value: this.extratoDetalhamentoTotal.total.liquido,
          align: 'right',
          type: 'currency',
          colspan: 1,
        },
        {
          value: this.extratoDetalhamentoTotal.total.comissao,
          align: 'right',
          type: 'currency',
          colspan: 1,
        }
      ];

      if (!this.showDetalhado) {
        tfootColumns.splice(1, 1);
        tfootColumns[0].colspan = 1;
        tfootColumns[0].value = 'Total' + ` (${this.extratoDetalhamentoTotal.total.quantidade})`;
        tfootColumns[2].colspan = 1;
      }

      return tfootColumns;
    },
    totalValorSangrias() {
      if (this.sangriasDetalhamento.length > 0) {
        return this.sangriasDetalhamento.reduce((total, sangria) => total + +sangria.valor, 0);
      }

      return 0;
    },
    extratoData() {
      return this.showDetalhado ? this.extratoDetalhamento : this.extratoDetalhamentoAgrupado;
    },
    showExportar() {
      return this.extratoData.length > 0 && this.tipo === "detalhamento";
    },
    datasInicialEFinal() {
      if (this.extratoData.length === 0) return "";
      const initialDate = this.extratoData[0].dtmovimento;
      const finalDate = this.extratoData[this.extratoData.length - 1].dtmovimento;

      return `${moment(initialDate).format("DD/MM/YYYY")} - ${moment(finalDate).format("DD/MM/YYYY")}`;
    },
    ...mapGetters([
      "OpenDialogDetalhamento",
      "extratoDetalhamento",
      "extratoDetalhamentoTotal",
      "sangriasDetalhamento",
      "transferenciaDetalhamento",
      "extratoDetalhamentoAgrupado"
    ])
  },
  watch: {
    OpenDialogDetalhamento(val) {
      if (!val) return;

      this.tipo = this.sangriasDetalhamento.length > 0 ? "sangria" : "detalhamento";
    }

  }
};
</script>
