<template>
  <div id="modalNovaFormaRecebimento">
    <q-dialog v-model="openDialogNovaFormaRecebimento" persistent>
      <q-card style="max-width: 800px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">{{isNewFormaPagar ? 'Nova ' : 'Editar '}} Forma de Pagar</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section v-if="etapas.etapa1">
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-input
                  v-model="titulo"
                  label="Título para esta forma de pagar"
                  bottom-slots
                  :error="errors.titulo ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.titulo" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  v-model="selectedConta"
                  :options="contas"
                  label="Selecione uma Conta"
                  :option-label="value => value === null ? 'Item Vazio' : value.titulo"
                  :option-value="value => value === null ? null : value.id"
                  :error="errors.conta_id ? true : false"
                  emit-value
                  map-options
                  bottom-slots
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.conta_id" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12 q-mt-lg">
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="bg-positive text-white pull-right"
                  clickable
                  type="submit"
                  :loading="buscaNew"
                  @click="buscaForma()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-section v-if="etapas.etapa2">
          <div class="q-pa-md">
            <div class="q-col-gutter-md row" v-if="loadItems">
              <div
                :class="{'col col-md-4': parametros && parametros.length > 1, 'col-12 col-md-12': parametros && parametros.length === 1}"
                v-for="key in parametros"
                :key="key.id"
              >
                <q-input v-model="key.valor" :label="key.nome"></q-input>
              </div>
              <div
                :class="{'col col-md-4': gateway.gateways_opts && gateway.gateways_opts.length > 1, 'col-12 col-md-12': gateway.gateways_opts && gateway.gateways_opts.length === 1}"
                v-for="key in gateway.gateways_opts"
                :key="key.id"
              >
                <q-input v-model="key.valor" :label="key.nome"></q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  v-model="selectedConta"
                  :options="contas"
                  label="Selecione uma Conta"
                  :option-label="value => value === null ? 'Item Vazio' : value.titulo"
                  :option-value="value => value === null ? null : value.id"
                  :error="errors.conta_id ? true : false"
                  emit-value
                  map-options
                  bottom-slots
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.conta_id" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12 q-mt-xl">
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="bg-positive text-white pull-right"
                  clickable
                  type="submit"
                  :loading="saveNew"
                  @click="save()"
                  >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
import { Notify } from "quasar";
export default {
  name: "modal-nova-forma-pagamento",
  props: [
    'tipo',
  ],
  data() {
    return {
      saveNew: false,
      buscaNew: false,
      homologados: [],
      titulo: "",
      forma: "18",
      parametros: [],
      selectedConta: null,
      itemName: {},
      errors: []
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("openDialogNovaFormaRecebimento", false);
      this.$store.commit("etapas", {
        etapa1: true,
        etapa2: false
      });
      this.parametros = [];
      this.itemName = {};
      this.$store.commit("loadItems", false);
      this.titulo = "";
      this.selectedConta = null;
    },
    modal() {
      let url = "/gateways/add?despesa=1";

      axios.get(url).then(res => {
        if (res.data.success) {
          let homologados = res.data.homologados;
          for (let i in homologados) {
            this.homologados.push({
              label: homologados[i],
              value: i,
            });
          }
        }
      });
    },
    editFormaPost() {
      this.saveNew = true;
      // eslint-disable-next-line
      let gateways_opts = {
        id: this.gateway.id,
        titulo: this.gateway.titulo,
        gateways_opts: this.gateway.gateways_opts,
        conta_id: this.selectedConta,
        padrao: this.gateway.padrao
      };
      axios
        .post("/gateways/edit/" + this.gateway.id, gateways_opts)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: "Forma de pagamento alterada!",
              color: "green",
              position: "top-right"
            });
            this.$store.commit("openDialogNovaFormaRecebimento", false);
            this.$store.commit("isNewFormaPagar", true);
            this.$store.commit("etapas", {
              etapa1: true,
              etapa2: false
            });
          }
          this.saveNew = false;
        })
        .catch(() => {
          Notify.create({
            message: "Não foi possível salvar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.saveNew = false;
        });
    },
    save() {
      if (!this.isNewFormaPagar) {
        this.editFormaPost();
        return;
      }
      this.saveNew = true;
      const dados = {
        tipo: this.forma,
        titulo: this.titulo,
        gateways_opts: this.parametros,
        conta_id: this.selectedConta,
        padrao: true
      };
      axios
        .post("/gateways/edit/" + this.itemName.id, dados)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: "Forma de pagamento criada!",
              color: "green",
              position: "top-right"
            });
            this.$store.dispatch("formasRecebimento", {tipo: this.tipo});
            this.$store.commit("openDialogNovaFormaRecebimento", false);
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            this.errors = res.data.errors;
          }
          this.saveNew = false;
          this.volta();
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível criar forma de pagamento, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.saveNew = false;
        });
    },
    buscaForma() {
      this.buscaNew = true;
      const dados = {
        tipo: this.forma,
        titulo: this.titulo,
        conta_id: this.selectedConta,
      };
      axios
        .post("/gateways/add/" + this.forma, dados)
        .then(res => {
          if (res.data.success) {
            this.parametros = res.data.gateway.gateways_opts;
            this.itemName = res.data.gateway;
            this.$store.commit("loadItems", true);
            this.errors = [];
            this.$store.commit("etapas", {
              etapa1: false,
              etapa2: true
            });
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            this.errors = res.data.errors;
          }
          this.buscaNew = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.buscaNew = false;
        });
    },
    volta() {
      this.$store.commit("etapas", {
        etapa1: true,
        etapa2: false
      });
      this.parametros = [];
      this.itemName = {};
      this.$store.commit("loadItems", false);
      this.forma = null;
      this.titulo = "";
    },
    checkConta() {
      this.selectedConta = this.contas.find(conta => conta.id === this.gateway.conta_id) ?? null;
    }
  },
  computed: {
    openDialogNovaFormaRecebimento: {
      get() {
        return this.$store.getters.openDialogNovaFormaRecebimento;
      },
      set() {}
    },
    gatewaysKeys() {
      return JSON.parse(JSON.stringify(this.parametros));
    },
    ...mapGetters(["isNewFormaPagar", "gateway", "etapas", "loadItems", "contas"])
  },
  mounted() {
    this.modal();
  },
  watch: {
    openDialogNovaFormaRecebimento(val) {
      if (val) this.checkConta();
    }
  }
};
</script>

<style lang="stylus" scoped></style>
