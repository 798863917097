<template>
  <div id="modalNovoLink">
    <q-dialog v-model="OpenDialogNovoLink" persistent>
      <q-card style="max-width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Novo Link</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-input v-model="meusLinks.titulo" label="Título" />
              </div>
              <div class="col-12 col-md-12">
                <q-input v-model="meusLinks.url" label="URL" />
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveLink"
            :loading="loadAtalho"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify } from "quasar";
export default {
  name: "modal-novo-link",
  data() {
    return {
      meusLinks: {
        titulo: "",
        url: ""
      },
      loadAtalho: false,
      error: ""
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogNovoLink", false);
      // this.$store.commit("isPerfil", false);
    },
    saveLink() {
      this.loadAtalho = true;
      const dados = {
        titulo: this.meusLinks.titulo,
        url: this.meusLinks.url,
        action: "addAtalhos"
      };

      dados.url = dados.url.indexOf('http') < 0 ? "http://".concat(dados.url) : dados.url;

      axios
        .post("/users/perfil", dados)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });

            this.closeModal();
            if (this.isPerfil) {
              this.$store.dispatch("listarUser");
            }
            this.meusLinks.titulo = "";
            this.meusLinks.url = "";
          } else {
            this.error = res.data.message;
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          this.loadAtalho = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível salvar o link, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.loadAtalho = false;
        });
    }
  },
  computed: {
    OpenDialogNovoLink: {
      get() {
        return this.$store.getters.OpenDialogNovoLink;
      },
      set() {}
    },
    ...mapGetters(["isPerfil"])
  }
};
</script>

<style lang="stylus" scoped></style>
