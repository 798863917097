<template>
  <q-layout view="hHh lpR fFf" class="fundo-login">
    <div id="login">
      <div class="q-pa-md">
        <div class="row">
          <div class="col-12 col-lg-3 col-md-6 fixed-center">
            <q-card>
              <q-linear-progress indeterminate v-if="dadosLogin.isLogged" />
              <q-card-section class="text-center">
                <img src="@/assets/douranovo.png" alt="DouraSoft Logo" width="150" />
              </q-card-section>

              <q-card-section>
                <q-form @submit="onSubmit" class="q-gutter-md">
                  <q-input
                    type="email"
                    v-model="email"
                    label="E-mail"
                    hint="Digite seu E-mail"
                    lazy-rules
                    :rules="[ val => val && val.length > 0 || 'Endereço de E-mail inválido']"
                    autofocus
                  />

                  <q-input
                    type="password"
                    v-model="senha"
                    label="Senha"
                    lazy-rules
                    :rules="[
                    val => val !== null && val !== '' || 'Digite sua senha'
                  ]"
                  />
                  <div class="q-mt-none  float-right">
                    <small>
                      <router-link
                        to="/alterar-senha"
                        style="text-decoration:none; color: black"
                      >Esqueci minha senha</router-link>
                    </small>
                  </div>
                  <div>
                    <small>
                      Ao "Entrar", concordo com os
                      <!-- <span
                        style="text-decoration: underline"
                      >Termos de Uso.</span>-->
                     <a type="a" href="https://dourasoft.com.br/termos-de-uso/"  target="_blank" label="Termos de uso." color="purple" >Termos de uso</a>
                    </small>
                  </div>

                  <div class="text-right">
                    <q-btn
                      label="Entrar"
                      type="submit"
                      color="blue-7"
                      :loading="dadosLogin.isLogged"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                  </div>
                </q-form>
              </q-card-section>

              <q-separator />

              <q-card-section class="flex justify-between" style="padding: 5px 16px;font-size: 13px">
                <a
                  href="https://conteudo.dourasoft.com.br/assinaturas"
                  target="_blank"
                  style="text-decoration:none; color: black"
                >
                  Ver Planos...
                </a>

                <router-link
                  to="/registro"
                  style="text-decoration:none; color: black"
                >Quero me cadastrar...</router-link>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
    </div>
  </q-layout>
</template>

<script>
export default {
  name: "login",
  meta: {
    title: "DSB | Login"
  },
  data() {
    return {
      email: null,
      senha: null
    };
  },
  methods: {
    onSubmit() {
      if (localStorage["token" + localStorage["empresa"]]) {
        this.$router.push("/visao360");
      } else {
        this.$store.dispatch("login", {
          email: this.email,
          senha: this.senha
        });
      }
    }
  },
  computed: {
    dadosLogin() {
      return this.$store.getters.login;
    }
  }
};
</script>

<style lang="stylus"></style>
