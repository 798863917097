<template>
  <div id="modalAlterarContaBancaria">
    <q-dialog v-model="OpenDialogCancelarConfirmarDespesas" persistent>
      <q-card style="min-width: 300px !important">
        <q-card-section>
          <div
            class="text-h6"
          >{{isDespesa ? 'Confirma o cancelamento em massa das despesas?' : 'Confirma o cancelamento em massa das receitas?'}}</div>
          <span>Digite </span><span style="color: #ea4335;">{{isDespesa ? '"Cancelar Despesas"' : '"Cancelar Receitas"'}}</span><span> no campo abaixo para prosseguir.</span>
        </q-card-section>

        <q-card-section>
          <q-input
            v-model="textodigitado"
            autofocus
            @keyup.enter="prompt = false"
          />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Cancelar" class="text-grey-8" clickable @click="voltar()" />
          <q-btn
            flat
            label="Prosseguir"
            clickable
            @click="cancelar()"
            :loading="realizandoAcaoReceitas"
            class="bg-positive text-white"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify } from "quasar";
export default {
  name: "modal-alterar-conta-bancaria",
  data() {
    return {
      textodigitado: "",
      prompt: false
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogCancelarConfirmarDespesas", false);
    },
    cancelar() {
      if (this.isDespesa) {
        if (this.textodigitado.toUpperCase() === "CANCELAR DESPESAS") {
          this.$store.dispatch("realizarCancelarMassaDespesas");
        } else if (
          this.textodigitado.toUpperCase() !== "CANCELAR DESPESAS" &&
          this.textodigitado !== ""
        ) {
          Notify.create({
            message: "Campo digitado inválido",
            color: "orange",
            position: "top-right"
          });
        } else {
          Notify.create({
            message: "Texto digitado vazio",
            color: "orange",
            position: "top-right"
          });
        }
      } else {
        if (this.textodigitado.toUpperCase() === "CANCELAR RECEITAS") {
          this.$store.dispatch("realizarCancelarMassa");
        } else if (
          this.textodigitado.toUpperCase() !== "CANCELAR RECEITAS" &&
          this.textodigitado !== ""
        ) {
          Notify.create({
            message: "Campo digitado inválido",
            color: "orange",
            position: "top-right"
          });
        } else {
          Notify.create({
            message: "Texto digitado vazio",
            color: "orange",
            position: "top-right"
          });
        }
      }
    },
    voltar() {
      this.textodigitado = "";
      this.closeModal();
    }
  },
  computed: {
    OpenDialogCancelarConfirmarDespesas: {
      get() {
        return this.$store.getters.OpenDialogCancelarConfirmarDespesas;
      },
      set() {}
    },
    realizandoAcaoReceitas: {
      get() {
        return this.$store.getters.realizandoAcaoReceitas;
      },
      set() {}
    },
    isDespesa() {
      return this.$route.path === "/despesas";
    }
  }
};
</script>

<style lang="stylus" scoped></style>
