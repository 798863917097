<template>
  <q-dialog v-model="OpenDialogIncluirConta">
    <q-card :style="`width: 100%; max-width: ${opcoesAvancadas ? '800px' : '450px'};`">
      <q-card-section class="row items-center">
        <div class="text-h6">Incluir Lançamento</div>
        <q-space />
        <q-btn v-close-popup dense flat icon="close" round @click="closeDialog" />
      </q-card-section>

      <q-separator />

      <q-card-section class="row q-col-gutter-lg">
        <div :class="`col-12 col-md-${opcoesAvancadas ? 6 : 12} row q-pb-md`">

          <q-markup-table v-if="selectedExtratos.length > 0" dense flat style="width: 100%">
            <thead>
            <tr>
              <th>Descrição</th>
              <th>Vencimento</th>
              <th>Valor</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(extrato, index) in selectedExtratos" :key="extrato.descricao + index">
              <td>
                {{
                  extrato.descricao.length > 25 ?
                    extrato.descricao.substring(0, 25) + "..." :
                    extrato.descricao
                }}
                <q-tooltip v-if="extrato.descricao.length > 25">{{ extrato.descricao }}</q-tooltip>
              </td>
              <td class="text-center">{{ extrato.dtmovimento  | formatDate }}</td>
              <td class="text-right">R$ {{ extrato.valor | currency }}</td>
            </tr>
            </tbody>
          </q-markup-table>

          <div v-if="selectedExtratos.length > 1" class="col-12 q-mt-md">
            <q-checkbox
              v-model="agruparLancamentos"
              :label="labelAgruparLancamento"
              clickable
              color="green"
            >
              <q-tooltip>Incluir um único lançamento no valor total</q-tooltip>
            </q-checkbox>
          </div>

          <div v-if="agruparLancamentos" class="col-12">
            <q-input
              v-model="newLancamentoAgrupado.descricao"
              :error="Boolean(errors.descricao)"
              :hide-bottom-space="!Boolean(errors.descricao)"
              label="Descrição"
            >
              <template v-slot:error>
                <span
                  v-for="(error, i) in errors.descricao"
                  :key="error + i"
                >
                  {{ error }}
                </span>
              </template>
            </q-input>
          </div>

          <div v-if="agruparLancamentos" class="col-12 q-mb-md">
            <q-input
              v-model="newLancamentoAgrupado.dtmovimento"
              class="white text-blue-7"
              :error="Boolean(errors.dtmovimento)"
              label="Data"
            >
              <template v-slot:default>
                <q-popup-proxy
                  ref="dtmovimentoProxy"
                  transition-hide="scale"
                  transition-show="scale"
                >
                  <q-date
                    v-model="newLancamentoAgrupado.dtmovimento"
                    mask="DD/MM/YYYY"
                    minimal
                    today-btn
                    @input="() => $refs.dtmovimentoProxy.hide()"
                  />
                </q-popup-proxy>
              </template>
              <template v-slot:error>
                <span
                  v-for="(error, i) in errors.dtmovimento"
                  :key="error + i"
                >
                  {{ error }}
                </span>
              </template>
            </q-input>
          </div>

          <div class="col-12 q-mt-sm">
            <q-checkbox
              v-model="isTransferencia"
              clickable
              color="green"
              label="Lançar como transferência"
            />
          </div>

          <div class="col-12 q-mb-sm">
            <autocomplete
              :cadastro="abreCadastro"
              :create="createValue"
              :display="displayMontar"
              :errors="errors.nome"
              :filter="filter"
              :formatDisplay="formatDisplay"
              :newCadastro="cadastroReturn"
              :options="options"
              tipoConsulta="cadastro"
              title="Cadastro ou fornecedor"
              @resultAutocomplete="resultEmitRecebe"
            />
          </div>

          <div v-if="isTransferencia" class="col-12 q-mb-sm">
            <q-select
              v-model="conta.conta_id"
              :option-label="
                  value => (value === null ? 'Item Vazio' : value.titulo)
                "
              :option-value="value => (value === null ? null : value)"
              :options="contas"
              clearable
              label="Conta"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>

          <div v-if="!isTransferencia" class="col-12 q-mb-sm">
            <q-select
              v-model="conta.planodeconta_id"
              :options="planoContasFilterResult"
              clearable
              emit-value
              input-debounce="500"
              label="Plano de Conta"
              map-options
              option-label="titulo"
              option-value="id"
              use-input
              @filter="planoFilter"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>

          <div v-if="hasOpcoesAvancadas" class="col-12 q-mt-md">
            <q-checkbox
              v-model="opcoesAvancadas"
              color="green"
              label="Opções Avançadas"
            />
          </div>

        </div>

        <div v-show="opcoesAvancadas" class="col-12 col-md-6">

          <div v-if="exibirCentrosdecusto" class="row">

            <div class="col-12 q-mb-md">
              <q-select
                v-model="newMovcontaRateio.centrodecusto"
                :error="Boolean(errors.centrodecusto)"
                :hide-bottom-space="!Boolean(errors.centrodecusto)"
                :option-label="value => value === null ? 'Item Vazio' : value.nome"
                :option-value="value => value === null ? null : value"
                :options="optionsCentrosdecusto"
                label="Centro de Custo"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem Resultados
                    </q-item-section
                    >
                  </q-item>
                </template>
                <template v-slot:error>
                  <span>{{ errors.centrodecusto }}</span>
                </template>
              </q-select>
            </div>

            <div class="col-2 flex items-end">
              <q-btn
                color="white"
                disable
                label="%"
                text-color="black"
              />
            </div>

            <div class="col-8">
              <q-field
                v-model="newMovcontaRateio.porcentagem"
                :error="Boolean(errors.porcentagem)"
                :hide-bottom-space="!Boolean(errors.porcentagem)"
                label="Porcentagem"
              >
                <template
                  v-slot:control="{ id, floatingLabel, value, emitValue }"
                >
                  <money
                    v-show="floatingLabel"
                    :id="id"
                    :value="value"
                    class="q-field__native text-right"
                    v-bind="percentFormat"
                    @input="emitValue"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span>{{ errors.porcentagem }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-2 flex items-end justify-end">
              <q-btn
                color="primary"
                flat
                icon="add"
                round
                @click="addMovcontaRateio()"
              />
            </div>

            <div class="col-12 q-mt-md">
              <p
                v-if="porcentagemMovcontasRateioTotal > 100"
                class="text-negative caption"
              >
                A porcentagem total passa de 100%
              </p>
              <div v-if="Boolean(errors.movcontas_rateios)">
                <p
                  v-for="(error, index) in errors.movcontas_rateios"
                  :key="index"
                  class="text-negative caption"
                >
                  {{ error }}
                </p>
              </div>

              <q-markup-table
                dense
                flat
              >
                <tbody>
                <tr
                  v-for="(rateio, index) in movcontas_rateios"
                  :key="index"
                >
                  <td>{{ rateio.centrocusto.nome }}</td>
                  <td class="text-right">{{ rateio.porcentagem }} %</td>
                  <td style="width: 30px;">
                    <q-btn
                      color="red"
                      dense
                      flat
                      icon="delete"
                      @click="movcontas_rateios.splice(index, 1)"
                    />
                  </td>
                </tr>
                <tr v-if="movcontas_rateios.length > 0">
                  <td colspan="2"><strong>Total</strong></td>
                  <td class="text-right">{{ porcentagemMovcontasRateioTotal }} %</td>
                </tr>
                <tr v-if="movcontas_rateios.length === 0">
                  <td class="text-center" colspan="3">Nenhum rateio</td>
                </tr>
                </tbody>
              </q-markup-table>
            </div>

          </div>

        </div>
      </q-card-section>

      <q-card-actions class="q-px-lg q-pb-md">
        <q-btn
          :disable="loadSave"
          :loading="loadSave"
          class="bg-positive text-white q-ml-auto"
          clickable
          flat
          label="Salvar"
          @click="incluirLancamento"
        >
          <template v-slot:loading>
            <q-spinner color="grey-10" size="1em" />
          </template>
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { bus } from "@/main";
import { Money } from "v-money";

const moment = require("moment");

export default {
  name: "modal-incluir-lancamento",
  components: { Money },
  props: ["contaExtrato"],
  data() {
    return {
      isTransferencia: false,
      conta: {
        planodeconta_id: null,
        conta_id: null
      },
      options: [],
      planodecontas: [],
      planoContasFilterResult: [],
      cadastro: {},
      loadSave: false,
      errors: {},
      // Opções Avançadas
      opcoesAvancadas: false,
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false
      },
      newMovcontaRateio: {
        centrodecusto: null,
        porcentagem: 0
      },
      newLancamentoAgrupado: null,
      movcontas_rateios: [],
      agruparLancamentos: false
    };
  },
  methods: {
    closeDialog() {
      this.agruparLancamentos = false;
      this.$store.commit("OpenDialogIncluirConta", false);
      this.$store.commit("OpenDialogSearch", false);
    },
    reset() {
      // Emitir evento para o componente pai, saber que deve atualizar a lista de extratos importado
      bus.$emit("atualizarConciliacao", true);
      this.$emit("clearExtratos");
      this.$store.commit("selectedExtratos", []);

      // Resetar variaveis
      this.loadSave = false;
      this.isTransferencia = false;
      this.cadastro = {};
      this.conta = {
        planodeconta_id: null,
        conta_id: null
      };
      this.errors = {};
      this.movcontas_rateios = [];
      this.opcoesAvancadas = false;
      this.newMovcontaRateio = {
        centrodecusto: null,
        porcentagem: 0
      };
      this.agruparLancamentos = false;
      this.newLancamentoAgrupado = null;
      // Fechar modal
      this.closeDialog();
    },
    // Funções do autocomplete
    filter(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        axios.get("/cadastros/busca?term=" + val).then(res => {
          this.options = res.data;
        });
      });
    },
    createValue(val, done) {
      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
    },
    formatDisplay(result) {
      return result.label;
    },
    resultEmitRecebe(result) {
      this.cadastro = result;
      this.$store.commit("cadastroReturn", null);

      if (this.cadastro && this.cadastro.id != null) {
        this.$q.notify({
          message: this.cadastro.name + " foi selecionado.",
          color: "green",
          position: "top-right"
        });
      }
    },
    displayMontar(value) {
      return !value ? value : value.name;
    },
    abreCadastro(value) {
      this.$store.dispatch("cadastro", {
        nome: value.name,
        noSearch: true
      });
    },
    planoFilter(val, update) {
      update(() => {
        this.planoContasFilterResult = this.planodecontas.filter(
          plano => plano.titulo.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
    planodeContasFilter(tipo = null) {
      axios
        .get("/plano-contas", {
          params: {
            cd: tipo
          }
        })
        .then(res => {
          if (res.data.success) {
            this.planodecontas = res.data.planodecontas;
          }
        });
    },
    async incluirLancamento() {
      this.loadSave = true;
      let cadastro = false;
      let data = {
        action: "adicionar",
        extratos_ids: this.selectedExtratos.map(extrato => extrato.id),
        cadastro_id: null
      };

      if (this.agruparLancamentos) {
        data.dtmovimento = moment(this.newLancamentoAgrupado.dtmovimento, "DD/MM/YYYY").format("YYYY/MM/DD");
        data.descricao = this.newLancamentoAgrupado.descricao;
        data.conta_id = this.contaExtrato.id;
        data.action = "adicionar_agrupado";
      }

      if (this.cadastro && this.cadastro.id) {
        data.cadastro_id = this.cadastro.id;
        cadastro = true;
      } else {
        if (!this.cadastro.name) {
          this.errors.nome = [
            "O cadastro é obrigatório."
          ];
          this.loadSave = false;
          return;
        }

        let newCadastro = {
          nome: this.cadastro.name,
          fornecedor: true
        };

        await axios
          .post("/cadastros/add", newCadastro)
          .then(res => {
            if (res.data.success) {
              data.cadastro_id = res.data.data.cadastro.id;
              this.errors = false;
              cadastro = true;
            } else {
              this.errors = res.data.errors;
              this.loadSave = false;
              cadastro = false;
              this.cadastro.name = "";
            }
          })
          .catch(() => {
            this.$q.notify({
              message: "Não foi possivel ignorar lançamento!",
              color: "red",
              position: "top-right"
            });
          });
      }

      if (this.conta.planodeconta_id !== null) {
        data.planodeconta_id = this.conta.planodeconta_id;
      }

      if (this.isTransferencia && this.conta.conta_id !== null) {
        data.conta_transferencia_id = this.conta.conta_id.id;
      }

      if (this.exibirCentrosdecusto) {
        data.movcontas_rateios = this.movcontas_rateios.map(rateio => ({
          porcentagem: rateio.porcentagem,
          centrocusto_id: rateio.centrocusto.id
        }));
      }

      const { arquivo_id, conta_id } = this.$route.query;

      let transferencia = 0;
      if (this.isTransferencia) {
        transferencia = 1;
      }

      const url = `financeiro/conciliar/${conta_id}?arquivo_id=${arquivo_id}&transferencia=${transferencia}`;

      if (cadastro) {
        axios
          .post(url, data)
          .then(res => {
            this.loadSave = false;

            this.$q.notify({
              message: res.data.message,
              color: res.data.success ? "green" : "orange",
              position: "top-right"
            });

            if (!res.data.success) {
              this.errors = res.data.errors || {};
              return;
            }

            this.reset();
          }).catch(() => {
          this.$q.notify({
            message: "Não foi possivel ignorar lançamento!",
            color: "red",
            position: "top-right"
          });

          this.loadSave = false;
        });
      }
    },
    init() {
      if (this.selectedExtratos) {
        let tipo = this.valorTotalLancamentosAgrupados >= 0 ? "C" : "D";
        this.planodeContasFilter(tipo);
      }

      this.newLancamentoAgrupado = {
        descricao: "",
        dtmovimento: moment(this.selectedExtratos[0].dtmovimento, "YYYY/MM/DD").format("DD/MM/YYYY"),
        valor: this.valorTotalLancamentosAgrupados
      };

      this.errors = {};
    },
    addMovcontaRateio() {
      this.errors.porcentagem = null;
      this.errors.centrodecusto = null;

      if (!this.newMovcontaRateio.centrodecusto) {
        this.errors.centrodecusto = "Centro de custo é obrigatório";
        return;
      }

      if (!this.newMovcontaRateio.porcentagem) {
        this.errors.porcentagem = "Porcentagem é obrigatória";
        return;
      }

      if (this.porcentagem < 0 || this.porcentagem > 100) {
        this.errors.porcentagem = "Valor inválido";
        return;
      }

      const rateioIndex = this.movcontas_rateios.findIndex(rateio => rateio.centrocusto.id === this.newMovcontaRateio.centrodecusto.id);

      const rateio = {
        porcentagem: Math.abs(Number(this.newMovcontaRateio.porcentagem)),
        centrocusto: {
          id: this.newMovcontaRateio.centrodecusto.id,
          nome: this.newMovcontaRateio.centrodecusto.nome
        }
      };

      if (rateioIndex === -1) {
        this.movcontas_rateios.push(rateio);
      } else {
        this.movcontas_rateios[rateioIndex] = rateio;
      }

      this.newMovcontaRateio = {
        centrodecusto: null,
        porcentagem: 0
      };
    }
  },
  computed: {
    ...mapGetters([
      "selectedExtratos",
      "contas",
      "cadastroReturn",
      "centroCustos",
      "OpenDialogIncluirConta"
    ]),
    exibirCentrosdecusto() {
      return this.selectedExtratos.length === 1 && this.selectedExtratos[0].valor < 0;
    },
    hasOpcoesAvancadas() {
      return this.exibirCentrosdecusto;
    },
    optionsCentrosdecusto() {
      if (!this.exibirCentrosdecusto) {
        return [];
      }

      return this.centroCustos.filter(centroCusto => {
        return !(this.movcontas_rateios.find(rateio => rateio.centrocusto.id == centroCusto.id));
      });
    },
    porcentagemMovcontasRateioTotal() {
      if (!this.exibirCentrosdecusto) {
        return 0.00;
      }

      return this.movcontas_rateios.reduce((total, rateio) => total + Math.abs(Math.abs(Number(rateio.porcentagem))), 0);
    },
    valorTotalLancamentosAgrupados() {
      return this.selectedExtratos.reduce((total, extrato) => total + parseFloat(extrato.valor), 0.00);
    },
    labelAgruparLancamento() {
      return "Realizar lançamento agrupado no valor de " + this.valorTotalLancamentosAgrupados.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    }
  },
  watch: {
    OpenDialogIncluirConta() {
      if (!this.OpenDialogIncluirConta) return;

      this.init();
    }
  },
  mounted() {
    this.$store.dispatch("listarCentroCustos");
  }
};
</script>
