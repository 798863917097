<template>
  <div id="modalCancelarFilas">
    <q-dialog v-model="OpenDialogCancelarFilas" persistent>
      <q-card style="width:600px">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">Cancelar filas de mensagens</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />

        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <p>Você deseja realmente cancelar {{ filas.length }} filas de mensagens?</p>
              </div>
              <div class="col-12 col-md-12">
                <q-input v-model="motivo" label="Motivo:" />
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            :loading="salvandoFilas"
            flat
            label="Prosseguir"
            clickable
            @click="salvarFilas"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <!-- <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template> -->
          </q-btn>
        </q-card-actions>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify } from "quasar";
import { mapGetters } from "vuex";

export default {
  name: "modal-cancelar-filas",
  data() {
    return {
      salvandoFilas: false,
      motivo: '',
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogCancelarFilas", false);
    },
    salvarFilas() {
      this.salvandoFilas = true;

      axios
        .post(`/whatsapp/edit`, {
          ids: this.filas.map(fila => fila.id),
          motivo: this.motivo,
        })
        .then(res => {
          if (!res.data.success) {
            throw res;
          }

          this.salvandoFilas = false;
          this.motivo = '';

          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          this.$store.dispatch("listarWppMessagesList", { atualizar: true });
          this.closeModal();
        })
        .catch(err => {
          this.salvandoFilas = false;

          let mensagem = "Houve um erro ao salvar as filas, tente novamente!";

          if (err.data) {
            mensagem = err.data.message;
          }

          Notify.create({
            message: mensagem,
            color: "red",
            position: "top-right"
          });
        });
    },
  },
  watch: {
    filas(val) {
      if (val) {
        this.status = val.status;
      }
    },
  },
  computed: {
    ...mapGetters([
      "OpenDialogCancelarFilas",
      "filas",
    ]),
  },
};
</script>
