<template>
  <div id="modalAdicionarRemoverEstoqueFuncionario">
    <q-dialog v-model="openDialog" persistent>
      <q-card :style="`width: 100%; max-width: ${tipo !== 'adicionar' ? 550 : 900}px`">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">
            {{ labelTipo }} Estoque - {{ funcionario ? funcionario.nome : '' }}
          </div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>

        <q-separator />

        <q-card-section class="row q-col-gutter-md">
          <div class="col-12 col-md-5" v-show="tipo === 'adicionar'">
            <div class="row q-col-gutter-md full-width">
              <div class="col-12">
                <autocomplete
                  title="Produto"
                  ref="produtoEstoque"
                  :create="() => {}"
                  :notClearable="true"
                  :filter="filterFnProduto"
                  :carregando="carregandoProduto"
                  :display="formatDisplayProduto"
                  :options="produtosOptions"
                  :formatDisplay="formatDisplayProduto"
                  @resultAutocomplete="resultEmitRecebeProduto"
                />
              </div>

              <div class="col-12 col-md-12" v-show="exibirTpController">
                <q-input
                  ref="numeroTpcontrole"
                  input-class="text-right"
                  :label="labelTpcontrole"
                  :error="!!errors.numeroTpcontrole"
                  :mask="tipoIsExtracao ? '########' : '####'"
                  @input="buscaAno"
                  v-model="numeroTpcontrole"
                  @keydown.enter="pulaCampoNumeroTpcontrole"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.numeroTpcontrole" :key="error + i">
                      {{ error }}
                    </span>
                  </template>
                </q-input>
              </div>

              <div class="col-12 col-md-12" v-show="exibirTpController && tipoIsExtracao">
                <q-input
                  input-class="text-right"
                  label="Vencimento"
                  :error="!!errors.vencimento"
                  @click="$refs.vencimento.show()"
                  v-model="vencimento"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="vencimento"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="vencimento"
                          mask="DD/MM/YYYY"
                          minimal
                          today-btn
                          :locale="langDate"
                          @input="() => pulaCampo('quantidade')"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.vencimento" :key="error + i">
                      {{ error }}
                    </span>
                  </template>
                </q-input>
              </div>

              <div class="col-12 col-md-12" v-show="exibirTpController && tipoIsEvento">
                <q-select
                  bottom-slots
                  ref="evento"
                  label="Evento"
                  :error="!!errors.evento"
                  :options="produtosEstoqueAno"
                  :option-label="value => (value === null ? 'Item Vazio' : value.nome)"
                  :option-value="value => (value === null ? null : value)"
                  @input="() => pulaCampo('quantidade')"
                  v-model="evento"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.evento" :key="error + i">
                      {{ error }}
                    </span>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <q-input
                  bottom-slots
                  mask="#####"
                  ref="quantidade"
                  label="Quantidade"
                  input-class="text-right"
                  :error="!!errors.quantidade"
                  @keydown.enter="addProdutoEstoque()"
                  v-model="quantidade"
                >
                  <template v-slot:after>
                    <q-btn
                      flat
                      round
                      icon="add"
                      color="primary"
                      style="height: 42px"
                      class="align-self-center"
                      @click="addProdutoEstoque()"
                    />
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.quantidade" :key="error + i">
                      {{ error }}
                    </span>
                  </template>
                </q-input>
              </div>
            </div>
          </div>
          <div :class="{ 'col-12': true, 'col-md-7': tipo === 'adicionar' }">
            <div class="row q-col-gutter-md full-width">
              <div class="col-12" v-show="tipo === 'transferir'">
                <q-toggle
                  color="green"
                  :label="
                    !transferirParaFuncionario
                      ? 'O estoque está indo para a empresa'
                      : 'O estoque está indo para o funcionário'
                  "
                  v-model="transferirParaFuncionario"
                />
              </div>

              <div class="col-12 q-px-lg q-pt-none" v-show="transferirParaFuncionario">
                <autocomplete
                  tipoConsulta="cadastro"
                  title="Selecionar Cadastro"
                  :filter="filterFnCadastro"
                  :options="cadastrosOptions"
                  :create="createValueCadastro"
                  :carregando="carregandoCadastro"
                  :display="formatDisplayCadastro"
                  :formatDisplay="formatDisplayCadastro"
                  @resultAutocomplete="resultEmitRecebeCadastro"
                />
              </div>

              <div class="col-12 q-mt-md">
                <q-markup-table dense class="no-shadow">
                  <thead>
                    <tr>
                      <th class="text-left">Produto</th>
                      <th class="text-right small-column">Quantidade</th>
                      <th class="text-right small-column">Valor</th>
                      <th class="text-right small-column">Total</th>
                      <th style="width: 24px"></th>
                    </tr>
                  </thead>
                  <tbody v-show="!!produtosEstoque.length">
                    <tr
                      v-for="(produtoEstoque, index) in produtosEstoque"
                      :key="index"
                      :id="`produtoEstoque${index}`"
                    >
                      <td style="white-space: break-spaces">
                        {{ formatDisplayProdutoEstoque(produtoEstoque) }}
                      </td>
                      <td class="text-right" style="width: 50px">
                        <q-input
                          dense
                          type="text"
                          mask="#####"
                          style="height: 34px"
                          input-class="text-right"
                          :ref="`produtoEstoqueQuantidadeRef${index}`"
                          :class="{ 'q-pa-none': true, input_error: errorsProdutos[index] }"
                          @keyup.enter="() => pulaProxQuantidade(produtoEstoque, index)"
                          @input="() => handleChangeQuantidadeProduto(produtoEstoque, index)"
                          v-model.number="produtoEstoque.quantidade"
                        />
                      </td>
                      <td class="text-right">
                        R$ {{ parseFloat(produtoEstoque.vlrvenda) | currency }}
                      </td>
                      <td class="text-right">
                        R$
                        {{
                          (parseFloat(produtoEstoque.vlrvenda) *
                            parseInt(produtoEstoque.quantidade))
                            | currency
                        }}
                      </td>
                      <td>
                        <q-btn
                          dense
                          flat
                          size="sm"
                          icon="delete"
                          color="negative"
                          @click="() => produtosEstoque.splice(index, 1)"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Total</strong></td>
                      <td class="text-right">
                        <strong>{{ quantidadeTotal }}</strong>
                      </td>
                      <td class="text-right">
                        <strong>R$ {{ vlrvendaTotal | currency }}</strong>
                      </td>
                      <td class="text-right">
                        <strong>R$ {{ valorTotal | currency }}</strong>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                  <tbody v-show="!produtosEstoque.length">
                    <tr>
                      <td class="text-center" colspan="5">Nenhum produto encontrado</td>
                    </tr>
                  </tbody>
                </q-markup-table>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions class="justify-end">
          <q-btn
            clickable
            :label="labelTipo"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="loading"
            :color="tipo === 'remover' ? 'negative' : 'positive'"
            @click="salvar()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import axios from '@/axios-auth';
import { bus } from '@/main';
import { mapGetters } from 'vuex';

export default {
  name: 'modal-adicionar-remover-estoque-funcionario',
  data() {
    return {
      tipo: null,
      funcionario: {},
      loading: false,
      openDialog: false,
      produtoEstoque: null,
      carregandoProduto: false,
      quantidade: 0,
      produtosEstoque: [],
      transferirParaFuncionario: false,
      carregandoCadastro: false,
      cadastrosOptions: [],
      cadastro: null,
      errors: {},
      errorsProdutos: {},
      vencimento: '',
      numeroTpcontrole: null,
      evento: null,
    };
  },
  methods: {
    init({ tipo, funcionario }) {
      if (tipo === 'adicionar') {
        this.$store.dispatch("produtosEstoque", {
          tipo: 'P',
          all: 1,
          ativo: 1,
        });
      }

      this.tipo = JSON.parse(JSON.stringify(tipo));
      this.funcionario = JSON.parse(JSON.stringify(funcionario));

      this.cadastrosOptions = [];
      this.produtosEstoque = [];
      this.cadastro = null;
      this.errorsProdutos = {};
      this.transferirParaFuncionario = false;

      this.resetProdutoEstoque();

      this.openDialog = true;

      this.getProdutosEstoque();
    },
    closeModal() {
      this.openDialog = false;
    },
    getProdutosEstoque() {
      if (this.tipo === 'adicionar') return;

      let produtosEstoque = JSON.parse(JSON.stringify(this.funcionario.produtos));

      produtosEstoque = produtosEstoque.map(produtoEstoque => ({
        id: produtoEstoque.produto_id,
        nome: produtoEstoque.nome,
        tpcontrole: produtoEstoque.tpcontrole,
        extracao: produtoEstoque.tpcontrole === 'Extração' ? produtoEstoque.extracao : null,
        vencimento:
          produtoEstoque.tpcontrole === 'Extração'
            ? moment(produtoEstoque.vencimento, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : null,
        concurso: produtoEstoque.tpcontrole === 'Concurso' ? produtoEstoque.concurso : null,
        evento: produtoEstoque.tpcontrole === 'Evento' ? produtoEstoque.evento : null,
        evento_id: produtoEstoque.tpcontrole === 'Evento' ? produtoEstoque.evento_id : null,
        funcionario: this.funcionario.id,
        importado: false,
        quantidade: 0,
        quantidadeOriginal: produtoEstoque.quantidade,
        vlrvenda: produtoEstoque.vlrvenda,
      }));

      this.produtosEstoque = produtosEstoque;
    },
    addProdutoEstoque() {
      const errors = {};

      if (!this.produtoEstoque) {
        errors['produtoEstoque'] = ['Este campo é obrigatório'];
      }

      if (!this.numeroTpcontrole) {
        errors['numeroTpcontrole'] = ['Este campo é obrigatório'];
      }

      if (this.tipoIsExtracao) {
        if (!moment(this.vencimento, 'DD/MM/YYYY').isValid()) {
          errors['vencimento'] = ['Valor inválido'];
        }

        if (!this.vencimento) {
          errors['vencimento'] = ['Este campo é obrigatório'];
        }
      }

      if (this.tipoIsEvento && !this.evento) {
        errors['evento'] = ['Este campo é obrigatório'];
      }

      if (this.tipoIsExtracao && !moment()) {
        errors['numeroTpcontrole'] = ['Este campo é obrigatório'];
      }

      if (!this.quantidade) {
        errors['quantidade'] = ['Este campo é obrigatório'];
      }

      if (this.produtoEstoque && this.quantidade > this.produtoEstoque.quantidade) {
        errors['quantidade'] = ['A quantidade informada é maior do que o funcionário possui'];
      }

      this.errors = errors;

      if (Object.keys(errors) > 0) return;

      this.produtosEstoque.push({
        id: this.produtoEstoque.id,
        nome: this.produtoEstoque.nome,
        tpcontrole: this.produtoEstoque.tpcontrole,
        extracao: this.tipoIsExtracao ? this.numeroTpcontrole : null,
        vencimento: this.vencimento,
        concurso: this.tipoIsConcurso ? this.numeroTpcontrole : null,
        evento: this.tipoIsEvento ? this.evento.nome : null,
        evento_id: this.tipoIsEvento ? this.evento.id : null,
        funcionario: this.funcionario.id,
        importado: false,
        quantidade: this.quantidade,
        vlrvenda: this.produtoEstoque.vlrvenda,
      });

      this.resetProdutoEstoque();
      this.pulaCampo('produtoEstoque');
    },
    resetProdutoEstoque() {
      this.produtoEstoque = null;
      this.quantidade = 0;
      this.vencimento = '';
      this.numeroTpcontrole = '';
      this.evento = null;
      this.errors = {};
      this.$store.commit('produtosEstoque', []);
      this.$root.$emit('limpar');
    },
    salvar() {
      if (this.produtosEstoque.length === 0) return;

      this.loading = true;

      const recursos = {
        adicionar: 'add-estoque/',
        remover: 'sub-estoque/',
        transferir: 'transferir/',
      };

      const url = '/produtos/' + recursos[this.tipo];
      const funcionarioId = this.funcionario.id;

      const data = {
        produtos: JSON.parse(JSON.stringify(this.produtosEstoque)).filter(
          produtoEstoque => produtoEstoque.quantidade > 0
        ),
        multiplo: true,
      };

      if (this.tipo === 'transferir') {
        data.cadastro_origem_id = funcionarioId;
        data.cadastro_destino_id = this.cadastro ? this.cadastro.id : null;
        data.cadastros = [];
      }

      axios
        .post(url, data)
        .then(res => {
          this.loading = false;

          let { message } = res.data;

          if (!res.data.sucess && res.data.errorsProdutos && res.data.errorsProdutos.length > 0) {
            message = res.data.errorsProdutos.join(', ');
          }

          this.$q.notify({
            message: message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) return;

          this.$store.commit('estoques', []);
          this.$store.dispatch('listarEstoque', {
            tipo: 1, // operador
            forcarCarregamento: true,
          });

          this.closeModal();
        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
          this.loading = false;
        });
    },
    pulaProxQuantidade(produtoEstoque, index) {
      if (
        this.tipo !== 'adicionar' &&
        produtoEstoque.quantidade > produtoEstoque.quantidadeOriginal
      ) {
        return;
      }

      const proximoIndex = index + 1;

      if (
        this.$refs[`produtoEstoqueQuantidadeRef${proximoIndex}`] &&
        this.$refs[`produtoEstoqueQuantidadeRef${proximoIndex}`][0]
      ) {
        this.$refs[`produtoEstoqueQuantidadeRef${proximoIndex}`][0].select();
      }
    },
    pulaCampo(campo, segundos = null) {
      const focar = () => {
        if (!this.$refs[campo]) {
          return;
        }

        if (this.$refs[campo].$refs && this.$refs[campo].$refs.autocompletedComponent) {
          this.$refs[campo].$refs.autocompletedComponent.focus();
          return;
        }

        if (this.$refs[campo].showPopup) {
          return this.$refs[campo].showPopup();
        }

        if (this.$refs[campo].show) {
          return this.$refs[campo].show();
        }

        if (this.$refs[campo].$el.classList.contains('v-money')) {
          return this.$refs[campo].$el.select();
        }

        return this.$refs[campo].select();
      };

      if (segundos) {
        return setTimeout(() => focar(), segundos);
      }

      focar();
    },
    formatDisplayProdutoEstoque(produtoEstoque) {
      if (!produtoEstoque) return produtoEstoque;

      const display = [];

      if (produtoEstoque.quantidadeOriginal !== undefined) {
        display.push(`(${produtoEstoque.quantidadeOriginal})`);
      }

      display.push(produtoEstoque.nome);

      if (produtoEstoque.tpcontrole && produtoEstoque.tpcontrole !== 'Nenhum') {
        display.push(produtoEstoque.tpcontrole);
      }

      switch (produtoEstoque.tpcontrole) {
        case 'Extração':
          display.push(produtoEstoque.extracao);
          display.push(moment(produtoEstoque.vencimento, 'YYYY-MM-DD').format('DD/MM/YYYY'));
          break;
        case 'Concurso':
          display.push(produtoEstoque.concurso);
          break;
        case 'Evento':
          display.push(produtoEstoque.evento);
          break;
      }

      return display.join(' ');
    },
    handleChangeQuantidadeProduto(produtoEstoque, index) {
      if (produtoEstoque.quantidade > produtoEstoque.quantidadeOriginal) {
        this.errorsProdutos = {
          [index]: true,
        };
        return;
      }

      if (this.errorsProdutos[index]) {
        delete this.errorsProdutos[index];
      }
    },
    buscaAno() {
      if (!this.tipoIsEvento) return;
      if (this.numeroTpcontrole <= 1990) return;
      this.$store.dispatch("produtosEstoque", {
        tipo: 'P',
        ano: this.numeroTpcontrole,
        ativo: 1,
      });
    },
    // Autocomplete
    filterFnProduto(val, update, abort) {
      update(() => {
        if (!val) return abort();
        this.carregandoProduto = true;
        this.$store.dispatch("produtosEstoque", {
          tipo: 'P',
          nome: val ,
          ativo: 1,
        }).then(res => {
          this.carregandoProduto = false;
        });
      });
    },
    formatDisplayProduto(value) {
      return !value ? value : value.nome;
    },
    resultEmitRecebeProduto(result) {
      this.produtoEstoque = result;

      if (!this.produtoEstoque) return;

      setTimeout(() => this.pulaCampo('numeroTpcontrole'), 50);

      this.$q.notify({
        message: `${this.produtoEstoque.nome} foi selecionado.`,
        color: 'green',
        position: 'top-right',
      });
    },
    filterFnCadastro(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregandoCadastro = true;
        axios.get(`/cadastros?ativo=1&funcionario=1&busca=${val}`).then(res => {
          this.carregandoCadastro = false;
          this.cadastrosOptions = res.data.data.cadastros.filter(
            cadastro => cadastro.id !== this.funcionario.id
          );
        });
      });
    },
    formatDisplayCadastro(value) {
      return !value.nome ? value.name : value.nome;
    },
    createValueCadastro(val, done) {
      Notify.create({
        message: 'Cadastro não foi encontrado.',
        color: 'orange',
        position: 'top-right',
      });
    },
    resultEmitRecebeCadastro(result) {
      this.cadastro = result;
    },
    pulaCampoNumeroTpcontrole() {
      if (this.tipoIsExtracao) {
        this.pulaCampo('vencimento');
      }
      if (this.tipoIsConcurso) {
        this.pulaCampo('quantidade');
      }
      if (this.tipoIsEvento) {
        this.pulaCampo('evento');
      }
    },
  },
  computed: {
    ...mapGetters(['produtosEstoqueAno']),
    ...mapGetters({ produtosOptions: 'produtosEstoque' }),
    labelTipo() {
      if (this.tipo === 'adicionar') return 'Adicionar';
      if (this.tipo === 'remover') return 'Remover';
      return 'Transferir';
    },
    quantidadeTotal() {
      return this.produtosEstoque.reduce(
        (total, produtoEstoque) => total + parseInt(produtoEstoque.quantidade || 0),
        0
      );
    },
    vlrvendaTotal() {
      return this.produtosEstoque.reduce(
        (total, produtoEstoque) => total + parseFloat(produtoEstoque.vlrvenda),
        0
      );
    },
    valorTotal() {
      return this.produtosEstoque.reduce(
        (total, produtoEstoque) =>
          total + parseFloat(produtoEstoque.vlrvenda) * parseInt(produtoEstoque.quantidade),
        0
      );
    },
    exibirTpController() {
      return (
        this.produtoEstoque &&
        this.produtoEstoque.tpcontrole &&
        this.produtoEstoque.tpcontrole !== 'Nenhum'
      );
    },
    tipoIsExtracao() {
      return this.produtoEstoque && this.produtoEstoque.tpcontrole === 'Extração';
    },
    tipoIsConcurso() {
      return this.produtoEstoque && this.produtoEstoque.tpcontrole === 'Concurso';
    },
    tipoIsEvento() {
      return this.produtoEstoque && this.produtoEstoque.tpcontrole === 'Evento';
    },
    labelTpcontrole() {
      let label = 'Extração';

      if (this.tipoIsEvento) {
        label = 'Ano do Evento';
      } else if (this.tipoIsConcurso) {
        label = 'Concurso';
      }

      return label;
    },
  },
  beforeMount() {
    bus.$off('openModalAdicionarRemoverEstoqueFuncionario');
  },
  mounted() {
    bus.$on('openModalAdicionarRemoverEstoqueFuncionario', this.init);
  },
  destroyed() {
    bus.$off('openModalAdicionarRemoverEstoqueFuncionario');
  },
};
</script>

<style lang="stylus">
.input_error.q-field--standard .q-field__control:before {
  border-bottom: 2px solid #ea4335 !important;
}
.input_error.q-field--standard .q-field__control:after {
  background: #ea4335 !important;
}
</style>
