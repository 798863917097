import axios from "@/axios-auth";

const state = {
  editUsers: [],
  OpenDialogNovoLink: false,
  OpenDialogAlterarSenha: false,
  isPerfil: false,
  urlAreaCliente: null,
};

const mutations = {
  editUsers: (state, userData) => {
    state.editUsers = userData;
  },
  OpenDialogNovoLink: (state, userData) => {
    state.OpenDialogNovoLink = userData;
  },
  OpenDialogAlterarSenha: (state, userData) => {
    state.OpenDialogAlterarSenha = userData;
  },
  isPerfil: (state, userData) => {
    state.isPerfil = userData;
  },
  urlAreaCliente: (state, userData) => {
    state.urlAreaCliente = userData;
  },
};

const actions = {
  editUsers({ commit }) {
    axios.get("/users").then(res => {
      if (res.data.success) {
        commit("editUsers", res.data.data.cadastros);
      }
    });
  }
};

const getters = {
  editUsers: state => {
    return state.editUsers;
  },
  OpenDialogNovoLink: state => {
    return state.OpenDialogNovoLink;
  },
  OpenDialogAlterarSenha: state => {
    return state.OpenDialogAlterarSenha;
  },
  isPerfil: state => {
    return state.isPerfil;
  },
  urlAreaCliente: state => {
    return state.urlAreaCliente;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
