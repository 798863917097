<template>
  <div id="notificacoes">
    <q-card style="overflow:hidden;opacity: 0.5">
      <q-card-section>
        <div class="text-h6">Notificações</div>
      </q-card-section>

      <q-card-section>
        <div class="text-center">Em Breve</div>
      </q-card-section>

      <q-card-actions></q-card-actions>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "notificacoes"
};
</script>

<style lang="stylus" scoped></style>
