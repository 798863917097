import axios from "@/axios-auth";
import { Notify } from "quasar";
import moment from "moment";

const state = {
  isPermissoCofreInteligente: true,
  cofreDepositos: [],
  loadingCofreDepositos: false,
  pagingCofreDepositos: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null,
  },
  filtrosCofreDepositos: {
    dtini: '',
    dtfim: '',
    importado: '',
    cadastroId: '',
  },
  cofreDeposito: {},
  openDialogOpcoesCofreInteligente: false,
  openDialogDetalharCofreDeposito: false,
  loadingDeposito: false,
};

const mutations = {
  setIsPermissoCofreInteligente: (state, userData) => {
    state.isPermissoCofreInteligente = userData;
  },
  setCofreDepositos: (state, userData) => {
    state.cofreDepositos = userData;
  },
  setLoadingCofreDepositos: (state, userData) => {
    state.loadingCofreDepositos = userData;
  },
  setPagingCofreDepositos: (state, userData) => {
    state.pagingCofreDepositos = userData;
  },
  setCofreDeposito: (state, userData) => {
    state.cofreDeposito = userData;
  },
  setOpenDialogOpcoesCofreInteligente: (state, userData) => {
    state.openDialogOpcoesCofreInteligente = userData;
  },
  setOpenDialogDetalharCofreDeposito: (state, userData) => {
    state.openDialogDetalharCofreDeposito = userData;
  },
  setLoadingDeposito: (state, userData) => {
    state.loadingDeposito = userData;
  },
  setFiltrosCofreDepositos: (state, userData) => {
    state.filtrosCofreDepositos = userData;
  },
};

const actions = {
  loadCofreDepositos: ({ commit, state }) => {
    if (state.loadingCofreDepositos) {
      return;
    }

    if (!state.pagingCofreDepositos.nextPage) {
      return;
    }

    let page = state.pagingCofreDepositos.page + 1;

    commit("setPagingCofreDepositos", {
      page: page,
    });
    commit('setLoadingCofreDepositos', true);

    axios.get("/cofre-inteligente", {
      params: {
        page: state.pagingCofreDepositos.page,
        dtini: state.filtrosCofreDepositos.dtini,
        dtfim: state.filtrosCofreDepositos.dtfim,
        importado: state.filtrosCofreDepositos.importado,
        cadastroId: state.filtrosCofreDepositos.cadastroId,
      },
    })
      .then(res => {
        commit('setLoadingCofreDepositos', false);

        if (!res.data.success) {
          throw new Error();
        }

        commit("setFiltrosCofreDepositos", res.data.filters);
        commit("setPagingCofreDepositos", res.data.paging);
        commit("setCofreDepositos", state.cofreDepositos.concat(res.data.cofreDepositos));
      })
      .catch(() => {
        Notify.create({
          message: 'Houve um erro ao listar os depósitos',
          color: "red",
          position: "top-right"
        });

        commit('setIsPermissoCofreInteligente', false);
      });
  },
  loadOpcoesCofreInteligente: ({ commit }) => {
    axios.get("/cofre-inteligente/opcoes")
      .then(res => {
        if (!res.data.success) {
          throw new Error();
        }

        commit('setOpcoesCofreInteligente', res.data.opcoes);
      })
      .catch(() => {
        Notify.create({
          message: 'Houve um erro ao listar as opções',
          color: "red",
          position: "top-right"
        });
      });
  },
  resetCofreDepositos: ({ commit }) => {
    commit("setPagingCofreDepositos", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });
    commit("setCofreDepositos", []);
  },
  changeFilterPeriodoCofreDeposito: ({ commit, dispatch }, userData) => {
    if (state.loadingCofreDepositos) return;

    let dtini = state.filtrosCofreDepositos.dtini.split("-");
    let dtfim = state.filtrosCofreDepositos.dtfim.split("-");

    let i = moment(new Date(dtini[2], dtini[1] - 1, dtini[0]));
    let f = moment(new Date(dtfim[2], dtfim[1] - 1, dtfim[0]));

    if (userData === "prev") {
      i.subtract(1, "months");
      f.subtract(1, "months");
    }

    if (userData === "next") {
      i.add(1, "months");
      f.add(1, "months");
    }

    commit("setFiltrosCofreDepositos", {
      dtini: i.startOf("month").format("DD-MM-YYYY"),
      dtfim: f.endOf("month").format("DD-MM-YYYY")
    });

    dispatch("resetCofreDepositos");
    dispatch("loadCofreDepositos");
  },
  updateFiltrosCofreDepositos: ({ commit, dispatch, state }, userData) => {
    if (userData) {
      commit("setFiltrosCofreDepositos", {
        ...state.filtrosCofreDepositos,
        ...userData,
      });
    } else {
      commit("setFiltrosCofreDepositos", state.filtrosCofreDepositos);
    }

    dispatch("resetCofreDepositos");
    dispatch("loadCofreDepositos");
  },
  toggleDialogOpcoesCofreInteligente: ({ commit, state }) => {
    commit("setOpenDialogOpcoesCofreInteligente", !state.openDialogOpcoesCofreInteligente);
  },
  toggleDialogDetalharCofreDeposito: ({ commit, state }) => {
    commit("setOpenDialogDetalharCofreDeposito", !state.openDialogDetalharCofreDeposito);
  },
};

const getters = {
  isPermissoCofreInteligente: state => {
    return state.isPermissoCofreInteligente;
  },
  cofreDepositos: state => {
    return state.cofreDepositos;
  },
  loadingCofreDepositos: state => {
    return state.loadingCofreDepositos;
  },
  pagingCofreDepositos: state => {
    return state.pagingCofreDepositos;
  },
  cofreDeposito: state => {
    return state.cofreDeposito;
  },
  openDialogOpcoesCofreInteligente: state => {
    return state.openDialogOpcoesCofreInteligente;
  },
  openDialogDetalharCofreDeposito: state => {
    return state.openDialogDetalharCofreDeposito;
  },
  loadingDeposito: state => {
    return state.loadingDeposito;
  },
  filtrosCofreDepositos: state => {
    return state.filtrosCofreDepositos;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
