<template>
  <div id="tourFuncionarios">
    <q-card-section class="text-center" v-if="loadFuncionarios">
      <q-spinner color="blue-7" size="4em" style="position: relative" />
    </q-card-section>
    <q-card-section v-if="!loadFuncionarios">
      <p class="text-justify">
        Agora será necessário o cadastro mínimo de sua equipe e para isso precisaremos do nome,
        telefone e e-mail de cada um deles. Vale ressaltar que os campos necessários serão apenas
        nome e e-mail.
        <br />Inclusive o endereço de e-mail será utilizado para realizar login no sistema.
      </p>

      <div
        v-for="(funcionario, index) in funcionarios"
        :key="funcionario.id"
        style="margin-bottom: 20px"
        class="row q-col-gutter-md"
      >
        <div class="col-12 col-md-4">
          <q-input
            @keyup.enter="
              funcionario.id && saveListado(funcionario, index);
              pulaProx(index);
            "
            @blur="funcionario.id && saveListado(funcionario, index)"
            square
            :ref="`email${index}`"
            v-model="funcionario.email"
            label="E-mail"
            :error="errors.email && !funcionario.valid ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.email" :key="error + i">{{ error }}</span>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-4">
          <q-input
            square
            :ref="`telefone${index}`"
            mask="(##)#####-####"
            v-model="funcionario.telefone"
            label="Telefone"
            @keyup.enter="
              funcionario.id && saveListado(funcionario, index);
              pulaProxT(index);
            "
            @blur="funcionario.id && saveListado(funcionario, index)"
            :error="errors.telefone && !funcionario.valid ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.telefone" :key="error + i">{{ error }}</span>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-4">
          <q-input
            square
            :ref="`funcionario${index}`"
            v-model="funcionario.nome"
            @keyup.enter="
              saveListado(funcionario, index);
              pulaLinha(index);
            "
            @blur="saveListado(funcionario, index)"
            label="Nome"
            :error="errors.nome && !funcionario.valid ? true : false"
          >
            <template v-slot:append v-if="funcionario.valid">
              <q-icon name="check" color="green" />
            </template>
            <template v-slot:error>
              <span v-for="(error, i) in errors.nome" :key="error + i">{{ error }}</span>
            </template>
          </q-input>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <q-btn
          icon="add"
          label="Adicionar novo funcionário..."
          color="white text-blue-7"
          flat
          clickable
          @click="adcFunc"
        ></q-btn>
      </div>
    </q-card-section>
  </div>
</template>

<script>
import axios from '@/axios-auth';
import { Notify } from 'quasar';
export default {
  name: 'tour-funcionarios',
  data() {
    return {
      funcionarios: [],
      loadFuncionarios: false,
      addNovo: true,
      loadNovo: false,
      newFuncionario: {
        nome: '',
        email: '',
        telefone: '',
        cliente: false,
        funcionario: true,
      },
      errors: false,
      saving: false,
    };
  },
  methods: {
    pulaProx(val) {
      this.$refs[`telefone${val}`][0].select();
    },
    pulaProxT(val) {
      this.$refs[`funcionario${val}`][0].select();
    },
    pulaLinha(val) {
      if (!this.$refs[`email${val + 1}`]) return;
      this.$refs[`email${val + 1}`][0].select();
    },
    adcFunc() {
      this.funcionarios.push({
        nome: null,
        email: null,
        telefone: null,
        funcionario: true,
        cliente: false,
        valid: false,
      });
    },
    cancelaNovo() {
      this.addNovo = true;
    },
    addFuncionario() {
      this.addNovo = false;
    },
    listar() {
      this.loadFuncionarios = true;
      axios.get('/users/tour?passo=4').then(res => {
        for (let i = 0; i < res.data.funcionarios.length; i++) {
          res.data.funcionarios[i].valid = false;
        }
        this.funcionarios = res.data.funcionarios;
        this.loadFuncionarios = false;
        for (let i = 0; i < this.funcionarios.length; i++) {
          if (!this.funcionarios[i].nome) {
            this.funcionarios[i].nome = '';
          } else {
            if (this.funcionarios[i].nome != null) {
              this.funcionarios[i].valid = true;
            }
          }
        }
      });
    },
    saveListado(funcionario, index) {
      if (funcionario.saving) return;

      const newFuncionario = JSON.parse(JSON.stringify(this.funcionarios[index]));
      newFuncionario.saving = true;
      this.$set(this.funcionarios, index, newFuncionario);

      let url = !funcionario.id ? '/cadastros/add' : `/cadastros/edit/${funcionario.id}`;

      funcionario.funcionario = true;
      funcionario.cliente = false;
      funcionario.tour = true;
      axios
        .post(url, funcionario)
        .then(res => {
          const newFuncionario = JSON.parse(JSON.stringify(this.funcionarios[index]));

          newFuncionario.saving = false;
          newFuncionario.valid = res.data.success;

          if (!res.data.success) {
            this.errors = res.data.errors;

            this.$set(this.funcionarios, index, newFuncionario);
            return;
          }

          newFuncionario.id = res.data.data.cadastro.id;
          newFuncionario.nome = res.data.data.cadastro.nome;
          newFuncionario.email = res.data.data.cadastro.email;
          newFuncionario.telefone = res.data.data.cadastro.telefone;

          this.errors = {};
          this.$set(this.funcionarios, index, newFuncionario);
        })
        .catch(() => {
          funcionario.saving = false;
          Notify.create({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    save() {
      this.loadNovo = true;
      if (this.addNovo === true) {
        this.loadNovo = false;
        return;
      }
      if (this.newFuncionario.nome === '') {
        this.loadNovo = false;
        this.errors = true;
        return;
      }
      for (let i = 0; i < this.newFuncionario.length; i++) {
        this.newFuncionario[i].funcionario = true;
        this.newFuncionario[i].cliente = false;
      }
      axios
        .post('/cadastros/add', this.newFuncionario)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: this.newFuncionario.nome + ' cadastrado com sucesso!',
              color: 'green',
              position: 'top-right',
            });

            this.addNovo = true;
            this.newFuncionario = {};
            this.errors = false;
          } else {
            Notify.create({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
          }
          this.loadNovo = false;
        })
        .catch(error => {
          otify.create({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });

          this.addNovo = true;
          this.loadNovo = false;
          this.newFuncionario = {};
          this.errors = res.data.errors;
        });
    },
  },
  mounted() {
    this.listar();
  },
};
</script>

<style lang="stylus" scoped></style>
