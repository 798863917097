<template>
  <div id="integracaoHubspotParametros">
    <card-section-sem-permissao v-if="semPermissao" />

    <div v-else>
      <!-- <div class="text-right">
        <q-btn
          flat
          round
          dense
          v-close-popup
          color="grey-8"
          class="q-ml-auto"
          icon="help_outline"
          @click="openDialogAjuda()"
        />
      </div> -->

      <div class="text-center" v-if="loadingConfiguracoes">
        <q-spinner color="blue-7" size="3em" />
      </div>

      <div class="row q-col-gutter-lg" v-else>

        <div class="col-12 col-md-6">
          <q-input
            v-model="config.integracoes_hubspot_url"
            label="Sua url"
            readonly
          >
            <template v-slot:append>
              <q-btn
                flat
                round
                dense
                @click="copyText(config.integracoes_hubspot_url, 'url', 'a')"
                icon="fa fa-clipboard"
                size="12px"
              />
            </template>
          </q-input>
        </div>

        <div class="col-12 col-md-6">
          <q-input
            v-model="config.integracoes_hubspot_token"
            label="Token"
          >
          </q-input>
        </div>
      </div>

      <div class="text-right" style="margin-top: 24px;">
        <q-btn
          color="green"
          label="Salvar"
          :loading="salvandoConfiguracoes"
          @click="salvarConfiguracoes()"
        />
      </div>
    </div>

    <q-dialog v-model="dialogAjuda">
      <q-card style="width: 90%; max-width: 400px;">

        <q-card-section class="row items-center">
          <div class="text-h6">Integração com Hubspot</div>
          <q-space />
          <q-btn
            flat
            round
            dense
            v-close-popup
            icon="close"
            @click="closeDialogAjuda()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          Texto ou vídeo de ajuda
        </q-card-section>

      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import axios from '@/axios-auth';
import { copyToClipboard } from 'quasar';

export default {
  name: 'integracao-hubspot-parametros',
  data() {
    return {
      dialogAjuda: false,
      salvandoConfiguracoes: false,
      loadingConfiguracoes: false,
      planosdecontas: [],
      planosdecontasOptions: [],
      semPermissao: false,
      config: {
        integracoes_hubspot_url: '',
        integracoes_hubspot_token: '',
      },
    };
  },
  methods: {
    openDialogAjuda() {
      this.dialogAjuda = true;
    },
    closeDialogAjuda() {
      this.dialogAjuda = false;
    },
    loadConfiguracoes() {
      this.loadingConfiguracoes = true;

      axios
        .get('/integracoes/hubspot')
        .then(res => {
          this.loadingConfiguracoes = false;

          if (!res.data.success) {
            throw new Error();
          }

          this.semPermissao = false;
          this.config = res.data.config;
          this.loadPlanosdecontas();
        })
        .catch(() => {
          this.loadingConfiguracoes = false;
          this.semPermissao = true;
        });
    },
    salvarConfiguracoes(renovar = false) {
      this.salvandoConfiguracoes = !renovar;

      let url = '/integracoes/hubspot';
      const data = JSON.parse(JSON.stringify(this.config));

      if (renovar === true) {
        url += '?token=1';
      }

      if (typeof data.integracoes_hubspot_planodeconta_parcelas === 'object') {
        data.integracoes_hubspot_planodeconta_parcelas = data.integracoes_hubspot_planodeconta_parcelas.id;
      }
      if (typeof data.integracoes_hubspot_planodeconta_faturamento === 'object') {
        data.integracoes_hubspot_planodeconta_faturamento = data.integracoes_hubspot_planodeconta_faturamento.id;
      }

      axios
        .post(url, data)
        .then(res => {
          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right'
          });

          if (!res.data.success) {
            return;
          }

          this.salvandoConfiguracoes = false;

          if (renovar) {
            this.config.integracoes_hubspot_token = res.data.config.integracoes_hubspot_token;
          } else {
            this.config = res.data.config;
            this.updatePlanodecontasParcelas();
            this.updatePlanodecontasFaturamento();
          }
        })
        .catch(() => {
          this.salvandoConfiguracoes = false;
          this.$q.notify({
            message: !renovar
              ? 'Houve um erro ao salvar as configurações, tente novamente!'
              : 'Houve um erro ao renovar o token, tente novamente!',
            color: 'red',
            position: 'top-right',
          });
      });
    },
    copyText(text, nomeItemCopia, artigo = 'o') {
      nomeItemCopia = nomeItemCopia.charAt(0).toUpperCase() + nomeItemCopia.slice(1);

      copyToClipboard(text)
        .then(() => {
          this.$q.notify({
            message: `${nomeItemCopia} copiad${artigo}`,
            color: 'green',
            position: 'top-right',
          });
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao tentar copiar seu texto',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    loadPlanosdecontas() {
      axios
        .get('/plano-contas?cd=C')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.planosdecontas = res.data.planodecontas;
          this.updatePlanodecontasParcelas();
          this.updatePlanodecontasFaturamento();
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    filterPlanosdecontas (val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.planosdecontasOptions = this.planosdecontas.filter(
          planodecontas => planodecontas.titulo.toLowerCase().includes(needle)
        );
      });
    },
    updatePlanodecontasParcelas() {
      if (!this.config.integracoes_hubspot_planodeconta_parcelas) {
        return;
      }

      this.config.integracoes_hubspot_planodeconta_parcelas = this.planosdecontas
        .find(planodecontas => planodecontas.id === parseInt(this.config.integracoes_hubspot_planodeconta_parcelas));
    },
    updatePlanodecontasFaturamento() {
      if (!this.config.integracoes_hubspot_planodeconta_faturamento) {
        return;
      }

      this.config.integracoes_hubspot_planodeconta_faturamento = this.planosdecontas
        .find(planodecontas => planodecontas.id === parseInt(this.config.integracoes_hubspot_planodeconta_faturamento));
    },
  },
  mounted() {
    this.loadConfiguracoes();
  },
};
</script>

<style lang="stylus" scoped>

</style>
