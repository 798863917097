<template>
  <div style="width:800px;">
    <q-dialog v-model="openChamadasLogsModal" persistent>
      <q-card style="width: 850px; max-width: 80vw">
        <q-card-section class="row items-center justify-center">
          <div class="text-h6">Histórico de Chamadas</div>
          <q-space />

          <q-btn-group class="q-ml-md" rounded flat no-wrap no-caps>
              <q-btn
                class="white text-blue-7"
                clickable
                padding="sm"
                @click="changeFilterPeriodo('prev')"
                rounded
                icon="chevron_left"
              />

              <q-btn
                class="white text-blue-7"
                rounded
                :label="dtini"
                padding="sm"
              >
                <template v-slot:default>
                  <q-popup-proxy ref="qDateDtini" transition-show="scale" transition-hide="scale">
                    <q-date
                      mask="DD/MM/YYYY"
                      minimal
                      today-btn
                      v-model="filtros.dtini"
                      @input="value => alterarDataInicio(value)"
                    />
                  </q-popup-proxy>
                </template>
              </q-btn>

              <q-btn
                class="white text-blue-7"
                rounded
                :label="dtfim"
                padding="sm"
              >
                <template v-slot:default>
                  <q-popup-proxy ref="qDateDtfim" transition-show="scale" transition-hide="scale">
                    <q-date
                      mask="DD/MM/YYYY"
                      minimal
                      today-btn
                      v-model="filtros.dtfim"
                      @input="value => alterarDataFim(value)"
                    />
                  </q-popup-proxy>
                </template>
              </q-btn>

              <q-btn
                class="white text-blue-7"
                clickable
                padding="sm"
                @click="changeFilterPeriodo('next')"
                rounded
                icon="chevron_right"
              />
            </q-btn-group>

            <q-btn icon="close" flat round dense v-close-popup @click="closeModal" />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="row q-col-gutter-md">
            <q-table
              flat
              dense
              row-key="id"
              :data="chamadasLogs"
              :columns="columns"
              :loading="loadingChamadasLogs"
              :rows-per-page-options="[0]"
              separator="horizontal"
              no-data-label="Nenhuma chamada encontrada"
              style="width: 100%"
            >
              <template v-slot:bottom></template>
              <template v-slot:body="props">
                <q-tr :props="props" style="height: 40px">
                  <q-td key="dados">
                    {{ formatDateTime(props.row.created) }}
                  </q-td>

                  <q-td key="chamada_id">
                    {{ props.row.chamada_id }}
                  </q-td>

                  <q-td key="user_nome">
                    {{ props.row.user.nome }}
                  </q-td>

                  <q-td key="cadastro_nome">
                    {{ props.row.cadastro.nome }}
                  </q-td>

                  <q-td key="ramal">
                    {{ props.row.ramal }}
                  </q-td>

                  <q-td key="numero">
                    {{ props.row.numero }}
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </div>
          <div class="text-right q-mt-md">
            <q-btn
              label="mostrar mais"
              :disable="paginationBtn"
              color="cursor-pointer bg-blue-7 text-white"
              @click="changePagination"
            />
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'ModalChamadasLogs',
  data() {
    return {
      dtini: '',
      dtfim: '',
      filtros: {
        dtini: '',
        dtfim: '',
        page: 1
      }
    };
  },
  methods: {
    list(pagination) {
      if (this.loadingChamadasLogs) return;
      if (!pagination) this.filtros.page = 1;

      this.$store.dispatch("getChamadasLogs", this.filtros);
    },
    getFiltros() {
      this.filtros.dtini = moment().startOf('month').format('DD-MM-YYYY');
      this.filtros.dtfim = moment().endOf('month').format('DD-MM-YYYY');
      this.dtini = moment().startOf('month').format('DD/MM/YYYY');
      this.dtfim = moment().endOf('month').format('DD/MM/YYYY');
    },
    closeModal() {
      this.filtros.page = 1;
      this.$store.commit("openChamadasLogsModal", false);
      this.$store.commit("chamadasLogs", []);
    },
    changeFilterPeriodo(periodo) {
      if (this.loadingChamadasLogs) return;

      let dtIni = moment(this.filtros.dtini, 'DD-MM-YYYY');
      let dtFim = moment(this.filtros.dtfim, 'DD-MM-YYYY');

      if (periodo === 'prev') {
        dtIni.subtract(1, 'months');
        dtFim.subtract(1, 'months');
      }

      if (periodo === 'next') {
        dtIni.add(1, 'months');
        dtFim.add(1, 'months');
      }

      this.filtros.dtini = dtIni.startOf('month').format('DD-MM-YYYY');
      this.filtros.dtfim = dtFim.endOf('month').format('DD-MM-YYYY');
      this.dtini = dtIni.startOf('month').format('DD/MM/YYYY');
      this.dtfim = dtFim.endOf('month').format('DD/MM/YYYY');
      this.filtros.page = 1;
      this.$store.commit("chamadasLogs", []);

      this.list();
    },
    alterarDataInicio(value) {
      this.filtros.dtini = value.replace(/\//g, '-');
      this.dtini = value.replace(/\//g, '/');
      this.$refs.qDateDtini.hide();
      this.$store.commit("chamadasLogs", []);

      this.list();
    },
    alterarDataFim(value) {
      this.filtros.dtfim = value.replace(/\//g, '-');
      this.dtfim = value.replace(/\//g, '/');
      this.$refs.qDateDtfim.hide();
      this.$store.commit("chamadasLogs", []);

      this.list();
    },
    formatDateTime(value) {
      return moment(value).format('DD/MM/YYYY HH:mm') + 'h';
    },
    changePagination() {
      if (this.loadingChamadasLogs) return;

      this.filtros.page += 1;
      this.list(true);
    }
  },
  computed: {
    ...mapGetters([
      "openChamadasLogsModal",
      "loadingChamadasLogs",
      "chamadasLogs",
      "paginationBtn"
    ]),
    columns() {
      return [
        {
          name: "data",
          required: true,
          label: "Data",
          align: "left",
          field: row => row.created,
          sortable: false,
        },
        {
          name: "chamada_id",
          required: true,
          label: "Chamada ID",
          align: "left",
          field: row => row.chamada_id,
          sortable: true,
        },
        {
          name: "user",
          required: true,
          label: "Usuário",
          align: "left",
          field: row => row.user.nome,
          sortable: true,
        },
        {
          name: "cadastro",
          required: true,
          label: "Cadastro",
          align: "left",
          field: row => row.cadastro.nome,
          sortable: true,
        },
        {
          name: "ramal",
          required: true,
          label: "Ramal",
          align: "left",
          field: row => row.ramal,
          sortable: true,
        },
        {
          name: "numero",
          required: true,
          label: "Número",
          align: "left",
          field: row => row.numero,
          sortable: true,
        },
      ];

    }
  },
  created() {
    this.getFiltros();
  }
};
</script>