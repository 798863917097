<template>
  <div id="modalCaixaVenda">
    <q-dialog v-model="OpenDialogVendaCaixas" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Venda</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="clear()" />
        </q-card-section>
        <q-separator />
        <venda-fiado
          ref="refVendaFiado"
          @autocomplete="recebeEmitDados"
        ></venda-fiado>
        <q-card-section style="padding-top: 0">
          <div class="q-pr-md q-pl-md">
            <div class="q-col-gutter-md">
              <div class="col-12" @keydown.enter.prevent="proximo('valor')">
                <q-input
                  bottom-slots
                  :error="errorsCaixas.nome ? true : false"
                  v-model="venda.descricao"
                  :label="'Produto'"
                  :disable="isSaldoFinal"
                  name="descricaoCaixa"
                  autocomplete="off"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsCaixas.nome"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
              <div class="col-12" @keydown.enter.prevent="proximo('salvar')">
                <q-field
                  id="valorCaixa"
                  v-model="venda.valor"
                  label="Valor"
                  bottom-slots
                  :error="errorsCaixas.valor ? true : false"
                  :disable="isSaldoFinal"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsCaixas.valor"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-field>
              </div>

              <div class="col-12" v-if="gatewayPixCaixa && isPrazo">
                <q-checkbox
                  color="green"
                  label="Pagar via PIX"
                  v-model="pagarViaPix"
                />
              </div>

              <div class="col-12" v-if="exibirUtilizarSaldoCliente">
                <q-checkbox
                  color="green"
                  label="Utilizar saldo do cliente"
                  :disable="!enableUtilizarSaldoCliente"
                  v-model="utilizarSaldoCliente"
                />

                <div v-show="exibirSaldoCliente">
                  <div>
                    Saldo Atual:
                    <span :class="classSaldoAtualCliente">
                      R$ {{ saldoAtualCliente | currency }}
                    </span>
                  </div>
                  <div>
                    Saldo Após a Liquidação:
                    <span :class="classNovoSaldoCliente">
                      R$ {{ novoSaldoCliente | currency }}
                    </span>
                  </div>
                </div>
                <div v-if="loadingSaldoCliente">
                  <q-spinner color="grey-10" size="1em" />
                </div>
              </div>

            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar & Novo"
            clickable
            @click="envia('Novo')"
            :disable="addItemCaixa2 || isSaldoFinal"
            :loading="addItemCaixa2"
            class="bg-grey-6 text-white"
            style="margin-right: 10px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
          <q-btn
            flat
            label="Salvar"
            clickable
            id="salvar"
            @click="envia()"
            :loading="addItemCaixa"
            :disable="addItemCaixa || isSaldoFinal"
            class="bg-green text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify } from "quasar";
import { Money } from "v-money";
import { bus } from "@/main";
const moment = require("moment");
export default {
  name: "modal-caixa-venda",
  components: { Money },
  data() {
    return {
      count: 0,
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      autocompleteResult: {},
      utilizarSaldoCliente: false,
      loadingSaldoCliente: false,
      saldoAtualCliente: 0.00,
      pagarViaPix: false,
    };
  },
  methods: {
    clear() {
      this.$store.commit("OpenDialogVendaCaixas", false);
      this.$store.commit("errorsCaixas", {});
      this.utilizarSaldoCliente = false;
      this.pagarViaPix = false;
    },
    async envia(salvareNovo) {
      if (this.salvandoVendaCaixa) {
        return;
      }

      if (this.isPrazo) {
        if (!this.autocompleteResult.id) {
          const novoCadastro = {
            ativo: true,
            cliente: true,
            nome: this.autocompleteResult.name
          };

          const response = await axios.post("/cadastros/add", novoCadastro);

          if (!response) {
            Notify.create({
                message:
                  "Não foi possível contactar, ou você não possui permissão!",
                color: "red",
                position: "top-right"
              });
          }

          if (response.data.success) {
            this.updateAutocompleteCadastro(response.data.data.cadastro);
            this.autocompleteResult = response.data.data.cadastro;
          } else {
            Notify.create({
              message: response.data.message,
              color: "orange",
              position: "top-right"
            });
          }
        }
      }

      const body = {
        tipo: this.acaoCaixa,
        valor: this.venda.valor,
        nome: this.venda.descricao,
        movconta_id: this.movconta_id_caixa,
        cadastro_id: null,
        conta_id: null,
        vencimento: null,
        utilizar_saldo_cliente: this.utilizarSaldoCliente,
      };

      if (this.isPrazo) {
        body.tipo = 5;
        body.vencimento = this.movconta_vencimento;
        body.conta_id = this.conta_id_caixa;
        body.pagar_via_pix = this.pagarViaPix;

        if (this.autocompleteResult.length === 0) {
          Notify.create({
            message:
              "Não foi possível realizar o cadastro, por favor realize o cadastro antes da venda!",
            color: "red",
            position: "top-right"
          });
          return false;
        }

        body.cadastro_id = this.autocompleteResult.id;
      }

      this.$store.commit("salvareNovo", salvareNovo);
      this.$store.commit("addItemCaixa2", true);
      this.$store.commit("addItemCaixa", true);

      this.$store.dispatch("alimentaCaixa", body);

      if (salvareNovo) {
        this.$store.commit("notClear", false);
      }
      this.$store.commit("blockValor", false);
    },
    proximo(val) {
      if (val === "valor") {
        document.getElementsByName("valorCaixa")[0].focus();
      }
      if (val === "salvar") {
        this.envia("Novo");
      }
    },
    recebeEmitDados(result) {
      this.autocompleteResult = result;

      if (this.autocompleteResult && this.autocompleteResult.id != null) {
        Notify.create({
          message: this.autocompleteResult.name + " foi selecionado.",
          color: "green",
          position: "top-right"
        });
      }
    },
    updateAutocompleteCadastro(cadastro){
      this.autocompleteResult = cadastro;

      bus.$emit("updateAutocompleteField", {
        id: cadastro.id,
        name: cadastro.nome,
        label: cadastro.nome,
        canEdit: true,
      });
    },
    loadSaldoCliente() {
      if (!this.extratoClienteInstalado || this.loadingSaldoCliente) return;

      if (!this.autocompleteResult || !this.autocompleteResult.id) {
        return;
      }

      this.loadingSaldoCliente = true;

      const cadastroId = this.autocompleteResult.id;

      axios
        .get(`/financeiro/extrato-cliente/${cadastroId}?apenas_saldo=1`)
        .then(res => {
          this.loadingSaldoCliente = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.saldoAtualCliente = res.data.saldo_atual;
        })
        .catch(() => {
          this.loadingSaldoCliente = false;
          this.$q.notify({
            message: 'Houve um erro ao consultar o extrato do cliente',
            color: 'red',
            position: 'top-right',
          });
        });
    },
  },
  computed: {
    ...mapGetters([
      "addItemCaixa",
      "addItemCaixa2",
      "errorsCaixas",
      "isPrazo",
      "acaoCaixa",
      "venda",
      "isSaldoFinal",
      "movconta_id_caixa",
      "conta_id_caixa",
      "movconta_vencimento",
      "conta_id_caixa",
      "cadastroReturn",
      "salvandoVendaCaixa",
      "extratoClienteInstalado",
      "gatewayPixCaixa",
    ]),
    OpenDialogVendaCaixas: {
      get() {
        return this.$store.getters.OpenDialogVendaCaixas;
      },
      set() {}
    },
    exibirUtilizarSaldoCliente() {
      return this.extratoClienteInstalado && this.isPrazo;
    },
    enableUtilizarSaldoCliente() {
      return this.venda.valor > 0;
    },
    novoSaldoCliente() {
      return this.saldoAtualCliente - parseFloat(this.venda.valor);
    },
    classSaldoAtualCliente() {
      if (this.saldoAtualCliente > 0) return 'text-green';
      if (this.saldoAtualCliente < 0) return 'text-red';

      return '';
    },
    classNovoSaldoCliente() {
      if (this.novoSaldoCliente > 0) return 'text-green';
      if (this.novoSaldoCliente < 0) return 'text-red';

      return '';
    },
    exibirSaldoCliente() {
      return this.autocompleteResult && this.autocompleteResult.id && !this.loadingSaldoCliente;
    },
  },
  watch: {
    OpenDialogVendaCaixas() {
      if (!this.OpenDialogVendaCaixas) return;

      this.$store.commit("errorsCaixas", {});
      this.utilizarSaldoCliente = false;
      this.pagarViaPix = false;
    },
    "autocompleteResult"() {
      if (!this.autocompleteResult || !this.autocompleteResult.id) {
        this.saldoAtualCliente = 0;
        return;
      }

      this.loadSaldoCliente();
    },
  },
  mounted() {
    bus.$on("novoCadastroAdicionado", () => {
      this.updateAutocompleteCadastro(this.cadastroReturn);
    });
    bus.$on('resetarModalCaixaVenda', () => {
      this.utilizarSaldoCliente = false;
      this.$set(this, 'autocompleteResult', {});
    });

    document.addEventListener("keyup", event => {
      if (event.ctrlKey && event.shiftKey) {
        if (event.keyCode === 65) {
          this.$refs.refVendaFiado.prazo();
        }
      }
    });
    document.addEventListener("keyup", event => {
      if (event.keyCode === 27) {
        this.clear();
      }
    });
  },
  destroyed() {
    bus.$off("resetarModalCaixaVenda");
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener("keyup");
    next();
  }
};
</script>

<style scoped></style>
