<template>
  <div id="modalDetalharLicenca">
    <q-dialog v-model="OpenDialogDetalharLicenca" persistent>
      <q-card style="width:600px">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6" v-if="licencaEdit">Detalhamento da Licença {{licencaEdit.id}}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-list v-if="cadastros">
            <q-item>
              <q-item-section>
                <q-item-label caption>Código</q-item-label>
                <q-item-label>{{cadastros.codigo}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>Nome</q-item-label>
                <q-item-label>{{cadastros.nome}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>Razão</q-item-label>
                <q-item-label>{{cadastros.razao}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>CPF/CNPJ</q-item-label>
                <q-item-label>{{cadastros.cpfcnpj}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>UF</q-item-label>
                <q-item-label>{{cadastros.estado}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>E-mail</q-item-label>
                <q-item-label v-html="breakLineBar(cadastros.email)"></q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>Telefone</q-item-label>
                <q-item-label v-html="breakLineBar(cadastros.telefone)"></q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-section>
          <div class="q-pa-md">
            <div class="text-center" v-if="licencaEdit">
              <div class="text-h6">{{licencaEdit.codigo_uuid}}</div>
              <div class="text-subtitle1 text-primary">{{licencaEdit.cripted_text}}</div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "modal-detalhar-licenca",
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogDetalharLicenca", false);
      this.$store.dispatch("editLicencaClear");
    },
    breakLineBar(value) {
      if (!value) return "";
      return value.replace(/,/g, "<br>").replace("/", "<br>");
    }
  },
  computed: {
    ...mapGetters(["licencaEdit"]),
    OpenDialogDetalharLicenca: {
      get() {
        return this.$store.getters.OpenDialogDetalharLicenca;
      },
      set() {}
    },
    cadastros() {
      return this.licencaEdit ? this.licencaEdit.cadastro : null ;
    }
  }
};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
