<template>
  <div id="meusAtalhos">
    <q-card>
      <q-card-section>
        <div class="text-h6">
          <q-btn
            flat
            round
            dense
            id="addAtalho"
            :icon="'add'"
            class="q-ml-md text-primary"
            style="margin-right: 10px;margin-left: 0"
            @click="link()"
          />Meus Atalhos
        </div>
      </q-card-section>

      <q-card-section v-if="atalhos && atalhos.length > 0">
        <q-list class="rounded-borders" style="max-width: 100%">
          <q-item-label
            header
          >{{atalhos && atalhos.length > 0 ? 'Atalhos Disponíveis' : 'Nenhum atalho cadastrado'}}</q-item-label>

          <q-item clickable v-ripple v-for="atalho in atalhos" :key="atalho.id">
            <q-item-section top>
              <q-item-label lines="1">
                <span class="text-weight-medium">{{atalho.titulo}}</span>
              </q-item-label>
              <q-item-label caption lines="1">{{atalho.url}}</q-item-label>
            </q-item-section>

            <q-item-section top side>
              <div class="text-grey-8 q-gutter-xs">
                <q-btn
                  clickable
                  @click.exact="deleteLink(atalho)"
                  class="gt-xs bg-negative text-white"
                  size="12px"
                  flat
                  dense
                  round
                  icon="delete_forever"
                />
              </div>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>

      <q-card-actions></q-card-actions>
    </q-card>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify } from "quasar";
import { mapGetters } from "vuex";
export default {
  name: "meus-atalhos",
  data() {
    return {
      perfil: true,
      loadAtalho: false
    };
  },
  methods: {
    link() {
      this.$store.commit("OpenDialogNovoLink", true);
      this.$store.commit("isPerfil", true);
    },
    deleteLink(value) {
      this.loadAtalho = true;
      const del = {
        id: value.id,
        action: "delAtalhos"
      };
      axios
        .post("/users/perfil", del)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });

            this.$store.dispatch("listarUser");
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          this.loadAtalho = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível excluir o Link, ou você não possui perissão!",
            color: "red",
            position: "top-right"
          });
        });
    }
  },
  computed: {
    ...mapGetters({
      atalhos: "atalhosUser",
      buscando: "buscandoUser"
    })
  }
};
</script>

<style lang="stylus" scoped></style>