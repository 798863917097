import { Notify, Loading, Dialog } from "quasar";
import axios from "@/axios-auth";
import router from "@/router/router";
import Vue from "vue";
import { bus } from "@/main";

let moment = require("moment");
moment.locale("pt-br");

const state = {
  buscandoRec: false,
  aguardeEmailRec: false,
  receitaQrcode: null,
  pagingReceitas: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  filtrosReceitas: {
    busca: "",
    dtini: "",
    dtfim: "",
    cadastro_id: null,
    gateway_id: "",
    status_remessa: null,
    status: "Aberto",
    movconta_id: null
  },
  movcontas: [],
  record_count: {
    aberto: 0,
    liquidado: 0,
    cancelado: 0
  },
  counterReceitas: 0,
  contadorReceitas: 0,
  totalReceitas: 0,
  allReceitas: false,
  urlGeral: "",
  urlDetalhe: "",
  dataReceitas: {
    dataArranjada: "",
    anoAtual: ""
  },
  acaoReceitas: "",
  selectediesReceitas: [],
  selectedReceitasCadastros: [],
  realizandoAcaoReceitas: false,
  avl: false,
  loadGateway: false,
  movconta: {
    id: null,
    tipo: 2,
    vencimento: moment().format("DD/MM/YYYY"),
    liquidacao: null,
    dtmovimento: null,
    conta_id: null,
    descricao: null,
    valor: 0.0,
    valor_liquidado: 0.0,
    valor_acrescimo_desconto: 0.0,
    cadastro_id: null,
    cadastro_nome: null,
    nomenclatura_id: null,
    liquidado: false,
    conciliado: false,
    gateway_id: null,
    cadastros_gateways_id: null,
    fatura_id: null,
    shortUrl: null,
    status: "Aberto",
    cobrar: false,
    planodeconta_item: false,
    cadastro: {},
    referencia: "",
  },
  movcontaa: [],
  errorsReceitas: [],
  errorsWhatsApp: [],
  pulseRapido: false,
  pulseCount: 0,
  marcado: false,
  marquinhos: false,
  mTodos: false,
  loadLiquidar: false,
  loadFaturamento: false,
  saveFaturamento: {
    vencimento: null,
    descricao: "",
    referencia: null,
    dtini: null,
    dtfim: null,
    personalizarDescricao: false,
    utilizarDtiniDtfim: false,
    apenasParcelas: false,
    cadastros_ids: [],
    recorrencias_ids: [],
  },
  faturamento: {
    opcoes: {},
  },
  loadModalFaturamento: false,
  timelineReceitas: [],
  timelineActionReceitas: {},
  pagingTimeReceitas: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  cadPraDet: {},
  movPraDet: {},
  movimentacao: {},
  itemsReceitas: [],
  buscandoItems: false,
  gerandoRemessa: false,
  gatewaysDrp: "Forma de Pagamento",
  statusRemessaDrp: 'Status da Remessa',
  btnDisabled: false,
  filtrosDrp: "Aberto",
  receitasAgrupadas: false,
  loadNovoREC: false,
  OpenDialogNovaReceita: false,
  OpenOpcoesAvancadasReceitas: false,
  OpenDialogEnviarEmail: false,
  OpenDialogInclusaoMassa: false,
  OpenDialogFaturar: false,
  OpenDialogAlterarFormaPagamento: false,
  OpenDialogLiquidarMassa: false,
  OpenDialogListarRegistros: false,
  callbackListar: false,
  isPermissoReceitas: true,
  selected: [],
  buscarPorLiquidacaoReceitas: 0,
  receitaArquivos: [],
  loadFiltrosQueryReceitas: false,
  cadastroReceita: {
    isCadastro: false,
    cadastro: {
      cadastro_id: null,
      cadastro_nome: null
    }
  },
  metadadosReceitas: {
    valor_total: 0.00,
  },
  statusRemessaLista: [],
  newRecorrencia: null,
  recorrenciaQrCode: null
};

const mutations = {
  selected: (state, userData) => {
    state.selected = userData;
  },
  buscandoRec: (state, userData) => {
    state.buscandoRec = userData.buscandoRec;
  },
  aguardeEmailRec: (state, userData) => {
    state.aguardeEmailRec = userData.aguardeEmailRec;
  },
  pagingReceitas: (state, userData) => {
    state.pagingReceitas.finder = userData.finder;
    state.pagingReceitas.page = userData.page;
    state.pagingReceitas.current = userData.current;
    state.pagingReceitas.count = userData.count;
    state.pagingReceitas.perPage = userData.perPage;
    state.pagingReceitas.prevPage = userData.prevPage;
    state.pagingReceitas.nextPage = userData.nextPage;
    state.pagingReceitas.pageCount = userData.pageCount;
    state.pagingReceitas.sort = userData.sort;
    state.pagingReceitas.direction = userData.direction;
    state.pagingReceitas.limit = userData.limit;
    state.pagingReceitas.sortDefault = userData.sortDefault;
    state.pagingReceitas.directionDefault = userData.directionDefault;
    state.pagingReceitas.scope = userData.scope;
  },
  filtrosReceitas: (state, userData) => {
    state.filtrosReceitas.busca = userData.busca;
    state.filtrosReceitas.dtini = userData.dtini;
    state.filtrosReceitas.dtfim = userData.dtfim;
    state.filtrosReceitas.cadastro_id = userData.cadastro_id;
    state.filtrosReceitas.gateway_id = userData.gateway_id;
    state.filtrosReceitas.status = userData.status;
    state.filtrosReceitas.movconta_id = userData.movconta_id;
    state.filtrosReceitas.status_remessa = userData.status_remessa;
  },
  movcontas: (state, userData) => {
    state.movcontas = userData.movcontas;
  },
  record_count: (state, userData) => {
    state.record_count.aberto = userData.aberto;
    state.record_count.liquidado = userData.liquidado;
    state.record_count.cancelado = userData.cancelado;
  },
  totalReceitas: (state, userData) => {
    state.totalReceitas = userData.totalReceitas;
  },
  allReceitas: (state, userData) => {
    state.allReceitas = userData.allReceitas;
  },
  urlGeral: (state, userData) => {
    state.urlGeral = userData.urlGeral;
  },
  urlDetalhe: (state, userData) => {
    state.urlDetalhe = userData.urlDetalhe;
  },
  dataReceitas: (state, userData) => {
    state.dataReceitas.dataArranjada = userData.dataArranjada;
    state.dataReceitas.anoAtual = userData.anoAtual;
  },
  counterReceitas: (state, userData) => {
    state.counterReceitas = userData.counterReceitas;
  },
  contadorReceitas: (state, userData) => {
    state.contadorReceitas = userData.contadorReceitas;
  },
  acaoReceitas: (state, userData) => {
    state.acaoReceitas = userData.acaoReceitas;
  },
  selectediesReceitas: (state, userData) => {
    state.selectediesReceitas = userData.selectediesReceitas;
  },
  selectedReceitasCadastros: (state, userData) => {
    state.selectedReceitasCadastros = userData.selectedReceitasCadastros;
  },
  realizandoAcaoReceitas: (state, userData) => {
    state.realizandoAcaoReceitas = userData.realizandoAcaoReceitas;
  },
  avl: (state, userData) => {
    state.avl = userData.avl;
  },
  loadGateway: (state, userData) => {
    state.loadGateway = userData.loadGateway;
  },
  movconta: (state, userData) => {
    state.movconta.id = userData.id;
    state.movconta.tipo = userData.tipo;
    state.movconta.vencimento = userData.vencimento;
    state.movconta.liquidacao = userData.liquidacao;
    state.movconta.dtmovimento = userData.dtmovimento;
    state.movconta.conta_id = userData.conta_id;
    state.movconta.planodeconta_id = userData.planodeconta_id;
    state.movconta.descricao = userData.descricao;
    state.movconta.valor = userData.valor;
    state.movconta.valor_liquidado = userData.valor_liquidado;
    state.movconta.valor_acrescimo_desconto = userData.valor_acrescimo_desconto;
    state.movconta.cadastro_id = userData.cadastro_id;
    state.movconta.cadastro_nome = userData.cadastro_nome;
    state.movconta.nomenclatura_id = userData.nomenclatura_id;
    state.movconta.liquidado = userData.liquidado;
    state.movconta.conciliado = userData.conciliado;
    state.movconta.gateway_id = userData.gateway_id;
    state.movconta.cadastros_gateways_id = userData.cadastros_gateways_id;
    state.movconta.fatura_id = userData.fatura_id;
    state.movconta.shortUrl = userData.shortUrl;
    state.movconta.status = userData.status;
    state.movconta.cobrar = userData.cobrar;
    state.movconta.cadastro = userData.cadastro;
    state.movconta.valor_acrecimo = userData.valor_acrecimo;
    state.movconta.valor_desconto = userData.valor_desconto;
    state.movconta.planodeconta_item = userData.planodeconta_item;
    state.movconta.referencia = userData.referencia;
  },
  movcontaa: (state, userData) => {
    state.movcontaa = userData.movcontaa;
  },
  errorsReceitas: (state, userData) => {
    state.errorsReceitas = userData.errorsReceitas;
  },
  errorsWhatsApp: (state, userData) => {
    state.errorsWhatsApp = userData.errorsWhatsApp;
  },
  marcarDesmarcarTodosReceitas: (state, userData) => {
    state.pulseRapido = userData.pulseRapido;
    state.pulseCount = userData.pulseCount;
    state.marcado = userData.marcado;
    state.marquinhos = userData.marquinhos;
    state.mTodos = userData.mTodos;
  },
  loadLiquidar: (state, userData) => {
    state.loadLiquidar = userData.loadLiquidar;
  },
  loadFaturamento: (state, userData) => {
    state.loadFaturamento = userData.loadFaturamento;
  },
  saveFaturamento: (state, userData) => {
    state.saveFaturamento = userData;
  },
  faturamento: (state, userData) => {
    state.faturamento.opcoes = userData.opcoes;
    state.faturamento.tipos = userData.tipos;
  },
  loadModalFaturamento: (state, userData) => {
    state.loadModalFaturamento = userData.loadModalFaturamento;
  },
  timelineReceitas: (state, userData) => {
    state.timelineReceitas = userData.timelineReceitas;
    state.timelineActionReceitas = userData.timelineAction;
  },
  pagingTimeReceitas: (state, userData) => {
    state.pagingTimeReceitas.finder = userData.finder;
    state.pagingTimeReceitas.page = userData.page;
    state.pagingTimeReceitas.current = userData.current;
    state.pagingTimeReceitas.count = userData.count;
    state.pagingTimeReceitas.perPage = userData.perPage;
    state.pagingTimeReceitas.prevPage = userData.prevPage;
    state.pagingTimeReceitas.nextPage = userData.nextPage;
    state.pagingTimeReceitas.pageCount = userData.pageCount;
    state.pagingTimeReceitas.sort = userData.sort;
    state.pagingTimeReceitas.direction = userData.direction;
    state.pagingTimeReceitas.limit = userData.limit;
    state.pagingTimeReceitas.sortDefault = userData.sortDefault;
    state.pagingTimeReceitas.directionDefault = userData.directionDefault;
    state.pagingTimeReceitas.scope = userData.scope;
  },
  cadPraDet: (state, userData) => {
    state.cadPraDet = userData.cadPraDet;
  },
  movPraDet: (state, userData) => {
    state.movPraDet = userData.movPraDet;
  },
  movimentacao: (state, userData) => {
    state.movimentacao = userData.movimentacao;
  },
  itemsReceitas: (state, userData) => {
    state.itemsReceitas = userData.itemsReceitas;
  },
  buscandoItems: (state, userData) => {
    state.buscandoItems = userData.buscandoItems;
  },
  gerandoRemessa: (state, userData) => {
    state.gerandoRemessa = userData.gerandoRemessa;
  },
  gatewaysDrp: (state, userData) => {
    state.gatewaysDrp = userData.gatewaysDrp;
  },
  statusRemessaDrp: (state, userData) => {
    state.statusRemessaDrp = userData.statusRemessaDrp;
  },
  btnDisabled: (state, userData) => {
    state.btnDisabled = userData.btnDisabled;
  },
  filtrosDrp: (state, userData) => {
    state.filtrosDrp = userData.filtrosDrp;
  },
  receitasAgrupadas: (state, userData) => {
    state.receitasAgrupadas = userData.receitasAgrupadas;
  },
  loadNovoREC: (state, userData) => {
    state.loadNovoREC = userData;
  },
  OpenDialogNovaReceita: (state, userData) => {
    state.OpenDialogNovaReceita = userData;
  },
  OpenOpcoesAvancadasReceitas: (state, userData) => {
    state.OpenOpcoesAvancadasReceitas = userData;
  },
  OpenDialogEnviarEmail: (state, userData) => {
    state.OpenDialogEnviarEmail = userData;
  },
  OpenDialogInclusaoMassa: (state, userData) => {
    state.OpenDialogInclusaoMassa = userData;
  },
  OpenDialogFaturar: (state, userData) => {
    state.OpenDialogFaturar = userData;
  },
  OpenDialogAlterarFormaPagamento: (state, userData) => {
    state.OpenDialogAlterarFormaPagamento = userData;
  },
  OpenDialogLiquidarMassa: (state, userData) => {
    state.OpenDialogLiquidarMassa = userData;
  },
  OpenDialogListarRegistros: (state, userData) => {
    state.OpenDialogListarRegistros = userData;
  },
  callbackListar: (state, userData) => {
    state.callbackListar = userData;
  },
  isPermissoReceitas: (state, userData) => {
    state.isPermissoReceitas = userData;
  },
  buscarPorLiquidacaoReceitas: (state, userData) => {
    state.buscarPorLiquidacaoReceitas = userData;
  },
  receitaArquivos: (state, userData) => {
    state.receitaArquivos = userData;
  },
  pushReceitaArquivo: (state, userData) => {
    state.receitaArquivos.unshift(userData);
  },
  setLoadFiltrosQueryReceitas: (state, userData) => {
    state.loadFiltrosQueryReceitas = userData;
  },
  cadastroReceita: (state, userData) => {
    state.cadastroReceita.isCadastro = userData.isCadastro;
    state.cadastroReceita.cadastro = userData.cadastro;
  },
  setMetadadosReceitas: (state, userData) => {
    state.metadadosReceitas = userData;
  },
  statusRemessaLista: (state, userData) => {
    state.statusRemessaLista = userData;
  },
  receitaQrcode: (state, userData) => {
    state.receitaQrcode = userData;
  },
  newRecorrencia:(state, userData) =>{
    state.newRecorrencia = userData;
  },
  recorrenciaQrCode:(state, userData) =>{
    state.recorrenciaQrCode = userData;
  }
};

const actions = {
  clearCheckbox({ commit }) {
    commit("selectediesReceitas", {
      selectediesReceitas: []
    });
    commit("selected", []);
  },
  listarReceitas: ({ commit, dispatch, state }) => {
    if (state.buscandoRec) {
      return;
    }
    if (!state.pagingReceitas.nextPage) {
      return;
    }

    commit("isPermissoReceitas", true);

    const query = router.history.current.query;

    if (!state.loadFiltrosQueryReceitas && Object.keys(query).length) {
      commit("setLoadFiltrosQueryReceitas", true);

      if (query.cadastro_id) {
        commit("filtrosReceitas", {
          ...state.filtrosReceitas,
          cadastro_id: query.cadastro_id
        });
      }
      if (query.dtini) {
        commit("filtrosReceitas", {
          ...state.filtrosReceitas,
          dtini: query.dtini
        });
      }
      if (query.dtfim) {
        commit("filtrosReceitas", {
          ...state.filtrosReceitas,
          dtfim: query.dtfim
        });
      }
      if (query.status) {
        commit("filtrosReceitas", {
          ...state.filtrosReceitas,
          status: query.status
        });
        commit("filtrosDrp", { filtrosDrp: query.status });
      }
      if (query.gateway_id) {
        commit("filtrosReceitas", {
          ...state.filtrosReceitas,
          gateway_id: query.gateway_id
        });
      }
      if (query.busca) {
        commit("filtrosReceitas", {
          ...state.filtrosReceitas,
          busca: query.busca
        });
      }
      if (query.movconta_id) {
        commit("filtrosReceitas", {
          ...state.filtrosReceitas,
          movconta_id: query.movconta_id
        });
      }
      if (query.status_remessa) {
        commit('filtrosReceitas', {
          ...state.filtrosReceitas,
          status_remessa: query.status_remessa
        });
      }
      if (query.liquidacao !== undefined) {
        commit("buscarPorLiquidacaoReceitas", query.liquidacao);
      }
    }

    commit("buscandoRec", {
      buscandoRec: true
    });
    let page = state.pagingReceitas.page + 1;
    commit("pagingReceitas", {
      page: page
    });

    if (Object.keys(query).length) {
      let newQueryParams = [];

      for (let queryParam in query) {
        if (state.filtrosReceitas[queryParam] !== undefined) {
          newQueryParams.push(
            queryParam + "=" + state.filtrosReceitas[queryParam]
          );
        }
      }

      if (state.buscarPorLiquidacaoReceitas) {
        newQueryParams.push("liquidacao=1");
      }

      let newUrl = "?" + newQueryParams.join("&");

      window.history.replaceState(state.filtrosReceitas, "", newUrl);
    }

    axios
      .get("/financeiro/receitas", {
        params: {
          page: state.pagingReceitas.page,
          busca: state.filtrosReceitas.busca,
          dtini: state.filtrosReceitas.dtini,
          dtfim: state.filtrosReceitas.dtfim,
          status: state.filtrosReceitas.status,
          cadastro_id: state.filtrosReceitas.cadastro_id,
          gateway_id: state.filtrosReceitas.gateway_id,
          movconta_id: state.filtrosReceitas.movconta_id,
          liquidacao: state.buscarPorLiquidacaoReceitas,
          status_remessa: state.filtrosReceitas.status_remessa,
        }
      })
      .then(res => {
        if (res.data.success) {
          let resmovcontas = res.data.data.movcontas;
          for (let i = 0; i < resmovcontas.length; i++) {
            if (
              resmovcontas[i].fatura === null ||
              resmovcontas[i].gateway === null
            ) {
              resmovcontas[i].nadaDaqui = true;
              resmovcontas[i].fatura = {
                id: null,
                nosso_numero: "",
                status_remessa: null,
                status_retorno: null
              };
              resmovcontas[i].gateway = {
                ativo: false,
                icon_url: "",
                id: null,
                pode_enviar: false,
                tipo: null,
                titulo: ""
              };
            }
          }
          commit('statusRemessaLista', res.data.data.status_remessa_lista);
          commit("movcontas", {
            movcontas: state.movcontas.concat(resmovcontas)
          });
          commit("pagingReceitas", {
            finder: res.data.data.paging.finder,
            page: res.data.data.paging.page,
            current: res.data.data.paging.current,
            count: res.data.data.paging.count,
            perPage: res.data.data.paging.perPage,
            prevPage: res.data.data.paging.prevPage,
            nextPage: res.data.data.paging.nextPage,
            pageCount: res.data.data.paging.pageCount,
            sort: res.data.data.paging.sort,
            direction: res.data.data.paging.direction,
            limit: res.data.data.paging.limit,
            sortDefault: res.data.data.paging.sortDefault,
            directionDefault: res.data.data.paging.directionDefault,
            scope: res.data.data.paging.scope
          });
          commit("filtrosReceitas", {
            busca: res.data.data.filters.busca,
            dtini: res.data.data.filters.dtini,
            dtfim: res.data.data.filters.dtfim,
            cadastro_id: res.data.data.filters.cadastro_id,
            gateway_id: res.data.data.filters.gateway_id,
            status: res.data.data.filters.status,
            movconta_id: res.data.data.filters.movconta_id,
            status_remessa: res.data.data.filters.status_remessa,
          });
          commit("record_count", {
            aberto: res.data.data.record_count.aberto,
            liquidado: res.data.data.record_count.liquidado,
            cancelado: res.data.data.record_count.cancelado
          });
          let total = state.totalReceitas + state.pagingReceitas.current;
          commit("totalReceitas", {
            totalReceitas: total
          });
          commit("setMetadadosReceitas", res.data.data.metadados);
          let stateMov = JSON.parse(JSON.stringify(state.movcontas));
          if (state.allReceitas) {
            for (let i = 0; i < stateMov.length; i++) {
              stateMov[i].marcado = true;
              for (let i = 0; i < stateMov.length; i++) {
                // $("tr.item" + i).addClass("marcou");
              }
            }
            commit("movcontas", {
              movcontas: stateMov
            });
          }
          commit("urlGeral", {
            urlGeral: res.data.data.url_boleto
          });
          commit("urlDetalhe", {
            urlDetalhe: res.data.data.url_detalhe
          });
          dispatch("displayDateReceitas");
        }
        commit("buscandoRec", {
          buscandoRec: false
        });
      })
      .catch(() => {
        // Notify.create({
        //   message: "Não foi possível listar as receitas. Atualize sua página!",
        //   color: "red",
        //   position: "top-right"
        // });
        commit("isPermissoReceitas", false);
        commit("buscandoRec", {
          buscandoRec: false
        });
      });
  },
  displayDateReceitas: ({ commit }) => {
    let value = state.filtrosReceitas.dtini;
    if (!value) return "";
    value = new Date(value);

    commit("dataReceitas", {
      dataArranjada: moment.utc(value).format("DD"),
      anoAtual: moment.utc(value).format("YYYY")
    });
  },
  setAll: ({ commit }) => {
    commit("allReceitas", {
      allReceitas: !state.allReceitas
    });
    if (state.allReceitas) {
      commit("counterReceitas", {
        counterReceitas: state.pagingReceitas.count
      });
    } else {
      commit("counterReceitas", {
        counterReceitas: JSON.parse(JSON.stringify(state.totalReceitas))
      });
    }
  },
  enviarEmailReceitas: ({ commit }, userData) => {
    commit("aguardeEmailRec", {
      aguardeEmailRec: true
    });
    let dados = {
      ids: [userData.id],
      all: state.allReceitas,
      busca: state.filtrosReceitas.busca,
      dtini: state.filtrosReceitas.dtini,
      dtfim: state.filtrosReceitas.dtfim,
      status: state.filtrosReceitas.status,
      cadastro_id: state.filtrosReceitas.cadastro_id,
      gateway_id: state.filtrosReceitas.gateway_id,
      status_remessa: state.filtrosReceitas.status_remessa,
    };
    if (dados.gateway_id === undefined) {
      dados.gateway_id = null;
    }
    if (dados.cadastro_id === undefined) {
      dados.cadastro_id = null;
    }
    if (dados.all === true) {
      dados.all = 1;
    } else {
      dados.all = 0;
    }
    Dialog.create({
      title: userData.cadastro.nome,
      message: "Confirma o envio de E-mail?",
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/financeiro/email", dados)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: "Ação Realizada com Sucesso!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
            commit("aguardeEmailRec", {
              aguardeEmailRec: false
            });
          })
          .catch(() => {
            Dialog.create({
              title: "Não foi possível contactar ou não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            commit("aguardeEmailRec", {
              aguardeEmailRec: false
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
        commit("aguardeEmailRec", {
          aguardeEmailRec: false
        });
      })
      .onDismiss(() => {
        Loading.hide();
        commit("aguardeEmailRec", {
          aguardeEmailRec: false
        });
      });
  },
  marcaItemReceitas: ({ commit }, userData) => {
    // let trClass = $(".item" + userData.val).attr("class");
    let contador = 0;
    let counter = 0;
    if (
      trClass === undefined ||
      trClass === "item" + userData.val + " marcou"
    ) {
      // $("tr.item" + userData.val).removeClass("marcou");
      contador = state.contadorReceitas + 1;
      counter = state.counterReceitas - 1;
      commit("contadorReceitas", {
        contadorReceitas: contador
      });
      commit("counterReceitas", {
        counterReceitas: counter
      });
    } else {
      // $("tr.item" + userData.val).addClass("marcou");
      counter = state.counterReceitas + 1;
      commit("counterReceitas", {
        counterReceitas: counter
      });
    }
  },
  marcarDesmarcarTodosReceitas: ({ commit, state }) => {
    if (state.pulseCount === 1) {
      commit("marcarDesmarcarTodosReceitas", {
        pulseCount: 0,
        pulseRapido: state.pulseRapido,
        marcado: state.marcado,
        marquinhos: state.marquinhos,
        mTodos: state.mTodos
      });
    } else {
      commit("marcarDesmarcarTodosReceitas", {
        pulseRapido: true,
        pulseCount: state.pulseCount,
        marcado: state.marcado,
        marquinhos: state.marquinhos,
        mTodos: state.mTodos
      });
    }
    let max = state.movcontas.length;
    let mov = state.movcontas;
    commit("marcarDesmarcarTodosReceitas", {
      marcado: !state.marcado,
      pulseRapido: state.pulseRapido,
      pulseCount: state.pulseCount,
      marquinhos: state.marquinhos,
      mTodos: state.mTodos
    });
    if (!state.marcado) {
      commit("allReceitas", {
        allReceitas: false
      });
    }
    for (let i = 0; i < max; i++) {
      mov[i].marcado = state.marcado;
      commit("movcontas", {
        movcontas: mov
      });
    }
    if (state.marcado) {
      commit("counterReceitas", {
        counterReceitas: JSON.parse(JSON.stringify(state.totalReceitas))
      });
      for (let i = 0; i < max; i++) {
        // $("tr.item" + i).addClass("marcou");
      }
      commit("marcarDesmarcarTodosReceitas", {
        marquinhos: true,
        mTodos: true,
        marcado: state.marcado,
        pulseRapido: state.pulseRapido,
        pulseCount: state.pulseCount
      });
    } else {
      commit("counterReceitas", {
        counterReceitas: 0
      });
      for (let i = 0; i < max; i++) {
        // $("tr.item" + i).removeClass("marcou");
      }
      commit("marcarDesmarcarTodosReceitas", {
        marquinhos: false,
        mTodos: false,
        marcado: state.marcado,
        pulseRapido: state.pulseRapido,
        pulseCount: state.pulseCount
      });
      for (let i = 0; i < max; i++) {
        // $("tr.item" + i).removeClass("marcou");
      }
    }
    setTimeout(() => {
      commit("marcarDesmarcarTodosReceitas", {
        marquinhos: state.marquinhos,
        mTodos: state.mTodos,
        marcado: state.marcado,
        pulseRapido: false,
        pulseCount: 1
      });
    }, 5000);
  },
  acaoMarcarReceitas: ({ commit, dispatch }) => {
    commit("acaoReceitas", {
      acaoReceitas: "marcar"
    });
    dispatch("realizarAcaoMassaReceitas");
  },
  acaoDesmarcarReceitas: ({ commit, dispatch }) => {
    commit("acaoReceitas", {
      acaoReceitas: "desmarcar"
    });
    dispatch("realizarAcaoMassaReceitas");
  },
  acaoEmailReceitas: ({ commit }) => {
    commit("OpenDialogEnviarEmail", true);
    commit("acaoReceitas", {
      acaoReceitas: "enviar-email"
    });
  },
  acaoWhatsAppReceitas: ({ commit }) => {
    commit("OpenDialogAgendarMensagem", true);
    commit("acaoReceitas", {
      acaoReceitas: "enviar-whatsapp"
    });
  },
  cancelarMassa: ({ commit }) => {
    commit("acaoReceitas", {
      acaoReceitas: "cancelar-massa"
    });
    // dispatch('realizarAcaoMassaReceitas')
  },
  realizarEnvioEmail: ({ commit, dispatch }, data) => {

    if (state.realizandoAcaoReceitas) return;

    commit("realizandoAcaoReceitas", {
      realizandoAcaoReceitas: true
    });
    // dispatch("getSelected");
    let dados = {
      ids: state.selectediesReceitas,
      all: state.allReceitas,
      busca: state.filtrosReceitas.busca,
      dtini: state.filtrosReceitas.dtini,
      dtfim: state.filtrosReceitas.dtfim,
      status: state.filtrosReceitas.status,
      cadastro_id: state.filtrosReceitas.cadastro_id,
      gateway_id: state.filtrosReceitas.gateway_id,
      status_remessa: state.filtrosReceitas.status_remessa,
    };
    if (data !== null && data !== undefined) {
      dados.template_id = data;
    }
    if (dados.gateway_id === undefined) {
      dados.gateway_id = null;
    }
    if (dados.cadastro_id === undefined) {
      dados.cadastro_id = null;
    }
    if (dados.all === true) {
      dados.all = 1;
    } else {
      dados.all = 0;
    }
    let acao = "";
    if (state.acaoReceitas === "enviar-email") {
      acao = "/financeiro/email";
    }

    axios
      .post(acao, dados)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
        commit("OpenDialogEnviarEmail", false);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        dispatch("setAll");
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
      });
  },
  realizarEnvioWhatsReceita: ({ commit, dispatch }, data) => {

    if (state.realizandoAcaoReceitas) return;

    let idArr = state.selectediesReceitas;

    if (idArr.length == 0) {
      Notify.create({
        message: "Selecione pelo menos um registro!",
        color: "orange",
        position: "top-right"
      });

      return;
    }

    let dados = {
      ids: idArr,
      all: state.allReceitas,
      tipo: data.tipo,
      template_id: data.template,
      mensagem: data.mensagem,
      expira: data.expira,
      confirmar: 1
    };

    if(data.whatsappBodyTemp) dados.mensagem = data.whatsappBodyTemp;

    if (dados.all === true) {
      dados.all = 1;
      dados.status = state.filtrosReceitas.status;
      dados.status_remessa = state.filtrosReceitas.status_remessa;
      dados.gateway_id = state.filtrosReceitas.gateway_id;
      dados.busca = state.filtrosReceitas.busca;
      dados.dtini =  state.filtrosReceitas.dtini;
      dados.dtfim = state.filtrosReceitas.dtfim;
      delete dados.ids;
    } else {
      dados.all = 0;
    }

    if (idArr.length > 1) {
      axios
        .post("/financeiro/whatsapp", dados)
        .then(res => {
          if (res.data.success) {
            const total = res.data.total;
            Dialog.create({
              title: "Confirmação",
              message: (total > 1 ? total + " mensagens" : total + " mensagens") + " para serem enviadas, deseja continuar?",
              ok: {
                label: "Confirmar",
                color: "green",
              },
              cancel: "Cancelar"
            })
            .onOk(() => {
              commit("realizandoAcaoReceitas", {
                realizandoAcaoReceitas: true
              });

              delete dados.confirmar;
              if (data !== null && data !== undefined) {
                dados.template_id = data.template;
              }
              if (dados.gateway_id === undefined) {
                dados.gateway_id = null;
              }

              dispatch("enviarWhatsReceita", dados);
            });
          } else {
            if (res.data.errors) {
              commit("errorsWhatsApp", {
                errorsWhatsApp: res.data.errors
              });
            } else {
              Dialog.create({
                message: "Não foram encontrados números de WhatsApp com as receitas selecionadas!",
              });
            }

          }
        })
        .catch(() => {
          Notify.create({
            message: "Não foi possível contactar ou não possui permissão!",
            color: "red",
            position: "top-right"
          });
          dispatch("setAll");
          commit("realizandoAcaoReceitas", {
            realizandoAcaoReceitas: false
          });
        });
    } else {
      Dialog.create({
        title: "Confirmação",
        message: "Deseja enviar a mensagem?",
        ok: {
          label: "Confirmar",
          color: "green",
        },
        cancel: "Cancelar",
        persistent: true
      })
      .onOk(() => {
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: true
        });

        delete dados.confirmar;
        dispatch("enviarWhatsReceita", dados);
      });
    }
  },
  enviarWhatsReceita: ({ commit, dispatch }, data) => {
    axios
      .post("financeiro/whatsapp", data)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          commit("OpenDialogAgendarMensagem", false);
          commit("OpenDialogNotificarCliente", false);
        } else {
          if (res.data.errors) {
            commit("errorsWhatsApp", {
              errorsWhatsApp: res.data.errors
            });
          } else {
            Dialog.create({
              message: res.data.message,
            });
          }
        }

        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        dispatch("setAll");
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
      });
  },
  realizarCancelarMassa: ({ commit, dispatch }, data) => {
    commit("realizandoAcaoReceitas", {
      realizandoAcaoReceitas: true
    });
    // dispatch("getSelected");
    let dados = {
      ids: state.selectediesReceitas,
      all: state.allReceitas,
      busca: state.filtrosReceitas.busca,
      dtini: state.filtrosReceitas.dtini,
      dtfim: state.filtrosReceitas.dtfim,
      status: state.filtrosReceitas.status,
      cadastro_id: state.filtrosReceitas.cadastro_id,
      gateway_id: state.filtrosReceitas.gateway_id,
      status_remessa: state.filtrosReceitas.status_remessa,
      tipo: 2 // Receita
    };
    if (data !== null && data !== undefined) {
      dados.template_id = data;
    }
    if (dados.gateway_id === undefined) {
      dados.gateway_id = null;
    }
    if (dados.cadastro_id === undefined) {
      dados.cadastro_id = null;
    }
    if (dados.all === true) {
      dados.all = 1;
    } else {
      dados.all = 0;
    }
    let acao = "";
    if (state.acaoReceitas === "cancelar-massa") {
      acao = "/financeiro/delete";
    }

    axios
      .post(acao, dados)
      .then(res => {
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
        commit("OpenDialogCancelarConfirmarDespesas", false);

        if (!res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
          return;
        }

        Notify.create({
          message: res.data.message,
          color: "green",
          position: "top-right"
        });
        dispatch("resetPagingReceitas");
        dispatch("listarReceitas");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        // dispatch('setAll')
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
      });
  },
  realizarAcaoMassaReceitas: ({ commit, dispatch }) => {
    if (state.aguardeEmailRec){
      return;
    }
    commit("aguardeEmailRec", {
      aguardeEmailRec: true
    });
    // dispatch("getSelected");
    let dados = JSON.parse(JSON.stringify({
      ids: state.selectediesReceitas,
      all: state.allReceitas,
      busca: state.filtrosReceitas.busca,
      dtini: state.filtrosReceitas.dtini,
      dtfim: state.filtrosReceitas.dtfim,
      status: state.filtrosReceitas.status,
      cadastro_id: state.filtrosReceitas.cadastro_id,
      gateway_id: state.filtrosReceitas.gateway_id,
      status_remessa: state.filtrosReceitas.status_remessa,
    }));
    if (dados.gateway_id === undefined) {
      dados.gateway_id = null;
    }
    if (dados.cadastro_id === undefined) {
      dados.cadastro_id = null;
    }
    if (dados.all === true) {
      dados.all = 1;
    } else {
      dados.all = 0;
    }
    if (state.acaoReceitas === "") {
      Notify.create({
        message: "Selecione uma ação!",
        color: "orange",
        position: "top-right"
      });

      return;
    }

    let acao = "";
    if (state.acaoReceitas === "marcar") {
      acao = "/financeiro/remessa";
      dados.tipo = "marcar";
    }
    if (state.acaoReceitas === "desmarcar") {
      acao = "/financeiro/remessa";
      dados.tipo = "desmarcar";
    }
    // if (state.acaoReceitas === 'enviar-email') {
    //   acao = '/financeiro/email'
    // }
    // if (state.acaoReceitas === 'cancelar-massa') {
    //   acao = '/financeiro/delete'
    // }
    commit("realizandoAcaoReceitas", {
      realizandoAcaoReceitas: true
    });
    // console.log(Loading.show());
    Dialog.create({
      title: "Confirma a Ação em Massa?",

      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post(acao, dados)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: "Ação Realizada com Sucesso!",
                message: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              commit("realizandoAcaoReceitas", {
                realizandoAcaoReceitas: false
              });
              if (acao === "/financeiro/delete") {
                dispatch("marcarDesmarcarTodosReceitas");
                dispatch("resetPagingReceitas");
                dispatch("listarReceitas");
              }
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
              commit("realizandoAcaoReceitas", {
                realizandoAcaoReceitas: false
              });
            }
            commit("aguardeEmailRec", {
              aguardeEmailRec: false
            });
            commit("realizandoAcaoReceitas", {
              realizandoAcaoReceitas: false
            });
            // $("#acoesEmMassa").modal("close");
            // $("#SettingReceitas").sidenav("close");

            dispatch("resetAcoes");
          })
          .catch(() => {
            Dialog.create({
              title: "Não foi possível contactar ou não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            dispatch("setAll");
            commit("aguardeEmailRec", {
              aguardeEmailRec: false
            });
            commit("realizandoAcaoReceitas", {
              realizandoAcaoReceitas: false
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
        commit("aguardeEmailRec", {
          aguardeEmailRec: false
        });
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
      })
      .onDismiss(() => {
        Loading.hide();
        commit("aguardeEmailRec", {
          aguardeEmailRec: false
        });
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
      });
  },
  resetAcoes: ({ commit }) => {
    commit("acaoReceitas", {
      acaoReceitas: ""
    });
    commit("allReceitas", {
      allReceitas: false
    });
  },
  getSelected: ({ commit }) => {
    if (state.selectediesReceitas.length > 0) {
      commit("selectediesReceitas", {
        selectediesReceitas: []
      });
    }
    let ids = [];
    let max = state.movcontas.length;
    for (let i = 0; i < max; i++) {
      if (state.movcontas[i].marcado) {
        ids.push(state.movcontas[i].id);
      }
    }
    commit("selectediesReceitas", {
      selectediesReceitas: ids
    });
  },
  resetPagingReceitas: ({ commit }) => {
    commit("pagingReceitas", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });
    commit("movcontas", {
      movcontas: []
    });
    commit("totalReceitas", {
      totalReceitas: 0
    });
    commit("counterReceitas", {
      counterReceitas: 0
    });
  },
  avl: ({ commit }, userData) => {
    commit("avl", {
      avl: userData
    });
  },
  saveNewGateway: ({ commit }, userData) => {
    commit("loadGateway", {
      loadGateway: true
    });
    let movconta = {
      id: userData.id,
      alterar_vencimento: userData.avl,
      gateway_id: userData.gateway_id,
      vencimento: userData.vencimento,
      valor: userData.valor,
      valor_desconto: userData.valor_desconto,
      data_limite_desconto: userData.data_limite_desconto
    };
    movconta.action = "alterar-forma-pagamento-receita";

    if (userData.personalizar_multa_juros === true) {
      movconta.personalizar_multa_juros = true;
      movconta.fatura_multa = userData.fatura_multa;
      movconta.fatura_juros = userData.fatura_juros;
      movconta.fatura_protesto = userData.fatura_protesto;
    }

    axios
      .post("/financeiro/edit/" + movconta.id, movconta)
      .then(res => {
        if (res.data.status) {
          if (userData.successCallback) {
            userData.successCallback();
          }

          Notify.create({
            message: "Lançamento " + res.data.movconta.id + " alterado!",
            color: "green",
            position: "top-right"
          });
          commit("loadGateway", {
            loadGateway: false
          });
          for (let i = 0; i < state.movcontas.length; i++) {
            if (state.movcontas[i].id === userData.id) {
              Vue.set(state.movcontas, i, res.data.movconta);
            }
          }
          commit("OpenDialogAlterarFormaPagamento", false);
        } else {
          commit("errorsReceitas", {
            errorsReceitas: res.data.errors
          });
          commit("loadGateway", {
            loadGateway: false
          });
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadGateway", {
          loadGateway: false
        });
      });
  },
  changeFilterPeriodoReceitas: ({ commit, dispatch }, userData) => {
    if (state.buscandoRec) return;

    let dtini = state.filtrosReceitas.dtini.split("-");
    let dtfim = state.filtrosReceitas.dtfim.split("-");

    let i = moment(new Date(dtini[2], dtini[1] - 1, dtini[0]));
    let f = moment(new Date(dtfim[2], dtfim[1] - 1, dtfim[0]));

    if (userData === "prev") {
      i.subtract(1, "months");
      f.subtract(1, "months");
    }
    if (userData === "next") {
      i.add(1, "months");
      f.add(1, "months");
    }
    commit("filtrosReceitas", {
      ...state.filtrosReceitas,
      page: 1,
      dtini: i.startOf("month").format("DD-MM-YYYY"),
      dtfim: f.endOf("month").format("DD-MM-YYYY")
    });
    dispatch("clearCheckbox");
    dispatch("resetPagingReceitas");
    dispatch("listarReceitas");
    dispatch("clearCheckbox");
  },
  changeGateways: ({ commit, dispatch }, userData) => {
    dispatch("findGateways", { gatewaysOpts: true });
    let movcontaa = JSON.parse(JSON.stringify(userData));
    movcontaa.vencimento = "";
    commit("movconta", {
      id: userData.id,
      tipo: userData.tipo,
      vencimento: userData.vencimento,
      liquidacao: userData.liquidacao,
      conta_id: userData.conta_id,
      descricao: userData.descricao,
      valor: userData.valor,
      valor_liquidado: userData.valor_liquidado,
      valor_acrescimo_desconto: userData.valor_acrescimo_desconto,
      cadastro_id: userData.cadastro_id,
      cadastro_nome: userData.cadastro_nome,
      nomenclatura_id: userData.nomenclatura_id,
      liquidado: userData.liquidado,
      conciliado: userData.conciliado,
      gateway_id: userData.gateway_id,
      cadastros_gateways_id: userData.cadastros_gateways_id,
      fatura_id: userData.fatura_id,
      shortUrl: userData.shortUrl,
      status: userData.status,
      cobrar: userData.cobrar,
    });
    commit("movcontaa", {
      movcontaa: movcontaa
    });
    commit("OpenDialogAlterarFormaPagamento", true);
  },
  newMovconta: ({ commit }) => {
    commit("movconta", {
      id: null,
      tipo: 2,
      vencimento: moment().format("DD/MM/YYYY"),
      referencia: "",
      liquidacao: null,
      conta_id: null,
      descricao: null,
      valor: 0.0,
      valor_liquidado: 0.0,
      valor_acrescimo_desconto: 0.0,
      cadastro_id: null,
      cadastro_nome: null,
      nomenclatura_id: null,
      liquidado: false,
      conciliado: false,
      gateway_id: null,
      cadastros_gateways_id: null,
      fatura_id: null,
      shortUrl: null,
      status: "Aberto",
      cobrar: false
    });
  },
  modalReceitas: ({ dispatch, commit }) => {
    commit("errorsReceitas", {
      errorsReceitas: []
    });
    commit("callDespesa", false);
    dispatch("newMovconta");
    dispatch("planoContas");
    commit("OpenDialogNovaReceita", true);
  },
  liquidarReceitas: ({ commit }, userData) => {
    commit("movconta", {
      id: userData.id,
      tipo: userData.tipo,
      vencimento: userData.vencimento,
      liquidacao: userData.liquidacao,
      conta_id: userData.conta_id,
      planodeconta_id: userData.planodeconta_id,
      descricao: userData.descricao,
      valor: userData.valor,
      valor_liquidado: userData.valor_liquidado,
      valor_acrescimo_desconto: userData.valor_acrescimo_desconto,
      cadastro_id: userData.cadastro_id,
      cadastro_nome: userData.cadastro_nome,
      nomenclatura_id: userData.nomenclatura_id,
      liquidado: userData.liquidado,
      conciliado: userData.conciliado,
      gateway_id: userData.gateway_id,
      cadastros_gateways_id: userData.cadastros_gateways_id,
      fatura_id: userData.fatura_id,
      shortUrl: userData.shortUrl,
      status: userData.status,
      cobrar: userData.cobrar,
      cadastro: userData.cadastro,
      valor_acrecimo: userData.valor_acrecimo,
      valor_desconto: userData.valor_desconto,
    });
    commit("OpenDialogLiquidarMovconta", true);
    commit("callbackListar", false);
  },
  saveLiquidarReceitas: ({ commit, dispatch }, userData) => {
    let movconta = userData;
    commit("loadLiquidar", {
      loadLiquidar: true
    });
    movconta.action = "liquidar-receita";

    let funcaoCallback = () => { };

    if (movconta.liquidarCallback) {
      funcaoCallback = movconta.liquidarCallback;
      movconta.liquidarCallback = null;
    }

    axios
      .post("/financeiro/edit/" + movconta.id, movconta)
      .then(res => {
        if (res.data.status) {
          Notify.create({
            message: "Lançamento " + res.data.movconta.id + " liquidado!",
            color: "green",
            position: "top-right"
          });
          commit("errorsReceitas", {
            errorsReceitas: []
          });
          commit("movcontas", {
            movcontas: state.movcontas.splice(
              state.movcontas.indexOf(state.movconta),
              1
            )
          });
          dispatch("resetPagingReceitas");
          dispatch("listarReceitas");
          funcaoCallback();
          commit("OpenDialogLiquidarMovconta", false);
          commit("callbackListar", true);
          // $("#liquidarMovconta").modal("close");
          // $("#SettingReceitas").sidenav("close");
          commit("loadLiquidar", {
            loadLiquidar: false
          });
          dispatch("newMovconta");

          if (router.history.current.path === "/extrato-cliente") {
            bus.$emit("loadExtratoCliente");
          }
        } else {
          commit("errorsReceitas", {
            errorsReceitas: res.data.errors
          });
          commit("loadLiquidar", {
            loadLiquidar: false
          });

          if (res.data.notify_warning) {
            Notify.create({
              message: res.data.notify_warning,
              color: "orange",
              position: "top-right",
            });
          }
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadLiquidar", {
          loadLiquidar: false
        });
      });
  },
  editarReceitas: ({ commit, dispatch }, userData) => {
    dispatch("itemsReceitasClear");

    commit("buscandoItems", {
      buscandoItems: true
    });

    axios
      .get("/financeiro/items/" + userData.id)
      .then(res => {
        if (res.data.success) {
          commit("itemsReceitas", {
            itemsReceitas: res.data.data.items
          });

          commit("movconta", {
            id: userData.id,
            tipo: userData.tipo,
            vencimento: userData.vencimento,
            liquidacao: userData.liquidacao,
            dtmovimento: userData.dtmovimento,
            conta_id: userData.conta_id,
            planodeconta_id: userData.planodeconta_id,
            descricao: userData.descricao,
            valor: userData.valor,
            valor_liquidado: userData.valor_liquidado,
            valor_acrescimo_desconto: userData.valor_acrescimo_desconto,
            cadastro_id: userData.cadastro_id,
            cadastro_nome: userData.cadastro_nome,
            nomenclatura_id: userData.nomenclatura_id,
            liquidado: userData.liquidado,
            conciliado: userData.conciliado,
            gateway_id: userData.gateway_id,
            cadastros_gateways_id: userData.cadastros_gateways_id,
            fatura_id: userData.fatura_id,
            shortUrl: userData.shortUrl,
            status: userData.status,
            cobrar: userData.cobrar,
            cadastro: userData.cadastro,
            valor_acrecimo: userData.valor_acrecimo,
            valor_desconto: userData.valor_desconto,
            planodeconta_item: userData.planodeconta_item,
            referencia: userData.referencia
          });
          commit("setOpenDialogEditarMovconta", true);
        }
        commit("buscandoItems", {
          buscandoItems: false
        });
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível editar, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("buscandoItems", {
          buscandoItems: false
        });
      });
  },
  saveEditarReceitas: ({ commit, dispatch }, userData) => {
    let receita = userData;
    receita.action = "editar-receita";

    commit("setLoadEditarMovconta", true);

    axios
      .post("/financeiro/edit/" + receita.id, receita)
      .then(res => {
        if (res.data.status) {
          Notify.create({
            message: "Lançamento " + res.data.movconta.id + " editado!",
            color: "green",
            position: "top-right"
          });
          commit("errorsReceitas", {
            errorsReceitas: []
          });
          dispatch("resetPagingReceitas");
          dispatch("listarReceitas");
          dispatch("newMovconta");

          commit("setOpenDialogEditarMovconta", false);
          commit("setLoadEditarMovconta", false);

          bus.$emit("closeModalEditarMovconta");
        } else {
          commit("errorsReceitas", {
            errorsReceitas: res.data.errors
          });
          commit("setLoadEditarMovconta", false);
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar ou não possui permissão!",
          color: "red",
          position: "top-right",
        });
        commit("setLoadEditarMovconta", false);
      });
  },
  saveFaturarReceitas: ({ commit, dispatch }, userData) => {
    commit("loadFaturamento", {
      loadFaturamento: true
    });
    let venc = userData.vencimento;
    let enviaFaturar = {
      vencimento: venc,
      descricao: userData.personalizarDescricao ? userData.descricao : null,
      referencia: userData.referencia,
      dtini: userData.utilizarDtiniDtfim ? userData.dtini : null,
      dtfim: userData.utilizarDtiniDtfim ? userData.dtfim : null,
      cadastros_ids: userData.cadastros_ids,
      recorrencias_ids: userData.recorrencias_ids,
      apenasParcelas: Number(userData.apenasParcelas),
    };
    axios
      .post("/financeiro/faturar", enviaFaturar)
      .then(res => {
        commit("loadFaturamento", {
          loadFaturamento: false
        });

        if (res.data.success) {
          if (userData.successCallback) {
            userData.successCallback();
          }

          Notify.create({
            message: "Faturamento realizado com sucesso!",
            color: "green",
            position: "top-right"
          });

          commit("OpenDialogFaturar", false);
          commit("errorsReceitas", {
            errorsReceitas: []
          });
          commit("saveFaturamento", {
            vencimento: null,
            descricao: "",
            referencia: null,
            dtini: null,
            dtfim: null,
            personalizarDescricao: false,
            utilizarDtiniDtfim: false,
            apenasParcelas: false,
            cadastros_ids: [],
            recorrencias_ids: [],
          });
          dispatch("resetPagingReceitas");
          dispatch("listarReceitas");

          bus.$emit("loadRecorrenciasCadastro");
          bus.$emit("atualizarRecorrencias");
        } else {
          if (res.data.errors) {
            commit("errorsReceitas", {
              errorsReceitas: res.data.errors
            });
          }
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível faturar, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadFaturamento", {
          loadFaturamento: false
        });
      });
  },
  buscarFaturamento: ({ commit, state }, userData) => {
    const params = {
      vencimento: userData.vencimento.replace(/\//g, "-"),
      referencia: userData.referencia.replace(/\//g, "-"),
      cadastros_ids: state.saveFaturamento.cadastros_ids.join(','),
      recorrencias_ids: state.saveFaturamento.recorrencias_ids.join(','),
      apenasParcelas: Number(userData.apenasParcelas),
    };

    if (userData.utilizarDtiniDtfim) {
      params.dtini = userData.dtini.replace(/\//g, "-");
      params.dtfim = userData.dtfim.replace(/\//g, "-");
    }

    axios.get("/financeiro/faturar", { params })
      .then(res => {
      if (res.data.success) {
        commit("faturamento", {
          opcoes: res.data.opcoes,
        });
        commit("saveFaturamento", {
          ...state.saveFaturamento,
          descricao: res.data.opcoes.descricao,
          referencia: res.data.opcoes.referencia,
          vencimento: state.saveFaturamento.vencimento,
        });
      }
    });
  },
  faturar: ({ commit, state }) => {
    commit("loadModalFaturamento", {
      loadModalFaturamento: true
    });

    const params = {
      cadastros_ids: state.saveFaturamento.cadastros_ids.join(','),
      recorrencias_ids: state.saveFaturamento.recorrencias_ids.join(','),
    };

    axios
      .get("/financeiro/faturar", { params })
      .then(res => {
        if (res.data.success) {
          commit("faturamento", {
            opcoes: res.data.opcoes,
          });
          commit("saveFaturamento", {
            ...state.saveFaturamento,
            descricao: res.data.opcoes.descricao,
            referencia: res.data.opcoes.referencia,
            vencimento: res.data.opcoes.vencimento
          });
          commit("OpenDialogFaturar", true);
          commit("loadModalFaturamento", {
            loadModalFaturamento: false
          });
        }
      })
      .catch(() => {
        commit("loadModalFaturamento", {
          loadModalFaturamento: false
        });
        Notify.create({
          message:
            "Não foi possível abrir faturamento, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  getTimelineReceitas: ({ commit, state }) => {
    if (state.pagingReceitas.page === 0) {
      commit("timelineReceitas", {
        timelineReceitas: []
      });
    }
    let dados = {
      page: state.pagingTimeReceitas.page,
      movconta_id: state.movimentacao.id,
    };
    axios
      .get("/timelines", {
        params: dados
      })
      .then(res => {
        if (res.data.success) {
          let time = res.data.timelines;
          commit("timelineReceitas", {
            timelineReceitas: state.timelineReceitas.concat(time),
            timelineAction: res.data.tipos,
          });
          commit("pagingTimeReceitas", {
            finder: res.data.paging.finder,
            page: res.data.paging.page,
            current: res.data.paging.current,
            count: res.data.paging.count,
            perPage: res.data.paging.perPage,
            prevPage: res.data.paging.prevPage,
            nextPage: res.data.paging.nextPage,
            pageCount: res.data.paging.pageCount,
            sort: res.data.paging.sort,
            direction: res.data.paging.direction,
            limit: res.data.paging.limit,
            sortDefault: res.data.paging.sortDefault,
            directionDefault: res.data.paging.directionDefault,
            scope: res.data.paging.scope
          });
        }
      });
  },
  cadPraDetClear: ({ commit }) => {
    commit("cadPraDet", {
      cadPraDet: {}
    });
  },
  movPraDetClear: ({ commit }) => {
    commit("movPraDet", {
      movPraDet: {}
    });
  },
  itemsReceitasClear: ({ commit }) => {
    commit("itemsReceitas", {
      itemsReceitas: []
    });
  },
  timelineReceitasClear: ({ commit }) => {
    commit("timelineReceitas", {
      timelineReceitas: []
    });
  },
  detalharReceitas: ({ commit, dispatch }, userData) => {
    dispatch("itemsReceitasClear");
    dispatch("timelineReceitasClear");
    commit("movimentacao", {
      movimentacao: userData
    });
    commit("buscandoItems", {
      buscandoItems: true
    });

    if (Number.isInteger(userData)){
      axios
      .get("/financeiro/items/" + userData)
      .then(res => {
        if (res.data.success) {
          commit("itemsReceitas", {
            itemsReceitas: res.data.data.items
          });
          commit("movimentacao", {
            movimentacao: res.data.data.movconta
          });
          dispatch("pagingTimeReceitas", res.data.data.movconta);
          if (res.data.data.arquivos) {
            commit("receitaArquivos", res.data.data.arquivos);
          }
        }
        commit("buscandoItems", {
          buscandoItems: false
        });
        commit("OpenDialogDetalharMovconta", true);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível detalhar, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("buscandoItems", {
          buscandoItems: false
        });
      });
      return;
    }

    axios
      .get("/financeiro/items/" + userData.id)
      .then(res => {
        if (res.data.success) {
          commit("itemsReceitas", {
            itemsReceitas: res.data.data.items
          });
          commit("movimentacao", {
            movimentacao: res.data.data.movconta
          });
          dispatch("pagingTimeReceitas", userData);
          if (res.data.data.arquivos) {
            commit("receitaArquivos", res.data.data.arquivos);
          }
        }
        commit("buscandoItems", {
          buscandoItems: false
        });
        commit("OpenDialogDetalharMovconta", true);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível detalhar, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("buscandoItems", {
          buscandoItems: false
        });
      });
  },
  cadPraDet: ({ commit }, userData) => {
    axios.get("/cadastros/view/" + userData.cadastro_id).then(res => {
      if (res.data.success) {
        let cadastro = res.data.data.cadastro;
        commit("cadPraDet", {
          cadPraDet: cadastro
        });
      }
    });
  },
  movimentacao: ({ commit }, userData) => {
    axios
      .get("/financeiro/receitas?cadastro_id=" + userData.cadastro_id)
      .then(res => {
        commit("movPraDet", {
          movPraDet: res.data.data.movcontas
        });
        let mov = state.movimentacao;
        let i = 0;
        for (i in state.movimentacao.length) {
          if (i === 0) {
            mov = mov.concat(state.movPraDet[i]);
          }
        }
        commit("movimentacao", {
          movimentacao: mov
        });
      });
  },
  pagingTimeReceitas: ({ commit }, userData) => {
    axios.get("/timelines?movconta_id=" + userData.id).then(res => {
      if (res.data.success) {
        commit("timelineReceitas", {
          timelineReceitas: res.data.timelines,
          timelineAction: res.data.tipos
        });
        commit("pagingTimeReceitas", {
          finder: res.data.paging.finder,
          page: res.data.paging.page,
          current: res.data.paging.current,
          count: res.data.paging.count,
          perPage: res.data.paging.perPage,
          prevPage: res.data.paging.prevPage,
          nextPage: res.data.paging.nextPage,
          pageCount: res.data.paging.pageCount,
          sort: res.data.paging.sort,
          direction: res.data.paging.direction,
          limit: res.data.paging.limit,
          sortDefault: res.data.paging.sortDefault,
          directionDefault: res.data.paging.directionDefault,
          scope: res.data.paging.scope
        });
      }
    });
  },
  closeModalItemsReceitas: ({ dispatch }) => {
    dispatch("cadPraDetClear");
    dispatch("movPraDetClear");
    dispatch("itemsReceitasClear");
    dispatch("timelineReceitasClear");
    dispatch("newMovconta");
  },
  estornarReceitas: ({ commit, dispatch }, userData) => {

    if (state.aguardeEmailRec){
      return ;
    }

    commit("aguardeEmailRec", {
      aguardeEmailRec: true
    });

    axios
      .get(`/financeiro/edit/${userData.id}`)
      .then(res => {
        if (!res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "red",
            position: "top-right",
          });
          commit("aguardeEmailRec", {
            aguardeEmailRec: false,
          });
          return;
        }

        let options = undefined;

        if (res.data.movcaixa_liquidado) {
          const dtmovimentoCaixa = moment(res.data.movcaixa_liquidado.dtmovimento, "YYYY/MM/DD").format("DD/MM/YYYY");
          options = {
            type: "checkbox",
            model: ["alterar_saldo"],
            items: [{
              label: `Cancelar liquidação no ${res.data.movcaixa_liquidado.caixa_nome} ID ${res.data.movcaixa_liquidado.id} ${dtmovimentoCaixa}?`,
              value: "alterar_saldo",
            }],
          };
        }

        Dialog.create({
          title: "Confirma o estorno da liquidação?",
          options: options,
          cancel: "Cancelar",
          persistent: true
        })
          .onOk(() => {
            Loading.show();
          })
          .onOk(data => {
            commit("movconta", {
              id: userData.id,
              tipo: userData.tipo,
              vencimento: userData.vencimento,
              liquidacao: userData.liquidacao,
              conta_id: userData.conta_id,
              descricao: userData.descricao,
              valor: userData.valor,
              valor_liquidado: userData.valor_liquidado,
              valor_acrescimo_desconto: userData.valor_acrescimo_desconto,
              cadastro_id: userData.cadastro_id,
              cadastro_nome: userData.cadastro_nome,
              nomenclatura_id: userData.nomenclatura_id,
              liquidado: userData.liquidado,
              conciliado: userData.conciliado,
              gateway_id: userData.gateway_id,
              cadastros_gateways_id: userData.cadastros_gateways_id,
              fatura_id: userData.fatura_id,
              shortUrl: userData.shortUrl,
              status: userData.status,
              cobrar: userData.cobrar
            });
            userData.action = "estornar";
            userData.alterar_saldo = data && data.includes("alterar_saldo");

            axios
              .post("/financeiro/edit/" + userData.id, userData)
              .then(res => {
                commit("aguardeEmailRec", {
                  aguardeEmailRec: false
                });

                if (!res.data.success) {
                  Dialog.create({
                    title: res.data.message || res.data.errors,
                    class: "bg-warning text-white",
                    ok: {
                      "text-color": "orange",
                      color: "white"
                    }
                  });
                  dispatch("resetPagingReceitas");
                  dispatch("listarReceitas");
                  return;
                }

                Dialog.create({
                  title: "Lançamento " + res.data.movconta.id + " estornado!",
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
                commit("movcontas", {
                  movcontas: state.movcontas.splice(
                    state.movcontas.indexOf(userData),
                    1
                  )
                });

                if (router.history.current.path === "/extrato-cliente") {
                  bus.$emit("loadExtratoCliente");
                }

                dispatch("resetPagingReceitas");
                dispatch("listarReceitas");
              })
              .catch(() => {
                Dialog.create({
                  title: "Não foi possível estornar, ou não possui permissão!",
                  class: "bg-negative text-white",
                  ok: {
                    "text-color": "red",
                    color: "white"
                  }
                });
                commit("aguardeEmailRec", {
                  aguardeEmailRec: false
                });
              });
          })
          .onCancel(() => {
            Loading.hide();
            commit("aguardeEmailRec", {
              aguardeEmailRec: false
            });
          })
          .onDismiss(() => {
            Loading.hide();
            commit("aguardeEmailRec", {
              aguardeEmailRec: false
            });
          });
      })
      .catch(() => {
        commit("aguardeEmailRec", {
          aguardeEmailRec: false,
        });
        Notify.create({
          message: "Não foi possível recuperar a receita!",
          color: "red",
          position: "top-right",
        });
      });
  },
  reativaReceitas: ({ commit, dispatch }, userData) => {
    commit("aguardeEmailRec", {
      aguardeEmailRec: true
    });
    Dialog.create({
      title: "Confirma a reativação da receita?",
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        commit("movconta", {
          id: userData.id,
          tipo: userData.tipo,
          vencimento: userData.vencimento,
          liquidacao: userData.liquidacao,
          conta_id: userData.conta_id,
          descricao: userData.descricao,
          valor: userData.valor,
          valor_liquidado: userData.valor_liquidado,
          valor_acrescimo_desconto: userData.valor_acrescimo_desconto,
          cadastro_id: userData.cadastro_id,
          cadastro_nome: userData.cadastro_nome,
          nomenclatura_id: userData.nomenclatura_id,
          liquidado: userData.liquidado,
          conciliado: userData.conciliado,
          gateway_id: userData.gateway_id,
          cadastros_gateways_id: userData.cadastros_gateways_id,
          fatura_id: userData.fatura_id,
          shortUrl: userData.shortUrl,
          status: userData.status,
          cobrar: userData.cobrar
        });
        userData.action = "reativar";
        axios
          .post("/financeiro/edit/" + userData.id, userData)
          .then(res => {
            if (res.data.status) {
              Dialog.create({
                title: "Lançamento " + res.data.movconta.id + " reativado!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              commit("movcontas", {
                movcontas: state.movcontas.splice(
                  state.movcontas.indexOf(userData),
                  1
                )
              });
              dispatch("resetPagingReceitas");
              dispatch("listarReceitas");
            } else {
              Dialog.create({
                title: res.data.message || res.data.errors,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
            commit("aguardeEmailRec", {
              aguardeEmailRec: false
            });
          })
          .catch(() => {
            Dialog.create({
              title: "Não foi possível reativar, ou não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            commit("aguardeEmailRec", {
              aguardeEmailRec: false
            });
            dispatch("resetPagingReceitas");
            dispatch("listarReceitas");
          });
      })
      .onCancel(() => {
        Loading.hide();
        commit("aguardeEmailRec", {
          aguardeEmailRec: false
        });
      })
      .onDismiss(() => {
        Loading.hide();
        commit("aguardeEmailRec", {
          aguardeEmailRec: false
        });
      });
  },
  excluirReceitas: async ({ commit, dispatch }, userData) => {
    commit("aguardeEmailRec", {
      aguardeEmailRec: true
    });

    let options = undefined;
    let motivo = '';

    try {
      const res = await axios.get(`/financeiro/edit/${userData.id}`);

      if (!res.data.success) {
        throw res.data;
      }

      userData = res.data.movconta;
      userData.movcaixa_criado = res.data.movcaixa_criado;
      userData.movcaixa_liquidado = res.data.movcaixa_criado;
    } catch (err) {
      Dialog.create({
        title: "Não foi possível recuperar a receita!",
        class: "bg-negative text-white",
        ok: {
          "text-color": "red",
          color: "white"
        }
      });
      return;
    }

    const items = [];

    if (userData.relacionada) {
      items.push({
        label: "Cancelar receitas relacionadas? (Apenas as em aberto)",
        value: "relacionadas",
      });
    }

    if (userData.movcaixa_criado) {
      const dtmovimentoCaixa = moment(userData.movcaixa_criado.dtmovimento, "YYYY/MM/DD").format("DD/MM/YYYY");
      items.push({
        label: `Cancelar saldo no ${userData.movcaixa_criado.caixa_nome} ID ${userData.movcaixa_criado.id} ${dtmovimentoCaixa}?`,
        value: "alterar_saldo",
      });
    }

    if (items.length > 0) {
      options = {
        type: "checkbox",
        model: ["alterar_saldo"],
        items: items,
      };
    }

    Dialog.create({
      title: "Descreva o Motivo de Cancelamento",
      cancel: "Cancelar",
      ok: "Continuar",
      prompt: {
        model: '',
        isValid: val => val.length > 2,
      },
      persistent: true
    }).onOk(data => {
      motivo = data;

      Dialog.create({
        title: "Deseja cancelar esta receita?",
        message: '<strong>Receita: </strong>' + userData.id + ' - ' + userData.cadastro.nome + '<br/><strong>Motivo: </strong>'+ motivo,
        options: options,
        cancel: "Cancelar",
        ok: "Confirmar",
        persistent: true,
        html: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(data => {
          userData.relacionadas = data && data.includes("relacionadas");
          userData.alterar_saldo = data && data.includes("alterar_saldo");
          userData.motivo = motivo;

          axios
            .post("/financeiro/delete/" + userData.id, userData)
            .then(res => {
              commit("aguardeEmailRec", {
                aguardeEmailRec: false
              });

              if (!res.data.success) {
                Dialog.create({
                  title: res.data.msg || res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
                dispatch("resetPagingReceitas");
                dispatch("listarReceitas");
                return;
              }

              commit("movcontas", {
                movcontas: state.movcontas.splice(
                  state.movcontas.indexOf(userData),
                  1
                )
              });
              Dialog.create({
                title: res.data.msg || res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              dispatch("resetPagingReceitas");
              dispatch("listarReceitas");

              if (router.history.current.path === "/extrato-cliente") {
                bus.$emit("loadExtratoCliente");
              }
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi possível cancelar a receita, ou não possui permissão!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
              commit("aguardeEmailRec", {
                aguardeEmailRec: false
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
          commit("aguardeEmailRec", {
            aguardeEmailRec: false
          });
        })
        .onDismiss(() => {
          Loading.hide();
          commit("aguardeEmailRec", {
            aguardeEmailRec: false
          });
        });


    }).onCancel(() => {
      Loading.hide();
      commit("aguardeEmailRec", {
        aguardeEmailRec: false
      });
    });

  },
  gerarRemessa: ({ commit, dispatch }) => {
    commit("aguardeEmailRec", {
      aguardeEmailRec: true
    });
    Dialog.create({
      title: "Confirma a geração de Remessa?",

      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        commit("gerandoRemessa", {
          gerandoRemessa: true
        });
        let dados = {
          tipo: "gerar"
        };
        axios
          .post("/financeiro/remessa", dados)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: "Remessa gerada!",
                message: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              dispatch("resetPagingReceitas");
              dispatch("listarReceitas");
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
            commit("aguardeEmailRec", {
              aguardeEmailRec: false
            });
            commit("gerandoRemessa", {
              gerandoRemessa: false
            });
          })
          .catch(() => {
            Dialog.create({
              title: "Não foi possível contactar ou não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            commit("aguardeEmailRec", {
              aguardeEmailRec: false
            });
            commit("gerandoRemessa", {
              gerandoRemessa: false
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
        commit("aguardeEmailRec", {
          aguardeEmailRec: false
        });
      })
      .onDismiss(() => {
        Loading.hide();
        commit("aguardeEmailRec", {
          aguardeEmailRec: false
        });
      });
  },
  changeFilterGateways: ({ commit, dispatch }, userData) => {
    if (state.buscandoRec) return;
    if (!userData) {
      commit("gatewaysDrp", {
        gatewaysDrp: "Todos"
      });
      commit("filtrosReceitas", {
        gateway_id: null,
        status: state.filtrosReceitas.status,
        busca: state.filtrosReceitas.busca,
        dtini: state.filtrosReceitas.dtini,
        dtfim: state.filtrosReceitas.dtfim,
        cadastro_id: state.filtrosReceitas.cadastro_id,
        movconta_id: state.filtrosReceitas.movconta_id,
        status_remessa: state.filtrosReceitas.status_remessa,
      });
      dispatch("resetPagingReceitas");
      dispatch("listarReceitas");
    } else {
      commit("gatewaysDrp", {
        gatewaysDrp: userData.status.titulo
      });
      commit("filtrosReceitas", {
        gateway_id: userData.status.id,
        status: state.filtrosReceitas.status,
        busca: state.filtrosReceitas.busca,
        dtini: state.filtrosReceitas.dtini,
        dtfim: state.filtrosReceitas.dtfim,
        cadastro_id: state.filtrosReceitas.cadastro_id,
        movconta_id: state.filtrosReceitas.movconta_id,
        status_remessa: state.filtrosReceitas.status_remessa,
      });
      dispatch('resetPagingReceitas');
      dispatch('listarReceitas');
      dispatch('clearCheckbox');
    }
  },
  changeFilterStatusRemessa: ({ commit, dispatch }, userData) => {
    if (state.buscandoRec) return;

    if (!userData) {
      commit('statusRemessaDrp', {
        statusRemessaDrp: 'Todos',
      });
      commit('filtrosReceitas', {
        status_remessa: null,
        gateway_id: state.filtrosReceitas.gateway_id,
        status: state.filtrosReceitas.status,
        busca: state.filtrosReceitas.busca,
        dtini: state.filtrosReceitas.dtini,
        dtfim: state.filtrosReceitas.dtfim,
        cadastro_id: state.filtrosReceitas.cadastro_id,
        movconta_id: state.filtrosReceitas.movconta_id,
      });
      dispatch('resetPagingReceitas');
      dispatch('listarReceitas');
    } else {
      commit('statusRemessaDrp', {
        statusRemessaDrp: userData.status,
      });
      commit('filtrosReceitas', {
        status_remessa: userData.valor,
        gateway_id: state.filtrosReceitas.gateway_id,
        status: state.filtrosReceitas.status,
        busca: state.filtrosReceitas.busca,
        dtini: state.filtrosReceitas.dtini,
        dtfim: state.filtrosReceitas.dtfim,
        cadastro_id: state.filtrosReceitas.cadastro_id,
        movconta_id: state.filtrosReceitas.movconta_id,
      });
      dispatch('resetPagingReceitas');
      dispatch('listarReceitas');
      dispatch('clearCheckbox');
    }
  },
  changeFilterStatus: ({ commit, dispatch }, userData) => {
    if (state.buscandoRec) return;
    commit("filtrosDrp", {
      filtrosDrp: userData.status
    });
    commit("filtrosReceitas", {
      status: userData.status,
      gateway_id: state.filtrosReceitas.gateway_id,
      busca: state.filtrosReceitas.busca,
      dtini: state.filtrosReceitas.dtini,
      dtfim: state.filtrosReceitas.dtfim,
      cadastro_id: state.filtrosReceitas.cadastro_id,
      movconta_id: state.filtrosReceitas.movconta_id,
      status_remessa: state.filtrosReceitas.status_remessa,
    });
    dispatch("resetPagingReceitas");
    dispatch("listarReceitas");
  },
  buscaReceitas: ({ commit, dispatch }, userData) => {
    commit("buscandoRec", {
      buscandoRec: true
    });
    dispatch("resetPagingReceitas");
    axios
      .get("/financeiro/receitas", {
        params: {
          busca: userData,
          page: state.pagingReceitas.page,
          dtini: state.filtrosReceitas.dtini,
          dtfim: state.filtrosReceitas.dtfim,
          status: state.filtrosReceitas.status,
          cadastro_id: state.filtrosReceitas.cadastro_id,
          gateway_id: state.filtrosReceitas.gateway_id,
          status_remessa: state.filtrosReceitas.status_remessa,
        }
      })
      .then(res => {
        let resmovcontas = res.data.data.movcontas;
        commit("movcontas", {
          movcontas: state.movcontas.concat(resmovcontas)
        });
        commit("pagingReceitas", {
          finder: res.data.data.paging.finder,
          page: res.data.data.paging.page,
          current: res.data.data.paging.current,
          count: res.data.data.paging.count,
          perPage: res.data.data.paging.perPage,
          prevPage: res.data.data.paging.prevPage,
          nextPage: res.data.data.paging.nextPage,
          pageCount: res.data.data.paging.pageCount,
          sort: res.data.data.paging.sort,
          direction: res.data.data.paging.direction,
          limit: res.data.data.paging.limit,
          sortDefault: res.data.data.paging.sortDefault,
          directionDefault: res.data.data.paging.directionDefault,
          scope: res.data.data.paging.scope
        });
        commit("record_count", {
          aberto: res.data.data.record_count.aberto,
          liquidado: res.data.data.record_count.liquidado,
          cancelado: res.data.data.record_count.cancelado
        });
        let total = state.totalReceitas + state.pagingReceitas.current;
        commit("totalReceitas", {
          totalReceitas: total
        });
        commit("buscandoRec", {
          buscandoRec: false
        });
        dispatch("displayDate");
        commit("counterReceitas", {
          counterReceitas: 0
        });
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível listar as receitas. Atualize sua página!",
          color: "red",
          position: "top-right"
        });
        commit("buscandoRec", {
          buscandoRec: false
        });
      });
  },
  loadMoreReceitas: ({ commit, dispatch }) => {
    if (state.pagingTimeReceitas.page >= state.pagingTimeReceitas.pageCount) {
      commit("btnDisabled", {
        btnDisabled: true
      });
      return;
    }
    let page = state.pagingTimeReceitas.page + 1;
    commit("pagingTimeReceitas", {
      ...state.pagingTimeReceitas,
      page: page
    });
    dispatch("getTimelineReceitas");
  },
  filtrosReceitas: ({ commit, dispatch }, userData) => {
    if (userData) {
      commit("filtrosReceitas", {
        busca: state.filtrosReceitas.busca,
        dtini: userData.dtini,
        dtfim: userData.dtfim,
        cadastro_id: state.filtrosReceitas.cadastro_id,
        gateway_id: state.filtrosReceitas.gateway_id,
        status: state.filtrosReceitas.status,
        movconta_id: state.filtrosReceitas.movconta_id,
        status_remessa: state.filtrosReceitas.status_remessa,
      });
    } else {
      commit("filtrosReceitas", {
        busca: state.filtrosReceitas.busca,
        dtini: state.filtrosReceitas.dtini,
        dtfim: state.filtrosReceitas.dtfim,
        cadastro_id: state.filtrosReceitas.cadastro_id,
        gateway_id: state.filtrosReceitas.gateway_id,
        status: state.filtrosReceitas.status,
        movconta_id: state.filtrosReceitas.movconta_id,
        status_remessa: state.filtrosReceitas.status_remessa,
      });
    }
    dispatch("clearCheckbox");
    dispatch("resetPagingReceitas");
    dispatch("listarReceitas");
  },
  acaoReceitasMassa: ({ commit, dispatch }) => {
    dispatch("contas");
    commit("receitasAgrupadas", {
      receitasAgrupadas: true
    });
    commit("OpenDialogInclusaoMassa", true);
  },
  receitasAgrupadas: ({ commit }) => {
    commit("receitasAgrupadas", {
      receitasAgrupadas: false
    });
  },
  gerarNota: async ({ commit, dispatch, state }) => {
    commit("aguardeEmailRec", {
      aguardeEmailRec: true
    });

    const dados = {
      ids: state.selectediesReceitas,
      all: state.allReceitas,
      busca: state.filtrosReceitas.busca,
      dtini: state.filtrosReceitas.dtini,
      dtfim: state.filtrosReceitas.dtfim,
      status: state.filtrosReceitas.status,
      cadastro_id: state.filtrosReceitas.cadastro_id,
      gateway_id: state.filtrosReceitas.gateway_id,
      status_remessa: state.filtrosReceitas.status_remessa,
      tipo: "gerarNota",
    };

    if (dados.gateway_id === undefined) {
      dados.gateway_id = null;
    }
    if (dados.status_remessa === undefined) {
      dados.status_remessa = null;
    }
    if (dados.cadastro_id === undefined) {
      dados.cadastro_id = null;
    }
    if (dados.all === true) {
      dados.all = 1;
    } else {
      dados.all = 0;
    }

    try {
      dados.confirmar = false;
      let res = await axios.post("/financeiro/remessa", dados);

      if (!res.data.success) {
        Dialog.create({
          title: res.data.message,
          class: "bg-orange text-white",
          ok: {
            "text-color": "orange",
            color: "white"
          }
        });
        return;
      }

      const opcoesDialog = {
        title: "Confirma a geração de notas?",
        message: res.data.message,
        cancel: "Cancelar",
        persistent: true,
        html: true,
      };

      if (res.data.precisa_confirmar) {
        opcoesDialog.prompt = {
          model: '',
          isValid: val => val === "Confirmo a inclusão de notas repetidas",
        };
      }

      Dialog.create(opcoesDialog)
        .onOk(async () => {
          Loading.show();

          dados.confirmar = true;
          res = await axios.post("/financeiro/remessa", dados);

          Loading.hide();

          let message = "";

          if (res.data.errors) {
            message = res.data.errors
              .map(error => `<li>${error}</li>`)
              .join("");
          }

          commit("aguardeEmailRec", {
            aguardeEmailRec: false
          });

          if (!res.data.success) {
            Dialog.create({
              title: res.data.message,
              message: message,
              class: "bg-warning text-white",
              ok: {
                "text-color": "orange",
                color: "white"
              },
              html: true
            });
            return;
          }

          Dialog.create({
            title: res.data.message,
            message: message,
            class: "bg-positive text-white",
            ok: {
              label: "Ir para o aplicativo de notas",
              flat: true,
              color: "white"
            },
            cancel: {
              label: "Continuar marcando",
              flat: true,
              color: "white"
            },
            html: true,
          })
          .onOk(() => {
            router.push("/notas-fiscais");
          });

          dispatch("resetPagingReceitas");
          dispatch("listarReceitas");

        })
        .onCancel(() => {
          Loading.hide();

          commit("aguardeEmailRec", {
            aguardeEmailRec: false
          });
        });
    } catch (error) {
      Dialog.create({
        title: "Não foi possível contactar ou não possui permissão!",
        class: "bg-negative text-white",
        ok: {
          "text-color": "red",
          color: "white"
        }
      });
      commit("aguardeEmailRec", {
        aguardeEmailRec: false
      });
    }
  },
  openModalLiquidarMassa: ({ commit }) => {
    commit("OpenDialogLiquidarMassa", true);
  },
};

const getters = {
  buscandoRec: state => {
    return state.buscandoRec;
  },
  pagingReceitas: state => {
    return state.pagingReceitas;
  },
  filtrosReceitas: state => {
    return state.filtrosReceitas;
  },
  movcontas: state => {
    return state.movcontas;
  },
  record_count: state => {
    return state.record_count;
  },
  totalReceitas: state => {
    return state.totalReceitas;
  },
  allReceitas: state => {
    return state.allReceitas;
  },
  urlGeral: state => {
    return state.urlGeral;
  },
  urlDetalhe: state => {
    return state.urlDetalhe;
  },
  dataReceitas: state => {
    return state.dataReceitas;
  },
  aguardeEmailRec: state => {
    return state.aguardeEmailRec;
  },
  contadorReceitas: state => {
    return state.contadorReceitas;
  },
  counterReceitas: state => {
    return state.counterReceitas;
  },
  acaoReceitas: state => {
    return state.acaoReceitas;
  },
  selectediesReceitas: state => {
    return state.selectediesReceitas;
  },
  selectedReceitasCadastros: state => {
    return state.selectedReceitasCadastros;
  },
  realizandoAcaoReceitas: state => {
    return state.realizandoAcaoReceitas;
  },
  avl: state => {
    return state.avl;
  },
  loadGateway: state => {
    return state.loadGateway;
  },
  movconta: state => {
    return state.movconta;
  },
  movcontaa: state => {
    return state.movcontaa;
  },
  errorsReceitas: state => {
    return state.errorsReceitas;
  },
  errorsWhatsApp: state => {
    return state.errorsWhatsApp;
  },
  pulseRapido: state => {
    return state.pulseRapido;
  },
  pulseCount: state => {
    return state.pulseCount;
  },
  marcado: state => {
    return state.marcado;
  },
  marquinhos: state => {
    return state.marquinhos;
  },
  mTodos: state => {
    return state.mTodos;
  },
  loadLiquidar: state => {
    return state.loadLiquidar;
  },
  loadFaturamento: state => {
    return state.loadFaturamento;
  },
  saveFaturamento: state => {
    return state.saveFaturamento;
  },
  faturamento: state => {
    return state.faturamento;
  },
  loadModalFaturamento: state => {
    return state.loadModalFaturamento;
  },
  timelineReceitas: state => {
    return state.timelineReceitas;
  },
  timelineActionReceitas: state => {
    return state.timelineActionReceitas;
  },
  pagingTimeReceitas: state => {
    return state.pagingTimeReceitas;
  },
  cadPraDet: state => {
    return state.cadPraDet;
  },
  movPraDet: state => {
    return state.movPraDet;
  },
  movimentacao: state => {
    return state.movimentacao;
  },
  itemsReceitas: state => {
    return state.itemsReceitas;
  },
  buscandoItems: state => {
    return state.buscandoItems;
  },
  gerandoRemessa: state => {
    return state.gerandoRemessa;
  },
  gatewaysDrp: state => {
    return state.gatewaysDrp;
  },
  btnDisabled: state => {
    return state.btnDisabled;
  },
  filtrosDrp: state => {
    return state.filtrosDrp;
  },
  receitasAgrupadas: state => {
    return state.receitasAgrupadas;
  },
  loadNovoREC: state => {
    return state.loadNovoREC;
  },
  OpenDialogNovaReceita: state => {
    return state.OpenDialogNovaReceita;
  },
  OpenOpcoesAvancadasReceitas: state => {
    return state.OpenOpcoesAvancadasReceitas;
  },
  OpenDialogEnviarEmail: state => {
    return state.OpenDialogEnviarEmail;
  },
  OpenDialogInclusaoMassa: state => {
    return state.OpenDialogInclusaoMassa;
  },
  OpenDialogFaturar: state => {
    return state.OpenDialogFaturar;
  },
  OpenDialogAlterarFormaPagamento: state => {
    return state.OpenDialogAlterarFormaPagamento;
  },
  OpenDialogLiquidarMassa: state => {
    return state.OpenDialogLiquidarMassa;
  },
  OpenDialogListarRegistros: state => {
    return state.OpenDialogListarRegistros;
  },
  isPermissoReceitas: state => {
    return state.isPermissoReceitas;
  },
  selected: state => {
    return state.selected;
  },
  buscarPorLiquidacaoReceitas: state => {
    return state.buscarPorLiquidacaoReceitas;
  },
  receitaArquivos: state => {
    return state.receitaArquivos;
  },
  loadFiltrosQueryReceitas: state => {
    return state.loadFiltrosQueryReceitas;
  },
  cadastroReceita: state => {
    return state.cadastroReceita;
  },
  metadadosReceitas: state => {
    return state.metadadosReceitas;
  },
  statusRemessaLista: state => {
    return state.statusRemessaLista;
  },
  statusRemessaDrp: state => {
    return state.statusRemessaDrp;
  },
  receitaQrcode: state => {
    return state.receitaQrcode;
  },
  newRecorrencia: state => {
    return state.newRecorrencia;
  },
  recorrenciaQrCode: state => {
    return state.recorrenciaQrCode;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
