import axios from "@/axios-auth";
import { Notify } from "quasar";
import moment from "moment";

const state = {
  openDialogNovoPlano: false,
  listandoPlanos: false,
  planos: [],
  plano: {
    nome: "",
    descricao: "",
    periodo: null,
    valor: 0,
    is_periodo_determinado: false,
    dtini: moment().format("DD/MM/YYYY"),
    dtfim: moment().format("DD/MM/YYYY"),
    quantidade_dias_trial: 0,
    ordem: 0,
    is_destaque: false,
    descontos: [],
  },
  periodos: [],
  OpenDrawerUpgradePlano: false
};

const mutations = {
  setPlanos: (state, userData) => {
    state.planos = userData;
  },
  setPlano: (state, userData) => {
    state.plano = userData;
  },
  setListandoPlanos: (state, userData) => {
    state.listandoPlanos = userData;
  },
  setOpenDialogNovoPlano: (state, userData) => {
    state.openDialogNovoPlano = userData;
  },
  setPeriodos: (state, userData) => {
    state.periodos = userData;
  },
  setOpenDrawerUpgradePlano: (state, userData) => {
    state.OpenDrawerUpgradePlano = userData;
  }
};

const actions = {
  toggleDialogNovoPlano: ({ commit }, userData) => {
    commit("setOpenDialogNovoPlano", userData);
  },
  toggleUpgradePlano: ({ commit }, userData) => {
    commit("setOpenDrawerUpgradePlano", userData);
  },
  listarPlanos: ({ commit }) => {
    if (state.listandoPlanos) return;

    commit("setListandoPlanos", true);

    axios
      .get("/planos")
      .then(res => {
        commit("setListandoPlanos", false);

        if (!res.data.success) {
          return;
        }

        const periodos = res.data.periodos.map((value, index) => ({ value: index, label: value }));

        commit("setPlanos", res.data.planos);
        commit("setPeriodos", periodos);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("setListandoPlanos", false);
      });
  },
  clearPlano: ({ commit }) => {
    commit("setPlano", {
      nome: "",
      descricao: "",
      periodo: null,
      valor: 0,
      is_periodo_determinado: false,
      dtini: moment().format("DD/MM/YYYY"),
      dtfim: moment().format("DD/MM/YYYY"),
      descontos: [],
    });
  }
};

const getters = {
  plano: state => {
    return state.plano;
  },
  planos: state => {
    return state.planos;
  },
  listandoPlanos: state => {
    return state.listandoPlanos;
  },
  openDialogNovoPlano: state => {
    return state.openDialogNovoPlano;
  },
  periodos: state => {
    return state.periodos;
  },
  OpenDrawerUpgradePlano: state => {
    return state.OpenDrawerUpgradePlano;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
