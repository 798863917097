<template>
  <q-dialog v-model="openDialogAddComissoesManual" persistent>
    <q-card style="width: 400px; max-width: 100%;" class="q-pb-md">

        <q-card-section class="row items-center no-wrap">
          <div class="text-h6" :v-if="modalEdit">
            {{ this.modalEdit ? 'Editar Comissão' : 'Nova Comissão'}}
          </div>

          <q-space />

          <q-btn
            flat
            round
            dense
            v-close-popup
            icon="close"
            @click="closeModal()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="row q-col-gutter-md">

            <!-- cadastro_id -->
            <div class="col-12">
              <q-select
                dense
                v-model="comissao.cadastro_id"
                :error="(errors.cadastro_id) ? true : false"
                :options="listFuncionario"
                label="Funcionário/Fornecedor"
                option-label="label"
                option-value="id"
                :rules="[val => !!val || 'Campo Obrigatório']"
                use-input
                emit-value
                map-options
                input-debounce="0"
                clearable
                @filter="filterFuncionario"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Sem resultados
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.cadastro_id"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-select>

            </div>

            <!-- descricao -->
            <div class="col-12" id="descricao">
              <q-input
                dense
                bottom-slots
                :error="errors.descricao ? true : false"
                v-model="comissao.descricao"
                label="Descrição"
                ref="descricao"
                @keydown.enter.prevent="pulaProx('descricao')"
                :rules="[
                  val => !!val || 'Campo obrigatório',
                ]"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.descricao"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

            <!-- cliente_id -->
            <div class="col-12">
              <q-select
                dense
                v-model="comissao.cliente_id"
                :error="(errors.cliente_id) ? true : false"
                :options="listClientes"
                label="Clientes"
                option-label="label"
                option-value="id"
                use-input
                emit-value
                map-options
                input-debounce="0"
                clearable
                @filter="filterCliente"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Sem resultados
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.cliente_id"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-select>
            </div>

            <!-- recorrencia_id -->
            <div class="col-12">
              <q-select
                dense
                v-model="comissao.recorrencia_id"
                :error="(errors.recorrencia_id) ? true : false"
                :options="listRecorrencias"
                label="Recorrencias"
                option-label="descricao"
                option-value="id"
                use-input
                emit-value
                map-options
                input-debounce="0"
                clearable
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Sem resultados
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.recorrencia_id"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-select>
            </div>

            <!-- dtmovimento -->
            <div class="col-12" id="vencimento">
              <q-input
                bottom-slots
                :error="errors.dtmovimento ? true : false"
                v-model="comissao.dtmovimento"
                label="Data Movimento"
                mask="##/##/####"
                @click="$refs.qDateProxy.show()"
                :rules="[
                  val => !!val || 'Campo obrigatório',
                ]"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="comissao.dtmovimento"
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        minimal
                        today-btn
                        @input="() => $refs.qDateProxy.hide()"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.dtmovimento"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

            <!-- parcela -->
            <div class="col-6">
              <q-input
                :error="errors.parcela ? true : false"
                v-model="comissao.parcela"
                label="Parcela"
                mask="###"
                :rules="[
                  val => !!val || 'Campo obrigatório',
                ]"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.parcela"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

            <!-- valor -->
            <div class="col-6" id="valor">
              <q-field
                v-model="comissao.valor"
                label="Valor"
                bottom-slots
                id="valor"
                :error="errors.valor ? true : false"
                :rules="[
                  val => !!val || 'Campo obrigatório',
                ]"
              >
                <template
                  v-slot:control="{ id, floatingLabel, value, emitValue }"
                >
                  <money
                    :id="id"
                    class="q-field__native text-right"
                    :value="value"
                    @input="emitValue"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                    ref="valor"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.valor" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-field>
            </div>


            <div class="col-12" id="planodeconta">
              <q-select
                dense
                v-model="valuePC"
                use-input
                :option-label="
                  value =>
                    value === null
                      ? 'Item Vazio'
                      : value.caminho != null
                      ? value.caminho
                      : value.titulo
                "
                emit-value
                :option-value="value => (value === null ? null : value)"
                :options="planoContasFilterResult"
                :error="errors.planodeconta_id ? true : false"
                label="Plano de Conta"
                clearable
                ref="planodecontaDespesa"
                @filter="planoContasFilter"
                :rules="[
                  val => !!val || 'Campo obrigatório',
                ]"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem Resultados</q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.planodeconta_id"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-select>
            </div>

          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveComissao"
            id="salvar"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>

    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment';
import axios from '@/axios-auth';
import { Money } from "v-money";
import { bus } from '@/main';

export default {
  name: 'modal-add-comissoes-manual',
  components: { Money },
  data() {
    return {
      openDialogAddComissoesManual: false,
      modalEdit: false,
      loading: true,
      loadingSalvar: false,
      // autocomplete
      carregando: false,
      options: [],
      listFuncionario: [],
      listFuncionarioDB: [],
      listClientes: [],
      listClientesDB: [],
      listRecorrencias: [],
      errors: [],
      selectItem: null,
      autocompleteResult: null,
      comissao: {
        valor: 0,
        descricao: "",
        cliente_id: "",
        dtmovimento: moment().format('DD/MM/YYYY'),
        cadastro_id: "",
        parcela: 1,
        tipo: "faturamento",
        recorrencia_id: null,
      },
      valuePC: null,
      planoContasFilterResult: [],
      planosdecontasComissoes: [],
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
    };
  },
  watch: {
    'comissao.cliente_id' (value){
      this.getListRecorrencias(value);
    }
  },
  methods: {
    clearModal() {
      this.errors = {};
      this.comissao.valor = 0;
      this.comissao.descricao = "";
      this.comissao.cliente_id = "";
      this.comissao.dtmovimento = moment().format('DD/MM/YYYY');
      this.comissao.cadastro_id = "";
      this.comissao.parcela = 1;
      this.comissao.tipo = "faturamento";
      this.comissao.recorrencia_id = null;
      this.valuePC = null;
    },
    closeModal() {
      this.clearModal();
      this.openDialogAddComissoesManual = false;
      this.modalEdit = false;
    },
    openModalNovo() {
      this.clearModal();
      this.openDialogAddComissoesManual = true;
      this.modalEdit = false;
    },
    openModalEdit(id) {
      axios.get("/comissoes/edit/" + id)
        .then(res => {
          this.listClientes = this.listClientesDB;
          this.listFuncionario = this.listFuncionarioDB;
          this.comissao.cliente_id = res.data.comissao.cliente_id;
          this.comissao.valor = res.data.comissao.valor;
          this.comissao.descricao = res.data.comissao.descricao;
          this.comissao.dtmovimento = moment(res.data.comissao.dtmovimento, 'YYYY-MM-DD').format('DD/MM/YYYY');;
          this.comissao.cadastro_id = res.data.comissao.cadastro_id;
          this.comissao.parcela = res.data.comissao.parcela;
          this.comissao.recorrencia_id = res.data.comissao.recorrencia_id;
          this.comissao.tipo = "faturamento";
          this.comissao.id = res.data.comissao.id;

          this.planosdecontasComissoes.find(planos => { if (planos.id === res.data.comissao.planodeconta_id) { this.valuePC = planos; } });
          this.openDialogAddComissoesManual = true;
          this.modalEdit = true;
        })
        .catch(() => {
          this.$q.notify({
            message:
              "Não foi possível buscar comissão ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    filterCliente(val, update, abort) {
       if (val === '') {
        update(() => {
          this.listClientes = this.listClientesDB;
        });
        return;
      }
      update(() => {
        axios.get("/cadastros/busca?cliente=1&term=" + val).then(res => {
          this.listClientes = res.data;
        });
      });
    },
    filterFuncionario(val, update, abort) {
       if (val === '') {
        update(() => {
          this.listFuncionario = this.listFuncionarioDB;
        });
        return;
      }
      update(() => {
         axios.get("/cadastros/busca?funcionario=1&fornecedor=1&term=" + val).then(res => {
          this.listFuncionario = res.data;
        });
      });
    },
    getListClient() {
       axios.get("/cadastros/busca?cliente=1").then(res => {
          this.listClientesDB = res.data;
        });
    },
    getListFuncionario() {
       axios.get("/cadastros/busca?funcionario=1&fornecedor=1").then(res => {
          this.listFuncionarioDB = res.data;
        });
    },
    getListRecorrencias(id) {
      if(!this.modalEdit){
        this.comissao.recorrencia_id = null;
      }
       axios.get("/cadastros/recorrencias/" + id).then(res => {
          this.listRecorrencias = res.data.data.recorrencias;
        });
    },
    planoContasFilter(val, update) {
      update(() => {
        this.planoContasFilterResult = this.planosdecontasComissoes.filter(
          plano => plano.titulo.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
    loadPlanosdecontasComissoes() {
      axios
        .get('/plano-contas?cd=D')
        .then(res => {
          this.planosdecontasComissoes = res.data.planodecontas;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    saveComissao() {
      if(this.modalEdit){
        this.editComissao();
      }else{
        this.createComissao();
      }
    },
    async createComissao() {
      if (this.valuePC === null) {
        this.errors = Object.assign({}, { planodeconta_id: { _empty: 'Este campo não pode ser em branco'} });
        this.$q.notify({
              message: 'Verifique os dados no formulário',
              color: "orange",
              position: "top-right"
            });
        return;
      }

      let planodeconta_id = this.valuePC.id;
      const newData = Object.assign({ planodeconta_id: planodeconta_id  }, this.comissao);

      await axios
        .post("/comissoes/add", newData)
        .then(res => {
          if (res.data.success) {

            this.openDialogAddComissoesManual = false;
            this.fpAtualizarPagamento();

            this.$q.notify({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });

            return true;
          } else {
            this.errors = res.data.errors;
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });

          }
        })
        .catch(() => {
          this.$q.notify({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    async editComissao() {
      if (this.valuePC === null) {
        this.errors = Object.assign({}, { planodeconta_id: { _empty: 'Este campo não pode ser em branco'} });
        this.$q.notify({
              message: 'Verifique os dados no formulário',
              color: "orange",
              position: "top-right"
            });
        return;
      }

      let planodeconta_id = this.valuePC.id;
      const newData = Object.assign({ planodeconta_id: planodeconta_id  }, this.comissao);

      await axios
        .post("/comissoes/edit/" + this.comissao.id , newData)
        .then(res => {
          if (res.data.success) {

            this.openDialogAddComissoesManual = false;
            this.fpAtualizarPagamento();

            this.$q.notify({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });

            return true;
          } else {
            this.errors = res.data.errors;
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });

          }
        })
        .catch(() => {
          this.$q.notify({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    fpAtualizarPagamento() {
      bus.$emit('AtualizarComissoes');
    },
  },
  mounted() {
    bus.$on('abrirAddComissoesManual', () => this.openModalNovo());
    bus.$on('abrirEditComissoesManual', this.openModalEdit);
    this.$store.getters.OpenDialogNovaDespesa;
    this.getListClient();
    this.getListFuncionario();
    this.loadPlanosdecontasComissoes();
  },
  beforeMount() {
    bus.$off('abrirAddComissoesManual');
    bus.$off('abrirEditComissoesManual');
  },
};
</script>
