<template>
  <div id="caixasParametros">
    <div class="text-right">
      <q-btn
        flat
        round
        class="q-ml-md"
        icon="settings"
        color="primary"
        @click="openModalOptions()"
      />
    </div>

    <q-table
      title
      :data="caixas"
      :columns="columns"
      row-key="name"
      :separator="separator"
      :loading="loadCaixas"
      no-data-label="Nenhum resultado encontrado."
      :pagination.sync="pagination"
    >
      <template v-slot:bottom>
        <div></div>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" class="text-right">{{props.row.id}}</q-td>
          <q-td key="nome">{{props.row.nome}}</q-td>
          <q-td key="numero" class="text-left">{{props.row.numero}}</q-td>
          <q-td class="tdfora">
            <div class="divfora">
              <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                <q-btn
                  @click="editar(props.row)"
                  size="12px"
                  class="bg-primary text-white"
                  icon="edit"
                ></q-btn>
                <q-btn
                  @click="excluir(props.row)"
                  class="bg-negative text-white"
                  icon="block"
                  size="12px"
                ></q-btn>
              </div>
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <q-dialog v-model="modalOptions" persistent>
      <q-card style="max-width: 400px; width: 90%;">
        <q-card-section class="row items-center">
          <div class="text-h6">Opções</div>
          <q-space />
          <q-btn
            flat
            round
            dense
            icon="close"
            v-close-popup
            @click="closeModalOptions()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-lg">

            <div class="col-12">
              <q-checkbox
                label="Permitir cancelamento de contas abertas criadas pelo caixa fechado"
                v-model="options.movcaixas_cancelar_movconta_aberta"
                true-value="1"
                false-value="0"
                color="green"
              />
            </div>

            <div class="col-12">
              <q-checkbox
                label="Permitir o estorno de liquidação de contas atreladas ao caixa fechado"
                v-model="options.movcaixas_cancelar_movconta_liquidada"
                true-value="1"
                false-value="0"
                color="green"
              />
            </div>

            <div class="col-12">
              <q-checkbox
                label="Permitir o estorno de sangrias que foram utilizadas em depósitos/transferências"
                v-model="options.movcaixas_cancelar_sangria_deposito"
                true-value="1"
                false-value="0"
                color="green"
              />
            </div>

            <div class="col-12">
              <q-checkbox
                label="Venda detalhada"
                v-model="options.movcaixas_venda_detalhada"
                true-value="1"
                false-value="0"
                color="green"
              />
            </div>

            <div class="col-12">
              <q-checkbox
                label="Compra detalhada"
                v-model="options.movcaixas_compra_detalhada"
                true-value="1"
                false-value="0"
                color="green"
              />
            </div>

            <div class="col-12">
              <q-checkbox
                label="Permitir uma venda com quantidade e valor maior que o resumo"
                v-model="options.movcaixas_permitir_venda_maior_que_resumo"
                true-value="1"
                false-value="0"
                color="green"
              />
            </div>

            <div class="col-12">
              <q-checkbox
                label="Utilizar PREST CONT"
                v-model="options.movcaixas_utiliza_prest_cont"
                true-value="1"
                false-value="0"
                color="green"
              />
            </div>

            <div class="col-12">
              <q-checkbox
                label="Validar estoque de moedas no reforço/sangria"
                v-model="options.movcaixas_validar_estoque_moedas"
                true-value="1"
                false-value="0"
                color="green"
              />
            </div>

            <div class="col-12">
              <q-checkbox
                label="Permitir recebimento de múltiplas contas"
                v-model="options.movcaixas_receber_multiplos"
                true-value="1"
                false-value="0"
                color="green"
              />
            </div>

            <div class="col-12 q-mb-md">
              <q-select
                emit-value
                map-options
                label="Bloquear a abertura do caixa"
                :options="optionsBloquearCaixa"
                v-model="options.movcaixas_bloquear_abertura"
              />
            </div>

          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            label="Salvar"
            clickable
            v-ripple
            @click="salvarOpcoes()"
            :loading="salvandoOpcoes"
            color="green"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Loading } from "quasar";
import axios from "@/axios-auth";

export default {
  name: "caixas-parametros",
  data() {
    return {
      separator: "horizontal",
      modalOptions: false,
      pagination: {
        rowsPerPage: 0
      },
      columns: [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "right",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "nome",
          align: "left",
          label: "Nome",
          field: row => row.nome,
          sortable: true
        },
        {
          name: "numero",
          label: "Número",
          field: row => row.numero,
          sortable: true,
          align: "left"
        },
        { name: 'tdfora' },
      ],
      salvandoOpcoes: false,
      options: {
        movcaixas_cancelar_movconta_aberta: "0",
        movcaixas_cancelar_movconta_liquidada: "0",
        movcaixas_cancelar_sangria_deposito: "0",
        movcaixas_venda_detalhada: "0",
        movcaixas_compra_detalhada: "0",
        movcaixas_permitir_venda_maior_que_resumo: "0",
        movcaixas_utiliza_prest_cont: "0",
        movcaixas_validar_estoque_moedas: "0",
        movcaixas_receber_multiplos: "0",
      },
      optionsOriginais: {
        movcaixas_cancelar_movconta_aberta: "0",
        movcaixas_cancelar_movconta_liquidada: "0",
        movcaixas_cancelar_sangria_deposito: "0",
        movcaixas_compra_detalhada: "0",
        movcaixas_permitir_venda_maior_que_resumo: "0",
        movcaixas_utiliza_prest_cont: "0",
        movcaixas_validar_estoque_moedas: "0",
      },
      optionsBloquearCaixa: [
        {
          label: 'Nenhum bloqueio',
          value: '0',
        },
        {
          label: 'Mesmo caixa mais de uma vez',
          value: '1',
        },
        {
          label: 'Mesmo operador mais de uma vez',
          value: '2',
        },
        {
          label: 'Mesmo caixa ou operador mais de uma vez',
          value: '3',
        },
      ],
    };
  },
  methods: {
    editar(caixa) {
      this.$store.dispatch("editarCaixa", caixa);
    },
    excluir(caixa) {
      this.$store.dispatch("excluirCaixa", caixa);
    },
    openModalOptions() {
      this.modalOptions = true;

      axios
        .get("/caixas/options")
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right",
            });
            return;
          }

          this.optionsOriginais = res.data.options;
          this.options = res.data.options;
        })
        .catch(() => {
          this.$q.notify({
            message: "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right",
          });
        });
    },
    closeModalOptions() {
      this.modalOptions = false;
    },
    salvarOpcoes() {
      if (this.salvandoOpcoes) return;

      this.salvandoOpcoes = true;

      if (this.optionsOriginais.movcaixas_utiliza_prest_cont === this.options.movcaixas_utiliza_prest_cont
        && !(parseInt(this.options.movcaixas_utiliza_prest_cont))
      ) {
        this.requestSalvarOpcoes();
        return;
      }

      this.$q.dialog({
        title: 'Utilizar PREST CONT',
        message: `Ao ativar a opção de 'Utilizar PREST CONT', todas as nomenclaturas de CRE CXAQUI e DEB CXAQUI serão
          alteradas para PREST CONT, confirma a alteração?`,
        cancel: 'Cancelar',
        persistent: true,
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          this.requestSalvarOpcoes();
        })
        .onCancel(() => {
          this.salvandoOpcoes = false;
          Loading.hide();
        })
        .onDismiss(() => {
          this.salvandoOpcoes = false;
          Loading.hide();
        });
    },
    requestSalvarOpcoes() {
      this.salvandoOpcoes = true;

      axios
        .post("/caixas/options", this.options)
        .then(res => {
          this.salvandoOpcoes = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? "green" : "orange",
            position: "top-right"
          });

          if (!res.data.success) {
            return;
          }

          this.options = res.data.options;

          this.closeModalOptions();
        })
        .catch(() => {
          this.salvandoOpcoes = false;
          this.$q.notify({
            message: "Houve um erro ao salvar as opções, tente novamente!",
            color: "red",
            position: "top-right"
          });
      });
    },
  },
  computed: {
    ...mapGetters(["caixas", "loadCaixas"])
  },
  mounted() {
    this.$store.dispatch("caixas");
  }
};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
