<template>
  <div id="modalListarRegistros">
    <q-dialog v-model="OpenDialogListarRegistros">
      <q-card style="width: 850px">
        <q-card-section class="row items-center">
          <div class="text-h6">Registros - {{ movcontaId}}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>

        <q-card-section>
          <q-table
            flat
            row-key="id"
            :data="registros"
            :columns="columns"
            :loading="loadingRegistros"
            :rows-per-page-options="[0]"
            hide-pagination
            no-data-label="Nenhum resultado encontrado."
          >
            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand" class="cursor-pointer">
                <q-td key="id_transaction" style="display: flex; align-items: center; padding-left: 0;">
                  <q-icon :name="props.expand ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" size="sm" />
                  <span>{{ props.row.response_idtransaction }}</span>
                </q-td>
                <q-td key="tipo">
                  {{ getPaymentMethod(props.row.payment_method) }}
                </q-td>
                <q-td key="status">
                  {{ props.row.response_message }} <br>
                  <span>{{ props.row.response_operationdate }}</span>
                </q-td>
                <q-td key="valor">
                  R$ {{ props.row.valor | currency }} <br>
                </q-td>
                <q-td class="tdfora" key="actions">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn 
                        v-if="props.row.fatura_id !== faturaId"
                        class="bg-positive text-white"
                        icon="arrow_forward"
                        size="12px"
                        :loading="ativandoFatura"
                        @click="ativarFatura(props.row.response_idtransaction)"
                      >
                        <q-tooltip>Ativar Fatura</q-tooltip>
                      </q-btn>
                      <q-btn 
                        class="bg-info text-white"
                        icon="search"
                        size="12px"
                        :loading="searchingRegistro"
                        @click="consultarRegistro(props.row.response_idtransaction)"
                      >
                        <q-tooltip>Consultar Registro</q-tooltip>
                      </q-btn>
                      <q-btn 
                        v-if="[2, 6].includes(props.row.payment_method)"
                        class="bg-secondary text-white"
                        icon="reply"
                        size="12px"
                        @click="gerenciarPagamento(props.row.response_idtransaction)"
                      >
                        <q-tooltip>Estornar Registro</q-tooltip>
                      </q-btn>
                      <q-btn 
                        v-if="props.row.payment_method === 1"
                        class="bg-negative text-white"
                        icon="delete_forever"
                        size="12px"
                        :loading="cancelingRegistro"
                        @click="gerenciarPagamento(props.row.response_idtransaction, 'cancelar')"
                      >
                        <q-tooltip>Cancelar Registro</q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>

              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%" style="text-wrap: wrap;">
                  <div class="text-left">
                    <div class="q-mb-md">
                      <div v-if="props.row.sacado_nome">Nome: <b>{{ props.row.sacado_nome }}</b></div>
                      <div v-if="props.row.sacado_documento">Documento: <b>{{ props.row.sacado_documento }}</b></div>
                      <div v-if="props.row.sacado_email">Email: <b>{{ props.row.sacado_email }}</b></div>
                      <div v-if="props.row.sacado_telefone">Telefone: <b>{{ props.row.sacado_telefone }}</b></div>
                      <div v-if="props.row.sacado_cep">CEP: <b>{{ props.row.sacado_cep }}</b></div>
                      <div v-if="props.row.sacado_logradouro">Logradouro: <b>{{ props.row.sacado_logradouro }}</b></div>
                      <div v-if="props.row.sacado_numero">Número: <b>{{ props.row.sacado_numero }}</b></div>
                      <div v-if="props.row.sacado_complemento">Complemento: <b>{{ props.row.sacado_complemento }}</b></div>
                      <div v-if="props.row.sacado_bairro">Bairro: <b>{{ props.row.sacado_bairro }}</b></div>
                      <div v-if="props.row.sacado_cidade">
                        Cidade: <b>{{ props.row.sacado_cidade }} - {{ props.row.sacado_estado }}</b>
                      </div>
                    </div>

                    <div class="q-mb-md">
                      <div v-if="props.row.payment_method">Forma de pagamento: <b>{{ getPaymentMethod(props.row.payment_method) }}</b></div>
                      <div v-if="props.row.vencimento">Vencimento: <b>{{ props.row.vencimento | formatDate }}</b></div>
                      <div v-if="props.row.instrucao">Instrução: <b>{{ props.row.instrucao }}</b></div>
                      <div v-if="props.row.cancelar_apos_vencimento">
                        Cancelar após o vencimento: <b>{{ props.row.cancelar_apos_vencimento ? 'Sim' : 'Não' }}</b>
                      </div>
                      <div v-if="props.row.response_idtransaction">Transação: <b>{{ props.row.response_idtransaction }}</b></div>
                      <div v-if="props.row.response_message">Status: <b>{{ props.row.response_message }}</b></div>
                      <div v-if="props.row.response_description" style="white-space: pre-line">Descrição: <b>{{ props.row.response_description }}</b></div>
                      <div v-if="props.row.response_digitableline">Linha digitável: <b>{{ props.row.response_digitableline }}</b></div>
                    </div>
                  </div>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";

export default {
  name: "modal-listar-registros",
  props: {
    movcontaId: {
      type: Number,
      required: true
    },
    faturaIdProp: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loadingRegistros: false,
      searchingRegistro: false,
      cancelingRegistro: false,
      ativandoFatura: false,
      faturaId: this.faturaIdProp,
      registros: [],
      columns: [
      {
          name: "id_transaction",
          required: true,
          label: "Transação",
          align: "left",
          field: row => row.response_idtransaction,
        },
        {
          name: "tipo",
          required: true,
          label: "Forma de Pagamento",
          align: "left",
          field: row => row.payment_method,
          sortable: true,
        },
        {
          name: "status",
          required: true,
          label: "Status",
          align: "left",
          field: row => row.response_message,
          sortable: true,
        },
        {
          name: "valor",
          required: true,
          label: "Valor",
          align: "left",
          field: row => row.valor,
          sortable: true,
        }
      ],
    };
  },
  methods: {
    fetchRegistros() {
      if (this.loadingRegistros) return;
      this.loadingRegistros = true;

      axios
        .get(`/financeiro/items/${this.movcontaId}?action=${'listar'}`)
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "red",
              position: "top-right"
            });
            return;
          }

          this.registros = res.data.registros;
        })
        .catch(error => {
          Notify.create({
            message: "Houve um erro ao buscar registros.",
            color: "red",
            position: "top-right"
          });
        })
        .finally(() => {
          this.loadingRegistros = false;
        });
    },
    consultarRegistro(idTransaction) {
      if (this.searchingRegistro) return;

      Loading.show();
      this.searchingRegistro = true;

      axios
        .get(`/financeiro/items/${this.movcontaId}?transaction_id=${idTransaction}&action=consultar`)
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "red",
              position: "top-right"
            });
            return;
          }

          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          // Atualiza o registro na lista
          const registro = res.data.registro;
          const index = this.registros.findIndex(r => r.response_idtransaction === idTransaction);
          this.registros[index]['response_message'] = registro['response_message'];
        })
        .catch(error => {
          Notify.create({
            message: "Houve um erro ao consultar registro.",
            color: "red",
            position: "top-right"
          });
        })
        .finally(() => {
          this.searchingRegistro = false;
          Loading.hide();
        });
    },
    gerenciarPagamento(idTransaction, action = 'estornar') {
      if (this.cancelingRegistro || this.searchingRegistro) return;
      
      const upperAction = action.charAt(0).toUpperCase() + action.slice(1);
      Dialog.create({
        title: `${upperAction} Registro`,
        message: `Tem certeza que deseja ${action} este registro?`,
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();

          axios
            .post(`/financeiro/items/${this.movcontaId}?transaction_id=${idTransaction}&action=cancelar`)
            .then(res => {
              if (!res.data.success) {
                Notify.create({
                  message: res.data.message,
                  color: "red",
                  position: "top-right"
                });
                return;
              }
    
              Notify.create({
                message: res.data.message,
                color: "green",
                position: "top-right"
              });
    
              this.fetchRegistros();
            })
            .catch(error => {
              Notify.create({
                message: "Houve um erro ao estornar registro.",
                color: "red",
                position: "top-right"
              });
            })
            .finally(() => {
              Loading.hide();
            });
        });
    },
    ativarFatura(idTransaction) {
      if (this.ativandoFatura) return;

      Dialog.create({
        title: "Ativar Fatura",
        message: "Tem certeza que deseja ativar esta fatura?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
          this.ativandoFatura = true;

          axios
            .post(`/financeiro/items/${this.movcontaId}?transaction_id=${idTransaction}&action=ativar_fatura`)
            .then(res => {
              if (!res.data.success) {
                Notify.create({
                  message: res.data.message,
                  color: "red",
                  position: "top-right"
                });
                return;
              }

              Notify.create({
                message: res.data.message,
                color: "green",
                position: "top-right"
              });

              this.faturaId = res.data.fatura_id;
              this.fetchRegistros();
            })
            .catch(() => {
              Notify.create({
                message: "Houve um erro ao ativar fatura.",
                color: "red",
                position: "top-right"
              });
            })
            .finally(() => {
              this.ativandoFatura = false;
              Loading.hide();
            });
        });
    },
    getPaymentMethod(method){
      switch(method) {
        case 1:
          return 'Boleto';
        case 2:
          return 'Cartão de Crédito';
        case 6:
          return 'PIX';
      }
    },
    closeModal() {
      this.$store.commit("OpenDialogListarRegistros", false);
    },
    reset() {
      this.registros = [];
    }
  },
  computed: {
    OpenDialogListarRegistros: {
      get() {
        return this.$store.getters.OpenDialogListarRegistros;
      },
      set() {}
    },
  },
  watch: {
    OpenDialogListarRegistros: {
      handler() {
        if (this.OpenDialogListarRegistros) {
          this.fetchRegistros();
          return;
        }

        this.reset();
      },
      immediate: true
    }
  }
};
</script>
