<template>
  <div id="tourCaixas"></div>
</template>

<script>
export default {
  name: "tour-caixas"
};
</script>

<style lang="stylus" scoped></style>
