import axios from "@/axios-auth";
import { Notify } from "quasar";
import moment from "moment";

const state = {
  pagingCompras: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  loadingSalvarEstoque: false,
  loadingEstoque: false,
  estoques: [],
  estoque: {},
  funcionariosEstoque: [],
  errorsEstoque: [],
  buscandoFuncEstoque: false,
  listaEstoques: false,
  produtosEstoque: [],
  produtosEstoqueAno: [],
  //compras estoque
  compras: [],
  fornecedorSelecionado: null,
  total: 0.0,
  comprasProdutos: [],
  fornecedores: [],
  carregamentoCompras: false,
  carregamentoItemsCompras: false,
  //
  openDialogEstoque: false,
  openDialogCompraEstoque: false,
  OpenDialogTransferirEstoque: false,
  openDialogInfoEstoque: false,
  casoTransferir: 1,
  isPermissoEstoque: true,
  informacoesEstoque: {
    estoque: {},
    funcionarios: [],
  },
  openVendaProdutosSemEstoque: false,
  filtrosCompras: {
    status: null,
    produto_id: null,
    dtini: moment().startOf('month').format("DD-MM-YYYY"),
    dtfim: moment().endOf('month').format("DD-MM-YYYY")
  },
  produtos: [],
  produtoNome: null
};

const mutations = {
  pagingCompras: (state, userData) => {
    state.pagingCompras.finder = userData.finder;
    state.pagingCompras.page = userData.page;
    state.pagingCompras.current = userData.current;
    state.pagingCompras.count = userData.count;
    state.pagingCompras.perPage = userData.perPage;
    state.pagingCompras.prevPage = userData.prevPage;
    state.pagingCompras.nextPage = userData.nextPage;
    state.pagingCompras.pageCount = userData.pageCount;
    state.pagingCompras.sort = userData.sort;
    state.pagingCompras.direction = userData.direction;
    state.pagingCompras.limit = userData.limit;
    state.pagingCompras.sortDefault = userData.sortDefault;
    state.pagingCompras.directionDefault = userData.directionDefault;
    state.pagingCompras.scope = userData.scope;
  },
  loadingSalvarEstoque: state => {
    state.loadingSalvarEstoque = !state.loadingSalvarEstoque;
  },
  loadingEstoque: (state, userData) => {
    state.loadingEstoque = userData;
  },
  estoques: (state, userData) => {
    state.estoques = userData;
  },
  estoque: (state, userData) => {
    state.estoque = userData;
  },
  funcionariosEstoque: (state, userData) => {
    state.funcionariosEstoque = userData;
  },
  errorsEstoque: (state, userData) => {
    state.errorsEstoque = userData;
  },
  buscandoFuncEstoque: state => {
    state.buscandoFuncEstoque = !state.buscandoFuncEstoque;
  },
  listaEstoques: state => {
    state.listaEstoques = !state.listaEstoques;
  },
  produtosEstoque: (state, userData) => {
    state.produtosEstoque = userData;
  },
  produtosEstoqueAno: (state, userData) => {
    state.produtosEstoqueAno = userData;
  },
  openDialogEstoque: (state, userData) => {
    state.openDialogEstoque = userData;
  },
  openDialogCompraEstoque: (state, userData) => {
    state.openDialogCompraEstoque = userData;
  },
  OpenDialogTransferirEstoque: (state, userData) => {
    state.OpenDialogTransferirEstoque = userData;
  },
  openDialogInfoEstoque: (state, userData) => {
    state.openDialogInfoEstoque = userData;
  },
  casoTransferir: (state, userData) => {
    state.casoTransferir = userData;
  },
  isPermissoEstoque: (state, userData) => {
    state.isPermissoEstoque = userData;
  },
  compras: (state, userData) => {
    state.compras = userData;
  },
  fornecedorSelecionado: (state, userData) => {
    state.fornecedorSelecionado = userData;
  },
  total: (state, userData) => {
    state.total = userData;
  },
  comprasProdutos: (state, userData) => {
    state.comprasProdutos = userData;
  },
  fornecedores: (state, userData) => {
    state.fornecedores = userData;
  },
  carregamentoCompras: (state, userData) => {
    state.carregamentoCompras = userData;
  },
  setOpenVendaProdutosSemEstoque: (state, userData) => {
    state.openVendaProdutosSemEstoque = userData;
  },
  carregamentoItemsCompras: (state, userData) => {
    state.carregamentoItemsCompras = userData;
  },
  setInformacoesEstoque: (state, userData) => {
    state.informacoesEstoque = userData;
  },
  filtrosCompras: (state, userData) => {
    if(userData.status) state.filtrosCompras.status = userData.status;
    if(userData.produto_id) state.filtrosCompras.produto_id = userData.produto_id;
    if(userData.dtini) state.filtrosCompras.dtini = userData.dtini;
    if(userData.dtfim) state.filtrosCompras.dtfim = userData.dtfim;
  },
  produtoNome: (state, userData) => {
    state.produtoNome = userData;
  },
  produtos: (state, userData) => {
    state.produtos = userData;
  }
};

const actions = {
  resetModal({ commit, state }) {
    commit("compras", []);
    commit("total", 0.0);
    commit("fornecedorSelecionado", null);
    commit("openDialogCompraEstoque", !state.openDialogCompraEstoque);
  },
  getFornecedores({ commit }) {
    commit("carregamentoItemsCompras", true);

    axios
      .get("/cadastros?ativo=1&fornecedor=1")
      .then(result => {
        if (result.data.success) {
          commit("fornecedores", result.data.data.cadastros);

          setTimeout(() => {
            commit("carregamentoItemsCompras", false);
          }, 1000);
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  getCompras({ commit, state }, atualizar = false) {
    let page = 1;
    let compras = [];

    if (atualizar) {
      if (!state.pagingCompras.nextPage) return;
      compras = state.comprasProdutos;
      page = state.pagingCompras.page + 1;
    } else {
      page = 1;
    }
    commit("pagingCompras", {
      page: page
    });

    commit("carregamentoCompras", true);

    axios
      .get("/produtos/compras", {
        params: {
          page: state.pagingCompras.page,
          status: state.filtrosCompras.status,
          produto_id: state.filtrosCompras.produto_id,
          dtini: state.filtrosCompras.dtini,
          dtfim: state.filtrosCompras.dtfim,
        }
      })
      .then(result => {
        if (result.data.success) {
          commit("carregamentoCompras", false);
          commit("comprasProdutos", compras.concat(result.data.compras));
          commit("pagingCompras", {
            finder: result.data.paging.Movcompras.finder,
            page: result.data.paging.Movcompras.page,
            current: result.data.paging.Movcompras.current,
            count: result.data.paging.Movcompras.count,
            perPage: result.data.paging.Movcompras.perPage,
            prevPage: result.data.paging.Movcompras.prevPage,
            nextPage: result.data.paging.Movcompras.nextPage,
            pageCount: result.data.paging.Movcompras.pageCount,
            sort: result.data.paging.Movcompras.sort,
            direction: result.data.paging.Movcompras.direction,
            limit: result.data.paging.Movcompras.limit,
            sortDefault: result.data.paging.Movcompras.sortDefault,
            directionDefault: result.data.paging.Movcompras.directionDefault,
            scope: result.data.paging.Movcompras.scope
          });
          commit('produtos', result.data.produtos);
          commit('filtrosCompras', result.data.filters);
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("isPermissoEstoque", false);
        commit("carregamentoCompras", false);
      });
  },
  comprasProdutosItems({ commit }, userData) {
    if (!Number(userData)) return false;
    if (this.fornecedorSelecionado && !this.fornecedorSelecionado.id)
      commit("carregamentoItemsCompras", true);

    axios
      .get("/produtos/compras/" + userData)
      .then(result => {
        if (result.data.success) {
          commit("compras", result.data);
          commit("total", result.data.compra.total);
          commit("fornecedorSelecionado", result.data.compra.fornecedor);
          commit("carregamentoItemsCompras", false);
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("carregamentoItemsCompras", false);
      });
  },
  listarEstoque: ({ commit, state }, userData = {}) => {
    if (state.listaEstoques) return;

    commit("listaEstoques");
    commit("isPermissoEstoque", true);
    axios
      .get("/produtos/estoque", {
        params: {
          funcionarios: userData.tipo
        }
      })
      .then(res => {
        commit("listaEstoques");

        if (!res.data.success) {
          return;
        }

        commit("estoques", res.data.estoques);

        let funcionarios = res.data.funcionarios ?? [];
        if (funcionarios.length > 0) {
          funcionarios.forEach(func => {
            func.quantidade = 0;
            func.valor = 0;
          });
          commit("funcionariosEstoque", funcionarios);
        }
      })
      .catch(err => {
        commit("listaEstoques");
        commit("isPermissoEstoque", false);
      });
  },
  salvaEstoque: ({ commit, dispatch }, userData) => {
    if (state.loadingSalvarEstoque) return;
    commit("loadingSalvarEstoque");

    const recursos = {
      novo: "add-estoque/",
      adicionar: "add-estoque/",
      remover: "sub-estoque/",
      transferir: "transferir/"
    };

    const url =
      "/produtos/" + recursos[userData.tipoDialogEstoque] + userData.id;

    return new Promise((resolve, reject) => {
      axios
        .post(url, {
          quantidade: +parseInt(userData.qnt),
          cadastros: userData.cadastros,
          funcionario: userData.funcionario,
          extracao: userData.extracao,
          cadastro_origem_id: userData.cadastro_origem_id,
          cadastro_destino_id: userData.cadastro_destino_id,
          concurso: userData.concurso,
          evento_id: userData.evento_id,
          vencimento: userData.vencimento,
          tpcontrole: userData.tpcontrole
        })
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });

            commit("estoques", []);
            dispatch("listarEstoque", {
              tipo: userData.tipo === "operador" ? 1 : 0,
              forcarCarregamento: true
            });
            commit("openDialogEstoque", false);
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          commit("loadingSalvarEstoque");
          resolve(res);
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          commit("loadingSalvarEstoque");
          reject();
        });
    });
  },
  produtosEstoque: ({ commit }, userData) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/produtos", {
          params: userData
        })
        .then(res => {
          if (res.data.success) {
            if (!userData.ano) {
              commit("produtosEstoque", res.data.produtos);
            } else {
              commit("produtosEstoqueAno", res.data.eventos);
            }
          }
          resolve(res);
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          reject();
        });
    });
  },
  dialogInfoEstoque: ({ commit }, userData = {}) => {
    let params = {
      produto: userData.produto_id,
      tpcontrole: userData.tpcontrole
    };

    if (userData.tpcontrole === "Extração") {
      params.extracao = userData.extracao;
      params.vencimento = userData.vencimento;
    }

    if (userData.tpcontrole === "Concurso") {
      params.concurso = userData.concurso;
    }

    if (userData.tpcontrole === "Evento") {
      params.evento_id = userData.evento_id;
    }

    axios
      .get("/produtos/estoque", {
        params: params
      })
      .then(res => {
        if (!res.data.success) {
          throw res.data;
        }

        commit("setInformacoesEstoque", {
          estoque: res.data.estoque[0],
          funcionarios: res.data.funcionarios
        });
        commit("openDialogInfoEstoque", true);
      })
      .catch(() => {
        Notify.create({
          message:
            "Não foi possível ver o estoque do produto, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  toggleModalVendaProdutosSemEstoque: ({ commit, state }, userData = null)  => {
    let newValue = !state.openVendaProdutosSemEstoque;

    if (userData) {
      newValue = userData;
    }

    commit("setOpenVendaProdutosSemEstoque", newValue);
  },
  changeFilterPeriodoCompras: ({ commit, dispatch }, userData) => {
    if (state.carregamentoCompras) return;

    let i = moment(state.filtrosCompras.dtini, "DD/MM/YYYY");
    let f = moment(state.filtrosCompras.dtfim, "DD/MM/YYYY");

    if (userData === "prev") {
      i.subtract(1, "months");
      f.subtract(1, "months");
    }
    if (userData === "next") {
      i.add(1, "months");
      f.add(1, "months");
    }
    commit("filtrosCompras", {
      ...state.filtrosCompras,
      page: 1,
      dtini: i.startOf("month").format("DD-MM-YYYY"),
      dtfim: f.endOf("month").format("DD-MM-YYYY")
    });
    dispatch("filtrosCompras");
  },
  filtrosCompras: ({ commit, dispatch }, userData) => {
    let status = userData && userData.status ? userData.status : state.filtrosCompras.status;
    let produto_id = userData && userData.produto_id ? userData.produto_id : state.filtrosCompras.produto_id;
    let dtini = userData && userData.dtini ? userData.dtini : state.filtrosCompras.dtini;
    let dtfim = userData && userData.dtfim ? userData.dtfim : state.filtrosCompras.dtfim;

    commit("filtrosCompras", {
      status: status,
      produto_id: produto_id,
      dtini: dtini,
      dtfim: dtfim,
    });
    dispatch("getCompras");
  },
  produtoNome: ({ commit }, userData) => {
    commit("produtoNome", userData);
  },
};

const getters = {
  loadingSalvarEstoque: state => {
    return state.loadingSalvarEstoque;
  },
  loadingEstoque: state => {
    return state.loadingEstoque;
  },
  estoques: state => {
    return state.estoques;
  },
  estoque: state => {
    return state.estoque;
  },
  funcionariosEstoque: state => {
    return state.funcionariosEstoque;
  },
  buscandoFuncEstoque: state => {
    return state.buscandoFuncEstoque;
  },
  listaEstoques: state => {
    return state.listaEstoques;
  },
  errorsEstoque: state => {
    return state.errorsEstoque;
  },
  produtosEstoque: state => {
    return state.produtosEstoque;
  },
  produtosEstoqueAno: state => {
    return state.produtosEstoqueAno;
  },
  openDialogEstoque: state => {
    return state.openDialogEstoque;
  },
  openDialogCompraEstoque: state => {
    return state.openDialogCompraEstoque;
  },
  OpenDialogTransferirEstoque: state => {
    return state.OpenDialogTransferirEstoque;
  },
  openDialogInfoEstoque: state => {
    return state.openDialogInfoEstoque;
  },
  casoTransferir: state => {
    return state.casoTransferir;
  },
  isPermissoEstoque: state => {
    return state.isPermissoEstoque;
  },
  compras: state => {
    return state.compras;
  },
  fornecedorSelecionado: state => {
    return state.fornecedorSelecionado;
  },
  total: state => {
    return state.total;
  },
  comprasProdutos: state => {
    return state.comprasProdutos;
  },
  fornecedores: state => {
    return state.fornecedores;
  },
  carregamentoCompras: state => {
    return state.carregamentoCompras;
  },
  carregamentoItemsCompras: state => {
    return state.carregamentoItemsCompras;
  },
  informacoesEstoque: state => {
    return state.informacoesEstoque;
  },
  openVendaProdutosSemEstoque: state => {
    return state.openVendaProdutosSemEstoque;
  },
  filtrosCompras: state => {
    return state.filtrosCompras;
  },
  produtoNome: state => {
    return state.produtoNome;
  },
  produtos: state => {
    return state.produtos;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
