<template>
  <q-page id="caixa">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <div class="col-12 col-md-12 text-center" v-if="loadMovcaixa">
          <q-spinner color="blue-7" size="4em" />
        </div>
        <div class="col-12 text-center" v-if="!loadMovcaixa">
          <cabecalho-caixa></cabecalho-caixa>
        </div>
        <div
          class="col-12 col-lg-8 col-md-10 offset-lg-2 offset-md-1"
          v-if="!loadMovcaixa"
        >
          <q-card>
            <q-card-section>
              <div class="q-pa-m">
                <div class="q-col-gutter-md row">
                  <div class="col-5 q-table__title">
                    <q-btn
                      flat
                      dense
                      round
                      icon="arrow_back"
                      clickable
                      @click="voltarPagina()"
                      >
                      <template v-slot:default>
                        <q-tooltip>Voltar aos Caixas</q-tooltip>
                      </template>
                    </q-btn>
                    {{ movcaixa.caixa_nome }}
                    <q-badge
                      :class="{
                        'bg-positive': movcaixa.status === 'A',
                        'bg-negative': movcaixa.status === 'F',
                        'bg-warning': movcaixa.status === 'E'
                      }"
                      >{{
                        movcaixa.status === "A" ? "Aberto" : (movcaixa.status === "F" ? "Fechado": "Excluído" )
                      }}</q-badge
                    >
                  </div>
                  <div class="col-7 text-right">
                    <q-btn
                      style="margin-right: 10px"
                      flat
                      dense
                      icon="account_balance_wallet"
                      class="text-blue-7"
                      clickable
                      @click="importarSangrias"
                      >
                      <template v-slot:default>
                        <q-tooltip>Importar sangrias</q-tooltip>
                      </template>
                    </q-btn>

                    <q-btn
                      style="margin-right: 10px"
                      flat
                      dense
                      icon="update"
                      class="text-blue-7"
                      clickable
                      @click="atualizaProd"
                      >
                      <template v-slot:default>
                        <q-tooltip>Importar produtos</q-tooltip>
                      </template>
                    </q-btn>

                    <q-btn
                      style="margin-right: 10px"
                      flat
                      dense
                      icon="print"
                      class="text-blue-7"
                      clickable
                      @click="imprimir(movcaixa)"
                      :loading="resumoPrinting"
                      >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                      <template v-slot:default>
                        <q-tooltip>Imprimir Relatório</q-tooltip>
                      </template>
                    </q-btn>
                    <q-btn
                      style="margin-right: 10px"
                      flat
                      dense
                      icon="add_a_photo"
                      class="text-blue-7"
                      clickable
                      @click="ativaDir()"
                      >
                      <template v-slot:default>
                        <q-tooltip>Importar Resumo de TFL</q-tooltip>
                      </template>
                    </q-btn>
                    <!-- <q-btn
                      color="green"
                      style="width:40px;"
                      icon="update"
                    /> -->
                    <q-btn
                      style="margin-right: 10px"
                      flat
                      dense
                      icon="notes"
                      :class="{
                        'bg-primary text-white':
                          $route.path === '/caixas/' + movcaixa.id + '/resumo',
                        'text-blue-7': $route.path === '/caixas/' + movcaixa.id
                      }"
                      clickable
                      @click="isResumo(movcaixa.id)"
                      >
                      <template v-slot:default>
                        <q-tooltip>Resumo de Caixa</q-tooltip>
                      </template>
                    </q-btn>

                    <q-btn
                      style="margin-right: 10px"
                      flat
                      dense
                      icon="help_outline"
                      class="text-orange-7"
                      clickable
                      @click="openHelp()"
                      >
                      <template v-slot:default>
                        <q-tooltip>Ajuda</q-tooltip>
                      </template>
                    </q-btn>
                    <q-btn
                      style="margin-right: 10px"
                      flat
                      dense
                      :icon="isFullScreen ? 'fullscreen_exit' : 'fullscreen'"
                      class="text-black"
                      clickable
                      @click="openFullscreen()"
                      >
                      <template v-slot:default>
                        <q-tooltip>Tela Cheia</q-tooltip>
                      </template>
                    </q-btn>
                  </div>
                  <div class="text-body2">
                    Lançamento Nº
                    <strong>{{ movcaixa.id }}</strong>
                    <br />Data movimento
                    <strong>{{ new Date(movcaixa.dtmovimento) | formatDate }}</strong>
                    <br />Responsável
                    <strong>{{ movcaixa.func_resp_nome }},</strong>
                    <br />
                    {{
                      movcaixa.func_aber_id !== movcaixa.func_resp_id
                        ? "Aberto por " + movcaixa.func_aber_nome
                        : ""
                    }}
                    em {{ movcaixa.created | formatDateTime }}
                  </div>
                </div>
              </div>
            </q-card-section>

            <q-card-section>
              <router-view></router-view>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <modal-upload-resumo-tfl ref="uploadResumoTfl" title="Enviar Resumo de TFL" :movmentacao="false"></modal-upload-resumo-tfl>
    <modal-upgrade-plano></modal-upgrade-plano>
  </q-page>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import modalUpgradePlano from '../components/Utils/modal-upgrade-plano.vue';

export default {
  components: { modalUpgradePlano },
  name: "caixa",
  meta: {
    title: "DSB | Caixa"
  },
  data() {
    return {
      escondeTotalizadores: false,
      button: "arrow_drop_up",
      buttonT: "arrow_drop_up",
      escondeTabela: false,
      dataAtual: null,
      isFullScreen: false,
      hideShowLine: false,
      mudaModoResumo: false
    };
  },
  methods: {
    voltarPagina() {
      this.$route.name === "caixa"
        ? this.$router.push("/caixas")
        : this.$router.push("/caixas/" + this.caixaId);

      this.closeFullscreen();
    },
    ativaDir() {
      if ( !this.podeTirarFoto ) {
        this.$store.dispatch("toggleUpgradePlano", true);
        return;
      }
      this.$store.dispatch("getTokenQrcode");
      this.$store.commit("OpenDialogUploadResumoCaixas", true);
      this.$refs.uploadResumoTfl.dizsize();
      this.$refs.uploadResumoTfl.recuperarResumosCaixa();
    },
    mudaTamanhoTela() {
      let tamanhoTela = !this.isResumo(this.movcaixa.id)
        ? "col s12"
        : "col s12 m8 offset-m2";
      return tamanhoTela;
    },
    isResumo(id) {
      if (this.$route.path === "/caixas/" + id + "/resumo") {
        this.$router.push("/caixas/" + id);
      } else {
        this.$router.push("/caixas/" + id + "/resumo");
      }
    },
    openHelp() {
      this.$store.dispatch("loadManual", "caixa");
    },
    openFullscreen() {
      if (this.isFullScreen) {
        this.closeFullscreen();
        return;
      }
      let elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
      document.getElementById("headerPrincipal").style.visibility = "hidden";
      document.getElementById("drawerPrincipal").style.visibility = "hidden";
      this.isFullScreen = true;
    },
    closeFullscreen() {
      if (!this.isFullScreen) return;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      document.getElementById("headerPrincipal").style.visibility = "visible";
      document.getElementById("drawerPrincipal").style.visibility = "visible";
      this.isFullScreen = false;
    },
    imprimir(caixa) {
      this.$store.dispatch("imprimirRelatorioCaixa", caixa);
    },
    atualizaProd() {
      this.$store.dispatch("atualizaCatProdutos", {
        id: this.caixaId
      });
    },
    importarSangrias() {
      axios
        .post(`/cofre-inteligente/importar/${this.caixaId}`)
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              position: "top-right",
              color: "orange",
            });
            return;
          }

          this.$q.notify({
            message: res.data.message,
            position: "top-right",
            color: "green",
          });
          this.$store.dispatch("buscaCaixa", this.caixaId);
        })
        .catch(() => {
          this.$q.notify({
            message: "Houve um erro ao importar as sangrias!",
            position: "top-right",
            color: "red",
          });
        });
    },
  },
  computed: {
    ...mapGetters([
      "podeTirarFoto",
      "caixa",
      "acaoCaixa",
      "movcaixa",
      "loadMovcaixa",
      "resumoPrinting",
      "fechandoCaixa"
    ]),
    caixaId() {
      return this.$route.params.id;
    },
    routeName() {
      return this.$route.name;
    }
  },
  mounted() {
    this.$store.dispatch("produtosEstoque", {
      tipo: "P",
      ativo: 1
    });
    document.addEventListener("keyup", event => {
      this.$store.dispatch("selecionaTecla", {
        ctrl: event.ctrlKey,
        tecla: event.which,
        shift: event.shiftKey,
        plano: this.$store.getters.dataEmpresa.assinatura.plano
      });

      if(event.keyCode == 27){
        this.closeFullscreen();
      }
    });
    this.$store.dispatch("buscaCaixa", this.caixaId);
    this.$store.commit("caixaId", this.caixaId);
    this.$store.commit("routeName", this.routeName);
  },
  beforeRouteLeave(to, from, next) {
    this.isFullScreen = true;
    this.closeFullscreen();

    document.removeEventListener("keyup", event => {
      this.$store.dispatch("selecionaTecla", {
        ctrl: event.ctrlKey,
        tecla: event.which,
        shift: event.shiftKey
      });
    });
    this.$store.commit("acaoCaixa", 0);
    this.$store.dispatch("newMovcaixa");
    next();
  }
};
</script>

<style lang="stylus" scoped></style>
