<template>
  <div id="area-cliente">
    <q-list>
      <q-item class="q-my-sm" clickable v-ripple>
        <q-item-section @click="OpenDialogAlterarLogin = true">
          <q-item-label>Alterar Login</q-item-label>
          <q-item-label caption lines="1">Alteração do login do usuário cadastrado no sistema</q-item-label>
        </q-item-section>
      </q-item>
      <q-item class="q-my-sm" clickable v-ripple @click="areaCliente">
        <q-item-section>
          <q-item-label>Enviar Acesso da Área do Cliente por E-mail</q-item-label>
          <q-item-label caption lines="1">Realizar envio de e-mail contendo acesso para a área do cliente</q-item-label>
        </q-item-section>
        <q-item-section side v-if="areadoCliente">
          <q-btn
            dense
            flat
            no-wrap
            v-ripple
            round
            icon="reply"
            no-caps
            class="q-ml-sm q-px-md text-white bg-positive rotate-180"
            clickable
            @click="abreLink(areadoCliente)"
          ></q-btn>
        </q-item-section>
      </q-item>
      <q-item class="q-my-sm" clickable v-ripple @click="areaClienteSemLogin">
        <q-item-section>
          <q-item-label>Atualizar Cadastro por E-mail</q-item-label>
          <q-item-label caption lines="1">Enviar E-Mail para realizar a atualização cadastral</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>

    <q-dialog v-model="OpenDialogAlterarLogin" persistent>
      <q-card style="width: 400px; max-width: 40vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">Alterar Login</div>
          <q-space />
          <q-btn
            icon="close"
            clickable
            flat
            round
            dense
            v-close-popup
            @click="OpenDialogAlterarLogin = false"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-gutter-md">
              <q-checkbox v-model="canLogin" label="Pode Efetuar o Login" color="secondary" />
              <q-form autocomplete="off" @submit="enviaLogin">
                <q-input
                  v-model="login"
                  label="Login"
                  bottom-slots
                  :error="errors.login ? true : false"
                >
                  <template v-slot="error">
                    <span v-for="error in errors.login" :key="error">{{error}}</span>
                  </template>
                </q-input>
                <q-input
                  type="password"
                  v-model="senha"
                  label="Senha"
                  bottom-slots
                  :error="errors.senha ? true : false"
                >
                  <template v-slot="error">
                    <span v-for="error in errors.senha" :key="error">{{error}}</span>
                  </template>
                </q-input>
                <br />
                <br />
                <br />
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  clickable
                  type="submit"
                  :loading="enviando"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </q-form>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";
import { mapGetters } from "vuex";
export default {
  name: "area-cliente",
  data() {
    return {
      OpenDialogAlterarLogin: false,
      areadoCliente: null,
      loading: false,
      canLogin: true,
      senha: "",
      enviando: false,
      errors: [],
      login: ""
    };
  },
  methods: {
    enviaLogin() {
      this.enviando = true;
      axios
        .post("/cadastros/edit/" + this.$route.params.id, {
          action: "area_cliente_edit_login",
          canlogin: this.canLogin,
          // id: this.$route.params.id,
          login: this.login,
          senha: this.senha,
          nome: this.cadastroView.nome
        })
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: "Novo login salvo!",
              color: "green",
              position: "top-right"
            });
            this.OpenDialogAlterarLogin = false;
          } else {
            this.errors = res.data.errors;
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          this.enviando = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível alterar o Login, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.enviando = false;
        });
    },
    areaCliente() {
      if (this.areadoCliente) return;
      Dialog.create({
        title: "Enviar acesso a Área do Cliente por E-mail",
        message: "Tem certeza que deseja fazer isto?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          this.loading = true;
          axios
            .post("/cadastros/edit/" + this.$route.params.id, {
              action: "area_cliente_com_login"
            })
            .then(res => {
              Dialog.create({
                title: "Área do Cliente",
                message: res.data.message,
                class: `bg-${res.data.success ? 'positive' : 'warning'} text-white`,
                ok: {
                  "text-color": res.data.success ? "green" : "orange",
                  color: "white"
                }
              });

              if (!res.data.success) {
                return;
              }

              this.areadoCliente = res.data.data.area_cliente;
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    areaClienteSemLogin() {
      Dialog.create({
        title: "Atualização cadastral por E-mail",
        message: "Tem certeza que deseja fazer isto?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post("/cadastros/edit/" + this.$route.params.id, {
              action: "area_cliente_sem_login"
            })
            .then(res => {
              if (res.data.success) {
                Dialog.create({
                  title: "Atualizar Cadastro",
                  message: res.data.message,
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
              }
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    abreLink(value) {
      window.open(value, "_blank");
    }
  },
  computed: {
    ...mapGetters(["cadastroView"]),
  },
  mounted() {
    this.login = this.cadastroView.login;

    if (!this.login) {
      this.login = JSON.parse(JSON.stringify(String(this.cadastroView.cpfcnpj).replace(/\D/g, '')));
    }
    this.canLogin = JSON.parse(JSON.stringify(this.cadastroView.canlogin));
  }
};
</script>

<style lang="stylus" scoped></style>
