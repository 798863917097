import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";

const state = {
  loadingLoterias: false,
  produtosLoterias: [],
  loteria: {
    importar_produtos: false,
    ativo: true,
    tipo: "L",
    nome: "",
    vlrcusto: 0.00,
    vlrvenda: 0.00,
    vlrlucro: 0.00,
    tpcontrole: "",
    ordem: "",
    estorno_id: null,
    tiposoma: "",
    categoria_id: null,
    loteria_id: null
  },
  filtrosLoterias: {},
  tipoControleLoterias: [],
  tiposLoterias: {},
  tipoSomaLoterias: {},
  pagingLoterias: {
    finder: "all",
    page: 0,
    current: 0,
    count: 0,
    perPage: 20,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  addRegraLoterias: {
    tipo: "",
    quando: "",
    dias: 0,
    tpvalor: "",
    valor: "",
    nomenclatura: "",
    conta_id: null
  },
  totalLoterias: 0,
  isNewLoteria: true,
  errorsLoterias: [],
  acontarLoterias: [],
  tipoValorLoterias: [],
  saveLoteria: false,
  OpenDialogNovaLoteria: false,
  OpenDialogNovaRegraLoteria: false,
  tiposSomaLoterias: [],
  isNewRegraLoteria: true,
  categoriasLoteriasDisp: [],
  tpcomissaoLoterias: ["%", "$"],
  tpcomissaofuncLoterias: ["%", "$"],
  usarconcursosLoterias: [],
  tpnomenclaturaLoterias: [{ name: "Crédito", value: "C" }, { name: "Débito", value: "D" }],
  canImportLoterias: false,
  tipoRegrasLoterias: [],
  tipoRegrasValorLoterias: [],
  tipoRegrasAcontarLoterias: []
};

const mutations = {
  loadingLoterias: state => {
    state.loadingLoterias = !state.loadingLoterias;
  },
  produtosLoterias: (state, userData) => {
    state.produtosLoterias = userData;
  },
  loteria: (state, userData) => {
    state.loteria = userData;
  },
  filtrosLoterias: (state, userData) => {
    state.filtrosLoterias = userData;
  },
  tipoControleLoterias: (state, userData) => {
    state.tipoControleLoterias = userData;
  },
  tiposLoterias: (state, userData) => {
    state.tiposLoterias = userData;
  },
  tiposSomaLoterias: (state, userData) => {
    state.tiposSomaLoterias = userData;
  },
  pagingLoterias: (state, userData) => {
    state.pagingLoterias = userData;
  },
  addRegraLoterias: (state, userData) => {
    state.addRegraLoterias = userData;
  },
  totalLoterias: (state, userData) => {
    state.totalLoterias = userData;
  },
  isNewLoteria: (state, userData) => {
    state.isNewLoteria = userData;
  },
  errorsLoterias: (state, userData) => {
    state.errorsLoterias = userData;
  },
  acontarLoterias: (state, userData) => {
    state.acontarLoterias = userData;
  },
  tipoValorLoterias: (state, userData) => {
    state.tipoValorLoterias = userData;
  },
  saveLoteria: state => {
    state.saveLoteria = !state.saveLoteria;
  },
  isNewRegraLoteria: (state, userData) => {
    state.isNewRegraLoteria = userData;
  },
  categoriasLoteriasDisp: (state, userData) => {
    state.categoriasLoteriasDisp = userData;
  },
  canImportLoterias: (state, userData) => {
    state.canImportLoterias = userData;
  },
  usarconcursosLoterias: (state, userData) => {
    state.usarconcursosLoterias = userData;
  },
  OpenDialogNovaLoteria: (state, userData) => {
    state.OpenDialogNovaLoteria = userData;
  },
  OpenDialogNovaRegraLoteria: (state, userData) => {
    state.OpenDialogNovaRegraLoteria = userData;
  },
  tipoRegrasAcontarLoterias: (state, userData) => {
    state.tipoRegrasAcontarLoterias = userData;
  },
  tipoRegrasValorLoterias: (state, userData) => {
    state.tipoRegrasValorLoterias = userData;
  },
  tipoRegrasLoterias: (state, userData) => {
    state.tipoRegrasLoterias = userData;
  },
};

const actions = {
  atualizar: ({ state, commit }, produtoEdit) => {
    let loterias = [...state.produtosLoterias];

    let posicao = loterias.findIndex((produto) => {
      return produto.id === produtoEdit.id;
    });

    if (loterias[posicao]) {
      if (produtoEdit && produtoEdit['action'] === 'update') {
        loterias[posicao].loading = false;
        loterias[posicao] = produtoEdit;
      } else {
        loterias.splice(posicao, 1);
      }
    }

    commit("produtosLoterias", []);
    commit("produtosLoterias", loterias);
  },
  listarLoterias: ({ commit, state }) => {
    if (state.loadingLoterias) return;
    if (!state.pagingLoterias.nextPage) return;
    commit("loadingLoterias");
    axios
      .get("/produtos", {
        params: state.filtrosLoterias
      })
      .then(res => {
        if (res.data.success) {
          let produtos = res.data.produtos;
          for (let i = 0; i < produtos.length; i++) {
            produtos[i].loading = false;
          }
          commit("produtosLoterias", state.produtosLoterias.concat(produtos));
          commit("filtrosLoterias", res.data.filtros);
          commit("tipoControleLoterias", res.data.tipoControle);
          commit("tiposLoterias", res.data.tipos);
          commit("tiposSomaLoterias", res.data.tipoSoma);
          commit("pagingLoterias", res.data.paging);
          commit(
            "totalLoterias",
            state.totalLoterias + state.pagingLoterias.current
          );
          commit("categoriasLoteriasDisp", res.data.categorias);
          commit("canImportLoterias", res.data.canImport);
          commit("usarconcursosLoterias", res.data.loterias);
        }
        commit("loadingLoterias");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadingLoterias");
      });
  },
  resetPagingLoterias: ({ commit }) => {
    const paging = {
      finder: "all",
      page: 0,
      current: 0,
      count: 0,
      perPage: 20,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    };
    commit("totalLoterias", 0);
    commit("produtosLoterias", []);
    commit("pagingLoterias", paging);
  },
  filtrosLoterias: ({ commit, dispatch }) => {
    commit("filtrosLoterias", {
      tipo: "L",
      page: 1,
      ativo: 1,
      categoria_id: null
    });
    dispatch("resetPagingLoterias");
    dispatch("listarLoterias");
  },
  modalLoterias: ({ commit, dispatch }, userData) => {
    let produtos = state.produtosLoterias;
    let posicao = produtos.indexOf(userData);
    commit("loteria", userData);
    axios
      .get("/produtos/edit/" + userData.id)
      .then(res => {
        if (res.data.success) {
          commit("loteria", res.data.produto);
          commit("tipoRegrasAcontarLoterias", res.data.tipoRegrasAcontar);
          commit("tipoRegrasValorLoterias", res.data.tipoRegrasValor);
          commit("tipoRegrasLoterias", res.data.tipoRegras);
          commit("isNewLoteria", false);
          dispatch("contas");
          dispatch("listarCategorias");
          commit("OpenDialogNovaLoteria", true);
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
        if (produtos[posicao]) {
          produtos[posicao].loading = false;
          commit("produtosLoterias", produtos);
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        if (produtos[posicao]) {
          produtos[posicao].loading = false;
          commit("produtosLoterias", produtos);
        }
        commit("loteria", userData);
      });
  },
  deleteItemLoterias: ({ dispatch }, userData) => {
    Dialog.create({
      title: userData.nome,
      message: "Tem certeza que deseja excluir este serviço?",
      html: true,
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/produtos/delete/" + userData.id)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              dispatch("atualizar", res.data.produto);
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi possível contactar, ou você não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  addLoteria: ({ commit, dispatch, state }, userData) => {
    commit("saveLoteria");
    let url = state.isNewLoteria
      ? "/produtos/add"
      : "/produtos/edit/" + userData.id;

    const params = userData.params ? userData.params : {};
    const abrirLoteria = userData.abrirLoteria ? userData.abrirLoteria : null;

    userData.params = null;
    userData.abrirLoteria = null;

    axios
      .post(url, userData, { params })
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          if (state.isNewLoteria) {
            commit("produtosLoterias", []);
            commit("loteria", {});
            dispatch("filtrosLoterias");
          } else {
            let produto = res.data.produto;
            produto.action = 'update';
            dispatch("atualizar", produto);
          }

          if (userData.importar_produtos) {
            dispatch("filtrosProdutos");
            dispatch("filtrosServicos");
            dispatch("listarCategorias");
          }
          commit("errorsLoterias", []);
          commit("OpenDialogNovaLoteria", false);

          if (abrirLoteria) {
            dispatch("modalLoterias", res.data.produto);
          }
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
          commit("errorsLoterias", res.data.errors);
        }
        commit("saveLoteria");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("saveLoteria");
      });
  },
  deleteRegraLoteria: ({ commit, dispatch, state }, userData, i) => {
    Dialog.create({
      title: userData.regra.nomenclatura,
      message: "Tem certeza que deseja excluir esta regra?",
      html: true,
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post(
            "/produtos/delete-regra/" + userData.regra.produto_id + "/" + userData.regra.id
          )
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: 'Remover Regra',
                message: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              //userData.loteria.prodregras = userData.loteria.prodregras.splice(userData.pos, 1);
              dispatch("modalLoterias", userData.loteria);

            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi possível contactar, ou você não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  modalRegraLoteria: ({ commit }, userData) => {
    if (userData) {
      commit("addRegraLoterias", userData);
      commit("isNewRegraLoteria", false);
    } else {
      commit("isNewRegraLoteria", true);
      commit("addRegraLoterias", {
        id: null,
        tipo: "",
        quando: "",
        dias: 0,
        tpvalor: "",
        valor: "",
        nomenclatura: "",
        conta_id: null
      });
    }
    commit("OpenDialogNovaRegraLoteria", true);
  },
  addRegraLoteria: ({ commit }, userData) => {
    return new Promise((resolve, reject) => {
      commit("saveRegra");
      let url = state.isNewRegraLoteria
        ? "/produtos/add-regra/" + userData.produto_id
        : "/produtos/edit-regra/" + userData.produto_id + "/" + userData.id;
      axios
        .post(url, userData)
        .then(res => {
          if (res.data.success) {
            let produto = res.data.produto;
            for (let i = 0; i < produto["prodregras"].length; i++) {
              produto.prodregras[i].loadingDelete = false;
            }
            commit("loteria", produto);
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });

            commit("addRegraLoterias", {
              tipo: "",
              quando: "",
              dias: 0,
              tpvalor: "",
              valor: "",
              nomenclatura: "",
              conta_id: null
            });
            commit("OpenDialogNovaRegraLoteria", false);
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });

            commit("errorsLoterias", res.data.errors);
          }
          commit("saveRegra");
          resolve(res.data);
        })
        .catch(() => {
          commit("saveRegra");
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          reject();
        });
    });
  },
  errorsLoterias: ({ commit }, userData) => {
    commit("errorsLoterias", userData);
  },
  setaNovaLoteria: ({ commit }, userData) => {
    commit("isNewLoteria", userData);
  },
};

const getters = {
  loadingLoterias: state => {
    return state.loadingLoterias;
  },
  produtosLoterias: state => {
    return state.produtosLoterias;
  },
  loteria: state => {
    return state.loteria;
  },
  filtrosLoterias: state => {
    return state.filtrosLoterias;
  },
  tipoControleLoterias: state => {
    return state.tipoControleLoterias;
  },
  tiposLoterias: state => {
    return state.tiposLoterias;
  },
  tipoSomaLoterias: state => {
    return state.tipoSomaLoterias;
  },
  pagingLoterias: state => {
    return state.pagingLoterias;
  },
  addRegraLoterias: state => {
    return state.addRegraLoterias;
  },
  totalLoterias: state => {
    return state.totalLoterias;
  },
  isNewLoteria: state => {
    return state.isNewLoteria;
  },
  errorsLoterias: state => {
    return state.errorsLoterias;
  },
  acontarLoterias: state => {
    return state.acontarLoterias;
  },
  tipoValorLoterias: state => {
    return state.tipoValorLoterias;
  },
  saveLoteria: state => {
    return state.saveLoteria;
  },
  tiposSomaLoterias: state => {
    return state.tiposSomaLoterias;
  },
  isNewRegraLoteria: state => {
    return state.isNewRegraLoteria;
  },
  categoriasLoteriasDisp: state => {
    return state.categoriasLoteriasDisp;
  },
  tpcomissaoLoterias: state => {
    return state.tpcomissaoLoterias;
  },
  tpcomissaofuncLoterias: state => {
    return state.tpcomissaofuncLoterias;
  },
  usarconcursosLoterias: state => {
    return state.usarconcursosLoterias;
  },
  tpnomenclaturaLoterias: state => {
    return state.tpnomenclaturaLoterias;
  },
  canImportLoterias: state => {
    return state.canImportLoterias;
  },
  OpenDialogNovaLoteria: state => {
    return state.OpenDialogNovaLoteria;
  },
  OpenDialogNovaRegraLoteria: state => {
    return state.OpenDialogNovaRegraLoteria;
  },
  tipoRegrasLoterias: state => {
    return state.tipoRegrasLoterias;
  },
  tipoRegrasValorLoterias: state => {
    return state.tipoRegrasValorLoterias;
  },
  tipoRegrasAcontarLoterias: state => {
    return state.tipoRegrasAcontarLoterias;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
