import axios from "@/axios-auth";

const state = {
  cadastroView: {
    cadastro: {},
    loading: false,
    timelinesCount: 0
  },
  zapPhone: [],
  phone: []
};

const mutations = {
  cadastroView: (state, userData) => {
    state.cadastroView.cadastro = userData.cadastro;
    state.cadastroView.loading = userData.loading;
    state.cadastroView.timelinesCount = userData.timelinesCount;
  },
  zapPhone: (state, userData) => {
    state.zapPhone = userData.zapPhone;
  },
  phone: (state, userData) => {
    state.phone = userData.phone;
  }
};

const actions = {
  viewCadastro: ({ commit, dispatch }, userData) => {
    commit("cadastroView", {
      cadastro: {},
      timelinesCount: 0,
      loading: true
    });
    commit("zapPhone", []);
    commit("phone", []);
    axios.get("/cadastros/view/" + userData).then(res => {
      if (res.data.success) {
        commit("cadastroView", {
          cadastro: res.data.data.cadastro,
          timelinesCount: res.data.timelinesCount,
          loading: false
        });
        dispatch("mandaZap", res.data.data.cadastro.telefone);
        dispatch("getTags", userData);
      }
    }).catch(() => {
      commit("cadastroView", {
        cadastro: {},
        timelinesCount: 0,
        loading: false
      });
    });
  },
  mandaZap: ({ commit }, userData) => {
    if (userData) {
      let arrTelefone = userData.split(",");
      commit("phone", {
        phone: JSON.parse(JSON.stringify(arrTelefone))
      });
      let objTelefone = [];
      for (let i = 0; i < arrTelefone.length; i++) {
        arrTelefone[i] = arrTelefone[i].replace(/\D/g, "");
        if (
          (arrTelefone[i].length === 10) ||
          (arrTelefone[i].length === 12) ||
          (arrTelefone[i].length === 11 && arrTelefone[i].substring(2, 3) === '9') ||
          (arrTelefone[i].length === 13 && arrTelefone[i].substring(4, 5) === '9')
        ) {
          objTelefone.push({
            zap: true,
            telefone: arrTelefone[i]
          });
        } else {
          objTelefone.push({
            zap: false,
            telefone: arrTelefone[i]
          });
        }
      }
      commit("zapPhone", {
        zapPhone: objTelefone
      });
    }
  }
};

const getters = {
  cadastroView: state => {
    return state.cadastroView.cadastro;
  },
  timelinesCount: state => {
    return state.cadastroView.timelinesCount;
  },
  loadingCadastro: state => {
    return state.cadastroView.loading;
  },
  zapPhone: state => {
    return state.zapPhone;
  },
  phone: state => {
    return state.phone;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
