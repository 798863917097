<template>
  <div id="formasRecebimentoParametros">
    <q-table
      title
      :data="gateways"
      :columns="columns"
      row-key="tipo"
      :separator="separator"
      :loading="loadingFormaRecebimento"
      no-data-label="Nenhum resultado encontrado."
      :pagination.sync="pagination"
    >
      <template v-slot:bottom>
        <div></div>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="ativo" align="center">
            <q-icon
              :name="props.row.ativo ? 'check' : 'close'"
              size="24px"
              :class="props.row.ativo ? 'text-positive' : 'text-negative'"
            ></q-icon>
          </q-td>
          <q-td v-if="tipo == 'receita'" key="padrao" align="center">
            <q-icon
              :name="props.row.padrao ? 'check' : ''"
              size="24px"
              :class="props.row.padrao ? 'text-positive' : 'text-negative'"
            />
          </q-td>
          <q-td key="tipo">{{homologados[props.row.tipo]}}</q-td>
          <q-td key="titulo">{{props.row.titulo}}</q-td>
          <q-td class="tdfora">
            <div class="divfora">
              <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                <q-btn
                  @click="modal(props.row)"
                  size="12px"
                  class="bg-primary text-white"
                  icon="edit"
                ></q-btn>
              </div>
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <modal-editar-forma-cobranca
      v-if="editModalCobranca"
      :editModalCobranca="editModalCobranca"
      :gateway="gatewayCobranca"
      @listarGateways="listar"
      @closeModal="editModalCobranca = false"
    />
  </div>
</template>

<script>
import axios from '@/axios-auth';

export default {
  name: "formas-recebimento-parametros",
  props: ['tipo'],
  data() {
    return {
      separator: "horizontal",
       pagination: {
        rowsPerPage: 0
      },
      editModalCobranca: false,
      gatewayCobranca: null,
    };
  },
  methods: {
    listar() {
      this.$store.dispatch("formasRecebimento", {tipo: this.tipo});
    },
    showModalEditarCobranca(gateway_id) {
      axios
        .get("/gateways/edit/" + gateway_id)
        .then(res => {
          if (res.data.success) {
            let gateway = res.data.gateway;

            if (res.data.gateway.gateways_opts.length === 0) {
              this.$q.notify({
                message: "Nada a ser editado.",
                color: "orange",
                position: "top-right"
              });
              return;
            }

            // Converte as strings com números para números
            const optionsParams = Object.values(gateway.optionsParams);
            for (let opt of optionsParams) {
              if (opt.type === "number") {
                opt.valor = parseFloat(opt.valor);
              }
            }

            this.gatewayCobranca = gateway;
            this.editModalCobranca = true;
          }
        });
    },
    modal(gateway) {
      if (this.tipo == "despesa") {
        this.$store.dispatch("editModalFormas", gateway);
      } else {
        this.showModalEditarCobranca(gateway.id);
      }
    }
  },
  computed: {
    columns() {
      let columns = [
        {
          name: "ativo",
          required: true,
          label: "Ativo",
          align: "center",
          field: row => row.ativo,
          sortable: true
        },
        {
          name: "tipo",
          align: "center",
          label: "Tipo",
          field: row => this.homologados[row.tipo],
          sortable: true
        },
        {
          name: "titulo",
          label: "Descrição",
          field: row => row.titulo,
          sortable: true,
          align: "center"
        },
        { name: 'tdfora' },
      ];

      if (this.tipo == "receita") {
        columns.splice(1, 0, {
          name: "padrao",
          required: true,
          label: "Padrão",
          align: "center",
          field: row => row.padrao,
          sortable: true
        });
      }

      return columns;
    },
    gateways() {
      return this.$store.getters.formasRecebimento.gateways;
    },
    homologados() {
      return this.$store.getters.formasRecebimento.homologados;
    },
    loadingFormaRecebimento() {
      return this.$store.getters.loadingFormaRecebimento;
    }
  },
  mounted() {
    this.listar();
  }
};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
