import axios from "@/axios-auth";
import { Notify } from "quasar";

const state = {
  formasRecebimento: {
    gateways: [],
    homologados: []
  },
  gateway: {},
  openDialogNovaFormaRecebimento: false,
  loadingFormaRecebimento: false,
  etapas: {
    etapa2: false,
    etapa1: true
  },
  loadItems: false,
  isNewFormaPagar: true
};

const mutations = {
  formasRecebimento: (state, userData) => {
    state.formasRecebimento.gateways = userData.gateways;
    state.formasRecebimento.homologados = userData.homologados;
  },
  openDialogNovaFormaRecebimento: (state, userData) => {
    state.openDialogNovaFormaRecebimento = userData;
  },
  loadingFormaRecebimento: (state, userData) => {
    state.loadingFormaRecebimento = userData;
  },
  gateway: (state, userData) => {
    state.gateway = userData;
  },
  etapas: (state, userData) => {
    state.etapas = userData;
  },
  loadItems: (state, userData) => {
    state.loadItems = userData;
  },
  isNewFormaPagar: (state, userData) => {
    state.isNewFormaPagar = userData;
  }
};

const actions = {
  formasRecebimento: ({ commit, state }, userData) => {
    if (state.loadingFormaRecebimento) return;

    let url = "/gateways";
    if (userData && userData.tipo == 'despesa'){
      url = "/gateways?despesa=1";
    }

    commit("loadingFormaRecebimento", true);
    axios
      .get(url)
      .then(res => {
        if (res.data.success) {
          commit("formasRecebimento", {
            gateways: res.data.gateways,
            homologados: res.data.homologados
          });
          commit("loadingFormaRecebimento", false);
        } else {
          commit("loadingFormaRecebimento", false);
        }
      })
      .catch(() => {
        commit("loadingFormaRecebimento", false);
      });
  },
  editModalFormas: ({ commit }, gateway) => {
    axios.get("/gateways/edit/" + gateway.id).then(res => {
      if (res.data.success) {
        commit("gateway", res.data.gateway);
        if (state.gateway.gateways_opts.length === 0) {
          Notify.create({
            message: "Nada a ser editado.",
            color: "orange",
            position: "top-right"
          });
          return;
        }
        commit("openDialogNovaFormaRecebimento", true);
        commit("loadItems", true);
        commit("isNewFormaPagar", false);
        commit("etapas", {
          etapa1: false,
          etapa2: true
        });
      }
    });
  }
};

const getters = {
  formasRecebimento(state) {
    return state.formasRecebimento;
  },
  openDialogNovaFormaRecebimento: state => {
    return state.openDialogNovaFormaRecebimento;
  },
  loadingFormaRecebimento: state => {
    return state.loadingFormaRecebimento;
  },
  gateway: state => {
    return state.gateway;
  },
  etapas: state => {
    return state.etapas;
  },
  loadItems: state => {
    return state.loadItems;
  },
  isNewFormaPagar: state => {
    return state.isNewFormaPagar;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
