<template>
  <q-dialog v-model="this.OpenDrawerTimelineObs">
    <q-card style="width: 800px">
      <q-card-section class="row justify-between">
        <div class="text-h6 col">Adicionar uma Observação</div>
        <q-btn
          class="col-1"
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="close()"
        />
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-editor 
          v-model="editor.corpo" 
          min-height="5rem"
          dense 
            :toolbar="[
              [
                {
                  label: $q.lang.editor.align,
                  icon: $q.iconSet.editor.align,
                  fixedLabel: true,
                  list: 'only-icons',
                  options: ['left', 'center', 'right', 'justify']
                }, 
              ],
              ['bold', 'italic', 'strike', 'underline'],
              [
                  {
                    label: $q.lang.editor.formatting,
                    icon: $q.iconSet.editor.formatting,
                    list: 'no-icons',
                    options: [
                      'h4',
                      'h5',
                      'h6',
                      'p',
                    ]
                  },
              ],
              ['undo', 'redo'],
            ]"
          />
        <transition name="slide-fade" mode="out-in">
          <p class="text-negative" v-if="erroEditor">Não é possivel salvar uma observação vazia!</p>
        </transition>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn 
          class="bg-positive" 
          color="primary" 
          label="Salvar" 
          v-close-popup 
          @click="salvar(); updateTimeline();"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from 'vuex';
export default {
  name: "modal-timeline",
  props: {
    'cadastroId':String, 
  },
  data() {
    return {
      editor: { 
        corpo: "",
      }, 
      erroEditor: false
    };
  },
  methods: {
     async salvar() {
      if(this.editor.corpo == ""){
        this.erroEditor = true;
        return;
      }

      let timeline = this.editor;

      if(!this.isTimelineGlobal){
        if(this.isTimelineCadastro.cadastro) {
          timeline.cadastro_id = this.isTimelineCadastro.routeId;
          timeline.movconta_id = null;
        } else {
          timeline.cadastro_id = this.movimentacao.cadastro_id;
          timeline.movconta_id = this.movimentacao.id;
        }

      } else {
        timeline.cadastro_id = "";
      }

      this.erroEditor = false;
      this.$store.dispatch("openObs", false);
      await axios
        .post("/timelines/add", timeline)
        .then(res=> {
          if(res.data.success){
           this.$q.notify ({
             message: res.data.message,
             color: 'green',
             position: 'top-right',
           });
           this.editor.corpo = "";
           this.changeFilterTimeline();
          }else{
            this.$q.notify ({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            this.editor.corpo = "";        
          }
        })
        .catch(() => {
          this.$q.notify ({
            message: "Não foi possível contactar, ou você não possui permissão!",
            color: "orange",
            position: "top-right",
          });
          this.editor.corpo ="";
        });

        if(!this.isTimelineCadastro.cadastro) {
          this.$store.dispatch("pagingTimeReceitas", this.movimentacao);
          this.$store.dispatch("pagingTimeDespesas", this.movimentacao);
        }

       
    },
    close() {
      this.$store.dispatch("openObs", false);
      this.erroEditor = false;
      this.editor.corpo = "";
    },
    changeFilterTimeline(value) {
      this.$store.dispatch("changeFilterTimeline", value);
      if (!value) {
        this.filterTimeline = "Todos";
      } else {
        this.filterTimeline = this.nomeFiltroTimeline(value.value);
      }
    },
    updateTimeline() {
      this.$emit('updateTimeline');
    } 
  },
  computed: {
    ...mapGetters([
      "OpenDrawerTimelineObs",
      "isTimelineGlobal",
      "movimentacao",
      "isTimelineCadastro",
    ])
  }
};
</script>

<style>
  .slide-fade-enter-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active em versões anteriores a 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>