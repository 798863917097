<template>
  <div id="modalEditarMovconta">
    <q-dialog v-model="OpenDialogEditarMovconta" persistent>
      <q-card :style="`width: 100%; max-width: ${opcoesAvancadas ? '1200px' : '600px'};`">
        <q-card-section class="row items-center">
          <div class="text-h6">
            <div>{{ title() }} <span class="text-h6" v-if="movconta.cadastro">&nbsp;- {{movconta.cadastro.nome.substr(0, 30)}}</span></div>
          </div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />

        <q-card-section class="text-center" v-if="!movconta">
          <q-spinner
            color="blue-7"
            size="4em"
            style="position:relative;top: 50%;left: 40%;"
          />
        </q-card-section>

        <q-card-section class="row q-pt-none q-col-gutter-lg" v-else>
          <div :class="`col-12 col-md-${opcoesAvancadas ? 6 : 12} col-lg-${opcoesAvancadas ? 5 : 12} row q-col-gutter-x-md`">

            <div class="col-12 q-mb-md" v-show="!movconta.liquidado">
              <q-input
                dense
                v-model="movconta.descricao"
                label="Descrição"
                bottom-slots
                :error="Boolean(errors.descricao)"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.descricao" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-6 q-mb-md" v-show="!movconta.liquidado">
              <q-input
                dense
                bottom-slots
                :error="Boolean(errors.vencimento)"
                v-model="movconta.vencimento"
                label="Vencimento"
                @click="$refs.qDateVencimentoProxy.show()"
                >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateVencimentoProxy"
                      transition-show="scale"
                      transition-hide="scale"
                      >
                      <q-date
                        v-model="movconta.vencimento"
                        @input="value => changeVencimento(value)"
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        minimal
                        today-btn
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.vencimento"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

            <div class="col-6 q-mb-md">
              <q-input
                dense
                v-model="movconta.referencia"
                label="Referência"
                bottom-slots
                :error="Boolean(errors.referencia)"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.referencia" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-4 q-mb-md" v-show="!movconta.liquidado">
              <q-field
                dense
                v-model="movconta.valor_acrecimo"
                label="Valor Acréscimo"
                bottom-slots
                :error="Boolean(errors.valor_acrecimo)"
                >
                <template
                  v-slot:control="{ id, floatingLabel, value, emitValue }"
                >
                  <money
                    :id="id"
                    class="q-field__native text-right"
                    :value="value"
                    @input="emitValue"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.valor_acrecimo" :key="error + i">
                    {{ error }}
                  </span>
                </template>
              </q-field>
            </div>

            <div class="col-4 q-mb-md" v-show="!movconta.liquidado">
              <q-field
                dense
                v-model="movconta.valor_desconto"
                label="Valor Desconto"
                bottom-slots
                :error="Boolean(errors.valor_desconto)"
                >
                <template
                  v-slot:control="{ id, floatingLabel, value, emitValue }"
                >
                  <money
                    :id="id"
                    class="q-field__native text-right"
                    :value="value"
                    @input="emitValue"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.valor_desconto" :key="error + i">
                    {{ error }}
                  </span>
                </template>
              </q-field>
            </div>

            <div class="col-4 q-mb-md">
              <q-field
                dense
                v-model="movconta.valor"
                label="Valor"
                bottom-slots
                :error="Boolean(errors.valor)"
                :disable="movconta.liquidado"
                >
                <template
                  v-slot:control="{ id, floatingLabel, value, emitValue }"
                >
                  <money
                    :id="id"
                    class="q-field__native text-right"
                    :value="value"
                    @input="emitValue"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                    :disabled="movconta.liquidado"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.valor" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-6 q-mb-md" v-show="movconta.liquidado">
              <q-input
                dense
                clearable
                bottom-slots
                label="Data de Liquidação"
                :error="Boolean(errors.liquidacao)"
                v-model="movconta.liquidacao"
                @click="$refs.qLiquidacaoRef.show()"
                >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qLiquidacaoRef"
                      transition-show="scale"
                      transition-hide="scale"
                      >
                      <q-date
                        minimal
                        today-btn
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        v-model="movconta.liquidacao"
                        @input="() => $refs.qLiquidacaoRef.hide()"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.liquidacao"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

            <div class="col-6 q-mb-md" v-show="movconta.liquidado">
              <q-input
                dense
                clearable
                bottom-slots
                label="Data de Movimento"
                :error="Boolean(errors.dtmovimento)"
                v-model="movconta.dtmovimento"
                @click="$refs.qDtmovimentoRef.show()"
                >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDtmovimentoRef"
                      transition-show="scale"
                      transition-hide="scale"
                      >
                      <q-date
                        minimal
                        today-btn
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        v-model="movconta.dtmovimento"
                        @input="() => $refs.qDtmovimentoRef.hide()"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.dtmovimento"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

            <div class="col-12 q-mb-md">
              <q-select
                dense
                use-input
                clearable
                emit-value
                map-options
                option-value="id"
                option-label="titulo"
                label="Plano de Contas"
                :options="planoContasFilterResult"
                v-model="movconta.planodeconta_id"
                @filter="planoContasFilter"
                @input="val => triggerPlanodeconta = val"
                >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey"
                      >Sem Resultados</q-item-section
                    >
                  </q-item>
                </template>
              </q-select>
            </div>

            <div class="row q-pt-md" v-if='tipo == "despesa" && exibirGatewaysDespesa'>
              <div class="col">
                <q-checkbox
                  dense
                  v-model="cobrar"
                  clickable
                  label="Incluir forma de pagamento"
                  color="green"
                  id="checkDespesa"
                />
              </div>
            </div>

            <div class="row col-12 q-mb-md">
                <div class="col" v-if="cobrar">
                  <q-select
                    dense
                    clearable
                    v-model="movconta.gateway_id"
                    option-label="titulo"
                    option-value="id"
                    :options="findGatewaysDespesa.gateways"
                    emit-value
                    map-options
                    bottom-slots
                    :error="errors.gateway_id ? true : false"
                    label="Forma de Pagamento"
                    ref="formaPagamento"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey"
                          >Sem Resultados</q-item-section
                        >
                      </q-item>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.gateway_id"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-select>
                </div>

                <div class="col" v-else>
                  <q-select
                    dense
                    v-model="movconta.conta_id"
                    option-label="titulo"
                    option-value="id"
                    :options="contas"
                    label="Conta Bancária: "
                    clearable
                    emit-value
                    map-options
                    id="contaDespesa"
                    >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey"
                          >Sem Resultados</q-item-section
                        >
                      </q-item>
                    </template>
                  </q-select>
                </div>
            </div>

            <div class="col-12" v-if="hasOpcoesAvancadas">
              <q-checkbox
                dense
                color="green"
                label="Opções Avançadas"
                v-model="opcoesAvancadas"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-7" v-if="hasOpcoesAvancadas" v-show="opcoesAvancadas">

            <div class="row" v-if="exibirCentrosdecusto">
              <div class="col-12 q-mb-md">
                <q-select
                  dense
                  v-model="centroCustoRateio"
                  :option-label="
                    value => (value === null ? 'Item Vazio' : value.nome)
                  "
                  :option-value="value => (value === null ? null : value)"
                  :options="optionsCentroCustos"
                  label="Centro de Custo"
                  :hide-bottom-space="centrocustoErro === null"
                  :error="centrocustoErro !== null"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span>{{ centrocustoErro }}</span>
                  </template>
                </q-select>
              </div>
              <div class="col-2 flex items-end">
                <q-btn
                  color="white"
                  text-color="black"
                  label="%"
                  disable
                />
              </div>
              <div class="col-8">
                <q-field
                  dense
                  v-model="porcentagemRateio"
                  label="Porcentagem"
                  :hide-bottom-space="centrocustoErro === null"
                  :error="porcentagemErro !== null"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="percentFormat"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span>{{ porcentagemErro }}</span
                    >
                  </template>
                </q-field>
              </div>
              <div class="col-2 flex items-end justify-end">
                <q-btn
                  icon="add"
                  color="primary"
                  flat
                  round
                  @click="addRateio"
                />
              </div>
              <div class="col-12 q-mt-md">
                <p
                  v-if="porcentagemRateioTotal > 100 && errors.movconta && !Boolean(errors.movconta.movcontas_rateios)"
                  class="text-negative caption"
                >
                  A porcentagem total passa de 100%
                </p>
                <div v-if="errors.movconta && Boolean(errors.movconta.movcontas_rateios)">
                  <p
                    class="text-negative caption"
                    v-for="(error, index) in errors.movconta.movcontas_rateios"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
                <q-markup-table
                  flat
                  dense
                >
                  <tbody>
                    <tr
                      v-for="(rateio, index) in movconta.movcontas_rateios"
                      :key="index"
                    >
                      <td>{{ rateio.centrocusto.nome }}</td>
                      <td class="text-right">{{ rateio.porcentagem }} %</td>
                      <td style="width: 30px;">
                        <q-btn
                          flat
                          dense
                          color="red"
                          icon="delete"
                          @click="movconta.movcontas_rateios.splice(index, 1)"
                        />
                      </td>
                    </tr>
                    <tr v-if="movconta.movcontas_rateios.length > 0">
                      <td colspan="2"><strong>Total</strong></td>
                      <td class="text-right">{{ porcentagemRateioTotal }} %</td>
                    </tr>
                    <tr v-if="movconta.movcontas_rateios.length === 0">
                      <td colspan="3" class="text-center">Nenhum rateio</td>
                    </tr>
                  </tbody>
                </q-markup-table>
              </div>
            </div>

            <div class="row">
              <div class="col-12 q-mb-md" v-if="movconta.fatura_id">
                <q-checkbox
                  dense
                  label="Personalizar multa e juros"
                  v-model="personalizarMultaJuros"
                  color="green"
                />
              </div>

              <div class="col-12 q-mb-md" v-show="movconta.fatura_id && personalizarMultaJuros">
                <div class="row q-col-gutter-lg">

                  <div class="col-4">
                    <q-field
                      dense
                      v-model="fatura.multa"
                      label="Multa"
                      bottom-slots
                      :error="Boolean(errors.fatura_multa)"
                    >
                      <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <money
                          :id="id"
                          class="q-field__native text-right"
                          :value="value"
                          @input="emitValue"
                          v-bind="percentFormat"
                          v-show="floatingLabel"
                          ref="multa"
                        >
                        </money>
                      </template>
                      <template v-slot:error>
                        <span
                          v-for="(error, i) in errors.fatura_multa"
                          :key="error + i"
                          >{{ error }}</span
                        >
                      </template>
                    </q-field>
                  </div>

                  <div class="col-4">
                    <q-field
                      dense
                      v-model="fatura.juros"
                      label="Juros"
                      bottom-slots
                      :error="Boolean(errors.fatura_juros)"
                    >
                      <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <money
                          :id="id"
                          class="q-field__native text-right"
                          :value="value"
                          @input="emitValue"
                          v-bind="percentFormat"
                          v-show="floatingLabel"
                          ref="juros"
                        >
                        </money>
                      </template>
                      <template v-slot:error>
                        <span
                          v-for="(error, i) in errors.fatura_juros"
                          :key="error + i"
                          >{{ error }}</span
                        >
                      </template>
                    </q-field>
                  </div>

                  <div class="col-4">
                    <q-input
                      dense
                      v-model="fatura.protesto"
                      label="Protesto"
                      mask="##"
                      ref="protesto"
                      bottom-slots
                      :error="Boolean(errors.fatura_protesto)"
                    >
                      <template v-slot:error>
                        <span
                          v-for="(error, i) in errors.fatura_protesto"
                          :key="error + i"
                          >{{ error }}</span
                        >
                      </template>
                    </q-input>
                  </div>

                </div>
              </div>
            </div>

            <div v-show="exibirMovcontasItems">
              <div class="col-12">
                <q-checkbox
                  dense
                  label="Utilizar planos de contas dos itens"
                  color="green"
                  v-model="movconta.planodeconta_item"
                />
              </div>

              <div class="col-12">
                <q-table
                  dense
                  flat
                  title
                  row-key="id"
                  separator="horizontal"
                  no-data-label="Nenhum resultado encontrado."
                  :data="movcontasItems"
                  :columns="columnsItems"
                  :loading="loadingMovcontasItems"
                  :pagination.sync="pagination"
                  >
                  <template v-slot:bottom>
                    <p v-if="!loadingMovcontasItems && movcontasItems.length > 0" class="full-width text-right">
                      <q-btn
                        flat
                        size="sm"
                        color="grey-7"
                        class="q-mr-sm"
                        icon="fa fa-money"
                        @click="alterarValoLancamento()"
                      >
                        <q-tooltip>Utilizar no valor no lançamento</q-tooltip>
                      </q-btn>
                      Total
                      <strong>R$ {{ movcontasItemsTotal | currency }}</strong>
                    </p>
                  </template>
                  <template v-slot:top="props">
                    <div class="col-12 q-table__title" style="font-size: 1.2em;">
                      <q-btn
                        flat
                        round
                        icon="add"
                        size="12px"
                        color="primary"
                        @click="addMovcontasItem()"
                      />
                      Itens
                    </div>
                  </template>
                  <template v-slot:body="props">
                    <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">

                      <q-td key="id" class="text-right">{{ props.row.id }}</q-td>
                      <q-td key="descricao" class="text-left" style="white-space: break-spaces;">
                        {{ props.row.descricao }}
                      </q-td>
                      <q-td key="planodeconta" class="text-left" style="white-space: break-spaces;">
                        <span v-if="props.row.planodeconta">
                          {{ `${props.row.planodeconta.numero} ${props.row.planodeconta.titulo}`}}
                        </span>
                      </q-td>
                      <q-td key="valor" class="text-right">R$ {{ props.row.valor | currency }}</q-td>
                      <q-td v-if="$q.platform.is.desktop" key="tdfora" class="tdfora">
                        <div class="divfora">
                          <div class="divdentro q-gutter-sm">
                            <q-btn
                              size="xs"
                              icon="edit"
                              color="warning"
                              @click="editMovcontasItem(props.row)"
                            />

                            <q-btn
                              size="xs"
                              color="negative"
                              icon="delete_forever"
                              :loading="loadingExcluirMovcontasItem"
                              @click="deleteMovcontasItem(props.row)"
                            />
                          </div>
                        </div>
                      </q-td>
                    </q-tr>
                    <q-tr v-show="props.expand" :props="props">
                      <q-td colspan="100%">
                        <div class="q-py-sm q-gutter-sm">
                          <q-btn
                            size="xs"
                            icon="edit"
                            color="warning"
                            @click="editMovcontasItem(props.row)"
                          />
                          <q-btn
                            size="xs"
                            color="negative"
                            icon="delete_forever"
                            :loading="loadingExcluirMovcontasItem"
                            @click="deleteMovcontasItem(props.row)"
                          />
                        </div>
                      </q-td>
                    </q-tr>
                  </template>
                </q-table>
              </div>

              <div class="col-12 q-mt-md" v-if="tipoConta === 'despesa'">
                <div
                  class="q-uploader"
                  style="width: 100%; box-shadow: none; overflow: hidden;"
                  @dragleave="onDragLeave"
                  @mouseover="onHover"
                  @mouseleave="offHover"
                  @dragover="onDragOver"
                  @drop.stop.prevent="addFile"
                >
                  <div class="q-uploader__header">
                    <div class="header-upload">
                      <a v-if="arrayFile && arrayFile.length > 0">
                        <div tabindex="-1" class="q-focus-helper"></div>
                        <q-btn
                          dense
                          flat
                          icon="clear_all"
                          @click="clearFiles"
                        />
                      </a>
                      <div :class="!this.$q.platform.is.mobile ?  'layout' :'layout-responsive'">
                        <div  v-if="!this.$q.platform.is.mobile">
                          <div class="q-uploader__title">Anexar imagens e documentos</div>
                        </div>

                        <div class="icones-layout">
                            <label for="file"  v-if="this.$q.platform.is.mobile">
                              <i
                                class="material-icons"
                                style="font-size: 20px; cursor: pointer; float: right"
                              >
                                add_a_photo
                              </i>
                              <input
                                accept="image/*"
                                capture="camera"
                                type="file"
                                id="file"
                                style="width: 0"
                                multiple
                                class="file"
                                @change="addFile"
                              />
                            </label>

                            <label v-else>
                              <i
                                @click="openModal"
                                class="material-icons"
                                style="font-size: 20px; cursor: pointer;"
                              >
                                add_a_photo
                              </i>
                            </label>

                            <label>
                              <i v-if="!this.$q.platform.is.mobile"
                                class="material-icons"
                                style="font-size: 20px; cursor: pointer">
                                cloud_upload
                              </i>
                              <input
                                type="file"
                                id="file"
                                style="width: 0"
                                multiple
                                class="file"
                                @change="addFile"
                              />
                            </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="q-uploader__list scroll" style="border: 1px solid #ccc;">
                    <div v-for="(file, i) in arrayFile" :key="file.name" style="margin-bottom: 8px">
                      <div class="q-uploader__file relative-position">
                        <div class="q-uploader__file-header row flex-center no-wrap">
                          <i
                            v-if="file.error"
                            aria-hidden="true"
                            class="material-icons q-icon text-negative q-uploader__file-status"
                          >
                            warning
                          </i>
                          <div class="q-uploader__file-header-content col">
                            <div class="q-uploader__title">{{file.fileName}}</div>
                              <div class="q-uploader__subtitle row items-center no-wrap">
                                {{file.fileSize}}
                              </div>
                            </div>
                            <q-btn
                              round
                              dense
                              flat
                              icon="clear"
                              @click="apagaItem(i)"
                              :loading="file.uploading"
                            >
                              <template v-slot:loading>
                                <q-spinner color="grey-10" size="1em" />
                              </template>
                            </q-btn>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>

                <div v-if="arrayFile.length > 0" class="q-mt-md">
                  <div class="text-h6">Anexos</div>

                  <q-markup-table
                    class="q-mt-sm"
                    style="border: 1px solid #ccc"
                    flat
                    dense
                  >
                    <tbody>
                      <tr
                        v-for="file in arrayFile"
                        :key="file.fileName"
                      >
                        <td>{{ file.fileName }}</td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveEditar()"
            :loading="loadEditarMovconta"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog persistent v-model="openDialogSalvarMovcontasItem">
      <q-card style="width: 100%; max-width: 400px;">

        <q-card-section class="row items-center">
          <div class="text-h6">{{ movcontasItem.id ? 'Editar' : 'Novo' }} Item</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModalSalvarMovcontasItem()"
          />
        </q-card-section>
        <q-separator />

        <q-card-section>
          <div class="row q-col-gutter-md">

            <div class="col-12">
              <q-input
                dense
                bottom-slots
                label="Descrição"
                :error="Boolean(errorsMovcontasItem.descricao)"
                v-model="movcontasItem.descricao"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errorsMovcontasItem.descricao"
                    :key="error + i"
                  >{{error}}</span>
                </template>
              </q-input>
            </div>

            <div class="col-12">
              <q-field
                dense
                v-model="movcontasItem.valor"
                label="Valor"
                bottom-slots
                :error="Boolean(errorsMovcontasItem.valor)"
              >
                <template
                  v-slot:control="{ id, floatingLabel, value, emitValue }"
                >
                  <money
                    :id="id"
                    class="q-field__native text-right"
                    :value="value"
                    @input="emitValue"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errorsMovcontasItem.valor"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-field>
            </div>

            <div class="col-12">
              <q-select
                dense
                clearable
                emit-value
                map-options
                option-value="id"
                option-label="titulo"
                label="Plano de Contas"
                :options="planoContas"
                v-model="movcontasItem.planodeconta_id"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey"
                      >Sem Resultados</q-item-section
                    >
                  </q-item>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errorsMovcontasItem.planodeconta_id"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-select>
            </div>

          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            no-caps
            v-ripple
            clickable
            type="submit"
            label="Salvar"
            color="positive"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="loadingSalvarMovcontasItem"
            @click="salvarMovcontasItem()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>

      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import moment from "moment";
import axios from "@/axios-auth";
import { Loading } from 'quasar';
import { mapGetters } from "vuex";
import { Money } from "v-money";
import { bus } from "@/main";

export default {
  name: "modal-editar-movconta",
  components: { Money },
  props: {
    tipoConta: String
  },
  data() {
    return {
      cobrar: false,
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      planoContasFilterResult: [],
      tipo: null,
      triggerPlanodeconta: null,
      opcoesAvancadas: false,
      centroCustoRateio: null,
      porcentagemRateio: 0,
      porcentagemErro: null,
      centrocustoErro: null,
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false,
      },
      personalizarMultaJuros: false,
      fatura: {
        protesto: 0,
        multa: 0.00,
        juros: 0.00,
      },
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 0,
      },
      columnsItems: [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: row => row.id,
          sortable: true,
        },
        {
          name: "descricao",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true,
          align: "center",
        },
        {
          name: "planodeconta",
          label: "Plano de Contas",
          field: row => row.planodeconta ? row.planodeconta.numero : null,
          sortable: true,
          align: "center",
        },
        {
          name: "valor",
          label: "Valor",
          field: row => row.valor,
          sortable: true,
          align: "center",
        },
        { name: "tdfora" },
      ],
      openDialogSalvarMovcontasItem: false,
      movcontasItem: this.newMovcontasItem(),
      loadingSalvarMovcontasItem: false,
      errorsMovcontasItem: {},
      loadingExcluirMovcontasItem: false,
      loadingMovcontasItems: false,
      selectedFiles: [],
      arrayFile: [],
    };
  },
  methods: {
    planoContasFilter(val, update) {
      update(() => {
        this.planoContasFilterResult = this.planoContas.filter(
          plano => plano.titulo.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
    saveEditar() {
      let movconta = JSON.parse(JSON.stringify(this.movconta));

      if (movconta.planodeconta_id && typeof movconta.planodeconta_id === "object") {
        movconta.planodeconta_id = movconta.planodeconta_id.id;
      }

      if (movconta.tipo === 1) {
        movconta.movcontas_rateios = this.movconta.movcontas_rateios.map(rateio => ({
          centrocusto_id: rateio.centrocusto.id,
          porcentagem: rateio.porcentagem,
        }));

        this.$store.dispatch("saveEditarDespesas", movconta);

        if (this.selectedFiles.length > 0) {
          this.selectedFiles.forEach((file, index) => {
            this.uploadImages(file, this.arrayFile[index]);
          });
        }
      } else if (movconta.tipo === 2) {

        if (movconta.fatura_id && this.personalizarMultaJuros) {
          movconta.personalizar_multa_juros = true;
          movconta.fatura_multa = this.fatura.multa;
          movconta.fatura_juros = this.fatura.juros;
          movconta.fatura_protesto = this.fatura.protesto;
        }

        this.$store.dispatch("saveEditarReceitas", movconta);
      }
    },
    closeModal() {
      this.movconta.planodeconta_id = null;
      this.movconta.conta_id = null;
      this.tipoConta === "despesa"
        ? this.$store.commit("errorsDespesas", { errorsDespesas: {} })
        : this.$store.commit("errorsReceitas", { errorsReceitas: {} });

      this.$store.commit("setOpenDialogEditarMovconta", false);
      this.$store.commit("cadastroDespesa", {
        isCadastro: false,
        cadastro: {}
      });
      this.opcoesAvancadas = false;
      this.cobrar = false;
      this.selectedFiles = [];
      this.arrayFile = [];
    },
    tipoModal() {
      if (this.tipoConta === "despesa") {
        this.$store.commit("callDespesa", true);
        this.$store.dispatch("planoContas", "D");
        this.$store.dispatch("findGatewaysDespesa");
      } else {
        this.$store.commit("callDespesa", false);
        this.$store.dispatch("planoContas", "C");
      }
      this.$store.dispatch("contas");
      this.$store.dispatch("listarCentroCustos");
    },
    title() {
      switch (this.tipo) {
        case "despesa":
          return "Editar Despesa";
          break;
        case "receita":
          return "Editar Receita";
          break;
      }
    },
    nomeItem() {
      switch (this.tipo) {
        case "despesa":
          return "despesa";
          break;
        case "receita":
          return "receita";
          break;
      }
    },
    addRateio() {
      this.porcentagemErro = null;
      this.centrocustoErro = null;

      if (!this.centroCustoRateio) {
        this.centrocustoErro = "Centro de custo é obrigatório";
        return;
      }

      if (!this.porcentagemRateio) {
        this.porcentagemErro = "Porcentagem é obrigatória";
        return;
      }

      if (this.porcentagem < 0 || this.porcentagem > 100) {
        this.porcentagemErro = "Valor inválido";
        return;
      }

      const rateioIndex = this.movconta.movcontas_rateios.findIndex(rateio => rateio.centrocusto.id === this.centroCustoRateio.id);

      const rateio = {
        porcentagem: Math.abs(Number(this.porcentagemRateio)),
        centrocusto: {
          id: this.centroCustoRateio.id,
          nome: this.centroCustoRateio.nome,
        },
      };

      if (rateioIndex === -1) {
        this.movconta.movcontas_rateios.push(rateio);
      } else {
        this.movconta.movcontas_rateios[rateioIndex] = rateio;
      }

      this.centroCustoRateio = null;
      this.porcentagemRateio = 0;
    },
    changeVencimento(vencimento) {
      this.$refs.qDateVencimentoProxy.hide();
      this.updateReferencia(vencimento);
    },
    updateReferencia(vencimento) {
      const newDtvencimento = moment(vencimento, "DD/MM/YYYY");

      if (vencimento) {
        this.movconta.referencia = newDtvencimento.format("MM/YYYY");
      }
    },
    addMovcontasItem() {
      this.movcontasItem = this.newMovcontasItem();
      this.openModalSalvarMovcontasItem();
    },
    editMovcontasItem(movcontasItem) {
      this.movcontasItem = JSON.parse(JSON.stringify(movcontasItem));
      this.openModalSalvarMovcontasItem();
    },
    deleteMovcontasItem(movcontasItem) {
      this.$q.dialog({
        title: movcontasItem.descricao,
        message: "Tem certeza que deseja excluir o item?",
        cancel: "Cancelar",
        persistent: true,
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(`/financeiro/delete-movcontas-item/${movcontasItem.id}`)
            .then(res => {
              this.$q.dialog({
                title: res.data.message,
                class: `bg-${res.data.success ? "positive" : "warning"} text-white`,
                ok: {
                  "text-color": res.data.success ? "green" : "orange",
                  color: "white",
                },
              });

              if (!res.data.success) return;

              this.atualizarItems();
            })
            .catch(() => {
              this.$q.dialog({
                title: "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white",
                },
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    salvarMovcontasItem() {
      if (this.loadingSalvarMovcontasItem) return;

      this.loadingSalvarMovcontasItem = true;

      const data = JSON.parse(JSON.stringify(this.movcontasItem));
      let url = `/financeiro/add-movcontas-item/${this.movconta.id}`;

      if (data.id) {
        url = `/financeiro/edit-movcontas-item/${data.id}`;
      }

      axios
        .post(url, data)
        .then(res => {
          this.loadingSalvarMovcontasItem = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) {
            this.errorsMovcontasItem = res.data.errors || {};
            return;
          }

          this.closeModalSalvarMovcontasItem();
          this.atualizarItems();
        })
        .catch((e) => {
          this.loadingSalvarMovcontasItem = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar o item da conta',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    newMovcontasItem() {
      return {
        descricao: '',
        valor: 0.00,
        planodeconta_id: null,
        conta_id: null,
        periodo: 1,
      };
    },
    openModalSalvarMovcontasItem() {
      this.errorsMovcontasItem = {};
      this.openDialogSalvarMovcontasItem = true;
    },
    closeModalSalvarMovcontasItem() {
      this.openDialogSalvarMovcontasItem = false;
    },
    atualizarItems() {
      if (this.loadingMovcontasItems) return;

      this.loadingMovcontasItems = true;

      const url = this.movconta.tipo === 1
        ? `/financeiro/edit/${this.movconta.id}`
        : `/financeiro/items/${this.movconta.id}`;

      axios
        .get(url)
        .then(res => {
          this.loadingMovcontasItems = false;

          if (!res.data.success) return;

          if (this.movconta.tipo === 2) {
            this.$store.commit("itemsReceitas", {
              itemsReceitas: res.data.data.items,
            });
            return;
          }

          this.$store.commit("itemsDespesas", res.data.movconta.movcontas_items);
        })
        .catch(() => {
          this.loadingMovcontasItems = false;
          this.$q.notify({
            message: "Houve um erro ao carregar os itens",
            color: "red",
            position: "top-right",
          });
        });
      return;
    },
    alterarValoLancamento() {
      this.movconta.valor = Math.abs(parseFloat(this.movcontasItemsTotal));
    },
    onDragLeave() {
      event.preventDefault();
    },
    onHover() {
      event.preventDefault();
    },
    offHover() {
      event.preventDefault();
    },
    onDragOver() {
      event.preventDefault();
    },
    addFile(event) {
      const files = this.getFiles(event);
      if (!files.length) return;

      for (let i = 0, f; (f = files[i]); i++) {
        this.selectedFiles.push(f);

        this.arrayFile.push({
          fileName: f.name,
          fileSize: this.bytesToSize(f.size),
          success: false,
          error: false,
          message: "0%",
          uploading: false,
        });
      }
    },
    getFiles(event) {
      return event.dataTransfer ? event.dataTransfer.files : event.target.files;
    },
    uploadImages(arq, arr) {
      const fd = new FormData();
      fd.append("file", arq, arq.name);
      fd.append("movconta_id", this.movconta.id);

      axios
        .post("/arquivos/upload", fd)
        .then(res => {
          if (!res.data.success) {
            arr.error = true;
            arr.message = res.data.message;

            this.$q.notify({
              message: `${arr.message} ${arr.fileName}`,
              color: "orange",
              position: "top-right"
            });

            return;
          }

          arr.message = res.data.message;
          arr.success = res.data.success;
          arr.uploading = false;
          arr.delivered = true;
        });
    },
    bytesToSize(bytes) {
      if (bytes == 0) return "0 Byte";

      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    openModal() {
      this.$store.dispatch("getTokenQrcode");
      this.$store.commit("OpenDialogUploadResumoCaixas", true);
      this.$refs.uploadResumoTfl.dizsize();
    },
    apagaItem(i) {
      if (this.arrayFile[i].uploading) return;

      this.arrayFile.splice(i, 1);
    },
    clearFiles() {
      this.arrayFile = [];
    }
  },
  computed: {
    ...mapGetters([
      "findGatewaysDespesa",
      "contas",
      "centroCustos",
      "planoContas",
      "loadEditarMovconta",
      "OpenDialogEditarMovconta",
    ]),
    movconta() {
      this.triggerPlanodeconta; // Apenas para atualizar o movconta quando houver uma alteração de valor no planodeconta

      return this.tipo === "despesa"
        ? this.$store.getters.despesa
        : this.$store.getters.movconta;
    },
    movcontasItems() {
      return this.tipo === "despesa"
        ? this.$store.getters.itemsDespesas
        : this.$store.getters.itemsReceitas;
    },
    errors() {
      return this.tipo === "despesa"
        ? this.$store.getters.errorsDespesas
        : this.$store.getters.errorsReceitas;
    },
    porcentagemRateioTotal() {
      if (!this.movconta || !this.movconta.movcontas_rateios) {
        return 0.00;
      }

      return this.movconta.movcontas_rateios.reduce((total, rateio) => total + Math.abs(Number(rateio.porcentagem)), 0);
    },
    optionsCentroCustos() {
      if (!this.movconta || !this.movconta.movcontas_rateios) {
        return [];
      }

      return this.centroCustos.filter(centroCusto => {
        return !(this.movconta.movcontas_rateios.find(rateio => rateio.centrocusto.id == centroCusto.id));
      });
    },
    exibirCentrosdecusto() {
      return this.movconta.tipo === 1 && this.movconta.movcontas_rateios;
    },
    exibirMovcontasItems() {
      return !this.movconta.is_despesas_recorrencia;
    },
    hasOpcoesAvancadas() {
      return (this.exibirCentrosdecusto || this.exibirMovcontasItems || this.movconta.fatura_id)
        && !this.movconta.liquidado;
    },
    movcontasItemsTotal() {
      return this.movcontasItems.reduce((total, item) => total + item.valor, 0.00);
    },
    exibirGatewaysDespesa(){
      if (this.tipo == 'despesa' &&
          this.findGatewaysDespesa.gateways !== undefined
          && this.findGatewaysDespesa.gateways.length > 0) {
           return true;
      }

      return false;
    }
  },
  mounted() {
    this.tipo = this.tipoConta;
    this.cobrar = false;

    bus.$on("closeModalEditarMovconta", () => this.closeModal());
  },
  destroyed() {
    bus.$off("closeModalEditarMovconta");
  },
  watch: {
    OpenDialogEditarMovconta() {
      this.movconta.vencimento = moment(this.movconta.vencimento, "YYYY/MM/DD").format("DD/MM/YYYY");

      if (this.tipoConta === "despesa") {
        this.movconta.valor = Math.abs(this.movconta.valor);

        if (this.movconta.gateway_id){
          this.cobrar = true;
        }
      }

      const dtmovimento = moment(this.movconta.dtmovimento, "YYYY/MM/DD");
      if (dtmovimento.isValid()) {
        this.movconta.dtmovimento = dtmovimento.format('DD/MM/YYYY');
      }

      const liquidacao = moment(this.movconta.liquidacao, "YYYY/MM/DD");
      if (liquidacao.isValid()) {
        this.movconta.liquidacao = liquidacao.format('DD/MM/YYYY');
      }

      if (this.OpenDialogEditarMovconta) {
        this.tipoModal();
      }
    },
    planoContas() {
      if (this.movconta.planodeconta_id) {
        const planodeconta = this.planoContas
          .find(planodeconta => planodeconta.id === this.movconta.planodeconta_id);

        this.movconta.planodeconta_id = planodeconta;
        this.triggerPlanodeconta = planodeconta;
      }
    }
  }
};
</script>

<style scoped>
.q-field--with-bottom {
  padding-bottom: unset;
}

.layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
}

.layout-responsive {
  text-align:center;
  padding: 10px 10px;
}

.layout-responsive .icones-layout label {
  padding: 3rem;
}

.icones-layout label {
  margin-right: 5px;
}
</style>
