<template>
  <div id="timeline">
    <q-page-sticky position="top-right" :offset="[0, 90]">
      <q-btn
        :color="OpenDrawerTimelineCadastro ? 'red-7' : 'blue-7'"
        :icon="OpenDrawerTimelineCadastro ? 'close' : 'timeline'"
        class="rotate-0"
        style="border-top-left-radius: 50px; border-bottom-left-radius: 50px"
        @click="abreTimeline"
        :loading="loadingTime"
      >
        <q-tooltip
          v-if="!OpenDrawerTimelineCadastro"
          :content-class="
            OpenDrawerTimelineCadastro
              ? 'bg-negative text-white text-center'
              : 'bg-secondary text-white text-center'
          "
        >
          Linha do Tempo
        </q-tooltip>
        <template v-slot:loading>
          <q-spinner color="white" size="1em" />
        </template>
      </q-btn>
    </q-page-sticky>
    <q-drawer
      side="right"
      v-model="OpenDrawerTimelineCadastro"
      bordered
      :width="$q.screen.lt.sm ? 380 : 550"
      :breakpoint="500"
      behavior="default"
      content-class="bg-white"
    >
      <q-scroll-area class="fit">
        <div class="q-px-lg q-pb-md">
          <q-timeline>
            <q-timeline-entry heading id="timeline-cadastro-heading">
              Linha do Tempo
              <q-btn
                flat
                round
                icon="add"
                color="primary"
                style="height: 42px"
                class="align-self-center"
                @click="add()"
              >
                <q-tooltip>Adicionar Observação</q-tooltip>
              </q-btn>
              <q-btn dense flat round icon="refresh" color="primary" @click="fetchAllTimelines()">
                <q-tooltip>Listar Todos</q-tooltip>
              </q-btn>
              <q-btn-group rounded flat no-wrap no-caps class="q-ml-md">
                <q-btn class="white text-blue-7" rounded dense :label="filters.dtini">
                  <template v-slot:default>
                    <q-popup-proxy ref="qDateDtini" transition-show="scale" transition-hide="scale">
                      <q-date
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        minimal
                        today-btn
                        v-model="filters.dtini"
                        @input="alterarData"
                      />
                    </q-popup-proxy>
                  </template>
                </q-btn>
                <q-btn class="white text-blue-7" rounded dense :label="filters.dtfim">
                  <template v-slot:default>
                    <q-popup-proxy ref="qDateDtfim" transition-show="scale" transition-hide="scale">
                      <q-date
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        minimal
                        today-btn
                        v-model="filters.dtfim"
                        @input="alterarData"
                      />
                    </q-popup-proxy>
                  </template>
                </q-btn>
              </q-btn-group>
            </q-timeline-entry>
            <div class="q-col-gutter-md row q-pb-lg">
              <div class="col col-md-6">
                <q-select
                  label="Filtrar por Ação"
                  use-input
                  clearable
                  input-debounce="20"
                  map-options
                  option-label="label"
                  option-value="value"
                  emit-value
                  @filter="timelineFilterResult"
                  @input="selected => setTimelineGroup(selected)"
                  v-model="filters.group"
                  :options="timelineFilter"
                />
              </div>
              <div class="col col-md-6">
                <autocomplete
                  title="Filtrar por Usuário"
                  :filter="filterFn"
                  :carregando="loadingAutoComplete"
                  :options="usersOptions"
                  :formatDisplay="formatDisplay"
                  :create="() => {}"
                  :display="displayMontar"
                  tipoConsulta="cadastro"
                  :newCadastro="cadastroReturn"
                  @resultAutocomplete="setTimelineUser"
                />
              </div>
            </div>

            <div class="col-12 col-md-12 text-center" v-if="loadingTimelines">
              <q-spinner color="blue-7" size="4em" />
            </div>

            <div v-else-if="timelines.length === 0" class="text-center">
              Nenhuma timeline encontrada.
            </div>

            <q-timeline-entry
              v-else
              v-for="timeline in timelines"
              :key="timeline.id"
              :title="(timeline.nome || '') + ' ' + acaoFiltroTimeline(timeline.tipo)"
              :subtitle="returnSubtitle(timeline)"
              :icon="getIcone(timeline.icone)"
            >
              <div class="q-timeline__body" v-html="timeline.corpo"></div>
            </q-timeline-entry>
          </q-timeline>
          <br />
          <br />
          <q-btn
            v-if="timelines && timelines.length > 0"
            @click="loadMore()"
            :label="'Carregar mais ' + paging.page + '/' + paging.pageCount"
            class="absolute-bottom-right text-white bg-primary"
            style="margin: 0 25px 15px 0"
            :disable="btnDisabled || paging.page >= paging.pageCount"
            :loading="loadingTime"
          >
            <template v-slot:loading>
              <q-spinner color="white" size="1em" />
            </template>
          </q-btn>
        </div>
      </q-scroll-area>
    </q-drawer>
    <modal-timeline :cadastroId="this.routeId" v-on:updateTimeline="fetchTimelines()" />
  </div>
</template>

<script>
import axios from '@/axios-auth';
import { Notify } from 'quasar';
const moment = require('moment');

export default {
  name: 'timeline-cadastro',
  data() {
    return {
      OpenDrawerTimelineCadastro: false,
      timelines: [],
      icones: {
        table: 'list',
        key: 'vpn_key',
        upload: 'cloud_upload',
        trash: 'delete_forever',
        envelope: 'mail_outline',
        plus: 'add',
        pencil: 'mode_edit',
        money: 'monetization_on',
        print: 'print',
        file: 'description',
        like: 'thumb_up_alt',
        redeem: 'redeem',
        search: 'search',
        phone: 'phone',
      },
      tipos: [],
      groups: [],
      paging: {
        page: 0,
        count: 0,
        prevPage: false,
        nextPage: true,
        pageCount: 0,
      },
      loadingTimelines: false,
      loadingTime: false,
      btnDisabled: false,
      timelineFilter: [],
      edit: false,
      erroEditor: false,
      filters: {
        group: 'Todos',
        dtini: null,
        dtfim: null,
        userId: null,
      },
      usersOptions: [],
      loadingAutoComplete: false,
      userIdFilter: null,
    };
  },
  methods: {
    fetchTimelines(reset = true) {
      if (this.loadingTimelines) return;
      this.loadingTimelines = true;

      if (reset) {
        this.timelines = [];
        this.paging.page = 0;
      }

      const params = {
        page: this.paging.page,
        cadastro_id: this.routeId,
        dtini: this.filters.dtini.replace(/\//g, '-'),
        dtfim: this.filters.dtfim.replace(/\//g, '-'),
        group: this.filters.group !== 'Todos' ? this.filters.group : undefined,
        user_id: this.filters.userId ?? undefined,
      };

      axios
        .get('/timelines', { params })
        .then(res => {
          this.loadingTimelines = false;

          if (res.data.success) {
            this.timelines = this.timelines.concat(res.data.timelines);
            this.tipos = res.data.tipos;
            this.groups = res.data.groups;
            this.paging = res.data.paging;
            this.OpenDrawerTimelineCadastro = true;
          }
        })
        .catch(error => {
          this.loadingTimelines = false;

          Notify.create({
            message: 'Não foi possível carregar as timelines.',
            color: 'orange',
            position: 'top-right',
          });
        });
    },
    fetchAllTimelines() {
      this.filters = {
        group: 'Todos',
        dtini: moment().subtract(1, 'years').startOf('month').format('DD/MM/YYYY'),
        dtfim: moment().endOf('month').format('DD/MM/YYYY'),
        userId: null,
      };
      this.$root.$emit('limpar');
      this.fetchTimelines();
    },
    loadMore() {
      if (this.loadingTimelines) return;

      if (this.paging.page >= this.paging.pageCount) {
        this.btnDisabled = true;
        return;
      }

      this.paging.page += 1;
      this.fetchTimelines(false);
    },
    returnSubtitle(timeline) {
      const date =
        'em ' +
        moment(timeline.created).format('DD/MM/YYYY') +
        ' às ' +
        moment(timeline.created).format('HH:mm') +
        'h';

      const timelineGroupTitle = this.timelineGroupTitle(timeline.tipo);
      if (timelineGroupTitle) {
        return date + ' | ' + timelineGroupTitle;
      }

      return date;
    },
    abreTimeline() {
      if (this.OpenDrawerTimelineCadastro) {
        this.OpenDrawerTimelineCadastro = false;
        return;
      }

      this.filters.dtini = moment().subtract(1, 'years').startOf('month').format('DD/MM/YYYY');
      this.filters.dtfim = moment().endOf('month').format('DD/MM/YYYY');
      this.fetchTimelines();
    },
    timelineGroupTitle(timelineType) {
      const tipos = Object.values(this.tipos);
      const group = tipos.find(tipo => tipo.value === timelineType);
      if (!group) return false;

      return this.groups[group.group];
    },
    nomeFiltroTimeline(value) {
      return this.groups[value];
    },
    acaoFiltroTimeline(value) {
      if (this.tipos[value] !== undefined) {
        return this.tipos[value].label;
      }
    },
    getIcone(value) {
      return this.icones[value];
    },
    timelineFilterResult(value, update) {
      update(() => {
        const groupsKeys = Object.keys(this.groups);
        this.timelineFilter = groupsKeys
          .filter(group => group.toLowerCase().indexOf(value.toLowerCase()) > -1)
          .map(group => {
            return {
              label: this.groups[group],
              value: group,
            };
          });
      });
    },
    add() {
      this.$store.dispatch('openObs', true);
      this.$store.dispatch('isTimeGlobal', false);
      this.$store.commit('isTimelineCadastro', {
        cadastro: true,
        routeId: this.routeId,
      });
    },
    alterarData() {
      if (this.loadingTimelines) return;
      this.$refs.qDateDtini.hide();
      this.$refs.qDateDtfim.hide();

      this.fetchTimelines();
    },
    setTimelineUser(cadastro) {
      if (this.loadingTimelines) return;
      this.filters.userId = cadastro ? cadastro.user.id : null;
      this.fetchTimelines();
    },
    setTimelineGroup(group) {
      if (this.loadingTimelines) return;
      this.filters.group = group;
      this.fetchTimelines();
    },
    // Autocomplete methods
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.loadingAutoComplete = true;
        axios.get('/users').then(res => {
          this.loadingAutoComplete = false;
          this.usersOptions = res.data.data.cadastros;
        });
      });
    },
    formatDisplay(result) {
      return result.user.nome;
    },
    displayMontar(value) {
      return value ? value.user.nome : '';
    },
  },
  computed: {
    cadastroReturn() {
      return this.$store.getters.cadastroReturn;
    },
    routeId() {
      return this.$route.params.id;
    },
  },
};
</script>

<style lang="stylus">
.q-timeline__heading-title {
  font-size: 1.5rem !important;
  padding: 0 !important;
}

.q-timeline__title {
  font-size: 1rem;
}

.q-timeline__body p {
  margin: 0px;
}
</style>
