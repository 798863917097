<template>
  <div>
    <card-section-sem-permissao v-if="semPermissaoWebhooks" />

    <div v-else>
      <div v-if="webhooksOptionsCopy !== null" class="row q-col-gutter-lg q-px-sm">
        <div class="col-12 col-md-6">
          <q-list padding>
            <q-item tag="label">
              <q-item-section side>
                <q-toggle
                  v-model="webhooksOptionsCopy.webhooks_reenvio_automatico"
                  true-value="1"
                  false-value="0"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label>Realizar reenvio automático em caso de falha</q-item-label>
                <q-item-label caption>
                  Em caso de falha, o webhook será reenviado automaticamente, realizando tentativas adicionais de acordo com a configuração definida.
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
        <div class="col-12 col-md-6">
          <q-input
            v-model="webhooksOptionsCopy.webhooks_reenvio_maximo_tentativas"
            class="q-ml-md"
            label="Quantidade máxima de tentativas"
            type="number"
            min="1"
            max="10"
            step="1"
            :disable="webhooksOptionsCopy.webhooks_reenvio_automatico === '0'"
          />
        </div>
        <div class="col-12 col-md-6">
          <q-table
            flat
            :data="webhooks"
            :columns="columns"
            row-key="id"
            :separator="'horizontal'"
            :loading="loadingWebhook"
            no-data-label="Nenhum resultado encontrado."
            :pagination.sync="pagination"
          >
            <template v-slot:bottom></template>
            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                <q-td key="acao">
                  <q-icon
                    :name="props.row.ativo ? 'check' : 'close'"
                    size="24px"
                    :class="props.row.ativo ? 'text-positive' : 'text-negative'"
                  ></q-icon>
                  {{ acoesWebhook[props.row.acao] }}
                </q-td>
                <q-td key="descricao">
                  {{ props.row.descricao }} <br>
                  {{  props.row.url }}
                </q-td>
                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn
                        @click="logs(props.row)"
                        size="12px"
                        class="bg-info text-white"
                        icon="info"
                        :loading="props.row.loading"
                      >
                        <template v-slot:loading>
                          <q-spinner color="grey-10" size="1em" />
                        </template>
                      </q-btn>
                      <q-btn
                        @click="edit(props.row)"
                        size="12px"
                        class="bg-secondary text-white"
                        icon="edit"
                        :loading="props.row.loading"
                      >
                        <template v-slot:loading>
                          <q-spinner color="grey-10" size="1em" />
                        </template>
                      </q-btn>
                      <q-btn
                        @click="remove(props.row)"
                        size="12px"
                        class="bg-negative text-white"
                        icon="delete_forever"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      @click="logs(props.row)"
                      size="12px"
                      class="bg-info text-white"
                      icon="info"
                      :loading="props.row.loading"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                    <q-btn
                      @click="edit(props.row)"
                      size="12px"
                      class="bg-secondary text-white"
                      icon="edit"
                      :loading="props.row.loading"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                    <q-btn
                      @click="remove(props.row)"
                      size="12px"
                      class="bg-negative text-white"
                      icon="delete_forever"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
        <div class="col-12">
          <div class="flex justify-end q-mb-lg q-mr-auto">
            <q-btn color="green" label="Salvar" :loading="savingConfig" @click="saveConfig" />
          </div>
        </div>
      </div>
    </div>

    <modal-webhook-logs />
  </div>
</template>

<script>
import axios from '@/axios-auth';
import { mapActions, mapGetters } from 'vuex';
import { Loading } from 'quasar';

export default {
  data: () => ({
    pagination: {
      rowsPerPage: 0,
    },
    columns: [
      {
        name: "acao",
        required: true,
        label: "Ação",
        align: "left",
        field: row => row.acao,
        sortable: true,
      },
      {
        name: "descricao",
        required: true,
        label: "Descrição",
        align: "center",
        field: row => row.descricao,
        sortable: true,
      },

      {},
    ],
    savingConfig: false,
    webhooksOptionsCopy: null,
  }),
  methods: {
    ...mapActions({
      list: 'listWebhooks',
      info: 'listWebhookInfo',
      resetPaging: 'resetPaginationWebhookLogs',
    }),
    logs(webhook) {
      this.resetPaging();
      this.info(webhook);
      this.webhook = JSON.parse(JSON.stringify(webhook));
      this.OpenDialogWebhookLogs = true;
    },
    edit(webhook) {
      this.$store.dispatch('getAcoesWebhook');
      this.webhook = JSON.parse(JSON.stringify(webhook));
      this.isNewWebhook = false;
      this.OpenDialogNovoWebhook = true;
    },
    remove(webhook) {
      this.$q.dialog({
        title: 'Webhook: ' + this.acoesWebhook[webhook.acao],
        message: `Tem certeza que deseja excluir este item?`,
        cancel: "Cancelar",
        persistent: true,
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post('/webhooks/delete/' + webhook.id)
            .then(res => {
              if (res.error && res.error.response.status === 403) {
                this.$q.notify({
                  message: res.error.response.data.message,
                  color: 'red',
                  position: 'top-right',
                });
                return;
              }

              if (res.data.success) {
                this.$q.dialog({
                  title: this.acoesWebhook[webhook.acao] + ' excluído com sucesso!',
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white",
                  },
                });
                this.list();
              } else {
                this.$q.dialog({
                  title: res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white",
                  },
                });
              }
            })
            .catch(() => {
              this.$q.dialog({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white",
                },
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    saveConfig() {
      if (this.savingConfig) return;
      this.savingConfig = true;

      axios.post('/webhooks/config', this.webhooksOptionsCopy)
        .then((res) => {
          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? "green" : "orange",
            position: "top-right",
          });

          if (res.data.success) {
            this.$store.commit('webhooksOptions', res.data.options);
          }
        })
        .catch(() => {
          this.$q.notify({
              message: 'Não foi possível contactar, ou você não possui permissão!',
              color: "red",
              position: "top-right"
            });
        })
        .finally(() => {
          this.savingConfig = false;
        });
    }
  },
  computed: {
    ...mapGetters([
      "semPermissaoWebhooks",
      "loadingWebhook",
      "acoesWebhook",
      "webhooks",
      "webhooksOptions"
    ]),
    OpenDialogNovoWebhook: {
      get () {
        return this.$store.getters.OpenDialogNovoWebhook;
      },
      set (value) {
        this.$store.commit('OpenDialogNovoWebhook', value);
      },
    },
    OpenDialogWebhookLogs: {
      get () {
        return this.$store.getters.OpenDialogWebhookLogs;
      },
      set (value) {
        this.$store.commit('OpenDialogWebhookLogs', value);
      },
    },
    isNewWebhook: {
      get () {
        return this.$store.getters.isNewWebhook;
      },
      set (value) {
        this.$store.commit('isNewWebhook', value);
      },
    },
    webhook: {
      get () {
        return this.$store.getters.webhook;
      },
      set (value) {
        this.$store.commit('webhook', value);
      },
    },
  },
  watch: {
    webhooksOptions(value) {
      this.webhooksOptionsCopy = { ...value };
    }
  },
  mounted () {
    this.list();
  },
};
</script>
