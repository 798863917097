<template>
  <div id="notificar-cliente">
    <q-dialog v-model="OpenDialogNotificarCliente" persistent>
      <q-card style="width: 800px; max-width: 90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">Notificar Cliente</div>
          <q-space />
          <q-btn icon="close" clickable flat round dense v-close-popup @click="closeModal" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md q-gutter-sm">
            <div v-if="!isIndividual" class="row q-col-gutter-x-md">
              <q-select
                clearable
                style="width: 100%"
                v-model="tipoNotificacao"
                :options="tiposNotificacoes"
                label="Tipo de Notificação"
              />
            </div>

            <div v-if="tipoNotificacao === 'WhatsApp'" class="q-mt-lg">
              <q-banner rounded style="background-color: #31ccec;">
                <q-icon name="info" size="2em" />
                Essa notificação possui apenas os dados do cadastro.
              </q-banner>
            </div>

            <div v-if="tipoNotificacao === 'E-mail' || tipoNotificacao === 'WhatsApp'" class="q-ml-none q-mt-md">
              <q-select
                v-if="tipoNotificacao === 'E-mail'"
                clearable
                style="width: 100%"
                v-model="template"
                :options="templates"
                emit-value
                option-label="subject"
                option-value="id"
                map-options
                label="Template de E-mail"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Nenhum template encontrado
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>

              <q-select
                v-if="tipoNotificacao === 'WhatsApp'"
                clearable
                style="width: 100%"
                v-model="template"
                :options="templates"
                emit-value
                option-label="subject"
                option-value="id"
                map-options
                :error="Boolean(errors.template_id)"
                label="Template de WhatsApp"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Nenhum template encontrado
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span
                      v-for="(error, i) in errors.template_id"
                      :key="error + i"
                      >{{ error }}
                  </span>
                </template>
              </q-select>

              <div class="col-12 col-md-12 q-mt-md" v-if="tipoNotificacao == 'WhatsApp'">
                <q-input
                  label="Data de expiração"
                  v-model="expira"
                  bottom-slots
                  :error="Boolean(errors.expira)"
                  @click="$refs.expiraDate.show()"
                  >
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="expiraDate"
                        transition-show="scale"
                        transition-hide="scale"
                        >
                        <q-date
                          v-model="expira"
                          mask="DD/MM/YYYY HH:mm"
                          :locale="langDate"
                          minimal
                          today-btn
                          @change="closeExpiraDialog"
                          @click="closeExpiraDialog"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span
                        v-for="(error, i) in errors.expira"
                        :key="error + i"
                        >{{ error }}
                    </span>
                  </template>
                  <template v-slot:append>
                    <q-icon name="access_time" class="cursor-pointer">
                      <q-popup-proxy
                        ref="expiraTime"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-time
                          v-model="expira"
                          mask="DD/MM/YYYY HH:mm"
                          format24h
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:hint>
                    Data limite para envio da mensagem
                  </template>
                </q-input>
              </div>

              <div v-if="tipoNotificacao == 'WhatsApp'" class="q-mt-md">
                <q-select
                  v-model="variavel"
                  :options="variaveis"
                  label="Variáveis"
                  bottom-slots
                />
              </div>
              <q-input
                v-model="whatsappBody"
                outlined
                autogrow
                label="Mensagem"
                v-if="tipoNotificacao === 'WhatsApp'"
              />
              <q-editor
                v-model="qeditor"
                ref="editor"
                class="q-mt-lg"
                v-if="tipoNotificacao !== 'WhatsApp'"
                :dense="$q.screen.lt.md"
                :toolbar="[
                ['left','center','right','justify'],
                ['bold', 'italic', 'underline', 'strike', 'color', 'colorFill'],
                ['token', 'hr', 'link', 'custom_btn'],
                ['unordered', 'ordered'],
                ['undo', 'redo']
                ]"
                :fonts="{
                    arial: 'Arial',
                    arial_black: 'Arial Black',
                    comic_sans: 'Comic Sans MS',
                    courier_new: 'Courier New',
                    impact: 'Impact',
                    lucida_grande: 'Lucida Grande',
                    times_new_roman: 'Times New Roman',
                    verdana: 'Verdana'
                }"
              >
                <template v-slot:color>
                  <q-btn-dropdown
                    dense
                    no-caps
                    ref="color"
                    no-wrap
                    unelevated
                    text-color="black"
                    size="sm"
                    icon="format_color_text"
                  >
                    <q-list dense>
                      <q-item
                        tag="label"
                        clickable
                        @click="color('foreColor', foreColor)"
                      >
                        <q-item-section>
                          <q-color
                            v-model="foreColor"
                             no-header
                             no-footer
                             default-view="palette"
                             :palette="textColor"
                          />
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
                </template>
                <template v-slot:colorFill>
                  <q-btn-dropdown
                    dense
                    no-caps
                    ref="colorFill"
                    no-wrap
                    unelevated
                    text-color="black"
                    size="sm"
                    icon="format_color_fill"
                  >
                    <q-list dense>
                      <q-item
                        tag="label"
                        clickable
                        @click="colorFill('backColor', highlight)"
                      >
                        <q-item-section>
                          <q-color
                            v-model="highlight"
                            default-view="palette"
                            no-header
                            no-footer
                            :palette="fillColor"
                          />
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
                </template>
              </q-editor>
            </div>
          </div>
          <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum repellendus sit voluptate voluptas eveniet porro. Rerum blanditiis perferendis totam, ea at omnis vel numquam exercitationem aut, natus minima, porro labore. -->
        </q-card-section>
        <!-- <q-separator /> -->
        <q-card-actions align="right">
          <!-- <q-btn flat label="Decline" color="primary" v-close-popup /> -->
          <q-btn
            @click.exact="actionEnviar"
            label="Enviar"
            class="bg-positive"
            color="primary"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify } from "quasar";
import axios from "@/axios-auth";
import moment from 'moment';

export default {
  name: "notificar-cliente",
  props: {
    isIndividual: {
      type: Boolean,
      default: false,
      required: false,
    },
    tipoNotificacaoIndividual: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      tipoNotificacao: null,
      tiposNotificacoes: ["E-mail", "WhatsApp"],
      text: "",
      qeditor: "",
      whatsappBody: "",
      whatsappBodyTemp: "",
      template: null,
      templates: [],
      expira: null,
      variaveis: [
        {
          label: "Campo nome no cadastro",
          value: "{CadastroNome}",
        },
        {
          label: "Campo email no cadastro",
          value: "{CadastroEmail}",
        },
        {
          label: "Cadastro CPF ou CNPJ",
          value: "{CadastroCpfCnpj}",
        },
        {
          label: "Boleto Descrição",
          value: "{FaturaDescricao}",
        },
        {
          label: "Boleto Link",
          value: "{FaturaLink}",
        },
        {
          label: "Boleto Vencimento",
          value: "{FaturaVencimento}",
        },
        {
          label: "Boleto Valor",
          value: "{FaturaValor}",
        },
        {
          label: "Boleto Valor Liquidado",
          value: "{FaturaValorLiquidado}",
        },
        {
          label: "Agenda Título",
          value: "{EventoAgenda}",
        },
        {
          label: "Agenda Horário",
          value: "{EventoHorario}",
        },
        {
          label: "Agenda Descrição",
          value: "{EventoDescricao}",
        },
        {
          label: "Link da Nota Fiscal",
          value: "{NotaFiscalLink}",
        },
        {
          label: "Número da Nota Fiscal",
          value: "{NotaFiscalNumero}",
        },
        {
          label: "Código de Verificação da Fatura",
          value: "{NotaFiscalCodigoVerificacao}",
        },
      ],
      variavel: null,
      textColor: ['#FF6666', '#FFB366', '#FFFF66', '#66FF66', '#66FF66', '#66FF66', '#66B3FF', '#6666FF', '#B366FF', '#FF66FF',
        '#FF0000', '#FF8000', '#FFFF00', '#00FF00', '#00FF80', '#00FFFF', '#0080FF', '#0000FF', '#8000FF', '#FF00FF',
        '#D60000', '#D66C00', '#D6D600', '#00D600', '#00D66C', '#00D6D6', '#006CD6', '#0000D6', '#6C00D6', '#D600D6',
        '#FFFFFF', '#CDCDCD', '#B2B2B2', '#999999', '#7F7F7F', '#666666', '#4C4C4C', '#333333', '#191919', '#000000'
      ],
      fillColor: ['#FF6666', '#FFB366', '#FFFF66', '#66FF66', '#66FF66', '#66FF66', '#66B3FF', '#6666FF', '#B366FF', '#FF66FF',
        '#FF0000', '#FF8000', '#FFFF00', '#00FF00', '#00FF80', '#00FFFF', '#0080FF', '#0000FF', '#8000FF', '#FF00FF',
        '#D60000', '#D66C00', '#D6D600', '#00D600', '#00D66C', '#00D6D6', '#006CD6', '#0000D6', '#6C00D6', '#D600D6',
        '#FFFFFF', '#CDCDCD', '#B2B2B2', '#999999', '#7F7F7F', '#666666', '#4C4C4C', '#333333', '#191919', '#000000'
      ],
      foreColor: '',
      highlight: ''
    };
  },
  watch: {
    tipoNotificacao(value) {
      this.buscaTemplates();
      this.template = null;
      this.qeditor = "";
      this.whatsappBody = "";
    },
    template(value){
      if (value){
        this.buscaTemplate(value);
      }
    },
    variavel(value) {
      if (value) {
        this.whatsappBody += value.value;
        this.variavel = null;
      }
    },
    expira() {
      const primeiroElementoHorario = document.querySelector('.q-time__clock-pos-0 span');

      if (primeiroElementoHorario && primeiroElementoHorario.innerHTML === '0') {
        this.$refs.expiraTime.hide();
      }
    },
    OpenDialogNotificarCliente(value){
      if(value && this.tipoNotificacao === "WhatsApp"){
        this.verificaConexaoDB();
      }
    },
  },
  computed: {
    ...mapGetters([
      "dadosNotificar",
      "hasWhatsApp",
      "errorsWhatsApp",
      "tipoWhatsapp"
    ]),
    OpenDialogNotificarCliente: {
      get() {
        return this.$store.getters.OpenDialogNotificarCliente;
      },
      set() {
        this.$store.commit(
          "OpenDialogNotificarCliente",
          this.OpenDialogNotificarCliente
        );
      }
    },
    errors() {
      return this.errorsWhatsApp;
    }

  },
  methods: {
    limparCampos() {
      this.tipoNotificacao = null;
      this.text = "";
      this.qeditor = "";
      this.whatsappBody = "";
      this.template = null;
      this.expira = null;
      this.variavel = null;
    },
    buscaTemplates() {
      let params = {
        tipo: this.tipoNotificacao === "WhatsApp" ? "whatsapp" : "cadastro",
      };
      axios
        .get("/templates", {
          params: params
        })
        .then(res => {
          if (res.data.success) {
            this.templates = res.data.data.templates;
          }
        })
        .catch(err => {
          Notify.create({
            message: "Houve um erro ao buscar os templates",
            color: "red",
            position: "top-right"
          });
        });
    },
    buscaTemplate(template) {
      axios.get("/templates/edit/" + template).then(res => {
        if (res.data.success) {
          this.tipoWhatsapp === 'dourasoft' ? (this.whatsappBody = "", this.whatsappBodyTemp = res.data.template.body) : this.whatsappBody = res.data.template.body;
        }
      });
    },
    closeModal() {
      this.$store.commit("OpenDialogNotificarCliente", false);
      this.$store.commit("errorsWhatsApp", { errorsWhatsApp: {} });
    },
    enviarNotificacao() {
      let errorMessage = null;

      if (!this.template) {
        errorMessage = "É necessário escolher o template!";
      }

      if (!this.tipoNotificacao) {
        errorMessage = "É necessário escolher o tipo de notificação!";
      }

      if (errorMessage) {
        Notify.create({
          message: errorMessage,
          color: "red",
          position: "top-right"
        });
        return;
      }

      let dados = 'email';
      this.dadosNotificar.mensagem = this.qeditor;

      this.dadosNotificar.tipo = dados;
      this.dadosNotificar.template_id = this.template;

      axios
        .post("/cadastros/notificar", this.dadosNotificar)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });

            if (dados === "whatsapp") {
              this.$store.dispatch("listarWppMessagesList", { atualizar: true });
            }

            this.limparCampos();
            this.$store.commit("OpenDialogNotificarCliente", false);
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          this.$store.dispatch("resetAcoes");
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível adicionar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    enviarWhatsApp() {
      var dados = {
        ids: this.dadosNotificar.ids,
        template: this.template,
        mensagem: this.whatsappBody,
        expira: this.expira,
      };

      if(this.tipoWhatsapp === 'dourasoft') dados.whatsappBodyTemp = this.whatsappBodyTemp;
      this.$store.dispatch("realizarEnvioWhatsCadastro", dados);
    },
    closeExpiraDialog() {
      this.$refs.expiraDate.hide();
    },
    actionEnviar() {
      this.tipoNotificacao === "WhatsApp" ? this.enviarWhatsApp() : this.enviarNotificacao();
    },
    checkEmailIndividual() {
      if (this.isIndividual && this.tipoNotificacaoIndividual !== null) {
        this.tipoNotificacao = this.tipoNotificacaoIndividual;
      }
    },
    color(cmd, name) {
      const edit = this.$refs.editor;
      this.$refs.color.hide();
      edit.caret.restore();
      edit.runCmd(cmd, name);
      edit.focus();
    },
    colorFill(cmd, name) {
      const edit = this.$refs.editor;
      this.$refs.color.hide();
      edit.caret.restore();
      edit.runCmd(cmd, name);
      edit.focus();
    },
    verificaConexaoDB(){
      axios.get("/whatsapp/status?get-conexaoDB=1")
        .then(res=>{
          this.$store.commit("conexao", res.data.status);
          this.$store.commit("tipoWhatsapp", res.data.tipo);
        })
        .catch(err=>{
        });
    }
  },
  mounted() {
    this.checkEmailIndividual();
  }
};
</script>

<style lang="stylus" scoped></style>
