<template>
  <div id="tourFornecedores">
    <q-card-section class="text-center" v-if="loadFornecedores">
      <q-spinner color="blue-7" size="4em" style="position: relative" />
    </q-card-section>
    <q-card-section v-if="!loadFornecedores">
      <p class="text-justify">
        Para garantir o mínimo de informações necessárias, preencha abaixo conforme solicitado. Caso
        haja mais de um fornecedor para os itens abaixo, basta clicar em "Adicionar novo
        fornecedor..."
      </p>

      <div class="row">
        <div
          class="col-12 col-md-12"
          v-for="(fornecedor, index) in fornecedores"
          :key="fornecedor.id"
          @keyup.enter="pulaProx(index)"
        >
          <q-input
            square
            :ref="`fornecedor${index}`"
            v-model="fornecedor.nome"
            @keyup.enter="envia(fornecedor, index)"
            @blur="envia(fornecedor, index)"
            :label="nomeFornecedor[index]"
          >
            <template v-slot:append v-if="fornecedor.valid">
              <q-icon name="check" color="green" />
            </template>
          </q-input>
          <br />
        </div>
        <div class="col-12 col-md-12">
          <q-btn
            icon="add"
            label="Adicionar novo fornecedor..."
            color="white text-blue-7"
            flat
            clickable
            @click="addNewItem"
          ></q-btn>
        </div>
      </div>
    </q-card-section>
  </div>
</template>

<script>
import { Notify } from 'quasar';
import axios from '@/axios-auth';
export default {
  name: 'tour-fornecedores',
  data() {
    return {
      fornecedores: [],
      loadFornecedores: false,
      loadSave: false,
      novoItem: false,
      errors: false,
      newFornecedor: {
        nome: '',
        fornecedor: true,
        cliente: false,
      },
      loadNovo: false,
      adicionaFornecedor: false,
      nomeFornecedor: [
        'Fornecedor de Água',
        'Fornecedor de Energia Elétrica',
        'Fornecedor de telefonia',
        'Escritório de Contabilidade',
        'DouraSoft',
        'Governo Federal',
        'Governo Estadual',
        'Governo Municipal',
      ],
      showButton: '',
      saved: [],
      saving: false,
    };
  },
  methods: {
    addNewItem() {
      this.fornecedores.push({ nome: '', valid: false, id: null });
      this.nomeFornecedor.push('Novo Fornecedor');
      // this.novoItem = true
    },
    fornecedoresNext() {
      this.$parent.fornecedoresNext();
    },
    fornecedoresPrev() {
      this.$parent.fornecedoresPrev();
    },
    listar() {
      this.loadFornecedores = true;
      axios.get('/users/tour?passo=3').then(res => {
        for (let i = 0; i < res.data.fornecedores.length; i++) {
          res.data.fornecedores[i].valid = false;
        }
        this.fornecedores = res.data.fornecedores;
        this.loadFornecedores = false;
      });
    },
    envia(fornecedor, index) {
      if (fornecedor.saving) return;

      const newFornecedor = JSON.parse(JSON.stringify(this.fornecedores[index]));
      newFornecedor.saving = true;
      this.$set(this.fornecedores, index, newFornecedor);

      if (fornecedor.nome === '') {
        newFornecedor.valid = false;
        this.$set(this.fornecedores, index, newFornecedor);
        return;
      }

      const url = !fornecedor.id ? '/cadastros/add' : `/cadastros/edit/${fornecedor.id}`;
      const nome = {
        nome: fornecedor.nome,
        tour: true,
        cliente: false,
        fornecedor: true,
      };

      axios
        .post(url, nome)
        .then(res => {
          newFornecedor.saving = false;
          newFornecedor.valid = res.data.success;
          this.$set(this.fornecedores, index, newFornecedor);

          if (!res.data.success) return;

          this.$set(this.fornecedores, index, res.data.data.cadastro);
        })
        .catch(() => {
          newFornecedor.saving = false;
          this.$set(this.fornecedores, index, newFornecedor);

          Notify.create({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    pulaProx(index) {
      if (!this.$refs[`fornecedor${index + 1}`]) return;

      this.$refs[`fornecedor${index + 1}`][0].select();
    }
  },
  mounted() {
    this.listar();
  },
};
</script>

<style lang="stylus" scoped></style>
