<template>
  <div id="modalDetalharNotaFiscal">
    <q-dialog v-model="OpenDialogDetalharNotaFiscal" persistent>
      <q-card id="cardModalDetalharNotaFiscal" style="width: 800px; max-width: 80vw;">
        <q-card-section class="row items-center">
          <div class="text-h6" v-if="!buscandoItems">
            Detalhamento -
            <strong>{{ notaFiscal.movconta_id }}</strong>
            {{
              notaFiscal.cadastro
                ? notaFiscal.cadastro.nome
                : "Sem Cadastro"
            }}
          </div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />

        <q-card-section v-if="buscandoItems">
          <q-spinner
            color="blue-7"
            size="4em"
            style="position:relative;top: 50%;left: 40%;"
          />
        </q-card-section>

        <q-card-section v-if="!buscandoItems" class="q-pb-none">
          <q-list>
            <q-item>
              <q-item-section>
                <q-item-label>
                  <p>
                    Status <b>{{ statusNotaList[notaFiscal.status] }}</b>
                  </p>
                  <p>
                    Valor <b>R$ {{notaFiscal.valor | currency}}</b>
                  </p>
                  <p v-show="notaFiscal.valor_desconto_incondicionado">
                    Desconto Incondicionado <b>R$ {{notaFiscal.valor_desconto_incondicionado | currency}}</b>
                  </p>
                  <p>
                    Data de Movimento <b>{{ new Date(notaFiscal.dtmovimento) | formatDate }}</b>
                  </p>
                  <p v-show="notaFiscal.dtemissao">
                    Data de Emissão <b>{{ new Date(notaFiscal.dtemissao) | formatDate }}</b>
                  </p>
                  <p v-if="notaFiscal.movconta">
                    Descrição <b>{{ notaFiscal.movconta.descricao }}</b>
                  </p>
                  <p v-if="notaFiscal.movconta">
                    Referência <b>{{ notaFiscal.movconta.referencia }}</b>
                  </p>
                  <p v-if="notaFiscal.cadastro">
                    Nome <b>{{ notaFiscal.cadastro.nome ? notaFiscal.cadastro.nome : "Sem Nome" }}</b>
                  </p>
                  <p v-if="notaFiscal.cadastro">
                    Email <b>{{ notaFiscal.cadastro.email ? notaFiscal.cadastro.email : "Sem Email" }}</b>
                  </p>
                  <p v-if="notaFiscal.cadastro">
                    Telefone <b>{{ notaFiscal.cadastro.telefone ? notaFiscal.cadastro.telefone : "Sem Telefone" }}</b>
                  </p>
                  <p v-show="notaFiscal.numero_nfse">
                    Número NFSE <b>{{ notaFiscal.numero_nfse }}</b>
                  </p>
                  <p v-show="notaFiscal.codigo_verificacao">
                    Código de Verificação <b>{{ notaFiscal.codigo_verificacao }}</b>
                  </p>
                  <p v-show="notaFiscal.numero_rps">
                    Número RPS <b>{{ notaFiscal.numero_rps }}</b>
                  </p>
                  <p v-show="notaFiscal.lote">
                    Lote <b>{{ notaFiscal.lote }}</b>
                  </p>
                  <p v-show="notaFiscal.created">
                    Gerada em <b>{{ notaFiscal.created | formatDate }}</b>
                  </p>
                </q-item-label>
              </q-item-section>

              <q-item-section side style="justify-content: start;">
                <q-btn
                  outline
                  type="a"
                  class="q-mb-md"
                  color="primary"
                  target="_blank"
                  label="Visualizar"
                  :href="notaFiscal.url_aol"
                />
                <q-btn
                  type="a"
                  :href="notaFiscal.url_xml"
                  target="_blank"
                  label="Visualizar XML"
                  color="primary"
                  outline
                />
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-section v-if="timelineNotasFiscais && timelineNotasFiscais.length > 0" class="q-pt-none">
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <q-timeline>
                <q-timeline-entry heading>Histórico</q-timeline-entry>

                <q-timeline-entry
                  v-for="time in timelineNotasFiscais"
                  :key="time.id"
                  :title="(time.nome ? `${time.nome} ` : '') + acaoFiltroTimeline(time.tipo)"
                  :subtitle="returnSubtitle(time)"
                  :icon="getIcone(time.icone)"
                >
                  <div v-html="time.corpo"></div>
                </q-timeline-entry>
              </q-timeline>
              <q-btn
                v-if="timelineNotasFiscais && timelineNotasFiscais.length > 0"
                @click="loadMore()"
                :label="
                  'Carregar mais ' +
                    pagingTimeNotasFiscais.page +
                    '/' +
                    pagingTimeNotasFiscais.pageCount
                "
                class="absolute-bottom-right text-white bg-primary"
                style="margin: 0 25px 15px 0;padding: 8px"
                :disable="
                  btnDisabled || pagingTimeNotasFiscais.page >= pagingTimeNotasFiscais.pageCount
                "
                :loading="false"
              >
                <template v-slot:loading>
                  <q-spinner color="white" size="1em" />
                </template>
              </q-btn>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
const moment = require("moment");
import { mapGetters } from 'vuex';

export default {
  name: "modal-detalhar-nota-fiscal",
  data() {
    return {
      icones: {
        money: "monetization_on",
        file: "description",
        receipt: "receipt",
        pencil: "edit",
      },
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogDetalharNotaFiscal", false);
    },
    acaoFiltroTimeline(value) {
      if (this.timelineActionNotasFiscais[value] !== undefined) {
        return this.timelineActionNotasFiscais[value].label;
      }
    },
    getIcone(value) {
      return this.icones[value];
    },
    returnSubtitle(timeline) {
      return (
        "em " +
        moment(timeline.created).format("DD/MM/YYYY") +
        " às " +
        moment(timeline.created).format("HH:mm") +
        "h"
      );
    },
    loadMore() {
      this.$store.dispatch("loadMoreNotasFiscais");
    },
  },
  computed: {
    ...mapGetters([
      "OpenDialogDetalharNotaFiscal",
      "timelineActionNotasFiscais",
      "timelineNotasFiscais",
      "pagingTimeNotasFiscais",
      "buscandoItems",
      "notaFiscal",
      "btnDisabled",
      "statusNotaList",
    ]),
  }
};
</script>

<style lang="stylus" scoped>
p {
  margin-bottom: 0;
}

.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>

<style>
  #cardModalDetalharNotaFiscal .q-timeline p {
    margin: 0px;
  }
</style>
