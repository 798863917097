<template>
  <q-page id="extrato">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!isPermisso"></sem-permissao>
        <div v-if="isPermisso" class="col-12 col-md-3">
            <q-card>
              <q-card-section>
                <div style="font-size: 20px;letter-spacing: 0.005em;font-weight: 400;">
                  <q-btn
                    flat
                    id="addTransferencia"
                    round
                    dense
                    :icon="'add'"
                    class="q-ml-md text-primary"
                    style="margin-right: 10px;margin-left: 0"
                    @click="openTransferir()"
                  />Transferência
                </div>
              </q-card-section>

              <q-card-section v-if="loadingExtratoSaldo">
                <q-spinner
                  color="blue-7"
                  size="4em"
                  style="position:relative;top: 50%;left: 40%;"
                />
              </q-card-section>

              <q-card-section v-if="!loadingExtratoSaldo" >
                <q-list>
                  <q-item>
                    <q-item-section>Saldo em:</q-item-section>
                    <q-item-section
                      avatar
                    >
                      <q-btn class="white text-blue-7 saldo"  dense flat :label="dataTransferencia">
                        <template v-slot:default>
                          <q-popup-proxy
                            ref="qDateProxy"
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              mask="DD/MM/YYYY"
                              minimal
                              today-btn
                              v-model="dtini"
                              @input="() => changeTransferencia()"
                            />
                          </q-popup-proxy>
                        </template>
                      </q-btn>
                    </q-item-section>
                  </q-item>
                  <q-item
                    dense
                    v-for="(conta, index) in listaContasExtrato"
                    :key="index"
                    :active="conta.titulo == tipoExtrato"
                    @click="trocaConta(conta)"
                    clickable
                    active-class="active_status"
                  >
                    <q-item-section>{{ conta.titulo }}</q-item-section>
                    <q-item-section
                      avatar
                      :class="{
                        'text-white': conta.titulo == tipoExtrato,
                        'text-red': conta.saldo < 0
                      }"
                      >R$ {{ conta.saldo | currency }}</q-item-section
                    >
                  </q-item>

                  <q-separator />

                  <q-item>
                    <q-item-section>Total</q-item-section>
                    <q-item-section
                      avatar
                      v-html="returnTotal()"
                    ></q-item-section>
                  </q-item>
                </q-list>
              </q-card-section>
            </q-card>
            <sem-permissao v-if="!isPermisso"></sem-permissao>
            <q-card class="q-mt-md">
              <q-card-section v-if="loadingExtratoSaldo">
                <q-spinner
                  color="blue-7"
                  size="4em"
                  style="position:relative;top: 50%;left: 40%;"
                />
              </q-card-section>
              <q-card-section v-if="this.fluxoDeCaixa.length > 0 && !loadingExtratoSaldo">
                <span style="font-size: 18px;letter-spacing: 0.005em;font-weight: 400; padding-right: 5px">Previsão de Fluxo de Caixa</span>
                <small style="color: #616161">
                  <q-btn-group flat>
                    <q-btn
                      flat
                      round
                      icon="chevron_left"
                      class="white text-blue-7"
                      clickable
                      @click="changeMonth('prev')"
                    />
                    <q-btn flat :label="returnMonth" class="white text-blue-7" />
                    <q-btn
                      flat
                      round
                      icon="chevron_right"
                      class="white text-blue-7"
                      clickable
                      @click="changeMonth('next')"
                    />
                  </q-btn-group>
                </small>
                <GChart
                  type="LineChart"
                  :data="this.organizaDados"
                  :options="this.graficoFluxoDeCaixaOptions"
                />
              </q-card-section>
            </q-card>
        </div>
        <div v-if="isPermisso" class="col-12 col-md-9">
          <q-table
            title="Extrato"
            :data="extratoFiltrado"
            :columns="columns"
            row-key="id"
            :loading="loadingExtrato"
            :bottom="false"
            :pagination.sync="pagination"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
          >
            <template v-slot:bottom>
              <div></div>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                <q-td key="tipo" class="text-center">
                  <q-icon
                    :name="returnIcons(props.row.tipo)"
                    size="24px"
                    :class="returnColors(props.row.tipo)"
                  ></q-icon>
                </q-td>
                <q-td key="liquidacao" class="text-center">
                  <span v-if="props.row.dtmovimento != null">
                    {{new Date(props.row.dtmovimento) | formatDate}}
                  </span>

                  <span v-else>
                    {{new Date(props.row.liquidacao) | formatDate}}
                  </span>

                  <q-tooltip v-if="props.row.liquidacao  != props.row.dtmovimento" >
                    Liquidação:  {{new Date(props.row.liquidacao) | formatDate}}
                  </q-tooltip>
                </q-td>
                <q-td key="descricao" :title="props.row.favorecido" class="text-left">
                  <span v-if="props.row.favorecido"> {{returnDescricao(props.row.favorecido)}}<br/></span>
                  <span @click="getDetalhamento(props.row.descricao, props.row.dtmovimento, props.row.id)" style="cursor:pointer;" v-if="props.row.descricao && props.row.tipo === 'Transferência'">{{returnDescricao(props.row.descricao)}}</span>
                  <span v-else-if="props.row.descricao">{{returnDescricao(props.row.descricao)}}</span>
                  <q-tooltip v-if="props.row.descricao && props.row.descricao.length > 40" >
                      {{props.row.descricao}}
                    </q-tooltip>
                  <q-tooltip v-if="props.row.favorecido && props.row.favorecido.length > 40" >
                      {{props.row.favorecido}}
                    </q-tooltip>
                </q-td>
                <q-td key="entrada" class="text-right">
                  <span v-if="props.row.entrada != 0">
                    <q-icon v-if="props.row.conciliado"
                      name="done_all"
                      size="24px"
                      color="green"
                      >
                    </q-icon>
                    R$ {{ props.row.entrada | currency }}
                  </span>
                </q-td>
                <q-td key="saida" class="text-right">
                  <span v-if="props.row.saida != 0">
                    <q-icon v-if="props.row.conciliado"
                      name="done_all"
                      size="24px"
                      color="green"
                      >
                    </q-icon>
                    R$ {{ props.row.saida | currency }}
                  </span>
                </q-td>
                <q-td
                  key="saldo"
                  class="text-right"
                  v-bind:class="{ 'text-red': props.row.saldo < 0 }"
                  >
                  R$ {{ props.row.saldo | currency }}
                </q-td>
                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div
                      class="divdentro q-gutter-sm"
                      style="margin-right: 10px"
                    >
                      <q-btn
                        v-if="['Transferência', 'Sangria', 'Reforço'].includes(props.row.tipo)"
                        icon="request_quote"
                        size="12px"
                        color="teal"
                        @click="detalharNotas(props.row)"
                      />
                      <q-btn
                        v-if="props.row.gateway_id"
                        @click="vaiPraReceita(props.row)"
                        size="12px"
                        class="bg-primary text-white"
                        icon="info"
                      ></q-btn>
                      <q-btn
                        v-if="exibirEditar(props.row)"
                        @click="conciliarDesconciliar(props.row)"
                        size="12px"
                        :class="props.row.conciliado ? 'bg-warning text-white' : 'bg-primary text-white' "
                        class=""
                        :icon="props.row.conciliado ? 'remove_done' : 'done_all' "
                      ></q-btn>
                      <q-btn
                        v-if="exibirEditar(props.row)"
                        @click="editarExtrato(props.row)"
                        size="12px"
                        class="bg-positive text-white"
                        icon="edit"
                      ></q-btn>
                      <q-btn
                        v-if="props.row.id && props.row.tipo === 'Saldo'"
                        @click="excluirSaldo(props.row)"
                        size="12px"
                        class="bg-negative text-white"
                        icon="delete"
                      ></q-btn>
                      <q-btn
                        v-if="props.row.tipo === 'Transferência'"
                        @click="cancelarTransferencia(props.row)"
                        size="12px"
                        class="bg-negative text-white"
                        icon="delete"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      v-if="['Transferência', 'Sangria', 'Reforço'].includes(props.row.tipo)"
                      icon="request_quote"
                      size="12px"
                      color="teal"
                      @click="detalharNotas(props.row)"
                    />
                    <q-btn
                      v-if="props.row.gateway_id"
                      @click="vaiPraReceita(props.row)"
                      size="12px"
                      class="bg-primary text-white"
                      icon="info"
                    />
                    <q-btn
                      v-if="exibirEditar(props.row)"
                      @click="conciliarDesconciliar(props.row)"
                      size="12px"
                      :class="props.row.conciliado ? 'bg-warning text-white' : 'bg-primary text-white' "
                      class=""
                      :icon="props.row.conciliado ? 'remove_done' : 'done_all' "
                    />
                    <q-btn
                      v-if="exibirEditar(props.row)"
                      @click="editarExtrato(props.row)"
                      size="12px"
                      class="bg-positive text-white"
                      icon="edit"
                    />
                    <q-btn
                      v-if="props.row.id && props.row.tipo === 'Saldo'"
                      @click="excluirSaldo(props.row)"
                      size="12px"
                      class="bg-negative text-white"
                      icon="delete"
                    />
                    <q-btn
                      v-if="props.row.tipo === 'Transferência'"
                      @click="cancelarTransferencia(props.row)"
                      size="12px"
                      class="bg-negative text-white"
                      icon="delete"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:top="props">
              <div class="col-2 q-table__title">Extrato Bancário</div>
              <q-space />
              <q-toggle
                left-label
                label="Detalhado"
                :true-value="1"
                :false-value="0"
                :disable="loadingExtrato"
                v-model="filtersExtrato.detalhado"
              />
              <date-picker></date-picker>
              <q-btn
                v-if="$q.screen.gt.xs"
                dense
                flat
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="filtrosExtratoDrp.status"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Tipos</q-item-section>
                    </q-item>
                    <q-item
                     :class="{ active_status: filtrosExtratoDrp.status === key }"
                     v-for="(key, val) in status"
                     :key = "key + val"
                     clickable
                     v-close-popup
                     aria-hidden="true"
                     @click="changeFilterStatus(key, val)"
                    >
                    <q-item-section>
                      {{ key }}
                    </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
              <q-btn
                v-if="$q.screen.gt.xs"
                dense
                flat
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="tipoExtrato"
                class="q-ml-sm q-px-md bg-white text-blue-7"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                        >Tipo de Conta</q-item-section
                      >
                    </q-item>
                    <q-item
                      :class="{ active_status: conta.titulo === tipoExtrato }"
                      v-for="(conta, index) in contasExtrato"
                      :key="index"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="trocaConta(conta)"
                    >
                      <q-item-section>{{ conta.titulo }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
              <q-btn
                flat
                no-caps
                color="primary"
                label="Controle de Moedas"
                @click="detalharContaMoedas()"
                v-if="controleMoedasInstalado"
              />
              <q-btn
                v-if="hasVideo"
                dense
                flat
                color="bg-primary text-blue-7"
                @click="modalIntro"
                icon="ondemand_video"
                style="margin-right: 10px"
                >
                <template v-slot:default>
                  <q-tooltip>Introdução Extrato Bancário</q-tooltip>
                </template>
              </q-btn>
              <q-btn
                style="margin-right: 10px"
                flat
                dense
                icon="print"
                class="text-blue-7"
                clickable
                @click="imprimir()"
                :loading="loadingImprimir"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
                <template v-slot:default>
                  <q-tooltip>Imprimir Relatório</q-tooltip>
                </template>
              </q-btn>
              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                rounded
                no-wrap
                v-ripple
                class="q-ml-sm q-px-md bg-white text-black"
                no-caps
                icon="more_vert"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                        >Mais Opções</q-item-section
                      >
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="listar()"
                    >
                      <q-item-section>Atualizar</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </template>
            <template v-slot:bottom-row v-if="filtrosExtratoDrp.status !== 'Todos' && (totalEntrada != 0 || totalSaida != 0)">
              <q-td></q-td>
              <q-td></q-td>
              <q-td></q-td>
              <q-td class="text-right">
                <strong v-if="totalEntrada != 0">
                 R$ {{ totalEntrada | currency }}
                </strong>
              </q-td>
              <q-td class="text-right">
                <strong v-if="totalSaida != 0">
                 R$ {{ totalSaida | currency }}
                </strong>
              </q-td>
            </template>
          </q-table>
          <div class="col-12 col-md-12 text-center" v-if="loadingExtrato">
            <br />
            <q-spinner color="blue-7" size="3em" />
          </div>
        </div>
      </div>
      <q-page-sticky position="top-right" :offset="[0, 90]">
        <q-btn
          :color="OpenOpcoesAvancadasExtrato ? 'red-7' : 'blue-7'"
          :icon="OpenOpcoesAvancadasExtrato ? 'close' : 'settings'"
          class="rotate-0"
          style="border-top-left-radius: 50px;border-bottom-left-radius: 50px"
          @click="
            $store.commit(
              'OpenOpcoesAvancadasExtrato',
              !OpenOpcoesAvancadasExtrato
            )
          "
        >
          <q-tooltip
            :content-class="
              OpenOpcoesAvancadasExtrato
                ? 'bg-negative text-white text-center'
                : 'bg-secondary text-white text-center'
            "
            >{{
              OpenOpcoesAvancadasExtrato
                ? "Fechar Opções Avançadas"
                : "Opções Avançadas"
            }}</q-tooltip
          >
        </q-btn>
      </q-page-sticky>
      <q-drawer
        side="right"
        v-model="OpenOpcoesAvancadasExtrato"
        bordered
        :width="280"
        :breakpoint="500"
        behavior="default"
        content-class="bg-white"
      >
        <q-scroll-area class="fit">
          <div class="q-pa-sm">
            <div class="q-table__title">Opções Avançadas</div>
            <div>
              <q-list
                class="rounded-borders"
                style="max-width: 350px; margin-left: -10px;width: 300px;"
              >
                <q-item-label header></q-item-label>

                <q-item clickable v-ripple @click="conciliacao()">
                  <q-item-section>
                    <q-item-label lines="1">Conciliação</q-item-label>
                    <q-item-label caption
                      >Realiza a conciliação bancária</q-item-label
                    >
                  </q-item-section>
                </q-item>

                <q-item clickable v-ripple @click="openFechamentoBancario()">
                  <q-item-section>
                    <q-item-label lines="1">Fechamento Bancário</q-item-label>
                    <q-item-label caption>Gerenciar fechamentos bancários</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-ripple @click="openSaldoInicial()">
                  <q-item-section>
                    <q-item-label lines="1">Saldo Inicial</q-item-label>
                    <q-item-label caption>Define o saldo inicial</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-ripple @click="openDataBloqueio()">
                  <q-item-section>
                    <q-item-label lines="1">Data de Bloqueio</q-item-label>
                    <q-item-label caption>Define a data de bloqueio</q-item-label>
                  </q-item-section>
                </q-item>

              </q-list>
            </div>
          </div>
        </q-scroll-area>
      </q-drawer>
    </div>
    <modal-saldo-inicial />
    <modal-nova-transferencia />
    <modal-editar-extrato />
    <modal-data-bloqueio />
    <modal-detalhar-notas />
    <modal-fechamento-bancario />
    <modal-detalhar-moedas-conta />
    <modal-detalhamento-caixa :movconta-id="movcontaDetalhamentoId" />
  </q-page>
</template>

<script>
import { openURL } from "quasar";
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { GChart } from 'vue-google-charts';
import moment from "moment";
import { bus } from "@/main";
import modalDetalhamentoCaixa from '../components/Extrato/modal-detalhamento-caixa.vue';

export default {
  components: { modalDetalhamentoCaixa, GChart },
  name: "extrato",
  meta: {
    title: "DSB | Extrato Bancário"
  },
  data() {
    return {
      graficoFluxoDeCaixaOptions: {
        colors: ['#4CAF50'],
        legend: { position: 'top' },
        vAxis: { minValue: 0, format: 'R$ ' },
      },
      pagination: {
        sortBy: "nome",
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      columns: [
        {
          name: "tipo",
          required: true,
          label: "Tipo",
          align: "center",
          field: row => row.tipo,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "liquidacao",
          required: true,
          align: "center",
          label: "Data",
          field: row => row.liquidacao,
          sortable: true
        },
        {
          name: "gateway_id",
          required: true,
          align: "center",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true
        },
        {
          name: "entrada",
          required: true,
          align: "center",
          label: "Entrada",
          field: row => row.entrada,
          sortable: true
        },
        {
          name: "saida",
          required: true,
          align: "center",
          label: "Saída",
          field: row => row.saida,
          sortable: true
        },
        {
          name: "saldo",
          required: true,
          align: "center",
          label: "Saldo",
          field: row => row.saldo,
          sortable: true
        },
        { name: "tdFora" }
      ],
      contasPermitidas: [],
      tipoExtrato: "Conta",
      status: {
        todos: "Todos",
        receita: "Receita",
        despesa: "Despesa",
        transferencia: "Transferência",
        reforco: "Reforço",
        sangria: "Sangria",
      },
      show: false,
      clique: false,
      loadingImprimir: false,
      dataTransferencia: moment().format("DD/MM/YYYY"),
      dtini: moment().format("DD/MM/YYYY"),
      fluxoDeCaixaParametros: {
        referencia: moment().format("MM/YYYY"),
        conta: null,
      },
      totalEntrada: 0,
      totalSaida: 0,
      hasVideo: false,
      movcontaDetalhamentoId: null
    };
  },
  watch: {
    contasExtrato() {
      this.contasExtrato.forEach(conta => {
        if (conta.id === this.filtersExtrato.conta_id) {
          this.tipoExtrato = conta.titulo;
        }
      });
    },
    "filtersExtrato.detalhado"() {
      if (!this.loadingExtrato) {
        this.$store.dispatch("listarExtrato", this.filtersExtrato);
      }
    },
  },
  methods: {
    changeTransferencia(){
      let filters = {
        dtiniSaldoFinal: moment(this.dtini, "DD/MM/YYYY").format("DD-MM-YYYY")
      };

      this.$store.dispatch('saldoExtrato', filters);
      this.dataTransferencia = this.dtini;
      this.$refs.qDateProxy.hide();
    },
    returnIcons(value) {
      switch (value) {
        case "Saldo":
          return "bookmark";
          break;
        case "Receita":
          return "add_circle_outline";
          break;
        case "Despesa":
          return "remove_circle_outline";
          break;
        case "Transferência":
          return "swap_horiz";
          break;
        case "Reforço":
          return "monetization_on";
          break;
        case "Sangria":
          return "monetization_on";
          break;
      }
    },
    returnColors(value) {
      switch (value) {
        case "Saldo":
          return "color7";
          break;
        case "Receita":
          return "color2";
          break;
        case "Despesa":
          return "color3";
          break;
        case "Transferência":
          return "color4";
          break;
        case "Reforço":
          return "color5";
          break;
        case "Sangria":
          return "color1";
          break;
      }
    },
    returnDescricao(value) {
      if (value.length > 40) {
        return value.substr(0, 40) + "...";
      }
      return value;
    },
    vaiPraReceita(value) {
      if (value.gateway_id) {
        const liquidacao = moment(value.liquidacao, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );

        const filtros = {
          busca: "",
          dtini: liquidacao,
          dtfim: liquidacao,
          status: "Liquidado",
          gateway_id: value.gateway_id,
          liquidacao: "1"
        };

        const routeData = this.$router.resolve({
          name: "receitas",
          query: filtros
        });
        window.open(routeData.href, "_blank");
      }
    },
    returnTotal() {
      let total = 0;
      this.contasExtrato.forEach(item => {
        total += item.saldo;
      });
      let val = (total / 1).toFixed(2).replace(".", ",");

      if (total < 0) {
        return (
          '<span class="red-text">R$ ' +
          val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
          "</span>"
        );
      }
      return (
        "<span>R$ " +
        val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
        "</span>"
      );
    },
    trocaConta(conta) {
      if (!conta.id) return;

      let filtro = this.filtersExtrato;

      filtro.dtiniSaldoFinal =  moment(this.dtini, "DD-MM-YYYY").format("DD-MM-YYYY");
      filtro.conta_id = conta.id;

      this.fluxoDeCaixaParametros.conta = conta.id;

      this.tipoExtrato = conta.titulo;
      this.$store.dispatch("resetExtrato");
      this.$store.dispatch("listarExtrato", filtro);
      this.$store.dispatch("getFluxoDeCaixa", this.fluxoDeCaixaParametros );
    },
    openTransferir() {
      this.$store.dispatch("contas");
      this.$store.commit("OpenDialogNovaTransferencia", true);
    },
    listar() {
      this.$store.dispatch("resetExtrato");
      this.$store.dispatch("listarExtrato", this.filtersExtrato);
    },
    openSaldoInicial() {
      this.$store.dispatch("contas");
      this.$store.dispatch("setOpenDialogSaldoInicial", true);
    },
    openDataBloqueio() {
      if(this.dataEmpresa.assinatura.plano !== "Premium") {
        this.$store.dispatch("toggleUpgradePlano", true);
        return;
      }
      this.$store.dispatch("setOpenDialogDataBloqueio", true);
    },
    editarExtrato(extrato) {
      let url = "/financeiro/edit/";

      if (extrato.id) {
        url += extrato.id;
      }

      const params = {
        gateway_id: extrato.gateway_id,
        liquidacao: extrato.liquidacao
      };

      axios
        .get(url, { params })
        .then(res => {
          if (!res.data.success) {
            throw res.data;
          }

          res.data.movconta.agrupado = extrato.agrupada;
          res.data.movconta.liquidacao = moment(
            res.data.movconta.liquidacao,
            "YYYY/MM/DD"
          ).format("DD/MM/YYYY");

          if(res.data.movconta && res.data.movconta.dtmovimento){
            res.data.movconta.dtmovimento = moment(
              res.data.movconta.dtmovimento,
              "YYYY/MM/DD"
            ).format("DD/MM/YYYY");
          }

          this.$store.commit("extratoItem", res.data.movconta);
          this.$store.commit("OpenDialogEditarExtrato", true);
          this.$store.dispatch("contas");
          this.$store.dispatch("planoContas", parseInt(res.data.movconta.tipo) === 1 ? "D" : "C");
        })
        .catch(() => {
          this.$q.notify({
            message: "Houve um erro ao visualizar o extrato",
            color: "red",
            position: "top-right"
          });
        });
    },
    conciliarDesconciliar(extrato) {

      let msgPergunta = "Conciliar o lançamento?";
      if (extrato.conciliado){
        msgPergunta = "Desconciliar o lançamento?";
      }

      this.$q
        .dialog({
          title: "Confirma",
          message: msgPergunta,
          cancel: true,
          persistent: false
        })
        .onOk(() => {
          axios
            .post("/financeiro/conciliar-manual", extrato)
            .then(res => {
              this.$q.notify({
                message: res.data.message,
                color: res.data.success ? "green" : "red",
                position: "top-right"
              });

              this.$store.dispatch("resetExtrato");
              this.$store.dispatch("listarExtrato", this.filtersExtrato);
            })
            .catch(() => {
              this.$q.notify({
                message: "Erro ao realizar a operação!",
                color: "red",
                position: "top-right"
              });
            });
        });
    },
    excluirSaldo(saldo) {
      this.financeiroDeleteRequest(
        saldo.id,
        "Deseja realmente excluir este saldo inicial?",
        "Houve um erro ao excluir o saldo inicial"
      );
    },
    cancelarTransferencia(transferencia) {
      this.financeiroDeleteRequest(
        transferencia.id,
        "Deseja realmente cancelar esta transferência?",
        "Houve um erro ao excluir a transferência!"
      );
    },
    financeiroDeleteRequest(id, msgPergunta, msgErro) {
      this.$q
        .dialog({
          title: "Confirmar",
          message: msgPergunta,
          cancel: true,
          persistent: true
        })
        .onOk(() => {
          axios
            .post("/financeiro/delete/" + id)
            .then(res => {
              this.$q.notify({
                message: res.data.message,
                color: res.data.success ? "green" : "red",
                position: "top-right"
              });

              this.$store.dispatch("resetExtrato");
              this.$store.dispatch("listarExtrato", this.filtersExtrato);
            })
            .catch(() => {
              this.$q.notify({
                message: msgErro,
                color: "red",
                position: "top-right"
              });
            });
        });
    },
    exibirEditar(row) {
      return row.tipo !== "Saldo" && (row.id || row.agrupada);
    },
    imprimir() {
      this.loadingImprimir = true;

      axios
        .post(
          "/financeiro/extrato/" + this.filtersExtrato.conta_id,
          {
            tipo: "imprimir",
            filtro: this.filtrosExtratoDrp.status,
          },
          {
            headers: {
              accept: "text/html, application/json"
            },
            params: {
              dtini: this.filtersExtrato.dtini,
              dtfim: this.filtersExtrato.dtfim,
              detalhado: this.filtersExtrato.detalhado,
            }
          }
        )
        .then(res => {
          this.loadingImprimir = false;
          let openWindow = window.open("/");
          openWindow.document.write(res.data);
        })
        .catch(() => {
          this.loadingImprimir = false;

          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "extrato");
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais || this.buscandoUser) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "extrato");

      if (!this.videosManuais.video_extrato) {
        this.modalIntro();
      }
    },
    detalharNotas(movconta) {
      bus.$emit("abrirModalDetalharNotas", movconta);
    },
    detalharContaMoedas() {
      const contaId = this.filtersExtrato.conta_id;

      if (!contaId) return;

      bus.$emit("abrirModalDetalharMoedas", contaId);
    },
    openFechamentoBancario() {
      bus.$emit("abrirModalFechamentoBancario");
    },
    conciliacao() {
      if(this.dataEmpresa.assinatura.plano !== "Premium") {
        this.$store.dispatch("toggleUpgradePlano", true);
        return;
      }
      this.$router.push({path: '/arquivos?tipo=extrato'});
    },
    getDetalhamento(descricao, data, id) {
      if (id === undefined) return;

      axios
        .get(
          "/financeiro/detalhamento/" + id,
          {
            params: {
              descricao: descricao,
              data: data,
            }
          }
      )
      .then (res => {
        if (res.data.success) {
          this.movcontaDetalhamentoId = id;
          this.$store.dispatch("setExtratoDetalhamento", res.data.data.itens);
          this.$store.commit("extratoDetalhamentoAgrupado", res.data.data.agrupados);
          this.$store.dispatch("setOpenDialogDetalhamento", true);
          this.$store.dispatch("setExtratoDetalhamentoTotal", res.data.data.titulo);
          this.$store.commit("sangriasDetalhamento", res.data.data.sangrias);
          this.$store.commit("transferenciaDetalhamento", res.data.data.transferencia);
        }
      })
      .catch(() => {
        //colocar um catch
         this.$q.notify({
            message: "Houve um erro ao visualizar o detalhamento do caixa",
            color: "red",
            position: "top-right"
          });
      });
    },
    changeMonth(value) {
      let referencia = moment(this.fluxoDeCaixaParametros.referencia, "MM/YYYY");

      if (value === "prev") {
        referencia.subtract(1, "months");
      } else {
        referencia.add(1, "months");
      }

      this.fluxoDeCaixaParametros.referencia = referencia.format("MM/YYYY");
      this.$store.dispatch("getFluxoDeCaixa", this.fluxoDeCaixaParametros );

      // let query = {};
      // query.dtini = referencia.format("DD-MM-YYYY");
      // query.dtfim = referencia.endOf('month').format("DD-MM-YYYY");
      // this.$store.dispatch("fetchUser", query);
    },

    changeFilterStatus(status, value){

      let filtro = {
        status: status,
        value: value,
      };
      this.$store.commit("filtrosExtratoDrp", filtro);
    },
    getExtratoFiltrado() {
       let selectedExtratos = this.extrato;
       this.totalEntrada = 0;
       this.totalSaida = 0;

      switch (this.filtrosExtratoDrp.status) {
        case 'Transferência':
          selectedExtratos = selectedExtratos.filter(extrato => extrato.tipo == "Saldo" || extrato.tipo == "Transferência");
          if(selectedExtratos.length > 1){
            this.totalEntrada = selectedExtratos.map(selectedExtratos => selectedExtratos.entrada).reduce((prev, next) => prev + next);
            this.totalSaida = selectedExtratos.map(selectedExtratos => selectedExtratos.saida).reduce((prev, next) => prev + next);
          }
          break;
        case 'Receita':
          selectedExtratos = selectedExtratos.filter(extrato => extrato.tipo == "Saldo" || extrato.tipo == "Receita");
          if(selectedExtratos.length > 1){
            this.totalEntrada = selectedExtratos.map(selectedExtratos => selectedExtratos.entrada).reduce((prev, next) => prev + next);
          }
          break;
        case 'Despesa':
          selectedExtratos = selectedExtratos.filter(extrato => extrato.tipo == "Saldo" || extrato.tipo == "Despesa");
          if(selectedExtratos.length > 1){
            this.totalSaida = selectedExtratos.map(selectedExtratos => selectedExtratos.saida).reduce((prev, next) => prev + next);
          }
          break;
        case 'Reforço':
          selectedExtratos = selectedExtratos.filter(extrato => extrato.tipo == "Saldo" || extrato.tipo == "Reforço");
          if(selectedExtratos.length > 1){
            this.totalSaida = selectedExtratos.map(selectedExtratos => selectedExtratos.saida).reduce((prev, next) => prev + next);
          }
          break;
        case 'Sangria':
          selectedExtratos = selectedExtratos.filter(extrato => extrato.tipo == "Saldo" || extrato.tipo == "Sangria");
          if(selectedExtratos.length > 1){
           this.totalEntrada = selectedExtratos.map(selectedExtratos => selectedExtratos.entrada).reduce((prev, next) => prev + next);
          }
          break;
      }

      return selectedExtratos;
    }
  },
  computed: {
    ...mapGetters([
      "dataEmpresa",
      "extrato",
      "contasExtrato",
      "filtersExtrato",
      "loadingExtrato",
      "loadingExtratoSaldo",
      "dataExtrato",
      "isPermisso",
      "controleMoedasInstalado",
      "extratoDetalhamento",
      "fluxoDeCaixa",
      "filtrosExtratoDrp",
      "videosManuais",
      "manuaisDisponiveis"
    ]),
    extratoFiltrado() {
      return this.getExtratoFiltrado();
    },
    OpenOpcoesAvancadasExtrato: {
      get() {
        return this.$store.getters.OpenOpcoesAvancadasExtrato;
      },
      set() {}
    },
    listaContasExtrato() {
      return this.contasExtrato.filter(conta => conta.id > 0 || conta.saldo != 0);
    },
    organizaDados() {
      let graficoDados = [['data', 'saldo']];
      this.fluxoDeCaixa.forEach(element => {

        const saldo = parseFloat(element.saldo);
        const entrada = parseFloat(element.entrada);
        const saida = parseFloat(element.saida);
        const descricao = element.descricao;

        graficoDados.push([
          descricao,
          {
            v:saldo,
            f:`${saldo.toLocaleString(
              'pt-BR',
              this.localeFormat
            )} \n Entrada: ${entrada} \n Saida: ${saida}`
          }
          ]);
      });
      return graficoDados;
    },
     returnMonth() {
      return moment(this.fluxoDeCaixaParametros.referencia, "MM/YYYY").format("MMM/YYYY");
    }
  },
  mounted() {
    this.$store.dispatch("resetExtrato");
    let filters = {
      dtiniSaldoFinal: moment(this.dtini, "DD-MM-YYYY").format("DD-MM-YYYY")
    };

    this.$store.commit("filtersExtrato", filters);
    this.$store.dispatch("listarExtrato", filters);
    this.$store.dispatch("saldoExtrato", filters);
    this.$store.dispatch("getFluxoDeCaixa", this.fluxoDeCaixaParametros);
    this.verificarPrimeiroVideo();
  }
};
</script>

<style lang="stylus" scoped>
.color1 {
  color: #008000;
}

.color2 {
  color: #34A853;
}

.color3 {
  color: #EA4335;
}

.color4 {
  color: #4285f4;
}

.color5 {
  color: #EA4335;
}

.color6 {
  color: #34A853;
}

.color7 {
  color: #808080;
}

</style>
