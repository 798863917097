<template>
  <div id="planoContasParametros">
    <div class="text-right">
      <q-btn
        flat
        round
        icon="description"
        color="primary"
        @click="openModalOptions(true)"
        >
        <q-tooltip>
          Configurar DRE - Demonstrativo Resultado Exercício
        </q-tooltip>
        </q-btn>

      <q-btn
        flat
        round
        class="q-ml-md"
        icon="settings"
        color="primary"
        @click="openModalOptions()">
        <q-tooltip>
          Outras configurações
        </q-tooltip>
        </q-btn>

    </div>

    <div class="row q-pa-md q-col-gutter-sm">

      <div class="col" v-if="loadingPlanodecontas">
        <div class="text-center">
          <q-spinner color="blue-7" size="3em" />
        </div>
      </div>

      <div class="col" v-else>
        <div
          v-if="planodecontas.length === 0"
          style="text-align: center"
        >
          <q-icon size="10em" name="info_outline" color="grey" />
          <br />
          <p>
            Não foi encontrado nenhum plano de conta. Deseja importar
            os planos de contas padrões?
          </p>
          <br />
          <q-btn
            no-wrap
            v-ripple
            label="Importar Planos de Contas"
            no-caps
            class="q-ml-sm q-px-md bg-positive text-white"
            clickable
            :loading="importando"
            @click="importaPadrao()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </div>
        <q-tree
          :nodes="planodecontas"
          node-key="id"
          label-key="numero"
          no-nodes-label=" "
          no-results-label=" "
        >
          <template v-slot:default-header="prop">
            <div class="row items-center" v-if="prop.node.label !== ''">
              <q-btn
                :outline="prop.node.tipoat === 'A' ? true : false"
                square
                :color="
                  prop.node.tipocd === 'C'
                    ? 'primary'
                    : prop.node.tipocd === 'D'
                    ? 'negative'
                    : 'grey-9'
                "
                :label="prop.node.numero"
                dense
                clickable
                class="text-weight-bold"
                style="min-width: 30px"
                @click="modalOpenEdit(prop.node)"
              />
              <div
                class="text-weight-bold"
                :class="
                  prop.node.tipocd === 'C'
                    ? 'text-primary'
                    : prop.node.tipocd === 'D'
                    ? 'text-negative'
                    : 'text-grey-9'
                "
                style="cursor: pointer"
              >
                &nbsp;{{ prop.node.label }}
              </div>
            </div>
          </template>
          <template v-slot:default-body="prop">
            <div style="margin-left: 17px">
              <q-btn
                round
                dense
                flat
                icon="add"
                class="text-blue-7"
                clickable
                @click="modalOpenEdit(novoItem(prop.node))"
              />
            </div>
          </template>
        </q-tree>
      </div>

      <q-dialog class="col" v-model="openEdit" v-if="nodeSelecionado">
        <q-card style="width: 500px">
          <q-card-section class="row items-center q-pb-lg">
            <div class="text-h6">Plano de Conta {{treePai}}</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
          </q-card-section>
          <q-card-section>
            <div class="row q-col-gutter-sm">
              <div class="col-12">
                <q-select
                  clearable
                  emit-value
                  map-options
                  label="Pai"
                  :options="optionsPlanosdecontasPai"
                  v-model="nodeSelecionado.pai"
                />
              </div>
               <div class="col-6 col-md-6" v-if="newFilho">
                <q-input
                  v-model="nodeSelecionado.numero"
                  label="Número"
                  bottom-slots
                  :error="errors.numero ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.numero"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
              <div :class=" newFilho ? 'col col-md-6' : 'col-12'">
                <q-input
                  v-model="nodeSelecionado.label"
                  label="Descrição"
                  bottom-slots
                  :error="errors.titulo ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.titulo"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <q-select
                  clearable
                  v-model="nodeSelecionado.tipocd"
                  :options="optionsCDN"
                  :option-value="opt => (opt === null ? null : opt.value)"
                  emit-value
                  map-options
                  bottom-slots
                  :error="errors.tipocd ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.tipocd"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <q-select
                  v-if="nodeSelecionado.tipocd === 'D'"
                  clearable
                  v-model="nodeSelecionado.tipovof"
                  :options="optionsVOF"
                  :option-value="opt => (opt ? opt.value : null)"
                  emit-value
                  map-options
                  label="Tipo"
                  dense
                  bottom-slots
                  :error="errors.tipovof ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.tipovof"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="q-gutter-sm">
                  <q-checkbox
                    v-model="nodeSelecionado.tipoat"
                    label="Totalizador"
                    color="green"
                    true-value="T"
                    false-value="A"
                  />
                </div>
              </div>
            </div>
            <q-card-actions align="right" class="text-primary">
                <q-btn
                v-if="newFilho"
                label="Excluir"
                color="red"
                text-color="white"
                @click="excluir()"
                class="q-ml-sm q-px-md bg-positive text-white "
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
              <q-btn
                label="Salvar"
                color="green"
                text-color="white"
                class="q-ml-sm q-px-md bg-positive text-white "
                @click="salvar()"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
            </q-card-actions>
          </q-card-section>
        </q-card>
      </q-dialog>

      <q-dialog v-model="modalOptions" persistent>
        <q-card style="max-width: 400px; width: 90%;">
          <q-card-section class="row items-center">
            <div class="text-h6">Opções</div>
            <q-space />
            <q-btn
              flat
              round
              dense
              icon="close"
              v-close-popup
              @click="closeModalOptions()"
            />
          </q-card-section>

          <q-separator />

          <q-card-section class="q-pa-md">
            <div class="row q-col-gutter-lg">

              <div class="col-12">
                <q-select
                  clearable
                  label="Plano de Contas de Acréscimo (Receitas)"
                  option-value="id"
                  option-label="titulo"
                  :options="optionsPlanodecontasCredito"
                  v-model="options.planodecontas_planodecontas_acrescimo"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <q-select
                  clearable
                  label="Plano de Contas de Desconto (Receitas)"
                  option-value="id"
                  option-label="titulo"
                  :options="optionsPlanodecontasDebito"
                  v-model="options.planodecontas_planodecontas_desconto"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <q-select
                  clearable
                  label="Plano de Contas de Acréscimo (Despesas)"
                  option-value="id"
                  option-label="titulo"
                  :options="optionsPlanodecontasDebito"
                  v-model="options.planodecontas_planodecontas_acrescimo_despesas"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <q-select
                  clearable
                  label="Plano de Contas de Desconto (Despesas)"
                  option-value="id"
                  option-label="titulo"
                  :options="optionsPlanodecontasCredito"
                  v-model="options.planodecontas_planodecontas_desconto_despesas"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <q-checkbox
                  true-value="1"
                  false-value="0"
                  color="green"
                  label="Utilizar reforço e sangria como categoria do DRE"
                  v-model="options.planodecontas_utilizar_reforco_sangria_dre"
                />
              </div>

              <div class="col-12">
                <q-checkbox
                  color="green"
                  true-value="1"
                  false-value="0"
                  label="Ao liquidar parcial, criar outra conta automaticamente"
                  v-model="options.movcontas_criar_movconta_valor_restante"
                />
              </div>

              <div class="col-12">
                <q-checkbox
                  color="green"
                  true-value="1"
                  false-value="0"
                  label="Ao liquidar parcial, criar outra conta obrigatoriamente"
                  v-model="options.movcontas_criar_movconta_valor_restante_obrigatorio"
                />
              </div>

            </div>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn
              label="Salvar"
              clickable
              v-ripple
              @click="salvarOpcoes()"
              :loading="salvandoOpcoes"
              color="green"
              text-color="white"
              style="margin-right: 22px; margin-bottom: 10px"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-dialog v-model="modalOptionsDRE" persistent>
        <q-card style="max-width: 600px; width: 90%;">
          <q-card-section class="row items-center">
            <div class="text-h6">Opções do DRE</div>
            <q-space />
            <q-btn
              flat
              round
              dense
              icon="close"
              v-close-popup
              @click="closeModalOptions()"
            />
          </q-card-section>

          <q-separator />

          <q-card-section class="q-pa-md">
            <div class="row q-col-gutter-lg">

              <div class="col-12">
                <strong>Receita Bruta das Vendas e Serviços, as deduções das vendas, os abatimentos e os impostos</strong>
              </div>

              <div class="col-12">
                <label>+ Venda de Produtos</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriaVendaProdutos"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <label>+ Venda de Loterias</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriaVendaLoterias"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <label>+ Comissões de Serviços</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriaVendaServicos"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <label>+ Receita Bruta</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasReceitaBruta"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <label>- Abatimentos da Receita Bruta</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasAbatimentoReceitaBruta"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <label>- Impostos da Receita Bruta</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasImpostosReceitaBruta"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <strong>= Receita Líquida das Vendas</strong>
              </div>

              <div class="col-12">
                - Custo da Venda de Produtos
                <small>(automático com base nas vendas de Produtos)</small>
              </div>

              <div class="col-12">
                - Custo da Venda de Loterias
                <small>(automático com base nas vendas de Loterias)</small>
              </div>

              <div class="col-12">
              <label>- Custos da Receita Líquida</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasCustosReceitaLiquida"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <label>- Impostos da Receita Líquida</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasImpostosReceitaLiquida"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <strong>= Lucro Bruto</strong>
              </div>

              <div class="col-12">
                <label>- Despesas com as Vendas</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasDespesasVendas"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <label>- Despesas Financeiras</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasDespesasFinanceiras"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <label>- Despesas Gerais, Administrativas e Outras Despesas Financeiras</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasDespesasGerais"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <strong>= Lucro ou Prejuízo Operacional</strong>
              </div>

              <div class="col-12">
                <label>+ Outras Receitas Financeiras</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasOutrasReceitasFinanceiras"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <label>- Outras Despesas Financeiras</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasOutrasDespesasFinanceiras"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <strong>= Resultado do Exercício Antes do Imposto de Renda</strong>
              </div>

              <div class="col-12">
                <label>- Provisão para o Imposto</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasProvisaoImposto"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <label>- Outras Despesas Financeiras Após a Dedução do Imposto de Renda</label>
                <q-select
                  use-chips
                  multiple
                  clearable
                  option-value="id"
                  option-label="titulo"
                  :options="planodecontasOptions"
                  v-model="options.planodecontas_categoriasOutrasDespesasAposDeducao"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

            </div>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn
              label="Salvar"
              clickable
              v-ripple
              @click="salvarOpcoes()"
              :loading="salvandoOpcoes"
              color="green"
              text-color="white"
              style="margin-right: 22px; margin-bottom: 10px"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";
export default {
  name: "plano-contas-parametros",
  data() {
    return {
      loading: false,
      newFilho: false,
      optionsCDN: [
        { label: "Crédito", value: "C" },
        { label: "Débito", value: "D" },
        { label: "Nulo", value: "N" }
      ],
      optionsAT: [
        { label: "Analitico", value: "A" },
        { label: "Totalizador", value: "T" }
      ],
      optionsVOF: [
        { label: "Variável", value: "V" },
        { label: "Operacional", value: "O" },
        { label: "Fixo", value: "F" },
        { label: "Nulo", value: "N" }
      ],
      errors: [],
      nodeSelecionado: null,
      openEdit: false,
      treePai: null,
      importando: false,
      modalOptions: false,
      modalOptionsDRE: false,
      options: {
        planodecontas_utilizar_reforco_sangria_dre: false,
        planodecontas_planodecontas_acrescimo: null,
        planodecontas_planodecontas_desconto: null,
        planodecontas_planodecontas_acrescimo_despesas: null,
        planodecontas_planodecontas_desconto_despesas: null,
        movcontas_criar_movconta_valor_restante: "0",
        planodecontas_categoriaVendaProdutos: "",
        planodecontas_categoriaVendaLoterias: "",
        planodecontas_categoriaVendaServicos: "",
        planodecontas_categoriasReceitaBruta: "",
        planodecontas_categoriasAbatimentoReceitaBruta: "",
        planodecontas_categoriasImpostosReceitaBruta: "",
        planodecontas_categoriasCustosReceitaLiquida: "",
        planodecontas_categoriasImpostosReceitaLiquida: "",
        planodecontas_categoriasDespesasVendas: "",
        planodecontas_categoriasDespesasFinanceiras: "",
        planodecontas_categoriasDespesasGerais: "",
        planodecontas_categoriasOutrasReceitasFinanceiras: "",
        planodecontas_categoriasOutrasDespesasFinanceiras: "",
        planodecontas_categoriasProvisaoImposto: "",
        planodecontas_categoriasOutrasDespesasAposDeducao: "",
      },
      salvandoOpcoes: false,
      loadingOptions: false,
      planodecontasOptions: [],
    };
  },
  methods: {
    modalOpenEdit(props) {
      this.openEdit = true;
      this.nodeSelecionado = props;
      this.newFilho = props.label;
      this.treePai =  props.label === '' ? ' - ' + props.nomePai : ' - ' + props.label;
    },
    novoItem(valor) {
      let novoNode = {
        nomePai: valor.label,
        id: null,
        label: "",
        tipocd: "",
        tipoat: "A",
        pai: valor.id,
        tipovof: "",
        adicionar: true,
        editar: false,
        children: []
      };

      return novoNode;
    },
    salvar() {
      this.loading = true;
      this.errors = [];

      let url = "/plano-contas/add";
      if (this.nodeSelecionado.id) {
        url = "/plano-contas/edit/" + this.nodeSelecionado.id;
      }

      let tipovof = "N";
      //tipoVOF só aparece para Débito por ser um Custo.
      if (this.nodeSelecionado.tipocd === "D") {
        tipovof = this.nodeSelecionado.tipovof;
      }
      //caso o pai seja crédito, o filho deve ser credito ou nulo
      //caso o pai seja débito, o filho deve ser debito ou nulo
      // if (this.nodeSelecionado.tipocd !== this.nodeSelecionado.tipocd && this.nodeSelecionado.tipocd !== "N") {
      //   this.nodeSelecionado.tipocd = node.tipocd;
      // }
      let dados = {
        pai: this.nodeSelecionado.pai,
        titulo: this.nodeSelecionado.label,
        tipocd: this.nodeSelecionado.tipocd,
        tipoat: this.nodeSelecionado.tipoat,
        tipovof: tipovof,
        numero: this.nodeSelecionado.numero
      };

      axios
        .post(url, dados)
        .then(res => {
          if (res.data.success) {
            this.nodeSelecionado = null;

            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            this.$store.dispatch("planodecontas");
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            this.errors = res.data.errors;
          }
          this.loading = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível adicionar o plano de contas, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.loading = false;
        });
    },
    excluir() {
      Dialog.create({
        title: "Tem certeza que deseja excluir o plano de contas?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post("/plano-contas/delete/" + this.nodeSelecionado.id)
            .then(res => {
              if (res.data.success) {
                this.nodeSelecionado = null;
                Notify.create({
                  message: res.data.message,
                  color: "green",
                  position: "top-right"
                });
              } else {
                Notify.create({
                  message: res.data.message,
                  color: "orange",
                  position: "top-right"
                });
              }
              this.$store.dispatch("planodecontas");
            })
            .catch(() => {
              Notify.create({
                message:
                  "Não foi possível excluir o plano de contas, ou você não possui permissão!",
                color: "red",
                position: "top-right"
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    importaPadrao() {
      this.importando = true;

      axios
        .post("/plano-contas/importar")
        .then(res => {
          this.importando = false;
          let color = "orange";

          if (res.data.success) {
            color = "green";
            this.$store.dispatch("planodecontas");
          }

          this.$q.notify({
            color: color,
            message: res.data.message,
            position: "top-right",
          });
        })
        .catch(() => {
          this.importando = false;
          this.$q.notify({
            color: "red",
            message: "Houve um erro ao importar os planos de contas",
            position: "top-right",
          });
        });
    },
    openModalOptions(isDRE = false) {
      if (this.loadingOptions) return;

      this.loadingOptions = true;

      axios
        .get('/plano-contas/options')
        .then(res => {
          this.loadingOptions = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          const { opcoes } = res.data;

          if (opcoes.planodecontas_planodecontas_acrescimo) {
            opcoes.planodecontas_planodecontas_acrescimo = this.optionsPlanodecontasCredito.find(
              planodeconta => planodeconta.id === parseInt(opcoes.planodecontas_planodecontas_acrescimo)
            );
          }
          if (opcoes.planodecontas_planodecontas_desconto) {
            opcoes.planodecontas_planodecontas_desconto = this.optionsPlanodecontasDebito.find(
              planodeconta => planodeconta.id === parseInt(opcoes.planodecontas_planodecontas_desconto)
            );
          }
          if (opcoes.planodecontas_planodecontas_acrescimo_despesas) {
            opcoes.planodecontas_planodecontas_acrescimo_despesas = this.optionsPlanodecontasDebito.find(
              planodeconta => planodeconta.id === parseInt(opcoes.planodecontas_planodecontas_acrescimo_despesas)
            );
          }
          if (opcoes.planodecontas_planodecontas_desconto_despesas) {
            opcoes.planodecontas_planodecontas_desconto_despesas = this.optionsPlanodecontasCredito.find(
              planodeconta => planodeconta.id === parseInt(opcoes.planodecontas_planodecontas_desconto_despesas)
            );
          }

          opcoes.planodecontas_categoriaVendaProdutos = opcoes.planodecontas_categoriaVendaProdutos
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriaVendaLoterias = opcoes.planodecontas_categoriaVendaLoterias
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriaVendaServicos = opcoes.planodecontas_categoriaVendaServicos
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasReceitaBruta = opcoes.planodecontas_categoriasReceitaBruta
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasAbatimentoReceitaBruta = opcoes.planodecontas_categoriasAbatimentoReceitaBruta
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasImpostosReceitaBruta = opcoes.planodecontas_categoriasImpostosReceitaBruta
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasCustosReceitaLiquida = opcoes.planodecontas_categoriasCustosReceitaLiquida
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasImpostosReceitaLiquida = opcoes.planodecontas_categoriasImpostosReceitaLiquida
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasDespesasVendas = opcoes.planodecontas_categoriasDespesasVendas
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasDespesasFinanceiras = opcoes.planodecontas_categoriasDespesasFinanceiras
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasDespesasGerais = opcoes.planodecontas_categoriasDespesasGerais
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasOutrasReceitasFinanceiras = opcoes.planodecontas_categoriasOutrasReceitasFinanceiras
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasOutrasDespesasFinanceiras = opcoes.planodecontas_categoriasOutrasDespesasFinanceiras
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasProvisaoImposto = opcoes.planodecontas_categoriasProvisaoImposto
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );
          opcoes.planodecontas_categoriasOutrasDespesasAposDeducao = opcoes.planodecontas_categoriasOutrasDespesasAposDeducao
            .split(',')
            .filter(Boolean)
            .map(id =>
              this.planodecontasOptions.find(planodeconta => planodeconta.id === parseInt(id))
            );

          this.options = opcoes;

          if (!isDRE) {
            this.modalOptions = true;
          } else {
            this.modalOptionsDRE = true;
          }
        })
        .catch(() => {
          this.loadingOptions = false;
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    closeModalOptions() {
      this.modalOptions = false;
      this.modalOptionsDRE = false;
    },
    salvarOpcoes() {
      this.salvandoOpcoes = true;

      const options = JSON.parse(JSON.stringify(this.options));

      if (options.planodecontas_planodecontas_acrescimo && options.planodecontas_planodecontas_acrescimo.id) {
        options.planodecontas_planodecontas_acrescimo = options.planodecontas_planodecontas_acrescimo.id;
      }
      if (options.planodecontas_planodecontas_desconto && options.planodecontas_planodecontas_desconto.id) {
        options.planodecontas_planodecontas_desconto = options.planodecontas_planodecontas_desconto.id;
      }
      if (options.planodecontas_planodecontas_acrescimo_despesas && options.planodecontas_planodecontas_acrescimo_despesas.id) {
        options.planodecontas_planodecontas_acrescimo_despesas = options.planodecontas_planodecontas_acrescimo_despesas.id;
      }
      if (options.planodecontas_planodecontas_desconto_despesas && options.planodecontas_planodecontas_desconto_despesas.id) {
        options.planodecontas_planodecontas_desconto_despesas = options.planodecontas_planodecontas_desconto_despesas.id;
      }

      options.planodecontas_categoriaVendaProdutos = options.planodecontas_categoriaVendaProdutos
        ? options.planodecontas_categoriaVendaProdutos.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriaVendaLoterias = options.planodecontas_categoriaVendaLoterias
        ? options.planodecontas_categoriaVendaLoterias.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriaVendaServicos = options.planodecontas_categoriaVendaServicos
        ? options.planodecontas_categoriaVendaServicos.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasReceitaBruta = options.planodecontas_categoriasReceitaBruta
        ? options.planodecontas_categoriasReceitaBruta.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasAbatimentoReceitaBruta = options.planodecontas_categoriasAbatimentoReceitaBruta
        ? options.planodecontas_categoriasAbatimentoReceitaBruta.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasImpostosReceitaBruta = options.planodecontas_categoriasImpostosReceitaBruta
        ? options.planodecontas_categoriasImpostosReceitaBruta.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasCustosReceitaLiquida = options.planodecontas_categoriasCustosReceitaLiquida
        ? options.planodecontas_categoriasCustosReceitaLiquida.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasImpostosReceitaLiquida = options.planodecontas_categoriasImpostosReceitaLiquida
        ? options.planodecontas_categoriasImpostosReceitaLiquida.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasDespesasVendas = options.planodecontas_categoriasDespesasVendas
        ? options.planodecontas_categoriasDespesasVendas.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasDespesasFinanceiras = options.planodecontas_categoriasDespesasFinanceiras
        ? options.planodecontas_categoriasDespesasFinanceiras.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasDespesasGerais = options.planodecontas_categoriasDespesasGerais
        ? options.planodecontas_categoriasDespesasGerais.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasOutrasReceitasFinanceiras = options.planodecontas_categoriasOutrasReceitasFinanceiras
        ? options.planodecontas_categoriasOutrasReceitasFinanceiras.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasOutrasDespesasFinanceiras = options.planodecontas_categoriasOutrasDespesasFinanceiras
        ? options.planodecontas_categoriasOutrasDespesasFinanceiras.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasProvisaoImposto = options.planodecontas_categoriasProvisaoImposto
        ? options.planodecontas_categoriasProvisaoImposto.map(item => item.id).join(',')
        : '';
      options.planodecontas_categoriasOutrasDespesasAposDeducao = options.planodecontas_categoriasOutrasDespesasAposDeducao
        ? options.planodecontas_categoriasOutrasDespesasAposDeducao.map(item => item.id).join(',')
        : '';

      axios
        .post('/plano-contas/options', options)
        .then(res => {
          this.salvandoOpcoes = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right'
          });

          if (!res.data.success) {
            return;
          }

          this.closeModalOptions();
        })
        .catch(() => {
          this.salvandoOpcoes = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar as opções, tente novamente!',
            color: 'red',
            position: 'top-right'
          });
      });
    },
    loadPlanodecontas() {
      axios
        .get('/plano-contas')
        .then(res => {
          if (!res.data.success) return;

          this.planodecontasOptions = res.data.planodecontas;
        });
    },
  },
  computed: {
    ...mapGetters([
      "planodecontas",
      "tiposcd",
      "tiposat",
      "tiposvof",
      "loadingPlanodecontas",
    ]),
    optionsPlanodecontasCredito() {
      return this.planodecontasOptions.filter(planodecontas => planodecontas.tipocd === 'C');
    },
    optionsPlanodecontasDebito() {
      return this.planodecontasOptions.filter(planodecontas => planodecontas.tipocd === 'D');
    },
    optionsPlanosdecontasPai() {
      return this.planodecontasOptions.map(planodeconta => ({
        label: planodeconta.titulo,
        value: planodeconta.id,
      }));
    },
  },
  mounted() {
    this.$store.dispatch("planodecontas");
    this.loadPlanodecontas();
  }
};
</script>

<style lang="stylus" scoped></style>
