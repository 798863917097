<template>
  <div id="modalAdicionarRemoverEstoque">
    <q-dialog v-model="openVendaProdutosSemEstoque" persistent>
      <q-card style="width: 450px; max-width: 80vw;">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">Opções Avançadas</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />

        <q-card-section>
          <q-toggle
            v-model="opcoes.estoque_venda_sem_estoque"
            label="Possibilita Vendas sem Controle de Estoque da Empresa e do Vendedor"
          />
          <q-toggle
            v-show="!opcoes.estoque_venda_sem_estoque"
            v-model="opcoes.estoque_operador_utilizar_estoque_empresa"
            label="Possibilita Vendas diretamente do Estoque da Empresa"
          />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="salvar"
            :loading="loading"
            class="text-white bg-positive"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from '@/axios-auth';
import { mapGetters } from "vuex";
const moment = require("moment");

export default {
  name: "modal-opcoes-avancadas-estoque",
  props: ["tipoDialogEstoque", "tipo"],
  data() {
    return {
      loading: false,
      opcoes: {},
    };
  },
  methods: {
    closeModal() {
      this.$store.dispatch("toggleModalVendaProdutosSemEstoque", false);
    },
    loadOpcoes() {
      axios.get("/produtos/opcoes-estoque")
        .then(res => {
          // console.log(res)
          if (res.error && res.error.reponse.status == 403){
            return ;
          }
          if (!res.data.success) {
            throw res.data;
          }

          this.opcoes = res.data.opcoes;
        })
        .catch(err => {
          // console.log(err)
          // this.$q.notify({
          //   message:"Não foi possível listar as opções!",
          //   color: "red",
          //   position: "top-right",
          // });
        });
    },
    salvar() {
      this.loading = true;

      axios
        .post("/produtos/opcoes-estoque", this.opcoes)
        .then(res => {
          this.loading = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right",
            });
            return;
          }
          this.opcoes = res.data.opcoes;

          this.$q.notify({
            message: res.data.message,
            color: "green",
            position: "top-right",
          });
          this.closeModal();
        })
        .catch(() => {
          this.loading = false;

          this.$q.notify({
            message:"Houve um erro ao salvar as opções!",
            color: "red",
            position: "top-right",
          });
        });
    },
  },
  computed: {
    ...mapGetters([
      "openVendaProdutosSemEstoque",
    ]),
  },
  mounted() {
    this.loadOpcoes();
  },
};
</script>

<style lang="stylus" scoped>
</style>
