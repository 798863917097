<template>
  <div id="modalNovoExtrato">
    <q-dialog v-model="modalFechamentoBancario" persistent>

      <q-card style="width: 100%; max-width: 1000px;">

        <q-card-section class="row items-center">
          <q-btn
            flat
            round
            icon="add"
            color="primary"
            @click="openModalAddExtratoConferencia()"
          />
          <div class="text-h6">Fechamento Bancário</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="q-pa-md">
            <q-table
              flat
              row-key="id"
              no-data-label="Nenhum dado"
              loading-label="Carregando dados"
              no-results-label="Nenhum resultado obtido"
              :columns="columns"
              :data="movcontasConferencias"
              :pagination.sync="pagination"
              :loading="loadingMovcontasConferencias"
            >
              <template v-slot:bottom></template>
              <template v-slot:body="props">
                <q-tr :props="props">

                  <q-td key="nome" class="text-center">{{ props.row.nome }} <br> {{ props.row.created | formatDateTime }}</q-td>

                  <q-td key="dtfim" class="text-center">{{ new Date(props.row.dtfim) | formatDate }}</q-td>

                  <q-td key="moeda" class="text-right">R$ {{ props.row.moeda | currency }}</q-td>

                  <q-td key="dinheiro" class="text-right">R$ {{ props.row.dinheiro | currency }}</q-td>

                  <q-td key="cheque" class="text-right">R$ {{ props.row.cheque | currency }}</q-td>

                  <q-td key="cofre" class="text-right">R$ {{ props.row.cofre | currency }}</q-td>

                  <q-td key="valor_total" class="text-right">
                    <span :class="{
                      'text-green' : props.row.valor_total > props.row.valor_sistema,
                      'text-red' : props.row.valor_total < props.row.valor_sistema,
                    }">
                      R$ {{ props.row.valor_total | currency }}
                    </span>
                    <q-tooltip v-if="props.row.justificativa">{{ props.row.justificativa }}</q-tooltip>
                  </q-td>

                  <q-td key="valor_sistema" class="text-right">R$ {{ props.row.valor_sistema | currency }}</q-td>

                  <q-td class="tdfora">
                    <div class="divfora">
                      <div class="divdentro q-gutter-sm" style="margin-right: 10px">

                        <q-btn
                          @click="excluirExtratoConferencia(props.row)"
                          color="negative"
                          icon="delete_forever"
                          size="12px"
                        ></q-btn>

                      </div>
                    </div>
                  </q-td>

                </q-tr>
              </template>
            </q-table>

            <div class="text-right" v-show="movcontasConferencias.length">
              <q-btn
                flat
                clickable
                class="bg-primary text-white"
                style="margin-right: 22px; margin-bottom: 10px"
                :loading="loadingMovcontasConferencias"
                :disable="!paging.nextPage || loadingMovcontasConferencias"
                :label="`Carregar Mais ${paging.page}/${paging.pageCount}`"
                @click="loadMovcontasConferencias()"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
            </div>

          </div>
        </q-card-section>

      </q-card>

    </q-dialog>

    <q-dialog v-model="modalAddExtratoConferencia" persistent>

      <q-card style="width: 400px !important">

        <q-card-section class="row items-center">
          <div class="text-h6">Fechamento Bancário</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModalAddExtratoConferencia()" />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="q-pa-md">
            <div class="row q-col-gutter-x-md">

              <div class="col-12">
                <q-select
                  clearable
                  emit-value
                  map-options
                  bottom-slots
                  ref="conta"
                  label="Conta"
                  option-value="id"
                  option-label="titulo"
                  :options="contas"
                  :error="errors.conta_id ? true : false"
                  v-model="movcontasConferencia.conta_id"
                  @input="pulaCampo('moeda')"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.conta_id" :key="error + i">{{error}}</span>
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12 col-md-6" @keydown.enter.prevent="pulaCampo('dinheiro')">
                <q-field
                  bottom-slots
                  label="Moeda"
                  v-model="movcontasConferencia.moeda"
                  :error="errors.moeda ? true : false"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      ref="moeda"
                      class="q-field__native text-right"
                      :id="id"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    />
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.moeda" :key="error + i">{{ error }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-12 col-md-6" @keydown.enter.prevent="pulaCampo('cheque')">
                <q-field
                  bottom-slots
                  label="Dinheiro"
                  v-model="movcontasConferencia.dinheiro"
                  :error="errors.dinheiro ? true : false"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      ref="dinheiro"
                      class="q-field__native text-right"
                      :id="id"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    />
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.dinheiro" :key="error + i">{{ error }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-12 col-md-6" @keydown.enter.prevent="pulaCampo('cofre')">
                <q-field
                  bottom-slots
                  label="Cheque"
                  v-model="movcontasConferencia.cheque"
                  :error="errors.cheque ? true : false"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      ref="cheque"
                      class="q-field__native text-right"
                      :id="id"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    />
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.cheque" :key="error + i">{{ error }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-12 col-md-6">
                <q-field
                  bottom-slots
                  label="Cofre"
                  v-model="movcontasConferencia.cofre"
                  :error="errors.cofre ? true : false"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      ref="cofre"
                      class="q-field__native text-right"
                      :id="id"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    />
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.cofre" :key="error + i">{{ error }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-12">
                <q-checkbox
                  color="green"
                  label="Bloquear Lançamentos"
                  v-model="movcontasConferencia.bloquear_lancamentos"
                />
              </div>

              <div class="col-12">
                <q-input
                  label="Até"
                  bottom-slots
                  @click="$refs.qDateProxy.show();"
                  v-model="movcontasConferencia.data_bloqueio"
                  :error="errors.data_bloqueio ? true : false"
                  :readonly="!movcontasConferencia.bloquear_lancamentos"
                  :disable="!movcontasConferencia.bloquear_lancamentos"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          minimal
                          today-btn
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          :readonly="!movcontasConferencia.bloquear_lancamentos"
                          :disable="!movcontasConferencia.bloquear_lancamentos"
                          v-model="movcontasConferencia.data_bloqueio"
                          @input="() => $refs.qDateProxy.hide()"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.data_bloqueio" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>

              <div class="col-12">
                <q-field
                  disable
                  readonly
                  bottom-slots
                  label="Valor Total"
                  :value="valorTotalMovcontasConferencia"
                  :error="errors.valor_total ? true : false"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      disable
                      readonly
                      class="q-field__native text-right"
                      :id="id"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    />
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.valor_total" :key="error + i">{{ error }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-12">
                <q-field
                  disable
                  readonly
                  bottom-slots
                  label="Valor no Sistema"
                  v-model="movcontasConferencia.valor_sistema"
                  :error="errors.valor_sistema ? true : false"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      disable
                      readonly
                      class="q-field__native text-right"
                      :id="id"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    />
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.valor_sistema" :key="error + i">{{ error }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-12">
                <q-checkbox
                  color="green"
                  label="Lançar como Transferência"
                  v-model="movcontasConferencia.lancar_transferencia"
                />
                <q-tooltip>Dessa forma o valor será apresentado apenas no extrato</q-tooltip>
              </div>

            </div>
          </div>
        </q-card-section>

         <q-card-actions align="right" class="text-primary">
          <q-btn
            no-wrap
            no-caps
            v-ripple
            clickable
            color="green"
            label="Salvar"
            style="margin: 0 20px 20px 0"
            :loadingSalvar="loadingSalvar"
            @click="salvarMovcontasConferencia()"
          >
            <template v-slot:loadingSalvar>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>

      </q-card>

    </q-dialog>

  </div>
</template>

<script>
import axios from '@/axios-auth';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { Loading  } from 'quasar';
import { Money } from 'v-money';
import { bus } from '@/main';

export default {
  name: 'modal-fechamento-bancario',
  components: { Money },
  data() {
    return {
      modalFechamentoBancario: false,
      loadingMovcontasConferencias: false,
      movcontasConferencias: [],
      columns: [
        {
          name: 'nome',
          label: 'Criado por',
          align: 'center',
        },
        {
          name: 'dtfim',
          label: 'Bloqueio',
          align: 'center',
          field: row => row.dtfim,
        },
        {
          name: 'moeda',
          label: 'Moeda',
          align: 'center',
          field: row => row.moeda,
        },
        {
          name: 'dinheiro',
          label: 'Dinheiro',
          align: 'center',
          field: row => row.dinheiro,
        },
        {
          name: 'cheque',
          label: 'Cheque',
          align: 'center',
          field: row => row.cheque,
        },
        {
          name: 'cofre',
          label: 'Cofre',
          align: 'center',
          field: row => row.cofre,
        },
        {
          name: 'valor_total',
          label: 'Valor Informado',
          align: 'center',
          field: row => row.valor_total,
        },
        {
          name: 'valor_sistema',
          label: 'Valor no Sistema',
          align: 'center',
          field: row => row.valor_sistema,
        },
        { name: 'tdfora' },
      ],
      pagination: {
        sortBy: 'dtfim',
        descending: true,
        page: 1,
        rowsPerPage: 0,
      },
      paging: {
        page: 0,
        nextPage: true,
      },
      modalAddExtratoConferencia: false,
      errors: {},
      loadingSalvar: false,
      movcontasConferencia: {
        id: null,
        conta_id: null,
        moeda: 0.00,
        dinheiro: 0.00,
        cheque: 0.00,
        cofre: 0.00,
        valor_sistema: 0.00,
        justificativa: '',
        bloquear_lancamentos: false,
        data_bloqueio: moment().format('DD/MM/YYYY'),
      },
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    loadMovcontasConferencias(reset = false) {
      if (!reset && !this.paging.nextPage) return;
      if (reset) {
        this.movcontasConferencias = [];
        this.paging = {
          page: 0,
          nextPage: true,
        };
      }

      this.paging.page++;
      this.loadingMovcontasConferencias = true;

      axios
        .get('/financeiro/extrato-conferencia', { params: this.paging })
        .then(res => {
          this.loadingMovcontasConferencias = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.movcontasConferencias.push(...res.data.movcontas_conferencias);
          this.paging = res.data.paging;
        })
        .catch(() => {
          this.loadingMovcontasConferencias = false;
          this.$q.notify({
            message: 'Houve um erro ao carregar conferências do extrato',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    closeModal() {
      this.modalFechamentoBancario = false;

      this.conta = null;
      this.dataVenc = moment().format('DD/MM/YYYY');
      this.errors = {};
      this.valor = 0;
    },
    openModalAddExtratoConferencia() {
      this.movcontasConferencia = {
        id: null,
        conta_id: null,
        moeda: 0.00,
        dinheiro: 0.00,
        cheque: 0.00,
        cofre: 0.00,
        valor_sistema: 0.00,
        justificativa: '',
        bloquear_lancamentos: false,
        lancar_transferencia: false,
        data_bloqueio: moment().format('DD/MM/YYYY'),
      };
      this.errors = {};

      this.modalAddExtratoConferencia = true;
    },
    closeModalAddExtratoConferencia() {
      this.modalAddExtratoConferencia = false;
    },
    salvarMovcontasConferencia() {
      const addMovcontasConferencia = (justificativa = null) => {
        const dados = JSON.parse(JSON.stringify(this.movcontasConferencia));
        dados.confirmar = true;
        dados.justificativa = justificativa;

        axios
          .post('/financeiro/add-extrato-conferencia', dados)
          .then(res => {
            this.loadingSalvar = false;

            this.$q.notify({
              message: res.data.message,
              color: res.data.success ? 'green' : 'orange',
              position: 'top-right',
            });

            if (!res.data.success) {
              this.errors = res.data.errors || [];
              return;
            }

            this.closeModalAddExtratoConferencia();
            this.loadMovcontasConferencias(true);

            if (res.data.movconta_diferenca) {
              const resTipo = res.data.movconta_diferenca.tipo;
              let tipo;
              if (resTipo === 1) tipo = 'despesa';
              else if (resTipo === 2) tipo = 'receita';
              else if (resTipo === 5) tipo = 'transferência';

              const valor = parseFloat(res.data.movconta_diferenca.valor_liquidado)
                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

              this.$q.dialog({
                title: `Nova ${tipo} gerada`,
                message: `Devido a diferença de valores, uma nova ${tipo} foi gerada no valor de R$ ${valor}`,
              });
            }
          })
          .catch(() => {
            this.loadingSalvar = false;

            this.$q.notify({
              message: 'Houve um erro ao adicionar a conferência do extrato',
              color: 'red',
              position: 'top-right',
            });
          });
      };

      if (this.loadingSalvar) return;

      this.loadingSalvar = true;

      if (parseFloat(this.movcontasConferencia.valor_sistema) === parseFloat(this.valorTotalMovcontasConferencia)) {
        addMovcontasConferencia();
        return;
      }

      this.$q.dialog({
        title: 'Informe a justificativa',
        cancel: 'Cancelar',
        persistent: true,
        prompt: {
          type: 'text',
          model: '',
          isValid: val => val && String(val).trim() !== '' && String(val).trim().length >= 5,
        },
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(addMovcontasConferencia)
        .onCancel(() => {
          Loading.hide();
          this.loadingSalvar = false;
        })
        .onDismiss(() => {
          Loading.hide();
          this.loadingSalvar = false;
        });
    },
    loadValorSistema() {
      if (!this.movcontasConferencia.conta_id) return;

      const dados = {
        conta_id: this.movcontasConferencia.conta_id,
      };

      axios
        .post('/financeiro/add-extrato-conferencia', dados)
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.movcontasConferencia.valor_sistema = res.data.valor_sistema;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar o valor do sistema',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    excluirExtratoConferencia(extratoConferencia) {
      const palavraConfirmacao = 'compreendo os riscos';

      this.$q.dialog({
        title: `Conferência de Extrato - ${extratoConferencia.id}`,
        message: `Ao excluir esse registro o saldo da sua conta bancária será modificado.
          Digite <strong style="color: #EA4335;">"${palavraConfirmacao}"</strong> para continuar`,
        cancel: 'Cancelar',
        persistent: true,
        html: true,
        prompt: {
          type: 'text',
          model: '',
          isValid: val => val === palavraConfirmacao,
        },
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(`/financeiro/delete-extrato-conferencia/${extratoConferencia.id}`)
            .then(res => {
              const { success } = res.data;

              if (res.error && res.error.response.status === 403) {
                this.$q.notify({
                  message: res.error.response.data.message,
                  color: 'orange',
                  position: 'top-right',
                });
                return;
              }

              this.$q.dialog({
                title: res.data.message,
                message: res.data.info || null,
                class: `bg-${success ? 'positive' : 'warning'} text-white`,
                ok: {
                  'text-color': success ? 'green' : 'orange',
                  color: 'white',
                },
              });

              if (!success) return;

              this.loadMovcontasConferencias(true);
            })
            .catch(() => {
              this.$q.dialog({
                title: 'Houve um erro ao excluir a conferência de extrato',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white',
                },
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    pulaCampo(campo, segundos = null) {
      const focar = () => {
        if (!this.$refs[campo]) {
          return;
        }

        if (this.$refs[campo].show) {
          return this.$refs[campo].show();
        }

        if (this.$refs[campo].$el.classList.contains("v-money")){
          return this.$refs[campo].$el.focus();
        }

        return this.$refs[campo].focus();
      };

      if (segundos) {
        return setTimeout(() => focar(), segundos);
      }

      focar();
    },
    criadoEm(nome, data) {
      return nome + ' em ' + moment(data).format('DD/MM/YYYY HH:mm');
    }
  },
  computed: {
    ...mapGetters(['contas']),
    valorTotalMovcontasConferencia() {
      return parseFloat(this.movcontasConferencia.moeda) + parseFloat(this.movcontasConferencia.dinheiro)
        + parseFloat(this.movcontasConferencia.cheque) + parseFloat(this.movcontasConferencia.cofre);
    },
  },
  watch: {
    modalFechamentoBancario(value) {
      if (!value) return;
      this.loadMovcontasConferencias(true);
    },
    'movcontasConferencia.conta_id'() {
      this.loadValorSistema();
    },
  },
  mounted(){
    this.$store.dispatch('contas');

    bus.$on('abrirModalFechamentoBancario', () => {
      this.modalFechamentoBancario = true;
    });
  },
  destroyed() {
    bus.$off('abrirModalFechamentoBancario');
  },
};
</script>

<style lang="stylus" scoped></style>
