<template>
  <div id="centroCustosParametros">
     <div class="text-right">
        Exibindo
        <strong>{{centroCustos.length}}</strong> itens.
    </div>
    <q-table
      title
      :data="centroCustos"
      :columns="columns"
      row-key="id"
      :separator="separator"
      :loading="listandoCentroCustos"
      no-data-label="Nenhum resultado encontrado."
      :pagination.sync="pagination"
    >
      <template v-slot:bottom>
        <div></div>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" class="text-right">{{props.row.id}}</q-td>
          <q-td key="nome">{{props.row.nome}}</q-td>
          <q-td class="tdfora">
            <div class="divfora">
              <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                <q-btn
                  @click="editCentroCusto(props.row)"
                  size="12px"
                  class="bg-primary text-white"
                  icon="edit"
                ></q-btn>
                <q-btn
                  @click="deleteCentroCusto(props.row)"
                  size="12px"
                  class="bg-negative text-white"
                  icon="delete_forever"
                ></q-btn>
              </div>
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Dialog, Loading, format } from "quasar";

export default {
  name: "centro-custos-parametros",
  data() {
    return {
      separator: "horizontal",
      pagination: {
        rowsPerPage: 0
      },
      columns: [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: row => row.id,
          sortable: true
        },
        {
          name: "nome",
          label: "Nome",
          field: row => row.nome,
          sortable: true,
          align: "left"
        },
        {
          name: "tdFora",
          label: "",
        },
      ],
      counter: 0
    };
  },
  methods: {
    deleteCentroCusto(value) {
      Dialog.create({
        title: "Tem certeza que deseja excluir este centro de custo?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post("/centrocustos/delete/" + value.id)
            .then(res => {
              if (res.data.success) {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
                this.$store.dispatch("listarCentroCustos");
              } else {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
              }
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
              Loading.hide();
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    editCentroCusto(value) {
      this.$store.dispatch("editarCentroCustoGet", value);
    },
    capitalize(value) {
      return format.capitalize(value);
    },
  },
  computed: {
    ...mapGetters([
      "centroCustos",
      "pagingCentroCustos",
      "totalCentroCustos",
      "listandoCentroCustos"
    ])
  },
  mounted() {
    this.$store.dispatch("listarCentroCustos");
  }
};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
