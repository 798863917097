<template>
  <div :class="col ? 'col col-md-12' : ''">
    <q-select
      :clearable="!notClearable"
      use-input
      autocomplete="off"
      v-model="itemSelecionado"
      :loading="carregando"
      :label="title"
      :options="options"
      :option-label="value => (value === null ? 'Item Vazio' : display(value))"
      :option-value="value => (value === null ? null : value)"
      @filter="filter"
      @input="emitEvento"
      @new-value="create"
      new-value-mode="add-unique"
      bottom-slots
      name="autocompleted"
      ref="autocompletedComponent"
      :disable="disable"
      :error="errors && Object.keys(errors).length > 0"
    >
      <template v-slot:option="scope">
        <q-list>
          <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
            <q-item-section>
              <q-item-label v-html="formatDisplay(scope.opt)"></q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </template>
      <template v-slot:hint> Pressione <b>ENTER</b> para selecionar </template>
      <template v-slot:error>
        <span v-for="(error, i) in errors" :key="i">{{ error }}</span>
      </template>
    </q-select>
    <template
      v-if="
        itemSelecionado &&
          itemSelecionado.id == null &&
          newCadastro === null &&
          tipoConsulta === 'cadastro'
          || (itemSelecionado && itemSelecionado.canEdit)
      "
    >
      <div class="novoCadastro" style="margin-top: 20px">
        Novo cadastro - <b> {{ itemSelecionado.name }} </b> <br />Caso queira
        alterá-lo,
        <span class="link" @click="cadastro(itemSelecionado)">clique aqui</span
        >.
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "@/main";

export default {
  name: "autocomplete",
  props: [
    "title",
    "filter",
    "options",
    "carregando",
    "create",
    "formatDisplay",
    "display",
    "tipoConsulta",
    "errors",
    "cadastro",
    "newCadastro",
    "reset",
    "pularProx",
    "pularProxChama",
    "col",
    "disable",
    "notClearable",
    "inputDebounce",
  ],
  data() {
    return {
      itemSelecionado: null
    };
  },
  methods: {
    emitEvento() {
      this.$emit("resultAutocomplete", this.itemSelecionado);

      if (this.reset) {
        this.clear();
      }

      if (this.pularProxChama) {
        this.pularProx();
      }
    },
    clear() {
      this.itemSelecionado = null;
    }
  },
  mounted() {
    this.$root.$on("limpar", () => {
      this.clear();
    });
    bus.$on("updateAutocompleteField", data => {
      this.itemSelecionado = { ...this.itemSelecionado, ...data };
    });
  }
};
</script>
<style lang="stylus" scoped>
.novoCadastro {
  padding: 15px;
  background-color: #fff3e0;
  border-left: 5px solid #ffa726;
  margin-bottom: 15px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
