<template>
  <q-dialog v-model="openDialogProdutosTags" persistent>
    <q-card style="width: 500px !important">
      <q-card-section class="row items-center">
        <div class="text-h6">Tags - {{ produto.nome }}</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
      </q-card-section>
      <q-card-section>
        <div v-if="produtosTags.length === 0">
          <p class="text-center text-grey-7 q-mt-md">Nenhuma tag adicionada</p>
        </div>
        <div v-else class="flex justify-start">
          <div v-for="produtoTag in produtosTags" :key="produtoTag.id">
            <q-chip
              :label="produtoTag.tp.title"
              color="secondary"
              removable
              @remove="removeTag(produtoTag)"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-section>
        <p class="text-weight-medium text-grey-10">Adicionar Tag</p>
        <div class="row q-col-gutter-md">
          <div class="col-12">
            <q-select 
              v-model="produtoTag"
              label="Tag"
              hint="Pressione ENTER para criar uma nova tag"
              use-input
              use-chips
              map-options
              option-label="title"
              option-value="id"
              :options="filteredTags"
              @input="addTag"
              @new-value="createTag"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
      </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify, Dialog } from 'quasar';
import axios from '@/axios-auth';

export default {
  props: ["section"],
  data() {
    return {
      produtoTag: null,
    };
  },
  methods: {
    addTag(tag) {
      if (!tag || !tag.id) return;

      axios
        .post(`/produtos/tags/${this.produto.id}`, {
          action: "add",
          tag_id: tag.id,
        })
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "red",
              position: "top-right"
            });
            return;
          }

          this.$store.commit("produtosTags", res.data.produtosTags);
          this.$store.commit("produtosAllTags", res.data.produtosAllTags);
          this.produtoTag = null;

          Notify.create({
            message: "Tag adicionada com sucesso!",
            color: "green",
            position: "top-right"
          });
        });
    },
    createTag(value, done) {
      done(value);

      axios
        .post(`/produtos/tags/${this.produto.id}`, {
          title: value,
          action: "create"
        })
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "red",
              position: "top-right"
            });
            return;
          }

          this.$store.commit("produtosTags", res.data.produtosTags);
          this.$store.commit("produtosAllTags", res.data.produtosAllTags);
          this.produtoTag = null;
          this.newTag = {
            title: "",
            color: "#00d66c"
          };

          Notify.create({
            message: "Tag criada com sucesso!",
            color: "green",
            position: "top-right"
          });
        })
        .catch(error => {
            Notify.create({
              message: "Não foi possível criar a tag",
              color: "red",
              position: "top-right"
            });
        });
    },
    removeTag(tag) {
      Dialog.create({
        title: "Remover Tag",
        message: "Deseja remover a tag ou apenas a associação com o produto?",
        options: {
          type: "radio",
          model: "action",
          items: [
            { label: "Apenas a associação", value: "remove_association" },
            { label: "Remover tag", value: "delete" }
          ]
        },
        ok: "Confirmar",
      })
      .onOk((action) => {
        if (!action) return;
        this.removeTagAction(tag, action);
      });
    },
    removeTagAction(tag, action) {
      axios
        .post("/produtos/tags/" + this.produto.id, {
          tag_id: tag.tp.id,
          action: action
        })
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "red",
              position: "top-right"
            });
            return;
          }

          this.$store.commit("produtosTags", res.data.produtosTags);
          this.$store.commit("produtosAllTags", res.data.produtosAllTags);
          this.produtoTag = null;

          Notify.create({
            message: "Tag removida com sucesso!",
            color: "green",
            position: "top-right"
          });
        });
    },
    closeModal() {
      this.openDialogProdutosTags = false;
      this.produtoTag = null;
      this.$store.commit("produto", []);
      this.$store.commit("produtosTags", []);
      this.updateItems();
    },
    updateItems() {
      if (this.section === "produtos") {
        this.$store.dispatch("filtrosProdutos", { tipo: "P", page: 1 });
      } else if (this.section === "loterias") {
        this.$store.dispatch("filtrosLoterias", { tipo: "L", page: 1 });
      } else if (this.section === "servicos") {
        this.$store.dispatch("filtrosServicos");
      }

      const dispatchMap = {
        produtos: { action: "filtrosProdutos", filters: { tipo: "P", page: 1 }},
        loterias: { action: "filtrosLoterias", filters: { tipo: "L", page: 1 }},
        servicos: { action: "filtrosServicos", filters: {} }
      };

      const dispatchAction = dispatchMap[this.section];
      if (dispatchAction) {
        this.$store.dispatch(dispatchAction.action, dispatchAction.filters);
      }
    }
  },
  computed: {
    ...mapGetters([
      "produto",
      "produtosTags",
      "produtosAllTags"
    ]),
    filteredTags() {
      return this.produtosAllTags.filter(tag => {
        return !this.produtosTags.some(produtoTag => {
          return produtoTag.tp.id === tag.id;
        });
      });
    },
    openDialogProdutosTags: {
      get() {
        return this.$store.getters.openDialogProdutosTags;
      },
      set(value) {
        this.$store.commit("openDialogProdutosTags", value);
      }
    },
  }
};
</script>

<style scoped>
.color-picker {
  max-width: 50px;
}
</style>
