<template>
  <div id="modalPlanilhaArquivo">
    <q-dialog
      v-model="OpenDialogPlanilhaArquivo"
      persistent
      full-width
      >
      <q-card>
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">{{arquivo.nome}}</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section class="row" style="gap: 32px;">
          <div>
            <div
              v-for="(error, val) in errors.colunas"
              :key="val + error"
            >
              <span class="text-red">{{error}}</span>
            </div>
          </div>

          <div>
            <div
              v-for="(error, val) in errors.cadastros"
              :key="val + error"
            >
              <span class="text-red">{{error}}</span>
            </div>
          </div>

          <div>
            <div
              v-for="(error, val) in errors.planodecontas"
              :key="val + error"
            >
              <span class="text-red">{{error}}</span>
            </div>
          </div>
        </q-card-section>

        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">

              <div class="col-12 col-md-6 col-lg-4">
                <q-select
                  emit-value
                  map-options
                  bottom-slots
                  label="Tipo de dados"
                  :options="optionsTipo"
                  :error="errors.tipo ? true : false"
                  v-model="tipo"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.tipo" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <q-select
                  emit-value
                  map-options
                  bottom-slots
                  label="A planilha possui cabeçalho?"
                  :options="optionsCabecalho"
                  :error="errors.cabecalho ? true : false"
                  v-model="cabecalho"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.cabecalho" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>

              <div class="col-12 col-md-12 col-lg-4" v-show="showUtilizarCodigo">
                <q-select
                  emit-value
                  map-options
                  bottom-slots
                  :options="tipoCodigo"
                  :error="errors.tipoCodigo ? true : false"
                  :label="'O Código é referente a qual coluna?'"
                  v-model="valueTipo"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.tipoCodigo" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>

            </div>
          </div>
        </q-card-section>

        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12">
                <table style="width: 100%">

                  <thead>
                    <tr>
                      <td v-for="(key, val) in countColunas" :key="val">
                        <q-select
                          label
                          bottom-slots
                          :options="colunas"
                          v-model="valueColunas[val]"
                        >
                          <template v-slot:no-option>
                            <q-item>
                              <q-item-section class="text-grey">Sem Resultados</q-item-section>
                            </q-item>
                          </template>
                        </q-select>
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(key, val) in planilha" :key="val">
                      <td v-for="(chave, valor) in key" :key="valor">{{chave}}</td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="salvar"
            :loading="salvando"
            class="bg-green text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from '@/axios-auth';

export default {
  name: 'modal-planilha-arquivo',
  props: [
    'planilha',
    'arquivo',
    'OpenDialogPlanilhaArquivo',
    'optionsTipo',
  ],
  data() {
    return {
      valueTipo: 'cliente',
      cabecalho: true,
      optionsCabecalho: [
        { value: '1', label: 'Sim' },
        { value: '0', label: 'Não' },
      ],
      tipoCodigo: [
        {
          'label': 'Cliente',
          'value': 'cliente',
        },
        {
          'label': 'Convênio',
          'value': 'convenio',
        },
        {
          'label': 'CPF/CNPJ',
          'value': 'cpfcnpj',
        },
      ],
      errors: [],
      salvando: false,
      tipo: null,
      valueColunas: [],
    };
  },
  methods: {
    closeModal() {
      this.$emit('onClose');
      this.limpaModal();
    },
    salvar() {
      if (this.salvando || !this.tipo) return;
      this.salvando = true;

      const colunas = [];
      for (let i = 0; i < this.countColunas; i++) {
        colunas[i] = this.valueColunas[i].value;
      }

      let dados = {
        cabecalho: this.cabecalho,
        tipo: this.tipo,
        tipoCodigo: this.valueTipo,
        colunas: colunas,
      };

      axios
        .post('/financeiro/importar/' + this.arquivo.id, dados)
        .then(res => {
          this.salvando = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.status ? 'green' : 'orange',
            position: 'top-right'
          });

          if (!res.data.status) {
            this.errors = res.data.errors || {};
            return;
          }

          this.closeModal();
        })
        .catch(() => {
          this.salvando = false;
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    limpaModal() {
      this.valueTipo = 'cliente';
      this.cabecalho = true;
      this.errors = [];
      this.tipo = null;
    },
  },
  computed: {
    colunas() {
      const option = this.optionsTipo.find(option => option.value === this.tipo);

      if (!option) return [];

      return option.colunas;
    },
    settings() {
      return {
        data: this.planilha,
        colHeaders: true,
        rowHeaders: false,
        editor: false
      };
    },
    countColunas() {
      if (this.planilha[0]) {
        return this.planilha[0].length;
      }
      return 0;
    },
    showUtilizarCodigo() {
      return ['recorrencias', 'receitas', 'despesas', 'despesas_eventuais', 'camposextras'].includes(this.tipo);
    },
  },
  watch: {
    colunas() {
      this.valueColunas = JSON.parse(JSON.stringify(this.colunas));
    },
  },
};
</script>

<style lang="stylus" scoped></style>
