<template>
  <div id="datePickerCaixas">
    <q-btn-group rounded flat no-wrap no-caps>
      <q-btn
        class="white text-blue-7"
        clickable
        @click="changeFilterPeriodo('prev')"
        rounded
        icon="chevron_left"
      />
      <q-btn class="white text-blue-7" flat :label="dtini">
        <template v-slot:default>
          <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
            <q-date
              mask="DD/MM/YYYY"
              :locale="langDate"
              minimal
              today-btn
              v-model="dtini"
              @input="(value) => enviaData(value)"
            />
          </q-popup-proxy>
        </template>
      </q-btn>
      <q-btn class="white text-blue-7" flat :label="dtfim">
        <template v-slot:default>
          <q-popup-proxy ref="qDateProxy2" transition-show="scale" transition-hide="scale">
            <q-date
              mask="DD/MM/YYYY"
              :locale="langDate"
              minimal
              today-btn
              v-model="dtfim"
              @input="(value) => enviaData2(value)"
            />
          </q-popup-proxy>
        </template>
      </q-btn>
      <q-btn
        class="white text-blue-7"
        clickable
        @click="changeFilterPeriodo('next')"
        rounded
        icon="chevron_right"
      />
    </q-btn-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const moment = require("moment");
export default {
  name: "date-picker-caixas",
  data() {
    return {
      dtini: null,
      dtfim: null
    };
  },
  methods: {
    enviaData(value) {
      this.filtrosCaixas.dtini = value.replace(/\//g, "-");
      this.filtrosCaixas.dtfim = this.dtfim.replace(/\//g, "-");
      this.filtrosCaixas.dtmovimento = null;
      this.$store.dispatch("filtrosCaixas", this.filtrosCaixas);
      this.buscaData();
      this.$refs.qDateProxy.hide();
    },
    enviaData2(value) {
      this.filtrosCaixas.dtini = this.dtini.replace(/\//g, "-");
      this.filtrosCaixas.dtfim = value.replace(/\//g, "-");
      this.filtrosCaixas.dtmovimento = null;
      this.$store.dispatch("filtrosCaixas", this.filtrosCaixas);
      this.buscaData();
      this.$refs.qDateProxy2.hide();
    },
    changeFilterPeriodo(userData) {
      // if (state.buscandoRec) return;

      this.filtrosCaixas.dtini = this.dtini.replace(/\//g, "-");
      this.filtrosCaixas.dtfim = this.dtfim.replace(/\//g, "-");

      let dtini = this.filtrosCaixas.dtini.split("-");
      let dtfim = this.filtrosCaixas.dtfim.split("-");

      let i = moment(new Date(dtini[2], dtini[1] - 1, dtini[0]));
      let f = moment(new Date(dtfim[2], dtfim[1] - 1, dtfim[0]));

      if (userData === "prev") {
        i.subtract(1, "months");
        f.subtract(1, "months");
      }
      if (userData === "next") {
        i.add(1, "months");
        f.add(1, "months");
      }
      this.filtrosCaixas.dtini = i.startOf("month").format("DD-MM-YYYY");
      this.filtrosCaixas.dtfim = f.endOf("month").format("DD-MM-YYYY");
      this.$store.commit("filtrosCaixas", this.filtrosCaixas);

      this.$store.dispatch("resetPagingCaixas");
      this.$store.dispatch("listarCaixas");
      this.buscaData();
    },
    async buscaData() {
      let data = await this.$store.getters.filtrosCaixas;
      this.dtini = data.dtini.replace(/-/g, "/");
      this.dtfim = data.dtfim.replace(/-/g, "/");
    }
  },
  computed: {
    ...mapGetters(["filtrosCaixas"])
  },
  mounted() {
    this.buscaData();
  }
};
</script>

<style scoped>
</style>
