<template>
  <div id="cardRelatorioTotalizadoresLoterica">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-h6">
            <q-icon style="position:relative; top: -3px" name="payment" />
            Saldo nas Contas Bancárias
          </div>
        </div>

      </div>
    </q-card-section>

    <q-card-section class="q-pt-none">
      <div>
        <q-markup-table
          dense
          flat
          separator="none"
          class="q-mb-md"
        >
          <tbody>
            <tr
              v-for="conta in contas"
              :key="conta.id"
            >
              <td>{{ conta.titulo }}</td>
              <td class="text-right">R$ {{ conta.saldo | currency }}</td>
            </tr>

            <tr class="text-center" v-if="!contas">
              <td>Não há conta bancária</td>
            </tr>
            <tr v-else class="text-weight-bold">
              <td>Total</td>
              <td class="text-right">R$ {{ totalContas | currency }}</td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>

      <div class="q-mx-md" v-if="saldoFinalCaixas != 0 || saldoFinalOperadores != 0">
        <q-separator class="q-mb-md" />

        <div
          class="flex justify-between"
          v-if="saldoFinalCaixas !== 0"
        >
          <span>Caixas</span>
          <strong>R$ {{ saldoFinalCaixas | currency }}</strong>
        </div>

        <div
          class="flex justify-between"
          v-if="saldoFinalOperadores !== 0"
        >
          <span>Operadores</span>
          <strong>R$ {{ saldoFinalOperadores | currency }}</strong>
        </div>

        <div
          class="flex justify-between q-mt-sm text-weight-bold"
          v-if="totalCaixasOperadores !== 0"
        >
          <span>Total Saldo nos Caixas + Operadores</span>
          <span>R$ {{ totalCaixasOperadores | currency }}</span>
        </div>
      </div>

    </q-card-section>
  </div>
</template>

<script>

export default {
  name: "card-relatorio-totalizadores-loterica",
  props: {
    totalizadores: {}
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    contas() {
      return this.totalizadores.contas;
    },
    saldoFinalCaixas() {
      return this.totalizadores.saldoFinalCaixas;
    },
    saldoFinalOperadores() {
      return this.totalizadores.saldoFinalOperadores;
    },
    totalCaixasOperadores() {
      const totalCaixasOperadores = this.saldoFinalCaixas + this.saldoFinalOperadores;

      return totalCaixasOperadores;
    },
    totalContas() {
      const totalContas = this.contas.reduce((total, conta) => total + conta.saldo, 0);

      return totalContas;
    },
  },
};
</script>

<style lang="stylus" scoped>
  #cardRelatorioTotalizadores
    height: 400px;
    overflow: auto;
</style>
