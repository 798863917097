var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{attrs:{"id":"caixa"}},[_c('div',{staticClass:"q-pa-md"},[_c('div',{staticClass:"q-col-gutter-md row"},[(_vm.loadMovcaixa)?_c('div',{staticClass:"col-12 col-md-12 text-center"},[_c('q-spinner',{attrs:{"color":"blue-7","size":"4em"}})],1):_vm._e(),(!_vm.loadMovcaixa)?_c('div',{staticClass:"col-12 text-center"},[_c('cabecalho-caixa')],1):_vm._e(),(!_vm.loadMovcaixa)?_c('div',{staticClass:"col-12 col-lg-8 col-md-10 offset-lg-2 offset-md-1"},[_c('q-card',[_c('q-card-section',[_c('div',{staticClass:"q-pa-m"},[_c('div',{staticClass:"q-col-gutter-md row"},[_c('div',{staticClass:"col-5 q-table__title"},[_c('q-btn',{attrs:{"flat":"","dense":"","round":"","icon":"arrow_back","clickable":""},on:{"click":function($event){return _vm.voltarPagina()}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('q-tooltip',[_vm._v("Voltar aos Caixas")])]},proxy:true}],null,false,2309102566)}),_vm._v(" "+_vm._s(_vm.movcaixa.caixa_nome)+" "),_c('q-badge',{class:{
                      'bg-positive': _vm.movcaixa.status === 'A',
                      'bg-negative': _vm.movcaixa.status === 'F',
                      'bg-warning': _vm.movcaixa.status === 'E'
                    }},[_vm._v(_vm._s(_vm.movcaixa.status === "A" ? "Aberto" : (_vm.movcaixa.status === "F" ? "Fechado": "Excluído" )))])],1),_c('div',{staticClass:"col-7 text-right"},[_c('q-btn',{staticClass:"text-blue-7",staticStyle:{"margin-right":"10px"},attrs:{"flat":"","dense":"","icon":"account_balance_wallet","clickable":""},on:{"click":_vm.importarSangrias},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('q-tooltip',[_vm._v("Importar sangrias")])]},proxy:true}],null,false,1564518292)}),_c('q-btn',{staticClass:"text-blue-7",staticStyle:{"margin-right":"10px"},attrs:{"flat":"","dense":"","icon":"update","clickable":""},on:{"click":_vm.atualizaProd},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('q-tooltip',[_vm._v("Importar produtos")])]},proxy:true}],null,false,95820466)}),_c('q-btn',{staticClass:"text-blue-7",staticStyle:{"margin-right":"10px"},attrs:{"flat":"","dense":"","icon":"print","clickable":"","loading":_vm.resumoPrinting},on:{"click":function($event){return _vm.imprimir(_vm.movcaixa)}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('q-spinner',{attrs:{"color":"grey-10","size":"1em"}})]},proxy:true},{key:"default",fn:function(){return [_c('q-tooltip',[_vm._v("Imprimir Relatório")])]},proxy:true}],null,false,3049840512)}),_c('q-btn',{staticClass:"text-blue-7",staticStyle:{"margin-right":"10px"},attrs:{"flat":"","dense":"","icon":"add_a_photo","clickable":""},on:{"click":function($event){return _vm.ativaDir()}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('q-tooltip',[_vm._v("Importar Resumo de TFL")])]},proxy:true}],null,false,2403687658)}),_c('q-btn',{class:{
                      'bg-primary text-white':
                        _vm.$route.path === '/caixas/' + _vm.movcaixa.id + '/resumo',
                      'text-blue-7': _vm.$route.path === '/caixas/' + _vm.movcaixa.id
                    },staticStyle:{"margin-right":"10px"},attrs:{"flat":"","dense":"","icon":"notes","clickable":""},on:{"click":function($event){return _vm.isResumo(_vm.movcaixa.id)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('q-tooltip',[_vm._v("Resumo de Caixa")])]},proxy:true}],null,false,1531275240)}),_c('q-btn',{staticClass:"text-orange-7",staticStyle:{"margin-right":"10px"},attrs:{"flat":"","dense":"","icon":"help_outline","clickable":""},on:{"click":function($event){return _vm.openHelp()}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('q-tooltip',[_vm._v("Ajuda")])]},proxy:true}],null,false,3489619571)}),_c('q-btn',{staticClass:"text-black",staticStyle:{"margin-right":"10px"},attrs:{"flat":"","dense":"","icon":_vm.isFullScreen ? 'fullscreen_exit' : 'fullscreen',"clickable":""},on:{"click":function($event){return _vm.openFullscreen()}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('q-tooltip',[_vm._v("Tela Cheia")])]},proxy:true}],null,false,2036312402)})],1),_c('div',{staticClass:"text-body2"},[_vm._v(" Lançamento Nº "),_c('strong',[_vm._v(_vm._s(_vm.movcaixa.id))]),_c('br'),_vm._v("Data movimento "),_c('strong',[_vm._v(_vm._s(_vm._f("formatDate")(new Date(_vm.movcaixa.dtmovimento))))]),_c('br'),_vm._v("Responsável "),_c('strong',[_vm._v(_vm._s(_vm.movcaixa.func_resp_nome)+",")]),_c('br'),_vm._v(" "+_vm._s(_vm.movcaixa.func_aber_id !== _vm.movcaixa.func_resp_id ? "Aberto por " + _vm.movcaixa.func_aber_nome : "")+" em "+_vm._s(_vm._f("formatDateTime")(_vm.movcaixa.created))+" ")])])])]),_c('q-card-section',[_c('router-view')],1)],1)],1):_vm._e()])]),_c('modal-upload-resumo-tfl',{ref:"uploadResumoTfl",attrs:{"title":"Enviar Resumo de TFL","movmentacao":false}}),_c('modal-upgrade-plano')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }