<template>
  <div id="modalManuais">
    <q-dialog v-model="openDialogManuais" persistent>
      <q-card v-if="manual" :style="`max-width: ${manual.tipo == 'video' ? '600px' : '400px'} !important; width: 100% !important;`" >
        <q-card-section class="flex justify-between">
          <div class="text-h6">{{ manual.titulo }}</div>

          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>

        <q-card-section v-if="showVideo" ref="youtubePlayer">
          <div>
            <youtube
              ref="youtube"
              style="width: 100%; max-width: 560px; max-height: 315px;"
              :video-id="videoId"
              :player-vars="playerVars"
            />
          </div>

          <!-- Timestamps -->
          <div v-if="extras.length > 0">
            <q-separator />
            <div class="text-h6 q-my-lg">Índices</div>
            <p v-for="(extra, index) in extras" :key="index" @click="goTo(extra.timestamp)" class="cursor-pointer text-body2">
              <span class="link">{{ extra.timestamp }} | {{ extra.label }}</span>
            </p>
          </div>

          <!-- Atalhos Caixa -->
          <q-list bordered padding class="rounded-borders q-mt-lg" v-if="manual.local == 'caixa'">
            <q-item-label header>Atalhos</q-item-label>

            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label lines="1">Saldo Inicial</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-item-label>CTRL + SHIFT + 1</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label lines="1">Reforço</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-item-label>CTRL + SHIFT + 2</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label lines="2">Compra</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-item-label>CTRL + SHIFT + 3</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label lines="2">Venda</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-item-label>CTRL + SHIFT + 4</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label lines="1">Recebimento Fiado</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-item-label>CTRL + SHIFT + 5</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label lines="1">Quitação de Contas</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-item-label>CTRL + SHIFT + 6</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label lines="1">Sangria</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-item-label>CTRL + SHIFT + 7</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label lines="1">Saldo Final</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-item-label>CTRL + SHIFT + 8</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label lines="1">Fechar Caixa</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-item-label>CTRL + SHIFT + 9</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label lines="2">Na opção venda, Venda a Prazo</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-item-label>CTRL + SHIFT + A</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label lines="1">Fecha a Janela</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-item-label>ESC</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-section v-else-if="tipo === 'audio'">
          <div class="text-center">
            <audio controls>
              <source :src="manual.link" type="audio/mpeg">
            </audio>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import VueYoutube from "vue-youtube";
import { getIdFromUrl } from 'vue-youtube';
import { mapGetters } from 'vuex';

Vue.use(VueYoutube);

export default {
  name: 'modalManuais',
  data() {
    return {
      tipo: '',
      videoId: '',
      showVideo: false,
      isYoutube: false,
      manual: null,
      playerVars: {
        autoplay: 1,
        controls: 1,
      },
    };
  },
  methods: {
    listVideo() {
      this.videoId = getIdFromUrl(this.manual.link);

      let extras = [];
      if (this.manual.extras.length > 0) {
        extras = JSON.parse(this.manual.extras);

        // Ordena os extras pelo timestamp (menor para maior)
        extras.sort((a, b) => {
          const aTime = a.timestamp.split(':').reduce((acc, time) => (60 * acc) + +time);
          const bTime = b.timestamp.split(':').reduce((acc, time) => (60 * acc) + +time);

          return aTime - bTime;
        });
      }

      this.extras = extras;
      this.showVideo = true;
    },
    goTo(tempo) {
      const tempoEmSegundos = tempo.split(':').reduce((acc, time) => (60 * acc) + +time);

      this.$refs.youtube.player.seekTo(tempoEmSegundos);
    },
    closeModal() {
      this.$store.dispatch('setVisualizado', this.manual);
      this.$store.commit("openDialogManuais", false);
      this.$store.commit("manual", null);
      this.player.stopVideo();
      this.showVideo = false;
      this.tipo = '';
      this.videoId = '';
      this.extras = [];
    }
  },
  computed: {
    ...mapGetters([
      "openDialogManuais",
    ]),
    player() {
      return this.$refs.youtube.player;
    },
  },
  watch: {
    openDialogManuais(val) {
      if (!val) return;

      this.manual = this.$store.getters.manual;
      this.tipo = this.manual.tipo;

      if (this.tipo === 'video') {
        this.listVideo();
      }
    }
  }
};
</script>

<style>
.link {
  color: #3f51b5;
  text-decoration: underline;
}
</style>