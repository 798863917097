import axios from "@/axios-auth";
import globalAxios from "axios";
import { Notify, Dialog, Loading } from "quasar";

const moment = require('moment');

const state = {
  cadastros: [],
  pagingCadastros: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  buscandoCadastros: false,
  acaoCadastros: "",
  allCadastros: false,
  totalCadastros: 0,
  isNewCadastro: false,
  errorsCadastros: [],
  filtrosCadastros: {
    ativo: 1,
    cliente: 1,
    funcionario: null,
    fornecedor: null,
    dependente: null,
    busca: null,
    page: 0
  },
  getSelectedCadastros: [],
  tiposCadastros: {
    cliente: "Clientes",
    funcionario: "Funcionários",
    fornecedor: "Fornecedores",
    dependente: "Vinculados",
    naocliente: "Não Clientes",
    todos: "Todos"
  },
  tipoCadastros: "",
  statusCadastros: {
    ativo: "Ativos",
    inativo: "Inativos"
  },
  cadastro: {
    cpfcnpj: "",
    nome: "",
    razao: "",
    email: "",
    telefone: "",
    cep: "",
    estado: "",
    cidade: "",
    bairro: "",
    endereco: "",
    numero: "",
    complemento: "",
    codigo: "",
    dtnascimento: "",
    cliente: true,
    funcionario: false,
    fornecedor: false,
    dependente: false,
    ativo: true,
    inscricao_estadual: ""
  },
  dadosNotificar: {},
  dadosGrupo: {},
  OpenDialogNotificarCliente: false,
  OpenDialogTags: false,
  openDialogCadastro: false,
  buscandoDetalhes: false,
  OpenDialogNovoCampo: false
};

const mutations = {
  cadastros: (state, userData) => {
    state.cadastros = userData;
  },
  pagingCadastros: (state, userData) => {
    state.pagingCadastros = userData;
  },
  buscandoCadastros: (state, userData) => {
    state.buscandoCadastros = userData;
  },
  totalCadastros: (state, userData) => {
    state.totalCadastros = userData;
  },
  cadastro: (state, userData) => {
    state.cadastro = userData;
  },
  isNewCadastro: (state, userData) => {
    state.isNewCadastro = userData;
  },
  errorsCadastros: (state, userData) => {
    state.errorsCadastros = userData;
  },
  filtrosCadastros: (state, userData) => {
    state.filtrosCadastros = userData;
  },
  tiposCadastros: (state, userData) => {
    state.tiposCadastros = userData;
  },
  tipoCadastros: (state, userData) => {
    state.tipoCadastros = userData;
  },
  statusCadastros: (state, userData) => {
    state.statusCadastros = userData;
  },
  acaoCadastros: (state, userData) => {
    state.acaoCadastros = userData;
  },
  allCadastros: (state, userData) => {
    state.allCadastros = userData;
  },
  getSelectedCadastros: (state, userData) => {
    state.getSelectedCadastros = userData;
  },
  dadosNotificar: (state, userData) => {
    state.dadosNotificar = userData;
  },
  dadosGrupo: (state, userData) => {
    state.dadosGrupo = userData;
  },
  OpenDialogNotificarCliente: (state, userData) => {
    state.OpenDialogNotificarCliente = userData;
  },
  OpenDialogTags: (state, userData) => {
    state.OpenDialogTags = userData;
  },
  openDialogCadastro: (state, userData) => {
    state.openDialogCadastro = userData;
  },
  buscandoDetalhes: (state, userData) => {
    state.buscandoDetalhes = userData;
  },
  OpenDialogNovoCampo: (state, userData) => {
    state.OpenDialogNovoCampo = userData;
  }
};

const actions = {
  listarCadastros: ({ commit, state }) => {
    if (state.buscandoCadastros) return;
    if (!state.pagingCadastros.nextPage) return;
  
    let dados = state.filtrosCadastros;
    dados.page += 1;
  
    commit("buscandoCadastros", true);
    axios
      .get("/cadastros", {
        params: dados
      })
      .then(res => {
        if (res.data.success) {
          commit("pagingCadastros", res.data.data.paging);
          commit("cadastros", state.cadastros.concat(res.data.data.cadastros));
        } else {
          commit("errorsCadastros", res.data.errors);
        }
        commit("buscandoCadastros", false);
      })
      .catch(() => {
        commit("buscandoCadastros", false);
      });
  },
  
  resetPagingCadastros: ({ commit }) => {
    commit("pagingCadastros", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });
    commit("filtrosCadastros", {
      ativo: 1,
      cliente: 1,
      funcionario: null,
      fornecedor: null,
      dependente: null,
      busca: null,
      page: 0
    });
    commit("cadastros", []);
    commit("errorsCadastros", []);
    commit("totalCadastros", 0);
  },
  resetPag: ({ commit, state }) => {
    commit("pagingCadastros", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });
    commit("filtrosCadastros", {
      ...state.filtrosCadastros,
      page: 0,
    });
    commit("cadastros", []);
    commit("errorsCadastros", []);
    commit("totalCadastros", 0);
  },
  resetBuscaCadastros: ({ commit, state }) => {
    commit("pagingCadastros", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });

    commit("filtrosCadastros", {
      ...state.filtrosCadastros,
      busca: null,
    });
    commit("cadastros", []);
    commit("errorsCadastros", []);
    commit("totalCadastros", 0);
  },
  realizarEnvioWhatsCadastro: ({ commit, dispatch }, data) => {

    if (state.realizandoAcaoReceitas) return;

    let idArr = data.ids || [];

    if (idArr.length == 0) {
      Notify.create({
        message: "Selecione pelo menos um cadastro!",
        color: "orange",
        position: "top-right"
      });

      return;
    }

    let dados = {
      ids: idArr,
      all: state.allCadastros,
      template_id: data.template,
      mensagem: data.mensagem,
      expira: data.expira,
      tipo: "whatsapp",
      confirmar: 1
    };

    if(data.whatsappBodyTemp) dados.mensagem = data.whatsappBodyTemp;

    if (dados.all === true) {
      dados.all = 1;
      dados.ativo = state.filtrosCadastros.ativo;
      dados.busca = state.filtrosCadastros.busca;
      dados.cliente = state.filtrosCadastros.cliente;
      dados.funcionario = state.filtrosCadastros.funcionario;
      dados.fornecedor = state.filtrosCadastros.fornecedor;
      dados.dependente = state.filtrosCadastros.dependente;
      dados.tags = state.filtrosCadastros.tags;
      delete dados.ids;
    } else {
      dados.all = 0;
    }

    if (idArr.length > 1) {
      axios
        .post("/cadastros/notificar", dados)
        .then(res => {
          if (res.data.success) {
            const total = res.data.total;
            Dialog.create({
              title: "Confirmação",
              message: (total > 1 ? total + " mensagens" : total + " mensagens") + " para serem enviadas, deseja continuar?",              ok: {
                label: "Confirmar",
                color: "green",
              },
              cancel: "Cancelar"
            })
            .onOk(() => {
              commit("realizandoAcaoReceitas", {
                realizandoAcaoReceitas: true
              });

              if (data !== null && data !== undefined) {
                dados.template_id = data.template;
              }
              if (dados.gateway_id === undefined) {
                dados.gateway_id = null;
              }
              delete dados.confirmar;
              dispatch("enviarWhatsCadastro", dados);
            });
          } else {
            if (res.data.errors) {
              commit("errorsWhatsApp", {
                errorsWhatsApp: res.data.errors
              });
            } else {
              Dialog.create({
                message: "Não foram encontrados números de WhatsApp com os cadastros selecionados!",
              });
            }

          }
        })
        .catch(() => {
          Notify.create({
            message: "Não foi possível contactar ou não possui permissão!",
            color: "red",
            position: "top-right"
          });
          dispatch("setAll");
          commit("realizandoAcaoReceitas", {
            realizandoAcaoReceitas: false
          });
        });
    } else {
      Dialog.create({
        title: "Confirmação",
        message: "Deseja enviar a mensagem?",
        ok: {
          label: "Confirmar",
          color: "green",
        },
        cancel: "Cancelar",
        persistent: true
      })
      .onOk(() => {
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: true
        });

        delete dados.confirmar;
        dispatch("enviarWhatsCadastro", dados);
      });
    }
  },
  enviarWhatsCadastro: ({ commit, dispatch }, data) => {
    axios
      .post("cadastros/notificar", data)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          commit("OpenDialogAgendarMensagem", false);
          commit("OpenDialogNotificarCliente", false);
        } else {
          if (res.data.errors) {
            commit("errorsWhatsApp", {
              errorsWhatsApp: res.data.errors
            });
          } else {
            Dialog.create({
              message: res.data.message,
            });
          }
        }

        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        dispatch("setAll");
        commit("realizandoAcaoReceitas", {
          realizandoAcaoReceitas: false
        });
      });
  },
  fetchCEPCadastros: ({ commit }, userData) => {
    if (!userData) {
      Notify.create({
        message: "CEP Inválido, digite novamente.",
        color: "red",
        position: "top-right"
      });
      return;
    }

    let cep = userData;
    cep = cep.replace(/\D/g, "");
    // Expressão regular para validar o CEP.
    let validacep = /^[0-9]{8}$/;
    // Valida o formato do CEP.

    if (!validacep.test(cep) || cep === "") {
      if (cep !== "") {
        // M.toast({html: 'CEP Inválido, digite novamente.', classes: 'red'})
        Notify.create({
          message: "CEP Inválido, digite novamente.",
          color: "red",
          position: "top-right"
        });
        return;
      }
    }

    commit("fetchCEP", {
      buscandoCEP: true
    });

    globalAxios
      .get("https://viacep.com.br/ws/" + cep + "/json/")
      .then(res => {
        let data = res.data;
        if (!("erro" in data)) {
          // M.toast({html: 'CEP encontrado!', classes: 'green'})
          Notify.create({
            message: "CEP encontrado!",
            color: "green",
            position: "top-right"
          });
          commit("cadastro", {
            cpfcnpj: state.cadastro.cpfcnpj,
            nome: state.cadastro.nome,
            razao: state.cadastro.razao,
            email: state.cadastro.email,
            telefone: state.cadastro.telefone,
            cep: res.data.cep,
            estado: res.data.uf,
            cidade: res.data.localidade,
            bairro: res.data.bairro,
            endereco: res.data.logradouro,
            cidade_ibge: res.data.ibge,
            numero: state.cadastro.numero,
            complemento: state.cadastro.complemento,
            codigo: state.cadastro.codigo,
            dtnascimento: state.cadastro.dtnascimento,
            cliente: state.cadastro.cliente,
            funcionario: state.cadastro.funcionario,
            fornecedor: state.cadastro.fornecedor,
            dependente: state.cadastro.dependente,
            ativo: state.cadastro.ativo,
            id: state.cadastro.id,
            inscricao_estadual: state.cadastro.inscricao_estadual
          });
          setTimeout(() => {
            document.getElementsByName("numeroCadastro")[0].focus();
          }, 200);
          commit("fetchCEP", {
            buscandoCEP: false
          });
        } else {
          commit("fetchCEP", {
            buscandoCEP: false
          });
        }
      })
      .catch(() => {
        Notify.create({
          message: "CEP Inválido, digite novamente.",
          color: "red",
          position: "top-right"
        });
        commit("fetchCEP", {
          buscandoCEP: false
        });
      });
  },
  // buscaCadastro: ({ commit }, userData) => {
  //   axios.get("/cadastros/view/" + userData).then(res => {
  //     if (res.data.success) {
  //       commit("cadastro", res.data.data.cadastro);
  //     }
  //   });
  // },
  acaoCnpj: ({ commit, dispatch }) => {
    commit("acaoCadastros", "atualizarCnpj");
    dispatch("realizarAcaoMassaCadastros");
  },
  acaoNotificar: ({ commit, dispatch }) => {
    commit("acaoCadastros", "notificarCliente");
    dispatch("realizarAcaoMassaCadastros");
  },
  acaoGrupo: ({ commit, dispatch }) => {
    commit("acaoCadastros", "adicionarGrupo");
    dispatch("realizarAcaoMassaCadastros");
  },
  resetAcoes: ({ commit }) => {
    commit("acaoCadastros", "");
    commit("allCadastros", false);
  },
  realizarAcaoMassaCadastros: ({ commit, dispatch, state }) => {
    let dados = {
      ...state.filtrosCadastros,
      ids: state.getSelectedCadastros,
      all: state.allCadastros ? 1 : 0,
      tipo: state.tipoCadastros,
    };
    if (state.acaoCadastros === "") {
      Notify.create({
        message: "Selecione uma ação!",
        color: "orange",
        position: "top-right"
      });
      return;
    }
    let acao = "";
    if (state.acaoCadastros === "atualizarCnpj") {
      Dialog.create({
        title: "Confirma a atualização cadastral com base na Receita Federal?",
        message: "Esse processo pode demorar alguns minutos.",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          acao = "/cadastros/edit-by-cnpj";
          axios
            .post(acao, dados)
            .then(res => {
              if (res.data.success) {
                Dialog.create({
                  title: "Ação Realizada com Sucesso!",
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
                dispatch("resetPag");
                dispatch("listarCadastros");
              } else {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
              }
              Loading.hide();
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
              Loading.hide();
            });
          dispatch("resetAcoes");
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    }
    if (state.acaoCadastros === "notificarCliente") {
      commit("OpenDialogNotificarCliente", true);
      commit("dadosNotificar", dados);
    }
    if (state.acaoCadastros === "adicionarGrupo") {
      commit("dadosGrupo", dados);
      dispatch("addTag");
    }
  },
  inativaCadastro: ({ commit, dispatch }, userData) => {
    userData.ativo = false;
    Dialog.create({
      title: userData.nome,
      message: "Deseja inativar este cadastro?",
      html: true,
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/cadastros/edit/" + userData.id, userData)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: userData.nome + " Inativado!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              dispatch("resetPag");
              dispatch("listarCadastros");
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
            Loading.hide();
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi Possível realizar a ação, ou você não possui permissão isto!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            Loading.hide();
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  reativaCadastro: ({ dispatch }, userData) => {
    userData.ativo = true;
    axios
      .post("/cadastros/edit/" + userData.id, userData)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: userData.nome + " Ativado!",
            color: "green",
            position: "top-right"
          });
          dispatch("resetPag");
          dispatch("listarCadastros");
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
      })
      .catch(() => {
        Notify.create({
          message:
            "Não foi possível ativar o cadastro, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  cadastro: ({ commit }, userData, novo) => {
    // commit("cadastro", {});
    commit("fetchCNPJ", {
      buscandoCNPJ: false,
      data: {},
    });

    if (userData && !userData.noSearch) {
      axios
        .get("/cadastros/view/" + userData.id)
        .then(res => {
          if (res.data.success) {
            let cadastro = res.data.data.cadastro;

            if (cadastro.dtnascimento === null) {
              cadastro.dtnascimento = "";
            } else {
              cadastro.dtnascimento = moment.utc(cadastro.dtnascimento).format("DD/MM/YYYY");
            }
            // let emailAux = [];
            // let telefoneAux = [];
            // if (cadastro.telefone === null) {
            //   cadastro.telefone = [];
            // } else if (cadastro.telefone === "") {
            //   cadastro.telefone = [];
            // } else {
            //   cadastro.telefone = cadastro.telefone.split(",");
            //   for (let i = 0; i < cadastro.telefone.length; i++) {
            //     telefoneAux[i] = {
            //       text: cadastro.telefone[i],
            //       tiClasses: ["valid"]
            //     };
            //   }
            //   cadastro.telefone = telefoneAux;
            // }
            // if (cadastro.email === null) {
            //   cadastro.email = [];
            // } else if (cadastro.email === "") {
            //   cadastro.email = [];
            // } else {
            //   cadastro.email = cadastro.email.split(",");
            //   for (let i = 0; i < cadastro.email.length; i++) {
            //     emailAux[i] = {
            //       text: cadastro.email[i],
            //       tiClasses: ["valid"]
            //     };
            //   }
            //   cadastro.email = emailAux;
            // }


            commit("cadastro", cadastro);
            commit("isNewCadastro", false);
            commit("openDialogCadastro", true);
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
        })
        .catch((error) => {
          // console.log(error)
          Notify.create({
            message:
              "Não foi possível ativar o cadastro, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    } else {
      if (userData.noSearch && !userData.novo) {
        let cadastro = {
          cpfcnpj: "",
          nome: userData.nome,
          razao: "",
          email: "",
          telefone: "",
          cep: "",
          estado: "",
          cidade: "",
          bairro: "",
          endereco: "",
          numero: "",
          complemento: "",
          codigo: "",
          dtnascimento: "",
          cliente: true,
          funcionario: false,
          fornecedor: false,
          dependente: false,
          ativo: true
        };

        if (userData.noCliente || userData.noFuncionario || userData.noFornecedor) {
          cadastro.cliente = userData.noCliente;
          cadastro.funcionario = userData.noFuncionario;
          cadastro.fornecedor = userData.noFornecedor;
        }

        commit("cadastro", cadastro);
        commit("isNewCadastro", true);
      }
      if (userData.novo) {
        commit("isNewCadastro", true);
      }
      commit("openDialogCadastro", true);
    }
  },
  filtrosCadastros: ({ commit }, userData) => {
    commit('filtrosCadastros', userData);
  }
};

const getters = {
  cadastros: state => {
    return state.cadastros;
  },
  pagingCadastros: state => {
    return state.pagingCadastros;
  },
  buscandoCadastros: state => {
    return state.buscandoCadastros;
  },
  totalCadastros: state => {
    return state.totalCadastros;
  },
  cadastro: state => {
    return state.cadastro;
  },
  isNewCadastro: state => {
    return state.isNewCadastro;
  },
  errorsCadastros: state => {
    return state.errorsCadastros;
  },
  filtrosCadastros: state => {
    return state.filtrosCadastros;
  },
  tiposCadastros: state => {
    return state.tiposCadastros;
  },
  tipoCadastros: state => {
    return state.tipoCadastros;
  },
  statusCadastros: state => {
    return state.statusCadastros;
  },
  allCadastros: state => {
    return state.allCadastros;
  },
  OpenDialogNotificarCliente: state => {
    return state.OpenDialogNotificarCliente;
  },
  dadosNotificar: state => {
    return state.dadosNotificar;
  },
  dadosGrupo: state => {
    return state.dadosGrupo;
  },
  OpenDialogTags: state => {
    return state.OpenDialogTags;
  },
  openDialogCadastro: state => {
    return state.openDialogCadastro;
  },
  buscandoDetalhes: state => {
    return state.buscandoDetalhes;
  },
  OpenDialogNovoCampo: state => {
    return state.OpenDialogNovoCampo;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
