<template>
  <div id="integracaoPiperunParametros">
    <card-section-sem-permissao v-if="semPermissao" />

    <div v-else>
      <!-- <div class="text-right">
        <q-btn
          flat
          round
          dense
          v-close-popup
          color="grey-8"
          class="q-ml-auto"
          icon="help_outline"
          @click="openDialogAjuda()"
        />
      </div> -->

      <div class="text-center" v-if="loadingConfiguracoes">
        <q-spinner color="blue-7" size="3em" />
      </div>

      <div class="row q-col-gutter-lg" v-else>

        <div class="col-12 col-md-6">
          <q-input
            v-model="config.integracoes_piperun_url"
            label="Sua url"
            readonly
          >
            <template v-slot:append>
              <q-btn
                flat
                round
                dense
                @click="copyText(config.integracoes_piperun_url, 'url', 'a')"
                icon="fa fa-clipboard"
                size="12px"
              />
            </template>
          </q-input>
        </div>

        <div class="col-12 col-md-6">
          <q-input
            v-model="config.integracoes_piperun_token"
            label="Token"
            readonly
          >
            <template v-slot:append>
              <q-btn
                flat
                round
                dense
                @click="copyText(config.integracoes_piperun_token, 'token', 'o')"
                icon="fa fa-clipboard"
                size="12px"
              />
              <q-btn
                flat
                round
                dense
                class="q-ml-sm"
                @click="renovarToken()"
                icon="fa fa-refresh"
                size="12px"
              />
            </template>
          </q-input>
        </div>

        <div class="col-12 col-md-6">
          <q-list padding>
            <q-item tag="label">
              <q-item-section side>
                <q-toggle
                  v-model="config.integracoes_piperun_cadastrar_tags"
                  color="green"
                  true-value="1"
                  false-value="0"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label>Importar Tags vindas do CRM</q-item-label>
                <q-item-label caption>Ao receber uma proposta, o sistema importará as Tags do CRM para o nosso ERP.
                  Caso a Tag não exista, ela será criada automaticamente.</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>

      <div class="flex justify-end q-mt-md q-mr-auto">
        <q-btn color="green" label="Salvar" :loading="loadingConfiguracoes" @click="salvarConfiguracoes(false)" />
      </div>
    </div>

    <q-dialog v-model="dialogAjuda">
      <q-card style="width: 90%; max-width: 400px;">

        <q-card-section class="row items-center">
          <div class="text-h6">Integração com Piperun</div>
          <q-space />
          <q-btn
            flat
            round
            dense
            v-close-popup
            icon="close"
            @click="closeDialogAjuda()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          Texto ou vídeo de ajuda
        </q-card-section>

      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import axios from '@/axios-auth';
import { copyToClipboard } from 'quasar';

export default {
  name: 'integracao-piperun-parametros',
  data() {
    return {
      dialogAjuda: false,
      loadingConfiguracoes: false,
      planosdecontas: [],
      planosdecontasOptions: [],
      semPermissao: false,
      config: {
        integracoes_piperun_url: '',
        integracoes_piperun_token: '',
        integracoes_piperun_cadastrar_tags: "0",
      },
    };
  },
  methods: {
    openDialogAjuda() {
      this.dialogAjuda = true;
    },
    closeDialogAjuda() {
      this.dialogAjuda = false;
    },
    loadConfiguracoes() {
      this.loadingConfiguracoes = true;

      axios
        .get('/integracoes/piperun')
        .then(res => {
          this.loadingConfiguracoes = false;

          if (!res.data.success) {
            throw new Error();
          }

          this.semPermissao = false;
          this.config = res.data.config;
        })
        .catch(() => {
          this.loadingConfiguracoes = false;
          this.semPermissao = true;
        });
    },
    renovarToken() {
      this.$q.dialog({
        title: 'Confirmar',
        message: `
          Deseja realmente renovar o token?<br/>
          Todos os dispositivos serão desconectados!
        `,
        cancel: true,
        persistent: true,
        html: true,
      }).onOk(() => {
        this.salvarConfiguracoes(true);
      });
    },
    salvarConfiguracoes(token = false) {
      let url = `/integracoes/piperun${token ? '?token=1' : ''}`;
      const data = JSON.parse(JSON.stringify(this.config));

      axios
        .post(url, data)
        .then(res => {
          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right'
          });

          if (!res.data.success) {
            return;
          }

          this.config.integracoes_piperun_token = res.data.config.integracoes_piperun_token;
          this.config.integracoes_piperun_cadastrar_tags = res.data.config.integracoes_piperun_cadastrar_tags;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao renovar o token, tente novamente!',
            color: 'red',
            position: 'top-right',
          });
      });
    },
    copyText(text, nomeItemCopia, artigo = 'o') {
      nomeItemCopia = nomeItemCopia.charAt(0).toUpperCase() + nomeItemCopia.slice(1);

      copyToClipboard(text)
        .then(() => {
          this.$q.notify({
            message: `${nomeItemCopia} copiad${artigo}`,
            color: 'green',
            position: 'top-right',
          });
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao tentar copiar seu texto',
            color: 'red',
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    this.loadConfiguracoes();
  },
};
</script>

<style lang="stylus" scoped>

</style>
