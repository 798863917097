<template>
  <div id="vinculos">
    <q-card white bordered class="q-mb-md">
      <q-card-section @click="qvinculos = !qvinculos">
        <div class="text-h6 cursor-pointer">
          <q-btn
            dense
            flat
            no-wrap
            v-ripple
            round
            icon="add"
            no-caps
            class="q-ml-sm q-mr-md bg-white text-blue-7"
            clickable
            size="11px"
            @click.stop="modal()"
            :loading="loading"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template> </q-btn
          >Vínculos
        </div>
        <q-btn
          dense
          flat
          no-wrap
          v-ripple
          round
          color="black"
          :icon="qvinculos ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          no-caps
          class="q-ml-sm bg-white text-black absolute-top-right"
          style="margin: 15px 35px 0 0"
          clickable
        ></q-btn>
      </q-card-section>
      <q-card-section v-if="qvinculos">
        <q-table
          title
          :data="vinculos"
          :columns="columns"
          :pagination.sync="pagination"
          row-key="id"
          :separator="'horizontal'"
          :loading="buscando"
          no-data-label="Nenhum resultado encontrado."
        >
          <template v-slot:bottom>
            <div></div>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
              <q-td key="ativo" class="text-center">
                <q-icon
                  :name="props.row.ativo ? 'check' : 'close'"
                  size="24px"
                  :class="props.row.ativo ? 'text-positive' : 'text-negative'"
                ></q-icon>
              </q-td>
              <q-td key="periodo">
                <router-link
                  style="color:#000;border-color: rgba(0,0,0,0.12);font-size: 13px;text-decoration:none"
                  :to="getUrl(props.row)"
                  clickable
                  >{{ props.row.nome }}</router-link
                >
              </q-td>
              <q-td key="numeroconvenio" class="text-right">{{
                props.row.cpfcnpj
              }}</q-td>
              <q-td key="dependente_id" class="text-right">{{
                props.row.dtnascimento | formatDate
              }}</q-td>
              <q-td key="descricao">{{ props.row.codigo }}</q-td>
              <q-td key="valor" class="text-right">{{
                props.row.created | formatDate
              }}</q-td>
              <q-td v-if="$q.platform.is.desktop" class="tdfora">
                <div class="divfora">
                  <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                    <q-btn
                      @click="excluir(props.row)"
                      class="bg-negative text-white"
                      icon="block"
                      size="12px"
                    ></q-btn>
                  </div>
                </div>
              </q-td>
            </q-tr>
            <q-tr v-show="props.expand" :props="props">
              <q-td colspan="100%">
                <div class="q-py-sm q-gutter-sm">
                  <q-btn
                    @click="excluir(props.row)"
                    class="bg-negative text-white"
                    icon="block"
                    size="12px"
                  />
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
    <q-dialog v-model="OpenDialogVinculo" persistent>
      <q-card style="width: 400px; max-width: 90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">{{ isNew ? "Novo " : "Editar " }}Vínculo</div>
          <q-space />
          <q-btn
            icon="close"
            clickable
            flat
            round
            dense
            v-close-popup
            @click="OpenDialogVinculo = false"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-gutter-md">
              <div class="col col-md-12" v-if="mostraNew">
                Vínculo
                <strong>{{ cadastro_nome }}</strong>
              </div>
              <div class="col col-md-12" v-if="!mostraNew">
                <autocomplete
                  title="Nome cliente"
                  :filter="filterFn"
                  :carregando="carregando"
                  :options="options"
                  :formatDisplay="formatDisplay"
                  :create="createValue"
                  @resultAutocomplete="resultEmitRecebe"
                  :display="displayMontar"
                  :newCadastro="cadastroReturn"
                />
              </div>
              <div class="col col-md-12">
                <q-select
                  v-model="cadastro_tipo"
                  :options="tipos"
                  label="Tipo"
                  :option-label="
                    value => (value === null ? 'Item Vazio' : value)
                  "
                  :option-value="value => (value === null ? null : value)"
                  clearable
                />
              </div>
              <div class="q-mt-xl">
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  type="submit"
                  :loading="saveVinculo"
                  @click="save()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify, Loading, Dialog } from "quasar";
import { mapGetters } from "vuex";
export default {
  name: "vinculos",
  data() {
    return {
      qvinculos: false,
      OpenDialogVinculo: false,
      columns: [
        {
          name: "ativo",
          required: true,
          label: "Ativo",
          align: "center",
          field: row => row.ativo,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "nome",
          align: "center",
          label: "Nome",
          field: row => row.nome,
          sortable: true
        },
        {
          name: "cpfcnpj",
          label: "CPF/CNPJ",
          field: row => row.cpfcnpj,
          sortable: true,
          align: "center"
        },
        {
          name: "dtnascimento",
          label: "Nascimento",
          field: row => row.dtnascimento,
          align: "center",
          sortable: true
        },
        {
          name: "codigo",
          label: "Código",
          field: row => row.codigo,
          align: "center",
          sortable: true
        },
        {
          name: "created",
          label: "Cadastro em",
          field: row => row.created,
          align: "center",
          sortable: true
        },
        { name: 'tdfora' },
      ],
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      buscando: false,
      isNew: true,
      vinculos: [],
      tipos: [],
      vinculo: this.newVinculo(),
      vinculosSuccess: false,
      loading: false,
      cadastro_tipo: "Dependente",
      cadastro_nome: "",
      cadastro_id: null,
      saveVinculo: false,
      errors: [],
      newDependente: false,
      mostraNew: false,
      newDep: {
        nome: "",
        dtnascimento: "",
        cpf: ""
      },
      loadNewDep: false,
      cadastros: [],
      // autocomplete
      carregando: false,
      options: [],
      selectItem: null,
      autocompleteResult: null
    };
  },
  watch: {
    qvinculos(value) {
      if (value) {
        this.listar();
      }
    }
  },
  methods: {
    busca() {
      axios.get("/cadastros").then(res => {
        this.cadastros = res.data.data.cadastros;
      });
    },
    getUrl(vinculo) {
      return "/cadastros/" + vinculo.id;
    },
    getAction() {
      return "/cadastros/add-vinculo/" + this.$route.params.id;
    },
    getAction2() {
      return "/cadastros/add" + this.$route.params.id;
    },
    modal(vinculo) {
      this.vinc = { tipos: "Dependente", vinculo: "" };
      this.loading = true;
      let url = "/cadastros/add-vinculo/" + this.$route.params.id;
      if (vinculo) {
        this.isNew = false;
        url =
          "/cadastros/add-vinculo/" + this.$route.params.id + "/" + vinculo.id;
        this.vinculo = vinculo;
      } else {
        this.vinculo = this.newVinculo();
        this.isNew = true;
      }
      axios
        .get(url)
        .then(res => {
          if (res.data.success) {
            this.tipos = res.data.data.tipos;
            this.OpenDialogVinculo = true;
            this.loading = false;
          }
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão para isto!",
            color: "red",
            position: "top-right"
          });
          this.loading = false;
        });
    },
    save() {
      if (!this.autocompleteResult) {
        Notify.create({
          message: "Selecione um vínculo para poder salvar",
          color: "orange",
          position: "top-right"
        });
        return;
      }

      this.saveVinculo = true;
      let vinculos = {
        parente_nome: this.autocompleteResult.name,
        tipo: this.tipos.indexOf(this.cadastro_tipo),
        parente_id: null
      };

      if (this.autocompleteResult && this.autocompleteResult.id) {
        vinculos.parente_id = this.autocompleteResult.id;
      }

      let action = "/cadastros/add-vinculo/" + this.$route.params.id;
      axios
        .post(action, vinculos)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: "Vínculo salvo!",
              color: "green",
              position: "top-right"
            });
            this.listar();
            this.saveVinculo = false;
            this.OpenDialogVinculo = false;
            this.mostraNew = false;
            this.options = [];
            this.autocompleteResult = {};
            this.$store.commit("cadastroReturn", null);
          } else {
            this.errors = res.data.data.errors;
            this.saveVinculo = false;
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível salvar o vínculo, ou você não possui permissão para salvar!",
            color: "red",
            position: "top-right"
          });
          this.saveVinculo = false;
        });
    },
    saveNew() {
      this.loadNewDep = true;
      const newDep = {
        nome: this.newDep.nome,
        cpfcnpj: this.newDep.cpf,
        dtnascimento: this.newDep.dtnascimento
      };

      axios.post("/cadastros/add", newDep).then(res => {
        if (res.data.success) {
          this.cadastro_id = res.data.data.cadastro.id;
          this.cadastro_nome = res.data.data.cadastro.nome;
          this.newDependente = false;
          this.mostraNew = true;
          this.newDep = {};
          this.loadNewDep = false;
          this.autocompleteResult = {};
        }
      });
    },
    excluir(vinculo) {
      Dialog.create({
        title: vinculo.nome,
        message: "Tem certeza que deseja excluir o vínculo?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(
              "/cadastros/delete-vinculo/" +
                this.$route.params.id +
                "/" +
                vinculo.id
            )
            .then(res => {
              Dialog.create({
                title: "Vínculo removido!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              this.listar();
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    newVinculo() {
      this.cadastro_id = null;
      return {
        cadastro_id: null,
        tipo: null,
        parente_id: null,
        parente_nome: ""
      };
    },
    listar() {
      if (this.buscando) {
        return;
      }
      this.buscando = true;
      this.vinculosSuccess = false;
      axios
        .get("/cadastros/vinculos/" + this.$route.params.id)
        .then(res => {
          if (res.data.success) {
            this.vinculos = res.data.data.cadastros;
            this.vinculosSuccess = res.data.success;
            this.buscando = false;
          }
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.buscando = false;
        });
    },
    // Funções do autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/cadastros/busca?term=" + val).then(res => {
          this.carregando = false;
          this.options = res.data;
        });
      });
    },
    createValue(val, done) {
      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
    },
    formatDisplay(result) {
      return result.label;
    },
    resultEmitRecebe(result) {
      this.autocompleteResult = result;
      this.$store.commit("cadastroReturn", null);
      if (this.autocompleteResult && this.autocompleteResult.id != null) {
        Notify.create({
          message: this.autocompleteResult.name + " foi selecionado.",
          color: "green",
          position: "top-right"
        });
      }
    },
    displayMontar(value) {
      return !value ? value : value.name;
    }
  },
  computed: {
    ...mapGetters(["cadastroReturn"])
  }
};
</script>

<style lang="stylus" scoped>
.text-h6, .text-subtitle2 {
  font-weight: 300;
}

.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
