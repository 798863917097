<template>
  <div id="modalNovoServico">
    <q-dialog v-model="OpenDialogNovoServico" persistent>
      <q-card :class="{ minwidth: isNewServico, maxwidth: !isNewServico }">
        <q-card-section class="row items-center">
          <div class="text-h6">
            {{ isNewServico ? "Novo " : "Editar " }} Serviço
          </div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />
        <div class="row">
          <q-card-section
            :class="{
              'col-12 col-md-12 col-sm-12': isNewServico,
              'col-md-6 col-sm-12': !isNewServico
            }"
          >
            <div class="q-pa-md">
              <div class="q-col-gutter-md row">
                <div class="col-12 col-md-12">
                  <q-input
                    v-model="servicoCopy.nome"
                    label="Nome"
                    bottom-slots
                    :error="errorsServicos.nome ? true : false"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsServicos.nome"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-input>
                </div>
                <div class="col-12 col-md-12">
                  <q-select
                    clearable
                    v-model="servicoCopy.categoria_id"
                    :options="categorias"
                    option-value="id"
                    option-label="titulo"
                    emit-value
                    map-options
                    label="Categoria"
                    bottom-slots
                    :error="errorsServicos.categoria_id ? true : false"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsServicos.categoria_id"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey"
                          >Sem Resultados</q-item-section
                        >
                      </q-item>
                    </template>
                  </q-select>
                </div>
                <div class="col-12 col-md-12">
                  <q-select
                    v-model="servicoCopy.tiposoma"
                    :options="tiposSomaServicos"
                    option-value="value"
                    option-label="name"
                    emit-value
                    map-options
                    label="Tipo Soma"
                    bottom-slots
                    :error="errorsServicos.tiposoma ? true : false"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsServicos.tiposoma"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-select>
                </div>

                <div class="col-12 col-md-6">
                  <q-field
                    v-model="servicoCopy.vlrcomissao"
                    label="Comissão da Lotérica"
                    bottom-slots
                    :error="errorsServicos.vlrcomissao ? true : false"
                  >
                    <template v-slot:before>
                      <q-btn
                        :label="setarTpComissao"
                        v-model="servicoCopy.tpcomissao"
                        size="md"
                        @click="printTpComissao"
                      />
                    </template>
                    <template
                      v-slot:control="{ id, floatingLabel, value, emitValue }"
                    >
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="
                          servicoCopy.tpcomissao === '%'
                            ? percentFormat
                            : moneyFormat
                        "
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsServicos.vlrcomissao"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>
                <div class="col-12 col-md-6">
                  <q-field
                    v-model="servicoCopy.vlrcomissao_func"
                    label="Comissão para o Vendedor"
                    bottom-slots
                    :error="errorsServicos.vlrcomissao_func ? true : false"
                  >
                    <template v-slot:before>
                      <q-btn
                        :label="setarTpComissaoFunc"
                        v-model="servicoCopy.tpcomissao_func"
                        size="md"
                        @click="printTpComissaoFunc"
                      />
                    </template>
                    <template
                      v-slot:control="{ id, floatingLabel, value, emitValue }"
                    >
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="
                          servicoCopy.tpcomissao_func === '%'
                            ? percentFormat
                            : moneyFormat
                        "
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsServicos.vlrcomissao_func"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>

                <div class="col-12">
                  <q-select
                    label="Tipo controle"
                    :options="tipoControle"
                    v-model="servicoCopy.tpcontrole"
                    :error="errorsServicos.tpcontrole ? true : false"
                  >
                   <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsServicos.tpcontrole"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-select>
                </div>

                <div class="col-6 col-md-6">
                  <q-input
                    v-model="servicoCopy.ordem"
                    mask="##"
                    bottom-slots
                    :error="errorsServicos.ordem ? true : false"
                    label="Ordem"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsServicos.ordem"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-input>
                </div>
                <div class="col-6 col-md-6">
                  <q-field
                    v-model="servicoCopy.vlrcusto"
                    label="Custo"
                    bottom-slots
                    :error="errorsServicos.vlrcusto ? true : false"
                  >
                    <template
                      v-slot:control="{ id, floatingLabel, value, emitValue }"
                    >
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsServicos.vlrcusto"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>
                <div class="col-6 col-md-6" v-if="!isNewServico">
                  <q-input
                    v-model="servicoCopy.text_detect"
                    bottom-slots
                    :error="errorsServicos.ordem ? true : false"
                    label="Detecção na Foto"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsServicos.ordem"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-input>
                </div>
                <div class="col-6 col-md-6" v-if="!isNewServico">
                  <q-field
                    v-model="servicoCopy.similaridade"
                    label="Similaridade (de 70% a 95%)"
                    bottom-slots
                    :error="Boolean(errorsServicos.similaridade)"
                  >
                    <template
                      v-slot:control="{ id, floatingLabel, value, emitValue }"
                    >
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="percentFormat"
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsServicos.similaridade"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>
                <div class="col-6 col-md-6">
                  <q-field
                    v-model="servicoCopy.vlrvenda"
                    label="Valor Venda"
                    bottom-slots
                    :error="errorsServicos.vlrvenda ? true : false"
                  >
                    <template v-slot:control="{ value, emitValue }">
                      <money
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsServicos.vlrvenda"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>
                <div class="col-12">
                  <q-checkbox
                    v-model="servicoCopy.ativo"
                    label="Ativo"
                    color="green"
                  />
                </div>
                <div class="col-12">
                  <q-select
                    use-input
                    v-model="planodeconta"
                    option-label="titulo"
                    option-value="id"
                    :options="planoContasFilterResult"
                    label="Plano de Conta"
                    clearable
                    @filter="planoContasFilter"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
              </div>
            </div>
          </q-card-section>
          <q-card-section class="col-md-6 col-sm-12" v-if="!isNewServico">
            <div class="q-pa-sm">
              <div class="row q-col-gutter-md">
                <div
                  class="col-12 col-md-12"
                  style="position:relative;left: -25px"
                >
                  <q-btn
                    v-ripple
                    label="Nova Regra"
                    icon="add"
                    dense
                    class="q-ml-sm q-px-md bg-positive text-white"
                    style="margin-bottom: 15px; margin-top: 15px;"
                    clickable
                    @click="modalRegra()"
                  />
                </div>
                <q-list
                  bordered
                  class="rounded-borders"
                  style="padding: 0px !important"
                >
                  <q-item-label header>Regras</q-item-label>
                  <div
                    v-for="(regra, i) in servicoCopy.prodregras"
                    :key="regra.nomenclatura + i"
                  >
                    <q-item>
                      <q-item-section top>
                        <q-item-label lines="1">
                          <span class="text-weight-medium">{{ regra.nomenclatura }}</span>
                          <span class="text-grey-8">&nbsp;-&nbsp;{{ regra.tipo }}</span>
                        </q-item-label>
                        <q-item-label caption lines="1" style="text-wrap: wrap;">
                          <strong>{{ regra.valor }}</strong>&nbsp;
                          <strong>{{ regra.tpvalor }}</strong>&nbsp;
                          <strong>{{ regra.quando }}</strong>&nbsp;em&nbsp;
                          <strong>{{ regra.dias }}</strong>&nbsp;dia(s)&nbsp;do&nbsp;tipo&nbsp;
                          <strong>{{ regra.tipo }}</strong>,&nbsp;da nomenclatura&nbsp;
                          <strong>{{ regra.nomenclatura }}</strong>&nbsp;e&nbsp;conta&nbsp;bancária&nbsp;
                          <strong>{{ getContaNome(regra.conta_id) }}</strong>.
                        </q-item-label>
                      </q-item-section>

                      <q-item-section top side style="padding-left: 0;">
                        <div class="text-grey-8 q-gutter-xs">
                          <q-btn
                            clickable
                            @click="deleteRegra(regra)"
                            size="12px"
                            flat
                            dense
                            round
                            icon="delete"
                          />
                          <q-btn
                            clickable
                            @click="modalRegra(regra)"
                            size="12px"
                            flat
                            dense
                            round
                            icon="edit"
                          />
                        </div>
                      </q-item-section>
                    </q-item>
                    <q-separator
                      spaced
                      v-if="
                        servicoCopy && servicoCopy.prodregras.length - i > 1
                      "
                    />
                  </div>
                </q-list>
              </div>
            </div>
          </q-card-section>
          <q-card-section class="col-12 q-mt-md">
            <q-btn
              no-wrap
              v-ripple
              label="Salvar"
              no-caps
              class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
              style="margin: 0 30px 20px 0"
              clickable
              :loading="saveServico"
              @click="addServico()"
            >
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
          </q-card-section>
        </div>
      </q-card>
    </q-dialog>
    <q-dialog v-model="OpenDialogNovaRegraServico" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Nova Regra Servico</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModalR()"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-input
                  v-model="regraServico.nomenclatura"
                  label="Nomenclatura"
                  bottom-slots
                  :error="errorsRegras.nomenclatura ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.nomenclatura"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  v-model="regraServico.tpnomenclatura"
                  :options="tpnomenclaturaServicos"
                  option-label="name"
                  option-value="value"
                  emit-value
                  map-options
                  label="Tipo de Nomenclatura"
                  bottom-slots
                  :error="errorsRegras.tpnomenclatura ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.tpnomenclatura"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  clearable
                  v-model="regraServico.tipo"
                  :options="tipoRegrasServicos"
                  label="Tipo"
                  bottom-slots
                  :error="errorsRegras.tipo ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.tipo"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  clearable
                  v-model="regraServico.conta_id"
                  :options="contas"
                  option-label="titulo"
                  option-value="id"
                  map-options
                  emit-value
                  label="Conta Bancária"
                  bottom-slots
                  :error="errorsRegras.conta_id ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.conta_id"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  clearable
                  v-model="regraServico.quando"
                  :options="tipoRegrasAcontarServicos"
                  label="A Contar"
                  bottom-slots
                  :error="errorsRegras.quando ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.quando"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  clearable
                  v-model="regraServico.tpvalor"
                  :options="tipoRegrasValorServicos"
                  label="Tipo Valor"
                  bottom-slots
                  :error="errorsRegras.tpvalor ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.tpvalor"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-6 col-md-6">
                <q-input
                  v-model="regraServico.dias"
                  bottom-slots
                  :error="errorsRegras.dias ? true : false"
                  mask="###"
                  label="Dias"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.dias"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
              <div class="col-6 col-md-6">
                <q-field
                  v-model="regraServico.valor"
                  label="Custo"
                  bottom-slots
                  :error="errorsRegras.valor ? true : false"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="
                        regraServico.tpvalor === '%' ||
                        regraServico.tpvalor === '% Restante'
                          ? percentFormat
                          : moneyFormat
                      "
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.valor"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-field>
              </div>
              <div class="col-12 col-md-12 q-pb-lg">
                <q-btn
                  no-wrap
                  v-ripple
                  label="Adicionar Regra"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  :loading="saveRegra"
                  @click="addRegra()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
export default {
  name: "modal-novo-servico",
  components: { Money },
  data() {
    return {
      servicoCopy: this.newServico(),
      regraServico: this.newRegraServico(),
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false
      },
      planodeconta: null,
      planoContasFilterResult: [],
      tipoControle: ["Nenhum", "Concurso"],
    };
  },
  watch: {
    servicoSelected(value) {
      if (this.isNewServico) {
        this.servicoCopy = this.newServico();
      } else {
        this.servicoCopy = JSON.parse(JSON.stringify(value));

        if (!this.planodeconta) {
          this.planodeconta = value.planodeconta ? value.planodeconta : null;
        }

        this.$store.dispatch("planoContas");
      }
    }
  },
  methods: {
    printTpComissao() {
      this.servicoCopy.tpcomissao =
        this.servicoCopy.tpcomissao === "$" ? "%" : "$";
    },
    printTpComissaoFunc() {
      this.servicoCopy.tpcomissao_func =
        this.servicoCopy.tpcomissao_func === "$" ? "%" : "$";
    },
    newServico() {
      return {
        id: null,
        ativo: true,
        tipo: "S",
        nome: "",
        categoria_id: null,
        vlrcusto: 0.0,
        vlrvenda: 0.0,
        vlrlucro: 0.0,
        vlrcomissao: 0.0,
        tpcontrole: "Nenhum",
        ordem: 1,
        planodeconta_id: null,
        text_detect: "",
        similaridade: 0.00,
        tiposoma: null,
        tpcomissao: "$",
        vlrcomissao_func: 0.0,
        tpcomissao_func: "$",
        servico_id: null,
        prodregras: []
      };
    },
    newRegraServico() {
      return {
        id: null,
        tipo: "",
        conta_id: null,
        quando: "",
        dias: 0,
        tpvalor: "",
        valor: "",
        nomenclatura: "",
        tpnomenclatura: "C"
      };
    },
    closeModalR() {
      this.regraServico = this.newRegraServico();
      this.$store.commit("OpenDialogNovaRegraServico", false);
      this.$store.commit("errorsServicos", []);
      this.$store.commit("addRegraServicos", this.regraServico);
    },
    closeModal() {
      this.servicoCopy = this.newServico();
      this.$store.commit("servico", this.servicoCopy);
      this.$store.commit("isNewServico", true);
      this.$store.commit("OpenDialogNovoServico", false);
      this.$store.commit("errorsServicos", []);
    },
    addServico() {
      this.servicoCopy.planodeconta_id = this.planodeconta ? this.planodeconta.id : null;
      this.servicoCopy.ordem =
        this.servicoCopy.ordem !== null && this.servicoCopy.ordem !== ""
          ? this.servicoCopy.ordem
          : this.produtosServicos.length;
      this.$store.dispatch("addServico", this.servicoCopy);
    },
    deleteRegra(regra) {
      const data = JSON.parse(JSON.stringify(regra));
      this.$store.dispatch("deleteRegraServico", data);
    },
    modalRegra(regra) {
      if (regra) {
        this.regraServico = JSON.parse(JSON.stringify(regra));
        this.$store.dispatch("modalRegraServico", this.regraServico);
        this.$store.dispatch("errorsServicos", []);
      } else {
        this.$store.dispatch("modalRegraServico");
        this.$store.dispatch("errorsServicos", []);
      }
    },
    addRegra() {
      if (this.isNewRegraServico) {
        this.regraServico.produto_id = this.servico.id;
      }
      this.$store.dispatch("addRegraServico", this.regraServico).then(res => {
        if (res.success) {
          this.regraServico = this.newRegraServico();
        }
      });
    },
    getContaNome(value) {
      if (!value) {
        return "Sem conta cadastrada";
      }
      for (let i = 0; i < this.contas.length; i++) {
        if (this.contas[i].id === value) {
          return this.contas[i].titulo;
        }
      }
      return "Sem conta cadastrada";
    },
    planoContasFilter(val, update) {
      update(() => {
        this.planoContasFilterResult = this.planoContas.filter(
          plano => plano.titulo.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
  },
  computed: {
    setarTpComissao:{
     get(){
       return this.servicoCopy.tpcomissao === "$" ? "R$" : "%";
     },
     set(){}
    },
    setarTpComissaoFunc:{
     get(){
       return this.servicoCopy.tpcomissao_func === "$" ? "R$" : "%";
     },
     set(){}
    },
    OpenDialogNovoServico: {
      get() {
        return this.$store.getters.OpenDialogNovoServico;
      },
      set() {}
    },
    OpenDialogNovaRegraServico: {
      get() {
        return this.$store.getters.OpenDialogNovaRegraServico;
      },
      set() {}
    },
    ...mapGetters([
      "servico",
      "servicoSelected",
      "isNewServico",
      "categorias",
      "saveServico",
      "errorsServicos",
      "tiposSomaServicos",
      "produtosServicos",
      "acontarServicos",
      "tipoValorServicos",
      "isNewRegraServico",
      "addRegraServicos",
      "acontarServicos",
      "tipoValorServicos",
      "tiposServicos",
      "contas",
      "saveRegra",
      "tpcomissaoServicos",
      "tpcomissaofuncServicos",
      "usarconcursosServicos",
      "tpnomenclaturaServicos",
      "tipoRegrasServicos",
      "tipoRegrasValorServicos",
      "tipoRegrasAcontarServicos",
      "planoContas",
    ]),
    errorsRegras() {
      return this.errorsServicos["prodregras"]
        ? this.errorsServicos["prodregras"][0]
        : [];
    }
  }
};
</script>

<style lang="stylus" scoped>
.minwidth {
  width: 100%;
  @media (min-width: 1024px) {
    width: 600px;
  }
}

.maxwidth {
  width: 100%;
  @media (min-width: 1024px) {
    min-width: 800px;
    max-width: 1200px;
  }
}
</style>
