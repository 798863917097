<template>
  <div id="sessoesAtivas">
    <q-card>
      <q-card-section>
        <div class="q-col-gutter-md row">
            <div class="col-12 col-md-5">
                <div class="text-h6">Aparelhos com sessões ativas</div>
                <q-btn
                    class="bg-blue-7 text-white absolute-top-right"
                    style="margin: 15px 15px 0 0"
                    label="Finalizar Sessôes"
                    @click="finalizarSessoes"
                ></q-btn>
            </div>
        </div>
      </q-card-section>

      <q-card-section>
          <q-list dense class="rounded-borders" style="max-width: 100%">
            <q-item clickable v-ripple v-for="sessao in sessoesAtivas" :key="sessao.id">
                <q-item-section avatar>
                    <q-icon name="phonelink" />
                </q-item-section>
                <q-item-section top>
                    <q-item-label lines="1">
                        <span class="text-weight-medium">{{sessao.is_atual ? 'Sessão atual' : 'Outras sessões'}}</span>
                    </q-item-label>
                    <q-item-label caption lines="1">Endereço: {{sessao.ip}}</q-item-label>
                    <q-item-label caption lines="1">Ultimo sessão ativa: {{sessaoAtiva(sessao.modified)}}</q-item-label>
                    <q-item-label caption lines="1">Validade: {{sessao.validade | formatDate}}</q-item-label>
                    <q-item-label caption lines="1">{{sessao.info}}</q-item-label>
                </q-item-section>
                <q-item-section side>
                      <q-icon name="delete" @click="finalizarSessoes(sessao.id)" />
                 </q-item-section>
            </q-item>
        </q-list>
      </q-card-section>

      <q-card-actions></q-card-actions>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify, Dialog, Loading  } from "quasar";
import axios from "@/axios-auth";
const moment = require("moment");

export default {
  name: "sessoes-ativas",
  data() {
    return {
      ativo: false
    };
  },
  methods:{
    finalizarSessoes(id = null) {

      let data = {
          action : id > 0 ? 'selecionado' : 'todos',
          token: id
      };

       Dialog.create({
            title: "Finalizar sessão",
            message: id >  0 ? "Deseja finalizar a sessão selecionada? " : "Sair de todas as sessões ?",
            cancel: "Cancelar",
            persistent: true
        })
        .onOk(() => {
            Loading.show();
        })
        .onOk(() => {
            axios.post("/users/logout", data)
            .then(res => {
                setTimeout(()=>{
                    this.$router.go('/perfil');
                },500);
            })
            .catch(error => {
                Notify.create({
                message:
                    "Não foi possível realizar processo! Por favor tente novamente!",
                color: "red",
                position: "top-right"
                });
            });
        })
        .onCancel(() => {
            Loading.hide();
        })
        .onDismiss(() => {
            Loading.hide();
        });
    },
    sessaoAtiva(modified){
      const ultimaModificacao = moment(modified).calendar();

      return ultimaModificacao;
    }
  },
  computed: {
    ...mapGetters(["sessoesAtivas"])
  }
};
</script>

<style lang="stylus" scoped></style>
