import axios from 'axios';
import store from './store/store';

const myLocal = window.localStorage;

const instance = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});

instance.interceptors.request.use((request) => {
  // Token principal
  const empresa = store.getters.dataEmpresa.id === undefined
    ? localStorage['empresa_atual'] : store.getters.dataEmpresa.id;

  const token = myLocal.getItem(`token${empresa}`);

  if (token) {
    request.headers['Authorization'] = 'Bearer ' + token;
  } else {
    request.headers['AuthorizationQrcode'] = 'Bearer ' + token;
  }

  return request;
}, (error) => {
  return Promise.reject(error);
});

instance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  return Promise.resolve({ error });
});

export default instance;
