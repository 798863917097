<template>
  <q-dialog v-model="openDialogMetricasSaasOpcoes" persistent>
    <q-card style="width: 90%; max-width: 450px;">

      <q-card-section class="row items-center">
        <div class="text-h6">Configurações de CAC</div>
        <q-space />
        <q-btn
          class="q-ml-lg"
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="closeDialogOpcoes()"
        />
      </q-card-section>

      <q-separator />

      <q-card-section>
        <div class="q-pa-md">
          <div class="q-col-gutter-md row">

            <div class="col-md-12">
              <q-select
                use-input
                emit-value
                map-options
                input-debounce="0"
                label="Plano de contas"
                @filter="filterPlanosdecontas"
                v-model="planodecontas"
                :options="planodecontasOptionsSelect"
                option-value="id"
                option-label="titulo"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem Resultados</q-item-section>
                  </q-item>
                </template>
                <template v-slot:after>
                  <q-btn
                    flat
                    round
                    icon="add"
                    color="primary"
                    @click="addPlanodeconta()"
                  />
                </template>
              </q-select>
            </div>

            <div class="col-12 q-mt-md">
              <q-list bordered class="rounded-borders">
                <q-expansion-item
                  class="planodeconta-item"
                  expand-separator
                  v-for="planodeconta in planosdecontasSelecionados"
                  :key="planodeconta.id"
                >
                  <template v-slot:header>
                    <q-item-section>
                      {{ planodeconta.titulo }}
                    </q-item-section>

                    <q-item-section side class="planodeconta-item-actions">
                      <q-btn
                        flat
                        round
                        color="red"
                        icon="delete"
                        @click="removePlanodeconta(planodeconta.id)"
                      />
                    </q-item-section>
                  </template>

                  <q-item v-if="planodeconta.centrosdecusto.length === 0">
                    <q-item-section>
                      <q-field
                        v-model="planodeconta.porcentagem"
                        label="Porcentagem"
                        @input="value => updatePorcentagem(value, planodeconta.id)"
                      >
                        <template
                          v-slot:control="{ id, floatingLabel, value, emitValue }"
                        >
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="percentFormat"
                            v-show="floatingLabel"
                          >
                          </money>
                        </template>
                      </q-field>
                    </q-item-section>
                  </q-item>

                  <q-item
                    v-for="centrodecusto in planodeconta.centrosdecusto"
                    :key="centrodecusto.id"
                    style="padding-right: 7px;"
                  >
                    <q-item-section>
                      {{ centrodecusto.nome }}
                    </q-item-section>
                    <q-item-section avatar>
                      <q-btn
                        flat
                        round
                        color="red"
                        icon="delete"
                        @click="removeCentrodecusto(planodeconta.id, centrodecusto.id)"
                      />
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section avatar>
                      <q-btn
                        flat
                        round
                        color="primary"
                        icon="add"
                        @click="openDialogAddCentrodecusto(planodeconta.id)"
                      />
                    </q-item-section>
                    <q-item-section class="text-grey-8">
                      Adicionar centro de custo
                    </q-item-section>
                  </q-item>
                </q-expansion-item>

                <q-item v-if="planosdecontasSelecionados.length === 0" class="justify-center">
                  <q-item-section>
                    Não há nenhum plano de contas
                  </q-item-section>
                </q-item>
              </q-list>
            </div>

          </div>
        </div>
      </q-card-section>

      <q-card-actions class="q-mb-sm" align="right">
        <q-btn
          @click="salvaOpcoes()"
          :loading="loadingSalvaOpcoes"
          label="Salvar"
          color="positive"
        />
      </q-card-actions>

    </q-card>

    <q-dialog
      v-model="dialogAddCentrodecusto"
      persistent
      transition-show="scale"
      transition-hide="scale"
    >
      <q-card style="width: 350px" class="q-py-md">
        <q-card-section class="q-pt-none">
          <q-select
            use-input
            emit-value
            map-options
            input-debounce="0"
            label="Centro de custo"
            @filter="filterCentrodecustos"
            v-model="centrodecusto"
            :options="centrosdecustoOptionsSelect"
            option-value="id"
            option-label="nome"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">Sem Resultados</q-item-section>
              </q-item>
            </template>
          </q-select>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            dense
            no-caps
            label="Cancelar"
            color="red"
            v-close-popup
            @click="closeDialogAddCentrodecusto()"
          />
          <q-btn
            dense
            no-caps
            label="OK"
            color="green"
            v-close-popup
            @click="addCentrodecusto()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </q-dialog>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from 'vuex';
import { Money } from "v-money";

export default {
  name: 'modal-metricas-saas-opcoes',
  components: { Money },
  data() {
    return {
      dialogAddCentrodecusto: false,
      centrodecustoPlanodecontasId: null,
      opcoes: {},
      loadingSalvaOpcoes: false,
      planodecontas: null,
      centrodecusto: null,
      planosdecontasOriginal: [],
      centrosdecustoOriginal: [],
      planosdecontasOptions: [],
      centrosdecustoOptions: [],
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    loadOpcoes() {
      axios.get('/metricas-saas/configuracoes')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.opcoes = res.data.opcoes;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao buscar as opções ou você não possui permissão',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    salvaOpcoes() {
      this.loadingSalvaOpcoes = true;
      axios
        .post('/metricas-saas/configuracoes', this.opcoes)
        .then(res => {
          this.loadingSalvaOpcoes = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.$q.notify({
            message: 'Sucesso ao salvar as opções',
            color: 'green',
            position: 'top-right',
          });

          this.closeDialogOpcoes();
        })
        .catch(() => {
          this.loadingSalvaOpcoes = false;

          this.$q.notify({
            message: 'Houve um erro ao salvar as opções ou você não possui permissão',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    closeDialogOpcoes() {
      this.planodecontas = null;
      this.centrodecusto = null;

      this.$store.dispatch('controlDialogOpcoesMetricasSaas', false);
    },
    filterPlanosdecontas(val, update) {
      let planosdecontasOriginal = JSON.parse(JSON.stringify(this.planosdecontasOriginal));

      if (val === '') {
        update(() => {
          this.planosdecontasOptions = planosdecontasOriginal;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.planosdecontasOptions = planosdecontasOriginal
          .filter(planodecontas => planodecontas.titulo.toLowerCase().indexOf(needle) > -1);
      });
    },
    filterCentrodecustos(val, update) {
      let centrosdecustoOriginal = JSON.parse(JSON.stringify(this.centrosdecustoOriginal));

      if (val === '') {
        update(() => {
          this.centrosdecustoOptions = centrosdecustoOriginal;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.centrosdecustoOptions = centrosdecustoOriginal
          .filter(centrodecustos => centrodecustos.nome.toLowerCase().indexOf(needle) > -1);
      });
    },
    loadPlanosdecontas() {
      axios
        .get('/plano-contas?cd=D')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.planosdecontasOriginal = res.data.planodecontas;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    loadCentrosdecusto() {
      axios
        .get('/centrocustos')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.centrosdecustoOriginal = res.data.centroCustos;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os centros de custo',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    addPlanodeconta() {
      if (!this.planodecontas) {
        return;
      }

      const planosdecontas = JSON.parse(this.opcoes.metricas_saas_planosdecontas);

      planosdecontas.push({
        id: this.planodecontas,
        centrosdecusto: [],
        porcentagem: 100,
      });

      this.opcoes.metricas_saas_planosdecontas = JSON.stringify(planosdecontas);
      this.planodecontas = null;
    },
    removePlanodeconta(planodecontasId) {
      const planosdecontas = JSON.parse(this.opcoes.metricas_saas_planosdecontas);
      const planodecontasIndex = planosdecontas
        .findIndex(planodecontas => planodecontas.id === planodecontasId);

      planosdecontas.splice(planodecontasIndex, 1);

      this.opcoes.metricas_saas_planosdecontas = JSON.stringify(planosdecontas);
    },
    addCentrodecusto(centrodecustoId) {
      if (!this.centrodecusto) {
        return;
      }

      const planosdecontas = JSON.parse(this.opcoes.metricas_saas_planosdecontas);
      const planodecontas = planosdecontas
        .find(planodecontas => planodecontas.id === this.centrodecustoPlanodecontasId);

      planodecontas.centrosdecusto.push({
        id: this.centrodecusto,
      });

      this.opcoes.metricas_saas_planosdecontas = JSON.stringify(planosdecontas);
      this.closeDialogAddCentrodecusto();
    },
    removeCentrodecusto(planodecontasId, centrodecustoId) {
      const planosdecontas = JSON.parse(this.opcoes.metricas_saas_planosdecontas);
      const planodecontas = planosdecontas.find(planodecontas => planodecontas.id === planodecontasId);

      const centrodecustoIndex = planodecontas.centrosdecusto.findIndex(
        centrodecusto => centrodecusto.id === centrodecustoId
      );
      planodecontas.centrosdecusto.splice(centrodecustoIndex, 1);

      this.opcoes.metricas_saas_planosdecontas = JSON.stringify(planosdecontas);
    },
    openDialogAddCentrodecusto(planodecontasId) {
      this.centrodecustoPlanodecontasId = planodecontasId;
      this.dialogAddCentrodecusto = true;
    },
    closeDialogAddCentrodecusto() {
      this.dialogAddCentrodecusto = false;
      this.centrodecusto = null;
    },
    updatePorcentagem(valor, planodecontaId) {
      valor = parseFloat(valor);

      const planosdecontas = JSON.parse(this.opcoes.metricas_saas_planosdecontas);
      const planodecontas = planosdecontas.find(planodecontas => planodecontas.id === planodecontaId);

      if (Number.isNaN(valor) || valor <= 0 || valor > 100) {
        return;
      }

      planodecontas.porcentagem = valor;

      this.opcoes.metricas_saas_planosdecontas = JSON.stringify(planosdecontas);
    }
  },
  computed: {
    ...mapGetters(['openDialogMetricasSaasOpcoes']),
    planosdecontasSelecionados() {
      if (!this.opcoes.metricas_saas_planosdecontas) {
        return [];
      }

      const planosdecontas = JSON.parse(this.opcoes.metricas_saas_planosdecontas);
      const centrosdecusto = {};

      this.centrosdecustoOriginal.forEach(centrodecusto => {
        centrosdecusto[centrodecusto.id] = centrodecusto.nome;
      });

      planosdecontas.forEach(planodecontas => {
        const planodecontasObject = this.planosdecontasOriginal.find(
          planodecontasOriginal => planodecontas.id === planodecontasOriginal.id
        );

        if (planodecontasObject) {
          planodecontas.titulo = planodecontasObject.titulo;
          planodecontas.centrosdecusto.map(centrodecusto => {
            centrodecusto.nome = centrosdecusto[centrodecusto.id];
          });
        }


        if (planodecontas.porcentagem === undefined) {
          planodecontas.porcentagem = 100;
        }
      });

      return planosdecontas;
    },
    centrosdecustoOptionsSelect() {
      if (!this.opcoes.metricas_saas_planosdecontas) {
        return [];
      }

      const planosdecontas = JSON.parse(this.opcoes.metricas_saas_planosdecontas);
      const planodecontas = planosdecontas
        .find(planodecontas => planodecontas.id === this.centrodecustoPlanodecontasId);

      if (!planodecontas) {
        return [];
      }

      const centrosdecusto = {};

      planodecontas.centrosdecusto.forEach(centrodecusto => {
        centrosdecusto[centrodecusto.id] = centrodecusto.id;
      });

      return this.centrosdecustoOptions.filter(
        centrodecusto => !(centrosdecusto[centrodecusto.id])
      );
    },
    planodecontasOptionsSelect() {
      if (!this.opcoes.metricas_saas_planosdecontas) {
        return [];
      }

      const planosdecontasSelecionados = JSON.parse(this.opcoes.metricas_saas_planosdecontas);
      const planosdecontas = {};

      planosdecontasSelecionados.forEach(planodecontas => {
        planosdecontas[planodecontas.id] = planodecontas.id;
      });

      return this.planosdecontasOptions
        .filter(planodecontas => !(planosdecontas[planodecontas.id]))
        .map(planodecontas => {
          planodecontas.disable = false;
          return planodecontas;
        });
    },
  },
  watch: {
    openDialogMetricasSaasOpcoes(value) {
      if (!value) return;

      this.loadOpcoes();
      this.loadPlanosdecontas();
      this.loadCentrosdecusto();
    },
  },
};
</script>

<style lang="stylus" scoped>
.planodeconta-item .planodeconta-item-actions {
  visibility: hidden;
}

.planodeconta-item:hover .planodeconta-item-actions {
  visibility: visible;
}
</style>
