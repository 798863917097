import { render, staticRenderFns } from "./sessoes-ativas.vue?vue&type=template&id=6f36e64a&scoped=true&"
import script from "./sessoes-ativas.vue?vue&type=script&lang=js&"
export * from "./sessoes-ativas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f36e64a",
  null
  
)

export default component.exports