<template>
  <div id="modalCaixaVendaDetalhada">
    <q-dialog v-model="openDialogVendaCaixasDetalhada" persistent>
      <q-card :style="`width: 100%; max-width: 900px`">

        <q-card-section class="row items-center">
          <div class="text-h6">Venda</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="fecharModal()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-py-xl text-center" v-if="loadingMovvenda">
          <q-spinner color="blue-7" size="4em" />
        </q-card-section>

        <q-card-section style="padding-top: 0;" v-if="!loadingMovvenda">
          <div class="row q-px-md q-col-gutter-sm">

            <div class="col-12 col-md-5">
              <q-select
                clearable
                use-input
                bottom-slots
                option-value="id"
                option-label="nome"
                label="Cliente"
                input-debounce="0"
                new-value-mode="add-unique"
                v-model="movvenda.cadastro"
                :disable="!isNovaVenda"
                :options="clientes"
                :error="Boolean(errorsCaixas.cadastro_id)"
                @new-value="createCliente"
                @filter="buscarCliente"
                @input="pulaCampo('quantidade')"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Sem resultados
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errorsCaixas.cadastro_id"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              <template v-slot:hint v-if="movvenda.cadastro">
                <span v-if="movvenda.cadastro.id">O cadastro está selecionado</span>
                <span v-else>Um novo cadastro será realizado</span>
              </template>
              </q-select>
            </div>
            <div class="col-12 col-md-3">
              <q-checkbox
                color="green"
                class="q-mt-md"
                label="Venda à Prazo"
                v-model="movvenda.is_venda_fiado"
                :disable="!isNovaVenda"
              >
              <q-btn v-show="movvenda.is_venda_fiado" icon="help_outline" flat dense round>
                <q-tooltip>
                  Venda a prazo de Loterias e Serviços devem ser deduzidas do Resumo de Sessão.<br>
                  Venda a prazo de Produtos não é deduzido do Resumo de Sessão.
                </q-tooltip>
              </q-btn>
            </q-checkbox>
            </div>

            <div
              class="col-12 col-md-4"
              v-show="movvenda.is_venda_fiado"
            >
              <q-input
                bottom-slots
                :error="Boolean(errorsCaixas.vencimento)"
                v-model="movvenda.vencimento"
                label="Vencimento"
                fill-mask
                @click="$refs.qDateProxy.show()"
                :disable="!isNovaVenda"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="movvenda.vencimento"
                        @input="() => pulaCampo('quantidade')"
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        minimal
                        today-btn
                        type="date"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errorsCaixas.vencimento"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

          </div>

          <div class="row q-px-md q-col-gutter-md" v-show="podeEditarVenda">

            <div class="col-12 col-md-2">
              <q-input
                label="Quantidade"
                v-model="movvendaItem.quantidade"
                ref="quantidade"
                @keydown.enter.prevent="pulaCampo('produto')"
              />
            </div>

            <div class="col-12 col-md-4">
              <q-select
                use-input
                clearable
                bottom-slots
                ref="produto"
                label="Produto/Loteria/Serviço"
                option-value="id"
                input-debounce="0"
                v-model="movvendaItem.produto"
                :error="Boolean(errorsCaixas.produto)"
                :options="produtosVendasOptionsFilter"
                :option-label="produto => getLabelProduto(produto)"
                @filter="buscarProduto"
                @input="pulaCampo('valor')"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Sem resultados
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errorsCaixas.produto"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-select>
            </div>
            <div class="col-12 col-md-2"  v-show="hasConcurso" >
              <q-input
                label="Concurso"
                v-model="concurso"
                ref="concurso"
                @keydown.enter.prevent="pulaCampo('valor')"
              />
            </div>

            <div :class=" hasConcurso ? 'col-12 col-md-2' : 'col-12 col-md-4'"
            @keydown.enter.prevent="addMovvendaItem()">
              <q-field
                id="valorCaixa"
                v-model="movvendaItem.vlrvenda_unit"
                label="Valor"
                bottom-slots
                :error="Boolean(errorsCaixas.valor || (errorsCaixas.movvenda_item && errorsCaixas.movvenda_item.valor))"
                :disable="movvendaItemValorDisable"
              >
                <template
                  v-slot:control="{ id, floatingLabel, value, emitValue }"
                >
                  <money
                    :id="id"
                    class="q-field__native text-right"
                    :value="value"
                    @input="emitValue"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                    :readonly="movvendaItemValorDisable"
                    ref="valor"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errorsCaixas.valor || errorsCaixas.movvenda_item.valor"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-2 text-right">
              <q-btn
                icon="add"
                color="green"
                class="q-mt-md"
                @click="addMovvendaItem()"
                :loading="loadingAdicionandoVendaItem"
              />
            </div>

          </div>
          <div class="q-mt-md">
            <q-markup-table flat bordered>
              <thead>
                <tr class="text-weight-bold">
                  <th class="text-center">Quantidade</th>
                  <th class="text-center">Produto/Loteria/Serviço</th>
                  <th class="text-center">Valor</th>
                  <th class="text-center">Total</th>
                  <th class="text-center" style="width: 82px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="movvendasItem in movvendasItems"
                  :key="movvendasItem.id"
                >
                  <td class="text-right cursor-pointer">
                    <q-icon
                      v-show="podeEditarVenda"
                      name="edit"
                      class="q-mb-xs"
                      color="grey"
                    />
                    {{ movvendasItem.quantidade }}
                    <q-popup-edit
                      v-if="podeEditarVenda"
                      v-model="movvendasItem.quantidade"
                      auto-save
                      :validate="() => movvendasItem.quantidade > 0"
                      @save="() => addMovvendaItem(movvendasItem)"
                    >
                      <q-input
                        v-model="movvendasItem.quantidade"
                        dense
                        autofocus
                        mask="####"
                      />
                    </q-popup-edit>
                  </td>
                  <td class="text-left">{{ getLabelMovvendaItem(movvendasItem) }}</td>
                  <td :class="{
                    'text-right': true,
                    'cursor-pointer': movvendasItem.produto.tipo !== 'P',
                  }">
                    <q-icon
                      v-show="podeEditarVenda"
                      name="edit"
                      class="q-mb-xs"
                      color="grey"
                      v-if="movvendasItem.produto.tipo !== 'P'"
                    />
                    R$ {{ movvendasItem.vlrvenda_unit | currency }}
                    <q-popup-edit
                      v-if="podeEditarVenda && movvendasItem.produto.tipo !== 'P'"
                      v-model="movvendasItem.vlrvenda_unit"
                      auto-save
                      :validate="() => movvendasItem.vlrvenda_unit > 0"
                      @save="() => addMovvendaItem(movvendasItem)"
                    >
                      <q-field v-model="movvendasItem.vlrvenda_unit">
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            name="valorCaixa"
                            v-show="floatingLabel"
                            autofocus
                          >
                          </money>
                        </template>
                      </q-field>
                    </q-popup-edit>
                  </td>
                  <td class="text-right">R$ {{ movvendasItem.vlrvenda_total | currency }}</td>
                  <td class="text-right tdHover">
                    <div>
                      <q-btn
                        v-if="podeEditarVenda"
                        size="sm"
                        color="red"
                        icon="delete"
                        :loading="loadingRemovendoVendaItem"
                        @click="removeMovvendaItem(movvendasItem)"
                      />
                    </div>
                  </td>
                </tr>
                <tr class="text-weight-bold" v-if="movvendasItems.length > 0">
                  <td class="text-right">{{ quantidadeTotalMovvendasItems }} itens</td>
                  <td class="text-right cursor-pointer" colspan="3">
                    <q-icon
                      v-show="podeEditarVenda"
                      name="edit"
                      class="q-mb-xs"
                      color="grey"
                    />
                    {{ totalItemsTabela }}
                    <q-popup-edit
                      v-if="podeEditarVenda"
                      v-show="!loadingAtualizarDesconto"
                      v-model="movvenda.desconto"
                      auto-save
                      :validate="() => movvenda.desconto >= 0 && movvenda.desconto < movvenda.valor"
                      @save="() => atualizarDesconto()"
                    >
                      <q-field v-model="movvenda.desconto">
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                            autofocus
                          >
                          </money>
                        </template>
                      </q-field>
                    </q-popup-edit>
                  </td>
                  <td></td>
                </tr>
                <tr v-else>
                  <td class="text-center" colspan="5">Nenhum item</td>
                </tr>
              </tbody>
            </q-markup-table>
          </div>

          <div v-if="exibirUtilizarSaldoCliente">
            <q-checkbox
              color="green"
              label="Utilizar saldo do cliente"
              :disable="!enableUtilizarSaldoCliente"
              v-model="utilizarSaldoCliente"
            />
            <q-icon name="help_outline" color="grey-8" size="sx" class="q-pl-xs" >
                <q-tooltip>
                  O mesmo que subtrair o saldo do cliente.
                </q-tooltip>
            </q-icon>
            <div v-show="exibirSaldoCliente">
              <div>
                Saldo Atual:
                <span :class="classSaldoAtualCliente">
                  R$ {{ saldoAtualCliente | currency }}
                </span>
              </div>
              <div>
                Saldo Após a Liquidação:
                <span :class="classNovoSaldoCliente">
                  R$ {{ novoSaldoCliente | currency }}
                </span>
              </div>
            </div>
            <div v-if="loadingSaldoCliente">
              <q-spinner color="grey-10" size="1em" />
            </div>
          </div>

          <div class="col-12" v-if="gatewayPixCaixa && movvenda.is_venda_fiado">
            <q-checkbox
              color="green"
              label="Pagar via PIX"
              v-model="pagarViaPix"
            />
          </div>

        </q-card-section>

        <q-card-actions align="right" class="text-primary" v-if="podeEditarVenda && !loadingMovvenda">
          <q-btn
            flat
            label="Salvar & Novo"
            clickable
            @click="salvar(true)"
            class="bg-grey-6 text-white"
            style="margin-right: 10px; margin-bottom: 10px"
            :disable="isNovaVenda"
            :loading="loadingSalvarVenda"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>

          <q-btn
            flat
            label="Salvar"
            clickable
            id="salvar"
            @click="salvar()"
            class="bg-green text-white"
            style="margin-right: 22px; margin-bottom: 10px"
            :disable="isNovaVenda"
            :loading="loadingSalvarVenda"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from '@/axios-auth';
import { Money } from 'v-money';
import { bus } from '@/main';
import moment from 'moment';

function formatNumber(number) {
  if (!number) {
    number = 0.00;
  }

  return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export default {
  name: 'modal-caixa-venda-detalhada',
  components: { Money },
  data() {
    return {
      loadingMovvenda: false,
      // Opções
      lancamento: {
        id: null,
        ativo: true,
      },
      // Venda
      movvenda: {
        id: null,
        finalizada: false,
        is_venda_fiado: false,
        vencimento: moment().format('DD/MM/YYYY'),
        dtmovimento: null,
        funcionario_id: null,
        cadastro: null,
        cadastro_id: null,
        movcaixasaldo_id: null,
        descricao: null,
        valor: null,
        observacao: '',
        desconto: 0.00,
      },
      // Vendas Itens
      movvendaItem: {
        id: null,
        quantidade: 1,
        produto: null,
        vlrvenda_unit: 0.00,
      },
      movvendasItems: [],
      // Configurações
      loadingAdicionandoVendaItem: false,
      loadingRemovendoVendaItem: false,
      loadingSalvarVenda: false,
      loadingAtualizarDesconto: false,
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      // Autocomplete
      autocompleteResult: {},
      // Opções de Select
      clientes: [],
      produtosVendaOptions: [],
      produtosVendasOptionsFilter: [],
      // Saldo do cliente
      utilizarSaldoCliente: false,
      loadingSaldoCliente: false,
      saldoAtualCliente: 0.00,
      // PIX
      pagarViaPix: false,
      hasConcurso: false,
      concurso: null,

    };
  },
  methods: {
    // Init
    init(movcaixasaldo = null) {
      this.loadProdutosVendaOptions();
      this.resetModal();

      this.loadMovcaixasaldo(movcaixasaldo);
    },
    loadMovcaixasaldo(movcaixasaldo) {
      if (!movcaixasaldo) return;

      this.lancamento.id = movcaixasaldo.id;
      this.lancamento.ativo = movcaixasaldo.ativo;
      this.loadMovvenda();
    },
    // Modal
    resetModal() {
      this.lancamento = {
        id: null,
        ativo: true,
      };
      this.movvenda = {
        id: null,
        finalizada: false,
        is_venda_fiado: false,
        vencimento: moment().format('DD/MM/YYYY'),
        dtmovimento: null,
        funcionario_id: null,
        cadastro: null,
        cadastro_id: null,
        movcaixasaldo_id: null,
        descricao: null,
        valor: null,
        observacao: '',
        desconto: 0.00,
      };
      this.movvendasItems = [];
      this.utilizarSaldoCliente = false;
      this.pagarViaPix = false;
      this.$store.commit('errorsCaixas', {});
      this.resetMovvendaItem();
    },
    resetMovvendaItem() {
      this.concurso = null;
      this.movvendaItem = {
        id: null,
        quantidade: 1,
        produto: null,
        concurso: null,
        vlrvenda_unit: 0.00,
      };
    },
    fecharModal() {
      this.$store.commit('setOpenDialogVendaCaixasDetalhada', false);
    },
    salvar(salvarENovo = false) {
      this.$q.dialog({
        title: 'Salvar venda',
        message: 'A venda não poderá ser alterada depois, deseja salvar?',
        cancel: 'Cancelar',
        persistent: true,
      }).onOk(() => {
        const data = {
        // Lançamento
        tipo: 11,
        id: this.lancamento.id,
        nome: '',
        valor: 0.01,
        // Movvenda
        movvenda_id: this.movvenda.id,
        movvenda_desconto: this.movvenda.desconto,
        // Ação
        atualizar_venda: 'salvar',
        utilizar_saldo_cliente: this.utilizarSaldoCliente,
        pagar_via_pix: this.pagarViaPix,
      };
      this.loadingSalvarVenda = true;

      axios
        .post(`/lancamentos/add-saldo/${this.movcaixa.id}`, data)
        .then(res => {
          this.loadingSalvarVenda = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) return;

          this.fecharModal();

          if (salvarENovo) {
            setTimeout(() => {
              this.$store.dispatch("openDialogVendaCaixasDetalhada", null);
            }, 1);
            return;
          }

          this.$store.dispatch('atualizarListaLancamentos', this.movcaixa.id);

          if (res.data.qrcode) {
            bus.$emit("openModalQrcodeVenda", res.data);
          }
        })
        .catch(() => {
          this.loadingSalvarVenda = false;

          this.$q.notify({
            message: 'Houve um erro ao salvar venda',
            color: 'red',
            position: 'top-right',
          });
        });
      });
    },
    atualizarDesconto() {
        const data = {
        // Lançamento
        tipo: 11,
        id: this.lancamento.id,
        nome: '',
        valor: 0.01,
        // Movvenda
        movvenda_id: this.movvenda.id,
        movvenda_desconto: this.movvenda.desconto,
        // Ação
        atualizar_venda: 'desconto',
      };
      this.loadingAtualizarDesconto = true;

      axios
        .post(`/lancamentos/add-saldo/${this.movcaixa.id}`, data)
        .then(res => {
          this.loadingAtualizarDesconto = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
           return;
          }
        })
        .catch(() => {
          this.loadingAtualizarDesconto = false;

          this.$q.notify({
            message: 'Houve um erro ao atualizar desconto',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    // Autocomplete
    recebeEmitDados(result) {
      this.autocompleteResult = result;

      if (this.autocompleteResult && this.autocompleteResult.id != null) {
        this.$q.notify({
          message: this.autocompleteResult.name + ' foi selecionado.',
          color: 'green',
          position: 'top-right'
        });
      }
    },
    updateAutocompleteCadastro(cadastro){
      this.autocompleteResult = cadastro;

      bus.$emit('updateAutocompleteField', {
        id: cadastro.id,
        name: cadastro.nome,
        label: cadastro.nome,
        canEdit: true,
      });
    },
    // Opções Selects
    loadProdutosVendaOptions() {
      axios
        .get(`/lancamentos/produtos-venda/${this.movcaixa.id}`)
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.produtosVendaOptions = res.data.produtos_venda;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os produtos para venda',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    buscarCliente(val, update) {
      update(() => {
        axios
          .get(`/cadastros?ativo=1&cliente=1&busca=${val}`)
          .then(res => {
            if (!res.data.success) return;
            this.clientes = res.data.data.cadastros;
          });
      });
    },
    buscarProduto(val, update) {
      update(() => {
        const tipos = {
          "P": "Produtos",
          "L": "Loterias",
          "S": "Serviços",
        };
        const produtosVendaPorTipo = {
          "P": [],
          "L": [],
          "S": [],
        };
        const produtosVendasOptionsFilter = [];

        this.produtosVendaOptions.forEach(produto => {
          if (this.getLabelProduto(produto).toLowerCase().indexOf(val.toLowerCase()) > -1) {
            produtosVendaPorTipo[produto.tipo].push(produto);
          }
        });

        for(let tipo in produtosVendaPorTipo) {
          const produtosVenda = produtosVendaPorTipo[tipo];

          if (produtosVenda.length > 0) {
            produtosVendasOptionsFilter.push({
              nome: tipos[tipo],
              disable: true,
            });
            produtosVendasOptionsFilter.push(...produtosVenda);
          }
        }

        this.produtosVendasOptionsFilter = produtosVendasOptionsFilter;
      });
    },
    createCliente(val, done) {
      let newCadastro = {
        id: null,
        nome: val,
      };
      done(newCadastro, "add-unique");
    },
    // Venda Itens
    validateAddItem() {
      let errorsCaixas = {};

      if (!this.movvenda.cadastro_id) {
        errorsCaixas.cadastro_id = ['O cliente é obrigatório'];
      }
      if (this.movvenda.is_venda_fiado && !this.movvenda.vencimento) {
        errorsCaixas.cadastro_id = ['A data de vencimento é obrigatória'];
      }
      if (!this.movvendaItem.produto) {
        errorsCaixas.produto = ['O produto é obrigatório'];
      }
      if (this.movvendaItem.quantidade <= 0) {
        errorsCaixas.quantidade = ['Quantidade inválida'];
      }
      if (this.movvendaItem.produto.tipo !== 'P' && this.movvendaItem.vlrvenda_unit <= 0) {
        errorsCaixas.valor = ['Valor inválido'];
      }

      return errorsCaixas;
    },
    addCadastro() {
      const data = JSON.parse(JSON.stringify(this.movvenda.cadastro));
      data.cliente = true;

      axios
        .post("/cadastros/add", data)
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            return;
          }

          const { cadastro } = res.data.data;

          this.movvenda.cadastro = cadastro;
          this.movvenda.cadastro_id = cadastro.id;

          this.addMovvendaItem();
        })
        .catch(() => {
          this.$q.notify({
            message: "Não foi salvar o cadastro, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    addMovvendaItem(movvendaItem = null) {
      if (this.loadingAdicionandoVendaItem) return;
      this.updateValorMovvendaItemProduto();

      if (this.movvenda.cadastro && !this.movvenda.cadastro.id) {
        this.addCadastro();
        return;
      }

      if (!movvendaItem) {
        this.movvendaItem.produto.concurso = this.concurso;
        movvendaItem = JSON.parse(JSON.stringify(this.movvendaItem));
        const errors = this.validateAddItem();
        if (Object.keys(errors).length > 0) {
          this.$store.commit('errorsCaixas', errors);
          return;
        }
      } else {
        movvendaItem = JSON.parse(JSON.stringify(movvendaItem));

        movvendaItem.produto = {
          produto_id: movvendaItem.produto_id,
          tpcontrole: movvendaItem.tpcontrole,
          extracao: movvendaItem.extracao,
          vencimento: movvendaItem.vencimento,
          concurso: movvendaItem.concurso,
          evento_id: movvendaItem.evento_id,
        };
      }

      this.loadingAdicionandoVendaItem = true;

      const data = {
        // Lançamento
        tipo: 11,
        id: this.lancamento.id,
        cadastro_id: typeof this.movvenda.cadastro_id === 'object' ? this.movvenda.cadastro_id.id : this.movvenda.cadastro_id,
        nome: '',
        valor: movvendaItem.vlrvenda_unit,
        is_venda_fiado: this.movvenda.is_venda_fiado,
        vencimento: this.movvenda.vencimento,
        // Movvenda
        movvenda_id: this.movvenda.id,
        // Movvenda Item
        movvenda_item_id: movvendaItem.id,
        movvenda_item_produto_id: movvendaItem.produto.produto_id,
        movvenda_item_quantidade: movvendaItem.quantidade,
        movvenda_item_tpcontrole: movvendaItem.produto.tpcontrole,
        movvenda_item_extracao: movvendaItem.produto.extracao,
        movvenda_item_vencimento: movvendaItem.produto.vencimento,
        movvenda_item_concurso: movvendaItem.produto.concurso,
        movvenda_item_evento_id: movvendaItem.produto.evento_id,
        movvenda_item_valor: Math.abs(movvendaItem.vlrvenda_unit),
      };

      axios
        .post(`/lancamentos/add-saldo/${this.movcaixa.id}`, data)
        .then(res => {
          this.loadingAdicionandoVendaItem = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            this.$store.commit('errorsCaixas', res.data.errors || {});
            return;
          }

          this.lancamento = res.data.movcaixasaldo;

          this.setMovvenda(res.data.movvenda);
          this.movvendasItems = res.data.movvendas_items;
          this.$store.commit('errorsCaixas', {});
          this.$store.dispatch('atualizarListaLancamentos', this.movcaixa.id);

          this.resetMovvendaItem();
          this.pulaCampo('quantidade');
        })
        .catch(() => {
          this.loadingAdicionandoVendaItem = false;

          this.$q.notify({
            message: 'Houve um erro ao adicionar item',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    removeMovvendaItem(movvendasItem) {
      if (this.loadingRemovendoVendaItem) return;

      this.loadingRemovendoVendaItem = true;

      const data = {
        movvenda_item_id: movvendasItem.id,
        movcaixasaldos_id: this.movvenda.movcaixasaldo_id
      };

      axios
        .post(`/lancamentos/delete-saldo/${this.movcaixa.id}`, data)
        .then(res => {
          this.loadingRemovendoVendaItem = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            this.$store.commit('errorsCaixas', res.data.errors || {});
            return;
          }

          this.movvendasItems = res.data.movvendas_items;
          this.movvenda.valor = res.data.totalMovvenda;
          this.$store.dispatch('atualizarListaLancamentos', this.movcaixa.id);
        })
        .catch(() => {
          this.loadingRemovendoVendaItem = false;

          this.$q.notify({
            message: 'Houve um erro ao remover item',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    loadMovvenda() {
      this.loadingMovvenda = true;

      axios
        .get(`/lancamentos/get-venda-detalhada/${this.lancamento.id}`)
        .then(res => {
          this.loadingMovvenda = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.setMovvenda(res.data.movvenda);
          this.movvendasItems = res.data.movvenda.movvendas_items;
        })
        .catch(() => {
          this.loadingMovvenda = false;

          this.$q.notify({
            message: 'Houve um erro ao recuperar venda',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    // Helpers
    getLabelProduto(produto) {
      const labelArray = [];

      if (produto.quantidade > 0) {
        labelArray.push(`${produto.quantidade} -`);
      }

      labelArray.push(produto.nome);

      switch (produto.tpcontrole) {
        case 'Extração':
          labelArray.push('Extração');

          if (produto.extracao) {
            labelArray.push(produto.extracao);
          }
          if (produto.vencimento && moment(produto.vencimento, 'YYYY-MM-DD').isValid()) {
            labelArray.push(moment(produto.vencimento, 'YYYY-MM-DD').format('DD/MM/YYYY'));
          }
          break;

        case 'Concurso':
          //labelArray.push('Concurso');

          if (produto.concurso) {
            //labelArray.push(produto.concurso);
          }
          break;

        case 'Evento':
          labelArray.push('Evento');

          if (produto.evento) {
            labelArray.push(produto.evento);
          }
          break;
      }

      return labelArray.join(' ');
    },
    getLabelMovvendaItem(movvendasItem) {
      const labelArray = [movvendasItem.produto.nome];

      switch (movvendasItem.tpcontrole) {
        case 'Extração':
          labelArray.push('Extração');

          if (movvendasItem.extracao) {
            labelArray.push(movvendasItem.extracao);
          }
          if (movvendasItem.vencimento && moment(movvendasItem.vencimento, 'YYYY-MM-DD').isValid()) {
            labelArray.push(moment(movvendasItem.vencimento, 'YYYY-MM-DD').format('DD/MM/YYYY'));
          }
          break;

        case 'Concurso':
          //labelArray.push('Concurso');

          if (movvendasItem.concurso) {
            labelArray.push(movvendasItem.concurso);
          }
          break;

        case 'Evento':
          labelArray.push('Evento');

          if (movvendasItem.evento) {
            labelArray.push(movvendasItem.evento);
          }
          break;
      }

      return labelArray.join(' ');
    },
    pulaCampo(campo, segundos = 1) {
      if (campo === 'valor' && this.movvendaItemValorDisable) {
        return this.addMovvendaItem();
      }

      const selecionar = field => {
        if (field.select) {
          setTimeout(() => {
            field.select();
          });
        }
      };

      const focar = () => {
        if (!this.$refs[campo]) {
          return;
        }

        if (this.$refs[campo].show) {
          return this.$refs[campo].show();
        }

        if (this.$refs[campo].$el.classList.contains("v-money")){
          this.$refs[campo].$el.focus();
          selecionar(this.$refs[campo].$el);
          return;
        }

        selecionar(this.$refs[campo]);
        this.$refs[campo].focus();

        if (this.$refs[campo].showPopup) {
          this.$refs[campo].showPopup();
        }
        return;
      };

      if (segundos) {
        return setTimeout(() => focar(), segundos);
      }

      focar();
    },
    setMovvenda(movvenda) {
      movvenda.cadastro_id = movvenda.cadastro;

      if (movvenda.vencimento) {
        movvenda.vencimento = moment(movvenda.vencimento, 'YYYY/MM/DD').format('DD/MM/YYYY');
      }

      this.movvenda = movvenda;
    },
    updateValorMovvendaItemProduto() {
      if (this.movvendaItemValorDisable) {
        this.movvendaItem.vlrvenda_unit = this.movvendaItem.produto.valor;
      }
    },
    loadSaldoCliente() {
      if (!this.extratoClienteInstalado || this.loadingSaldoCliente) return;

      this.loadingSaldoCliente = true;

      const cadastroId = this.movvenda.cadastro_id;

      axios
        .get(`/financeiro/extrato-cliente/${cadastroId}?apenas_saldo=1`)
        .then(res => {
          this.loadingSaldoCliente = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.saldoAtualCliente = res.data.saldo_atual;
        })
        .catch(() => {
          this.loadingSaldoCliente = false;
          this.$q.notify({
            message: 'Houve um erro ao consultar o extrato do cliente',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    updateSaldoCliente() {
      if (!this.movvenda.cadastro_id || !this.movvenda.is_venda_fiado) {
        this.saldoAtualCliente = 0.00;
        return;
      }

      this.loadSaldoCliente();
    },
  },
  computed: {
    ...mapGetters([
      'errorsCaixas',
      'cadastroReturn',
      'movcaixa',
      'openDialogVendaCaixasDetalhada',
      'vendaCaixasDetalhada',
      'extratoClienteInstalado',
      'gatewayPixCaixa',
    ]),
    movvendaItemValorDisable() {
      if (!this.movvendaItem.produto) return false;

      return this.movvendaItem.produto.tipo === "P";
    },
    isNovaVenda() {
      return this.movvenda.id === null;
    },
    quantidadeTotalMovvendasItems() {
      return this.movvendasItems.reduce((total, movvendaItem) => movvendaItem.quantidade + total, 0);
    },
    totalItemsTabela() {
      const valorTotal = formatNumber(this.movvenda.valor);

      if (!this.movvenda.desconto) {
        return valorTotal;
      }

      const desconto = formatNumber(this.movvenda.desconto);
      const valorVenda = formatNumber(this.movvenda.valor - this.movvenda.desconto);

      return `${valorTotal} - ${desconto} = ${valorVenda}`;
    },
    podeEditarVenda() {
      return !this.movvenda.finalizada && this.lancamento.ativo;
    },
    exibirUtilizarSaldoCliente() {
      return this.extratoClienteInstalado && this.movvenda.is_venda_fiado && this.podeEditarVenda;
    },
    enableUtilizarSaldoCliente() {
      const valor = this.movvenda.valor - this.movvenda.desconto;
      return valor > 0;
    },
    novoSaldoCliente() {
      const valor = this.movvenda.valor - this.movvenda.desconto;
      return this.saldoAtualCliente - parseFloat(valor);
    },
    classSaldoAtualCliente() {
      if (this.saldoAtualCliente > 0) return 'text-green';
      if (this.saldoAtualCliente < 0) return 'text-red';

      return '';
    },
    classNovoSaldoCliente() {
      if (this.novoSaldoCliente > 0) return 'text-green';
      if (this.novoSaldoCliente < 0) return 'text-red';

      return '';
    },
    exibirSaldoCliente() {
      return this.movvenda.is_venda_fiado && this.movvenda.cadastro_id && !this.loadingSaldoCliente;
    },
  },
  watch: {
    'movvendaItem.produto'() {
      this.updateValorMovvendaItemProduto();

      if (this.movvendaItem.produto && this.movvendaItem.produto.tpcontrole == 'Concurso') {
        this.hasConcurso = true;
        this.pulaCampo('concurso');
      } else {
        this.hasConcurso = false;
      }
    },
    'movvenda.cadastro'() {
      if (this.movvenda.cadastro) {
        this.movvenda.cadastro_id = this.movvenda.cadastro.id;
      }
    },
    openDialogVendaCaixasDetalhada() {
      if (!this.openDialogVendaCaixasDetalhada) return;

      this.init(this.vendaCaixasDetalhada);
    },
    "movvenda.cadastro_id"() {
      this.updateSaldoCliente();
    },
    "movvenda.is_venda_fiado"() {
      this.updateSaldoCliente();
    },
  },
  mounted() {
    bus.$on('novoCadastroAdicionado', () => {
      this.updateAutocompleteCadastro(this.cadastroReturn);
    });

    document.addEventListener('keyup', event => {
      if (event.keyCode === 27) { // Esc
        this.fecharModal();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener('keyup');

    bus.$off('novoCadastroAdicionado');

    next();
  },
};
</script>

<style scoped>
.tdHover div {
  display: none;
}

tr:hover .tdHover div {
  display: initial;
}
</style>
