<template>
  <div id="modalLiquidarMovconta">
    <q-dialog v-model="OpenDialogLiquidarMovconta" persistent>
      <q-card :style="`width: 100%; max-width: ${opcoesAvancadas ? '1200px' : '600px'};`">
        <q-card-section class="row items-center">
          <div class="text-h6">{{ title() }}</div> <span class="text-h6" v-if="movconta.cadastro">&nbsp;- {{movconta.cadastro.nome.substr(0, 30)}}</span>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />

        <q-card-section class="row q-col-gutter-lg">
          <div :class="`col-12 col-md-${opcoesAvancadas ? 6 : 12} q-pb-md`">
            <p class="text-weight-regular q-mb-none" v-if="movconta.cadastro && movconta.cadastro.codigo">
              Código Cadastro <b>{{ movconta.cadastro.codigo }}</b>
            </p>
            <p class="text-weight-regular q-mb-none" v-if="movconta.gateway && movconta.gateway.titulo">
              Forma de pagamento <b>{{ movconta.gateway.titulo }}</b>
            </p>
            <p class="text-weight-regular q-mb-none" v-if="movconta.cadastros_gateway && movconta.cadastros_gateway.codigo">
              Codigo <b>{{ movconta.cadastros_gateway.codigo }}</b>
            </p>
            <p class="text-weight-regular q-mb-none" v-if="movconta.cadastros_gateway">
              Conta <b>{{movconta.cadastros_gateway.agencia}} {{movconta.cadastros_gateway.operacao}} {{movconta.cadastros_gateway.conta}}</b><br>
              Banco {{movconta.cadastros_gateway.banco}}
            </p>
            <p class="text-weight-regular q-mb-none" v-if="movconta.tipo === 2">
              Descrição <b>{{ movconta.descricao }}</b>
            </p>
            <p class="text-weight-regular q-mb-none" v-if="movconta.tipo === 2">
              Vencimento <b>{{ movconta.vencimento }}</b>
            </p>
            <p class="text-weight-regular q-mb-none" v-if="movconta.tipo === 2">
              Valor <b>R$ {{ movconta.valor | currency }}</b>
            </p>

            <div class="row q-col-gutter-x-md q-mt-md">
              <div class="col-12 q-mb-md" v-if="movconta.tipo === 1">
                <q-input
                  v-model="movconta.descricao"
                  label="Descrição"
                  bottom-slots
                  :error="Boolean(errors.descricao)"
                  dense
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.descricao" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-input>
              </div>

              <div class="col-6 q-mb-md" v-if="movconta.tipo === 1">
                <q-input
                  bottom-slots
                  :error="Boolean(errors.vencimento)"
                  v-model="movconta.vencimento"
                  label="Vencimento"
                  @click="$refs.qDateVencimentoProxy.show()"
                  dense
                  >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateVencimentoProxy"
                        transition-show="scale"
                        transition-hide="scale"
                        >
                        <q-date
                          v-model="movconta.vencimento"
                          @input="() => $refs.qDateVencimentoProxy.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.vencimento"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>

              <div class="col-6 q-mb-md" v-if="movconta.tipo === 1">
                <q-field
                  v-model="movconta.valor"
                  label="Valor"
                  bottom-slots
                  :error="Boolean(errors.valor)"
                  dense
                  >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.valor" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-6 q-mb-md">
                <q-input
                  bottom-slots
                  :error="errors.liquidacao ? true : false"
                  v-model="movconta.liquidacao"
                  label="Data de Liquidação"
                  @click="$refs.qDateProxy.show()"
                  dense
                  >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="movconta.liquidacao"
                          @input="() => $refs.qDateProxy.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.liquidacao"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>

              <div class="col-6 q-mb-md">
                <q-input
                  bottom-slots
                  label="Data de Movimento"
                  :error="errors.dtmovimento ? true : false"
                  v-model="movconta.dtmovimento"
                  @click="$refs.qDtmovimentoRef.show()"
                  dense
                  >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDtmovimentoRef"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          minimal
                          today-btn
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          v-model="movconta.dtmovimento"
                          @input="() => $refs.qDtmovimentoRef.hide()"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.dtmovimento"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>

              <div class="col-4 q-mb-md">
                <q-field
                  v-model="movconta.valor_acrecimo"
                  label="Valor Acréscimo"
                  bottom-slots
                  :error="errors.valor_acrecimo ? true : false"
                  dense
                  >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.valor_acrecimo" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-4 q-mb-md">
                <q-field
                  v-model="movconta.valor_desconto"
                  label="Valor Desconto"
                  bottom-slots
                  :error="errors.valor_desconto ? true : false"
                  dense
                  >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.valor_desconto" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-4 q-mb-md">
                <q-field
                  v-model="movconta.valor_liquidado"
                  label="Valor Pago"
                  bottom-slots
                  :error="errors.valor_liquidado ? true : false"
                  dense
                  >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.valor" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-12 q-mb-md">
                <q-select
                  use-input
                  clearable
                  v-model="planodeconta"
                  option-label="titulo"
                  option-value="id"
                  @filter="planoContasFilter"
                  :options="planoContasFilterResult"
                  label="Plano de Conta"
                  dense
                  >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12 q-mb-md">
                <q-select
                  v-model="movconta.conta_id"
                  option-label="titulo"
                  option-value="id"
                  :options="contas"
                  label="Conta Bancária: "
                  clearable
                  emit-value
                  map-options
                  id="contaDespesa"
                  dense
                  >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12" v-if="movconta.tipo == 1 && movconta.conta && movconta.conta.tipo === 'banco-dourasoft'">
                <q-checkbox
                  dense
                  v-model="agendar"
                  clickable
                  label=""
                  color="green"
                />
                Agendar o Pagamento para o dia {{ movconta.liquidacao }} ?<br>
                O Gestor precisará autorizar o pagamento.
              </div>

              <div class="col-4" v-if="agendar && movconta.tipo == 1 && movconta.conta && movconta.conta.tipo === 'banco-dourasoft'">
                <q-select
                  v-model="agendarForma"
                  label="Forma"
                  bottom-slots
                  dense
                  :options="['Código de Barras', 'Chave Pix', 'Pix Copia e Cola']"
                >
                </q-select>
              </div>

              <div class="col-8" v-if="agendar && movconta.tipo == 1 && movconta.conta && movconta.conta.tipo === 'banco-dourasoft'">
                <q-input
                  v-model="agendarTxtPagar"
                  :label="agendarForma"
                  bottom-slots
                  :error="Boolean(errors.agendar_txt_pagar)"
                  dense
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.agendar_txt_pagar" :key="error + i">
                      {{ error }}
                    </span>
                  </template>
                </q-input>
              </div>

              <div class="col-12" v-if="exibirUtilizarSaldoCliente">
                <q-checkbox
                  color="green"
                  label="Utilizar saldo do cliente"
                  v-model="utilizarSaldoCliente"
                  dense
                />

                <div v-show="exibirSaldoCliente">
                  <div>
                    Saldo Atual:
                    <span :class="classSaldoAtualCliente">
                      R$ {{ saldoAtualCliente | currency }}
                    </span>
                  </div>
                  <div>
                    Saldo Após a Liquidação:
                    <span :class="classNovoSaldoCliente">
                      R$ {{ novoSaldoCliente | currency }}
                    </span>
                  </div>
                </div>
                <div v-if="loadingSaldoCliente">
                  <q-spinner color="grey-10" size="1em" />
                </div>
              </div>

              <div class="col-12 q-mb-md" v-if="exibirCriarNovaMovconta">
                <q-checkbox
                  color="green"
                  v-model="gerarNovaMovconta"
                  :label="`Criar outra ${nomeItem()} com valor restante?`"
                  dense
                />
              </div>

              <div class="col-12 q-mb-md" v-if="exibirCriarNovaMovconta && gerarNovaMovconta">
                <q-input
                  bottom-slots
                  :error="errors.novoVencimento ? true : false"
                  v-model="novoVencimento"
                  label="Nova data de vencimento"
                  @click="$refs.qDateNovoVencimento.show()"
                  dense
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateNovoVencimento"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="novoVencimento"
                          @input="() => $refs.qDateNovoVencimento.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.novoVencimento"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>

              <div class="col-12 q-mb-md" v-if="exibirCriarNovaMovconta && gerarNovaMovconta">
                <q-field
                  disable
                  v-model="valorRestante"
                  label="Valor Restante"
                  bottom-slots
                  :error="errors.valorRestante ? true : false"
                  dense
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.valor" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-12 q-mt-md" v-if="hasOpcoesAvancadas">
                <q-checkbox
                  color="green"
                  label="Opções Avançadas"
                  v-model="opcoesAvancadas"
                  dense
                />
              </div>
            </div>

          </div>

          <div class="col-12 col-md-6" v-show="opcoesAvancadas">
            <div class="row" v-if="exibirCentrosdecusto">

              <div class="col-12 q-mb-md">
                <q-select
                  v-model="newMovcontaRateio.centrodecusto"
                  :option-label="value => value === null ? 'Item Vazio' : value.nome"
                  :option-value="value => value === null ? null : value"
                  :options="optionsCentrosdecusto"
                  label="Centro de Custo"
                  :hide-bottom-space="!Boolean(errors.centrodecusto)"
                  :error="Boolean(errors.centrodecusto)"
                  dense
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span>{{ errors.centrodecusto }}</span>
                  </template>
                </q-select>
              </div>

              <div class="col-2 flex items-end">
                <q-btn
                  color="white"
                  text-color="black"
                  label="%"
                  disable
                />
              </div>

              <div class="col-8">
                <q-field
                  v-model="newMovcontaRateio.porcentagem"
                  label="Porcentagem"
                  :hide-bottom-space="!Boolean(errors.porcentagem)"
                  :error="Boolean(errors.porcentagem)"
                  dense
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="percentFormat"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span>{{ errors.porcentagem }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-2 flex items-end justify-end">
                <q-btn
                  icon="add"
                  color="primary"
                  flat
                  round
                  @click="addMovcontaRateio()"
                />
              </div>

              <div class="col-12 q-mt-md">
                <p
                  v-if="porcentagemMovcontasRateioTotal > 100"
                  class="text-negative caption"
                >
                  A porcentagem total passa de 100%
                </p>
                <div v-if="Boolean(errors.movcontas_rateios)">
                  <p
                    class="text-negative caption"
                    v-for="(error, index) in errors.movcontas_rateios"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>

                <q-markup-table
                  flat
                  dense
                >
                  <tbody>
                    <tr
                      v-for="(rateio, index) in movconta.movcontas_rateios"
                      :key="index"
                    >
                      <td>{{ rateio.centrocusto.nome }}</td>
                      <td class="text-right">{{ rateio.porcentagem }} %</td>
                      <td style="width: 30px;">
                        <q-btn
                          flat
                          dense
                          color="red"
                          icon="delete"
                          @click="movconta.movcontas_rateios.splice(index, 1)"
                        />
                      </td>
                    </tr>
                    <tr v-if="movconta.movcontas_rateios.length > 0">
                      <td colspan="2"><strong>Total</strong></td>
                      <td class="text-right">{{ porcentagemMovcontasRateioTotal }} %</td>
                    </tr>
                    <tr v-if="movconta.movcontas_rateios.length === 0">
                      <td colspan="3" class="text-center">Nenhum rateio</td>
                    </tr>
                  </tbody>
                </q-markup-table>
              </div>

              <div class="col-12 q-mt-md" v-if="hasOpcoesAvancadas">
                <div
                  class="q-uploader"
                  style="width: 100%; box-shadow: none;overflow:hidden"
                  @dragleave="onDragLeave"
                  @mouseover="onHover"
                  @mouseleave="offHover"
                  @dragover="onDragOver"
                  @drop.stop.prevent="addFile"
                >
                  <div class="q-uploader__header">
                    <div class="header-upload">
                      <a v-if="arrayFile && arrayFile.length > 0">
                        <div tabindex="-1" class="q-focus-helper"></div>
                        <q-btn
                          dense
                          flat
                          icon="clear_all"
                          @click="clearFiles"
                        />
                      </a>
                      <div :class="!this.$q.platform.is.mobile ?  'layout' :'layout-responsive'">
                        <div  v-if="!this.$q.platform.is.mobile">
                          <div class="q-uploader__title">Anexar imagens e documentos</div>
                        </div>

                        <div class="icones-layout">
                            <label for="file"  v-if="this.$q.platform.is.mobile">
                              <i
                                class="material-icons"
                                style="font-size: 20px; cursor: pointer; float: right"
                              >
                                add_a_photo
                              </i>
                              <input
                                accept="image/*"
                                capture="camera"
                                type="file"
                                id="file"
                                style="width: 0"
                                multiple
                                class="file"
                                @change="addFile"
                              />
                            </label>

                            <label v-else>
                              <i
                                @click="openModal"
                                class="material-icons"
                                style="font-size: 20px; cursor: pointer;"
                              >
                                add_a_photo
                              </i>
                            </label>

                            <label>
                              <i v-if="!this.$q.platform.is.mobile"
                                class="material-icons"
                                style="font-size: 20px; cursor: pointer">
                                cloud_upload
                              </i>
                              <input
                                type="file"
                                id="file"
                                style="width: 0"
                                multiple
                                class="file"
                                @change="addFile"
                              />
                            </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="q-uploader__list scroll" style="border: 1px solid #ccc;">
                    <div v-for="(file, i) in arrayFile" :key="file.name" style="margin-bottom: 8px">
                      <div class="q-uploader__file relative-position">
                        <div class="q-uploader__file-header row flex-center no-wrap">
                          <i
                            v-if="file.error"
                            aria-hidden="true"
                            class="material-icons q-icon text-negative q-uploader__file-status"
                          >
                            warning
                          </i>
                          <div class="q-uploader__file-header-content col">
                            <div class="q-uploader__title">{{file.fileName}}</div>
                              <div class="q-uploader__subtitle row items-center no-wrap">
                                {{file.fileSize}}
                              </div>
                            </div>
                            <q-btn
                              round
                              dense
                              flat
                              icon="clear"
                              @click="apagaItem(i)"
                              :loading="file.uploading"
                            >
                              <template v-slot:loading>
                                <q-spinner color="grey-10" size="1em" />
                              </template>
                            </q-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                <div v-if="(arrayFile.length > 0)" class="q-mt-md">
                  <div class="text-h6">Anexos</div>

                  <q-markup-table
                    class="q-mt-sm"
                    style="border: 1px solid #ccc"
                    flat
                    dense
                  >
                    <tbody>
                      <tr
                        v-for="(file, index) in arrayFile"
                        :key="index"
                      >
                        <td>{{ file.fileName }}</td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveLiquidar"
            :loading="loadLiquidar"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
import { bus } from "@/main";
import moment from "moment";
import axios from "@/axios-auth";

export default {
  name: "modal-liquidar-movconta",
  components: { Money },
  props: {
    tipoConta: String
  },
  data() {
    return {
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      planoContasFilterResult: [],
      gerarNovaMovconta: false,
      novoVencimento: "",
      valorRestante: 0,
      triggerPlanodecontas: null,
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false,
      },
      newMovcontaRateio: {
        centrodecusto: null,
        porcentagem: 0,
      },
      utilizarSaldoCliente: false,
      loadingSaldoCliente: false,
      saldoAtualCliente: 0.00,
      selectedFiles: [],
      arrayFile: [],
      agendar: false,
      agendarForma: "Código de Barras",
      agendarTxtPagar: "",
    };
  },
  methods: {
    planoContasFilter(val, update) {
      update(() => {
        this.planoContasFilterResult = this.planoContas.filter(
          plano => plano.titulo.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
    saveLiquidar() {
      let movconta = JSON.parse(JSON.stringify(this.movconta));

      if (this.exibirCriarNovaMovconta) {
        movconta.gerarNovaMovconta = this.gerarNovaMovconta;
        movconta.novoVencimento = this.novoVencimento;
        movconta.valorRestante = this.valorRestante;
      }

      if (movconta.planodeconta_id && typeof movconta.planodeconta_id === "object") {
        movconta.planodeconta_id = movconta.planodeconta_id.id;
      }

      if (movconta.tipo === 1) {
        if (this.exibirCentrosdecusto) {
          movconta.movcontas_rateios = movconta.movcontas_rateios.map(rateio => ({
            porcentagem: rateio.porcentagem,
            centrocusto_id: rateio.centrocusto.id,
          }));
        }

        if (movconta.conta && movconta.conta.tipo === "banco-dourasoft" && this.agendar) {
          movconta.agendar = this.agendar;
          movconta.agendar_forma = this.agendarForma;
          movconta.agendar_txt_pagar = this.agendarTxtPagar;
        }

        this.$store.dispatch("saveLiquidarDespesas", movconta);

        if (this.selectedFiles.length > 0) {
          this.selectedFiles.forEach((file, index) => {
            this.uploadImages(file, this.arrayFile[index]);
          });
        }
      } else if (movconta.tipo === 2) {
        movconta.utilizar_saldo_cliente = this.utilizarSaldoCliente;
        this.$store.dispatch("saveLiquidarReceitas", movconta);
      }

      if (movconta.planodeconta_id && typeof movconta.planodeconta_id === "object") {
        this.movconta.planodeconta_id = movconta.planodeconta_id.id;
      } else {
        this.movconta.planodeconta_id = movconta.planodeconta_id;
      }

      this.$emit("listarCallback", true);
    },
    closeModal() {
      this.movconta.planodeconta_id = null;
      this.utilizarSaldoCliente = false;
      this.selectedFiles = [];
      this.arrayFile = [];
      this.agendar = false;
      this.agendarForma = "Código de Barras";
      this.agendarTxtPagar = "";
      this.tipoConta === "despesa"
        ? this.$store.commit("errorsDespesas", { errorsDespesas: {} })
        : this.$store.commit("errorsReceitas", { errorsReceitas: {} });

      this.$store.commit("OpenDialogLiquidarMovconta", false);
      this.$store.commit("OpenOpcoesAvancadasDespesas", false);
      this.$store.commit("cadastroDespesa", {
        isCadastro: false,
        cadastro: {}
      });
    },
    tipoModal() {
      if (this.tipoConta === "despesa") {
        this.$store.commit("callDespesa", true);
        this.$store.dispatch("planoContas");
      } else {
        this.$store.commit("callDespesa", false);
        this.$store.dispatch("planoContas");
      }
      this.$store.dispatch("contas");
    },
    title() {
      switch (this.tipoConta) {
        case "despesa":
          return "Liquidar Despesa";
          break;
        case "receita":
          return "Liquidar Receita";
          break;
      }
    },
    nomeItem() {
      switch (this.tipoConta) {
        case "despesa":
          return "despesa";
          break;
        case "receita":
          return "receita";
          break;
      }
    },
    init() {
      this.tipoModal();
      this.$store.dispatch("listarCentroCustos");
    },
    addMovcontaRateio() {
      this.errors.porcentagem = null;
      this.errors.centrodecusto = null;

      if (!this.newMovcontaRateio.centrodecusto) {
        this.errors.centrodecusto = "Centro de custo é obrigatório";
        return;
      }

      if (!this.newMovcontaRateio.porcentagem) {
        this.errors.porcentagem = "Porcentagem é obrigatória";
        return;
      }

      if (this.porcentagem < 0 || this.porcentagem > 100) {
        this.errors.porcentagem = "Valor inválido";
        return;
      }

      const rateioIndex = this.movconta.movcontas_rateios.findIndex(rateio => rateio.centrocusto.id === this.newMovcontaRateio.centrodecusto.id);

      const rateio = {
        porcentagem: Math.abs(Number(this.newMovcontaRateio.porcentagem)),
        centrocusto: {
          id: this.newMovcontaRateio.centrodecusto.id,
          nome: this.newMovcontaRateio.centrodecusto.nome,
        },
      };

      if (rateioIndex === -1) {
        this.movconta.movcontas_rateios.push(rateio);
      } else {
        this.movconta.movcontas_rateios[rateioIndex] = rateio;
      }

      this.newMovcontaRateio = {
        centrodecusto: null,
        porcentagem: 0,
      };
    },
    updateValorAcrecimoDesconto() {
      const valor = Math.abs(this.movconta.valor);
      const valorLiquidado = Math.abs(this.movconta.valor_liquidado);

      this.movconta.valor_acrecimo = 0;
      this.movconta.valor_desconto = 0;

      if (valor > valorLiquidado) {
        this.movconta.valor_desconto = valor - valorLiquidado;
        } else if (valor < valorLiquidado) {
        this.movconta.valor_acrecimo = valorLiquidado - valor;
      }
    },
    loadSaldoCliente() {
      if (!this.extratoClienteInstalado || this.loadingSaldoCliente || this.tipoConta === "despesa") return;

      this.loadingSaldoCliente = true;

      axios
        .get(`/financeiro/extrato-cliente/${this.movconta.cadastro_id}?apenas_saldo=1`)
        .then(res => {
          this.loadingSaldoCliente = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.saldoAtualCliente = res.data.saldo_atual;
        })
        .catch(() => {
          this.loadingSaldoCliente = false;
          this.$q.notify({
            message: 'Houve um erro ao consultar o extrato do cliente',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    onDragLeave() {
      event.preventDefault();
    },
    onHover() {
      event.preventDefault();
    },
    offHover() {
      event.preventDefault();
    },
    onDragOver() {
      event.preventDefault();
    },
    addFile(event) {
      const files = this.getFiles(event);

      for (let i = 0, f; (f = files[i]); i++) {
        this.selectedFiles.push(f);

        this.arrayFile.push({
          fileName: f.name,
          fileSize: this.bytesToSize(f.size),
          success: false,
          error: false,
          message: "0%",
          uploading: false,
        });
      }
    },
    getFiles(event) {
      return event.dataTransfer ? event.dataTransfer.files : event.target.files;
    },
    uploadImages(arq, arr) {
      arr.uploading = true;
      const fd = new FormData();
      fd.append("file", arq, arq.name);
      fd.append("movconta_id", this.movconta.id);

      axios
        .post("/arquivos/upload", fd)
        .then(res => {
          if (!res.data.success) {
            arr.error = true;
            arr.message = res.data.message;

            this.$q.notify({
              message: `${arr.message} ${arr.fileName}`,
              color: "orange",
              position: "top-right"
            });

            return;
          }

          arr.message = res.data.message;
          arr.success = res.data.success;
          arr.uploading = false;
          arr.delivered = true;
        });
    },
    bytesToSize(bytes) {
      if (bytes == 0) return "0 Byte";

      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    openModal() {
      this.$store.dispatch("getTokenQrcode");
      this.$store.commit("OpenDialogUploadResumoCaixas", true);
      this.$refs.uploadResumoTfl.dizsize();
    },
    apagaItem(i) {
      if (this.arrayFile[i].uploading) return;

      this.arrayFile.splice(i, 1);
    },
    clearFiles() {
      this.arrayFile = [];
    },
  },
  computed: {
    ...mapGetters([
      "contas",
      "centroCustos",
      "OpenOpcoesAvancadasDespesas",
      "planoContas",
      "loadLiquidar",
      "OpenDialogLiquidarMovconta",
      "extratoClienteInstalado",
      "movcontasOptions",
    ]),
    movconta() {
      return this.tipoConta === "despesa"
        ? this.$store.getters.despesa
        : this.$store.getters.movconta;
    },
    errors() {
      return this.tipoConta === "despesa"
        ? this.$store.getters.errorsDespesas
        : this.$store.getters.errorsReceitas;
    },
    exibirCriarNovaMovconta() {
      if (this.movconta.valor == this.movconta.valor_liquidado) {
        return false;
      }

      return (
        this.movconta.valor_liquidado > 0 &&
        this.movconta.valor_liquidado < Math.abs(this.movconta.valor)
      );
    },
    planodeconta: {
      get() {
        this.triggerPlanodecontas;
        return this.movconta.planodeconta;
      },
      set(val) {
        this.triggerPlanodecontas = val ? val.id : null;
        this.$set(this.movconta, "planodeconta", val);
        this.$set(this.movconta, "planodeconta_id", val ? val.id : null);
      },
    },
    opcoesAvancadas: {
      get() {
        return this.OpenOpcoesAvancadasDespesas;
      },
      set(val) {
        this.$store.commit("OpenOpcoesAvancadasDespesas", val);
      },
    },
    exibirCentrosdecusto() {
      return this.movconta.tipo === 1;
    },
    hasOpcoesAvancadas() {
      return this.exibirCentrosdecusto;
    },
    optionsCentrosdecusto() {
      if (!this.exibirCentrosdecusto) {
        return [];
      }

      return this.centroCustos.filter(centroCusto => {
        return !(this.movconta.movcontas_rateios.find(rateio => rateio.centrocusto.id == centroCusto.id));
      });
    },
    porcentagemMovcontasRateioTotal() {
      if (!this.exibirCentrosdecusto) {
        return 0.00;
      }

      return this.movconta.movcontas_rateios.reduce((total, rateio) => total + Math.abs(Math.abs(Number(rateio.porcentagem))), 0);
    },
    exibirUtilizarSaldoCliente() {
      return this.extratoClienteInstalado && this.movconta.tipo === 2;
    },
    novoSaldoCliente() {
      return this.saldoAtualCliente - parseFloat(this.movconta.valor_liquidado);
    },
    classSaldoAtualCliente() {
      if (this.saldoAtualCliente > 0) return 'text-green';
      if (this.saldoAtualCliente < 0) return 'text-red';

      return '';
    },
    classNovoSaldoCliente() {
      if (this.novoSaldoCliente > 0) return 'text-green';
      if (this.novoSaldoCliente < 0) return 'text-red';

      return '';
    },
    exibirSaldoCliente() {
      return !this.loadingSaldoCliente;
    },
    criarContaValorRestanteAutomaticamento() {
      return this.movcontasOptions.movcontas_criar_movconta_valor_restante
        && parseInt(this.movcontasOptions.movcontas_criar_movconta_valor_restante);
    },
  },
  created() {
    bus.$on("tipo", tipo => {
      this.tipo = tipo;
    });
  },
  destroyed() {
    bus.$off("tipo");
  },
  mounted() {
    this.init();
  },
  watch: {
    /**
     * OBS: Caso seja necessário alterar o objeto de movconta, sempre validar se este modal está aberto,
     * para evitar de alterar informações em outros modais, como por exemplo no de editar
     */
    OpenDialogLiquidarMovconta() {
      if (!this.OpenDialogLiquidarMovconta) return;

      this.init();

      this.gerarNovaMovconta = false;
      this.utilizarSaldoCliente =  false;
      this.novoVencimento = null;
      this.movconta.valorRestante = 0;
      this.movconta.liquidacao = moment().format("DD/MM/YYYY");
      this.movconta.valor_liquidado = Math.abs(this.movconta.valor);
      this.movconta.vencimento = moment(this.movconta.vencimento, "YYYY/MM/DD").format("DD/MM/YYYY");

      if (!this.movconta.dtmovimento) {
        this.movconta.dtmovimento = moment().format("DD/MM/YYYY");
      } else {
        this.movconta.dtmovimento = moment(this.movconta.dtmovimento, 'YYYY/MM/DD').format("DD/MM/YYYY");
        this.movconta.jaPossuiDtmovimento = true;
      }

      if (this.movconta.planodeconta_id) {
        this.movconta.planodeconta = this.planoContas.find(
          planodeconta => planodeconta.id === this.movconta.planodeconta_id
        );

        this.triggerPlanodecontas = this.movconta.planodeconta_id;
      }

      if (this.$router.history.current.path === "/extrato-cliente") {
        this.utilizarSaldoCliente = true;
      }

      this.loadSaldoCliente();
    },
    "movconta.valor_liquidado"() {
      if (this.exibirCriarNovaMovconta) {
        this.valorRestante =
          Math.abs(this.movconta.valor) -
          Math.abs(this.movconta.valor_liquidado);
      }

      // this.updateValorAcrecimoDesconto();


      if (this.criarContaValorRestanteAutomaticamento){
        this.gerarNovaMovconta = this.exibirCriarNovaMovconta;
      }

    },
    "movconta.liquidacao"() {
      if (!this.OpenDialogLiquidarMovconta || this.movconta.jaPossuiDtmovimento) return;

      const liquidacao = moment(this.movconta.liquidacao, 'DD/MM/YYYY');

      if (liquidacao.isValid()) {
        this.movconta.dtmovimento = liquidacao.format('DD/MM/YYYY');
      }
    },
    gerarNovaMovconta(value) {
      if (value) {
        this.movconta.valor_desconto = 0;
      } else {
        // this.updateValorAcrecimoDesconto();
      }
    }
  },
};
</script>

<style scoped>
.q-field--with-bottom {
  padding-bottom: unset;
}

.layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
}

.layout-responsive {
  text-align:center;
  padding: 10px 10px;
}

.layout-responsive .icones-layout label {
  padding: 3rem;
}

.icones-layout label {
  margin-right: 5px;
}
</style>
