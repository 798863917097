<template>
  <div id="modalNovoCaixa">
    <q-dialog v-model="OpenDialogNovoCaixa" persistent>
      <q-card style="min-width: 350px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">{{newCaixa.isNew ? 'Novo' : 'Editar'}} Caixa</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-input
                  v-model="newCaixa.nome"
                  label="Nome do Caixa"
                  maxlength="20"
                  bottom-slots
                  :error="errorsCaixas.nome ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsCaixas.nome" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12" id="numero">
                <q-input
                  v-model="newCaixa.numero"
                  label="Número"
                   maxlength="20"
                  bottom-slots
                  :error="errorsCaixas.numero ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsCaixas.numero" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <br />
                <br />
                <br />
                <q-btn
                  no-wrap
                  v-ripple
                  id="Salvar"
                  label="Salvar"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  :loading="save"
                  @click="salvar()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify } from "quasar";
import axios from "@/axios-auth";
export default {
  name: "modal-novo-caixa",
  data() {
    return {
      save: false
    };
  },
  methods: {
    newModalCaixa(){
      return{
        id: null,
        isNew: true,
        nome: null,
        numero: null
      };
    },
    closeModal() {
      this.$store.commit("OpenDialogNovoCaixa", false);
      this.$store.commit("errorsCaixas", {});
      this.$store.commit('newCaixa', this.newModalCaixa());
    },
    salvar() {
      if (this.save) return;
      this.save = true;
      let url = this.newCaixa.isNew
        ? "/caixas/add"
        : "/caixas/edit/" + this.newCaixa.id;
      let sucecssMessage = this.newCaixa.isNew
        ? "Caixa " + this.newCaixa.nome + " adicionado com sucesso!"
        : "Caixa " + this.newCaixa.nome + " editado com sucesso!";
      axios
        .post(url, {
          numero: this.newCaixa.numero,
          nome: this.newCaixa.nome
        })
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: sucecssMessage,
              color: "green",
              position: "top-right"
            });
            this.$store.commit("caixas", []);
            this.$store.dispatch("caixas");
            this.closeModal();
          } else {
            Notify.create({
              message: "Verifique os campos!",
              color: "orange",
              position: "top-right"
            });
            this.$store.commit("errorsCaixas", res.data.errors);
          }
          this.save = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível cadastrar o novo caixa, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.save = false;
        });
    }
  },
  computed: {
    ...mapGetters(["newCaixa", "errorsCaixas", "loadCaixas"]),
    OpenDialogNovoCaixa: {
      get() {
        return this.$store.getters.OpenDialogNovoCaixa;
      },
      set() {}
    }
  }
};
</script>

<style lang="stylus" scoped></style>
