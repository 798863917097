<template>
  <div id="modalAtualizarVersao">
    <q-dialog v-model="OpenDialogAtualizarVersao" persistent>
      <q-card style="width:400px">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">Atualizar Versão</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-select
                  v-model="servicoSelected"
                  :option-label="value => value === null ? 'Item Vazio' : value.nome"
                  :option-value="value => value === null ? null : value"
                  :options="servicos"
                  label="Serviço"
                  bottom-slots
                  :error="errorsLicencas.servico_id ? true : false"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsLicencas.servico_id" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  v-model="versionU"
                  :option-label="value => value === null ? 'Item Vazio' : value.numero"
                  :option-value="value => value === null ? null : value"
                  :options="versoes"
                  label="Versão"
                  bottom-slots
                  :error="errorsLicencas.version_id ? true : false"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsLicencas.version_id" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="updateV"
            :loading="salvaSV"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "modal-atualizar-versao",
  data() {
    return {
      versionU: null,
      servicoSelected: null
    };
  },
  watch: {
    servicoSelected(servicoId) {
      this.licenca.servico_id = servicoId.id;
      this.$store.dispatch("versoes", servicoId.id);
    }
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogAtualizarVersao", false);
      this.$store.dispatch("errorsLicencasClear");
    },
    updateV() {
      this.$store.dispatch("updateV", {
        versionU: this.versionU.id,
        servico_id: this.licenca.servico_id
      });
    }
  },
  computed: {
    ...mapGetters([
      "filtrosLicencas",
      "errorsLicencas",
      "licenca",
      "versoes",
      "servicos",
      "salvaSV"
    ]),
    OpenDialogAtualizarVersao: {
      get() {
        return this.$store.getters.OpenDialogAtualizarVersao;
      },
      set() {}
    }
  }
};
</script>

<style lang="stylus" scoped></style>
