<template>
  <q-page id="licencas">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!isPermissoLicencas"></sem-permissao>
        <div class="col-12 col-md-12" v-if="isPermissoLicencas">
          <grafico-acessos-licencas></grafico-acessos-licencas>
        </div>
        <div class="col-12 col-md-12" v-if="isPermissoLicencas">
          <q-table
            title="Licenças"
            :data="licencas"
            :columns="columns2"
            row-key="id"
            :loading="buscandoLicencas"
            :bottom="false"
            :pagination.sync="pagination"
            selection="multiple"
            :selected.sync="selected"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
            :visible-columns="visibleColumns"
            >
            <template v-slot:bottom>
              <div></div>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                <q-td auto-width>
                  <q-checkbox v-model="props.selected" color="secondary" />
                </q-td>
                <q-td
                  key="id"
                  v-if="visibleColumns.indexOf('id') !== -1"
                  class="text-right"
                >{{props.row.id }}</q-td>
                <q-td key="cadastro" v-if="visibleColumns.indexOf('cadastro') !== -1">
                  <router-link
                    :to="getUrl(props.row)"
                    style="text-decoration: none; color: black"
                  >{{props.row.cadastro.nome }}</router-link>
                </q-td>
                <q-td
                  key="descricao"
                  v-if="visibleColumns.indexOf('descricao') !== -1"
                >{{props.row.descricao }}</q-td>
                <q-td
                  key="identificador"
                  v-if="visibleColumns.indexOf('identificador') !== -1"
                >{{props.row.codigo_uuid }}</q-td>
                <q-td
                  key="bloqueio"
                  v-if="visibleColumns.indexOf('bloqueio') !== -1"
                  class="text-center"
                >{{props.row.bloquear_em | formatDate }}</q-td>
                <q-td
                  key="plano"
                  v-if="visibleColumns.indexOf('plano') !== -1"
                  class="text-center"
                >{{props.row.plano }}</q-td>
                <q-td
                  key="versao"
                  v-if="visibleColumns.indexOf('versao') !== -1"
                  class="text-center"
                >{{props.row.version.numero }}</q-td>
                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn
                        @click="detalhar(props.row)"
                        size="12px"
                        class="bg-primary text-white"
                        icon="info"
                      ></q-btn>
                      <q-btn
                        v-if="btnStatusLicencas === 'Inativos'"
                        @click="ativar(props.row)"
                        class="bg-green text-white"
                        icon="check"
                        size="12px"
                      ></q-btn>
                      <q-btn
                        v-if="btnStatusLicencas === 'Ativos'"
                        @click="modalLicenca(props.row)"
                        class="bg-primary text-white"
                        icon="edit"
                        size="12px"
                      ></q-btn>
                      <q-btn
                        v-if="btnStatusLicencas === 'Ativos'"
                        @click="excluir(props.row)"
                        class="bg-orange text-white"
                        icon="block"
                        size="12px"
                      ></q-btn>
                      <q-btn
                        v-if="btnStatusLicencas !== 'Ativos'"
                        @click="excluir(props.row, 's')"
                        class="bg-negative text-white"
                        icon="delete_forever"
                        size="12px"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      @click="detalhar(props.row)"
                      size="12px"
                      class="bg-primary text-white"
                      icon="info"
                    />
                    <q-btn
                      v-if="btnStatusLicencas === 'Inativos'"
                      @click="ativar(props.row)"
                      class="bg-green text-white"
                      icon="check"
                      size="12px"
                    />
                    <q-btn
                      v-if="btnStatusLicencas === 'Ativos'"
                      @click="modalLicenca(props.row)"
                      class="bg-primary text-white"
                      icon="edit"
                      size="12px"
                    />
                    <q-btn
                      v-if="btnStatusLicencas === 'Ativos'"
                      @click="excluir(props.row)"
                      class="bg-orange text-white"
                      icon="block"
                      size="12px"
                    />
                    <q-btn
                      v-if="btnStatusLicencas !== 'Ativos'"
                      @click="excluir(props.row, 's')"
                      class="bg-negative text-white"
                      icon="delete_forever"
                      size="12px"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:top="props">
              <q-btn
                flat
                round
                dense
                :icon="'add'"
                class="q-ml-md text-primary"
                style="margin-right: 10px;margin-left: 0"
                @click="modalLicenca()"
              />
              <div class="col-2 q-table__title">Licença</div>
              <q-btn
                v-if="allLicenca"
                flat
                dense
                no-wrap
                v-ripple
                color="secondary"
                no-caps
                :label="'Selecionar todos as ' + pagingLicencas.count + ' licenças'"
                class="q-ml-sm q-px-md"
                :class="{'bg-white text-blue-7': !allLicencas, 'bg-green-7 text-white': allLicencas}"
                clickable
                @click="setAll"
                />
              <q-space />
              <q-btn
                v-if="$q.screen.gt.xs"
                dense
                flat
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="btnStatusLicencas"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Status da Licença</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': val === dadosLicencas.ativo}"
                      v-for="(key, val) in statusLicencas"
                      :key="key + val"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="changeFilterStatus(key, val)"
                    >
                      <q-item-section>{{ key }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-btn
                v-if="$q.screen.gt.xs"
                dense
                flat
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="servicoLabel"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Serviço</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': key.nome === servicoLabel}"
                      v-for="(key) in servicos"
                      :key="key.id"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="changeFilterServicos(key)"
                    >
                      <q-item-section>{{ key.nome }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-btn
                v-if="$q.screen.gt.xs"
                dense
                flat
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="versoesLabel"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                style="margin-right: 15px"
                >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Serviço</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': key.numero === versoesLabel}"
                      v-for="(key) in versoes"
                      :key="key.id"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="changeFilterVercoes(key)"
                    >
                      <q-item-section>{{ key.numero }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-select
                v-model="visibleColumns"
                multiple
                borderless
                dense
                options-dense
                display-value="Colunas"
                emit-value
                map-options
                :options="columns2"
                option-value="name"
                style="min-width: 100px"
              />

              <q-btn
                v-if="hasVideo"
                dense
                flat
                color="bg-primary text-blue-7"
                icon="ondemand_video"
                class="q-ml-md"
                @click="modalIntro"
              >
                <template v-slot:default>
                  <q-tooltip>Introdução as Licenças</q-tooltip>
                </template>
              </q-btn>

              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                rounded
                no-wrap
                v-ripple
                class="q-ml-sm q-px-md bg-white text-black"
                no-caps
                icon="more_vert"
                style="margin-right: 10px"
                >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Mais Opções</q-item-section>
                    </q-item>
                    <!-- <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="$store.dispatch('resetPagingLicencas'), listar()"
                    >
                      <q-item-section>Atualizar</q-item-section>
                    </q-item>-->
                    <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="props.toggleFullscreen"
                      >
                      <q-item-section>{{props.inFullscreen ? 'Minimizar' : 'Maximizar Tela'}}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
              <div class="col-12">
                Exibindo {{totalLicencas}} de {{pagingLicencas.count}} Licenças
                <span
                  v-if="btnStatusLicencas === 'Ativos'"
                >ativas.</span>
                <span v-if="btnStatusLicencas === 'Inativos'">inativas.</span>
                <span
                  v-if="counterLicencas > 0 && counterLicencas !== 1"
                >{{counterLicencas}} Selecionados.</span>
                <span
                  v-if="counterLicencas > 0 && counterLicencas === 1"
                >{{counterLicencas}} Selecionado.</span>
              </div>
            </template>
          </q-table>
          <div class="col-12 col-md-12 text-center" v-if="buscandoLicencas">
            <br />
            <q-spinner color="blue-7" size="3em" />
          </div>
          <q-page-sticky position="top-right" :offset="[0, 90]">
            <q-btn
              :color="drawerRight ? 'red-7' : 'blue-7'"
              :icon="drawerRight ? 'close' : 'settings'"
              class="rotate-0"
              style="border-top-left-radius: 50px;border-bottom-left-radius: 50px"
              @click="drawerRight = !drawerRight"
              >
              <q-tooltip
                :content-class="drawerRight ? 'bg-negative text-white text-center' : 'bg-secondary text-white text-center'"
              >{{drawerRight ? 'Fechar Opções Avançadas' : 'Opções Avançadas'}}</q-tooltip>
            </q-btn>
          </q-page-sticky>
          <q-drawer
            side="right"
            v-model="drawerRight"
            bordered
            :width="280"
            :breakpoint="500"
            behavior="default"
            content-class="bg-white"
            >
            <q-scroll-area class="fit">
              <div class="q-pa-sm">
                <div class="q-table__title">
                  Opções Avançadas
                </div>
                <div>
                  <q-list
                    class="rounded-borders"
                    style="max-width: 350px; margin-left: -10px;width: 300px;"
                    >
                    <q-item-label header></q-item-label>

                    <q-item clickable v-ripple to="/arquivos">
                      <q-item-section>
                        <q-item-label lines="1">Arquivos</q-item-label>
                        <q-item-label caption>Visualizar os arquivos</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="liberar">
                      <q-item-section>
                        <q-item-label lines="1">Liberar</q-item-label>
                        <q-item-label caption>Realizar a liberação de licenças em massa</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="modalUpdateV">
                      <q-item-section>
                        <q-item-label lines="1">Atualizar Versão</q-item-label>
                        <q-item-label caption>Realizar a atualização em massa</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="openServico">
                      <q-item-section>
                        <q-item-label lines="1">Adicionar serviço</q-item-label>
                        <q-item-label caption>Realizar a inclusão de um Serviço</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="openVersao">
                      <q-item-section>
                        <q-item-label lines="1">Adicionar versão</q-item-label>
                        <q-item-label caption>Realizar a inclusão de uma versão</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </div>
            </q-scroll-area>
          </q-drawer>
        </div>
      </div>
    </div>
    <modal-adicionar-servico></modal-adicionar-servico>
    <modal-adicionar-versao></modal-adicionar-versao>
    <modal-liberar-licenca></modal-liberar-licenca>
    <modal-adicionar-licenca :isNew="isNew"></modal-adicionar-licenca>
    <modal-detalhar-licenca></modal-detalhar-licenca>
    <modal-atualizar-versao></modal-atualizar-versao>
  </q-page>
</template>

<script>
import { mapGetters } from "vuex";
const moment = require("moment");
export default {
  name: "licencas",
  meta: {
    title: "DSB | Licenças"
  },
  data() {
    return {
      isNew: true,
      visibleColumns: JSON.parse(localStorage.getItem("licencas__visibleColumns")) || [
        "id",
        "cadastro",
        "descricao",
        "identificador",
        "bloqueio",
        "plano",
        "versao"
      ],
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      selected: [],
      columns2: [
        {
          name: "id",
          label: "ID",
          align: "center",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "cadastro",
          required: true,
          align: "left",
          label: "Cadastro",
          field: row => row.cadastro.nome,
          sortable: true
        },
        {
          name: "descricao",
          align: "left",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true
        },
        {
          name: "identificador",
          align: "left",
          label: "Identificador",
          field: row => row.codigo_uuid,
          sortable: true
        },
        {
          name: "bloqueio",
          align: "center",
          label: "Bloqueio",
          field: row => row.codigo_uuid,
          sortable: true
        },
        {
          name: "plano",
          align: "center",
          label: "Plano",
          field: row => row.plano,
          sortable: true
        },
        {
          name: "versao",
          align: "center",
          label: "Versão",
          field: row => row.version.numero,
          sortable: true
        }
      ],
      allLicenca: false,
      drawerRight: false,
      hasVideo: false
    };
  },
  watch: {
    allLicenca(value) {
      this.$store.commit("marcadoLicencas", value);
    },
    selected(value) {
      if (value.length === 0) {
        this.allLicenca = false;
        this.$store.commit("getSelectedLicencas", []);
        this.$store.commit("counterLicencas", 0);
      } else {
        if (value.length >= 10) {
          this.allLicenca = true;
        }
        let ids = [];
        for (let i in value) {
          if (value.indexOf(value[i]) !== -1) {
            ids.push(value[i].id);
          }
        }
        this.$store.commit("getSelectedLicencas", ids);
        this.$store.commit("counterLicencas", value.length);
      }
    },
    visibleColumns() {
      localStorage.setItem("licencas__visibleColumns", JSON.stringify(this.visibleColumns));
    },
  },
  methods: {
    openServico() {
      this.$store.dispatch("newServico");
      this.$store.commit("OpenDialogAdicionarServico", true);
    },
    openVersao() {
      this.$store.dispatch("servicos");
      this.$store.dispatch("newVersion");
      this.$store.commit("OpenDialogAdicionarVersao", true);
    },
    liberar() {
      this.$store.commit("OpenDialogLiberarLicenca", true);
    },
    modalUpdateV() {
      this.$store.dispatch("servicos");
      this.$store.commit("OpenDialogAtualizarVersao", true);
    },
    detalhar(key) {
      key.bloquear_em = moment(key.bloquear_em).format("DD/MM/YYYY");
      this.$store.dispatch("editLicenca", key);
      this.$store.commit("OpenDialogDetalharLicenca", true);
    },
    excluir(key, val) {
      let dados = {};
      dados.key = key;
      if (val) {
        dados.excluir = val;
      }
      this.$store.dispatch("excluirLicenca", dados);
    },
    ativar(key) {
      key.ativo = true;
      if (key.bloquear_em !== "Invalid date") {
        key.bloquear_em = moment(key.bloquear_em).format("DD/MM/YYYY");
      } else {
        key.bloquear_em = "";
      }
      let dados = {
        id: key.id,
        ativo: key.ativo,
        bloquear_em: key.bloquear_em,
        key: key
      };
      this.$store.dispatch("reativaLicenca", dados);
    },
    setAll() {
      this.$store.dispatch("setAllLicencas");
    },
    marcaItem(key, val) {
      this.$store.dispatch("marcaItemLicencas", { key: key, val: val });
    },
    marcarDesmarcarTodos() {
      this.$store.dispatch("marcarDesmarcarTodosLicencas");
    },
    listar() {
      this.$store.dispatch("listaLicencas");
    },
    handleScroll() {
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.listar();
      }
    },
    getUrl(key) {
      return "/cadastros/" + key.cadastro.id;
    },
    changeFilterStatus(key, val) {
      this.$store.dispatch("changeFilterStatusLicencas", {
        key: key,
        val: val
      });
    },
    changeFilterServicos(key, val) {
      this.$store.dispatch("changeFilterServicos", key);
    },
    changeFilterVercoes(key) {
      this.$store.dispatch("changeFilterVercoes", key);
    },
    modalLicenca(licenca) {
      if (licenca) {
        this.isNew = false;
      } else {
        this.isNew = true;
      }

      this.$store.dispatch("servicos");
      this.$store.dispatch("editLicenca", licenca);
      this.$store.commit("OpenDialogAdicionarLicenca", true);
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "licencas");

      if (!this.videosManuais.video_licencas) {
        this.modalIntro();
      }
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "licencas");
    },
  },
  computed: {
    columns() {
      if (this.licencas.length === 0) {
        return {};
      }
      let licenca = {
        id: "ID",
        cadastro: "Cadastro",
        descricao: "Descrição",
        identificador: "Identificador",
        bloqueio: "Bloqueio",
        plano: "Plano",
        versao: "Versão"
      };
      return licenca;
    },
    query() {
      return this.$route.query;
    },
    ...mapGetters([
      "licencas",
      "pagingLicencas",
      "filtrosLicencas",
      "buscandoLicencas",
      "totalLicencas",
      "statusLicencas",
      "btnStatusLicencas",
      "dadosLicencas",
      "pulseCountLicencas",
      "pulseRapidoLicencas",
      "marcadoLicencas",
      "allLicencas",
      "counterLicencas",
      "aguardeBackground",
      "bloqueioLicenca",
      "servicos",
      "versoes",
      "servico",
      "versao",
      "btnServico",
      "btnVersao",
      "isPermissoLicencas",
      "videosManuais",
      "manuaisDisponiveis"
    ]),
    servicoLabel() {
      for (let i = 0; i < this.servicos.length; i++) {
        if (this.servicos[i].id === this.btnServico) {
          return this.servicos[i].nome;
        }
      }
      return "Serviço";
    },
    versoesLabel() {
      for (let i = 0; i < this.versoes.length; i++) {
        if (this.versoes[i].id === this.btnVersao) {
          return this.versoes[i].numero;
        }
      }
      return "Versão";
    }
  },
  mounted() {
    this.$store.commit('filtrosLicencas', {});
    this.$store.commit('dadosLicencas', {
      page: 0,
      busca: null,
      ativo: "ativo",
      estado: null,
      uuid: null,
      cadastro_id: null,
      servico_id: null,
      version_id: null
    });
    this.$store.dispatch("resetPagingLicencas");
    this.$store.dispatch("listaLicencas");
    this.$store.dispatch("servicos");
    this.verificarPrimeiroVideo();
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    this.$store.dispatch("queryLicencas", this.query);
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  }
};
</script>

<style lang="stylus" scoped></style>
