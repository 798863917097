import axios from "axios";
import { Notify } from "quasar";
import register from "./register";

const state = {
  fetchCEP: {
    cep: "",
    buscandoCEP: false,
    data: {}
  }
};

const mutations = {
  fetchCEP: (state, userData) => {
    state.fetchCEP.cep = userData.cep;
    state.fetchCEP.buscandoCEP = userData.buscandoCEP;
    state.fetchCEP.data = userData.data;
  }
};

const actions = {
  fetchCEP: ({ commit, dispatch }, userData) => {
    if (!userData) {
      Notify.create({
        message: "CEP Inválido, digite novamente.",
        color: "red",
        position: "top-right"
      });
      return;
    }

    let cep = userData;
    cep = cep.replace(/\D/g, "");
    // Expressão regular para validar o CEP.
    let validacep = /^[0-9]{8}$/;
    // Valida o formato do CEP.
    if (!validacep.test(cep) || cep === "") {
      if (cep !== "") {
        Notify.create({
          message: "CEP Inválido, digite novamente.",
          color: "red",
          position: "top-right"
        });
        return;
      }
    }
    commit("fetchCEP", {
      buscandoCEP: true
    });
    axios
      .get("https://viacep.com.br/ws/" + cep + "/json/")
      .then(res => {
        let data = res.data;
        if (!("erro" in data)) {
          Notify.create({
            message: "CEP encontrado!",
            color: "green",
            position: "top-right"
          });
          commit("fetchCEP", {
            buscandoCEP: false,
            data: res.data
          });
          let dados = {
            email: register.state.registro.email,
            nome: register.state.registro.nome,
            celular: register.state.registro.celular,
            tfls: register.state.registro.tfls,
            complemento: register.state.registro.complemento,
            acceptTerms: register.state.registro.acceptTerms,
            telefone: register.state.registro.telefone,
            cnpj: register.state.registro.cnpj,
            atividade: register.state.registro.atividade,
            razao: register.state.registro.razao,
            fantasia: register.state.registro.fantasia,
            bairro: res.data.bairro,
            cidade: res.data.localidade,
            estado: res.data.uf,
            endereco: res.data.logradouro,
            numero: "",
            cep: res.data.cep,
            uuid: register.state.registro.uuid,
          };
          dispatch("registro", dados);
        } else {
          Notify.create({
            message: "CEP não encontrado.",
            color: "orange",
            position: "top-right"
          });
          commit("fetchCEP", {
            buscandoCEP: false
          });
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!.",
          color: "red",
          position: "top-right"
        });
        commit("fetchCEP", {
          buscandoCEP: false
        });
      });
  }
};

const getters = {
  fetchCEP: state => {
    return state.fetchCEP;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
