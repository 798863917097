import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";

const state = {
  loadingServicos: false,
  produtosServicos: [],
  servico: {
    importar_produtos: false,
    ativo: true,
    tipo: "S",
    nome: "",
    vlrcusto: 0,
    vlrvenda: 0,
    vlrlucro: 0,
    tpcontrole: "",
    ordem: "",
    estorno_id: null,
    tiposoma: "",
    categoria_id: null
  },
  filtrosServicos: {},
  tipoControleServicos: [],
  tiposServicos: [],
  tipoSomaServicos: {},
  pagingServicos: {
    finder: "all",
    page: 0,
    current: 0,
    count: 0,
    perPage: 20,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  addRegraServicos: {
    tipo: "",
    quando: "",
    dias: 0,
    tpvalor: "",
    valor: "",
    nomenclatura: "",
    conta_id: null
  },
  totalServicos: 0,
  isNewServico: true,
  errorsServicos: [],
  acontarServicos: [],
  tipoValorServicos: [],
  saveServico: false,
  OpenDialogNovoServico: false,
  OpenDialogNovaRegraServico: false,
  tiposSomaServicos: [],
  isNewRegraServico: true,
  categoriasServicosDisp: [],
  tpcomissaoServicos: ["%", "$"],
  tpcomissaofuncServicos: ["%", "$"],
  tpnomenclaturaServicos: [{ name: "Crédito", value: "C" }, { name: "Débito", value: "D" }],
  canImportServicos: false,
  tipoRegrasServicos: [],
  tipoRegrasValorServicos: [],
  tipoRegrasAcontarServicos: []
};

const mutations = {
  loadingServicos: state => {
    state.loadingServicos = !state.loadingServicos;
  },
  produtosServicos: (state, userData) => {
    state.produtosServicos = userData;
  },
  servico: (state, userData) => {
    state.servico = userData;
  },
  filtrosServicos: (state, userData) => {
    state.filtrosServicos = userData;
  },
  tipoControleServicos: (state, userData) => {
    state.tipoControleServicos = userData;
  },
  tiposServicos: (state, userData) => {
    state.tiposServicos = userData;
  },
  tiposSomaServicos: (state, userData) => {
    state.tiposSomaServicos = userData;
  },
  pagingServicos: (state, userData) => {
    state.pagingServicos = userData;
  },
  addRegraServicos: (state, userData) => {
    state.addRegraServicos = userData;
  },
  totalServicos: (state, userData) => {
    state.totalServicos = userData;
  },
  isNewServico: (state, userData) => {
    state.isNewServico = userData;
  },
  errorsServicos: (state, userData) => {
    state.errorsServicos = userData;
  },
  acontarServicos: (state, userData) => {
    state.acontarServicos = userData;
  },
  tipoValorServicos: (state, userData) => {
    state.tipoValorServicos = userData;
  },
  saveServico: state => {
    state.saveServico = !state.saveServico;
  },
  isNewRegraServico: (state, userData) => {
    state.isNewRegraServico = userData;
  },
  categoriasServicosDisp: (state, userData) => {
    state.categoriasServicosDisp = userData;
  },
  canImportServicos: (state, userData) => {
    state.canImportServicos = userData;
  },
  OpenDialogNovoServico: (state, userData) => {
    state.OpenDialogNovoServico = userData;
  },
  OpenDialogNovaRegraServico: (state, userData) => {
    state.OpenDialogNovaRegraServico = userData;
  },
  tipoRegrasAcontarServicos: (state, userData) => {
    state.tipoRegrasAcontarServicos = userData;
  },
  tipoRegrasValorServicos: (state, userData) => {
    state.tipoRegrasValorServicos = userData;
  },
  tipoRegrasServicos: (state, userData) => {
    state.tipoRegrasServicos = userData;
  },
};

const actions = {
  atualizar: ({ state, commit }, produtoEdit) => {
    let servicos = [...state.produtosServicos];

    let posicao = servicos.findIndex((produto) => {
      return produto.id === produtoEdit.id;
    });

    if (servicos[posicao]) {
      if (produtoEdit && produtoEdit['action'] === 'update') {
        servicos[posicao].loading = false;
        servicos[posicao] = produtoEdit;
      } else {
        servicos.splice(posicao, 1);
      }
    }

    commit("produtosServicos", []);
    commit("produtosServicos", servicos);
  },
  listarServicos: ({ commit, state }) => {
    if (state.loadingServicos) return;
    if (!state.pagingServicos.nextPage) return;

    commit("loadingServicos");
    axios
      .get("/produtos", {
        params: state.filtrosServicos
      })
      .then(res => {
        if (res.data.success) {
          let produtos = res.data.produtos;
          for (let i = 0; i < produtos.length; i++) {
            produtos[i].loading = false;
          }
          commit("produtosServicos", state.produtosServicos.concat(produtos));
          commit("filtrosServicos", res.data.filtros);
          commit("tipoControleServicos", res.data.tipoControle);
          commit("tiposServicos", res.data.tipos);
          commit("tiposSomaServicos", res.data.tipoSoma);
          commit("pagingServicos", res.data.paging);
          commit(
            "totalServicos",
            state.totalServicos + state.pagingServicos.current
          );
          commit("categoriasServicosDisp", res.data.categorias);
          commit("canImportServicos", res.data.canImport);
        }
        commit("loadingServicos");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadingServicos");
      });
  },
  resetPagingServicos: ({ commit }) => {
    const paging = {
      finder: "all",
      page: 0,
      current: 0,
      count: 0,
      perPage: 20,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    };
    commit("totalServicos", 0);
    commit("produtosServicos", []);
    commit("pagingServicos", paging);
  },
  filtrosServicos: ({ commit, dispatch }) => {
    commit("filtrosServicos", {
      tipo: "S",
      page: 1,
      ativo: 1,
      categoria_id: null
    });
    dispatch("resetPagingServicos");
    dispatch("listarServicos");
  },
  modalServicos: ({ commit, dispatch }, userData) => {
    let produtos = state.produtosServicos;
    let posicao = produtos.indexOf(userData);
    commit("servico", userData);
    axios
      .get("/produtos/edit/" + userData.id)
      .then(res => {
        if (res.data.success) {
          commit("servico", res.data.produto);
          commit("tipoRegrasAcontarServicos", res.data.tipoRegrasAcontar);
          commit("tipoRegrasValorServicos", res.data.tipoRegrasValor);
          commit("tipoRegrasServicos", res.data.tipoRegras);
          commit("isNewServico", false);
          dispatch("contas");
          dispatch("listarCategorias");
          commit("OpenDialogNovoServico", true);
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
        if (produtos[posicao]) {
          produtos[posicao].loading = false;
          commit("produtosServicos", produtos);
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        if (produtos[posicao]) {
          produtos[posicao].loading = false;
          commit("produtosServicos", produtos);
        }
        commit("servico", userData);
      });
  },
  deleteItemServicos: ({ dispatch }, userData) => {
    Dialog.create({
      title: userData.nome,
      message: "Tem certeza que deseja excluir este serviço?",
      html: true,
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/produtos/delete/" + userData.id)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              dispatch("atualizar", res.data.produto);
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi possível contactar, ou você não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  addServico: ({ commit, dispatch, state }, userData) => {
    commit("saveServico");
    let url = state.isNewServico
      ? "/produtos/add"
      : "/produtos/edit/" + userData.id;

    const params = userData.params ? userData.params : {};
    const abrirServico = userData.abrirServico ? userData.abrirServico : null;

    userData.params = null;
    userData.abrirServico = null;

    axios
      .post(url, userData, { params })
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          if (state.isNewServico) {
            commit("produtosServicos", []);
            commit("servico", {});
            dispatch("filtrosServicos");
          } else {
            let produto = res.data.produto;
            produto.action = 'update';
            dispatch("atualizar", produto);
          }



          if (userData.importar_produtos) {
            dispatch("filtrosProdutos");
            dispatch("filtrosLoterias");
            dispatch("listarCategorias");
          }
          commit("errorsServicos", []);
          commit("OpenDialogNovoServico", false);

          if (abrirServico) {
            dispatch("modalServicos", res.data.produto);
          }
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
          commit("errorsServicos", res.data.errors);
        }
        commit("saveServico");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("saveServico");
      });
  },
  deleteRegraServico: ({ commit }, userData) => {
    Dialog.create({
      title: userData.nomenclatura,
      message: "Tem certeza que deseja excluir esta regra?",
      html: true,
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post(
            "/produtos/delete-regra/" + userData.produto_id + "/" + userData.id
          )
          .then(res => {
            if (!res.data.success) {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
              return;
            }

            Dialog.create({
              title: res.data.message,
              class: "bg-positive text-white",
              ok: {
                "text-color": "green",
                color: "white"
              }
            });
            const servico = JSON.parse(JSON.stringify(state.servico));

            const regraIndex = servico.prodregras.findIndex(regra => regra.id === userData.id);
            servico.prodregras.splice(regraIndex, 1);

            commit("servico", servico);
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi possível contactar, ou você não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  modalRegraServico: ({ commit }, userData) => {
    if (userData) {
      commit("addRegraServicos", userData);
      commit("isNewRegraServico", false);
    } else {
      commit("isNewRegraServico", true);
      commit("addRegraServicos", {
        id: null,
        tipo: "",
        quando: "",
        dias: 0,
        tpvalor: "",
        valor: "",
        nomenclatura: "",
        conta_id: null
      });
    }
    commit("OpenDialogNovaRegraServico", true);
  },
  addRegraServico: ({ commit }, userData) => {
    return new Promise((resolve, reject) => {
      commit("saveRegra");
      let url = state.isNewRegraServico
        ? "/produtos/add-regra/" + userData.produto_id
        : "/produtos/edit-regra/" + userData.produto_id + "/" + userData.id;
      axios
        .post(url, userData)
        .then(res => {
          if (res.data.success) {
            let produto = res.data.produto;
            for (let i = 0; i < produto["prodregras"].length; i++) {
              produto.prodregras[i].loadingDelete = false;
            }
            commit("servico", produto);
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            commit("addRegraServicos", {
              tipo: "",
              quando: "",
              dias: 0,
              tpvalor: "",
              valor: "",
              nomenclatura: "",
              conta_id: null
            });
            commit("OpenDialogNovaRegraServico", false);
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            commit("errorsServicos", res.data.errors);
          }
          resolve(res.data);
          commit("saveRegra");
        })
        .catch(() => {
          commit("saveRegra");
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          reject();
        });
    });
  },
  errorsServicos: ({ commit }, userData) => {
    commit("errorsServicos", userData);
  },
  setaNovoServico: ({ commit }, userData) => {
    commit("isNewServico", userData);
  },
};

const getters = {
  loadingServicos: state => {
    return state.loadingServicos;
  },
  produtosServicos: state => {
    return state.produtosServicos;
  },
  servicoSelected: state => {
    return state.servico;
  },
  filtrosServicos: state => {
    return state.filtrosServicos;
  },
  tipoControleServicos: state => {
    return state.tipoControleServicos;
  },
  tiposServicos: state => {
    return state.tiposServicos;
  },
  tipoSomaServicos: state => {
    return state.tipoSomaServicos;
  },
  pagingServicos: state => {
    return state.pagingServicos;
  },
  addRegraServicos: state => {
    return state.addRegraServicos;
  },
  totalServicos: state => {
    return state.totalServicos;
  },
  isNewServico: state => {
    return state.isNewServico;
  },
  errorsServicos: state => {
    return state.errorsServicos;
  },
  acontarServicos: state => {
    return state.acontarServicos;
  },
  tipoValorServicos: state => {
    return state.tipoValorServicos;
  },
  saveServico: state => {
    return state.saveServico;
  },
  tiposSomaServicos: state => {
    return state.tiposSomaServicos;
  },
  isNewRegraServico: state => {
    return state.isNewRegraServico;
  },
  categoriasServicosDisp: state => {
    return state.categoriasServicosDisp;
  },
  tpcomissaoServicos: state => {
    return state.tpcomissaoServicos;
  },
  tpcomissaofuncServicos: state => {
    return state.tpcomissaofuncServicos;
  },
  tpnomenclaturaServicos: state => {
    return state.tpnomenclaturaServicos;
  },
  canImportServicos: state => {
    return state.canImportServicos;
  },
  OpenDialogNovoServico: state => {
    return state.OpenDialogNovoServico;
  },
  OpenDialogNovaRegraServico: state => {
    return state.OpenDialogNovaRegraServico;
  },
  tipoRegrasServicos: state => {
    return state.tipoRegrasServicos;
  },
  tipoRegrasValorServicos: state => {
    return state.tipoRegrasValorServicos;
  },
  tipoRegrasAcontarServicos: state => {
    return state.tipoRegrasAcontarServicos;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
