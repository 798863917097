import { render, staticRenderFns } from "./tags-cadastro.vue?vue&type=template&id=ece9f962&scoped=true&"
import script from "./tags-cadastro.vue?vue&type=script&lang=js&"
export * from "./tags-cadastro.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ece9f962",
  null
  
)

export default component.exports