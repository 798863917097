<template>
  <div id="novo-cadastro">
    <q-dialog v-model="openDialogCadastro" persistent>
      <q-card style="max-width: 800px">
        <q-card-section class="row items-center">
          <div class="text-h6">
            {{ isNewCadastro ? "Novo Cadastro" : "Editar Cadastro" }}
            <q-toggle
              v-model="newCadastro.ativo"
              color="positive"
              class="right"
              label="Ativo"
            />
          </div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <q-form class="row q-col-gutter-x-md" @submit="save">
              <div class="col-s12" style="padding-left: 0;margin-bottom: 16px">
                <q-checkbox
                  v-model="newCadastro.cliente"
                  color="positive"
                  label="Cliente/Associado"
                />
                <q-checkbox
                  v-model="newCadastro.funcionario"
                  color="positive"
                  label="Funcionário"
                />
                <q-checkbox
                  v-model="newCadastro.fornecedor"
                  color="positive"
                  label="Fornecedor"
                />
                <q-checkbox
                  v-model="newCadastro.dependente"
                  color="positive"
                  label="Dependente"
                />
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  label="CPF ou CNPJ"
                  id="valorCPFCNPJ"
                  input-class="noDisplay"
                  autofocus
                  :error="errors.cpfcnpj ? true : false"
                >
                  <template v-slot:default>
                    <the-mask
                      autocomplete="off"
                      :mask="['###.###.###-##', '##.###.###/####-##']"
                      placeholder=" "
                      :masked="true"
                      style="width: 100% !important"
                      type="text"
                      v-model="newCadastro.cpfcnpj"
                      class="input-money"
                    />
                  </template>
                  <template v-if="newCadastro.cpfcnpj" v-slot:append>
                    <q-btn
                      v-if="
                        newCadastro.cpfcnpj != null &&
                          newCadastro.cpfcnpj.length > 14
                      "
                      :loading="fetchCNPJ.buscandoCNPJ"
                      name="search"
                      clickable
                      @click.stop="consultacnpj(newCadastro.cpfcnpj)"
                      icon="search"
                      dense
                      class="cursor-pointer bg-positive"
                      color="text-white"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                  </template>

                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.cpfcnpj"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6" id="nome">
                <q-input
                  class="col-12 col-md-8"
                  v-model="newCadastro.nome"
                  label="Nome"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                  lazy-rules
                  bottom-slots
                  :error="errors.nome ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.nome" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-input>
              </div>
              <div
                v-if="
                  newCadastro.cpfcnpj != null && newCadastro.cpfcnpj.length > 14
                "
                class="col-8 col-md-6"
                id="razao-social"
              >
                <q-input
                  class="col-8 col-md-8"
                  v-model="newCadastro.razao"
                  label="Razão Social"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                  bottom-slots
                  :error="errors.razao ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.razao" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-input>
              </div>
              <div
                v-if="
                  newCadastro.cpfcnpj != null && newCadastro.cpfcnpj.length > 14
                "
                class="col-4 col-md-6"
                id="inscricao_estadual"
              >
                <q-input
                  class="col-12 col-md-8"
                  v-model="newCadastro.inscricao_estadual"
                  label="Inscrição Estadual"
                  bottom-slots
                  :error="errors.inscricao_estadual ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.inscricao_estadual" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6" id="email">
                <q-select
                  label="E-mail"
                  v-model="selectEmail"
                  use-input
                  use-chips
                  multiple
                  hide-dropdown-icon
                  input-debounce="0"
                  new-value-mode="add-unique"
                  @new-value="adicionaNovoValor"
                  bottom-slots
                  :error="errors.email ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.email" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-select>
                <!-- <q-input
                  v-model="newCadastro.email"
                  label="E-mail"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                  bottom-slots
                  :error="errors.email ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.email" :key="error + i">{{error}}</span>
                  </template>
                </q-input>-->
              </div>
              <div class="col-12 col-md-6"  id="telefone">
                <q-select
                  label="Telefone"
                  v-model="selectTelefone"
                  use-input
                  use-chips
                  multiple
                  hide-dropdown-icon
                  input-debounce="0"
                  new-value-mode="add-unique"
                  @new-value="adicionaNovoValorTelefone"
                  bottom-slots
                  :error="errors.telefone ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.telefone"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
                <!-- <q-input
                  v-model="newCadastro.telefone"
                  label="Telefone"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                  bottom-slots
                  :error="errors.telefone ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.telefone" :key="error + i">{{error}}</span>
                  </template>
                </q-input>-->
              </div>
              <div class="col-12 col-md-6" id="cep">
                <q-input v-model="newCadastro.cep" label="CEP" mask="#####-###">
                  <template v-if="newCadastro.cep" v-slot:append>
                    <q-btn
                      :loading="fetchCEP.buscandoCEP"
                      name="search"
                      clickable
                      @click.stop="buscacep(newCadastro.cep)"
                      icon="search"
                      dense
                      class="cursor-pointer bg-positive"
                      color="text-white"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-2" id="estado">
                <q-input
                  v-model="newCadastro.estado"
                  label="Estado"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                />
              </div>
              <div class="col-12 col-md-4" id="cidade">
                <q-input
                  v-model="newCadastro.cidade"
                  label="Cidade"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                />
              </div>
              <div class="col-12 col-md-6"  id="bairro">
                <q-input
                  v-model="newCadastro.bairro"
                  label="Bairro"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                />
              </div>
              <div class="col-12 col-md-4"  id="endereco">
                <q-input
                  v-model="newCadastro.endereco"
                  label="Endereço"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                />
              </div>
              <div class="col-12 col-md-2"  id="numero">
                <q-input
                  id="numeroCadastro"
                  name="numeroCadastro"
                  v-model="newCadastro.numero"
                  label="Número"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                />
              </div>
              <div class="col-12 col-md-4" id="complemento">
                <q-input
                  v-model="newCadastro.complemento"
                  label="Complemento"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                />
              </div>
              <div class="col-12 col-md-4" id="codigo">
                <q-input
                  v-model="newCadastro.codigo"
                  label="Código"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                  hint="Identifique o cliente facilmente através do código."
                />
              </div>
              <div class="col-12 col-md-4"  id="datanascimento">
                <q-input
                  v-model="newCadastro.dtnascimento"
                  label="Data de Nascimento"
                  mask="##/##/####"
                  :clearable="!$q.screen.gt.xs"
                  clear-icon="close"
                />
              </div>
            </q-form>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            label="Salvar"
            class="bg-positive"
            color="primary"
            @click="save"
            :loading="saveCadastro"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify } from "quasar";
import { bus } from "@/main";
import { mask } from "vue-the-mask";

export default {
  name: "novo-cadastro",
  directives: { mask },
  data() {
    return {
      saveCadastro: false,
      errors: [],
      newCadastro: {
        cpfcnpj: "",
        nome: "",
        razao: "",
        email: "",
        telefone: "",
        cep: "",
        estado: "",
        cidade: "",
        bairro: "",
        endereco: "",
        numero: "",
        complemento: "",
        codigo: "",
        dtnascimento: "",
        cliente: true,
        funcionario: false,
        fornecedor: false,
        dependente: false,
        ativo: true,
        inscricao_estadual: null
      },
      selectEmail: [],
      selectTelefone: []
    };
  },
  watch: {
    selectEmail(value) {
      if (value.length > 0) {
        this.newCadastro.email = value.join();
      } else {
        this.newCadastro.email = "";
      }
    },
    selectTelefone(value) {
      if (value.length > 0) {
        this.newCadastro.telefone = value.join();
      } else {
        this.newCadastro.telefone = "";
      }
    },
    openDialogCadastro(value) {
      if (value && !this.isNewCadastro) {
        this.newCadastro = JSON.parse(JSON.stringify(this.cadastro));
        this.selectEmail =
          this.newCadastro.email && this.newCadastro.email.length > 0
            ? this.newCadastro.email.split(",")
            : [];
        this.selectTelefone =
          this.newCadastro.telefone && this.newCadastro.telefone.length > 0
            ? this.newCadastro.telefone.split(",")
            : [];
      }
      if (this.isNewCadastro) {
        this.newCadastro = {
          cpfcnpj: "",
          nome: "",
          razao: "",
          email: "",
          telefone: "",
          cep: "",
          estado: "",
          cidade: "",
          bairro: "",
          endereco: "",
          numero: "",
          complemento: "",
          codigo: "",
          dtnascimento: "",
          cliente: true,
          funcionario: false,
          fornecedor: false,
          dependente: false,
          ativo: true,
          inscricao_estadual: null
        };

        this.selectEmail = [];
        this.selectTelefone = [];
      }
      this.errors = [];
      // document.getElementById("valorCPFCNPJ").classList.add("noDisplay");
    },
    cadastro(newValue, oldValue) {
      this.newCadastro = newValue;

      if (newValue.email !== oldValue.telefone) {
        this.selectEmail = newValue.email
          ? String(newValue.email).split(',')
          : [];
      }
      if (newValue.telefone !== oldValue.telefone) {
        this.selectTelefone = newValue.telefone
          ? String(newValue.telefone).split(',')
          : [];
      }
    },
    newCadastro(value) {
      this.$store.commit("cadastro", value);
    }
  },
  methods: {
    adicionaNovoValor(value, done) {
      if (this.newCadastro.email != null) {
        if (this.newCadastro.email && this.newCadastro.email.length === 0) {
          this.newCadastro.email = value;
        } else {
          this.newCadastro.email = this.newCadastro.email + "," + value;
        }
      }
      done(value);
    },
    adicionaNovoValorTelefone(value, done) {
      if (this.newCadastro.telefone != null) {
        if (this.newCadastro.telefone.length === 0) {
          this.newCadastro.telefone = value;
        } else {
          this.newCadastro.telefone = this.newCadastro.telefone + "," + value;
        }
      }
      done(value);
    },
    returnError() {
      return this.errors.nome ? true : false;
    },
    closeModal() {
      this.$store.commit("openDialogCadastro", false);
    },
    buscacep(value) {
      this.$store.dispatch("fetchCEPCadastros", value);
    },
    consultacnpj(value) {
      let dados = {
        cnpj: this.newCadastro.cpfcnpj,
        url: "/cadastros/consultar-cnpj/"
      };
      this.$store.dispatch("fetchCNPJ", dados);
    },
    save() {
      this.saveCadastro = true;

      let cadastro_id = this.fetchCNPJ.data && this.fetchCNPJ.data.id
        ? this.fetchCNPJ.data.id
        : this.cadastro.id;

      let url = this.isNewCadastro
        ? "/cadastros/add"
        : "/cadastros/edit/" + cadastro_id;
      axios
        .post(url, this.newCadastro)
        .then(res => {
          if (res.data.success) {
            if (this.$route.path === "/cadastros") {
              this.$store.dispatch("listarCadastros", this.filtrosCadastro);
            }
            Notify.create({
              message: "Cadastro salvo com sucesso!",
              color: "green",
              position: "top-right"
            });

            let c = res.data.data.cadastro;

            if(this.$route.name === 'calendario'){
              this.$store.commit("cadastroReturn", c);
              this.saveCadastro = false;
              this.closeModal();
              return;
            }

            /**
             * Isso aqui vai ser usado na parte dos caixas quando chegar a hora
             */
            this.$store.commit("cadastroReturn", c);

            bus.$emit("novoCadastroAdicionado");

            if (
              this.$route.name === "caixas" ||
              this.$route.name === "parametros"
            ) {
              this.closeModal();
              this.errors = [];
              this.saveCadastro = false;
              return;
            }

            if (
                this.isNewCadastro &&
                this.$route.name !== "lancamento_caixa" &&
                this.$route.name !== 'caixa' &&
                this.$route.name !== 'receitas' &&
                this.$route.name !== 'despesas'
              ) {
              // Trocar código abaixo pelo código comentado quando os caixas entrarem em vigor
              this.$router.push("/cadastros/" + res.data.data.cadastro.id);

              if (this.$route.name !== "lancamento_caixa" && !this.cadastro.noLeave) {
                this.$router.push("/cadastros/" + res.data.data.cadastro.id);
              } else {
                document.getElementsByName("descricaoCaixa")[0].focus();
              }
            }
            if (
              this.$route.path ===
              "/cadastros/" + res.data.data.cadastro.id
            ) {
              this.$store.dispatch("viewCadastro", res.data.data.cadastro.id);
            }
            this.closeModal();
            this.errors = [];
          } else {
            if(res.data && res.data.errors){
              this.errors = res.data.errors;
            }
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          this.saveCadastro = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.saveCadastro = false;
        });
    }
  },
  computed: {
    openDialogCadastro: {
      get() {
        return this.$store.getters.openDialogCadastro;
      },
      set() {}
    },
    ...mapGetters([
      "cadastro",
      "fetchCEP",
      "isNewCadastro",
      "filtrosCadastros",
      "fetchCNPJ"
    ])
  },
  mounted() {}
};
</script>

<style lang="stylus" scoped>
.input-money {
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  border: none;
  border-top-color: initial;
  border-top-style: none;
  border-top-width: initial;
  border-right-color: initial;
  border-right-style: none;
  border-right-width: initial;
  border-bottom-color: initial;
  border-bottom-style: none;
  border-bottom-width: initial;
  border-left-color: initial;
  border-left-style: none;
  border-left-width: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  background: none;
  color: rgba(0, 0, 0, 0.87);
  outline: 0;
  padding: 0 0;
  position: relative;
  top: 15px;
}
</style>
