import axios from "axios";
import { Notify } from "quasar";
import register from "./register";
import cadastros from "./cadastros";

const state = {
  fetchCNPJ: {
    cnpj: "",
    data: {},
    buscandoCNPJ: false
  }
};

const mutations = {
  fetchCNPJ: (state, userData) => {
    state.fetchCNPJ.cnpj = userData.cnpj;
    state.fetchCNPJ.data = userData.data;
    state.fetchCNPJ.buscandoCNPJ = userData.buscandoCNPJ;
  }
};

const actions = {
  fetchCNPJ: ({ commit, dispatch, state }, userData) => {
    let cadastro = cadastros.state.cadastro;
    let onSuccess = null;
    if (userData.onSuccess) {
      onSuccess = userData.onSuccess;
      delete userData.onSuccess;
    }

    if (!userData.cnpj) {
      Notify.create({
        message: "CNPJ Vazio.",
        color: "orange",
        position: "top-right"
      });
      return;
    }
    // console.log(userData);
    let cnpj = userData.cnpj;
    cnpj = cnpj.replace(/\D/g, "");
    // Expressão regular para validar o cnpj.
    let validacnpj = /^[0-9]{14}$/;
    // Valida o formato do CNPJ
    if (!validacnpj.test(cnpj) || cnpj === "") {
      if (cnpj !== "") {
        Notify.create({
          message: "CNPJ Inválido.",
          color: "orange",
          position: "top-right"
        });
        return;
      }
      Notify.create({
        message: "CNPJ Inválido ouu Vazio.",
        color: "orange",
        position: "top-right"
      });
      return;
    }

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    if (userData.url === "/cadastros/consultar-cnpj/") {

      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Bearer " +
          localStorage.getItem("token" + localStorage.getItem("empresa_atual"))
      };
    }

    commit("fetchCNPJ", {
      buscandoCNPJ: true
    });

    let url = userData.url + cnpj;

    axios
      .get(url, {
        headers: headers
      })
      .then(res => {
        if (userData.url === "/cadastros/consultar-cnpj/") {
          if (res.data.success) {
            Notify.create({
              message: "Empresa encontrada!",
              color: "green",
              position: "top-right"
            });
            let dados = {
              email: res.data.data.email,
              nome: res.data.data.nome,
              telefone: res.data.data.telefone,
              cnpj: res.data.data.cnpj,
              atividade: `${res.data.data.atividade_principal[0].code} - ${res.data.data.atividade_principal[0].text}`,
              razao: res.data.data.nome,
              cep: res.data.data.cep.replace(/\D/g, ""),
              cidade: res.data.data.municipio,
              estado: res.data.data.uf,
              bairro: res.data.data.bairro,
              endereco: res.data.data.logradouro,
              numero: res.data.data.numero
            };

            if(cadastro && cadastro.id > 0){
              dados.id = cadastro.id;
            }

            commit("fetchCNPJ", {
              buscandoCNPJ: false,
              data: dados
            });
            commit("cadastro", {
              id: cadastros.state.cadastro.id,
              cpfcnpj: res.data.data.cnpj,
              nome: res.data.data.nome,
              razao: res.data.data.nome,
              email: cadastros.state.cadastro.email && String(cadastros.state.cadastro.email).trim() !== ''
                ? cadastros.state.cadastro.email
                : res.data.data.email,
              telefone: cadastros.state.cadastro.telefone && String(cadastros.state.cadastro.telefone).trim() !== ''
                ? cadastros.state.cadastro.telefone
                : res.data.data.telefone,
              cep: res.data.data.cep.replace(/\D/g, ""),
              estado: res.data.data.uf,
              cidade: res.data.data.municipio,
              bairro: res.data.data.bairro,
              endereco: res.data.data.logradouro,
              numero: res.data.data.numero,
              complemento: cadastros.state.cadastro.complemento,
              codigo: cadastros.state.cadastro.codigo,
              dtnascimento: cadastros.state.cadastro.dtnascimento,
              cliente: cadastros.state.cadastro.cliente,
              funcionario: cadastros.state.cadastro.funcionario,
              fornecedor: cadastros.state.cadastro.fornecedor,
              dependente: cadastros.state.cadastro.dependente,
              ativo: cadastros.state.cadastro.ativo
            });
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            commit("fetchCNPJ", {
              buscandoCNPJ: false,
              data: {}
            });
          }
        } else {
          if (res.data.status === "ERROR") {
            Notify.create({
              message: "CNPJ Inválido.",
              color: "orange",
              position: "top-right"
            });
            commit('registrar', {
              ...register.state.registrar,
              errors: {
                ...register.state.registrar.errors,
                cnpj: ['CNPJ Inválido.'],
              },
            });
            commit("fetchCNPJ", {
              buscandoCNPJ: false,
              data: {}
            });
          } else {
            let dados = {
              email: register.state.registro.email,
              nome: register.state.registro.nome,
              celular: register.state.registro.celular,
              tfls: register.state.registro.tfls,
              complemento: register.state.registro.complemento,
              acceptTerms: register.state.registro.acceptTerms,
              telefone: res.data.telefone,
              cnpj: res.data.cnpj,
              atividade: `${res.data.atividade_principal[0].code} - ${res.data.atividade_principal[0].text}`,
              razao: res.data.nome,
              cep: res.data.cep.replace(/\D/g, ""),
              cidade: res.data.municipio,
              estado: res.data.uf,
              bairro: res.data.bairro,
              endereco: res.data.logradouro,
              numero: res.data.numero,
              uuid: register.state.registro.uuid,
            };
            commit("fetchCNPJ", {
              buscandoCNPJ: false,
              data: dados
            });
            dispatch("registro", state.fetchCNPJ.data);

            if (register.state.registrar.errors.cnpj) {
              delete register.state.registrar.errors.cnpj;
            }

            // Notify.create({
            //   message: "Empresa encontrada!",
            //   color: "green",
            //   position: "top-right"
            // });

            if (onSuccess) {
              onSuccess();
            }
          }
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível obter os dados do CNPJ!",
          color: "red",
          position: "top-right"
        });
        commit('registrar', {
          ...register.state.registrar,
          errors: {
            cnpj: ['Não foi possível obter os dados do CNPJ!'],
          },
        });
        commit("fetchCNPJ", {
          buscandoCNPJ: false,
          data: {}
        });
      });
  }
};

const getters = {
  fetchCNPJ: state => {
    return state.fetchCNPJ;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
