<template>
  <div id="modalNovoCaixa">
    <q-dialog v-model="OpenDialogNovoLancamentoCaixa" persistent>
      <q-card style="max-width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Novo Caixa</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="$store.commit('OpenDialogNovoLancamentoCaixa', false)"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12" id="dtmovimentoCaixa">
                <q-input
                  bottom-slots
                  v-model="dtmovimento"
                  label="Data"
                  :error="errors.data ? true : false"
                  @click="$refs.qDateProxy.show();"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="dtmovimento"
                          @input="() => $refs.qDateProxy.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.data" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12" id="selectcaixaCaixa">
                <q-select
                  clearable
                  :options="caixas"
                  v-model="caixaSelecionado"
                  option-value="id"
                  option-label="nome"
                  emit-value
                  map-options
                  label="Caixa"
                  bottom-slots
                  :error="errors.caixa_id ? true : false"
                  >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.caixa_id"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12" id="operadorCaixa">
                <q-select
                  clearable
                  :options="listarFuncionarios"
                  v-model="operadorSelecionado"
                  option-value="id"
                  option-label="nome"
                  emit-value
                  map-options
                  label="Operador"
                  bottom-slots
                  :error="errors.func_resp_id ? true : false"
                  >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.func_resp_id"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="salvar"
            :loading="salvando"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify } from "quasar";
const moment = require("moment");
export default {
  name: "modal-novo-caixa",
  data() {
    return {
      errors: {},
      caixaSelecionado: null,
      salvando: false,
      operadorSelecionado: null,
      dtmovimento : null
    };
  },
  methods: {
    salvar() {
      this.errors = {};

      if (!this.operadorSelecionado){
        this.errors.func_resp_id = {message:"Selecione um operador!"};
      }
      if (!this.caixaSelecionado){
        this.errors.caixa_id = {message:"Selecione um caixa!"};
      }
      if (this.errors.func_resp_id || this.errors.caixa_id){
        return false;
      }

      this.salvando = true;
      let dados = {
        func_resp_id: this.operadorSelecionado,
        caixa_id: this.caixaSelecionado,
        dtmovimento: this.dtmovimento
      };

      dados.func_resp_id  = this.operadorSelecionado && this.operadorSelecionado.cadastro_id
                          ? this.operadorSelecionado.cadastro_id : this.operadorSelecionado;

      axios
        .post("/lancamentos/abrir", dados)
        .then(res => {
          Notify.create({
            message: res.data.message,
            color: res.data.success ? "green" : "orange",
            position: "top-right"
          });

          this.salvando = false;

          if (!res.data.success) {
            this.errors = res.data.errors || {};
            return;
          }

          this.dtmovimento = moment().format("DD/MM/YYYY");
          this.caixaSelecionado = null;
          this.operadorSelecionado = null;
          this.$store.dispatch("resetPagingCaixas");
          this.$store.dispatch("listarCaixas");
          this.$store.commit("OpenDialogNovoLancamentoCaixa", false);
          this.$router.push("/caixas/" + res.data.movcaixa.id);
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.salvando = false;
        });
    },
    clear() {
      this.$store.dispatch("newMovcaixa");
      this.$store.commit("errorsCaixas", {});
    },
    attFunc(value) {
      this.operador = value;
    }
  },
  computed: {
    ...mapGetters([
      "dataUser",
      "caixas",
      "listarFuncionarios",
      "novoCaixa",
    ]),
    OpenDialogNovoLancamentoCaixa: {
      get() {
        return this.$store.getters.OpenDialogNovoLancamentoCaixa;
      },
      set() {}
    }
  },
  mounted() {
    this.dtmovimento = moment().format("DD/MM/YYYY");
    if (this.dataUser && this.dataUser.cadastro_id){
      this.operadorSelecionado = this.dataUser;
    }
  }
};
</script>

<style lang="stylus" scoped></style>
