<template>
  <div id="modalLiberarLicenca">
    <q-dialog v-model="OpenDialogLiberarLicenca" persistent>
      <q-card style="width:400px">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">Data de Bloqueio</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-input
                  bottom-slots
                  :error="errorsLicencas.bloquear_em ? true : false"
                  v-model="bloquear_em"
                  label="Bloquear Em"
                  @click="$refs.qDateProxy.show();"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="bloquear_em"
                          @input="() => $refs.qDateProxy.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsLicencas.bloquear_em"
                      :key="error + i"
                    >{{error}}</span>
                  </template>
                </q-input>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="liberarLicencas"
            :loading="aguardeBackground"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "modal-liberar-licenca",
  data() {
    return {
      bloquear_em: ""
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogLiberarLicenca", false);
    },
    liberarLicencas() {
      this.$store.dispatch("liberarLicencas", this.bloquear_em);
    }
  },
  computed: {
    ...mapGetters(["errorsLicencas", "aguardeBackground"]),
    OpenDialogLiberarLicenca: {
      get() {
        return this.$store.getters.OpenDialogLiberarLicenca;
      },
      set() {}
    }
  }
};
</script>

<style lang="stylus" scoped></style>
