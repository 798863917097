<template>
  <q-page id="despesas_recorrencias" class="q-pa-md">
    <sem-permissao v-if="semPermissao"></sem-permissao>

    <q-table
      row-key="id"
      no-data-label="Nenhum dado"
      no-results-label="Nenhum resultado obtido"
      loading-label="Carregando dados"
      :data="despesasRecorrencias"
      :columns="columns"
      :loading="loading"
      :pagination.sync="pagination"
    >
      <template v-slot:bottom></template>

      <template v-slot:body="props">
        <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
          <q-td key="cadastro" class="text-left">
            <router-link
              style="text-decoration: none; color: #000"
              :to="`/cadastros/${props.row.cadastro_id}`"
            >
              {{
                props.row.cadastro
                  ? props.row.cadastro.nome.length > 40
                    ? props.row.cadastro.nome.substr(0, 40) + '...'
                    : props.row.cadastro.nome
                  : 'Sem Cadastro'
              }}
              <q-tooltip
                v-if="
                  props.row.cadastro &&
                  props.row.cadastro.nome &&
                  props.row.cadastro.nome.length > 40
                "
              >
                {{ props.row.cadastro.nome }}
              </q-tooltip>
            </router-link>
          </q-td>

          <q-td key="descricao" class="text-left">
            {{ props.row.descricao | truncate(80) }}
            <q-tooltip v-if="props.row.descricao && props.row.descricao.length > 80" >
              {{ props.row.descricao }}
            </q-tooltip>
          </q-td>

          <q-td key="valor" class="text-right">
            R$ {{ Math.abs(props.row.valor) | currency }}
          </q-td>

          <q-td key="dia_vencimento" class="text-center">
            {{ props.row.dia_vencimento }} - {{ tipoReferenciaText(props.row.tipo_referencia) }}
          </q-td>

          <q-td key="ultima_geracao" class="text-center">
            <span>{{ new Date(props.row.ultima_geracao) | formatDate }}</span>
          </q-td>

          <q-td key="dtfim" class="text-center">
            <span v-if="props.row.dtfim">{{ new Date(props.row.dtfim) | formatDate }}</span>
            <span v-else class="text-grey">Indeterminado</span>
          </q-td>

          <q-td v-if="$q.platform.is.desktop" class="tdfora">
            <div class="divfora">
              <div class="divdentro q-gutter-sm" style="margin-right: 10px">

                <q-btn
                  @click="modalEditDespesaRecorrencia(props.row)"
                  color="primary"
                  icon="edit"
                  size="12px"
                ></q-btn>

                <q-btn
                  @click="excluirDespesasRecorrencia(props.row)"
                  color="negative"
                  icon="delete_forever"
                  size="12px"
                ></q-btn>

              </div>
            </div>
          </q-td>
        </q-tr>
        <q-tr v-show="props.expand" :props="props">
          <q-td colspan="100%">
            <div class="q-py-sm q-gutter-sm">
              <q-btn
                @click="modalEditDespesaRecorrencia(props.row)"
                color="primary"
                icon="edit"
                size="12px"
              />
              <q-btn
                @click="excluirDespesasRecorrencia(props.row)"
                color="negative"
                icon="delete_forever"
                size="12px"
              />
            </div>
          </q-td>
        </q-tr>
      </template>

      <template v-slot:top>
        <q-btn
          flat
          round
          dense
          icon="add"
          color="primary"
          class="q-ml-md"
          style="margin-right: 10px; margin-left: 0"
          @click="modalAddDespesaRecorrencia()"
        />
        <div class="q-table__title">Despesa Recorrentes</div>
      </template>
    </q-table>

    <div class="text-center" v-show="loading">
      <q-spinner color="blue-7" size="3em" />
    </div>

    <!-- Modal Salvar Despesa Recorrencia -->
    <q-dialog v-model="openDialogSalvarDespesasRecorrencia" persistent>
      <q-card :style="`width: 100%; max-width: ${opcoesAvancadas ? '800px' : '400px'};`">
        <q-card-section class="row items-center">
          <div class="text-h6">{{ despesasRecorrencia.id ? 'Editar' : 'Nova' }} Despesa Recorrente</div>
          <q-space />
          <q-btn
            icon="close"
            clickable
            flat
            round
            dense
            v-close-popup
            @click="closeModalSalvarDespesasRecorrencia()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section class="row q-col-gutter-lg">
          <div :class="`col-12 col-md-${opcoesAvancadas ? 6 : 12}`">
            <div class="row q-col-gutter-x-md">

              <div class="col-12" v-show="!despesasRecorrencia.id">
                <q-select
                  clearable
                  use-input
                  bottom-slots
                  option-value="id"
                  option-label="nome"
                  label="Fornecedor"
                  input-debounce="0"
                  new-value-mode="add-unique"
                  v-model="despesasRecorrencia.cadastro"
                  :options="fornecedores"
                  :error="Boolean(errors.cadastro_id)"
                  @new-value="createFornecedor"
                  @filter="buscarFornecedor"
                  @input="pulaCampo('descricao')"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">
                        Sem resultados
                      </q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.cadastro_id"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                  <template v-slot:hint v-if="despesasRecorrencia.cadastro">
                    <span v-if="despesasRecorrencia.cadastro.id">O cadastro está selecionado</span>
                    <span v-else>Um novo cadastro será realizado</span>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <q-input
                  v-model="despesasRecorrencia.descricao"
                  label="Descrição"
                  bottom-slots
                  ref="descricao"
                  @keydown.enter.prevent="pulaCampo('dia_vencimento')"
                  :error="Boolean(errors.descricao)"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.descricao" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>

              <div class="col-12 col-sm-5">
                <q-input
                  v-model="despesasRecorrencia.dia_vencimento"
                  label="Dia de vencimento"
                  bottom-slots
                  mask="##"
                  ref="dia_vencimento"
                  :error="Boolean(errors.dia_vencimento)"
                  @keydown.enter.prevent="pulaCampo('valor')"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.dia_vencimento" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>

              <div
                class="col-12 col-sm-7"
                @keydown.enter.prevent="pulaCampo('ultima_geracao')"
              >
                <q-field
                  v-model="despesasRecorrencia.valor"
                  label="Valor"
                  bottom-slots
                  :error="Boolean(errors.valor)"
                  >
                  <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                      ref="valor"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.valor"
                      :key="error + i"
                    >
                      {{error}}
                    </span>
                  </template>
                </q-field>
              </div>

              <div class="col-12 col-sm-6">
                <q-input
                  clearable
                  bottom-slots
                  label="Última"
                  :error="Boolean(errors.ultima_geracao)"
                  v-model="despesasRecorrencia.ultima_geracao"
                  @click="$refs.ultima_geracao.show()"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="ultima_geracao"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="despesasRecorrencia.ultima_geracao"
                          @input="() => pulaCampo('dtfim')"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.ultima_geracao"
                      :key="error + i"
                      >
                        {{ error }}
                      </span
                    >
                  </template>
                </q-input>
              </div>

              <div class="col-12 col-sm-6">
                <q-input
                  clearable
                  bottom-slots
                  label="Data de fim"
                  :error="Boolean(errors.dtfim)"
                  v-model="despesasRecorrencia.dtfim"
                  @click="$refs.dtfim.show()"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="dtfim"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="despesasRecorrencia.dtfim"
                          @input="() => pulaCampo('conta_id')"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.dtfim"
                      :key="error + i"
                      >
                        {{ error }}
                      </span
                    >
                  </template>
                </q-input>
              </div>

              <div class="row col-12">
                <div class="col-12">
                  <q-select
                  clearable
                  v-model="despesasRecorrencia.tipo_referencia"
                  option-label="label"
                  option-value="value"
                  :options="referenciaOptions"
                  label="Referência"
                  emit-value
                  map-options
                  :error="errors.tipo_referencia ? true : false"
                  >
                  </q-select>
                </div>
              </div>

              <div class="row q-pt-md">
                <div class="col">
                  <q-checkbox
                    v-model="cobrar"
                    clickable
                    label="Incluir forma de pagamento"
                    color="green"
                    id="checkDespesa"
                  />
                </div>
              </div>

              <div class="row col-12">
                <div class="col-12" v-if="cobrar">
                  <q-select
                    clearable
                    v-model="despesasRecorrencia.gateway_id"
                    option-label="titulo"
                    option-value="id"
                    :options="findGatewaysDespesa.gateways"
                    emit-value
                    map-options
                    bottom-slots
                    :error="errors.gateway_id ? true : false"
                    label="Forma de Pagamento"
                    ref="formaPagamento"
                    @input="() => pulaCampo('planodeconta_id')"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey"
                          >Sem Resultados</q-item-section
                        >
                      </q-item>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.gateway_id"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-select>
                </div>
                <div class="col-12" v-else>
                  <q-select
                    clearable
                    bottom-slots
                    option-value="id"
                    option-label="titulo"
                    label="Contas"
                    :options="contas"
                    v-model="despesasRecorrencia.conta"
                    :error="Boolean(errors.conta_id)"
                    ref="conta_id"
                    @input="pulaCampo('planodeconta_id')"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          Sem Resultados
                        </q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.conta_id"
                        :key="error + i"
                      >
                        {{error}}
                      </span>
                    </template>
                  </q-select>
                </div>
              </div>

              <div class="col-12">
                <q-select
                  use-input
                  clearable
                  bottom-slots
                  input-debounce="0"
                  option-value="id"
                  option-label="titulo"
                  label="Planos de contas"
                  @filter="filterPlanosdecontas"
                  :options="planosdecontasOptions"
                  v-model="despesasRecorrencia.planodeconta"
                  ref="planodeconta_id"
                  :error="Boolean(errors.planodeconta_id)"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">
                        Sem Resultados
                      </q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.planodeconta_id"
                      :key="error + i"
                    >
                      {{error}}
                    </span>
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <q-checkbox
                  color="green"
                  label="Opções Avançadas"
                  v-model="opcoesAvancadas"
                />
              </div>

            </div>
          </div>

          <div class="col-12 col-md-6" v-show="opcoesAvancadas">
            <div class="row">

              <div class="col-12 q-mb-md">
                <q-select
                  v-model="newRateio.centrodecusto"
                  :option-label="value => value === null ? 'Item Vazio' : value.nome"
                  :option-value="value => value === null ? null : value"
                  :options="optionsCentrosdecusto"
                  label="Centro de Custo"
                  :hide-bottom-space="!Boolean(errors.centrodecusto)"
                  :error="Boolean(errors.centrodecusto)"
                  ref="centrodecusto"
                  @input="pulaCampo('porcentagem')"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span>{{ errors.centrodecusto }}</span>
                  </template>
                </q-select>
              </div>

              <div class="col-2 flex items-end">
                <q-btn
                  color="white"
                  text-color="black"
                  label="%"
                  disable
                />
              </div>

              <div class="col-8" @keydown.enter.prevent="addRateio()">
                <q-field
                  v-model="newRateio.porcentagem"
                  label="Porcentagem"
                  :hide-bottom-space="!Boolean(errors.porcentagem)"
                  :error="Boolean(errors.porcentagem)"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="percentFormat"
                      v-show="floatingLabel"
                      ref="porcentagem"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span>{{ errors.porcentagem }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-2 flex items-end justify-end">
                <q-btn
                  icon="add"
                  color="primary"
                  flat
                  round
                  @click="addRateio()"
                />
              </div>

              <div class="col-12 q-mt-md">
                <p
                  v-if="porcentagemRateioTotal > 100"
                  class="text-negative caption"
                >
                  A porcentagem total passa de 100%
                </p>
                <div v-if="Boolean(errors.despesas_recorrencias_rateios)">
                  <p
                    class="text-negative caption"
                    v-for="(error, index) in errors.despesas_recorrencias_rateios"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>

                <q-markup-table
                  flat
                  dense
                >
                  <tbody>
                    <tr
                      v-for="(rateio, index) in despesasRecorrencia.despesas_recorrencias_rateios"
                      :key="index"
                    >
                      <td>{{ rateio.centrocusto.nome }}</td>
                      <td class="text-right">{{ rateio.porcentagem }} %</td>
                      <td style="width: 30px;">
                        <q-btn
                          flat
                          dense
                          color="red"
                          icon="delete"
                          @click="despesasRecorrencia.despesas_recorrencias_rateios.splice(index, 1)"
                        />
                      </td>
                    </tr>
                    <tr v-if="despesasRecorrencia.despesas_recorrencias_rateios.length > 0">
                      <td colspan="2"><strong>Total</strong></td>
                      <td class="text-right">{{ porcentagemRateioTotal }} %</td>
                    </tr>
                    <tr v-if="despesasRecorrencia.despesas_recorrencias_rateios.length === 0">
                      <td colspan="3" class="text-center">Nenhum rateio</td>
                    </tr>
                  </tbody>
                </q-markup-table>
              </div>

            </div>

          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            no-caps
            v-ripple
            clickable
            type="submit"
            label="Salvar"
            color="positive"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="loadingSalvarDespesasRecorrencia"
            @click="salvarDespesasRecorrencia()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>

      </q-card>
    </q-dialog>

  </q-page>
</template>

<script>
import moment from 'moment';
import axios from '@/axios-auth';
import { Loading } from 'quasar';
import { Money } from 'v-money';
import { mapGetters } from 'vuex';
import { bus } from '@/main';
import router from "@/router/router";

export default {
  name: 'despesas-recorrencias',
  meta: {
    title: 'DSB | Despesas Recorrentes',
  },
  components: { Money },
  data() {
    return {
      // Default
      cobrar: false,
      semPermissao: false,
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      percentFormat: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: ' %',
        precision: 2,
        masked: false,
      },
      // Tabela
      columns: [
        {
          name: 'cadastro',
          label: 'Cadastro',
          field: row => row.cadastro ? row.cadastro.nome : null,
          align: 'center',
          sortable: true,
        },
        {
          name: 'descricao',
          label: 'Descrição',
          field: row => row.descricao,
          align: 'center',
          sortable: true,
        },
        {
          name: 'valor',
          label: 'Valor',
          field: row => row.valor,
          align: 'center',
          sortable: true,
        },
        {
          name: 'dia_vencimento',
          label: 'Dia de Vencimento',
          field: row => row.dia_vencimento,
          align: 'center',
          sortable: true,
        },
        {
          name: 'ultima_geracao',
          label: 'Última',
          field: row => row.ultima_geracao,
          align: 'center',
          sortable: true,
        },
        {
          name: 'dtfim',
          label: 'Data de fim',
          field: row => row.dtfim,
          align: 'center',
          sortable: true,
        },
        { name: 'tdfora' },
      ],
      pagination: {
        sortBy: 'id',
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      // Despesas Recorrencias
      despesasRecorrencias: [],
      loading: false,
      paging: {
        page: 0,
        nextPage: true,
      },
      filters: {
        busca: null,
      },
      // Salvar Despesas Recorrencia
      openDialogSalvarDespesasRecorrencia: false,
      loadingSalvarDespesasRecorrencia: false,
      opcoesAvancadas: false,
      despesasRecorrencia: {
        descricao: '',
        valor: 0.00,
        dia_vencimento: moment().format('DD'),
        ultima_geracao: moment().subtract(1, 'month').format('DD/MM/YYYY'),
        dtfim: null,
        conta: null,
        tipo_referencia: null,
        gateway_id: null,
        planodeconta: null,
        despesas_recorrencias_rateios: [],
      },
      errors: {},
      newRateio: {
        centrodecusto: null,
        porcentagem: 0,
      },
      planosdecontas: [],
      planosdecontasOptions: [],
      fornecedores: [],
      referenciaOptions: [
        { label: 'Mês Atual', value: 0 },
        { label: 'Mês Anterior', value: 1 },
        { label: 'Ano Atual', value: 2 }
      ]
    };
  },
  methods: {
    loadDespesasRecorrencias(concat = false) {
      if (this.loading || !this.paging.nextPage) return;

      this.loading = true;

      const params = {
        page: 1,
        busca: this.filters.busca,
      };

      if (concat) {
        params.page = this.paging.page + 1;
      }

      const query = router.history.current.query;
      if (query.cadastro_id) {
        params.cadastro_id = query.cadastro_id;
      }

      axios
        .get('/financeiro/despesas-recorrencias', { params })
        .then(res => {
          this.loading = false;

          if (this.notificarSemPermissao(res)) return;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          if (concat) {
            this.despesasRecorrencias.push(...res.data.despesas_recorrencias);
          } else {
            this.despesasRecorrencias = res.data.despesas_recorrencias;
          }

          this.paging = res.data.paging;
          this.filters = res.data.filters;
        })
        .catch(() => {
          this.loading = false;

          this.$q.notify({
            message: 'Houve um erro ao listar as despesas recorrentes',
            color: 'orange',
            position: 'top-right',
          });
      });
    },
    buscaDespesasRecorrencias(busca) {
      this.filters.busca = busca;
      this.paging = {
        page: 0,
        nextPage: true,
      };

      this.loadDespesasRecorrencias();
    },
    modalAddDespesaRecorrencia() {
      this.cobrar = false;

      this.despesasRecorrencia = {
        descricao: '',
        valor: 0.00,
        dia_vencimento: moment().format('DD'),
        ultima_geracao: moment().subtract(1, 'month').format('DD/MM/YYYY'),
        dtfim: null,
        conta: null,
        tipo_referencia: 0,
        gateway_id: null,
        planodeconta: null,
        despesas_recorrencias_rateios: [],
      };

      this.openModalSalvarDespesasRecorrencia();
    },
    modalEditDespesaRecorrencia({ id }) {
      axios
        .get(`/financeiro/view-despesas-recorrencia/${id}`)
        .then(res => {
          if (this.notificarSemPermissao(res)) return;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-left',
            });
            return;
          }

          const despesasRecorrencia = res.data.despesas_recorrencia;
          despesasRecorrencia.valor = Math.abs(despesasRecorrencia.valor);

          despesasRecorrencia.ultima_geracao = moment(despesasRecorrencia.ultima_geracao, "YYYY/MM/DD").format("DD/MM/YYYY");
          if (despesasRecorrencia.dtfim) {
            despesasRecorrencia.dtfim = moment(despesasRecorrencia.dtfim, "YYYY/MM/DD").format("DD/MM/YYYY");
          }

          this.despesasRecorrencia = despesasRecorrencia;
          if (this.despesasRecorrencia.gateway_id){
            this.cobrar = true;
          }

          this.despesasRecorrencia.tipo_referencia = despesasRecorrencia.tipo_referencia;

          this.openModalSalvarDespesasRecorrencia();
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao recuperar a despesa recorrente',
            color: 'red',
            position: 'top-left',
          });
        });
    },
    openModalSalvarDespesasRecorrencia() {
      this.errors = {};
      this.opcoesAvancadas = false;
      this.openDialogSalvarDespesasRecorrencia = true;
    },
    closeModalSalvarDespesasRecorrencia() {
      this.openDialogSalvarDespesasRecorrencia = false;
    },
    loadPlanosdecontas() {
      axios
        .get('/plano-contas?cd=D')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.planosdecontas = res.data.planodecontas;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    salvarDespesasRecorrencia() {
      this.loadingSalvarDespesasRecorrencia = true;
      this.despesasRecorrencia.tipo_referencia = this.despesasRecorrencia.tipo_referencia;
      const despesasRecorrenciaId = this.despesasRecorrencia.id;
      const data = JSON.parse(JSON.stringify(this.despesasRecorrencia));

      if (data.conta) {
        data.conta_id = data.conta.id;
      }
      if (data.planodeconta) {
        data.planodeconta_id = data.planodeconta.id;
      }

      data.despesas_recorrencias_rateios = data.despesas_recorrencias_rateios.map(
        rateio => ({ centrocusto_id: rateio.centrocusto.id, porcentagem: rateio.porcentagem })
      );

      let url = `/financeiro/add-despesas-recorrencia`;
      if (despesasRecorrenciaId) {
        url = `/financeiro/edit-despesas-recorrencia/${despesasRecorrenciaId}`;
      }

      axios
        .post(url, data)
        .then(res => {
          this.loadingSalvarDespesasRecorrencia = false;

          if (this.notificarSemPermissao(res)) return;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) {
            this.errors = res.data.errors || {};
            return;
          };

          this.closeModalSalvarDespesasRecorrencia();
          this.paging = {
            page: 0,
            nextPage: true,
          };
          this.loadDespesasRecorrencias();
        })
        .catch(() => {
          this.loadingSalvarDespesasRecorrencia = false;

          this.$q.notify({
            message: 'Houve um erro ao salvar a despesa recorrente',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    excluirDespesasRecorrencia(despesasRecorrencia) {
      this.$q.dialog({
        title: despesasRecorrencia.descricao,
        message: 'Tem certeza que deseja excluir despesa recorrente a despesa recorrente?',
        cancel: 'Cancelar',
        persistent: true,
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(`/financeiro/delete-despesas-recorrencia/${despesasRecorrencia.id}`)
            .then(res => {
              if (this.notificarSemPermissao(res)) return;

              if (!res.data.success) return;
              this.$q.dialog({
                title: res.data.message,
                class: `bg-${res.data.success ? 'positive' : 'warning'} text-white`,
                ok: {
                  'text-color': res.data.success ? 'green' : 'orange',
                  color: 'white',
                },
              });

              this.paging = {
                page: 0,
                nextPage: true,
              };
              this.loadDespesasRecorrencias();
            })
            .catch(() => {
              this.$q.dialog({
                title:
                  'Não foi Possível realizar a ação, ou você não possui permissão isto!',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white'
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    addRateio() {
      this.errors.porcentagem = null;
      this.errors.centrodecusto = null;

      if (!this.newRateio.centrodecusto) {
        this.errors.centrodecusto = "Centro de custo é obrigatório";
        return;
      }

      if (!this.newRateio.porcentagem) {
        this.errors.porcentagem = "Porcentagem é obrigatória";
        return;
      }

      if (this.porcentagem < 0 || this.porcentagem > 100) {
        this.errors.porcentagem = "Valor inválido";
        return;
      }

      const rateioIndex = this.despesasRecorrencia.despesas_recorrencias_rateios.findIndex(rateio => rateio.centrocusto.id === this.newRateio.centrodecusto.id);

      const rateio = {
        porcentagem: Math.abs(Number(this.newRateio.porcentagem)),
        centrocusto: {
          id: this.newRateio.centrodecusto.id,
          nome: this.newRateio.centrodecusto.nome,
        },
      };

      if (rateioIndex === -1) {
        this.despesasRecorrencia.despesas_recorrencias_rateios.push(rateio);
      } else {
        this.despesasRecorrencia.despesas_recorrencias_rateios[rateioIndex] = rateio;
      }

      this.newRateio = {
        centrodecusto: null,
        porcentagem: 0,
      };

      this.pulaCampo('centrodecusto');
    },
    filterPlanosdecontas (val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.planosdecontasOptions = this.planosdecontas.filter(
          planodecontas => planodecontas.titulo.toLowerCase().includes(needle)
        );
      });
    },
    buscarFornecedor(val, update) {
      update(() => {
        axios
          .get(`/cadastros?ativo=1&fornecedor=1&funcionario=1&busca=${val}`)
          .then(res => {
            if (!res.data.success) return;
            this.fornecedores = res.data.data.cadastros;
          });
      });
    },
    createFornecedor(val, done) {
      let newCadastro = {
        id: null,
        nome: val,
      };
      done(newCadastro, "add-unique");
    },
    pulaCampo(campo, segundos = 1) {
      if (campo === 'valor' && this.movvendaItemValorDisable) {
        return this.addMovvendaItem();
      }

      const selecionar = field => {
        if (field.select) {
          setTimeout(() => {
            field.select();
          });
        }
      };

      const focar = () => {
        if (!this.$refs[campo]) {
          return;
        }

        if (this.$refs[campo].show) {
          return this.$refs[campo].show();
        }

        if (this.$refs[campo].$el.classList.contains("v-money")){
          this.$refs[campo].$el.focus();
          selecionar(this.$refs[campo].$el);
          return;
        }

        selecionar(this.$refs[campo]);
        this.$refs[campo].focus();

        if (this.$refs[campo].showPopup) {
          this.$refs[campo].showPopup();
        }
        return;
      };

      if (segundos) {
        return setTimeout(() => focar(), segundos);
      }

      focar();
    },
    handleScroll() {
      if (window.scrollY >= document.body.clientHeight - window.innerHeight - 20) {
        this.loadDespesasRecorrencias(true);
      }
    },
    notificarSemPermissao(res) {
      const semPermissao = res.error && res.error.response.status === 403;

      if (!semPermissao) {
        return false;
      }

      this.$q.notify({
        message: res.error.response.data.message,
        color: 'red',
        position: 'top-right',
      });

      this.semPermissao = true;
      return true;
    },
    tipoReferenciaText($tipo_referencia){
      switch ($tipo_referencia) {
        case 0:
          return 'Mês Atual';
        case 1:
          return 'Mês Anterior';
        case 2:
          return 'Ano Atual';
        default:
          return 'Mês Atual';
      }
    }
  },
  computed: {
    ...mapGetters([
      "findGatewaysDespesa",
      'contas',
      'centroCustos',
    ]),
    optionsCentrosdecusto() {
      return this.centroCustos.filter(centroCusto => {
        return !(this.despesasRecorrencia.despesas_recorrencias_rateios.find(rateio => rateio.centrocusto.id == centroCusto.id));
      });
    },
    porcentagemRateioTotal() {
      return this.despesasRecorrencia.despesas_recorrencias_rateios.reduce((total, rateio) => total + Math.abs(Number(rateio.porcentagem)), 0);
    },
  },
  watch: {
    cobrar(value) {
      if (value) {
        //this.$store.dispatch('findGatewaysDespesa');
      } else {
        despesasRecorrencia.gateway_id = null;
      }
    },
    'despesasRecorrencia.cadastro'(cadastro) {
      if (cadastro) {
        this.despesasRecorrencia.cadastro_id = cadastro.id;
      }
    },
    'despesasRecorrencia.dia_vencimento'() {
      const diaVencimento = parseInt(this.despesasRecorrencia.dia_vencimento);
      const ultimaGeracao = moment(this.despesasRecorrencia.ultima_geracao, 'DD/MM/YYYY');

      if (diaVencimento <= 0 || !ultimaGeracao.isValid()) return;

      if (diaVencimento >= ultimaGeracao.daysInMonth()) {
        ultimaGeracao.endOf('month');
      } else {
        ultimaGeracao.date(diaVencimento);
      }

      this.despesasRecorrencia.ultima_geracao = ultimaGeracao.format('DD/MM/YYYY');
    },
  },
  beforeMount() {
    bus.$off("buscaDespesasRecorrencias");
  },
  mounted() {
    this.loadDespesasRecorrencias();
    this.loadPlanosdecontas();
    this.$store.dispatch('contas');
    this.$store.dispatch("listarCentroCustos");
    this.$store.dispatch('findGatewaysDespesa');
    window.addEventListener("scroll", this.handleScroll);

    bus.$on("buscaDespesasRecorrencias", busca => this.buscaDespesasRecorrencias(busca));
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  },
};
</script>

<style lang="stylus"></style>
