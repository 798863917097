<template>
  <div id="openDialogNovaFormaCobranca">
    <q-dialog v-model="openDialogNovaFormaCobranca" persistent>
      <q-card style="max-width: 400px; width: 100%;">
        <q-card-section class="row items-center">
          <div class="text-h6">{{ titulo }}</div>
          <q-space />
          <q-btn flat dense round icon="close" v-close-popup @click="closeModal" />
        </q-card-section>

        <q-separator />

        <q-card-section v-if="etapa == 1">
          <div class="row">
            <div class="col-12">
              <q-select
                v-model="fornecedor"
                :options="bancos"
                :error="errors.fornecedor ? true : false"
                label="Selecione um Fornecedor"
                bottom-slots
                map-options
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.fornecedor" :key="error + i">{{ error }}</span>
                </template>
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem Resultados</q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>

            <div class="col-12" v-if="fornecedor">
              <q-option-group
                :options="gatewaysFiltrados"
                type="checkbox"
                v-model="gatewaysSelecionados"
              />
                <span
                  v-for="(error, i) in errors.tipos"
                  :key="error + i"
                  class="text-negative"
                >
                  {{ error }}
                </span>
            </div>

            <div class="col-12" v-if="fornecedor">
              <q-select
                v-model="newCobranca.conta"
                :options="contas"
                label="Selecione uma Conta"
                :option-label="value => value === null ? 'Item Vazio' : value.titulo"
                :option-value="value => value === null ? null : value"
                :error="errors.conta_id ? true : false"
                emit-value
                map-options
                bottom-slots
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.conta_id" :key="error + i">{{error}}</span>
                </template>
              </q-select>
            </div>
          </div>
        </q-card-section>

        <q-card-section v-if="etapa == 2">
          <div class="row q-col-gutter-sm">
            <div class="col-12">
              <q-input
                v-model="newCobranca.codigoBanco"
                label="Código do Banco"
                bottom-slots
                maxlength="3"
                :error="errors.BankData_Bank_Code ? true : false"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.BankData_Bank_Code" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-md-6 col-12">
              <q-input
                v-model="newCobranca.conta.agencia"
                label="Agência"
                bottom-slots
                maxlength="4"
                :error="errors.BankData_BankAgency ? true : false"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.BankData_BankAgency" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-md-6 col-12">
              <q-input
                v-model="newCobranca.digitoAgencia"
                label="Digito da Agência"
                bottom-slots
                maxlength="1"
                :error="errors.BankData_BankAgencyDigit ? true : false"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.BankData_BankAgencyDigit" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-md-6 col-12">
              <q-input
                v-model="newCobranca.conta.conta"
                label="Conta"
                bottom-slots
                maxlength="50"
                :error="errors.BankData_BankAccount ? true : false"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.BankData_BankAccount" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-md-6 col-12">
              <q-input
                v-model="newCobranca.digitoConta"
                label="Digito da Conta"
                bottom-slots
                maxlength="1"
                :error="errors.BankData_BankAccountDigit ? true : false"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.BankData_BankAccountDigit" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>

            <p class="text-h6">Responsável</p>

            <div class="col-12">
              <q-input
                v-model="newCobranca.nome"
                :error="errors.ResponsibleName ? true : false"
                label="Nome"
                bottom-slots
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.ResponsibleName" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-12">
              <q-input
                v-model="newCobranca.cpf"
                label="CPF"
                bottom-slots
                mask="###.###.###-##"
                :error="errors.ResponsibleIdentity ? true : false"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.ResponsibleIdentity" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-12">
              <q-input
                v-model="newCobranca.email"
                :error="errors.Email ? true : false"
                label="E-mail"
                bottom-slots
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.Email" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>
          </div>
        </q-card-section>

        <q-card-section>
          <div class="flex">
            <q-btn
              class="q-mr-auto q-p-md"
              v-if="etapa == 2"
              @click="backEtapa"
              flat
              clickable
              dense
              icon="chevron_left"
              label="Voltar"
            />

            <q-btn
              class="q-ml-auto bg-positive text-white"
              label="Salvar"
              :loading="saving"
              @click="salvar"
            />
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify } from "quasar";

export default {
  name: "modalNovaFormaCobranca",
  props: {
    openDialogNovaFormaCobranca: {
      type: Boolean,
      default: false
    }
  },
  emits: ["closeModal"],
  data() {
    return {
      saving: false,
      titulo: "Nova Forma de Cobrança",
      fornecedor: "",
      etapa: 1,
      gatewaysHomologados: [],
      gatewaysSelecionados: [],
      bancosHomologados: [],
      contas: [],
      errors: [],
      newCobranca: {
        conta: "",
        codigoBanco: "",
        digitoAgencia: "",
        digitoConta: "",
        nome: "",
        cpf: "",
        email: "",
      }
    };
  },
  methods: {
    salvar() {
      if (this.saving) return;
      this.errors = [];

      if (this.fornecedor === "") {
        this.errors.fornecedor = ["O campo Fornecedor é obrigatório"];
        return;
      }

      if (this.gatewaysSelecionados.length === 0) {
        this.errors.tipos = ["Selecione ao menos uma forma de pagamento"];
        return;
      }

      if (!this.newCobranca.conta) {
        this.errors.conta_id = ["O campo Conta é obrigatório"];
        return;
      }

      if (this.fornecedor === "Gateway 1" && this.etapa == 1) {
        this.titulo = "Dados da Conta Bancária";
        this.etapa++;
        return;
      }

      const dados = {
        tipos: this.gatewaysSelecionados,
        fornecedor: this.fornecedor,
        conta_id: this.newCobranca.conta.id,
      };

      if (this.fornecedor === "Gateway 1") {
        dados.BankData_BankAgency = this.newCobranca.conta.agencia;
        dados.BankData_BankAgencyDigit = this.newCobranca.digitoAgencia || "0";
        dados.BankData_BankAccount = this.newCobranca.conta.conta;
        dados.BankData_BankAccountDigit = this.newCobranca.digitoConta;
        dados.BankData_Bank_Code = this.newCobranca.codigoBanco;
        dados.ResponsibleName = this.newCobranca.nome;
        dados.ResponsibleIdentity = "";
        dados.Email = this.newCobranca.email;

        if (this.newCobranca.cpf !== "") {
          dados.ResponsibleIdentity = this.newCobranca.cpf.match(/\d/g).join("");
        }
      }

      this.saving = true;
      axios
        .post("/gateways/add?cobranca=1", dados)
        .then(res => {
          this.saving = false;

          if (!res.data.success) {
            this.errors = res.data.errors;

            Notify.create({
              message: res.data.message,
              color: "negative",
              position: "top-right"
            });
            return;
          }

          this.$store.dispatch("formasRecebimento", { tipo: "receita" });
          this.$emit("closeModal");

          Notify.create({
            message: res.data.message,
            color: "positive",
            position: "top-right"
          });
        });
    },
    getData() {
      axios
        .get("/gateways?forma_recebimento=1")
        .then(res => {
          this.gatewaysHomologados = res.data.homologados;
          this.bancosHomologados = res.data.homologadosBancos;
        });
    },
    getContas() {
      axios
        .get("/contas")
        .then(res => {
          this.contas = res.data.contas;
        });
    },
    backEtapa() {
      this.titulo = "Nova Forma de Cobrança";
      this.etapa--;
    },
    closeModal() {
      if (this.saving) return;
      this.$emit("closeModal");
    }
  },
  computed: {
    gatewaysFiltrados() {
      let gatewayIds = [];

      this.bancosHomologados.filter(banco => {
        if (banco.nome === this.fornecedor) {
          gatewayIds = banco.tipo;
        }
      });

      const gatewaysHomologados = Object.keys(this.gatewaysHomologados)
        .filter(key => gatewayIds.includes(+key))
        .reduce((obj, key) => {
          obj[key] = {
            "label": this.gatewaysHomologados[key],
            "value": key,
          };

          return obj;
        }, {});

      const gatewaysFiltrados = Object.keys(gatewaysHomologados).map(
        key => gatewaysHomologados[key]
      );

      return gatewaysFiltrados;
    },
    bancos() {
      const bancos = Object.keys(this.bancosHomologados).map(key => {
        return this.bancosHomologados[key].nome;
      });

      return bancos;
    },
  },
  watch: {
    fornecedor() {
      this.gatewaysSelecionados = [];
    }
  },
  mounted() {
    this.getData();
    this.getContas();
  }
};
</script>
