<template>
  <div id="modalCobrancaDiaria">
    <q-dialog v-model="OpenModalCobrancaDiaria" persistent>
      <q-card style="max-width: 400px !important">
        <q-card-section class="row items-center">
          <div
            class="text-h6"
          >{{tipo === 'loterias' ? 'Cobrança diária de Loterias' : 'Cobrança diária de Serviços'}}</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="$store.commit('OpenModalCobrancaDiaria', false)"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-input
                  bottom-slots
                  v-model="dtini"
                  label="Vencimento"
                  @click="$refs.qDtiniProxy.show();"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDtiniProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="dtini"
                          @input="() => $refs.qDtiniProxy.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  bottom-slots
                  v-model="dtfim"
                  label="Vencimento"
                  @click="$refs.qDtfimProxy.show();"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDtfimProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="dtfim"
                          @input="() => $refs.qDtfimProxy.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Visualizar"
            clickable
            @click="openRelatorio(tipo)"
            :loading="resumoCobrancaPrinting"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const moment = require("moment");
export default {
  name: "modal-cobranca-diaria",
  props: {
    tipo: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      dtini: moment().format("DD/MM/YYYY"),
      dtfim: moment().format("DD/MM/YYYY")
    };
  },
  methods: {
    openRelatorio(value) {
      let dtini = JSON.parse(JSON.stringify(this.dtini)).replace(/\//g, "-");
      let dtfim = JSON.parse(JSON.stringify(this.dtfim)).replace(/\//g, "-");
      this.$store.dispatch("imprimirRelatorioCobranca", {
        dtini: dtini,
        dtfim: dtfim,
        tipo: value
      });
    }
  },
  computed: {
    ...mapGetters(["resumoCobrancaPrinting"]),
    OpenModalCobrancaDiaria: {
      get() {
        return this.$store.getters.OpenModalCobrancaDiaria;
      },
      set() {}
    }
  }
};
</script>
