<template>
  <q-page id="cliente-dourasoft" class="row items-strech">

    <iframe
      v-if="urlAreaCliente"
      :src="urlAreaCliente"
      frameborder="0"
    ></iframe>

  </q-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "cliente_dourasoft",
  meta: {
    title: "DSB | Área do Cliente Dourasoft",
  },
  computed: {
    ...mapGetters(["urlAreaCliente" ]),
  },
};
</script>

<style lang="stylus">
iframe {
  width: 100%;
}
</style>
