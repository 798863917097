<template>
  <div id="adicionar-card-dashboard">
    <q-dialog v-model="openDialogAdicionarCard" persistent>
      <q-card style="max-width: 450px; width: 80%;">
        <q-card-section class="row items-center">
          <div class="text-h6">
            Adicionar Cartão
          </div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <q-form class="row q-col-gutter-x-md" @submit="salvarLayout">
             <div class="col-12">
              <q-select
                v-model="card"
                :options="cardOptions"
                option-label="titulo"
                option-value="i"
                label="Cartão">
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Sem resultados
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
             </div>
            </q-form>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            label="Adicionar"
            class="bg-positive"
            color="primary"
            @click="salvarLayout"
            :loading="loadingSave"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";

export default {
  name: "adicionar-card-dashboard",
  data() {
    return {
      loadingSave: false,
      card: null,
    };
  },
  methods: {
    closeModal() {
      this.card = null;
      this.$store.dispatch("openDialogAdicionarCard", false);
    },
    salvarLayout() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: this.card.i,
        reload: true,
        callback: () => {
          this.closeModal();
        },
      });
    },
  },
  computed: {
    ...mapGetters([
      "openDialogAdicionarCard",
      "fetchUser",
      "layout",
    ]),
    cardOptions() {
      if (!this.layout) return [];

      return this.layout.filter(item => item.visivel === false || item.visivel === "false");
    },
  },
};
</script>

<style lang="stylus" scoped></style>
