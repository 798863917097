<template>
  <div>
    <div>
      <q-toggle label="Visualizar planos de contas" v-model="visualizarConfiguracoesCac" />

      <div class="q-my-md" v-if="visualizarConfiguracoesCac">
        <ul>
          <li v-for="planodecontas in tabela.configuracoes_cac" :key="planodecontas.id">
            <span v-if="planodecontas.centrocustos.length === 0"
              >{{ planodecontas.titulo }}
              {{ parseFloat(planodecontas.porcentagem) | currency }}%</span
            >

            <span v-if="planodecontas.centrocustos.length > 0">
              {{ planodecontas.titulo }}, Centros de custo:
              {{ planodecontas.centrocustos.map(centrocusto => centrocusto.nome).join(', ') }}
            </span>
          </li>
        </ul>
      </div>
    </div>

    <q-table
      flat
      dense
      row-key="id"
      :data="tabela.data"
      :columns="columns"
      :title="tabela.title"
      :pagination="pagination"
    >
      <template v-slot:top>
        <div class="q-table__title">{{ tabela.title }}</div>

        <q-space />

        <q-btn
          flat
          class="q-mr-md"
          color="primary"
          label="Atualizar CAC"
          @click="atualizarCac()"
          :loading="loadingAtualizarCac"
          v-show="tabela.metricas_saas && tabela.metricas_saas.id"
        />

        <button-metricas-saas-imprimir-detalhamento :campo="campo" :referencia="referencia" />
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" class="text-right">{{ props.row.id }}</q-td>

          <q-td key="cadastro_nome" class="text-left">
            {{ props.row.cadastro_nome | truncate(30) }}
            <q-tooltip v-if="props.row.cadastro_nome && props.row.cadastro_nome.length > 30">
              {{ props.row.cadastro_nome }}
            </q-tooltip>

            <br v-if="props.row.cadastro_cpfcnpj" />
            {{ props.row.cadastro_cpfcnpj }}
          </q-td>

          <q-td key="descricao" class="text-left">
            {{ props.row.descricao | truncate(40) }}
            <q-tooltip v-if="props.row.descricao && props.row.descricao.length > 40">
              {{ props.row.descricao }}
            </q-tooltip>
          </q-td>

          <q-td key="planodecontas_titulo" class="text-left">
            {{ props.row.planodecontas_titulo | truncate(40) }}
            <q-tooltip
              v-if="props.row.planodecontas_titulo && props.row.planodecontas_titulo.length > 40"
            >
              {{ props.row.planodecontas_titulo }}
            </q-tooltip>
          </q-td>

          <q-td key="centrocustos" class="text-left">
            {{ props.row.centrocustos | truncate(35) }}
            <q-tooltip v-if="props.row.centrocustos && props.row.centrocustos.length > 35">
              {{ props.row.centrocustos }}
            </q-tooltip>
          </q-td>

          <q-td key="valor" class="text-right">{{ props.row.valor | currency }}</q-td>
        </q-tr>
      </template>

      <template v-slot:bottom-row>
        <tr class="text-weight-bold">
          <td colspan="2">Total</td>
          <td class="text-right" colspan="4">
            <span v-if="growthQuantidadeClientes > 0">
              {{ totalValor | currency }} / {{ growthQuantidadeClientes }} novos clientes =
            </span>
            {{ totalValorCac | currency }}
          </td>
        </tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import axios from '@/axios-auth';
import { bus } from '@/main';

export default {
  props: ['tabela', 'campo', 'referencia', 'atualizarModal'],
  name: 'tabela-metricas-saas-cac',
  data() {
    return {
      pagination: {
        sortBy: 'cadastro_nome',
        descending: false,
        page: 1,
        rowsPerPage: 20,
      },
      lastIndex: 0,
      columns: [
        {
          name: 'id',
          label: 'ID',
          field: 'id',
          sortable: true,
          align: 'center',
        },
        {
          name: 'cadastro_nome',
          label: 'Fornecedor',
          field: 'cadastro_nome',
          sortable: true,
          align: 'center',
        },
        {
          name: 'descricao',
          label: 'Descrição',
          field: 'descricao',
          sortable: true,
          align: 'center',
        },
        {
          name: 'planodecontas_titulo',
          label: 'Plano de contas',
          field: 'planodecontas_titulo',
          sortable: true,
          align: 'center',
        },
        {
          name: 'centrocustos',
          label: 'Centros de custo',
          field: 'centrocustos',
          sortable: true,
          align: 'center',
        },
        {
          name: 'valor',
          label: 'Valor',
          field: 'valor',
          sortable: true,
          align: 'center',
        },
      ],
      visualizarConfiguracoesCac: false,
      loadingAtualizarCac: false,
    };
  },
  methods: {
    getPeriodoDescricao(intervalo) {
      intervalo = parseInt(intervalo);

      switch (intervalo) {
        case 1:
          return 'Mensal';
        case 2:
          return 'Bimestral';
        case 3:
          return 'Trimestral';
        case 6:
          return 'Semestral';
        case 12:
          return 'Anual';
        default:
          return intervalo + ' meses';
      }
    },
    atualizarCac() {
      this.$q
        .dialog({
          title: 'Atualizar CAC',
          message: 'As métricas de CAC e LTV/CAC serão recalculadas',
          options: {
            type: 'checkbox',
            model: [],
            items: [
              {
                label:
                  'Utilizar configurações atuais (as configurações antigas serão sobreescritas)',
                value: 'utilizar_configuracoes_atuais',
                color: 'positive',
              },
            ],
          },
          cancel: true,
          persistent: true,
        })
        .onOk(value => {
          this.loadingAtualizarCac = true;

          const data = {
            utilizar_configuracoes_atuais: value.includes('utilizar_configuracoes_atuais'),
          };

          axios
            .post(`/metricas-saas/atualizar-cac/${this.tabela.metricas_saas.id}`, data)
            .then(res => {
              this.loadingAtualizarCac = false;

              if (res.error && res.error.response.status === 403) {
                this.$q.notify({
                  message: res.error.response.data.message,
                  color: 'red',
                  position: 'top-right',
                });
                return;
              }

              this.$q.notify({
                message: res.data.message,
                color: res.data.success ? 'green' : 'orange',
                position: 'top-right',
              });

              if (!res.data.success) return;

              this.$emit('atualizarModal');
              bus.$emit('loadMetricasSaas');
            })
            .catch(err => {
              this.loadingAtualizarCac = false;
              this.$q.notify({
                message: 'Houve um erro ao atualizar o CAC',
                color: 'red',
                position: 'top-right',
              });
            });
        });
    },
  },
  computed: {
    totalValor() {
      return this.tabela.data.reduce((total, item) => total + parseFloat(item.valor), 0);
    },
    growthQuantidadeClientes() {
      return parseInt(this.tabela.growth_quantidade_clientes);
    },
    totalValorCac() {
      return this.totalValor / (this.growthQuantidadeClientes || 1);
    },
  },
  mounted() {
    this.lastIndex = 0;
  },
};
</script>

<style>
ul {
  list-style: none;
  padding-left: 16px;
}
</style>
