<template>
  <div id="modalAlterarFormaPagamento">
    <q-dialog v-model="OpenDialogAlterarFormaPagamento" persistent>
      <q-card style="width: 600px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Alterar Forma de Pagamento - {{movconta.id}}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="cancela()" />
        </q-card-section>
        <q-separator />

        <q-card-section class="q-pb-none">
          <q-list>
            <q-item class="q-pl-none q-pr-md">
              <q-item-section >
                <q-item-label>
                  <p>Vencimento: <b>{{new Date(movconta.vencimento) | formatDate}}</b></p>
                  <p>Valor: <b>R$ {{movconta.valor | currency}}</b></p>
                </q-item-label>
                <q-item-label v-if="errorsReceitas.cadastro_id" class="text-red animated shake">
                  <span v-for="(error, i) in errorsReceitas.cadastro_id" :key="error + i">{{error}}</span>
                </q-item-label>
                <q-item-label v-if="errorsReceitas.others" class="text-red animated shake">
                  <span v-for="(error, i) in errorsReceitas.others" :key="error + i">{{error}}</span>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-section class="q-pt-none q-pb-none">
          <div class="row">
            <div class="col-12">
              <q-select
                clearable
                v-model="movcontaa.gateway_id"
                option-label="titulo"
                option-value="id"
                :options="findGateways.gateways"
                emit-value
                map-options
                bottom-slots
                :error="errorsReceitas.gateway_id ? true : false"
                label="Nova Forma de Pagamento"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem Resultados</q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsReceitas.gateway_id" :key="error + i">{{error}}</span>
                </template>
              </q-select>
            </div>

            <div class="col-12 q-pb-md">
              <q-checkbox v-model="alterarVencimentoValor" label="Alterar vencimento e valor" color="green" />
            </div>

            <div class="col-12" v-if="alterarVencimentoValor">
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-input
                    bottom-slots
                    :error="errorsReceitas.vencimento ? true : false"
                    v-model="movcontaa.vencimento"
                    label="Novo Vencimento"
                    @focus="$refs.qDateProxyVencimento.show();"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer" @click="$refs.qDateProxyVencimento.show();"></q-icon>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errorsReceitas.vencimento" :key="error + i">{{error}}</span>
                    </template>
                  </q-input>
                  <q-popup-proxy
                    ref="qDateProxyVencimento"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="movcontaa.vencimento"
                      @input="() => {changeVencimento(); $refs.qDateProxyVencimento.hide();}"
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      minimal
                      today-btn
                    />
                  </q-popup-proxy>
                </div>
                <div class="col-6">
                  <q-field
                    v-model="movcontaa.valor"
                    label="Novo Valor"
                    bottom-slots
                    :error="errorsReceitas.valor ? true : false"
                  >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                      />
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errorsReceitas.valor" :key="error + i">{{error}}</span>
                    </template>
                  </q-field>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <p>Multa: <b>R$ {{movconta.valor | currency}} * {{valorMulta}}% = R$ {{(movconta.valor * (valorMulta/100)) | currency}}</b></p>
                  <p>Juros Mensal: <b>( R$ {{movconta.valor | currency}} * ( {{valorJuros}}% / 30 dias ) ) * {{quantidadeDias}} dias = R$ {{((movconta.valor * ((valorJuros/100)/30)) * quantidadeDias) | currency}}</b></p>
                  <p>Dias de atraso: <b>{{quantidadeDias}}</b></p>
                </div>
              </div>
            </div>

            <div class="col-12 q-pb-md">
              <q-checkbox v-if="alterarVencimentoValor && movconta.gateway_id"
                color="green"
                label="Personalizar multa e juros"
                v-model="personalizarMultaJuros"
              />
            </div>

            <div class="col-12" v-if="personalizarMultaJuros && (movconta.gateway_id || movcontaa.gateway_id)">
              <div class="row q-col-gutter-lg">
                <div class="col-6">
                  <q-field
                    v-model="fatura.multa"
                    label="Multa"
                    bottom-slots
                    :error="Boolean(errorsReceitas.fatura_multa)"
                    @change="calcularNovoValor"
                  >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="percentFormat"
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsReceitas.fatura_multa"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>

                <div class="col-6">
                  <q-field
                    v-model="fatura.juros"
                    label="Juros ao mês"
                    bottom-slots
                    :error="Boolean(errorsReceitas.fatura_juros)"
                    @change="calcularNovoValor"
                  >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="percentFormat"
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsReceitas.fatura_juros"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>
              </div>
            </div>

            <div class="col-12 q-pb-md">
              <q-checkbox v-if="alterarVencimentoValor && movconta.gateway_id"
                color="green"
                label="Protestar"
                v-model="protestar"
              >
              <q-btn icon="help_outline" flat dense round>
                <q-tooltip>Disponível para o Gateway da Caixa Econômica</q-tooltip>
              </q-btn>
              </q-checkbox>
            </div>

            <div class="col-12" v-if="protestar">
                <q-input
                  v-model="fatura.protesto"
                  label="Protestar x dias após o vencimento"
                  mask="##"
                  bottom-slots
                  :error="Boolean(errorsReceitas.fatura_protesto)"
                  >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsReceitas.fatura_protesto"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
            </div>

            <div v-if="alterarVencimentoValor" class="col-12 q-mb-md">
              <q-checkbox v-model="alterarDescontoPagamentoAntecipado" label="Aplicar desconto para pagamento antecipado" color="green" />
                <q-btn icon="help_outline" flat dense round>
                <q-tooltip>Disponível para o Gateway 1</q-tooltip>
              </q-btn>
            </div>

            <div class="col-12" v-if="alterarDescontoPagamentoAntecipado">
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-input
                    bottom-slots
                    :error="errorsReceitas.data_limite_desconto ? true : false"
                    v-model="movcontaa.data_limite_desconto"
                    label="Data Limite Desconto"
                    @focus="$refs.qDateProxyDesconto.show();"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer" @click="$refs.qDateProxyDesconto.show();"></q-icon>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errorsReceitas.data_limite_desconto" :key="error + i">{{error}}</span>
                    </template>
                  </q-input>
                  <q-popup-proxy
                    ref="qDateProxyDesconto"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="movcontaa.data_limite_desconto"
                      @input="() => {$refs.qDateProxyDesconto.hide();}"
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      minimal
                      today-btn
                    />
                  </q-popup-proxy>
                </div>
                <div class="col-6">
                  <q-field
                    v-model="movcontaa.valor_desconto"
                    label="Novo Valor de Desconto"
                    bottom-slots
                    :error="errorsReceitas.valor_desconto ? true : false"
                  >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                      />
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errorsReceitas.valor_desconto" :key="error + i">{{error}}</span>
                    </template>
                  </q-field>
                </div>
              </div>
            </div>

          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveNewGateway"
            :loading="loadGateway"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Money } from 'v-money';
import moment from 'moment';
export default {
  name: "alterar-forma-pagamento",
  components: {Money},
  data() {
    return {
      alterarVencimentoValor: false,
      alterarDescontoPagamentoAntecipado: false,
      protestar:false,
      quantidadeDias: 0,
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      personalizarMultaJuros: false,
      fatura: {
        protesto: 0,
        multa: 0.00,
        juros: 0.00,
      },
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false,
      },
    };
  },
  props: {visibleName: Boolean} ,
  methods: {
    inicializar(){
      this.alterarVencimentoValor = false;
      this.alterarDescontoPagamentoAntecipado = false;
      this.personalizarMultaJuros = false;
      this.protestar = false;
      this.fatura = {
        protesto: 0,
        multa: 0.00,
        juros: 0.00,
      };
    },
    saveNewGateway() {
      let movconta = JSON.parse(JSON.stringify(this.movcontaa));

      movconta.avl = this.alterarVencimentoValor;

      if (this.personalizarMultaJuros) {
        movconta.personalizar_multa_juros = true;
        movconta.fatura_multa = this.fatura.multa;
        movconta.fatura_juros = this.fatura.juros;
        movconta.fatura_protesto = this.fatura.protesto;
      }
      movconta.successCallback = () => {
        this.personalizarMultaJuros = false;
        this.protestar = false;
        this.fatura = {
          protesto: 0,
          multa: 0.00,
          juros: 0.00,
        };
      };

      this.$store.dispatch("saveNewGateway", movconta);
    },
    cancela() {
      this.$store.commit("OpenDialogAlterarFormaPagamento", false);
      this.$store.commit("errorsReceitas", {
        errorsReceitas: []
      });
      this.personalizarMultaJuros = false;
      this.protestar = false;
      this.fatura = {
        protesto: 0,
        multa: 0.00,
        juros: 0.00,
      };
    },
    changeVencimento() {
      const novoVencimento = moment(this.movcontaa.vencimento, 'DD/MM/YYYY');
      if (!novoVencimento.isValid()) return;

      this.quantidadeDias = novoVencimento.diff(moment(this.movconta.vencimento), "days");
      this.calcularNovoValor();
    },
    calcularNovoValor() {
      let valor = this.movconta.valor;

      if (this.valorMulta && this.quantidadeDias > 0) {
        valor += valor * this.valorMulta / 100;
      }

      if (this.valorJuros && this.quantidadeDias > 0){
        valor += this.movconta.valor * ((this.valorJuros / 100) / 30) * this.quantidadeDias;
      }

      this.movcontaa.valor = valor;
    },
  },
  watch: {
    'fatura.juros' : function(val, oldVal){
      if (val){
        this.calcularNovoValor();
      }
    },
    'fatura.multa' : function(val, oldVal){
      if (val){
        this.calcularNovoValor();
      }
    },
  },
  computed: {
    ...mapGetters([
      "loadGateway",
      "errorsReceitas",
      "movcontaa",
      "movconta",
      "findGateways",
    ]),
    OpenDialogAlterarFormaPagamento: {
      get() {
        if (this.visibleName){
          return this.visibleName;
        }
        return this.$store.getters.OpenDialogAlterarFormaPagamento;
      },
      set() {}
    },
    gatewaySelecionado() {
      if (!this.findGateways || !this.findGateways.gateways || !this.movconta) return null;

      const gateway = this.findGateways.gateways.find(
        gateway => gateway.id == this.movconta.gateway_id
      );

      return gateway;
    },
    valorMulta: {
      get() {
        let multa = 0.00;

        if (this.personalizarMultaJuros){
          multa = this.fatura.multa;
          return Number(multa) || 0;
        }

        if (!this.gatewaySelecionado || !this.gatewaySelecionado.gateways_opts) return 0;

        multa = this.gatewaySelecionado.gateways_opts.find(gatewayOpt => gatewayOpt.nome === "multa");

        if (multa && multa.valor){
          return Number(multa.valor);
        }

        return 0;
      },
      set(value) {},
    },
    valorJuros: {
      get() {

        let juros = 0.00;
        if (this.personalizarMultaJuros){
          juros = this.fatura.juros;
          return Number(juros) || 0;
        }

        if (!this.gatewaySelecionado || !this.gatewaySelecionado.gateways_opts) return 0;

        juros = this.gatewaySelecionado.gateways_opts.find(gatewayOpt => gatewayOpt.nome === "juros");

        if (juros && juros.valor){
          return Number(juros.valor);
        }

        return 0;
      },
      set(value) {},
    },
  }
};
</script>

<style lang="stylus" scoped>
p {
  margin-bottom: 0;
}

</style>
