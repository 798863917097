<template>
  <q-dialog v-model="openDialog" persistent>
    <q-card class="scroll" style="width: 90%; max-width: 400px">
      <q-card-section class="row items-center no-wrap">
        <div class="text-h6">Congelar recorrências em massa</div>
        <q-space />
        <q-btn icon="close" clickable flat round dense v-close-popup @click="fecharModal()" />
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pa-md">
        <div class="row q-col-gutter-x-md">
          <div class="col-12">
            <q-input
              clearable
              bottom-slots
              label="Fim do congelamento"
              :error="Boolean(erros.congelado_dtfim)"
              v-model="congeladoDtfim"
              @click="$refs.qDateCongelamentoDtfim.show()"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateCongelamentoDtfim"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      minimal
                      today-btn
                      mask="DD/MM/YYYY"
                      v-model="congeladoDtfim"
                      @input="() => $refs.qDateCongelamentoDtfim.hide()"
                      :locale="langDate"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in erros.congelado_dtfim" :key="erro + i">
                  {{ erro }}
                </span>
              </template>
            </q-input>
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          no-caps
          v-ripple
          clickable
          label="Salvar"
          color="positive"
          text-color="white"
          style="margin-right: 22px; margin-bottom: 10px"
          :loading="salvando"
          @click="congelar"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { Loading } from 'quasar';

import axios from '@/axios-auth';
import { bus } from '@/main';

export default {
  name: 'modal-congelar-recorrencias-massa',
  data() {
    return {
      // Modal
      openDialog: false,
      // Formulário
      congeladoDtfim: '',
      erros: {},
      // Salvar
      salvando: false,
      // Informações de recorrências selecionadas
      ids: [],
      filtros: {},
      selecionarTodas: false,
    };
  },
  methods: {
    // Modal
    abrirModal({ ids, filtros, selecionarTodas }) {
      this.ids = JSON.parse(JSON.stringify(ids));
      this.filtros = JSON.parse(JSON.stringify(filtros));
      this.selecionarTodas = selecionarTodas;

      this.congeladoDtfim = '';
      this.resetarErros();

      this.openDialog = true;
    },
    fecharModal() {
      this.openDialog = false;
    },
    // Formulário
    resetarErros() {
      this.erros = {};
    },
    // Salvar
    congelar() {
      const data = {
        acao: 'congelar',
        ids: this.ids,
        all: this.selecionarTodas,
        congelado_dtfim: this.congeladoDtfim,
      };

      this.salvando = true;

      axios
        .post('/recorrencias/edit-multiple', data, { params: this.filtros })
        .then(res => {
          this.salvando = false;

          if (res.error && res.error.response.status === 403) {
            this.notificarSemPermissao(res.error.response.data.message);
            return;
          }

          if (!res.data.success) {
            this.erros = res.data.errors;
            this.$q.dialog({
              title: res.data.message,
              class: 'bg-warning text-white',
              ok: {
                'text-color': 'orange',
                color: 'white',
              },
            });
            return;
          }

          this.$q
            .dialog({
              title: 'Deseja realmente congelar as recorrências?',
              message: res.data.message,
              cancel: 'Cancelar',
              persistent: true,
            })
            .onOk(() => {
              this.salvando = true;

              data.confirmar = true;

              axios
                .post('/recorrencias/edit-multiple', data, { params: this.filtros })
                .then(res => {
                  this.salvando = false;

                  if (res.error && res.error.response.status === 403) {
                    this.notificarSemPermissao(res.error.response.data.message);
                    return;
                  }

                  this.$q.dialog({
                    title: res.data.message,
                    class: `bg-${res.data.success ? 'positive' : 'warning'} text-white`,
                    ok: {
                      'text-color': res.data.success ? 'green' : 'orange',
                      color: 'white',
                    },
                  });

                  if (!res.data.success) {
                    this.erros = res.data.errors;
                    return;
                  }

                  this.openDialog = false;
                  this.$emit('atualizar');
                });
            });
        })
        .catch(() => {
          this.salvando = false;

          this.$q.dialog({
            title: 'Não foi possível realizar a ação, ou você não possui permissão isto!',
            class: 'bg-negative text-white',
            ok: {
              'text-color': 'red',
              color: 'white',
            },
          });
        });
    },
    // Utilidade
    notificarSemPermissao(message) {
      this.$q.notify({
        message: message,
        color: 'red',
        position: 'top-right',
      });
    },
  },
  mounted() {
    bus.$on('abrirModalCongelarRecorrenciasMassa', this.abrirModal);
  },
};
</script>
