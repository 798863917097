import { render, staticRenderFns } from "./modal-caixa-reforco.vue?vue&type=template&id=5fa2a280&scoped=true&"
import script from "./modal-caixa-reforco.vue?vue&type=script&lang=js&"
export * from "./modal-caixa-reforco.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa2a280",
  null
  
)

export default component.exports