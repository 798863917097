import { render, staticRenderFns } from "./card-estoque-loterica.vue?vue&type=template&id=c6357bea&scoped=true&"
import script from "./card-estoque-loterica.vue?vue&type=script&lang=js&"
export * from "./card-estoque-loterica.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6357bea",
  null
  
)

export default component.exports