<template>
  <div id="modalNovaRegua">
    <q-dialog v-model="OpenDialogNovaRegua" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">{{valueQuando.isNew ? 'Nova ' : 'Editar '}} Régua de Mensagens</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-select
                  v-model="tipoReguaSelecionado"
                  :options="tiposRegua"
                  label="Regua de"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <q-radio v-model="quandoEnvia" val="antes" label="Enviar Antes" :disable="disableQuandoEnvia">
                  <template v-slot:default >
                    <q-icon
                      style="margin-left:10px"
                      color="grey"
                      size='23px'
                      name="info"
                      class="cursor-pointer"
                    >
                      <q-tooltip v-if="tipoReguaSelecionado == 'Email'" max-width='400px' :content-style="{fontSize:'15px'}">{{textos.enviaEmailAntes}}</q-tooltip>
                      <q-tooltip v-if="tipoReguaSelecionado == 'WhatsApp'" max-width='400px' :content-style="{fontSize:'15px'}">{{textos.enviaAntes}}</q-tooltip>
                    </q-icon>
                  </template>
                </q-radio>
                <q-radio v-model="quandoEnvia" val="depois" label="Enviar Depois">
                  <template v-slot:default >
                    <q-icon
                      style="margin-left:10px"
                      color="grey"
                      size='23px'
                      name="info"
                      class="cursor-pointer"
                    >
                      <q-tooltip v-if="tipoReguaSelecionado == 'Email'" max-width='400px' :content-style="{fontSize:'15px'}">{{textos.enviaEmailDepois}}</q-tooltip>
                      <q-tooltip v-if="tipoReguaSelecionado == 'WhatsApp'" max-width='400px' :content-style="{fontSize:'15px'}">{{textos.enviaDepois}}</q-tooltip>
                    </q-icon>
                  </template>
                </q-radio>
              </div>

              <div class="col-12 col-md-6">
                <q-input
                  v-model="quando"
                  label="Digite Quanto"
                  bottom-slots
                  :error="errors.quando ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.quando" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div v-if="tipoReguaSelecionado == 'Email' || origemIsBoleto " class="col-12 col-md-6">
                <q-select
                  v-model="unidadeDias"
                  label="Unidade"
                  bottom-slots
                  disable
                >
                </q-select>
              </div>
              <div v-if="tipoReguaSelecionado == 'WhatsApp' && !origemIsBoleto " class="col-12 col-md-6">
                <q-select
                  v-model="unidadesTempoSelecionado"
                  :options="unidadesTempo"
                  label="Unidade"
                  bottom-slots
                  option-label="descricao"
                  option-value="value"
                >
                </q-select>
              </div>

              <div v-if="tipoReguaSelecionado == 'Email'" class="col-12">
                <q-select
                  v-model="origemEmailSelecionado"
                  :options="origemEmail"
                  label="De Onde"
                  bottom-slots
                  :error="errors.origem ? true : false"
                  option-label="descricao"
                  option-value="tipo"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div v-if="tipoReguaSelecionado == 'WhatsApp'" class="col-12 col-md-12">
                <div class="col-12 col-md-12">
                  <q-select
                    clearable
                    v-model="origemWppSelecionado"
                    :options="origemWpp"
                    label="De Onde"
                    bottom-slots
                    :error="errors.origem ? true : false"
                    option-label="descricao"
                    option-value="tipo"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
              </div>

              <div class="col-12 col-md-12">
                <q-select
                  clearable
                  v-model="templateSelecionado"
                  :options="optionsTemplates"
                  :label="tipoReguaSelecionado == 'Email'? 'Template de E-mail': 'Template de WhatsApp'"
                  bottom-slots
                  :error="errors.template_id ? true : false"
                  :option-label="value => value === null ? 'Item Vazio' : value.subject"
                  :option-value="value => value === null ? null : value"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.template_id" :key="error + i">{{error}}</span>
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12 col-md-12">
                <q-input v-model="reguaNameWrite" label="Nome Regua">
                  <template v-slot:append >
                    <q-icon
                      style="margin-left:10px"
                      color="grey"
                      size='23px'
                      name="info"
                      class="cursor-pointer"
                    >
                      <q-tooltip max-width='400px' :content-style="{fontSize:'15px'}">{{textos.nomeRegua}}</q-tooltip>
                    </q-icon>
                  </template>
                </q-input>
              </div>

              <div class="col-12">
                <q-input
                  label="Horário de envio"
                  v-model="reguaHorario"
                  mask="time"
                  :rules="['time']"
                  @click="() => $refs.qHorarioRegua.show()"
                >
                  <template v-slot:append>
                    <q-icon name="access_time" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qHorarioRegua"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-time
                          v-model="reguaHorario"
                          @input="() => $refs.qHorarioRegua.hide()"
                        >
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                          </div>
                        </q-time>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>

              <div class="col-12" v-if="tipoReguaSelecionado == 'WhatsApp'">
                <q-select
                    v-model="tagsSelecionadas"
                    multiple
                    use-chips
                    clearable
                    label="Tags"
                    bottom-slots
                    option-label="title"
                    option-value="id"
                    :options="allTags"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
              </div>

              <div class="col-12 col-md-12">
                <br />
                <br />
                <br />
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  :loading="salvando"
                  @click="salvar()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify } from "quasar";
export default {
  name: "modal-nova-regua",
  data() {
    return {
      optionsTemplates: null,
      salvando: false,
      reguaNameWrite: "",
      enviaAntes: true,
      unidadeDias: "Dias",
      quandoEnvia: 'antes',
      quando: 0,
      descricao: "",
      tipoReguaSelecionado: 'Email',
      origemWppSelecionado: null,
      origemEmailSelecionado: { descricao: "Cobranças", tipo: 'boleto' },
      unidadesTempoSelecionado: { descricao: "Dias", value: "dias"},
      errors: [],
      tagsSelecionadas: [],
      templateSelecionado: null,
      textos:{
        enviaAntes:'As mensagens serão enviadas antes da data do evento.',
        enviaDepois:'As mensagens serão enviadas depois da data do evento.',
        enviaEmailAntes:'Os e-mails serão enviadas antes da data de vencimento.',
        enviaEmailDepois:'Os e-mails serão enviadas depois da data de vencimento.',
        nomeRegua:'Se um nome não for escrito, será criado um baseado nas configurações desta regua.'
      },
      reguaHorario: '00:00',
    };
  },
  methods: {
    closeModal() {
      this.errors = [];
      this.templateSelecionado = null;
      this.tipoReguaSelecionado = "Email";
      this.unidadesTempoSelecionado = { descricao: "Dias", value: "dias"};
      this.origemWppSelecionado = null;
      this.tagsSelecionadas = [];
      this.quando = 0;
      this.descricao = "";
      this.outraData = false;
      this.enviaAntes = true;
      this.reguaNameWrite = "";

      this.$store.commit("OpenDialogNovaRegua", false);
      this.$store.commit("OpenDialogTags", false);
      this.errors = [];
      this.$store.commit("valueQuando", {});
    },

    salvar() {
      if (this.templateSelecionado === null) {
        this.errors = {
          template_id: {
            required: "Um template precisa ser escolhido!"
          }
        };
        return false;
      }
      if(this.origemWppSelecionado === null && this.tipoReguaSelecionado == "WhatsApp") {
        this.errors = {
          origem: {
            required: "Uma origem precisa ser escolhida!"
          }
        };
        return false;
      }

      if (String(this.reguaHorario).split(":").length === 0) {
        this.errors = {
          horario: {
            required: "Um horário precisa ser informado!",
          },
        };
        return false;
      }

      let [horarioRegua] = String(this.reguaHorario).split(":");
      horarioRegua = parseInt(horarioRegua);

      if (Number.isNaN(horarioRegua) || horarioRegua < 0 || horarioRegua > 24) {
        this.errors = {
          horario: {
            required: "Escolha um horário válido!",
          },
        };
        return false;
      }

      this.errors = [];
      let dados = {};

      dados.template_id = this.templateSelecionado.id;
      dados.horario = horarioRegua;

      this.quandoEnvia == "antes" && this.quando !== 0? dados.quando = this.quando * -1: dados.quando = this.quando;
      if (this.tipoReguaSelecionado == "Email") {
        dados.unidade = "dias";
        dados.tipo = 1;
        dados.origem = this.origemEmailSelecionado.tipo;
        if (this.reguaNameWrite !== "") {
          dados.descricao = this.reguaNameWrite;
        } else {
          dados.descricao = this.templateSelecionado.subject + " via " + this.tipoReguaSelecionado + ", " + this.quando + " dia(s) " + this.quandoEnvia + " do vencimento do Boleto.";
        }
      } else if (this.tipoReguaSelecionado == "WhatsApp") {
        dados.unidade = this.origemIsBoleto ? "dias" : this.unidadesTempoSelecionado.value;
        dados.tipo = 2;
        dados.origem = this.origemWppSelecionado.tipo;

        if (this.tagsSelecionadas.length > 0) {
          dados.tags = this.tagsSelecionadas.map((tag) => tag.id);
        }

        if (this.reguaNameWrite !== "") {
          dados.descricao = this.reguaNameWrite;
        } else {
          const descricaoTags = this.tagsSelecionadas.map((tag) => tag.title);
          let unidadeTempo = "";
          let origem = "";

          if (dados.unidade == 'dias') unidadeTempo = " Dia(s) ";
          else if (dados.unidade == 'horas') unidadeTempo = " Hora(s) ";
          else unidadeTempo = " Minuto(s) ";

          if (this.origemWppSelecionado.tipo == "movcontas") origem = " do vencimento do Boleto";
          else if (this.origemWppSelecionado.tipo == "calendario") origem = " do evento na Agenda";
          else if (this.origemWppSelecionado.tipo == "cadastros") origem = " do Cadastro";
          else if (this.origemWppSelecionado.tipo == "faturas-liquidadas")  origem = " do Boleto Liquidado";
          else  origem = " da expiração da Mensagem";
          dados.descricao = this.templateSelecionado.subject + " via " + this.tipoReguaSelecionado + ", " + this.quando + unidadeTempo + this.quandoEnvia + origem;

          if (descricaoTags.length > 0) {
            dados.descricao += ". Tags: " + descricaoTags.join(", ");
          }
        }
      }
      this.salvando = true;

      axios
        .post("/regua-cobrancas/add", dados)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });

            this.$store.dispatch("reguaDeCobranca");
            this.closeModal();
          } else {
            this.errors = res.data.errors;
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          this.salvando = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível adicionar a régua de cobrança, ou você não possui permissão para isto!",
            color: "red",
            position: "top-right"
          });
          this.$store.dispatch("reguaDeCobranca");
          this.salvando = false;
        });
    },
    addTagRegua() {
      this.$store.dispatch("addTag");
    },
    buscaTemplates() {
      let params = null;
      if(this.tipoReguaSelecionado === "Email"){
          params = {
            tipo: "boleto",
          };
          if (this.origemEmailSelecionado && this.origemEmailSelecionado.tipo === "cartao") {
            params = {
              tipo: "cartao",
            };
          }
      }else if (this.tipoReguaSelecionado == "WhatsApp"){
        params = {
          tipo: "whatsapp",
        };
      }

      axios
        .get("/templates", {
          params: params
        })
        .then(res => {
          if (res.data.success) {
            this.optionsTemplates = res.data.data.templates;
          }
        })
        .catch(err => {
          Notify.create({
            message: "Houve um erro ao buscar os templates",
            color: "red",
            position: "top-right"
          });
        });
    }
  },
  computed: {
    ...mapGetters([
      "valueQuando",
      "allTags"
    ]),
    OpenDialogNovaRegua: {
      get() {
        return this.$store.getters.OpenDialogNovaRegua;
      },
      set() {}
    },
    origemIsBoleto() {
      return this.origemWppSelecionado && this.origemWppSelecionado.descricao === "Boletos";
    },
    disableQuandoEnvia() {
      return (this.origemEmailSelecionado && this.origemEmailSelecionado.tipo === "faturas")
       || (this.origemWppSelecionado && this.origemWppSelecionado.tipo === "faturas");
    },
  },
  mounted() {
  },
  watch: {
    tipoReguaSelecionado(val){
      if(val){
        this.buscaTemplates();
      }
    },
    OpenDialogNovaRegua(val){
      if (val){
        this.addTagRegua();
        this.buscaTemplates();
      }
    }
  }
};
</script>

<style lang="stylus" scoped></style>
