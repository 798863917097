import axios from "@/axios-auth";
import Router from "@/router/router";
import User from "./profile";
import { bus } from "@/main";
import { Notify, Dialog, Loading } from "quasar";
import moment from "moment";

const state = {
  lancamentoCaixas: [],
  pagingCaixas: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  filtrosCaixas: {
    status: "A",
    dtini: moment()
      .startOf("month")
      .format("DD-MM-YYYY"),
    dtfim: moment()
      .endOf("month")
      .format("DD-MM-YYYY"),
    busca: null,
    cadastro_id: null,
    dtmovimento: null
  },
  caixas: [],
  caixa: [],
  acaoCaixa: 1,
  venda: {
    descricao: "",
    valor: 0
  },
  lobbyCaixas: true,
  fechaCaixa: false,
  valoresTotais: {
    saldoinicial: 0,
    totalentrada: 0,
    totalsaida: 0,
    totalfiado: 0,
    saldofinal: 0
  },
  cadastroReturn: null,
  movcontaReturn: null,
  isSaldoInicial: false,
  addItemCaixa: false,
  addItemCaixa2: false,
  ordenaCima: false,
  newCaixa: {
    isNew: true,
    nome: "",
    numero: "",
    id: null
  },
  errorsCaixas: {},
  loadCaixas: false,
  queryCaixas: {},
  buscandoCaixas: false,
  novoCaixa: false,
  movcaixa: {
    id: null,
    empresa_id: null,
    caixa_id: null,
    status: "",
    func_resp_id: null,
    func_resp_nome: "",
    func_aber_id: null,
    func_aber_nome: "",
    func_fech_id: null,
    func_fech_nome: "",
    sessao: null,
    created: "",
    modified: "",
    dtmovimento: "",
    dtfechamento: "",
    total: 0,
    quebra: 0,
    justificativa: null,
    viufechamento: 0,
    caixa_nome: "",
    caixa: {
      id: null,
      empresa_id: null,
      nome: "",
      numero: "",
      created: "",
      modified: ""
    },
    movcaixasaldos: []
  },
  tiposSaldos: [],
  totaldosCaixas: {
    total: null,
    total_entrada: null,
    total_saida: null,
    total_fiado: null,
    total_saldo_inicial: null,
    total_vendas: null,
    total_resumo: null,
    total_reforco: null,
    total_recebimentos: null,
    total_fiado: null,
    total_pagamentos: null,
    total_sangria: null,
    total_saldo_final: null,
    total_saldo_final_operador: null
  },
  isSaldoFinal: false,
  saldoFinal: {},
  listarFuncionarios: [],
  totalCaixas: 0,
  loadMovcaixa: false,
  movconta_vencimento: "",
  movconta_id_caixa: null,
  conta_id_caixa: null,
  caixaId: null,
  routeName: null,
  funcionarioCaixa: [],
  funcionarioNome: "Funcionário",
  salvareNovo: false,
  func: {},
  isPrazo: false,
  isModal: {
    isModalSaldoInicial: false,
    isModalVenda: false,
    isModalQuitacaoContas: false,
    isModalReforco: false,
    isModalSangria: false,
    isModalSaldoFinal: false,
    isModalRecebimentoFiado: false,
    isModalCompras: false,
    isModalRecebimentoFiadoMultiplo: false
  },
  blockValor: false,
  resumoPrint: "",
  resumoPrinting: false,
  fechandoCaixa: false,
  resumoCobrancaPrinting: false,
  imgOCRId: null,
  movcaixacategoria: {
    id: 1,
    titulo: "Recebimentos - Loterias"
  },
  OpenDialogNovoCaixa: false,
  OpenDialogNovoLancamentoCaixa: false,
  OpenDialogAlterarIntroducaoCaixas: false,
  OpenModalCobrancaDiaria: false,
    OpenDialogHelpCaixas: false,
  OpenDialogUploadResumoCaixas: false,
  OpenDialogSaldoInicialCaixas: false,
  OpenDialogRecebimentoFiadoCaixas: false,
  OpenDialogQuitacaoContasCaixas: false,
  OpenDialogReforcoCaixas: false,
  OpenDialogSaldoFinalCaixas: false,
  OpenDialogSangriaCaixas: false,
  OpenDialogVendaCaixas: false,
  openDialogVendaCaixasDetalhada: false,
  OpenDialogRecebimentoFiadoCaixasMultiplo: false,
  isReadOnlyCaixa: false,
  isPermissoCaixas: true,
  OpenDialogComprasProduto: false,
  dialogCaixaCompraDetalhada: false,
  notClear: false,
  salvandoVendaCaixa: false,
  dadosCaixaContaPagarReceber: {
    gerarNovaMovconta: false,
    novoVencimento: moment().format("DD/MM/YYYY"),
    valorRestante: 0,
    adicionarSaldoCliente: false,
  },
  dadosSangrias: {
    caixa: null,
    detalharNotas: false,
    detalharMoedas: false,
    transferirCaixa: false,
    notas: {
      notas_2: 0,
      notas_5: 0,
      notas_10: 0,
      notas_20: 0,
      notas_50: 0,
      notas_100: 0,
      notas_200: 0,
    },
    moedas: {
      moedas_5: 0,
      moedas_10: 0,
      moedas_25: 0,
      moedas_50: 0,
      moedas_1: 0,
    },
  },
  dadosReforcos: {
    caixa: null,
    detalharNotas: false,
    detalharMoedas: false,
    transferirCaixa: false,
    notas: {
      notas_2: 0,
      notas_5: 0,
      notas_10: 0,
      notas_20: 0,
      notas_50: 0,
      notas_100: 0,
      notas_200: 0,
    },
    moedas: {
      moedas_5: 0,
      moedas_10: 0,
      moedas_25: 0,
      moedas_50: 0,
      moedas_1: 0,
    },
  },
  opcoesMovcaixas: {
    movcaixas_cancelar_movconta_aberta: "0",
    movcaixas_cancelar_movconta_liquidada: "0",
    movcaixas_venda_detalhada: "0",
    movcaixas_compra_detalhada: "0",
  },
  opcoesEstoque: {
    validar_estoque: false,
    utilizar_estoque_empresa: false,
    utilizar_compra_detalhada_caixa: false,
  },
  vendaCaixasDetalhada: null,
  recebimentoFiadoCaixasMultiplo: null,
  temPermissaoVer: false,
  podeTirarFoto: false,
  gatewayPixCaixa: null,
  compraCaixasDetalhada: null,
  metadadosCaixas: {
    valor_total: 0,
  },
  permissoesCaixas: {},
  isCardView: false,
  lancamentoCaixasComResumo:[]
};

const mutations = {
  isModal: (state, userData) => {
    state.isModal.isModalQuitacaoContas = userData.quitacaoContas;
    state.isModal.isModalRecebimentoFiado = userData.recebimentoFiado;
    state.isModal.isModalReforco = userData.reforco;
    state.isModal.isModalSaldoFinal = userData.saldoFinal;
    state.isModal.isModalSaldoInicial = userData.saldoInicial;
    state.isModal.isModalSangria = userData.sangria;
    state.isModal.isModalVenda = userData.venda;
    state.isModal.isModalCompras = userData.compras;
    state.isModal.isModalRecebimentoFiadoMultiplo = userData.recebimentoFiadoMultiplo;
  },
  lancamentoCaixas: (state, userData) => {
    state.lancamentoCaixas = userData;
  },
  lancamentoCaixasComResumo: (state, userData) => {
    state.lancamentoCaixasComResumo = userData;
  },
  caixas: (state, userData) => {
    state.caixas = userData;
  },
  notClear: (state, userData) => {
    state.notClear = userData;
  },
  caixa: (state, userData) => {
    state.caixa = userData;
  },
  acaoCaixa: (state, userData) => {
    state.acaoCaixa = userData;
  },
  venda: (state, userData) => {
    state.venda.descricao = userData.descricao;
    state.venda.valor = userData.valor;
  },
  lobbyCaixas: (state, userData) => {
    state.lobbyCaixas = userData;
  },
  fechaCaixa: (state, userData) => {
    state.fechaCaixa = userData;
  },
  valoresTotais: (state, userData) => {
    state.valoresTotais.saldoinicial = userData.saldoinicial;
    state.valoresTotais.totalentrada = userData.totalentrada;
    state.valoresTotais.totalsaida = userData.totalsaida;
    state.valoresTotais.totalfiado = userData.totalfiado;
    state.valoresTotais.saldofinal = userData.saldofinal;
  },
  cadastroReturn: (state, userData) => {
    state.cadastroReturn = userData;
  },
  movcontaReturn: (state, userData) => {
    state.movcontaReturn = userData;
  },
  isSaldoInicial: (state, userData) => {
    state.isSaldoInicial = userData;
  },
  addItemCaixa: (state, userData) => {
    state.addItemCaixa = userData;
  },
  addItemCaixa2: (state, userData) => {
    state.addItemCaixa2 = userData;
  },
  ordenaCima: state => {
    state.ordenaCima = !state.ordenaCima;
  },
  newCaixa: (state, userData) => {
    state.newCaixa.isNew = userData.isNew;
    state.newCaixa.nome = userData.nome;
    state.newCaixa.numero = userData.numero;
    state.newCaixa.id = userData.id;
  },
  errorsCaixas: (state, userData) => {
    state.errorsCaixas = userData;
  },
  loadCaixas: state => {
    state.loadCaixas = !state.loadCaixas;
  },
  pagingCaixas: (state, userData) => {
    state.pagingCaixas = userData;
  },
  filtrosCaixas: (state, userData) => {
    state.filtrosCaixas = userData;
  },
  queryCaixas: (state, userData) => {
    state.queryCaixas = userData;
  },
  buscandoCaixas: (state, userData) => {
    state.buscandoCaixas = userData;
  },
  isSaldoFinal: (state, userData) => {
    state.isSaldoFinal = userData;
  },
  saldoFinal: (state, userData) => {
    state.saldoFinal = userData;
  },
  listarFuncionarios: (state, userData) => {
    state.listarFuncionarios = userData;
  },
  novoCaixa: (state, userData) => {
    state.novoCaixa = userData;
  },
  movcaixa: (state, userData) => {
    state.movcaixa = userData;
  },
  tiposSaldos: (state, userData) => {
    state.tiposSaldos = userData;
  },
  totalCaixas: (state, userData) => {
    state.totalCaixas = userData;
  },
  totaldosCaixas: (state, userData) => {
    state.totaldosCaixas = userData;
  },
  loadMovcaixa: (state, userData) => {
    state.loadMovcaixa = userData;
  },
  movconta_id_caixa: (state, userData) => {
    state.movconta_id_caixa = userData;
  },
  conta_id_caixa: (state, userData) => {
    state.conta_id_caixa = userData;
  },
  caixaId: (state, userData) => {
    state.caixaId = userData;
  },
  routeName: (state, userData) => {
    state.routeName = userData;
  },
  funcionarioCaixa: (state, userData) => {
    state.funcionarioCaixa = userData;
  },
  funcionarioNome: (state, userData) => {
    state.funcionarioNome = userData;
  },
  salvareNovo: (state, userData) => {
    state.salvareNovo = userData;
  },
  func: (state, userData) => {
    state.func = userData;
  },
  isPrazo: (state, userData) => {
    state.isPrazo = userData;
  },
  movconta_vencimento: (state, userData) => {
    state.movconta_vencimento = userData;
  },
  blockValor: (state, userData) => {
    state.blockValor = userData;
  },
  resumoPrint: (state, userData) => {
    state.resumoPrint = userData;
  },
  resumoPrinting: state => {
    state.resumoPrinting = !state.resumoPrinting;
  },
  fechandoCaixa: state => {
    state.fechandoCaixa = !state.fechandoCaixa;
  },
  resumoCobrancaPrinting: state => {
    state.resumoCobrancaPrinting = !state.resumoCobrancaPrinting;
  },
  imgOCRId: (state, userData) => {
    state.imgOCRId = userData;
  },
  movcaixacategoria: (state, userData) => {
    state.movcaixacategoria = userData;
  },
  OpenDialogNovoCaixa: (state, userData) => {
    state.OpenDialogNovoCaixa = userData;
  },
  OpenDialogNovoLancamentoCaixa: (state, userData) => {
    state.OpenDialogNovoLancamentoCaixa = userData;
  },
  OpenDialogAlterarIntroducaoCaixas: (state, userData) => {
    state.OpenDialogAlterarIntroducaoCaixas = userData;
  },
  OpenModalCobrancaDiaria: (state, userData) => {
    state.OpenModalCobrancaDiaria = userData;
  },
  OpenDialogHelpCaixas: (state, userData) => {
    state.OpenDialogHelpCaixas = userData;
  },
  OpenDialogUploadResumoCaixas: (state, userData) => {
    state.OpenDialogUploadResumoCaixas = userData;
  },
  OpenDialogSaldoInicialCaixas: (state, userData) => {
    state.OpenDialogSaldoInicialCaixas = userData;
  },
  OpenDialogRecebimentoFiadoCaixas: (state, userData) => {
    state.OpenDialogRecebimentoFiadoCaixas = userData;
  },
  OpenDialogQuitacaoContasCaixas: (state, userData) => {
    state.OpenDialogQuitacaoContasCaixas = userData;
  },
  OpenDialogReforcoCaixas: (state, userData) => {
    state.OpenDialogReforcoCaixas = userData;
  },
  OpenDialogSaldoFinalCaixas: (state, userData) => {
    state.OpenDialogSaldoFinalCaixas = userData;
  },
  OpenDialogSangriaCaixas: (state, userData) => {
    state.OpenDialogSangriaCaixas = userData;
  },
  OpenDialogVendaCaixas: (state, userData) => {
    state.OpenDialogVendaCaixas = userData;
  },
  OpenDialogComprasProduto: (state, userData) => {
    state.OpenDialogComprasProduto = userData;
  },
  setDialogCaixaCompraDetalhada: (state, userData) => {
    state.dialogCaixaCompraDetalhada = userData;
  },
  isReadOnlyCaixa: (state, userData) => {
    state.isReadOnlyCaixa = userData;
  },
  isPermissoCaixas: (state, userData) => {
    state.isPermissoCaixas = userData;
  },
  setSalvandoLancamentoItemCaixa: (state, userData) => {
    state.salvandoVendaCaixa = userData;
  },
  setDadosCaixaContaPagarReceber: (state, userData) => {
    state.dadosCaixaContaPagarReceber = userData;
  },
  setDadosSangrias: (state, userData) => {
    state.dadosSangrias = userData;
  },
  setDadosReforcos: (state, userData) => {
    state.dadosReforcos = userData;
  },
  setOpcoesMovcaixas: (state, userData) => {
    state.opcoesMovcaixas = userData;
  },
  setOpcoesEstoque: (state, userData) => {
    state.opcoesEstoque = userData;
  },
  setOpenDialogVendaCaixasDetalhada: (state, userData) => {
    state.openDialogVendaCaixasDetalhada = userData;
  },
  OpenDialogRecebimentoFiadoCaixasMultiplo: (state, userData) => {
    state.OpenDialogRecebimentoFiadoCaixasMultiplo = userData;
  },
  setRecebimentoFiadoCaixasMultiplo: (state, userData) => {
    state.recebimentoFiadoCaixasMultiplo = userData;
  },
  setVendaCaixasDetalhada: (state, userData) => {
    state.vendaCaixasDetalhada = userData;
  },
  setTemPermissaoVer: (state, userData) => {
    state.temPermissaoVer = userData;
  },
  setPodeTirarFoto: (state, userData) => {
    state.podeTirarFoto = userData;
  },
  setGatewayPixCaixa: (state, userData) => {
    state.gatewayPixCaixa = userData;
  },
  setCompraCaixasDetalhada: (state, userData) => {
    state.compraCaixasDetalhada = userData;
  },
  metadadosCaixas: (state, userData) => {
    state.metadadosCaixas = userData;
  },
  permissoesCaixas: (state, userData) => {
    state.permissoesCaixas = userData;
  },
  setIsCardView: (state, newValue) => {
    state.isCardView = newValue;
  },
};

const actions = {
  movconta_vencimento: ({ commit }, userData) => {
    commit("movconta_vencimento", userData);
  },
  listarCaixas: ({ commit }) => {
    if (state.buscandoCaixas || !state.pagingCaixas.nextPage) return;
    commit("buscandoCaixas", true);
    if (!state.pagingCaixas.nextPage) {
      return;
    }
    commit("isPermissoCaixas", true);
    if (state.queryCaixas.query) {
      if (state.queryCaixas.query.cadastro_id) {
        commit("filtrosCaixas", {
          cadastro_id: state.queryCaixas.query.cadastro_id,
          busca: state.filtrosCaixas.busca,
          dtini: state.filtrosCaixas.dtini,
          dtfim: state.filtrosCaixas.dtfim,
          dtmovimento: state.filtrosCaixas.dtmovimento,
          status: state.filtrosCaixas.status
        });
      }
      if (state.queryCaixas.query.dtmovimento) {
        commit("filtrosCaixas", {
          dtini: state.filtrosCaixas.dtini,
          busca: state.filtrosCaixas.busca,
          dtfim: state.filtrosCaixas.dtfim,
          dtmovimento: state.queryCaixas.query.dtmovimento,
          cadastro_id: state.filtrosCaixas.cadastro_id,
          status: state.filtrosCaixas.status
        });
      }
      if (state.queryCaixas.query.dtini) {
        commit("filtrosCaixas", {
          dtini: state.queryCaixas.query.dtini,
          busca: state.filtrosCaixas.busca,
          dtfim: state.filtrosCaixas.dtfim,
          cadastro_id: state.filtrosCaixas.cadastro_id,
          dtmovimento: state.filtrosCaixas.dtmovimento,
          status: state.filtrosCaixas.status
        });
      }
      if (state.queryCaixas.query.dtfim) {
        commit("filtrosCaixas", {
          dtfim: state.queryCaixas.query.dtfim,
          busca: state.filtrosCaixas.busca,
          dtini: state.filtrosCaixas.dtini,
          cadastro_id: state.filtrosCaixas.cadastro_id,
          dtmovimento: state.filtrosCaixas.dtmovimento,
          status: state.filtrosCaixas.status
        });
      }
      if (state.queryCaixas.query.status) {
        commit("filtrosCaixas", {
          status: state.queryCaixas.query.status,
          busca: state.filtrosCaixas.busca,
          dtini: state.filtrosCaixas.dtini,
          dtfim: state.filtrosCaixas.dtfim,
          cadastro_id: state.filtrosCaixas.cadastro_id,
          dtmovimento: state.filtrosCaixas.dtmovimento
        });
      }
    }
    axios
      .get("/lancamentos/caixas", {
        params: {
          page: state.pagingCaixas.page + 1,
          status: state.filtrosCaixas.status,
          dtini: state.filtrosCaixas.dtini,
          dtfim: state.filtrosCaixas.dtfim,
          dtmovimento: state.filtrosCaixas.dtmovimento,
          busca: state.filtrosCaixas.busca,
          cadastro_id: state.filtrosCaixas.cadastro_id
        }
      })
      .then(res => {
        if (res.data.success) {
          commit(
            "lancamentoCaixas",
            state.lancamentoCaixas.concat(res.data.movcaixas)
          );
          commit("pagingCaixas", res.data.paging);
          commit("totalCaixas", state.totalCaixas + res.data.paging.current);
          // commit('filtrosCaixas', res.data.filters)
          commit("funcionarioCaixa", res.data.funcionarios);
          commit("metadadosCaixas", res.data.metadados);
          commit("permissoesCaixas", res.data.permissoes);

        }
        commit("buscandoCaixas", false);
      })
      .catch(() => {
        // Notify.create({
        //   message:
        //     "Não foi possível listar os caixas, ou você não possui permissão!",
        //   color: "red",
        //   position: "top-right"
        // });
        commit("isPermissoCaixas", false);
        commit("buscandoCaixas", false);
      });
  },
  listarCaixasComResumo: ({ commit }) =>{
    commit("setIsCardView", true);
    axios
    .post("/lancamentos/caixas?getCardView=1")
    .then(res => {
      if (res.data.success) {
        commit("lancamentoCaixasComResumo", res.data.caixasresumo);
      }else{
        commit("setIsCardView", false);
      }
      commit("buscandoCaixas", false);
    })
    .catch(() => {
      Notify.create({
        color: 'red',
        message: 'Não foi possível abrir a visualização, ou você não possui permissão!',
        position: 'top-right',
      });
      commit("buscandoCaixas", false);
    });
  },
  caixas: ({ commit }) => {
    commit("loadCaixas");
    axios
      .get("/caixas")
      .then(res => {
        if (res.data.success) {
          commit("caixas", res.data.caixas);
        }
        commit("loadCaixas");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadCaixas");
      });
  },
  comparaValores: ({ commit }, userData) => {
    commit("isSaldoFinal", true);
    commit("saldoFinal", userData);
    commit("OpenDialogSaldoFinalCaixas", true);
  },
  alimentaCaixa: async ({ commit, dispatch, state }, userData) => {
    if (state.salvandoVendaCaixa) {
      commit("addItemCaixa", false);
      commit("addItemCaixa2", false);
      return;
    }

    commit("setSalvandoLancamentoItemCaixa", true);

    await axios
      .post("/lancamentos/add-saldo/" + state.movcaixa.id, userData)
      .then(res => {
        commit("setSalvandoLancamentoItemCaixa", false);
        commit("addItemCaixa", false);
        commit("addItemCaixa2", false);

        if (res.data.success) {
          let dados = res.data.movcaixasaldo;
          let cx = state.caixa;
          if(Array.isArray(dados)) {
            for(let dado of dados) {
              dado.hide = false;
              cx.unshift(dado);
            }
          } else {
            dados.hide = false;
            cx.unshift(dados);
          }

          commit("caixa", cx);
          commit("totaldosCaixas", {
            total: res.data.total,
            total_entrada: res.data.total_entrada,
            total_saida: res.data.total_saida,
            total_fiado: res.data.total_fiado,
            total_saldo_inicial: res.data.total_saldo_inicial,
            total_vendas: res.data.total_vendas,
            total_resumo: res.data.total_resumo,
            total_reforco: res.data.total_reforco,
            total_recebimentos: res.data.total_recebimentos,
            total_fiado: res.data.total_fiado,
            total_pagamentos: res.data.total_pagamentos,
            total_sangria: res.data.total_sangria,
            total_saldo_final: res.data.total_saldo_final,
            total_saldo_final_operador: res.data.total_saldo_final_operador,
            total_compras: res.data.total_compras
          });
          if (userData.tipo === 4 || userData.tipo === 6) {
            let autocompletedElement = document.getElementsByName(
              "autocompleted"
            );
            if (autocompletedElement.length > 0) {
              autocompletedElement[0].focus();
            }
          }

          if (!state.notClear) {
            dispatch("resetVenda");

            bus.$emit('resetarModalCaixaVenda');
            bus.$emit("clear");
          }

          commit("movcontaReturn", null);
          if (!state.salvareNovo) {
            commit("OpenDialogSaldoInicialCaixas", false);
            commit("OpenDialogRecebimentoFiadoCaixas", false);
            commit("OpenDialogQuitacaoContasCaixas", false);
            commit("OpenDialogReforcoCaixas", false);
            commit("OpenDialogSaldoFinalCaixas", false);
            commit("OpenDialogSangriaCaixas", false);
            commit("OpenDialogVendaCaixas", false);
          }
          commit("isPrazo", false);
          commit("isReadOnlyCaixa", false);
          commit("blockValor", false);
          commit("movconta_id_caixa", null);
          commit("conta_id_caixa", null);
          commit("errorsCaixas", {});
          dispatch("resetDadosSangrias");
          dispatch("resetDadosReforcos");

          if (userData.tipo === 5 || userData.tipo === 1) {
            bus.$emit("clearReset");
            bus.$emit("clearFiado");
          }

          if (userData.tipo === 4) {
            bus.$emit("clearReceberContaFiado");
          }

          if (userData.tipo === 6) {
            bus.$emit("clearQuitarConta");
          }

          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          if (userData.tipo === 5 && res.data.qrcode) {
            bus.$emit("openModalQrcodeVenda", res.data);
          }
        } else {
          commit("errorsCaixas", res.data.errors || {});

          if (parseInt(userData.valor) === 0) {
            Notify.create({
              message: "Não é possível efetuar o lançamento com valor R$0,00",
              color: "orange",
              position: "top-right"
            });
          }

          if (
            userData.tipo === 4 ||
            userData.tipo === 6 ||
            userData.tipo === 5
          ) {
            if (document.getElementsByName("autocompleted")[0]) {
              document.getElementsByName("autocompleted")[0].focus();
            }
          } else if (userData.tipo != 9) {
            if (document.getElementsByName("descricaoCaixa")[0]) {
              document.getElementsByName("descricaoCaixa")[0].focus();
            }
          }

          let mensagem = res.data.message;

          if (res.data.errors) {
            if (res.data.errors.movconta_id) {
              mensagem = res.data.message + ' ' + res.data.errors.movconta_id[0];
            }

            if (res.data.errors.cadastro_id) {
              mensagem = res.data.message + ' ' + res.data.errors.cadastro_id[0];
            }
          }

          if (mensagem) {
            Notify.create({
              message: mensagem ? mensagem : 'Erro ao tentar salvar dados',
              color: "orange",
              position: "top-right"
            });
          }
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("setSalvandoLancamentoItemCaixa", false);
        commit("addItemCaixa", false);
        commit("addItemCaixa2", false);
      });
  },
  //   OpenDialogSaldoInicialCaixas
  //   OpenDialogRecebimentoFiadoCaixas
  //   OpenDialogQuitacaoContasCaixas
  //   OpenDialogReforcoCaixas
  //   OpenDialogSaldoFinalCaixas
  //   OpenDialogSangriaCaixas
  //   OpenDialogVendaCaixas
  selecionaTecla: ({ commit, dispatch, state }, userData) => {
    if (state.acaoCaixa === 1 && state.isSaldoFinal) return;
    if (userData.ctrl && userData.shift) {
      switch (userData.tecla) {
        case 49:
          commit("isModal", {
            quitacaoContas: false,
            recebimentoFiado: false,
            recebimentoFiadoMultiplo: false,
            reforco: false,
            saldoFinal: false,
            saldoInicial: true,
            sangria: false,
            venda: true,
            compras: false
          });
          commit("acaoCaixa", 1);
          dispatch("resetVenda");
          setTimeout(() => {
            commit("OpenDialogSaldoInicialCaixas", true);
            setTimeout(() => {
              document.getElementsByName("descricaoCaixa")[0].focus();
            }, 100);
          }, 100);
          break;
        case 50:
          commit("isModal", {
            quitacaoContas: false,
            recebimentoFiado: false,
            recebimentoFiadoMultiplo: false,
            reforco: true,
            saldoFinal: false,
            saldoInicial: false,
            sangria: false,
            venda: false,
            compras: false
          });
          commit("acaoCaixa", 3);
          dispatch("resetVenda");
          dispatch("contas");
          setTimeout(() => {
            commit("OpenDialogReforcoCaixas", true);
            setTimeout(() => {
              document.getElementsByName("descricaoCaixa")[0].focus();
            }, 100);
          }, 100);
          break;
        case 51:
          commit("isModal", {
            quitacaoContas: false,
            recebimentoFiado: false,
            recebimentoFiadoMultiplo: false,
            reforco: false,
            saldoFinal: false,
            saldoInicial: false,
            sangria: false,
            venda: false,
            compras: true
          });
          commit("acaoCaixa", 10);
          dispatch("resetVenda");
          setTimeout(() => {
            if (state.opcoesMovcaixas.movcaixas_compra_detalhada && state.opcoesMovcaixas.movcaixas_compra_detalhada === "1") {
              dispatch("openDialogCaixaCompraDetalhada", null);
            } else {
              commit("OpenDialogComprasProduto", true);
            }
            setTimeout(() => {
              // $(".multiselect").addClass("multiselect--active");
              // $(".multiselect__content-wrapper").css("display", "block");
              // $("body").addClass("keyboard-focused");
              // $(".multiselect__content-wrapper").focus();
            }, 100);
          }, 100);
          break;
        case 52:
          commit("isModal", {
            quitacaoContas: false,
            recebimentoFiado: false,
            recebimentoFiadoMultiplo: false,
            reforco: false,
            saldoFinal: false,
            saldoInicial: false,
            sangria: false,
            venda: true,
            compras: false
          });
          commit("acaoCaixa", 2);
          dispatch("resetVenda");
          commit("movcontaReturn", null);
          setTimeout(() => {
            if (state.opcoesMovcaixas.movcaixas_venda_detalhada && state.opcoesMovcaixas.movcaixas_venda_detalhada === "1") {
              dispatch("openDialogVendaCaixasDetalhada", null);
            } else {
              commit("OpenDialogVendaCaixas", true);
            }
            setTimeout(() => {
              if (document.getElementsByName("autocompleted")[0]) {
                document.getElementsByName("autocompleted")[0].focus();
              }
            }, 100);
          }, 100);
          break;
        // case 53:
        //   commit('acaoCaixa', 5)
        //   dispatch("resetVenda");
        //   $('#vendaFiado').modal('open')
        //   break
        case 53:
          commit("acaoCaixa", 4);
          if (userData.plano === "Essencial") {
            dispatch("toggleUpgradePlano", true);
            return;
          }
          commit("isModal", {
            quitacaoContas: false,
            recebimentoFiado: state.opcoesMovcaixas.movcaixas_receber_multiplos
              && state.opcoesMovcaixas.movcaixas_receber_multiplos === "1"? false : true,
            recebimentoFiadoMultiplo: state.opcoesMovcaixas.movcaixas_receber_multiplos
              && state.opcoesMovcaixas.movcaixas_receber_multiplos === "1"? true : false,
            reforco: false,
            saldoFinal: false,
            saldoInicial: false,
            sangria: false,
            venda: false,
            compras: false
          });
          dispatch("resetVenda");
          commit("movcontaReturn", null);
          setTimeout(() => {
            if (state.opcoesMovcaixas.movcaixas_receber_multiplos
              && state.opcoesMovcaixas.movcaixas_receber_multiplos === "1") {
              dispatch("OpenDialogRecebimentoFiadoCaixasMultiplo", null);
            } else {
              commit("OpenDialogRecebimentoFiadoCaixas", true);
            }
            setTimeout(() => {
              if (document.getElementsByName("autocompleted")[0]) {
                document.getElementsByName("autocompleted")[0].focus();
              }
            }, 100);
          }, 100);
          dispatch("contas");
          break;
        case 54:
          commit("acaoCaixa", 6);
          if (userData.plano === "Essencial") {
            dispatch("toggleUpgradePlano", true);
            return;
          }
          commit("isModal", {
            quitacaoContas: true,
            recebimentoFiado: false,
            recebimentoFiadoMultiplo: false,
            reforco: false,
            saldoFinal: false,
            saldoInicial: false,
            sangria: false,
            venda: false,
            compras: false
          });
          dispatch("resetVenda");
          dispatch("contas");
          setTimeout(() => {
            commit("OpenDialogQuitacaoContasCaixas", true);
            setTimeout(() => {
              // $(".multiselect").addClass("multiselect--active");
              // $(".multiselect__content-wrapper").css("display", "block");
              // $("body").addClass("keyboard-focused");
              // $(".multiselect__content-wrapper").focus();
            }, 100);
          }, 100);
          break;
        case 55:
          commit("isModal", {
            quitacaoContas: false,
            recebimentoFiado: false,
            recebimentoFiadoMultiplo: false,
            reforco: false,
            saldoFinal: false,
            saldoInicial: false,
            sangria: true,
            venda: false,
            compras: false
          });
          commit("acaoCaixa", 7);
          dispatch("resetVenda");
          dispatch("contas");
          setTimeout(() => {
            commit("OpenDialogSangriaCaixas", true);
            setTimeout(() => {
              document.getElementsByName("descricaoCaixa")[0].focus();
            }, 100);
          }, 100);
          break;
        case 56:
          commit("isModal", {
            quitacaoContas: false,
            recebimentoFiado: false,
            recebimentoFiadoMultiplo: false,
            reforco: false,
            saldoFinal: true,
            saldoInicial: false,
            sangria: false,
            venda: false,
            compras: false
          });
          commit("acaoCaixa", 8);
          dispatch("resetVenda");
          setTimeout(() => {
            commit("OpenDialogSaldoFinalCaixas", true);
            setTimeout(() => {
              document.getElementsByName("descricaoCaixa")[0].focus();
            }, 100);
          }, 100);
          break;
        case 57:
          if (state.movcaixa.status === "A") {
            dispatch("fecharCaixa");
          } else {
            dispatch("reativarCaixa", state.movcaixa);
          }
          break;
      }
    }
  },
  openDialogCaixaCompraDetalhada: ({ commit, dispatch }, userData) => {
    commit("setDialogCaixaCompraDetalhada", true);
    commit("setCompraCaixasDetalhada", userData);
    dispatch("resetModal");
    dispatch("comprasProdutosItems", userData ? userData.movcompra.id : null);
    dispatch("getFornecedores");
    dispatch("produtosEstoque", {
      tipo: "P",
      all: 1,
      ativo: 1
    });
  },
  editarCaixa: ({ commit }, userData) => {
    commit("newCaixa", {
      isNew: false,
      nome: userData.nome,
      numero: userData.numero,
      id: userData.id
    });
    commit("OpenDialogNovoCaixa", true);
  },
  excluirCaixa: ({ commit, dispatch }, userData) => {
    Dialog.create({
      title: userData.nome,
      message: "Confirma a exclusão do caixa?",
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/caixas/delete/" + userData.id)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: "Caixa " + userData.nome + " Excluído!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              commit("caixas", []);
              dispatch("caixas");
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi Possível realizar a ação, ou você não possui permissão isto!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  inativarCaixa: ({ dispatch }, userData) => {
    const palavraConfirmacao = 'excluir caixa';

    Dialog.create({
      title: "Essa operação desfaz os lançamentos relacionados a esse caixa.",
      message: `Digite <strong style="color: #EA4335;">"${palavraConfirmacao}"</strong> no campo abaixo para confirmar`,
      html: true,
      cancel: "Cancelar",
      persistent: true,
      prompt: {
        model: "",
        type: "text",
        filled: false,
        isValid: val => val === palavraConfirmacao,
      }
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(data => {

          axios
            .post("/lancamentos/excluir/" + userData.id, {
              justificativa: data.toLowerCase()
            })
            .then(res => {
              if (res.data.success) {
                Dialog.create({
                  title: "Caixa " + userData.caixa_nome + " Excluído!",
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
                dispatch("resetPagingCaixas");
                dispatch("listarCaixas");
              } else {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
              }
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi possível excluir o caixa, ou você não possui permissão!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });

      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  reativarCaixa: ({ commit, dispatch }, userData) => {
    Dialog.create({
      title: userData.caixa_nome,
      message: "Confirma a reabertura do caixa?",
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/lancamentos/reabrir/" + userData.id)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: "Caixa " + userData.caixa_nome + " reaberto!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });

              if (["caixa", "resumo_tfl"].includes(state.routeName)) {
                dispatch("buscaCaixa", state.caixaId);
                commit("isSaldoFinal", false);
                commit("acaoCaixa", 1);
              }
              dispatch("resetPagingCaixas");
              dispatch("listarCaixas");
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi possível excluir o caixa, ou você não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  filtrosCaixas: ({ commit, dispatch }, userData) => {
    if (userData) {
      commit("filtrosCaixas", userData);
    } else {
      commit("filtrosCaixas", state.filtrosCaixas);
    }
    dispatch("resetPagingCaixas");
    dispatch("listarCaixas");
  },
  resetPagingCaixas: ({ commit }) => {
    commit("pagingCaixas", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });
    commit("lancamentoCaixas", []);
    commit("totalCaixas", 0);
    // commit('totalCaixas', 0)
    // commit('counterCaixas', 0)
  },
  callFuncionarios: ({ commit, dispatch }, value) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/cadastros?funcionario=1&ativo=1")
        .then(res => {
          if (res.data.success) {
            commit("listarFuncionarios", res.data.data.cadastros);
            if (!value) dispatch("getFunc");
          }
          resolve(res.data);
        })
        .catch(() => {
          reject();
        });
    });
  },
  newMovcaixa: ({ commit }) => {
    commit("movcaixa", {
      id: null,
      empresa_id: null,
      caixa_id: null,
      status: "",
      func_resp_id: null,
      func_resp_nome: "",
      func_aber_id: null,
      func_aber_nome: "",
      func_fech_id: null,
      func_fech_nome: "",
      sessao: null,
      created: "",
      modified: "",
      dtmovimento: "",
      dtfechamento: "",
      total: 0,
      quebra: 0,
      justificativa: null,
      viufechamento: 0,
      caixa_nome: "",
      caixa: {
        id: null,
        empresa_id: null,
        nome: "",
        numero: "",
        created: "",
        modified: ""
      }
    });
  },
  buscaCaixa: ({ commit, state, dispatch }, userData) => {
    return new Promise((resolve, reject) => {
      if (!userData) return false;
      commit("loadMovcaixa", true);
      axios
        .get("/lancamentos/caixa/" + userData)
        .then(res => {
          //commit("imgOCRId", res.data.movcaixa.arquivo_id);
          if(!res.data.success){
            Notify.create({
              message: res.data.message,
              color: "red",
              position: "top-right"
            });
            commit("loadMovcaixa", false);
            commit("setTemPermissaoVer", false);
            Router.push("/caixas");
            reject();
            return;
          }
          commit("movcaixa", res.data.movcaixa);
          commit("setOpcoesMovcaixas", res.data.opcoes);
          commit("setOpcoesEstoque", res.data.opcoes_estoque);
          commit("movcaixacategoria", res.data.categoria);
          let dados = JSON.parse(JSON.stringify(state.movcaixa.movcaixasaldos));
          for (let i = 0; i < dados.length; i++) {
            dados[i].hide = false;
          }
          if (dados && dados.length === 0) {
            commit("isSaldoInicial", true);
            dispatch("selecionaTecla", { shift: true, ctrl: true, tecla: 49 });
          } else {
            commit("isSaldoInicial", false);
          }
          if (state.movcaixa.status === "F") {
            commit("isSaldoFinal", true);
            commit("acaoCaixa", 8);
            commit("fechaCaixa", false);
          } else {
            commit("isSaldoFinal", false);
            commit("acaoCaixa", 1);
            commit("fechaCaixa", false);
          }
          commit("caixa", dados);
          commit("setTemPermissaoVer", res.data.temPermissaoVer);
          commit("setPodeTirarFoto", res.data.podeTirarFoto);
          commit("tiposSaldos", res.data.tiposSaldos);
          commit("totaldosCaixas", {
            total: res.data.total,
            total_entrada: res.data.total_entrada,
            total_saida: res.data.total_saida,
            total_fiado: res.data.total_fiado,
            total_saldo_inicial: res.data.total_saldo_inicial,
            total_vendas: res.data.total_vendas,
            total_resumo: res.data.total_resumo,
            total_reforco: res.data.total_reforco,
            total_recebimentos: res.data.total_recebimentos,
            total_fiado: res.data.total_fiado,
            total_pagamentos: res.data.total_pagamentos,
            total_sangria: res.data.total_sangria,
            total_saldo_final: res.data.total_saldo_final,
            total_saldo_final_operador: res.data.total_saldo_final_operador,
            total_compras: res.data.total_compras
          });
          commit("setGatewayPixCaixa", res.data.gateway_pix);
          commit("loadMovcaixa", false);
          resolve(res);
        })
        .catch(() => {
          Router.push("/caixas");
          Notify.create({
            message:
              "Não foi possível abrir o caixa, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          commit("loadMovcaixa", false);
          commit("setTemPermissaoVer", false);
          reject();
        });
    });
  },
  atualizarListaLancamentos: ({ commit }, userData) => {
    axios
      .get(`/lancamentos/caixa/${userData}`)
      .then(res => {
        let dados = res.data.movcaixa.movcaixasaldos;
        dados.forEach(dado => {
          dado.hide = false;
        });

        commit("caixa", dados);
        commit("setTemPermissaoVer", res.data.temPermissaoVer);
        commit("totaldosCaixas", {
          total: res.data.total,
          total_entrada: res.data.total_entrada,
          total_saida: res.data.total_saida,
          total_fiado: res.data.total_fiado,
          total_saldo_inicial: res.data.total_saldo_inicial,
          total_vendas: res.data.total_vendas,
          total_resumo: res.data.total_resumo,
          total_reforco: res.data.total_reforco,
          total_recebimentos: res.data.total_recebimentos,
          total_fiado: res.data.total_fiado,
          total_pagamentos: res.data.total_pagamentos,
          total_sangria: res.data.total_sangria,
          total_saldo_final: res.data.total_saldo_final,
          total_saldo_final_operador: res.data.total_saldo_final_operador,
          total_compras: res.data.total_compras,
        });
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível atualizar os lançamentos, ou você não possui permissão!",
          color: "red",
          position: "top-right",
        });
      });
  },
  excluiLancamento: ({ commit, dispatch }, userData) => {
    Dialog.create({
      title: "Digite o motivo do cancelamento do lançamento",
      cancel: "Cancelar",
      persistent: true,
      prompt: {
        model: "",
        type: "text"
      }
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(data => {
        axios
          .post("/lancamentos/delete-saldo/" + state.movcaixa.id, {
            movcaixasaldos_id: userData.id,
            motivo: data
          })
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: res.data.message,
                persistent: true,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              commit("totaldosCaixas", {
                total: res.data.total,
                total_entrada: res.data.total_entrada,
                total_saida: res.data.total_saida,
                total_saldo_inicial: res.data.total_saldo_inicial,
                total_vendas: res.data.total_vendas,
                total_resumo: res.data.total_resumo,
                total_reforco: res.data.total_reforco,
                total_recebimentos: res.data.total_recebimentos,
                total_fiado: res.data.total_fiado,
                total_pagamentos: res.data.total_pagamentos,
                total_sangria: res.data.total_sangria,
                total_saldo_final: res.data.total_saldo_final,
                total_saldo_final_operador: res.data.total_saldo_final_operador,
                total_compras: res.data.total_compras
              });
              let obj = state.caixa;
              let val = obj.indexOf(userData);
              obj[val] = res.data.movcaixasaldo;

              commit("caixa", obj);
              dispatch("buscaCaixa", state.caixaId);
              commit("loadMovcaixa", false);
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi possível excluir a conta, ou você não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  fecharCaixa: ({ state, dispatch, commit }) => {
    commit("fechandoCaixa");
    axios
      .get("/lancamentos/fechar/" + state.movcaixa.id)
      .then(res => {
        if (res.data.success) {
          if (res.data.podefechar) {
            Dialog.create({
              title: "Apenas para lembrar",
              message: "Você está fechando seu caixa sem diferença.",
              cancel: "Cancelar",
              persistent: true
            })
              .onOk(() => {
                commit("fechaCaixa", true);
                dispatch("postFecharCaixa");
              })
              .onCancel(() => {
                commit("fechandoCaixa");
              });
          } else {
            Dialog.create({
              title: res.data.message,
              message: "Digite a justificativa",
              cancel: "Cancelar",
              persistent: true,
              prompt: {
                model: "",
                type: "text"
              }
            })
              .onOk(() => {
                Loading.show();
              })
              .onOk(data => {
                if (data && data.length <= 3) {
                  Dialog.create({
                    title:
                      "A justificativa deve conter mais de três caracteres!",
                    class: "bg-warning text-white",
                    ok: {
                      "text-color": "orange",
                      color: "white"
                    }
                  });
                  commit("fechandoCaixa");
                } else {
                  dispatch("postFecharCaixa", data);
                }
              })
              .onCancel(() => {
                Loading.hide();
                commit("fechandoCaixa");
              })
              .onDismiss(() => {
                Loading.hide();
              });
          }
        } else {
          Dialog.create({
            title: res.data.message,
            class: "bg-warning text-white",
            ok: {
              "text-color": "orange",
              color: "white"
            }
          });
          commit("fechandoCaixa");
        }
      })
      .catch(() => {
        Dialog.create({
          title:
            "Não foi possível fechar o caixa, ou você não possui permissão!",
          class: "bg-negative text-white",
          ok: {
            "text-color": "red",
            color: "white"
          }
        });
        commit("fechandoCaixa");
      });
  },
  postFecharCaixa: ({ commit, state }, userData) => {
    axios
      .post("/lancamentos/fechar/" + state.movcaixa.id, {
        justificativa: userData
      })
      .then(res => {
        if (res.data.success) {
          commit("fechandoCaixa");
          commit("totaldosCaixas", {
            total: res.data.total,
            total_entrada: res.data.total_entrada,
            total_saida: res.data.total_saida,
            total_fiado: res.data.total_fiado,
            total_saldo_inicial: res.data.total_saldo_inicial,
            total_vendas: res.data.total_vendas,
            total_resumo: res.data.total_resumo,
            total_reforco: res.data.total_reforco,
            total_recebimentos: res.data.total_recebimentos,
            total_fiado: res.data.total_fiado,
            total_pagamentos: res.data.total_pagamentos,
            total_sangria: res.data.total_sangria,
            total_saldo_final: res.data.total_saldo_final,
            total_saldo_final_operador: res.data.total_saldo_final_operador,
            total_compras: res.data.total_compras
          });
          commit("movcaixa", res.data.movcaixa);
          Dialog.create({
            title: res.data.message,
            class: "bg-positive text-white",
            ok: {
              "text-color": "green",
              color: "white"
            }
          });
          commit("isSaldoFinal", true);
          commit("acaoCaixa", 8);
          commit("fechaCaixa", false);
          Router.push("/caixas");
        } else {
          commit("fechandoCaixa");
          Dialog.create({
            title: res.data.message,
            class: "bg-warning text-white",
            ok: {
              "text-color": "orange",
              color: "white"
            }
          });
        }
      })
      .catch(() => {
        Dialog.create({
          title:
            "Não foi possível fechar o caixa, ou você não possui permissão!",
          class: "bg-negative text-white",
          ok: {
            "text-color": "red",
            color: "white"
          }
        });
        commit("fechandoCaixa");
      });
  },
  funcionarioNome: ({ commit }, userData) => {
    commit("funcionarioNome", userData);
  },
  getFunc: ({ commit, state }) => {
    let id = User.state.dataUser.cadastro_id;
    let pessoa = {};
    state.listarFuncionarios.forEach(funcionario => {
      if (funcionario.id === id) {
        pessoa = funcionario;
      }
    });
    commit("func", pessoa);
  },
  imprimirRelatorioCaixa: ({ commit, state }, userData) => {
    commit("resumoPrinting");

    const options = {
      headers: {
        Accept: "text/html,application/json"
      }
    };

    axios
      .get("/lancamentos/caixa/" + userData.id + "?tipo=relatorio", options)
      .then(res => {
        commit("resumoPrinting");
        commit("resumoPrint", res.data);
        let openWindow = window.open("/");
        openWindow.document.write(res.data);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("resumoPrinting");
      });
  },
  imprimirRelatorioCobranca: ({ commit }, userData) => {
    commit("resumoCobrancaPrinting");
    let url =
      userData.tipo === "loterias"
        ? "/relatorios/resumo?tipo=CDL"
        : "/relatorios/resumo?tipo=CDS";

    axios
      .get(url, {
        headers: {
          Accept: "text/html"
        },
        params: {
          dtini: userData.dtini,
          dtfim: userData.dtfim
        }
      })
      .then(res => {
        let openWindow = window.open("/");
        openWindow.document.write(res.data);
        commit("OpenModalCobrancaDiaria", false);
        commit("resumoCobrancaPrinting");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("resumoCobrancaPrinting");
      });
  },
  resetDadosSangrias: ({ commit }) => {
    commit("setDadosSangrias", {
      caixa: null,
      detalharNotas: false,
      detalharMoedas: false,
      transferirCaixa: false,
      notas: {
        notas_2: 0,
        notas_5: 0,
        notas_10: 0,
        notas_20: 0,
        notas_50: 0,
        notas_100: 0,
        notas_200: 0,
      },
      moedas: {
        moedas_5: 0,
        moedas_10: 0,
        moedas_25: 0,
        moedas_50: 0,
        moedas_1: 0,
      },
    });
  },
  resetDadosReforcos: ({ commit }) => {
    commit("setDadosReforcos", {
      caixa: null,
      detalharNotas: false,
      detalharMoedas: false,
      transferirCaixa: false,
      notas: {
        notas_2: 0,
        notas_5: 0,
        notas_10: 0,
        notas_20: 0,
        notas_50: 0,
        notas_100: 0,
        notas_200: 0,
      },
      moedas: {
        moedas_5: 0,
        moedas_10: 0,
        moedas_25: 0,
        moedas_50: 0,
        moedas_1: 0,
      },
    });
  },
  resetVenda: ({ commit }) => {
    commit("venda", {
      descricao: "",
      valor: 0,
    });
    commit("movconta_vencimento", "");
  },
  openDialogVendaCaixasDetalhada: ({ commit }, vendaCaixasDetalhada = null) => {
    commit("setVendaCaixasDetalhada", vendaCaixasDetalhada);
    commit("setOpenDialogVendaCaixasDetalhada", true);
  },
  // openDialogCaixaCompraDetalhada: ({ commit, dispatch }, userData) => {
  //   commit("setDialogCaixaCompraDetalhada", true);
  //   commit("setCompraCaixasDetalhada", userData);
  //   dispatch("resetModal");
  //   dispatch("comprasProdutosItems", userData ? userData.movcompra.id : null);
  //   dispatch("getFornecedores");
  //   dispatch("produtosEstoque", {
  //     tipo: "P",
  //     all: 1,
  //     ativo: 1
  //   });
  // },
  OpenDialogRecebimentoFiadoCaixasMultiplo: ({ commit }, recebimentoFiadoCaixasMultiplo = null) => {
    commit("setRecebimentoFiadoCaixasMultiplo", recebimentoFiadoCaixasMultiplo);
    commit("OpenDialogRecebimentoFiadoCaixasMultiplo", true);
  },
  resetDadosCaixaContaPagarReceber: ({ commit }) => {
    commit("setDadosCaixaContaPagarReceber", {
      gerarNovaMovconta: false,
      novoVencimento: moment().format("DD/MM/YYYY"),
      valorRestante: 0,
      adicionarSaldoCliente: false,
    });
  },
};

const getters = {
  caixas: state => {
    return state.caixas;
  },
  caixa: state => {
    return state.caixa;
  },
  acaoCaixa: state => {
    return state.acaoCaixa;
  },
  venda: state => {
    return state.venda;
  },
  lobbyCaixas: state => {
    return state.lobbyCaixas;
  },
  fechaCaixa: state => {
    return state.fechaCaixa;
  },
  valoresTotais: state => {
    return state.valoresTotais;
  },
  cadastroReturn: state => {
    return state.cadastroReturn;
  },
  movcontaReturn: state => {
    return state.movcontaReturn;
  },
  isSaldoInicial: state => {
    return state.isSaldoInicial;
  },
  addItemCaixa: state => {
    return state.addItemCaixa;
  },
  addItemCaixa2: state => {
    return state.addItemCaixa2;
  },
  ordenaCima: state => {
    return state.ordenaCima;
  },
  newCaixa: state => {
    return state.newCaixa;
  },
  errorsCaixas: state => {
    return state.errorsCaixas;
  },
  loadCaixas: state => {
    return state.loadCaixas;
  },
  lancamentoCaixas: state => {
    return state.lancamentoCaixas;
  },
  lancamentoCaixasComResumo: state => {
    return state.lancamentoCaixasComResumo;
  },
  pagingCaixas: state => {
    return state.pagingCaixas;
  },
  filtrosCaixas: state => {
    return state.filtrosCaixas;
  },
  buscandoCaixas: state => {
    return state.buscandoCaixas;
  },
  infoCaixas: state => {
    return state.infoCaixas;
  },
  isSaldoFinal: state => {
    return state.isSaldoFinal;
  },
  saldoFinal: state => {
    return state.saldoFinal;
  },
  listarFuncionarios: state => {
    return state.listarFuncionarios;
  },
  novoCaixa: state => {
    return state.novoCaixa;
  },
  movcaixa: state => {
    return state.movcaixa;
  },
  tiposSaldos: state => {
    return state.tiposSaldos;
  },
  totalCaixas: state => {
    return state.totalCaixas;
  },
  totaldosCaixas: state => {
    return state.totaldosCaixas;
  },
  loadMovcaixa: state => {
    return state.loadMovcaixa;
  },
  movconta_id_caixa: state => {
    return state.movconta_id_caixa;
  },
  conta_id_caixa: state => {
    return state.conta_id_caixa;
  },
  funcionarioCaixa: state => {
    return state.funcionarioCaixa;
  },
  funcionarioNome: state => {
    return state.funcionarioNome;
  },
  func: state => {
    return state.func;
  },
  caixaId: state => {
    return state.caixaId;
  },
  isPrazo: state => {
    return state.isPrazo;
  },
  movconta_vencimento: state => {
    return state.movconta_vencimento;
  },
  isModal: state => {
    return state.isModal;
  },
  blockValor: state => {
    return state.blockValor;
  },
  resumoPrint: state => {
    return state.resumoPrint;
  },
  resumoPrinting: state => {
    return state.resumoPrinting;
  },
  fechandoCaixa: state => {
    return state.fechandoCaixa;
  },
  resumoCobrancaPrinting: state => {
    return state.resumoCobrancaPrinting;
  },
  imgOCRId: state => {
    return state.imgOCRId;
  },
  movcaixacategoria: state => {
    return state.movcaixacategoria;
  },
  OpenDialogNovoCaixa: state => {
    return state.OpenDialogNovoCaixa;
  },
  OpenDialogNovoLancamentoCaixa: state => {
    return state.OpenDialogNovoLancamentoCaixa;
  },
  OpenDialogAlterarIntroducaoCaixas: state => {
    return state.OpenDialogAlterarIntroducaoCaixas;
  },
  OpenModalCobrancaDiaria: state => {
    return state.OpenModalCobrancaDiaria;
  },
  OpenDialogHelpCaixas: state => {
    return state.OpenDialogHelpCaixas;
  },
  OpenDialogUploadResumoCaixas: state => {
    return state.OpenDialogUploadResumoCaixas;
  },
  OpenDialogSaldoInicialCaixas: state => {
    return state.OpenDialogSaldoInicialCaixas;
  },
  OpenDialogRecebimentoFiadoCaixas: state => {
    return state.OpenDialogRecebimentoFiadoCaixas;
  },
  OpenDialogQuitacaoContasCaixas: state => {
    return state.OpenDialogQuitacaoContasCaixas;
  },
  OpenDialogReforcoCaixas: state => {
    return state.OpenDialogReforcoCaixas;
  },
  OpenDialogSaldoFinalCaixas: state => {
    return state.OpenDialogSaldoFinalCaixas;
  },
  OpenDialogSangriaCaixas: state => {
    return state.OpenDialogSangriaCaixas;
  },
  OpenDialogVendaCaixas: state => {
    return state.OpenDialogVendaCaixas;
  },
  OpenDialogComprasProduto: state => {
    return state.OpenDialogComprasProduto;
  },
  dialogCaixaCompraDetalhada: state => {
    return state.dialogCaixaCompraDetalhada;
  },
  isReadOnlyCaixa: state => {
    return state.isReadOnlyCaixa;
  },
  isPermissoCaixas: state => {
    return state.isPermissoCaixas;
  },
  salvareNovo: state => {
    return state.salvareNovo;
  },
  salvandoVendaCaixa: state => {
    return state.salvandoVendaCaixa;
  },
  dadosCaixaContaPagarReceber: state => {
    return state.dadosCaixaContaPagarReceber;
  },
  dadosSangrias: state => {
    return state.dadosSangrias;
  },
  dadosReforcos: state => {
    return state.dadosReforcos;
  },
  opcoesMovcaixas: state => {
    return state.opcoesMovcaixas;
  },
  opcoesEstoque: state => {
    return state.opcoesEstoque;
  },
  openDialogVendaCaixasDetalhada: state => {
    return state.openDialogVendaCaixasDetalhada;
  },
  OpenDialogRecebimentoFiadoCaixasMultiplo: state => {
    return state.OpenDialogRecebimentoFiadoCaixasMultiplo;
  },
  recebimentoFiadoCaixasMultiplo: state => {
    return state.recebimentoFiadoCaixasMultiplo;
  },
  vendaCaixasDetalhada: state => {
    return state.vendaCaixasDetalhada;
  },
  temPermissaoVer: state => {
    return state.temPermissaoVer;
  },
  podeTirarFoto: state => {
    return state.podeTirarFoto;
  },
  gatewayPixCaixa: state => {
    return state.gatewayPixCaixa;
  },
  compraCaixasDetalhada: state => {
    return state.compraCaixasDetalhada;
  },
  metadadosCaixas: state => {
    return state.metadadosCaixas;
  },
  permissoesCaixas: state => {
    return state.permissoesCaixas;
  },
  getIsCardView: state => {
    return state.isCardView;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
