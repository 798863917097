<template>
  <div id="cardGrafEmail">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-h6">
            <q-icon
              style="position:relative; top: -3px"
              :name="nomeIcone"
              size="xs"
            />&nbsp;
            {{item.titulo}}
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section
      style="width: 90%;"
      class="absolute-center"
    >
      <p class="text-center q-mb-none">Você não possui permissão para visualizar este cartão</p>
    </q-card-section>
  </div>
</template>

<script>
export default {
  name: "card-sem-permissao",
  props: [
    "item",
  ],
  methods: {
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: this.item.i,
      });
    },
  },
  computed: {
    nomeIcone () {
      switch(this.item.i) {
        case '0':
          return 'show_chart';
        case '1':
          return 'insert_chart';
        case '2':
          return 'trending_up';
        case '3':
          return 'playlist_add';
        case '4':
          return 'trending_down';
        case '5':
          return 'developer_board';
        case '6':
          return 'playlist_add_check';
        case '7':
          return 'equalizer';
        case '8':
          return 'contact_mail';
        case '9':
          return 'payment';
        case '10':
          return 'fa fa-archive';
        case '11':
          return 'fa fa-building';
        default:
          return '';
      }
    },
  },
};
</script>
