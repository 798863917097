import axios from "@/axios-auth";
import { Notify } from "quasar";

const state = {
  timelines: [],
  pagingTimeline: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: false,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  timeline: {
    loadingTimeline: false,
    groups: null,
    tipos: null
  },
  filtersTimeline: {
    group: null,
    userId: null,
    dtini: null,
    dtfim: null
  },
  OpenDrawerTimelineGlobal: false,
  OpenDrawerTimelineObs: false,
  isTimelineGlobal: false,
  isTimelineCadastro: {
    cadastro: false,
    routeId: "",
  },
};

const mutations = {
  isTimelineCadastro: (state, userData) => {
    state.isTimelineCadastro = userData;
  },
  isTimelineGlobal: (state, userData) => {
    state.isTimelineGlobal = userData;
  },
  OpenDrawerTimelineObs: (state, userData) => {
    state.OpenDrawerTimelineObs = userData;
  },
  timelines: (state, userData) => {
    state.timelines = userData.timelines;
  },
  pagingTimeline: (state, userData) => {
    state.pagingTimeline = { ...userData };
  },
  timelineAction: (state, userData) => {
    state.timeline.groups = userData.groups;
    state.timeline.loadingTimeline = userData.loadingTimeline;
    state.timeline.tipos = userData.tipos;
  },
  filtersTimeline: (state, userData) => {
    state.filtersTimeline.group = userData.group;
    state.filtersTimeline.userId = userData.userId;
    state.filtersTimeline.dtini = userData.dtini;
    state.filtersTimeline.dtfim = userData.dtfim;
  },
  OpenDrawerTimelineGlobal: (state, userData) => {
    state.OpenDrawerTimelineGlobal = userData;
  }
};

const actions = {
  isTimeGlobal: ({commit}, userData) => {
    commit("isTimelineGlobal", userData);
  },
  openObs: ({commit}, userData) => {
    commit("OpenDrawerTimelineObs", userData);
  },
  fetchTimelines: ({ commit }, userData) => {
    let page = userData ?? 1;

    // Caso o userData seja diferente de null, significa que está carregando
    // mais timelines na paginação
    if (!userData) {
      commit("timelines", { timelines: [] });
      commit("pagingTimeline", {
        finder: null,
        page: 0,
        current: 0,
        count: 0,
        perPage: 0,
        prevPage: false,
        nextPage: false,
        pageCount: 0,
        sort: null,
        direction: false,
        limit: null,
        sortDefault: false,
        directionDefault: false,
        scope: null
      });
    }

    commit("timelineAction", { loadingTimeline: true });
    const params = {
      page,
      group: state.filtersTimeline.group ?? undefined,
      user_id: state.filtersTimeline.userId ?? undefined,
      dtini: state.filtersTimeline.dtini.replace(/\//g, '-'),
      dtfim: state.filtersTimeline.dtfim.replace(/\//g, '-')
    };

    axios
      .get("/timelines", { params })
      .then(res => {
        commit("timelineAction", { loadingTimeline: false });
        if (res.data.success) {
          commit("timelineAction", { 
            groups: res.data.groups,
            tipos: res.data.tipos
          });
          commit("timelines", { timelines: state.timelines.concat(res.data.timelines) });
          commit("pagingTimeline", { ...res.data.paging });
          commit("OpenDrawerTimelineGlobal", true);
          return;
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
    });
  }
};

const getters = {
  timelines: state => {
    return state.timelines;
  },
  pagingTimeline: state => {
    return state.pagingTimeline;
  },
  timelineAction: state => {
    return state.timeline;
  },
  filtersTimeline: state => {
    return state.filtersTimeline;
  },
  OpenDrawerTimelineGlobal: state => {
    return state.OpenDrawerTimelineGlobal;
  },
  OpenDrawerTimelineObs: state => {
    return state.OpenDrawerTimelineObs;
  },
  isTimelineGlobal: state => {
    return state.isTimelineGlobal;
  },
  isTimelineCadastro: state => {
    return state.isTimelineCadastro;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
