import axios from "@/axios-auth";
import { Notify } from "quasar";
import moment from "moment";

const state = {
  isPermissoNotaFiscal: true,
  notasFiscais: [],
  statusNotaList: [],
  totaisStatusNotaList: {},
  loadingNotasFiscais: false,
  buscarPorEmissaoNotasFiscais: 0,
  opcoesNotaFiscal: {},
  openDialogOpcoesNotaFiscal: false,
  pagingNotasFiscais: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null,
  },
  timelineNotasFiscais: [],
  metadadosNotasFiscais: {
    valor_total: 0.00,
  },
  pagingTimeNotasFiscais: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  notaFiscal: {},
  timelineActionNotasFiscais: {},
  OpenDialogDetalharNotaFiscal: false,
  OpenDialogEditarNotaFiscal: false,
  allNotasFiscais: false,
  counterNotasFiscais: 0,
  filtroStatusNotaFiscal: 'all',
  filtrosNotasFiscais: {
    dtini: "",
    dtfim: "",
    status: "all",
    busca: "",
    cadastroId: null,
  },
  notasFiscaisSelecionadas: [],
  OpenDialogAlterarIntroducaoNotasFiscais: false,
  homologadosNotaFiscal: [],
};

const mutations = {
  isPermissoNotaFiscal: (state, userData) => {
    state.isPermissoNotaFiscal = userData;
  },
  notasFiscais: (state, userData) => {
    state.notasFiscais = userData;
  },
  statusNotaList: (state, userData) => {
    state.statusNotaList = userData;
  },
  loadingNotasFiscais: (state, userData) => {
    state.loadingNotasFiscais = userData;
  },
  opcoesNotaFiscal: (state, userData) => {
    state.opcoesNotaFiscal = userData;
  },
  openDialogOpcoesNotaFiscal: (state, userData) => {
    state.openDialogOpcoesNotaFiscal = userData;
  },
  pagingNotasFiscais: (state, userData) => {
    state.pagingNotasFiscais.finder = userData.finder;
    state.pagingNotasFiscais.page = userData.page;
    state.pagingNotasFiscais.current = userData.current;
    state.pagingNotasFiscais.count = userData.count;
    state.pagingNotasFiscais.perPage = userData.perPage;
    state.pagingNotasFiscais.prevPage = userData.prevPage;
    state.pagingNotasFiscais.nextPage = userData.nextPage;
    state.pagingNotasFiscais.pageCount = userData.pageCount;
    state.pagingNotasFiscais.sort = userData.sort;
    state.pagingNotasFiscais.direction = userData.direction;
    state.pagingNotasFiscais.limit = userData.limit;
    state.pagingNotasFiscais.sortDefault = userData.sortDefault;
    state.pagingNotasFiscais.directionDefault = userData.directionDefault;
    state.pagingNotasFiscais.scope = userData.scope;
  },
  timelineNotasFiscais: (state, userData) => {
    state.timelineNotasFiscais = userData.timelineNotasFiscais;
    state.timelineActionNotasFiscais = userData.timelineAction;
  },
  pagingTimeNotasFiscais: (state, userData) => {
    state.pagingTimeNotasFiscais.finder = userData.finder;
    state.pagingTimeNotasFiscais.page = userData.page;
    state.pagingTimeNotasFiscais.current = userData.current;
    state.pagingTimeNotasFiscais.count = userData.count;
    state.pagingTimeNotasFiscais.perPage = userData.perPage;
    state.pagingTimeNotasFiscais.prevPage = userData.prevPage;
    state.pagingTimeNotasFiscais.nextPage = userData.nextPage;
    state.pagingTimeNotasFiscais.pageCount = userData.pageCount;
    state.pagingTimeNotasFiscais.sort = userData.sort;
    state.pagingTimeNotasFiscais.direction = userData.direction;
    state.pagingTimeNotasFiscais.limit = userData.limit;
    state.pagingTimeNotasFiscais.sortDefault = userData.sortDefault;
    state.pagingTimeNotasFiscais.directionDefault = userData.directionDefault;
    state.pagingTimeNotasFiscais.scope = userData.scope;
  },
  OpenDialogDetalharNotaFiscal: (state, userData) => {
    state.OpenDialogDetalharNotaFiscal = userData;
  },
  setOpenDialogEditarNotaFiscal: (state, userData) => {
    state.OpenDialogEditarNotaFiscal = userData;
  },
  notaFiscal: (state, userData) => {
    state.notaFiscal = userData;
  },
  allNotasFiscais: (state, userData) => {
    state.allNotasFiscais = userData;
  },
  notasFiscaisSelecionadas: (state, userData) => {
    state.notasFiscaisSelecionadas = userData;
  },
  counterNotasFiscais: (state, userData) => {
    state.counterNotasFiscais = userData.counterNotasFiscais;
  },
  filtroStatusNotaFiscal: (state, userData) => {
    state.filtroStatusNotaFiscal = userData.filtroStatusNotaFiscal;
  },
  filtrosNotasFiscais: (state, userData) => {
    state.filtrosNotasFiscais = userData;
  },
  OpenDialogAlterarIntroducaoNotasFiscais: (state, userData) => {
    state.OpenDialogAlterarIntroducaoNotasFiscais = userData;
  },
  homologadosNotaFiscal: (state, userData) => {
    state.homologadosNotaFiscal = userData;
  },
  setMetadadosNotasFiscais: (state, userData) => {
    state.metadadosNotasFiscais = userData;
  },
  setTotaisStatusNotaList: (state, userData) => {
    state.totaisStatusNotaList = userData;
  },
  setBuscarPorEmissaoNotasFiscais: (state, userData) => {
    state.buscarPorEmissaoNotasFiscais = userData;
  },
};

const actions = {
  loadNotasFiscais: ({ commit, state }) => {
    if (state.loadingNotasFiscais) {
      return;
    }

    if (!state.pagingNotasFiscais.nextPage) {
      return;
    }

    let page = state.pagingNotasFiscais.page + 1;

    commit("pagingNotasFiscais", {
      page: page,
    });
    commit('loadingNotasFiscais', true);

    axios.get("/notas-fiscais", {
      params: {
        page: state.pagingNotasFiscais.page,
        dtini: state.filtrosNotasFiscais.dtini,
        dtfim: state.filtrosNotasFiscais.dtfim,
        status: state.filtrosNotasFiscais.status,
        busca: state.filtrosNotasFiscais.busca,
        cadastro_id: state.filtrosNotasFiscais.cadastroId,
        dtemissao: state.buscarPorEmissaoNotasFiscais,
      },
    })
      .then(res => {
        commit('loadingNotasFiscais', false);

        if (!res.data.success) {
          throw new Error();
        }

        commit("filtrosNotasFiscais", {
          dtini: res.data.filters.dtini,
          dtfim: res.data.filters.dtfim,
          status: res.data.filters.status,
          busca: res.data.filters.busca,
          cadastroId: res.data.filters.cadastro_id,
      });

        commit("pagingNotasFiscais", {
          finder: res.data.paging.finder,
          page: res.data.paging.page,
          current: res.data.paging.current,
          count: res.data.paging.count,
          perPage: res.data.paging.perPage,
          prevPage: res.data.paging.prevPage,
          nextPage: res.data.paging.nextPage,
          pageCount: res.data.paging.pageCount,
          sort: res.data.paging.sort,
          direction: res.data.paging.direction,
          limit: res.data.paging.limit,
          sortDefault: res.data.paging.sortDefault,
          directionDefault: res.data.paging.directionDefault,
          scope: res.data.paging.scope,
        });
        commit("notasFiscais", state.notasFiscais.concat(res.data.notasFiscais));
        commit("statusNotaList", res.data.statusNotaList);
        commit("setTotaisStatusNotaList", res.data.totaisStatusNotaList);
        commit("setMetadadosNotasFiscais", res.data.meta);
      })
      .catch(() => {
        Notify.create({
          message: 'Houve um erro ao listar as notas',
          color: "red",
          position: "top-right"
        });

        commit('isPermissoNotaFiscal', false);
      });
  },
  loadOpcoesNotaFiscal: ({ commit, dispatch, state }) => {

    if(!state.isPermissoNotaFiscal) return false;

    axios
      .get("/notas-fiscais/opcoes")
      .then(res => {
        if (!res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right",
          });
          return;
        }

        commit("homologadosNotaFiscal", res.data.homologados);
        commit("opcoesNotaFiscal", res.data.opcoes);
        dispatch("openDialogOpcoesNotaFiscal", true);
      })
      .catch(() => {
        Notify.create({
          message: "Houve um erro ao carregar as opções de notas fiscais",
          color: "red",
          position: "top-right",
        });
      });
  },
  openDialogOpcoesNotaFiscal: ({ commit }, userData) => {
    commit("openDialogOpcoesNotaFiscal", Boolean(userData));
  },
  resetNotasFiscais: ({ commit }) => {
    commit("pagingNotasFiscais", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });
    commit("notasFiscais", []);
    commit("notasFiscaisSelecionadas", []);
    commit("counterNotasFiscais", {
      counterNotasFiscais: 0,
    });
  },
  detalharNotasFiscais: ({ commit, dispatch }, userData) => {
    dispatch("timelineNotasFiscaisClear");
    commit("notaFiscal", userData);
    commit("buscandoItems", {
      buscandoItems: true
    });

    axios
      .get("/notas-fiscais/view/" + userData.id)
      .then(res => {
        if (res.data.success) {
          commit("notaFiscal", res.data.notaFiscal);
          dispatch("pagingTimeNotasFiscais", userData);
        }
        commit("buscandoItems", {
          buscandoItems: false,
        });
        commit("OpenDialogDetalharNotaFiscal", true);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível detalhar, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("buscandoItems", {
          buscandoItems: false
        });
      });
  },
  editarNotasFiscais: ({ commit }, userData) => {
    commit("notaFiscal", userData);
    commit("setOpenDialogEditarNotaFiscal", true);
  },
  pagingTimeNotasFiscais: ({ commit }, userData) => {
    axios.get("/timelines?nota_fiscal_id=" + userData.id)
      .then(res => {
        if (res.data.success) {
          commit("timelineNotasFiscais", {
            timelineNotasFiscais: res.data.timelines,
            timelineAction: res.data.tipos
          });
          commit("pagingTimeNotasFiscais", {
            finder: res.data.paging.finder,
            page: res.data.paging.page,
            current: res.data.paging.current,
            count: res.data.paging.count,
            perPage: res.data.paging.perPage,
            prevPage: res.data.paging.prevPage,
            nextPage: res.data.paging.nextPage,
            pageCount: res.data.paging.pageCount,
            sort: res.data.paging.sort,
            direction: res.data.paging.direction,
            limit: res.data.paging.limit,
            sortDefault: res.data.paging.sortDefault,
            directionDefault: res.data.paging.directionDefault,
            scope: res.data.paging.scope
          });
        }
      });
  },
  loadMoreNotasFiscais: ({ commit, dispatch, state }) => {
    if (!state.pagingTimeNotasFiscais.nextPage) {
      commit("btnDisabled", {
        btnDisabled: true,
      });
      return;
    }
    commit("pagingTimeNotasFiscais", {
      ...state.pagingTimeNotasFiscais,
      page: state.pagingTimeNotasFiscais.page + 1,
    });

    dispatch("getTimelineNotasFiscais");
  },
  getTimelineNotasFiscais: ({ commit, state }) => {
    if (state.pagingNotasFiscais.page === 0) {
      commit("timelineNotasFiscais", {
        timelineNotasFiscais: []
      });
    }
    axios
      .get("/timelines", {
        params: {
          page: state.pagingTimeNotasFiscais.page,
          nota_fiscal_id: state.notaFiscal.id,
        },
      })
      .then(res => {
        if (res.data.success) {
          let time = res.data.timelines;
          commit("timelineNotasFiscais", {
            timelineNotasFiscais: state.timelineNotasFiscais.concat(time),
            timelineAction: res.data.tipos,
          });
          commit("pagingTimeNotasFiscais", {
            finder: res.data.paging.finder,
            page: res.data.paging.page,
            current: res.data.paging.current,
            count: res.data.paging.count,
            perPage: res.data.paging.perPage,
            prevPage: res.data.paging.prevPage,
            nextPage: res.data.paging.nextPage,
            pageCount: res.data.paging.pageCount,
            sort: res.data.paging.sort,
            direction: res.data.paging.direction,
            limit: res.data.paging.limit,
            sortDefault: res.data.paging.sortDefault,
            directionDefault: res.data.paging.directionDefault,
            scope: res.data.paging.scope
          });
        }
      });
  },
  timelineNotasFiscaisClear: ({ commit }) => {
    commit("timelineNotasFiscais", {
      timelineNotasFiscais: []
    });
  },
  setAllNotasFiscais: ({ commit, state }) => {
    commit("allNotasFiscais", !(state.allNotasFiscais));
    commit("counterNotasFiscais", {
      counterNotasFiscais: state.pagingNotasFiscais.count
    });
  },
  changeFilterPeriodoNotaFiscal: ({ commit, dispatch, state }, userData) => {
    if (state.loadingNotasFiscais) return;

    let dtini = state.filtrosNotasFiscais.dtini.split("-");
    let dtfim = state.filtrosNotasFiscais.dtfim.split("-");

    let i = moment(new Date(dtini[2], dtini[1] - 1, dtini[0]));
    let f = moment(new Date(dtfim[2], dtfim[1] - 1, dtfim[0]));

    if (userData === "prev") {
      i.subtract(1, "months");
      f.subtract(1, "months");
    }

    if (userData === "next") {
      i.add(1, "months");
      f.add(1, "months");
    }

    commit("filtrosNotasFiscais", {
      dtini: i.startOf("month").format("DD-MM-YYYY"),
      dtfim: f.endOf("month").format("DD-MM-YYYY")
    });

    dispatch("resetNotasFiscais");
    dispatch("loadNotasFiscais");
  },
  changeFilterStatusNotaFiscal: ({ commit, dispatch, state }, userData) => {
    if (state.loadingNotasFiscais) return;

    commit("filtroStatusNotaFiscal", {
      filtroStatusNotaFiscal: userData.status
    });

    commit("filtrosNotasFiscais", {
      status: userData.status,
      dtini: state.filtrosNotasFiscais.dtini,
      dtfim: state.filtrosNotasFiscais.dtfim,
      busca: userData.busca,
      cadastroId: state.filtrosNotasFiscais.cadastroId,
    });

    dispatch("resetNotasFiscais");
    dispatch("loadNotasFiscais");
  },
  filtrosNotasFiscais: ({ commit, dispatch, state }, userData) => {
    if (userData) {
      commit("filtrosNotasFiscais", {
        dtini: userData.dtini,
        dtfim: userData.dtfim,
        status: state.filtrosNotasFiscais.status,
        busca: state.filtrosNotasFiscais.busca,
        cadastroId: state.filtrosNotasFiscais.cadastroId,
      });
    } else {
      commit("filtrosNotasFiscais", {
        dtini: state.filtrosNotasFiscais.dtini,
        dtfim: state.filtrosNotasFiscais.dtfim,
        status: state.filtrosNotasFiscais.status,
        busca: state.filtrosNotasFiscais.busca,
        cadastroId: state.filtrosNotasFiscais.cadastroId,
      });

    }

    dispatch("novaNotaSelecionada", []);
    dispatch("resetNotasFiscais");
    dispatch("loadNotasFiscais");
  },
  changeFilterPeriodoNotasFiscais: ({ commit, dispatch, state }, userData) => {
    if (state.loadingNotasFiscais) return;

    let dtini = state.filtrosNotasFiscais.dtini.split("-");
    let dtfim = state.filtrosNotasFiscais.dtfim.split("-");

    let i = moment(new Date(dtini[2], dtini[1] - 1, dtini[0]));
    let f = moment(new Date(dtfim[2], dtfim[1] - 1, dtfim[0]));

    if (userData === "prev") {
      i.subtract(1, "months");
      f.subtract(1, "months");
    }

    if (userData === "next") {
      i.add(1, "months");
      f.add(1, "months");
    }

    commit("filtrosNotasFiscais", {
      status: state.filtrosNotasFiscais.status,
      dtini: i.startOf("month").format("DD-MM-YYYY"),
      dtfim: f.endOf("month").format("DD-MM-YYYY"),
      busca: state.filtrosNotasFiscais.busca,
      cadastroId: state.filtrosNotasFiscais.cadastroId,
    });

    dispatch("novaNotaSelecionada", []);
    dispatch("resetNotasFiscais");
    dispatch("loadNotasFiscais");
  },
  novaNotaSelecionada: ({ commit }, userData) => {
    commit("notasFiscaisSelecionadas", userData);
  },
};

const getters = {
  isPermissoNotaFiscal: state => {
    return state.isPermissoNotaFiscal;
  },
  notasFiscais: state => {
    return state.notasFiscais;
  },
  statusNotaList: state => {
    return state.statusNotaList;
  },
  loadingNotasFiscais: state => {
    return state.loadingNotasFiscais;
  },
  opcoesNotaFiscal: state => {
    return state.opcoesNotaFiscal;
  },
  openDialogOpcoesNotaFiscal: state => {
    return state.openDialogOpcoesNotaFiscal;
  },
  pagingNotasFiscais: state => {
    return state.pagingNotasFiscais;
  },
  timelineNotasFiscais: state => {
    return state.timelineNotasFiscais;
  },
  pagingTimeNotasFiscais: state => {
    return state.pagingTimeNotasFiscais;
  },
  OpenDialogDetalharNotaFiscal: state => {
    return state.OpenDialogDetalharNotaFiscal;
  },
  OpenDialogEditarNotaFiscal: state => {
    return state.OpenDialogEditarNotaFiscal;
  },
  notaFiscal: state => {
    return state.notaFiscal;
  },
  timelineActionNotasFiscais: state => {
    return state.timelineActionNotasFiscais;
  },
  contadorNotasFiscais: state => {
    return state.contadorNotasFiscais;
  },
  counterNotasFiscais: state => {
    return state.counterNotasFiscais;
  },
  filtroStatusNotaFiscal: state => {
    return state.filtroStatusNotaFiscal;
  },
  filtrosNotasFiscais: state => {
    return state.filtrosNotasFiscais;
  },
  allNotasFiscais: state => {
    return state.allNotasFiscais;
  },
  notasFiscaisSelecionadas: state => {
    return state.notasFiscaisSelecionadas;
  },
  OpenDialogAlterarIntroducaoNotasFiscais: state => {
    return state.OpenDialogAlterarIntroducaoNotasFiscais;
  },
  homologadosNotaFiscal: state => {
    return state.homologadosNotaFiscal;
  },
  metadadosNotasFiscais: state => {
    return state.metadadosNotasFiscais;
  },
  totaisStatusNotaList: state => {
    return state.totaisStatusNotaList;
  },
  buscarPorEmissaoNotasFiscais: state => {
    return state.buscarPorEmissaoNotasFiscais;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
