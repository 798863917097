import axios from "axios";
import { Notify } from "quasar";

const state = {
  enviar: {
    loadAlterar: false,
    primeiraEtapa: true,
    segundaEtapa: false,
    message: ""
  },
};

const mutations = {
  enviar: (state, userData) => {
    state.enviar.loadAlterar = userData.loadAlterar;
    state.enviar.primeiraEtapa = userData.primeiraEtapa;
    state.enviar.segundaEtapa = userData.segundaEtapa;
    state.enviar.message = userData.message;
  },
};

const actions = {
  enviar: ({ commit }, userData) => {
    commit("enviar", {
      loadAlterar: true,
      primeiraEtapa: true,
      segundaEtapa: false
    });
    axios
      .post("/users/alterar-senha", userData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.data.success) {
          commit("enviar", {
            primeiraEtapa: false,
            segundaEtapa: true,
            message: res.data.message,
            loadAlterar: false
          });
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });
        } else {
          commit("enviar", {
            message: res.data.message,
            primeiraEtapa: true,
            segundaEtapa: false,
            loadAlterar: false
          });
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
      })
      .catch(() => {
        Notify.create({
          message: "Ocorreu algum erro. Atualize a página e tente novamente.",
          color: "red",
          position: "top-right"
        });
      });
  },
};

const getters = {
  enviar: state => {
    return state.enviar;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
