<template>
  <div id="modalCompraCaixa">
    <q-dialog v-model="OpenDialogComprasProduto" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Compra</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModel()"
          />
        </q-card-section>
        <q-separator />
        <q-card-section style="padding-bottom: 0">
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12">
                <autocomplete
                  title="Fornecedor:"
                  :filter="filtroCadastros"
                  :carregando="carregando"
                  :options="options"
                  :formatDisplay="formatDisplay"
                  :create="createValue"
                  @resultAutocomplete="resultEmitRecebe"
                  :display="displayMontar"
                  tipoConsulta="cadastro"
                  :cadastro="abreCadastro"
                  :newCadastro="cadastroReturn"
                  :pularProx="() => proximo('autocomplete')"
                  :pularProxChama="true"
                />
              </div>
              <div class="col-12">
                <q-input
                  @keydown.enter.prevent="proximo('valor')"
                  v-model="compra.nome"
                  label="Descrição"
                  bottom-slots
                  ref="descricao"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.nome" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12" @keyup.enter.prevent="proximo('salvar')">
                <q-field
                  v-model="compra.valor"
                  id="valorCaixa"
                  label="Valor"
                  bottom-slots
                  :error="errors.valor ? true : false"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      name="valorCaixa"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.valor" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-field>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            @click="lancarCompra(true)"
            label="Salvar & Novo"
            clickable
            class="bg-grey-6 text-white"
            style="margin-right: 10px; margin-bottom: 10px"
            id="salvarNovo"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
          <q-btn
            flat
            @click="lancarCompra()"
            label="Salvar"
            clickable
            class="bg-green text-white"
            style="margin-right: 22px; margin-bottom: 10px"
            id="salvar"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
import { Money } from "v-money";
import { Notify } from "quasar";

export default {
  components: { Money },
  name: "modal-compras",
  data() {
    return {
      fornecedoresFilter: [],
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      compra: this.compraObject(),
      errors: [],
      // autocomplete
      carregando: false,
      autocompleteResult: null,
      options: [],
      saving: false
    };
  },
  methods: {
    proximo(val) {
      if (val === "autocomplete") {
        this.$refs.descricao.focus();
      }
      if (val === "valor") {
        document.getElementsByName("valorCaixa")[0].focus();
      }
      if (val === "salvar") {
        this.lancarCompra(true);
      }
    },
    compraObject() {
      return {
        tipo: 10,
        fornecedor: null,
        nome: null,
        valor: 0
      };
    },

    atualizarListaLancamentos(res, novo) {
      let caixa = this.caixa;
      let lancamentos = res.data.movcaixasaldo;

      caixa.unshift(lancamentos);

      this.$store.commit("caixa", caixa);
      this.$store.commit("totaldosCaixas", {
        total: res.data.total,
        total_entrada: res.data.total_entrada,
        total_saida: res.data.total_saida,
        total_fiado: res.data.total_fiado,
        total_saldo_inicial: res.data.total_saldo_inicial,
        total_vendas: res.data.total_vendas,
        total_resumo: res.data.total_resumo,
        total_reforco: res.data.total_reforco,
        total_recebimentos: res.data.total_recebimentos,
        total_fiado: res.data.total_fiado,
        total_pagamentos: res.data.total_pagamentos,
        total_sangria: res.data.total_sangria,
        total_saldo_final: res.data.total_saldo_final,
        total_saldo_final_operador: res.data.total_saldo_final_operador,
        total_compras: res.data.total_compras
      });

      this.clear(novo);
    },
    clear(novo = false) {
      if (!novo) this.$store.commit("OpenDialogComprasProduto", false);
      this.compra = this.compraObject();
      this.autocompleteResult = null;
      this.$root.$emit("limpar"); // $root acessar a instancia raiz, ou seja, consigo acessar componente autocomplete
      this.errors = [];
    },
    closeModel() {
      this.clear();
    },
    lancarCompra(novo = false) {
      let caixa = this.$route.params.id;

      if (this.saving) {
        return;
      }

      this.saving = true;

      if (
        this.autocompleteResult &&
        this.autocompleteResult.id == null &&
        this.autocompleteResult.name != null
      ) {
        let cadastro = {
          nome: this.autocompleteResult.name,
          cliente: true,
          fornecedor: true,
        };
        axios
          .post("/cadastros/add", cadastro)
          .then(res => {
            if (res.data.success) {
              Notify.create({
                message: "Cadastro salvo com sucesso !",
                color: "green",
                position: "top-right"
              });
            }
          })
          .catch(() => {
            Notify.create({
              message:
                "Não foi possível contactar, ou você não possui permissão!",
              color: "red",
              position: "top-right"
            });
          });
      }

      if(this.autocompleteResult && this.autocompleteResult.id){
        this.compra.fornecedor = this.autocompleteResult.id;
      }

      axios
        .post("/lancamentos/add-saldo/" + caixa, this.compra)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            this.atualizarListaLancamentos(res, novo);
          } else {
            if (res.data.errors) {
              this.errors = res.data.errors;
            }
          }
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });

      setTimeout(() => {
        this.saving = false;
      }, 500);
    },
    // Funções do autocomplete
    filtroCadastros(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/cadastros/busca?term=" + val).then(res => {
          this.carregando = false;
          this.options = res.data;
        });
      });
    },
    createValue(val, done) {
      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
    },
    formatDisplay(result) {
      return result.label;
    },
    resultEmitRecebe(result) {
      this.compra.nome = "Compra: " + result.name;
      this.autocompleteResult = result;
    },
    displayMontar(value) {
      return !value ? value : value.name;
    },
    abreCadastro(value) {
      this.$store.dispatch("cadastro", {
        nome: value.name,
        noSearch: true
      });
    }
  },
  computed: {
    ...mapGetters([
      "cadastroReturn",
      "cadastro",
      "OpenDialogComprasProduto",
      "caixa"
    ]),
    OpenDialogComprasProduto: {
      get() {
        return this.$store.getters.OpenDialogComprasProduto;
      },
      set() {}
    }
  }
};
</script>
