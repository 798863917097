<template>
  <div id="despesas">
    <q-card white bordered class="q-mb-md">
      <q-card-section @click="qdespesas = !qdespesas">
        <div class="text-h6 cursor-pointer">
          <q-btn
            dense
            flat
            no-wrap
            v-ripple
            round
            icon="add"
            no-caps
            class="q-ml-sm q-mr-md bg-white text-blue-7"
            clickable
            size="11px"
            @click.stop="novo()"
            :loading="loading"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>Despesas
        </div>
        <q-btn
          v-if="qdespesas"
          flat
          dense
          no-wrap
          v-ripple
          no-caps
          clickable
          color="primary"
          class="q-mr-md absolute-top-right"
          label="Despesas Recorrentes"
          :to="`/despesas-recorrentes?cadastro_id=${cadastro.id}`"
          style="margin: 15px 75px 0 0"
        />
        <q-btn
          dense
          flat
          no-wrap
          v-ripple
          round
          color="black"
          :icon="qdespesas ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          no-caps
          class="q-ml-sm bg-white text-black absolute-top-right"
          style="margin: 15px 35px 0 0"
          clickable
        >
        </q-btn>
      </q-card-section>
      <q-card-section v-if="qdespesas">
        <q-table
          title
          :data="despesas"
          :columns="columns"
          row-key="id"
          :separator="'horizontal'"
          :loading="buscando"
          :pagination.sync="pagination"
          no-data-label="Nenhum resultado encontrado."
         >
          <template v-slot:bottom>
          </template>
          <template v-slot:body="props">
            <q-tr @click="props.expand = !props.expand && $q.platform.is.mobile">
              <q-td key="id" class="text-left">{{ props.row.id }}</q-td>
              <q-td key="descricao">
                <template v-slot:default>
                  <q-badge v-if="props.row.status == 'Aberto'" :color="new Date(props.row.vencimento) > new Date() ? 'blue' : 'red'">Aberto</q-badge>
                  <q-badge v-if="props.row.status == 'Liquidado'" color="green">Liquidado</q-badge>
                  <q-badge v-if="props.row.status == 'Cancelado'" color="orange">Cancelado</q-badge>
                  {{
                    props.row.descricao.length > 40
                      ? props.row.descricao.substr(0, 40) + "..."
                      : props.row.descricao
                  }}
                  <br v-if="props.row.conta">
                  {{ props.row.conta ? props.row.conta.titulo : '' }}
                  <q-tooltip
                    v-if="
                      props.row.descricao && props.row.descricao.length > 40
                    "
                    >{{ props.row.descricao }}</q-tooltip
                  >
                </template>
              </q-td>
              <q-td key="vencimento" class="text-center">{{
                props.row.vencimento | formatDate
              }}</q-td>
              <q-td key="valor" class="text-right"> R$ {{ props.row.valor | currency }} </q-td>
              <q-td key="liquidacao" class="text-center">
                {{ props.row.liquidacao ? props.row.liquidacao : ''  | formatDate}}
              </q-td>
              <q-td key="valor_liquidado" class="text-right">
               R$ {{ props.row.liquidacao ?  props.row.valor_liquidado : ''  | currency}}
              </q-td>
              <q-td v-if="$q.platform.is.desktop" class="tdfora">
                <div class="divfora">
                  <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                    <q-btn
                      @click="detalhar(props.row)"
                      class="bg-primary text-white"
                      icon="info"
                      size="12px"
                    ></q-btn>
                    <q-btn
                      v-if="props.row.status !== 'Cancelado' && !props.row.liquidado"
                      @click="liquidar(props.row)"
                      class="bg-green-7 text-white"
                      icon="attach_money"
                      size="12px"
                    ></q-btn>
                    <q-btn
                      v-if="props.row.status === 'Liquidado'"
                      @click="estornar(props.row)"
                      class="bg-secondary text-white"
                      icon="reply"
                      size="12px"
                    ></q-btn>
                    <q-btn
                      v-if="props.row.status === 'Cancelado'"
                      @click="reativa(props.row)"
                      class="bg-secondary text-white"
                      icon="reply"
                      size="12px"
                    ></q-btn>
                    <q-btn
                      @click="del(props.row)"
                      v-if="props.row.status === 'Aberto'"
                      class="bg-negative text-white"
                      icon="delete_forever"
                      size="12px"
                    ></q-btn>
                  </div>
                </div>
              </q-td>
            </q-tr>
            <q-tr v-show="props.expand">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      @click="detalhar(props.row)"
                      class="bg-primary text-white"
                      icon="info"
                      size="12px"
                    />
                    <q-btn
                      v-if="props.row.status !== 'Cancelado' && !props.row.liquidado"
                      @click="liquidar(props.row)"
                      class="bg-green-7 text-white"
                      icon="attach_money"
                      size="12px"
                    />
                    <q-btn
                      v-if="props.row.status === 'Liquidado'"
                      @click="estornar(props.row)"
                      class="bg-secondary text-white"
                      icon="reply"
                      size="12px"
                    />
                    <q-btn
                      v-if="props.row.status === 'Cancelado'"
                      @click="reativa(props.row)"
                      class="bg-secondary text-white"
                      icon="reply"
                      size="12px"
                    />
                    <q-btn
                      @click="del(props.row)"
                      v-if="props.row.status === 'Aberto'"
                      class="bg-negative text-white"
                      icon="delete_forever"
                      size="12px"
                    />
                  </div>
                </q-td>
            </q-tr>
          </template>
          <template v-slot:bottom-row>
            <q-tr style="background-color: #F7F7F7">
              <q-td> </q-td>
              <q-td> </q-td>
              <q-td> </q-td>
              <q-td class="text-right">
                <strong>R$ {{ total_valores.total_valor | currency }}</strong>
              </q-td>
              <q-td class="text-right">
              </q-td>
              <q-td key="valor_liquidado" class="text-right">
                <strong
                  >R$
                  {{ total_valores.total_valor_liquidado | currency }}</strong
                >
              </q-td>
              <q-td class="tdfora"> </q-td>
            </q-tr>
          </template>
        </q-table>
        <div class="text-center">
          <q-btn
            flat
            clickable
            @click="$router.push('/despesas?cadastro_id=' + $route.params.id)"
            no-wrap
            v-ripple
            no-caps
            label="Ir para despesas"
            class="bg-blue-7 text-white q-mr-md"
          />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";
import { bus } from "@/main";

export default {
  name: 'despesas-components',
  emits: ['changeTipo'],
  data: () => ({
    qdespesas: false,
    loading: false,
    pagination: {
      sortBy: "vencimento",
      descending: true,
      page: 1,
      rowsPerPage: 0
    },
    buscando: false,
    despesas: [],
    total_valores: {},
    status: 'Aberto',
    filtros: {
      status: "Aberto"
    },
    createMenuStatus: [
      { text: "Aberto" },
      { text: "Liquidado" },
      { text: "Cancelado" }
    ],
  }),
  watch: {
    qdespesas(value) {
      if (value){
        this.listar();
      }
    }
  },
  methods: {
    novo() {
      this.$store.commit("callDespesa", true);
      this.$store.commit("cadastroDespesa", {
        isCadastro: true,
        cadastro: {
          cadastro_id: this.cadastro.id,
          cadastro_nome: this.cadastro.nome
        }
      });
      this.$store.dispatch("planoContas");
      this.$store.dispatch("contas");
      this.$store.dispatch("listarCentroCustos");
      this.$store.commit("OpenDialogNovaDespesa", true);
    },
    listar() {
      if (this.buscando) {
        return;
      }
      this.buscando = true;
      this.total_valores = {
        total_valor: 0,
        total_valor_liquidado: 0
      };

      axios
        .get("/cadastros/despesas/" + this.$route.params.id)
        .then(res => {
          if (!res.data.success) {
            throw res.data;
          }

          this.despesas = res.data.data.despesas;
          this.total_valores = res.data.data.total_valores;
          this.buscando = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.buscando = false;
        });
    },
    liquidar (despesa) {
      despesa.isCadastro = true;
      this.$emit('changeTipo', 'despesa');
      const despesa2 = JSON.parse(JSON.stringify(despesa));
      this.$store.dispatch("liquidarDespesas", despesa2);
    },
    detalhar (despesa) {
      despesa.isCadastro = true;
      this.$store.commit('despesaQrCode', despesa.id);
      this.$store.dispatch("detalharDespesas", despesa);
    },
    estornar (despesa) {
      Dialog.create({
        title: "Confirma o estorno da despesa?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          despesa.action = "estornar";
          axios
            .post("/financeiro/edit/" + despesa.id, despesa)
            .then(res => {
              if (!res.data.success) {
                Dialog.create({
                  title: res.data.errors,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
                this.listar();
                return;
              }

              Dialog.create({
                title: "Despesa " + res.data.movconta.id + " estornada!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              this.despesas.splice(this.despesas.indexOf(despesa), 1);
              this.listar();
            })
            .catch(() => {
              Dialog.create({
                title: "Não foi possível estornar, ou não possui permissão!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    reativa (despesa) {
      Dialog.create({
        title: "Confirma a reativação da despesa?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          despesa.action = "reativar";
          axios
            .post("/financeiro/edit/" + despesa.id, despesa)
            .then(res => {
              if (res.data.status) {
                Dialog.create({
                  title: "Despesa " + res.data.movconta.id + " reativada!",
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
                this.despesas.splice(this.despesas.indexOf(despesa), 1);
                this.listar();
              } else {
                Dialog.create({
                  title: res.data.errors,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
                this.listar();
              }
            })
            .catch(() => {
              Dialog.create({
                title: "Não foi possível reativar, ou não possui permissão!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    del (despesa) {
      Dialog.create({
        title: "Tem certeza que deseja cancelar esta despesa?",
        message: "Descreva o Motivo de Cancelamento",
        cancel: "Cancelar",
        persistent: true,
        prompt: {
          model: '',
          isValid: val => val.length > 2,
        },
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk((data) => {
          despesa.motivo = data;
          axios
            .post("/financeiro/delete/" + despesa.id, despesa)
            .then(res => {
              this.listar();
              if (!res.data.success) {
                Dialog.create({
                  title: res.data.msg,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
                return;
              }

              Dialog.create({
                title: res.data.msg,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              this.listar();
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    }
  },
  computed: {
    cadastro() {
      return this.$store.getters.cadastroView;
    },
    columns() {
      let data = [
        {
            name: "id",
            required: true,
            label: "ID",
            align: "center",
            field: row => row.id,
            format: val => `${val}`,
            sortable: true
        },
        {
          name: "descricao",
          align: "center",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true
        },
        {
          name: "vencimento",
          label: "Vencimento",
          field: row => row.vencimento,
          sortable: true,
          align: "center"
        },
        {
          name: "liquidacao",
          label: "Liquidação",
          field: row => row.liquidacao,
          align: "center",
          sortable: true
        },
        {
          name: "valor_liquidado",
          label: "Valor Liquidado",
          field: row => row.liquidacao,
          align: "center",
          sortable: true
        },
        { name: 'tdfora' },
      ];

      let valor_index = data.indexOf({
        name: "valor",
        label: "Valor",
        field: row => row.valor,
        align: "center",
        sortable: true
      });

      if (this.filtros.status === 'Aberto' || this.filtros.status === 'Cancelado') {
        if (valor_index === -1) {
          data.splice(3, 0, {
            name: "valor",
            label: "Valor",
            field: row => row.valor,
            align: "center",
            sortable: true
          });
        }

        let valor_liquidado_index = data.indexOf({
          name: "valor_liquidado",
          label: "Valor Pago",
          field: row => row.valor_liquidado,
          align: "center",
          sortable: true
        });

        let liquidacao_index = data.indexOf({
          name: "liquidacao",
          label: "Liquidado Em",
          field: row => row.liquidacao,
          align: "center",
          sortable: true
        });

        if (valor_liquidado_index !== -1) {
          data.splice(valor_liquidado_index, 1);
        }

        if (liquidacao_index !== -1) {
          data.splice(liquidacao_index, 1);
        }
      } else if (this.filtros.status === 'Liquidado') {

        data.splice(3, 0, {
          name: "liquidacao",
          label: "Liquidado Em",
          field: row => row.liquidacao,
          align: "center",
          sortable: true
        });

        data.splice(4, 0, {
          name: "valor_liquidado",
          label: "Valor Pago",
          field: row => row.valor_liquidado,
          align: "center",
          sortable: true
        });

        if (valor_index !== -1) {
          data.splice(valor_index, 1);
        }
      }

      return data;
    }
  },
  created() {
    bus.$on("listarDespesas", () => {
      this.listar();
    });
  },
  destroyed(){
    bus.$off('listarDespesas');
  }
};
</script>

<style lang="stylus" scoped>
.text-h6, .text-subtitle2 {
  font-weight: 300;
}

.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
