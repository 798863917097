import axios from "@/axios-auth";
import { Notify } from "quasar";

const state = {
  openDialogNovoMapaResgates: false,
  openDialogOpcoesMapaResgates: false,
  pagingMapasResgates: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  mapaResgates: {
      mapa_resgates_items: [],
  },
  mapasResgates: [],
  loadingMapasResgates: false,
  isPermissoMapaResgates: true,
  loadingDialogNovoMapaResgates: true,
  opcoes: {},
  produtosMapaResgate: []
};

const mutations = {
  openDialogNovoMapaResgates: (state, userData) => {
    state.openDialogNovoMapaResgates = userData;
  },
  openDialogOpcoesMapaResgates: (state, userData) => {
    state.openDialogOpcoesMapaResgates = userData;
  },
  pagingMapasResgates: (state, userData) => {
    state.pagingMapasResgates.finder = userData.finder;
    state.pagingMapasResgates.page = userData.page;
    state.pagingMapasResgates.current = userData.current;
    state.pagingMapasResgates.count = userData.count;
    state.pagingMapasResgates.perPage = userData.perPage;
    state.pagingMapasResgates.prevPage = userData.prevPage;
    state.pagingMapasResgates.nextPage = userData.nextPage;
    state.pagingMapasResgates.pageCount = userData.pageCount;
    state.pagingMapasResgates.sort = userData.sort;
    state.pagingMapasResgates.direction = userData.direction;
    state.pagingMapasResgates.limit = userData.limit;
    state.pagingMapasResgates.sortDefault = userData.sortDefault;
    state.pagingMapasResgates.directionDefault = userData.directionDefault;
    state.pagingMapasResgates.scope = userData.scope;
  },
  mapaResgates: (state, userData) => {
    state.mapaResgates = userData;
  },
  mapasResgates: (state, userData) => {
    state.mapasResgates = userData;
  },
  loadingMapasResgates: (state, userData) => {
    state.loadingMapasResgates = userData;
  },
  isPermissoMapaResgates: (state, userData) => {
    state.isPermissoMapaResgates = userData;
  },
  loadingDialogNovoMapaResgates: (state, userData) => {
    state.loadingDialogNovoMapaResgates = userData;
  },
  opcoes: (state, userData) => {
    state.opcoes = userData;
  },
  produtosMapaResgate: (state, userData) => {
    state.produtosMapaResgate = userData;
  },
};

const actions = {
  openDialogNovoMapaResgates:({commit, dispatch}, userData)=>{
    commit("loadingDialogNovoMapaResgates", Boolean(userData));
    dispatch("loadMapaResgates", userData);
    commit("openDialogNovoMapaResgates", Boolean(userData));
  },
  openDialogOpcoesMapaResgates:({ commit }, userData)=>{
    commit("openDialogOpcoesMapaResgates", Boolean(userData));

    axios
      .get('/produtos', {
        params: { tipo: 'P' }
      })
      .then(res => {
        if (res.data.success) {
          commit('produtosMapaResgate', res.data.produtos);
        }
      })
      .catch(() => {
        Notify.create({
          message:
            'Não foi possível contactar, ou você não possui permissão!',
          color: 'red',
          position: 'top-right',
        });
      });
  },
  mapaResgates:({commit}, userData)=>{
    commit("mapaResgates", userData);
  },
  isPermissoMapaResgates:({commit}, userData)=>{
    commit("isPermissoMapaResgates", userData);
  },
  loadMapasResgates: ({commit}, userData = {}) => {

    let page = 1;
    let novosMapaResgates = [];

    if (!userData.forcarCarregamento) {
      if (state.loadingMapasResgates) return;
      if (!state.pagingMapasResgates.nextPage) return;

      page = state.pagingMapasResgates.page + 1;
      novosMapaResgates = state.mapasResgates;
    }

    commit("pagingMapasResgates", {
      page: page
    });
    commit('loadingMapasResgates', true);

    axios.get('/maparesgates', {
      params: {
        page: state.pagingMapasResgates.page,
        },
      })
      .then(resp => {
        commit('loadingMapasResgates', false);
        commit('mapasResgates', novosMapaResgates.concat(resp.data.mapas_resgate));
        commit("pagingMapasResgates", {
          finder: resp.data.paging.MapaResgates.finder,
          page: resp.data.paging.MapaResgates.page,
          current: resp.data.paging.MapaResgates.current,
          count: resp.data.paging.MapaResgates.count,
          perPage: resp.data.paging.MapaResgates.perPage,
          prevPage: resp.data.paging.MapaResgates.prevPage,
          nextPage: resp.data.paging.MapaResgates.nextPage,
          pageCount: resp.data.paging.MapaResgates.pageCount,
          sort: resp.data.paging.MapaResgates.sort,
          direction: resp.data.paging.MapaResgates.direction,
          limit: resp.data.paging.MapaResgates.limit,
          sortDefault: resp.data.paging.MapaResgates.sortDefault,
          directionDefault: resp.data.paging.MapaResgates.directionDefault,
          scope: resp.data.paging.MapaResgates.scope,
        });
      })
      .catch(err => {
        Notify.create({
          message: 'Houve um erro ao listar os mapas de resgates',
          color: "red",
          position: "top-right"
        });
        commit('loadingMapasResgates', false);
        commit("isPermissoMapaResgates", false);
      });
  },
  loadMapaResgates: ({commit, dispatch}, userData) => {
    let url = '/maparesgates/add';

    if (userData.id) {
      url += '/' + userData.id;
    }

    axios.get(url)
      .then(resp => {
        commit('loadingDialogNovoMapaResgates', false);

        if (!resp.data.success) {
          Notify.create({
            message: 'Houve um erro ao listar os resgates',
            color: "red",
            position: "top-right"
          });
          return;
        }

        dispatch("mapaResgates", {
          ...resp.data.mapa_resgates,
          visualizar: userData.visualizar,
        });
        if (resp.data.created) {
          dispatch("loadMapasResgates", { forcarCarregamento: true });
        }
      })
      .catch(err => {
        Notify.create({
          message: 'Houve um erro ao listar os resgates',
          color: "red",
          position: "top-right"
        });
        commit('loadingDialogNovoMapaResgates', false);
      });
  },

  loadOpcoes: ({ commit, state }) => {

    if(!state.isPermissoMapaResgates) return false;

    axios.get('/maparesgates/opcoes')
      .then(resp => {
        commit("opcoes", resp.data.opcoes);
      });
  },
};

const getters = {
  openDialogNovoMapaResgates: state => {
    return state.openDialogNovoMapaResgates;
  },
  openDialogOpcoesMapaResgates: state => {
    return state.openDialogOpcoesMapaResgates;
  },
  mapaResgates: state => {
    return state.mapaResgates;
  },
  mapasResgates: state => {
    return state.mapasResgates;
  },
  loadingMapasResgates: state => {
    return state.loadingMapasResgates;
  },
  isPermissoMapaResgates: state => {
    return state.isPermissoMapaResgates;
  },
  loadingDialogNovoMapaResgates: state => {
    return state.loadingDialogNovoMapaResgates;
  },
  opcoes: state => {
    return state.opcoes;
  },
  produtosMapaResgate: state => {
    return state.produtosMapaResgate;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
