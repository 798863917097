import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";

const state = {
  relatorios: [],
  relatoriosCarregamento: false,
  isPermissoRelatorios: true,
  relatoriosGerenciais: [],
  openDialogRelatoriosAgendas: false,
};

const mutations = {
  relatorios: (state, data) => {
    state.relatorios = data;
  },
  relatoriosCarregamento: (state, data) => {
    state.relatoriosCarregamento = data;
  },
  isPermissoRelatorios: (state, data) => {
    state.isPermissoRelatorios = data;
  },
  relatoriosGerenciais: (state, data) => {
    state.relatoriosGerenciais = data;
  },
  setOpenDialogRelatoriosAgendas: (state, userData) => {
    state.openDialogRelatoriosAgendas = userData;
  },
};

const actions = {
  relatorios: ({ commit }) => {
    commit("isPermissoRelatorios", true);
    commit("relatoriosCarregamento", true);
    commit("relatorios", []);
    commit("relatoriosCarregamento", []);
    axios
      .get("/relatorios")
      .then(res => {
        commit("relatorios", res.data.dados);
        commit("relatoriosGerenciais", res.data.relatoriosGerenciais);
        commit("relatoriosCarregamento", false);
      })
      .catch(() => {
        // Notify.create({
        //   message: "Não foi possível contactar, ou você não possui permissão!",
        //   color: "red",
        //   position: "top-right"
        // });
        commit("isPermissoRelatorios", false);
      });
  },
  deleteRelatorio: ({ commit, dispatch }, userData) => {
    Dialog.create({
      title: userData.nome,
      message: "Confirma a exclusão desse relatório?",
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/relatorios/delete/" + userData.id)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
            Loading.hide();
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi Possível realizar a ação, ou você não possui permissão isto!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            Loading.hide();
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  controlDialogRelatoriosAgendas: ({ commit }, userData) => {
    commit("setOpenDialogRelatoriosAgendas", userData);
  },
};

const getters = {
  relatorios: state => {
    return state.relatorios;
  },
  relatoriosCarregamento: state => {
    return state.relatoriosCarregamento;
  },
  isPermissoRelatorios: state => {
    return state.isPermissoRelatorios;
  },
  relatoriosGerenciais: state => {
    return state.relatoriosGerenciais;
  },
  openDialogRelatoriosAgendas: state => {
    return state.openDialogRelatoriosAgendas;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
