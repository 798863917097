<template>
  <div id="integracaoRecorrenciasParametros">
    <card-section-sem-permissao v-if="semPermissao" />

    <div v-else>
      <div class="text-center" v-if="loadingConfiguracoes">
        <q-spinner color="blue-7" size="3em" />
      </div>

      <!--
        Esta parte estava vinculada a sessão do piperun, mas foi movido para cá devido a ser
        configurações gerais de recorrências, mas as opções no banco continuam com o nome do piperun
      -->

      <div class="row" v-else>
        <div class="col-12 col-md-4">
            <q-item tag="label" v-ripple>
              <q-item-section>
                <q-select use-input clearable option-value="id" input-debounce="0" option-label="titulo"
                label="Planos de Contas de Adesão Produtos e Serviços" @filter="filterPlanosdecontas"
                :options="planosdecontasOptions" v-model="config.integracoes_piperun_planodeconta_parcelas">
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Sem Resultados
                    </q-item-section>
                  </q-item>
                </template>
                </q-select>
              </q-item-section>
            </q-item>
        </div>

        <div class="col-12 col-md-4">
          <q-item tag="label" v-ripple>
              <q-item-section>
                <q-select use-input clearable option-value="id" input-debounce="0" option-label="titulo"
                label="Planos de Contas de Assinaturas e Recorrências" @filter="filterPlanosdecontas"
                :options="planosdecontasOptions" v-model="config.integracoes_piperun_planodeconta_faturamento">
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Sem Resultados
                    </q-item-section>
                  </q-item>
                </template>
                </q-select>
              </q-item-section>
            </q-item>
        </div>

        <div class="col-12 col-md-4">
          <q-item tag="label">
              <q-item-section>
                <q-input v-model="config.integracoes_piperun_email_erro_propostas" label="Email para notificar erros de propostas">
                  <template v-slot:append >
                  <q-btn
                      name="info"
                      clickable
                      @click="getWebhooksLogs"
                      icon="info"
                      dense
                      class="cursor-pointer bg-blue-7 q-mr-md"
                      color="text-white"
                    />
                  </template>
                </q-input>
              </q-item-section>
            </q-item>
        </div>
      </div>

      <div class="row q-pt-md">
        <div class="col-12 col-md-6">
          <q-item tag="label" v-ripple>
            <q-item-section side>
              <q-toggle color="green"
              v-model="config.integracoes_piperun_permitir_abertas" true-value="1" false-value="0"
              ></q-toggle>
            </q-item-section>
            <q-item-section>
              <q-item-label>Permitir Proposta do CRM sem estar Aprovada ou Assinada</q-item-label>
              <q-item-label caption>Independente desta opção estar selecionada, a Oportunidade no CRM obrigatóriamente
                deve ter o status como "Ganha"</q-item-label>
            </q-item-section>
          </q-item>

          <q-item tag="label" v-ripple>
            <q-item-section side>
              <q-toggle color="green"
              v-model="config.integracoes_piperun_adicionar_recorrencias_aprovadas" true-value="1" false-value="0"
              ></q-toggle>
            </q-item-section>
            <q-item-section>
              <q-item-label>Aprovar Novas Assinaturas/Recorrências vindas do CRM Automaticamente</q-item-label>
              <q-item-label caption> Quando uma Nova Assinatura Chega do CRM, ela será considerada como
                Revisada/Aprovada aqui no ERP Automaticamente.
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-item tag="label" v-ripple>
            <q-item-section side>
              <q-toggle color="green"
              v-model="config.integracoes_piperun_faturar_quando_aprovada" true-value="1" false-value="0"
              ></q-toggle>
            </q-item-section>
            <q-item-section>
              <q-item-label>Faturar Imediatamente Assinaturas/Recorrências Quando Forem Aprovadas no ERP</q-item-label>
              <q-item-label caption>Ao clicar no ícone e aprovar aqui no nosso ERP uma Assinatura, nosso sistema fará o
                Faturamento Imediatamente.
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-item tag="label" v-ripple>
            <q-item-section side>
              <q-toggle color="green"
              v-model="config.integracoes_piperun_gerar_boletos_parcelas" true-value="1" false-value="0"
              ></q-toggle>
            </q-item-section>
            <q-item-section>
              <q-item-label>Gerar Cobranças de Adesão no Primeiro Faturamento</q-item-label>
              <q-item-label caption>Ao faturar pela primeira vez, o sistema gerará a cobrança de todas as parcelas (Caso
                haja) da Adesão
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-item tag="label" v-ripple>
            <q-item-section side>
              <q-toggle color="green"
              v-model="config.integracoes_piperun_permitir_boletos_avulso"
              true-value="1"
              false-value="0"
              ></q-toggle>
            </q-item-section>
            <q-item-section>
              <q-item-label>Permitir Propostas sem Mensalidade</q-item-label>
              <q-item-label caption>Ativar esta opção permite criar propostas sem mensalidades, em vez disso, elas geram boletos avulsos para cobranças únicas separadas.
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-item tag="label" v-ripple>
            <q-item-section side>
              <q-toggle color="green"
              v-model="config.integracoes_piperun_parcelas_sem_multa_juros_protesto" true-value="1" false-value="0"
              ></q-toggle>
            </q-item-section>
            <q-item-section>
              <q-item-label>Não Gerar Multa/Juros/Protesto na Primeira Parcela da Adesão</q-item-label>
              <q-item-label caption>Caso o cliente atrase o pagamento, não será atualizado o valor da cobrança com
                encargos sobre os valores de primeira parcela da Adesão.
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-item tag="label" v-ripple>
            <q-item-section side>
              <q-toggle color="green"
              v-model="config.integracoes_piperun_utilizar_trial" true-value="1" false-value="0"
              ></q-toggle>
            </q-item-section>
            <q-item-section>
              <q-item-label>Ativar Modo Trial (Testes)</q-item-label>
              <q-item-label caption>Permite Assinaturas/Recorrências sem Cobrança do período estipulado, possibilitando
                análises de conversão de Trial em Assinaturas/Vendas.
              </q-item-label>
            </q-item-section>

          </q-item>
        </div>
        <div class="col-12 col-md-6">
          <q-item tag="label" v-ripple>
              <q-item-section side>
                <q-toggle 
                  v-model="config.integracoes_piperun_planodeconta_recorrencias"
                  true-value="1"
                  false-value="0"
                  color="green"
                  />
              </q-item-section>
              <q-item-section>
                <q-item-label>Permitir a Edição do Plano de Contas na Assinatura/Recorrência</q-item-label>
                <q-item-label caption> Marque esta opção, caso queira editar campo "Planos de Contas em
                  Assinaturas/Recorrências" .
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item  item tag="label" v-ripple>
              <q-item-section side>
                <q-toggle color="green"
                v-model="config.integracoes_piperun_utilizar_comissoes"
                true-value="1"
                false-value="0"
                ></q-toggle>
              </q-item-section>
              <q-item-section>
                <q-item-label>Gerador de Comissões para Vendedores</q-item-label>
                <q-item-label caption>Permite cadastrar comissionamento para pré-vendedores, vendedores e parceiros a
                  partir de regras personalizadas.
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item tag="label" v-ripple>
              <q-item-section side>
                <q-toggle color="green"
                v-model="config.integracoes_piperun_exibir_campo_plano"
                true-value="1"
                false-value="0"
                ></q-toggle>
              </q-item-section>
              <q-item-section>
                <q-item-label>Permitir Seleção de Planos</q-item-label>
                <q-item-label caption>Ativando este item, você poderá selecionar Planos cadastrados do seu Portal de
                  Planos/Check-Out.
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item tag="label" v-ripple>
              <q-item-section side>
                <q-toggle color="green"
                v-model="config.integracoes_piperun_exibir_campo_vinculo"
                true-value="1"
                false-value="0"
                ></q-toggle>
              </q-item-section>
              <q-item-section>
                <q-item-label>Permitir Seleção de Pessoas Vinculadas</q-item-label>
                <q-item-label caption>Ativar este item, permite selecionar pessoas vinculadas entre si. Exemplo: Holding
                  que paga Assinaturas/Recorrências de empresas subsidiárias, ou Associados que pagam por seus
                  Dependentes.
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item tag="label" v-ripple>
              <q-item-section side>
                <q-toggle color="green"
                v-model="config.integracoes_piperun_exibir_campo_convenio"
                true-value="1"
                false-value="0"
                ></q-toggle>
              </q-item-section>
              <q-item-section>
                <q-item-label>Permitir a Seleção de Convênios</q-item-label>
                <q-item-label caption>Normalmente utilizado por Associações, ao ativar esta opção permite importar
                  cobranças de outras instituições/empresas (Planos de Saúde/Farmácias) e repassar estes valores aos
                  Clientes/Associados. Exemplo: Cobrar dos Associados e repassar estes valores ao Plano de Saúde, Posto de
                  Combustíveis.
                </q-item-label>
              </q-item-section>
            </q-item>

        </div>
      </div>

      <div class="flex justify-between q-mt-md">
        <q-btn flat no-caps to="/planos" color="primary" label="Portal de Planos/Check-Out" />
        <q-btn color="green" label="Salvar" :loading="salvandoConfiguracoes" @click="salvarConfiguracoes()" />
      </div>
    </div>

    <modalWebhookLogs v-if="showLogsModal" :webhookLogs="webhookLogs" @closeModal="showLogsModal = false"/>

  </div>
</template>

<script>
import axios from '@/axios-auth';
import moment from 'moment';
import modalWebhookLogs from './modal-entrada-webhooks.vue';

export default {
  name: 'integracao-recorrencias-parametros',
  data() {
    return {
      salvandoConfiguracoes: false,
      loadingConfiguracoes: false,
      planosdecontas: [],
      planosdecontasOptions: [],
      semPermissao: false,
      showLogsModal: false,
      webhookLogs: [],
      config: {
        integracoes_piperun_planodeconta_parcelas: '',
        integracoes_piperun_planodeconta_faturamento: '',
        integracoes_piperun_permitir_abertas: '0',
        integracoes_piperun_faturar_quando_aprovada: '0',
        integracoes_piperun_gerar_boletos_parcelas: '0',
        integracoes_piperun_parcelas_sem_multa_juros_protesto: '0',
        integracoes_piperun_adicionar_recorrencias_aprovadas: '0',
        integracoes_piperun_utilizar_trial: '0',
        integracoes_piperun_utilizar_comissoes: '0',
        integracoes_piperun_exibir_campo_vinculo: '0',
        integracoes_piperun_exibir_campo_convenio: '0',
        integracoes_piperun_exibir_campo_plano: '0',
        integracoes_piperun_permitir_boletos_avulso: '0'
      },
    };
  },
  components: {
    modalWebhookLogs
  },
  methods: {
    loadConfiguracoes() {
      this.loadingConfiguracoes = true;

      axios
        .get('/integracoes/piperun')
        .then(res => {
          this.loadingConfiguracoes = false;

          if (!res.data.success) {
            throw new Error();
          }

          this.semPermissao = false;
          this.config = res.data.config;
          this.loadPlanosdecontas();
        })
        .catch(() => {
          this.loadingConfiguracoes = false;
          this.semPermissao = true;
        });
    },
    salvarConfiguracoes() {
      this.salvandoConfiguracoes = true;

      let url = '/integracoes/piperun';
      const data = JSON.parse(JSON.stringify(this.config));

      if (typeof data.integracoes_piperun_planodeconta_parcelas === 'object') {
        data.integracoes_piperun_planodeconta_parcelas = data.integracoes_piperun_planodeconta_parcelas.id;
      }
      if (typeof data.integracoes_piperun_planodeconta_faturamento === 'object') {
        data.integracoes_piperun_planodeconta_faturamento = data.integracoes_piperun_planodeconta_faturamento.id;
      }

      axios
        .post(url, data)
        .then(res => {
          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right'
          });

          if (!res.data.success) {
            return;
          }

          this.salvandoConfiguracoes = false;
          this.config = res.data.config;

          this.updatePlanodecontasParcelas();
          this.updatePlanodecontasFaturamento();
        })
        .catch(() => {
          this.salvandoConfiguracoes = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar as configurações, tente novamente!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    loadPlanosdecontas() {
      axios
        .get('/plano-contas?cd=C')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.planosdecontas = res.data.planodecontas;
          this.updatePlanodecontasParcelas();
          this.updatePlanodecontasFaturamento();
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    filterPlanosdecontas(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.planosdecontasOptions = this.planosdecontas.filter(
          planodecontas => planodecontas.titulo.toLowerCase().includes(needle)
        );
      });
    },
    updatePlanodecontasParcelas() {
      if (!this.config.integracoes_piperun_planodeconta_parcelas) {
        return;
      }

      this.config.integracoes_piperun_planodeconta_parcelas = this.planosdecontas
        .find(planodecontas => planodecontas.id === parseInt(this.config.integracoes_piperun_planodeconta_parcelas));
    },
    updatePlanodecontasFaturamento() {
      if (!this.config.integracoes_piperun_planodeconta_faturamento) {
        return;
      }

      this.config.integracoes_piperun_planodeconta_faturamento = this.planosdecontas
        .find(planodecontas => planodecontas.id === parseInt(this.config.integracoes_piperun_planodeconta_faturamento));
    },
    getWebhooksLogs() {
      const filtros = {
        tipo: 'todos',
        page: 1,
        dtini: moment().startOf('month').format('DD-MM-YYYY'),
        dtfim: moment().endOf('month').format('DD-MM-YYYY')
      };

      const params = JSON.parse(JSON.stringify({ ...filtros }));
      axios
        .get('/webhooks/logs', { params })
        .then(res => {
          if (res.data.success) {
            this.webhookLogs = Object.values(res.data.data);
            this.showLogsModal = true;
          }
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os logs dos webhooks.',
            color: 'red',
            position: 'top-right',
          });
        });
    }
  },
  mounted() {
    this.loadConfiguracoes();
  },
};
</script>

<style lang="stylus" scoped>

</style>
