<template>
  <div id="cadastro_view">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <div class="col-12 text-center" v-if="loadingCadastro">
          <q-spinner color="blue-7" size="4em" />
        </div>
        <div class="col-12 text-center" v-else-if="!loadingCadastro && Object.keys(cad).length === 0">
          <div id="cadastroNaoEncontrado">
            <div style="text-align: center" class="fixed-center">
              <q-icon size="10em" name="info_outline" color="grey" />
              <br />
              <p style="font-weight: bold;font-size: 25px" class="text-grey">Cadastro não encontrado</p>
              <br />
              <p>
                Não conseguimos encontrar esse cadastro no sistema, verifique se os dados estão corretos.
              </p>
              <br />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4" v-else-if="!loadingCadastro && cad">
          <q-card white bordered class="q-mb-md">
            <q-card-section>
              <div class="text-h6">{{ cad.nome }}</div>
              <div class="text-subtitle2">{{ cad.razao }}</div>
              <q-space />
              <q-btn
                dense
                no-wrap
                v-ripple
                no-caps
                label="Editar"
                class="q-ml-sm q-px-md bg-primary text-white absolute-top-right"
                style="margin: 15px 15px 0 0"
                clickable
                @click="modal"
              ></q-btn>
            </q-card-section>
            <q-card-section>
              <p class="q-pb-xl">
                <span v-if="cad.user_id" class="left">Usuário,&nbsp;</span>
                <span v-if="cad.cliente" class="left"
                  >Cliente/Associado,&nbsp;</span
                >
                <span v-if="!cad.cliente && !cad.dependente" class="left"
                  >Não associado,&nbsp;</span
                >
                <span v-if="cad.dependente" class="left">Vinculado,&nbsp;</span>
                <span v-if="cad.funcionario" class="left"
                  >Funcionário,&nbsp;</span
                >
                <span v-if="cad.fornecedor" class="left"
                  >Fornecedor,&nbsp;</span
                >
                <span v-if="cad.responsavel" class="left"
                  >Responsável,&nbsp;</span
                >
                <span v-if="cad.ativo" class="left">Ativo&nbsp;</span>
                <span v-if="!cad.ativo" class="left"><q-badge color="red">Inativo</q-badge></span>
                <span
                  v-if="cad.cadastro_id && cad.cadastro_id != undefined"
                  class="left"
                >
                  <router-link
                    :to="'/cadastros/' + cad.cadastro_id"
                    class="btn btn-small btn-flat white black-text waves-effect"
                    >Vínculo responsável</router-link
                  >
                </span>
              </p>
              <div
                style="position:relative;top:-30px; height: 33px;"
                v-for="(val, key) in zapPhone"
                :key="key"
                class="row wrap items-center"
              >
                <span>{{ val.telefone }}</span>
                <q-btn
                  v-if="isWhatsappAtual(val.telefone)"
                  flat
                  dense
                  class="q-ml-sm"
                  @click="abreZap(key)"
                >
                  <img height="25" src="@/assets/whatsapp-icon.png" />
                  <q-tooltip v-if="hasWhatsApp">Enviar Mensagem</q-tooltip>
                </q-btn>
                <q-btn
                  flat
                  dense
                  class="q-ml-sm"
                  v-if="val.zap && !isWhatsappAtual(val.telefone)"
                  :loading="loadingDefinirWhatsapp === key"
                  @click="() => definirWhatsapp(key)"
                >
                  <img height="25" src="@/assets/whatsapp-icon-cinza.png" />
                </q-btn>
                <q-btn
                  v-if="val.telefone && hasRamal"
                  flat
                  dense
                  icon="fa fa-mobile"
                  @click="abreLigacao(val.telefone)"
                >
                  <q-tooltip>Ligar</q-tooltip>
                </q-btn>
              </div>
              <p
                v-for="(email, idx) in emails"
                :key="email + idx"
                style="position:relative;top:-30px; margin-bottom: 0;"
              >
                {{ email }}
                <q-btn
                  v-if="idx === 0 && isAssinaturas"
                  icon="mail_outline"
                  class="q-ml-sm"
                  size="12px"
                  flat
                  dense
                  @click="sendEmail"
                >
                  <q-tooltip>Enviar E-mail</q-tooltip>
                </q-btn>
                <q-btn
                  v-if="idx === 0 && isAssinaturas"
                  icon="history"
                  class="q-ml-sm"
                  size="12px"
                  flat
                  dense
                  :href="`/historicoemails?cadastro_id=${cad.id}`"
                  target="_blank"
                >
                  <q-tooltip>Visualizar histórico de e-mails</q-tooltip>
                </q-btn>
              </p>

              <p
                v-for="(email, idx) in emailsBloqueados"
                :key="email + idx"
                style="position:relative;top:-30px; margin-bottom: 0;"
                class="text-red-9"
              >
                {{ email }}
                <q-btn
                  v-if="isAssinaturas"
                  icon="fa fa-exclamation-triangle "
                  size="10px"
                  flat
                  dense
                  @click="desbloquearEmail(email)"
                >
                  <q-tooltip>Desbloquear e-mail</q-tooltip>
                </q-btn>
              </p>
              <grupos-component></grupos-component>
            </q-card-section>
          </q-card>
          <q-card white bordered class="q-mb-md">
            <q-card-section @click="infoExtra = !infoExtra">
              <div class="text-h6 cursor-pointer">
                Informações Extras
                <q-btn
                  no-wrap
                  v-ripple
                  label="Visualizar Licença"
                  class="bg-blue-7 text-white right"
                  no-caps
                  clickable
                  @click="$router.push('/licencas?cadastro_id=' + routeId)"
                  v-if="isLicenca"
                ></q-btn>
              </div>

              <q-btn
                dense
                flat
                no-wrap
                v-ripple
                round
                color="black"
                :icon="infoExtra ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                no-caps
                class="q-ml-sm bg-white text-black absolute-top-right"
                style="margin: 15px 35px 0 0"
                clickable
              ></q-btn>
            </q-card-section>
            <q-card-section v-if="infoExtra">
              <q-markup-table flat dense>
                <tr>
                  <td class="text-bold">Código</td>
                  <td>{{ cad.codigo }}</td>
                </tr>
                <tr>
                  <td class="text-bold">CPF/CNPJ</td>
                  <td side>{{ cad.cpfcnpj }}</td>
                </tr>
                <tr>
                  <td class="text-bold">Nascimento</td>
                  <td>{{ cad.dtnascimento | formatDate }}</td>
                </tr>
                <tr>
                  <td class="text-bold">Endereço</td>
                  <td>
                    {{ cad.endereco }} {{ cad.numero }} {{ cad.complemento }}
                  </td>
                </tr>
                <tr>
                  <td class="text-bold">CEP</td>
                  <td>{{ cad.cep }}</td>
                </tr>
                <tr>
                  <td class="text-bold">Cidade</td>
                  <td>{{ cad.cidade }}</td>
                </tr>
                <tr>
                  <td class="text-bold">UF</td>
                  <td>{{ cad.estado }}</td>
                </tr>
                <tr>
                  <td class="text-bold">Bairro</td>
                  <td>{{ cad.bairro }}</td>
                </tr>
                <tr>
                  <td class="text-bold">Limite</td>
                  <td>{{ cad.limite }}</td>
                </tr>
                <tr>
                  <td class="text-bold">Saldo</td>
                  <td>{{ cad.saldo }}</td>
                </tr>
                <tr>
                  <td class="text-bold">Cadastrou</td>
                  <td>{{ cad.cadastrou }}</td>
                </tr>
                <tr>
                  <td class="text-bold">Cadastrado em</td>
                  <td>{{ cad.created | formatDate }}</td>
                </tr>
                <tr>
                  <td class="text-bold">Atualizado em</td>
                  <td>{{ cad.modified | formatDate }}</td>
                </tr>
                <tr v-if="cad.confirmed">
                  <td class="text-bold">Cadastro Confirmado em</td>
                  <td>{{ cad.confirmed | formatDateTime }}</td>
                </tr>
                <tr v-if="!cad.ativo">
                  <td class="text-bold">Desativado em</td>
                  <td>{{ cad.deactivated | formatDateTime }}</td>
                </tr>
              </q-markup-table>
            </q-card-section>
          </q-card>
          <q-card white bordered class="q-mb-md">
            <q-card-section @click="camposAdicionais = !camposAdicionais">
              <div class="text-h6 cursor-pointer">
                <q-btn
                  dense
                  flat
                  id="addNovoCampo"
                  no-wrap
                  v-ripple
                  round
                  icon="add"
                  no-caps
                  class="q-ml-sm q-mr-md bg-white text-blue-7"
                  clickable
                  size="11px"
                  @click.stop="novoCampo()"
                ></q-btn
                >Campos Adicionais
              </div>
              <q-btn
                dense
                flat
                no-wrap
                v-ripple
                round
                color="black"
                :icon="
                  camposAdicionais ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                "
                no-caps
                class="q-ml-sm bg-white text-black absolute-top-right"
                style="margin: 15px 35px 0 0"
                clickable
                :loading="buscandoDetalhes"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
            </q-card-section>
            <q-card-section v-show="camposAdicionais">
              <detalhes-component ref="camposAdicionaiss" />
            </q-card-section>
          </q-card>
          <q-card
            white
            bordered
            class="q-mb-md"
            v-if="isAreaCliente && !cad.dependente"
          >
            <q-card-section @click="areaCliente = !areaCliente">
              <div class="text-h6 cursor-pointer">Área do Cliente</div>
              <q-btn
                dense
                flat
                no-wrap
                v-ripple
                round
                color="black"
                :icon="
                  areaCliente ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                "
                no-caps
                class="q-ml-sm q-px-md bg-white text-black absolute-top-right"
                style="margin: 15px 15px 0 0"
                clickable
              ></q-btn>
            </q-card-section>
            <q-card-section v-if="areaCliente">
              <area-cliente />
            </q-card-section>
          </q-card>
        </div>
        <div class="col-12 col-md-8" v-if="!loadingCadastro && !cad.dependente && Object.keys(cad).length > 0">
          <formas-pagamento-component />
          <recorrencias-component :cadastroNome="cadastroView.nome" />
          <vinculos-component />
          <cobrancas-component
            :liquidarCallback="callbackListarReceitas"
            @changeTipo="changeTipoConta"
          />
          <despesas-component v-if="cad.fornecedor" @changeTipo="changeTipoConta" />
        </div>
      </div>
    </div>
    <timeline-cadastro
      ref="timelineCadastro"
      v-if="!loadingCadastro"
    />
    <modal-agendar-mensagem :isCadastrosPage="true" />
    <modal-detalhar-movconta />
    <modal-liquidar-movconta
      :tipo-conta="tipoConta"
      @changeTipo="changeTipoConta"
    />
    <notificar-cliente
      v-if="OpenDialogNotificarCliente"
      :isIndividual="true"
      tipoNotificacaoIndividual="E-mail"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify, Dialog } from "quasar";
import axios from "@/axios-auth";

export default {
  name: "cadastro_view",
  meta: {
    title: "DSB | Cadastro"
  },
  data() {
    return {
      infoExtra: true,
      camposAdicionais: false,
      areaCliente: false,
      isAreaCliente: false,
      isLicenca: false,
      tipoConta: null,
      loadingDefinirWhatsapp: -1,
    };
  },
  watch: {
    routeId(value) {
      this.$store.dispatch("viewCadastro", value);
    },
  },
  methods: {
    abreZap(val) {
      if (this.hasWhatsApp) {
        this.$store.commit("allCadastros", false);
        this.$store.commit("OpenDialogAgendarMensagem", true);
      } else {
        window.open(
          "https://api.whatsapp.com/send?phone=55" + this.zapPhone[val].telefone,
          "_blank"
        );
      }
    },
    modal() {
      this.$store.dispatch("cadastro", this.cad);
    },
    breakLine(value) {
      if (!value) {
        return "";
      }
      return value.toString().replace(/,/g, "<br>");
    },
    novoCampo() {
      this.$store.commit("OpenDialogNovoCampo", true);
    },
    async isCliente() {
      let empresa = await this.$store.getters.dataEmpresa;
      if (!empresa.aplicativos) {
        return;
      }

      for (let i = 0; i < empresa.aplicativos.length; i++) {
        if (empresa.aplicativos[i].label === "Área do cliente") {
          this.isAreaCliente = true;
        }
      }
    },
    abreLigacao(numero) {
      Dialog.create({
        title: "Confirmar ligação",
        message: `Deseja ligar para ${numero}?`,
        ok: "Confirmar",
        cancel: "Cancelar",
      })
      .onOk(() => {
        const dados = {
          numero: numero,
          cadastro_id: this.cad.id,
        };

        this.$store.dispatch("getWebPhone", dados);
      });
    },
    async verificaLicenca() {
      let empresa = await this.$store.getters.dataEmpresa;
      if (!empresa.aplicativos) {
        return;
      }

      for (let i = 0; i < empresa.aplicativos.length; i++) {
        if (empresa.aplicativos[i].label === "Licenças") {
          this.isLicenca = true;
        }
      }
    },
    definirWhatsapp(key) {
      if (this.loadingDefinirWhatsapp !== -1) return;

      this.loadingDefinirWhatsapp = key;

      let dados = {
        whatsapp: this.zapPhone[key].telefone,
        action: "update_whatsapp"
      };

      axios
        .post("/cadastros/edit/" + this.cad.id, dados)
        .then(res => {
          if (res.data.success) {
            if (this.$route.path === "/cadastros") {
              this.$store.dispatch("resetPagingCadastros");
              this.$store.dispatch("listarCadastros", this.filtrosCadastro);
            }
            Notify.create({
              message: "Whatsapp alterado com sucesso!",
              color: "green",
              position: "top-right"
            });

            if (
              this.$route.path ===
              "/cadastros/" + res.data.data.cadastro.id
            ) {
              this.cad.whatsapp = res.data.data.cadastro.whatsapp;
            }
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          this.loadingDefinirWhatsapp = -1;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.loadingDefinirWhatsapp = -1;
        });
    },
    isWhatsappAtual(telefone) {
      if (!telefone || !this.cad.whatsapp) {
        return false;
      }
      return (
        this.cad.whatsapp.indexOf(
          telefone,
          this.cad.whatsapp.length - telefone.length
        ) !== -1
      );
    },
    changeTipoConta(tipo) {
      this.tipoConta = tipo;
    },
    sendEmail() {
      this.$store.commit("dadosNotificar", {
        ids: [this.cad.id],
        all: false
      });
      this.$store.commit("OpenDialogNotificarCliente", true);
    },
    desbloquearEmail(email) {
      Dialog.create({
        title: `Deseja desbloquear o e-mail?`,
        message: `
          Ao realizar o desbloqueio, você concorda que já entrou em contato com o cliente e confirma
          a validade do e-mail.<br><br>
          Digite <b style="color: #EA4335;">${email}</b> abaixo para confirmar a ação:
        `,
        cancel: "Cancelar",
        persistent: true,
        html: true,
        prompt: {
          type: "text",
          model: '',
          isValid: val => val === email,
        },
      })
      .onOk(() => {
        let dados = {
          email_remover: email,
          action: "remover_email_bloqueado"
        };

        axios
          .post("/cadastros/edit/" + this.cad.id, dados)
          .then(res => {
            if (res.data.success) {
              this.cad.emails = res.data.data.cadastro.emails;
              this.cad.emails_bloqueados = res.data.data.cadastro.emails_bloqueados;
              this.$store.dispatch("viewCadastro", this.cad.id);
            }

            Notify.create({
              message: res.data.message,
              success: res.data.success,
              color: res.data.success ? "green" : "orange",
              position: "top-right"
            });
          })
          .catch(() => {
            Notify.create({
              message:
                "Não foi possível contactar, ou você não possui permissão!",
              color: "red",
              position: "top-right"
            });
          });
      });
    },
  },
  computed: {
    ...mapGetters([
      "cadastro",
      "phone",
      "zapPhone",
      "cadastroView",
      "loadingCadastro",
      "buscandoDetalhes",
      "hasWhatsApp",
      "popupWebPhone",
      "webphoneUrl",
      "hasRamal",
      "OpenDialogNotificarCliente",
      "dataEmpresa"
    ]),
    id() {
      return this.$route.params.id;
    },
    cad() {
      return JSON.parse(JSON.stringify(this.cadastroView));
    },
    routeId() {
      return this.$route.params.id;
    },
    callbackListarReceitas() {
      return this.$store.state.receitas.callbackListar;
    },
    emailsBloqueados() {
      if (!this.cad.emails_bloqueados) return [];

      return this.cad.emails_bloqueados.split(",");
    },
    emails() {
      if (!this.cad.email) return [];

      return this.cad.email.split(",").filter(email => {
        return !this.emailsBloqueados.includes(email);
      });
    },
    isAssinaturas() {
      return this.dataEmpresa?.assinatura?.segmento === "Assinaturas";
    }
  },
  mounted() {
    // this.$store.dispatch("buscaCadastro", this.id);
    this.$store.dispatch("viewCadastro", this.id);
    this.$store.dispatch("hasRamal");
    this.isCliente();
    this.verificaLicenca();
  }
};
</script>

<style lang="stylus" scoped>
.text-h6, .text-subtitle2 {
  font-weight: 300;
}
</style>
