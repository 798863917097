import axios from "axios";
import axiosAuth from "@/axios-auth";
import { Notify } from "quasar";
import router from "@/router/router";
import store from '@/store/store';

const state = {
  login: {
    message: "",
    isLogged: false
  },
  aposLogin: false,
  token: null,
  urlQrcode: null
};

const mutations = {
  login: (state, userData) => {
    state.login.message = userData.message;
    state.login.isLogged = userData.isLogged;
  },
  token: (state, userData) => {
    state.token = userData;
  },
  urlQrcode: (state, userData) => {
    state.urlQrcode = userData;
  },
  aposLogin: (state, userData) => {
    state.aposLogin = userData;
  }
};

const actions = {
  getTokenQrcode({ commit }, OpenDialogUploadResumoCaixas = false) {

    if (!OpenDialogUploadResumoCaixas) return false;

    let detalhar;
    const recorrenciaQrCode = this.getters.recorrenciaQrCode;
    const hasRecorrenciaQrCode = recorrenciaQrCode !== null && recorrenciaQrCode?.id;
    if (hasRecorrenciaQrCode) {
      detalhar = recorrenciaQrCode.id;
    } else {
      detalhar = this.getters.despesaQrCode ? this.getters.despesaQrCode : this.getters.receitaQrcode;
    }

    let variaveis = {
      params: {},
      empresa: store.getters.dataEmpresa.id === undefined ? localStorage['empresa_atual'] : store.getters.dataEmpresa.id,
      urlAtual: window.location.origin + window.location.pathname,
      movmentacao: detalhar,
    };
    if (detalhar && !hasRecorrenciaQrCode) {
      let url = variaveis.urlAtual.split("/");
      if (this.getters.despesaQrCode){
        variaveis.urlAtual = url[0]+'//'+ url[2] + '/' + 'despesas' ;
      } else {
        variaveis.urlAtual = url[0]+'//'+ url[2] + '/' + 'receitas' ;
      }
    }
    if (localStorage[`qrcodeSession${variaveis.empresa}`] &&
      localStorage[`qrcodeSession${variaveis.empresa}`].length > 15
    ) {
      variaveis.params = {
        token: localStorage[`qrcodeSession${variaveis.empresa}`]
      };
    }

    axiosAuth
      .get("/users/qrcode", {
        params: variaveis.params
      })
      .then(response => {
        localStorage.setItem(`qrcodeSession${variaveis.empresa}`, response.data.id);
        const param = hasRecorrenciaQrCode ? `recorrencia_id=${variaveis.movmentacao}` : `movconta_id=${variaveis.movmentacao}`;
        commit('urlQrcode', `${variaveis.urlAtual}?token=${response.data.token}&empresa=${variaveis.empresa}&${param}`);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível gerar QRCODE autenticado !",
          color: "red",
          position: "top-right"
        });
      });
  },
  login: ({ commit, dispatch }, userData) => {
    commit("login", {
      isLogged: true
    });
    axios
      .post("/users/token", userData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(res => {

        if (res.data.success) {
          let empresa_id = res.data.data.empresa.id;
          let empresa = `empresa${empresa_id}`;
          let tokenName = `token${empresa_id}`;

          axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage[tokenName];

          localStorage.setItem('empresa_atual', res.data.data.empresa.id);
          localStorage.setItem(empresa, res.data.data.empresa.id);
          localStorage.setItem(`menu${empresa_id}`, JSON.stringify(res.data.data.menu));
          localStorage.setItem(tokenName, res.data.data.token);
          localStorage.setItem("validade", res.data.data.validade);
          localStorage.setItem('empresa_plano', res.data.data.empresa.assinatura.plano);

          commit("token", res.data.data.token);
          commit("dataUser", res.data.data.user);
          commit("dataEmpresa", res.data.data.empresa);
          commit("aposLogin", true);

          if (res.data.data.isTour) {
            router.go("/tour");
          } else if (res.data.data.isConfig) {
            router.push("/configuracao");
          } else {
            router.push("/visao360");
            dispatch("listarUser");
          }
        } else {
          commit("login", {
            message: res.data.message,
            isLogged: false
          });
          let msg = state.login.message;
          Notify.create({
            message: msg,
            color: "orange",
            position: "top-right"
          });
        }
      })
      .catch(() => {
        // Notify.create({
        //   message: 'Não foi possível contactar, ou você não possui permissão!',
        //   color: 'red'
        // })
      });
  },
  logout: ({ commit }) => {

    let data = {
      action: 'atual',
      token: localStorage.getItem("sessionAtual")
    };

    axiosAuth.post("/users/logout", data)
      .then(res => {
        localStorage.clear();
        router.go("/login");
      })
      .catch(error => {
        Notify.create({
          message: "Não foi possível estabelcer conexão com o servidor",
          color: "orange",
          position: "top-right"
        });
      });
  }
};

const getters = {
  login: state => {
    return state.login;
  },
  token: state => {
    return state.token;
  },
  urlQrcode: state => {
    return state.urlQrcode;
  },
  aposLogin: state => {
    return state.aposLogin;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
