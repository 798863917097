import { render, staticRenderFns } from "./modal-enviar-relatorio-rme.vue?vue&type=template&id=f898474c&scoped=true&"
import script from "./modal-enviar-relatorio-rme.vue?vue&type=script&lang=js&"
export * from "./modal-enviar-relatorio-rme.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f898474c",
  null
  
)

export default component.exports