<template>
  <div id="modalInclusaoMassa">
    <q-dialog v-model="OpenDialogInclusaoMassa" persistent>
      <q-card style="max-width: 400px !important" id="cardInclusaoMassa">
        <q-card-section class="row items-center">
          <div class="text-h6">Inclusão de Receitas em Massa</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-input
                  bottom-slots
                  :error="errors.vencimento ? true : false"
                  v-model="movconta.vencimento"
                  label="Vencimento"
                  @click="$refs.qDateProxy.show();"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="movconta.vencimento"
                          @input="() => $refs.qDateProxy.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.vencimento" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  bottom-slots
                  :error="errors.descricao ? true : false"
                  v-model="movconta.descricao"
                  label="Descrição"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.descricao" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-6 col-md-6">
                <q-input
                  bottom-slots
                  :error="errors.referencia ? true : false"
                  v-model="movconta.referencia"
                  label="Referência"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.referencia" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-6 col-md-6">
                <q-input
                  v-model="movconta.valor"
                  bottom-slots
                  :error="errors.valor ? true : false"
                  mask="#,##"
                  fill-mask="0"
                  reverse-fill-mask
                  input-class="text-right"
                  label="Valor"
                  prefix="R$ "
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.valor" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12" v-for="(i, v) in tipoCadastro" :key="v + i.nome">
                <q-select
                  v-model="i.value"
                  :options="i.labels"
                  :label="i.nome"
                  :option-label="value => value === null ? 'Item Vazio' : value"
                  :option-value="value => value === null ? null : value"
                  bottom-slots
                  :error="errors[v] ? true : false"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors[v]" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12" style="padding-left: 0">
                <q-checkbox
                  v-model="cobrar"
                  clickable
                  @click="findGateway"
                  label="Gerar Cobrança"
                  color="green"
                />
              </div>
              <div class="col-12 col-md-12" v-if="movconta.cobrar">
                <q-select
                  v-model="movconta.gateway_id"
                  :option-label="value => value === null ? 'Item Vazio' : value.titulo"
                  :option-value="value => value === null ? null : value"
                  :options="findGateways.gateways"
                  label="Cobrança"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12" v-if="!movconta.cobrar">
                <q-select
                  v-model="movconta.conta_id"
                  :option-label="value => value === null ? 'Item Vazio' : value.titulo"
                  :option-value="value => value === null ? null : value"
                  :options="contas"
                  label="Conta"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveReceitaAgrupada"
            :loading="realizandoAcaoReceitas"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Dialog, Loading, Notify } from "quasar";
import { mapGetters } from "vuex";
export default {
  name: "moda-inclusao-massa",
  data() {
    return {
      tipoCadastro: {
        ativo: {
          nome: "Ativo",
          values: [true, false, null],
          labels: ["Sim", "Não", "Nulo"],
          value: null
        },
        cliente: {
          nome: "Cliente",
          values: [true, false, null],
          labels: ["Sim", "Não", "Nulo"],
          value: null
        },
        funcionario: {
          nome: "Funcionário",
          values: [true, false, null],
          labels: ["Sim", "Não", "Nulo"],
          value: null
        },
        fornecedor: {
          nome: "Fornecedor",
          values: [true, false, null],
          labels: ["Sim", "Não", "Nulo"],
          value: null
        },
        dependente: {
          nome: "Dependente",
          values: [true, false, null],
          labels: ["Sim", "Não", "Nulo"],
          value: null
        }
      },
      movconta: {
        id: null,
        tipo: 2,
        vencimento: null,
        liquidacao: null,
        conta_id: null,
        descricao: null,
        valor: 0.0,
        valor_liquidado: 0.0,
        valor_acrescimo_desconto: 0.0,
        cadastro_id: null,
        cadastro_nome: null,
        nomenclatura_id: null,
        liquidado: false,
        conciliado: false,
        gateway_id: null,
        cadastros_gateways_id: null,
        fatura_id: null,
        shortUrl: null,
        status: "Aberto",
        cobrar: false
      },
      errors: [],
      cobrar: false,
      realizandoAcaoReceitas: false
    };
  },
  watch: {
    cobrar(value) {
      if (value) {
        this.findGateway();
      }
      this.movconta.cobrar = value;
    }
  },
  methods: {
    findGateway() {
      this.$store.dispatch("findGateways");
    },
    newMovconta() {
      return {
        id: null,
        tipo: 2,
        vencimento: "",
        liquidacao: null,
        conta_id: null,
        descricao: null,
        valor: 0.0,
        valor_liquidado: 0.0,
        valor_acrescimo_desconto: 0.0,
        cadastro_id: this.id,
        cadastro_nome: this.nome,
        nomenclatura_id: null,
        liquidado: false,
        conciliado: false,
        gateway_id: null,
        cadastros_gateways_id: null,
        fatura_id: null,
        shortUrl: null,
        status: "Aberto",
        cobrar: false
      };
    },
    closeModal() {
      this.$store.commit("OpenDialogInclusaoMassa", false);
      this.movconta = this.newMovconta();
      this.tipoCadastro["cliente"].value = null;
      this.tipoCadastro["dependente"].value = null;
      this.tipoCadastro["funcionario"].value = null;
      this.tipoCadastro["fornecedor"].value = null;
      this.errors = [];
    },
    saveReceitaAgrupada() {
      this.realizandoAcaoReceitas = true;
      this.errors = [];
      let mov = JSON.parse(JSON.stringify(this.movconta));

      let dados = {
        vencimento: mov.vencimento,
        referencia: mov.referencia,
        descricao: mov.descricao,
        valor: parseFloat(mov.valor.replace(",", ".")),
        confirmar: false
      };
      dados.gateway_id = mov.gateway_id ? mov.gateway_id.id : null ;
      if (this.tipoCadastro["ativo"].value !== null) {
        if (this.tipoCadastro["ativo"].value !== "Nulo") {
          dados.ativo =
            this.tipoCadastro["ativo"].value === "Sim" ? true : false;
        }
      }
      if (this.tipoCadastro["cliente"].value !== null) {
        if (this.tipoCadastro["cliente"].value !== "Nulo") {
          dados.cliente =
            this.tipoCadastro["cliente"].value === "Sim" ? true : false;
        }
      }
      if (this.tipoCadastro["dependente"].value !== null) {
        if (this.tipoCadastro["dependente"].value !== "Nulo") {
          dados.dependente =
            this.tipoCadastro["dependente"].value === "Sim" ? true : false;
        }
      }
      if (this.tipoCadastro["funcionario"].value !== null) {
        if (this.tipoCadastro["funcionario"].value !== "Nulo") {
          dados.funcionario =
            this.tipoCadastro["funcionario"].value === "Sim" ? true : false;
        }
      }
      if (this.tipoCadastro["fornecedor"].value !== null) {
        if (this.tipoCadastro["fornecedor"].value !== "Nulo") {
          dados.fornecedor =
            this.tipoCadastro["fornecedor"].value === "Sim" ? true : false;
        }
      }
      let url = this.isDespesa
        ? "/financeiro/add-multiple/despesa"
        : "/financeiro/add-multiple/receita";
      axios
        .post(url, dados)
        .then(res => {
          if (res.data.success) {
            Dialog.create({
              title: this.isDespesa
                ? "Despesas Agrupadas"
                : "Receitas Agrupadas",
              message: res.data.message,
              cancel: "Cancelar",
              persistent: true
            })
              .onOk(() => {
                Loading.show();
              })
              .onOk(() => {
                dados.confirmar = true;
                axios
                  .post(url, dados)
                  .then(res => {
                    if (res.data.success) {
                      Dialog.create({
                        title: "Ação Realizada com Sucesso!",
                        message: res.data.message,
                        class: "bg-positive text-white",
                        ok: {
                          "text-color": "green",
                          color: "white"
                        }
                      });
                      this.closeModal();
                      this.movconta = this.newMovconta();
                      for (let i = 0; i < this.tipoCadastro.length; i++) {
                        this.tipoCadastro[i].value = null;
                      }
                      if (this.$route.path === "/receitas") {
                        this.$store.dispatch("receitasAgrupadas");
                        this.$store.dispatch("resetPagingReceitas");
                        this.$store.dispatch("listarReceitas");
                      }
                      if (this.$route.path === "/despesas") {
                        this.$store.dispatch("despesasAgrupadas");
                        this.$store.dispatch("resetPagingDespesas");
                        this.$store.dispatch("listarDespesas");
                      }
                    } else {
                      Dialog.create({
                        title: "Ação não realizada!",
                        message: res.data.message,
                        class: "bg-warning text-white",
                        ok: {
                          "text-color": "orange",
                          color: "white"
                        }
                      });
                    }
                    this.realizandoAcaoReceitas = false;
                  })
                  .catch(() => {
                    this.realizandoAcaoReceitas = false;
                    Dialog.create({
                      title:
                        "Não foi possível contactar ou não possui permissão!",
                      class: "bg-negative text-white",
                      ok: {
                        "text-color": "red",
                        color: "white"
                      }
                    });
                  });
              })
              .onCancel(() => {
                Loading.hide();
                this.realizandoAcaoReceitas = false;
              })
              .onDismiss(() => {
                Loading.hide();
                this.realizandoAcaoReceitas = false;
              });
          } else {
            this.errors = res.data.errors;
            let elm = document.getElementById("cardInclusaoMassa");
            elm.scrollTop = 0;
            this.realizandoAcaoReceitas = false;
          }
        })
        .catch(() => {
          Notify.create({
            message: "Não foi possível contactar ou não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.realizandoAcaoReceitas = false;
        });
    }
  },
  computed: {
    ...mapGetters(["contas", "findGateways"]),
    OpenDialogInclusaoMassa: {
      get() {
        return this.$store.getters.OpenDialogInclusaoMassa;
      },
      set() {}
    }
  }
};
</script>

<style lang="stylus" scoped></style>
