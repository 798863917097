<template>
  <div id="tagsCadastro">
    <q-dialog v-model="OpenDialogTags" persistent>
      <q-card class="col" style="max-width: 400px">
        <q-card-section class="row items-center">
          <div class="text-h6">Adicionar Tag</div>
          <q-space />
          <q-btn icon="close" clickable flat round dense v-close-popup @click="closeModal" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-list class="rounded-borders" style="max-width: 600px">
            <q-item-label header>Tags Disponíveis</q-item-label>

            <div v-for="(tag, i) in allTags" :key="tag + i">
              <q-item>
                <q-item-section top>
                  <q-item-label lines="1" v-if="!tag.edit">
                    <span class="text-weight-medium">{{tag.title}}</span>
                    <!-- <span class="text-grey-8">&nbsp;-&nbsp;{{tag.created | formatDate}}</span> -->
                  </q-item-label>
                  <!-- <q-item-label caption lines="1">Criado em {{tag.created | formatDate}}</q-item-label> -->
                  <q-item-label
                    caption
                    lines="1"
                    v-if="!tag.edit"
                  >Última alteração em {{tag.modified | formatDate}}</q-item-label>
                  <q-item-label lines="1" v-if="tag.edit">
                    <q-input v-model="tag.title" label="Editar Tag" />
                  </q-item-label>
                </q-item-section>

                <q-item-section top side>
                  <div class="text-grey-8 q-gutter-xs">
                    <q-btn
                      v-if="tag.edit"
                      clickable
                      @click.exact="deleteTag(tag, i)"
                      class="bg-negative text-white"
                      size="12px"
                      flat
                      dense
                      round
                      icon="delete_forever"
                    />
                    <q-btn
                      v-if="!tag.edit"
                      clickable
                      @click.exact="editTag(tag, i)"
                      size="12px"
                      flat
                      dense
                      round
                      icon="edit"
                    />
                    <q-btn
                      v-if="!tag.edit"
                      clickable
                      @click.exact="saveTag(tag, i)"
                      size="12px"
                      flat
                      dense
                      round
                      icon="add"
                      :loading="tag.save"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                    <q-btn
                      v-if="tag.edit"
                      clickable
                      @click.exact="saveEditTag(tag, i)"
                      class="cursor-pointer bg-positive text-white"
                      size="12px"
                      flat
                      dense
                      round
                      icon="check"
                      :loading="savingEditTag"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>

                     <q-btn
                      v-if="tag.edit"
                      clickable
                      @click.exact="tag.edit = !tag.edit"
                      class="bg-orange text-white"
                      size="12px"
                      flat
                      dense
                      round
                      icon="close"
                    />

                    <!-- <q-btn size="12px" flat dense round icon="more_vert" /> -->
                  </div>
                </q-item-section>
              </q-item>

              <q-separator spaced />
            </div>
            <q-item>
              <q-input v-model="tagName" label="Nova Tag" style="width: 100%">
                <template v-slot:append>
                  <q-btn
                    :loading="salvarTag"
                    name="check"
                    clickable
                    @click.exact="saveNewTag()"
                    icon="check"
                    dense
                    class="cursor-pointer"
                    :class="{'bg-positive': !salvarTag, 'bg-grey': salvarTag}"
                    color="text-white"
                  >
                    <template v-slot:loading>
                      <q-spinner color="white" size=".7em" />
                    </template>
                  </q-btn>
                </template>
              </q-input>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";
export default {
  name: "tags-cadastros",
  data() {
    return {
      tagName: "",
      text: "",
      salvarTag: false,
      savingEditTag: false,
    };
  },
  methods: {
    saveNewTag() {
      if (this.tagName === "" || this.tagName === " ") {
        Notify.create({
          message: "Tag Vazia",
          color: "orange",
          position: "top-right"
        });
        return;
      }
      
      this.salvarTag = true;
      let dados = { title: this.tagName };

      axios
        .post("/tags/add", dados)
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            this.errors = res.data.errors;
            return;
          }

          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          this.tagName = "";
          this.$store.dispatch("addTag");
          this.salvarTag = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.salvarTag = false;
        });
    },
    closeModal() {
      this.$store.commit("OpenDialogTags", false);
      this.$store.commit("dadosGrupo", {});
    },
    editTag(value, key) {
      value.edit = true;
      this.tagName = this.tagName === " " ? "" : " ";
    },
    saveTag(tag, tagIndex) {
      this.tagName = this.tagName === " " ? "" : " ";

      this.allTags[tagIndex].save = true;
      let dados = JSON.parse(JSON.stringify(this.dadosGrupo));
      
      if (tag) {
        dados.tag_id = tag.id;
        if (!dados.ids) {
          dados.ids = [parseInt(this.$route.params.id)];
        }

        axios
          .post("/cadastros/add-tag", dados)
          .then(res => {
            if (!res.data.success) {
              Notify.create({
                message: res.data.message,
                color: "orange",
                position: "top-right"
              });
              return;
            }

            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });

            if (this.$route.name === "cadastro_view") {
              this.$store.dispatch("getTags", this.$route.params.id);
            }

            this.closeModal();

            this.allTags[tagIndex].save = false;
          })
          .catch(() => {
            Notify.create({
              message:
                "Não foi possível contactar, ou você não possui permissão!",
              color: "red",
              position: "top-right"
            });
            this.allTags[tagIndex].save = false;
          });
      }
    },
    deleteTag(value) {
      Dialog.create({
        title: value.title,
        message:
          "Excluir permanentemente a tag?<br>A <strong class='text-red'>tag será excluída</strong> em todos os cadastros.",
        html: true,
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post("/tags/delete/" + value.id)
            .then(res => {
              if (res.data.success) {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
                this.$store.dispatch("getTags", this.$route.params.id);
                this.$store.dispatch("addTag");
              } else {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
              }
              Loading.hide();
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
              Loading.hide();
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    saveEditTag(value) {
      this.savingEditTag = true;
      let dados = {
        title: value.title
      };
      Dialog.create({
        title: value.title,
        message:
          "Deseja editar esta tag?\n Será editado em todos os cadastros.",
        html: true,
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post("/tags/edit/" + value.id, dados)
            .then(res => {
              if (res.data.success) {
                this.$store.dispatch("addTag");
                this.$store.dispatch("getTags", this.$route.params.id);

                Notify.create({
                  message: res.data.message,
                  color: "green",
                  position: "top-right"
                });
              } else {
                this.errorsEdit = res.data.errors;

                Notify.create({
                  message: res.data.message,
                  color: "orange",
                  position: "top-right"
                });
              }
              this.savingEditTag = false;
              Loading.hide();
            })
            .catch(() => {
              Notify.create({
                message:
                  "Não foi possível contactar, ou você não possui permissão!",
                color: "red",
                position: "top-right"
              });
              this.savingEditTag = false;
              Loading.hide();
            });
        })
        .onCancel(() => {
          this.savingEditTag = false;
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    }
  },
  computed: {
    ...mapGetters(["OpenDialogTags", "dadosGrupo", "allTags"])  
  }
};
</script>

<style lang="stylus" scoped></style>
