<template id="calendario">
  <q-page class="row full-height">
      <sem-permissao v-if="!isPermissoCalendario"></sem-permissao>
      <div v-else>
         <div class="fixo">
            <FullCalendar
              ref="fullCalendar"
              defaultView="listWeek"
              :plugins="calendarPlugins"
              :weekends="option.agendas_weekends"
              :fixedWeekCount="false"
              :allDaySlot="option.agendas_todo_ocultar"
              :minTime="option.agendas_mintime"
              :maxTime="option.agendas_maxtime"
              :selectable="true"
              :navLinks="true"
              :eventLimit="true"
              :editable="false"
              height="parent"
              :locale="traducao"
              :customButtons="{
                evento: {
                  text: 'Novo evento',
                  click: () => {
                    modalAbreEvento('Novo evento');
                  }
                },
                prev: {
                  text: 'prev',
                  click: event => {
                    filtroEventos(event, 'prev');
                  }
                },
                next: {
                  text: 'next',
                  click: event => {
                    filtroEventos(event, 'next');
                  }
                }
              }"
              :header="{
                left: 'prev,next today,evento',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
              }"
              :events="eventos"
              @eventClick="editarEvento"
              @dateClick="selecionarData"
            />
          </div>
          <div>
            <q-page-sticky position="top-right" :offset="[0, 90]">
              <q-btn
                :color="abrirBarraLateral ? 'red-7' : 'blue-7'"
                :icon="abrirBarraLateral ? 'close' : 'settings'"
                class="rotate-0"
                style="border-top-left-radius: 50px;border-bottom-left-radius: 50px;"
                @click="abrirBarraLateral = !abrirBarraLateral"
              >
                <q-tooltip
                  :content-class="
                    abrirBarraLateral
                      ? 'bg-negative text-white text-center'
                      : 'bg-secondary text-white text-center'
                  "
                  >{{
                    abrirBarraLateral ? "Fechar Opções Avançadas" : "Opções Avançadas"
                  }}</q-tooltip
                >
              </q-btn>
            </q-page-sticky>
            <q-drawer
              side="right"
              v-model="abrirBarraLateral"
              bordered
              :width="320"
              :breakpoint="500"
              behavior="default"
              content-class="bg-white"
            >
              <q-scroll-area class="fit">
                <div class="q-pa-sm">
                  <div>
                    <q-list
                      class="rounded-borders"
                      style="max-width: 350px; margin-left: -10px;width: 320px;"
                    >
                      <q-item class="q-mt-md q-pa">
                        <q-item-section>
                          <q-item-label><b>Agendas</b></q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-btn
                            icon="add"
                            flat
                            round
                            dense
                            @click="modalAbreAgenda()"
                            color="primary"
                          />
                        </q-item-section>
                      </q-item>
                      <q-item v-for="agenda in agendas" :key="agenda.id" dense>
                        <q-item-section>
                          <q-item-label>
                            <q-checkbox
                              :label="agenda.titulo"
                              v-model="agenda.marcada"
                              @input="marcarAgenda(agenda)"
                            />
                          </q-item-label>
                        </q-item-section>

                        <q-item-section side>
                          <q-btn
                            flat
                            round
                            dense
                            :style="{
                              backgroundColor: agenda.color
                            }"
                          />
                        </q-item-section>
                        <q-item-section side>
                          <q-btn
                            icon="edit"
                            flat
                            round
                            dense
                            @click="editarAgendamento(agenda)"
                            color="primary"
                          />
                        </q-item-section>
                      </q-item>
                      <q-item dense>
                        <q-item-section side>
                          <div class="q-mt-lg">
                            <q-btn
                              color="primary"
                              @click="modalConfig()"
                              icon="build"
                              size="13px"
                              label="Configurações calendário"
                            />
                          </div>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                </div>
              </q-scroll-area>
            </q-drawer>
          </div>
          <div>
            <q-dialog v-model="modalAgenda" persistent>
              <q-card style="width: 400px; max-width: 80vw;">
                <q-card-section class="row items-center">
                  <div class="text-h6">
                    {{ agenda.id ? "Editar" : "Nova" }} Agenda
                  </div>
                  <q-space />
                  <q-btn icon="close" flat round dense @click="modalAgenda = false" />
                </q-card-section>
                <q-separator />
                <q-card-section>
                  <q-form class="q-gutter-md" @submit.prevent="salvarAgenda()">
                    <div class="q-mt-md">
                      <q-input
                        bottom-slots
                        :error="errors.titulo ? true : false"
                        label="Titulo:"
                        v-model="agenda.titulo"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errors.titulo"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </div>

                    <div
                      :style="{
                        backgroundColor: agenda.color,
                        height: '40px',
                        width: '350px'
                      }"
                      class="colorSelect text-white"
                    >
                      <label>Cor da agenda e eventos.</label>
                    </div>
                    <div class="q-mt-md">
                      <q-color
                        v-model="agenda.color"
                        default-view="palette"
                        :palette="colors"
                        no-header
                        no-footer
                      />
                    </div>

                    <div align="right" class="text-primary">
                      <q-btn
                        icon="delete"
                        v-if="agenda.id"
                        type="button"
                        label="Excluir"
                        @click="deletarAgenda(agenda.id)"
                        flat
                        class="q-ml-sm text-red"
                      />
                      <q-btn
                        type="submit"
                        label="Salvar"
                        class="bg-green-6 text-white"
                        :loading="carregamentoAgenda"
                      >
                        <template v-slot:loading>
                          <q-spinner color="grey-10" size="1em" />
                        </template>
                      </q-btn>
                    </div>
                  </q-form>
                </q-card-section>
                <q-separator />
              </q-card>
            </q-dialog>
          </div>
          <div>
            <q-dialog v-model="modalEvento" persistent>
              <q-card
                :style="
                  eventoSalvo
                    ? 'width: 920px; max-width: 80vw;'
                    : 'width: 600px; max-width: 80vw;'
                "
              >
                <q-card-section class="row items-center">
                  <div class="text-h6">
                    {{ evento.id ? "Editar" : "Novo" }} Evento
                  </div>
                  <q-space />
                  <q-btn icon="close" flat round dense @click="modalEventoClose()" />
                </q-card-section>
                <q-separator />
                <div class="row q-pa-md">
                  <div
                    :class="
                      !eventoSalvo
                        ? 'col-12 col-md-12 q-pl-sm'
                        : 'col-12 col-md-8 q-pl-sm'
                    "
                  >
                    <q-card-section>
                      <q-form class="q-gutter-md" @submit.prevent="salvarEvento()">
                        <div class="q-mt-md text-center">
                          <q-btn-toggle
                            v-model="evento.status"
                            toggle-color="primary"
                            :options="status"
                          />
                        </div>

                        <q-select
                          dense
                          v-model="evento.agenda_id"
                          option-label="titulo"
                          option-value="id"
                          map-options
                          emit-value
                          :error="errors.agenda_id ? true : false"
                          :options="agendas"
                          label="Agenda"
                        >
                          <template v-slot:error>
                            <span
                              v-for="(error, i) in errors.agenda_id"
                              :key="error + i"
                              >{{ error }}</span
                            >
                          </template>
                        </q-select>

                        <div class="row ">
                          <div class="col-4 col-md-4" style="max-width: 300px">
                            <q-input
                              v-model="evento.start"
                              :error="errors.dtini ? true : false"
                              @click="$refs.start.show()"
                              :disable="evento.status === 'Adiado' ? true : false"
                            >
                              <template v-slot:prepend>
                                <q-icon name="event" class="cursor-pointer">
                                  <q-popup-proxy
                                    transition-show="scale"
                                    transition-hide="scale"
                                    ref="start"
                                    :cover="false"
                                    anchor="bottom left"
                                  >
                                    <q-date
                                      v-model="evento.start"
                                      :mask="
                                        evento.allDay
                                          ? 'DD/MM/YYYY'
                                          : 'DD/MM/YYYY HH:mm'
                                      "
                                      :locale="langDate"
                                      minimal
                                      today-btn
                                    />
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                              <template v-slot:append>
                                <q-icon
                                  name="access_time"
                                  class="cursor-pointer"
                                  v-if="!evento.allDay"
                                >
                                  <q-popup-proxy
                                    transition-show="scale"
                                    transition-hide="scale"
                                    :cover="false"
                                    anchor="bottom left"
                                  >
                                    <q-time
                                      v-model="evento.start"
                                      :mask="
                                        evento.allDay
                                          ? 'DD/MM/YYYY'
                                          : 'DD/MM/YYYY HH:mm'
                                      "
                                      format24h
                                    />
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                              <template v-slot:error>
                                <span
                                  v-for="(error, i) in errors.dtini"
                                  :key="error + i"
                                  >{{ error }}</span
                                >
                              </template>
                            </q-input>
                          </div>

                          <div class="col-1 col-md-1 center">
                            <span class="enter">Até</span>
                          </div>

                          <div class="col-4 col-md-4" style="max-width: 300px">
                            <q-input
                              v-model="evento.end"
                              :error="errors.dtfim ? true : false"
                              @click="$refs.end.show()"
                              :disable="evento.status === 'Adiado' ? true : false"
                            >
                              <template v-slot:prepend>
                                <q-icon name="event" class="cursor-pointer">
                                  <q-popup-proxy
                                    transition-show="scale"
                                    transition-hide="scale"
                                    ref="end"
                                    :cover="false"
                                    anchor="bottom left"
                                  >
                                    <q-date
                                      v-model="evento.end"
                                      :mask="
                                        evento.allDay
                                          ? 'DD/MM/YYYY'
                                          : 'DD/MM/YYYY HH:mm'
                                      "
                                      :locale="langDate"
                                      minimal
                                      today-btn
                                    />
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                              <template v-slot:append>
                                <q-icon
                                  name="access_time"
                                  class="cursor-pointer"
                                  v-if="!evento.allDay"
                                >
                                  <q-popup-proxy
                                    transition-show="scale"
                                    transition-hide="scale"
                                    :cover="false"
                                    anchor="bottom left"
                                  >
                                    <q-time
                                      v-model="evento.end"
                                      :mask="
                                        evento.allDay
                                          ? 'DD/MM/YYYY'
                                          : 'DD/MM/YYYY HH:mm'
                                      "
                                      format24h
                                    />
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                              <template v-slot:error>
                                <span
                                  v-for="(error, i) in errors.dtfim"
                                  :key="error + i"
                                  >{{ error }}</span
                                >
                              </template>
                            </q-input>
                          </div>

                          <div class="col-3 col-md-3 center">
                            <q-toggle
                              dense
                              label="Dia todo"
                              color="green"
                              v-model="evento.allDay"
                            />
                          </div>
                        </div>

                        <div class="title" v-if="evento.status === 'Adiado'">
                          <h1>Reagendamento</h1>
                        </div>

                        <div class="row " v-if="evento.status === 'Adiado'">
                          <div class="col-5 col-md-5" style="max-width: 300px">
                            <q-input
                              v-model="reagendamento.dtini"
                              :error="errors.reagendamentoDtini ? true : false"
                              @click="$refs.startReagendado.show()"
                            >
                              <template v-slot:prepend>
                                <q-icon name="event" class="cursor-pointer">
                                  <q-popup-proxy
                                    transition-show="scale"
                                    transition-hide="scale"
                                    ref="startReagendado"
                                    :cover="false"
                                    anchor="bottom left"
                                  >
                                    <q-date
                                      v-model="reagendamento.dtini"
                                      :mask="
                                        evento.allDay
                                          ? 'DD/MM/YYYY'
                                          : 'DD/MM/YYYY HH:mm'
                                      "
                                      :locale="langDate"
                                      minimal
                                      today-btn
                                    />
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                              <template v-slot:append>
                                <q-icon
                                  name="access_time"
                                  class="cursor-pointer"
                                  v-if="!evento.allDay"
                                >
                                  <q-popup-proxy
                                    transition-show="scale"
                                    transition-hide="scale"
                                    :cover="false"
                                    anchor="bottom left"
                                  >
                                    <q-time
                                      v-model="reagendamento.dtini"
                                      :mask="
                                        evento.allDay
                                          ? 'DD/MM/YYYY'
                                          : 'DD/MM/YYYY HH:mm'
                                      "
                                      format24h
                                    />
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                              <template v-slot:error>
                                <span
                                  v-for="(error, i) in errors.reagendamentoDtini"
                                  :key="error + i"
                                  >{{ error }}</span
                                >
                              </template>
                            </q-input>
                          </div>

                          <div class="col-1 col-md-1 center">
                            <span class="enter">Até</span>
                          </div>

                          <div class="col-5 col-md-5" style="max-width: 300px">
                            <q-input
                              v-model="reagendamento.dtfim"
                              :error="errors.reagendamentoDtfim ? true : false"
                              @click="$refs.endReagendado.show()"
                            >
                              <template v-slot:prepend>
                                <q-icon name="event" class="cursor-pointer">
                                  <q-popup-proxy
                                    transition-show="scale"
                                    transition-hide="scale"
                                    ref="endReagendado"
                                    :cover="false"
                                    anchor="bottom left"
                                  >
                                    <q-date
                                      v-model="reagendamento.dtfim"
                                      :mask="
                                        evento.allDay
                                          ? 'DD/MM/YYYY'
                                          : 'DD/MM/YYYY HH:mm'
                                      "
                                      :locale="langDate"
                                      minimal
                                      today-btn
                                    />
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                              <template v-slot:append>
                                <q-icon
                                  name="access_time"
                                  class="cursor-pointer"
                                  v-if="!evento.allDay"
                                >
                                  <q-popup-proxy
                                    transition-show="scale"
                                    transition-hide="scale"
                                    :cover="false"
                                    anchor="bottom left"
                                  >
                                    <q-time
                                      v-model="reagendamento.dtfim"
                                      :mask="
                                        evento.allDay
                                          ? 'DD/MM/YYYY'
                                          : 'DD/MM/YYYY HH:mm'
                                      "
                                      format24h
                                    />
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                              <template v-slot:error>
                                <span
                                  v-for="(error, i) in errors.reagendamentoDtfim"
                                  :key="error + i"
                                  >{{ error }}</span
                                >
                              </template>
                            </q-input>
                          </div>
                        </div>

                        <!-- <q-select
                    dense
                    label="Serviços"
                  > -->
                        <!-- <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                      <template v-slot:error>
                      <span v-for="(error, i) in errors.agenda_id" :key="error + i">{{error}}</span>
                    </template> -->
                        <!-- </q-select> -->

                        <div>
                          <q-editor v-model="evento.title" min-height="5rem" />
                          <div class="q-field__bottom">
                            <div class="q-field__messages col error">
                              <span
                                v-for="(error, i) in errors.descricao"
                                :key="error + i"
                                >{{ error }}</span
                              >
                            </div>
                          </div>
                        </div>

                        <!-- <div class="q-mt-md">
                      <q-toggle
                      dense
                      label="Repete"
                      color="green"
                      v-model="evento.repete"
                      />
                    </div> -->
                        <!-- <div class="q-mt-md" v-if="evento.repete">
                        <q-select
                        v-model="diasSelecionados"
                        use-input
                        use-chips
                        multiple
                        input-debo
                        :options="diasSemana"
                      />
                    </div> -->

                        <div align="right" class="text-primary">
                          <q-btn
                            icon="delete"
                            v-if="evento.id"
                            type="button"
                            label="Excluir"
                            @click="deletarEvento(evento.id)"
                            flat
                            class="q-ml-sm text-red"
                          />
                          <q-btn
                            type="submit"
                            label="Salvar"
                            class="bg-green-6 text-white q-mr-sm"
                            :loading="carregamentoEvento"
                          >
                            <template v-slot:loading>
                              <q-spinner color="grey-10" size="1em" />
                            </template>
                          </q-btn>
                          <q-btn
                            label="Histórico"
                            color="primary"
                            class="primary text-white"
                            @click="historicoEventoAgendamento(evento.id)"
                            v-if="eventoSalvo"
                          />
                        </div>
                      </q-form>
                    </q-card-section>
                  </div>
                  <div
                    :class="
                      eventoSalvo
                        ? 'col-12 col-md-4 q-pl-sm'
                        : 'col-12 col-md-12 q-pl-sm'
                    "
                    v-if="eventoSalvo"
                  >
                    <div class="col-12">
                      <autocomplete
                        title=" Adicionar Participantes"
                        :filter="filterFn"
                        :carregando="carregando"
                        :options="options"
                        :formatDisplay="formatDisplay"
                        :create="createValue"
                        @resultAutocomplete="resultEmitRecebe"
                        :display="displayMontar"
                        tipoConsulta="cadastro"
                        :reset="true"
                      />
                    </div>
                    <div class="q-mt-sm">
                      <q-chip
                        removable
                        @remove="deleteCadastro(convidado.id)"
                        icon="people"
                        v-for="(convidado, index) in convidados"
                        :key="index"
                        dense
                        :label="convidado.nome"
                      />
                    </div>
                  </div>
                </div>
              </q-card>
            </q-dialog>
          </div>
          <div>
            <q-dialog v-model="configCalender" persistent>
              <q-card class="widthDialogConfig">
                <q-card-section class="row items-center">
                  <div class="text-h6">
                    Configurações Calendário
                  </div>
                  <q-space />
                  <q-btn icon="close" flat round dense @click="modalConfig()" />
                </q-card-section>
                <q-separator />
                <q-card-section>
                  <q-form class="q-gutter-md">
                    <div>
                      <div class="row">
                        <div class="col-12 col-md-6 q-pa-md">
                          <div class="q-gutter-md row">
                            <div class="col col-md-5">
                              <q-input
                                v-model="configEdit.agendas_mintime"
                                mask="time"
                                label="Hora inicial"
                                :rules="['time']"
                              >
                                <template v-slot:append>
                                  <q-icon name="access_time" class="cursor-pointer">
                                    <q-popup-proxy
                                      transition-show="scale"
                                      transition-hide="scale"
                                    >
                                      <q-time
                                        v-model="configEdit.agendas_mintime"
                                        format24h
                                      />
                                    </q-popup-proxy>
                                  </q-icon>
                                </template>
                              </q-input>
                            </div>
                            <div class="col col-md-5">
                              <q-input
                                v-model="configEdit.agendas_maxtime"
                                mask="time"
                                label="Hora Final"
                                :rules="['time']"
                              >
                                <template v-slot:append>
                                  <q-icon name="access_time" class="cursor-pointer">
                                    <q-popup-proxy
                                      transition-show="scale"
                                      transition-hide="scale"
                                    >
                                      <q-time
                                        v-model="configEdit.agendas_maxtime"
                                        format24h
                                      />
                                    </q-popup-proxy>
                                  </q-icon>
                                </template>
                              </q-input>
                            </div>
                          </div>
                          <div>
                            <q-input
                              bottom-slots
                              label="Tempo de duração dos agendamentos"
                              v-model="configEdit.agendas_intervalo"
                              mask="##"
                            />
                          </div>
                          <div>
                            <q-select
                              label="Visualização padrão"
                              :options="defaultView"
                              option-value="view"
                              option-label="trad"
                              emit-value
                              map-options
                              v-model="configEdit.agendas_defaultview"
                            />
                          </div>
                          <div class="q-mt-lg">
                            <div>
                              <q-list dense>
                                <q-item tag="label" v-ripple>
                                  <q-item-section>
                                    <q-item-label>
                                      Exibir a opção 'Dia todo'?
                                    </q-item-label>
                                  </q-item-section>
                                  <q-item-section avatar>
                                    <q-toggle
                                      v-model="configEdit.agendas_todo_ocultar"
                                    />
                                  </q-item-section>
                                </q-item>
                                <q-item tag="label" v-ripple>
                                  <q-item-section>
                                    <q-item-label>
                                      Exibir os finais de semanas?
                                    </q-item-label>
                                  </q-item-section>
                                  <q-item-section avatar>
                                    <q-toggle v-model="configEdit.agendas_weekends" />
                                  </q-item-section>
                                </q-item>
                              </q-list>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 q-pa-md">
                          <div>
                            <div
                              :style="{
                                backgroundColor:
                                  configEdit.agendas_cor_padrao_cancelamento,
                                height: '40px',
                                width: '100%'
                              }"
                              class="colorSelect text-white"
                            >
                              <label>Cor padrão quando for cancelado o evento.</label>
                            </div>
                            <div class="q-mt-md">
                              <q-color
                                class="widthDialogConfig"
                                v-model="configEdit.agendas_cor_padrao_cancelamento"
                                default-view="palette"
                                :palette="colors"
                                no-header
                                no-footer
                              />
                            </div>
                          </div>
                          <div>
                            <div
                              :style="{
                                backgroundColor: configEdit.agendas_cor_padrao_adiado,
                                height: '40px',
                                width: '100%'
                              }"
                              class="colorSelect text-white"
                            >
                              <label>Cor padrão quando for adiado o evento.</label>
                            </div>
                            <div class="q-mt-md">
                              <q-color
                                class="widthDialogConfig"
                                v-model="configEdit.agendas_cor_padrao_adiado"
                                default-view="palette"
                                :palette="colors"
                                no-header
                                no-footer
                              />
                            </div>
                          </div>
                          <div align="right">
                            <q-btn
                              :loading="salvandoOptions"
                              type="button"
                              label="Salvar"
                              class="q-mt-md  bg-green text-white"
                              @click="editConfigOptions()"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </q-form>
                </q-card-section>
                <q-separator />
              </q-card>
            </q-dialog>
          </div>
          <div>
            <q-dialog v-model="modalHistorico">
              <q-card style="width: 700px; max-width: 80vw;">
                <q-card-section class="q-pt-none">
                  <q-card-section class="row items-center">
                    <div class="text-h6">
                      Histórico
                    </div>
                    <q-space />
                    <q-btn icon="close" flat round dense v-close-popup />
                  </q-card-section>
                  <div class="q-px-lg q-pb-md">
                    <center>
                      <q-spinner
                        color="primary"
                        size="3em"
                        :thickness="2"
                        v-if="carregamentoHistoricoEvento"
                      />
                    </center>

                    <q-timeline>
                      <q-timeline-entry
                        v-for="timeline in timelines"
                        :key="timeline.id"
                        :subtitle="returnSubtitle(timeline)"
                        :icon="getIcone(timeline.icone)"
                      >
                        <template v-slot:title>
                          <h6 class="q-timeline__title">
                            {{ timeline.nome }}
                            <router-link
                              v-if="timeline.cadastro_id !== null"
                              :to="'/cadastros/' + timeline.cadastro_id"
                              style="color: rgba(0,0,0,.87);text-decoration: underline"
                              target="_blank"
                              >{{ acaoFiltroTimeline(timeline.tipo) }}</router-link
                            >
                            <div v-else>{{ acaoFiltroTimeline(timeline.tipo) }}</div>
                          </h6>
                        </template>
                        <div v-html="timeline.corpo"></div>
                      </q-timeline-entry>
                    </q-timeline>
                  </div>
                </q-card-section>
              </q-card>
            </q-dialog>
          </div>
      </div>
  </q-page>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listGridPlugin from "@fullcalendar/list";
import ptbr from "@fullcalendar/core/locales/pt-br";
import * as moment from "moment";
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
import { Notify, Dialog } from "quasar";

export default {
  name: "calendario",
  meta: {
    title: "DSB | Calendário"
  },
  components: {
    FullCalendar
  },
  data() {
    return {
      diasSelecionados: [],
      status: [
        { label: "Agendado", value: "Agendado" },
        { label: "Confirmado", value: "Confirmado" },
        { label: "Realizado", value: "Realizado" },
        { label: "Cancelado", value: "Cancelado" },
        { label: "Adiado", value: "Adiado" }
      ],
      diasSemana: [
        { label: "Domingo", value: 0 },
        { label: "Segunda", value: 1 },
        { label: "Terça", value: 2 },
        { label: "Quarta", value: 3 },
        { label: "Quinta", value: 4 },
        { label: "Sexta", value: 5 },
        { label: "Sabádo", value: 6 }
      ],
      colors: [
        "#027be3",
        "#26a69a",
        "#9c27b0",
        "#21ba45",
        "#c10015",
        "#31ccec",
        "#f2c037",
        "#1d1d1d",
        "#FB581D",
        "#76534E",
        "#AA9B9A",
        "#657A8A",
        "#F34141",
        "#F21A63",
        "#9C29A8",
        "#673CAE",
        "#4150B5",
        "#2795EC",
        "#00AAFA",
        "#03BAD7",
        "#029689",
        "#4FAD56"
      ],
      defaultView: [
        { view: "dayGridWeek", trad: "Semanal dia todo" },
        { view: "timeGridWeek", trad: "Semanal por hora" },
        { view: "timeGridDay", trad: "Diário" },
        { view: "listWeek", trad: "Compromissos" },
        { view: "dayGridMonth", trad: "Mensal" }
      ],
      reagendamento: {
        dtini: null,
        dtfim: null
      },
      modalEvento: false,
      configCalender: false,
      modalAgenda: false,
      abrirBarraLateral: false,
      carregamentoEvento: false,
      carregamentoAgenda: false,
      filtroData: null,
      traducao: ptbr,
      eventos: [],
      agendas: [],
      errors: [],
      agenda: this.newAgenda(),
      evento: this.newEvento(),
      calendarPlugins: [
        timeGridPlugin,
        dayGridPlugin,
        interactionPlugin,
        listGridPlugin
      ],
      option: {},
      configEdit: {},
      salvandoOptions: false,
      intervalo: 30,

      // autocomplete
      carregando: false,
      options: [],
      selectItem: null,
      autocompleteResult: [],
      eventoSalvo: false,
      eventoIdSalvo: null,
      convidados: [],
      // fim
      modalHistorico: false,
      carregamentoHistoricoEvento: true,
      timelines: [],
      icones: {
        table: "list",
        key: "vpn_key",
        upload: "cloud_upload",
        trash: "delete_forever",
        envelope: "mail_outline",
        plus: "add",
        pencil: "mode_edit",
        money: "monetization_on",
        print: "print",
        file: "description"
      },
      timelineAction:[],
      isPermissoCalendario: true
    };
  },
  computed: {
    statusSelecionado() {
      return this.evento.status;
    },
    eventoDiaTodo() {
      return this.evento.allDay;
    },
    ...mapGetters(["cadastroReturn"])
  },
  watch: {
    statusSelecionado: function(status) {
      if (status === "Adiado") {
        this.reagendamento.dtini = moment(this.evento.start, "DD/MM/YYYY HH:mm")
          .add(1, "days")
          .format("DD/MM/YYYY HH:mm");
        this.reagendamento.dtfim = moment(this.evento.end, "DD/MM/YYYY HH:mm")
          .add(1, "days")
          .format("DD/MM/YYYY HH:mm");
      }
    },
    eventoDiaTodo: function(diaTodo) {
      if (diaTodo === true) {
        this.evento.start = moment(this.evento.start, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        );
        this.evento.end = moment(this.evento.end, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        );
      } else {
        this.evento.start = moment(
          this.evento.start,
          "DD/MM/YYYY HH:mm"
        ).format("DD/MM/YYYY HH:mm");
        this.evento.end = moment(this.evento.end, "DD/MM/YYYY HH:mm").format(
          "DD/MM/YYYY HH:mm"
        );
      }
    }
  },
  methods: {
    eventosMensalFiltro(movimento, defaultViewCalendar) {
      let data = this.filtroData;

      data.dtini = moment(data.dtini);
      data.dtfim = moment(data.dtfim);

      if (movimento === "prev") {
        switch (defaultViewCalendar) {
          case "listMonth":
            data.dtini = moment(data.dtini).subtract(1, "months");
            data.dtfim = moment(data.dtfim).subtract(1, "months");
            break;
          case "dayGridMonth":
            data.dtini = moment(data.dtini).subtract(1, "months");
            data.dtfim = moment(data.dtfim).subtract(1, "months");
            break;
          case "timeGridWeek":
            data.dtini = moment(data.dtini).subtract(1, "week");
            data.dtfim = moment(data.dtfim).subtract(1, "week");
            break;
          case "dayGridWeek":
            data.dtini = moment(data.dtini).subtract(1, "week");
            data.dtfim = moment(data.dtfim).subtract(1, "week");
            break;
          case "timeGridDay":
            data.dtini = moment(data.dtini).subtract(1, "days");
            data.dtfim = moment(data.dtfim).subtract(1, "days");
            break;
          default:
            Notify.create({
              message: "Visualização não encontrada.",
              color: "orange",
              position: "top-right"
            });
            break;
        }
      } else {
        switch (defaultViewCalendar) {
          case "listMonth":
            data.dtini = moment(data.dtini).add(1, "months");
            data.dtfim = moment(data.dtfim).add(1, "months");
            break;
          case "dayGridMonth":
            data.dtini = moment(data.dtini).add(1, "months");
            data.dtfim = moment(data.dtfim).add(1, "months");
            break;
          case "timeGridWeek":
            data.dtini = moment(data.dtini).add(1, "week");
            data.dtfim = moment(data.dtfim).add(1, "week");
            break;
          case "dayGridWeek":
            data.dtini = moment(data.dtini).add(1, "week");
            data.dtfim = moment(data.dtfim).add(1, "week");
            break;
          case "timeGridDay":
            data.dtini = moment(data.dtini).add(1, "days");
            data.dtfim = moment(data.dtfim).add(1, "days");
            break;
          default:
            Notify.create({
              message: "Visualização não encontrada.",
              color: "orange",
              position: "top-right"
            });
            break;
        }
      }

      data.dtini = data.dtini.format("DD-MM-YYYY");
      data.dtfim = data.dtfim.format("DD-MM-YYYY");

      this.carregarAgenda(data);
    },
    filtroEventos(event, movimento) {
      let calendarApi = this.$refs.fullCalendar.getApi();
      let defaultViewCalendar = calendarApi.view.type; // Obter o tipo de visualização no calendário, se é timeGridDay,timeGridWeek,dayGridMonth,listMonth.

      if (movimento === "next") {
        calendarApi.next();
        this.eventosMensalFiltro(movimento, defaultViewCalendar);
      }

      if (movimento === "prev") {
        calendarApi.prev();
        this.eventosMensalFiltro(movimento, defaultViewCalendar);
      }
    },
    modalAbreEvento(evento) {
      if (evento && evento.id) {
        this.evento = evento;
      } else {
        this.evento = this.newEvento(evento);
      }
      this.modalEvento = true;
    },
    modalAbreAgenda(agenda) {
      if (agenda && agenda.id) {
        this.agenda = agenda;
      } else {
        this.agenda = this.newAgenda();
      }
      this.modalAgenda = !this.modalAgenda;
    },
    newEvento(evento) {
      let start =
        evento && evento.date
          ? moment(evento.date)
          : moment()
              .add(this.intervalo, "m")
              .minute(0);
      let end = moment(start).add(this.intervalo, "m");
      let title = "Descrição";

      this.errors = [];
      this.reagendamento = {
        dtini: null,
        dtfim: null
      };
      return {
        id: null,
        title: title,
        status: "Agendado",
        start: start.format("DD/MM/YYYY HH:mm"),
        end: end.format("DD/MM/YYYY HH:mm"),
        allDay: false,
        agenda_id: null,
        daysOfWeek: [],
        recorrente: false,
        startTime: null,
        endTime: null,
        startRecur: null,
        endRecur: null,
        color: null
      };
    },
    newAgenda() {
      this.errors = [];
      return {
        id: "",
        titulo: "",
        marcada: true,
        color: "#4285f4"
      };
    },
    salvarAgenda(marcar = false) {
      let url = this.agenda.id
        ? "/agendas/edit/" + this.agenda.id
        : "/agendas/add";
      this.carregamentoAgenda = true;

      axios
        .post(url, this.agenda)
        .then(response => {
          if (response.data.success) {
            if (marcar == false) {
              Notify.create({
                message: response.data.message,
                color: "green",
                position: "top-right"
              });
            }
            this.agenda = this.newAgenda();
            this.modalAgenda = false;
            this.carregarAgenda();
          } else {
            this.errors = response.data.errors;
            Notify.create({
              message: response.data.message,
              color: "orange",
              position: "top-right"
            });
          }

          this.carregamentoAgenda = false;
        })
        .catch(() => {
          this.carregamentoAgenda = false;
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    deletarAgenda(id) {
      Dialog.create({
        title: "ATENÇÃO: Apagar agenda",
        contentStyle: { color: "red" },
        message:
          "Ao apagar agenda, você exclui todos os agendamentos \nConfirma a operação?",
        cancel: "Cancelar",
        persistent: true
      }).onOk(() => {
        axios
          .post("/agendas/delete/" + id)
          .then(response => {
            if (response.data.success) {
              Notify.create({
                message: response.data.message,
                color: "green",
                position: "top-right"
              });
              this.modalAgenda = false;
              this.carregarAgenda();
            } else {
              Notify.create({
                message: response.data.message,
                color: "orange",
                position: "top-right"
              });
            }
          })
          .catch(error => {
            Notify.create({
              message:
                "Não foi possível contactar, ou você não possui permissão!",
              color: "red",
              position: "top-right"
            });
          });
      });
    },
    salvarEvento() {
      let id = !this.evento.id ? this.eventoIdSalvo : this.evento.id;

      let url =
        this.evento.id || this.eventoSalvo
          ? "/agendas/edit-evento/" + id
          : "/agendas/add-evento";
      this.carregamentoEvento = true;

      if (this.evento.daysOfWeek) {
        this.evento.dias_semana = this.diasSelecionados.join(",");
      } else {
        this.evento.dias_semana = null;
      }

      let formato = this.evento.allDay ? "DD-MM-YYYY" : "DD-MM-YYYY HH:mm";
      let dtini = moment(this.evento.start, formato);
      let dtfim = moment(this.evento.end, formato);

      let agenda = this.agendas.filter(agenda => {
        return agenda.id == this.evento.agenda_id;
      });

      let data = {
        descricao: this.evento.title,
        status: this.evento.status,
        dtini: dtini ? dtini.format("YYYY-MM-DD HH:mm") : null,
        dtfim: dtfim ? dtfim.format("YYYY-MM-DD HH:mm") : null,
        agenda_id: agenda.length > 0 ? agenda[0].id : null,
        dias_semana: this.evento.daysOfWeek,
        recorrente: this.evento.recorrente,
        diatodo: this.evento.allDay,
        hrini: this.evento.startTime,
        hrfim: this.evento.endTime,
        dtini_recorrencia: this.evento.startRecur,
        dtfim_recorrencia: this.evento.endRecur,
        color: agenda.length > 0 ? agenda[0].color : null
      };

      if (data.status === "Cancelado") {
        data.color = this.option.agendas_cor_padrao_cancelamento;
      }

      if (data.status === "Adiado") {
        data.color = this.option.agendas_cor_padrao_adiado;

        if (
          this.reagendamento.dtini == null ||
          this.reagendamento.dtfim == null
        ) {
          let errors = {
            reagendamentoDtini: {
              required: "Esse campo não pode ser em branco"
            },
            reagendamentoDtfim: {
              required: "Esse campo não pode ser em branco"
            }
          };
          this.errors = errors;
          this.carregamentoEvento = false;
          return;
        }

        let reagendamentoDtini = moment(
          this.reagendamento.dtini,
          "DD-MM-YYYY HH:mm"
        );
        let reagendamentoDtfim = moment(
          this.reagendamento.dtfim,
          "DD-MM-YYYY HH:mm"
        );
        data.reagendamentoDtini = reagendamentoDtini.format("YYYY-MM-DD HH:mm");
        data.reagendamentoDtfim = reagendamentoDtfim.format("YYYY-MM-DD HH:mm");
      }

      axios
        .post(url, data)
        .then(response => {
          if (response.data.success) {
            Notify.create({
              message: response.data.message,
              color: "green",
              position: "top-right"
            });

            if (this.eventoSalvo == false) {
              this.eventoSalvo = true;
              this.eventoIdSalvo = response.data.evento.id;
            } else {
              this.modalEventoClose();
            }

            this.carregamentoEvento = false;
            this.errors = [];
            this.carregarAgenda();
          } else {
           this.carregamentoEvento = false;
           this.errors = response.data.errors;
            Notify.create({
              message: response.data.message,
              color: "orange",
              position: "top-right"
            });
          }
        })
        .catch(error => {
          this.carregamentoEvento = false;
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    deletarEvento(id) {
      Dialog.create({
        title: "Apagar evento do calendário",
        message: "Deseja excluir evento  ?",
        cancel: "Cancelar",
        persistent: true
      }).onOk(() => {
        axios
          .post("/agendas/delete-evento/" + id)
          .then(response => {
            if (response.data.success) {
              Notify.create({
                message: response.data.message,
                color: "green",
                position: "top-right"
              });
              this.modalEvento = false;
              this.carregarAgenda();
            } else {
              Notify.create({
                message: response.data.message,
                color: "orange",
                position: "top-right"
              });
            }
          })
          .catch(error => {
            Notify.create({
              message:
                "Não foi possível contactar, ou você não possui permissão!",
              color: "red",
              position: "top-right"
            });
          });
      });
    },
    carregarAgenda(params = null) {
      axios
        .get("/agendas", { params: params })
        .then(response => {
          this.agendas = response.data.agendas;
          this.eventos = response.data.eventos;
          this.filtroData = response.data.filtros;

          let config = response.data.options;

          // config.agendas_todo_ocultar = (config.agendas_todo_ocultar === 'true');
          // config.agendas_weekends = (config.agendas_weekends === 'true');

          this.option = config;
          this.configEdit = JSON.parse(JSON.stringify(config));

          let calendarApi = this.$refs.fullCalendar.getApi();
          calendarApi.changeView(this.option.agendas_defaultview);

          this.intervaloEvento();
        })
        .catch(error => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });

          this.isPermissoCalendario = false;
        });
    },
    editarAgendamento(agenda) {
      this.agenda = agenda;
      this.modalAgenda = true;
    },
    editarEvento(event) {
      event.jsEvent.preventDefault();

      let evento = null;

      if (event.event) {
        let formato = event.event.allDay ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm";

        this.eventoIdSalvo = event.event.id;
        this.eventoSalvo = true;
        this.cadastros();

        evento = {
          id: event.event.id,
          agenda_id: event.event.extendedProps.agenda_id,
          status: event.event.extendedProps.status,
          recorrente: event.event.extendedProps.recorrente,
          repeticoes: event.event.extendedProps.repeticoes,
          url: event.event.url,
          color: event.event.color,
          title: event.event.title,
          groupId: event.event.groupId,
          start: event.event.start
            ? moment(event.event.start).format(formato)
            : null,
          end: event.event.end
            ? moment(event.event.end).format(formato)
            : moment(event.event.start).format(formato), // Full Calender, não retorna a data final do evento quando a data inicial e final são iguais, com isso, utilizei a data de start como end.
          allDay: event.event.allDay,
          daysOfWeek: event.event.extendedProps.daysOfWeek,
          startTime: event.event.extendedProps.startTime,
          endTime: event.event.extendedProps.endTime,
          startRecur: event.event.extendedProps.startRecur,
          endRecur: event.event.extendedProps.endRecur,
          className: event.event.className
        };
      }
      this.modalAbreEvento(evento);
    },
    selecionarData(evento) {
      this.modalAbreEvento(evento);
    },
    marcarAgenda(agenda) {
      this.agenda = agenda;
      this.salvarAgenda(true);
    },
    modalConfig() {
      this.configCalender = !this.configCalender;
    },
    editConfigOptions() {
      this.salvandoOptions = true;
      let data = this.configEdit;
      data.agendas_todo_ocultar = data.agendas_todo_ocultar.toString();
      data.agendas_weekends = data.agendas_weekends.toString();
      axios
        .post("/agendas/config/", data)
        .then(response => {
          this.salvandoOptions = false;
          if (response.data.success) {
            Notify.create({
              message: response.data.message,
              color: "green",
              position: "top-right"
            });

            this.option = response.data.options;
            let calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.changeView(this.option.agendas_defaultview);
            this.intervaloEvento();
            this.modalConfig();
          }
        })
        .catch(error => {
          this.salvandoOptions = false;
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    intervaloEvento() {
      this.intervalo = this.option.agendas_intervalo;
    },
    // Funções do autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/cadastros?ativo=1&cliente=1&busca=" + val).then(res => {
          this.carregando = false;
          this.options = res.data.data.cadastros;
        });
      });
    },
    createValue(val, done) {
      Notify.create({
        message: "Convidado não foi encontrado.",
        color: "orange",
        position: "top-right"
      });
    },
    formatDisplay(result) {
      return result.nome;
    },
    resultEmitRecebe(result) {
      this.newCadastros(result);
    },
    displayMontar(value) {
      return !value.nome ? value.name : value.nome;
    },
    // convidados
    cadastros() {
      axios
        .get("agendas/cadastros/" + this.eventoIdSalvo)
        .then(res => {
          this.convidados = res.data.cadastros;
        })
      .catch(error => {
        Notify.create({
          message:
            "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
    },
    deleteCadastro(id) {
      let data = {
        cadastro_id: id,
        evento_id: this.eventoIdSalvo,
        action: "deletar",
      };

      axios
        .post("/agendas/cadastros", data)
        .then(res => {
          this.cadastros();
        })
        .catch(error => {
          Notify.create({
            message: "Não foi possivel adicionar novo convidado.",
            color: "red",
            position: "top-right"
          });
        });
    },
    newCadastros(result) {
      let data = {
        cadastro_id: result.id,
        evento_id: this.eventoIdSalvo
      };
      axios
        .post("/agendas/cadastros", data)
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          } else {
            this.cadastros();
          }
        })
        .catch(error => {
          Notify.create({
            message: "Não foi possivel adicionar novo convidado.",
            color: "red",
            position: "top-right"
          });
        });
    },
    modalEventoClose() {
      this.modalEvento = false;
      this.eventoIdSalvo = null;
      this.eventoSalvo = false;
      this.convidados = [];
    },
    historicoEventoAgendamento(id) {
      let verificarID = !id ? this.eventoIdSalvo : id;

      this.timelines = [];
      this.modalHistorico = true;

      axios
        .get("/timelines", {
          params: {
            evento_id: verificarID
          }
        })
        .then(response => {
          let restimelines = response.data.timelines;
          this.timelines = this.timelines.concat(restimelines);
          this.timelineAction = response.data.tipos;
          this.carregamentoHistoricoEvento = false;
        })
        .catch(error => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    returnSubtitle(timeline) {
      return (
        "em " +
        moment(timeline.created).format("DD/MM/YYYY") +
        " às " +
        moment(timeline.created).format("HH:mm") +
        "h"
      );
    },
    getIcone(value) {
      return this.icones[value];
    },
    acaoFiltroTimeline(value) {
      if (this.timelineAction[value] !== undefined) {
        return this.timelineAction[value].label;
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.carregarAgenda();
    }, 300);
  }
};
</script>

<style scoped>
@import "../styles/calendarQuasar.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~@fullcalendar/list/main.css";

:root {
  --widthDialogConfig: 750px;
  --widthDialogConfigMax: 90vw;
}
.expand-width {
  width: 100%;
}
.colorSelect,
.center {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px 10px;
}
.error {
  color: red;
}
.title h1 {
  font-size: 1rem;
  line-height: inherit;
  font-weight: bold;
}
.widthDialogConfig {
  width: var(--widthDialogConfig);
  max-width: var(--widthDialogConfigMax);
}
.fixo {
  position: fixed;
  padding: 0px 20px;
  left: 0;
  right: 0;
}

@media screen and (min-width: 640px) {
  .fixo {
    position: fixed;
    top: 80px;
    left: 80px;
    right: 0;
    bottom: 0;
    padding: unset;
    padding-right: 20px;
  }
}
</style>
