<template>
  <q-dialog v-model="openDialog" persistent>
    <q-card class="scroll" style="width: 100%; max-width: 800px">
      <q-card-section class="row items-center">
        <div class="text-h6">
          Comissões: {{ recorrencia.descricao }} R$ {{ recorrencia.valor | currency }}
        </div>
        <q-space />
        <q-btn flat round dense clickable v-close-popup icon="close" @click="fecharModal()" />
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pa-md">
        <div class="row q-col-gutter-x-md">
          <div class="col-12 col-md-6">
            <q-select
              use-input
              bottom-slots
              label="Cadastro"
              option-value="id"
              option-label="nome"
              input-debounce="200"
              ref="comissoesCadastro"
              :options="cadastros"
              :error="Boolean(errosComissoes.cadastro_id)"
              @input="pulaCampo('comissoesPlanodeconta')"
              @filter="(val, update) => buscarCadastro(val, update)"
              v-model="comissao.cadastro"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">Sem resultados</q-item-section>
                </q-item>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in errosComissoes.cadastro_id" :key="erro + i">
                  {{ erro }}
                </span>
              </template>
            </q-select>
          </div>

          <div class="col-12 col-md-6">
            <q-select
              use-input
              clearable
              bottom-slots
              option-value="id"
              input-debounce="0"
              option-label="titulo"
              label="Planos de contas"
              ref="comissoesPlanodeconta"
              :options="planosdecontasOptions"
              :error="Boolean(errosComissoes.planodeconta_id)"
              @filter="filterPlanosdecontasComissoes"
              @input="pulaCampo('comissoesQuando')"
              v-model="comissao.planodeconta"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                </q-item>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in errosComissoes.planodeconta_id" :key="erro + i">
                  {{ erro }}
                </span>
              </template>
            </q-select>
          </div>

          <div class="col-12 col-md-6">
            <q-select
              emit-value
              map-options
              bottom-slots
              ref="comissoesQuando"
              :options="comissaoQuandoOptions"
              :error="Boolean(errosComissoes.quando)"
              :label="`Gerar n${comissao.quando === 'liquidacao' ? 'a' : 'o'}`"
              @input="pulaCampo('comissoesItemQuando')"
              v-model="comissao.quando"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                </q-item>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in errosComissoes.quando" :key="erro + i">
                  {{ erro }}
                </span>
              </template>
            </q-select>
          </div>

          <div class="col-12 col-md-6">
            <q-select
              emit-value
              map-options
              bottom-slots
              label="da"
              ref="comissoesItemQuando"
              :options="comissaoItemQuandoOptions"
              :error="Boolean(errosComissoes.item_quando)"
              @input="pulaCampo('comissoesOrigemValor')"
              v-model="comissao.item_quando"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                </q-item>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in errosComissoes.item_quando" :key="erro + i">
                  {{ erro }}
                </span>
              </template>
            </q-select>
          </div>

          <div class="col-12 col-md-6">
            <q-select
              emit-value
              map-options
              bottom-slots
              label="Origem do valor"
              ref="comissoesOrigemValor"
              :options="comissaoOrigemValorOptions"
              :error="Boolean(errosComissoes.origem_valor)"
              @input="pulaCampo('comissoesValor')"
              v-model="comissao.origem_valor"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                </q-item>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in errosComissoes.origem_valor" :key="erro + i">
                  {{ erro }}
                </span>
              </template>
            </q-select>
          </div>

          <div class="col-12 col-md-6" @keydown.enter.prevent="pulaCampo('qDateIniComissao')">
            <q-field
              bottom-slots
              label="Valor"
              :error="Boolean(errosComissoes.valor)"
              v-model="comissao.valor"
            >
              <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                <money
                  ref="comissoesValor"
                  class="q-field__native text-right"
                  :id="id"
                  :value="value"
                  @input="emitValue"
                  v-bind="
                    ['recorrencia', 'parcela'].includes(comissao.origem_valor)
                      ? percentFormat
                      : moneyFormat
                  "
                  v-show="floatingLabel"
                >
                </money>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in errosComissoes.valor" :key="erro + i">
                  {{ erro }}
                </span>
              </template>
            </q-field>
          </div>

          <div class="col-12 col-md-4">
            <q-input
              bottom-slots
              label="Data de início"
              :error="Boolean(errosComissoes.dtini)"
              @click="$refs.qDateIniComissao.show()"
              v-model="comissao.dtini"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateIniComissao"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      minimal
                      today-btn
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      @input="
                        () => {
                          handleDtiniDtfimComissao();
                          pulaCampo('qDateFimComissao');
                        }
                      "
                      v-model="comissao.dtini"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in errosComissoes.dtini" :key="erro + i">
                  {{ erro }}
                </span>
              </template>
            </q-input>
          </div>

          <div class="col-12 col-md-4">
            <q-input
              bottom-slots
              label="Duração em meses"
              ref="comissoesQuantidadeMeses"
              v-model="quantidadeMesesComissao"
              @keydown.enter.prevent="pulaCampo('qDateFimComissao')"
              @blur="handleQuantidadeMesesComissao()"
            />
          </div>

          <div class="col-12 col-md-4">
            <q-input
              clearable
              bottom-slots
              label="Data de fim"
              :error="Boolean(errosComissoes.dtfim)"
              @click="$refs.qDateFimComissao.show()"
              v-model="comissao.dtfim"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateFimComissao"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      minimal
                      today-btn
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      @input="
                        () => {
                          handleDtiniDtfimComissao();
                          $refs.qDateFimComissao.hide();
                        }
                      "
                      v-model="comissao.dtfim"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in errosComissoes.dtfim" :key="erro + i">
                  {{ erro }}
                </span>
              </template>
              <template v-slot:after>
                <q-btn
                  flat
                  round
                  icon="add"
                  color="primary"
                  style="height: 42px"
                  class="align-self-center"
                  :loading="salvando"
                  @click="addComissao()"
                />
              </template>
            </q-input>
          </div>

          <div class="col-12">
            <q-markup-table
              flat
              dense
              class="q-my-md full-width"
              separator="none"
              v-if="recorrencia.cadastros_recorrencias_comissoes"
            >
              <thead>
                <tr>
                  <th>Informações</th>
                  <th>Geração</th>
                  <th>Valor</th>
                  <th style="width: 50px"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="comissao in recorrencia.cadastros_recorrencias_comissoes"
                  :key="comissao.funcionarioId"
                >
                  <td>
                    {{ comissao.cadastro.nome }}
                    <br v-if="comissao.planodeconta" />
                    <span v-if="comissao.planodeconta">{{ comissao.planodeconta.titulo }}</span>
                  </td>
                  <td class="text-left">
                    {{ comissao.quando === 'liquidacao' ? 'Na liquidação' : 'No faturamento' }}
                    da {{ comissao.item_quando === 'recorrencia' ? 'recorrência' : 'parcela' }}
                    <br />
                    <span>Início: {{ formatDate(comissao.dtini) }}</span>
                    <span v-if="comissao.dtfim"> Fim: {{ formatDate(comissao.dtfim) }}</span>
                  </td>
                  <td class="text-right">
                    <span v-if="comissao.origem_valor === 'fixo'">R$</span>
                    {{ comissao.valor | currency }}
                    <span v-if="comissao.origem_valor !== 'fixo'">%</span>
                    <br v-if="comissao.origem_valor !== 'fixo'" />
                    <span v-if="comissao.origem_valor !== 'fixo'"
                      >da
                      {{
                        comissao.origem_valor === 'recorrencia' ? 'recorrência' : 'parcela'
                      }}</span
                    >
                  </td>
                  <td style="width: 50px">
                    <q-btn
                      flat
                      dense
                      size="12px"
                      color="red"
                      icon="delete"
                      :loading="comissoesSalvando.includes(comissao.id)"
                      @click="removeComissao(comissao.id)"
                    />
                  </td>
                </tr>
                <tr v-if="recorrencia.cadastros_recorrencias_comissoes.length === 0">
                  <td colspan="3" align="center">Nenhuma comissão</td>
                </tr>
              </tbody>
            </q-markup-table>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import moment from 'moment';
import { Money } from 'v-money';

import { bus } from '@/main';
import axios from '@/axios-auth';

export default {
  name: 'modal-comissoes-recorrencia',
  components: { Money },
  data() {
    return {
      // Modal
      openDialog: false,
      // Formulário
      recorrencia: {},
      comissao: {},
      quantidadeMesesComissao: null,
      errosComissoes: {},
      // Salvar
      salvando: false,
      comissoesSalvando: [],
      // Opções
      planosdecontasComissoes: [],
      planosdecontasOptions: [],
      cadastros: [],
      comissaoQuandoOptions: [
        {
          label: 'Faturamento',
          value: 'faturamento',
        },
        {
          label: 'Liquidação',
          value: 'liquidacao',
        },
      ],
      comissaoItemQuandoOptions: [
        {
          label: 'Recorrência',
          value: 'recorrencia',
        },
        {
          label: 'Parcela',
          value: 'parcela',
        },
      ],
      // Máscaras
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      percentFormat: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: ' %',
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    // Modal
    abrirModal({ recorrencia }) {
      this.resetarComissao();

      this.recorrencia = recorrencia;

      this.openDialog = true;
    },
    fecharModal() {
      this.openDialog = false;
    },
    // Formulário
    resetarComissao() {
      this.errosComissoes = {};
      this.quantidadeMesesComissao = null;
      this.comissao = {
        cadastro_id: null,
        cadastro: null,
        planodeconta_id: null,
        planodeconta: null,
        dtini: moment().format('DD/MM/YYYY'),
        dtfim: null,
        origem_valor: null,
        valor: 0,
        quando: null,
        item_quando: null,
      };
    },
    // Handlers
    handleDtiniDtfimComissao() {
      const dtini = moment(this.comissao.dtini, 'DD/MM/YYYY');
      const dtfim = moment(this.comissao.dtfim, 'DD/MM/YYYY');

      if (!dtini.isValid() || !dtfim.isValid()) return;

      this.quantidadeMesesComissao = dtfim.diff(dtini, 'months');
    },
    handleQuantidadeMesesComissao() {
      const dtini = moment(this.comissao.dtini, 'DD/MM/YYYY');

      if (!dtini.isValid()) return;

      this.comissao.dtfim = dtini.add(this.quantidadeMesesComissao, 'months').format('DD/MM/YYYY');
    },
    // Opções
    loadPlanosdecontasComissoes() {
      axios
        .get('/plano-contas?cd=D')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.planosdecontasComissoes = res.data.planodecontas;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    filterPlanosdecontasComissoes(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.planosdecontasOptions = this.planosdecontasComissoes.filter(planodecontas =>
          planodecontas.titulo.toLowerCase().includes(needle)
        );
      });
    },
    buscarCadastro(val, update) {
      update(() => {
        axios.get(`/cadastros?ativo=1&fornecedor=1&funcionario=1&busca=${val}`).then(res => {
          if (!res.data.success) return;
          this.cadastros = res.data.data.cadastros;
        });
      });
    },
    // Salvar
    addComissao() {
      this.salvando = true;

      const comissao = JSON.parse(JSON.stringify(this.comissao));
      comissao.cadastro_id = comissao.cadastro.id;
      comissao.planodeconta_id = comissao.planodeconta
        ? comissao.planodeconta.id
        : comissao.planodeconta;
      comissao.dtini = moment(comissao.dtini, 'DD/MM/YYYY').format('YYYY/MM/DD');

      if (comissao.dtfim) {
        comissao.dtfim = moment(comissao.dtfim, 'DD/MM/YYYY').format('YYYY/MM/DD');
      }

      axios
        .post(`/recorrencias/add-comissao/${this.recorrencia.id}`, comissao)
        .then(res => {
          this.salvando = false;

          this.$q.notify({
            color: res.data.success ? 'green' : 'orange',
            message: res.data.message,
            position: 'top-right',
          });

          if (!res.data.success) {
            this.errosComissoes = res.data.errors;
            return;
          }

          this.$emit('abrirModal', this.recorrencia);
          this.pulaCampo('comissoesCadastro');
        })
        .catch(() => {
          this.salvando = false;
          this.$q.notify({
            color: 'red',
            message: 'Houve um erro ao adicionar a comissão',
            position: 'top-right',
          });
        });
    },
    removeComissao(id) {
      if (this.comissoesSalvando.includes(id)) return;

      this.comissoesSalvando.push(id);

      axios
        .post(`/cadastros/delete-recorrencia-comissao/${id}`)
        .then(res => {
          this.comissoesSalvando.splice(this.comissoesSalvando.indexOf(id), 1);

          this.$q.notify({
            color: res.data.success ? 'green' : 'orange',
            message: res.data.message,
            position: 'top-right',
          });

          this.$emit('abrirModal', this.recorrencia);
        })
        .catch(() => {
          this.comissoesSalvando.splice(this.comissoesSalvando.indexOf(id), 1);

          this.$q.notify({
            color: 'red',
            message: 'Houve um erro ao remover a comissão',
            position: 'top-right',
          });
        });
    },
    // Utilidade
    notificarSemPermissao(message) {
      this.$q.notify({
        message: message,
        color: 'red',
        position: 'top-right',
      });
    },
    pulaCampo(campo, segundos = null) {
      const focar = () => {
        if (!this.$refs[campo]) {
          return;
        }

        if (this.$refs[campo].show) {
          return this.$refs[campo].show();
        }

        if (this.$refs[campo].$el.classList.contains('v-money')) {
          return this.$refs[campo].$el.focus();
        }

        return this.$refs[campo].focus();
      };

      if (segundos) {
        return setTimeout(() => focar(), segundos);
      }

      focar();
    },
    formatDate(data) {
      if (!data) {
        return '';
      }

      return moment(data, 'YYYY/MM/DD').format('DD/MM/YYYY');
    },
  },
  computed: {
    comissaoOrigemValorOptions() {
      return [
        {
          label: 'Fixo',
          value: 'fixo',
        },
        {
          label: 'Recorrência',
          value: 'recorrencia',
        },
        {
          label: 'Parcela',
          value: 'parcela',
          disable: this.comissao.item_quando && this.comissao.item_quando !== 'parcela',
        },
      ];
    },
  },
  mounted() {
    bus.$on('abrirModalComissoesRecorrencia', this.abrirModal);

    this.loadPlanosdecontasComissoes();
  },
};
</script>
