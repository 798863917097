<template>
  <q-dialog v-model="openModalAtualizacoes" persistent>
    <q-card style="width: 100%; max-width: 800px;">

      <q-card-section class="row items-center">
        <div class="text-h6">Melhorias e Atualizações</div>

        <q-space />

        <q-btn
          class="q-ml-lg"
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="fecharModalAtualizacoes()"
        />
      </q-card-section>

      <q-separator />

      <q-scroll-area style="height: 80vh;">
        <q-card-section class="q-pt-none q-px-lg">
          <div
            v-for="(atualizacao, index) in atualizacoes"
            :key="atualizacao.id"
            style="overflow-x: auto;"
          >
            <div v-html="atualizacao.html" />
            <q-separator v-if="index !== atualizacoes.length - 1" />
          </div>

          <div class="row justify-center q-my-md" v-if="loading">
            <q-spinner color="blue-7" size="4em" />
          </div>
        </q-card-section>

        <q-card-actions class="row justify-end q-px-lg q-pb-lg">
          <q-btn
            color="primary"
            label="Carregar mais"
            v-show="atualizacoes.length > 0"
            :disable="loading || !paging.nextPage"
            @click="loadAtualizacoes()"
          />
        </q-card-actions>
      </q-scroll-area>

    </q-card>
  </q-dialog>
</template>

<script>
import axios from '@/axios-auth';
import { bus } from '@/main';
import { mapGetters } from 'vuex';

export default {
  name: 'modal-atualizacoes',
  data() {
    return {
      openModalAtualizacoes: false,
      loading: false,
      atualizacoes: [],
      paging: {
        page: 0,
        nextPage: true,
      },
    };
  },
  methods: {
    fecharModalAtualizacoes() {
      this.openModalAtualizacoes = false;
    },
    loadAtualizacoes() {
      if (!this.paging.nextPage) {
        return;
      }

      this.loading = true;

      this.paging.page++;
      const params = {
        page: this.paging.page,
      };

      axios
        .get('/atualizacoes', { params })
        .then(res => {
          this.loading = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.atualizacoes.push(...res.data.atualizacoes);
          this.paging = res.data.paging;
        })
        .catch(() => {
          this.loading = false;
          this.$q.notify({
            message: 'Houve um erro ao recuperar as atualizações',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    updateViuAtualizacao() {
      const data = {
        action: 'viu_atualizacao',
      };

      axios
        .post('/users/perfil', data)
        .then(res => {
          commit("setViuAtualizacao", res.data.success);
        })
        .catch(() => {});
    },
  },
  computed: mapGetters(['viuAtualizacao']),
  watch: {
    viuAtualizacao() {
      if (this.viuAtualizacao === false) {
        bus.$emit("abrirModalAtualizacoes");
        this.updateViuAtualizacao();
      }
    }
  },
  created() {
    bus.$on('abrirModalAtualizacoes', () => {
      this.openModalAtualizacoes = true;

      if (this.atualizacoes.length === 0) {
        this.loadAtualizacoes();
      }
    });
  },
  destroyed() {
    bus.$off('abrirModalAtualizacoes');
  },
};
</script>

<style lang="stylus" scoped></style>
