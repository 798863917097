<template>
  <div id="modalNovoPlanoContas">
    <q-dialog v-model="OpenDialogNovoPlanoContas" persistent>
      <q-card style="width: 400px">
        <q-card-section class="row items-center">
          <div class="text-h6">Novo Plano de Contas</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12">
                <q-input
                  v-model="titulo"
                  label="Título"
                  :error="errors.titulo ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.titulo"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-select
                  clearable
                  v-model="tipocd"
                  :options="optionsCDN"
                  :option-value="opt => (opt === null ? null : opt.value)"
                  emit-value
                  map-options
                  label="Crédito/Débito"
                  bottom-slots
                  :error="errors.tipocd ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.tipocd"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
              <div class="col-12">
                <q-select
                  clearable
                  v-model="tipoat"
                  :options="optionsAT"
                  :option-value="opt => (opt === null ? null : opt.value)"
                  emit-value
                  map-options
                  label="Analítico/Totalizador"
                  bottom-slots
                  :error="errors.tipoat ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.tipoat"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
              <div class="col-12">
                <q-select
                  v-if="tipocd === 'D'"
                  clearable
                  v-model="tipovof"
                  :options="optionsVOF"
                  :option-value="opt => (opt === null ? null : opt.value)"
                  emit-value
                  map-options
                  label="Tipo de Custo"
                  bottom-slots
                  :error="errors.tipovof ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.tipovof"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="novoPlano"
            :loading="loading"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify } from "quasar";
export default {
  name: "modal-novo-plano-contas",
  data() {
    return {
      loading: false,
      titulo: "",
      tipoat: "",
      tipocd: "",
      tipovof: "",
      errors: [],
      optionsCDN: [
        { label: "Crédito", value: "C" },
        { label: "Débito", value: "D" },
        { label: "Nulo", value: "N" }
      ],
      optionsAT: [
        { label: "Analitico", value: "A" },
        { label: "Totalizador", value: "T" }
      ],
      optionsVOF: [
        { label: "Variável", value: "V" },
        { label: "Operacional", value: "O" },
        { label: "Fixo", value: "F" },
        { label: "Nulo", value: "N" }
      ]
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogNovoPlanoContas", false);
      this.errors = [];
    },
    novoPlano() {
      this.errors = [];
      this.loading = true;
      let dados = {
        titulo: this.titulo,
        tipocd: this.tipocd,
        tipoat: this.tipoat,
        tipovof: !this.tipovof ? 'N' : this.tipovof,
        pai: null
      };
      axios
        .post("/plano-contas/add", dados)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            this.$store.dispatch("planodecontas");
            this.titulo = "";
            this.tipocd = "";
            this.tipoat = "";
            this.tipovof = "";
            this.closeModal();
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            this.errors = res.data.errors;
          }
          this.loading = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível criar o Plano de Contas, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    }
  },
  computed: {
    OpenDialogNovoPlanoContas: {
      get() {
        return this.$store.getters.OpenDialogNovoPlanoContas;
      },
      set() {}
    }
  }
};
</script>

<style scoped></style>
