var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.col ? 'col col-md-12' : ''},[_c('q-select',{ref:"autocompletedComponent",attrs:{"clearable":!_vm.notClearable,"use-input":"","autocomplete":"off","loading":_vm.carregando,"label":_vm.title,"options":_vm.options,"option-label":value => (value === null ? 'Item Vazio' : _vm.display(value)),"option-value":value => (value === null ? null : value),"new-value-mode":"add-unique","bottom-slots":"","name":"autocompleted","disable":_vm.disable,"error":_vm.errors && Object.keys(_vm.errors).length > 0},on:{"filter":_vm.filter,"input":_vm.emitEvento,"new-value":_vm.create},scopedSlots:_vm._u([{key:"option",fn:function(scope){return [_c('q-list',[_c('q-item',_vm._g(_vm._b({},'q-item',scope.itemProps,false),scope.itemEvents),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(_vm.formatDisplay(scope.opt))}})],1)],1)],1)]}},{key:"hint",fn:function(){return [_vm._v(" Pressione "),_c('b',[_vm._v("ENTER")]),_vm._v(" para selecionar ")]},proxy:true},{key:"error",fn:function(){return _vm._l((_vm.errors),function(error,i){return _c('span',{key:i},[_vm._v(_vm._s(error))])})},proxy:true}]),model:{value:(_vm.itemSelecionado),callback:function ($$v) {_vm.itemSelecionado=$$v},expression:"itemSelecionado"}}),(
      _vm.itemSelecionado &&
        _vm.itemSelecionado.id == null &&
        _vm.newCadastro === null &&
        _vm.tipoConsulta === 'cadastro'
        || (_vm.itemSelecionado && _vm.itemSelecionado.canEdit)
    )?[_c('div',{staticClass:"novoCadastro",staticStyle:{"margin-top":"20px"}},[_vm._v(" Novo cadastro - "),_c('b',[_vm._v(" "+_vm._s(_vm.itemSelecionado.name)+" ")]),_vm._v(" "),_c('br'),_vm._v("Caso queira alterá-lo, "),_c('span',{staticClass:"link",on:{"click":function($event){return _vm.cadastro(_vm.itemSelecionado)}}},[_vm._v("clique aqui")]),_vm._v(". ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }