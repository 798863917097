<template>
  <div>
    <q-dialog v-model="openDialog" persistent>
      <q-card
        :class="{
          scroll: true,
          'nova-recorrencia-card': true,
          open: opcoesAvancadas,
        }"
      >
        <q-card-section class="row items-center">
          <div class="text-h6">{{ isNew ? 'Nova' : 'Editar' }} Recorrência</div>
          <q-space />
          <q-btn icon="close" clickable flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-lg">
            <!-- OPÇÕES AVANÇADAS LADO ESQUERDO -->
            <div :class="opcoesAvancadas ? 'col-md-4' : 'col-12'">
              <div class="row q-col-gutter-xs">
                <div class="col-12">
                  <q-checkbox
                    v-model="recorrencia.ativo"
                    label="Ativo"
                    color="green"
                    @input="pulaCampo('periodo')"
                  />
                </div>

                <div class="col-6 q-pt-none">
                  <q-select
                    dense
                    emit-value
                    map-options
                    bottom-slots
                    v-model="recorrencia.periodo"
                    label="Período em meses"
                    ref="periodo"
                    :options="periodoMeses"
                    :error="erros.periodo ? true : false"
                    @keydown.enter.prevent="pulaCampo('dia_cobranca')"
                  >
                    <template v-slot:error>
                      <span v-for="(erro, i) in erros.periodo" :key="erro + i">{{ erro }}</span>
                    </template>
                  </q-select>
                </div>

                <div class="col-6 q-pt-none">
                  <q-input
                    dense
                    bottom-slots
                    mask="##"
                    ref="dia_cobranca"
                    label="Dia da cobrança"
                    :error="Boolean(erros.dia_cobranca)"
                    @keydown.enter.prevent="pulaCampo('convenio')"
                    v-model="recorrencia.dia_cobranca"
                  >
                    <template v-slot:error>
                      <span v-for="(error, i) in erros.dia_cobranca" :key="error + i">
                        {{ error }}
                      </span>
                    </template>
                  </q-input>

                  <q-tooltip>
                    Se agrupado sobrescreve o dia de pagamento da recorrência
                  </q-tooltip>
                </div>

                <div class="col-12 q-pt-none">
                  <q-select
                    dense
                    clearable
                    v-model="recorrencia.convenio_id"
                    :options="convenios"
                    option-value="id"
                    option-label="nome"
                    map-options
                    label="Convênio"
                    ref="convenio"
                    bottom-slots
                    :error="erros.convenio ? true : false"
                    @input="pulaCampo('numeroConvenio')"
                    v-if="exibirCampoConvenio"
                  >
                    <template v-slot:error>
                      <span v-for="(erro, i) in erros.convenio" :key="erro + i">{{ erro }}</span>
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-12 q-pt-none">
                  <q-input
                    dense
                    v-model="recorrencia.numeroconvenio"
                    label="Número Convênio"
                    ref="numeroConvenio"
                    bottom-slots
                    @keydown.enter.prevent="pulaCampo('dependente')"
                    v-if="exibirCampoConvenio"
                  />
                </div>

                <div class="col-12 q-pt-none">
                  <q-select
                    dense
                    clearable
                    v-model="recorrencia.dependente_id"
                    :options="dependentes"
                    label="Dependente"
                    option-value="id"
                    option-label="nome"
                    map-options
                    bottom-slots
                    ref="dependente"
                    :error="erros.dependente ? true : false"
                    @input="pulaCampo('descricao')"
                    v-if="exibirCampoVinculo"
                  >
                    <template v-slot:error>
                      <span v-for="(erro, i) in erros.dependente" :key="erro + i">{{ erro }}</span>
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-12 q-pt-none">
                  <q-input
                    dense
                    v-model="recorrencia.descricao"
                    label="Descrição"
                    bottom-slots
                    ref="descricao"
                    @keydown.enter.prevent="pulaCampo('quantidade')"
                    :error="Boolean(erros.descricao)"
                  >
                    <template v-slot:error>
                      <span v-for="(erro, i) in erros.descricao" :key="erro + i">{{ erro }}</span>
                    </template>
                  </q-input>
                </div>

                <div class="col-3 q-pt-none">
                  <q-input
                    dense
                    v-model="recorrencia.quantidade"
                    label="Quantidade"
                    bottom-slots
                    mask="######"
                    ref="quantidade"
                    :error="erros.quantidade ? true : false"
                    @keydown.enter.prevent="pulaCampo('valor')"
                  >
                    <template v-slot:error>
                      <span v-for="(erro, i) in erros.quantidade" :key="erro + i">{{ erro }}</span>
                    </template>
                  </q-input>
                </div>

                <div class="col-9 q-pt-none" @keydown.enter.prevent="salvar()">
                  <q-field
                    dense
                    v-model="recorrencia.valor"
                    label="Valor"
                    bottom-slots
                    :error="erros.valor ? true : false"
                  >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                        ref="valor"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span v-for="(erro, i) in erros.valor" :key="erro + i">{{ erro }}</span>
                    </template>
                  </q-field>
                </div>

                <div class="col-12" v-if="editarPlanodecontas">
                  <q-select
                    dense
                    use-input
                    clearable
                    bottom-slots
                    input-debounce="0"
                    option-value="id"
                    option-label="titulo"
                    label="Planos de contas"
                    @filter="filtrarPlanosdecontas"
                    :options="planosdecontasOpcoes"
                    v-model="recorrencia.planodeconta"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-12" v-if="exibirCampoPlano">
                  <q-select
                    dense
                    use-input
                    clearable
                    bottom-slots
                    option-value="id"
                    option-label="nome"
                    map-options
                    emit-value
                    label="Planos"
                    :options="planosOptions"
                    v-model="recorrencia.plano_id"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-12 flex justify-end">
                  <q-btn
                    flat
                    dense
                    no-caps
                    label="Opções Avançadas"
                    :icon-right="opcoesAvancadas ? 'keyboard_arrow_left' : 'keyboard_arrow_right'"
                    @click="toggleOpcoesAvancadas()"
                  />
                </div>

                <!-- component uploader start -->
                <upload-document-recorrencia v-if="opcoesAvancadas" :uploads="uploads" :recorrencia="recorrencia" :isNew="isNew"/>
                <!-- component uploader end -->
              </div>
            </div>

            <!-- OPÇÕES AVANÇADAS LADO DIREITO -->
            <div :class="opcoesAvancadas ? 'col-md-8' : 'col-12'" v-show="opcoesAvancadas">
              <div class="row q-col-gutter-xs">
                <div class="col-12">
                  <div class="row q-col-gutter-x-md">
                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        clearable
                        bottom-slots
                        label="Data de início"
                        :error="Boolean(erros.dtini)"
                        v-model="recorrencia.dtini"
                        @click="$refs.qDateIni.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateIni"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="recorrencia.dtini"
                                @input="() => pulaCampo('qDateFim')"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(erro, i) in erros.dtini" :key="erro + i">{{ erro }}</span>
                        </template>
                      </q-input>
                    </div>
                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        clearable
                        bottom-slots
                        label="Data de fim"
                        :error="Boolean(erros.dtfim)"
                        v-model="recorrencia.dtfim"
                        @click="$refs.qDateFim.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateFim"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="recorrencia.dtfim"
                                @input="() => pulaCampo('qDateBloqueio')"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(erro, i) in erros.dtfim" :key="erro + i">{{ erro }}</span>
                        </template>
                      </q-input>
                    </div>
                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                      clearable
                      bottom-slots
                      label="Data de bloqueio"
                      :error="Boolean(erros.data_bloqueio)"
                      v-model="recorrencia.data_bloqueio"
                      @click="$refs.qDateBloqueio.show()"
                      >
                      <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          ref="qDateBloqueio"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                          v-model="recorrencia.data_bloqueio"
                          @input="() => pulaCampo('quantidadeItem')"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                          />
                        </q-popup-proxy>
                        </q-icon>
                      </template>
                      <template v-slot:error>
                          <span v-for="(erro, i) in erros.data_bloqueio" :key="erro + i">{{ erro }}</span>
                      </template>
                      </q-input>
                    </div>
                  </div>
                </div>

                <!-- ITEMS -->
                <div class="col-12">
                  <div class="row q-col-gutter-x-md">
                    <div class="col-12">
                      <p class="q-mb-none"><strong>Itens</strong></p>
                    </div>

                    <div class="col-12">
                      <q-select
                        dense
                        use-input
                        clearable
                        emit-value
                        map-options
                        bottom-slots
                        input-debounce="0"
                        option-value="id"
                        option-label="titulo"
                        label="Planos de contas"
                        :options="planosdecontasOpcoes"
                        @filter="filtrarPlanosdecontas"
                        @input="pulaCampo('quantidadeItem')"
                        v-model="item.planodeconta_id"
                      >
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey">Sem Resultados</q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>

                    <div class="col-2">
                      <q-input
                        dense
                        v-model="item.quantidade"
                        label="Quantidade"
                        bottom-slots
                        mask="###"
                        :error="errosItem.quantidade ? true : false"
                        ref="quantidadeItem"
                        @keydown.enter.prevent="pulaCampo('descricaoItem')"
                      >
                        <template v-slot:error>
                          <span v-for="(erro, i) in errosItem.quantidade" :key="erro + i">
                            {{ erro }}
                          </span>
                        </template>
                      </q-input>
                    </div>

                    <div class="col-5">
                      <q-input
                        dense
                        v-model="item.descricao"
                        label="Descrição"
                        bottom-slots
                        :error="errosItem.descricao ? true : false"
                        ref="descricaoItem"
                        @keydown.enter.prevent="pulaCampo('valorItem')"
                      >
                        <template v-slot:error>
                          <span v-for="(erro, i) in errosItem.descricao" :key="erro + i">
                            {{ erro }}
                          </span>
                        </template>
                      </q-input>
                    </div>

                    <div class="col-5" @keydown.enter.prevent="addItem()">
                      <q-field
                        dense
                        v-model="item.valor"
                        label="Valor"
                        bottom-slots
                        :error="errosItem.valor ? true : false"
                      >
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                            ref="valorItem"
                          >
                          </money>
                        </template>
                        <template v-slot:error>
                          <span v-for="(erro, i) in errosItem.valor" :key="erro + i">
                            {{ erro }}
                          </span>
                        </template>
                        <template v-slot:after>
                          <q-btn
                            flat
                            round
                            icon="add"
                            class="align-self-center"
                            style="height: 42px"
                            color="primary"
                            @click="addItem"
                          />
                        </template>
                      </q-field>
                    </div>

                    <q-markup-table
                      flat
                      dense
                      class="q-my-md full-width"
                      separator="none"
                      v-if="recorrencia.cadastros_recorrencias_items"
                    >
                      <thead>
                        <tr>
                          <th>Descrição</th>
                          <th>Plano de contas</th>
                          <th>Valor</th>
                          <th style="width: 50px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in recorrencia.cadastros_recorrencias_items"
                          :key="item.funcionarioId"
                        >
                          <td>
                            ({{ item.quantidade }}) {{ item.descricao }}
                            <br v-if="item.dtini || item.dtfim" />
                            <span v-if="item.dtini">Início: {{ formatDate(item.dtini) }}</span>
                            <span v-if="item.dtfim"> Fim: {{ formatDate(item.dtfim) }}</span>
                          </td>
                          <td class="text-left">
                            <span v-if="item.planodeconta">{{ item.planodeconta.titulo }}</span>
                          </td>
                          <td class="text-right">R$ {{ item.valor | currency }}</td>
                          <td style="width: 50px">
                            <q-btn
                              flat
                              dense
                              size="12px"
                              color="red"
                              icon="delete"
                              @click="removeItem(index)"
                            />
                          </td>
                        </tr>
                        <tr v-if="recorrencia.cadastros_recorrencias_items.length === 0">
                          <td colspan="3" align="center">Nenhum item</td>
                        </tr>
                      </tbody>
                    </q-markup-table>
                  </div>
                </div>

                <!-- DESCONTOS -->
                <div class="col-12">
                  <div class="row q-col-gutter-x-md">
                    <div class="col-12">
                      <p class="q-mb-none"><strong>Descontos</strong></p>
                    </div>

                    <div @keydown.enter.prevent="pulaCampo('qDateFimDesconto')" class="col-6">
                      <q-field
                        dense
                        v-model="desconto.valor_desconto"
                        label="Desconto"
                        bottom-slots
                        :error="errosDesconto.valor_desconto ? true : false"
                        style="width: 100%"
                      >
                        <template v-slot:before>
                          <q-btn
                            small
                            size="md"
                            style="width: 49px"
                            :label="desconto.tipo_desconto === '%' ? '%' : 'R$'"
                            v-model="desconto.tipo_desconto"
                            @click="toggleTipoDesconto()"
                          />
                        </template>
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="desconto.tipo_desconto === '%' ? percentFormat : moneyFormat"
                            v-show="floatingLabel"
                            ref="valorDesconto"
                          >
                          </money>
                        </template>
                        <template v-slot:error>
                          <span v-for="(erro, i) in errosDesconto.valor_desconto" :key="erro + i">
                            {{ erro }}
                          </span>
                        </template>
                      </q-field>
                    </div>

                    <div class="col-6">
                      <q-input
                        dense
                        bottom-slots
                        label="Fim do desconto"
                        :error="Boolean(errosDesconto.dtfim)"
                        v-model="desconto.dtfim"
                        @click="$refs.qDateFimDesconto.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateFimDesconto"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="desconto.dtfim"
                                @input="() => addDesconto()"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(erro, i) in errosDesconto.dtfim" :key="erro + i">
                            {{ erro }}
                          </span>
                        </template>
                        <template v-slot:after>
                          <q-btn
                            flat
                            round
                            icon="add"
                            class="align-self-center"
                            style="height: 42px"
                            color="primary"
                            @click="addDesconto"
                          />
                        </template>
                      </q-input>
                    </div>

                    <q-markup-table
                      flat
                      dense
                      class="q-my-md full-width"
                      separator="none"
                      v-if="recorrencia.cadastros_recorrencias_descontos"
                    >
                      <tbody>
                        <tr
                          v-for="(desconto, index) in recorrencia.cadastros_recorrencias_descontos"
                          :key="desconto.funcionarioId"
                        >
                          <td>até {{ formatDate(desconto.dtfim) }}</td>
                          <td class="text-right">
                            {{ desconto.valor_desconto | currency | value(desconto.tipo_desconto) }}
                          </td>
                          <td style="width: 50px">
                            <q-btn
                              flat
                              dense
                              size="12px"
                              color="red"
                              icon="delete"
                              @click="removeDesconto(index)"
                            />
                          </td>
                        </tr>
                        <tr v-if="recorrencia.cadastros_recorrencias_descontos.length === 0">
                          <td colspan="3" align="center">Nenhum desconto</td>
                        </tr>
                      </tbody>
                    </q-markup-table>
                  </div>
                </div>

                <!-- DESCONTOS ASSINATURAS -->
                <div class="col-12">
                  <div class="row q-col-gutter-x-md">
                    <div class="col-12">
                      <p class="q-mb-none"><strong>Desconto no pagamento adiantado</strong></p>
                    </div>

                    <div class="col-6">
                      <q-field
                        dense
                        v-model="recorrencia.desconto"
                        label="Desconto"
                        bottom-slots
                        style="width: 100%"
                      >
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                            ref="valorDesconto"
                          >
                          </money>
                        </template>
                      </q-field>
                    </div>

                    <div class="col-6">
                      <q-input
                        dense
                        v-model="recorrencia.desconto_dias_antecipacao"
                        label="Dias de Antecipação"
                        bottom-slots
                        mask="###"
                        ref="quantidadeDesconto"
                      >
                      </q-input>
                    </div>

                  </div>
                </div>

                <!-- PARCELAS -->
                <div class="col-12">
                  <div class="row q-col-gutter-x-md">
                    <div class="col-12">
                      <p class="q-mb-none"><strong>Parcelas</strong></p>
                    </div>

                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        label="Descrição"
                        ref="descricaoParcela"
                        v-model="parcela.descricao"
                        @keydown.enter.prevent="pulaCampo('valorParcela')"
                      />
                    </div>

                    <div
                      @keydown.enter.prevent="pulaCampo('qDateVencimentoParcela')"
                      class="col-12 col-md-4"
                    >
                      <q-field
                        dense
                        v-model="parcela.valor"
                        label="Valor"
                        bottom-slots
                        :error="errosParcela.valor ? true : false"
                        style="width: 100%"
                      >
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                            ref="valorParcela"
                          >
                          </money>
                        </template>
                        <template v-slot:error>
                          <span v-for="(erro, i) in errosParcela.valor" :key="erro + i">
                            {{ erro }}
                          </span>
                        </template>
                      </q-field>
                    </div>

                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        bottom-slots
                        label="Vencimento"
                        :error="Boolean(errosParcela.vencimento)"
                        v-model="parcela.vencimento"
                        @click="$refs.qDateVencimentoParcela.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateVencimentoParcela"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="parcela.vencimento"
                                @input="() => addParcela()"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(erro, i) in errosParcela.vencimento" :key="erro + i">
                            {{ erro }}
                          </span>
                        </template>
                        <template v-slot:after>
                          <q-btn
                            flat
                            round
                            icon="add"
                            class="align-self-center"
                            style="height: 42px"
                            color="primary"
                            @click="addParcela"
                          />
                        </template>
                      </q-input>
                    </div>

                    <q-markup-table
                      flat
                      dense
                      class="q-my-md full-width"
                      separator="none"
                      v-if="recorrencia.cadastros_recorrencias_parcelas"
                    >
                      <tbody>
                        <tr
                          v-for="(parcela, index) in recorrencia.cadastros_recorrencias_parcelas"
                          :key="parcela.funcionarioId"
                        >
                          <td style="white-space: break-spaces">{{ parcela.descricao }}</td>
                          <td class="text-center">{{ formatDate(parcela.vencimento) }}</td>
                          <td class="text-right">{{ parcela.valor | currency }}</td>
                          <td style="width: 50px">
                            <q-btn
                              flat
                              dense
                              size="12px"
                              color="red"
                              icon="delete"
                              @click="removeParcela(index)"
                            />
                          </td>
                        </tr>
                        <tr v-if="recorrencia.cadastros_recorrencias_parcelas.length === 0">
                          <td colspan="4" align="center">Nenhuma parcela</td>
                        </tr>
                      </tbody>
                    </q-markup-table>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row q-col-gutter-md">
                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        clearable
                        bottom-slots
                        label="Primeiro Pagamento"
                        :error="Boolean(erros.data_primeiro_pagamento)"
                        v-model="recorrencia.data_primeiro_pagamento"
                        @click="$refs.qDatePrimeiroPagamento.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDatePrimeiroPagamento"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="recorrencia.data_primeiro_pagamento"
                                @input="() => pulaCampo('qDateUltimoFaturamentoEm')"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span
                            v-for="(erro, i) in erros.data_primeiro_pagamento"
                            :key="erro + i"
                            >{{ erro }}</span
                          >
                        </template>
                      </q-input>
                    </div>

                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        clearable
                        bottom-slots
                        label="Último faturamento"
                        :error="Boolean(erros.ultimo_faturamento)"
                        v-model="recorrencia.ultimo_faturamento"
                        @click="$refs.qDateUltimoFaturamentoEm.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateUltimoFaturamentoEm"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="recorrencia.ultimo_faturamento"
                                @input="() => pulaCampo('valor_ativacao')"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(erro, i) in erros.ultimo_faturamento" :key="erro + i">
                            {{ erro }}
                          </span>
                        </template>
                      </q-input>
                    </div>

                    <div class="col-12 col-md-4" @keydown.enter.prevent="salvar()">
                      <q-field
                        dense
                        v-model="recorrencia.valor_ativacao"
                        label="Valor de ativação"
                        bottom-slots
                        :error="erros.valor ? true : false"
                      >
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                            ref="valor_ativacao"
                          >
                          </money>
                        </template>
                        <template v-slot:error>
                          <span v-for="(erro, i) in erros.valor" :key="erro + i">{{ erro }}</span>
                        </template>
                      </q-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            no-caps
            v-ripple
            clickable
            type="submit"
            label="Salvar"
            color="positive"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="salvando"
            @click="salvar()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <modal-add-item-recorrencia @itemAdicionado="itemAdicionado" />
    <modal-upload-resumo-tfl 
      ref="uploadResumoTfl"
      title="Faça login com o código QR"
      movmentacao="true" 
    />
  </div>
</template>

<script>
import moment from 'moment';
import { Money } from 'v-money';
import { bus } from '@/main';
import axios from '@/axios-auth';

export default {
  name: 'modal-nova-recorrencia',
  props: {
    opcoes: Object,
  },
  components: { Money },
  data() {
    return {
      // Modal
      openDialog: false,
      opcoesAvancadas: false,
      // Carregando
      salvando: false,
      // Recorrência
      recorrencia: {},
      item: {
        descricao: '',
        quantidade: 1,
        valor: 0,
        dtini: null,
        dtfim: null,
        planodeconta_id: null,
      },
      desconto: {
        dtfim: null,
        valor_desconto: 0,
        tipo_desconto: '$',
      },
      parcela: {
        descricao: '',
        valor: 0,
        vencimento: null,
      },
      // Erros
      erros: {},
      errosDesconto: {},
      errosItem: {},
      errosParcela: {},
      // Opções
      convenios: [],
      dependentes: [],
      planosdecontas: [],
      planosdecontasOpcoes: [],
      planosOptions: [],
      // Máscaras
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      percentFormat: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: ' %',
        precision: 2,
        masked: false,
      },
      periodoMeses: [
        {
          label: 'Mensal',
          value: 1,
        },
        {
          label: 'Bimestral',
          value: 2,
        },
        {
          label: 'Trimestral',
          value: 3,
        },
        {
          label: 'Quadrimestral',
          value: 4,
        },
        {
          label: 'Semestral',
          value: 6,
        },
        {
          label: 'Anual',
          value: 12,
        }
      ],
      selectedFile: [],
      uploads: []
    };
  },
  methods: {
    // Modal
    abrirModal({ recorrencia, convenios, dependentes }) {
      this.opcoesAvancadas = false;

      this.resetarRecorrencia();
      this.resetarErros();
      this.loadPlanosdecontas();
      this.loadPlanos();

      this.planosOptions = [];
      this.convenios = convenios;
      this.dependentes = dependentes;

      if (recorrencia.id) {
        this.recorrencia = recorrencia;
      }
      this.openDialog = true;
    },
    closeModal() {
      this.openDialog = false;
    },
    // Salvar
    salvar() {
      this.salvando = true;

      let recorrencia = JSON.parse(JSON.stringify(this.recorrencia));
      const { id, cadastro_id } = recorrencia;

      const url = id ? `/recorrencias/edit/${id}` : `/recorrencias/add/${cadastro_id}`;

      const body = {
        convenio_id: !recorrencia.convenio_id ? null : recorrencia.convenio_id.id,
        dependente_id: !recorrencia.dependente_id ? null : recorrencia.dependente_id.id,
        planodeconta_id: !recorrencia.planodeconta ? null : recorrencia.planodeconta.id,
        plano_id: recorrencia.plano_id,
        descricao: recorrencia.descricao,
        numeroconvenio: recorrencia.numeroconvenio,
        periodo: recorrencia.periodo,
        valor: recorrencia.valor,
        valor_ativacao: recorrencia.valor_ativacao,
        ativo: recorrencia.ativo,
        dtini: recorrencia.dtini,
        dtfim: recorrencia.dtfim,
        data_bloqueio: recorrencia.data_bloqueio,
        quantidade: recorrencia.quantidade,
        ultimo_faturamento: recorrencia.ultimo_faturamento,
        data_primeiro_pagamento: recorrencia.data_primeiro_pagamento,
        is_trial: recorrencia.is_trial,
        dtini_trial: recorrencia.dtini_trial,
        dtfim_trial: recorrencia.dtfim_trial,
        data_trial: recorrencia.data_trial,
        dia_cobranca: recorrencia.dia_cobranca,
        cadastros_recorrencias_descontos: recorrencia.cadastros_recorrencias_descontos,
        cadastros_recorrencias_items: recorrencia.cadastros_recorrencias_items,
        cadastros_recorrencias_parcelas: recorrencia.cadastros_recorrencias_parcelas,
        cadastros_recorrencias_comissoes: recorrencia.cadastros_recorrencias_comissoes,
        desconto: recorrencia.desconto,
        desconto_dias_antecipacao: recorrencia.desconto_dias_antecipacao
      };

      axios
        .post(url, body)
        .then(res => {
          this.salvando = false;

          if (!res.data.success) {
            if (res.data.errors.dtini) {
              this.erros.dtini = res.data.errors;
              return;
            }

            this.$q.notify({
              message: 'Não foi possível salvar a recorrência!',
              color: 'orange',
              position: 'top-right',
            });
            this.erros = res.data.errors;
            return;
          }

          this.$store.commit("newRecorrencia", res.data.data.recorrencia);

          this.$q.notify({
            message: 'Recorrência salva!',
            color: 'green',
            position: 'top-right',
          });

          this.$emit('atualizar');
          this.closeModal();
        })
        .catch(() => {
          this.salvando = false;
          this.$q.notify({
            message:
              'Não foi possível salvar a recorrência, ou você não possui permissão para isto!',
            color: 'orange',
            position: 'top-right',
          });
        });
    },
    // Formulário
    resetarRecorrencia() {
      this.recorrencia = {
        id: null,
        ativo: true,
        congelado: false,
        periodo: 1,
        convenio_id: null,
        numeroconvenio: '',
        dependente_id: null,
        descricao: '',
        valor: 0.0,
        valor_ativacao: 0.0,
        quantidade: 1,
        dia_cobranca: null,
        cadastros_recorrencias_items: [],
        cadastros_recorrencias_descontos: [],
        cadastros_recorrencias_parcelas: [],
        desconto: null,
        desconto_dias_antecipacao: 0
      };

      this.resetarDesconto();
      this.resetarItem();
      this.resetarParcela();
    },
    // Desconto
    addDesconto() {
      const errosDesconto = {};

      if (Number.isNaN(this.desconto.valor_desconto) || this.desconto.valor_desconto <= 0) {
        errosDesconto['valor_desconto'] = ['Valor inválido'];
      }
      if (!this.desconto.dtfim || !moment(this.desconto.dtfim, 'DD/MM/YYYY').isValid()) {
        errosDesconto['dtfim'] = ['Data inválida'];
      }

      if (Object.keys(errosDesconto).length > 0) {
        this.errosDesconto = errosDesconto;
        return;
      }

      const desconto = JSON.parse(JSON.stringify(this.desconto));
      desconto.dtfim = moment(desconto.dtfim, 'DD/MM/YYYY').format('YYYY/MM/DD');

      this.recorrencia.cadastros_recorrencias_descontos.push(desconto);
      this.errosDesconto = [];

      this.resetarDesconto();
      this.pulaCampo('valorDesconto');
    },
    removeDesconto(descontoIndex) {
      this.recorrencia.cadastros_recorrencias_descontos.splice(descontoIndex, 1);
      this.pulaCampo('valorDesconto');
    },
    resetarDesconto() {
      this.desconto = {
        dtfim: null,
        valor_desconto: 0,
        tipo_desconto: '$',
      };
    },
    // Item
    addItem() {
      const errosItem = {};

      if (Number.isNaN(this.item.quantidade) || this.item.quantidade <= 0) {
        errosItem['quantidade'] = ['Valor inválido'];
      }

      if (Number.isNaN(this.item.valor) || this.item.valor <= 0) {
        errosItem['valor'] = ['Valor inválido'];
      }

      if (String(this.item.descricao) === '') {
        errosItem['descricao'] = ['Este campo não pode ser em branco'];
      }

      if (Object.keys(errosItem).length > 0) {
        this.errosItem = errosItem;
        return;
      }

      bus.$emit('abrirModalAddItemRecorrencia', { item: this.item });
    },
    itemAdicionado(item) {
      const items = this.clonarRecorrenciasItems();

      if (item.planodeconta_id) {
        item.planodeconta = this.planosdecontas.find(
          planodeconta => planodeconta.id === item.planodeconta_id
        );
      }

      items.push(item);
      let valorItems = 0;
      items.map(item => valorItems += +item.valor);

      this.recorrencia.valor = valorItems;
      this.recorrencia.cadastros_recorrencias_items = items;
      this.errosItem = [];

      this.resetarItem();

      bus.$emit('fecharModalAddItemRecorrencia');

      this.pulaCampo('quantidadeItem', 10);
    },
    removeItem(itemIndex) {
      const items = this.clonarRecorrenciasItems();
      items.splice(itemIndex, 1);

      let valorItems = 0;
      items.map(item => valorItems += +item.valor);

      this.recorrencia.valor = valorItems;
      this.recorrencia.cadastros_recorrencias_items = items;
      this.pulaCampo('quantidadeItem');
    },
    resetarItem() {
      this.item = {
        descricao: '',
        quantidade: 1,
        valor: 0,
        dtini: null,
        dtfim: null,
        planodeconta_id: null,
      };
    },
    // Parcela
    addParcela() {
      const errosParcelas = {};

      if (this.parcela.descricao.trim() === '') {
        errosParcelas['descricao'] = ['Este campo não pode ser vazio'];
      }
      if (Number.isNaN(this.parcela.valor) || this.parcela.valor <= 0) {
        errosParcelas['valor'] = ['Valor inválido'];
      }
      if (!this.parcela.vencimento || !moment(this.parcela.vencimento, 'DD/MM/YYYY').isValid()) {
        errosParcelas['vencimento'] = ['Data inválida'];
      }

      if (Object.keys(errosParcelas).length > 0) {
        this.errosParcelas = errosParcelas;
        return;
      }

      const parcela = JSON.parse(JSON.stringify(this.parcela));
      parcela.vencimento = moment(parcela.vencimento, 'DD/MM/YYYY').format('YYYY/MM/DD');

      const parcelas = this.clonarRecorrenciasParcelas();
      parcelas.push(parcela);

      this.recorrencia.cadastros_recorrencias_parcelas = parcelas;
      this.errosParcelas = [];

      this.resetarParcela();
      this.pulaCampo('descricaoParcela');
    },
    removeParcela(parcelaIndex) {
      const parcelas = this.clonarRecorrenciasParcelas();
      parcelas.splice(parcelaIndex, 1);

      this.recorrencia.cadastros_recorrencias_parcelas = parcelas;
      this.pulaCampo('descricaoParcela');
    },
    resetarParcela() {
      this.parcela = {
        descricao: '',
        valor: 0,
        vencimento: null,
      };
    },
    // Erros
    resetarErros() {
      this.erros = {};
      this.errosDesconto = {};
      this.errosItem = {};
      this.errosParcela = {};
    },
    // Opções
    loadPlanosdecontas() {
      axios
        .get('/plano-contas?cd=C')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.planosdecontas = res.data.planodecontas;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    filtrarPlanosdecontas(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.planosdecontasOpcoes = this.planosdecontas.filter(planodecontas =>
          planodecontas.titulo.toLowerCase().includes(needle)
        );
      });
    },
    loadPlanos() {
      axios
        .get('/planos')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }
          this.planosOptions = res.data.planos.map(function(item) {
            var periodo = 'Anual';
            if(item.periodo == 1){
               periodo = 'Mensal';
            }
            return { id: item.id, nome: item.nome + ' - ' + periodo };
          });
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    // Toggle
    toggleOpcoesAvancadas() {
      this.opcoesAvancadas = !this.opcoesAvancadas;
    },
    toggleTipoDesconto() {
      this.desconto.tipo_desconto = this.desconto.tipo_desconto === '%' ? '$' : '%';

      this.pulaCampo('valorDesconto');
    },
    // Clonar
    clonarRecorrenciasItems() {
      return JSON.parse(JSON.stringify(this.recorrencia.cadastros_recorrencias_items));
    },
    clonarRecorrenciasParcelas() {
      return JSON.parse(JSON.stringify(this.recorrencia.cadastros_recorrencias_parcelas));
    },
    clonarRecorrenciasComissoes() {
      return JSON.parse(JSON.stringify(this.recorrencia.cadastros_recorrencias_comissoes));
    },
    // Utilidade
    formatDate(data) {
      if (!data) {
        return '';
      }

      return moment(data, 'YYYY/MM/DD').format('DD/MM/YYYY');
    },
    pulaCampo(campo, segundos = null) {
      const focar = () => {
        if (!this.$refs[campo]) {
          return;
        }

        if (this.$refs[campo].show) {
          return this.$refs[campo].show();
        }

        if (this.$refs[campo].$el.classList.contains('v-money')) {
          return this.$refs[campo].$el.focus();
        }

        return this.$refs[campo].focus();
      };

      if (segundos) {
        return setTimeout(() => focar(), segundos);
      }

      focar();
    },
    notificarSemPermissao(message) {
      this.$q.notify({
        message: message,
        color: 'red',
        position: 'top-right',
      });
    },
    loadConfiguracoes() {
      axios
        .get('/integracoes/piperun')
        .then(res => {
          if (!res.data.success) {
            throw new Error();
          }
          this.config = res.data.config;
        });
    },
  },
  computed: {
    isNew() {
      return !this.recorrencia.id;
    },
    editarPlanodecontas() {
      return (
        this.opcoes.integracoes_piperun_planodeconta_recorrencias &&
        parseInt(this.opcoes.integracoes_piperun_planodeconta_recorrencias)
      );
    },
    exibirCampoVinculo() {
      return (
        this.opcoes.integracoes_piperun_exibir_campo_vinculo &&
        parseInt(this.opcoes.integracoes_piperun_exibir_campo_vinculo)
      );
    },
    exibirCampoConvenio() {
      return (
        this.opcoes.integracoes_piperun_exibir_campo_convenio &&
        parseInt(this.opcoes.integracoes_piperun_exibir_campo_convenio)
      );
    },
    exibirCampoPlano() {
      return (
        this.opcoes.integracoes_piperun_exibir_campo_plano &&
        parseInt(this.opcoes.integracoes_piperun_exibir_campo_plano)
      );
    },
  },
  mounted() {
    this.loadConfiguracoes();
    bus.$on('abrirModalNovaRecorrencia', this.abrirModal);
  },

};
</script>

<style lang="stylus" scoped>
.text-h6, .text-subtitle2 {
  font-weight: 300;
}

.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.nova-recorrencia-card {
  max-width: 400px;
  width: 90%;
}

.nova-recorrencia-card.open {
  max-width: 1000px;
}

.q-field--labeled .q-field__native {
  padding-bottom: 0;
}
</style>
