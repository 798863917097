<template>
  <div id="modalNovaCategoria">
    <q-dialog v-model="OpenDialogNovaCategoria" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Nova Categoria</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-checkbox v-model="ativo" label="Ativo" color="green" />
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="categoria.titulo"
                  label="Título"
                  bottom-slots
                  :error="errorsCategorias.titulo ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsCategorias.titulo" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="categoria.ordem"
                  bottom-slots
                  :error="errorsCategorias.ordem ? true : false"
                  label="Ordem"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsCategorias.ordem" :key="error + i">{{error}}</span>
                  </template>
                  <template v-slot:after>
                    <q-icon
                      name="info"
                      class="cursor-pointer"
                    >
                      <q-tooltip max-width='400px' :content-style="{fontSize:'15px'}">{{textos.ordem}}</q-tooltip>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-checkbox v-model="isEstorno" label="A Categoria é um estorno" color="green">
                   <template v-slot:default >
                    <q-icon
                      style="margin-left:10px"
                      color="grey"
                      size='23px'
                      name="info"
                      class="cursor-pointer"
                    >
                      <q-tooltip max-width='400px' :content-style="{fontSize:'15px'}">{{textos.estorno}}</q-tooltip>
                    </q-icon>
                  </template>
                </q-checkbox>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-section v-if="isEstorno">
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-select
                  v-model="valuePC"
                  :options="categorias"
                  label="É um estorno de"
                  bottom-slots
                  :error="errorsCategorias.categoria_id ? true : false"
                  :option-label="value => value === null ? 'Item Vazio' : value.titulo"
                  :option-value="value => value === null ? null : value.titulo"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsCategorias.categoria_id"
                      :key="error + i"
                    >{{error}}</span>
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <br />
                <br />
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  :loading="saveCategoria"
                  @click="enviaCategoria()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "modal-nova-categoria",
  data() {
    return {
      valuePC: {},
      isEstorno: false,
      ativo: true,
      textos:{
        estorno:'Realizar lançamento de estorno referente a categoria original.',
        ordem: 'A ordem em que esse cadastro será exibido na lista de categorias da venda.'
      }
    };
  },
  watch: {
    categoria(value) {
      if (value.estorno_id) {
        this.isEstorno = true;
        this.valuePC = this.returnCategorias();
      } else {
        this.isEstorno = false;
      }
      if (value.ativa) {
        this.ativo = this.categoria.ativa;
      }
    }
  },
  methods: {
    closeModal() {
      this.$store.commit("categoria", {
        titulo: "",
        ordem: null,
        estorno_id: null,
        ativo: true
      });
      this.$store.commit("errorsCategorias", []);
      this.$store.commit("isNewCategoria", true);
      this.$store.commit("OpenDialogNovaCategoria", false);
    },
    enviaCategoria() {
      this.categoria.estorno_id = this.valuePC.id;
      this.categoria.ativa = this.ativo;
      this.$store.dispatch("addCategoria", this.categoria);
    },
    returnCategorias() {
      for (let i = 0; i < this.categorias.length; i++) {
        if (this.categorias[i].id === this.categoria.estorno_id) {
          return this.categorias[i];
        }
      }
      return {};
    }
  },
  computed: {
    OpenDialogNovaCategoria: {
      get() {
        return this.$store.getters.OpenDialogNovaCategoria;
      },
      set() {}
    },
    ...mapGetters([
      "categoria",
      "categorias",
      "isNewCategoria",
      "saveCategoria",
      "errorsCategorias"
    ])
  }
};
</script>

<style lang="stylus" scoped></style>
