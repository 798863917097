<template>
  <div id="modalCaixaRecebimentoFiadoMultiplo">
    <q-dialog v-model="OpenDialogRecebimentoFiadoCaixasMultiplo" persistent>
      <q-card style="min-width: 400px; max-width: 900px">
        <q-card-section class="row items-center">
          <div class="text-h6">Receber Contas</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="clear()" />
        </q-card-section>
        <q-separator />
        <q-card-section style="padding-bottom: 0">
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-10">
                <autocomplete
                  title="Busque uma conta"
                  :filter="filterFn"
                  :carregando="carregando"
                  :options="options"
                  :formatDisplay="formatDisplay"
                  :create="createValue"
                  @resultAutocomplete="resultEmitRecebe"
                  @input="incluirConta()"
                  :display="displayMontar"
                />
              </div>
              <div class="col-2 text-center">
                <q-btn
                  flat
                  rounded
                  dense
                  icon="add"
                  class="text-primary"
                  style="position:relative; top: 25px"
                  clickable
                  @click="newReceita"
                ></q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="col" v-if="contasAReceber.length">
            <q-markup-table
              flat bordered
            >
              <thead>
                <q-tr>
                  <th class="text-center">ID Conta</th>
                  <th class="text-center">Cliente</th>
                  <th class="text-center">Vencimento</th>
                  <th class="text-center">Descrição</th>
                  <th class="text-center">Valor</th>
                  <th class="text-center"></th>
                </q-tr>
              </thead>
              <tbody>
                <q-tr v-for="(conta, index) in contasAReceber" :key="index">
                  <td class="text-left">
                    {{ conta.id }}
                  </td>
                  <td class="text-left">
                    {{ conta.cadastro_nome }}
                  </td>
                  <td class="text-right">
                    {{ conta.vencimento }}
                  </td>
                  <td class="text-left">
                    {{
                      String(conta.descricao).length > 60
                        ? conta.descricao.substr(0, 60) + "..."
                        : conta.descricao
                    }}
                  </td>
                  <td class="text-right">
                    R$ {{ conta.valor | currency }}
                  </td>
                  <td>
                    <q-btn
                      size="sm"
                      color="red"
                      icon="delete"
                      @click="removerConta(index)"
                    />
                  </td>
                </q-tr>
                <q-tr v-if="contasAReceber.length" no-hover class="text-weight-bold">
                  <td v-if="contasAReceber.length === 1" class="text-right">{{ contasAReceber.length }} item</td>
                  <td v-else class="text-right">{{ contasAReceber.length }} itens</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>R$ {{ totalContasAReceber | currency }}</td>
                </q-tr>
                <q-tr v-else no-hover>
                  <td class="text-center" colspan="5">Nenhum item</td>
                </q-tr>
              </tbody>
            </q-markup-table>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="salvar()"
            :disable="!contasAReceber.length"
            class="bg-green text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { bus } from "@/main";
import { Notify } from "quasar";
import moment from "moment";
import { Money } from "v-money";

export default {
  name: "modal-caixa-recebimento-fiado-multiplo",
  // components: { Money },
  data() {
    return {
      movconta_id: null,
      cadastro_id: null,
      cadastro_nome: null,
      movconta_valor: null,
      movconta_descricao: "",
      carregando: false,
      options: [],
      selectItem: null,
      autocompleteResult: {},
      loading: false,
      contasAReceber: [],
    };
  },
  methods: {
    clear(modal = true) {
      this.$store.commit("movcontaReturn", null);
      this.$store.commit("isReadOnlyCaixa", false);
      this.$store.commit("errorsCaixas", {});
      this.contasAReceber = [];
      this.$store.dispatch("contas");
      this.$root.$emit("limpar");
      this.$store.dispatch("resetDadosCaixaContaPagarReceber");

      if (modal) {
        this.$store.commit("OpenDialogRecebimentoFiadoCaixasMultiplo", false);
      }
    },
    newReceita() {
      this.$store.commit("callDespesa", false);
      this.$store.dispatch("planoContas");
      this.$store.commit("OpenDialogNovaReceita", true);
    },
    incluirConta() {
      if (
        this.autocompleteResult != null &&
        this.autocompleteResult.id === null
      ) {
        Notify.create({
          message: "Não foi possivel localizar conta !",
          color: "orange",
          position: "top-right"
        });
        return false;
      }

      if (this.autocompleteResult) {
        let venc = this.autocompleteResult.vencimento.split("/");
        if(this.contasAReceber.find((conta) => conta.id == this.autocompleteResult.id)) return;
        this.contasAReceber.push({
          'id': this.autocompleteResult.id,
          'cadastro_id': this.autocompleteResult.cadastro_id,
          'cadastro_nome': this.autocompleteResult.cadastro ? this.autocompleteResult.cadastro.nome : "Sem nome",
          'valor': this.autocompleteResult.valor,
          'vencimento': venc[2] + "/" + venc[1] + "/" + venc[0],
          'descricao': this.autocompleteResult.descricao,
        });
      }
    },
    async salvar() {
      if(!this.contasAReceber.length) {
        Notify.create({
          message: "Nenhuma conta selecionada",
          color: "orange",
          position: "top-right"
        });
        return false;
      }

      let data = {
        is_recebimento_multiplo: true,
        movcontas: []
      };

      this.loading  = true;
      for (const conta of this.contasAReceber) {
        if (
          this.movcontaReturn &&
          conta.cadastro_nome === null &&
          conta.movconta_id === null
        ) {
          conta.movconta_id = this.movcontaReturn.cadastro.id;
          conta.cadastro_nome = this.movcontaReturn.cadastro.nome;
        }

        const contas = {
          tipo: 4,
          valor: conta.valor,
          nome: "Recebido ID: " + conta.id + ", " + conta.descricao +
            (conta.cadastro_nome ? `, ${conta.cadastro_nome}` : ''),
          valor: Math.abs(conta.valor).toFixed(2),
          movconta_id: conta.id
        };

        data.movcontas.push(contas);
      }
      await this.$store.dispatch("alimentaCaixa", data);
      this.loading  = false;

      if(
        this.errorsCaixas &&
        Object.keys(this.errorsCaixas).length === 0 &&
        this.errorsCaixas.constructor === Object
      ) {
        this.clear();
      }
    },
    // Funções do autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/financeiro/busca/receita?term=" + val).then(res => {
          this.carregando = false;
          this.options = res.data;
        });
      });
    },
    createValue(val, done) {
      if (val && !val.name) {
        Notify.create({
          message: "Não foi possivel localizar conta !",
          color: "orange",
          position: "top-right"
        });
        return false;
      }

      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
    },
    formatValor(value) {
      if (!value) return "R$ 0,00";
      var val = (value / 1).toFixed(2).replace(".", ",");
      return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDisplay(result) {
      let receita = {
        id: null,
        nome: "",
        descricao: "",
        valor: 0,
        vencimento: "",
        verificarCadatros: ""
      };

      receita.id = result.id;
      receita.verificarCadatros = !result.cadastro
        ? (result.nome ? result.nome : "Sem cadastro")
        : result.cadastro.nome;
      receita.nome =
        result["cadastro"] === null ? "Sem cadastro" : receita.verificarCadatros;
      receita.descricao =
        result.descricao === null ? "Sem Descrição" : result.descricao;
      receita.valor =
        result.valor === null ? "Sem Valor" : this.formatValor(result.valor);
      receita.vencimento =
        result.vencimento === null
          ? "Sem Vencimento"
          : moment.utc(result.vencimento).format("DD/MM/YYYY");
        return `
          <span>
            ${receita.vencimento} - <strong>${receita.valor}</strong>
            <br/>
            Cliente <strong>${receita.verificarCadatros}</strong>
            <br/>
            ${receita.id} - ${receita.descricao}
          </span>
        `;
    },
    resultEmitRecebe(result) {
      this.autocompleteResult = result;
      if (this.autocompleteResult && this.autocompleteResult.id != null) {
        Notify.create({
          message:
            this.autocompleteResult.descricao + " - conta foi selecionado.",
          color: "green",
          position: "top-right"
        });
      }
      this.incluirConta();
    },
    displayMontar(value) {
      if (!value.descricao) return;
      let format = moment.utc(value.vencimento).format("DD/MM/YYYY") + " - " + value.descricao;
      return !value ? value : format;
    },
    removerConta(index) {
      this.contasAReceber.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters([
      "movcontaReturn",
      "errorsCaixas",
      "contas",
      "recebimentoFiadoCaixasMultiplo"
    ]),
    OpenDialogRecebimentoFiadoCaixasMultiplo: {
      get() {
        return this.$store.getters.OpenDialogRecebimentoFiadoCaixasMultiplo;
      },
      set() {}
    },
    conta_id_caixa:{
      get(){
        return this.$store.getters.conta_id_caixa;
      },
      set(value){
        this.$store.commit('conta_id_caixa', value);
      }
    },
    totalContasAReceber() {
      return this.contasAReceber.reduce((total, conta) => total + conta.valor, 0);
    }
  },
  watch: {
    OpenDialogRecebimentoFiadoCaixasMultiplo() {
      if (!this.OpenDialogRecebimentoFiadoCaixasMultiplo) return;
    },
    movcontaReturn() {
      if (!this.movcontaReturn || !this.movcontaReturn.cadastro.id) {
        return;
      }

      if (this.movcontaReturn) {
        let venc = this.movcontaReturn.movconta.vencimento.split("/");
        this.contasAReceber.push({
          'id': this.movcontaReturn.movconta.id,
          'cadastro_id': this.movcontaReturn.cadastro.id,
          'cadastro_nome': this.movcontaReturn.cadastro.nome ? this.movcontaReturn.cadastro.nome : "Sem nome",
          'valor': this.movcontaReturn.movconta.valor,
          'vencimento': venc[2] + "/" + venc[1] + "/" + venc[0],
          'descricao': this.movcontaReturn.movconta.descricao,
        });
      }
    },
    cadastro_id() {
      if (!this.cadastro_id) {
        return;
      }
    },
  },
  created() {
    bus.$on("clearReceberContaFiado", () => {
      this.clear(false);
    });
  },
  mounted() {
    document.addEventListener("keyup", event => {
      if (event.keyCode === 27) {
        this.clear();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener("keyup");
    next();
  }
};
</script>

<style lang="stylus" scoped></style>
