<template>
  <div id="modalNovaConciliacao">
    <q-dialog v-model="OpenDialogNovaConciliacao" persistent>
      <q-card style="width: 400px;">
        <q-card-section class="row items-center">
          <div class="text-h6">Selecione a conta bancária</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="$emit('onClose', false)" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">            
            <div class="q-col-gutter-md row" v-if="arquivo">
              <div class="text-h6">
                Arquivo: {{arquivo.id}} {{arquivo.nome}}
              </div>                
            </div>
            <div class="q-col-gutter-md row">
              <div class="col col-md-12">
                <q-select
                  v-if="!Isconta"
                  clearable
                  v-model="conta"
                  :options="contas"
                  label="Conta bancária para conciliar"
                  :option-label="value => value === null ? 'Item Vazio' : value.titulo"
                   :option-value="value => (value === null ? null : value)"
                  :rules="[ val => val && val.id > 0 || 'Por favor selecione uma conta bancária']"
                  emit-value
                  ap-options
                  >
                </q-select>
              </div>
              <div class="col col-md-12">
                <p v-if="conta || Isconta">
                {{Isconta != null ? "Continuando" : "Iniciando"}} a conciliação da conta: <b>{{conta != null ? conta.titulo : Isconta.titulo}}</b> com o arquivo <b>{{arquivo.nome}}</b>
                </p>                
              </div>
              <div class="col col-md-12 q-pt-xl">
                <q-btn
                  no-wrap
                  v-ripple
                  label="Abrir a conciliação"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  :loading="carregando"                  
                  @click="onSubmit"
                  >
                  <template v-slot:carregando>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { Notify } from "quasar";
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
export default {
  name: "modal-nova-conciliacao",
  props: [
    'OpenDialogNovaConciliacao',
    'arquivo',
    'Isconta'
    ],
  data() {
    return {
      conta: null,
      carregando: false
    };
  },
  methods: {
    onSubmit(){
      if (!this.conta && !this.Isconta){
        return false;
      }

      if (!this.arquivo){
        return false;
      }

      if(this.Isconta != null){
        this.conta = this.Isconta;
      }

      this.$router.push({ name: 'conciliacao', query: { conta_id: this.conta.id ,arquivo_id: this.arquivo.id}});
      this.$emit('onClose', false);
      this.conta = null;
    }
  },
  computed: {
    ...mapGetters([
      "contas",
    ]),
  }
};
</script>

<style lang="stylus" scoped>

</style>
