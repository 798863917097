<template>
  <div id="modalNovoTemplate">
    <q-dialog v-model="openDialogNovoTemplate" persistent>
      <q-card style="max-width: 1200px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">{{isNewTemplate ? 'Novo ' : 'Editar '}} Template</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-xs">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-6" v-bind:class="{ 'col-md-12': templateData.tipo == 'whatsapp' ||  templateData.tipo == 'relatorio-whatsapp'}">
                <q-select
                  dense
                  v-model="templateData.tipo"
                  :options="templateTypes"
                  label="Tipo"
                  bottom-slots
                  :error="errorsTemplate.tipo ? true : false"
                  emit-value
                  map-options
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsTemplate.tipo" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-6" v-bind:class="{ 'col-md-12': templateData.tipo == 'whatsapp' ||  templateData.tipo == 'relatorio-whatsapp'}">
                <q-input
                  dense
                  v-model="templateData.subject"
                  :label="templateData.tipo != 'whatsapp' && templateData.tipo !== 'relatorio-whatsapp' ? 'Assunto' : 'Nome do template'"
                  bottom-slots
                  :error="errorsTemplate.subject ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsTemplate.subject" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>

              <div class="col-12 col-md-6" v-if="templateData.tipo != 'whatsapp' && templateData.tipo !== 'relatorio-whatsapp'">
                <q-input
                  dense
                  v-model="templateData.from_name"
                  label="De: Nome"
                  bottom-slots
                  :error="errorsTemplate.from_name ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsTemplate.from_name" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
                <div class="col-12 col-md-6" v-if="templateData.tipo != 'whatsapp' && templateData.tipo != 'relatorio-whatsapp'">
                <q-input
                  dense
                  v-model="templateData.from_email"
                  label="De: E-mail"
                  bottom-slots
                  :error="errorsTemplate.from_email ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsTemplate.from_email" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
               <div class="col-12 col-md-6" v-if="templateData.tipo != 'whatsapp' && templateData.tipo !== 'relatorio-whatsapp'">
                <q-input
                  dense
                  v-model="templateData.replyto_name"
                  label="Responder Para: Nome"
                  bottom-slots
                  :error="errorsTemplate.replyto_name ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsTemplate.replyto_name"
                      :key="error + i"
                    >{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6" v-if="templateData.tipo != 'whatsapp' && templateData.tipo !== 'relatorio-whatsapp'">
                <q-input
                  dense
                  v-model="templateData.replyto_email"
                  label="Responder Para: E-mail"
                  bottom-slots
                  :error="errorsTemplate.replyto_email ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsTemplate.replyto_email"
                      :key="error + i"
                    >{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12" v-if="templateData.tipo != 'whatsapp' && templateData.tipo !== 'relatorio-whatsapp'">
                <q-input
                  dense
                  v-model="templateData.return_path"
                  label="E-mail para tratar errors (bounces)"
                  bottom-slots
                  :error="errorsTemplate.return_path ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsTemplate.return_path"
                      :key="error + i"
                    >{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-4">
                <q-checkbox v-model="templateData.padrao" label="Padrão" color="positive" />
              </div>
              <div class="col-12 col-md-4">
                <q-checkbox
                  color="positive"
                  label="Enviar o boleto como anexo"
                  v-model="templateData.enviar_boleto_anexo">
                  <q-tooltip>Somente boletos em aberto e registrados serão enviados</q-tooltip>
                  </q-checkbox>
              </div>
              <div class="col-12 col-md-4">
                <q-checkbox
                  color="positive"
                  label="Enviar o extrato como anexo"
                  v-model="templateData.enviar_extrato_anexo"
                />
              </div>

              <div class="col-12" v-if="variaveis.length && (templateData.tipo === 'whatsapp' || templateData.tipo === 'relatorio-whatsapp')">
                <q-select
                  v-model="variavel"
                  :options="variaveis"
                  label="Variáveis"
                  bottom-slots
                  dense
                />
              </div>

              <div class="col-12" v-if="templateData.tipo === 'whatsapp' || templateData.tipo === 'relatorio-whatsapp'">
                <q-input
                  v-model="templateData.bodyWhatsapp"
                  outlined
                  autogrow
                  label="Mensagem"
                  :error="errorsTemplate.body ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsTemplate.body" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12" v-if="templateData.tipo != 'whatsapp' && templateData.tipo !== 'relatorio-whatsapp'">
                <q-editor
                  ref = "editor"
                  v-model="templateData.body"
                  :dense="$q.screen.lt.md"
                  min-height="5rem"
                  @paste.native="evt => handleFileUpload(evt)"
                  @drop.native="evt => handleFileUpload(evt)"
                  :toolbar="[
                  ['variavel'],
                  ['left','center','right','justify'],
                  ['bold','italic','underline','strike', 'color', 'colorFill'],
                  ['link'],
                  ['image'],
                  [
                    {
                      label: $q.lang.editor.formatting,
                      icon: $q.iconSet.editor.formatting,
                      list: 'no-icons',
                      options: [
                        'p',
                        'h1',
                        'h2',
                        'h3',
                        'h4',
                        'h5',
                        'h6',
                      ]
                    },
                    {
                      icon: $q.iconSet.editor.fontSize,
                      fixedLabel: true,
                      fixedIcon: true,
                      list: 'no-icons',
                      options: [
                        'size-1',
                        'size-2',
                        'size-3',
                        'size-4',
                        'size-5',
                        'size-6',
                        'size-7'
                      ]
                    },
                    {
                      label: $q.lang.editor.defaultFont,
                      icon: $q.iconSet.editor.font,
                      fixedIcon: true,
                      list: 'no-icons',
                      options: [
                        'default_font',
                        'arial',
                        'arial_black',
                        'comic_sans',
                        'courier_new',
                        'impact',
                        'lucida_grande',
                        'times_new_roman',
                        'verdana'
                      ]
                    },
                    'removeFormat'
                  ],
                  ['quote', 'unordered', 'ordered'],
                  ['fullscreen'],
                  ['undo','redo'],
                  ['viewsource'],
                  ]"
                  :fonts="{
                    arial: 'Arial',
                    arial_black: 'Arial Black',
                    comic_sans: 'Comic Sans MS',
                    courier_new: 'Courier New',
                    impact: 'Impact',
                    lucida_grande: 'Lucida Grande',
                    times_new_roman: 'Times New Roman',
                    verdana: 'Verdana'
                  }"
                  >
                     <template v-slot:image>
                       <div class="q-mx-sm q-mt-xs">
                          <div class="cursor-pointer">
                            <q-icon name="photo" size="xs"/>
                            <q-popup-edit v-model="image"  auto-save>
                              <q-input v-model="image" placeholder="https://" dense autofocus counter @keyup.enter="insertImage()" />
                            </q-popup-edit>
                          </div>
                        </div>
                    </template>
                    <template v-slot:color>
                      <q-btn-dropdown dense
                        no-caps
                        ref="color"
                        no-wrap
                        unelevated
                        text-color="black"
                        size="sm"
                        icon="format_color_text"
                      >
                      <q-list dense>
                        <q-item tag="label"
                                clickable
                                @click="color('foreColor', foreColor)">
                          <q-item-section>
                            <q-color v-model="foreColor"
                                no-header
                                no-footer
                                default-view="palette"
                                :palette="textColor"
                            />
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-btn-dropdown>
                    </template>
                    <template v-slot:colorFill>
                      <q-btn-dropdown dense
                        no-caps
                        ref="colorFill"
                        no-wrap
                        unelevated
                        text-color="black"
                        size="sm"
                        icon="format_color_fill"
                      >
                      <q-list dense>
                        <q-item
                          tag="label"
                          clickable
                          @click="colorFill('backColor', highlight)"
                        >
                          <q-item-section>
                            <q-color
                              v-model="highlight"
                              default-view="palette"
                              no-header
                              no-footer
                              :palette="fillColor"
                            />
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-btn-dropdown>
                    </template>

                    <template v-slot:variavel>
                      <q-btn-dropdown
                        dense
                        no-caps
                        ref="variavel"
                        no-wrap
                        unelevated
                        text-color="black"
                        label="Variáveis"
                        size="sm"
                      >
                        <q-list v-if="variaveis.length">
                          <q-item
                            v-for="(variavel, idx) in variaveis" :key="idx"
                            clickable
                            @click="insertVariavel(variavel)"
                          >
                            <q-item-section>
                              {{ variavel.label }}
                            </q-item-section>
                          </q-item>
                        </q-list>
                      </q-btn-dropdown>
                    </template>
                  </q-editor>
                <span
                class="text-negative"
                  v-for="(error, i) in errorsTemplate.body"
                  :key="error + i"
                  style="font-size: 12px"
                >{{error}}</span>
              </div>
              <div class="col-12 col-md-12 align flex justify-end">
                <div class="q-pr-sm flex">
                  <q-input
                    style="min-width: 250px"
                    dense
                    label="Enviar email de teste"
                    v-model="emailTest"
                  >
                    <q-btn
                      round
                      dense
                      flat
                      :loading="enviandoEmail"
                      icon="outgoing_mail"
                      @click="executarTestes()"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                  </q-input>
                </div>
                <div>
                  <q-btn
                    no-wrap
                    v-ripple
                    label="Salvar"
                    no-caps
                    class="q-ml-sm q-px-md bg-positive text-white"
                    clickable
                    type="submit"
                    :loading="salvandoTemplate"
                    @click="salvar()"
                  >
                    <template v-slot:loading>
                      <q-spinner color="grey-10" size="1em" />
                    </template>
                  </q-btn>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify, Loading, Dialog } from "quasar";

export default {
  name: "modal-novo-template",
  data() {
    return {
      templateTypes: [
        {
          label: "Cobrança",
          value: "boleto",
        },
        {
          label: "Cadastro",
          value: "cadastro",
        },
        {
          label: "Relatório",
          value: "relatorio",
        },
        {
          label: "Backup",
          value: "backup",
        },
        {
          label: "WhatsApp",
          value: "whatsapp",
        },
        {
          label: "Notas Fiscais",
          value: "nota",
        },
        {
          label: "Área do cliente",
          value: "areaclient",
        },
        {
          label: "Cartão de Crédito",
          value: "cartao",
        },
        {
          label: "Cartão de Crédito - Pagamento Recebido",
          value: "cartao-pagamento",
        },
        {
          label: "Cartão de Crédito - Cadastrar Cartão",
          value: "cartao-cadastro",
        },
        {
          label: "Cartão de Crédito - Erro ao cobrar",
          value: "cartao-erro",
        },
        {
          label: "Registro de Movimentação em Espécie",
          value: "rme",
        },
        {
          label: "Relatorio em anexo via Whatsapp",
          value: "relatorio-whatsapp",
        },
      ],
      variaveisBoleto: [
        {
          label: "Url da imagem no email",
          value: "{VisualizacaoEmail}",
        },
        {
          label: "Campo nome no cadastro",
          value: "{CadastroNome}",
        },
        {
          label: "Boleto Descrição",
          value: "{FaturaDescricao}",
        },
        {
          label: "Link da Fatura/Boleto",
          value: "{FaturaLink}",
        },
        {
          label: "Link do Extrato",
          value: "{ExtratoLink}",
        },
        {
          label: "Boleto Vencimento",
          value: "{FaturaVencimento}",
        },
        {
          label: "Boleto Referencia",
          value: "{FaturaReferencia}",
        },
        {
          label: "Boleto Liquidação",
          value: "{FaturaLiquidacao}",
        },
        {
          label: "Boleto Valor",
          value: "{FaturaValor}",
        },
        {
          label: "Boleto Valor Liquidado",
          value: "{FaturaValorLiquidado}",
        },
        {
          label: "Código de Barras do Boleto",
          value: "{FaturaCodigoBarras}",
        },
        {
          label: "Pix Copia e Cola",
          value: "{FaturaPixCopiaCola}"
        },
        {
          label: "QR Code do Pix",
          value: "{FaturaPixQRCode}"
        },
        {
          label: "Link Nota Fiscal",
          value: "{NotaFiscalLink}",
        },
        {
          label: "Número Nota Fiscal",
          value: "{NotaFiscalNumero}",
        },
        {
          label: "Código de Verificação da Nota Fiscal",
          value: "{NotaFiscalCodigoVerificacao}",
        },
        {
          label: "Logo da Empresa",
          value: "{Logo da Empresa}",
        },
        {
          label: "XML da Nota Fiscal",
          value: "{NotaFiscalXml}",
        },
      ],
      variaveisCadastro: [
        {
          label: "Campo nome no cadastro",
          value: "{CadastroNome}",
        },
        {
          label: "Link de ativação da área do cliente",
          value: "{AtivarCadastroURL}",
        },
        {
          label: "Url da imagem no email",
          value: "{VisualizacaoEmail}",
        },
        {
          label: "Mensagem customizada",
          value: "{mensagem}",
        },
      ],
      variaveisAreacliente: [
        {
          label: "Campo nome no cadastro",
          value: "{CadastroNome}",
        },
        {
          label: "Campo email no cadastro",
          value: "{CadastroEmail}",
        },
        {
          label: "Cadastro CPF ou CNPJ",
          value: "{CadastroCpfCnpj}",
        },
        {
          label: "CNPJ do prestador de serviço",
          value: "{EmpresaCpfCnpj}",
        },
        {
          label: "Link de ativação da área do cliente",
          value: "{AtivarCadastroURL}",
        },
      ],
      variaveisBackup: [
        {
          label: "Mensagem customizada",
          value: "{mensagem}",
        },
      ],
      variaveisRelatorio: [
        {
          label: "Mensagem customizada",
          value: "{mensagem}",
        },
      ],
      variaveisNotaFiscal: [
        {
          label: "Campo nome no cadastro",
          value: "{CadastroNome}",
        },
        {
          label: "Campo email no cadastro",
          value: "{CadastroEmail}",
        },
        {
          label: "Cadastro CPF ou CNPJ",
          value: "{CadastroCpfCnpj}",
        },
        {
          label: "Receita Descrição",
          value: "{FaturaDescricao}",
        },
        {
          label: "Receita Valor",
          value: "{FaturaValor}",
        },
        {
          label: "Link Nota Fiscal",
          value: "{FaturaLink}",
        },
        {
          label: "Número Nota Fiscal",
          value: "{FaturaNumero}",
        },
        {
          label: "Código de Verificação da Nota Fiscal",
          value: "{FaturaCodigoVerificacao}",
        },
        {
          label: "CNPJ do Prestador de Serviço",
          value: "{EmpresaCpfCnpj}",
        },
        {
          label: "XML da Nota Fiscal",
          value: "{NotaFiscalXml}",
        },
      ],
      variaveisWhatsapp: [
        {
          label: "Campo nome no cadastro",
          value: "{CadastroNome}",
        },
        {
          label: "Campo email no cadastro",
          value: "{CadastroEmail}",
        },
        {
          label: "Cadastro CPF ou CNPJ",
          value: "{CadastroCpfCnpj}",
        },
        {
          label: "Boleto Descrição",
          value: "{FaturaDescricao}",
        },
        {
          label: "Boleto Link",
          value: "{FaturaLink}",
        },
        {
          label: "Extrato Link",
          value: "{ExtratoLink}",
        },
        {
          label: "Boleto Vencimento",
          value: "{FaturaVencimento}",
        },
        {
          label: "Boleto Referencia",
          value: "{FaturaReferencia}",
        },
        {
          label: "Boleto Liquidação",
          value: "{FaturaLiquidacao}",
        },
        {
          label: "Boleto Valor",
          value: "{FaturaValor}",
        },
        {
          label: "Boleto Valor Liquidado",
          value: "{FaturaValorLiquidado}",
        },
        {
          label: "Pix Copia e Cola",
          value: "{FaturaPixCopiaCola}"
        },
        {
          label: "Agenda Título",
          value: "{EventoAgenda}",
        },
        {
          label: "Agenda Horário",
          value: "{EventoHorario}",
        },
        {
          label: "Agenda Descrição",
          value: "{EventoDescricao}",
        },
        {
          label: "Anexo Nome",
          value: "{NomeAnexo}",
        },
        {
          label: "Anexo Link",
          value: "{LinkAnexo}",
        },
        {
          label: "Link Nota Fiscal",
          value:"{NotaFiscalLink}"
        },
        {
          label: "Número Nota Fiscal",
          value:"{NotaFiscalNumero}"
        },
        {
          label: "Código de Verificação da Nota Fiscal",
          value:"{NotaFiscalCodigoVerificacao}"
        },
        {
          label: "XML da Nota Fiscal",
          value:"{NotaFiscalXml}"
        }
      ],
      variaveisCartao: [
        {
          label: "Campo nome no cadastro",
          value: "{CadastroNome}",
        },
        {
          label: "Campo email no cadastro",
          value: "{CadastroEmail}",
        },
        {
          label: "Cadastro CPF ou CNPJ",
          value: "{CadastroCpfCnpj}",
        },
        {
          label: "Boleto Descrição",
          value: "{FaturaDescricao}",
        },
        {
          label: "Boleto Link",
          value: "{FaturaLink}",
        },
        {
          label: "Boleto Vencimento",
          value: "{FaturaVencimento}",
        },
        {
          label: "Boleto Referencia",
          value: "{FaturaReferencia}",
        },
        {
          label: "Boleto Valor",
          value: "{FaturaValor}",
        },
        {
          label: "CNPJ do Prestador de Serviço",
          value: "{EmpresaCpfCnpj}",
        },
      ],
      variavel: null,
      image: "",
      textColor: ['#FF6666', '#FFB366', '#FFFF66', '#66FF66', '#66FF66', '#66FF66', '#66B3FF', '#6666FF', '#B366FF', '#FF66FF',
                  '#FF0000', '#FF8000', '#FFFF00', '#00FF00', '#00FF80', '#00FFFF', '#0080FF', '#0000FF', '#8000FF', '#FF00FF',
                  '#D60000', '#D66C00', '#D6D600', '#00D600', '#00D66C', '#00D6D6', '#006CD6', '#0000D6', '#6C00D6', '#D600D6',
                  '#FFFFFF', '#CDCDCD', '#B2B2B2', '#999999', '#7F7F7F', '#666666', '#4C4C4C', '#333333', '#191919', '#000000'
                  ],
      fillColor: ['#FF6666', '#FFB366', '#FFFF66', '#66FF66', '#66FF66', '#66FF66', '#66B3FF', '#6666FF', '#B366FF', '#FF66FF',
                  '#FF0000', '#FF8000', '#FFFF00', '#00FF00', '#00FF80', '#00FFFF', '#0080FF', '#0000FF', '#8000FF', '#FF00FF',
                  '#D60000', '#D66C00', '#D6D600', '#00D600', '#00D66C', '#00D6D6', '#006CD6', '#0000D6', '#6C00D6', '#D600D6',
                  '#FFFFFF', '#CDCDCD', '#B2B2B2', '#999999', '#7F7F7F', '#666666', '#4C4C4C', '#333333', '#191919', '#000000'
                ],
      foreColor: '',
      highlight: '',
      emailTest: '',
      enviandoEmail: false
    };
  },
  methods: {
    closeModal() {
      this.$store.dispatch("clearTemplate");
      this.$store.commit("openDialogNovoTemplate", false);
    },
    salvar() {
      if (this.templateData.tipo === "whatsapp" || this.templateData.tipo === "relatorio-whatsapp") {
        this.templateData.body = this.templateData.bodyWhatsapp;
      }

      if (!this.isNewTemplate) {
        this.$store.dispatch("editarTemplatePost", this.templateData);
      } else {
        this.$store.dispatch("salvarTemplate", this.templateData);
      }
    },
    async executarTestes(){
      let data = this.templateData;
      data.email = this.emailTest;
      this.enviandoEmail = true;
      let result = await axios.post("/templates/edit?testar_email=1", data);
      this.enviandoEmail = false;

      if (!result.data.success) {
        Notify.create({
          message: result.data.message,
          color: "negative",
          position: "top-right"
        });
        return;
      }

      Notify.create({
        message: result.data.message,
        color: "green",
        position: "top-right"
      });
      return;
    },
    clearTemplate() {
      this.$store.dispatch("clearTemplate");
    },
    insertImage() {
      let img = '<img src="'+this.image+'" />';
      const editor = this.$refs.editor;
      editor.runCmd('insertHTML' , img);
      this.image = "";
    },
    insertVariavel(variavel) {
      if (this.templateData.tipo == "whatsapp" || this.templateData.tipo === "relatorio-whatsapp") {
        this.templateData.bodyWhatsapp += variavel.value;
      } else {
        const edit = this.$refs.editor;
        this.variaveisBoleto[14].value = '<img src="'+this.dataEmpresa.logo_url+'"/>';
        edit.runCmd('insertHTML', variavel.value);
      }

      this.variavel = null;
    },
    color(cmd, name) {
      const edit = this.$refs.editor;
      this.$refs.color.hide();
      edit.caret.restore();
      edit.runCmd(cmd, name);
      edit.focus();
    },
    colorFill(cmd, name) {
      const edit = this.$refs.editor;
      this.$refs.color.hide();
      edit.caret.restore();
      edit.runCmd(cmd, name);
      edit.focus();
    },
    handleFileUpload(event) {
      let image;
      if (event instanceof DragEvent) {
        image = event.dataTransfer.files[0];
      } else if (event instanceof ClipboardEvent) {
        image = event.clipboardData.files[0];
      } else {
        image = event.target.files[0];
      }

      if (image) {
        event.preventDefault();
        // caso o tamanho da imagem seja maior que 10MB, não envia
        if (image.size > 10000000) {
          Dialog.create({
            title: "Erro",
            message: "A imagem deve ter no máximo 10MB",
            class: "bg-negative text-white",
            ok: {
              color: "white",
              "text-color": "black"
            }
          });
          return;
        }

        const formData = new FormData();
        formData.append("image", image);

        Loading.show({
          message: "Enviando imagem..."
        });

        axios
          .post("/templates/upload-image", formData)
          .then(res => {
            Loading.hide();
            if (!res.data.success) {
              Notify.create({
                message: res.data.message,
                color: "negative",
                position: "top-right"
              });
              return;
            }

            const imageURL = res.data.imageUrl;
            const img = '<img src="'+imageURL+'" />';
            const editor = this.$refs.editor;
            editor.runCmd('insertHTML' , img);
          });
      }
    }
  },
  watch: {
    variavel(value) {
      if (value) {
        this.insertVariavel(value);
      }
    }
  },
  computed: {
    ...mapGetters([
      "templateData",
      "dataEmpresa",
      "config",
      "errorsTemplate",
      "salvandoTemplate",
      "isNewTemplate"
    ]),
    openDialogNovoTemplate: {
      get() {
        return this.$store.getters.openDialogNovoTemplate;
      },
      set() {}
    },
    variaveis: {
      get() {
        switch (this.templateData.tipo) {
          case "whatsapp":
            return this.variaveisWhatsapp;
          case "relatorio-whatsapp":
            return this.variaveisWhatsapp;
          case "nota":
            return this.variaveisNotaFiscal;
          case "boleto":
            return this.variaveisBoleto;
          case "cadastro":
            return this.variaveisCadastro;
          case "backup":
            return this.variaveisBackup;
          case "relatorio":
            return this.variaveisRelatorio;
          case "areaclient":
            return this.variaveisAreacliente;
          case "cartao":
          case "cartao-pagamento":
          case "cartao-cadastro":
            return this.variaveisCartao;
          case "cartao-erro":
            return [
            {
              label: "Erro",
              value: "{Erro}",
            },
            ...this.variaveisCartao,
          ];
          default:
            return [];
        }
      },
      set(val) {},
    }
  }
};
</script>
