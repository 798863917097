<template>
  <div id="cardGrafReceita">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-h6">
            <q-icon style="position:relative; top: -3px" name="payment" />
            {{fetchUser.db.clientesFormaPagamento.title}}
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section class="q-py-none">
      <GChart
        type="PieChart"
        v-if="exibirGrafico"
        :data="fetchUser.db.clientesFormaPagamento.data"
        :options="chartOptions"
      />
      <p v-else class="text-center q-mt-lg">Nenhuma forma de pagamento com clientes utilizando</p>
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GChart } from "vue-google-charts";
export default {
  name: "card-graf-clientes-forma-pagamento",
  components: {
    GChart
  },
  data() {
    return {
      chartOptions: {
        height: 250,
        legend: { position: "top", maxLines: 3 },
      }
    };
  },
  methods: {
    atualizaDash() {
      this.$store.dispatch("fetchUser");
    },
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "9",
      });
    },
  },
  computed: {
    ...mapGetters(["fetchUser", "layout"]),
    exibirGrafico() {
      const forma = this.fetchUser.db.clientesFormaPagamento.data.find(forma => parseInt(forma[1]) > 0);
      return forma ? true : false;
    },
  },
  mounted() {}
};
</script>

<style lang="stylus" scoped></style>
