<template>
  <div id="modalNovoExtrato">
    <q-dialog v-model="OpenDialogNovaTransferencia" persistent>
      <q-card :style="`width: 100% !important; max-width: ${usarSangria ? '1000px' : '400px'}; !important`">
        <q-card-section class="row items-center">
          <div class="text-h6">Transferir</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal(false)" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-px-md row q-col-gutter-md">
            <div :class="`col col-md-${usarSangria ? 4 : 12}`">
              <div id="dtmovimentoTransferencia">
                <q-input
                  bottom-slots
                  :error="errors.vencimento ? true : false"
                  v-model="dataVenc"
                  label="Data"
                  @click="$refs.qDateProxy.show();"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="dataVenc"
                          @input="() => $refs.qDateProxy.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.vencimento" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col col-md-12" id="contaOrigem">
                <q-select
                  clearable
                  v-model="tipo.conta_origem"
                  :options="contas"
                  label="Transferir de"
                  bottom-slots
                  :error="errors.conta_origem ? true : false"
                  option-value="id"
                  option-label="titulo"
                  emit-value
                  map-options
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.conta_origem" :key="error + i">{{error}}</span>
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col col-md-12" id="contaTransferir">
                <q-select
                  clearable
                  v-model="tipo.conta_destino"
                  :options="contas"
                  label="Para"
                  bottom-slots
                  :error="errors.conta_destino ? true : false"
                  option-value="id"
                  option-label="titulo"
                  emit-value
                  map-options
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.conta_destino" :key="error + i">{{error}}</span>
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col col-md-12" id="descricaoTransferencia">
                <q-input
                  v-model="descricao"
                  label="Descrição"
                  bottom-slots
                  :error="errors.descricao ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.descricao" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col col-md-12" id="valorTransferencia">
                <q-field
                  v-model="valor"
                  label="Valor"
                  bottom-slots
                  :error="errors.valor ? true : false"
                  :disable="usarSangria"
                >
                  <template
                    v-slot:control="{
                      id,
                      floatingLabel,
                      value,
                      emitValue
                    }"
                  >
                    <money
                      :id="id"
                      :value="value"
                      class="q-field__native text-right"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                      name="valorCaixa"
                      @input="emitValue"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.valor" :key="error + i">{{error}}</span>
                  </template>
                </q-field>
              </div>
            </div>

            <div v-if="usarSangria && isLoterica" class="col-8">
              <div class="text-center q-mb-md">
                <q-btn-group rounded flat no-wrap no-caps>
                  <q-btn
                    class="white text-blue-7"
                    clickable
                    @click="changeFilterPeriodo('prev')"
                    rounded
                    icon="chevron_left"
                  />
                  <q-btn class="white text-blue-7" rounded :label="dtIniVenc" >
                    <template v-slot:default>
                      <q-popup-proxy ref="dtIni" transition-show="scale" transition-hide="scale">
                        <q-date
                          v-model="dtIniVenc"
                          mask="DD/MM/YYYY"
                          minimal
                          today-btn
                          :locale="langDate"
                          @input="pulaCampo('dtIni')"
                        />
                      </q-popup-proxy>
                    </template>
                  </q-btn>
                  <q-btn class="white text-blue-7" rounded :label="dtFimVenc">
                    <template v-slot:default>
                      <q-popup-proxy ref="dtFim" transition-show="scale" transition-hide="scale">
                        <q-date
                          v-model="dtFimVenc"
                          mask="DD/MM/YYYY"
                          minimal
                          today-btn
                          :locale="langDate"
                          @input="pulaCampo('dtFim')"
                        />
                      </q-popup-proxy>
                    </template>
                  </q-btn>
                  <q-btn
                    class="white text-blue-7"
                    clickable
                    @click="changeFilterPeriodo('next')"
                    rounded
                    icon="chevron_right"
                  />
                </q-btn-group>
              </div>

              <div v-if="loadingSangrias" class="text-center">
                <q-spinner  color="blue-7" size="2em" />
              </div>
              <div v-else-if="sangrias.length == 0">
                <div class="text-center text-body1">Nenhuma sangria encontrada na data e conta selecionada.</div>
              </div>
              <div v-else>
                <q-markup-table
                  flat
                  dense
                  separator="horizontal"
                  class="q-mx-md"
                  style="width: 100%;"
                >
                  <thead>
                    <tr>
                      <th class="text-left">
                        <q-checkbox
                          v-model="selectAllSangrias"
                        />
                      </th>
                      <th class="text-left">Data</th>
                      <th class="text-left">Descrição</th>
                      <th class="text-right">Valor</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(sangria, index) in sangrias"
                      :key="index"
                      :style="sangria.selected ? 'background-color: #e9e9e9;' : ''"
                    >
                      <td class="text-left" style="max-width: 100px;">
                        <q-checkbox
                          v-model="sangria.selected"
                        />

                      </td>
                      <td class="text-left">{{ sangria.vencimento | formatDate }}</td>
                      <td class="text-left">
                        {{ sangria.descricao.length > 40 ?
                          sangria.descricao.substring(0, 40) + '...'
                          : sangria.descricao
                        }}
                        <q-tooltip v-if="sangria.descricao.length > 40">
                          {{ sangria.descricao }}
                        </q-tooltip>
                      </td>
                      <td class="text-right">{{ sangria.valor | currency }}</td>
                    </tr>
                  </tbody>
                </q-markup-table>
                <div class="q-ml-auto q-mt-md" style="width: 40%">
                  <div class="flex justify-between">
                    <div>
                      <b>Total</b>
                      ({{ sangrias.length }})
                    </div>

                    <span>R${{ totalValorSangrias | currency }}</span>
                  </div>


                  <div class="flex justify-between">
                    <div>
                      <b>Selecionado</b>
                      ({{ sangriasSelecionadas.length }})
                    </div>

                    <span>R${{ valor | currency }}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </q-card-section>

        <div class="flex justify-end q-mb-md" v-if="isLoterica">
          <div>
            <q-btn
              flat
              no-caps
              label="Utilizar Sangrias para Compor"
              :icon-right="usarSangria ? 'keyboard_arrow_left' : 'keyboard_arrow_right'"
              :disable="!sangriaAvailable"
              :loading="loadingSangrias"
              @click="listarSangrias(false)"
            />

            <q-tooltip v-if="!sangriaAvailable">
              Preencha a data e a conta de origem para utilizar as sangrias
            </q-tooltip>
          </div>
        </div>

         <q-card-actions align="right" class="text-primary">
          <q-btn
              no-wrap
              v-ripple
              id="SalvarNovo"
              label="Salvar & Novo"
              no-caps
              style="margin: 0 10px 20px 0"
              clickable
              color="grey"
              :loading="loadingNovoSave"
              @click="SalvarTransferencia(true)"
            >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>

          <q-btn
            no-wrap
            v-ripple
            label="Salvar"
            id="Salvar"
            color="green"
            no-caps
            style="margin: 0 20px 20px 0"
            clickable
            :loading="loading"
            @click="SalvarTransferencia()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify } from "quasar";
import axios from "@/axios-auth";
import { Money } from "v-money";
const moment = require("moment");

export default {
  name: "modal-novo-extrato",
  components: { Money },
  data() {
    return {
      tipo: {
        conta_origem: null,
        conta_destino: null
      },
      descricao: "",
      dataVenc: moment().format("DD/MM/YYYY"),
      dtIniVenc: moment().startOf("week").format("DD/MM/YYYY"),
      dtFimVenc: moment().endOf("week").format("DD/MM/YYYY"),
      errors: [],
      valor: 0,
      loading: false,
      loadingNovoSave: false,
      sangrias: [],
      sangriasSelecionadas: [],
      usarSangria: false,
      loadingSangrias: false,
      selectAllSangrias: false,
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    closeModal(fechar) {
      if(fechar != true){
        this.$store.commit("OpenDialogNovaTransferencia", false);
      }
      this.tipo = {
        conta_origem: null,
        conta_destino: null
      };
      this.descricao = "";
      this.dataVenc = moment().format("DD/MM/YYYY");
      this.errors = [];
      this.valor = 0;
      this.sangrias = [];
      this.sangriasSelecionadas = [];
      this.usarSangria = false;
      this.loadingSangrias = false;

    },
    showErrors(value) {
      if (value === "dataVenc") {
        Notify.create({
          message: "Você deve selecionar uma Data para a transferência!",
          color: "orange",
          position: "top-right"
        });
      }
      if (value === "contas") {
        Notify.create({
          message: "Você deve selecionar ao menos uma conta origem ou uma conta destino!",
          color: "orange",
          position: "top-right"
        });
      }
      return;
    },
    pulaCampo(date) {
      if (date === "dtIni") {
        this.$refs.dtIni.hide();
        this.$refs.dtFim.show();
      }

      if (date === "dtFim") {
        this.$refs.dtFim.hide();
      }

      return;
    },
    changeFilterPeriodo(periodo) {
      let dtIniVenc = moment(this.dtIniVenc, "DD/MM/YYYY");
      let dtFimVenc = moment(this.dtFimVenc, "DD/MM/YYYY");

      if (periodo == "prev") {
        dtIniVenc.subtract(1, "week");
        dtFimVenc.subtract(1, "week");
      }

      if (periodo == "next") {
        dtIniVenc.add(1, "week");
        dtFimVenc.add(1, "week");
      }

      this.dtIniVenc = dtIniVenc.format("DD/MM/YYYY");
      this.dtFimVenc = dtFimVenc.format("DD/MM/YYYY");

      return;
    },
    atualizarSangrias() {
      if (!this.usarSangria) return;

      this.sangrias = [];
      this.listarSangrias(true);
    },
    listarSangrias(atualizar) {
      if (!atualizar && this.usarSangria) {
        this.usarSangria = false;
        return;
      }

      let dtini = moment(this.dtIniVenc, "DD/MM/YYYY").format("YYYY-MM-DD");
      let dtfim = moment(this.dtFimVenc, "DD/MM/YYYY").format("YYYY-MM-DD");
      let conta_id = this.tipo.conta_origem;

      if (!conta_id) {
        conta_id = null;
      }

      this.loadingSangrias = true;
      this.errors = [];

      axios
        .get("/financeiro/sangrias", {
          params: {
            dtini,
            dtfim,
            conta_id
          }
        })
        .then(res => {
          if (res.data.success) {
            if (res.data.sangrias.length > 0) {
              res.data.sangrias.forEach(sangria => {
                sangria.selected = false;
              });
              this.sangrias = res.data.sangrias;
            }
          } else {
            this.sangrias = [];
            this.errors = res.data.errors;
          }

          this.valor = 0;
          this.usarSangria = true;
          this.loadingSangrias = false;
        });
    },
    SalvarTransferencia(novo = false) {
      if (this.loading) return;
      if (this.dataVenc === "") return this.showErrors("dataVenc");
      if (this.tipo.conta_origem === null && this.tipo.conta_destino === null)
        return this.showErrors("contas");

      if(novo){
         this.loadingNovoSave = true;
      }else{
        this.loading = true;
      }

      let val = this.valor.toString().replace(",", ".");
      val = parseFloat(val);
      let body = {
        vencimento: this.dataVenc,
        descricao: this.descricao,
        valor: val,
        conta_origem: this.tipo.conta_origem,
        conta_destino: this.tipo.conta_destino,
      };

      if (this.usarSangria && this.sangriasSelecionadas.length > 0) {
        body.sangrias = this.sangriasSelecionadas;
      }

      axios
        .post("/financeiro/add/transferencia", body)
        .then(res => {
          if (res.data.status) {
            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            this.$store.dispatch("resetExtrato");
            this.$store.dispatch("listarExtrato", this.filtersExtrato);
            this.closeModal(novo);
          } else {
            if (res.data.tipo !== undefined && res.data.tipo == "sangria") {
              Notify.create({
                message: res.data.message,
                color: "orange",
                position: "top-right"
              });
            }

            this.errors = res.data.errors;
          }

          this.loading = false;
          this.loadingNovoSave = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.loading = false;
          this.loadingNovoSave = false;
        });
    }
  },
  computed: {
    ...mapGetters([
      "contas",
      "filtersExtrato",
      "dataEmpresa"
    ]),
    OpenDialogNovaTransferencia: {
      get() {
        return this.$store.getters.OpenDialogNovaTransferencia;
      },
      set() {}
    },
    isLoterica() {
      return this.dataEmpresa.assinatura.segmento === "Lotéricas";
    },
    sangriaAvailable() {
      if (this.usarSangria) return true;
      return this.dataVenc != '' && this.tipo.conta_origem != null;
    },
    totalValorSangrias() {
      let total = 0;
      this.sangrias.forEach(sangria => {
        total += sangria.valor;
      });

      return total;
    }
  },
  watch: {
    dtIniVenc() {
      if (!this.usarSangria) return;

      this.atualizarSangrias();
    },
    dtFimVenc() {
      if (!this.usarSangria) return;
      this.atualizarSangrias();
    },
    'tipo.conta_origem': {
      handler() {
        if (!this.usarSangria) return;
        this.atualizarSangrias();
      },
      deep: true
    },
    sangrias: {
      handler(val) {
        this.valor = 0;
        this.sangriasSelecionadas = [];

        val.forEach(sangria => {
          if (sangria.selected) {
            this.valor += sangria.valor;
            this.sangriasSelecionadas.push(sangria);
          }
        });
      },
      deep: true
    },
    selectAllSangrias(val) {
      this.sangrias.forEach(sangria => {
        sangria.selected = val;
      });
    }
  }
};
</script>
