<template>
  <div id="modalCaixaRecebimentoFiado">
    <q-dialog v-model="OpenDialogRecebimentoFiadoCaixas" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Receber Contas</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="clear()" />
        </q-card-section>
        <q-separator />
        <q-card-section v-if="!movcontaReturn" style="padding-bottom: 0">
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-10">
                <autocomplete
                  title="Busque uma conta"
                  :filter="filterFn"
                  :carregando="carregando"
                  :options="options"
                  :formatDisplay="formatDisplay"
                  :create="createValue"
                  @resultAutocomplete="resultEmitRecebe"
                  @input="criarDescricaoConta()"
                  :display="displayMontar"
                />
              </div>
              <div class="col-2 text-center">
                <q-btn
                  flat
                  rounded
                  dense
                  icon="add"
                  class="text-primary"
                  style="position:relative; top: 25px"
                  clickable
                  @click="newReceita"
                ></q-btn>
              </div>
            </div>
          </div>
        </q-card-section>

        <acao-caixa>
          <template v-slot:valor-bottom>

            <div class="col-12" v-if="exibirAdicionarSaldoCliente">
              <q-checkbox
                color="green"
                label="Adicionar no saldo do cliente"
                :disable="!enableAdicionarSaldoCliente"
                v-model="dadosCaixaContaPagarReceber.adicionarSaldoCliente"
              />
              <q-icon name="help_outline" color="grey-8" size="sx" class="q-pl-xs" >
                <q-tooltip>
                  O valor será incrementado no saldo do cliente.
                </q-tooltip>
              </q-icon>
              <div v-show="exibirSaldoCliente">
                <div>
                  Saldo Atual:
                  <span :class="classSaldoAtualCliente">
                    R$ {{ saldoAtualCliente | currency }}
                  </span>
                </div>
                <div>
                  Saldo Após a Liquidação:
                  <span :class="classNovoSaldoCliente">
                    R$ {{ novoSaldoCliente | currency }}
                  </span>
                </div>
              </div>
              <div v-if="loadingSaldoCliente">
                <q-spinner color="grey-10" size="1em" />
              </div>
            </div>

            <div class="col-12" v-show="exibirCriarNovaMovconta">
              <q-checkbox
                label="Criar outra receita com valor restante?"
                v-model="dadosCaixaContaPagarReceber.gerarNovaMovconta"
                color="green"
              />
            </div>

            <div class="col" v-show="exibirCriarNovaMovconta && dadosCaixaContaPagarReceber.gerarNovaMovconta">
            <q-input
              bottom-slots
              :error="errorsCaixas.novo_vencimento ? true : false"
              v-model="dadosCaixaContaPagarReceber.novoVencimento"
              label="Nova data de vencimento"
              @click="$refs.qDateNovoVencimento.show()"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateNovoVencimento"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="dadosCaixaContaPagarReceber.novoVencimento"
                      @input="() => $refs.qDateNovoVencimento.hide()"
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      minimal
                      today-btn
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span
                  v-for="(error, i) in errorsCaixas.novo_vencimento"
                  :key="error + i"
                  >{{ error }}</span
                >
              </template>
            </q-input>
          </div>

          <div class="col" v-show="exibirCriarNovaMovconta && dadosCaixaContaPagarReceber.gerarNovaMovconta">
            <q-field
              disable
              v-model="dadosCaixaContaPagarReceber.valorRestante"
              label="Valor Restante"
              bottom-slots
              :error="errorsCaixas.valor_restante ? true : false"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <money
                  :id="id"
                  class="q-field__native text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  v-show="floatingLabel"
                >
                </money>
              </template>
              <template v-slot:error>
                <span v-for="(error, i) in errorsCaixas.valor_restante" :key="error + i">{{
                  error
                }}</span>
              </template>
            </q-field>
          </div>

          </template>
        </acao-caixa>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { bus } from "@/main";
import { Notify } from "quasar";
import moment from "moment";
import { Money } from "v-money";

export default {
  name: "modal-caixa-recebimento-fiado",
  components: { Money },
  data() {
    return {
      movconta_id: null,
      cadastro_id: null,
      cadastro_nome: null,
      movconta_valor: null,
      movconta_descricao: "",
      carregando: false,
      options: [],
      selectItem: null,
      autocompleteResult: {},
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
      loadingSaldoCliente: false,
      saldoAtualCliente: 0.00,
    };
  },
  methods: {
    clear(modal = true) {
      this.$store.commit("movcontaReturn", null);
      this.$store.commit("isReadOnlyCaixa", false);
      this.$store.commit("blockValor", false);
      this.$store.commit("errorsCaixas", {});

      if (modal) {
        this.$store.commit("OpenDialogRecebimentoFiadoCaixas", false);
      }
    },
    newReceita() {
      this.$store.commit("callDespesa", false);
      this.$store.dispatch("planoContas");
      this.$store.commit("OpenDialogNovaReceita", true);
    },
    criarDescricaoConta() {
      if (
        this.autocompleteResult != null &&
        this.autocompleteResult.id === null
      ) {
        Notify.create({
          message: "Não foi possivel localizar conta !",
          color: "orange",
          position: "top-right"
        });
        return false;
      }
      if (!this.autocompleteResult) {
        this.movconta_id = null;
        this.cadastro_id = null;
        this.cadastro_nome = null;
        this.movconta_descricao = "";
        this.movconta_vencimento = null;
        this.$store.commit("venda", {
          descricao: "",
          valor: 0.00,
        });
        this.$store.commit("movconta_id_caixa", null);
        this.$store.commit("conta_id_caixa", null);
        return;
      }

      this.movconta_id = this.autocompleteResult.id;
      this.cadastro_id = this.autocompleteResult.cadastro_id;
      this.cadastro_nome =
        this.autocompleteResult.cadastro === null
          ? "Sem nome"
          : this.autocompleteResult.cadastro.nome;
      this.movconta_descricao = this.autocompleteResult.descricao;
      this.movconta_valor = this.autocompleteResult.valor;
      let venc = this.autocompleteResult.vencimento.split("/");
      this.movconta_vencimento = venc[2] + "/" + venc[1] + "/" + venc[0];
      this.save();
      this.$store.commit("isReadOnlyCaixa", true);
    },
    save() {
      if (
        this.movcontaReturn &&
        this.cadastro_nome === null &&
        this.movconta_id === null
      ) {
        this.movconta_id = this.movcontaReturn.cadastro.id;
        this.cadastro_nome = this.movcontaReturn.cadastro.nome;
      }
      this.$store.commit("movconta_id_caixa", this.movconta_id);
      this.$store.commit("conta_id_caixa", this.conta_id);
      this.$store.commit("venda", {
        descricao:
          "Recebido ID: " + this.movconta_id + ", " + this.movconta_descricao +
          (this.cadastro_nome ? `, ${this.cadastro_nome}` : ''),
        valor: Math.abs(this.movconta_valor).toFixed(2)
      });
      this.$store.commit("isReadOnlyCaixa", false);
      this.$store.commit("blockValor", false);
    },
    // Funções do autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/financeiro/busca/receita?term=" + val).then(res => {
          this.carregando = false;
          this.options = res.data;
        });
      });
    },
    createValue(val, done) {
      if (val && !val.name) {
        Notify.create({
          message: "Não foi possivel localizar conta !",
          color: "orange",
          position: "top-right"
        });
        return false;
      }

      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
    },
    formatValor(value) {
      if (!value) return "R$ 0,00";
      var val = (value / 1).toFixed(2).replace(".", ",");
      return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDisplay(result) {
      let receita = {
        id: null,
        nome: "",
        descricao: "",
        valor: 0,
        vencimento: "",
        verificarCadatros: ""
      };

      receita.id = result.id;
      receita.verificarCadatros = !result.cadastro
        ? (result.nome ? result.nome : "Sem cadastro")
        : result.cadastro.nome;
      receita.nome =
        result["cadastro"] === null ? "Sem cadastro" : receita.verificarCadatros;
      receita.descricao =
        result.descricao === null ? "Sem Descrição" : result.descricao;
      receita.valor =
        result.valor === null ? "Sem Valor" : this.formatValor(result.valor);
      receita.vencimento =
        result.vencimento === null
          ? "Sem Vencimento"
          : moment.utc(result.vencimento).format("DD/MM/YYYY");
        return `
          <span>
            ${receita.vencimento} - <strong>${receita.valor}</strong>
            <br/>
            Cliente <strong>${receita.verificarCadatros}</strong>
            <br/>
            ${receita.id} - ${receita.descricao}
          </span>
        `;
    },
    resultEmitRecebe(result) {
      this.autocompleteResult = result;
      if (this.autocompleteResult && this.autocompleteResult.id != null) {
        Notify.create({
          message:
            this.autocompleteResult.descricao + " - conta foi selecionado.",
          color: "green",
          position: "top-right"
        });
      }
      this.criarDescricaoConta();
    },
    displayMontar(value) {
      if (!value.descricao) return;
      let format = moment.utc(value.vencimento).format("DD/MM/YYYY") + " - " + value.descricao;
      return !value ? value : format;
    },
    loadSaldoCliente() {
      if (!this.extratoClienteInstalado || this.loadingSaldoCliente) return;

      this.loadingSaldoCliente = true;

      let cadastroId = this.cadastro_id;

      if (this.movcontaReturn) {
        cadastroId = this.movcontaReturn.cadastro.id;
      }

      axios
        .get(`/financeiro/extrato-cliente/${cadastroId}?apenas_saldo=1`)
        .then(res => {
          this.loadingSaldoCliente = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.saldoAtualCliente = res.data.saldo_atual;
        })
        .catch(() => {
          this.loadingSaldoCliente = false;
          this.$q.notify({
            message: 'Houve um erro ao consultar o extrato do cliente',
            color: 'red',
            position: 'top-right',
          });
        });
    },
  },
  computed: {
    ...mapGetters([
      "movcontaReturn",
      "movconta_id_caixa",
      "movcontasOptions",
      "errorsCaixas",
      "contas",
      "venda",
      "dadosCaixaContaPagarReceber",
      "extratoClienteInstalado",
    ]),
    OpenDialogRecebimentoFiadoCaixas: {
      get() {
        return this.$store.getters.OpenDialogRecebimentoFiadoCaixas;
      },
      set() {}
    },
    conta_id_caixa:{
      get(){
        return this.$store.getters.conta_id_caixa;
      },
      set(value){
        this.$store.commit('conta_id_caixa', value);
      }
    },
    exibirCriarNovaMovconta() {
      return this.venda.valor !== 0 && this.movconta_valor > parseFloat(this.venda.valor);
    },
    exibirAdicionarSaldoCliente() {
      return this.extratoClienteInstalado;
    },
    enableAdicionarSaldoCliente() {
      return this.movconta_valor > 0 || this.movcontaReturn && this.movcontaReturn.movconta.valor > 0;
    },
    novoSaldoCliente() {
      const valor = this.movcontaReturn ? this.movcontaReturn.movconta.valor : this.venda.valor;
      return this.saldoAtualCliente + parseFloat(valor);
    },
    classSaldoAtualCliente() {
      if (this.novoSaldoCliente > 0) return 'text-green';
      if (this.novoSaldoCliente < 0) return 'text-red';

      return '';
    },
    classNovoSaldoCliente() {
      if (this.novoSaldoCliente > 0) return 'text-green';
      if (this.novoSaldoCliente < 0) return 'text-red';

      return '';
    },
    exibirSaldoCliente() {
      return (this.cadastro_id || (this.movcontaReturn && this.movcontaReturn.cadastro.id)) && !this.loadingSaldoCliente;
    },
    criarContaValorRestanteAutomaticamente() {
      return this.movcontasOptions.movcontas_criar_movconta_valor_restante
        && parseInt(this.movcontasOptions.movcontas_criar_movconta_valor_restante);
    },
  },
  watch: {
    "venda.valor"() {
      if (this.exibirCriarNovaMovconta) {
        this.dadosCaixaContaPagarReceber.valorRestante = this.movconta_valor - parseFloat(this.venda.valor);
      }
    },
    OpenDialogRecebimentoFiadoCaixas() {
      if (!this.OpenDialogRecebimentoFiadoCaixas) return;

      this.dadosCaixaContaPagarReceber.gerarNovaMovconta = false;
      this.dadosCaixaContaPagarReceber.novoVencimento = moment().format("DD/MM/YYYY");
      this.dadosCaixaContaPagarReceber.valorRestante = 0;
      this.dadosCaixaContaPagarReceber.adicionarSaldoCliente = false;

      if (this.criarContaValorRestanteAutomaticamente) {
        this.dadosCaixaContaPagarReceber.gerarNovaMovconta = true;
      }
    },
    movcontaReturn() {
      if (!this.movcontaReturn || !this.movcontaReturn.cadastro.id) {
        this.saldoAtualCliente = 0.00;
        return;
      }

      this.loadSaldoCliente();
    },
    cadastro_id() {
      if (!this.cadastro_id) {
        this.saldoAtualCliente = 0.00;
        return;
      }

      this.loadSaldoCliente();
    },
  },
  created() {
    bus.$on("clearReceberContaFiado", () => {
      this.clear(false);
    });
  },
  mounted() {
    document.addEventListener("keyup", event => {
      if (event.keyCode === 27) {
        this.clear();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener("keyup");
    next();
  }
};
</script>

<style lang="stylus" scoped></style>
