<template>
  <div id="integracaoMovideskParametros">
    <card-section-sem-permissao v-if="semPermissao" />

    <div v-else>
      <div class="text-center" v-if="loadingConfiguracoes">
        <q-spinner color="blue-7" size="3em" />
      </div>

      <div class="row q-col-gutter-lg" v-else>

        <!-- url disponibilizada com o token -->
        <div class="col-12 col-md-6">
          <q-input
            v-model="movidesk_url"
            label="Sua url"
            readonly
          >
          <template v-slot:append>
              <q-btn
                flat
                round
                dense
                @click="copyText(movidesk_url, 'url', 'a')"
                icon="fa fa-clipboard"
                size="12px"
              />
              <q-btn
                flat
                round
                dense
                class="q-ml-sm"
                @click="renovarToken()"
                icon="fa fa-refresh"
                size="12px"
              />
            </template>
          </q-input>
        </div>

        <!-- token do movidesk -->
        <div class="col-12 col-md-6">
          <q-input
            v-model="config.movidesk_token_externo"
            label="Token Movidesk"
          >
            <template v-slot:append>
              <q-btn
                flat
                round
                dense
                @click="copyText(config.movidesk_token_externo, 'token', 'o')"
                icon="fa fa-clipboard"
                size="12px"
              />
            </template>
          </q-input>
        </div>

        <div class="row q-col-gutter-lg">
        <div class="col">

          <q-list padding>

            <q-item tag="label" v-ripple>
              <q-item-section side>
                <q-toggle color="green"
                v-model="config.movidesk_enviar_via_dourasoft" true-value="1" false-value="0"
                @input="handleToggle('email')"
                ></q-toggle>
              </q-item-section>
              <q-item-section>
                <q-item-label>Enviar E-mail de Cobrança Via DouraSoft</q-item-label>
                <q-item-label caption>Ticket no Movidesk com o "Serviço pré-definido",
                  enviaremos as cobranças diretamente da DouraSoft via e-mail ao seu cliente
                  (Somente pré-cadastrados em nossa plataforma).
                </q-item-label>
              </q-item-section>

            </q-item>

            <q-item tag="label" v-ripple>
              <q-item-section side>
                <q-toggle color="green"
                v-model="config.movidesk_enviar_via_movidesk" true-value="1" false-value="0"
                @input="handleToggle('movidesk')"
                ></q-toggle>
              </q-item-section>
              <q-item-section>
                <q-item-label>Enviar Cobrança Via Movidesk</q-item-label>
                <q-item-label caption>Ticket no Movidesk com o "Serviço pré-definido",
                  enviaremos as cobranças diretamente para dentro do Ticket no Movidesk,
                  apenas se o e-mail do solicitante estiver cadastrado em nossa plataforma da DouraSoft.
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item tag="label" v-ripple>
              <q-item-section side>
                <q-toggle color="green"
                v-model="config.movidesk_permitir_diferente_cadastro" true-value="1" false-value="0"
                ></q-toggle>
              </q-item-section>
              <q-item-section>
                <q-item-label>Permitir Cobrança Via Movidesk para e-mails não Cadastrados</q-item-label>
                <q-item-label caption>Ticket no Movidesk com o "Serviço pré-definido",
                  enviaremos as cobranças diretamente para dentro do Ticket no Movidesk,
                  independente se o e-mail do solicitante estiver cadastrado ou não em nossa plataforma da DouraSoft.
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>
    <div class="flex justify-end q-mt-md q-mr-auto">
      <q-btn color="green" label="Salvar" :loading="loadingConfiguracoes" @click="salvarConfiguracoes(false)" />
    </div>

    <q-dialog v-model="dialogAjuda">
      <q-card style="width: 90%; max-width: 400px;">

        <q-card-section class="row items-center">
          <div class="text-h6">Integração com movidesk</div>
          <q-space />
          <q-btn
            flat
            round
            dense
            v-close-popup
            icon="close"
            @click="closeDialogAjuda()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          Texto ou vídeo de ajuda
        </q-card-section>

      </q-card>
    </q-dialog>
  </div>
  </div>
</template>

<script>
import axios from '@/axios-auth';
import { copyToClipboard } from 'quasar';

export default {
  name: 'integracao-movidesk-parametros',
  data() {
    return {
      dialogAjuda: false,
      loadingConfiguracoes: false,
      planosdecontas: [],
      planosdecontasOptions: [],
      semPermissao: false,
      config: {
        movidesk_token_interno: '',
        movidesk_token_externo: '',
        movidesk_url: '',
        movidesk_user_id: '',
        movidesk_user_nome: '',
        movidesk_enviar_via_dourasoft: '',
        movidesk_enviar_via_movidesk: '',
        movidesk_permitir_diferente_cadastro: '',
      },
    };
  },
  methods: {
    openDialogAjuda() {
      this.dialogAjuda = true;
    },
    closeDialogAjuda() {
      this.dialogAjuda = false;
    },
    loadConfiguracoes() {
      this.loadingConfiguracoes = true;

      axios
        .get('/integracoes/movidesk')
        .then(res => {
          this.loadingConfiguracoes = false;

          if (!res.data.success) {
            throw new Error();
          }

          this.semPermissao = false;
          this.config = res.data.config;
          this.config.movidesk_token_interno = res.data.config.movidesk_token_interno;
        })
        .catch(() => {
          this.loadingConfiguracoes = false;
          this.semPermissao = true;
        });
    },
    renovarToken() {
      this.$q.dialog({
        title: 'Confirmar',
        message: `
          Deseja realmente renovar o token?<br/>
          Todos os dispositivos serão desconectados!
        `,
        cancel: true,
        persistent: true,
        html: true,
      }).onOk(() => {
        this.salvarConfiguracoes(true);
      });
    },
    salvarConfiguracoes(token) {
      let url = `/integracoes/movidesk?token=${token ? 1 : 0}`;
      const data = JSON.parse(JSON.stringify(this.config));

      axios
        .post(url, data)
        .then(res => {
          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right'
          });

          if (!res.data.success) {
            return;
          }

          this.config.movidesk_token_interno = res.data.config.movidesk_token_interno;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao renovar o token, tente novamente!',
            color: 'red',
            position: 'top-right',
          });
      });
    },
    copyText(text, nomeItemCopia, artigo = 'o') {
      nomeItemCopia = nomeItemCopia.charAt(0).toUpperCase() + nomeItemCopia.slice(1);

      copyToClipboard(text)
        .then(() => {
          this.$q.notify({
            message: `${nomeItemCopia} copiad${artigo}`,
            color: 'green',
            position: 'top-right',
          });
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao tentar copiar seu texto',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    handleToggle(handle) {
      if (handle == 'movidesk' && this.config.movidesk_enviar_via_movidesk === '0') {
        this.config.movidesk_enviar_via_dourasoft = '1';
      } else if (handle == 'email' && this.config.movidesk_enviar_via_dourasoft === '0') {
        this.config.movidesk_enviar_via_movidesk = '1';
      }
    }
  },
  computed: {
    movidesk_url() {
      return this.config.movidesk_url + '?token=' + this.config.movidesk_token_interno;
    },
  },
  mounted() {
    this.loadConfiguracoes();
  },
};
</script>
