<template>
  <div id="dados-da-empresa">
    <div class="q-pa-md">
      <div class="text-right q-mb-md ">
        <q-btn
          flat
          round
          class="q-mr-sm"
          icon="settings"
          color="primary"
          @click="openModalOptions()"
        />
        <q-btn
          outline
          color="negative"
          label="Limpar registros"
          @click="openModalComecarDoZero()"
          :disable="disableBotaoComecarDoZero"
        />
      </div>

      <div class="q-col-gutter-md row">
        <div class="col-12 col-md-4" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.cnpj"
            label="CNPJ"
            readonly
            bottom-slots
            :error="errors.cnpj ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.cnpj" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-4" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.tfls"
            mask="###"
            label="TFLs"
            bottom-slots
            :error="errors.tfls ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.tfls" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-4" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.codigo"
            label="Identificador"
            bottom-slots
            :error="errors.codigo ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.codigo" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-6" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.razao"
            label="Razão"
            readonly
            bottom-slots
            :error="errors.razao ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.razao" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-6" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.fantasia"
            label="Fantasia"
            bottom-slots
            :error="errors.fantasia ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.fantasia" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col col-md-2" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.cep"
            label="CEP"
            bottom-slots
            :error="errors.cep ? true : false"
            mask="#####-###"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.cep" :key="error + i">{{error}}</span>
            </template>
            <template v-if="perfil.empresa.cep" v-slot:append>
              <q-btn
                :loading="buscandoCEP"
                name="search"
                clickable
                @click.stop="consultaCEP(perfil.empresa.cep)"
                :disable="buscandoCEP"
                icon="search"
                dense
                class="cursor-pointer bg-positive"
                color="text-white"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-4" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.cidade"
            label="Cidade"
            bottom-slots
            :error="errors.cidade ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.cidade" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col col-md-2" v-if="perfil.empresa">
          <q-select
            v-model="perfil.empresa.estado"
            :options="estadosShort"
            label="Estado"
            bottom-slots
            :error="errors.estado ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.estado" :key="error + i">{{error}}</span>
            </template>
          </q-select>
        </div>
        <div class="col-12 col-md-4" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.bairro"
            label="Bairro"
            bottom-slots
            :error="errors.bairro ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.bairro" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-6" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.endereco"
            label="Endereço"
            bottom-slots
            :error="errors.endereco ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.endereco" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col col-md-2" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.numero"
            label="Número"
            bottom-slots
            :error="errors.numero ? true : false"
            id="numero"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.numero" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-4" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.complemento"
            label="Complemento"
            bottom-slots
            :error="errors.complemento ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.telefone" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-6" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.atividade"
            label="Atividade"
            bottom-slots
            :error="errors.atividade ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.telefone" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-6" v-if="perfil.empresa">
          <q-input
            v-model="perfil.empresa.telefone"
            label="Telefone"
            bottom-slots
            :error="errors.telefone ? true : false"
          >
            <template v-slot:error>
              <span v-for="(error, i) in errors.telefone" :key="error + i">{{error}}</span>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-md-3" v-if="perfil.empresa">
          <ImageInput
            title="Logo da Empresa"
            :hasImage="hasImage"
            :imageUrl="imageUrl"
            :loading="loadingFile"
            @fileUploaded="handleFileUpload"
            @fileRemoved="removerLogo"
          />

          <p class="text-caption">A imagem deve ser no formato .png, 80px de largura e 45px de altura.</p>
        </div>
        <div class="col-12 q-mt-lg" v-if="perfil.empresa">
          <q-btn
            no-wrap
            v-ripple
            label="Salvar"
            no-caps
            class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
            style="margin: 0 30px 20px 0"
            clickable
            type="submit"
            :loading="loadEdit"
            @click="salvar()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </div>
      </div>
    </div>

    <q-dialog v-model="modalComecarDoZero" persistent>
      <q-card style="max-width: 400px;width:100%;">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">Limpar registros</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModalComecarDoZero()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="q-mb-md">
            <div class="text-h6">Quais registros devemos apagar?</div>
          </div>

          <div>
            <div>
              <q-checkbox
                color="green"
                label="Cadastros Clientes, Funcionários, Fornecedores, Vínculos"
                v-model="comecarDoZeroOptions.cadastros"
              />
            </div>

            <div>
              <q-checkbox
                color="green"
                label="Produtos, Serviços, Loterias"
                v-model="comecarDoZeroOptions.produtos"
              />
            </div>

            <div>
              <q-checkbox
                color="green"
                label="Contas Pagar"
                :disable="opcaoDespesasDesabilitado"
                v-model="comecarDoZeroOptions.despesas"
              />
            </div>

            <div>
              <q-checkbox
                color="green"
                label="Contas Receber"
                :disable="opcaoReceitasDesabilitado"
                v-model="comecarDoZeroOptions.receitas"
              />
            </div>

            <div>
              <q-checkbox
                color="green"
                label="Lançamento de Caixas"
                :disable="opcaoCaixasDesabilitado"
                v-model="comecarDoZeroOptions.caixas"
              />
            </div>

            <div>
              <q-checkbox
                color="green"
                label="Estoque"
                :disable="opcaoEstoqueDesabilitado"
                v-model="comecarDoZeroOptions.estoque"
              />
            </div>
          </div>

          <div class="q-mt-lg">
            <div>Digite <strong>"compreendo os riscos"</strong> para continuar</div>

            <q-input v-model="comecarDoZeroOptions.textoConfirmacao" />
          </div>

        </q-card-section>

        <q-card-actions
          align="right"
          :class="`text-primary q-pb-md q-pr-md ${!comecarDoZeroValido ? 'disable-item' : ''}`"
        >
          <q-btn
            color="negative"
            label="Sim, Quero deletar permanentemente os dados selecionados"
            :loading="loadingComecarDoZero"
            :disable="!comecarDoZeroValido"
            @click="confirmarComecarDoZero()"
          />
        </q-card-actions>

      </q-card>
    </q-dialog>

    <q-dialog v-model="modalOptions" persistent>
      <q-card style="max-width: 400px; width: 90%;">
        <q-card-section class="row items-center">
          <div class="text-h6">Opções</div>
          <q-space />
          <q-btn
            flat
            round
            dense
            icon="close"
            v-close-popup
            @click="closeModalOptions()"
          />
        </q-card-section>
        <q-separator/>
        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-lg">
            <div class="col-12">
              <q-checkbox
                label="Validar CPF e CNPJ repetidos"
                v-model="options.empresa_validar_cpf_cnpj_repetidos"
                true-value="1"
                false-value="0"
                color="green"
              />
            </div>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="col-12">
            <q-input
              v-model="options.empresa_responsavel_id"
              label="ID do Cadastro Responsável da Empresa"
              stack-label
            />
          </div>
        </q-card-section>
        <q-card-section>
          <div class="col-12">
            <q-input
              v-model="options.empresa_destinatario_recursos_id"
              label="ID do Cadastro do Destinatário dos Recursos."
              stack-label
            />
          </div>
        </q-card-section>
        <q-card-section>
          <div class="col-12">
            <q-input
              v-model="options.empresa_responsavel_transporte_id"
              label="ID do Cadastro da Empresa de Transporte de Valores"
              stack-label
            />
          </div>
        </q-card-section>
        <q-card-section align="right">
          <q-btn
            label="Salvar"
            clickable
            v-ripple
            @click="salvarOpcoes()"
            :loading="salvandoOpcoes"
            color="green"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import globalAxios from "axios";
import { mapGetters } from "vuex";
import { Loading, Dialog, Notify } from "quasar";
import ImageInput from '../Utils/image-input.vue';

export default {
  name: "dados-da-empresa",
  components: { ImageInput },
  data() {
    return {
      perfil: {},
      errors: [],
      loadEdit: false,
      buscandoCEP: false,
      buscandoCNPJ: false,
      maxLogoSize: 500 * 1024,
      arquivos: [],
      imagemUploadErro: '',
      loadingRemoverLogo: false,
      modalComecarDoZero: false,
      loadingComecarDoZero: false,
      disableBotaoComecarDoZero: false,
      comecarDoZeroOptions: {
        textoConfirmacao: '',
        cadastros: false,
        produtos: false,
        receitas: false,
        despesas: false,
        caixas: false,
        estoque: false,
      },
      modalOptions: false,
      options: {
        empresa_validar_cpf_cnpj_repetidos: "0",
        empresa_responsavel_id: null,
        empresa_responsavel_transporte_id: null,
        empresa_destinatario_recursos_id: null,
      },
      optionsOriginais: {
        empresa_validar_cpf_cnpj_repetidos: "0",
      },
      salvandoOpcoes: false,
      hasImage: false,
      imageUrl: '',
      loadingFile: false
    };
  },
  methods: {
    consultaCEP() {
      if (!this.perfil.empresa.cep) {
        Notify.create({
          message: "CEP Inválido, digite novamente.",
          color: "orange",
          position: "top-right"
        });
      }

      let cep = this.perfil.empresa.cep;
      cep = cep.replace(/\D/g, "");
      // Expressão regular para validar o CEP.
      let validacep = /^[0-9]{8}$/;
      // Valida o formato do CEP.
      if (!validacep.test(cep) || cep === "") {
        if (cep !== "") {
          Notify.create({
            message: "CEP Inválido, digite novamente.",
            color: "orange",
            position: "top-right"
          });
        }
      }
      this.buscandoCEP = true;

      globalAxios
        .get("https://viacep.com.br/ws/" + cep + "/json/")
        .then(res => {
          let data = res.data;
          if (!("erro" in data)) {
            Notify.create({
              message: "CEP encontrado!",
              color: "green",
              position: "top-right"
            });
            this.perfil.empresa.endereco = data.logradouro;
            this.perfil.empresa.cidade = data.localidade;
            this.perfil.empresa.bairro = data.bairro;
            this.perfil.empresa.estado = data.uf;
            this.perfil.empresa.numero = "";
            document.getElementById("numero").focus();
          } else {
            Notify.create({
              message: "CEP não encontrado.",
              color: "orange",
              position: "top-right"
            });
          }
          this.buscandoCEP = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.buscandoCEP = false;
        });
    },
    listar(response) {
      axios.get("/empresas/edit/" + response.id).then(res => {
        if (res.data.success) {
          this.perfil = res.data;

          if (this.perfil.empresa.logo_url) {
            this.hasImage = true;
            this.imageUrl = this.perfil.empresa.logo_url;
          }
        }
      });
    },
    salvar() {
      this.loadEdit = true;
      Dialog.create({
        title: "Deseja Alterar os dados da Empresa?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post("/empresas/edit", this.perfil.empresa)
            .then(res => {
              if (res.data.success) {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
              } else {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
                this.errors = res.data.errors;
              }
              this.loadEdit = false;
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
              Loading.hide();
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    async buscaEmpresa() {
      let response = await this.$store.getters.dataEmpresa;

      this.listar(response);
    },
    openModalComecarDoZero() {
      this.modalComecarDoZero = true;
    },
    closeModalComecarDoZero() {
      this.modalComecarDoZero = false;
    },
    confirmarComecarDoZero() {
      const data = JSON.parse(JSON.stringify(this.comecarDoZeroOptions));

      this.loadingComecarDoZero = true;

      axios
        .post('/empresas/comecar-novamente', data)
        .then(res => {
          this.loadingComecarDoZero = false;

          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          window.location.href = window.location.origin;
          this.disableBotaoComecarDoZero = true;
          this.closeModalComecarDoZero();
        })
        .catch(() => {
          this.loadingComecarDoZero = false;
          Notify.create({
            message: 'Houve um erro ao começar do zero',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    openModalOptions() {
      this.modalOptions = true;

      axios
        .get("/empresas/options")
        .then(res => {
          if (!res.data.message) {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right",
            });
            return;
          }

          this.optionsOriginais = res.data.options;
          this.options = res.data.options;
        })
        .catch(() => {
          Notify.create({
            message: "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right",
          });
        });
    },
    closeModalOptions() {
      this.modalOptions = false;
    },
    salvarOpcoes() {
      if (this.salvandoOpcoes) return;

      this.salvandoOpcoes = true;

      axios
        .post("/empresas/options", this.options)
        .then(res => {
          this.salvandoOpcoes = false;

          Notify.create({
            message: res.data.message,
            color: res.data.success ? "green" : "orange",
            position: "top-right"
          });

          if (!res.data.success) {
            return;
          }

          this.options = res.data.options;

          this.closeModalOptions();
        })
        .catch(() => {
          this.salvandoOpcoes = false;
          Notify.create({
            message: "Houve um erro ao salvar as opções, tente novamente!",
            color: "red",
            position: "top-right"
          });
        });
    },
    handleFileUpload(file) {
      if (!file) return;
      this.loadingFile = true;

      const fd = new FormData();
      fd.append("logo", file);

      axios
        .post("/empresas/edit?imagem=1", fd)
        .then(res => {
          this.loadingFile = false;
          if (!res.data.success) {
            if (res.data.error) {
              Dialog.create({
                title: res.data.error,
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            } else {
              Notify.create({
                message: res.data.message,
                color: "red",
                position: "top-right"
              });
            }
            return;
          }

          this.hasImage = true;
          this.imageUrl = res.data.logo;
          this.dataEmpresa.logo_url = res.data.logo;
          this.perfil.empresa.logo_url = res.data.logo;
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });
        });
    },
    removerLogo() {
      Dialog.create({
        title: "Tem certeza que deseja remover a logo da empresa?",
      }).onOk(() => {
        this.loadingFile = true;

        axios
          .post("/empresas/edit?imagem=2")
          .then(res => {
            if (res.data.success) {
              Notify.create({
                message: "Logo da empresa removido com sucesso!",
                color: "green",
                position: "top-right"
              });
              this.hasImage = false;
              this.imageUrl = "";
              this.perfil.empresa.logo_url = null;
              this.dataEmpresa.logo_url = null;
            } else {
              Notify.create({
                message: "Houve um erro ao remover logo da empresa",
                color: "red",
                position: "top-right"
              });
              this.errors = res.data.errors;
            }
            this.loadingFile = false;
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi Possível realizar a ação, ou você não possui permissão isto!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
            this.loadingRemoverLogo = false;
          });
      });
    },
  },
  computed: {
    ...mapGetters([
      'token',
      'dataEmpresa'
    ]),
    comecarDoZeroValido() {
      return this.comecarDoZeroOptions.textoConfirmacao === 'compreendo os riscos' && (
        this.comecarDoZeroOptions.cadastros || this.comecarDoZeroOptions.produtos ||
        this.comecarDoZeroOptions.movcontas || this.comecarDoZeroOptions.caixas ||
        this.comecarDoZeroOptions.estoque
      );
    },
    opcaoCaixasDesabilitado() {
      return this.comecarDoZeroOptions.cadastros || this.comecarDoZeroOptions.produtos;
    },
    opcaoEstoqueDesabilitado() {
      return this.comecarDoZeroOptions.cadastros || this.comecarDoZeroOptions.produtos;
    },
    opcaoReceitasDesabilitado() {
      return this.comecarDoZeroOptions.cadastros;
    },
    opcaoDespesasDesabilitado() {
      return this.comecarDoZeroOptions.cadastros;
    },
  },
  watch: {
    'comecarDoZeroOptions.cadastros'(value) {
      if (value) {
        this.comecarDoZeroOptions.caixas = true;
        this.comecarDoZeroOptions.estoque = true;
        this.comecarDoZeroOptions.despesas = true;
        this.comecarDoZeroOptions.receitas = true;
      }
    },
    'comecarDoZeroOptions.produtos'(value) {
      if (value) {
        this.comecarDoZeroOptions.caixas = true;
        this.comecarDoZeroOptions.estoque = true;
      }
    },
  },
  mounted() {
    this.buscaEmpresa();
  },
};
</script>

<style lang="stylus" scoped>
  .disable-item {
    pointer-events: none;
    opacity: 0.6;
  }
</style>
