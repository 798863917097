import axios from "axios";
import { Notify } from "quasar";

const state = {
  listarActivatePass: {
    preEtapa: false,
    user: {},
    message: "",
    errorEtapa: false
  },
  salvarActivatePass: {
    loadAlterar: false,
    segundaEtapa: false
  }
};

const mutations = {
  listarActivatePass: (state, userData) => {
    state.listarActivatePass.preEtapa = userData.preEtapa;
    state.listarActivatePass.user = userData.user;
    state.listarActivatePass.message = userData.message;
    state.listarActivatePass.errorEtapa = userData.errorEtapa;
  },
  salvarActivatePass: (state, userData) => {
    state.salvarActivatePass.loadAlterar = userData.loadAlterar;
    state.salvarActivatePass.segundaEtapa = userData.segundaEtapa;
  }
};

const actions = {
  listarActivatePass: ({ commit }, userData) => {
    commit("listarActivatePass", {
      preEtapa: true,
      user: {},
      message: "",
      errorEtapa: false
    });
    axios
      .get("/users/ativar/" + userData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });
          commit("listarActivatePass", {
            preEtapa: state.listarActivatePass.preEtapa,
            user: res.data.user,
            message: state.listarActivatePass.message,
            errorEtapa: state.listarActivatePass.errorEtapa
          });
        } else {
          commit("listarActivatePass", {
            preEtapa: state.listarActivatePass.preEtapa,
            user: {},
            message: res.data.message,
            errorEtapa: true
          });
        }
        commit("listarActivatePass", {
          preEtapa: false,
          user: state.listarActivatePass.user,
          message: state.listarActivatePass.message,
          errorEtapa: state.listarActivatePass.errorEtapa
        });
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("listarActivatePass", {
          preEtapa: false,
          user: state.listarActivatePass.user,
          message: state.listarActivatePass.message,
          errorEtapa: state.listarActivatePass.errorEtapa
        });
      });
  },
  salvarActivatePass({ commit }, userData) {
    commit("salvarActivatePass", {
      loadAlterar: true,
      segundaEtapa: false
    });
    axios
      .post("/users/ativar/" + userData.id, userData.senha, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          commit("salvarActivatePass", {
            loadAlterar: state.salvarActivatePass.loadAlterar,
            segundaEtapa: true
          });
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
        commit("salvarActivatePass", {
          loadAlterar: false,
          segundaEtapa: state.salvarActivatePass.segundaEtapa
        });
      })
      .catch(() => {
        Notify.create({
          message: "Usuário não encontrado, ou link expirado!",
          color: "red",
          position: "top-right"
        });
      });
  }
};

const getters = {
  listarActivatePass: state => {
    return state.listarActivatePass;
  },
  salvarActivatePass: state => {
    return state.salvarActivatePass;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
