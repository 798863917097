<template>
  <q-dialog v-model="openDialog" persistent>
    <q-card class="scroll" style="width: 90%; max-width: 400px">
      <q-card-section class="row items-center">
        <div class="text-h6">Período de teste</div>
        <q-space />
        <q-btn flat round dense clickable v-close-popup icon="close" @click="fecharModal()" />
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pa-md">
        <div class="row q-col-gutter-x-md">
          <div class="col-12">
            <q-select
              emit-value
              map-options
              bottom-slots
              label="Período de teste"
              :options="optionsTrial"
              v-model="recorrencia.is_trial"
            >
              <template v-slot:error>
                <span v-for="(erro, i) in erros.is_trial" :key="erro + i">{{ erro }}</span>
              </template>
            </q-select>
          </div>

          <div class="col-6">
            <q-input
              clearable
              bottom-slots
              label="Início"
              :error="Boolean(erros.dtini_trial)"
              @click="$refs.qDateInicioTrial.show()"
              v-model="recorrencia.dtini_trial"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateInicioTrial"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      minimal
                      today-btn
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      @input="() => pulaCampo('qDateFimTrial')"
                      v-model="recorrencia.dtini_trial"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in erros.dtini_trial" :key="erro + i">{{ erro }}</span>
              </template>
            </q-input>
          </div>

          <div class="col-6">
            <q-input
              clearable
              bottom-slots
              label="Fim"
              :error="Boolean(erros.dtfim_trial)"
              @click="$refs.qDateFimTrial.show()"
              v-model="recorrencia.dtfim_trial"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateFimTrial"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      minimal
                      today-btn
                      @input="() => $refs.qDateFimTrial.hide()"
                      :locale="langDate"
                      mask="DD/MM/YYYY"
                      v-model="recorrencia.dtfim_trial"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in erros.dtfim_trial" :key="erro + i">{{ erro }}</span>
              </template>
            </q-input>
          </div>

          <div class="col-12">
            <q-input
              clearable
              bottom-slots
              label="Data de conversão/perda"
              :error="Boolean(erros.data_trial)"
              @click="$refs.qDataTrial.show()"
              v-model="recorrencia.data_trial"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDataTrial" transition-show="scale" transition-hide="scale">
                    <q-date
                      minimal
                      today-btn
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      @input="() => $refs.qDataTrial.hide()"
                      v-model="recorrencia.data_trial"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in erros.data_trial" :key="erro + i">{{ erro }}</span>
              </template>
            </q-input>
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          no-caps
          v-ripple
          clickable
          label="Salvar"
          color="positive"
          text-color="white"
          style="margin-right: 22px; margin-bottom: 10px"
          :loading="salvando"
          @click="salvar()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import axios from '@/axios-auth';
import { bus } from '@/main';

export default {
  name: 'modal-trial-recorrencia',
  data() {
    return {
      // Modal
      openDialog: false,
      // Formulário
      recorrencia: {},
      erros: {},
      // Salvar
      salvando: false,
      // Opções
      optionsTrial: [
        {
          label: 'Sem período de teste',
          value: 0,
        },
        {
          label: 'Em período de teste',
          value: 1,
        },
        {
          label: 'Convertido',
          value: 2,
        },
        {
          label: 'Perdido',
          value: 3,
        },
      ],
    };
  },
  methods: {
    // Modal
    abrirModal({ recorrencia }) {
      this.recorrencia = JSON.parse(JSON.stringify(recorrencia));

      this.resetarErros();

      this.openDialog = true;
    },
    fecharModal() {
      this.openDialog = false;
    },
    // Formulário
    resetarErros() {
      this.erros = {};
    },
    // Salvar
    salvar() {
      this.salvando = true;

      let recorrencia = JSON.parse(JSON.stringify(this.recorrencia));
      const { id, cadastro_id } = recorrencia;

      const url = id ? `/recorrencias/edit/${id}` : `/recorrencias/add/${cadastro_id}`;

      const body = {
        convenio_id: !recorrencia.convenio_id ? null : recorrencia.convenio_id.id,
        dependente_id: !recorrencia.dependente_id ? null : recorrencia.dependente_id.id,
        planodeconta_id: !recorrencia.planodeconta ? null : recorrencia.planodeconta.id,
        descricao: recorrencia.descricao,
        numeroconvenio: recorrencia.numeroconvenio,
        periodo: recorrencia.periodo,
        valor: recorrencia.valor,
        valor_ativacao: recorrencia.valor_ativacao,
        ativo: recorrencia.ativo,
        dtini: recorrencia.dtini,
        dtfim: recorrencia.dtfim,
        quantidade: recorrencia.quantidade,
        ultimo_faturamento: recorrencia.ultimo_faturamento,
        data_primeiro_pagamento: recorrencia.data_primeiro_pagamento,
        is_trial: recorrencia.is_trial,
        dtini_trial: recorrencia.dtini_trial,
        dtfim_trial: recorrencia.dtfim_trial,
        data_trial: recorrencia.data_trial,
        cadastros_recorrencias_descontos: recorrencia.cadastros_recorrencias_descontos,
        cadastros_recorrencias_items: recorrencia.cadastros_recorrencias_items,
        cadastros_recorrencias_parcelas: recorrencia.cadastros_recorrencias_parcelas,
        cadastros_recorrencias_comissoes: recorrencia.cadastros_recorrencias_comissoes,
      };

      axios
        .post(url, body)
        .then(res => {
          this.salvando = false;

          if (!res.data.success) {
            if (res.data.errors.dtini) {
              this.erros.dtini = res.data.errors;
              return;
            }

            this.$q.notify({
              message: 'Não foi possível salvar a recorrência!',
              color: 'orange',
              position: 'top-right',
            });
            this.erros = res.data.errors;
            return;
          }

          this.$q.notify({
            message: 'Recorrência salva!',
            color: 'green',
            position: 'top-right',
          });

          this.$emit('atualizar');
          this.fecharModal();
        })
        .catch(() => {
          this.salvando = false;
          this.$q.notify({
            message:
              'Não foi possível salvar a recorrência, ou você não possui permissão para isto!',
            color: 'orange',
            position: 'top-right',
          });
        });
    },
    // Utilidade
    pulaCampo(campo, segundos = null) {
      const focar = () => {
        if (!this.$refs[campo]) {
          return;
        }

        if (this.$refs[campo].show) {
          return this.$refs[campo].show();
        }

        if (this.$refs[campo].$el.classList.contains('v-money')) {
          return this.$refs[campo].$el.focus();
        }

        return this.$refs[campo].focus();
      };

      if (segundos) {
        return setTimeout(() => focar(), segundos);
      }

      focar();
    },
  },
  mounted() {
    bus.$on('abrirModalTrialRecorrencia', this.abrirModal);
  },
};
</script>
