<template>
  <div id="editModalCobranca">
    <q-dialog v-model="editModalCobranca" persistent>
      <q-card style="max-width: 600px; width: 100%;">
        <q-card-section class="flex justify-between" >
          <div class="text-h6">Editar Forma de Cobrança</div>

          <q-btn flat dense round v-close-popup icon="close" @click="closeModal" />
        </q-card-section>

        <q-card-section>
          <div class="row q-col-gutter-md">
            <div class="col-md-6 col-12" v-for="opt in gateway.optionsParams" :key="opt.value">
              <q-input
                v-if="opt.type === 'text'"
                :label="opt.label"
                v-model="opt.valor"
              />

              <q-select
                v-else-if="opt.type === 'select'"
                v-model="opt.valor"
                :label="opt.label"
                :options="opt.options"
                emit-value
                map-options
                bottom-slots
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem Resultados</q-item-section>
                  </q-item>
                </template>
              </q-select>

              <q-field
                v-else-if="opt.type === 'number'"
                v-model="opt.valor"
                :label="opt.label"
              >
                <template v-slot:control="{ floatingLabel, value, emitValue }">
                  <money
                    @input="emitValue"
                    :value="value"
                    v-bind="percentFormat"
                    v-show="floatingLabel"
                  />
                </template>
              </q-field>
            </div>
          </div>
        </q-card-section>

        <q-card-section class="flex justify-between">
          <div>
            <q-checkbox
              v-model="gateway.padrao"
              label="Padrão"
              color="green"
            />

            <q-checkbox
              v-model="gateway.ativo"
              label="Ativo"
              color="green"
            />
          </div>

          <q-btn
            class="bg-positive text-white"
            label="Salvar"
            :loading="saving"
            @click="save"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify } from "quasar";
import { Money } from "v-money";

export default {
  props: ["editModalCobranca", "gateway"],
  emits: ["closeModal", "listarGateways"],
  components: { Money },
  data() {
    return {
      saving: false,
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false
      },
    };
  },
  methods: {
    closeModal() {
      if (this.saving) return;
      this.$emit("closeModal");
    },
    save() {
      if (this.saving) return;
      this.saving = true;

      this.gateway.gateways_opts.forEach(opt => {
        if (this.gateway.optionsParams[opt.nome] !== undefined) {
          opt.valor = this.gateway.optionsParams[opt.nome].valor;
        }
      });

      /*procura as opções que não possuem correspondência, ou seja,
      que existe no optionsParams mas não existe no gateways_opts**/
      let keys = Object.keys(this.gateway.optionsParams);
      let chavesSemCorrespondencia = keys.filter(chave =>
        !this.gateway.gateways_opts.some(obj => obj.nome === chave)
      );

      this.gateway.new_gateways_opts = [];

      /*para cada gateway_opt sem correspondecia, um novo objeto sera
      enviado como new_gateways_opts**/
      for(let chave of chavesSemCorrespondencia) {
        if(this.gateway.optionsParams[chave].valor) {
          this.gateway.new_gateways_opts.push({
            nome: chave,
            valor: this.gateway.optionsParams[chave].valor
          });
        }
      }

      const data = {
        id: this.gateway.id,
        titulo: this.gateway.titulo,
        conta_id: this.gateway.conta_id,
        padrao: this.gateway.padrao,
        ativo: this.gateway.ativo,
        gateways_opts: this.gateway.gateways_opts,
        new_gateways_opts: this.gateway.new_gateways_opts
      };

      axios
        .post("/gateways/edit/" + this.gateway.id, data)
        .then(res => {
          this.$emit("closeModal");

          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "negative",
              position: "top-right"
            });
            return;
          }

          Notify.create({
            message: "Forma de pagamento alterada com sucesso.",
            color: "positive",
            position: "top-right"
          });

          this.$emit("listarGateways");
          this.saving = false;
        })
        .catch(() => {
          Notify.create({
            message: "Não foi possível salvar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    }
  }
};
</script>