<template>
  <span v-if="!(parseFloat(celula[`referencia${index}`]) === 0 && celula.isChild)">
    <span
      v-if="parseFloat(celula[`referencia${index}`]) === 0"
      class="text-center full-width"
      style="display: inline-block;"
    >
      -
    </span>
    <span v-else-if="celula.type === '$'">
      R$ {{ celula.prefixo }}{{ celula[`referencia${index}`] | currency }}{{celula.sufixo}}
    </span>
    <span v-else-if="celula.type === '%'">
      {{ celula.prefixo }}{{ celula[`referencia${index}`] | currency }}{{celula.sufixo}} %
    </span>
    <span v-else-if="celula.type === 'currency'">
      {{ celula.prefixo }}{{ celula[`referencia${index}`] | currency }}{{celula.sufixo}}
    </span>
    <span v-else>
      {{ celula.prefixo }}{{ celula[`referencia${index}`] }}{{celula.sufixo}}
    </span>

    <p class="q-my-none text-grey-8" v-if="celula.subitem">
      <td-metricas-saas :celula="celula.subitem" :index="index" />
    </p>
  </span>
</template>

<script>
export default {
  name: 'td-metricas-saas',
  props: ['celula', 'index'],
};
</script>
