<template>
  <div id="modalNovoExtrato">
    <q-dialog v-model="OpenDialogSaldoInicial" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Saldo Inicial</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">

              <div class="col col-md-12">
                <q-select
                  clearable
                  v-model="conta"
                  :options="contas"
                  label="Conta"
                  bottom-slots
                  :error="errors.conta ? true : false"
                  option-value="id"
                  option-label="titulo"
                  emit-value
                  map-options
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.conta" :key="error + i">{{error}}</span>
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col col-md-12">
                <q-input
                  bottom-slots
                  :error="errors.liquidacao ? true : false"
                  v-model="dataVenc"
                  label="Data"
                  @click="$refs.qDateProxy.show();"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="dataVenc"
                          @input="() => $refs.qDateProxy.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.liquidacao" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col col-md-12">
                <q-field
                  v-model="valor"
                  label="Valor"
                  bottom-slots
                  :error="errors.valor ? true : false"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.valor" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-field>
              </div>

            </div>
          </div>
        </q-card-section>

         <q-card-actions align="right" class="text-primary">
          <q-btn
            no-wrap
            v-ripple
            label="Salvar"
            color="green"
            no-caps
            style="margin: 0 20px 20px 0"
            clickable
            :loading="loading"
            @click="salvarSaldoInicial()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify } from "quasar";
import axios from "@/axios-auth";
const moment = require("moment");
import { Money } from "v-money";

export default {
  name: "modal-saldo-inicial",
  components: { Money },
  data() {
    return {
      conta: null,
      dataVenc: "",
      errors: {},
      valor: 0,
      loading: false,
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogSaldoInicial", false);

      this.conta = null;
      this.dataVenc = moment().format("DD/MM/YYYY");
      this.errors = {};
      this.valor = 0;
    },
    salvarSaldoInicial() {
      if (this.loading) return;

      this.errors = {};

      if (this.dataVenc === "") {
        this.errors['liquidacao'] = "Você deve selecionar uma data!";
        return;
      }
      if (this.conta === null) {
        this.errors['conta'] = "Você deve selecionar uma conta!";
        return;
      }

      this.loading = true;

      let valor = this.valor.toString().replace(",", ".");
      valor = parseFloat(valor);

      let body = {
        vencimento: this.dataVenc,
        valor: valor,
        conta_destino: this.conta,
        tipo: 6,
        descricao: 'Saldo Inicial',
      };
      axios
        .post("/financeiro/add/saldo", body)
        .then(res => {
          if (res.data.status) {
            Notify.create({
              message: "Sucesso ao adicionar saldo inicial",
              color: "green",
              position: "top-right"
            });
            this.$store.dispatch("resetExtrato");
            this.$store.dispatch("listarExtrato", this.filtersExtrato);
            this.closeModal();
          } else {
            this.errors = res.data.errors;
          }

          this.loading = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters([
      "contas",
      "filtersExtrato",
      "OpenDialogSaldoInicial",
    ]),
  },
  mounted(){
    this.dataVenc = moment().format("DD/MM/YYYY");
  }
};
</script>

<style lang="stylus" scoped></style>
