<template>
  <div id="modalCaixaQuitacaoContas">
    <q-dialog v-model="OpenDialogQuitacaoContasCaixas" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Pagar Contas</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="clear()" />
        </q-card-section>
        <q-separator />
        <q-card-section v-if="!movcontaReturn" style="padding-bottom: 0">
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-10">
                <autocomplete
                  title="Busque uma conta"
                  :filter="filterFn"
                  :carregando="carregando"
                  :options="options"
                  :formatDisplay="formatDisplay"
                  :create="createValue"
                  @resultAutocomplete="resultEmitRecebe"
                  @input="criarDescricaoConta()"
                  :display="displayMontar"
                />
              </div>
              <div class="col-2 text-center">
                <q-btn
                  flat
                  rounded
                  dense
                  icon="add"
                  class="text-primary"
                  style="position:relative; top: 25px"
                  clickable
                  @click="newDespesa"
                ></q-btn>
              </div>
            </div>
          </div>
        </q-card-section>

        <acao-caixa>
          <template v-slot:valor-bottom>

            <div class="col-12" v-show="exibirCriarNovaMovconta">
              <q-checkbox
                label="Criar outra despesa com valor restante?"
                v-model="dadosCaixaContaPagarReceber.gerarNovaMovconta"
                color="green"
              />
            </div>

            <div class="col" v-show="exibirCriarNovaMovconta && dadosCaixaContaPagarReceber.gerarNovaMovconta">
            <q-input
              bottom-slots
              :error="errorsCaixas.novo_vencimento ? true : false"
              v-model="dadosCaixaContaPagarReceber.novoVencimento"
              label="Nova data de vencimento"
              @click="$refs.qDateNovoVencimento.show()"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateNovoVencimento"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="dadosCaixaContaPagarReceber.novoVencimento"
                      @input="() => $refs.qDateNovoVencimento.hide()"
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      minimal
                      today-btn
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span
                  v-for="(error, i) in errorsCaixas.novo_vencimento"
                  :key="error + i"
                  >{{ error }}</span
                >
              </template>
            </q-input>
          </div>

          <div class="col" v-show="exibirCriarNovaMovconta && dadosCaixaContaPagarReceber.gerarNovaMovconta">
            <q-field
              disable
              v-model="dadosCaixaContaPagarReceber.valorRestante"
              label="Valor Restante"
              bottom-slots
              :error="errorsCaixas.valor_restante ? true : false"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <money
                  :id="id"
                  class="q-field__native text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  v-show="floatingLabel"
                >
                </money>
              </template>
              <template v-slot:error>
                <span v-for="(error, i) in errorsCaixas.valor_restante" :key="error + i">{{
                  error
                }}</span>
              </template>
            </q-field>
          </div>

          </template>
        </acao-caixa>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "@/main";
import axios from "@/axios-auth";
import { Notify } from "quasar";
import moment from "moment";
import { Money } from "v-money";

export default {
  name: "modal-caixa-quitacao-contas",
  components: { Money },
  data() {
    return {
      movconta_id: null,
      cadastro_nome: null,
      movconta_valor: 0,
      movconta_descricao: "",
      // autocomplete
      carregando: false,
      options: [],
      selectItem: null,
      autocompleteResult: {},
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    clear(modal = true) {
      this.$store.commit("movcontaReturn", null);
      this.$store.commit("isReadOnlyCaixa", false);
      this.$store.commit("blockValor", false);
      this.$store.commit("errorsCaixas", {});

      if (modal) {
        this.$store.commit("OpenDialogQuitacaoContasCaixas", false);
      }
    },
    newDespesa() {
      this.$store.commit("callDespesa", true);
      this.$store.dispatch("planoContas");
      this.$store.commit("OpenDialogNovaDespesa", true);
    },
    criarDescricaoConta() {
      this.movconta_id = this.autocompleteResult.id;
      this.cadastro_nome =
        this.autocompleteResult.cadastro === null
          ? "Sem nome"
          : this.autocompleteResult.cadastro.nome;
      this.movconta_descricao = this.autocompleteResult.descricao;
      this.movconta_valor = Math.abs(this.autocompleteResult.valor);
      let venc = this.autocompleteResult.vencimento.split("/");
      this.movconta_vencimento = venc[2] + "/" + venc[1] + "/" + venc[0];
      document.getElementsByName("descricaoCaixa")[0].focus();
      this.save();
    },
    save() {
      if (
        this.movcontaReturn &&
        this.cadastro_nome === null &&
        this.movconta_id === null
      ) {
        this.movconta_id = this.movcontaReturn.cadastro.id;
        this.cadastro_nome = this.movcontaReturn.cadastro.nome;
      }

      this.$store.commit("movconta_id_caixa", this.movconta_id);
      this.$store.commit("venda", {
        descricao:
          "Pago: " +
          this.movconta_id +
          ", " +
          this.movconta_vencimento +
          " - " +
          this.movconta_descricao +
          " - " +
          this.cadastro_nome,
        valor: Math.abs(this.movconta_valor).toFixed(2)
      });
      this.$store.commit("isReadOnlyCaixa", false);
      this.$store.commit("blockValor", true);
    },
    // Funções do autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/financeiro/busca/despesa?term=" + val).then(res => {
          this.carregando = false;
          this.options = res.data;
        });
      });
    },
    createValue(val, done) {
      if (val && !val.name) {
        Notify.create({
          message: "Não foi possivel localizar conta !",
          color: "orange",
          position: "top-right"
        });
        return false;
      }
      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
    },
    formatValor(value) {
      if (!value) return "R$ 0,00";
      var val = (value / 1).toFixed(2).replace(".", ",");
      return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDisplay(result) {
      let receita = {
        id: null,
        nome: "",
        descricao: "",
        valor: 0,
        vencimento: "",
        verificarCadatros: ""
      };

      receita.id = result.id;
      receita.verificarCadatros = !result.cadastro
        ? (result.nome ? result.nome : "Sem cadastro")
        : result.cadastro.nome;
      receita.nome =
        result["cadastro"] === null ? "Sem cadastro" : receita.verificarCadatros;
      receita.descricao =
        result.descricao === null ? "Sem Descrição" : result.descricao;
      receita.valor =
        result.valor === null ? "Sem Valor" : this.formatValor(result.valor);
      receita.vencimento =
        result.vencimento === null
          ? "Sem Vencimento"
          : moment.utc(result.vencimento).format("DD/MM/YYYY");
      return `
        <span>
          ${receita.vencimento} - <strong>${receita.valor}</strong>
          <br/>
          Fornecedor <strong>${receita.verificarCadatros}</strong>
          <br/>
          ${receita.id} - ${receita.descricao}
        </span>
      `;
    },
    resultEmitRecebe(result) {
      this.autocompleteResult = result;

      if (this.autocompleteResult && this.autocompleteResult.id != null) {
        Notify.create({
          message:
            this.autocompleteResult.descricao + " - conta foi selecionado.",
          color: "green",
          position: "top-right"
        });
      }

      this.criarDescricaoConta();
    },
    displayMontar(value) {
      if (!value.descricao) return;
      let format = moment.utc(value.vencimento).format("DD/MM/YYYY") + " - " + value.descricao;
      return !value ? value : format;
    },
  },
  computed: {
    ...mapGetters([
      "movcontaReturn",
      "errorsCaixas",
      "movcontasOptions",
      "contas",
      "venda",
      "dadosCaixaContaPagarReceber",
    ]),
    OpenDialogQuitacaoContasCaixas: {
      get() {
        return this.$store.getters.OpenDialogQuitacaoContasCaixas;
      },
      set() {}
    },
    conta_id_caixa:{
      get(){
        return this.$store.getters.conta_id_caixa;
      },
      set(value){
        this.$store.commit('conta_id_caixa', value);
      }
    },
    exibirCriarNovaMovconta() {
      return this.venda.valor !== 0 && this.movconta_valor > parseFloat(this.venda.valor);
    },
    criarContaValorRestanteAutomaticamente() {
      return this.movcontasOptions.movcontas_criar_movconta_valor_restante
        && parseInt(this.movcontasOptions.movcontas_criar_movconta_valor_restante);
    },
  },
  watch :{
    "venda.valor"() {
      if (this.exibirCriarNovaMovconta) {
        this.dadosCaixaContaPagarReceber.valorRestante = this.movconta_valor - parseFloat(this.venda.valor);
      }
    },
    OpenDialogQuitacaoContasCaixas() {
      if (!this.OpenDialogQuitacaoContasCaixas) return;

      this.dadosCaixaContaPagarReceber.gerarNovaMovconta = false;
      this.dadosCaixaContaPagarReceber.novoVencimento = moment().format("DD/MM/YYYY");
      this.dadosCaixaContaPagarReceber.valorRestante = 0;

      if (this.criarContaValorRestanteAutomaticamente) {
        this.dadosCaixaContaPagarReceber.gerarNovaMovconta = true;
      }
    },
  },
  created() {
    bus.$on("clearQuitarConta", () => {
      this.clear(false);
    });
  },
  mounted() {
    document.addEventListener("keyup", event => {
      if (event.keyCode === 27) {
        this.clear();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener("keyup");
    next();
  }
};
</script>

<style lang="stylus" scoped></style>
