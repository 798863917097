<template>
  <div id="datePicker">
    <q-btn-group rounded flat no-wrap no-caps>
      <q-btn
        class="white text-blue-7"
        clickable
        @click="changeFilterPeriodo('prev')"
        rounded
        icon="chevron_left"
      />
      <q-btn class="white text-blue-7" rounded :label="dtini">
        <template v-slot:default>
          <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
            <q-date
              mask="DD/MM/YYYY"
              :locale="langDate"
              minimal
              today-btn
              v-model="dtini"
              @input="(value) => enviaData1(value)"
            />
          </q-popup-proxy>
        </template>
      </q-btn>
      <q-btn class="white text-blue-7" rounded :label="dtfim">
        <template v-slot:default>
          <q-popup-proxy ref="qDateProxy2" transition-show="scale" transition-hide="scale">
            <q-date
              mask="DD/MM/YYYY"
              :locale="langDate"
              minimal
              today-btn
              v-model="dtfim"
              @input="(value) => enviaData2(value)"
            />
          </q-popup-proxy>
        </template>
      </q-btn>
      <q-btn
        class="white text-blue-7"
        clickable
        @click="changeFilterPeriodo('next')"
        rounded
        icon="chevron_right"
      />
    </q-btn-group>
  </div>
</template>

<script>
export default {
  name: "date-picker-notas-fiscais",
  data() {
    return {
      format: "d MMMM yyyy",
      mes: [],
    };
  },
  methods: {
    enviaData1(value) {
      let query = {
        dtini: value.replace(/\//g, "-"),
        dtfim: this.dtfim.replace(/\//g, "-")
      };

      this.$store.dispatch("filtrosNotasFiscais", query);
      this.$refs.qDateProxy.hide();
    },
    enviaData2(value) {
      let query = {
        dtini: this.dtini.replace(/\//g, "-"),
        dtfim: value.replace(/\//g, "-")
      };
      this.$store.dispatch("filtrosNotasFiscais", query);
      this.$refs.qDateProxy2.hide();
    },
    changeFilterPeriodo(val) {
      this.$store.dispatch("changeFilterPeriodoNotasFiscais", val);
    },
  },
  computed: {
    dtini: {
      get() {
        let date = this.$store.getters.filtrosNotasFiscais.dtini.replace(/-/g, "/");
        return date;
      },
      set() {}
    },
    dtfim: {
      get() {
        let date = this.$store.getters.filtrosNotasFiscais.dtfim.replace(/-/g, "/");
        return date;
      },
      set() {}
    }
  }
};
</script>

<style lang="stylus" scoped></style>
