<template>
  <div id="formas-pagamento">
    <q-card white bordered class="q-mb-md">
      <q-card-section @click="qformaspagamento = !qformaspagamento">
        <div class="text-h6 cursor-pointer">
          <q-btn
            dense
            flat
            no-wrap
            v-ripple
            round
            icon="add"
            no-caps
            class="q-ml-sm q-mr-md bg-white text-blue-7"
            clickable
            size="11px"
            @click.stop="modal()"
            :loading="loading"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>Formas de Pagamento
        </div>
        <q-btn
          dense
          flat
          no-wrap
          v-ripple
          round
          color="black"
          :icon="qformaspagamento ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          no-caps
          class="q-ml-sm bg-white text-black absolute-top-right"
          style="margin: 15px 35px 0 0"
          clickable
        ></q-btn>
      </q-card-section>
      <q-card-section v-if="qformaspagamento">
        <q-table
          title
          :data="formasPagamentos"
          :columns="columns"
          row-key="id"
          :separator="separator"
          :loading="buscando"
          no-data-label="Nenhum resultado encontrado."
          :pagination.sync="pagination"
        >
          <template v-slot:bottom>
            <div></div>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
              <q-td key="ativo" class="text-center">
                <q-icon
                  :name="props.row.ativo ? 'check' : 'close'"
                  size="24px"
                  :class="props.row.ativo ? 'text-positive' : 'text-negative'"
                ></q-icon>
              </q-td>
              <q-td key="titulo">{{props.row.gateway.titulo}} <q-badge v-if="props.row.tipord" color="orange" label="Despesa" /></q-td>
              <q-td key="diacobranca" class="text-right">{{props.row.diacobranca ? props.row.diacobranca : "-"}}</q-td>
              <q-td key="tipogeracao">{{tipogeracao[props.row.tipogeracao] ? tipogeracao[props.row.tipogeracao].label : "-"}}</q-td>
              <q-td
                key="agencia"
                class="text-right"
              >{{props.row.agencia}} {{props.row.operacao}} {{props.row.conta}}</q-td>
              <q-td key="created" class="text-right">{{props.row.created | formatDate}}</q-td>
              <q-td v-if="$q.platform.is.desktop" class="tdfora">
                <div class="divfora">
                  <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                    <q-btn
                      @click="modal(props.row)"
                      size="12px"
                      class="bg-primary text-white"
                      icon="edit"
                    ></q-btn>
                    <q-btn
                      @click="remessa(props.row)"
                      class="bg-orange-7 text-white"
                      icon="insert_drive_file"
                      size="12px"
                    ></q-btn>
                    <q-btn
                      @click="excluir(props.row)"
                      class="bg-negative text-white"
                      icon="delete_forever"
                      size="12px"
                    ></q-btn>
                  </div>
                </div>
              </q-td>
            </q-tr>
            <q-tr v-show="props.expand" :props="props">
              <q-td colspan="100%">
                <div class="q-py-sm q-gutter-sm">
                  <q-btn
                    @click="modal(props.row)"
                    size="12px"
                    class="bg-primary text-white"
                    icon="edit"
                  />
                  <q-btn
                    @click="remessa(props.row)"
                    class="bg-orange-7 text-white"
                    icon="insert_drive_file"
                    size="12px"
                  />
                  <q-btn
                    @click="excluir(props.row)"
                    class="bg-negative text-white"
                    icon="delete_forever"
                    size="12px"
                  />
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
    <q-dialog v-model="OpenDialogFormasPagamento" persistent>
      <q-card style="width: 400px; max-width: 90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">{{isNew ? 'Nova ' : 'Editar '}}Forma de Pagamento</div>
          <q-space />
          <q-btn
            icon="close"
            clickable
            flat
            round
            dense
            v-close-popup
            @click="OpenDialogFormasPagamento = false"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="col col-md-12">
            <q-checkbox v-model="formaPagamento.ativo" label="Ativo" color="green" />
            <q-toggle
              class="pull-right"
              v-model="tipord"
              :label="tipord ? 'Despesa' : 'Receita' "
              left-label
            />
          </div>

          <div class="q-pa-md">
            <div class="q-gutter-md">
              <div class="col col-md-12">
                <q-select
                  v-model="formaPagamento.gateway_id"
                  :options="gateways"
                  option-label="titulo"
                  option-value="id"
                  emit-value
                  map-options
                  label="Forma de Pagamento"
                  bottom-slots
                  clearable
                  :error="errors.gateway_id ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.gateway_id" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
              <div class="col col-md-12">
                <q-select
                  bottom-slots
                  v-model="formaPagamento.tipogeracao"
                  :options="tipogeracao"
                  label="Tipo da cobrança"
                  map-options
                  emit-value
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col col-md-12">
                <q-input v-model="formaPagamento.diacobranca" mask="##" label="Dia da cobrança" />
              </div>
              <div class="col col-md-12">
                <q-input v-model="formaPagamento.codigo" maxlength="100" label="Código" />
              </div>
              <div class="col col-md-12">
                <q-input v-model="formaPagamento.agencia" mask="####" label="Agência" />
              </div>
              <div class="col col-md-12">
                <q-input v-model="formaPagamento.operacao" mask="###" label="Operação" />
              </div>
              <div class="col col-md-12">
                <q-input v-model="formaPagamento.conta" mask="#########" label="Conta" />
              </div>
              <div class="col col-md-12">
                <q-input v-model="formaPagamento.banco" mask="###" label="Banco" />
              </div>
              <br />
              <br />
              <br />
              <q-btn
                no-wrap
                v-ripple
                label="Salvar"
                no-caps
                class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                style="margin: 0 30px 20px 0"
                clickable
                type="submit"
                :loading="saveForma"
                @click="save()"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";
export default {
  name: "formasPagamento",
  data() {
    return {
      qformaspagamento: false,
      separator: "horizontal",
      columns: [
        {
          name: "ativo",
          required: true,
          label: "Ativo",
          align: "center",
          field: row => row.ativo,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "titulo",
          align: "center",
          label: "Título",
          field: row => row.gateway ? row.gateway.titulo : null,
          sortable: true
        },
        {
          name: "diacobranca",
          label: "Dia",
          field: row => row.diacobranca ? row.diacobranca : "-",
          sortable: true,
          align: "center"
        },
        {
          name: "tipogeracao",
          label: "Tipo",
          field: row => row.tipogeracao ? row.tipogeracao : "-",
          align: "center",
          sortable: true
        },
        {
          name: "agencia",
          label: "Conta",
          field: row => row.agencia,
          align: "center",
          sortable: true
        },
        {
          name: "created",
          label: "Cadastro em",
          field: row => row.created,
          align: "center",
          sortable: true
        },
        { name: 'tdfora' },
      ],
      buscando: false,
      isNew: false,
      formaPagamento: this.newFormaPgto(),
      gateways: [],
      formasPagamentos: [],
      tipogeracao: [],
      errors: [],
      formasSuccess: false,
      bancos: [],
      loading: false,
      saveForma: false,
      emmitAction: {
        novo: false,
        edit: false,
        remessa: false,
        delete: false
      },
      OpenDialogFormasPagamento: false,
      ativo: true,
      tipord: false,
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 0
      }
    };
  },
  watch: {
    tipord(tipo){
      this.formaPagamento.gateway_id = null;
      this.formaPagamento.tipord = tipo;
      this.modal(this.formaPagamento);
    },
    qformaspagamento(value) {
      if (value) {
        this.listar();
      }
    }
  },
  methods: {
    excluir(gateway) {
      Dialog.create({
        title: gateway.gateway.titulo,
        message: "Tem certeza que deseja excluir a forma de pagamento?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post("/cadastros/delete-gateway/" + gateway.id)
            .then(res => {
              if (res.data.success) {
                Dialog.create({
                  title: "Forma de pagamento removida!",
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
                this.emmitAction.delete = true;
                this.listar();
              } else {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
              }
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    remessa(gateway) {
      Dialog.create({
        title: gateway.gateway.titulo,
        message: "Gerar remessa de cadastro?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          var url =
            "/cadastros/gateway-remessa/" +
            gateway.cadastro_id +
            "/" +
            gateway.id;
          axios
            .post(url, gateway)
            .then(res => {
              if (res.data.success) {
                this.emmitAction.remessa = true;
                Dialog.create({
                  title: "Remessa Gerada! Arquivo id: " + res.data.arquivo.id,
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
              } else {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
              }
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    save() {
      this.saveForma = true;
      let formaPagamento = this.formaPagamento;
      formaPagamento.cadastro_id = this.$route.params.id;
      formaPagamento.diacobranca = parseInt(formaPagamento.diacobranca);
      formaPagamento.tipogeracao = parseInt(formaPagamento.tipogeracao);

      let action = this.getAction();
      axios
        .post(action, formaPagamento)
        .then(res => {
          if (res.data.success) {
            this.OpenDialogFormasPagamento = false;
            Notify.create({
              message: "Cadastro salvo!",
              color: "green",
              position: "top-right"
            });
            this.emmitAction.novo = true;
            this.listar();
          } else {
            Notify.create({
              message: "Não foi possível salvar forma de pagamento!",
              color: "orange",
              position: "top-right"
            });
            this.errors = res.data.errors;
          }
          this.saveForma = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.saveForma = false;
        });
    },
    getAction() {
      var url = "/cadastros/add-gateway/" + this.$route.params.id;
      if (this.formaPagamento.id) {
        url =
          "/cadastros/add-gateway/" +
          this.$route.params.id +
          "/" +
          this.formaPagamento.id;
      }
      return url;
    },
    modal(formaPgto) {
      this.loading = true;

      if (formaPgto) {
        this.isNew = false;
        this.formaPagamento = formaPgto;
      } else {
        this.formaPagamento = this.newFormaPgto();
        this.isNew = true;
      }

      let url = "/cadastros/add-gateway/" + this.$route.params.id + ( this.formaPagamento.tipord ? "/?despesa=1" : "" ) ;

      axios
        .get(url)
        .then(res => {
          if (res.data.success) {
            this.gateways = res.data.data.gateways;
            this.bancos = res.data.data.bancos;
            this.tipogeracao = res.data.data.tipogeracao.map((tipo, index) => ({
              label: tipo,
              value: index,
            }));
            this.OpenDialogFormasPagamento = true;
            this.loading = false;
            this.errors = [];
          }
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível listar as formas de pagamento, atualize a página e tente novamente!",
            color: "red",
            position: "top-right"
          });
          this.loading = false;
        });
    },
    newFormaPgto() {
      return {
        id: null,
        ativo: true,
        cadastro_id: null,
        gateway_id: null,
        codigo: "",
        agencia: "",
        operacao: "",
        conta: "",
        banco: "",
        tipogeracao: null,
        diacobranca: 0,
        gateway: null,
        tipord: false, //false receita, true despesa
      };
    },
    listar() {
      if (this.buscando) {
        return;
      }
      this.buscando = true;
      this.formasSuccess = false;
      axios
        .get("/cadastros/gateways/" + this.$route.params.id)
        .then(res => {
          if (res.data.success) {
            this.formasPagamentos = res.data.data.gateways;
            this.tipogeracao = res.data.data.tipogeracao.map((tipo, index) => ({
              label: tipo,
              value: index,
            }));
            this.formasSuccess = res.data.success;
            this.buscando = false;
          }
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível listar as formas de pagamento, atualize a página e tente novamente!",
            color: "red",
            position: "top-right"
          });
          this.buscando = false;
        });
    }
  },
  mounted() {
    this.formaPagamento = this.newFormaPgto();
  }
};
</script>

<style lang="stylus" scoped>
.text-h6, .text-subtitle2 {
  font-weight: 300;
}

.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
