<template>
  <q-dialog v-model="openDialog" persistent @keydown.enter.prevent="confirmAddItem()">
    <q-card class="scroll" style="width: 90%; max-width: 400px">
      <q-card-section class="row items-center">
        <div class="text-h6">Adicionar Item</div>
        <q-space />
        <q-btn icon="close" clickable flat round dense v-close-popup @click="fecharModal()" />
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pa-md">
        <div class="row q-col-gutter-x-md">
          <div class="col-6">
            <q-input
              bottom-slots
              label="Início"
              :error="Boolean(errorsItem.dtini)"
              v-model="item.dtini"
              @click="$refs.qDateInicioItem.show()"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateInicioItem"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="item.dtini"
                      @input="() => pulaCampo('qDateFimItem')"
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      minimal
                      today-btn
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span v-for="(error, i) in errorsItem.dtini" :key="error + i">{{ error }}</span>
              </template>
            </q-input>
          </div>

          <div class="col-6">
            <q-input
              bottom-slots
              label="Fim"
              :error="Boolean(errorsItem.dtfim)"
              v-model="item.dtfim"
              @click="$refs.qDateFimItem.show()"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateFimItem" transition-show="scale" transition-hide="scale">
                    <q-date
                      v-model="item.dtfim"
                      @input="() => confirmAddItem()"
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      minimal
                      today-btn
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span v-for="(error, i) in errorsItem.dtfim" :key="error + i">{{ error }}</span>
              </template>
            </q-input>
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          no-caps
          v-ripple
          clickable
          label="Adicionar"
          color="positive"
          text-color="white"
          style="margin-right: 22px; margin-bottom: 10px"
          @click="confirmAddItem()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import moment from 'moment';
import { bus } from '@/main';

export default {
  name: 'modal-add-item-recorrencia',
  data() {
    return {
      openDialog: false,
      item: {},
      errorsItem: {},
    };
  },
  methods: {
    abrirModal({ item }) {
      this.item = JSON.parse(JSON.stringify(item));
      this.openDialog = true;
    },
    fecharModal() {
      this.openDialog = false;
    },
    confirmAddItem() {
      const errorsItem = {};

      if (this.item.dtini && !moment(this.item.dtini, 'DD/MM/YYYY').isValid()) {
        errorsItem['dtini'] = ['Data inválida'];
      }

      if (this.item.dtfim && !moment(this.item.dtfim, 'DD/MM/YYYY').isValid()) {
        errorsItem['dtfim'] = ['Data inválida'];
      }

      if (Object.keys(errorsItem).length > 0) {
        this.errorsItem = errorsItem;
        return;
      }

      const item = JSON.parse(JSON.stringify(this.item));

      if (item.dtini) {
        item.dtini = moment(item.dtini, 'DD/MM/YYYY').format('YYYY/MM/DD');
      }

      if (item.dtfim) {
        item.dtfim = moment(item.dtfim, 'DD/MM/YYYY').format('YYYY/MM/DD');
      }

      this.$emit('itemAdicionado', item);
    },
    pulaCampo(campo, segundos = null) {
      const focar = () => {
        if (!this.$refs[campo]) {
          return;
        }

        if (this.$refs[campo].show) {
          return this.$refs[campo].show();
        }

        if (this.$refs[campo].$el.classList.contains('v-money')) {
          return this.$refs[campo].$el.focus();
        }

        return this.$refs[campo].focus();
      };

      if (segundos) {
        return setTimeout(() => focar(), segundos);
      }

      focar();
    },
  },
  mounted() {
    bus.$on('abrirModalAddItemRecorrencia', this.abrirModal);
    bus.$on('fecharModalAddItemRecorrencia', this.fecharModal);
  },
};
</script>
