<template>
  <q-page id="planos">
    <div class="q-pa-md">

      <div class="row q-col-gutter-md">
        <sem-permissao v-if="semPermissao" />

        <div class="col-12" v-else>

          <q-table
            row-key="id"
            title="Portal de Planos/Check-Out"
            no-data-label="Nenhum dado"
            loading-label="Carregando dados"
            no-results-label="Nenhum resultado obtido"
            :bottom="false"
            :data="planos"
            :columns="columns"
            :loading="loadingPlanos"
            :pagination.sync="pagination"
          >
            <template v-slot:top>
              <q-btn
                flat
                round
                dense
                icon="add"
                class="q-ml-md text-primary"
                style="margin-right: 10px; margin-left: 0;"
                @click="openModalSalvarPlano()"
              />
              <div class="q-table__title">Portal de Planos/Check-Out</div>
              <a
                v-show="urlPublica"
                target="_blank"
                class="q-ml-md"
                style="text-decoration: none;"
                :href="urlPublica"
              >
                <q-icon name="fa fa-external-link" size="18px" color="grey-7" />
              </a>
            </template>

            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                <q-td align="right" key="id">
                  {{ props.row.id }}
                </q-td>
                <q-td align="left" key="nome">
                  {{ props.row.nome.length > 40? `${props.row.nome.substr(0, 40)}...` : props.row.nome }}
                  <q-tooltip v-if="props.row.nome && props.row.nome.length > 40">
                    {{ props.row.nome }}
                  </q-tooltip>
                </q-td>
                <q-td align="right" key="periodo">
                  {{ getPeriodoDescricao(props.row.periodo) }}
                </q-td>
                <q-td align="right" key="valor">
                  R$ {{ props.row.valor | currency }}
                </q-td>
                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm">
                      <q-btn
                        size="12px"
                        icon="edit"
                        color="green"
                        text-color="white"
                        @click="openModalSalvarPlano(props.row)"
                      />
                      <q-btn
                        size="12px"
                        class="q-mr-sm"
                        color="negative"
                        text-color="white"
                        icon="delete_forever"
                        @click="excluirPlano(props.row)"
                      />
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      size="12px"
                      icon="edit"
                      color="green"
                      text-color="white"
                      @click="openModalSalvarPlano(props.row)"
                    />
                    <q-btn
                      size="12px"
                      class="q-mr-sm"
                      color="negative"
                      text-color="white"
                      icon="delete_forever"
                      @click="excluirPlano(props.row)"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:bottom></template>

          </q-table>

        </div>
      </div>

      <!-- Menu lateral -->
      <q-page-sticky position="top-right" :offset="[0, 90]">
        <q-btn
          v-show="!$q.fullscreen.isActive"
          :color="openOpcoesAvancadas ? 'red-7' : 'blue-7'"
          :icon="openOpcoesAvancadas ? 'close' : 'settings'"
          class="rotate-0"
          style="border-top-left-radius: 50px;border-bottom-left-radius: 50px;"
          @click="openOpcoesAvancadas = !openOpcoesAvancadas"
        >
          <q-tooltip
            :content-class="
              openOpcoesAvancadas
                ? 'bg-negative text-white text-center'
                : 'bg-secondary text-white text-center'
            "
            >{{
              openOpcoesAvancadas ? "Fechar Opções Avançadas" : "Opções Avançadas"
            }}</q-tooltip
          >
        </q-btn>
      </q-page-sticky>
      <q-drawer
        bordered
        side="right"
        behavior="default"
        content-class="bg-white"
        :width="300"
        :breakpoint="500"
        v-model="openOpcoesAvancadas"
      >
        <q-scroll-area class="fit">
          <div class="q-pa-sm">
            <div class="q-table__title">Opções Avançadas</div>
            <div>
              <q-list
                class="rounded-borders"
                style="max-width: 350px; margin-left: -10px; width: 300px;"
              >
                <q-item-label header></q-item-label>

                <q-item clickable v-ripple @click.exact="abrirConfiguracoes()">
                  <q-item-section>
                    <q-item-label lines="1">Configurações</q-item-label>
                    <q-item-label caption>Configurações Avançadas</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-ripple @click.exact="salvarModeloPlanos()" v-if="planos">
                  <q-item-section>
                    <q-item-label lines="1">Importar Planos</q-item-label>
                    <q-item-label caption>Importar Modelo de Planos</q-item-label>
                  </q-item-section>
                </q-item>

              </q-list>
            </div>
          </div>
        </q-scroll-area>
      </q-drawer>

      <q-dialog v-model="dialogSalvarPlano" persistent>
        <q-card class="scroll" style="width: 100%; max-width: 1000px;">

          <q-card-section class="row items-center">
            <div class="text-h6">{{ plano.id ? 'Editar' : 'Novo'}} Plano</div>
            <q-space />
            <q-btn
              flat
              dense
              round
              clickable
              v-close-popup
              icon="close"
              @click="closeModalSalvarPlano()"
            />
          </q-card-section>

          <q-separator />

          <q-card-section class="q-pa-md">
            <div class="row q-col-gutter-lg items-start">

              <div class="col-12 col-md-6 col-lg-4 row q-col-gutter-x-md">
                <div class="col-12">
                  <q-checkbox
                    color="green"
                    label="Ativo"
                    v-model="plano.ativo"
                  />
                </div>

                <div class="col-12">
                  <q-checkbox
                    color="green"
                    label="Destaque"
                    v-model="plano.is_destaque"
                  />
                </div>

                <div class="col-12">
                  <q-select
                    emit-value
                    map-options
                    bottom-slots
                    v-model="plano.periodo"
                    :options="periodoMeses"
                    label="Período em meses"
                    :error="Boolean(planoErrors.periodo)"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in planoErrors.periodo"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-select>
                </div>

                <div class="col-12">
                  <q-input
                    bottom-slots
                    label="Nome"
                    :error="Boolean(planoErrors.nome)"
                    v-model="plano.nome"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in planoErrors.nome"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-input>
                </div>

                <div class="col-12">
                  <q-input
                    bottom-slots
                    label="Código"
                    :error="Boolean(planoErrors.codigo)"
                    v-model="plano.codigo"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in planoErrors.codigo"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-input>
                </div>

                <div class="col-12">
                  <q-field
                    bottom-slots
                    label="Valor"
                    :error="Boolean(planoErrors.valor)"
                    v-model="plano.valor"
                    >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                      />
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in planoErrors.valor"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-field>
                </div>

                <div class="col-12">
                  <q-field
                    bottom-slots
                    label="Valor de Ativação"
                    :error="Boolean(planoErrors.valor_ativacao)"
                    v-model="plano.valor_ativacao"
                    >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                      />
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in planoErrors.valor_ativacao"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-field>
                </div>

                <div class="col-12 col-md-6">
                  <q-input
                    bottom-slots
                    mask="######"
                    label="Quantidade"
                    :error="Boolean(planoErrors.quantidade)"
                    v-model="plano.quantidade"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in planoErrors.quantidade"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-input>
                </div>

                <div class="col-12 col-md-6">
                  <q-input
                    bottom-slots
                    label="Ordem"
                    mask="###"
                    :error="Boolean(planoErrors.ordem)"
                    v-model="plano.ordem"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in planoErrors.ordem"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-input>
                </div>

                <div class="col-12" v-if="optionsRecorrencias.integracoes_piperun_utilizar_trial === '1'">
                  <q-input
                    bottom-slots
                    mask="###"
                    label="Quantidade de dias de teste"
                    :error="Boolean(planoErrors.quantidade_dias_trial)"
                    v-model="plano.quantidade_dias_trial"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in planoErrors.quantidade_dias_trial"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-input>
                </div>

                <div class="col-12">
                  <q-select
                    use-input
                    clearable
                    bottom-slots
                    input-debounce="0"
                    option-value="id"
                    option-label="titulo"
                    label="Planos de contas"
                    :error="Boolean(planoErrors.planodeconta_id)"
                    :options="planosdecontasOptionsPlanos"
                    @filter="filterPlanosdecontasPlanos"
                    v-model="plano.planodeconta"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          Sem Resultados
                        </q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in planoErrors.planodeconta_id"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-select>
                </div>

              </div>

              <div class="col-12 col-md-6 col-lg-8 row q-col-gutter-md">

                <div class="col-12">
                  <q-input
                    bottom-slots
                    label="Texto do botão"
                    :error="Boolean(planoErrors.texto_botao)"
                    v-model="plano.texto_botao"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in planoErrors.texto_botao"
                        :key="error + i"
                      >
                        {{ error }}
                      </span>
                    </template>
                  </q-input>
                </div>

                <div class="col-12">
                  <q-editor
                    min-height="5rem"
                    :dense="$q.screen.lt.md"
                    :toolbar="toolbarDescricao"
                    v-model="plano.descricao"
                  />
                </div>

                <div class="col-12">
                  <q-list separator dense>
                    <q-expansion-item
                      label="Itens da Assinatura"
                      :disable="!plano.id"
                      group="planos"
                    >
                      <div class="row items-center q-col-gutter-x-md q-ml-md">

                        <div class="col-9">
                          <q-select
                            use-input
                            clearable
                            emit-value
                            map-options
                            bottom-slots
                            option-value="id"
                            input-debounce="0"
                            option-label="titulo"
                            label="Planos de contas"
                            :error="Boolean(planoItem.planodeconta)"
                            :options="planosdecontasOptionsItems"
                            @filter="filterPlanosdecontasItems"
                            v-model="planoItem.planodeconta_id"
                          >
                            <template v-slot:no-option>
                              <q-item>
                                <q-item-section class="text-grey">
                                  Sem Resultados
                                </q-item-section>
                              </q-item>
                            </template>
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoItemErrors.planodeconta_id"
                                :key="error + i"
                              >
                                {{ error }}
                              </span>
                            </template>
                          </q-select>
                        </div>

                        <div class="col-3">
                          <q-input
                            bottom-slots
                            mask="###"
                            label="Quantidade de meses"
                            :error="Boolean(planoItemErrors.quantidade_meses)"
                            v-model="planoItem.quantidade_meses"
                          >
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoItemErrors.quantidade_meses"
                                :key="error + i"
                              >
                                {{ error }}
                              </span>
                            </template>
                          </q-input>
                        </div>

                        <div class="col-2">
                          <q-input
                            bottom-slots
                            mask="######"
                            label="Quantidade"
                            :error="Boolean(planoItemErrors.quantidade)"
                            v-model="planoItem.quantidade"
                          >
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoItemErrors.quantidade"
                                :key="error + i"
                              >
                                {{ error }}
                              </span>
                            </template>
                          </q-input>
                        </div>

                        <div class="col-5">
                          <q-input
                            bottom-slots
                            label="Descrição"
                            :error="Boolean(planoItemErrors.descricao)"
                            v-model="planoItem.descricao"
                          >
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoItemErrors.descricao"
                                :key="error + i"
                              >
                                {{ error }}
                              </span>
                            </template>
                          </q-input>
                        </div>

                        <div class="col-5">
                          <q-field
                            bottom-slots
                            label="Valor"
                            :error="Boolean(planoItemErrors.valor)"
                            v-model="planoItem.valor"
                          >
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                              <money
                                :id="id"
                                class="q-field__native text-right"
                                :value="value"
                                @input="emitValue"
                                v-bind="moneyFormat"
                                v-show="floatingLabel"
                              >
                              </money>
                            </template>
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoErrors.valor"
                                :key="error + i"
                              >
                                {{ error }}
                              </span>
                            </template>
                          </q-field>
                        </div>

                        <div class="col-4">
                          <q-checkbox
                            label="Quantidade editável"
                            color="green"
                            v-model="planoItem.quantidade_editavel"
                          />
                        </div>

                        <div class="col-3">
                          <q-input
                            bottom-slots
                            mask="######"
                            label="Quantidade mínima"
                            :error="Boolean(planoItemErrors.quantidade_min)"
                            :disable="!planoItem.quantidade_editavel"
                            :readonly="!planoItem.quantidade_editavel"
                            v-model="planoItem.quantidade_min"
                          >
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoItemErrors.quantidade_min"
                                :key="error + i"
                              >
                                {{ error }}
                              </span>
                            </template>
                          </q-input>
                        </div>

                        <div class="col-3">
                          <q-input
                            bottom-slots
                            mask="######"
                            label="Quantidade máxima"
                            :error="Boolean(planoItemErrors.quantidade_max)"
                            :disable="!planoItem.quantidade_editavel"
                            :readonly="!planoItem.quantidade_editavel"
                            v-model="planoItem.quantidade_max"
                          >
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoItemErrors.quantidade_max"
                                :key="error + i"
                              >
                                {{ error }}
                              </span>
                            </template>
                          </q-input>
                        </div>

                        <div class="col-2 text-right">
                          <q-btn
                            flat
                            round
                            icon="add"
                            color="primary"
                            style="height: 42px;"
                            class="align-self-center"
                            :loading="loadingSalvarPlanoItem"
                            @click="addPlanoItem()"
                          />
                        </div>

                        <q-markup-table
                          flat
                          dense
                          class="q-my-md full-width"
                          separator="none"
                          v-if="plano.planos_items"
                        >
                          <thead>
                            <tr>
                              <th class="text-left">Descrição</th>
                              <th class="text-left">Plano de contas</th>
                              <th class="text-right">Valor</th>
                              <th class="text-right">Duração</th>
                              <th style="width: 50px;"></th>
                            </tr>
                          </thead>
                          <tbody v-show="loadingPlanosItems">
                            <tr>
                              <td colspan="4" class="text-center" style="height: 3em;">
                                <q-spinner size="2em" color="primary" />
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-show="!loadingPlanosItems">
                            <tr
                              v-for="item in plano.planos_items"
                              :key="item.id"
                            >
                              <td>({{ item.quantidade }}) {{ item.descricao }}</td>
                              <td class="text-left">
                                <span v-if="item.planodeconta">{{ item.planodeconta.titulo }}</span>
                              </td>
                              <td class="text-right">R$ {{ item.valor | currency }}</td>
                              <td class="text-right">{{ item.quantidade_meses }} meses</td>
                              <td class="text-right">
                                <q-btn
                                  flat
                                  dense
                                  size="12px"
                                  color="positive"
                                  icon="attach_money"
                                  v-if="item.quantidade_editavel"
                                  @click="openModalFaixaPrecoItem(item)"
                                />
                                <q-btn
                                  flat
                                  dense
                                  size="12px"
                                  color="red"
                                  icon="delete"
                                  @click="excluirPlanoItem(item)"
                                />
                              </td>
                            </tr>
                            <tr v-if="loadingPlanosItems && plano.planos_items.length === 0">
                              <td colspan="3" align="center">Nenhum item</td>
                            </tr>
                          </tbody>
                        </q-markup-table>

                      </div>
                    </q-expansion-item>

                    <q-expansion-item
                      label="Descontos sobre o MRR"
                      :disable="!plano.id"
                      group="planos"
                    >
                      <div class="row items-center q-col-gutter-x-md q-ml-md">

                        <div class="col-12 col-md-6">
                          <q-field
                            bottom-slots
                            label="Desconto"
                            style="width: 100%;"
                            v-model="planoDesconto.valor_desconto"
                            :error="Boolean(planoDescontoErrors.valor_desconto)"
                          >
                            <template v-slot:before>
                              <q-btn
                                small
                                size="md"
                                style="width: 49px;"
                                :label="planoDesconto.tipo_desconto  === '%' ? '%' : 'R$'"
                                v-model="planoDesconto.tipo_desconto"
                                @click="toggleTipoDesconto()"
                              />
                            </template>
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                              <money
                                :id="id"
                                class="q-field__native text-right"
                                :value="value"
                                @input="emitValue"
                                v-bind="planoDesconto.tipo_desconto === '%' ? percentFormat : moneyFormat"
                                v-show="floatingLabel"
                              >
                              </money>
                            </template>
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoDescontoErrors.valor_desconto"
                                :key="error + i"
                                >{{ error }}</span
                              >
                            </template>
                          </q-field>
                        </div>

                        <div class="col-12 col-md-6">
                          <q-input
                            bottom-slots
                            label="Duração em meses"
                            :error="Boolean(planoDescontoErrors.quantidade_meses)"
                            v-model="planoDesconto.quantidade_meses"
                          >
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoDescontoErrors.quantidade_meses"
                                :key="error + i"
                              >
                                {{ error }}
                              </span>
                            </template>
                            <template v-slot:after>
                              <q-btn
                                flat
                                round
                                icon="add"
                                color="primary"
                                style="height: 42px;"
                                class="align-self-center"
                                :loading="loadingSalvarPlanoDesconto"
                                @click="addPlanoDesconto()"
                              />
                            </template>
                          </q-input>
                        </div>

                        <q-markup-table
                          flat
                          dense
                          separator="none"
                          class="q-my-md full-width"
                          v-if="plano.planos_descontos"
                        >
                          <tbody v-show="loadingPlanosDescontos">
                            <tr>
                              <td colspan="4" class="text-center" style="height: 3em;">
                                <q-spinner size="2em" color="primary" />
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-show="!loadingPlanosDescontos">
                            <tr
                              v-for="desconto in plano.planos_descontos"
                              :key="desconto.funcionarioId"
                            >
                              <td>Durante {{ desconto.quantidade_meses }} meses</td>
                              <td class="text-right">{{ desconto.valor_desconto | currency | value(desconto.tipo_desconto) }}</td>
                              <td style="width: 50px;">
                                <q-btn
                                  flat
                                  dense
                                  size="12px"
                                  color="red"
                                  icon="delete"
                                  @click="excluirPlanoDesconto(desconto)"
                                />
                              </td>
                            </tr>
                            <tr v-if="!loadingPlanosItems && plano.planos_descontos.length === 0">
                              <td colspan="3" align="center">Nenhum desconto</td>
                            </tr>
                          </tbody>
                        </q-markup-table>

                      </div>
                    </q-expansion-item>

                    <q-expansion-item
                      label="Parcelas Adesão/Ativação"
                      :disable="!plano.id"
                      group="planos"
                    >
                      <div class="row items-center q-col-gutter-x-md q-ml-md">

                        <div class="col-12 col-md-4">
                          <q-input
                            bottom-slots
                            label="Descrição"
                            :error="Boolean(planoParcelaErrors.descricao)"
                            v-model="planoParcela.descricao"
                          >
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoParcelaErrors.descricao"
                                :key="error + i"
                              >
                                {{ error }}
                              </span>
                            </template>
                          </q-input>
                        </div>

                        <div class="col-12 col-md-4">
                          <q-field
                            bottom-slots
                            label="Valor"
                            style="width: 100%;"
                            :error="Boolean(planoParcelaErrors.valor)"
                            v-model="planoParcela.valor"
                          >
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                              <money
                                :id="id"
                                class="q-field__native text-right"
                                :value="value"
                                @input="emitValue"
                                v-bind="moneyFormat"
                                v-show="floatingLabel"
                              >
                              </money>
                            </template>
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoParcelaErrors.valor"
                                :key="error + i"
                              >
                                {{ error }}
                              </span>
                            </template>
                          </q-field>
                        </div>

                        <div class="col-12 col-md-4">
                          <q-input
                            bottom-slots
                            mask="##"
                            label="Cobrar após meses"
                            :error="Boolean(planoParcelaErrors.apos_meses)"
                            v-model="planoParcela.apos_meses"
                          >
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoParcelaErrors.apos_meses"
                                :key="error + i"
                              >
                                {{ error }}
                              </span>
                            </template>
                            <template v-slot:after>
                              <q-btn
                                flat
                                round
                                icon="add"
                                color="primary"
                                style="height: 42px;"
                                class="align-self-center"
                                @click="addPlanoParcela()"
                              />
                            </template>
                          </q-input>
                        </div>

                        <q-markup-table
                          flat
                          dense
                          class="q-my-md full-width"
                          separator="none"
                          v-if="plano.planos_parcelas"
                        >
                          <tbody v-show="loadingPlanosParcelas">
                            <tr>
                              <td colspan="4" class="text-center" style="height: 3em;">
                                <q-spinner size="2em" color="primary" />
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-show="!loadingPlanosParcelas">
                            <tr
                              v-for="parcela in plano.planos_parcelas"
                              :key="parcela.id"
                            >
                              <td style="white-space: break-spaces;">{{ parcela.descricao }}</td>
                              <td class="text-center">Após {{ parcela.apos_meses }} meses</td>
                              <td class="text-right">{{ parcela.valor | currency }}</td>
                              <td style="width: 50px;">
                                <q-btn
                                  flat
                                  dense
                                  size="12px"
                                  color="red"
                                  icon="delete"
                                  @click="excluirPlanoParcela(parcela)"
                                />
                              </td>
                            </tr>
                            <tr v-if="loadingPlanosParcelas && plano.planos_parcelas.length === 0">
                              <td colspan="4" align="center">Nenhuma parcela</td>
                            </tr>
                          </tbody>
                        </q-markup-table>

                      </div>
                    </q-expansion-item>

                    <q-expansion-item
                      label="Comissões aos Parceiros na Aquisição do Plano"
                      group="planos"
                    >
                      <div class="row items-center q-col-gutter-x-md q-ml-md">
                        <div class="col-12">Valor Recorrente</div>

                          <div class="col-12 col-md-4">
                            <q-select
                              emit-value
                              map-options
                              bottom-slots
                              ref="comissoesQuando"
                              :options="comissaoQuandoOptions"
                              :error="Boolean(planoErrors.comissao_recorrente_quando)"
                              :label="`Gerar n${plano.comissao_recorrente_quando === 'liquidacao' ? 'a' : 'o'}`"
                              v-model="plano.comissao_recorrente_quando"
                            >
                              <template v-slot:no-option>
                                <q-item>
                                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                                </q-item>
                              </template>
                              <template v-slot:error>
                                <span v-for="(error, i) in planoErrors.comissao_recorrente_quando" :key="error + i">{{
                                  error
                                }}</span>
                              </template>
                            </q-select>
                          </div>

                          <div class="col-12 col-md-4">
                            <q-field
                              bottom-slots
                              label="Comissão"
                              style="width: 100%;"
                              v-model="plano.comissao_recorrente"
                              :error="Boolean(planoErrors.comissao_recorrente)"
                            >
                            <template v-slot:before>
                              <q-btn
                                small
                                size="md"
                                style="width: 49px;"
                                :label="plano.comissao_recorrente_tipo  === '%' ? '%' : 'R$'"
                                v-model="plano.comissao_recorrente_tipo"
                                @click="toggleTipoComissaoRecorrencia()"
                              />
                            </template>
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                              <money
                                :id="id"
                                class="q-field__native text-right"
                                ref="plano_comissao_recorrente_tipo"
                                :value="value"
                                @input="emitValue"
                                v-bind="plano.comissao_recorrente_tipo === '%' ? percentFormat : moneyFormat"
                                v-show="floatingLabel"
                              >
                              </money>
                            </template>
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoErrors.comissao_recorrente"
                                :key="error + i"
                                >{{ error }}</span
                              >
                            </template>
                            </q-field>
                          </div>

                          <div class="col-12 col-md-4">
                            <q-input
                              bottom-slots
                              mask="###"
                              label="Duração em meses"
                              v-model="plano.comissao_recorrente_meses"
                            >
                            </q-input>
                          </div>

                          <div class="col-12">Valor Ativação</div>

                          <div class="col-12 col-md-4">
                            <q-select
                              emit-value
                              map-options
                              bottom-slots
                              ref="comissoesQuando"
                              :options="comissaoQuandoOptions"
                              :error="Boolean(planoErrors.comissao_ativacao_quando)"
                              :label="`Gerar n${plano.comissao_ativacao_quando === 'liquidacao' ? 'a' : 'o'}`"
                              v-model="plano.comissao_ativacao_quando"
                            >
                              <template v-slot:no-option>
                                <q-item>
                                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                                </q-item>
                              </template>
                              <template v-slot:error>
                                <span v-for="(error, i) in planoErrors.comissao_ativacao_quando" :key="error + i">{{
                                  error
                                }}</span>
                              </template>
                            </q-select>
                          </div>

                          <div class="col-12 col-md-4">
                            <q-field
                              bottom-slots
                              label="Comissão"
                              style="width: 100%;"
                              v-model="plano.comissao_ativacao"
                              :error="Boolean(planoErrors.comissao_ativacao)"
                            >
                            <template v-slot:before>
                              <q-btn
                                small
                                size="md"
                                style="width: 49px;"
                                :label="plano.comissao_ativacao_tipo  === '%' ? '%' : 'R$'"
                                v-model="plano.comissao_ativacao_tipo"
                                @click="toggleTipoComissaoValor()"
                              />
                            </template>
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                              <money
                                :id="id"
                                class="q-field__native text-right"
                                ref="plano_comissao_ativacao_tipo"
                                :value="value"
                                @input="emitValue"
                                v-bind="plano.comissao_ativacao_tipo === '%' ? percentFormat : moneyFormat"
                                v-show="floatingLabel"
                              >
                              </money>
                            </template>
                            <template v-slot:error>
                              <span
                                v-for="(error, i) in planoErrors.comissao_ativacao"
                                :key="error + i"
                                >{{ error }}</span
                              >
                            </template>
                            </q-field>
                          </div>

                          <div class="col-12" v-if="funcionariosList.length > 0">Link Comissão</div>

                          <div class="col-12 col-md-8" v-if="funcionariosList.length > 0">
                            <q-select
                              map-options
                              bottom-slots
                              :options="funcionariosList"
                              v-model="funcionariosLink"
                              option-value="id"
                              option-label="name"
                              label="Selecione o Funcionário"
                              ref="funcionariosListagem"
                              @change="copy()"
                            >
                              <q-tooltip>
                                Ref = Campo código
                              </q-tooltip>

                              <template v-slot:no-option>
                                <q-item>
                                  <q-item-section class="text-grey">Sem Resultados</q-item-section>
                                </q-item>
                              </template>

                              <template v-slot:hint v-if="funcionarioLinkCopy">
                                <span class="text-green">
                                  URL copiada! {{ funcionarioLink }}
                                </span>
                              </template>

                            </q-select>
                          </div>

                      </div>
                    </q-expansion-item>

                  </q-list>
                </div>

              </div>

            </div>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn
              no-caps
              v-ripple
              clickable
              type="submit"
              label="Salvar"
              color="positive"
              text-color="white"
              style="margin-right: 22px; margin-bottom: 10px;"
              :loading="loadingSalvarPlano"
              @click="salvarPlano()"
            >
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
          </q-card-actions>

        </q-card>
      </q-dialog>

      <q-dialog v-model="dialogConfiguracoes" persistent>
        <q-card style="width: 100%; max-width: 1200px;">

          <q-card-section class="row items-center">
            <div class="text-h6">Configurações</div>
            <q-space />
            <q-btn
              flat
              dense
              round
              clickable
              v-close-popup
              icon="close"
              @click="closeModalConfiguracoes()"
            />
          </q-card-section>

          <q-separator />

          <q-card-section class="q-pa-md">
            <div class="row q-col-gutter-md">

              <div class="col-12 col-md-6">
                <q-input
                  label="Texto principal do banner"
                  v-model="configuracoes.planos_texto_banner"
                />
              </div>

              <div class="col-12 col-md-6">
                <q-input
                  label="Texto padrão do botão"
                  v-model="configuracoes.planos_texto_botao"
                />
              </div>

              <div class="col-12 col-md-6">
                <q-input
                  label="Cor principal"
                  v-model="configuracoes.planos_primary_color"
                >
                  <template v-slot:after>
                    <div :style="`width: 35px; height: 35px; background-color: ${configuracoes.planos_primary_color}; border: 1px solid #000;`"></div>
                  </template>
                </q-input>
              </div>

              <div class="col-12 col-md-6">
                <q-input
                  label="Cor do texto"
                  v-model="configuracoes.planos_text_color"
                >
                  <template v-slot:after>
                    <div :style="`width: 35px; height: 35px; background-color: ${configuracoes.planos_text_color}; border: 1px solid #000;`"></div>
                  </template>
                </q-input>
              </div>

              <div class="col-12 col-md-6">
                <q-select
                  clearable
                  emit-value
                  map-options
                  label="Boleto"
                  option-value="id"
                  input-debounce="0"
                  option-label="titulo"
                  :options="gateways"
                  v-model="configuracoes.planos_gateway_boleto"
                />
              </div>

              <div class="col-12 col-md-6">
                <q-select
                  clearable
                  emit-value
                  map-options
                  label="Cartão de crédito"
                  option-value="id"
                  input-debounce="0"
                  option-label="titulo"
                  :options="gateways"
                  v-model="configuracoes.planos_gateway_cartao_credito"
                />
              </div>

              <div class="col-12 col-md-6">
                <q-select
                  clearable
                  emit-value
                  map-options
                  label="Pix"
                  option-value="id"
                  input-debounce="0"
                  option-label="titulo"
                  :options="gateways"
                  v-model="configuracoes.planos_gateway_pix"
                />
              </div>

              <div class="col-12 col-md-6">
                <q-select
                  clearable
                  emit-value
                  map-options
                  label="Cartão de débito"
                  option-value="id"
                  input-debounce="0"
                  option-label="titulo"
                  :options="gateways"
                  v-model="configuracoes.planos_gateway_cartao_debito"
                />
              </div>

              <div class="col-12 col-md-6">
                <q-select
                  clearable
                  emit-value
                  map-options
                  label="Template de cadastro de plano"
                  option-value="id"
                  input-debounce="0"
                  option-label="subject"
                  :options="templates"
                  v-model="configuracoes.planos_template_cadastro_plano"
                />
              </div>

              <div class="col-12 col-md-6">
                <q-select
                  label="Exibir Planos"
                  option-label="label"
                  option-value="value"
                  emit-value
                  map-options
                  :options="opcoesExibirPlanos"
                  v-model="configuracoes.planos_periodo_exibir"
                />
              </div>

              <div class="col-12">
                <q-input
                  label="Na aquisição do Plano redirecionar para essa url"
                  v-model="configuracoes.planos_redirect_url"
                >
                </q-input>
              </div>

              <div class="col-12">
                <label class="text-grey-8">Conteúdo abaixo de planos</label>
                <q-editor
                  min-height="5rem"
                  :dense="$q.screen.lt.md"
                  :toolbar="toolbarDescricao"
                  v-model="configuracoes.planos_conteudo_abaixo_planos"
                />
              </div>

            </div>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn
              no-caps
              v-ripple
              clickable
              type="submit"
              label="Salvar"
              color="positive"
              text-color="white"
              style="margin-right: 22px; margin-bottom: 10px;"
              :loading="loadingSalvarConfiguracoes"
              @click="salvarConfiguracoes()"
            >
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
          </q-card-actions>

        </q-card>
      </q-dialog>

      <q-dialog v-model="openDialogFaixaPrecoItem" persistent>
        <q-card style="width: 100%; max-width: 600px;">

          <q-card-section class="row items-center">
            <div class="text-h6">{{ item ? item.descricao : null }}</div>
            <q-space />
            <q-btn
              flat
              dense
              round
              clickable
              v-close-popup
              icon="close"
              @click="closeModalFaixaPrecoItem()"
            />
          </q-card-section>

          <q-separator />

          <q-card-section class="q-pa-md">
            <div class="row q-col-gutter-md">

              <div class="col-6">
                <q-input
                  mask="######"
                  label="Quantidade mínima"
                  v-model="faixaPreco.min"
                />
              </div>

              <div class="col-6">
                <q-input
                  mask="######"
                  label="Quantidade máxima"
                  v-model="faixaPreco.max"
                />
              </div>

              <div class="col-12">
                <q-field
                  label="Valor"
                  style="width: 100%;"
                  v-model="faixaPreco.valor"
                >
                  <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                </q-field>
              </div>

              <div class="col-8">
                <q-checkbox
                  color="green"
                  label="Valor por quantidade"
                  v-model="faixaPreco.valor_por_quantidade"
                />
              </div>

              <div class="col-4 text-right">
                <q-btn
                  flat
                  round
                  icon="add"
                  color="primary"
                  @click="adicionarFaixaPreco()"
                />
              </div>

              <div class="col-12">
                <q-markup-table flat>
                  <tbody>
                    <tr v-for="(faixa, index) in item.faixa_preco_array" :key="index">
                      <td>
                        <span v-if="!faixa.min && faixa.max">Até {{ faixa.max }}</span>

                        <span v-if="faixa.min && faixa.max">De {{ faixa.min }} a {{ faixa.max }}</span>

                        <span v-if="faixa.min && !faixa.max">A partir de {{ faixa.min }}</span>

                        {{ String(item.descricao).toLowerCase() }}
                      </td>
                      <td>R$ {{ faixa.valor | currency }}</td>
                      <td>{{ faixa.valor_por_quantidade ? 'Por quantidade' : 'Total' }}</td>
                      <td>
                        <q-btn
                          flat
                          dense
                          size="12px"
                          color="red"
                          icon="delete"
                          @click="deleteFaixaPreco(index)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </q-markup-table>
              </div>

            </div>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn
              no-caps
              v-ripple
              clickable
              type="submit"
              label="Salvar"
              color="positive"
              text-color="white"
              style="margin-right: 22px; margin-bottom: 10px;"
              :loading="loadingSalvarFaixaPrecoItem"
              @click="salvarFaixaPrecoItem()"
            >
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
          </q-card-actions>

        </q-card>
      </q-dialog>

    </div>
  </q-page>
</template>

<script>
import axios from '@/axios-auth';
import { Loading } from 'quasar';
import { Money } from 'v-money';

export default {
  name: 'planos',
  components: { Money },
  meta: {
    title: 'DSB | Planos',
  },
  data() {
    return {
      /** Planos */
      semPermissao: false,
      planos: [],
      urlPublica: null,
      loadingPlanos: false,
      columns: [
        {
          name: 'id',
          label: 'ID',
          align: 'right',
          field: row => row.id,
          sortable: true
        },
        {
          name: 'nome',
          label: 'Nome',
          align: 'left',
          field: row => row.nome,
          sortable: true
        },
        {
          name: 'periodo',
          label: 'Período',
          align: 'right',
          field: row => row.periodo,
          sortable: true
        },
        {
          name: 'valor',
          label: 'Valor',
          align: 'right',
          field: row => row.valor,
          sortable: true
        },
        { name: 'tdFora' },
      ],
      pagination: {
        sortBy: 'id',
        descending: false,
        rowsPerPage: 0,
        page: 1,
      },
      periodoMeses: [
        {
          label: 'Mensal',
          value: 1,
        },
        {
          label: 'Bimestral',
          value: 2,
        },
        {
          label: 'Trimestral',
          value: 3,
        },
        {
          label: 'Quadrimestral',
          value: 4,
        },
        {
          label: 'Semestral',
          value: 6,
        },
        {
          label: 'Anual',
          value: 12,
        }
      ],
      /** Plano */
      dialogSalvarPlano: false,
      plano: this.newPlano(),
      planoErrors: {},
      loadingSalvarPlano: false,
      planosdecontasOptionsPlanos: [],
      planosdecontasOptionsItems: [],
      planoItem: this.newPlanoItem(),
      planoItemErrors: {},
      loadingSalvarPlanoItem: false,
      loadingPlanosItems: false,
      planoParcela: this.newPlanoParcela(),
      planoParcelaErrors: {},
      loadingSalvarPlanoParcela: false,
      loadingPlanosParcelas: false,
      planoDesconto: this.newPlanoDesconto(),
      planoDescontoErrors: {},
      loadingSalvarPlanoDesconto: false,
      loadingPlanosDescontos: false,
      /** Configurações */
      dialogConfiguracoes: false,
      configuracoes: {},
      loadingConfiguracoes: false,
      loadingSalvarConfiguracoes: false,
      gateways: [],
      templates: [],
      optionsRecorrencias: {},
      /** Utilidade */
      openOpcoesAvancadas: false,
      planosdecontas: [],
      toolbarDescricao: [
        ['left','center','right','justify'],
        ['bold','italic','underline','strike'],
        ['undo','redo'],
        ['viewsource'],
      ],
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      percentFormat: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: ' %',
        precision: 2,
        masked: false,
      },
      /** Faixa de Preço do Item */
      openDialogFaixaPrecoItem: false,
      item: this.newPlanoItem(),
      loadingSalvarFaixaPrecoItem: false,
      faixaPreco: this.newFaixaPreco(),
      comissaoQuandoOptions: [
        {
          label: 'Faturamento',
          value: 'faturamento',
        },
        {
          label: 'Liquidação',
          value: 'liquidacao',
        },
      ],
      comissaoItemQuandoOptions: [
        {
          label: 'Recorrência',
          value: 'recorrencia',
        },
        {
          label: 'Parcela',
          value: 'parcela',
        },
      ],
      opcoesExibirPlanos: [
        {
          value: "ambos",
          label: 'Anuais e Mensais',
        },
        {
          value: "anuais",
          label: "Apenas Anuais",
        },
        {
          value: "mensais",
          label: "Apenas Mensais",
        },
      ],
      funcionariosList: [],
      funcionariosLink: '',
      funcionarioLinkCopy: false,
      funcionarioLink: ''
    };
  },
  methods: {
    /** Planos */
    loadPlanos() {
      if (this.loadingPlanos) return;

      this.loadingPlanos = true;

      axios
        .get('/planos')
        .then(res => {
          this.loadingPlanos = false;

          if (this.hasErroPermissao(res)) {
            this.semPermissao = true;
            return;
          }

          if (!res.data.success) {
            throw new Error();
          }

          this.planos = res.data.planos;
          this.optionsRecorrencias = res.data.options_recorrencias;
          this.urlPublica = res.data.url_publica;
        })
        .catch(() => {
          this.loadingPlanos = false;
          this.$q.notify({
            message: 'Houve um erro ao buscar os planos',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    /** Plano */
    openModalSalvarPlano(plano = null) {
      if (!plano) {
        this.loadPlanosdecontas();
        this.resetPlano();
        this.dialogSalvarPlano = true;
        return;
      }

      axios
        .get(`/planos/view/${plano.id}`)
        .then(res => {
          if (!res.data.success) {
            throw new Error();
          }

          this.resetPlano();
          this.loadPlanosdecontas();

          this.plano = res.data.plano;
          this.dialogSalvarPlano = true;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar o plano',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    closeModalSalvarPlano() {
      this.dialogSalvarPlano = false;
    },
    salvarPlano() {
      if (this.loadingSalvarPlano) return;

      this.loadingSalvarPlano = true;

      const url = this.plano.id
        ? `/planos/edit/${this.plano.id}`
        : '/planos/add';
      const data = JSON.parse(JSON.stringify(this.plano));

      axios
        .post(url, data)
        .then(res => {
          this.loadingSalvarPlano = false;

          if (this.hasErroPermissao(res)) return;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) {
            this.planoErrors = res.data.errors;
            return;
          }

          this.closeModalSalvarPlano();
          this.loadPlanos();

          if (!this.plano.id) {
            this.openModalSalvarPlano(res.data.plano);
          }
        })
        .catch(() => {
          this.loadingSalvarPlano = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar o plano',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    salvarModeloPlanos() {
      if (this.loadingSalvarPlano) return;

      this.loadingSalvarPlano = true;

      axios
        .post('/planos/add-modelo', [])
        .then(res => {
          this.loadingSalvarPlano = false;

          if (this.hasErroPermissao(res)) return;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          this.loadPlanos();

        })
        .catch(() => {
          this.loadingSalvarPlano = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar o planos do modelo',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    excluirPlano(plano) {
      this.$q.dialog({
        title: plano.nome,
        message: 'Tem certeza que deseja excluir o plano?',
        cancel: 'Cancelar',
        persistent: true,
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(`/planos/delete/${plano.id}`)
            .then(res => {
              if (this.hasErroPermissao(res)) return;

              this.$q.dialog({
                title: res.data.message,
                class: `bg-${res.data.success ? 'positive' : 'warning'} text-white`,
                ok: {
                  'text-color': res.data.success ? 'green' : 'orange',
                  color: 'white',
                },
              });

              this.loadPlanos();
            })
            .catch(() => {
              this.$q.dialog({
                title: 'Não foi Possível realizar a ação, ou você não possui permissão isto!',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white',
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    loadPlanosItems() {
      if (this.loadingPlanosItems || !this.plano.id) return;

      this.loadingPlanosItems = true;

      axios
        .get(`/planos/view/${this.plano.id}`)
        .then(res => {
          this.loadingPlanosItems = false;

          if (!res.data.success) {
            throw new Error();
          }

          this.plano.planos_items = res.data.plano.planos_items;
        })
        .catch(() => {
          this.loadingPlanosItems = false;
          this.$q.notify({
            message: 'Houve um erro ao carregar os items do plano',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    loadPlanosParcelas() {
      if (this.loadingPlanosParcelas || !this.plano.id) return;

      this.loadingPlanosParcelas = true;

      axios
        .get(`/planos/view/${this.plano.id}`)
        .then(res => {
          this.loadingPlanosParcelas = false;

          if (!res.data.success) {
            throw new Error();
          }

          this.plano.planos_parcelas = res.data.plano.planos_parcelas;
        })
        .catch(() => {
          this.loadingPlanosParcelas = false;
          this.$q.notify({
            message: 'Houve um erro ao carregar as parcelas do plano',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    loadPlanosDescontos() {
      if (this.loadingPlanosDescontos || !this.plano.id) return;

      this.loadingPlanosDescontos = true;

      axios
        .get(`/planos/view/${this.plano.id}`)
        .then(res => {
          this.loadingPlanosDescontos = false;

          if (!res.data.success) {
            throw new Error();
          }

          this.plano.planos_descontos = res.data.plano.planos_descontos;
        })
        .catch(() => {
          this.loadingPlanosDescontos = false;
          this.$q.notify({
            message: 'Houve um erro ao carregar os descontos do plano',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    addPlanoItem() {
      if (this.loadingSalvarPlanoItem) return;

      this.loadingSalvarPlanoItem = true;

      const data = JSON.parse(JSON.stringify(this.planoItem));

      axios
        .post(`/planos/add-item/${this.plano.id}`, data)
        .then(res => {
          this.loadingSalvarPlanoItem = false;

          if (this.hasErroPermissao(res)) return;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) {
            this.planoItemErrors = res.data.errors;
            return;
          }

          this.resetPlanoItem();
          this.loadPlanosItems();
        })
        .catch(() => {
          this.loadingSalvarPlanoItem = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar o item',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    excluirPlanoItem(item) {
      this.$q.dialog({
        title: item.descricao,
        message: 'Tem certeza que deseja excluir o item?',
        cancel: 'Cancelar',
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(`/planos/delete-item/${item.id}`)
            .then(res => {
              if (this.hasErroPermissao(res)) return;

              this.$q.dialog({
                title: res.data.message,
                class: `bg-${res.data.success ? 'positive' : 'warning'} text-white`,
                ok: {
                  'text-color': res.data.success ? 'green' : 'orange',
                  color: 'white',
                },
              });

              if (!res.data.success) return;

              this.loadPlanosItems();
            })
            .catch(() => {
              this.$q.dialog({
                title: 'Não foi Possível realizar a ação, ou você não possui permissão isto!',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white',
                },
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    addPlanoParcela() {
      if (this.loadingSalvarPlanoParcela) return;

      this.loadingSalvarPlanoParcela = true;

      const data = JSON.parse(JSON.stringify(this.planoParcela));

      axios
        .post(`/planos/add-parcela/${this.plano.id}`, data)
        .then(res => {
          this.loadingSalvarPlanoParcela = false;

          if (this.hasErroPermissao(res)) return;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) {
            this.planoParcelaErrors = res.data.errors;
            return;
          }

          this.resetPlanoParcela();
          this.loadPlanosParcelas();
        })
        .catch(() => {
          this.loadingSalvarPlanoParcela = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar o item',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    excluirPlanoParcela(item = null) {
      this.$q.dialog({
        title: item.descricao,
        message: 'Tem certeza que deseja excluir a parcela?',
        cancel: 'Cancelar',
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(`/planos/delete-parcela/${item.id}`)
            .then(res => {
              if (this.hasErroPermissao(res)) return;

              this.$q.dialog({
                title: res.data.message,
                class: `bg-${res.data.success ? 'positive' : 'warning'} text-white`,
                ok: {
                  'text-color': res.data.success ? 'green' : 'orange',
                  color: 'white',
                },
              });

              if (!res.data.success) return;

              this.loadPlanosParcelas();
            })
            .catch(() => {
              this.$q.dialog({
                title: 'Não foi Possível realizar a ação, ou você não possui permissão isto!',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white',
                },
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    addPlanoDesconto() {
      if (this.loadingSalvarPlanoDesconto) return;

      this.loadingSalvarPlanoDesconto = true;

      const data = JSON.parse(JSON.stringify(this.planoDesconto));

      axios
        .post(`/planos/add-desconto/${this.plano.id}`, data)
        .then(res => {
          this.loadingSalvarPlanoDesconto = false;

          if (this.hasErroPermissao(res)) return;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) {
            this.planoDescontoErrors = res.data.errors;
            return;
          }

          this.resetPlanoDesconto();
          this.loadPlanosDescontos();
        })
        .catch(() => {
          this.loadingSalvarPlanoDesconto = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar o desconto',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    excluirPlanoDesconto(desconto = null) {
      this.$q.dialog({
        title: desconto.descricao,
        message: 'Tem certeza que deseja excluir o desconto?',
        cancel: 'Cancelar',
        persistent: true,
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(`/planos/delete-desconto/${desconto.id}`)
            .then(res => {
              if (this.hasErroPermissao(res)) return;

              this.$q.dialog({
                title: res.data.message,
                class: `bg-${res.data.success ? 'positive' : 'warning'} text-white`,
                ok: {
                  'text-color': res.data.success ? 'green' : 'orange',
                  color: 'white',
                },
              });

              if (!res.data.success) return;

              this.loadPlanosDescontos();
            })
            .catch(() => {
              this.$q.dialog({
                title: 'Não foi Possível realizar a ação, ou você não possui permissão isto!',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white',
                },
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    /** Opções Avançadas */
    abrirConfiguracoes() {
      if (this.loadingConfiguracoes) return;

      this.loadingConfiguracoes = true;

      axios
        .get('/planos/configuracoes')
        .then(res => {
          this.loadingConfiguracoes = false;

          if (this.hasErroPermissao(res)) return;

          if (!res.data.success) {
            throw new Error();
          }

          const { config } = res.data;

          this.configuracoes = {
            ...config,
            planos_gateway_boleto: config.planos_gateway_boleto
              ? parseInt(config.planos_gateway_boleto)
              : null,
            planos_gateway_pix: config.planos_gateway_pix
            ? parseInt(config.planos_gateway_pix)
            : null,
            planos_gateway_cartao_credito: config.planos_gateway_cartao_credito
              ? parseInt(config.planos_gateway_cartao_credito)
              : null,
            planos_gateway_cartao_debito: config.planos_gateway_cartao_debito
              ? parseInt(config.planos_gateway_cartao_debito)
              : null,
            planos_template_cadastro_plano: config.planos_template_cadastro_plano
              ? parseInt(config.planos_template_cadastro_plano)
              : null,
          };
          this.dialogConfiguracoes = true;
        })
        .catch(() => {
          this.loadingConfiguracoes = false;
          this.$q.notify({
            message: 'Houve um erro ao carregar as configurações',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    closeModalConfiguracoes() {
      this.dialogConfiguracoes = false;
    },
    salvarConfiguracoes() {
      if (this.loadingSalvarConfiguracoes) return;

      this.loadingSalvarConfiguracoes = true;

      const data = JSON.parse(JSON.stringify(this.configuracoes));
      for (let key in data) {
        if (!data[key]) {
          data[key] = "";
        }
      }

      axios
        .post('/planos/configuracoes', data)
        .then(res => {
          this.loadingSalvarConfiguracoes = false;

          if (this.hasErroPermissao(res)) return;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) return;

          this.closeModalConfiguracoes();
        })
        .catch(() => {
          this.loadingSalvarConfiguracoes = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar as configurações',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    /** Utilizade */
    resetPlano() {
      this.plano = this.newPlano();
      this.planoErrors = {};

      this.resetPlanoItem();
    },
    resetPlanoItem() {
      this.planoItem = this.newPlanoItem();
      this.planoItemErrors = {};
    },
    resetPlanoParcela() {
      this.planoParcela = this.newPlanoParcela();
      this.planoParcelaErrors = {};
    },
    resetPlanoDesconto() {
      this.planoDesconto = this.newPlanoDesconto();
      this.planoDescontoErrors = {};
    },
    newPlano() {
      return {
        id: null,
        ativo: true,
        is_destaque: false,
        periodo: 1,
        nome: '',
        codigo: '',
        descricao: '',
        valor: 0.00,
        valor_ativacao: 0.00,
        quantidade: 1,
        ordem: 1,
        planodeconta_id: null,
        planodeconta: null,
        texto_botao: '',
      };
    },
    newPlanoItem() {
      return {
        id: null,
        descricao: '',
        faixa_preco: null,
        valor: 0.00,
        quantidade: 1,
        quantidade_meses: 1,
        quantidade_min: null,
        quantidade_max: null,
        quantidade_editavel: false,
        planodeconta_id: null,
        planodeconta: null,
        faixa_preco_array: [],
      };
    },
    newPlanoParcela() {
      return {
        id: null,
        descricao: '',
        valor: 0.00,
        apos_meses: 0,
      };
    },
    newPlanoDesconto() {
      return {
        valor_desconto: 0.00,
        tipo_desconto: '$',
        quantidade_meses: null,
      };
    },
    hasErroPermissao(res) {
      if (res.error && res.error.response.status === 403) {
        this.$q.notify({
          message: res.error.response.data.message,
          color: 'red',
          position: 'top-right',
        });
        return true;
      }

      return false;
    },
    loadPlanosdecontas() {
      axios
        .get('/plano-contas?cd=C')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.planosdecontas = res.data.planodecontas;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    loadFuncionarios() {
      axios
        .get('/cadastros/busca?funcionario=1')
        .then(res => {
          this.funcionariosList = res.data;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar a lista de funcionarios',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    loadGateways() {
      axios
        .get('/gateways?ativo=1')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.gateways = res.data.gateways;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar as formas de pagamento',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    loadTemplates() {
      axios
        .get('/templates')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.templates = res.data.data.templates;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar as formas de pagamento',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    filterPlanosdecontasPlanos(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.planosdecontasOptionsPlanos = this.planosdecontas.filter(
          planodecontas => planodecontas.titulo.toLowerCase().includes(needle)
        );
      });
    },
    filterPlanosdecontasItems(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.planosdecontasOptionsItems = this.planosdecontas.filter(
          planodecontas => planodecontas.titulo.toLowerCase().includes(needle)
        );
      });
    },
    getPeriodoDescricao(intervalo) {
      intervalo = parseInt(intervalo);

      switch(intervalo) {
        case 1:
          return 'Mensal';
        case 2:
          return 'Bimestral';
        case 3:
          return 'Trimestral';
        case 6:
          return 'Semestral';
        case 12:
          return 'Anual';
        default:
          return intervalo + ' meses';
      }
    },
    newFaixaPreco() {
      return {
        min: null,
        max: null,
        valor: 0,
        valor_por_quantidade: false,
      };
    },
    openModalFaixaPrecoItem(item) {
      this.faixaPreco = this.newFaixaPreco();
      this.item = JSON.parse(JSON.stringify(item));
      this.openDialogFaixaPrecoItem = true;
    },
    closeModalFaixaPrecoItem() {
      this.openDialogFaixaPrecoItem = false;
    },
    adicionarFaixaPreco() {
      if (this.faixaPreco.valor <= 0) return;

      const faixaPreco = JSON.parse(JSON.stringify(this.faixaPreco));

      this.item.faixa_preco_array.push(faixaPreco);
      this.faixaPreco = this.newFaixaPreco();
    },
    deleteFaixaPreco(index) {
      this.item.faixa_preco_array.splice(index, 1);
    },
    salvarFaixaPrecoItem() {
      if (this.loadingSalvarFaixaPrecoItem) return;

      this.loadingSalvarFaixaPrecoItem = true;

      const data = JSON.parse(JSON.stringify(this.item));
      data.faixa_preco = JSON.stringify(data.faixa_preco_array);

      axios
        .post(`/planos/edit-item/${data.id}`, data)
        .then(res => {
          this.loadingSalvarFaixaPrecoItem = false;

          if (this.hasErroPermissao(res)) return;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) return;

          this.loadPlanosItems();
          this.closeModalFaixaPrecoItem();
        })
        .catch(() => {
          this.loadingSalvarFaixaPrecoItem = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar a faixa de preço',
            position: 'top-right',
            color: 'red',
          });
        });
    },
    toggleTipoDesconto() {
      this.planoDesconto.tipo_desconto = this.planoDesconto.tipo_desconto === '%' ? '$' : '%';
      this.pulaCampo('valorDesconto');
    },
    toggleTipoComissaoValor() {
      this.plano.comissao_ativacao_tipo = this.plano.comissao_ativacao_tipo === '%' ? '$' : '%';
      this.pulaCampo('plano_comissao_ativacao_tipo');
    },
    toggleTipoComissaoRecorrencia() {
      this.plano.comissao_recorrente_tipo = this.plano.comissao_recorrente_tipo === '%' ? '$' : '%';
      this.pulaCampo('plano_comissao_recorrente_tipo');
    },
    pulaCampo(campo, segundos = null) {
      const focar = () => {
        if (!this.$refs[campo]) {
          return;
        }

        if (this.$refs[campo].show) {
          return this.$refs[campo].show();
        }

        if (this.$refs[campo].$el.classList.contains('v-money')) {
          return this.$refs[campo].$el.focus();
        }

        return this.$refs[campo].focus();
      };

      if (segundos) {
        return setTimeout(() => focar(), segundos);
      }

      focar();
    },
    async copy() {
      this.urlPublica;
      let url = this.urlPublica + '?ref=' + this.funcionariosLink.codigo;
      this.funcionarioLinkCopy = true;
      this.funcionarioLink = url;
      // let id_b64 = btoa(this.funcionariosLink.id);
      await navigator.clipboard.writeText(url);
      return true;
    }
  },
  watch: {
    'plano.planodeconta'(planodeconta) {
      if (planodeconta) {
        this.plano.planodeconta_id = planodeconta.id;
        return;
      }

      this.plano.planodeconta_id = null;
    },
    'funcionarioLinkCopy'(value) {
      if(value){
        setTimeout(() => this.funcionarioLinkCopy = false, 3000);
      }
    },
    'funcionariosLink'(value) {
      if(value){
        this.copy();
      }
    }
  },
  mounted() {
    this.loadPlanos();
    this.loadGateways();
    this.loadTemplates();
    this.loadFuncionarios();
  },
};
</script>
