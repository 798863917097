<template>
  <div id="modalEditarNotaFiscal">
    <q-dialog v-model="OpenDialogEditarNotaFiscal" persistent>
      <q-card style="width: 90%; max-width: 400px">
        <q-card-section class="row items-center">
          <div class="text-h6">Editar Nota Fiscal</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-lg">
            <div class="col-12">
              <q-checkbox label="Ativo" color="green" v-model="dados.ativo" />
            </div>

            <div class="col-12">
              <q-input
                bottom-slots
                label="Descrição, OS, Observação"
                :error="Boolean(errors.descricao)"
                v-model="dados.descricao"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.descricao" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-12 col-md-6">
              <q-input
                bottom-slots
                label="Código de Tributação do Município"
                :error="Boolean(errors.codigo_tributacao_municipio)"
                v-model="dados.codigo_tributacao_municipio"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.codigo_tributacao_municipio" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-12 col-md-6">
              <q-input
                bottom-slots
                label="Item da Lista de Serviço"
                :error="Boolean(errors.item_lista_servico)"
                v-model="dados.item_lista_servico"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.item_lista_servico" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-12">
              <q-input
                bottom-slots
                label="Descrição do Item da Lista de Serviço"
                :error="Boolean(errors.descricao_item_lista_servico)"
                v-model="dados.descricao_item_lista_servico"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.descricao_item_lista_servico" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-12 col-md-6">
              <q-field
                bottom-slots
                label="Valor"
                :error="Boolean(errors.valor)"
                :disabled="dados.status !== 0"
                v-model="dados.valor"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    class="q-field__native text-right"
                    :disabled="dados.status !== 0"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.valor" :key="error + i">{{ error }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-6">
              <q-field
                bottom-slots
                label="Alíquota"
                :error="Boolean(errors.aliquota_iss)"
                v-model="dados.aliquota_iss"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    class="q-field__native text-right"
                    v-bind="percentFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.aliquota_iss" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-6">
              <q-field
                bottom-slots
                label="Valor Deduções"
                :error="Boolean(errors.valor_deducoes)"
                v-model="dados.valor_deducoes"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    class="q-field__native text-right"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.valor_deducoes" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-6">
              <q-field
                bottom-slots
                label="Valor Pis"
                :error="Boolean(errors.valor_pis)"
                v-model="dados.valor_pis"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    class="q-field__native text-right"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.valor_pis" :key="error + i">{{ error }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-6">
              <q-field
                bottom-slots
                label="Valor Cofins"
                :error="Boolean(errors.valor_cofins)"
                v-model="dados.valor_cofins"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    class="q-field__native text-right"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.valor_cofins" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-6">
              <q-field
                bottom-slots
                label="Valor Inss"
                :error="Boolean(errors.valor_inss)"
                v-model="dados.valor_inss"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    class="q-field__native text-right"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.valor_inss" :key="error + i">{{ error }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-6">
              <q-field
                bottom-slots
                label="Valor Ir"
                :error="Boolean(errors.valor_ir)"
                v-model="dados.valor_ir"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    class="q-field__native text-right"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.valor_ir" :key="error + i">{{ error }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-6">
              <q-field
                bottom-slots
                label="Valor Csll"
                :error="Boolean(errors.valor_csll)"
                v-model="dados.valor_csll"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    class="q-field__native text-right"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.valor_csll" :key="error + i">{{ error }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-6">
              <q-field
                bottom-slots
                label="Outras Retenções"
                :error="Boolean(errors.outras_retencoes)"
                v-model="dados.outras_retencoes"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    class="q-field__native text-right"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.outras_retencoes" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-6">
              <q-field
                bottom-slots
                label="Desconto Incondicionado"
                :error="Boolean(errors.valor_desconto_incondicionado)"
                v-model="dados.valor_desconto_incondicionado"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    class="q-field__native text-right"
                    v-bind="moneyFormat"
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.valor_desconto_incondicionado"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-6">
              <q-select
                v-model="dados.iss_retido"
                label="ISS Retido"
                :options="opcoesIssRetido"
                emit-value
                map-options
                :error="Boolean(errors.iss_retido)"
                bottom-slots
              >
              <template v-slot:error>
                <span
                    v-for="(error, i) in errors.iss_retido"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
            </q-select>
            </div>

          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            label="Salvar"
            clickable
            v-ripple
            @click="salvar"
            :loading="loading"
            color="green"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import axios from '@/axios-auth';
import { Money } from 'v-money';
export default {
  name: 'modal-editar-nota-fiscal',
  components: { Money },
  data() {
    return {
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      percentFormat: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: ' %',
        precision: 2,
        masked: false,
      },
      loading: false,
      errors: {},
      dados: {
        ativo: true,
        descricao: '',
        item_lista_servico: '',
        descricao_item_lista_servico: '',
        valor: 0.0,
        aliquota_iss: 0.0,
        valor_deducoes: 0.0,
        valor_pis: 0.0,
        valor_cofins: 0.0,
        valor_inss: 0.0,
        valor_ir: 0.0,
        valor_csll: 0.0,
        outras_retencoes: 0.0,
        valor_desconto_incondicionado: 0.0,
        iss_retido: 0,
      },
      opcoesIssRetido: [
        {
          value: 0,
          label: 'Não',
        },
        {
          value: 1,
          label: 'Sim',
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$store.commit('setOpenDialogEditarNotaFiscal', false);
      this.$store.commit('notaFiscal', {});
      this.clearDados();
    },
    clearDados() {
      this.errors = {};
      this.dados = {
        ativo: true,
        descricao: '',
        item_lista_servico: '',
        descricao_item_lista_servico: '',
        valor: 0.0,
        aliquota_iss: 0.0,
        valor_deducoes: 0.0,
        valor_pis: 0.0,
        valor_cofins: 0.0,
        valor_inss: 0.0,
        valor_ir: 0.0,
        valor_csll: 0.0,
        outras_retencoes: 0.0,
        valor_desconto_incondicionado: 0.0,
        iss_retido: 0,
      };
    },
    salvar() {
      this.loading = true;
      const { id } = this.notaFiscal;

      axios
        .post(`/notas-fiscais/edit/${id}`, this.dados)
        .then(res => {
          this.loading = false;

          this.$q.notify({
            color: res.data.success ? 'green' : 'orange',
            message: res.data.message,
            position: 'top-right',
          });

          if (!res.data.success) {
            this.errors = res.data.errors;
            return;
          }

          this.updateNotasFiscais();
          this.closeModal();
        })
        .catch(() => {
          this.loading = false;
          this.$q.notify({
            color: 'red',
            message: 'Houve um erro ao editar a nota fiscal',
            position: 'top-right',
          });
        });
    },
    updateNotasFiscais() {
      const notasFiscais = this.cloneObjeto(this.notasFiscais);
      const notaFiscal = {
        ...this.cloneObjeto(this.notaFiscal),
        ...this.dados,
      };

      const notaFiscalIndex = notasFiscais.findIndex(nota => nota.id === notaFiscal.id);

      this.$set(this.notasFiscais, notaFiscalIndex, notaFiscal);
    },
    cloneObjeto(objeto) {
      return JSON.parse(JSON.stringify(objeto));
    },
  },
  computed: {
    ...mapGetters(['OpenDialogEditarNotaFiscal', 'notaFiscal', 'notasFiscais']),
  },
  watch: {
    notaFiscal(value) {
      if (!value) {
        return;
      }
      this.dados = value;
    },
  },
};
</script>

<style lang="stylus" scoped></style>
