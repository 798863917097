<template>
  <div id="cobrancas">
    <q-card white bordered class="q-mb-md">
      <q-card-section @click="qcobrancas = !qcobrancas" class="row items-center">
        <div class="text-h6 cursor-pointer">
          <q-btn
            dense
            flat
            no-wrap
            v-ripple
            round
            icon="add"
            no-caps
            class="q-ml-sm q-mr-md bg-white text-blue-7"
            clickable
            size="11px"
            @click.stop="novo()"
            :loading="loading"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>Cobranças
        </div>
        <q-space />
        <q-btn-group flat v-if="qcobrancas">
          <q-btn
            flat
            dense
            size="11px"
            color="primary"
            icon="settings"
            @click.stop="openOpcoesNotasFiscais()"
          />
          <q-btn
            flat
            dense
            no-wrap
            v-ripple
            no-caps
            class="q-mr-md"
            color="primary"
            clickable
            label="Notas Fiscais"
            :to="`/notas-fiscais?cadastro_id=${cadastroId}`"
          ></q-btn>
        </q-btn-group>
        <q-btn
          dense
          flat
          no-wrap
          v-ripple
          round
          color="white"
          text-color="black"
          :icon="qcobrancas ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          no-caps
          style="margin-right: 18px"
          clickable
        >
        </q-btn>
      </q-card-section>
      <q-card-section v-if="qcobrancas">
        <q-table
          title
          :data="cobrancas"
          :columns="columns"
          row-key="id"
          :separator="'horizontal'"
          :loading="buscando"
          :pagination.sync="pagination"
          no-data-label="Nenhum resultado encontrado."
        >
          <template v-slot:bottom> </template>
          <template v-slot:body="props">
            <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
              <q-td key="id" class="text-right">{{ props.row.id }}</q-td>
              <q-td key="descricao">
                <template v-slot:default>
                  <q-badge v-if="props.row.status == 'Aberto'" :color="new Date(props.row.vencimento) > new Date() ? 'blue' : 'red'">Aberto</q-badge>
                  <q-badge v-if="props.row.status == 'Liquidado'" color="green">Liquidado</q-badge>
                  <q-badge v-if="props.row.status == 'Cancelado'" color="orange">Cancelado</q-badge>
                  {{
                    props.row.descricao.length > 40
                      ? props.row.descricao.substr(0, 40) + "..."
                      : props.row.descricao
                  }}
                  <br v-if="props.row.gateway">
                  {{ props.row.gateway ? props.row.gateway.titulo : '' }}
                  <q-tooltip
                    v-if="
                      props.row.descricao && props.row.descricao.length > 40
                    "
                    >{{ props.row.descricao }}</q-tooltip
                  >
                </template>
              </q-td>
              <q-td key="vencimento" class="text-right">
                {{props.row.vencimento | formatDate }}
              </q-td>
              <q-td key="valor" class="text-right">
                R$ {{ props.row.valor | currency }}
              </q-td>
              <q-td key="liquidacao" class="text-right">
                {{ props.row.liquidacao | formatDate }}
              </q-td>
              <q-td
                key="valor_liquidado"
                class="text-right"
                :class="
                  props.row.valor_liquidado > props.row.valor
                    ? 'text-positive'
                    : props.row.valor_liquidado < props.row.valor
                    ? 'text-negative'
                    : ''
                "
                >R$ {{ props.row.valor_liquidado | currency }}</q-td
              >
              <q-td v-if="$q.platform.is.desktop" class="tdfora">
                <div class="divfora">
                  <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                    <q-btn
                      v-if="
                        props.row.status !== 'Cancelado' &&
                          props.row.status !== 'Liquidado'
                      "
                      @click="changeGateways(props.row)"
                      class="bg-primary text-white"
                      icon="repeat"
                      size="12px"
                    ></q-btn>
                    <q-btn
                      @click="linkBoleto(props.row)"
                      v-if="
                        props.row.status !== 'Cancelado' &&
                          props.row.status !== 'Liquidado'
                      "
                      class="bg-white text-black"
                      icon="img:data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAAmJLR0QA/4ePzL8AAAA7SURBVDjLY2AYLuAow2Ew+R8ID6PwkXmHERpACiEkgsWAhTeqYVTDwGs4zHAITIIED6HwkXmHhktuBgBYmul3nH84agAAAABJRU5ErkJggg=="
                      size="12px"
                      :disable="props.row.gateway ? !props.row.gateway.pode_enviar : true"
                    ></q-btn>
                    <q-btn
                      @click="linkDetalhe(props.row)"
                      class="bg-white text-black"
                      icon="receipt"
                      size="12px"
                    />
                    <q-btn
                      v-if="
                        props.row.status !== 'Cancelado' &&
                          props.row.status !== 'Liquidado'
                      "
                      @click="enviarEmail(props.row)"
                      class="bg-secondary text-white"
                      icon="mail"
                      size="12px"
                      :disable="props.row.gateway ? !props.row.gateway.pode_enviar : true"
                    ></q-btn>
                    <q-btn
                      v-if="
                        props.row.status !== 'Cancelado' && !props.row.liquidado
                      "
                      @click="liquidar(props.row)"
                      class="bg-green-7 text-white"
                      icon="attach_money"
                      size="12px"
                    ></q-btn>
                    <q-btn
                      @click="detalhar(props.row)"
                      class="bg-primary text-white"
                      icon="info"
                      size="12px"
                    ></q-btn>
                    <q-btn
                      v-if="props.row.status === 'Liquidado'"
                      @click="estornar(props.row)"
                      class="bg-secondary text-white"
                      icon="reply"
                      size="12px"
                    ></q-btn>
                    <q-btn
                      v-if="props.row.status === 'Cancelado'"
                      @click="reativa(props.row)"
                      class="bg-secondary text-white"
                      icon="reply"
                      size="12px"
                    ></q-btn>
                    <q-btn
                      @click="del(props.row)"
                      v-if="props.row.status === 'Aberto'"
                      class="bg-negative text-white"
                      icon="delete_forever"
                      size="12px"
                    ></q-btn>
                  </div>
                </div>
              </q-td>
            </q-tr>
            <q-tr v-show="props.expand" :props="props">
              <q-td colspan="100%">
                <div class="q-py-sm q-gutter-sm">
                  <q-btn
                    v-if="props.row.status !== 'Cancelado' && props.row.status !== 'Liquidado'"
                    @click="changeGateways(props.row)"
                    class="bg-primary text-white"
                    icon="repeat"
                    size="12px"
                  />
                  <q-btn
                    v-if="props.row.status !== 'Cancelado' && props.row.status !== 'Liquidado'"
                    @click="linkBoleto(props.row)"
                    class="bg-white text-black"
                    icon="img:data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAAmJLR0QA/4ePzL8AAAA7SURBVDjLY2AYLuAow2Ew+R8ID6PwkXmHERpACiEkgsWAhTeqYVTDwGs4zHAITIIED6HwkXmHhktuBgBYmul3nH84agAAAABJRU5ErkJggg=="
                    size="12px"
                    :disable="props.row.gateway ? !props.row.gateway.pode_enviar : true"
                  />
                  <q-btn
                    @click="linkDetalhe(props.row)"
                    class="bg-white text-black"
                    icon="receipt"
                    size="12px"
                  />
                  <q-btn
                    v-if="props.row.status !== 'Cancelado' && props.row.status !== 'Liquidado'"
                    @click="enviarEmail(props.row)"
                    class="bg-secondary text-white"
                    icon="mail"
                    size="12px"
                    :disable="props.row.gateway ? !props.row.gateway.pode_enviar : true"
                  />
                  <q-btn
                    v-if="props.row.status !== 'Cancelado' && !props.row.liquidado"
                    @click="liquidar(props.row)"
                    class="bg-green-7 text-white"
                    icon="attach_money"
                    size="12px"
                  />
                  <q-btn
                    @click="detalhar(props.row)"
                    class="bg-primary text-white"
                    icon="info"
                    size="12px"
                  />
                  <q-btn
                    v-if="props.row.status === 'Liquidado'"
                    @click="estornar(props.row)"
                    class="bg-secondary text-white"
                    icon="reply"
                    size="12px"
                  />
                  <q-btn
                    v-if="props.row.status === 'Cancelado'"
                    @click="reativa(props.row)"
                    class="bg-secondary text-white"
                    icon="reply"
                    size="12px"
                  />
                  <q-btn
                    @click="del(props.row)"
                    v-if="props.row.status === 'Aberto'"
                    class="bg-negative text-white"
                    icon="delete_forever"
                    size="12px"
                  />
                </div>
              </q-td>
            </q-tr>
          </template>
          <template v-slot:bottom-row>
            <q-tr style="background-color: #F7F7F7">
              <q-td> </q-td>
              <q-td> </q-td>
              <q-td> </q-td>
              <q-td class="text-right">
                <strong>R$ {{ total_valores.total_valor | currency }}</strong>
              </q-td>
              <q-td class="text-right">
              </q-td>
              <q-td key="valor_liquidado" class="text-right">
                <strong
                  >R$
                  {{ total_valores.total_valor_liquidado | currency }}</strong
                >
              </q-td>
              <q-td class="tdfora"> </q-td>
            </q-tr>
          </template>
        </q-table>
        <div class="text-center">
          <q-btn
            flat
            clickable
            @click="$router.push('/receitas?cadastro_id=' + $route.params.id)"
            no-wrap
            v-ripple
            no-caps
            label="Ir para cobranças"
            class="bg-blue-7 text-white"
          >
          </q-btn>
          <q-btn
            no-caps
            no-wrap
            v-ripple
            clickable
            color="blue-7"
            class="q-ml-md"
            label="Ir para extrato do cliente"
            @click="$router.push(`/extrato-cliente?cadastro_id=${$route.params.id}`)"
            v-show="extratoClienteInstalado"
          >
          </q-btn>
        </div>
      </q-card-section>
    </q-card>
    <q-dialog v-model="OpenDialogChangeGateways" persistent>
      <q-card style="width: 600px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Alterar Forma de Pagamento - {{muveconta.id}}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup clickable @click="closeDialogChangeGateways()" />
        </q-card-section>
        <q-separator />

        <q-card-section class="q-pb-none">
          <q-list>
            <q-item class="q-pl-none q-pr-md">
              <q-item-section >
                <q-item-label>
                  <p>Vencimento: <b>{{new Date(muveconta.vencimento) | formatDate}}</b></p>
                  <p>Valor: <b>R$ {{muveconta.valor | currency}}</b></p>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-section class="q-pt-none q-pb-none">
          <div class="row">
            <div class="col-12">
              <q-select
                v-model="muveconta2.gateway_id"
                :options="gateways"
                label="Nova Forma de Pagamento"
                emit-value
                map-options
                option-label="titulo"
                option-value="id"
                clearable
                bottom-slots
                :error="Boolean(errors.gateway_id)"
              >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.gateway_id"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
              </q-select>
            </div>

            <div class="col-12 q-pb-md">
              <q-checkbox v-model="alterarVencimentoValor" label="Alterar vencimento e valor" color="green" />
            </div>

            <div class="col-12" v-if="alterarVencimentoValor">
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-input
                    bottom-slots
                    :error="errors.vencimento ? true : false"
                    v-model="muveconta2.vencimento"
                    label="Novo Vencimento"
                    @click="$refs.qDateProxyVencimento.show()"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer" @click.stop="$refs.qDateProxyVencimento.show()">
                        <q-popup-proxy
                          ref="qDateProxyVencimento"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="muveconta2.vencimento"
                            @input="() => {changeVencimento(); $refs.qDateProxyVencimento.hide();}"
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            minimal
                            today-btn
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.vencimento" :key="error + i">{{error}}</span>
                    </template>
                  </q-input>
                </div>
                <div class="col-6">
                  <q-field
                    v-model="muveconta2.valor"
                    label="Novo Valor"
                    bottom-slots
                    :error="errors.valor ? true : false"
                  >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.valor" :key="error + i">{{error}}</span>
                    </template>
                  </q-field>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <p>Multa: <b>R$ {{muveconta.valor | currency}} * {{valorMulta}}% = R$ {{(muveconta.valor * (valorMulta/100)) | currency}}</b></p>
                  <p>Juros Mensal: <b>( R$ {{muveconta.valor | currency}} * ( {{valorJuros}}% / 30 dias ) ) * {{quantidadeDias}} dias = R$ {{((muveconta.valor * ((valorJuros/100)/30)) * quantidadeDias) | currency}}</b></p>
                  <p>Dias de atraso: <b>{{quantidadeDias}}</b></p>
                </div>
              </div>
            </div>

            <div class="col-12 q-pb-md">
              <q-checkbox v-if="alterarVencimentoValor && muveconta.gateway_id"
                color="green"
                label="Personalizar multa e juros"
                v-model="personalizarMultaJuros"
              />
            </div>

            <div class="col-12" v-if="personalizarMultaJuros && (muveconta.gateway_id || muveconta2.gateway_id)">
              <div class="row q-col-gutter-lg">
                <div class="col-6">
                  <q-field
                    v-model="fatura.multa"
                    label="Multa"
                    bottom-slots
                    :error="Boolean(errors.fatura_multa)"
                    @change="calcularNovoValor"
                  >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="percentFormat"
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.fatura_multa"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>

                <div class="col-6">
                  <q-field
                    v-model="fatura.juros"
                    label="Juros ao mês"
                    bottom-slots
                    :error="Boolean(errors.fatura_juros)"
                    @change="calcularNovoValor"
                  >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="percentFormat"
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.fatura_juros"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>
              </div>
            </div>

            <div class="col-12 q-pb-md">
              <q-checkbox v-if="alterarVencimentoValor && muveconta.gateway_id"
                color="green"
                label="Protestar"
                v-model="protestar"
              >
              <q-btn icon="help_outline" flat dense round>
                <q-tooltip>Disponível para o Gateway da Caixa Econômica</q-tooltip>
              </q-btn>
              </q-checkbox>
            </div>

            <div class="col-12" v-if="protestar">
                <q-input
                  v-model="fatura.protesto"
                  label="Protestar x dias após o vencimento"
                  mask="##"
                  bottom-slots
                  :error="Boolean(errors.fatura_protesto)"
                  >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.fatura_protesto"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
            </div>

            <div v-if="alterarVencimentoValor" class="col-12 q-mb-md">
              <q-checkbox v-model="alterarDescontoPagamentoAntecipado" label="Aplicar desconto para pagamento antecipado" color="green" />
                <q-btn icon="help_outline" flat dense round>
                <q-tooltip>Disponível para o Gateway 1</q-tooltip>
              </q-btn>
            </div>

            <div class="col-12" v-if="alterarDescontoPagamentoAntecipado">
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-input
                    bottom-slots
                    :error="errors.data_limite_desconto ? true : false"
                    v-model="muveconta2.data_limite_desconto"
                    label="Data Limite Desconto"
                    @focus="$refs.qDateProxyDesconto.show();"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer" @click="$refs.qDateProxyDesconto.show();"></q-icon>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.data_limite_desconto" :key="error + i">{{error}}</span>
                    </template>
                  </q-input>
                  <q-popup-proxy
                    ref="qDateProxyDesconto"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="muveconta2.data_limite_desconto"
                      @input="() => {$refs.qDateProxyDesconto.hide();}"
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      minimal
                      today-btn
                    />
                  </q-popup-proxy>
                </div>
                <div class="col-6">
                  <q-field
                    v-model="muveconta2.valor_desconto"
                    label="Novo Valor de Desconto"
                    bottom-slots
                    :error="errors.valor_desconto ? true : false"
                  >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                      />
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.valor_desconto" :key="error + i">{{error}}</span>
                    </template>
                  </q-field>
                </div>
              </div>
            </div>

          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveNewGateway()"
            :loading="loadGateway"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="OpenDialogOpcoesNotasFiscais" persistent>
      <q-card style="width: 90%; max-width: 400px;">
        <q-card-section class="row items-center">
          <div class="text-h6">Opções de Notas Fiscais</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-lg">

            <div class="col-12">
              <q-select
                v-model="opcoesNotasFiscais.gerar_nfse"
                label="Gerar nota"
                :options="opcoesGerarNota"
                emit-value
                map-options
              />
            </div>

            <div class="col-12">
              <q-select
                v-model="opcoesNotasFiscais.iss_retido"
                label="ISS Retido"
                :options="opcoesIssRetido"
                emit-value
                map-options
              />
            </div>

          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            label="Salvar"
            clickable
            v-ripple
            @click="salvarOpcoesNotasFiscais"
            :loading="loadingOpcoesNotasFiscais"
            color="green"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";
import { Money } from "v-money";
import { bus } from "@/main";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "cobrancas",
  emits: ['changeTipo'],
  components: { Money },
  props: {
    liquidarCallback: Boolean,
  },
  data() {
    return {
      total_valores: {},
      OpenDialogChangeGateways: false,
      createMenuStatus: [
        { text: "Aberto" },
        { text: "Liquidado" },
        { text: "Cancelado" }
      ],
      pagination: {
        sortBy: "vencimento",
        descending: true,
        page: 1,
        rowsPerPage: 0
      },
      qcobrancas: false,
      status: "Aberto",
      filtros: {
        status: "Aberto"
      },
      buscando: false,
      cobrancas: [],
      cobrancaSuccess: false,
      movconta: {},
      gateways: [],
      loadNovo: false,
      errors: [],
      items: [],
      buscandoItems: false,
      time: [],
      pagingTime: {
        page: 0,
        count: 0,
        prevPage: false,
        nextPage: true,
        pageCount: 0
      },
      btnDisabled: false,
      icones: {
        table: "list",
        key: "vpn_key",
        upload: "cloud_upload",
        trash: "delete_forever",
        envelope: "mail_outline",
        plus: "add",
        pencil: "mode_edit",
        money: "monetization_on",
        print: "print",
        file: "description"
      },
      movPraDet: [],
      url_boleto: "",
      loadGateway: false,
      alterarVencimentoValor: false,
      alterarDescontoPagamentoAntecipado: false,
      personalizarMultaJuros: false,
      protestar:false,
      quantidadeDias: 0,
      fatura: {
        protesto: 0,
        multa: 0.00,
        juros: 0.00,
      },
      muveconta: {},
      muveconta2: {},
      loading: false,
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false,
      },
      OpenDialogOpcoesNotasFiscais: false,
      loadingOpcoesNotasFiscais: false,
      opcoesGerarNota: [
        {
          value: 0,
          label: 'Desativado',
        },
        {
          value: 3,
          label: 'Padrão do Aplicativo de Notas',
        },
        {
          value: 1,
          label: 'Ao Inserir uma Cobrança',
        },
        {
          value: 2,
          label: 'Ao Liquidar uma Cobrança',
        },
      ],
      opcoesIssRetido: [
        {
          value: 0,
          label: 'Não',
        },
        {
          value: 1,
          label: 'Sim',
        },
      ],
      opcoesNotasFiscais: {},
    };
  },
  watch: {
    qcobrancas(value) {
      if (value) {
        this.listar();
      }
    },
    'liquidarCallback' : function(val, oldVal){
      if (val) {
        this.listar();
      }
    },
    'fatura.juros' : function(val, oldVal){
      if (val){
        this.calcularNovoValor();
      }
    },
    'fatura.multa' : function(val, oldVal){
      if (val){
        this.calcularNovoValor();
      }
    },
  },
  methods: {
    novo() {
      this.findGateways();
      this.$store.commit("cadastroReceita", {
        isCadastro: true,
        cadastro: {
          cadastro_id: this.cadastro.id,
          cadastro_nome: this.cadastro.nome
        }
      });
      this.$store.dispatch("modalReceitas");
      this.$store.dispatch("contas");
    },
    enviarEmail(value) {
      value.cadastro = this.cadastroView;
      this.$store.dispatch("enviarEmailReceitas", value);
    },
    detalhar(movconta) {
      this.$store.commit('receitaQrcode', movconta.id);
      this.$store.dispatch("detalharReceitas", movconta);
    },
    estornar(movconta) {
      Dialog.create({
        title: "Confirma o estorno da liquidação?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          movconta.action = "estornar";
          axios
            .post("/financeiro/edit/" + movconta.id, movconta)
            .then(res => {
              if (!res.data.success) {
                Dialog.create({
                  title: res.data.message || res.data.errors,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
                this.listar();
                return;
              }

              Dialog.create({
                title: "Lançamento " + res.data.movconta.id + " estornado!",
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              this.cobrancas.splice(this.cobrancas.indexOf(movconta), 1);
              this.listar();
            })
            .catch(() => {
              Dialog.create({
                title: "Não foi possível estornar, ou não possui permissão!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
      // this.$store.dispatch("estornarReceitas", movconta, true);
    },
    reativa(movconta) {
      Dialog.create({
        title: "Confirma a reativação da receita?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          movconta.action = "reativar";
          axios
            .post("/financeiro/edit/" + movconta.id, movconta)
            .then(res => {
              if (res.data.status) {
                Dialog.create({
                  title: "Lançamento " + res.data.movconta.id + " reativado!",
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
                this.cobrancas.splice(this.cobrancas.indexOf(movconta), 1);
                this.listar();
              } else {
                Dialog.create({
                  title: res.data.errors,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
                this.listar();
              }
            })
            .catch(() => {
              Dialog.create({
                title: "Não foi possível reativar, ou não possui permissão!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    del(movconta) {
      Dialog.create({
        title: "Tem certeza que deseja cancelar esta receita?",
        message: "Descreva o Motivo de Cancelamento",
        cancel: "Cancelar",
        persistent: true,
        prompt: {
          model: '',
          isValid: val => val.length > 2,
        },
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk((data) => {
          movconta.motivo = data;
          axios
            .post("/financeiro/delete/" + movconta.id, movconta)
            .then(res => {
              this.listar();
              if (!res.data.success) {
                Dialog.create({
                  title: res.data.msg,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
                return;
              }

              Dialog.create({
                title: res.data.msg,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              this.listar();
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    saveNewGateway() {
      this.loadGateway = true;
      let movconta = {
        id : this.muveconta2.id,
        action: "alterar-forma-pagamento-receita",
        alterar_vencimento: this.alterarVencimentoValor,
        gateway_id: this.muveconta2.gateway_id,
        valor: this.muveconta2.valor,
        vencimento: this.muveconta2.vencimento,
        valor_desconto: this.muveconta2.valor_desconto,
        data_limite_desconto: this.muveconta2.data_limite_desconto
      };

      if (this.personalizarMultaJuros) {
        movconta.personalizar_multa_juros = true;
        movconta.fatura_multa = this.fatura.multa;
        movconta.fatura_juros = this.fatura.juros;
        movconta.fatura_protesto = this.fatura.protesto;
      }

      axios
        .post("/financeiro/edit/" + movconta.id, movconta)
        .then(res => {
          if (res.data.status) {
            Notify.create({
              message: "Lançamento " + res.data.movconta.id + " alterado!",
              color: "green",
              position: "top-right"
            });

            this.loadGateway = false;
            this.closeDialogChangeGateways();

            this.listar();
          } else {
            this.errors = res.data.errors;
            this.loadGateway = false;
          }
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível trazer as formas de pagamento, ou não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.loadGateway = false;
        });
    },
    changeGateways(value) {
      this.alterarVencimentoValor = false;
      this.alterarDescontoPagamentoAntecipado = false;
      this.personalizarMultaJuros = false;
      this.protestar = false;
      this.quantidadeDias = 0;
      this.fatura = {
        protesto: 0,
        multa: 0.00,
        juros: 0.00,
      };
      this.muveconta = JSON.parse(JSON.stringify(value));
      this.muveconta2 = JSON.parse(JSON.stringify(value));
      this.muveconta2.vencimento = "";
      this.findGateways();
      this.OpenDialogChangeGateways = true;
    },
    changeVencimento() {
      const novoVencimento = moment(this.muveconta2.vencimento, 'DD/MM/YYYY');
      if (!novoVencimento.isValid()) return;

      this.quantidadeDias = novoVencimento.diff(moment(this.muveconta.vencimento), "days");
      this.calcularNovoValor();
    },
    calcularNovoValor() {
      let valor = this.muveconta.valor;

      if (this.valorMulta && this.quantidadeDias > 0) {
        valor += valor * this.valorMulta / 100;
      }

      if (this.valorJuros && this.quantidadeDias > 0){
        valor += this.muveconta.valor * ((this.valorJuros / 100) / 30) * this.quantidadeDias;
      }

      this.muveconta2.valor = valor;
    },
    linkBoleto(cobranca) {
      window.open(this.url_boleto + cobranca.short_url, "_blank");
    },
    linkDetalhe(cobranca) {
      window.open(this.url_detalhe + cobranca.short_url, "_blank");
    },
    newMovconta() {
      return {
        id: null,
        tipo: 2,
        vencimento: null,
        liquidacao: null,
        conta_id: null,
        descricao: null,
        valor: 0.0,
        valor_liquidado: 0.0,
        valor_acrescimo_desconto: 0.0,
        cadastro_id: this.id,
        cadastro_nome: this.nome,
        nomenclatura_id: null,
        liquidado: false,
        conciliado: false,
        gateway_id: null,
        cadastros_gateways_id: null,
        fatura_id: null,
        shortUrl: null,
        status: "Aberto"
      };
    },
    listar() {
      if (this.buscando) {
        return;
      }
      this.buscando = true;
      this.total_valores = {
        total_valor: 0,
        total_valor_liquidado: 0
      };
      this.cobrancaSuccess = false;
      this.cobrancas = [];
      axios
        .get("/cadastros/cobrancas/" + this.$route.params.id, null)
        .then(res => {
          if (res.data.success) {
            this.cobrancaSuccess = res.data.success;
            this.cobrancas = res.data.data.cobrancas;
            this.url_boleto = res.data.data.url_boleto;
            this.url_detalhe = res.data.data.url_detalhe;
            this.total_valores = res.data.data.total_valores;
            this.buscando = false;
          }
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível trazer as formas de pagamento, ou não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.buscando = false;
        });
    },
    changeFilterStatus(status) {
      if (this.buscando) {
        return;
      }
      this.status = status;
      this.filtros.status = status;
      this.listar();
    },
    liquidar(movconta) {
      this.$emit('changeTipo', 'receita');
      this.$store.dispatch("liquidarReceitas", movconta);
    },
    findGateways() {
      axios
        .get("/gateways?ativo=true&gateways_opts=true")
        .then(res => {
          this.gateways = res.data.gateways;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível trazer as formas de pagamento, ou não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    closeDialogChangeGateways() {
      this.OpenDialogChangeGateways = false;
      this.alterarVencimentoValor = false;
      this.errors = [];
    },
    openOpcoesNotasFiscais() {
      this.OpenDialogOpcoesNotasFiscais = true;
      this.opcoesNotasFiscais = {
        gerar_nfse: this.cadastroView.gerar_nfse,
        iss_retido: this.cadastroView.iss_retido,
      };
      this.errorsOpcoesNotasFiscais = {};
    },
    closeOpcoesNotasFiscais() {
      this.OpenDialogOpcoesNotasFiscais = false;
    },
    salvarOpcoesNotasFiscais() {
      this.loadingOpcoesNotasFiscais = true;

      const data = JSON.parse(JSON.stringify(this.opcoesNotasFiscais));
      data.action = 'opcoes_notas_fiscais';

      axios
        .post(`/cadastros/edit/${this.cadastroView.id}`, data)
        .then(res => {
          this.loadingOpcoesNotasFiscais = false;

          this.$q.notify({
            color: res.data.success ? 'green' : 'orange',
            message: res.data.message,
            position: 'top-right',
          });

          if (!res.data.success) {
            this.errorsOpcoesNotasFiscais = res.data.errors;
            return;
          }

          this.cadastroView.gerar_nfse = this.opcoesNotasFiscais.gerar_nfse;
          this.cadastroView.iss_retido = this.opcoesNotasFiscais.iss_retido;
          this.closeOpcoesNotasFiscais();
        })
        .catch(() => {
          this.loadingOpcoesNotasFiscais = false;

          this.$q.notify({
            color: 'red',
            message: 'Houve um erro ao editar as opções de notas fiscais',
            position: 'top-right',
          });
        });
    },
  },
  computed: {
    ...mapGetters([
      "cadastroView",
      "extratoClienteInstalado",
    ]),
    cadastro() {
      return this.$store.getters.cadastroView;
    },
    cadastroReceita() {
      return this.$store.getters.cadastroReceita;
    },
    cadastroId() {
      return this.$route.params.id;
    },
    columns() {
      let data = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "descricao",
          align: "center",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true
        },
        {
          name: "vencimento",
          label: "Vencimento",
          field: row => row.vencimento,
          sortable: true,
          align: "center"
        },
        {
          name: "valor",
          label: "Valor",
          field: row => row.valor,
          align: "center",
          sortable: true
        },
        {
          name: "liquidacao",
          label: "Liquidação",
          field: row => row.liquidacao,
          align: "center",
          sortable: true
        },
        {
          name: "valor_liquidado",
          label: "Valor Liquidado",
          field: row => row.valor_liquidado,
          align: "center",
          sortable: true
        },
        { name: 'tdfora' },
      ];

      return data;
    },
    gatewaySelecionado() {
      if (!this.gateways || !this.gateways || !this.muveconta2) return null;

      const gateway = this.gateways.find(
        gateway => gateway.id == this.muveconta2.gateway_id
      );

      return gateway;
    },
    valorMulta: {
      get() {
        let multa = 0.00;

        if (this.personalizarMultaJuros){
          multa = this.fatura.multa;
          return Number(multa) || 0;
        }

        if (!this.gatewaySelecionado || !this.gatewaySelecionado.gateways_opts) return 0;

        multa = this.gatewaySelecionado.gateways_opts.find(gatewayOpt => gatewayOpt.nome === "multa");

        if (multa && multa.valor){
          return Number(multa.valor);
        }

        return 0;
      },
      set(value) {},
    },
    valorJuros: {
      get() {

        let juros = 0.00;
        if (this.personalizarMultaJuros){
          juros = this.fatura.juros;
          return Number(juros) || 0;
        }

        if (!this.gatewaySelecionado || !this.gatewaySelecionado.gateways_opts) return 0;

        juros = this.gatewaySelecionado.gateways_opts.find(gatewayOpt => gatewayOpt.nome === "juros");

        if (juros && juros.valor){
          return Number(juros.valor);
        }

        return 0;
      },
      set(value) {},
    },

  },
  created() {
    bus.$on("listarReceitas", () => {
      this.listar();
    });
  },
  destroyed() {
    bus.$off("listarReceitas");
  }
};
</script>

<style lang="stylus" scoped>
.text-h6, .text-subtitle2 {
  font-weight: 300;
}

.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.active_status {
  background-color: #ff9c00 !important;
  color: white !important;
}

p {
  margin-bottom: 0;
}

</style>
