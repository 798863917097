<template>
  <div id="modalAdicionarLicenca">
    <q-dialog v-model="OpenDialogAdicionarLicenca" persistent>
      <q-card style="width:600px">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6" v-if="isNew">Nova Licença</div>
          <div class="text-h6" v-if="!isNew">Editar Licença</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />

        <q-card-section v-if="licencaEdit && licencaEdit.cadastro">
          <q-list>
            <q-item>
              <q-item-section>
                <q-item-label caption>Código</q-item-label>
                <q-item-label>{{ licencaEdit.cadastro.codigo }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>Nome</q-item-label>
                <q-item-label>{{ licencaEdit.cadastro.nome }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>Razão</q-item-label>
                <q-item-label>{{ licencaEdit.cadastro.razao }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>CPF/CNPJ</q-item-label>
                <q-item-label>{{ licencaEdit.cadastro.cpfcnpj }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>UF</q-item-label>
                <q-item-label>{{ licencaEdit.cadastro.estado }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>E-mail</q-item-label>
                <q-item-label
                  v-html="breakLineBar(licencaEdit.cadastro.email)"
                ></q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label caption>Telefone</q-item-label>
                <q-item-label
                  v-html="breakLineBar(licencaEdit.cadastro.telefone)"
                ></q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-if="!isNew">
              <q-item-section>
                <q-item-label caption>Serviço</q-item-label>
                <q-item-label>{{ licencaEdit.servico.nome }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12" style="padding-left: 6px">
                <q-checkbox
                  v-model="licenca.ativo"
                  label="Ativo"
                  color="green"
                />
              </div>
              <div v-if="isNew" class="col-12 col-md-12">
                <autocomplete
                  title="Nome cliente"
                  :filter="filterFn"
                  :carregando="carregando"
                  :options="options"
                  :formatDisplay="formatDisplay"
                  :create="createValue"
                  @resultAutocomplete="resultEmitRecebe"
                  :display="displayMontar"
                  :cadastro="abreCadastro"
                  :newCadastro="cadastroReturn"
                />
              </div>
              <div v-if="isNew" class="col-12 col-md-6">
                <q-select
                  v-model="servico_id"
                  map-options
                  emit-value
                  option-label="nome"
                  option-value="id"
                  :options="servicos"
                  label="Serviço"
                  bottom-slots
                  :error="errorsLicencas.servico_id ? true : false"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsLicencas.servico_id"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
              <div v-if="isNew" class="col-12 col-md-6">
                <q-select
                  v-model="licenca.version_id"
                  option-label="numero"
                  option-value="id"
                  map-options
                  emit-value
                  :options="versoes"
                  label="Versão"
                  bottom-slots
                  :error="errorsLicencas.version_id ? true : false"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsLicencas.version_id"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-6">
                <q-select
                  v-model="licenca.tipo"
                  :options="tipoLicencas"
                  label="Tipo"
                  bottom-slots
                  :error="errorsLicencas.tipo ? true : false"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsLicencas.tipo"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="licenca.plano"
                  label="Plano"
                  bottom-slots
                  :error="errorsLicencas.plano ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsLicencas.plano"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  bottom-slots
                  :error="errorsLicencas.bloquear_em ? true : false"
                  v-model="licenca.bloquear_em"
                  label="Bloquear Em"
                  @click="$refs.qDateProxy.show()"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="licenca.bloquear_em"
                          @input="() => $refs.qDateProxy.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsLicencas.bloquear_em"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="licenca.descricao"
                  label="Descrição"
                  bottom-slots
                  :error="errorsLicencas.descricao ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsLicencas.descricao"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="licenca.codigo_uuid"
                  label="UUID"
                  bottom-slots
                  :error="errorsLicencas.codigo_uuid ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsLicencas.codigo_uuid"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="adicionarLicenca"
            :loading="aguardeLicencas"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { bus } from "@/main";
import { Notify } from "quasar";
const moment = require("moment");
export default {
  name: "modal-adicionar-licenca",
  props: ["isNew"],
  data() {
    return {
      cadastro_id: null,
      cadastro_nome: null,
      licSalvo: false,
      group: {},
      servico_id: null,
      // autocomplete
      carregando: false,
      options: [],
      selectItem: null,
      autocompleteResult: {}
    };
  },
  watch: {
    servico_id(id) {
      if (id) {
        this.versoes = [];
        this.$store.dispatch("versoes", id);
      }
    }
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogAdicionarLicenca", false);
      this.$store.dispatch("editLicencaClear");
      this.$store.dispatch("errorsLicencasClear");
      this.$store.dispatch("newLicenca");
    },
    breakLineBar(value) {
      if (!value) return "";
      return value.replace(/,/g, "<br>").replace("/", "<br>");
    },
    async adicionarLicenca() {
      if (this.isNew) {
        if (this.autocompleteResult.id) {
          this.licenca.cadastro_id = this.autocompleteResult.id;
          this.licenca.cadastro_nome = this.autocompleteResult.name;
        } else {
          let cadastro = {
            nome: this.autocompleteResult.label,
            cliente: true
          };
          await axios
            .post("/cadastros/add", cadastro)
            .then(res => {
              if (res.data.success) {
                this.licenca.cadastro_id = res.data.data.cadastro.id;
                this.licenca.cadastro_nome = res.data.data.cadastro.nome;
              } else {
                Notify.create({
                  message: res.data.message,
                  color: "orange",
                  position: "top-right"
                });
              }
            })
            .catch(() => {
              Notify.create({
                message:
                  "Não foi possível contactar, ou você não possui permissão!",
                color: "red",
                position: "top-right"
              });
            });
        }
        this.licenca.servico_id = this.servico_id;
        this.$store.dispatch("adicionarLicenca", this.licenca);
      } else {
        this.$store.dispatch("editarLicenca", this.licenca);
      }
    },
    getTitle() {
      return this.isNew ? "Nova Licença" : "Editar Licença";
    },
    // Funções do autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/cadastros/busca?term=" + val).then(res => {
          this.carregando = false;
          this.options = res.data;
        });
      });
    },
    createValue(val, done) {
      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
    },
    formatDisplay(result) {
      return result.label;
    },
    resultEmitRecebe(result) {
      this.autocompleteResult = result;
      this.$store.commit("cadastroReturn", null);

      if (this.autocompleteResult && this.autocompleteResult.id != null) {
        Notify.create({
          message: this.autocompleteResult.name + " foi selecionado.",
          color: "green",
          position: "top-right"
        });
      }
    },
    displayMontar(value) {
      return !value ? value : value.name;
    },
    abreCadastro(value) {
      this.$store.dispatch("cadastro", {
        nome: value.name,
        noSearch: true
      });
    }
  },
  computed: {
    ...mapGetters([
      "licenca",
      "errorsLicencas",
      "tipoLicencas",
      "licencaEdit",
      "servicos",
      "aguardeLicencas",
      "cadastroReturn"
    ]),
    versoes: {
      get() {
        return this.$store.getters.versoes;
      },
      set() {}
    },
    OpenDialogAdicionarLicenca: {
      get() {
        return this.$store.getters.OpenDialogAdicionarLicenca;
      },
      set() {}
    }
  }
};
</script>

<style lang="stylus" scoped></style>
