<template>
  <div>
    <q-dialog v-model="openLogsModal" persistent>
      <q-card :style="`width: 100%; max-width: ${showResponseData ? '1280' : '720'}px;`">
        <q-card-section class="row items-center justify-center">
          <div class="text-h6">Webhooks Recebidos</div>
          <q-space />

          <q-btn-group class="q-ml-md" rounded flat no-wrap no-caps>
            <q-btn
              class="white text-blue-7"
              clickable
              padding="sm"
              @click="changeFilterPeriodo('prev')"
              rounded
              icon="chevron_left"
            />

            <q-btn
              class="white text-blue-7"
              rounded
              :label="dtini"
              padding="sm"
            >
              <template v-slot:default>
                <q-popup-proxy ref="qDateDtini" transition-show="scale" transition-hide="scale">
                  <q-date
                    mask="DD/MM/YYYY"
                    minimal
                    today-btn
                    v-model="filtros.dtini"
                    @input="value => alterarDataInicio(value)"
                  />
                </q-popup-proxy>
              </template>
            </q-btn>

            <q-btn
              class="white text-blue-7"
              rounded
              :label="dtfim"
              padding="sm"
            >
              <template v-slot:default>
                <q-popup-proxy ref="qDateDtfim" transition-show="scale" transition-hide="scale">
                  <q-date
                    mask="DD/MM/YYYY"
                    minimal
                    today-btn
                    v-model="filtros.dtfim"
                    @input="value => alterarDataFim(value)"
                  />
                </q-popup-proxy>
              </template>
            </q-btn>

            <q-btn
              class="white text-blue-7"
              clickable
              padding="sm"
              @click="changeFilterPeriodo('next')"
              rounded
              icon="chevron_right"
            />
          </q-btn-group>

          <!-- Filtro Tipo -->
          <q-btn
            :label="filtrosTipo[filtros.tipo]"
            color="primary"
            flat
            class="q-mr-lg"
          >
            <q-menu>
              <q-list class="text-gray-8">
                <q-item
                  v-for="(label, key) in filtrosTipo"
                  :key="label"
                  clickable
                  v-close-popup
                  @click="changeFilterType(key)"
                >
                  <q-item-section>
                    {{ label }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>

        <q-separator />

        <q-card-section class="row column">
          <div class="row q-col-gutter-md">
            <div :class="showResponseData ? 'col-6' : 'col-12'">
              <q-table
                flat
                row-key="id"
                :data="webhookLogs"
                :columns="columns"
                :loading="searching"
                :separator="'horizontal'"
                :rows-per-page-options="[0]"
                no-data-label="Nenhum resultado encontrado."
              >
                <template v-slot:bottom></template>

                <template v-slot:body="props">
                  <q-tr :props="props" :style="getRowStyle(props.row)" @click="inspectWebhook(props.row)">
                    <q-td key="created">
                      {{formatDateTime(props.row.created)}}
                    </q-td>

                    <q-td key="status" class="text-center">
                      {{props.row.status == 1 ? 'Sucesso' : 'Falha'}}
                    </q-td>

                    <q-td key="request_data">
                      <span v-if="props.row.request_data" class="cursor-pointer" >
                        {{props.row.request_data.substr(0, 30) + '...'}}
                      </span>
                      <span v-else>Sem Dados</span>
                    </q-td>

                    <q-td key="response_data">
                      <span v-if="props.row.response_data" class="cursor-pointer">
                        {{props.row.response_data.substr(0, 30) + '...'}}
                      </span>
                      <span v-else>Sem Dados</span>
                    </q-td>
                  </q-tr>
                </template>
              </q-table>
            </div>

            <div v-if="showResponseData && webhookInspected" class="col-6">
              <q-btn icon="close" flat round dense v-close-popup @click="closeWebhookInspect()" />
              <q-card-section>
              <div class="text-h5">Headers</div>
              <pre style="white-space: pre-wrap;">{{webhookInspected.request_headers}}</pre>
              </q-card-section>
              <q-card-section>
                <div class="text-h5">Dados</div>
                <pre style="white-space: pre-wrap;">{{webhookInspected.request_data}}</pre>
              </q-card-section>
              <q-card-section>
                <div class="text-h5">Resposta</div>
                <pre style="white-space: pre-wrap;">{{webhookInspected.response_data}}</pre>
              </q-card-section>
            </div>
          </div>
          <q-btn
            label="mostrar mais"
            color="primary"
            class="q-ml-auto bg-primary text-white"
            flat
            :disable="disableBtn"
            @click="changePagination"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import axios from '@/axios-auth';

export default {
  data: () => ({
    openLogsModal: true,
    showResponseData: false,
    searching: false,
    webhookInspected: [],
    disableBtn: false,
    dtini: '',
    dtfim: '',
    filtros: {
      tipo: 'todos',
      dtini: '',
      dtfim: '',
      page: 1
    },
    filtrosTipo: {
      todos: 'Todos',
      piperun: 'Piperun',
      hubspot: 'Hubspot',
      movidesk: 'Movidesk',
      zenvia: 'Zenvia',
      cofreinteligente: "Cofre Inteligente"
    },
  }),
  props: ['webhookLogs'],
  methods: {
    list(pagination) {
      if (this.searching) return;
      if (!pagination) this.filtros.page = 1;
      this.searching = true;
      this.disableBtn = false;

      const params = JSON.parse(JSON.stringify(this.filtros));
      axios
        .get('/webhooks/logs', { params })
        .then(res => {
          if (res.data.success) {
            if (res.data.data.length == 0) {
              this.disableBtn = true;
            }

            if (pagination) {
              this.webhookLogs = this.webhookLogs.concat(Object.values(res.data.data));
            } else {
              this.webhookLogs = Object.values(res.data.data);
            }
            this.searching = false;
          }
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os logs dos webhooks.',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    alterarDataInicio(value) {
      this.filtros.dtini = value.replace(/\//g, '-');
      this.dtini = value.replace(/\//g, '/');
      this.$refs.qDateDtini.hide();

      this.list();
    },
    alterarDataFim(value) {
      this.filtros.dtfim = value.replace(/\//g, '-');
      this.dtfim = value.replace(/\//g, '/');
      this.$refs.qDateDtfim.hide();

      this.list();
    },
    formatDateTime(value) {
      if (!value) return '';
      return moment(value).format('DD/MM/YYYY HH:mm') + 'h';
    },
    changeFilterPeriodo(periodo) {
      if (this.searching) return;

      let dtIni = moment(this.filtros.dtini, 'DD-MM-YYYY');
      let dtFim = moment(this.filtros.dtfim, 'DD-MM-YYYY');

      if (periodo === 'prev') {
        dtIni.subtract(1, 'months');
        dtFim.subtract(1, 'months');
      }

      if (periodo === 'next') {
        dtIni.add(1, 'months');
        dtFim.add(1, 'months');
      }

      this.filtros.dtini = dtIni.startOf('month').format('DD-MM-YYYY');
      this.filtros.dtfim = dtFim.endOf('month').format('DD-MM-YYYY');
      this.dtini = dtIni.startOf('month').format('DD/MM/YYYY');
      this.dtfim = dtFim.endOf('month').format('DD/MM/YYYY');
      this.filtros.page = 1;

      this.list();
    },
    inspectWebhook(data) {
      if (this.searching) return;

      this.webhookInspected = data;
      this.showResponseData = true;
    },
    closeWebhookInspect() {
      this.showResponseData = false;
      this.webhookInspected = null;
    },
    closeModal() {
      this.openLogsModal = false;
      this.showResponseData = false;
      this.$emit('closeModal');
    },
    changeFilterType(key) {
      if (this.searching) return;

      this.filtros.tipo = key;
      this.filtros.page = 1;
      this.list();
    },
    getFiltros() {
      this.filtros.dtini = moment().startOf('month').format('DD-MM-YYYY');
      this.filtros.dtfim = moment().endOf('month').format('DD-MM-YYYY');
      this.dtini = moment().startOf('month').format('DD/MM/YYYY');
      this.dtfim = moment().endOf('month').format('DD/MM/YYYY');
    },
    changePagination() {
      if (this.searching) return;

      this.filtros.page += 1;
      this.list('pagination');
    },
    getRowStyle(row) {
      if (!this.showResponseData || !this.webhookInspected) return "";

      if (row.id === this.webhookInspected.id) {
        return "background-color: #e6f7ff !important";
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          name: "created",
          required: true,
          label: "Criado",
          align: "left",
          field: row => this.formatDateTime(row.created),
          sortable: true,
        },
        {
          name: "status",
          required: true,
          label: "Status",
          align: "left",
          field: row => row.status,
          sortable: true,
        },
        {
          name: "request_data",
          required: true,
          label: "Dados",
          align: "left",
          field: row => row.request_data,
          sortable: true,
        },
        {
          name: "response_data",
          required: true,
          label: "Resposta",
          align: "left",
          field: row => row.response_data,
          sortable: true,
        }
      ];
    },
  },
  created() {
    this.getFiltros();
  }
};
</script>
