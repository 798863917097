<template>
  <div id="modalAlterarVencimento">
    <q-dialog v-model="OpenDialogAlterarVencimento" persistent>
      <q-card style="width: 400px">
        <q-card-section class="row items-center">
          <div class="text-h6">Alterar Data Vencimento</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
         <q-card-section>
            <q-banner dense class="bg-grey-3 q-mt-sm" >
              <template v-slot:avatar>
                <q-icon color="red" name="warning" />
              </template>
              Essa operação só deve ser efetuada antes de marcar e/ou gerar remessa. Caso já tenha gerado a remessa, NÃO REALIZE essa alteração!
            </q-banner>
          </q-card-section>
        <q-card-section>
            <div class="q-pa-sm">
              <q-input
                bottom-slots
                v-model="vencimento"
                label="Nova data de Vencimento"
                @click="$refs.qDateProxy.show()"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="vencimento"
                        mask="DD/MM/YYYY"
                       :locale="langDate"
                        minimal
                        today-btn
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
          </div>
          <div class="q-pa-md q-mb-md">
              <div>
                <div class="text-h6">{{isDespesa ? 'Confirma alteração no vencimento em massa das despesas?' : 'Confirma alteração no vencimento em massa das receitas?'}}</div>
                <span>Digite <b style="color: #EA4335;">"Compreendo os riscos"</b> para prosseguir.</span>
              </div>
              <div>
                <q-input
                  square
                  dense
                  v-model="textodigitado"
                  autofocus
                  @keyup.enter="prompt = false"
                />
              </div>
          </div>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  :loading="loading"
                  @click="enviaTroca()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";
const moment = require("moment");
export default {
  name: "modal-alterar-vencimento",
  data() {
    return {
      vencimento: moment().format("DD/MM/YYYY"),
      loading: false,
      textodigitado : null,
      prompt: false,
      isDespesa: this.$route.path != "/receitas" ? true : false
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("OpenDialogAlterarVencimento", false);
    },
    enviaTroca() {
      let dados = {};
      let url = "";
      if (this.$route.path === "/receitas") {
        url = "/financeiro/edit-multiple/receita";
        dados = {
          action: "alterar-vencimento",
          confirmar: 0,
          vencimento: this.vencimento,
          status: 'Aberto',
          ids: this.selectediesReceitas,
          all: this.allReceitas ? 1 : 0,
          dtini: this.filtrosReceitas.dtini,
          dtfim: this.filtrosReceitas.dtfim,
          status: this.filtrosReceitas.status,
          status_remessa: this.filtrosReceitas.status_remessa,
        };
      }
      if (this.$route.path === "/despesas") {
        url = "/financeiro/edit-multiple/despesa";
        dados = {
          action: "alterar-vencimento",
          confirmar: 0,
          vencimento: this.vencimento,
          status: 'Aberto',
          ids: this.selectediesDespesas,
          all: this.allDespesas ? 1 : 0,
          dtini: this.filtrosDespesas.dtini,
          dtfim: this.filtrosDespesas.dtfim,
          status: this.filtrosDespesas.status
        };
      }

      if(this.textodigitado != null){
          if(this.textodigitado.toUpperCase() === 'COMPREENDO OS RISCOS'){

          this.loading = true;
          this.textodigitado = null;

          axios.post(url, dados)
            .then(res => {
              if (res.data.success) {
                Dialog.create({
                title: res.data.message,
                cancel: "Cancelar",
                persistent: true
                })
                .onOk(() => {
                    Loading.show();
                })
                .onOk(() => {
                    dados.confirmar = 1;
                    axios
                    .post(url, dados)
                    .then(res => {
                        if (res.data.success) {
                        Dialog.create({
                            title: res.data.message,
                            class: "bg-positive text-white",
                            ok: {
                            "text-color": "green",
                            color: "white"
                            }
                        });

                        if(this.$route.name === 'despesas'){
                          this.$store.dispatch("resetPagingDespesas");
                          this.$store.dispatch("listarDespesas");
                        }else{
                          this.$store.dispatch("resetPagingReceitas");
                          this.$store.dispatch("listarReceitas");
                        }

                        // limpar checkbox selecionados
                        this.$store.dispatch("clearCheckbox");

                        this.closeModal();
                        } else {
                            Dialog.create({
                                title: res.data.message,
                                class: "bg-warning text-white",
                                ok: {
                                "text-color": "orange",
                                color: "white"
                                }
                            });
                        }
                    })
                    .catch(() => {
                        Dialog.create({
                        title:
                            "Não foi possível contactar, ou você não possui permissão!",
                        class: "bg-negative text-white",
                        ok: {
                            "text-color": "red",
                            color: "white"
                        }
                        });
                    });

                })
                .onCancel(() => {
                    Loading.hide();
                })
                .onDismiss(() => {
                    Loading.hide();
                });
              }
            })
            .catch(() => {
                Notify.create({
                    message:
                    "Não foi possível contactar, ou você não possui permissão!",
                    color: "red",
                    position: "top-right"
                });
            });
        } else{
             Dialog.create({
                title:"Não foi possível alterar vencimentos, confirmação inválida",
                class: "bg-warning text-white",
                ok: {
                    "text-color": "red",
                    color: "white"
                }
            });
        }
        this.loading = false;
      }else{
        Dialog.create({
          title:"Confirmação não foi informada.",
            class: "bg-warning text-white",
            ok: {
                "text-color": "red",
                color: "white"
            }
          });
        }
    }
  },
  computed: {
    OpenDialogAlterarVencimento: {
      get() {
        return this.$store.getters.OpenDialogAlterarVencimento;
      },
      set() {}
    },
    ...mapGetters([
      "contas",
      "selectediesReceitas",
      "allReceitas",
      "filtrosReceitas",
      "selectediesDespesas",
      "allDespesas",
      "filtrosDespesas"
    ])
  }
};
</script>

<style lang="stylus" scoped></style>
