import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";

const state = {
  loadingProdutos: false,
  produtosProdutos: [],
  produto: {
    importar_produtos: false,
    ativo: true,
    tipo: "P",
    nome: "",
    vlrcusto: 0.00,
    vlrvenda: 0.00,
    vlrlucro: 0.00,
    tpcontrole: "",
    ordem: "",
    estorno_id: null,
    tiposoma: "",
    categoria_id: null
  },
  filtrosProdutos: {
    tipo: "",
    page: 0
  },
  tipoControleProdutos: ["Nenhum", "Extração", "Concurso", "Evento"],
  tiposProdutos: [],
  pagingProdutos: {
    finder: "all",
    page: 0,
    current: 0,
    count: 0,
    perPage: 20,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  addRegraProdutos: {
    tipo: "",
    quando: "",
    dias: 0,
    tpvalor: "",
    valor: "",
    nomenclatura: "",
    conta_id: null
  },
  totalProdutos: 0,
  isNewProduto: true,
  saveProduto: false,
  errorsProdutos: [],
  acontarProdutos: [],
  tipoValorProdutos: [],
  saveRegra: false,
  isNewRegra: true,
  tiposSomaProdutos: [],
  isNewRegraProduto: true,
  categoriasProdutosDisp: [],
  tpcomissaoProdutos: ["%", "$"],
  tpcomissaofuncProdutos: ["%", "$"],
  tpnomenclaturaProdutos: [{ name: "Crédito", value: "C" }, { name: "Débito", value: "D" }],
  canImportProdutos: false,
  OpenDialogNovoProduto: false,
  OpenDialogNovaRegraProduto: false,
  tipoRegrasAcontarProdutos: [],
  tipoRegrasValorProdutos: [],
  tipoRegrasProdutos: [],
  openDialogProdutosTags: false,
  produtosTags: [],
  produtosAllTags: []
};

const mutations = {
  loadingProdutos: state => {
    state.loadingProdutos = !state.loadingProdutos;
  },
  produtosProdutos: (state, userData) => {
    state.produtosProdutos = userData;
  },
  produto: (state, userData) => {
    state.produto = userData;
  },
  filtrosProdutos: (state, userData) => {
    state.filtrosProdutos = userData;
  },
  tipoControleProdutos: (state, userData) => {
    state.tipoControleProdutos = userData;
  },
  tiposProdutos: (state, userData) => {
    state.tiposProdutos = userData;
  },
  tiposSomaProdutos: (state, userData) => {
    state.tiposSomaProdutos = userData;
  },
  pagingProdutos: (state, userData) => {
    state.pagingProdutos = userData;
  },
  addRegraProdutos: (state, userData) => {
    state.addRegraProdutos = userData;
  },
  totalProdutos: (state, userData) => {
    state.totalProdutos = userData;
  },
  isNewProduto: (state, userData) => {
    state.isNewProduto = userData;
  },
  saveProduto: state => {
    state.saveProduto = !state.saveProduto;
  },
  errorsProdutos: (state, userData) => {
    state.errorsProdutos = userData;
  },
  acontarProdutos: (state, userData) => {
    state.acontarProdutos = userData;
  },
  tipoValorProdutos: (state, userData) => {
    state.tipoValorProdutos = userData;
  },
  saveRegra: state => {
    state.saveRegra = !state.saveRegra;
  },
  isNewRegra: (state, userData) => {
    state.isNewRegra = userData;
  },
  isNewRegraProduto: (state, userData) => {
    state.isNewRegraProduto = userData;
  },
  categoriasProdutosDisp: (state, userData) => {
    state.categoriasProdutosDisp = userData;
  },
  canImportProdutos: (state, userData) => {
    state.canImportProdutos = userData;
  },
  OpenDialogNovoProduto: (state, userData) => {
    state.OpenDialogNovoProduto = userData;
  },
  OpenDialogNovaRegraProduto: (state, userData) => {
    state.OpenDialogNovaRegraProduto = userData;
  },
  tipoRegrasAcontarProdutos: (state, userData) => {
    state.tipoRegrasAcontarProdutos = userData;
  },
  tipoRegrasValorProdutos: (state, userData) => {
    state.tipoRegrasValorProdutos = userData;
  },
  tipoRegrasProdutos: (state, userData) => {
    state.tipoRegrasProdutos = userData;
  },
  openDialogProdutosTags: (state, userData) => {
    state.openDialogProdutosTags = userData;
  },
  produtosTags: (state, userData) => {
    state.produtosTags = userData;
  },
  produtosAllTags: (state, userData) => {
    state.produtosAllTags = userData;
  }
};

const actions = {
  atualizar: ({ state, commit }, produtoEdit) => {
    let produtos = [...state.produtosProdutos];

    let posicao = produtos.findIndex((produto) => {
      return produto.id === produtoEdit.id;
    });

    if (produtos[posicao]) {
      if (produtoEdit && produtoEdit['action'] === 'update') {
        produtos[posicao].loading = false;
        produtos[posicao] = produtoEdit;
      } else {
        produtos.splice(posicao, 1);
      }
    }

    commit("produtosProdutos", []);
    commit("produtosProdutos", produtos);
  },
  listarProdutos: ({ commit, state }) => {
    if (state.loadingProdutos) return;
    if (!state.pagingProdutos.nextPage) return;
    commit("loadingProdutos");
    axios
      .get("/produtos", {
        params: state.filtrosProdutos
      })
      .then(res => {
        if (res.data.success) {
          let produtos = res.data.produtos;
          for (let i = 0; i < produtos.length; i++) {
            produtos[i].loading = false;
          }
          commit("produtosProdutos", state.produtosProdutos.concat(produtos));
          commit("filtrosProdutos", res.data.filtros);
          commit("tipoControleProdutos", res.data.tipoControle);
          commit("tiposProdutos", res.data.tipos);
          commit("tiposSomaProdutos", res.data.tipoSoma);
          commit("pagingProdutos", res.data.paging);
          commit(
            "totalProdutos",
            state.totalProdutos + state.pagingProdutos.current
          );
          commit("categoriasProdutosDisp", res.data.categorias);
          commit("canImportProdutos", res.data.canImport);
        }
        commit("loadingProdutos");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadingProdutos");
      });
  },
  resetPagingProdutos: ({ commit }) => {
    const paging = {
      finder: "all",
      page: 0,
      current: 0,
      count: 0,
      perPage: 20,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    };
    commit("totalProdutos", 0);
    commit("produtosProdutos", []);
    commit("pagingProdutos", paging);
  },
  filtrosProdutos: ({ commit, dispatch }) => {
    commit("filtrosProdutos", {
      tipo: "P",
      page: 1,
      ativo: 1,
      categoria_id: null
    });
    dispatch("resetPagingProdutos");
    dispatch("listarProdutos");
  },
  modalProdutos: ({ commit, dispatch, state }, userData) => {
    let produtos = [...state.produtosProdutos];
    let posicao = produtos.indexOf(userData);
    commit("produto", userData);
    axios
      .get("/produtos/edit/" + userData.id)
      .then(res => {
        if (res.data.success) {
          commit("produto", res.data.produto);
          commit("tipoRegrasAcontarProdutos", res.data.tipoRegrasAcontar);
          commit("tipoRegrasValorProdutos", res.data.tipoRegrasValor);
          commit("tipoRegrasProdutos", res.data.tipoRegras);
          commit("isNewProduto", false);
          dispatch("contas");
          dispatch("listarCategorias");
          commit("OpenDialogNovoProduto", true);
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
        if (produtos[posicao]) {
          produtos[posicao].loading = false;
          commit("produtosProdutos", produtos);
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        if (produtos[posicao]) {
          produtos[posicao].loading = false;
          commit("produtosProdutos", produtos);
        }
        commit("produto", userData);
      });
  },
  deleteItemProdutos: ({ dispatch }, userData) => {
    Dialog.create({
      title: userData.nome,
      message: "Tem certeza que deseja excluir este produto?",
      html: true,
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post("/produtos/delete/" + userData.id)
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              dispatch("atualizar", res.data.produto);
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi possível contactar, ou você não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  addProduto: ({ commit, dispatch, state }, userData) => {
    commit("saveProduto");
    let url = state.isNewProduto
      ? "/produtos/add"
      : "/produtos/edit/" + userData.id;

    const params = userData.params ? userData.params : {};
    const abrirProduto = userData.abrirProduto ? userData.abrirProduto : null;

    userData.params = null;
    userData.abrirProduto = null;

    axios
      .post(url, userData, { params })
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          if (state.isNewProduto) {
            commit("produtosProdutos", []);
            commit("produto", {});
            dispatch("filtrosProdutos");
          } else {
            let produto = res.data.produto;
            produto.action = 'update';
            dispatch("atualizar", produto);
          }

          if (userData.importar_produtos) {
            dispatch("filtrosServicos");
            dispatch("filtrosLoterias");
            dispatch("listarCategorias");
          }
          commit("errorsProdutos", []);
          commit("OpenDialogNovoProduto", false);

          if (abrirProduto) {
            dispatch("modalProdutos", res.data.produto);
          }
        } else {
          if (res.data && res.data.errors) {
            commit("errorsProdutos", res.data.errors);
          }

          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
        commit("saveProduto");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("saveProduto");
      });
  },
  addRegraProduto: ({ commit }, userData) => {
    return new Promise((resolve, reject) => {
      commit("saveRegra");
      let url = state.isNewRegraProduto
        ? "/produtos/add-regra/" + userData.produto_id
        : "/produtos/edit-regra/" + userData.produto_id + "/" + userData.id;
      axios
        .post(url, userData)
        .then(res => {
          if (res.data.success) {
            let produto = res.data.produto;
            for (let i = 0; i < produto["prodregras"].length; i++) {
              produto.prodregras[i].loadingDelete = false;
            }
            commit("produto", produto);

            Notify.create({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });
            commit("addRegraProdutos", {
              tipo: "",
              quando: "",
              dias: 0,
              tpvalor: "",
              valor: "",
              nomenclatura: "",
              conta_id: null
            });
            commit("OpenDialogNovaRegraProduto", false);
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
            commit("errorsProdutos", res.data.errors);
          }
          resolve(res.data);
          commit("saveRegra");
        })
        .catch(() => {
          commit("saveRegra");
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          reject();
        });
    });
  },
  modalRegraProduto: ({ commit }, userData) => {
    if (userData) {
      commit("addRegraProdutos", userData);
      commit("isNewRegraProduto", false);
    } else {
      commit("isNewRegraProduto", true);
      commit("addRegraProdutos", {
        id: null,
        tipo: "",
        quando: "",
        dias: 0,
        tpvalor: "",
        valor: "",
        nomenclatura: "",
        conta_id: null
      });
    }
    commit("OpenDialogNovaRegraProduto", true);
  },
  deleteRegraProduto: ({ commit, dispatch, state }, userData, i) => {
    Dialog.create({
      title: userData.nomenclatura,
      message: "Tem certeza que deseja excluir esta regra?",
      html: true,
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        axios
          .post(
            "/produtos/delete-regra/" + userData.regra.produto_id + "/" + userData.regra.id
          )
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: 'Remover Regra',
                message: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              //userData.produto.prodregras = userData.produto.prodregras.splice(userData.pos, 1);
              dispatch("modalProdutos", userData.produto);

            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi possível contactar, ou você não possui permissão!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  },
  errorsProdutos: ({ commit }, userData) => {
    commit("errorsProdutos", userData);
  },
  setaNovoProduto: ({ commit }, userData) => {
    commit("isNewProduto", userData);
  },
  modalProdutosTags({ commit }, userData) {
    axios
      .get("/produtos/tags/" + userData.id)
      .then(res => {
        if (!res.data.success) {
          Notify.create({
            message: res.data.message || "Não foi possível contactar, ou você não possui permissão!",
            color: "orange",
            position: "top-right"
          });
        }

        userData.loading = false;
        commit("produto", userData);
        commit("produtosTags", res.data.produtosTags);
        commit("produtosAllTags", res.data.produtosAllTags);
        commit("openDialogProdutosTags", true);
      })
      .catch(error => {
        Notify.create({
          message: error.message || "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  }
};

const getters = {
  loadingProdutos: state => {
    return state.loadingProdutos;
  },
  produtosProdutos: state => {
    return state.produtosProdutos;
  },
  produto: state => {
    return state.produto;
  },
  filtrosProdutos: state => {
    return state.filtrosProdutos;
  },
  tipoControleProdutos: state => {
    return state.tipoControleProdutos;
  },
  tiposProdutos: state => {
    return state.tiposProdutos;
  },
  pagingProdutos: state => {
    return state.pagingProdutos;
  },
  addRegraProdutos: state => {
    return state.addRegraProdutos;
  },
  totalProdutos: state => {
    return state.totalProdutos;
  },
  isNewProduto: state => {
    return state.isNewProduto;
  },
  saveProduto: state => {
    return state.saveProduto;
  },
  errorsProdutos: state => {
    return state.errorsProdutos;
  },
  acontarProdutos: state => {
    return state.acontarProdutos;
  },
  tipoValorProdutos: state => {
    return state.tipoValorProdutos;
  },
  saveRegra: state => {
    return state.saveRegra;
  },
  isNewRegra: state => {
    return state.isNewRegra;
  },
  tiposSomaProdutos: state => {
    return state.tiposSomaProdutos;
  },
  isNewRegraProduto: state => {
    return state.isNewRegraProduto;
  },
  categoriasProdutosDisp: state => {
    return state.categoriasProdutosDisp;
  },
  tpcomissaoProdutos: state => {
    return state.tpcomissaoProdutos;
  },
  tpcomissaofuncProdutos: state => {
    return state.tpcomissaofuncProdutos;
  },
  tpnomenclaturaProdutos: state => {
    return state.tpnomenclaturaProdutos;
  },
  canImportProdutos: state => {
    return state.canImportProdutos;
  },
  OpenDialogNovoProduto: state => {
    return state.OpenDialogNovoProduto;
  },
  OpenDialogNovaRegraProduto: state => {
    return state.OpenDialogNovaRegraProduto;
  },
  tipoRegrasProdutos: state => {
    return state.tipoRegrasProdutos;
  },
  tipoRegrasValorProdutos: state => {
    return state.tipoRegrasValorProdutos;
  },
  tipoRegrasAcontarProdutos: state => {
    return state.tipoRegrasAcontarProdutos;
  },
  openDialogProdutosTags: state => {
    return state.openDialogProdutosTags;
  },
  produtosTags: state => {
    return state.produtosTags;
  },
  produtosAllTags: state => {
    return state.produtosAllTags;
  }
};
export default {
  state,
  mutations,
  actions,
  getters
};
