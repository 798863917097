import { render, staticRenderFns } from "./modal-adicionar-licenca.vue?vue&type=template&id=4d9d4d4a&scoped=true&"
import script from "./modal-adicionar-licenca.vue?vue&type=script&lang=js&"
export * from "./modal-adicionar-licenca.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d9d4d4a",
  null
  
)

export default component.exports