<template>
  <div id="modalAlterarSenha">
    <q-dialog v-model="OpenDialogAlterarSenha" persistent>
      <q-card style="max-width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Alterar Senha</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />

        <q-card-section>
          <q-list padding class="rounded-borders" style="max-width: 350px">
            <q-item-label header>{{dataUser.nome}}, siga as instruções para alterar sua senha:</q-item-label>

            <q-item>
              <q-item-section>
                <q-item-label lines="1">Possuir pelo menos seis caracteres</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-icon :name="regra1 ? 'check' : 'close'" :color="regra1 ? 'green' : 'red'" />
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label lines="1">Possuir pelo menos uma letra</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-icon :name="regra2 ? 'check' : 'close'" :color="regra2 ? 'green' : 'red'" />
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label lines="1">Possuir pelo menos um número</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-icon :name="regra3 ? 'check' : 'close'" :color="regra3 ? 'green' : 'red'" />
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md">
              <div class="col-12 col-md-12">
                <q-input
                  autocomplete="off"
                  type="password"
                  label="Sua Senha Atual"
                  v-model="senhaatual"
                ></q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  autocomplete="off"
                  label="Digite sua nova senha"
                  v-model="senha"
                  :type="isPwd ? 'password' : 'text'"
                  @input="val => verificaPass(val)"
                >
                  <template v-slot:append>
                    <q-icon
                      :name="isPwd ? 'visibility_off' : 'visibility'"
                      class="cursor-pointer"
                      @click="isPwd = !isPwd"
                    />
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  autocomplete="off"
                  label="Confirme sua nova senha"
                  v-model="confirmar"
                  type="password"
                  @input="val => verificaPass(val)"
                ></q-input>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveSenha"
            :loading="loadSenha"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify } from "quasar";
export default {
  name: "modal-alterar-senha",
  data() {
    return {
      regra1: false,
      regra2: false,
      regra3: false,
      isPwd: true,
      senhaatual: "",
      senha: "",
      confirmar: "",
      loadSenha: false
    };
  },
  methods: {
    saveSenha() {
      this.loadSenha = true;
      const newPass = {
        senhaatual: this.senhaatual,
        senha: this.senha,
        confirmar: this.confirmar
      };
      axios
        .post("/users/perfil", newPass)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: "Senha alterada com sucesso!",
              color: "green",
              position: "top-right"
            });
            this.closeModal();
            this.$store.dispatch("listarUser");
          } else {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }
          this.loadSenha = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível alterar a senha, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    closeModal() {
      this.$store.commit("OpenDialogAlterarSenha", false);
    },
    verificaPass(pass) {
      if (pass.length >= 6) {
        this.regra1 = true;
      } else {
        this.regra1 = false;
      }

      let testeL = /[a-zA-Z]/;

      if (testeL.test(pass)) {
        this.regra2 = true;
      } else {
        this.regra2 = false;
      }

      let testeN = /[0-9]/;

      if (testeN.test(pass)) {
        this.regra3 = true;
      } else {
        this.regra3 = false;
      }
    }
  },
  computed: {
    ...mapGetters(["dataUser"]),
    OpenDialogAlterarSenha: {
      get() {
        return this.$store.getters.OpenDialogAlterarSenha;
      },
      set() {}
    }
  }
};
</script>

<style lang="stylus" scoped></style>
