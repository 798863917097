<template>
  <q-layout id="loja">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <div class="col-12 col-md-12 text-center" v-if="buscando">
          <q-spinner color="blue-7" size="4em" />
        </div>
        <div class="col-12 col-md-12" v-if="!buscando">
          <div class="col-4 q-table__title">Loja de Aplicativos</div>
        </div>
        <div class="col-12 col-md-3" v-for="(app) in apps" :key="app.id">
          <q-card class="my-card" v-if="!buscando">
            <q-item :class="{'bg-green text-white': app.instalado}">
              <q-item-section avatar>
                <q-btn
                  round
                  :id=app.titulo
                  flat
                  dense
                  clickable
                  @click="instalar(app)"
                  :icon="app.instalado ? 'check' : 'file_download'"
                  :class="app.instalado ? 'text-white bg-green' : 'text-white bg-primary'"
                  :loading="app.loading"
                  :disable="app.instalado"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                  <template v-slot:default>
                    <q-tooltip
                      content-class="bg-green text-white"
                    >{{app.instalado ? 'Pacote instalado!' : 'Ativar este pacote'}}</q-tooltip>
                  </template>
                </q-btn>
              </q-item-section>

              <q-item-section>
                <q-item-label class="text-body1">{{app.titulo}}</q-item-label>
                <q-item-label caption :class="{'text-white': app.instalado}">{{app.valor_str}}</q-item-label>
              </q-item-section>
            </q-item>
            <img :src="app.imagem" :alt="app.titulo" />

            <q-list>
              <q-item clickable v-ripple>
                <q-item-section>
                  <q-item-label caption>Descrição do Aplicativo</q-item-label>
                  <q-item-label v-html="app.descricao"></q-item-label>
                </q-item-section>
                <!--
                <q-item-section side>
                  <q-icon :name="app.expande ? 'arrow_drop_up' : 'arrow_drop_down'" color="black" />
                </q-item-section>-->
              </q-item>
            </q-list>
          </q-card>
        </div>
      </div>
    </div>
  </q-layout>
</template>

<script>
import axios from "@/axios-auth";
import { Notify, Dialog, Loading } from "quasar";
export default {
  name: "loja",
  data() {
    return {
      apps: [],
      buscando: false,
      loadingApps: false
    };
  },
  meta: {
    title: "DSB | Loja"
  },
  methods: {
    listar() {
      this.buscando = true;
      axios
        .get("/aplicativos")
        .then(res => {
          if (res.data.success) {
            this.apps = res.data.aplicativos;
            for (let i in this.apps) {
              this.apps[i].loading = false;
            }
          }
          this.buscando = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível listar a loja de aplicativos, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.buscando = false;
        });
    },
    quebraLinha(key) {
      let arr = key.split("</p>");
      return arr[0];
    },
    instalar(app) {
      Dialog.create({
        title: `Confirma a instalação do Aplicativo ${app.titulo}?`,
        message: "O Valor de sua Fatura será alterado!",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          let index = this.apps.indexOf(app);
          this.apps[index].loading = true;
          axios
            .post("/aplicativos/install/" + app.id)
            .then(res => {
              if (res.data.success) {
                Notify.create({
                  message: res.data.message,
                  color: "green",
                  position: "top-right"
                });

                let dados = {
                  path: this.$route.path,
                  name: this.$route.name
                };
                this.$store.dispatch("alwaysOn", dados);
                this.listar();
              } else {
                Notify.create({
                  message: res.data.message,
                  color: "orange",
                  position: "top-right"
                });
              }
              this.apps[index].loading = false;
            })
            .catch(() => {
              Notify.create({
                message:
                  "Não foi possível contactar, ou você não possui permissão!",
                color: "red",
                position: "top-right"
              });
              this.apps[index].loading = false;
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    }
  },
  mounted() {
    this.listar();
  }
};
</script>
