import Router from "@/router/router";
import store from "@/store/store.js";

/** Função responsável pela autenticação  dos dados, enviado pelo qrcode, especificamente no lançamento de TFL */
function authQrcode(query, params, router = null) {
    // Validar entrdas
    if (query.token == null && !query.empresa) {
        next('login');
    }

    // Limpar localStorage, garantir que não tenha algum lixo de dados.
    localStorage.clear();

    // Setar novas credenciais
    localStorage.setItem("auth-qrcode", true);
    localStorage.setItem("empresa_atual", query.empresa);
    localStorage.setItem(
        "token" + localStorage.getItem("empresa_atual"),
        query.token
    );
    
    switch (router) {
        case 'caixas':
            if (params.id) {
                Router.push({ path: `/caixas/${params.id}/resumo` });
            }
            break;
        case 'despesas':
            store.dispatch("detalharDespesas", parseInt(query.movconta_id));
            Router.push({ path: "/despesas" });
            break;
        case 'receitas':
            store.dispatch("detalharReceitas", parseInt(query.movconta_id));
            Router.push({ path: "/receitas" });
            break;
        case 'cadastros':
            if (params.id) {
                Router.push({
                    path: `/cadastros/${params.id}`,
                    query: {
                        recorrencia_id: query.recorrencia_id, 
                        modal_recorrencia: '1'
                    }
                });
            }
            break;
        case 'assinaturas':
            if (query.recorrencia_id) {
                Router.push({
                    path: `/assinaturas`,
                    query: {
                        recorrencia_id: query.recorrencia_id, 
                        modal_recorrencia: '1'
                    }
                });
            }
            break;
    }
}

export default authQrcode;
