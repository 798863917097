<template>
  <div id="modalAdicionarServico">
    <q-dialog v-model="OpenDialogAdicionarServico" persistent>
      <q-card style="width:400px">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">Novo Serviço</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12" style="padding-left: 6px">
                <q-checkbox v-model="servico.ativo" label="Ativo" color="green" />
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="servico.nome"
                  label="Nome"
                  bottom-slots
                  :error="errorsLicencas.nome ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsLicencas.nome" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="servico.descricao"
                  label="Descrição"
                  bottom-slots
                  :error="errorsLicencas.descricao ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsLicencas.descricao" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="servico.token"
                  label="Token"
                  bottom-slots
                  :error="errorsLicencas.token ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsLicencas.token" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="servico.private_key"
                  label="Private Key"
                  bottom-slots
                  :error="errorsLicencas.private_key ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsLicencas.private_key"
                      :key="error + i"
                    >{{error}}</span>
                  </template>
                </q-input>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveServico"
            :loading="salvaSV"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "modal-adicionar-servico",
  methods: {
    saveServico() {
      this.$store.dispatch("saveServico", this.servico);
    },
    closeModal() {
      this.$store.commit("OpenDialogAdicionarServico", false);
      this.$store.dispatch("errorsLicencasClear");
      this.$store.dispatch("newServico");
    }
  },
  computed: {
    ...mapGetters(["errorsLicencas", "servico", "salvaSV"]),
    OpenDialogAdicionarServico: {
      get() {
        return this.$store.getters.OpenDialogAdicionarServico;
      },
      set() {}
    }
  }
};
</script>

<style lang="stylus" scoped></style>
