<template>
  <div id="modalDetalharArquivo">
    <q-dialog v-model="OpenDialogDetalharArquivo" persistent>
      <q-card style="max-width:100%">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">{{arquivo.nome}}</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="$emit('onClose', false)"
          />
        </q-card-section>

        <q-card-section class="row no-wrap">
          <div
            class="text-black"
          >Detalhamento {{contents && contents.length > 0 ? contents[0].arquivo_id : ''}}</div>
        </q-card-section>

        <q-separator />

        <q-card-section v-if="cadastrosContent && cadastrosContent.length > 0">
          <q-table
            title="Receitas"
            :data="cadastrosContent"
            :columns="columns"
            row-key="id"
            :loading="loadDetalhamento"
            :bottom="false"
            :pagination.sync="pagination"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
          >
            <template v-slot:bottom>
              <div></div>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td key="codigo" class="text-right">{{props.row.codigo}}</q-td>
                <q-td class="text-left"
                  key="banco"
                >{{props.row.banco}} {{props.row.agencia}} {{props.row.operacao}} {{props.row.conta}}</q-td>
                <q-td key="nome">{{props.row.nome}}</q-td>
                <q-td key="retornotxt">{{props.row.retornotxt}}</q-td>
                <q-td key="status" class="text-center">{{props.row.status}}</q-td>
                <q-td key="tipo" class="text-center">{{props.row.tipo}}</q-td>
                <q-td key="cadastroem" class="text-center">{{props.row.cadastroem}}</q-td>
                <q-td key="created" class="text-center">{{props.row.created | formatDateTime}}</q-td>
              </q-tr>
            </template>
          </q-table>
        </q-card-section>

        <q-card-section v-if="contents && contents.length > 0">
          <q-table
            :data="contents"
            :columns="columns2"
            row-key="id"
            :loading="loadDetalhamento"
            :bottom="false"
            :pagination.sync="pagination2"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
          >
            <template v-slot:bottom>
              <div></div>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td key="movconta_id" class="text-right">{{props.row.movconta_id}}</q-td>
                <q-td key="fatura_id" class="text-right">{{props.row.fatura_id}}</q-td>
                <q-td key="observacao">
                  <span v-if="props.row.cadastro && props.row.cadastro.codigo">{{ props.row.cadastro.codigo }} - </span>
                  <span v-if="props.row.cadastro">{{ props.row.cadastro.nome }} <br/></span>
                  {{props.row.retornotxt}}
                  <span
                    v-if="props.row.observacao !== ''"
                  >{{props.row.observacao}}</span>
                  <br />
                  <strong>Nosso Número</strong>
                  {{props.row.nosso_numero}}
                  <br />
                  <strong>Documento</strong>
                  {{props.row.documento}}
                  <br />
                  <strong>Vencimento</strong>
                  {{props.row.vencimento | formatDate}}
                  <br />
                  <strong>Data da Ocorrência</strong>
                  {{props.row.data_ocorrencia | formatDate}}
                  <br />
                  <strong>Data da Tarifa</strong>
                  {{props.row.data_tarifa | formatDate}}
                </q-td>
                <q-td key="valor" class="text-right">R$ {{props.row.valor | currency}}</q-td>
                <q-td key="valor_pago" class="text-right">R$ {{props.row.valor_pago | currency}}</q-td>
                <q-td key="valor_despesa" class="text-right">R$ {{props.row.valor_despesa | currency}}</q-td>
                <q-td key="valor_desconto" class="text-right">R$ {{props.row.valor_desconto | currency}}</q-td>
              </q-tr>
            </template>
          </q-table>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            :label="'Carregar Mais ' +pagingDetalhar.page + '/' + pagingDetalhar.pageCount "
            clickable
            @click="mostrarMais(pagingDetalhar)"
            :loading="loadDetalhamento"
            :disable="!pagingDetalhar.nextPage || loadDetalhamento"
            class="bg-primary text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
export default {
  name: "modal-detalhar-arquivo",
  props: [
    "cadastrosContent",
    "contents",
    "arquivo",
    "filtroTipo",
    "pagingDetalhar",
    "loadDetalhamento",
    "OpenDialogDetalharArquivo"
  ],
  data() {
    return {
      columns: [
        {
          name: "codigo",
          label: "Código",
          align: "left",
          field: row => row.name,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "banco",
          label: "Banco/Ag/Op/Cc",
          align: "left",
          field: row => row.name,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "nome",
          required: true,
          align: "left",
          label: "Nome",
          field: row => row.nome,
          sortable: true
        },
        {
          name: "retornotxt",
          align: "left",
          label: "Retorno",
          field: row => row.retornotxt,
          sortable: true
        },
        {
          name: "status",
          align: "center",
          label: "status",
          field: row => row.status,
          sortable: true
        },
        {
          name: "tipo",
          align: "center",
          label: "Tipo",
          field: row => row.tipo,
          sortable: true
        },
        {
          name: "cadastroem",
          align: "center",
          label: "Cadastrado Em",
          field: row => row.cadastroem,
          sortable: true
        },
        {
          name: "created",
          align: "center",
          label: "Criado",
          field: row => row.created,
          sortable: true
        }
      ],
      pagination: {
        sortBy: "codigo",
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      columns2: [
        {
          name: "movconta_id",
          label: "ID Receita",
          align: "right",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "fatura_id",
          label: "ID Fatura",
          align: "right",
          field: row => row.fatura_id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "observacao",
          required: true,
          align: "left",
          label: "Observações",
          field: row => row.observacao,
          sortable: true
        },
        {
          name: "valor",
          align: "right",
          label: "Valor",
          field: row => row.valor,
          sortable: true
        },
        {
          name: "valor_pago",
          align: "right",
          label: "Valor Pago",
          field: row => row.valor_pago,
          sortable: true
        },
        {
          name: "valor_despesa",
          align: "right",
          label: "Valor Despesa",
          field: row => row.valor_despesa,
          sortable: true
        },
        {
          name: "valor_desconto",
          align: "right",
          label: "Valor Desconto",
          field: row => row.valor_desconto,
          sortable: true
        },
      ],
      pagination2: {
        sortBy: "movconta_id",
        descending: false,
        page: 1,
        rowsPerPage: 0
      }
    };
  },
  methods: {
    mostrarMais(value) {
      if (!value.nextPage) return;
      this.loadDetalhamento = true;
      let url = "";
      if (this.cadastrosContent && this.cadastrosContent.length > 0) {
        url = "/arquivos/cadastros/" + this.arquivo.id;
      }
      if (this.contents && this.contents.length > 0) {
        url = "/arquivos/contents/" + this.arquivo.id;
      }
      let q = {
        page: value.page + 1,
        tipo: this.filtroTipo
      };

      axios
        .get(url, { params: q})
        .then(res => {
          if (this.cadastrosContent && this.cadastrosContent.length > 0) {
            this.cadastrosContent = this.cadastrosContent.concat(res.data.data.cadastros);
          } else if (this.contents && this.contents.length > 0) {
            this.contents = this.contents.concat(res.data.data.contents);
          }
          this.pagingDetalhar = res.data.data.paging;
          this.loadDetalhamento = false;
        });
    }
  }
};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
