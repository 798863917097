<template>
  <q-dialog v-model="openDialogDetalharMetricasSaas" persistent>
    <q-card style="width: 1200px; max-width: 100%">
      <q-card-section class="row items-center">
        <div class="text-h6">Detalhamento de Métricas Saas ({{ tipoDetalhamento }})</div>
        <q-space />

        <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
      </q-card-section>

      <q-card-section class="q-py-xl text-center" v-show="loadingDados">
        <q-spinner color="blue-7" size="4em" />
      </q-card-section>

      <q-card-section v-show="!loadingDados" class="q-pt-none">
        <div v-if="tabela.tipo === 'recorrencias'">
          <tabela-metricas-saas-recorrencias
            :tabela="tabela"
            :campo="campo"
            :referencia="referencia"
          />
        </div>

        <div v-if="tabela.tipo === 'cac'">
          <tabela-metricas-saas-cac
            :campo="campo"
            :tabela="tabela"
            :referencia="referencia"
            @atualizarModal="loadDados()"
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import axios from '@/axios-auth';
import { bus } from '@/main';

export default {
  name: 'modal-detalhar-metricas-saas',
  data() {
    return {
      openDialogDetalharMetricasSaas: false,
      tabela: {},
      campo: null,
      referencia: null,
      tipoDetalhamento: null,
      loadingDados: true,
    };
  },
  methods: {
    abrirModalDetalharMetricasSaas(dados) {
      this.campo = dados.campo;
      this.referencia = dados.referencia;
      this.tipoDetalhamento = dados.tipoDetalhamento;
      this.openDialogDetalharMetricasSaas = true;

      this.loadDados();
    },
    closeModal() {
      this.openDialogDetalharMetricasSaas = false;
    },
    loadDados() {
      this.loadingDados = true;
      this.tabela = {};

      const params = {
        referencia: String(this.referencia).replace('/', '-'),
      };

      axios
        .get(`/metricas-saas/detalhamento/${this.campo}`, { params })
        .then(res => {
          if (!res.data.success) throw new Error();

          this.loadingDados = false;

          this.tabela = res.data.tabela;
        })
        .catch(() => {
          this.loadingDados = false;
          this.$q.notify({
            message: 'Houve um erro ao recuperar as informações do detalhamento',
            position: 'top-right',
            color: 'red',
          });
        });
    },
  },
  beforeMount() {
    bus.$off('abrirModalDetalharMetricasSaas');
  },
  mounted() {
    bus.$on('abrirModalDetalharMetricasSaas', dados => {
      this.abrirModalDetalharMetricasSaas(dados);
    });
  },
};
</script>

<style>
table {
  width: 100%;
}
</style>
