import axios from "@/axios-auth";
let moment = require("moment");

const state = {
    extrato: [],
    extratoItem: {},
    contasExtrato: [],
    filtersExtrato: {
        dtini: "",
        dtfim: "",
        conta_id: null,
        detalhado: 1,
    },
    loadingExtrato: false,
    loadingExtratoSaldo: false,
    dataExtrato: {
        dtini: "",
        dtfim: ""
    },
    isPermisso: true,
    OpenDialogNovaTransferencia: false,
    OpenOpcoesAvancadasExtrato: false,
    OpenDialogSaldoInicial: false,
    OpenDialogEditarExtrato: false,
    dtiniSaldoFinal: false,
    OpenDialogDataBloqueio: false,
    OpenDialogAlterarIntroducaoExtratos: false,
    OpenDialogAlterarIntroducaoExtratosCliente: false,
    // Conciliação
    OpenDialogLancarDiferencaConta: false,
    OpenDialogIncluirConta: false,
    OpenDialogLancarDiferenca: false,
    OpenDialogSearch: false,
    selectedExtrato: null,
    selectedExtratos: [],
    // Detalhamento
    extratoDetalhamento: [],
    extratoDetalhamentoAgrupado: [],
    sangriasDetalhamento: [],
    transferenciaDetalhamento: [],
    OpenDialogDetalhamento: false,
    extratoDetalhamentoTotal: [],
    referencia:  "",
    fluxoDeCaixa: [],
    filtrosExtratoDrp: {
        status: "Todos",
        value: "todos",
    },
};

const mutations = {
    extrato: (state, userData) => {
        state.extrato = userData;
    },
    dtiniSaldoFinal: (state, userData) => {
        state.dtiniSaldoFinal = userData;
    },
    extratoItem: (state, userData) => {
        state.extratoItem = userData;
    },
    contasExtrato: (state, userData) => {
        state.contasExtrato = userData;
    },
    filtersExtrato: (state, userData) => {
        state.filtersExtrato = userData;
    },
    loadingExtrato: (state, userData) => {
        state.loadingExtrato = userData;
    },
    loadingExtratoSaldo: (state, userData) => {
        state.loadingExtratoSaldo = userData;
    },
    dataExtrato: (state, userData) => {
        state.dataExtrato.dtini = userData.dtini;
        state.dataExtrato.dtfim = userData.dtfim;
    },
    isPermisso: (state, userData) => {
        state.isPermisso = userData;
    },
    OpenDialogNovaTransferencia: (state, userData) => {
        state.OpenDialogNovaTransferencia = userData;
    },
    OpenOpcoesAvancadasExtrato: (state, userData) => {
        state.OpenOpcoesAvancadasExtrato = userData;
    },
    OpenDialogSaldoInicial: (state, userData) => {
        state.OpenDialogSaldoInicial = userData;
    },
    OpenDialogEditarExtrato: (state, userData) => {
        state.OpenDialogEditarExtrato = userData;
    },
    OpenDialogDataBloqueio: (state, userData) => {
        state.OpenDialogDataBloqueio = userData;
    },
    // Conciliação
    OpenDialogIncluirConta(state, userData) {
        state.OpenDialogIncluirConta = userData;
    },
    OpenDialogLancarDiferenca(state, userData) {
        state.OpenDialogLancarDiferenca = userData;
    },
    OpenDialogLancarDiferencaConta(state, userData) {
        state.OpenDialogLancarDiferencaConta = userData;
    },
    selectedExtrato(state, userData) {
        state.selectedExtrato = userData;
    },
    selectedExtratos(state, userData) {
        state.selectedExtratos = userData;
    },
    OpenDialogAlterarIntroducaoExtratos(state, userData) {
        state.OpenDialogAlterarIntroducaoExtratos = userData;
    },
    OpenDialogAlterarIntroducaoExtratosCliente(state, userData) {
      state.OpenDialogAlterarIntroducaoExtratosCliente = userData;
    },
    OpenDialogSearch(state, userData) {
        state.OpenDialogSearch = userData;
    },
    // Detalhamento
    OpenDialogDetalhamento(state, userData) {
        state.OpenDialogDetalhamento = userData;
    },
    extratoDetalhamento(state, userData) {
        state.extratoDetalhamento = userData;
    },
    extratoDetalhamentoAgrupado(state, userData) {
        state.extratoDetalhamentoAgrupado = userData;
    },
    extratoDetalhamentoTotal(state, userData) {
        state.extratoDetalhamentoTotal = userData;
    },
    sangriasDetalhamento(state, userData) {
        state.sangriasDetalhamento = userData;
    },
    transferenciaDetalhamento(state, userData) {
        state.transferenciaDetalhamento = userData;
    },
    referencia(state, userData) {
        state.referencia = userData;
    },
    fluxoDeCaixa(state, userData) {
        state.fluxoDeCaixa = userData;
    },
    filtrosExtratoDrp(state, userData) {
        state.filtrosExtratoDrp = userData;
    }
};

const actions = {
    saldoExtrato: ({ commit, state }, userData) => {
        commit("loadingExtratoSaldo", true);
        commit("isPermisso", true);

        let date = userData && !userData.dtiniSaldoFinal ? moment().format("DD-MM-YYYY") : userData.dtiniSaldoFinal;

        commit('dtiniSaldoFinal', date);

        axios
            .get("/financeiro/extrato", {
                params: {
                    dtiniSaldoFinal: state.dtiniSaldoFinal
                }
            })
            .then(res => {
                if (res.data.success) {
                    commit("contasExtrato", res.data.contas);
                } else {
                    commit("isPermisso", false);
                }
                commit("loadingExtratoSaldo", false);
            })
            .catch(() => {
                commit("isPermisso", false);
                commit("loadingExtratoSaldo", false);
            });
    },
    listarExtrato: ({ commit, state }, userData) => {
        commit("loadingExtrato", true);
        commit("isPermisso", true);
        let url = userData && userData.conta_id ? "/financeiro/extrato/" + userData.conta_id : '/financeiro/extrato';

        if (!userData.dtini) {
            userData.dtini = moment().startOf('isoWeek').format("DD-MM-YYYY");
            userData.dtfim = moment().endOf('isoWeek').format("DD-MM-YYYY");
            let dataAtual = moment(new Date()).format('DD/MM/YYYY');
            state.referencia = dataAtual;
        }

        axios
            .get(url, {
                params: {
                    dtini: userData.dtini,
                    dtfim: userData.dtfim,
                    dtiniSaldoFinal: state.dtiniSaldoFinal,
                    detalhado: userData.detalhado,
                }
            })
            .then(res => {
                if (res.data.success) {
                    commit("extrato", res.data.extrato);
                    let filters = res.data.filters;
                    filters.dtini = moment(new Date(filters.dtini)).format("DD-MM-YYYY");
                    filters.dtfim = moment(new Date(filters.dtfim)).format("DD-MM-YYYY");
                    commit("filtersExtrato", filters);
                    commit("isPermisso", true);
                    commit("dataExtrato", {
                        dtini: state.filtersExtrato.dtini.replace(/-/g, "/"),
                        dtfim: state.filtersExtrato.dtfim.replace(/-/g, "/")
                    });
                } else {
                    commit("isPermisso", false);
                }
                commit("loadingExtrato", false);
            })
            .catch(() => {
                commit("isPermisso", false);
                commit("loadingExtrato", false);
            });
    },
    resetExtrato: ({ commit }) => {
        commit("extrato", []);
    },
    changeFilterPeriodoExtrato: ({ commit, dispatch, state }, userData) => {
        if (state.loadingExtrato) return;

        let i = moment(state.dataExtrato.dtini, "DD/MM/YYYY");
        let f = moment(state.dataExtrato.dtfim, "DD/MM/YYYY");

        if (userData === "prev") {
            i.subtract(1, "week");
            f.subtract(1, "week");
        }
        if (userData === "next") {
            i.add(1, "week");
            f.add(1, "week");
        }

        commit("filtersExtrato", {
            dtini: i.format("DD-MM-YYYY"),
            dtfim: f.format("DD-MM-YYYY"),
            conta_id: state.filtersExtrato.conta_id,
            detalhado: state.filtersExtrato.detalhado,
        });
        commit("referencia", i.format("DD/MM/YYYY"));
        dispatch("resetExtrato");
        dispatch("listarExtrato", state.filtersExtrato);
    },
    filtersExtrato: ({ commit, dispatch }, userData) => {
        commit("filtersExtrato", {
            dtini: userData.dtini,
            dtfim: userData.dtfim,
            conta_id: state.filtersExtrato.conta_id,
            detalhado: state.filtersExtrato.detalhado,
        });

        let inicio = moment(userData.dtini, "DD-MM-YYYY").format("DD/MM/YYYY");
        commit("referencia", inicio);

        dispatch("resetExtrato");
        dispatch("listarExtrato", state.filtersExtrato);
    },
    setOpenDialogSaldoInicial: ({ commit }, userData) => {
        commit("OpenDialogSaldoInicial", userData);
    },
    setOpenDialogDataBloqueio: ({ commit }, userData) => {
        commit("OpenDialogDataBloqueio", userData);
    },
    setOpenDialogDetalhamento: ({ commit }, userData) => {
        commit("OpenDialogDetalhamento", userData);
    },
    setExtratoDetalhamento: ({ commit }, userData) => {
        commit("extratoDetalhamento", userData);
    },
    setExtratoDetalhamentoTotal: ({ commit }, userData) => {
        commit("extratoDetalhamentoTotal", userData);
    },
    getFluxoDeCaixa: ( { commit }, userData ) => {
        /**SEPERAR OS PARAMETROS
         *
         * conta
         * referencia
         *
         * montar a requisição
         * montar a resposta
        */
        axios
            .get('/financeiro/extrato-previsao?referencia=' + userData.referencia + '&conta=' + userData.conta)
            .then (res => {
                if (res.data.sucesso){
                    commit("fluxoDeCaixa", res.data.data);
                }
            }).catch(() => {
                Notify.create({
                  message: "Ocorreu um erro ao recurar os dados do gráfico!",
                  color: "red",
                  position: "top-right"
                });
            });



    }
};

const getters = {
    extrato: state => {
        return state.extrato;
    },
    extratoItem: state => {
        return state.extratoItem;
    },
    contasExtrato: state => {
        return state.contasExtrato;
    },
    filtersExtrato: state => {
        return state.filtersExtrato;
    },
    loadingExtrato: state => {
        return state.loadingExtrato;
    },
    loadingExtratoSaldo: state => {
        return state.loadingExtratoSaldo;
    },
    dataExtrato: state => {
        return state.dataExtrato;
    },
    isPermisso: state => {
        return state.isPermisso;
    },
    OpenDialogNovaTransferencia: state => {
        return state.OpenDialogNovaTransferencia;
    },
    OpenOpcoesAvancadasExtrato: state => {
        return state.OpenOpcoesAvancadasExtrato;
    },
    OpenDialogSaldoInicial: state => {
        return state.OpenDialogSaldoInicial;
    },
    OpenDialogEditarExtrato: state => {
        return state.OpenDialogEditarExtrato;
    },
    dtiniSaldoFinal: state => {
        return state.dtiniSaldoFinal;
    },
    OpenDialogDataBloqueio: state => {
        return state.OpenDialogDataBloqueio;
    },
    // Conciliação
    OpenDialogIncluirConta: state => {
        return state.OpenDialogIncluirConta;
    },
    OpenDialogLancarDiferenca: state => {
        return state.OpenDialogLancarDiferenca;
    },
    OpenDialogLancamentoSearch: state => {
        return state.OpenDialogLancamentoSearch;
    },
    selectedExtrato: state => {
        return state.selectedExtrato;
    },
    selectedExtratos: state => {
        return state.selectedExtratos;
    },
    OpenDialogLancarDiferencaConta: state => {
        return state.OpenDialogLancarDiferencaConta;
    },
    OpenDialogEditarLancamento: state => {
        return state.OpenDialogEditarLancamento;
    },
    OpenDialogAlterarIntroducaoExtratos: state => {
        return state.OpenDialogAlterarIntroducaoExtratos;
    },
    OpenDialogAlterarIntroducaoExtratosCliente: state => {
      return state.OpenDialogAlterarIntroducaoExtratosCliente;
    },
    OpenDialogSearch: state => {
        return state.OpenDialogSearch;
    },
    OpenDialogDetalhamento: state => {
        return state.OpenDialogDetalhamento;
    },
    extratoDetalhamento: state => {
        return state.extratoDetalhamento;
    },
    extratoDetalhamentoAgrupado: state => {
      return state.extratoDetalhamentoAgrupado;
    },
    extratoDetalhamentoTotal: state => {
        return state.extratoDetalhamentoTotal;
    },
    sangriasDetalhamento: state => {
        return state.sangriasDetalhamento;
    },
    transferenciaDetalhamento: state => {
        return state.transferenciaDetalhamento;
    },
    fluxoDeCaixa: state => {
        return state.fluxoDeCaixa;
    },
    filtrosExtratoDrp: state => {
        return state.filtrosExtratoDrp;
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};
