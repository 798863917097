var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{attrs:{"id":"relatorios"}},[_c('div',{staticClass:"q-pa-md"},[_c('div',{staticClass:"q-col-gutter-md row"},[(!_vm.isPermissoRelatorios)?_c('sem-permissao'):_vm._e(),(_vm.relatoriosCarregamento)?_c('div',{staticClass:"col-12 col-md-12 text-center"},[_c('q-spinner',{attrs:{"color":"blue-7","size":"4em"}})],1):_vm._e(),(_vm.hasVideo)?_c('div',{staticClass:"col-12 text-right"},[_c('q-btn',{attrs:{"dense":"","flat":"","color":"bg-primary text-blue-7","icon":"ondemand_video"},on:{"click":_vm.modalIntro},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('q-tooltip',[_vm._v("Introdução aos Relatórios")])]},proxy:true}],null,false,863525883)})],1):_vm._e(),(_vm.isPermissoRelatorios)?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.relatoriosCarregamento),expression:"!relatoriosCarregamento"}],staticClass:"row q-col-gutter-md"},_vm._l((_vm.grupos),function(grupo,index){return _c('div',{key:index,staticClass:"col-12 col-lg-6"},[_c('q-card',{staticClass:"full-height"},[_c('q-card-section',{staticClass:"q-pa-sm"},[_c('q-list',{staticClass:"rounded-borders"},[_c('q-item-label',{staticClass:"q-pa-sm"},[_c('div',{staticClass:"q-table__title"},[_vm._v(_vm._s(grupo.nome))])]),_vm._l((grupo.relatorios),function(relatorio){return _c('q-item',{directives:[{name:"ripple",rawName:"v-ripple"}],key:relatorio.id,class:{
                      active_status: _vm.isRotaAtual(
                        '/relatorios/' + (grupo.gerencial ? 'gerenciais/' : '') + relatorio.id
                      ),
                      'q-px-sm': true,
                    },staticStyle:{"min-height":"40px"},attrs:{"dense":"","clickable":""},on:{"click":function($event){_vm.irParaRota(
                        '/relatorios/' + (grupo.gerencial ? 'gerenciais/' : '') + relatorio.id
                      )}}},[_c('q-item-section',[_vm._v(_vm._s(relatorio.nome))])],1)})],2)],1)],1)],1)}),0)]):_vm._e(),(!_vm.relatoriosCarregamento)?_c('div',{staticClass:"col-12 col-md-6"},[(!_vm.$route.params.id && _vm.isPermissoRelatorios)?_c('div',[_c('q-table',{attrs:{"dense":"","title":"Últimos Relatórios Exportados","data":_vm.arquivos,"columns":_vm.columns,"row-key":"id","loading":_vm.carregamentoHistorico,"no-data-label":'Nenhum relatório exportado.',"no-results-label":'Nenhum resultado obtido',"loading-label":'Carregando relatórios exportados',"hide-bottom":"","virtual-scroll":"","pagination":_vm.pagination,"rows-per-page-options":[0]},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},[_c('q-td',{key:"nome",staticClass:"text-left"},[_vm._v(" "+_vm._s(props.row.nome)+" ")]),_c('q-td',{key:"created",staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.created))+" às "+_vm._s(_vm._f("formatTime")(props.row.created))+" ")]),_c('q-td',{staticClass:"text-center"},[_c('q-btn',{staticClass:"bg-primary text-white",staticStyle:{"width":"75px"},attrs:{"size":"12px","icon":"file_download","label":props.row.download},on:{"click":function($event){return _vm.baixaArquivo(props.row.id)}}})],1)],1)]}}],null,false,96197540)})],1):_vm._e(),_c('div',[_c('router-view')],1)]):_vm._e()],1),_c('q-page-sticky',{attrs:{"position":"top-right","offset":[0, 90]}},[_c('q-btn',{staticClass:"rotate-0",staticStyle:{"border-top-left-radius":"50px","border-bottom-left-radius":"50px"},attrs:{"color":_vm.openOpcoesAvancadas ? 'red-7' : 'blue-7',"icon":_vm.openOpcoesAvancadas ? 'close' : 'settings'},on:{"click":function($event){_vm.openOpcoesAvancadas = !_vm.openOpcoesAvancadas}}},[_c('q-tooltip',{attrs:{"content-class":_vm.openOpcoesAvancadas
              ? 'bg-negative text-white text-center'
              : 'bg-secondary text-white text-center'}},[_vm._v(_vm._s(_vm.openOpcoesAvancadas ? 'Fechar Opções Avançadas' : 'Opções Avançadas'))])],1)],1),_c('q-drawer',{attrs:{"side":"right","bordered":"","width":300,"breakpoint":500,"behavior":"default","content-class":"bg-white"},model:{value:(_vm.openOpcoesAvancadas),callback:function ($$v) {_vm.openOpcoesAvancadas=$$v},expression:"openOpcoesAvancadas"}},[_c('q-scroll-area',{staticClass:"fit"},[_c('div',{staticClass:"q-pa-sm"},[_c('div',{staticClass:"q-table__title"},[_vm._v("Opções Avançadas")]),_c('div',[_c('q-list',{staticClass:"rounded-borders",staticStyle:{"max-width":"350px","margin-left":"-10px","width":"300px"}},[_c('q-item-label',{attrs:{"header":""}}),_c('q-item',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"clickable":""},on:{"click":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.openRelatoriosAgendas()}}},[_c('q-item-section',[_c('q-item-label',{attrs:{"lines":"1"}},[_vm._v("Relatórios Agendados")]),_c('q-item-label',{attrs:{"caption":""}},[_vm._v("Agende envio de relatórios todo mês")])],1)],1)],1)],1)])])],1)],1),_c('modal-relatorios-agendas')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }