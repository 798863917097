import { Notify } from "quasar";
import axios from "@/axios-auth";

const state = {
  webphoneUrl: "",
  popupWebPhone: null,
  webphoneNumero: null,
  hasRamal: false,
  openChamadasLogsModal: false,
  chamadasLogs: [],
  loadingChamadasLogs: false,
  paginationBtn: false,
};

const mutations = {
  webphoneUrl: (state, userData) => {
    state.webphoneUrl = userData;
  },
  popupWebPhone: (state, userData) => {
    state.popupWebPhone = userData;
  },
  webphoneNumero: (state, userData) => {
    state.webphoneNumero = userData;
  },
  hasRamal: (state, userData) => {
    state.hasRamal = userData;
  },
  openChamadasLogsModal: (state, userData) => {
    state.openChamadasLogsModal = userData;
  },
  chamadasLogs: (state, userData) => {
    state.chamadasLogs = userData;
  },
  loadingChamadasLogs: (state, userData) => {
    state.loadingChamadasLogs = userData;
  },
  paginationBtn: (state, userData) => {
    state.paginationBtn = userData;
  }
};

const actions = {
  hasRamal: ({ commit }) => {
    axios
      .get("/integracoes/ligar", { params: { get_ramal: 1 } })
      .then(res => {
        if (!res.data.success && !res.data.ramal) {
          commit("hasRamal", false);
          return;
        }

          commit("hasRamal", true);
      });
  },
  getWebPhone: ({ commit, dispatch }, dados) => {
    const numero = dados.numero;

    commit("webphoneUrl", "");
    commit("popupWebPhone", null);
    commit("webphoneNumero", numero);

    axios
      .post("/integracoes/ligar", { numero })
      .then(res => {
        if (res.error && res.error.response.status === 403) {
          Notify.create({
            message: "Você não tem permissão para realizar esta ação!",
            color: "red",
            position: "top-right",
          });

          return;
        }

        if (!res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "red",
            position: "top-right",
          });

          return;
        }

        commit("webphoneUrl", res.data.data.url);
        dispatch("openWebPhone", dados);
      });
  },
  openWebPhone: ({ state, commit, dispatch }, dados) => {
    if (state.popupWebPhone) return;

    const windowFeatures = "popup, width=400, height=600";
    const webphone = window.open(
      state.webphoneUrl,
      "WebPhone",
      windowFeatures
    );

    setTimeout(() => {
      dispatch("saveChamada", dados);
    }, 5000);

    commit("popupWebPhone", webphone);
  },
  saveChamada: ({ commit, dispatch }, dados) => {
    const url = '/integracoes/zenvia?save_chamada=1';
    const key = state.webphoneUrl.substring(37, 69);
    dados.numero = state.webphoneNumero;
    dados.key = key;

    axios
      .post(url, dados)
      .then(res => {
        if (!res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "red",
            position: "top-right",
          });
        }
      });
  },
  getChamadasLogs: ({ commit }, filtros) => {
    commit("loadingChamadasLogs", true);
    commit("paginationBtn", false);

    const url = "/integracoes/chamadas";
    axios
      .get(url, { params: filtros })
      .then(res => {
        if (!res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "red",
            position: "top-right",
          });

          return;
        }

        if (res.data.data.length === 0) {
          commit("paginationBtn", true);
        }

        commit("chamadasLogs", [...state.chamadasLogs, ...res.data.data]);
        commit("openChamadasLogsModal", true);
        commit("loadingChamadasLogs", false);
      }
    );
  }
};

const getters = {
  webphoneUrl: (state) => state.webphoneUrl,
  popupWebPhone: (state) => state.popupWebPhone,
  webphoneNumero: (state) => state.webphoneNumero,
  hasRamal: (state) => state.hasRamal,
  openChamadasLogsModal: (state) => state.openChamadasLogsModal,
  chamadasLogs: (state) => state.chamadasLogs,
  loadingChamadasLogs: (state) => state.loadingChamadasLogs,
  paginationBtn: (state) => state.paginationBtn,
};

export default {
  state,
  mutations,
  actions,
  getters
};
