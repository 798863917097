<template>
  <div id="modalNovoCentroCusto">
    <q-dialog v-model="openDialogNovoCentroCusto" persistent>
      <q-card style="max-width: 1090px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">{{isNewCentroCusto ? 'Novo ' : 'Editar '}} Centro de Custo</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-input
                  v-model="centroCustoData.nome"
                  label="Nome"
                />
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="salvar"
            :loading="salvandoCentroCusto"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "modal-novo-centro-custo",
  methods: {
    closeModal() {
      this.$store.dispatch("clearCentroCusto");
      this.$store.commit("openDialogNovoCentroCusto", false);
    },
    salvar() {
      if (!this.isNewCentroCusto) {
        this.$store.dispatch("editarCentroCustoPost", this.centroCustoData);
      } else {
        this.$store.dispatch("salvarCentroCusto", this.centroCustoData);
      }
    },
    clearCentroCusto() {
      this.$store.dispatch("clearCentroCusto");
    }
  },
  computed: {
    ...mapGetters([
      "config",
      "centroCustoData",
      "errorsCentroCusto",
      "salvandoCentroCusto",
      "isNewCentroCusto",
      "openDialogNovoCentroCusto",
    ]),
  }
};
</script>

<style lang="stylus" scoped></style>
