<template>
    <q-dialog v-model="this.OpenDrawerUpgradePlano">
      <q-card style="width: 800px">
        <q-card-section class="row bg-upgrade items-center">
          <p class=" q-mb-none text-h6 text-left">Evolua com a Gente!</p>
          <q-space/>
          <q-btn
            style="font-size: 18px;"
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="close()"
          />
        </q-card-section>
        <q-separator/>
        <q-card-section class=" bg-upgrade-text">
          <h6 class="text-right">Seu Plano atual não possui este recurso, <br>
          <a href="https://dourasoft.com.br/" target="_blanck" style="color: white;">Fale com Nosso Time</a>, e realize um Upgrade.</h6>
        </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from 'vuex';
export default {
  name: "modal-upgrade-plano",
  data(){
    return {
      teste: ""
    };
  },
  methods: {
    close() {
      this.$store.dispatch("toggleUpgradePlano", false);
    }
  },
  computed: {
    ...mapGetters([
      "OpenDrawerUpgradePlano"
    ])
  }
};
</script>

<style>

.bg-upgrade{
  /*background-color: #2cafc9;*/
  background-color: #4285F4;
  color: white;
}

.bg-upgrade-text{
  background-color: #4285F4;
  color: white;
}

</style>
