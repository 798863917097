import axios from "@/axios-auth";

const state = {
  reguaDeCobranca: [],
  OpenDialogNovaRegua: false,
  valueQuando: {
    isNew: false
  }
};

const mutations = {
  reguaDeCobranca(state, userData) {
    state.reguaDeCobranca = userData;
  },
  OpenDialogNovaRegua: (state, userData) => {
    state.OpenDialogNovaRegua = userData;
  },
  valueQuando: (state, userData) => {
    state.valueQuando = userData;
  }
};

const actions = {
  reguaDeCobranca({ commit }) {
    axios
      .get("/regua-cobrancas")
      .then(res => {
        commit("reguaDeCobranca", res.data.regras);
      })
      .catch(() => {
        commit("reguaDeCobranca", [{ message: "Sem permissão" }]);
      });
  }
};

const getters = {
  reguaDeCobranca(state) {
    return state.reguaDeCobranca;
  },
  OpenDialogNovaRegua: state => {
    return state.OpenDialogNovaRegua;
  },
  valueQuando: state => {
    return state.valueQuando;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
