<template>
  <div id="modalCaixaSaldoInicial">
    <q-dialog v-model="OpenDialogSaldoInicialCaixas" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Saldo Inicial</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="clear()" />
        </q-card-section>
        <q-separator />
        <acao-caixa></acao-caixa>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: "modal-caixa-saldo-inicial",
  methods: {
    clear() {
      this.$store.commit("venda", {
        descricao: "",
        valor: 0
      });
      this.$store.commit("errorsCaixas", {});
      this.$store.commit("OpenDialogSaldoInicialCaixas", false);
    }
  },
  computed: {
    OpenDialogSaldoInicialCaixas: {
      get() {
        return this.$store.getters.OpenDialogSaldoInicialCaixas;
      },
      set() {}
    }
  },
  mounted() {
    document.addEventListener("keyup", event => {
      if (event.keyCode === 27) {
        this.clear();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener("keyup");
    next();
  }
};
</script>

<style lang="stylus" scoped></style>
