<template>
  <div id="cardConversoes">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-h6">
            <q-icon style="position:relative; top: -3px" name="developer_board" />&nbsp;Plano
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section v-if="fetchUser && fetchUser.db.assinatura">
      <q-list>
        <q-item style="padding-top: 0; padding-bottom: 0;min-height: 35px">
          <q-item-section>Plano</q-item-section>
          <q-item-section avatar>
            <strong>{{fetchUser.db.assinatura.plano}}</strong>
          </q-item-section>
        </q-item>

        <q-item
          style="padding-top: 0; padding-bottom: 0;min-height: 35px"
          v-for="(item, i) in fetchUser.db.assinaturaItems"
          :key="i + item.descricao"
        >
          <q-item-section v-html="returnDescricao(item.descricao)"></q-item-section>
          <q-item-section avatar>
            <strong>{{item.quantidade}}</strong>
          </q-item-section>
        </q-item>

        <q-item style="padding-top: 0; padding-bottom: 0;min-height: 35px">
          <q-item-section>Recorrência</q-item-section>
          <q-item-section avatar>
            <strong>{{fetchUser.db.assinatura.periodo}}</strong>
          </q-item-section>
        </q-item>

        <q-item style="padding-top: 0; padding-bottom: 0;min-height: 35px">
          <q-item-section>Valido até</q-item-section>
          <q-item-section avatar>
            <strong>{{vencimento}}</strong>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>

    <q-card-section v-else>
      <div class="text-center">Nenhuma assinatura encontrada</div>
    </q-card-section>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
const moment = require("moment");
export default {
  name: "card-plano",
  data() {
    return {};
  },
  methods: {
    returnDescricao(descricao) {
      return descricao.length <= 18
        ? descricao
        : descricao.substr(0, 18) + "...";
    },
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "5",
      });
    },
  },
  computed: {
    ...mapGetters(["fetchUser", "percentCards", "layout"]),
    ano() {
      return moment().year();
    },
    vencimento() {
      if (!this.fetchUser.db.assinatura) return "";

      return moment(new Date(this.fetchUser.db.assinatura.vencimento)).format(
        "DD/MM/YYYY"
      );
    }
  },
  mounted() {}
};
</script>

<style lang="stylus" scoped></style>
