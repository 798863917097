import { render, staticRenderFns } from "./area-cliente.vue?vue&type=template&id=b25a5f22&scoped=true&"
import script from "./area-cliente.vue?vue&type=script&lang=js&"
export * from "./area-cliente.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b25a5f22",
  null
  
)

export default component.exports