<template>
  <div id="modalDetalharNotas">
    <q-dialog v-model="OpenDialogDetalharNotas" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Detalhamento de Notas</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section v-show="loadingMovconta" class="text-center">
          <q-spinner color="blue-7" size="3em" />
        </q-card-section>

        <q-card-section v-show="!loadingMovconta">
          <p class="q-mb-none text-h6">Cédulas</p>
          <q-markup-table flat dense>
            <thead>
              <tr>
                <th colspan="3">Clique na quantidade para alterar</th>
              </tr>
            </thead>

            <tbody>

              <tr>
                <td>R$ 2,00</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhenota.notas_2 }}
                  <q-popup-edit
                    v-model="movconta.movcontas_detalhenota.notas_2"
                    auto-save
                    :validate="() => parseInt(movconta.movcontas_detalhenota.notas_2) >= 0"
                    @save="salvar(false)"
                  >
                    <q-input
                      v-model="movconta.movcontas_detalhenota.notas_2"
                      dense
                      autofocus
                      mask="####"
                    />
                  </q-popup-edit>
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhenota.notas_2 * 2) | currency }}</td>
              </tr>

              <tr>
                <td>R$ 5,00</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhenota.notas_5 }}
                  <q-popup-edit
                    v-model="movconta.movcontas_detalhenota.notas_5"
                    auto-save
                    :validate="() => parseInt(movconta.movcontas_detalhenota.notas_5) >= 0"
                    @save="salvar(false)"
                  >
                    <q-input
                      v-model="movconta.movcontas_detalhenota.notas_5"
                      dense
                      autofocus
                      mask="####"
                    />
                  </q-popup-edit>
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhenota.notas_5 * 5) | currency }}</td>
              </tr>

              <tr>
                <td>R$ 10,00</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhenota.notas_10 }}
                  <q-popup-edit
                    v-model="movconta.movcontas_detalhenota.notas_10"
                    auto-save
                    :validate="() => parseInt(movconta.movcontas_detalhenota.notas_10) >= 0"
                    @save="salvar(false)"
                  >
                    <q-input
                      v-model="movconta.movcontas_detalhenota.notas_10"
                      dense
                      autofocus
                      mask="####"
                    />
                  </q-popup-edit>
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhenota.notas_10 * 10) | currency }}</td>
              </tr>

              <tr>
                <td>R$ 20,00</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhenota.notas_20 }}
                  <q-popup-edit
                    v-model="movconta.movcontas_detalhenota.notas_20"
                    auto-save
                    :validate="() => parseInt(movconta.movcontas_detalhenota.notas_20) >= 0"
                    @save="salvar(false)"
                  >
                    <q-input
                      v-model="movconta.movcontas_detalhenota.notas_20"
                      dense
                      autofocus
                      mask="####"
                    />
                  </q-popup-edit>
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhenota.notas_20 * 20) | currency }}</td>
              </tr>

              <tr>
                <td>R$ 50,00</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhenota.notas_50 }}
                  <q-popup-edit
                    v-model="movconta.movcontas_detalhenota.notas_50"
                    auto-save
                    :validate="() => parseInt(movconta.movcontas_detalhenota.notas_50) >= 0"
                    @save="salvar(false)"
                  >
                    <q-input
                      v-model="movconta.movcontas_detalhenota.notas_50"
                      dense
                      autofocus
                      mask="####"
                    />
                  </q-popup-edit>
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhenota.notas_50 * 50) | currency }}</td>
              </tr>

              <tr>
                <td>R$ 100,00</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhenota.notas_100 }}
                  <q-popup-edit
                    v-model="movconta.movcontas_detalhenota.notas_100"
                    auto-save
                    :validate="() => parseInt(movconta.movcontas_detalhenota.notas_100) >= 0"
                    @save="salvar(false)"
                  >
                    <q-input
                      v-model="movconta.movcontas_detalhenota.notas_100"
                      dense
                      autofocus
                      mask="####"
                    />
                  </q-popup-edit>
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhenota.notas_100 * 100) | currency }}</td>
              </tr>

              <tr>
                <td>R$ 200,00</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhenota.notas_200 }}
                  <q-popup-edit
                    v-model="movconta.movcontas_detalhenota.notas_200"
                    auto-save
                    :validate="() => parseInt(movconta.movcontas_detalhenota.notas_200) >= 0"
                    @save="salvar(false)"
                  >
                    <q-input
                      v-model="movconta.movcontas_detalhenota.notas_200"
                      dense
                      autofocus
                      mask="####"
                    />
                  </q-popup-edit>
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhenota.notas_200 * 200) | currency }}</td>
              </tr>

            </tbody>

            <tfoot>
              <tr class="text-weight-bold">
                <td>Total</td>
                <td class="text-right">{{ quantidadeTotalNotas }}</td>
                <td class="text-right">R$ {{ valorTotalNotas | currency }}</td>
              </tr>
            </tfoot>

          </q-markup-table>
        </q-card-section>

        <q-separator />

        <q-card-section v-show="!loadingMovconta">
          <p class="q-mb-none text-h6">Moedas</p>
          <q-markup-table flat dense>
            <tbody>
              <tr>
                <td>R$ 0,05</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhemoeda.moedas_5 }}
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhemoeda.moedas_5 * 0.05) | currency }}</td>
              </tr>

              <tr>
                <td>R$ 0,10</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhemoeda.moedas_10}}
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhemoeda.moedas_10 * 0.10) | currency }}</td>
              </tr>

              <tr>
                <td>R$ 0,25</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhemoeda.moedas_25 }}
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhemoeda.moedas_25 * 0.25) | currency }}</td>
              </tr>

              <tr>
                <td>R$ 0,50</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhemoeda.moedas_50 }}
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhemoeda.moedas_50 * 0.50) | currency }}</td>
              </tr>

              <tr>
                <td>R$ 1,00</td>
                <td class="cursor-pointer text-right">
                  {{ movconta.movcontas_detalhemoeda.moedas_1 }}
                </td>
                <td class="text-right">R$ {{ (movconta.movcontas_detalhemoeda.moedas_1) | currency }}</td>
              </tr>
            </tbody>

            <tfoot>
              <tr class="text-weight-bold">
                <td>Total</td>
                <td class="text-right">{{ quantidadeTotalMoedas }}</td>
                <td class="text-right">R$ {{ valorTotalMoedas | currency }}</td>
              </tr>
            </tfoot>

          </q-markup-table>
        </q-card-section>

        <q-card-actions class="text-primary justify-between" style="margin: 10px 22px; margin-top: 0;">
          <q-btn
            outline
            color="cyan"
            icon="mail"
            :disable="loadingMovconta"
            @click="enviarEmail()"
          />
          <q-btn
            outline
            color="cyan"
            icon="print"
            :disable="loadingMovconta"
            @click="imprimir()"
          />
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="salvar(true)"
            :loading="loadingSalvar"
            class="bg-positive text-white"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <modal-enviar-relatorio-rme />
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { bus } from "@/main";

export default {
  name: "modal-detalhar-notas",
  data() {
    return {
      OpenDialogDetalharNotas: false,
      loadingSalvar: false,
      loadingMovconta: false,
      movconta: {
        movcontas_detalhenota: {},
      },
    };
  },
  methods: {
    init(movconta) {
      this.OpenDialogDetalharNotas = true;
      this.loadingMovconta = true;

      axios
        .get(`/financeiro/edit/${movconta.id}`)
        .then(res => {
          this.loadingMovconta = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'green',
              position: 'top-right',
            });
            return;
          }

          this.movconta = res.data.movconta;
        })
        .catch(() => {
          this.loadingMovconta = false;
          this.$q.notify({
            message: 'Houve um erro ao recuperar a movconta',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    salvar(fechar) {
      this.loadingSalvar = true;

      const data = JSON.parse(JSON.stringify(this.movconta.movcontas_detalhenota));
      data.action = 'editar-detalhamento-notas';
      data.conta_id = this.movconta.conta_id;

      axios
        .post(`/financeiro/edit/${this.movconta.id}`, data)
        .then(res => {
          this.loadingSalvar = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) return;

          if (fechar){
            this.closeModal();
          }
        })
        .catch(() => {
          this.loadingSalvar = false;
          this.$q.notify({
            message: 'Houve um erro ao editar o detalhamento de notas!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    imprimir() {
      this.loadingSalvar = true;

      const data = {
        impressao: 1,
      };

      axios
        .post(`/financeiro/enviar-relatorio-rme/${this.movconta.id}`, data)
        .then(res => {
          this.loadingSalvar = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? "green" : "orange",
            position: "top-right",
          });

          if (!res.data.success) return;

            const link = document.createElement("a");
            link.href = res.data.arquivo.public_url;
            link.setAttribute("type", "hidden");
            window.open(link, "_blank");

          this.closeModal();
          bus.$emit("fecharModalDetalharNotas");
        })
        .catch((e) => {
          this.loadingSalvar = false;
          this.$q.notify({
            message: "Houve um erro ao enviar email de relatório de RME",
            color: "red",
            position: "top-right",
          });
        });
    },
    closeModal() {
      this.OpenDialogDetalharNotas = false;
    },
    enviarEmail() {
      bus.$emit("abrirModalEnviarRelatorioRme", this.movconta);
    },
  },
  computed: {
    valorTotalNotas() {
      const { movcontas_detalhenota } = this.movconta;

      return (
        (parseInt(movcontas_detalhenota.notas_2) * 2)
        + (parseInt(movcontas_detalhenota.notas_5) * 5)
        + (parseInt(movcontas_detalhenota.notas_10) * 10)
        + (parseInt(movcontas_detalhenota.notas_20) * 20)
        + (parseInt(movcontas_detalhenota.notas_50) * 50)
        + (parseInt(movcontas_detalhenota.notas_100) * 100)
        + (parseInt(movcontas_detalhenota.notas_200) * 200)
      );
    },
    quantidadeTotalNotas() {
      const { movcontas_detalhenota } = this.movconta;

      return (
        parseInt(movcontas_detalhenota.notas_2) + parseInt(movcontas_detalhenota.notas_5)
        + parseInt(movcontas_detalhenota.notas_10) + parseInt(movcontas_detalhenota.notas_20)
        + parseInt(movcontas_detalhenota.notas_50) + parseInt(movcontas_detalhenota.notas_100)
        + parseInt(movcontas_detalhenota.notas_200)
      );
    },
    valorTotalMoedas() {
      const { movcontas_detalhemoeda } = this.movconta;

      return (
        + (parseInt(movcontas_detalhemoeda.moedas_5) * 0.05)
        + (parseInt(movcontas_detalhemoeda.moedas_10) * 0.10)
        + (parseInt(movcontas_detalhemoeda.moedas_25) * 0.25)
        + (parseInt(movcontas_detalhemoeda.moedas_50) * 0.50)
        + (parseInt(movcontas_detalhemoeda.moedas_1))
      );
    },
    quantidadeTotalMoedas() {
      const { movcontas_detalhemoeda } = this.movconta;

      return (
        parseInt(movcontas_detalhemoeda.moedas_5) + parseInt(movcontas_detalhemoeda.moedas_10)
        + parseInt(movcontas_detalhemoeda.moedas_25) + parseInt(movcontas_detalhemoeda.moedas_50)
        + parseInt(movcontas_detalhemoeda.moedas_1)
      );
    },
  },
  mounted() {
    bus.$on("abrirModalDetalharNotas", movconta => {
      this.init(movconta);
    });
    bus.$on("fecharModalDetalharNotas", () => {
      this.closeModal();
    });
  },
  destroyed() {
    bus.$off("abrirModalDetalharNotas");
    bus.$off("fecharModalDetalharNotas");
  },
};
</script>

<style lang="stylus" scoped></style>
