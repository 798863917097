<template>
  <div id="modalAlterarPlanoContas">
    <q-dialog v-model="OpenDialogAlterarPlanoDeContas" persistent>
      <q-card style="min-width: 300px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Alterar Plano de Contas</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-select
                  use-input
                  v-model="planodeconta_id"
                  label="Plano de Contas"
                  option-label="titulo"
                 @filter="planoContasFilter"
                 :options="planoContasFilterResult"
                  option-value="id"
                  map-options
                  clearable
                  emit-value
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <br />
                <br />
                <br />
                <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  :loading="loading"
                  @click="enviaTroca()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify, Dialog, Loading } from "quasar";
import axios from "@/axios-auth";
export default {
  name: "modal-alterar-plano-contas",
  data() {
    return {
      planodeconta_id: null,
      planoContasFilterResult: [],
      loading: false
    };
  },
  methods: {
    planoContasFilter(val, update) {
      update(() => {
        this.planoContasFilterResult = this.planoContas.filter(
          plano => plano.titulo.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
    closeModal() {
      this.planodeconta_id = null;
      this.$store.commit("OpenDialogAlterarPlanoDeContas", false);
      // limpar checkbox selecionados
      this.$store.dispatch("clearCheckbox");
    },
    enviaTroca() {
      let dados = {};
      let url = "";
      if (this.$route.path === "/receitas") {
        url = "/financeiro/edit-multiple/receita";
        dados = {
          action: "alterar-planocontas",
          confirmar: 0,
          ids: this.selectediesReceitas,
          all: this.allReceitas ? 1 : 0,
          planodeconta_id: this.planodeconta_id,
          dtini: this.filtrosReceitas.dtini,
          dtfim: this.filtrosReceitas.dtfim,
          status: this.filtrosReceitas.status,
          busca: this.filtrosReceitas.busca,
          status_remessa: this.filtrosReceitas.status_remessa,
        };
      }
      if (this.$route.path === "/despesas") {
        url = "/financeiro/edit-multiple/despesa";
        dados = {
          action: "alterar-planocontas",
          confirmar: 0,
          ids: this.selectediesDespesas,
          all: this.allDespesas ? 1 : 0,
          planodeconta_id: this.planodeconta_id,
          dtini: this.filtrosDespesas.dtini,
          dtfim: this.filtrosDespesas.dtfim,
          status: this.filtrosDespesas.status,
          busca: this.filtrosDespesas.busca
        };
      }
      this.loading = true;
      axios
        .post(url, dados)
        .then(res => {
          if (res.data.success) {
            Dialog.create({
              title: res.data.message,
              cancel: "Cancelar",
              persistent: true
            })
              .onOk(() => {
                Loading.show();
              })
              .onOk(() => {
                dados.confirmar = 1;
                axios
                  .post(url, dados)
                  .then(res => {
                    if (res.data.success) {
                      Dialog.create({
                        title: res.data.message,
                        class: "bg-positive text-white",
                        ok: {
                          "text-color": "green",
                          color: "white"
                        }
                      });

                      if (this.$route.name === "despesas") {
                        this.$store.dispatch("resetPagingDespesas");
                        this.$store.dispatch("listarDespesas");
                      } else {
                        this.$store.dispatch("resetPagingReceitas");
                        this.$store.dispatch("listarReceitas");
                      }
                      // limpar checkbox selecionados
                      this.$store.dispatch("clearCheckbox");
                      this.closeModal();
                    } else {
                      Dialog.create({
                        title: res.data.message,
                        class: "bg-warning text-white",
                        ok: {
                          "text-color": "orange",
                          color: "white"
                        }
                      });
                    }
                    this.loading = false;
                  })
                  .catch(() => {
                    Dialog.create({
                      title:
                        "Não foi possível contactar, ou você não possui permissão!",
                      class: "bg-negative text-white",
                      ok: {
                        "text-color": "red",
                        color: "white"
                      }
                    });
                    this.loading = false;
                  });
              })
              .onCancel(() => {
                Loading.hide();
                this.loading = false;
              })
              .onDismiss(() => {
                Loading.hide();
                this.loading = false;
              });
          }
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.loading = false;
        });
    }
  },
  computed: {
    OpenDialogAlterarPlanoDeContas: {
      get() {
        return this.$store.getters.OpenDialogAlterarPlanoDeContas;
      },
      set() {}
    },
    ...mapGetters([
      "planoContas",
      "selectediesReceitas",
      "allReceitas",
      "filtrosReceitas",
      "selectediesDespesas",
      "allDespesas",
      "filtrosDespesas"
    ])
  }
};
</script>

<style lang="stylus" scoped></style>
