<template>
  <div id="modalNotaFiscalOpcoes">
    <q-dialog v-model="openDialogOpcoesCofreInteligente" persistent>
      <q-card class="q-px-lg" style="width: 800px; max-width: 90%;">

        <q-card-section class="row items-center">
          <div class="text-h6">Opções Cofre Inteligente</div>
          <q-space />
          <q-btn
            class="q-ml-lg"
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="cancela()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="row q-col-gutter-md">
            <div class="col-md-6">
               <div class="row">
                <div class="col-12 q-mb-sm">
                  <q-select
                    map-options
                    emit-value
                    v-model="opcoes.cofreinteligente_tipo"
                    :options="tipos"
                    label="Tipo"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
                <div class="col-12 q-mb-sm">
                  <q-input
                    label="Url da Api"
                    v-model="opcoes.cofreinteligente_url"
                  />
                </div>
                <div class="col-12 q-mb-sm">
                  <q-input
                    label="ID Equipamento / Cofre"
                    v-model="opcoes.cofreinteligente_idequipamento"
                  />
                </div>
                <div class="col-12 q-mb-sm">
                  <q-input
                    label="ID Cliente"
                    v-model="opcoes.cofreinteligente_idcliente"
                  />
                </div>
                <div class="col-12 q-mb-sm">
                  <q-input
                    label="Usuário Autenticação"
                    v-model="opcoes.cofreinteligente_user"
                  />
                </div>
                <div class="col-12 q-mb-sm">
                  <q-input
                    label="Senha Autenticação"
                    v-model="opcoes.cofreinteligente_pass"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-12">
                  <autocomplete
                    title="Selecionar Cadastro"
                    :filter="filterFn"
                    :carregando="carregando"
                    :options="options"
                    :formatDisplay="formatDisplay"
                    :create="createValue"
                    @resultAutocomplete="resultEmitRecebe"
                    :display="displayMontar"
                    tipoConsulta="cadastro"
                    :cadastro="abreCadastro"
                  />
                </div>
                <div class="col-10">
                  <q-input
                    label="ID do Usuário"
                    v-model="idUsuario"
                  />
                </div>
                <div class="col-2 flex flex-center">
                  <q-btn
                    flat
                    round
                    icon="add"
                    color="primary"
                    @click="addUsuario"
                  />
                </div>
              </div>

              <q-markup-table
                flat
                class="q-mt-md"
                separator="none"
                v-if="opcoes.cofreinteligente_usuarios"
              >
                <thead>
                  <tr>
                    <th colspan="2">Lista de usuários</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(usuario, index) in opcoes.cofreinteligente_usuarios"
                    :key="usuario.funcionarioId"
                  >
                    <td>{{ usuario.usuarioId }}</td>
                    <td>{{ usuario.funcionarioNome }}</td>
                    <td>
                      <q-btn
                        flat
                        dense
                        color="red"
                        icon="delete"
                        @click="removeUsuario(index)"
                      />
                    </td>
                  </tr>
                  <tr v-if="opcoes.cofreinteligente_usuarios.length === 0">
                    <td colspan="2" align="center">Você não possui nenhum usuário registrado</td>
                  </tr>
                </tbody>
              </q-markup-table>
            </div>
          </div>
        </q-card-section>

        <q-card-actions class="q-mb-sm" align="right">
          <q-btn
            @click="salvaOpcoes()"
            :loading="loadingSalvaOpcoes"
            label="Salvar"
            color="positive"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "modal-cofre-inteligente-opcoes",
  emits: ["loadOpcoes"],
  data() {
    return {
      loadingSalvaOpcoes: false,
      opcoes: {},
      idUsuario: '',
      cadastro: null,
      tipos: [],

      // Autocomplete
      carregando: false,
      options: [],
    };
  },
  methods: {
    ...mapActions([
      "toggleDialogOpcoesCofreInteligente",
    ]),
    cancela() {
      this.toggleDialogOpcoesCofreInteligente();
    },
    loadOpcoes() {
      axios
        .get("cofre-inteligente/opcoes")
        .then(res => {
          if (!res.data.success) {
            throw res.data;
          }
          this.tipos = res.data.tiposCofre;
          this.opcoes = res.data.opcoes;
          this.opcoes.cofreinteligente_usuarios = JSON.parse(this.opcoes.cofreinteligente_usuarios);
        })
        .catch(() => {});
    },
    salvaOpcoes() {
      this.loadingSalvaOpcoes = true;

      const opcoes = JSON.parse(JSON.stringify(this.opcoes));
      opcoes.cofreinteligente_usuarios = JSON.stringify(this.opcoes.cofreinteligente_usuarios);
      opcoes.cofreinteligente_statuscode = "2";
      opcoes.cofreinteligente_message = "Aguardando Sicronização";

      axios.post('cofre-inteligente/opcoes', opcoes)
        .then(res => {

          if (!res.data.success) {
            throw new Error();
          }

          this.$q.notify({
            message: "Sucesso ao salvar as opções",
            color: "green",
            position: "top-right",
          });

          this.loadingSalvaOpcoes = false;

          this.opcoes = res.data.opcoes;
          this.opcoes.cofreinteligente_usuarios = JSON.parse(this.opcoes.cofreinteligente_usuarios);

          this.cancela();
          this.$emit("loadOpcoes");
        })
        .catch(() => {
          this.$q.notify({
            message: "Houve um erro ao salvar as opções",
            color: "red",
            position: "top-right",
          });
          this.loadingSalvaOpcoes = false;
        });
    },
    addUsuario() {
      if (!this.cadastro || !this.idUsuario) {
        return;
      }

      let cadastroIndex = this.opcoes.cofreinteligente_usuarios.findIndex(
        usuario => usuario.funcionarioId == this.cadastro.id
      );

      const novoUsuario = {
        usuarioId: this.idUsuario.toString(),
        funcionarioId: this.cadastro.id,
        funcionarioNome: this.cadastro.nome,
      };

      if (cadastroIndex !== -1) {
        this.opcoes.cofreinteligente_usuarios[cadastroIndex] = novoUsuario;
        this.$forceUpdate();
      } else {
        this.opcoes.cofreinteligente_usuarios.push(novoUsuario);
      }
    },
    removeUsuario(index) {
      this.opcoes.cofreinteligente_usuarios.splice(index, 1);
    },

    // Autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/cadastros?ativo=1&funcionario=1&busca=" + val).then(res => {
          this.carregando = false;
          this.options = res.data.data.cadastros;
        });
      });
    },
    formatDisplay(result) {
      return result.nome;
    },
    createValue(val, done) {
      Notify.create({
        message: "Cadastro não foi encontrado.",
        color: "orange",
        position: "top-right"
      });
    },
    resultEmitRecebe(result) {
      this.cadastro = result;
    },
    displayMontar(value) {
      return !value.nome ? value.name : value.nome;
    },
    abreCadastro(value) {
      this.$store.dispatch("cadastro", {
        nome: value.name,
        noSearch: true,
      });
    },
  },
  computed: {
    ...mapGetters([
      "openDialogOpcoesCofreInteligente",
    ]),
    tipoSelecionado(){
      return this.opcoes.cofreinteligente_tipo;
    }
  },
  mounted() {

  },
  watch:{
    tipoSelecionado(val){
      if(val){
        this.tipos.filter(element => {
          if(element.value === val)
          this.opcoes.cofreinteligente_url = element.url;
        });
      }
    },
    openDialogOpcoesCofreInteligente(val){
      if(val){
        this.loadOpcoes();
      }
    }
  }
};
</script>
