<template>
  <div id="modalNovaLoteria">
    <q-dialog v-model="OpenDialogNovaLoteria" persistent>
      <q-card :class="{ minwidth: isNewLoteria, maxwidth: !isNewLoteria }">
        <q-card-section class="row items-center">
          <div class="text-h6">
            {{ isNewLoteria ? "Nova " : "Editar " }} Loteria
          </div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />
        <div class="row">
          <q-card-section
            :class="{
              'col-12': isNewLoteria,
              'col-md-6 col-sm-12': !isNewLoteria
            }"
          >
            <div class="q-pa-md">
              <div class="q-col-gutter-md row">
                <div class="col-12 col-md-12">
                  <q-input
                    v-model="loteriaCopy.nome"
                    label="Nome"
                    bottom-slots
                    :error="errorsLoterias.nome ? true : false"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.nome"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-input>
                </div>
                <div class="col-12 col-md-12">
                  <q-select
                    clearable
                    v-model="loteriaCopy.categoria_id"
                    :options="categorias"
                    option-value="id"
                    option-label="titulo"
                    emit-value
                    map-options
                    label="Categoria"
                    bottom-slots
                    :error="errorsLoterias.categoria_id ? true : false"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.categoria_id"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey"
                          >Sem Resultados</q-item-section
                        >
                      </q-item>
                    </template>
                  </q-select>
                </div>
                <div class="col-12 col-md-12">
                  <q-select
                    v-model="loteriaCopy.tiposoma"
                    :options="tiposSomaLoterias"
                    option-value="value"
                    option-label="name"
                    emit-value
                    map-options
                    label="Tipo Soma"
                    bottom-slots
                    :error="errorsLoterias.tiposoma ? true : false"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.tiposoma"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-select>
                </div>
                <div class="col-12 col-md-12">
                  <q-select
                    clearable
                    v-model="loteriaCopy.loteria_id"
                    :options="usarconcursosLoterias"
                    option-value="id"
                    option-label="nome"
                    emit-value
                    map-options
                    label="Usar Concursos"
                    bottom-slots
                    :error="errorsLoterias.loteria_id ? true : false"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.loteria_id"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey"
                          >Sem Resultados</q-item-section
                        >
                      </q-item>
                    </template>
                  </q-select>
                </div>
                <div class="col-12 col-md-6">
                  <q-field
                    v-model="loteriaCopy.vlrcomissao"
                    label="Comissão da Lotérica"
                    bottom-slots
                    :error="errorsLoterias.vlrcomissao ? true : false"
                  >
                    <template v-slot:before>
                      <q-btn
                        :label="setarTpComissao"
                        v-model="loteriaCopy.tpcomissao"
                        size="md"
                        @click="printTpComissao"
                      />
                    </template>
                    <template
                      v-slot:control="{ id, floatingLabel, value, emitValue }"
                    >
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="
                          loteriaCopy.tpcomissao === '%'
                            ? percentFormat
                            : moneyFormat
                        "
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.vlrcomissao"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>
                <div class="col-12 col-md-6">
                  <q-field
                    v-model="loteriaCopy.vlrcomissao_func"
                    label="Comissão para o vendedor"
                    bottom-slots
                    :error="errorsLoterias.vlrcomissao_func ? true : false"
                  >
                    <template v-slot:before>
                      <q-btn
                        :label="setarTpComissaoFunc"
                        v-model="loteriaCopy.tpcomissao_func"
                        size="md"
                        @click="printTpComissaoFunc"
                      />
                    </template>
                    <template
                      v-slot:control="{ id, floatingLabel, value, emitValue }"
                    >
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="
                          loteriaCopy.tpcomissao_func === '%'
                            ? percentFormat
                            : moneyFormat
                        "
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.vlrcomissao_func"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>

                <div class="col-12">
                  <q-select
                    label="Tipo controle"
                    :options="tipoControle"
                    v-model="loteriaCopy.tpcontrole"
                    :error="errorsLoterias.tpcontrole ? true : false"
                  >
                   <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.tpcontrole"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-select>
                </div>

                <div class="col-6 col-md-6">
                  <q-input
                    v-model="loteriaCopy.ordem"
                    mask="##"
                    bottom-slots
                    :error="errorsLoterias.ordem ? true : false"
                    label="Ordem"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.ordem"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-input>
                </div>
                <div class="col-6 col-md-6">
                  <q-field
                    v-model="loteriaCopy.vlrcusto"
                    label="Custo"
                    bottom-slots
                    :error="errorsLoterias.vlrcusto ? true : false"
                  >
                    <template
                      v-slot:control="{ id, floatingLabel, value, emitValue }"
                    >
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.vlrcusto"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>
                <div class="col-6 col-md-6" v-if="!isNewLoteria">
                  <q-input
                    v-model="loteriaCopy.text_detect"
                    bottom-slots
                    :error="errorsLoterias.ordem ? true : false"
                    label="Detecção na Foto"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.ordem"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-input>
                </div>
                <div class="col-6 col-md-6" v-if="!isNewLoteria">
                  <q-field
                    v-model="loteriaCopy.similaridade"
                    label="Similaridade (de 70% a 95%)"
                    bottom-slots
                    :error="Boolean(errorsLoterias.similaridade)"
                  >
                    <template
                      v-slot:control="{ id, floatingLabel, value, emitValue }"
                    >
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="percentFormat"
                        v-show="floatingLabel"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.similaridade"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>
                <div class="col-6 col-md-6">
                  <q-field
                    v-model="loteriaCopy.vlrvenda"
                    label="Valor Venda"
                    bottom-slots
                    :error="errorsLoterias.vlrvenda ? true : false"
                  >
                    <template v-slot:control="{ value, emitValue }">
                      <money
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errorsLoterias.vlrvenda"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>
                <div class="col-12">
                  <q-checkbox
                    v-model="loteriaCopy.ativo"
                    label="Ativo"
                    color="green"
                  />
                </div>
                <div class="col-12">
                  <q-select
                    use-input
                    v-model="planodeconta"
                    option-label="titulo"
                    option-value="id"
                    :options="planoContasFilterResult"
                    label="Plano de Conta"
                    clearable
                    @filter="planoContasFilter"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
              </div>
            </div>
          </q-card-section>
          <q-card-section class="col-md-6 col-sm-12" v-if="!isNewLoteria">
            <div class="q-pa-sm">
              <div class="row q-col-gutter-md">
                <div
                  class="col-12 col-md-12"
                  style="position:relative;left: -25px"
                >
                  <q-btn
                    v-ripple
                    label="Nova Regra"
                    icon="add"
                    dense
                    class="q-ml-sm q-px-md bg-positive text-white"
                    style="margin-bottom: 15px; margin-top: 15px;"
                    clickable
                    @click="modalRegra()"
                  />
                </div>
                <q-list
                  bordered
                  class="rounded-borders"
                  style="padding: 0px !important"
                >
                  <q-item-label header>Regras</q-item-label>
                  <div
                    v-for="(regra, i) in loteriaCopy.prodregras"
                    :key="regra.id"
                  >
                    <q-item>
                      <q-item-section top>
                        <q-item-label lines="1">
                          <span class="text-weight-medium">{{ regra.nomenclatura }}</span>
                          <span class="text-grey-8">&nbsp;-&nbsp;{{ regra.tipo }}</span>
                        </q-item-label>
                        <q-item-label caption lines="1" style="text-wrap: wrap">
                          <strong>{{ regra.valor }}</strong>&nbsp;
                          <strong>{{ regra.tpvalor }}</strong>&nbsp;
                          <strong>{{ regra.quando }}</strong>&nbsp;em&nbsp;
                          <strong>{{ regra.dias }}</strong>&nbsp;dia(s)&nbsp;do&nbsp;tipo&nbsp;
                          <strong>{{ regra.tipo }}</strong>,&nbsp;da nomenclatura&nbsp;
                          <strong>{{ regra.nomenclatura }}</strong>&nbsp;e&nbsp;conta&nbsp;bancária&nbsp;
                          <strong>{{ getContaNome(regra.conta_id) }}</strong>.
                        </q-item-label>
                      </q-item-section>

                      <q-item-section top side style="padding-left: 0;">
                        <div class="text-grey-8 q-gutter-xs">
                          <q-btn
                            clickable
                            @click="deleteRegra(regra, i)"
                            size="12px"
                            flat
                            dense
                            round
                            icon="delete"
                          />
                          <q-btn
                            clickable
                            @click="modalRegra(regra)"
                            size="12px"
                            flat
                            dense
                            round
                            icon="edit"
                          />
                        </div>
                      </q-item-section>
                    </q-item>

                    <q-separator
                      spaced
                      v-if="
                        loteriaCopy && loteriaCopy.prodregras.length - i > 1
                      "
                    />
                  </div>
                </q-list>
              </div>
            </div>
          </q-card-section>
          <q-card-section class="col-12 q-mt-md">
            <q-btn
              no-wrap
              v-ripple
              label="Salvar"
              no-caps
              class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
              style="margin: 0 30px 20px 0"
              clickable
              :loading="saveLoteria"
              @click="addLoteria()"
            >
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
          </q-card-section>
        </div>
      </q-card>
    </q-dialog>
    <q-dialog v-model="OpenDialogNovaRegraLoteria" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Nova Regra Loteria</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModalR()"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 col-md-12">
                <q-input
                  v-model="regraLoteria.nomenclatura"
                  label="Nomenclatura"
                  bottom-slots
                  :error="errorsRegras.nomenclatura ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.nomenclatura"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  v-model="regraLoteria.tpnomenclatura"
                  :options="tpnomenclaturaLoterias"
                  option-label="name"
                  option-value="value"
                  emit-value
                  map-options
                  label="Tipo de Nomenclatura"
                  bottom-slots
                  :error="errorsRegras.tpnomenclatura ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.tpnomenclatura"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  clearable
                  v-model="regraLoteria.tipo"
                  :options="tipoRegrasLoterias"
                  label="Tipo"
                  bottom-slots
                  :error="errorsRegras.tipo ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.tipo"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  clearable
                  v-model="regraLoteria.conta_id"
                  :options="contas"
                  option-label="titulo"
                  option-value="id"
                  map-options
                  emit-value
                  label="Conta Bancária"
                  bottom-slots
                  :error="errorsRegras.conta_id ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.conta_id"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  clearable
                  v-model="regraLoteria.quando"
                  :options="tipoRegrasAcontarLoterias"
                  label="A Contar"
                  bottom-slots
                  :error="errorsRegras.quando ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.quando"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12">
                <q-select
                  clearable
                  v-model="regraLoteria.tpvalor"
                  :options="tipoRegrasValorLoterias"
                  label="Tipo Valor"
                  bottom-slots
                  :error="errorsRegras.tpvalor ? true : false"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.tpvalor"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-6 col-md-6">
                <q-input
                  v-model="regraLoteria.dias"
                  bottom-slots
                  :error="errorsRegras.dias ? true : false"
                  mask="###"
                  label="Dias"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.dias"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>
              <div class="col-6 col-md-6">
                <q-field
                  v-model="regraLoteria.valor"
                  label="Custo"
                  bottom-slots
                  :error="errorsRegras.valor ? true : false"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="
                        regraLoteria.tpvalor === '%' ||
                        regraLoteria.tpvalor === '% Restante'
                          ? percentFormat
                          : moneyFormat
                      "
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errorsRegras.valor"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-field>
              </div>
              <div class="col-12 col-md-12 q-pb-lg">
                <q-btn
                  no-wrap
                  v-ripple
                  label="Adicionar Regra"
                  no-caps
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  :loading="saveRegra"
                  @click="addRegra()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
export default {
  name: "modal-nova-loteria",
  components: { Money },
  data() {
    return {
      loteriaCopy: this.newLoteria(),
      regraLoteria: this.newRegraLoteria(),
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false
      },
      planodeconta: null,
      planoContasFilterResult: [],
      tipoControle: ["Nenhum", "Concurso"],
    };
  },
  watch: {
    loteria(value) {
      if (this.isNewLoteria) {
        this.loteriaCopy = this.newLoteria();
      } else {
        this.loteriaCopy = JSON.parse(JSON.stringify(value));

        if (!this.planodeconta) {
          this.planodeconta = value.planodeconta ? value.planodeconta : null;
        }

        this.$store.dispatch("planoContas");
      }
    }
  },
  methods: {
    printTpComissao() {
      this.loteriaCopy.tpcomissao =
        this.loteriaCopy.tpcomissao === "$" ? "%" : "$";
    },
    printTpComissaoFunc() {
      this.loteriaCopy.tpcomissao_func =
        this.loteriaCopy.tpcomissao_func === "$" ? "%" : "$";
    },
    newLoteria() {
      return {
        id: null,
        ativo: true,
        tipo: "L",
        nome: "",
        categoria_id: null,
        vlrcusto: 0.0,
        vlrvenda: 0.0,
        vlrlucro: 0.0,
        vlrcomissao: 0.0,
        tpcontrole: "Nenhum",
        ordem: 1,
        planodeconta_id: null,
        text_detect: "",
        similaridade: 0.00,
        tiposoma: null,
        tpcomissao: "$",
        vlrcomissao_func: 0.0,
        tpcomissao_func: "$",
        loteria_id: null,
        prodregras: []
      };
    },
    newRegraLoteria() {
      return {
        id: null,
        tipo: "",
        conta_id: null,
        quando: "",
        dias: 0,
        tpvalor: "",
        valor: "",
        nomenclatura: "",
        tpnomenclatura: "C",
        tpcontrole: "Nenhum",
      };
    },
    closeModalR() {
      this.regraLoteria = this.newRegraLoteria();
      this.$store.commit("OpenDialogNovaRegraLoteria", false);
      this.$store.commit("errorsLoterias", []);
      this.$store.commit("addRegraLoterias", this.regraLoteria);
    },
    closeModal() {
      this.loteriaCopy = this.newLoteria();
      this.$store.commit("loteria", this.loteriaCopy);
      this.$store.commit("isNewLoteria", true);
      this.$store.commit("OpenDialogNovaLoteria", false);
      this.$store.commit("errorsLoterias", []);
    },
    addLoteria() {
      this.loteriaCopy.planodeconta_id = this.planodeconta ? this.planodeconta.id : null;
      this.loteriaCopy.ordem =
        this.loteriaCopy.ordem !== null && this.loteriaCopy.ordem !== ""
          ? this.loteriaCopy.ordem
          : this.produtosLoterias.length;
      this.loteriaCopy.tipo = "L";
      this.$store.dispatch("addLoteria", this.loteriaCopy);
    },
    deleteRegra(regra, i) {
      let data = {
        loteria: this.loteriaCopy,
        regra: regra,
        pos: i
      };

      this.$store.dispatch("deleteRegraLoteria", data);
    },
    modalRegra(regra) {
      this.$store.dispatch("errorsLoterias", []);
      if (regra) {
        this.regraLoteria = JSON.parse(JSON.stringify(regra));
        this.$store.dispatch("modalRegraLoteria", this.regraLoteria);
      } else {
        this.$store.dispatch("modalRegraLoteria");
      }
    },
    addRegra() {
      if (this.isNewRegraLoteria) {
        this.regraLoteria.produto_id = this.loteria.id;
      }
      this.$store.dispatch("addRegraLoteria", this.regraLoteria).then(res => {
        if (res.success) {
          this.regraLoteria = this.newRegraLoteria();
        }
      });
    },
    getContaNome(value) {
      if (!value) {
        return "Sem conta cadastrada";
      }
      for (let i = 0; i < this.contas.length; i++) {
        if (this.contas[i].id === value) {
          return this.contas[i].titulo;
        }
      }
      return "Sem conta cadastrada";
    },
    planoContasFilter(val, update) {
      update(() => {
        this.planoContasFilterResult = this.planoContas.filter(
          plano => plano.titulo.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
  },
  computed: {
    setarTpComissao:{
     get(){
       return this.loteriaCopy.tpcomissao === "$" ? "R$" : "%";
     },
     set(){}
    },
    setarTpComissaoFunc:{
     get(){
       return this.loteriaCopy.tpcomissao_func === "$" ? "R$" : "%";
     },
     set(){}
    },
    OpenDialogNovaLoteria: {
      get() {
        return this.$store.getters.OpenDialogNovaLoteria;
      },
      set() {}
    },
    OpenDialogNovaRegraLoteria: {
      get() {
        return this.$store.getters.OpenDialogNovaRegraLoteria;
      },
      set() {}
    },
    ...mapGetters([
      "loteria",
      "isNewLoteria",
      "categorias",
      "saveLoteria",
      "errorsLoterias",
      "tiposSomaLoterias",
      "produtosLoterias",
      "acontarLoterias",
      "tipoValorLoterias",
      "isNewRegraLoteria",
      "addRegraLoterias",
      "acontarLoterias",
      "tipoValorLoterias",
      "tiposLoterias",
      "contas",
      "saveRegra",
      "tpcomissaoLoterias",
      "tpcomissaofuncLoterias",
      "usarconcursosLoterias",
      "tpnomenclaturaLoterias",
      "tipoRegrasLoterias",
      "tipoRegrasValorLoterias",
      "tipoRegrasAcontarLoterias",
      "planoContas",
    ]),
    errorsRegras() {
      return this.errorsLoterias["prodregras"]
        ? this.errorsLoterias["prodregras"][0]
        : [];
    }
  }
};
</script>

<style lang="stylus" scoped>
.minwidth {
  width: 100%;
  @media (min-width: 1024px) {
    width: 600px;
  }
}

.maxwidth {
  width: 100%;
  @media (min-width: 1024px) {
    min-width: 800px;
    max-width: 1200px;
  }
}
</style>
