<template>
  <div id="modalDetalharMoedas">
    <q-dialog v-model="OpenDialogDetalharMoedas" persistent>
      <q-card style="width: 100%; max-width: 1000px">
        <q-card-section class="row items-center">
          <div class="text-h6">Detalhamento de Moedas</div>

          <q-space />

          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>

        <q-separator />

        <q-card-section v-show="loadingConta" class="text-center">
          <q-spinner color="blue-7" size="3em" />
        </q-card-section>

        <q-card-section v-show="!loadingConta">
          <q-markup-table flat dense style="max-width: 300px; margin: 0 auto;">
            <thead>
              <tr>
                <th>Quantidade</th>
                <th></th>
                <th>Valor</th>
                <th></th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-right">{{conta.contas_moeda.moedas_5}}</td>
                <td class="text-center">X</td>
                <td class="text-right">R$ 0,05</td>
                <td class="text-center">=</td>
                <td class="text-right">R$ {{ (conta.contas_moeda.moedas_5 * 0.05) | currency }}</td>
              </tr>
              <tr>
                <td class="text-right">{{conta.contas_moeda.moedas_10}}</td>
                <td class="text-center">X</td>
                <td class="text-right">R$ 0,10</td>
                <td class="text-center">=</td>
                <td class="text-right">R$ {{ (conta.contas_moeda.moedas_10 * 0.10) | currency }}</td>
              </tr>
              <tr>
                <td class="text-right">{{conta.contas_moeda.moedas_25}}</td>
                <td class="text-center">X</td>
                <td class="text-right">R$ 0,25</td>
                <td class="text-center">=</td>
                <td class="text-right">R$ {{ (conta.contas_moeda.moedas_25 * 0.25) | currency }}</td>
              </tr>
              <tr>
                <td class="text-right">{{conta.contas_moeda.moedas_50}}</td>
                <td class="text-center">X</td>
                <td class="text-right">R$ 0,50</td>
                <td class="text-center">=</td>
                <td class="text-right">R$ {{ (conta.contas_moeda.moedas_50 * 0.50) | currency }}</td>
              </tr>
              <tr>
                <td class="text-right">{{conta.contas_moeda.moedas_1}}</td>
                <td class="text-center">X</td>
                <td class="text-right">R$ 1,00</td>
                <td class="text-center">=</td>
                <td class="text-right">R$ {{ (conta.contas_moeda.moedas_1 * 1) | currency }}</td>
              </tr>
              <tr>
                <td class="text-right">{{conta.contas_moeda.notas_2}}</td>
                <td class="text-center">X</td>
                <td class="text-right">R$ 2,00</td>
                <td class="text-center">=</td>
                <td class="text-right">R$ {{ (conta.contas_moeda.notas_2 * 2) | currency }}</td>
              </tr>
              <tr>
                <td class="text-right">{{conta.contas_moeda.notas_5}}</td>
                <td class="text-center">X</td>
                <td class="text-right">R$ 5,00</td>
                <td class="text-center">=</td>
                <td class="text-right">R$ {{ (conta.contas_moeda.notas_5 * 5) | currency }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right text-weight-bold">
                  R$ {{ valorTotalMoedas | currency }}
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>

        <q-card-section v-show="!loadingConta && movcontas.length > 0">
          <q-markup-table flat dense>
            <thead>
              <tr>
                <th class="text-center">Movimento</th>
                <th>Descrição</th>
                <th class="text-right">0,05</th>
                <th class="text-right">0,10</th>
                <th class="text-right">0,25</th>
                <th class="text-right">0,50</th>
                <th class="text-right">1,00</th>
                <th class="text-right">2,00</th>
                <th class="text-right">5,00</th>
                <th class="text-right">Valor</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="movconta in movcontas" :key="movconta.id">
                <td class="text-center">
                  {{ movconta.liquidacao | formatDate }}
                </td>
                <td>
                  {{ movconta.descricao | truncate(40) }}

                  <q-tooltip v-if="movconta.descricao.length > 40">
                    {{ movconta.descricao }}
                  </q-tooltip>
                </td>

                <q-td class="text-right">
                  {{ (movconta.movcontas_detalhemoeda ? movconta.movcontas_detalhemoeda.moedas_5 * 0.05 : 0) | currency }}
                  <q-tooltip>{{ movconta.movcontas_detalhemoeda ? movconta.movcontas_detalhemoeda.moedas_5 : 0}}</q-tooltip>
                </q-td>

                <q-td class="text-right">
                  {{ (movconta.movcontas_detalhemoeda ? movconta.movcontas_detalhemoeda.moedas_10 * 0.1 : 0) | currency }}
                  <q-tooltip>{{ movconta.movcontas_detalhemoeda ? movconta.movcontas_detalhemoeda.moedas_10 : 0}}</q-tooltip>
                </q-td>

                <q-td class="text-right">
                  {{ (movconta.movcontas_detalhemoeda ? movconta.movcontas_detalhemoeda.moedas_25 * 0.25 : 0) | currency }}
                  <q-tooltip>{{ movconta.movcontas_detalhemoeda ? movconta.movcontas_detalhemoeda.moedas_25 : 0}}</q-tooltip>
                </q-td>

                <q-td class="text-right">
                  {{ (movconta.movcontas_detalhemoeda ? movconta.movcontas_detalhemoeda.moedas_50 * 0.5 : 0) | currency }}
                  <q-tooltip>{{ movconta.movcontas_detalhemoeda ? movconta.movcontas_detalhemoeda.moedas_50 : 0}}</q-tooltip>
                </q-td>

                <q-td class="text-right">
                  {{ (movconta.movcontas_detalhemoeda ? movconta.movcontas_detalhemoeda.moedas_1 * 1 : 0) | currency }}
                  <q-tooltip>{{ movconta.movcontas_detalhemoeda ? movconta.movcontas_detalhemoeda.moedas_1 : 0}}</q-tooltip>
                </q-td>

                <q-td class="text-right">
                  {{ ((movconta.movcontas_detalhenota ? movconta.movcontas_detalhenota.notas_2 : 0) * 2) | currency }}
                  <q-tooltip>{{ (movconta.movcontas_detalhenota ? movconta.movcontas_detalhenota.notas_2 : 0) }}</q-tooltip>
                </q-td>

                <q-td class="text-right">
                  {{ ((movconta.movcontas_detalhenota ? movconta.movcontas_detalhenota.notas_5 : 0) * 5) | currency }}
                  <q-tooltip>{{ (movconta.movcontas_detalhenota ? movconta.movcontas_detalhenota.notas_5 : 0) }}</q-tooltip>
                </q-td>
                <td class="text-right">
                  R$ {{ getValorTotalMoedasMovconta(movconta) | currency }}
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from '@/axios-auth';
import { mapGetters } from 'vuex';
import { bus } from '@/main';

export default {
  name: 'modal-detalhar-moedas-conta',
  data() {
    return {
      OpenDialogDetalharMoedas: false,
      loadingSalvar: false,
      loadingConta: false,
      conta: {
        contas_moeda: {
          moedas_5: 0,
          moedas_10: 0,
          moedas_25: 0,
          moedas_50: 0,
          moedas_1: 0,
          notas_2: 0,
          notas_5: 0,
        },
      },
      movcontas: [],
    };
  },
  methods: {
    init(contaId) {
      this.OpenDialogDetalharMoedas = true;
      this.loadingConta = true;

      const params = {
        dtini: this.filtersExtrato.dtini,
        dtfim: this.filtersExtrato.dtfim,
      };

      axios
        .get(`/contas/moedas/${contaId}`, { params })
        .then(res => {
          this.loadingConta = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'green',
              position: 'top-right',
            });
            return;
          }

          this.conta = res.data.conta;
          this.movcontas = res.data.movcontas;
        })
        .catch(() => {
          this.loadingConta = false;
          this.$q.notify({
            message: 'Houve um erro ao recuperar a conta',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    closeModal() {
      this.OpenDialogDetalharMoedas = false;
    },
    getValorTotalMoedasMovconta(movconta) {
      let valorTotal = 0;

      if (movconta.movcontas_detalhemoeda) {
        valorTotal += (
          movconta.movcontas_detalhemoeda.moedas_5 * 0.05 +
          movconta.movcontas_detalhemoeda.moedas_10 * 0.1 +
          movconta.movcontas_detalhemoeda.moedas_25 * 0.25 +
          movconta.movcontas_detalhemoeda.moedas_50 * 0.5 +
          movconta.movcontas_detalhemoeda.moedas_1 * 1
        );
      }
      if (movconta.movcontas_detalhenota) {
        valorTotal +=
          movconta.movcontas_detalhenota.notas_2 * 2 +
          movconta.movcontas_detalhenota.notas_5 * 5;
      }

      return valorTotal;
    },
  },
  computed: {
    ...mapGetters(['filtersExtrato']),
    valorTotalMoedas() {
      const { contas_moeda } = this.conta;

      return (
        parseInt(contas_moeda.moedas_5) * 0.05 +
        parseInt(contas_moeda.moedas_10) * 0.1 +
        parseInt(contas_moeda.moedas_25) * 0.25 +
        parseInt(contas_moeda.moedas_50) * 0.5 +
        parseInt(contas_moeda.moedas_1) * 1 +
        parseInt(contas_moeda.notas_2) * 2 +
        parseInt(contas_moeda.notas_5) * 5
      );
    },
    quantidadeTotalMoedas() {
      const { contas_moeda } = this.conta;

      return (
        parseInt(contas_moeda.moedas_5) +
        parseInt(contas_moeda.moedas_10) +
        parseInt(contas_moeda.moedas_25) +
        parseInt(contas_moeda.moedas_50) +
        parseInt(contas_moeda.moedas_1) +
        parseInt(contas_moeda.notas_2) +
        parseInt(contas_moeda.notas_5)
      );
    },
  },
  beforeMount() {
    bus.$off('abrirModalDetalharMoedas');
    bus.$off('fecharModalDetalharMoedas');
  },
  mounted() {
    bus.$on('abrirModalDetalharMoedas', contaId => {
      this.init(contaId);
    });
    bus.$on('fecharModalDetalharMoedas', () => {
      this.closeModal();
    });
  },
};
</script>

<style lang="stylus" scoped></style>
