<template>
  <div id="cardDespesa">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-h6" :to="'/receitas'">
            <q-icon style="position:relative; top: -3px" name="trending_down" />&nbsp;Despesas
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <div>
        <h5 class="q-mt-none q-mb-md">
          <span style="font-size: 12pt; position: relative; top: 0px;" class="left">R$&nbsp;&nbsp;</span>
          {{fetchUser.db.despesas.Liquidado | currency}}
        </h5>
        <p>
          <span v-if="fetchUser.db.percent.mesDespesas > 0" class="text-negative q-mb-sm">
            {{fetchUser.db.percent.mesDespesas}}%
            <i class="fa fa-level-up"></i>
          </span>
          <span v-if="fetchUser.db.percent.mesDespesas < 0" class="text-positive q-mb-sm">
            {{fetchUser.db.percent.mesDespesas}}%
            <i class="fa fa-level-down"></i>
          </span>
          período anterior:<br>
          R$ <strong>{{fetchUser.db.percent.despesasAnterior | currency}}</strong>
        </p>
      </div>
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "card-despesa",
  data() {
    return {};
  },
  methods: {
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "4",
      });
    },
  },
  computed: {
    ...mapGetters(["fetchUser"])
  },
  mounted() {}
};
</script>

<style lang="stylus" scoped></style>
