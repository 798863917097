<template>
  <q-page id="comissoes">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!permiteVisualizarComissoes"></sem-permissao>

        <div class="col-12" v-else>

          <div v-show="!loading">
              <q-table
                :data="comissoes"
                :columns="columns"
                :loading="buscandoComissao"
                :bottom="false"
                row-key="id"
                no-data-label="Nenhum dado"
                no-results-label="Nenhum resultado obtido"
                :pagination="pagination"
                :selected.sync="comissoesSelected"
                selection="multiple"
              >
                <template v-slot:top>

                  <q-btn
                    flat
                    round
                    dense
                    :icon="'add'"
                    id="addComissaoManual"
                    class="text-primary"
                    style="margin-right: 10px;margin-left: 0"
                    @click.exact="abrirAddComissoes()"
                  />
                  <div class="col-3 q-table__title">Comissões</div>

                  <q-btn
                    v-if="totalComissoesAbertasItens > 1 && comissoesSelected[0]"
                    flat
                    dense
                    no-wrap
                    v-ripple
                    color="secondary"
                    no-caps
                    :label="
                      'Selecionar todas as ' + totalComissoesAbertasItens + ' comissões'
                    "
                    class="q-ml-sm q-px-md left"
                    :class="{
                      'bg-white text-blue-7': !allComissoes,
                      'bg-green-7 text-white': allComissoes
                    }"
                    clickable
                    @click="allComissoes = !allComissoes"
                  />
                  <q-space />

                  <q-btn
                    v-if="hasVideo"
                    flat
                    dense
                    color="primary"
                    class="q-mr-md"
                    icon="ondemand_video"
                    @click="openModalIntroducao()"
                  >
                    <template v-slot:default>
                      <q-tooltip>Introdução às Comissões</q-tooltip>
                    </template>
                  </q-btn>

                  <q-btn-group flat rounded class="q-mr-md">
                    <q-btn
                      flat
                      rounded
                      clickable
                      color="blue-7"
                      icon="chevron_left"
                      :disable=buscandoComissao
                      @click="changeFilterPeriodo('prev')"
                    />

                    <q-btn rounded class="white text-blue-7" :label="filter.dtini" :disable="buscandoComissao">
                      <template v-slot:default>
                        <q-popup-proxy ref="qDateDtini" transition-show="scale" transition-hide="scale">
                          <q-date
                            minimal
                            today-btn
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            v-model="filter.dtini"
                            @input="() => $refs.qDateDtini.hide()"
                          />
                        </q-popup-proxy>
                      </template>
                    </q-btn>

                    <q-btn rounded class="white text-blue-7" :label="filter.dtfim" :disable="buscandoComissao">
                      <template v-slot:default>
                        <q-popup-proxy ref="qDateDtfim" transition-show="scale" transition-hide="scale">
                          <q-date
                            minimal
                            today-btn
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            v-model="filter.dtfim"
                            @input="() => $refs.qDateDtfim.hide()"
                          />
                        </q-popup-proxy>
                      </template>
                    </q-btn>

                    <q-btn
                      flat
                      rounded
                      clickable
                      color="blue-7"
                      icon="chevron_right"
                      :disable="buscandoComissao"
                      @click="changeFilterPeriodo('next')"
                    />
                  </q-btn-group>

                  <q-btn
                    flat
                    dense
                    no-wrap
                    v-ripple
                    color="primary"
                    no-caps
                    :label="(filter.status === null || filter.status === '') ? labelAll : filter.status"
                    class="q-ml-sm q-px-md bg-white text-blue-7"
                    style="margin-right: 10px"
                  >
                    <q-menu anchor="top right" self="top right" :disable="buscandoComissao">
                      <q-list class="text-grey-8" style="min-width: 100px">
                        <q-item aria-hidden="true">
                          <q-item-section
                            class="text-uppercase text-grey-7"
                            style="font-size: 0.7rem"
                            >Status</q-item-section
                          >
                        </q-item>
                        <q-item
                          :class="{ active_status: Boolean(filter.status === null || filter.status === '') }"
                          clickable
                          v-close-popup
                          aria-hidden="true"
                          @click="changeFilterStatus('')"
                        >
                          <q-item-section>Todos | {{ totalComissoesAbertasItens + totalComissoesGeradasItens}}</q-item-section
                          >
                        </q-item>

                        <q-item
                          :class="{ active_status: Boolean(filter.status === 'Aberto') }"
                          clickable
                          v-close-popup
                          aria-hidden="true"
                          @click="changeFilterStatus('Aberto')"
                        >
                          <q-item-section>Aberto | {{ totalComissoesAbertasItens }}</q-item-section
                          >
                        </q-item>

                         <q-item
                          :class="{ active_status: Boolean(filter.status === 'Gerados') }"
                          clickable
                          v-close-popup
                          aria-hidden="true"
                          @click="changeFilterStatus('Gerado')"
                        >
                          <q-item-section>Gerados | {{ totalComissoesGeradasItens }}</q-item-section
                          >
                        </q-item>

                      </q-list>
                    </q-menu>
                  </q-btn>

                </template>

                <template v-slot:body="props">
                  <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                    <q-td auto-width key="comissoesSelected">
                        <q-checkbox
                          color="secondary"
                          :val="props.row"
                          v-if="!props.row.gerado"
                          v-model="comissoesSelected"
                        />
                    </q-td>
                    <q-td
                      key="id"
                      class="text-right"
                    >
                      {{ props.row.id }}
                    </q-td>

                    <q-td key="fornecedor">
                      <router-link
                        style="text-decoration: none;color: #000"
                        :to="
                          props.row.fornecedor
                            ? '/cadastros/' + props.row.cadastro_id
                            : ''
                        "
                        >
                          {{
                            props.row.fornecedor
                              ? props.row.fornecedor.nome.length > 40
                                ? props.row.fornecedor.nome.substr(0, 40) + "..."
                                : props.row.fornecedor.nome
                              : "Sem Cadastro"
                          }}
                          <q-tooltip
                            v-if=" props.row.fornecedor && props.row.fornecedor.nome && props.row.fornecedor.nome.length > 40"
                            >
                            {{ props.row.fornecedor.nome }}
                          </q-tooltip>
                        </router-link
                      >
                    </q-td>

                    <q-td key="dtmovimento"
                      class="text-center"
                      >{{ new Date(props.row.dtmovimento) | formatDate }}</q-td
                    >

                    <q-td key="cliente">
                      <router-link
                        style="text-decoration: none;color: #000"
                        :to="
                          props.row.cliente
                            ? '/cadastros/' + props.row.cliente_id
                            : ''
                        "
                        >
                          {{
                            props.row.cliente
                              ? props.row.cliente.nome.length > 40
                                ? props.row.cliente.nome.substr(0, 40) + "..."
                                : props.row.cliente.nome
                              : "Sem Cadastro"
                          }}
                          <q-tooltip
                            v-if=" props.row.cliente && props.row.cliente.nome && props.row.cliente.nome.length > 40"
                            >
                            {{ props.row.cliente.nome }}
                          </q-tooltip>
                        </router-link
                      >
                    </q-td>

                    <q-td key="gerado"
                      class="text-center"
                      >
                        <q-badge
                          color="positive"
                          v-if="props.row.gerado"
                          style="display: inline-block; width: fit-content"
                          >Gerado</q-badge
                        >
                      </q-td>

                    <q-td key="descricao"
                    >
                      <template v-slot:default>
                        {{
                          props.row.descricao && props.row.descricao.length > 40
                            ? props.row.descricao.substr(0, 40) + "..."
                            : props.row.descricao
                        }}
                        <q-tooltip
                          v-if="
                            props.row.descricao && props.row.descricao.length > 40
                          "
                        >
                          {{ props.row.descricao }}
                        </q-tooltip>
                      </template>
                    </q-td>

                    <q-td key="valor"
                      class="text-right"
                      >R$ {{ props.row.valor | currency }}</q-td
                    >

                    <q-td v-if="$q.platform.is.desktop" class="tdfora">
                      <div class="divfora">
                        <div
                          class="divdentro q-gutter-sm"
                          style="margin-right: 10px"
                        >
                           <q-btn
                            @click="editComissao(props.row.id)"
                            size="12px"
                            class="bg-secondary text-white"
                            icon="edit"
                            v-if="!props.row.gerado"
                          />

                          <q-btn
                            @click="confirmeDeleteComissao(props.row.id)"
                            size="12px"
                            class="bg-red text-white"
                            icon="delete"
                            v-if="!props.row.gerado"
                          />

                        </div>
                      </div>
                    </q-td>
                  </q-tr>
                  <q-tr v-show="props.expand" :props="props">
                    <q-td colspan="100%">
                      <div class="q-py-sm q-gutter-sm">
                        <q-btn
                            @click="editComissao(props.row.id)"
                            size="12px"
                            class="bg-secondary text-white"
                            icon="edit"
                            v-if="!props.row.gerado"
                          />

                          <q-btn
                            @click="confirmeDeleteComissao(props.row.id)"
                            size="12px"
                            class="bg-red text-white"
                            icon="delete"
                            v-if="!props.row.gerado"
                          />
                      </div>
                    </q-td>
                  </q-tr>
                </template>

                <template v-slot:bottom></template>

              </q-table>
          </div>

          <q-card :class="{ 'fixed-bottom': true, 'fixed-middle': true, cardInformacoesExpanded }" style="border">
            <q-card-section :class="{ relative: true, 'q-pa-xs': !cardInformacoesExpanded }">
              <div :class="cardInformacoesExpanded ? 'absolute-right' : ''">
                <q-btn
                  flat
                  round
                  dense
                  color="grey-7"
                  :icon="cardInformacoesExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
                  @click="cardInformacoesExpanded = !cardInformacoesExpanded"
                />
              </div>

              <q-slide-transition>
                <div v-show="cardInformacoesExpanded" :class="{ 'q-pr-xl': true, 'total-info': true }" ref="totalInfo">

                <div class="flex">
                  <div style="flex: 1;" class="q-mr-lg">
                    <div class="flex justify-between">
                      <div>
                        <strong>Total</strong>
                        ({{ totalComissoesItens }})
                      </div>
                      <p class="q-mb-none">R$ {{ totalComissoesValor | currency }}</p>
                      </div>


                      <div class="flex justify-between" v-if="allComissoes">
                        <div>
                          <strong>Selecionado</strong>
                          ({{ totalComissoesAbertasItens }})
                        </div>
                        <p class="q-mb-none">R$ {{ totalComissoesAbertasValor | currency }}</p>
                      </div>

                      <div class="flex justify-between" v-if="!allComissoes">
                        <div>
                          <strong>Selecionado</strong>
                          ({{ countTotalComissaoSelecionado }})
                        </div>
                        <p class="q-mb-none">R$ {{ totalComissaoSelecionado | currency }}</p>
                      </div>

                  </div>

                  <div style="width: 40px;">
                    <q-btn
                      icon="event"
                      class="q-mr-sm"
                      color="primary"
                      @click="openModal()"
                      :disable="buscandoComissao"
                    >
                      <q-tooltip>
                        Realizar Pagamentos
                      </q-tooltip>
                    </q-btn>
                  </div>
                </div>

                </div>
              </q-slide-transition>
            </q-card-section>
          </q-card>

          <div class="text-center" v-show="loading || buscandoComissao">
            <q-spinner color="blue-7" size="3em" />
          </div>

          <q-page-sticky position="top-right" :offset="[0, 90]">
            <q-btn
              :color="openOpcoesAvancadas ? 'red-7' : 'blue-7'"
              :icon="openOpcoesAvancadas ? 'close' : 'settings'"
              class="rotate-0"
              style="border-top-left-radius: 50px; border-bottom-left-radius: 50px"
              @click="openOpcoesAvancadas = !openOpcoesAvancadas"
            >
              <q-tooltip
                :content-class="
                  openOpcoesAvancadas
                    ? 'bg-negative text-white text-center'
                    : 'bg-secondary text-white text-center'
                "
                >{{ openOpcoesAvancadas ? 'Fechar Opções Avançadas' : 'Opções Avançadas' }}</q-tooltip
              >
            </q-btn>
          </q-page-sticky>

          <q-drawer
            bordered
            side="right"
            behavior="default"
            content-class="bg-white"
            :width="280"
            :breakpoint="500"
            v-model="openOpcoesAvancadas"
          >
            <q-scroll-area class="fit">
              <div class="q-pa-sm">
                <div class="q-table__title">Opções Avançadas</div>
                <div>
                  <q-list
                    class="rounded-borders"
                    style="max-width: 350px; margin-left: -10px; width: 300px"
                  >
                    <q-item-label header></q-item-label>

                    <q-item clickable v-ripple @click.exact="abrirOpcoes()">
                      <q-item-section>
                        <q-item-label lines="1">Opções</q-item-label>
                        <q-item-label caption>Configurações de folha de pagamentos</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </div>
            </q-scroll-area>
          </q-drawer>

        </div>
      </div>
      </div>

      <q-dialog v-model="openDialogFaturar" persistent>
        <q-card :style="`width: 100%; max-width: ${opcoesAvancadasFaturar ? '800' : '400'}px;`" class="q-pb-md">

            <q-card-section class="row items-center no-wrap">
              <div class="text-h6">
                Faturar
              </div>

              <q-space />

              <q-btn
                flat
                round
                dense
                v-close-popup
                icon="close"
                @click="closeModal()"
              />
            </q-card-section>

            <q-separator />

            <q-card-section>
              <div class="row q-col-gutter-md">
                <div :class="`col-12 col-md-${opcoesAvancadasFaturar ? '6' : '12'}`">
                  <q-input
                    bottom-slots
                    v-model="vencimento"
                    label="Data de Vencimento"
                    @click="$refs.qDateProxy.show()"
                    :error="errors.vencimento ? true : false"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          ref="qDateProxy"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="vencimento"
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            minimal
                            today-btn
                            @input="() => $refs.qDateProxy.hide()"
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.vencimento"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-input>

                  <q-input
                    label="Descrição das comissões"
                    v-model="descricao_comissoes"
                  />

                  <div class="row q-col-gutter-md">
                    <div class="col-6">
                      <q-input
                        bottom-slots
                        :error="errors.referencia ? true : false"
                        v-model="referencia"
                        label="Referência"
                        ref="referenciaDespesa"
                        mask="##/####"
                        :rules="[
                          val => !!val || 'Campo obrigatório',
                        ]"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errors.referencia"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </div>

                    <div class="col-6">
                      <q-field
                        v-model="totalComissaoSelecionado"
                        label="Valor"
                        bottom-slots
                        :disable="true"
                        class="q-pb-md"
                      >
                        <template
                          v-slot:control="{
                            id,
                            floatingLabel,
                            value,
                            emitValue
                          }"
                        >
                          <money
                            :id="id"
                            :value="value"
                            class="q-field__native text-right"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                            name="valorCaixa"
                            @input="emitValue"
                          >
                          </money>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.valor" :key="error + i">{{error}}</span>
                        </template>
                      </q-field>
                    </div>
                  </div>

                  <q-checkbox
                    v-model="incluirFormaPagamento"
                    color="green"
                    label="Incluir forma de pagamento"
                  />

                  <q-select
                    v-if="incluirFormaPagamento"
                    v-model="gatewayId"
                    label="Forma de pagamento"
                    :options="gateways"
                    option-label="titulo"
                    option-value="id"
                    :error="errors.gateway_id ? true : false"
                    clearable
                    emit-value
                    map-options
                    bottom-slots
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.gateway_id" :key="i">
                        {{ error }}
                      </span>
                    </template>
                  </q-select>

                  <div class="flex justify-start">
                    <q-checkbox
                      v-model="opcoesAvancadasFaturar"
                      color="green"
                      label="Opções Avançadas"
                    />
                  </div>
                </div>

                <div class="col-6" v-if="opcoesAvancadasFaturar">
                  <q-checkbox
                    label="Rateio"
                    v-model="utilizarRateio"
                  />

                  <div v-if="utilizarRateio">
                    <div class="row">
                      <div class="col-12">
                        <q-select
                          v-model="centroCustoRateio"
                          :options="optionsCentroCustos"
                          :option-label="value => (value === null ? 'Item Vazio' : value.nome)"
                          :option-value="value => (value === null ? null : value)"
                          :error="(errors.rateios || centrocustoErro != null)  ? true : false"
                          label="Centro de Custos"
                        >
                          <template v-slot:no-option>
                            <q-item>
                              <q-item-section class="text-grey">Sem Resultados</q-item-section>
                            </q-item>
                          </template>
                          <template v-slot:error>
                            <span>{{ centrocustoErro }}</span>
                            <span v-for="(error, i) in errors.rateios" :key="i">
                              {{ error }}
                            </span>
                          </template>
                        </q-select>
                      </div>

                      <div class="col-10 q-mt-md">
                        <q-field
                          v-model="porcentagemRateio"
                          label="Porcentagem"
                          :hide-bottom-space="centrocustoErro === null"
                          :error="porcentagemErro !== null"
                        >
                          <template
                            v-slot:control="{ id, floatingLabel, value, emitValue }"
                          >
                            <money
                              :id="id"
                              class="q-field__native text-right"
                              :value="value"
                              @input="emitValue"
                              v-bind="percentFormat"
                              v-show="floatingLabel"
                            >
                            </money>
                          </template>
                          <template v-slot:error>
                            <span>{{ porcentagemErro }}</span
                            >
                          </template>
                        </q-field>
                      </div>

                      <div class="col-2 flex items-center justify-center">
                        <q-btn
                          icon="add"
                          color="primary"
                          flat
                          round
                          @click="addRateio"
                        />
                      </div>

                      <div class="col-12 q-ml-sm q-mt-lg">
                        <p
                          v-if="porcentagemRateioTotal > 100"
                          class="text-negative caption"
                        >
                          A porcentagem total passa de 100%
                        </p>
                      </div>

                      <div class="col-12">
                        <q-markup-table dense flat>
                          <tbody>
                            <tr
                              v-for="(rateio, index) in rateios"
                              :key="index"
                            >
                              <td>{{ rateio.centrocusto.nome }}</td>
                              <td>{{ rateio.porcentagem }} %</td>
                              <td style="width: 30px;">
                                <q-btn
                                  flat
                                  dense
                                  color="red"
                                  icon="delete"
                                  @click="rateios.splice(index, 1)"
                                />
                              </td>
                            </tr>

                            <tr v-if="rateios.length > 0">
                              <td colspan="2"><strong>Total</strong></td>
                              <td class="text-right">{{ porcentagemRateioTotal }} %</td>
                            </tr>
                            <tr v-if="rateios.length === 0">
                              <td colspan="3" class="text-center">Nenhum rateio</td>
                            </tr>
                          </tbody>
                        </q-markup-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </q-card-section>

            <q-card-actions align="right" class="text-primary">
              <q-btn
                flat
                label="Faturar"
                clickable
                @click="faturarPagamento"
                id="salvar"
                class="bg-positive text-white"
                style="margin-right: 22px; margin-bottom: 10px"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
            </q-card-actions>

        </q-card>
      </q-dialog>

    <modal-opcoes-folha-pagamentos />
    <modal-add-comissoes-manual />
  </q-page>
</template>

<script>
import moment from 'moment';
import axios from '@/axios-auth';
import { bus } from '@/main';
import { mapGetters } from 'vuex';
import { QSlideTransition } from 'quasar';
import { Money } from "v-money";

export default {
  components: { QSlideTransition, Money },
  name: 'comissoes',
  meta: {
    title: 'DSB | Comissões',
  },
  data() {
    return {
      filter: {
        dtini: moment().startOf('month').format('DD/MM/YYYY'),
        dtfim: moment().endOf('month').format('DD/MM/YYYY'),
        status: '',
        cadastro_id: '',
        cliente_id: '',
        forceUpdate: false
      },
      referencia: moment().format('MM/YYYY'),
      liquidacao: moment().format('DD/MM/YYYY'),
      vencimento: moment().format("DD/MM/YYYY"),
      labelAll: 'Todos',
      descricao_comissoes: '',
      comissoesSelected: [],
      totalComissaoSelecionado: 0,
      countTotalComissaoSelecionado: 0,
      funcionarioItensSelected: [],
      loading: false,
      loadingNovoPagamento: false,
      loadingFecharPagamento: false,
      loadingAtualizarPagamento: false,
      openOpcoesAvancadas: false,
      loadingFaturarPagamento: false,
      cardInformacoesExpanded: true,
      columns: [
        {
          name: "id",
          label: "ID",
          align: "right",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'fornecedor',
          required: true,
          label: 'Funcionário/Fornecedor',
          align: 'left',
          field: row => row.id,
          sortable: true
        },
        {
          name: 'movimento',
          required: true,
          label: 'Movimento',
          align: 'left',
          field: row => row.dtmovimento,
          sortable: true
        },
        {
          name: 'cliente',
          required: true,
          label: 'Cliente',
          align: 'left',
          field: row => row.dtmovimento,
          sortable: true
        },
        {
          name: 'gerado',
          required: true,
          label: 'Gerado',
          align: 'left',
          field: row => row.gerado,
          sortable: true
        },
        {
          name: 'descricao',
          required: true,
          label: 'Descrição',
          align: 'left',
          field: row => row.descricao,
          sortable: true
        },
        {
          name: 'valor',
          required: true,
          label: 'Valor',
          align: 'left',
          field: row => row.valor,
          sortable: true
        },
      ],
      pagination: {
        rowsPerPage: 0
      },
      errors: [],
      planodeconta_id: '',
      openDialogFaturar: false,
      planoContasFilterResult: [],
      planosdecontasComissoes: [],
      allComissoes: false,
      first: true,
      opcoesAvancadasFaturar: false,
      incluirFormaPagamento: false,
      utilizarRateio: false,
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false,
      },
      centroCustoRateio: null,
      rateios: [],
      porcentagemRateio: 0,
      centrocustoErro: null,
      porcentagemErro: null,
      gatewayId: null,
      gateways: [],
      hasVideo: false
    };
  },
  methods: {
    calcSelecionados (){
      let total = 0;
      this.allComissoes = false;
      this.countTotalComissaoSelecionado = 0;
      this.totalComissaoSelecionado = 0.0;
      const f = this.comissoesSelected;
        for (var i = 0; i < f.length; i++) {
          if(f[i]['gerado'] !== true){
            this.totalComissaoSelecionado +=  parseFloat(f[i]['valor']);
            total += 1;
          }
        }
        this.countTotalComissaoSelecionado = total;
    },
    loadComissoes(forceUpdate = false) {
      this.allComissoes = false;
      if(forceUpdate){
        forceUpdate = true;
      }
      const dtini = moment(this.filter.dtini, 'DD/MM/YYYY');
      const dtfim = moment(this.filter.dtfim, 'DD/MM/YYYY');

      const params = {
        dtini: dtini.format("DD-MM-YYYY"),
        dtfim: dtfim.format("DD-MM-YYYY"),
        status: this.filter.status,
        forceUpdate: forceUpdate
      };

      this.$store.dispatch("filtrosComissoes", params);
      this.filter.forceUpdate = false;

      if(this.newHashFilter){
        this.comissoesSelected = [];
      }
    },
    notificarSemPermissao(res) {
      const semPermissao = res.error && res.error.response.status === 403;

      if (!semPermissao) return false;

      this.$q.notify({
        message: res.error.response.data.message,
        color: 'red',
        position: 'top-right',
      });

      return true;
    },
    changeFilterPeriodo(value) {
      const dtini = moment(this.filter.dtini, 'DD/MM/YYYY');
      const dtfim = moment(this.filter.dtfim, 'DD/MM/YYYY');
      const isLastDayDtini = dtini.format('DD') === dtini.clone().endOf('month').format('DD');
      const isLastDayDtfim = dtfim.format('DD') === dtfim.clone().endOf('month').format('DD');

      if (value === 'prev') {
        dtini.subtract(1, 'months');
        dtfim.subtract(1, 'months');
      } else {
        dtini.add(1, 'months');
        dtfim.add(1, 'months');
      }

      if (isLastDayDtini) {
        dtini.endOf('month');
      }
      if (isLastDayDtfim) {
        dtfim.endOf('month');
      }

      this.filter.dtini = dtini.format('DD/MM/YYYY');
      this.filter.dtfim = dtfim.format('DD/MM/YYYY');

    },
    faturarPagamento() {
      this.$q
        .dialog({
          title: 'Confirma a realização do faturamento?',
          cancel: 'Cancelar',
          persistent: true,
        })
        .onOk(() => {
          const data = {
            comissoes: this.comissoesSelected,
            referencia: this.referencia,
            vencimento: this.vencimento,
            descricao_comissoes: this.descricao_comissoes,
            planodeconta_id: this.planodeconta_id.id,
            allComissoes: this.allComissoes,
            filter: this.filtrosComissoes,
            utilizarRateio: this.utilizarRateio,
            incluirFormaPagamento: this.incluirFormaPagamento,
          };

          if (this.utilizarRateio){
            data.rateios = [];
            this.rateios.forEach(rateio => {
              data.rateios.push({
                centrocusto_id: rateio.centrocusto.id,
                porcentagem: rateio.porcentagem,
              });
            });
          }

          if (this.incluirFormaPagamento) {
            data.gateway_id = this.gatewayId;
          }

          this.loadingFaturarPagamento = true;
          this.errors = [];

          axios
            .post('/comissoes/pagamentos', data)
            .then(res => {
              this.loadingFaturarPagamento = false;

              if (this.notificarSemPermissao(res)) return;

              this.$q.notify({
                message: res.data.message,
                color: res.data.success ? 'green' : 'orange',
                position: 'top-right',
              });

              if (!res.data.success) {
                this.errors = res.data.errors;
                return;
              }

              this.comissoesSelected = [];
              this.descricao_comissoes = '';
              this.planodeconta_id = null;
              this.referencia = moment().format('MM/YYYY');
              this.vencimento = moment().format("DD/MM/YYYY");
              this.openDialogFaturar = false;
              this.loadComissoes(true);
            })
            .catch(() => {
              this.loadingFaturarPagamento = false;
              this.openDialogFaturar = false;
              this.$q.notify({
                message: 'Houve um erro ao faturar os pagamentos',
                color: 'red',
                position: 'top-right',
              });
            });
        });
    },
    abrirOpcoes() {
      bus.$emit('abrirOpcoesComissoes');
    },
    abrirAddComissoes() {
      bus.$emit('abrirAddComissoesManual');
    },
    editComissao(id) {
      bus.$emit('abrirEditComissoesManual', id);
    },
    openModalIntroducao() {
      this.$store.dispatch("loadManual", "comissoes");
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "comissoes");

      if (!this.videosManuais.video_comissoes) {
        this.openModalIntroducao();
      }
    },
    async confirmeDeleteComissao (value) {
      this.$q.dialog({
        title: 'Deseja excluir essa comissão?',
        cancel: "Cancelar",
        persistent: true,
      }).onOk(() => {
        this.deleteComissao(value);
      });
    },
    async deleteComissao(value) {
      await axios
        .post("/comissoes/delete/" + value, null)
        .then(res => {
          if (res.data.success) {

            this.loadComissoes(true);

            this.$q.notify({
              message: res.data.message,
              color: "green",
              position: "top-right"
            });

            return true;
          } else {
            this.errors = res.data.errors;
            this.$q.notify({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });

          }
        })
        .catch(() => {
          this.$q.notify({
            message:
              "Não foi possível contactar ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    clearModal() {
      this.errors = {};
      this.valuePC = null;
      this.utilizarRateio = false;
      this.descricao_comissoes = '';
      this.opcoesAvancadasFaturar = false;
      this.incluirFormaPagamento = false;
      this.gatewayId = null;
      this.rateios = [];
      this.porcentagemRateio = 0;
      this.centrocustoErro = null;
      this.porcentagemErro = null;
    },
    closeModal() {
      this.clearModal();
      this.openDialogFaturar = false;
    },
    openModal() {
      this.clearModal();
      this.openDialogFaturar = true;
    },
    planoContasFilter(val, update) {
      update(() => {
        this.planoContasFilterResult = this.planosdecontasComissoes.filter(
          plano => plano.titulo.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
    loadPlanosdecontas() {
      axios
        .get('/plano-contas?cd=D')
        .then(res => {
          this.planosdecontasComissoes = res.data.planodecontas;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },

    changeFilterStatus(val){
      this.filter.status = val;
    },
    handleScroll() {
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.loadComissoes();
      }
    },
    updateDataUrl() {
      const dtIni = moment(this.filtrosComissoes.dtini, 'DD-MM-YYYY');
      const dtFim = moment(this.filtrosComissoes.dtfim, 'DD-MM-YYYY');

      if(this.filter.dtini !== dtIni.format("DD/MM/YYYY")){
        this.filter.dtini = dtIni.format("DD/MM/YYYY");
      }
      if(this.filter.dtfim !== dtFim.format("DD/MM/YYYY")){
        this.filter.dtfim = dtFim.format("DD/MM/YYYY");
      }
    },
    addRateio() {
      this.centrocustoErro = null;
      this.porcentagemErro =  null;

      if (!this.centroCustoRateio) {
        if (this.errors.rateios) this.errors.rateios = null;
        this.centrocustoErro = "Selecione um centro de custo";
        return;
      }

      if (!this.porcentagemRateio) {
        this.porcentagemErro = "Informe a porcentagem";
        return;
      }

      if (this.porcetagenRateio < 0 || this.porcentagemRateio > 100) {
        this.porcentagemErro = "Valor inválido";
        return;
      }

      const rateioIndex = this.rateios.findIndex(rateio => rateio.centrocusto.id === this.centroCustoRateio.id);
      const rateio = {
        porcentagem: Math.abs(+this.porcentagemRateio),
        centrocusto: {
          id: this.centroCustoRateio.id,
          nome: this.centroCustoRateio.nome,
        }
      };

      if (rateioIndex === -1) {
        this.rateios.push(rateio);
      } else {
        this.rateios[rateioIndex] = rateio;
      }

      this.centroCustoRateio = null;
      this.porcentagemRateio = 0;
    },
    findGateways() {
      axios
        .get("/gateways?ativo=true&gateways_opts=true&despesa=1")
        .then(res => {
          this.gateways = res.data.gateways;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível trazer as formas de pagamento, ou não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
  },
  computed: {
    ...mapGetters([
      "permiteVisualizarComissoes",
      "centroCustos",
      "videosManuais",
      "manuaisDisponiveis"
    ]),
    comissoes () {
     return this.$store.state.comissoes.comissoes;
    },
    filtrosComissoes () {
     return this.$store.state.comissoes.filtrosComissoes;
    },
    totalComissoesAbertasValor () {
     return this.$store.state.comissoes.totalComissoesAbertasValor;
    },
    totalComissoesAbertasItens () {
     return this.$store.state.comissoes.totalComissoesAbertasItens;
    },
    totalComissoesGeradasValor () {
     return this.$store.state.comissoes.totalComissoesGeradasValor;
    },
    totalComissoesGeradasItens () {
     return this.$store.state.comissoes.totalComissoesGeradasItens;
    },
    totalComissoesValor () {
     return this.$store.state.comissoes.totalComissoesValor;
    },
    totalComissoesItens () {
     return this.$store.state.comissoes.totalComissoesItens;
    },
    totalGrid () {
     return this.$store.state.comissoes.totalGrid;
    },
    totalLiquidados () {
     return this.$store.state.comissoes.totalLiquidados;
    },
    buscandoComissao () {
     return this.$store.state.comissoes.buscandoComissao;
    },
    newHashFilter () {
     return this.$store.state.comissoes.newHashFilter;
    },
    pagingComissoes () {
     return this.$store.state.comissoes.pagingComissoes;
    },
    validationReferencia() {
      return [val => (val && moment(val, 'MM/YYYY').isValid()) || 'Formato inválido'];
    },
    ValorTotalGrid() {
      //return this.comissoes.filter(({gerado}) => gerado !== true).reduce((total, row) => parseFloat(row.valor) + total, 0.00);
      return this.comissoes.reduce((total, row) => parseFloat(row.valor) + total, 0.00);
    },
    porcentagemRateioTotal() {
      return this.rateios.reduce((total, rateio) => total + Math.abs(+rateio.porcentagem), 0);
    },
    optionsCentroCustos() {
      return this.centroCustos.filter(centroCusto => {
        return !(this.rateios.find(rateio => rateio.centrocusto.id == centroCusto.id));
      });
    }
  },
  watch: {
    filter: {
      handler () {
       this.loadComissoes();
      },
      deep: true,
    },
    comissoesSelected: {
      handler () {
        this.calcSelecionados();
      },
      deep: true,
    },
    filtrosComissoes: {
      handler () {
        this.updateDataUrl();
      },
      deep: true,
    }
  },
  mounted() {
    this.loadComissoes();
    this.verificarPrimeiroVideo();
    this.loadPlanosdecontas();
    this.findGateways();
    this.$store.dispatch("listarCentroCustos");
    bus.$on('AtualizarComissoes', () => this.loadComissoes(true));
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    bus.$off('AtualizarComissoes');
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="stylus" scoped>
.q-card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.q-card:not(.cardInformacoesExpanded) {
  width: 41px;

  .total-info {
    width 100vh;
    visibility: hidden !important;
  }
}

.q-card.cardInformacoesExpanded {
  width: 90%;
  max-width: 400px;

  .absolute-right {
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
}

.fixed-middle {
  left: 50%;
  transform: translateX(-50%);
}
</style>
