<template>
  <div id="tourResponsavel"></div>
</template>

<script>
export default {
  name: "tour-responsavel"
};
</script>

<style lang="stylus" scoped></style>
