<template>
  <div id="opAvancadasCadastros">
    <h1>Oi Cadastros</h1>
  </div>
</template>

<script>
export default {
  name: 'opAvancadasCadastros'
};
</script>

<style lang="scss">
</style>
