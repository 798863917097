<template>
  <div id="modalCaixaSaldoFinal">
    <q-dialog v-model="OpenDialogSaldoFinalCaixas" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Saldo Final</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="clear()" />
        </q-card-section>
        <q-separator />
        <acao-caixa></acao-caixa>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "modal-caixa-saldo-final",
  methods: {
    save() {
      this.saving = true;
      this.$store.commit("fechaCaixa", true);
      setTimeout(() => {
        this.maos = true;
        this.$store.commit("fechaCaixa", false);
        this.$store.commit("lobbyCaixas", true);
        this.$store.commit("caixa", []);
        this.$store.commit("isSaldoInicial", false);
        this.$store.commit("isSaldoFinal", false);
        this.$store.commit("valoresTotais", {
          saldoinicial: 0,
          totalentrada: 0,
          totalsaida: 0,
          totalfiado: 0,
          saldofinal: 0
        });
        swal("Caixa finalizado com sucesso!", {
          icon: "success"
        });
        this.$store.commit("OpenDialogSaldoFinalCaixas", false);
        this.saving = false;
      }, 2000);
    },
    clear() {
      this.maos = true;
      this.maosValue = 0;
      this.caixaValue = 0;
      this.saving = false;
      this.$store.commit("errorsCaixas", {});
      this.$store.commit("OpenDialogSaldoFinalCaixas", false);
    }
  },
  computed: {
    ...mapGetters(["valoresTotais"]),
    OpenDialogSaldoFinalCaixas: {
      get() {
        return this.$store.getters.OpenDialogSaldoFinalCaixas;
      },
      set() {}
    }
  },
  mounted() {
    document.addEventListener("keyup", event => {
      if (event.keyCode === 27) {
        this.clear();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener("keyup");
    next();
  }
};
</script>

<style lang="stylus" scoped></style>
