<template>
  <div>
    <div
      class="q-updloader"
      style="width: 100%"
      @drop="evt => handleFileUpload(evt)"
      @dragover="evt => evt.preventDefault()"
      @dragleave="evt => evt.preventDefault()"
    >
      <div class="q-uploader__header text-center">
        <!-- Logo da empresa title -->
        <div class="text-h6 text-bold">{{ title }}</div>
      </div>

      <div style="height: 120px; border: 1px dashed #ccc; border-radius: 5px;">
        <div
          v-if="!hasImage"
          class="flex column justify-center items-center cursor-pointer"
          style="height: 120px; border: 1px dashed #ccc; border-radius: 5px;"
          @click="$refs.file.click()"
        >
          <i class="fa fa-picture-o fa-3x text-grey-5" />
          <p class="text-caption q-mt-sm">
            Arraste a imagem para cá ou clique para selecionar
          </p>
        </div>
        <div v-else-if="hasImage && imageUrl !== ''" class="relative-position">
          <div>
            <q-btn
              class="absolute-top-right"
              icon="fa fa-trash"
              @click="removeImage"
              flat
            >
              <q-tooltip>Remover Logo</q-tooltip>
            </q-btn>
          </div>
          <div class="flex justify-center">
            <img :src="imageUrl" style="width: 50%;" />
          </div>
        </div>
      </div>

      <input type="file" ref="file" style="display: none;" @change="evt => handleFileUpload(evt)">
    </div>
  </div>
</template>

<script>
import {Loading} from "quasar";

export default {
  name: 'image-input',
  emits: ['fileUploaded', 'fileRemoved'],
  props: {
    title: {
      required: true,
      type: String
    },
    hasImage: {
      required: true,
      type: Boolean
    },
    imageUrl: {
      required: false,
      type: String
    },
    loading: {
      required: false,
      type: Boolean
    }
  },
  methods: {
    handleFileUpload(event) {
      event.preventDefault();

      const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
      this.$emit('fileUploaded', file);
      event.target.value = '';
    },
    removeImage() {
      this.$emit('fileRemoved');
    }
  },
  watch: {
    loading(value) {
      if (value) {
        Loading.show();
        return;
      }
      Loading.hide();
    }
  }
};
</script>

<style scoped>

</style>
