<template>
  <q-page id="saas">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <div class="col-12 row items-center" style="margin-left: 10px; padding-right: 20px">
          <q-btn-group flat>
            <q-btn
              flat
              round
              icon="chevron_left"
              class="white text-blue-7"
              clickable
              @click="changeMonth('prev')"
              :disable="loading"
            />
            <q-btn flat :label="returnMonth()" class="white text-blue-7" />
            <q-btn
              flat
              round
              icon="chevron_right"
              class="white text-blue-7"
              clickable
              @click="changeMonth('next')"
              :disable="loading || !nextMonth"
            />
          </q-btn-group>

          <q-toggle v-model="compararPeriodo" label="Comparar período" class="q-pr-md" />

          <q-btn-group v-show="compararPeriodo" flat>
            <q-btn
              flat
              round
              icon="chevron_left"
              class="white text-blue-7"
              clickable
              @click="changeMonth('prev', true)"
              :disable="loading"
            />
            <q-btn flat :label="returnMonth(true)" class="white text-blue-7" />
            <q-btn
              flat
              round
              icon="chevron_right"
              class="white text-blue-7"
              clickable
              @click="changeMonth('next', true)"
              :disable="loading || !nextMonth"
            />
          </q-btn-group>

          <q-space />

          <q-btn
            flat
            dense
            :icon="$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'"
            class="text-black"
            clickable
            @click="toogleFullscreen()"
          >
            <template v-slot:default>
              <q-tooltip>Tela Cheia</q-tooltip>
            </template>
          </q-btn>
        </div>

        <div class="col-12 col-md-12 text-center" v-if="loading">
          <q-spinner color="blue-7" size="4em" />
        </div>

        <div class="col-12">
          <div class="row q-col-gutter-md">

            <div class="col-12 col-md-4">
              <q-card>
               <card-relatorio-totalizadores-loterica :totalizadores="totalizadores"></card-relatorio-totalizadores-loterica>
              </q-card>
            </div>

            <div class="col-12 col-md-4">
              <q-card>
                <card-caixas-abertos-loterica :caixasAbertos="caixasAbertos"></card-caixas-abertos-loterica>
              </q-card>
            </div>

            <div class="col-12 col-md-4">
              <q-card>
                <card-estoque-loterica class="card-dashboard" :estoqueEmpresa=estoqueEmpresa ></card-estoque-loterica>
              </q-card>
            </div>

          </div>
          <div class="row q-col-gutter-md q-pt-md">

            <!-- Grafico Quantidade de Serviços -->
            <div class="col-12 col-md-4">
              <q-card>
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-h6">Quantidade de Serviços</div>

                  <q-space />
                  <div v-if="!compararPeriodo" class="q-gutter-sm">
                    <q-toggle v-model="graficoQtServicosOptions.isStacked" label="Agrupar" left-label />
                  </div>

                  <q-space />

                  <q-btn
                    flat
                    dense
                    rounded
                    clickable
                    icon="filter_list"
                    :class="`text-blue-7 q-mr-sm ${showQtServicosFilter ? 'bg-grey-3' : ''}`"
                    @click="showQtServicosFilter = !showQtServicosFilter"
                  >
                    <q-tooltip>Exibir Filtros</q-tooltip>
                  </q-btn>

                  <q-btn
                    flat
                    dense
                    icon="print"
                    rounded
                    class="text-blue-7 q-mr-sm"
                    clickable
                    @click="printChart('grafQtServicos')"
                  />

                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      Quantidades Total
                    </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pa-none">
                  <div v-if="showQtServicosFilter" class="row">
                    <div class="col-12 q-pl-md q-pr-md">
                      <q-select
                        v-model="operadoresQtServicosFilter"
                        :options="operadoresQtServicosList"
                        emit-value
                        map-options
                        multiple
                        label="Filtrar Operadores"
                      >
                        <template v-slot:append>
                          <q-icon
                            v-if="operadoresQtServicosFilter !== null"
                            class="cursor-pointer"
                            name="cancel"
                            @click.stop="operadoresQtServicosFilter = null"
                          />
                        </template>
                      </q-select>

                    </div>
                    <div class="col-12 q-pl-md q-pr-md">
                      <q-select
                        v-model="produtosQtServicosFilter"
                        :options="produtosList"
                        emit-value
                        map-options
                        multiple
                        label="Produtos, Loterias e Serviços"
                      >
                        <template v-slot:append>
                            <q-icon
                              v-if="produtosQtServicosFilter !== null"
                              class="cursor-pointer"
                              name="cancel"
                              @click.stop="produtosQtServicosFilter = null"
                            />
                          </template>
                      </q-select>
                    </div>
                    <div class="col-12 q-pl-md q-pr-md">
                      <q-select
                        v-model="tagsQtServicosFilter"
                        :options="produtosTagsList"
                        emit-value
                        map-options
                        multiple
                        use-chips
                        label="Filtrar Produtos por Tags"
                      >
                        <template v-slot:append>
                          <q-icon
                            v-if="tagsQtServicosFilter !== null"
                            class="cursor-pointer"
                            name="cancel"
                            @click.stop="tagsQtServicosFilter = null"
                          />
                        </template>
                      </q-select>
                    </div>
                  </div>
                </q-card-section>
                <q-card-section v-if="grafQtServicosActive" class="q-pa-none">
                  <GChart
                    id="grafQtServicos"
                    :type="compararPeriodo ? 'LineChart' : 'ColumnChart'"
                    :data="graficoQtServicosData"
                    :options="graficoQtServicosOptions"
                    @ready="(el, google) => onChartReady(el, google, 'grafQtServicos', 'Quantidade de Serviços')"
                  />
                </q-card-section>
                <q-card-section v-else>
                  <p class="text-center q-mt-lg">Não há dados</p>
                </q-card-section>

              </q-card>
            </div>

            <!-- Grafico Vendas -->
            <div class="col-12 col-md-4">
              <q-card>
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-h6">Vendas Total</div>

                  <q-space />
                  <div v-if="!compararPeriodo" class="q-gutter-sm">
                    <q-toggle v-model="graficoVendasOptions.isStacked" label="Agrupar" left-label />
                  </div>

                  <q-space />

                  <q-btn
                    flat
                    dense
                    rounded
                    clickable
                    icon="filter_list"
                    :class="`text-blue-7 q-mr-sm ${showVendasFilter ? 'bg-grey-3' : ''}`"
                    @click="showVendasFilter = !showVendasFilter"
                  >
                    <q-tooltip>Exibir Filtros</q-tooltip>
                  </q-btn>

                  <q-btn
                    flat
                    dense
                    icon="print"
                    rounded
                    class="text-blue-7 q-mr-sm"
                    clickable
                    @click="printChart('grafVendas')"
                  />

                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      Vendas totais dos últimos 6 meses
                    </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pa-none">
                  <div v-if="showVendasFilter" class="row">
                      <div class="col-12 q-pl-md q-pr-md">
                        <q-select
                        v-model="operadoresVendasFilter"
                        :options="operadoresVendasList"
                        emit-value
                        map-options
                        multiple
                        label="Filtrar Operadores"
                        >
                        <template v-slot:append>
                          <q-icon
                            v-if="operadoresVendasFilter !== null"
                            class="cursor-pointer"
                            name="cancel"
                            @click.stop="operadoresVendasFilter = null"
                          />
                        </template>
                        </q-select>
                      </div>
                      <div class="col-12 q-pl-md q-pr-md">

                        <q-select
                        v-model="produtosVendasFilter"
                        :options="produtosList"
                        emit-value
                        map-options
                        multiple
                        label="Produtos, Loterias e Serviços"
                        >
                        <template v-slot:append>
                          <q-icon
                            v-if="produtosVendasFilter !== null"
                            class="cursor-pointer"
                            name="cancel"
                            @click.stop="produtosVendasFilter = null"
                          />
                        </template>
                        </q-select>

                      </div>
                      <div class="col-12 q-pl-md q-pr-md">
                        <q-select
                          v-model="tagsVendasFilter"
                          :options="produtosTagsList"
                          emit-value
                          map-options
                          multiple
                          use-chips
                          label="Filtrar Produtos por Tags"
                        >
                          <template v-slot:append>
                            <q-icon
                              v-if="tagsVendasFilter !== null"
                              class="cursor-pointer"
                              name="cancel"
                              @click.stop="tagsVendasFilter = null"
                            />
                          </template>
                        </q-select>
                      </div>
                    </div>
                </q-card-section>
                <q-card-section v-if="grafVendasActive" class="q-pa-none">
                  <GChart
                    :type="compararPeriodo ? 'LineChart' : 'ColumnChart'"
                    :data="graficoVendasData"
                    :options="graficoVendasOptions"
                    @ready="(el, google) => onChartReady(el, google, 'grafVendas', 'Vendas Total')"
                  />
                </q-card-section>
                <q-card-section v-else>
                  <p class="text-center q-mt-lg">Não há dados</p>
                </q-card-section>

              </q-card>
            </div>

            <!-- Grafico Lucro -->
            <div class="col-12 col-md-4">
              <q-card>
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-h6">Lucro Total</div>

                  <q-space />
                  <div v-if="!compararPeriodo" class="q-gutter-sm">
                    <q-toggle v-model="graficoLucratividadeOptions.isStacked" label="Agrupar" left-label />
                  </div>

                  <q-space />

                  <q-btn
                    flat
                    dense
                    rounded
                    clickable
                    icon="filter_list"
                    :class="`text-blue-7 q-mr-sm ${showLucrosFilter ? 'bg-grey-3' : ''}`"
                    @click="showLucrosFilter = !showLucrosFilter"
                  >
                    <q-tooltip>Exibir Filtros</q-tooltip>
                  </q-btn>

                  <q-btn
                    flat
                    dense
                    icon="print"
                    rounded
                    class="text-blue-7 q-mr-sm"
                    clickable
                    @click="printChart('grafLucros')"
                  />

                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      Lucros Totais dos últimos 6 meses
                    </q-tooltip>
                  </q-icon>
                </q-card-section>
                 <q-card-section class="q-pa-none">
                  <div v-if="showLucrosFilter" class="row">
                      <div class="col-12 q-pl-md q-pr-md">
                        <q-select
                        v-model="operadoresLucrosFilter"
                        :options="operadoresLucrosList"
                        emit-value
                        map-options
                        multiple
                        label="Filtrar Operadores"
                        >
                        <template v-slot:append>
                          <q-icon
                            v-if="operadoresLucrosFilter !== null"
                            class="cursor-pointer"
                            name="cancel"
                            @click.stop="operadoresLucrosFilter = null"
                          />
                        </template>
                        </q-select>

                      </div>
                      <div class="col-12 q-pl-md q-pr-md">

                        <q-select
                        v-model="produtosLucrosFilter"
                        :options="produtosList"
                        emit-value
                        map-options
                        multiple
                        label="Produtos, Loterias e Serviços"
                        >
                        <template v-slot:append>
                          <q-icon
                            v-if="produtosLucrosFilter !== null"
                            class="cursor-pointer"
                            name="cancel"
                            @click.stop="produtosLucrosFilter = null"
                          />
                        </template>
                        </q-select>

                      </div>
                      <div class="col-12 q-pl-md q-pr-md">
                        <q-select
                          v-model="tagsLucrosFilter"
                          :options="produtosTagsList"
                          emit-value
                          map-options
                          multiple
                          use-chips
                          label="Filtrar Produtos por Tags"
                        >
                          <template v-slot:append>
                            <q-icon
                              v-if="tagsLucrosFilter !== null"
                              class="cursor-pointer"
                              name="cancel"
                              @click.stop="tagsLucrosFilter = null"
                            />
                          </template>
                        </q-select>
                      </div>
                  </div>
                </q-card-section>
                <q-card-section v-if="grafLucrosActive" class="q-pa-none">
                  <GChart
                    :type="compararPeriodo ? 'LineChart' : 'ColumnChart'"
                    :data="graficoLucratividadeData"
                    :options="graficoLucratividadeOptions"
                    @ready="(el, google) => onChartReady(el, google, 'grafLucros', 'Lucro Total')"
                  />
                </q-card-section>
                <q-card-section v-else>
                  <p class="text-center q-mt-lg">Não há dados</p>
                </q-card-section>

              </q-card>
            </div>

            <!-- Grafico Diferença Operador -->
            <div class="col-12 col-md-6">
              <q-card>
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-h6">Faltas de Caixa Mensal</div>
                  <q-space />

                  <q-space />
                  <div v-if="!compararPeriodo" class="q-gutter-sm">
                    <q-toggle v-model="graficoDiferencaCaixaOperadorOptions.isStacked" label="Agrupar" left-label />
                  </div>

                  <q-btn
                    flat
                    dense
                    icon="print"
                    rounded
                    class="text-blue-7 q-mr-sm"
                    clickable
                    @click="printChart('grafDiferencaCaixaOperador')"
                  />

                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      Faltas de Caixas, últimos 6 meses
                    </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pa-none">
                  <div class="row">
                      <div class="col-12 col-md-6 offset-6 q-pr-md">
                        <q-select
                          dense
                          v-model="operadoresFilter"
                          :options="operadoresList"
                          emit-value
                          map-options
                          multiple
                          label="Filtrar Operadores"
                          >
                    <template v-slot:append>
                      <q-icon
                        v-if="operadoresFilter !== null"
                        class="cursor-pointer"
                        name="cancel"
                        @click.stop="operadoresFilter = null"
                      />
                    </template>

                  </q-select>
                      </div>
                  </div>
                </q-card-section>
                <div v-if="grafDiferencaCaixaOperadorActive" class="q-pa-none">
                  <GChart
                    :type="compararPeriodo ? 'LineChart' : 'ColumnChart'"
                    :data="graficoDiferencaCaixaOperadorData"
                    :options="graficoDiferencaCaixaOperadorOptions"
                    @ready="(el, google) => onChartReady(el, google, 'grafDiferencaCaixaOperador', 'Faltas de Caixa Mensal')"
                  />
                </div>
                <q-card-section v-else>
                  <p class="text-center q-mt-lg">Não há dados</p>
                </q-card-section>

              </q-card>
            </div>

            <!-- Grafico Diferença Operador Diário-->
            <div class="col-12 col-md-6">
              <q-card>
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-h6">Quebra de Caixas Diário</div>
                  <q-space />

                  <q-space />
                  <div class="q-gutter-sm">
                    <q-toggle v-model="graficoDiferencaCaixaOperadorDiarioOptions.isStacked" label="Agrupar" left-label />
                  </div>

                  <q-btn
                    flat
                    dense
                    icon="print"
                    rounded
                    class="text-blue-7 q-mr-sm"
                    clickable
                    @click="printChart('grafDiferencaCaixaOperadorDiario')"
                  />

                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      Quebra de Caixas diário do mês de referência,<br>O valor da Falta é negativo,<br>O Valor da Sobra é Positivo.
                    </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pa-none">
                  <div class="row">
                    <div class="col-12 col-md-6 offset-6 q-pr-md">
                      <q-select
                        dense
                        v-model="operadoresDiarioFilter"
                        :options="operadoresList"
                        emit-value
                        map-options
                        multiple
                        label="Filtrar Operadores"
                      >
                        <template v-slot:append>
                          <q-icon
                            v-if="operadoresDiarioFilter !== null"
                            class="cursor-pointer"
                            name="cancel"
                            @click.stop="operadoresDiarioFilter = null"
                          />
                        </template>
                      </q-select>
                    </div>
                  </div>
                </q-card-section>
                <div v-if="grafDiferencaCaixaOperadorDiarioActive" class="q-pa-none">
                  <GChart
                    type="ColumnChart"
                    :data="graficoDiferencaCaixaOperadorDiarioData"
                    :options="graficoDiferencaCaixaOperadorDiarioOptions"
                    @ready="(el, google) => onChartReady(el, google, 'grafDiferencaCaixaOperadorDiario', 'Quebra de Caixas Diário')"
                  />
                </div>
                <q-card-section v-else>
                  <p class="text-center q-mt-lg">Não há dados</p>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>
    </div>
    </div>

  </q-page>
</template>

<script>
import axios from '@/axios-auth';
import moment from 'moment';
import { GChart } from 'vue-google-charts';
import { bus } from '@/main';

function wrapCsvValue(value) {
  let formatted = value === void 0 || value === null ? '' : String(value);
  formatted = formatted.split('"').join('""');
  formatted = formatted.replaceAll('ã', 'a').replaceAll('ç', 'c').replaceAll('é', 'e');
  return `"${formatted}"`;
}

export default {
  meta: {
    title: 'DSB | Dashboard Lotérica',
  },
  components: {
    GChart,
  },
  data() {
    return {
      // Filtros
      referencia: moment().format('MM/YYYY'),
      compararPeriodo: false,
      referenciaComparar: moment().subtract(1, 'year').format('YYYY'),
      // Métricas
      loading: false,
      nextMonth: false,
      dados: {},
      semPermissao: false,
      pagination: {
        sortBy: 'mes',
        descending: true,
        rowsPerPage: 0,
        page: 1,
      },
      openOpcoesAvancadas: false,
      quantidadeMesesTabela: [3, 6, 9, 12].includes(
        parseInt(localStorage.getItem('metricas_saas__quantidade_meses_tabela'))
      )
        ? parseInt(localStorage.getItem('metricas_saas__quantidade_meses_tabela'))
        : 12,
      opcoesMesesTabela: [
        {
          label: '12 Meses',
          value: 12,
        },
        {
          label: '9 Meses',
          value: 9,
        },
        {
          label: '6 Meses',
          value: 6,
        },
        {
          label: '3 Meses',
          value: 3,
        },
      ],
      dadosCopy: {},
      graficoQtServicosOptions: {
        legend: { position: 'top', maxLines: 3 },
        height: 400,
        bar: {groupWidth: "75%"},
        isStacked: true,
      },
      graficoLucratividadeOptions: {
        legend: { position: 'top', maxLines: 3 },
        vAxis: {format:'R$ ###,###,###.##'},
        height: 400,
        bar: {groupWidth: "75%"},
        isStacked: true,
      },
      graficoDiferencaCaixaOperadorOptions: {
        legend: { position: 'top', maxLines: 3 },
        vAxis: {format:'R$ ###,###,###.##'},
        height: 400,
        bar: {groupWidth: "75%"},
        isStacked: true,
      },
      graficoDiferencaCaixaOperadorDiarioOptions: {
        legend: { position: 'top', maxLines: 3 },
        vAxis: {format:'R$ ###,###,###.##'},
        height: 400,
        bar: {groupWidth: "75%"},
        isStacked: true,
      },
      graficoVendasOptions: {
        legend: { position: 'top', maxLines: 3 },
        height: 400,
        bar: {groupWidth: "75%"},
        isStacked: true,
        vAxis: {format:'R$ ###,###,###.##'},
        numberFormat: {
          decimalSymbol: ',',
          groupingSymbol: '.',
          prefix: 'R$ '
        }
      },
      localeFormat: {
        style: 'currency',
        currency: 'BRL',
      },
      tiposDetalhamento: {},
      opcoesRecorrencias: {},
      // Autocomplete
      carregandoCadastro: false,
      optionsCadastros: [],
      topFiveVendas: [],
      cadastro: null,
      totalizadores: [],
      caixasAbertos: [],
      estoqueEmpresa: [],
      tipoVendas: null,
      tipoVendasList: [
        {
          value: 'P',
          label: 'Produtos'
        },
        {
          value: 'L',
          label: 'Loterias'
        },
        {
          value: 'S',
          label: 'Serviços'
        }
      ],
      grafVendas: [],
      grafVendasActive: false,
      operadoresVendasList: null,
      produtosVendasFilter: null,
      operadoresVendasFilter: null,
      tagsVendasFilter: null,
      grafDiferencaCaixaOperador: [],
      grafDiferencaCaixaOperadorActive: false,
      grafDiferencaCaixaOperadorDiario: [],
      grafDiferencaCaixaOperadorDiarioActive: false,
      produtosList: null,
      produtosTagsList: null,
      servicosList: null,
      operadoresList: null,
      operadoresFilter: null,
      operadoresDiarioFilter: null,
      grafLucros: [],
      grafLucrosActive: false,
      operadoresLucrosList: null,
      operadoresLucrosFilter: null,
      produtosLucrosFilter: null,
      tagsLucrosFilter: null,
      grafQtServicos: [],
      grafQtServicosActive: false,
      operadoresQtServicosList: null,
      operadoresQtServicosFilter: null,
      produtosQtServicosFilter: null,
      tagsQtServicosFilter: null,
      showQtServicosFilter: false,
      showVendasFilter: false,
      showLucrosFilter: false,
      chartsImg: {
        grafVendas: {
          title: '',
          url: '',
        },
        grafLucros: {
          title: '',
          url: '',
        },
        grafQtServicos: {
          title: '',
          url: '',
        },
        grafDiferencaCaixaOperador: {
          title: '',
          url: '',
        },
        grafDiferencaCaixaOperadorDiario: {
          title: '',
          url: '',
        }
      }
    };
  },
  methods: {
    returnMonth(comparedDate = false) {
      let format = this.compararPeriodo ? 'YYYY' : 'MMM/YYYY';
      let date = comparedDate ? this.referenciaComparar : this.referencia;
      let inputFormat = this.compararPeriodo ? 'YYYY' : 'MM/YYYY';

      return moment(date, inputFormat).format(format);
    },
    changeMonth(value, comparar = false) {
      let format = 'MM/YYYY';
      let unit = 'month';

      if (this.compararPeriodo) {
        format = 'YYYY';
        unit = 'year';
        if (comparar) {
          this.referenciaComparar = moment(this.referenciaComparar, format)
            .add(value === 'prev' ? -1 : 1, unit)
            .format(format);
          return;
        }
      }

      this.referencia = moment(this.referencia, format)
        .add(value === 'prev' ? -1 : 1, unit)
        .format(format);
    },
    loadLoterias(dados = 'all') {
      if (this.loading) {
        return;
      }

      this.loading = true;

      const params = {
        referencia: this.referencia.replace(/\//g, '-'),
        referenciaComparar: this.compararPeriodo ? this.referenciaComparar.replace(/\//g, '-') : undefined,
        operadores: this.operadoresFilter ? this.operadoresFilter.join(',') : undefined,
        operadoresDiario: this.operadoresDiarioFilter ? this.operadoresDiarioFilter.join(',') : undefined,
        tipoVendas: this.tipoVendas,
        operadoresVendas: this.operadoresVendasFilter ? this.operadoresVendasFilter.join(',') : undefined,
        produtosVendasFilter: this.produtosVendasFilter ? this.produtosVendasFilter.join(',') : undefined,
        tagsVendasFilter: this.tagsVendasFilter ? this.tagsVendasFilter.join(',') : undefined,
        operadoresLucros: this.operadoresLucrosFilter ? this.operadoresLucrosFilter.join(',') : undefined,
        produtosLucrosFilter: this.produtosLucrosFilter ? this.produtosLucrosFilter.join(',') : undefined,
        operadoresQtServicosFilter: this.operadoresQtServicosFilter ? this.operadoresQtServicosFilter.join(',') : undefined,
        produtosQtServicosFilter: this.produtosQtServicosFilter ? this.produtosQtServicosFilter.join(',') : undefined,
        tagsQtServicosFilter: this.tagsQtServicosFilter ? this.tagsQtServicosFilter.join(',') : undefined,
        tagsLucrosFilter: this.tagsLucrosFilter ? this.tagsLucrosFilter.join(',') : undefined,
        tagsVendasFilter: this.tagsVendasFilter ? this.tagsVendasFilter.join(',') : undefined,
        dadosUpdate: dados
      };

      if(dados == 'vendas' || dados == 'all') {
        this.grafVendas = null;
        this.grafVendasActive = false;
      }
      if(dados == 'lucros' || dados == 'all') {
        this.grafLucros = null;
        this.grafLucrosActive = false;
      }
      if(dados == 'quantidades' || dados == 'all') {
        this.grafQtServicos = null;
        this.grafQtServicosActive = false;
      }
      if(dados == 'operador' || dados == 'all') {
        this.grafDiferencaCaixaOperadorActive = false;
        this.grafDiferencaCaixaOperador = null;
      }
      if(dados == 'operadorDiario' || dados == 'all') {
        this.grafDiferencaCaixaOperadorDiarioActive = false;
        this.grafDiferencaCaixaOperadorDiario = null;
      }

      axios
        .get('/dashboard-lotericas', { params })
        .then(res => {
          this.loading = false;

          if (!res.data.success) {
            this.$q.notify({
              color: 'orange',
              message: res.data.message,
              position: 'top-right',
            });
            return;
          }

          if(dados == 'all') {
            this.totalizadores = JSON.parse(JSON.stringify(res.data.relatorioTotalizadores));
            this.caixasAbertos = JSON.parse(JSON.stringify(res.data.caixasAbertos));
            this.estoqueEmpresa = JSON.parse(JSON.stringify(res.data.estoqueEmpresa));
            this.nextMonth = res.data.nextMonth;

            if (this.produtosList === null) {
              this.produtosList = res.data.produtosList;
            }

            if (this.produtosTagsList === null) {
              this.produtosTagsList = res.data.produtosTagsList;
            }

            if (this.servicosList === null) {
              this.servicosList = res.data.servicosList;
            }
          }

          if(dados == 'vendas' || dados == 'all') {
            this.grafVendas = JSON.parse(JSON.stringify(res.data.vendas.dataGrafico));
            if (this.grafVendas[0][1]) {
              this.grafVendasActive = true;
            }
            if (this.operadoresVendasList === null){
              this.operadoresVendasList = JSON.parse(JSON.stringify(res.data.vendas.operadoresList));
            }
          }
          if(dados == 'lucros' || dados == 'all') {
            this.grafLucros = JSON.parse(JSON.stringify(res.data.lucratividade.dataGrafico));
            if (this.grafLucros[0][1]) {
              this.grafLucrosActive = true;
            }
            if (this.operadoresLucrosList === null){
              this.operadoresLucrosList = JSON.parse(JSON.stringify(res.data.lucratividade.operadoresList));
            }
          }
          if(dados == 'quantidades' || dados == 'all') {
            this.grafQtServicos = JSON.parse(JSON.stringify(res.data.quantidadeServicos.dataGrafico));
            if (this.grafQtServicos[0][1]) {
              this.grafQtServicosActive = true;
            }
            if (this.operadoresQtServicosList === null){
              this.operadoresQtServicosList = JSON.parse(JSON.stringify(res.data.quantidadeServicos.operadoresList));
            }
          }
          if(dados == 'operador' || dados == 'all') {
            this.grafDiferencaCaixaOperador = JSON.parse(JSON.stringify(res.data.diferencaCaixaOperador.dataGrafico));
            if (this.grafDiferencaCaixaOperador[0][1]) {
              this.grafDiferencaCaixaOperadorActive = true;
            }
            if (this.operadoresList === null){
              this.operadoresList = JSON.parse(JSON.stringify(res.data.diferencaCaixaOperador.operadoresList));
            }
          }

          if(dados == 'operadorDiario' || dados == 'all') {
            this.grafDiferencaCaixaOperadorDiario = JSON.parse(JSON.stringify(res.data.diferencaCaixaOperadorDiario.dataGrafico));
            if (this.grafDiferencaCaixaOperadorDiario[0][1]) {
              this.grafDiferencaCaixaOperadorDiarioActive = true;
            }
          }

        })
        .catch(() => {
          this.loading = false;
          this.semPermissao = true;

          this.$q.notify({
            color: 'red',
            message: 'Houve um erro ao carregar as métricas ou você não possui permissão',
            position: 'top-right',
          });
        });
    },
    // Fullscreen methods
    closeFullscreen() {
      this.$q.fullscreen.exit();
    },
    toogleFullscreen() {
      if (this.$q.fullscreen.isActive) {
        return this.closeFullscreen();
      } else {
        this.openOpcoesAvancadas = false;
      }

      this.$q.fullscreen.request();
    },
    keydownFullscreenEvent(event) {
      if (event.keyCode === 122 && !event.shiftKey && !event.altKey && !event.ctrlKey) {
        // F11
        event.preventDefault();
        this.toogleFullscreen();
      }
    },
    loadFullscreenChangeListener() {
      window.addEventListener('fullscreenchange', this.changeLayout);
      window.addEventListener('mozfullscreenchange', this.changeLayout);
      window.addEventListener('webkitfullscreenchange', this.changeLayout);
      window.addEventListener('msfullscreenchange', this.changeLayout);
      window.addEventListener('keydown', this.keydownFullscreenEvent);
    },
    removeFullscreenChangeListener() {
      window.removeEventListener('fullscreenchange', this.changeLayout);
      window.removeEventListener('mozfullscreenchange', this.changeLayout);
      window.removeEventListener('webkitfullscreenchange', this.changeLayout);
      window.removeEventListener('msfullscreenchange', this.changeLayout);
      window.removeEventListener('keydown', this.keydownFullscreenEvent);
    },
    changeLayout(fullscreenActive = null) {
      if (typeof fullscreenActive !== 'boolean') {
        fullscreenActive = this.$q.fullscreen.isActive;
      }

      let display = 'block';
      let paddingLeft = '57px';

      if (fullscreenActive) {
        display = 'none';
        paddingLeft = '0px';
      }

      document.getElementById('headerPrincipal').style.display = display;
      document.getElementById('drawerPrincipal').style.display = display;
      document.getElementById('pageContainerPrincipal').style.paddingLeft = paddingLeft;
    },
    onChartReady(chart, google, chartId, chartTitle) {
      google.visualization.events.addListener(chart, 'ready', () => {
        this.chartsImg[chartId].title = chartTitle;
        this.chartsImg[chartId].url = chart.getImageURI();
      });
    },
    printChart(chartId) {
      const img = new Image();
      const title = this.chartsImg[chartId].title;
      const dourasoftLogoUrl = "https://dourasoft.nyc3.cdn.digitaloceanspaces.com/img/logo-dourasoft-azul-claro-300x136.gif";
      img.src = this.chartsImg[chartId].url;

      const html = `
        <html>
          <head>
            <title>${title}</title>
            <style>
              .wrapper {
                display: flex;
                align-items: start;
                justify-content: between;
                font-family: Arial, Helvetica, sans-serif;
              }

              img.logo-dourasoft {
                width: 100px;
              }
            </style>
          </head>
          <body>
            <div class="wrapper">
              <div>
                <h4>${title} - ${this.returnMonth()} | ${this.empresaRazao}</h4>
                <img id="chart" src="${img.src}" />
              </div>
              <img class="logo-dourasoft" src="${dourasoftLogoUrl}" />
            </div>
          </body>
        </html>
      `;


      const win = window.open('', 'printChart', 'width=800,height=600');
      win.document.write(html);
      win.document.close();
      win.focus();
    }
  },
  computed: {
    graficoLucratividadeData() {
      return this.grafLucros;
    },
    graficoDiferencaCaixaOperadorData () {
      // const data = [['Mês/Ano', 'Diferença Caixa', { role: 'annotation' } ]];

      // Array.from(this.diferencaCaixaOperador).forEach(function (caixa) {
      //   if (caixa.quebra < 0) {
      //     caixa.quebra = caixa.quebra * -1;
      //   }
      //   data.push([
      //     caixa.mes_movimento,
      //     parseFloat(caixa.quebra),
      //     caixa.func_resp_nome,
      //   ]);
      // });
      // return data;
      return this.grafDiferencaCaixaOperador;
    },
    graficoDiferencaCaixaOperadorDiarioData () {
      return this.grafDiferencaCaixaOperadorDiario;
    },
    graficoVendasData () {
      return this.grafVendas;
    },
    graficoQtServicosData () {
      return this.grafQtServicos;
    },
    empresaRazao() {
      return this.$store.getters.dataEmpresa.razao;
    }
  },
  beforeMount() {
    bus.$off('loadLoterias');
  },
  mounted() {
    this.loadLoterias();
    this.loadFullscreenChangeListener();

    bus.$on('loadLoterias', () => {
      this.loadLoterias();
    });
  },
  beforeDestroy() {
    this.changeLayout(false);
    this.removeFullscreenChangeListener();
  },
  watch: {
    referencia() {
      this.loadLoterias();
    },
    referenciaComparar() {
      this.loadLoterias();
    },
    compararPeriodo(value) {
      this.referencia = moment().format(value ? 'YYYY' : 'MM/YYYY');
      this.referenciaComparar = moment().subtract(1, 'year').format('YYYY');
      this.loadLoterias();
    },
    operadoresFilter() {
      this.loadLoterias('operador');
    },
    operadoresDiarioFilter() {
      this.loadLoterias('operadorDiario');
    },
    operadoresVendasFilter() {
      this.loadLoterias('vendas');
    },
    produtosVendasFilter() {
     this.loadLoterias('vendas');
    },
    tagsVendasFilter() {
      this.loadLoterias('vendas');
    },
    operadoresLucrosFilter() {
      this.loadLoterias('lucros');
    },
    produtosLucrosFilter() {
      this.loadLoterias('lucros');
    },
    tagsLucrosFilter() {
      this.loadLoterias('lucros');
    },
    operadoresQtServicosFilter() {
      this.loadLoterias('quantidades');
    },
    produtosQtServicosFilter() {
      this.loadLoterias('quantidades');
    },
    tagsQtServicosFilter() {
      this.loadLoterias('quantidades');
    },
    showQtServicosFilter(val) {
      if (!val) {
        this.operadoresQtServicosFilter = null;
        this.produtosQtServicosFilter = null;
        this.tagsQtServicosFilter = null;
      }
    },
    showVendasFilter(val) {
      if (!val) {
        this.operadoresVendasFilter = null;
        this.produtosVendasFilter = null;
        this.tagsVendasFilter = null;
      }
    },
    showLucrosFilter(val) {
      if (!val) {
        this.operadoresLucrosFilter = null;
        this.produtosLucrosFilter = null;
        this.tagsLucrosFilter = null;
      }
    },
  }
};
</script>

<style lang="stylus" scoped>

div[class^="col-"] .q-card {
  height: 100%;
}

table.q-table {
  overflow: hidden;
  z-index: 1;
}

.card-dashboard{
  height: 400px;
  overflow: auto;
}

table.q-table tbody tr:hover td::before {
  content: none;
  background: none;
}

table.q-table td:hover:not(:first-child)::before {
  content: '' !important;

  width: calc(5000px + 100%);
  height: 100%;

  position: absolute;
  left: -5000px;
  top: 0;

  background-color: rgba(66, 133, 244, .15) !important;
}

table.q-table td:hover:not(:first-child)::after {
  content: '';

  width: 100%;
  height: calc(5000px + 100%);

  position: absolute;
  top: -5000px;
  left: 0;

  background-color: rgba(66, 133, 244, .15);
}

.note-card {
  height: inherit !important;
  background-color: #fff9c4;

  border-left: 3px solid #fdd835;
  border-right: 3px solid #fdd835;
}

.select-produtos-list {
  text-align: center;
  font-weight: bold;
  color: blue;
}
</style>

<style lang="stylus">
@media (max-width: 1439px) {
  .sticky-header-column-table {
    /* height or max-height is important */
    max-height: calc(95vh - 64px);

    tr th {
      position: sticky;
      /* higher than z-index for td below */
      z-index: 2;
      /* bg color is important; just specify one */
      background: #fff;
    }

    /* this will be the loading indicator */
    thead tr:last-child th {
      /* height of all previous header rows */
      top: 48px;
      /* highest z-index */
      z-index: 3;
    }

    thead tr:first-child th {
      top: 0;
      z-index: 1;
    }

    tr:first-child th:first-child {
      /* highest z-index */
      z-index: 3;
    }

    td:first-child, th:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background: #fff;
    }
  }
}
</style>
