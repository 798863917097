<template>
  <div>
    <q-dialog v-model="Open">
      <q-card style="width: 800px !important; max-width: 800px">
        <q-card-section class="row items-center">
          <div class="text-h6">Buscar lançamentos</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal"
          />
        </q-card-section>

        <q-separator />

        <div class="col q-pa-lg q-mb-none" v-if="carregandoTela">
          <center>
            <q-spinner color="primary" size="3em" :thickness="2" />
          </center>
        </div>

        <q-card-section v-else class="q-pt-none">
          <q-card-section class="q-pa-md q-pa-none">
            <div class="col row">
              <div class="col-12 q-mb-md">
                <div v-if="extrato && extrato.id">
                  <q-markup-table flat>
                    <thead>
                      <tr>
                        <th class="text-center">Movimento</th>
                        <th class="text-center">Descrição</th>
                        <th class="text-center">Valor</th>
                        <th class="text-center">Diferença</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">
                          {{ extrato.dtmovimento | formatDate }}
                        </td>
                        <td class="text-center">{{ extrato.descricao }}</td>
                        <td class="text-center">
                          R$ {{ Math.abs(extrato.valor) | currency }}
                        </td>
                        <td class="text-center" v-if="diferenca != null">
                          <span
                            :class="diferenca >= 0 ? 'text-green' : 'text-red'"
                            >R$ {{ diferenca | currency }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                </div>
              </div>
              <div class="col-6 col-12">
                <div class="row q-col-gutter-sm">
                  <div class="col">
                    <q-input
                      bottom-slots
                      label="Data Inicial"
                      v-model="dtini"
                      @click="$refs.qDateProxy.show()"
                    >
                      <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            ref="qDateProxy"
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              v-model="dtini"
                              mask="DD/MM/YYYY"
                              :locale="langDate"
                              minimal
                              today-btn
                              @input="$refs.qDateProxy.hide()"
                            />
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                  <div class="col">
                    <q-input
                      bottom-slots
                      label="Data Fim"
                      v-model="dtfim"
                      @click="$refs.qDateProxy2.show()"
                    >
                      <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            ref="qDateProxy2"
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              v-model="dtfim"
                              mask="DD/MM/YYYY"
                              :locale="langDate"
                              minimal
                              today-btn
                              @input="$refs.qDateProxy2.hide()"
                            />
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
              </div>

              <div class="col col-12">
                <autocomplete
                  title="Buscar lançamentos não conciliados"
                  :filter="filter"
                  :carregando="carregando"
                  :options="options"
                  :create="() => {}"
                  :formatDisplay="formatDisplayContas"
                  @resultAutocomplete="resultAutocompleteContas"
                  :display="displayMontarAutocompleteContas"
                />
              </div>
            </div>
            <div
              class="header-modal-lancamentos"
              v-if="selecionado && (selecionado.id || selecionado.is_despesas_recorrencia)"
            >
              <div v-if="selecionado && selecionado.status === 'Aberto'">
                <div class="novoCadastro" style="margin-top: 20px">
                  <b>Lançamento em aberto e será liquidado automáticamente</b>
                </div>
              </div>
              <div class="q-pa-lg" v-if="carregandoContaSelecionado">
                <center>
                  <q-spinner color="primary" size="3em" :thickness="2" />
                </center>
              </div>
              <div v-else>
                <ul v-if="selecionado != null">
                  <li><b>Status: </b> {{ selecionado.status }}</li>
                  <li>
                    <b>Vencimento: </b>
                    {{ selecionado.vencimento | formatDate }}
                  </li>
                  <li v-if="selecionado.liquidado">
                    <b>Liquidação: </b>
                    {{ selecionado.liquidacao | formatDate }}
                  </li>
                  <li><b>Descrição: </b> {{ selecionado.descricao }}</li>
                  <li>
                    <b>Valor: </b>R$
                    {{ Math.abs(selecionado.valor) | currency }}
                  </li>

                  <li class="q-pb-lg">
                    <q-btn
                      @click="selecionar(selecionado)"
                      color="green"
                      label="Salvar"
                      class="pull-right"
                      ref="cadastrar"
                      size="12px"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div v-else>
              <div class="q-mt-lg text-center text-grey-9">
                Nenhum lançamento encontrado,
                <span class="link" @click="incluirLancamento()">clique aqui</span>
                para incluir
              </div>
            </div>
            <div class="col">
              <div class="col q-mt-lg" v-if="carregamentoTable">
                <center>
                  <q-spinner color="primary" size="3em" :thickness="2" />
                </center>
              </div>
              <q-markup-table
                flat
                class="q-mt-lg"
                v-if="items.length > 0 && !carregamentoTable"
              >
                <thead>
                  <tr>
                    <th class="text-center">ID</th>
                    <th class="text-center">Data</th>
                    <th class="text-center">Descrição</th>
                    <th class="text-center">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(conta, index) in items" :key="index">
                    <td class="text-left">
                      {{ conta.movconta.id }}
                    </td>
                    <td
                      class="text-center"
                      v-if="conta.movconta.dtmovimento != null"
                    >
                      {{ conta.movconta.dtmovimento | formatDate }}
                    </td>
                    <td class="text-right" v-else>
                      {{ conta.movconta.liquidacao | formatDate }}
                    </td>
                    <td class="text-left">
                      {{
                        String(conta.movconta.descricao).length > 40
                          ? conta.movconta.descricao.substr(0, 40) + "..."
                          : conta.movconta.descricao
                      }}
                      <br />

                      <span v-if="conta.movconta && conta.movconta.cadastro">
                        {{
                          String(conta.movconta.cadastro.nome).length > 40
                            ? conta.movconta.cadastro.nome.substr(0, 40) + "..."
                            : conta.movconta.cadastro.nome
                        }}
                      </span>

                      <q-tooltip
                        v-if="String(conta.movconta.descricao).length > 40"
                      >
                        {{ conta.movconta.descricao }}
                      </q-tooltip>
                      <q-tooltip
                        v-if="
                          conta.movconta.cadastro &&
                          conta.movconta.cadastro.nome.length > 40
                        "
                      >
                        {{ conta.movconta.cadastro.nome }}
                      </q-tooltip>
                    </td>
                    <td class="text-right">
                      {{ conta.movconta.valor_liquidado | currency }}
                    </td>
                    <td>
                      <q-btn
                        flat
                        round
                        dense
                        icon="remove"
                        class="q-ml-md text-red"
                        style="margin-right: 10px; margin-left: 0"
                        @click="deleteItemExtrato(conta)"
                      />
                    </td>
                  </tr>
                </tbody>
              </q-markup-table>
            </div>
          </q-card-section>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import moment from "moment";
import { bus } from "@/main";
import { Loading } from "quasar";
export default {
  name: "modal-buscar-contas",
  props: ["data"],
  data() {
    return {
      selecionado: null,
      diferenca: null,
      carregando: false,
      carregandoTela: false,
      carregandoContaSelecionado: false,
      carregamentoTable: false,
      extrato: null,
      options: [],
      items: [],
      dtini: null,
      dtfim: null,
    };
  },
  computed: {
    Open: {
      get() {
        return this.$store.getters.OpenDialogSearch;
      },
      set() {},
    },
  },
  methods: {
    closeModal() {
      this.diferenca = null;
      this.selecionado = null;
      this.options = [];
      this.extrato = null;
      this.items = [];

      this.$store.commit("OpenDialogSearch", false);
      this.$store.commit("selectedExtratos", []);
    },

    filter(val, update, abort) {
      update(() => {
        this.search(val);
      });
    },

    formatValor(value) {
      if (!value) return "R$ 0,00";
      return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },

    formatDisplayContas(result) {
      let receita = {
        id: null,
        nome: "",
        descricao: "",
        valor: 0,
        vencimento: "",
        verificarCadatros: "",
      };

      receita.id = !result.is_despesas_recorrencia ? result.id : '(Previsto)';
      receita.verificarCadatros = !result.cadastro
        ? result.nome
          ? result.nome
          : "Sem cadastro"
        : result.cadastro.nome;
      receita.nome =
        result["cadastro"] === null
          ? "Sem cadastro"
          : receita.verificarCadatros;
      receita.descricao =
        result.descricao === null ? "Sem Descrição" : result.descricao;
      receita.valor =
        result.valor === null ? "Sem Valor" : this.formatValor(result.valor);
      receita.vencimento =
        result.vencimento === null
          ? "Sem Vencimento"
          : moment.utc(result.vencimento).format("DD/MM/YYYY");

      return `
        <span>
            ${receita.vencimento} - <strong>${receita.valor}</strong>
            <br/>
            Cliente <strong>${receita.verificarCadatros}</strong>
            <br/>
            ${receita.id} - ${receita.descricao}
        </span>
        `;
    },

    resultAutocompleteContas(result) {
      this.carregamentoSpinner("carregandoContaSelecionado", false);

      this.selecionado = result;

      if (result === null) return;

      this.$q.notify({
        message: result.descricao + " - conta foi selecionado.",
        color: "green",
        position: "top-right",
      });
    },

    displayMontarAutocompleteContas(value) {
      if (!value.descricao) return;
      let format =
        this.tipoFiltro === "global"
          ? value.vencimento + " - " + value.descricao
          : value.descricao + " - R$ " + value.valor;
      return !value ? value : format;
    },

    carregamentoSpinner(tipo, status) {
      if (tipo === "carregamentoTable") {
        this.carregamentoTable = status;
      }

      if (tipo === "carregandoContaSelecionado") {
        this.carregandoContaSelecionado = status;
      }

      if (tipo === "carregandoTela") {
        this.carregandoTela = status;
      }
    },

    async search(val = null) {
      const { arquivo_id, conta_id } = this.$route.query;

      this.carregando = true;

      let search = await axios.get(`/financeiro/conciliar`, {
        params: {
          action: "search",
          arquivo_id: arquivo_id,
          conta_id: conta_id,
          dtini: moment(this.dtini, "DD/MM/YYYY").format("YYYY-MM-DD"),
          dtfim: moment(this.dtfim, "DD/MM/YYYY").format("YYYY-MM-DD"),
          tipo: this.extrato.valor > 0 ? 2 : 1,
          query: val,
        },
      });

      if (search.data.success) {
        this.options = search.data.movcontas;
      }

      this.carregando = false;
    },

    async selecionar(item) {
      const { arquivo_id, conta_id } = this.$route.query;
      const data = {
        action: "selecionado",
        movconta_id: item.id,
        extrato_id: this.extrato.id,
        tipo: "selecionar",
      };

      if (this.selecionado.is_despesas_recorrencia) {
        data.is_despesas_recorrencia = true;
        data.despesas_recorrencia_id = this.selecionado.despesas_recorrencia_id;
        data.vencimento_original = moment(this.selecionado.vencimento, "YYYY/MM/DD").format("DD/MM/YYYY");
      }

      let response = await axios.post(`/financeiro/conciliar/${conta_id}?arquivo_id=${arquivo_id}`, data);

      if (!response.data.success) {
        this.$q.notify({
          message: response.data.message,
          color: "green",
          position: "top-right",
        });

        this.options = [];
        this.diferenca = response.data.diferenca;
        this.$root.$emit("limpar");
      } else {
        this.$q.notify({
          message: "Não foi possivel selecionar item!",
          color: "green",
          position: "top-right",
        });
      }

      this.selecionado = null;
      this.loadItens("carregamentoTable");
      bus.$emit("atualizarConciliacao");
    },

    async loadItens(tipo) {
      this.carregamentoSpinner(tipo, true);

      const { arquivo_id, conta_id } = this.$route.query;
      const extrato = this.data.selectedExtrato;

      if (!extrato) {
        this.$q.notify({
          message: "Selecione um extrato.",
          color: "red",
          position: "top-right",
        });
        return;
      }

      let response = await axios.get(
        `/financeiro/conciliar/${conta_id}?arquivo_id=${arquivo_id}`,
        {
          params: {
            action: "selecionado",
            extrato_id: extrato.id,
            tipo: "detalhar",
          },
        }
      );

      this.dtini = moment(this.data.dtini, "YYYY-MM-DD").format("DD/MM/YYYY");
      this.dtfim = moment(this.data.dtfim, "YYYY-MM-DD").format("DD/MM/YYYY");

      if (response.data.success) {
        this.items = response.data.movcontas;
        this.diferenca = response.data.diferenca;

        setTimeout(() => {
          this.carregamentoSpinner(tipo, false);
        }, 500);
      } else {
        this.$q.notify({
          message: "Não foi possivel listar extrato !",
          color: "green",
          position: "top-right",
        });

        setTimeout(() => {
          this.carregamentoSpinner(tipo, false);
        }, 500);
      }

      // Armazenando informações do item extrato selecionado pelo usuário
      this.extrato = extrato;
    },

    async deleteItemExtrato(item) {
      const { arquivo_id, conta_id } = this.$route.query;

      let response = await axios.post(
        `/financeiro/conciliar/${conta_id}?arquivo_id=${arquivo_id}`,
        {
          action: "selecionado",
          lancamento_item_id: item.lancamento_item_id,
          extrato_id: this.extrato.id,
          tipo: "remover",
        }
      );

      if (response.data.success) {
        this.$q.notify({
          message: response.data.message,
          color: "green",
          position: "top-right",
        });

        this.diferenca = response.data.diferenca;
      } else {
        this.$q.notify({
          message: "Não foi possivel selecionar item!",
          color: "green",
          position: "top-right",
        });
      }

      this.loadItens("carregamentoTable");
      bus.$emit("atualizarConciliacao");
    },

    incluirLancamento() {
      this.$store.dispatch("contas");
      this.$store.commit("OpenDialogIncluirConta", true);
    },
  },
  watch: {
    Open(value) {
      if (value) {
        this.loadItens("carregandoTela");
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.header-modal-lancamentos ul {
  list-style: none;
  padding: unset;
}

.novoCadastro {
  padding: 15px;
  background-color: #fff3e0;
  border-left: 5px solid #ffa726;
  margin-bottom: 15px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
