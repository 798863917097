<template>
  <div id="cardCaixasAbertos">
    <q-card-section class="q-pb-sm">
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-h6">
            <q-icon style="position:relative; top: -3px" name="dvr" size="xs" />&nbsp;Caixas Abertos
          </div>
        </div>

      </div>
    </q-card-section>

    <q-card-section class="q-pt-none">
      <q-markup-table dense flat separator="none">
        <tbody>
          <tr
            v-for="caixa in this.caixasAbertos"
            :key="caixa.id"
          >
            <td class="text-left">{{ new Date(caixa.dtmovimento) | formatDate }}</td>
            <td class="text-right">
              <router-link :to="`/caixas/${caixa.id}`" style="text-decoration: none; color: black">
                {{ caixa.caixa_nome }}
              </router-link>
            </td>
            <td class="text-right">{{ caixa.func_resp_nome }}</td>
            <td class="text-right">{{ caixa.total | currency }}</td>
          </tr>
          <tr v-if="this.caixasAbertos.length === 0">
            <td colspan="4">Não há nenhum caixa aberto!</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card-section>
  </div>
</template>

<script>
export default {
  name: "card-caixas-abertos-loterica",
  props: {
    caixasAbertos: Array
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="stylus" scoped>
  #cardCaixasAbertos
    height: 400px;
    overflow: auto;
  </style>
