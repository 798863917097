<template>
  <div id="modalEditarExtrato">
    <q-dialog v-model="OpenDialogEditarExtrato" persistent>
      <q-card :style="`width: 100%; max-width: ${opcoesAvancadas ? '800px' : '400px'};`">
        <q-card-section class="row items-center">
          <div class="text-h6">Editar Extrato</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />

        <q-card-section class="row q-col-gutter-lg">
          <div :class="`col-12 col-md-${opcoesAvancadas ? 6 : 12} row`">
            <div class="q-col-gutter-md row">
              <div class="col-12">
                <q-toggle
                  v-if="extrato.editarConciliado"
                  v-model="extrato.conciliado"
                  label="Conciliado"
                  color="green"
                />
              </div>

              <div class="col-12 col-md-12">
                <q-input
                  v-if="!extratoItem.agrupado"
                  v-model="extrato.descricao"
                  label="Descrição"
                />
              </div>
              <div class="col-md-12">
                <q-input
                  v-if="!extratoItem.agrupado"
                  v-model="extrato.referencia"
                  label="Referência"
                />
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="extrato.liquidacao"
                  label="Data de Liquidação"
                  @click="$refs.qDateProxy.show()"
                  :disable="verifyRoute() && extrato.liquidacao != null"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="extrato.liquidacao"
                          @input="() => $refs.qDateProxy.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
                <p class="q-mt-sm text-negative" v-if="errors.liquidacao">
                  {{ errors.liquidacao }}
                </p>
              </div>
              <div class="col-12 col-md-12">
                <q-input
                  v-model="extrato.dtmovimento"
                  label="Data de Movimento"
                  @click="$refs.qDateProxyDtmovimento.show()"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxyDtmovimento"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="extrato.dtmovimento"
                          @input="() => $refs.qDateProxyDtmovimento.hide()"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-field
                  v-if="!extratoItem.agrupado"
                  v-model="extrato.valor_liquidado"
                  label="Valor Liquidado"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="moneyFormat"
                      v-show="floatingLabel"
                    />
                  </template>
                </q-field>
              </div>

              <div class="col-12 col-md-12">
                <q-select
                  v-if="extratoItem.tipo != 5 && !extratoItem.agrupado"
                  v-model="extrato.conta_id"
                  :options="contas"
                  option-label="titulo"
                  option-value="id"
                  emit-value
                  map-options
                  label="Conta"
                  clearable
                  id="contaExtrato"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12 col-md-12">
                <q-select
                  v-if="extratoItem.tipo != 5 && !extratoItem.agrupado"
                  v-model="extrato.planodeconta_id"
                  :options="planoContas"
                  option-label="titulo"
                  option-value="id"
                  emit-value
                  map-options
                  label="Plano de contas"
                  clearable
                  id="planodecontasExtrato"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                </q-select>
              </div>

              <div class="col-12" v-if="$router.path === '/extrato'">
                <q-checkbox
                  v-model="extrato.conciliado"
                  clickable
                  label="Conciliado"
                  color="green"
                />
              </div>

              <div class="col-12" v-if="hasOpcoesAvancadas">
                <q-checkbox
                  color="green"
                  label="Opções Avançadas"
                  v-model="opcoesAvancadas"
                />
              </div>

            </div>
          </div>

          <div class="col-12 col-md-6" v-show="opcoesAvancadas">
            <div class="row" v-if="exibirCentrosdecusto">

              <div class="col-12 q-mb-md">
                <q-select
                  v-model="newMovcontaRateio.centrodecusto"
                  :option-label="value => value === null ? 'Item Vazio' : value.nome"
                  :option-value="value => value === null ? null : value"
                  :options="optionsCentrosdecusto"
                  label="Centro de Custo"
                  :hide-bottom-space="!Boolean(errors.centrodecusto)"
                  :error="Boolean(errors.centrodecusto)"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey"
                        >Sem Resultados</q-item-section
                      >
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span>{{ errors.centrodecusto }}</span>
                  </template>
                </q-select>
              </div>

              <div class="col-2 flex items-end">
                <q-btn
                  color="white"
                  text-color="black"
                  label="%"
                  disable
                />
              </div>

              <div class="col-8">
                <q-field
                  v-model="newMovcontaRateio.porcentagem"
                  label="Porcentagem"
                  :hide-bottom-space="!Boolean(errors.porcentagem)"
                  :error="Boolean(errors.porcentagem)"
                >
                  <template
                    v-slot:control="{ id, floatingLabel, value, emitValue }"
                  >
                    <money
                      :id="id"
                      class="q-field__native text-right"
                      :value="value"
                      @input="emitValue"
                      v-bind="percentFormat"
                      v-show="floatingLabel"
                    >
                    </money>
                  </template>
                  <template v-slot:error>
                    <span>{{ errors.porcentagem }}</span>
                  </template>
                </q-field>
              </div>

              <div class="col-2 flex items-end justify-end">
                <q-btn
                  icon="add"
                  color="primary"
                  flat
                  round
                  @click="addMovcontaRateio()"
                />
              </div>

              <div class="col-12 q-mt-md">
                <p
                  v-if="porcentagemMovcontasRateioTotal > 100"
                  class="text-negative caption"
                >
                  A porcentagem total passa de 100%
                </p>
                <div v-if="Boolean(errors.movcontas_rateios)">
                  <p
                    class="text-negative caption"
                    v-for="(error, index) in errors.movcontas_rateios"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>

                <q-markup-table
                  flat
                  dense
                >
                  <tbody>
                    <tr
                      v-for="(rateio, index) in extratoItem.movcontas_rateios"
                      :key="index"
                    >
                      <td>{{ rateio.centrocusto.nome }}</td>
                      <td class="text-right">{{ rateio.porcentagem }} %</td>
                      <td style="width: 30px;">
                        <q-btn
                          flat
                          dense
                          color="red"
                          icon="delete"
                          @click="extratoItem.movcontas_rateios.splice(index, 1)"
                        />
                      </td>
                    </tr>
                    <tr v-if="extratoItem.movcontas_rateios.length > 0">
                      <td colspan="2"><strong>Total</strong></td>
                      <td class="text-right">{{ porcentagemMovcontasRateioTotal }} %</td>
                    </tr>
                    <tr v-if="extratoItem.movcontas_rateios.length === 0">
                      <td colspan="3" class="text-center">Nenhum rateio</td>
                    </tr>
                  </tbody>
                </q-markup-table>
              </div>

            </div>
          </div>

        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Excluir"
            clickable
            @click="deletarExtrato(extrato.id)"
            class="text-red"
            style="margin-bottom: 10px"
            v-if="!verifyRoute()  && !extrato.agrupado"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="confirmarEdicao"
            :loading="loadingEditar"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import moment from "moment";
import { Notify } from "quasar";
import { mapGetters } from "vuex";
import { Money } from "v-money";
import { bus } from "@/main";

export default {
  name: "modal-editar-extrato",
  components: { Money },
  data() {
    return {
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      extrato: {},
      loadingEditar: false,
      errors: {},
      opcoesAvancadas: false,
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false,
      },
      newMovcontaRateio: {
        centrodecusto: null,
        porcentagem: 0,
      },
    };
  },
  methods: {
    confirmarEdicao() {
      this.loadingEditar = true;

      if (!this.extratoItem.agrupado) {
        return this.saveEditar();
      }

      const data = {
        action: "alterar-liquidacao",
        confirmar: 0,
        dtini: moment(this.extratoItem.liquidacao, "DD/MM/YYYY").format(
          "DD-MM-YYYY"
        ),
        dtfim: moment(this.extratoItem.liquidacao, "DD/MM/YYYY").format(
          "DD-MM-YYYY"
        ),
        dtmovimento: moment(this.extratoItem.dtmovimento, "DD/MM/YYYY").format(
          "DD-MM-YYYY"
        ),
        gateway_id: this.extratoItem.gateway_id,
        conta_id: this.extratoItem.conta_id,
        planodeconta_id: this.extratoItem.planodeconta_id,
        tipo: this.extratoItem.tipo,
        conciliado: this.extratoItem.conciliado,
        status: "Liquidado",
        all: 1,
        is_liquidacao: 1,
      };

      if (this.extrato.editarConciliado) {
        data.editar_conciliado = this.extrato.conciliado;
      }

      axios
        .post("/financeiro/edit-multiple/receita", data)
        .then(res => {
          if (!res.data.success) {
            this.errors = res.data.errors;
            throw res.data;
          }

          data.liquidacao = moment(
            this.extrato.liquidacao,
            "DD/MM/YYYY"
          ).format("DD-MM-YYYY");

          if(this.extrato.dtmovimento != this.extrato.liquidacao){
            data.atualizar_dtmovimento = moment(
              this.extrato.dtmovimento,
              "DD/MM/YYYY"
            ).format("DD-MM-YYYY");
          }

          data.confirmar = 1;

          this.$q
            .dialog({
              title: res.data.message,
              cancel: "Cancelar",
              persistent: true
            })
            .onOk(() => {
              axios
                .post("/financeiro/edit-multiple/receita", data)
                .then(res => {
                  this.loadingEditar = false;

                  if (!res.data.success) {
                    return this.$q.dialog({
                      title: res.data.message,
                      class: "bg-warning text-white",
                      ok: {
                        "text-color": "orange",
                        color: "white"
                      }
                    });
                  }

                  this.$q.dialog({
                    title: res.data.message,
                    class: "bg-positive text-white",
                    ok: {
                      "text-color": "green",
                      color: "white"
                    }
                  });

                  this.$store.dispatch("resetExtrato");
                  this.$store.dispatch("listarExtrato", this.filtersExtrato);

                  if(this.verifyRoute()){
                      bus.$emit("atualizarConciliacao", true);
                  }

                  this.closeModal();
                })
                .catch(() => {
                  this.$q.dialog({
                    title:
                      "Não foi possível contactar, ou você não possui permissão!",
                    class: "bg-negative text-white",
                    ok: {
                      "text-color": "red",
                      color: "white"
                    }
                  });
                });
            })
            .onCancel(() => {
              this.loadingEditar = false;
            });
        })
        .catch(() => {
          this.loadingEditar = false;

          Notify.create({
            message: "Houve um erro ao salvar o extrato",
            color: "red",
            position: "top-right"
          });
        });
    },
    saveEditar() {
      this.errors = {};
      this.loadingEditar = true;

      const data = {
        action: "editar-extrato",
        descricao: this.extrato.descricao,
        liquidacao: this.extrato.liquidacao,
        dtmovimento: this.extrato.dtmovimento,
        referencia: this.extrato.referencia,
        valor_liquidado: this.extrato.valor_liquidado,
        conta_id: this.extrato.conta_id,
        planodeconta_id: this.extrato.planodeconta_id,
        tipo: this.extrato.tipo,
      };

      if (this.extrato.editarConciliado) {
        data.conciliado = this.extrato.conciliado;
      }

      if (this.exibirCentrosdecusto) {
        data.movcontas_rateios = this.extratoItem.movcontas_rateios.map(rateio => ({
          porcentagem: rateio.porcentagem,
          centrocusto_id: rateio.centrocusto.id,
        }));
      }

      axios
        .post("/financeiro/edit/" + this.extratoItem.id, data)
        .then(res => {
          this.loadingEditar = false;

          if (!res.data.success) {
            this.errors = res.data.errors;
            throw res.data;
          }

          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          this.$store.dispatch("resetExtrato");
          this.$store.dispatch("listarExtrato", this.filtersExtrato);

          if(this.verifyRoute()){
              bus.$emit("atualizarConciliacao", true);
          }

          this.closeModal();
        })
        .catch(e => {
          this.loadingEditar = false;

          Notify.create({
            message: e && e.message ? e.message : "Houve um erro ao salvar o extrato",
            color: "red",
            position: "top-right"
          });
        });
    },
    closeModal() {
      this.errors = [];
      this.$store.commit("OpenDialogEditarExtrato", false);
    },
    verifyRoute(){
      return this.$route && this.$route.name === 'conciliacao';
    },
    deletarExtrato(id) {
      this.$q
        .dialog({
          title: "Deseja excluir esse lançamento?",
          message: "Descreva o Motivo de Cancelamento",
          cancel: "Cancelar",
          persistent: true,
          prompt: {
            model: '',
            isValid: val => val.length > 2,
          },
        })
        .onOk((data) => {
          const param = { 'motivo': data };
          axios
            .post(`/financeiro/delete/${id}`, param)
            .then(res => {
              if (!res.data.success) {
                return this.$q.dialog({
                  title: res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
              }

              this.$q.dialog({
                title: res.data && res.data.msg ? res.data.msg : res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });

              this.$store.dispatch("resetExtrato");
              this.$store.dispatch("listarExtrato", this.filtersExtrato);

              if(this.verifyRoute()){
                bus.$emit("atualizarConciliacao", true);
              }

              this.closeModal();
            })
            .catch(() => {
              this.$q.dialog({
                title:
                  "Não foi possível contactar, ou você não possui permissão!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
            });
        });
    },
    addMovcontaRateio() {
      this.errors.porcentagem = null;
      this.errors.centrodecusto = null;

      if (!this.newMovcontaRateio.centrodecusto) {
        this.errors.centrodecusto = "Centro de custo é obrigatório";
        return;
      }

      if (!this.newMovcontaRateio.porcentagem) {
        this.errors.porcentagem = "Porcentagem é obrigatória";
        return;
      }

      if (this.porcentagem < 0 || this.porcentagem > 100) {
        this.errors.porcentagem = "Valor inválido";
        return;
      }

      const rateioIndex = this.extratoItem.movcontas_rateios.findIndex(rateio => rateio.centrocusto.id === this.newMovcontaRateio.centrodecusto.id);

      const rateio = {
        porcentagem: Math.abs(Number(this.newMovcontaRateio.porcentagem)),
        centrocusto: {
          id: this.newMovcontaRateio.centrodecusto.id,
          nome: this.newMovcontaRateio.centrodecusto.nome,
        },
      };

      if (rateioIndex === -1) {
        this.extratoItem.movcontas_rateios.push(rateio);
      } else {
        this.extratoItem.movcontas_rateios[rateioIndex] = rateio;
      }

      this.newMovcontaRateio = {
        centrodecusto: null,
        porcentagem: 0,
      };
    },
  },
  computed: {
    ...mapGetters([
      "extratoItem",
      "OpenDialogEditarExtrato",
      "contas",
      "planoContas",
      "filtersExtrato",
      "centroCustos",
    ]),
    exibirCentrosdecusto() {
      return this.extratoItem.tipo === 1 && this.extratoItem.movcontas_rateios;
    },
    hasOpcoesAvancadas() {
      return this.exibirCentrosdecusto;
    },
    optionsCentrosdecusto() {
      if (!this.exibirCentrosdecusto) {
        return [];
      }

      return this.centroCustos.filter(centroCusto => {
        return !(this.extratoItem.movcontas_rateios.find(rateio => rateio.centrocusto.id == centroCusto.id));
      });
    },
    porcentagemMovcontasRateioTotal() {
      if (!this.exibirCentrosdecusto) {
        return 0.00;
      }

      return this.extratoItem.movcontas_rateios.reduce((total, rateio) => total + Math.abs(Number(rateio.porcentagem)), 0);
    },
  },
  watch: {
    extratoItem(val) {
      this.extrato = JSON.parse(JSON.stringify(val));

      if(this.extrato.dtmovimento === null && this.extrato.liquidado){
        this.extrato.dtmovimento = this.extrato.liquidacao;
      }
    }
  },
  mounted() {
    this.$store.dispatch("listarCentroCustos");
  },
};
</script>

<style lang="stylus" scoped></style>
