import axios from "@/axios-auth";
import { Notify } from "quasar";

const state = {
  gateways: {},
  gatewaysDespesa: {}
};

const mutations = {
  findGateways: (state, userData) => {
    state.gateways = userData.gateways;
  },
  findGatewaysDespesa: (state, userData) => {
    state.gatewaysDespesa = userData.gatewaysDespesa;
  }
};

const actions = {
  findGateways: ({ commit }, userData) => {
    const params = {
      ativo: true,
    };

    if (userData && userData.gatewaysOpts) {
      params.gateways_opts = true;
    }

    axios
      .get("/gateways", { params })
      .then(res => {
        commit("findGateways", {
          gateways: res.data
        });
      })
      .catch(() => {
        Notify.create({
          message:
            "Não foi possível trazer as formas de pagamento, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  findGatewaysDespesa: ({ commit }) => {
    axios
      .get("/gateways?ativo=true&despesa=1")
      .then(res => {
        commit("findGatewaysDespesa", {
          gatewaysDespesa: res.data
        });
      })
      .catch(() => {
        Notify.create({
          message:
            "Não foi possível trazer as formas de pagamento, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  }
};

const getters = {
  findGateways: state => {
    return state.gateways;
  },
  findGatewaysDespesa: state => {
    return state.gatewaysDespesa;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
