import { render, staticRenderFns } from "./modal-agendar-mensagem.vue.vue?vue&type=template&id=4cc7a072&scoped=true&"
import script from "./modal-agendar-mensagem.vue.vue?vue&type=script&lang=js&"
export * from "./modal-agendar-mensagem.vue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc7a072",
  null
  
)

export default component.exports