<template>
  <q-dialog v-model="openDialog" persistent>
    <q-card class="scroll" style="width: 90%; max-width: 400px">
      <q-card-section class="row items-center no-wrap">
        <div class="text-h6">{{ recorrencia.descricao }}</div>
        <q-space />
        <q-btn icon="close" clickable flat round dense v-close-popup @click="fecharModal()" />
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pa-md">
        <div class="row q-col-gutter-x-md">
          <div class="col-12">Tem certeza que deseja congelar a recorrência?</div>

          <div class="col-12">
            <q-input
              clearable
              bottom-slots
              label="Fim do congelamento"
              :error="Boolean(erros.congelado_dtfim)"
              v-model="recorrencia.congelado_dtfim"
              @click="$refs.qDateCongelamentoDtfim.show()"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateCongelamentoDtfim"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      minimal
                      today-btn
                      mask="DD/MM/YYYY"
                      v-model="recorrencia.congelado_dtfim"
                      @input="value => handleCongeladoDtfim(value)"
                      :locale="langDate"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span v-for="(erro, i) in erros.congelado_dtfim" :key="erro + i">
                  {{ erro }}
                </span>
              </template>
            </q-input>
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          no-caps
          v-ripple
          clickable
          label="Salvar"
          color="positive"
          text-color="white"
          style="margin-right: 22px; margin-bottom: 10px"
          :loading="salvando"
          @click="congelarRequest(recorrencia)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { Loading } from 'quasar';

import axios from '@/axios-auth';
import { bus } from '@/main';

export default {
  name: 'modal-congelar-recorrencia',
  data() {
    return {
      openDialog: false,
      salvando: false,
      recorrencia: {},
      erros: {},
    };
  },
  methods: {
    // Modal
    abrirModal({ recorrencia }) {
      if (!recorrencia.congelado) {
        recorrencia = JSON.parse(JSON.stringify(recorrencia));

        if (!recorrencia.congelado_dtfim) {
          recorrencia.congelado_dtfim = '';
        }

        this.resetarErros();

        this.recorrencia = recorrencia;
        this.openDialog = true;
        return;
      }

      this.$q
        .dialog({
          title: recorrencia.descricao,
          message: 'Tem certeza que deseja descongelar a recorrência?',
          cancel: 'Cancelar',
          persistent: true,
        })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          this.congelarRequest(recorrencia);
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    fecharModal() {
      this.openDialog = false;
    },
    // Formulário
    resetarErros() {
      this.erros = {};
    },
    // Salvar
    congelarRequest(recorrencia) {
      let mensagemSucesso = 'congelada';
      let data = {
        ativo: recorrencia.ativo,
        descricao: recorrencia.descricao,
        periodo: recorrencia.periodo,
        valor: recorrencia.valor,
        congelado: !recorrencia.congelado,
      };

      if (recorrencia.congelado) {
        mensagemSucesso = 'descongelada';
      } else {
        data.congelado_dtfim = recorrencia.congelado_dtfim;
      }

      this.salvando = true;

      axios
        .post(`/recorrencias/congelar/${recorrencia.id}`, data)
        .then(res => {
          this.openDialog = false;
          this.salvando = false;

          if (res.error && res.error.response.status === 403) {
            this.notificarSemPermissao(res.error.response.data.message);
            return;
          }

          if (!res.data.success) {
            this.$q.dialog({
              title: res.data.message,
              class: 'bg-warning text-white',
              ok: {
                'text-color': 'orange',
                color: 'white',
              },
            });
            return;
          }

          this.$q.dialog({
            title: `Recorrencia ${mensagemSucesso}!`,
            class: 'bg-positive text-white',
            ok: {
              'text-color': 'green',
              color: 'white',
            },
          });

          this.$emit('atualizar');
        })
        .catch(() => {
          this.openDialog = false;
          this.salvando = false;

          this.$q.dialog({
            title: 'Não foi possível realizar a ação, ou você não possui permissão isto!',
            class: 'bg-negative text-white',
            ok: {
              'text-color': 'red',
              color: 'white',
            },
          });
        });
    },
    // Handlers
    handleCongeladoDtfim(value) {
      this.recorrencia.congelado_dtfim = value;
      this.$refs.qDateCongelamentoDtfim.hide();
    },
    // Utilidade
    notificarSemPermissao(message) {
      this.$q.notify({
        message: message,
        color: 'red',
        position: 'top-right',
      });
    },
  },
  mounted() {
    bus.$on('abrirModalCongelarRecorrencia', this.abrirModal);
  },
};
</script>
