import Vue from "vue";
import Router from "vue-router";
import DefaultLayout from "@/layouts/Default.vue";
import alterarSenha from "@/views/alterar_senha.vue";
import arquivos from "@/views/arquivos.vue";
import ativar_senha from "@/views/ativar_senha.vue";
import cadastro_view from "@/views/cadastro_view.vue";
import cadastros from "@/views/cadastros.vue";
import caixas from "@/views/caixas.vue";
import configuracao from "@/views/configuracao.vue";
import dashboard from "@/views/dashboard.vue";
import despesas from "@/views/despesas.vue";
import empresa from "@/views/empresa.vue";
import extrato from "@/views/extrato.vue";
import conciliacao from "@/views/conciliacao.vue";
import fluxo_de_caixa from "@/views/fluxo_de_caixa.vue";
import historico_emails from "@/views/historico_emails.vue";
import licencas from "@/views/licencas.vue";
import login from "@/views/login.vue";
// import not_found from "@/views/not_found.vue";
import notificacao from "@/views/notificacao.vue";
import parametros from "@/views/parametros.vue";
import perfil from "@/views/perfil.vue";
import plano_contratado from "@/views/plano_contratado.vue";
import receitas from "@/views/receitas.vue";
import registro from "@/views/registro.vue";
import relatorios from "@/views/relatorios.vue";
import relatorio from "@/components/Relatorios/relatorio.vue";
import relatorio_gerencial from "@/components/Relatorios/relatorio_gerencial.vue";
import suporte from "@/views/suporte.vue";
import tour from "@/views/tour.vue";
import estoque from "@/views/estoque.vue";
import compras from "@/views/compras.vue";
import mapa_resgate from "@/views/mapa_resgate.vue";
import loja from "@/views/loja.vue";
import caixa from "@/views/caixa.vue";
import lancamento_caixa from "@/views/lancamento_caixa.vue";
import resumo_tfl from "@/views/resumo_tfl.vue";
import calendario from "@/views/calendario.vue";
import whatsapp from "@/views/whatsapp.vue";
import authQrcode from "@/auth-qrcode.js";
import notas_fiscais from "@/views/notas_fiscais.vue";
import cofre_inteligente from "@/views/cofre_inteligente.vue";
import metricasSaas from "@/views/metricas_saas.vue";
import dashboardLotericas from "@/views/dashboard_lotericas.vue";
import extrato_cliente from "@/views/extrato_cliente.vue";
import despesas_recorrencias from "@/views/despesas_recorrencias.vue";
import cliente_dourasoft from "@/views/cliente_dourasoft.vue";
import areaCliente from "@/views/area_cliente.vue";
import planos from "@/views/planos.vue";
import comissoes from "@/views/comissoes.vue";
import recorrencias from "@/views/recorrencias.vue";
import store from "@/store/store.js";

Vue.use(Router);

if (localStorage && localStorage["empresa"]) {
  localStorage.clear();
}

export default new Router({
  mode: "history",
  routes: [
    { path: "/", redirect: "/login" },
    {
      path: "/",
      component: DefaultLayout,
      children: [
        {
          path: "/arquivos",
          component: arquivos,
          name: "arquivos",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/cadastros/:id",
          component: cadastro_view,
          name: "cadastro_view",
          beforeEnter(to, from, next) {
            if (to.query && to.query.token) {
              authQrcode(to.query, to.params, 'cadastros');
            } else {
              if (localStorage["token" + localStorage["empresa_atual"]]) {
                next();
              } else {
                next("/login");
              }
            }
          }
        },
        {
          path: "/logout",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              localStorage.clear();
              next("/login");
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/cadastros",
          component: cadastros,
          name: "cadastros",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/caixas",
          component: caixas,
          name: "caixas",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/calendario",
          component: calendario,
          name: "calendario",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/caixas/:id",
          component: caixa,
          beforeEnter(to, from, next) {
            if (to.query && to.query.token) {
              authQrcode(to.query, to.params, 'caixas');
            } else {
              if (localStorage["token" + localStorage["empresa_atual"]]) {
                next();
              } else {
                next("/login");
              }
            }
          },
          children: [
            {
              path: "",
              name: "caixa",
              component: lancamento_caixa,
              beforeEnter(to, from, next) {
                if (localStorage["token" + localStorage["empresa_atual"]]) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            {
              path: "resumo",
              name: "resumo_tfl",
              component: resumo_tfl,
              beforeEnter(to, from, next) {
                if (localStorage["token" + localStorage["empresa_atual"]]) {
                  next();
                } else {
                  next("/login");
                }
              }
            }
          ]
        },
        {
          path: "/visao360",
          component: dashboard,
          name: "dashboard",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/despesas",
          component: despesas,
          name: "despesas",
          beforeEnter(to, from, next) {
            let plano = localStorage.getItem('empresa_plano');
            if (plano === "Essencial"){
              store.commit("setOpenDrawerUpgradePlano", true);
              next("/visao360");
            } else if (to.query && to.query.token) {
              authQrcode(to.query, to.params, 'despesas');
            } else {
              if (localStorage["token" + localStorage["empresa_atual"]]) {
                next();
              } else {
                next("/login");
              }
            }
          }
        },
        {
          path: "/despesas-recorrentes",
          component: despesas_recorrencias,
          name: "despesas-recorrencias",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/empresa",
          component: empresa,
          name: "empresa",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/extrato",
          component: extrato,
          name: "extrato",
          beforeEnter(to, from, next) {
            let plano = localStorage.getItem('empresa_plano');
            if (plano === "Essencial"){
              store.commit("setOpenDrawerUpgradePlano", true);
              next("/visao360");
            } else if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/extrato-cliente",
          component: extrato_cliente,
          name: "extrato_cliente",
          beforeEnter(to, from, next) {
            let plano = localStorage.getItem('empresa_plano');
            if (plano === "Essencial") {
             store.commit("setOpenDrawerUpgradePlano", true);
             next("/visao360");
            } else if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/conciliacao",
          component: conciliacao,
          name: "conciliacao",
          beforeEnter(to, from, next) {
            let plano = localStorage.getItem('empresa_plano');
            if (plano && plano !== "Premium") {
             store.commit("setOpenDrawerUpgradePlano", true);
             next("/visao360");
            } else if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/fluxo_de_caixa",
          component: fluxo_de_caixa,
          name: "fluxo_de_caixa",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/historicoemails",
          component: historico_emails,
          name: "historico_emails",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/licencas",
          component: licencas,
          name: "licencas",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/notificacao",
          component: notificacao,
          name: "notificacao",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/parametros",
          component: parametros,
          name: "parametros",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/perfil",
          component: perfil,
          name: "perfil",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/plano_contratado",
          component: plano_contratado,
          name: "plano_contratado",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/receitas",
          component: receitas,
          name: "receitas",
          beforeEnter(to, from, next) {
            let plano = localStorage.getItem('empresa_plano');
            if(plano === "Essencial") {
              store.commit("setOpenDrawerUpgradePlano", true);
              next("/visao360");
            } else if (to.query && to.query.token) {
                authQrcode(to.query, to.params, 'receitas');
            } else {
               if (localStorage["token" + localStorage["empresa_atual"]]) {
                next();
              } else {
                next("/login");
              }
            }
          }
        },
        {
          path: "/relatorios",
          component: relatorios,
          name: "relatorios",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          },
          children: [
            {
              path: "/relatorios/gerenciais/:id",
              component: relatorio_gerencial,
              name: "relatorio_gerencial",
              beforeEnter(to, from, next) {
                if (localStorage["token" + localStorage["empresa_atual"]]) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            {
              path: "/relatorios/:id",
              component: relatorio,
              name: "relatorio",
              beforeEnter(to, from, next) {
                if (localStorage["token" + localStorage["empresa_atual"]]) {
                  next();
                } else {
                  next("/login");
                }
              }
            }
          ]
        },
        {
          path: "/suporte",
          component: suporte,
          name: "suporte",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/estoques",
          component: estoque,
          name: "estoque",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/compras",
          component: compras,
          name: "compras",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/mapa-resgates",
          component: mapa_resgate,
          name: "mapa_resgate",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/loja",
          component: loja,
          name: "loja",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/whatsapp",
          component: whatsapp,
          name: "whatsapp",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/notas-fiscais",
          component: notas_fiscais,
          name: "notas_fiscais",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/cofre-inteligente",
          component: cofre_inteligente,
          name: "cofre_inteligente",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          },
        },
        {
          path: "/metricas-saas",
          component: metricasSaas,
          name: "metricas_saas",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          },
        },
        {
          path: "/dashboard-lotericas",
          component: dashboardLotericas,
          name: "dashboard_lotericas",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          },
        },
        {
          path: "/cliente-dourasoft",
          component: cliente_dourasoft,
          name: "cliente_dourasoft",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          },
        },
        {
          path: "/area-cliente",
          component: areaCliente,
          name: "area-cliente",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          },
        },
        {
          path: "/planos",
          component: planos,
          name: "planos",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          },
        },
        {
          path: "/comissoes",
          component: comissoes,
          name: "comissoes",
          beforeEnter(to, from, next) {
            if (localStorage["token" + localStorage["empresa_atual"]]) {
              next();
            } else {
              next("/login");
            }
          },
        },
        {
          path: "/assinaturas",
          component: recorrencias,
          name: "recorrencias",
          beforeEnter(to, from, next) {
            if (to.query && to.query.token) {
              authQrcode(to.query, to.params, 'assinaturas');
            } else {
              if (localStorage["token" + localStorage["empresa_atual"]]) {
                next();
              } else {
                next("/login");
              }
            }
          }
        },
      ]
    },
    {
      path: "/login",
      component: login,
      name: "login",
      beforeEnter(to, from, next) {
        if (localStorage["token" + localStorage["empresa_atual"]]) {
          next("/visao360");
        } else {
          next();
        }
      }
    },
    {
      path: "/registro",
      component: registro,
      name: "registro",
      beforeEnter(to, from, next) {
        if (!localStorage["token" + localStorage["empresa_atual"]]) {
          next();
        } else {
          next("/visao360");
        }
      }
    },
    {
      path: "/alterar-senha",
      component: alterarSenha,
      name: "alterar-senha",
      beforeEnter(to, from, next) {
        if (!localStorage["token" + localStorage["empresa_atual"]]) {
          next();
        } else {
          next("/visao360");
        }
      }
    },
    {
      path: "/ativar/:id",
      component: ativar_senha,
      name: "ativar_senha",
      beforeEnter(to, from, next) {
        localStorage.clear();
        next();
      }
    },
    {
      path: "/configuracao",
      component: configuracao,
      name: "configuracao",
      beforeEnter(to, from, next) {
        if (localStorage["token" + localStorage["empresa_atual"]]) {
          next();
        } else {
          next("/login");
        }
      }
    },
    {
      path: "/tour",
      component: tour,
      name: "tour",
      beforeEnter(to, from, next) {
        if (localStorage["token" + localStorage["empresa_atual"]]) {
          next();
        } else {
          next("/login");
        }
      }
    },
    // {
    //   path: "/not_found",
    //   component: not_found,
    //   name: 'not_found',
    //   beforeEnter (to, from, next) {
    //     if (localStorage['token' + localStorage['empresa']]) {
    //       next()
    //     } else {
    //       next('/login')
    //     }
    //   }
    // },
    {
      path: "*",
      redirect: "/",
      beforeEnter(to, from, next) {
        if (localStorage["token" + localStorage["empresa_atual"]]) {
          next();
        } else {
          next("/login");
        }
      }
    }
  ]
});
