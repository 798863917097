<template>
  <div id="datePicker">
    <q-btn-group rounded flat no-wrap no-caps>
      <q-btn
        class="white text-blue-7"
        clickable
        @click="changeFilterPeriodo('prev')"
        rounded
        icon="chevron_left"
      />
      <q-btn class="white text-blue-7" rounded :label="dtini">
        <template v-slot:default>
          <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
            <q-date
              mask="DD/MM/YYYY"
              :locale="langDate"
              minimal
              today-btn
              v-model="dtini"
              @input="(value) => enviaData1(value)"
            />
          </q-popup-proxy>
        </template>
      </q-btn>
      <q-btn class="white text-blue-7" rounded :label="dtfim">
        <template v-slot:default>
          <q-popup-proxy ref="qDateProxy2" transition-show="scale" transition-hide="scale">
            <q-date
              mask="DD/MM/YYYY"
              :locale="langDate"
              minimal
              today-btn
              v-model="dtfim"
              @input="(value) => enviaData2(value)"
            />
          </q-popup-proxy>
        </template>
      </q-btn>
      <q-btn
        class="white text-blue-7"
        clickable
        @click="changeFilterPeriodo('next')"
        rounded
        icon="chevron_right"
      />
    </q-btn-group>
  </div>
</template>

<script>
let moment = require("moment");
import { mapGetters } from 'vuex';
export default {
  name: "date-picker",
  data() {
    return {
      format: "d MMMM yyyy",
      mes: []
    };
  },
  methods: {
    enviaData1(value) {
      let query = {
        dtini: value.replace(/\//g, "-"),
        dtfim: this.dtfim.replace(/\//g, "-")
      };

      if (this.$route.path === "/receitas") {
        query = { ...this.filtrosReceitas, ...query };
        this.$store.dispatch("filtrosReceitas", query);
      }
      if (this.$route.path === "/despesas") {
        query = { ...this.filtrosDespesas, ...query };
        this.$store.dispatch("filtrosDespesas", query);
      }
      if (this.$route.path === "/extrato") {
        this.$store.dispatch("filtersExtrato", query);
      }
      if (this.$route.path === "/compras") {
        query = { ...this.filtrosCompras, ...query };
        this.$store.dispatch("filtrosCompras", query);
      }
      this.$refs.qDateProxy.hide();
    },
    enviaData2(value) {
      let query = {
        dtini: this.dtini.replace(/\//g, "-"),
        dtfim: value.replace(/\//g, "-")
      };
      if (this.$route.path === "/receitas") {
        query = { ...this.filtrosReceitas, ...query };
        this.$store.dispatch("filtrosReceitas", query);
      }
      if (this.$route.path === "/despesas") {
        query = { ...this.filtrosDespesas, ...query };
        this.$store.dispatch("filtrosDespesas", query);
      }
      if (this.$route.path === "/extrato") {
        this.$store.dispatch("filtersExtrato", query);
      }
      if (this.$route.path === "/compras") {
        query = { ...this.filtrosCompras, ...query };
        this.$store.dispatch("filtrosCompras", query);
      }
      this.$refs.qDateProxy2.hide();
    },
    changeFilterPeriodo(val) {
      if (this.$route.path === "/receitas") {
        this.$store.dispatch("changeFilterPeriodoReceitas", val);
      }
      if (this.$route.path === "/despesas") {
        this.$store.dispatch("changeFilterPeriodoDespesas", val);
      }
      if (this.$route.path === "/extrato") {
        this.$store.dispatch("changeFilterPeriodoExtrato", val);
      }
      if (this.$route.path === "/compras") {
        this.$store.dispatch("changeFilterPeriodoCompras", val);
      }
    },
    returnData() {
      if (this.$route.path === "/receitas") {
        return this.$store.getters.filtrosReceitas.dtini;
      }
      if (this.$route.path === "/despesas") {
        return this.$store.getters.filtrosDespesas.dtini;
      }
      if (this.$route.path === "/extrato") {
        return this.$store.getters.dataExtrato.dtini;
      }
      if (this.$route.path === "/compras") {
        return this.$store.getters.filtrosCompras.dtini;
      }
    },
    returnData2() {
      if (this.$route.path === "/receitas") {
        return this.$store.getters.filtrosReceitas.dtfim;
      }
      if (this.$route.path === "/despesas") {
        return this.$store.getters.filtrosDespesas.dtfim;
      }
      if (this.$route.path === "/extrato") {
        return this.$store.getters.dataExtrato.dtfim;
      }
      if (this.$route.path === "/compras") {
        return this.$store.getters.filtrosCompras.dtfim;
      }
    },
    returnRoute() {
      return this.$route.path === "/extrato" ? true : false;
    }
  },
  computed: {
    ...mapGetters([
      "filtrosReceitas",
      "filtrosDespesas",
      "filtrosCompras",
    ]),
    dtini: {
      get() {
        let date =
          this.$route.path === "/receitas"
            ? this.$store.getters.filtrosReceitas.dtini.replace(/-/g, "/")
            : this.$route.path === "/despesas"
            ? this.$store.getters.filtrosDespesas.dtini.replace(/-/g, "/")
            : this.$route.path === "/extrato"
            ? this.$store.getters.dataExtrato.dtini.replace(/-/g, "/")
            : this.$store.getters.filtrosCompras.dtini.replace(/-/g, "/");
        return date;
      },
      set() {}
    },
    dtfim: {
      get() {
        let date =
          this.$route.path === "/receitas"
            ? this.$store.getters.filtrosReceitas.dtfim.replace(/-/g, "/")
            : this.$route.path === "/despesas"
            ? this.$store.getters.filtrosDespesas.dtfim.replace(/-/g, "/")
            : this.$route.path === "/extrato"
            ? this.$store.getters.dataExtrato.dtfim.replace(/-/g, "/")
            : this.$store.getters.filtrosCompras.dtfim.replace(/-/g, "/");
        return date;
      },
      set() {}
    }
  }
};
</script>

<style lang="stylus" scoped></style>
