<template>
  <q-page
    id="relatorio_gerencial"
    v-if="this.$router.history.current.name === 'relatorio_gerencial'"
  >
    <div class="col-12 col-md-12 text-center" v-if="buscando">
      <q-spinner color="blue-7" size="4em" />
    </div>

    <q-card class="bg-white" v-else-if="erro">
      <q-card-section>
        <div class="text-subtitle2">{{ erro }}</div>
      </q-card-section>
    </q-card>

    <q-card class="bg-white" v-else>
      <q-card-section>
        <div class="flex items-center">
          <q-btn flat round class="q-mr-sm" icon="arrow_back" @click="historicoShow()" />
          <div class="text-h6">{{ relatorio.nome }}</div>
        </div>
        <div class="text-subtitle2" v-html="relatorio.descricao"></div>
      </q-card-section>

      <q-card-actions>
        <div style="margin-right: 10px" v-for="(filtro, key) in relatorio.filtros" :key="key">
          <q-input
            v-if="filtro.type != 'option'"
            v-model="filtro.value"
            :label="filtro.labelUser"
            @click="toggle(filtro)"
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer" v-if="filtro.type === 'date'">
                <q-popup-proxy
                  :ref="'input' + filtro.labelUser"
                  transition-show="scale"
                  transition-hide="scale"
                  @click="toggle(filtro, 'hide')"
                >
                  <q-date v-model="filtro.value" mask="DD/MM/YYYY" minimal today-btn />
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <q-select
            v-else
            v-model="filtro.value"
            class="option"
            emit-value
            :display-value="returnLabel(filtro)"
            :options="filtro.options"
            :label="filtro.labelUser"
            clearable
          />
        </div>
      </q-card-actions>
      <q-card-actions>
        <q-btn
          flat
          label="Visualizar Relatório"
          class="bg-green text-white"
          @click="filtrar"
          :loading="loadRelatorio"
        >
          <template v-slot:loading>
            <q-spinner color="grey-10" size="1em" />
          </template>
        </q-btn>

        <q-btn
          flat
          label="Exportar"
          class="bg-green text-white"
          @click="exportarRelatorioExcel()"
          :loading="loadExportar"
        >
          <template v-slot:loading>
            <q-spinner color="grey-10" size="1em" />
          </template>
        </q-btn>

        <q-btn
          flat
          label="Exportar PDF"
          class="bg-green text-white"
          @click="exportarRelatorioPdf()"
          :loading="loadExportarPdf"
        >
          <template v-slot:loading>
            <q-spinner color="grey-10" size="1em" />
          </template>
        </q-btn>

        <q-btn
          flat
          label="Atualizar Ano Anterior"
          class="bg-green text-white"
          :loading="loadAtualizar"
          v-show="exibirAtualizarAnoAnterior"
          @click="atualizarRelatorio('ano_anterior')"
        >
          <template v-slot:loading>
            <q-spinner color="grey-10" size="1em" />
          </template>
        </q-btn>

        <q-btn
          flat
          label="Atualizar Ano"
          class="bg-green text-white"
          :loading="loadAtualizar"
          v-show="exibirAtualizarAno"
          @click="atualizarRelatorio()"
        >
          <template v-slot:loading>
            <q-spinner color="grey-10" size="1em" />
          </template>
        </q-btn>
      </q-card-actions>

      <q-card-section class="q-py-sm">
        <div class="text-grey">
          Os relatórios gerenciais são atualizados automaticamente todo dia às 23:00hs
        </div>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from '@/axios-auth';
import { scroll } from 'quasar';
import moment from 'moment';
import { bus } from '@/main';

export default {
  name: 'relatorio_gerencial',
  meta: {
    title: 'DSB | Relatório',
  },
  data() {
    return {
      relatorio: [],
      buscando: false,
      erro: null,
      data: {},
      loadRelatorio: false,
      loadAtualizar: false,
      loadExportarPdf: false,
      loadExportar: false,
    };
  },
  watch: {
    routeId() {
      this.buscaRelatorio();
    },
  },
  methods: {
    returnLabel(filtro) {
      let option = filtro.options.find(result => {
        return result.value === filtro.value;
      });

      if (option) return option.label;

      return filtro.value;
    },
    toggle(input, tipo = 'show') {
      if (input.type != 'date') return;
      let label = 'input' + input.labelUser + '';

      if (tipo === 'show') {
        return this.$refs[label][0].show();
      }
      return this.$refs[label][0].hide();
    },
    returnType(type) {
      return type === 'string' ? 'text' : type;
    },
    buscaRelatorio() {
      this.buscando = true;
      this.erro = null;
      scroll.setScrollPosition(window, 0);

      axios
        .get(`/relatorios/view-gerencial/${this.routeId}`)
        .then(res => {
          this.buscando = false;

          if (!res.data.success) {
            this.erro = res.data.message;
            return;
          }

          this.relatorio = res.data.relatorio;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
          this.buscando = false;
        });
    },
    filtrar() {
      this.loadRelatorio = true;
      let filtros = this.relatorio.filtros;

      if (filtros) {
        filtros.forEach(filtro => {
          this.data[filtro.label] = filtro.value;
        });
      }

      axios
        .post(`/relatorios/view-gerencial/${this.relatorio.id}`, this.data, {
          headers: {
            Accept: 'application/json,text/html',
          },
        })
        .then(res => {
          this.loadRelatorio = false;

          if (res.error) {
            throw res.error;
          }

          if (res.data.error) {
            this.$q.notify({
              message: res.data.error,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          let windowJanela = window.open('about:blank');
          windowJanela.document.write(res.data);
          windowJanela.document.close();
        })
        .catch(e => {
          if (e.message == "windowJanela is null"){
            this.$q.notify({
              message: 'O navegador bloqueou a janela do relatório!',
              color: 'red',
              position: 'top-right',
            });
          }else{
             this.$q.notify({
              message: 'Não foi possível contactar, ou você não possui permissão!',
              color: 'red',
              position: 'top-right',
            });
          }
          this.loadRelatorio = false;
        });
    },
    atualizarRelatorio(campoAno = 'ano') {
      this.loadAtualizar = true;

      const data = {
        ano: this.relatorio.filtros.find(filtro => filtro.label === campoAno).value,
      };
      const tipoGerencial = [9, 10].includes(parseInt(this.routeId)) ? 'dre2' : 'dre';

      this.$q
        .dialog({
          title: 'Atualizar relatório',
          message: `Todo o ano de ${data.ano} será atualizado, este processo pode demorar um pouco.`,
          persistent: true,
          cancel: true,
        })
        .onOk(() => {
          axios
            .post(`/relatorios/atualizar-${tipoGerencial}`, data, {
              // timeout: 1000 * 60 * 5, // 5 Minutos
              headers: {
                Accept: 'application/json',
              },
            })
            .then(res => {
              this.$q.notify({
                message: res.data.message,
                color: res.data.success ? 'green' : 'red',
                position: 'top-right',
              });
              this.loadAtualizar = false;
            })
            .catch(() => {
              this.$q.notify({
                message: 'Não foi possível contactar, ou você não possui permissão!',
                color: 'red',
                position: 'top-right',
              });
              this.loadAtualizar = false;
            });
        })
        .onCancel(() => {
          this.loadAtualizar = false;
        });
    },
    exportarRelatorioPdf() {
      this.loadExportarPdf = true;

      const filtros = this.relatorio.filtros;

      if (filtros) {
        filtros.forEach(filtro => {
          this.data[filtro.label] = filtro.value;
        });
      }

      axios
        .post(`/relatorios/view-gerencial/${this.relatorio.id}?exportar_pdf=true`, this.data, {
          headers: {
            Accept: 'text/html,application/json',
          },
        })
        .then(res => {
          this.loadExportarPdf = false;

          if (res.data.error) {
            this.$q.notify({
              message: res.data.error,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) return;

          window.open(res.data.linkArquivo, 'blank');
        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
          this.loadExportarPdf = false;
        });
    },
    exportarRelatorioExcel() {
      this.loadExportar = true;

      const filtros = this.relatorio.filtros;

      if (filtros) {
        filtros.forEach(filtro => {
          this.data[filtro.label] = filtro.value;
        });
      }

      axios
        .post(`/relatorios/view-gerencial/${this.relatorio.id}?exportar=true`, this.data, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then(res => {
          this.loadExportar = false;

          if (!res.data.message && !res.data.linkArquivo) throw new Error();

          if (res.data.error) {
            this.$q.notify({
              message: res.data.error,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.linkArquivo) return;

          window.location.href = res.data.linkArquivo;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
          this.loadExportar = false;
        });
    },
    historicoShow() {
      this.$router.push('/relatorios');
      bus.$emit('getHistoricoRelatorios');
    },
  },
  computed: {
    ...mapGetters(['relatoriosGerenciais']),
    routeId() {
      return this.$route.params.id;
    },
    exibirAtualizarAno() {
      if ([6].includes(this.relatorio.id)) return false;
      if (!this.relatorio.filtros) return false;

      return this.relatorio.filtros.some(filtro => filtro.label === 'ano');
    },
    exibirAtualizarAnoAnterior() {
      if (!this.relatorio.filtros) return false;

      return this.relatorio.filtros.some(filtro => filtro.label === 'ano_anterior');
    },
  },
  mounted() {
    this.buscaRelatorio();
  },
};
</script>

<style lang="stylus">
.option{
  width:200px;
}
</style>
