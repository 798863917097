import axios from '@/axios-auth';
import { Notify } from 'quasar';
import moment from "moment";

const state = {
  OpenDialogNovaEmpresa: false,
  OpenDialogNovoUsuario: false,
  OpenDialogNovoWebhook: false,
  isNewWebhook: true,
  webhooks: [],
  webhook: {
    acao: '',
    url: null,
    headers: {},
    ativo: true,
    descricao: '',
  },
  loadingWebhook: false,
  acoesWebhook: [],
  acoesWebhookArray: [],
  loadingWebhook: false,
  OpenDialogWebhookLogs: false,
  webhookLogs: [],
  paginationWebhookLogs: {
    page: 0,
    nextPage: true,
    count: 0
  },
  webhookLogsFilters: {
    dtini: '',
    dtfim: '',
  },
  loadingWebhookLogs: false,
  semPermissaoWebhooks: false,
  openDialogNovoFaturamentoAutomatico: false,
  faturamentoAutomatico: {},
  webhooksOptions: null
};

const mutations = {
  OpenDialogNovaEmpresa: (state, userData) => {
    state.OpenDialogNovaEmpresa = userData;
  },
  OpenDialogNovoUsuario: (state, userData) => {
    state.OpenDialogNovoUsuario = userData;
  },
  OpenDialogNovoWebhook: (state, userData) => {
    state.OpenDialogNovoWebhook = userData;
  },
  isNewWebhook: (state, userData) => {
    state.isNewWebhook = userData;
  },
  webhooks: (state, userData) => {
    state.webhooks = userData;
  },
  webhook: (state, userData) => {
    state.webhook = userData;
  },
  loadingWebhook: (state, userData) => {
    state.loadingWebhook = userData;
  },
  acoesWebhook: (state, userData) => {
    state.acoesWebhook = userData;
  },
  acoesWebhookArray: (state, userData) => {
    state.acoesWebhookArray = userData;
  },
  loadingWebhook: (state, userData) => {
    state.loadingWebhook = userData;
  },
  OpenDialogWebhookLogs: (state, userData) => {
    state.OpenDialogWebhookLogs = userData;
  },
  webhookLogs: (state, userData) => {
    state.webhookLogs = userData;
  },
  paginationWebhookLogs: (state, userData) => {
    state.paginationWebhookLogs = userData;
  },
  webhookLogsFilters: (state, userData) => {
    state.webhookLogsFilters = userData;
  },
  loadingWebhookLogs: (state, userData) => {
    state.loadingWebhookLogs = userData;
  },
  setSemPermissaoWebhooks: (state, userData) => {
    state.semPermissaoWebhooks = userData;
  },
  setOpenDialogNovoFaturamentoAutomatico: (state, userData) => {
    state.openDialogNovoFaturamentoAutomatico = userData;
  },
  setFaturamentoAutomatico: (state, userData) => {
    state.faturamentoAutomatico = userData;
  },
  webhooksOptions: (state, userData) => {
    state.webhooksOptions = userData;
  }
};

const actions = {
  getAcoesWebhook: ({ commit }) => {
    axios.get('/webhooks/add')
      .then((res) => {
        if (res.error && res.error.response.status === 403) {
          Notify.create({
            message: res.error.response.data.message,
            color: 'red',
            position: 'top-right',
          });
          return;
        }

        if (res.data.success) {
          const response = res.data.acoes;
          const acoes = Object.keys(response).map((key) => ({action: key, label: response[key]}));
          commit('acoesWebhookArray', acoes);
        }
      });
  },
  listWebhooks: ({ commit, state }) => {
    if (state.loadingWebhook) return;

    commit('loadingWebhook', true);
    commit('webhooks', []);
    axios.get('/webhooks')
      .then((res) => {
        if (res.error && res.error.response.status === 403) {
          commit('setSemPermissaoWebhooks', true);
          return;
        }

        if (res.data.success) {
          commit('setSemPermissaoWebhooks', false);
          commit('webhooks', res.data.data);
          commit('acoesWebhook', res.data.acoes);
          commit('webhooksOptions', res.data.options);
        }
      })
      .finally(() => {
        commit('loadingWebhook', false);
      });
  },
  listWebhookInfo: ({commit, state}, webhook) => {
    if (!state.paginationWebhookLogs.nextPage) return;
    if (state.loadingWebhookLogs) return;

    commit('loadingWebhookLogs', true);
    axios.get('/webhooks/info/' + webhook.id, {
      params: {
        page: state.paginationWebhookLogs.page + 1,
        dtini: state.webhookLogsFilters.dtini,
        dtfim: state.webhookLogsFilters.dtfim,
      }
    })
      .then((res) => {
        if (res.error && res.error.response.status === 403) {
          Notify.create({
            message: res.error.response.data.message,
            color: 'red',
            position: 'top-right',
          });
          return;
        }

        if (res.data.success) {
          commit('webhookLogs', state.webhookLogs.concat(res.data.data.fila_webhooks));
          commit('paginationWebhookLogs', res.data.data.paging);
        }
      })
      .finally(() => {
        commit('loadingWebhookLogs', false);
      });
  },
  resetPaginationWebhookLogs: ({commit}) => {
    commit('paginationWebhookLogs', {
      page: 0,
      nextPage: true
    });
    commit('webhookLogs', []);
  },
  abrirModalAdicionarFaturamento: ({ commit }, faturamentoAutomatico) => {
    if (!faturamentoAutomatico) {
      faturamentoAutomatico = {
        id: null,
        ativo: true,
        dia_execucao: parseInt(moment().add(1, 'day').format('DD')),
        horario_execucao: parseInt(moment().add(1, 'hour').format('HH')),
        dia_vencimento: null,
        mes_vencimento: 0,
        dia_inicial: 1,
        dia_final: 31,
        descricao_personalizada: 'Mensalidade {mesAtualNome}/{anoAtual}',
        utilizar_inicio_fim: false,
        personalizar_descricao: false,
        faturamento_diario: false,
        dias_antes: 10,
      };
    }

    commit("setFaturamentoAutomatico", faturamentoAutomatico);
    commit("setOpenDialogNovoFaturamentoAutomatico", true);
  },
};

const getters = {
  OpenDialogNovaEmpresa: state => state.OpenDialogNovaEmpresa,
  OpenDialogNovoUsuario: state => state.OpenDialogNovoUsuario,
  OpenDialogNovoWebhook: state => state.OpenDialogNovoWebhook,
  isNewWebhook: state => state.isNewWebhook,
  webhooks: state => state.webhooks,
  webhook: state => state.webhook,
  loadingWebhook: state => state.loadingWebhook,
  acoesWebhook: state => state.acoesWebhook,
  acoesWebhookArray: state => state.acoesWebhookArray,
  loadingWebhook: state => state.loadingWebhook,
  OpenDialogWebhookLogs: state => state.OpenDialogWebhookLogs,
  webhookLogs: state => state.webhookLogs,
  paginationWebhookLogs: state => state.paginationWebhookLogs,
  webhookLogsFilters: state => state.webhookLogsFilters,
  loadingWebhookLogs: state => state.loadingWebhookLogs,
  semPermissaoWebhooks: state => state.semPermissaoWebhooks,
  openDialogNovoFaturamentoAutomatico: state => state.openDialogNovoFaturamentoAutomatico,
  faturamentoAutomatico: state => state.faturamentoAutomatico,
  webhooksOptions: state => state.webhooksOptions
};

export default {
  state,
  mutations,
  actions,
  getters
};
