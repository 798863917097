<template>
  <q-layout view="hHh lpR fFf" class="fundo-alterar">
    <div id="alterarSenha">
      <div class="q-pa-md">
        <div class="row">
          <div class="col-12 col-md-3 fixed-center">
            <q-card>
              <q-card-section class="text-center">
                <img src="@/assets/douranovo.png" alt="DouraSoft Logo" width="150" />
              </q-card-section>

              <q-card-section v-if="listarActivatePass.preEtapa && !listarActivatePass.errorEtapa">
                <q-spinner color="blue-7" size="4em" style="position:relative;top: 50%;left: 40%;" />
              </q-card-section>

              <q-card-section
                v-if="!salvarActivatePass.segundaEtapa && !listarActivatePass.errorEtapa && !listarActivatePass.preEtapa"
              >
                <q-item-label
                  header
                >{{listarActivatePass.user.nome}}, crie uma nova senha para ativar o seu cadastro:</q-item-label>
              </q-card-section>

              <q-card-section
                v-if="!salvarActivatePass.segundaEtapa && !listarActivatePass.errorEtapa && !listarActivatePass.preEtapa"
              >
                <div class="row">
                  <div class="col-12 col-md-12">
                    <q-input
                      label="Digite sua nova senha"
                      v-model="senha.senha"
                      :type="isPwd ? 'password' : 'text'"
                      @input="val => verificaPass(val)"
                    >
                      <template v-slot:append>
                        <q-icon
                          :name="isPwd ? 'visibility_off' : 'visibility'"
                          class="cursor-pointer"
                          @click="isPwd = !isPwd"
                        />
                      </template>
                    </q-input>
                  </div>
                  <div class="col-12 col-md-12">
                    <br />
                    <br />
                    <q-input
                      label="Confirme sua nova senha"
                      type="password"
                      v-model="senha.confirmar"
                    ></q-input>
                  </div>

                  <div class="col-12 col-md-12">
                    <q-card-section
                      v-if="!salvarActivatePass.segundaEtapa && !listarActivatePass.errorEtapa && !listarActivatePass.preEtapa"
                    >
                      <q-list bordered padding class="rounded-borders">
                        <q-item-label header>A senha precisa</q-item-label>

                        <q-item>
                          <q-item-section>
                            <q-item-label lines="1">Possuir pelo menos seis caracteres</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-icon
                              :name="regra1 ? 'check' : 'close'"
                              :color="regra1 ? 'green' : 'red'"
                            />
                          </q-item-section>
                        </q-item>
                        <q-item>
                          <q-item-section>
                            <q-item-label lines="1">Possuir pelo menos uma letra</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-icon
                              :name="regra2 ? 'check' : 'close'"
                              :color="regra2 ? 'green' : 'red'"
                            />
                          </q-item-section>
                        </q-item>
                        <q-item>
                          <q-item-section>
                            <q-item-label lines="1">Possuir pelo menos um número</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-icon
                              :name="regra3 ? 'check' : 'close'"
                              :color="regra3 ? 'green' : 'red'"
                            />
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-card-section>
                  </div>

                  <div class="col col-md-12">
                    <br />
                    <br />
                    <br />
                    <q-btn
                      no-wrap
                      v-ripple
                      label="Salvar"
                      no-caps
                      class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                      style="margin: 0 15px 20px 0"
                      clickable
                      :loading="salvarActivatePass.loadAlterar"
                      @click="salvar()"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                  </div>
                </div>
              </q-card-section>

              <q-card-section class="text-center" v-if="listarActivatePass.errorEtapa">
                <p>Este Link já não é mais válido!</p>
                <br />
                <p class="invalido">
                  Ainda deseja efetuar a troca de sua senha?
                  <br />Clique
                  <router-link
                    class="black-text"
                    style="text-decoration: underline;color: black"
                    to="/alterar-senha"
                  >aqui</router-link>
                </p>
              </q-card-section>

              <q-card-section
                class="text-center"
                v-if="salvarActivatePass.segundaEtapa && !listarActivatePass.errorEtapa"
              >
                <p>Senha alterada com sucesso!</p>
                <span>Redirecionando para a tela de login em {{ time }}</span>
              </q-card-section>

              <q-separator />

              <q-card-section class="text-center" style="padding: 5px;font-size: 13px">
                <router-link
                  to="/login"
                  style="text-decoration:none; color: black"
                >Voltar ao Login...</router-link>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
    </div>
  </q-layout>
</template>

<script>
export default {
  name: "alterar-senha",
  meta: {
    title: "DSB | Ativar Senha"
  },
  data() {
    return {
      senha: {
        senha: "",
        confirmar: ""
      },
      time: 5,
      isPwd: true,
      isPwd2: true,
      regra1: false,
      regra2: false,
      regra3: false
    };
  },
  methods: {
    verificaPass(pass) {
      if (pass.length >= 6) {
        this.regra1 = true;
      } else {
        this.regra1 = false;
      }

      let testeL = /[a-zA-Z]/;

      if (testeL.test(pass)) {
        this.regra2 = true;
      } else {
        this.regra2 = false;
      }

      let testeN = /[0-9]/;

      if (testeN.test(pass)) {
        this.regra3 = true;
      } else {
        this.regra3 = false;
      }
    },
    listar() {
      this.$store.dispatch("listarActivatePass", this.$route.params.id);
    },
    salvar() {
      let dados = {
        id: this.$route.params.id,
        senha: this.senha
      };
      this.$store.dispatch("salvarActivatePass", dados);
      let cronometro = setInterval(() => {
          this.time--;
          if(this.time == 0){
            this.$router.push({ path: '/login'});
          }
      }, 1000);

    }
  },
  computed: {
    listarActivatePass() {
      return this.$store.getters.listarActivatePass;
    },
    salvarActivatePass() {
      return this.$store.getters.salvarActivatePass;
    }
  },
  mounted() {
    this.listar();
  }
};
</script>

<style lang="stylus" scoped></style>
