import axios from "@/axios-auth";
import { Dialog, Loading, Notify } from "quasar";

const state = {
  contas: [],
  contasOperacoes: [],
  contasTipos: [],
  newConta: {
    agencia: "",
    operacao: "",
    conta: "",
    tipo: "",
    isNew: true,
    id: null,
    titulo: "",
    ativo: false,
    padrao_sangria: false,
    padrao_reforco: false,
    password: ""
  },
  errorsContas: [],
  loadContas: false,
  OpenDialogNovaConta: false,
  contaPadraoSangria: false,
  contaPadraoReforco: false,
  hasDourasoftBank: false
};

const mutations = {
  contaPadraoSangria: (state, userData) => {
    state.contaPadraoSangria = userData;
  },
  contaPadraoReforco: (state, userData) => {
    state.contaPadraoReforco = userData;
  },
  contas: (state, userData) => {
    state.contas = userData;
  },
  contasOperacoes: (state, userData) => {
    state.contasOperacoes = userData;
  },
  contasTipos: (state, userData) => {
    state.contasTipos = userData;
  },
  newConta: (state, userData) => {
    state.newConta.agencia = userData.agencia;
    state.newConta.operacao = userData.operacao;
    state.newConta.conta = userData.conta;
    state.newConta.tipo = userData.tipo;
    state.newConta.isNew = userData.isNew;
    state.newConta.id = userData.id;
    state.newConta.titulo = userData.titulo;
    state.newConta.ativo = userData.ativo;
    state.newConta.padrao_sangria = userData.padrao_sangria;
    state.newConta.padrao_reforco = userData.padrao_reforco;

    if (userData.tipo === "banco-dourasoft") {
      state.newConta.hasPassword = userData.hasPassword;
    }
  },
  errorsContas: (state, userData) => {
    state.errorsContas = userData;
  },
  loadContas: state => {
    state.loadContas = !state.loadContas;
  },
  OpenDialogNovaConta: (state, userData) => {
    state.OpenDialogNovaConta = userData;
  },
  hasDourasoftBank: (state, userData) => {
    state.hasDourasoftBank = userData;
  }
};

const actions = {
  contas: ({ commit, state }, all = false) => {
    if (state.loadContas) return;
    commit("loadContas");

    let params = all ? { all: true } : {};

    axios
      .get("/contas", {
        params: params
      })
      .then(res => {
        if (res.data.success) {
          commit("contaPadraoSangria", res.data.conta_padrao_sangria);
          commit("contaPadraoReforco", res.data.conta_padrao_reforco);
          commit("contas", res.data.contas);
          commit("contasOperacoes", res.data.operacoes);
          commit("contasTipos", res.data.tipos);
          commit("hasDourasoftBank", res.data.has_dourasoft_bank);
        }
        commit("loadContas");
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadContas");
      });
  },
  excluirConta: ({ commit, dispatch }, userData) => {
    Dialog.create({
      title:
        'Digite <b style="color:red;">"Entendo os riscos"</b> para continuar!',
      cancel: "Cancelar",
      persistent: true,
      prompt: {
        model: "",
        type: "text" // optional
      },
      html: true
    })
      .onOk(() => {
        Loading.show();
      })
      .onOk(data => {
        axios
          .post("/contas/delete/" + userData.id, {
            entendo: data
          })
          .then(res => {
            if (res.data.success) {
              Dialog.create({
                title: res.data.message,
                class: "bg-positive text-white",
                ok: {
                  "text-color": "green",
                  color: "white"
                }
              });
              commit("contas", []);
              dispatch("contas");
              commit("OpenDialogNovaConta", false);
            } else {
              Dialog.create({
                title: res.data.message,
                class: "bg-warning text-white",
                ok: {
                  "text-color": "orange",
                  color: "white"
                }
              });
            }
          })
          .catch(() => {
            Dialog.create({
              title:
                "Não foi Possível realizar a ação, ou você não possui permissão isto!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white"
              }
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
  }
};

const getters = {
  contas: state => {
    return state.contas;
  },
  contasOperacoes: state => {
    return state.contasOperacoes;
  },
  contasTipos: state => {
    return state.contasTipos;
  },
  newConta: state => {
    return state.newConta;
  },
  errorsContas: state => {
    return state.errorsContas;
  },
  loadContas: state => {
    return state.loadContas;
  },
  OpenDialogNovaConta: state => {
    return state.OpenDialogNovaConta;
  },
  contaPadraoSangria: state => {
    return state.contaPadraoSangria;
  },
  contaPadraoReforco: state => {
    return state.contaPadraoReforco;
  },
  hasDourasoftBank: state => {
    return state.hasDourasoftBank;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
