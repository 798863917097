<template>
  <div id="resumoTFL">
    <div class="q-pb-md" style="padding-left: 0; padding-right: 0">
      <div class="q-col-gutter-md row">
        <div class="col-12 col-md-5">
          <q-markup-table
            dense
            separator="none"
            class="table-categorias"
          >
            <tbody>
              <tr
                clickable
                :key="categoria.id"
                @click="abreProd(categoria)"
                v-for="categoria in categoriasResumo"
                :class="{'active_status': movcaixacategoria.id === categoria.id, 'cursor-pointer': true}"
              >
                <td style="white-space: break-spaces;">
                  {{categoria.titulo}}
                </td>
                <td :class="comparaTotal('quantidade', categoria) + (movcaixacategoria.id === categoria.id ? 'text-white' : '')">
                  {{categoria.quantidade}}
                </td>
                <td :class="comparaTotal('valor', categoria) + (movcaixacategoria.id === categoria.id ? 'text-white' : '')">
                  R$ {{categoria.valor | currency}}
                </td>
                <td :class="{'text-white': movcaixacategoria.id === categoria.id, 'text-center' : true}">
                  <q-btn
                    :icon="categoria.ignorar? 'check_circle_outline' : 'replay'"
                    flat
                    size="sm"
                    round
                    padding="none"
                    v-if="categoria.ignorar !== null"
                    @click.stop="updateIgnorar(categoria)"
                  >
                    <q-tooltip>
                      <span v-if="categoria.ignorar">Habilitar esta detecção automática.</span>
                      <span v-else>Ignorar esta detecção automática.</span>
                      <br><span v-if="categoria.qnt_detect !== null">Quantidade: {{categoria.qnt_detect | currency }} - </span>
                      <span v-if="categoria.valor_detect !== null">Valor: R${{categoria.valor_detect | currency }} </span>
                    </q-tooltip>
                  </q-btn>
                </td>
              </tr>
            </tbody>
          </q-markup-table>

          <div class="float-right q-mt-lg">
            <q-list bordered padding class="rounded-borders" style="max-width: 200px">
              <q-item clickable v-ripple style="min-height: 28px;padding: 0px 16px;">
                <q-item-section>
                  <q-item-label lines="1">Créditos</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>R$ {{totalResumoCreditos | currency}}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-ripple style="min-height: 28px;padding: 0px 16px;">
                <q-item-section>
                  <q-item-label lines="1">Débitos</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>R$ {{totalResumoDebitos | currency}}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-ripple style="min-height: 28px;padding: 0px 16px;">
                <q-item-section>
                  <q-item-label lines="1">Saldo Total</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label>R$ {{somaSaldoTotal | currency}}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
        <div class="col-12 col-md-7">
          <q-markup-table
            dense
            separator="none"
            v-show="!disabled"
            class="table-tfl"
          >
            <thead class="bg-primary text-white">
              <tr>
                <td>{{ movcaixacategoria ? movcaixacategoria.titulo : '' }}</td>
                <td class="text-right" v-if="temColunaConcurso">Concurso</td>
                <td class="text-right" v-if="exibirQuantidadeMaos">Em Mãos</td>
                <td class="text-right">Quantidade</td>
                <td class="text-right">Valor</td>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!disabled && produtosCategoria.length === 0">
                <td colspan="4" class="text-center">
                  Nenhum produto cadastrado para esta categoria
                </td>
              </tr>
              <tr
                v-for="(produto, index) in produtosCategoria"
                :key="index"
                :id="'line' + index"
                class="produtos"
              >
                <td style="white-space: break-spaces;">
                  {{ getNomeProduto(produto) }}
                </td>
                <td
                  @keyup.enter="pularProx(index, 'concurso', produto)"
                  @keyup.tab="pularProx(index, 'concurso', produto)"
                  @keyup.down="campoPularBaixo(index, 'concurso')"
                  @keyup.up="campoPularCima(index, 'concurso')"
                  @click="selecionaLinha(index)"
                  side
                  v-if="temColunaConcurso"
                >
                  <q-btn
                    flat
                    size="sm"
                    icon="content_copy"
                    @click="duplicarProduto(produto, index)"
                    v-if="temColunaConcurso && produto.concurso"
                  >
                    <q-tooltip>Novo Concurso</q-tooltip>
                  </q-btn>

                  <q-field
                    dense
                    style="width: 50px"
                    class="float-right q-field--min-height-unset"
                    autocomplete="off"
                    v-if="produto.tpcontrole === 'Concurso'"
                  >
                    <template v-slot:default>
                      <the-mask
                        type="text"
                        placeholder=" "
                        autocomplete="off"
                        class="input-money"
                        style="text-align: right; width: 50px"
                        :masked="true"
                        :mask="['#####']"
                        :id="`concursoProd${index}`"
                        :class="{'input_verificado': produto.id}"
                        v-model="produto.concurso"
                      />
                    </template>
                  </q-field>
                </td>
                <td
                  @keyup.tab="pularProx(index, 'quantidadeMaos', produto)"
                  @keyup.enter="pularProx(index, 'quantidadeMaos', produto)"
                  @keyup.down="campoPularBaixo(index, 'quantidadeMaos')"
                  @keyup.up="campoPularCima(index, 'quantidadeMaos')"
                  @click="selecionaLinha(index)"
                  side
                  v-if="exibirQuantidadeMaos"
                >
                  <q-field
                    dense
                    style="width: 80px"
                    class="float-right q-field--min-height-unset"
                    autocomplete="off"
                  >
                    <template v-slot:default>
                      <the-mask
                        autocomplete="off"
                        :mask="['######']"
                        placeholder=" "
                        :id="'quantidadeMaosProd' + index"
                        :masked="true"
                        type="text"
                        style="text-align: right; width: 80px"
                        v-model="produto.estoque_maos"
                        :class="{'input_verificado': produto.id}"
                        class="input-money"
                      />
                    </template>
                  </q-field>
                </td>
                <td
                  @keyup.tab="pularProx(index, 'quantidadeProduto', produto)"
                  @keyup.enter="pularProx(index, 'quantidadeProduto', produto)"
                  @keyup.down="campoPularBaixo(index, 'quantidadeProduto')"
                  @keyup.up="campoPularCima(index, 'quantidadeProduto')"
                  @click="selecionaLinha(index)"
                  side
                >
                  <q-field
                    dense
                    no-error-icon
                    style="width: 80px"
                    class="float-right q-field--min-height-unset"
                    autocomplete="off"
                    :error="!ocultaValor(produto) && produto.valor > 0 && !produto.quantidade"
                  >
                    <template v-slot:default>
                      <the-mask
                        autocomplete="off"
                        :mask="['######']"
                        placeholder=" "
                        :masked="true"
                        :id="'quantidadeProd' + index"
                        type="text"
                        style="text-align: right; width: 80px;"
                        v-model="produto.quantidade"
                        :class="{
                          'input_verificado': produto.id,
                          'input_error': produto.quantidade === 0 && produto.valor !== 0
                        }"
                        class="input-money"
                      />
                    </template>
                  </q-field>
                </td>
                <td
                  @keyup.tab="pularProx(index, 'valorProduto', produto)"
                  @keyup.enter="pularProx(index, 'valorProduto', produto)"
                  @keyup.down="campoPularBaixo(index, 'valorProduto')"
                  @keyup.up="campoPularCima(index, 'valorProduto')"
                  @click="selecionaLinha(index)"
                  align="right"
                  side
                >
                  <q-field
                    dense
                    style="width: 100px"
                    class="float-right q-field--min-height-unset"
                    autocomplete="off"
                    :error="!ocultaValor(produto) && produto.quantidade > 0 && !produto.valor"
                    no-error-icon
                  >
                    <template v-slot:default>
                      <money
                        autocomplete="off"
                        type="text"
                        v-model="produto.valor"
                        v-bind="money"
                        style="text-align:right; width: 100px"
                        :id="'valorProd' + index"
                        :disabled="ocultaValor(produto)"
                        :class="{'input_verificado': produto.id}"
                        class="input-money"
                      ></money>
                    </template>
                  </q-field>
                </td>
              </tr>
            </tbody>
          </q-markup-table>
          <div class="col-12 col-md-12 text-center" v-if="disabled">
            <q-spinner color="blue-7" size="4em" />
          </div>
        </div>

        <div class="col-12 text-right" v-if="isMobile">
          <q-btn
            clickable
            color="primary"
            label="Salvar"
            :loading="salvandoTodosProdutos"
            @click="salvarTodosProdutos()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </div>

         <div class="col-5 q-table__title">
          <q-btn
            flat
            dense
            round
            icon="arrow_back"
            clickable
            @click="voltarPagina"
            >
            <template v-slot:default>
              <q-tooltip>Voltar aos Caixas</q-tooltip>
            </template>
          </q-btn>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios-auth';
import { mapGetters } from "vuex";
import { Money } from "v-money";
import Vue from "vue";
import VueTheMask from "vue-the-mask";
import moment from 'moment';
import { Platform } from "quasar";

Vue.use(VueTheMask);
export default {
  name: "resumo-tfl",
  components: {
    Money
  },
  data() {
    return {
      disabled: false,
      indexAtual: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        masked: false,
        precision: 2
      },
      salvandoTodosProdutos: false,
    };
  },
  methods: {
    updateIgnorar(categoria) {
      this.$store.dispatch("updateIgnorar", {
        linhaId: categoria.total_id,
        ignorar: !categoria.ignorar,
        caixaId: this.caixaId
      });
    },
    comparaTotal(tipo, categoria) {
      let toReturn = '';
      if(categoria.total_id && !categoria.ignorar) {
        if(tipo == 'quantidade') {
          (categoria.quantidade == categoria.qnt_detect || categoria.quantidade == (-1 * categoria.qnt_detect))? toReturn = 'text-green' : toReturn = 'text-red';
        } else {
          (categoria.valor == categoria.valor_detect || categoria.valor == (-1 * categoria.valor_detect))? toReturn = 'text-green' : toReturn = 'text-red';
        }
      }
      toReturn += ' text-right ';
      return toReturn;
    },
    voltarPagina() {
      return this.$router.push("/caixas/" + this.caixaId);
    },
    selecionaLinha(index) {
      document.getElementById("line" + index).classList.add("activeTr");
      const linhaAntiga = document.getElementById("line" + this.indexAtual);

      if (linhaAntiga) {
        linhaAntiga.classList.remove("activeTr");
      }

      this.indexAtual = index;
    },
    atualizaProd() {
      this.$store.dispatch("atualizaCatProdutos", {
        id: this.caixaId
      });
    },
    campoPularBaixo(index, tipoCampo) {
      if (tipoCampo === "concurso") {
        if (document.getElementById("concursoProd" + (index + 1))) {
        document.getElementById("concursoProd" + (index + 1)).focus();
        setTimeout(() => {
          document.getElementById("concursoProd" + (index + 1)).select();
        }, 1);
        document.getElementById("line" + (index + 1)).classList.add("activeTr");
        document.getElementById("line" + index).classList.remove("activeTr");
        this.indexAtual = index + 1;
        }
      }

      if (tipoCampo === "quantidadeMaos") {
        if (document.getElementById("quantidadeMaosProd" + (index + 1))) {
        document.getElementById("quantidadeMaosProd" + (index + 1)).focus();
        setTimeout(() => {
          document.getElementById("quantidadeMaosProd" + (index + 1)).select();
        }, 1);
        document.getElementById("line" + (index + 1)).classList.add("activeTr");
        document.getElementById("line" + index).classList.remove("activeTr");
        this.indexAtual = index + 1;
        }
      }

      if (tipoCampo === "quantidadeProduto") {
        if (document.getElementById("quantidadeProd" + (index + 1))) {
        document.getElementById("quantidadeProd" + (index + 1)).focus();
        setTimeout(() => {
          document.getElementById("quantidadeProd" + (index + 1)).select();
        }, 1);
        document.getElementById("line" + (index + 1)).classList.add("activeTr");
        document.getElementById("line" + index).classList.remove("activeTr");
        this.indexAtual = index + 1;
        }
      }

      if (tipoCampo === "valorProduto") {
        if (document.getElementById("valorProd" + (index + 1))) {
        document.getElementById("valorProd" + (index + 1)).focus();
        setTimeout(() => {
          document.getElementById("valorProd" + (index + 1)).select();
        }, 1);
        document.getElementById("line" + (index + 1)).classList.add("activeTr");
        document.getElementById("line" + index).classList.remove("activeTr");
        this.indexAtual = index + 1;
        }
      }
    },
    campoPularCima(index, tipoCampo) {
      if (tipoCampo === "concurso") {
        if (document.getElementById("concursoProd" + (index - 1))) {
          document.getElementById("concursoProd" + (index - 1)).focus();
          setTimeout(() => {
            document.getElementById("concursoProd" + (index - 1)).select();
          }, 1);
          document.getElementById("line" + (index - 1)).classList.add("activeTr");
          document.getElementById("line" + index).classList.remove("activeTr");
          this.indexAtual = index - 1;
        }
      }

      if (tipoCampo === "quantidadeMaos") {
        if (document.getElementById("quantidadeMaosProd" + (index - 1))) {
          document.getElementById("quantidadeMaosProd" + (index - 1)).focus();
          setTimeout(() => {
            document.getElementById("quantidadeMaosProd" + (index - 1)).select();
          }, 1);
          document.getElementById("line" + (index - 1)).classList.add("activeTr");
          document.getElementById("line" + index).classList.remove("activeTr");
          this.indexAtual = index - 1;
        }
      }

      if (tipoCampo === "quantidadeProduto") {
        if (document.getElementById("quantidadeProd" + (index - 1))) {
          document.getElementById("quantidadeProd" + (index - 1)).focus();
          setTimeout(() => {
            document.getElementById("quantidadeProd" + (index - 1)).select();
          }, 1);
          document.getElementById("line" + (index - 1)).classList.add("activeTr");
          document.getElementById("line" + index).classList.remove("activeTr");
          this.indexAtual = index - 1;
        }
      }

      if (tipoCampo === "valorProduto") {
        if (document.getElementById("valorProd" + (index - 1))) {
          document.getElementById("valorProd" + (index - 1)).focus();
          setTimeout(() => {
            document.getElementById("valorProd" + (index - 1)).select();
          }, 1);
          document.getElementById("line" + (index - 1)).classList.add("activeTr");
          document.getElementById("line" + index).classList.remove("activeTr");
          this.indexAtual = index - 1;
        }
      }
    },
    editaProdutoConcurso(produto, index) {
      produto.caixaId = this.$route.params.id;
      this.$store
        .dispatch("editProdutoResumo", produto)
        .then(res => {
          if (!res.success) {
            throw res.data;
          }

          const inputConcurso = document.getElementById("concursoProd" + index);

          if (inputConcurso) {
            inputConcurso.classList.remove("input_error");
            inputConcurso.classList.add("input_verificado");
          }
        })
        .catch(() => {
          const inputConcurso = document.getElementById("concursoProd" + index);

          if (inputConcurso) {
            inputConcurso.classList.remove("input_verificado");
            inputConcurso.classList.add("input_error");
          }
        });
    },
    editaProdutoValor(produto, index) {
      if (!produto.id && produto.valor === 0){
        return false;
      }

      produto.caixaId = this.$route.params.id;
      this.$store
        .dispatch("editProdutoResumo", produto)
        .then(res => {
          if (!res.success) {
            throw res.data;
          }

          const inputValor = document.getElementById("valorProd" + index);

          if (inputValor) {
            inputValor.classList.remove("input_error");
            inputValor.classList.add("input_verificado");
          }
        })
        .catch(() => {
          const inputValor = document.getElementById("valorProd" + index);

          if (inputValor) {
            inputValor.classList.remove("input_verificado");
            inputValor.classList.add("input_error");
          }
        });

    },
    editaProdutoQuantidade(produto, index, tipoCampo) {
      if (!produto.id && produto.quantidade === 0){
        return false;
      }

      produto.caixaId = this.$route.params.id;
      this.$store
        .dispatch("editProdutoResumo", produto)
        .then(res => {
          if (!res.success) {
            throw res.data;
          }

          if (tipoCampo === "quantidadeProduto") {
            if (document.getElementById("quantidadeProd" + index)) {
              document.getElementById("quantidadeProd" + index).classList.remove("input_error");
              document.getElementById("quantidadeProd" + index).classList.add("input_verificado");
            }
          } else {
            if (document.getElementById("quantidadeMaosProd" + index)) {
              document.getElementById("quantidadeMaosProd" + index).classList.remove("input_error");
              document.getElementById("quantidadeMaosProd" + index).classList.add("input_verificado");
            }
          }
        })
        .catch(() => {
          if (tipoCampo === "quantidadeProduto") {
            document.getElementById("quantidadeProd" + index).classList.remove("input_verificado");
            document.getElementById("quantidadeProd" + index).classList.add("input_error");
          } else {
            document.getElementById("quantidadeMaosProd" + index).classList.remove("input_verificado");
            document.getElementById("quantidadeMaosProd" + index).classList.add("input_error");
          }
        });

    },
    duplicarProduto(produto, index) {
      let params = {
        id: null,
        categoria_id: produto.categoria_id,
        produto_id: produto.produto_id,
        tpcontrole: produto.tpcontrole,
        is_resumo: true,
        quantidade: 0,
        valor: 0,
        concurso: null,
        duplicar: true,
      };

      axios
        .post(`/lancamentos/add-saldo/${this.$route.params.id}`, params)
        .then(res => {
          this.abreProd(this.movcaixacategoria);
        })
        .catch(() => {
        });
    },
    abreProd(selecionada) {
      this.disabled = true;
      this.$store
        .dispatch("buscaProdutos", {
          categoriaId: selecionada.id,
          caixaId: this.$route.params.id
        })
        .then(res => {
          this.disabled = false;
          if (res.produtos.length > 0) {
            setTimeout(() => {
              if (document.getElementById("concursoProd0")) {
                document.getElementById("concursoProd0").focus();
                document.getElementById("line0").classList.add("activeTr");
              } else if (this.movcaixacategoria.is_produtos && this.validarEstoque && !this.utilizarEstoqueEmpresa) {
                document.getElementById("quantidadeMaosProd0").focus();
                document.getElementById("line0").classList.add("activeTr");
              } else if (document.getElementById("quantidadeProd0")) {
                document.getElementById("quantidadeProd0").focus();
                document.getElementById("line0").classList.add("activeTr");
              }
            }, 10);
          }
        });
    },
    pularProx(index, tipoCampo, produto) {
      /*
        concursoProd
        quantidadeMaosProd
        quantidadeProd
        valorProd
        =======================
        concurso
        quantidadeMaos
        quantidadeProduto
        valorProduto

        Para direita quando é emMãos ou Quantidade
        para baixo quando é valor ou quando é quantidade e não pode digitar valor
        para proxima categoria quando é a ultima linha;
      */

      const temQuantidadeMaos = this.movcaixacategoria.is_produtos && this.validarEstoque && !this.utilizarEstoqueEmpresa;

      if (tipoCampo === "concurso") {
        const proximoCampo = temQuantidadeMaos ? 'quantidadeMaosProd' : 'quantidadeProd';

        if (document.getElementById(proximoCampo + index)) {
          document.getElementById(proximoCampo + index).focus();
          setTimeout(() => {
            document.getElementById(proximoCampo + index).select();
          }, 1);
        }
        // this.editaProdutoConcurso(produto, index, tipoCampo);
        return;
      }

      if (tipoCampo === "quantidadeMaos") {

          produto.quantidade = produto.estoque - produto.estoque_maos;

        document.getElementById("quantidadeProd" + index).focus();
        setTimeout(() => {
          document.getElementById("quantidadeProd" + index).select();
        }, 1);
         //this.editaProdutoQuantidade(produto, index, tipoCampo);
        return;
      }

      if (tipoCampo === "quantidadeProduto") {

        produto.estoque_maos = produto.estoque - produto.quantidade;

        if (!this.ocultaValor(produto)) {
          document.getElementById("valorProd" + index).focus();
          setTimeout(() => {
            document.getElementById("valorProd" + index).select();
          }, 1);
          // this.editaProdutoQuantidade(produto, index, tipoCampo);
          return true;
        }

        if (this.verificaUltimoElemento(index, tipoCampo, produto)) {
          return true;
        }

        if (this.movcaixacategoria.is_produtos && this.validarEstoque && !this.utilizarEstoqueEmpresa) {
          document.getElementById("quantidadeMaosProd" + (index + 1)).focus();
          setTimeout(() => {
            document.getElementById("quantidadeMaosProd" + (index + 1)).select();
          }, 1);
        } else {
          document.getElementById("quantidadeProd" + (index + 1)).focus();
          setTimeout(() => {
            document.getElementById("quantidadeProd" + (index + 1)).select();
          }, 1);
        }

        document.getElementById("line" + (index + 1)).classList.add("activeTr");
        document.getElementById("line" + index).classList.remove("activeTr");

        this.editaProdutoQuantidade(produto, index, tipoCampo);
        this.indexAtual = index + 1;
        return true;
      }

      if (tipoCampo === "valorProduto") {
        if (this.verificaUltimoElemento(index, tipoCampo, produto)) {
          return true;
        }

        if (this.temColunaConcurso && document.getElementById("concursoProd" + (index + 1))) {
          document.getElementById("concursoProd" + (index + 1)).focus();
          setTimeout(() => {
            document.getElementById("concursoProd" + (index + 1)).select();
          }, 1);
        } else if (produto.is_produto) {
          document.getElementById("quantidadeMaosProd" + (index + 1)).focus();
          setTimeout(() => {
            document.getElementById("quantidadeMaosProd" + (index + 1)).select();
          }, 1);
        } else {
          document.getElementById("quantidadeProd" + (index + 1)).focus();
          setTimeout(() => {
            document.getElementById("quantidadeProd" + (index + 1)).select();
          }, 1);
        }
        document.getElementById("line" + (index + 1)).classList.add("activeTr");
        this.indexAtual = index + 1;
        document.getElementById("line" + index).classList.remove("activeTr");
        this.editaProdutoValor(produto, index);
        return true;
      }

      return true;
    },
    verificaUltimoElemento(index, tipoCampo, produto) {
      if ( index < this.produtosCategoria.length - 1 ){
        return false;
      }

      this.editaProdutoQuantidade(produto, index, tipoCampo);

      const indexCategoria = this.categoriasResumo.findIndex(categoria => categoria.id === produto.categoria_id);
      const proximaCategoria = this.categoriasResumo[indexCategoria + 1];

      if (proximaCategoria) {
        this.abreProd(proximaCategoria);
      }

      return true;
    },
    buscaCategoria(index) {
      let i = -1;
      this.disabled = true;
      this.categoriasResumo.forEach(categoria => {
        if (categoria.id === this.movcaixacategoria.id) {
          i = this.categoriasResumo.indexOf(categoria);
        }
      });
      if (i >= 0 && i < this.categoriasResumo.length - 1) {
        this.$store
          .dispatch("buscaProdutos", {
            categoriaId: this.categoriasResumo[i + 1].id,
            caixaId: this.caixaId
          })
          .then(() => {
            this.disabled = false;
            if (this.produtosCategoria && this.produtosCategoria.length === 0){
              return false;
            }
            if (this.movcaixacategoria.is_produtos && this.validarEstoque && !this.utilizarEstoqueEmpresa) {
              setTimeout(() => {
                document.getElementById("quantidadeMaosProd0").focus();
                setTimeout(() => {
                  document.getElementById("quantidadeMaosProd0").select();
                }, 1);
                document.getElementById("line0").classList.add("activeTr");
              }, 10);
            } else {
              setTimeout(() => {
                document.getElementById("quantidadeProd0").focus();
                setTimeout(() => {
                  document.getElementById("quantidadeProd0").select();
                }, 1);
                document.getElementById("line0").classList.add("activeTr");
              }, 10);
            }
            document
              .getElementById("line" + index)
              .classList.remove("activeTr");
          })
          .catch(() => {
            this.disabled = false;
          });
      } else {
        this.disabled = false;
      }
    },
    isDisabled() {
      let i = -1;
      this.categoriasResumo.forEach(categoria => {
        if (categoria.id === this.movcaixacategoria.id) {
          i = this.categoriasResumo.indexOf(categoria);
        }
      });
      if (i >= 0 && i < this.categoriasResumo.length - 1) {
        return false;
      }
      return true;
    },
    ocultaValor(produto) {
      return produto && produto.tiposoma === "N" || this.movcaixacategoria.is_produtos;
    },
    getValorTotalProduto(index) {
      const produto = this.produtosCategoria[index];

      let quantidade = produto.quantidade ? produto.quantidade : 0;
      let valor = produto.valor ? produto.valor : 0;

      return quantidade * valor;
    },
    getNomeProduto(produto) {
      let nomeProduto = "";

      if (produto.nome && String(produto.nome).trim()) {
        nomeProduto += String(produto.nome).trim() + " ";
      }
      if (produto.extracao && String(produto.extracao).trim()) {
        nomeProduto += String(produto.extracao).trim() + " ";
      }
      if (produto.concurso && String(produto.concurso).trim()) {
        nomeProduto += String(produto.concurso).trim() + " ";
      }
      if (produto.evento && String(produto.evento).trim()) {
        nomeProduto += String(produto.evento).trim() + " ";
      }
      if (produto.vencimento && String(produto.vencimento).trim()) {
        nomeProduto += moment.utc(new Date(produto.vencimento)).format('DD/MM/YYYY') + " ";
      }

      return nomeProduto.trim();
    },
    salvarTodosProdutos() {
      this.salvandoTodosProdutos = true;

      Promise
        .all(this.produtosCategoria.map((produto, index ) => {
          return new Promise((resolve, reject) => {
            if (!produto.id && produto.valor === 0 && produto.quantidade === 0){
              return resolve(null);
            }

            produto.caixaId = this.$route.params.id;
            this.$store
              .dispatch("editProdutoResumo", produto)
              .then(res => {
                if (!res.success) {
                  return reject(res.data);
                }

                const inputValor = document.getElementById("valorProd" + index);

                if (inputValor) {
                  inputValor.classList.remove("input_error");
                  inputValor.classList.add("input_verificado");
                }

                resolve(res.data);
              })
              .catch(e => {
                const inputValor = document.getElementById("valorProd" + index);

                if (inputValor) {
                  inputValor.classList.remove("input_verificado");
                  inputValor.classList.add("input_error");
                }

                reject(e);
              });
          });
        }))
        .then(() => {
          this.salvandoTodosProdutos = false;
        })
        .catch(() => {
          this.salvandoTodosProdutos = false;
        });
    },
  },
  computed: {
    ...mapGetters([
      "produtosCategoria",
      "movcaixa",
      "categoriasResumo",
      "totalResumoDebitos",
      "totalResumoCreditos",
      "movcaixacategoria",
      "caixaId",
      "opcoesEstoque",
      "utilizarEstoqueEmpresa",
      "validarEstoque"
    ]),
    somaSaldoTotal() {
      return this.totalResumoDebitos + this.totalResumoCreditos;
    },
    exibirQuantidadeMaos() {
      return this.movcaixacategoria.is_produtos && this.validarEstoque;
    },
    temColunaConcurso() {
      return this.movcaixacategoria && [1, 13, 8].includes(parseInt(this.movcaixacategoria.id));
    },
    isMobile() {
      return Platform.is.mobile;
    },
  },
  mounted() {
    this.$store
      .dispatch("buscaProdutos", {
        categoriaId: this.movcaixacategoria.id,
        caixaId: this.$route.params.id
      })
      .then(() => {
        setTimeout(() => {
          if (document.getElementById("quantidadeProd0")) {
            document.getElementById("quantidadeProd0").focus();
            setTimeout(() => {
              document.getElementById("quantidadeProd0").select();
            }, 1);
            document.getElementById("line0").classList.add("activeTr");
          } else if(document.getElementById("quantidadeMaosProd0")) {
            document.getElementById("quantidadeMaosProd0").focus();
            setTimeout(() => {
              document.getElementById("quantidadeMaosProd0").select();
            }, 1);
            document.getElementById("line0").classList.add("activeTr");
          }
        }, 10);
      });
  },
  beforeDestroy() {
    this.$store.dispatch("buscaCaixa", this.$route.params.id);
  }
};
</script>

<style lang="stylus" scoped>
.activeTr {
  background-color: #eeeeee;
}

.input_verificado {
  border-bottom: 1px solid #34a853 !important;
  -webkit-box-shadow: 0 1px 0 0 #34a853 !important;
  box-shadow: 0 1px 0 0 #34a853 !important;
}

.input_error {
  border-bottom: 1px solid #ea4335 !important;
  -webkit-box-shadow: 0 1px 0 0 #ea4335 !important;
  box-shadow: 0 1px 0 0 #ea4335 !important;
}

.input-money {
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  border: none;
  border-top-color: initial;
  border-top-style: none;
  border-top-width: initial;
  border-right-color: initial;
  border-right-style: none;
  border-right-width: initial;
  border-bottom-color: initial;
  border-bottom-style: none;
  border-bottom-width: initial;
  border-left-color: initial;
  border-left-style: none;
  border-left-width: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  background: none;
  color: rgba(0, 0, 0, 0.87);
  outline: 0;
}
.q-input {
  max-height:20px;
}

.q-table--dense td {
  font-size: 14px;
}

.table-tfl {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.table-categorias.q-table--dense td:last-child {
  padding-right: 4px;
}

table.q-table .q-field--with-bottom {
  padding-bottom: 0;
}
</style>

<style lang="stylus">
.q-field--min-height-unset.q-field--auto-height.q-field--dense .q-field__control,
.q-field--min-height-unset.q-field--auto-height.q-field--dense .q-field__native {
  min-height: unset;
}
</style>
