import axios from "@/axios-auth";
import router from "@/router/router";
import { Notify } from "quasar";

const state = {
  loadEmpresa: false
};

const mutations = {
  loadEmpresa: (state, userData) => {
    state.loadEmpresa = userData.loadEmpresa;
  }
};

const actions = {
  changeMultiplo: ({ commit, dispatch }, userData) => {
    commit("loadEmpresa", {
      loadEmpresa: true
    });
    const newCompany = {
      empresa_id: userData.id
    };
    axios
      .post("/users/token", newCompany)
      .then(res => {
        if (res.data.success) {
          localStorage.setItem("empresa", res.data.data.empresa.id);
          localStorage.setItem("menu", JSON.stringify(res.data.data.menu));
          localStorage.setItem(
            "token" + localStorage.getItem("empresa"),
            res.data.data.token
          );
          dispatch("token", res.data.data.token);
          if (res.data.data.isTour) {
            router.go("/tour");
          } else if (res.data.data.isConfig) {
            router.go("/config");
          } else {
            window.open("/visao360", "_blank");
          }
          dispatch("setLogoutTImer", res.data.data.validade);
          commit("loadEmpresa", {
            loadEmpresa: false
          });
        }
      })
      .catch(() => {
        Notify.create({
          message:
            "Não foi possível trocar a empresa, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadEmpresa", {
          loadEmpresa: false
        });
      });
    dispatch("fetchUser");
  },
  changeCompany: ({ commit, dispatch }, userData) => {
    commit("loadEmpresa", {
      loadEmpresa: true
    });
    const newCompany = {
      empresa_id: userData.id
    };
    axios
      .post("/users/token", newCompany)
      .then(res => {
        if (res.data.success) {
          let empresa_id = res.data.data.empresa.id;
          let empresa = `empresa${empresa_id}`;

          localStorage.setItem('empresa_atual', empresa_id);
          localStorage.setItem(empresa, empresa_id);
          localStorage.setItem(`empresa${empresa_id}`, empresa_id);
          localStorage.setItem(`menu${empresa_id}`, JSON.stringify(res.data.data.menu));
          localStorage.setItem(
            `token${empresa_id}`,
            res.data.data.token
          );
          localStorage.setItem('empresa_plano', res.data.data.empresa.assinatura.plano);

          // return false
          commit("dataUser", res.data.data.user);
          commit("dataEmpresa", res.data.data.empresa);

          if (res.data.data.isTour) {
            router.go("/tour");
          } else if (res.data.data.isConfig) {
            router.go("/config");
          } else {
            window.open("/visao360", "_self");
          }
          dispatch("setLogoutTImer", res.data.data.validade);
          commit("loadEmpresa", {
            loadEmpresa: false
          });
        }
      })
      .catch(() => {
        Notify.create({
          message:
            "Não foi possível trocar a empresa, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("loadEmpresa", {
          loadEmpresa: false
        });
      });
    dispatch("fetchUser");
  }
};

const getters = {
  loadEmpresa: state => {
    return state.loadEmpresa;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
