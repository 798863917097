import { render, staticRenderFns } from "./modal-nova-categoria.vue?vue&type=template&id=6c38467e&scoped=true&"
import script from "./modal-nova-categoria.vue?vue&type=script&lang=js&"
export * from "./modal-nova-categoria.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c38467e",
  null
  
)

export default component.exports