<template>
  <q-layout class="fundo-login flex justify-center">
    <div id="registro" class="q-pa-lg">
      <q-stepper
        header-nav
        ref="stepper"
        color="primary"
        style="max-width: 700px;"
        v-model="step"
        v-if="!registrar.quartaEtapa"
      >
        <template v-slot:default>
          <q-step
            icon="settings"
            :name="1"
            :done="step > 1"
            :error="errors.email ? true : false"
            :title="$q.screen.gt.sm ? 'Dados Pessoais' : ''"
          >
            <div class="text-center">
              <img src="@/assets/douranovo.png" alt="DouraSoft Logo" width="150" />
            </div>
            <div class="row">
              <div class="col-12">
                <q-input
                  v-model="registro.email"
                  label="E-mail"
                  v-on:blur="userCheckEmail(registro)"
                  bottom-slots
                  :error="errors.email ? true : false || status"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.email" :key="error + i">{{ error }}</span>
                    <span v-if="status">Email já esta sendo utilizado</span>
                  </template>
                  <template v-if="!status && registro.email">
                    <q-icon
                      :name="!warning ? 'check' : 'warning'"
                      size="30px"
                      :color="!warning ? 'green' : 'red'"
                      style="margin-top: 15px"
                    />
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-input
                  v-model="registro.nome"
                  label="Nome"
                  bottom-slots
                  :error="errors.nome ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.nome" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-input
                  :disable="codigoValido"
                  v-model="registro.celular"
                  label="Whatsapp"
                  mask="(##)#####-####"
                  bottom-slots
                  :hint="
                    !recebeuPrimeiroCodigo
                      ? 'Você receberá uma mensagem com o código de ativação de conta'
                      : null
                  "
                  :error="errors.celular ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.celular" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>

              <div class="col-12 col-md-4" v-show="recebeuPrimeiroCodigo && !codigoValido">
                <q-input
                  mask="####"
                  label="Insira o código"
                  bottom-slots
                  :error="!!errors.codigo"
                  v-model="codigo"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.codigo" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>
              <div
                style="font-size: 12px"
                class="col-12 col-md-10 text-grey q-mt-sm"
                v-show="recebeuPrimeiroCodigo && !codigoValido"
              >
                <div>
                  Confirme o código enviado para seu Whatsapp.
                </div>
                <div>
                  Não recebeu o código?
                  <span
                    class="cursor-pointer text-info"
                    @click.prevent="receberCodigo()"
                    v-if="!loadingReceberCodigo && tempoProximoCodigo === 0"
                  >
                    Tente novamente
                  </span>
                  <span v-else>
                    Tente novamente
                    {{ tempoProximoCodigo > 0 ? `em ${tempoProximoCodigo} segundos` : '' }}
                  </span>
                </div>
              </div>
            </div>
          </q-step>

          <q-step
            icon="wb_cloudy"
            :name="2"
            :done="step > 2"
            :header-nav="step > 1"
            :title="$q.screen.gt.sm ? 'Dados da Empresa' : ''"
          >
            <div class="text-center">
              <img src="@/assets/douranovo.png" alt="DouraSoft Logo" width="150" />
            </div>
            <div class="row">
              <div class="col-12">
                <q-input
                  v-model="registro.cnpj"
                  label="CNPJ"
                  mask="##.###.###/####-##"
                  bottom-slots
                  :error="errors.cnpj ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.cnpj" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>
            </div>
          </q-step>

          <q-step
            icon="work"
            :name="3"
            :header-nav="step > 2"
            :title="$q.screen.gt.sm ? 'Endereço da Empresa' : ''"
          >
            <div class="text-center">
              <img src="@/assets/douranovo.png" alt="DouraSoft Logo" width="150" />
            </div>
            <div class="row q-col-gutter-x-md">
              <div class="col-12">
                <q-input
                  v-model="registro.razao"
                  label="Razão Social"
                  bottom-slots
                  :error="errors.razao ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.razao" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-input
                  v-model="registro.fantasia"
                  label="Fantasia"
                  bottom-slots
                  :error="errors.fantasia ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.fantasia" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>

              <div class="col-12 col-md-6">
                <q-input
                  v-model="registro.cep"
                  label="CEP"
                  mask="#####-###"
                  bottom-slots
                  :error="errors.cep ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.cep" :key="error + i">{{ error }}</span>
                  </template>
                  <template v-if="registro.cep && registro.cep.length > 0" v-slot:append>
                    <q-btn
                      :loading="fetchCEP.buscandoCEP"
                      name="search"
                      clickable
                      @click.stop="consultaCEP(registro.cep)"
                      icon="search"
                      dense
                      class="cursor-pointer bg-positive"
                      color="text-white"
                    >
                      <template v-slot:loading>
                        <q-spinner color="grey-10" size="1em" />
                      </template>
                    </q-btn>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="registro.cidade"
                  label="Cidade"
                  bottom-slots
                  :error="errors.cidade ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.cidade" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="registro.estado"
                  label="Estado"
                  bottom-slots
                  :error="errors.estado ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.estado" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="registro.bairro"
                  label="Bairro"
                  bottom-slots
                  :error="errors.bairro ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.bairro" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-input
                  v-model="registro.endereco"
                  label="Endereço Empresa"
                  bottom-slots
                  :error="errors.endereco ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.endereco" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="registro.numero"
                  label="Número"
                  id="numero"
                  bottom-slots
                  :error="errors.numero ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.numero" :key="error + i">{{ error }}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="registro.complemento"
                  label="Complemento"
                  bottom-slots
                  :error="errors.complemento ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errors.complemento" :key="error + i">{{
                      error
                    }}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-8">
                <p>
                  Leia os
                  <a
                    href="https://dourasoft.com.br/termos-de-uso/"
                    style="color: black; text-decoration: underline"
                    target="_blank"
                    >Termos de uso</a
                  >
                </p>
                <q-list>
                  <q-item tag="label" v-ripple>
                    <q-item-section avatar top>
                      <q-checkbox v-model="registro.acceptTerms" color="green" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label
                        >Concordo que li e aceito os termos de uso do sistema.</q-item-label
                      >
                      <q-item-label caption v-if="errors.acceptTerms">
                        <span
                          style="color: red"
                          v-for="(error, i) in errors.acceptTerms"
                          :key="error + i"
                          >{{ error }}</span
                        >
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-step>
        </template>
        <template v-slot:navigation>
          <q-stepper-navigation class="text-right">
            <q-btn
              style="margin-top: 10px; margin-left: 10px"
              v-if="step > 1"
              flat
              color="primary"
              @click="$refs.stepper.previous()"
              label="Voltar"
              class="q-ml-sm"
            />
            <q-btn
              color="primary"
              style="margin-top: 10px; margin-left: 10px"
              :loading="loadingVerificarCodigo"
              :label="!recebeuPrimeiroCodigo ? 'Receber código' : 'Verificar'"
              :disable="
                recebeuPrimeiroCodigo &&
                (loadingVerificarCodigo ||
                  !codigo ||
                  codigo.length !== 4 ||
                  codigo === ultimoCodigo)
              "
              @click="verificarCodigo()"
              v-if="step === 1 && !codigoValido"
            />
            <q-btn
              v-if="step === 2"
              color="primary"
              label="Continuar"
              style="margin-top: 10px; margin-left: 10px"
              :loading="fetchCNPJ.buscandoCNPJ"
              :disable="!registro.cnpj || registro.cnpj.length === 0"
              @click="consultaCNPJ(registro.cnpj)"
            >
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
            <q-btn
              v-if="step === 1 && codigoValido"
              style="margin-top: 10px; margin-left: 10px"
              @click="$refs.stepper.next()"
              color="primary"
              :label="step === 3 ? 'Finalizar' : 'Continuar'"
            />
            <q-btn
              v-if="step === 3"
              style="margin-top: 10px; margin-left: 10px"
              @click="registrarse()"
              color="primary"
              :loading="registrar.saveRegistro"
              :label="step === 3 ? 'Finalizar' : 'Continuar'"
            />
          </q-stepper-navigation>
          <q-separator />
          <q-stepper-navigation class="text-center" style="padding: 5px; font-size: 13px">
            <router-link to="/login" style="text-decoration: none; color: black"
              >Já possui cadastro? Faça Login...</router-link
            >
          </q-stepper-navigation>
        </template>
      </q-stepper>
      <q-card v-if="registrar.quartaEtapa">
        <q-card-section class="text-center">
          <img src="@/assets/douranovo.png" alt="DouraSoft Logo" width="150" />
        </q-card-section>
        <q-card-section class="text-center">
          <youtube
            v-if="hasVideo"
            ref="youtube"
            style="width: 425px; height: 240px"
            :video-id="videoId"
            :player-vars="playerVars"
          />
        </q-card-section>
        <q-card-section>
          <br />
          <p class="text-justify">
            <strong>{{ registro.nome }}</strong
            >, acabamos de enviar um e-mail para
            <strong>{{ registro.email }}</strong>
            contendo seu acesso ao sistema.
          </p>
          <br />
          <p class="text-justify">
            Agora, você deve clicar no link de ativação enviado no seu E-mail para completarmos a
            ativação do seu cadastro e ter acesso ao melhor sistema de gestão finaneiro que a sua
            empresa pode ter.
          </p>
          <p>Abraços. Equipe DouraSoft do Brasil.</p>
          <p class="text-right">
            <q-btn label="Saiba mais" clickable v-ripple @click="gotoPortal()" color="blue-7" />
          </p>
        </q-card-section>
        <q-separator />
        <q-card-section style="padding: 5px; font-size: 13px" class="text-center">
          <router-link to="/login" style="text-decoration: none; color: black"
            >Faça Login...</router-link
          >
        </q-card-section>
      </q-card>
    </div>
  </q-layout>
</template>

<script>
import axios from 'axios';
import { Notify } from 'quasar';
import { bus } from '@/main';
import { mapGetters } from "vuex";
import Vue from "vue";
import VueYoutube from "vue-youtube";
import { getIdFromUrl } from 'vue-youtube';

Vue.use(VueYoutube);

export default {
  name: 'registro',
  meta: {
    title: 'DSB | Registro',
  },
  data() {
    return {
      warning: false,
      status: false,
      nextEtapa: false,
      prevEtapa: false,
      primeiraEtapa: true,
      segundaEtapa: false,
      terceiraEtapa: false,
      finalizaEtapa: false,
      saveRegistro: false,
      step: 1,
      // errors: [],
      recebeuPrimeiroCodigo: false,
      loadingReceberCodigo: false,
      loadingVerificarCodigo: false,
      codigo: null,
      tempoProximoCodigo: 0,
      ultimoCodigo: null,
      codigoValido: false,
      hasVideo: false,
      playerVars: {
        autoplay: 0,
        controls: 1,
      },
      videoId: ''
    };
  },
  watch: {
    passosRegistro(value) {
      if (value === 1) {
        this.step = 1;
      } else if (value === 2) {
        this.step = 2;
      }
    },
    videoRegistro: {
      handler(value) {
        if (value.link !== null) {
          this.videoId = getIdFromUrl(value.link);

          this.hasVideo = true;
        }
      },
      deep: true
    }
  },
  methods: {
    consultaCNPJ() {
      this.$store.commit('registrar', {
        ...this.registrar,
        errors: {},
      });

      let dados = {
        cnpj: this.registro.cnpj,
        url: '/empresas/cnpj/',
        onSuccess: () => this.$refs.stepper.next(),
      };
      this.$store.dispatch('fetchCNPJ', dados);
    },
    consultaCEP() {
      this.$store.dispatch('fetchCEP', this.registro.cep);
      document.getElementById('numero').focus();
    },
    registrarse() {
      this.$store.dispatch('registrar', this.registro);
    },
    gotoPortal() {
      window.open('https://dourasoft.com.br/portal', '_blank');
    },
    userCheckEmail(value) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.email)) {
        this.warning = true;
        this.status = false;
        return;
      }
      axios
        .post(
          '/users/registrar?checkemail=' + value.email,
          {},
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        )
        .then(res => {
          this.warning = false;
          this.status = !res.data.success;
        })
        .catch(() => {
          Notify.create({
            message: 'Não foi possível verificar email !',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    verificarCodigo() {
      this.loadingVerificarCodigo = true;

      if (!this.recebeuPrimeiroCodigo) {
        this.receberCodigo();
        return;
      }

      const data = {
        uuid: this.registro.uuid,
        codigo: this.codigo,
      };

      this.$store.commit('registrar', {
        ...this.registrar,
        errors: {},
      });

      axios
        .post('/users/confirmar-codigo-celular', data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then(res => {
          this.loadingVerificarCodigo = false;

          if (!res.data.success) {
            this.ultimoCodigo = data.codigo;

            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });

            this.$store.commit('registrar', {
              ...this.registrar,
              errors: res.data.errors || {},
            });
            return;
          }

          this.codigoValido = true;
          this.$refs.stepper.next();
        })
        .catch(() => {
          this.loadingVerificarCodigo = false;
          this.ultimoCodigo = data.codigo;

          this.$q.notify({
            message: 'Houve um erro ao receber o código',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    receberCodigo() {
      this.loadingReceberCodigo = true;

      const data = {
        celular: this.registro.celular,
      };

      this.$store.commit('registrar', {
        ...this.registrar,
        errors: {},
      });

      axios
        .post('/users/gerar-codigo-celular', data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then(res => {
          this.loadingVerificarCodigo = false;
          this.loadingReceberCodigo = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });

            this.$store.commit('registrar', {
              ...this.registrar,
              errors: res.data.errors || {},
            });
            return;
          }

          this.recebeuPrimeiroCodigo = true;

          this.$store.dispatch('registro', {
            ...this.registro,
            uuid: res.data.uuid,
          });

          this.tempoProximoCodigo = 60;
          this.iniciarContadorTempoProximoCodigo();
        })
        .catch(() => {
          this.loadingVerificarCodigo = false;
          this.loadingReceberCodigo = false;

          this.$q.notify({
            message: 'Houve um erro ao receber o código',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    iniciarContadorTempoProximoCodigo() {
      setTimeout(() => {
        if (this.tempoProximoCodigo > 0) {
          this.tempoProximoCodigo--;
          this.iniciarContadorTempoProximoCodigo();
        }
      }, 1000);
    },
    resetCelularValido() {
      this.codigo = null;
      this.ultimoCodigo = null;
      this.codigoValido = false;
    },
  },
  computed: {
    ...mapGetters([
      'videoRegistro',
      'registrar',
      'passosRegistro',
      'registrar',
      'registro',
      'fetchCNPJ',
      'fetchCEP',
    ]),
    errors() {
      return this.registrar.errors;
    },
    passosRegistro() {
      return this.$store.getters.passosRegistro;
    },
  },
  mounted() {
    bus.$on('celularDiferenteRegistro', () => this.resetCelularValido());
  },
  destroyed() {
    bus.$off('celularDiferenteRegistro');
  },
};
</script>

<style>
#registro {
  display: flex;
  justify-content: center;
  align-items: center;
}

#registro .q-panel.scroll {
  overflow: inherit;
}

#registro .q-stepper .q-stepper__header {
  padding: 8px 0;
}

#registro .q-stepper .q-stepper__header .q-stepper__tab {
  min-height: 40px;
}

#registro .q-stepper .q-stepper__step-inner {
  padding: 0 10%;
}
</style>
